import profile from './Profile.en';

export default {
  meta: {
    title: 'My Home Resiliency Audit Status',
    url: 'https://www.floodhelpny.org/en/audit-status'
  },
  previewHeader: 'Home Resiliency Report Preview',
  floodZoneProvider: profile.floodZoneProvider,
  auditRateProvider: {
    header: 'YOUR INSURANCE RATE, BASED ON YOUR ELEVATION CERTIFICATE',
    current: 'Current maps',
    future: 'Advisory maps',
    zone: 'zone',
    perYear: 'Per year*',
    assumingMax: '*Assuming max coverage',
    numbersAreEstimates:
      'These numbers are estimates for many reasons. To learn more about them go to our ',
    termsAndConditions: 'Terms & Conditions'
  },
  errorText:
    'Something went wrong. <a href="mailto:info@floodhelpny.org">Contact us</a> if this continues to happen.',
  loadingText: 'Loading...'
};
