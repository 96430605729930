export default {
  header: 'ENFMASYON SOU APLIKASYON AN:',
  // All questions keys must be listed here with a corresponding title.
  // Use the yes or no keys as replacements for boolean answers (true, false)
  yes: 'Yes',
  no: 'No',
  co_applicant_first_name: 'Prenon',
  co_applicant_last_name: 'Siyati',
  co_applicant_birthdate: 'Dat Nesans (AAAA-MM-JJ)',
  co_applicant_email: 'Imèl',
  co_applicant_home_phone: 'Telefòn Kay',
  co_applicant_mobile_phone: 'Telefòn Mobil',
  co_applicant_signature: 'Siyati',
  co_applicant_type: 'Kalite Ko-Aplikan',
  e_signature: 'Siyati elektwonik',
  household_member_race: 'Ras',
  household_member_income: 'Revni Anyèl',
  household_member_ethnicity: 'Etnisite',
  household_member_gender: 'Sèks',
  sandy_compensation: 'Konpansasyonpou Sandy',
  sandy_insurance_types: 'Asirans ki Te La Pandan Sipètanpèt Sandy an',
  tenants: 'Lokatè',
  flood_insurance: 'Dokiman pou Asirans kont Inondasyon',
  homeowners_insurance: 'Dokiman pou Asirans pou Pwopriyetè Kay',
  household_member_proofincome: 'Prèv Revni pou Fwaye an',
  backwater_valve_compensation: 'Konpansasyon pou Dlo k ap Tounen',
  applicant_race: 'Ras Aplikan Prensipal lan',
  applicant_ethnicity: 'Etnisite Aplikan Prensipal lan',
  applicant_gender: 'Idantite Seksyèl Aplikan Prensipal lan',
  applicant_birthdate: 'Dat Nesans Aplikan Prensipal lan'
};
