export default {
  meta: {
    title: 'Ваш предварительный расчет тарифа',
    url: 'https://floodhelpny.org/ru/rate',
    description: null,
    image: null,
    imageAlt: null
  },
  profileCalloutCopy: 'Мы добавили некоторые рекомендации. ',
  noMitigationCopy: "Мы не смогли найти никаких вариантов мер по предотвращению или снижению рисков",
  profileCalloutLink: 'Просмотреть ваш профиль устойчивости.',
  betaCalloutCopy:
    "Как вы заметили, довольно сложно разобраться в предварительном расчете страхования от наводнения. В сущности, никто в Нью-Йорке не предоставляет аналогичный инструмент для расчета тарифов населению. Мы приложили большие усилия по разреботке инструмента, который представляет содержательные предварительные расчеты. Однако, мы выпускаем этот инструмент как первую версию осознавая, что он несовершенен. С вашей помощью мы можем его улучшить для всех ньюйоркцев. Если вам покажется, что результаты, которые вы получаете, неверны, свяжитесь с нами по электронной почте по адресу",
  betaCalloutLink: 'info@floodhelpny.org.',

  headerProvider: {
    backToProfileLink: '‹ Назад к профилю',
    header: 'Ваш предварительный расчет страхования от наводнения.',
    editAnswersLink: 'Начать сначала',
    copy:
      'Мы дадим вам подробные объяснения ваших потенциальных затрат сейчас и после изменения карт.'
  },
  rateCalculatorResultProvider: {
    header: 'Предварительный расчет  страхования от наводнения',
    beta: 'BETA',
    link: 'See your full estimate',
    currentMapStart: 'Посмотрите ваш полный предварительный расчет ',
    currentMapEnd: 'Текущие карты (',
    futureMapStart: 'Ориентировочные карты (',
    futureMapEnd: ' зона)',
    assumingMax: '*Предполагая максимальное покрытие',
    numbersAreEstimates:
      'Эти цифры являются приблизительными по многим причинам. Для получения допольнительной информации о них обратитесь к нашим ',
    termsAndConditions: 'Правилам и условиям',
    floodZoneRateProvider: {
      perYear: 'В год*'
    },
    rateAdjusterProvider: {
      adjustYourCoverage: 'Корректировка вашего страхового покрытия',
      buildingCoverage: 'Страховое покрытие здания',
      buildingCoverageTooltip:
        'Страховое покрытие здания покрывает страхование только здания, но не содержимого или двора. Оно включает некоторую встроенную мебель и бытовые приборы, а также инженерно-техническое оборудование.',
      buildingDeductible: 'Вычитаемая франшиза здания',
      buildingDeductibleTooltip:
        'Это сумма денег, которые вы должны будете выплатить из собственного кармана на ремонт повреждения здания до того, как ваше страхование от наводнения вступит в действие.',
      contentCoverage: 'Страховое покрытие содержимого',
      contentCoverageTooltip:
        'Страховое покрытие содержимого покрывает страхование вашего имущества внутри вашего дома. Стиральные машины и сушилки, а также морозильники считаются содержимым.  (Плиты и холодильники застрахованы вашим “страховым покрытием здания.”)',
      contentDeductible: 'Вычитаемая франшиза содержимого',
      contentDeductibleTooltip:
        'Это сумма денег, которые вы должны будете выплатить из собственного кармана на замену поврежденного имущества до того, как ваше страхование от наводнения вступит в действие.',
      collapse: 'Разрушение'
    }
  },
  prefirmCalloutProvider: {
    copy:
      'Субсидии для пре-FIRM заканчиваются. Ваши будущие тарифы могут подняться на целых 18% в год!'
  },
  lomaCalloutProvider: {
    header: 'Подождите! Ваш дом возможно не должен находиться в зоне повышенного риска наводнения.',
    body:
      'Похоже ваш дом находится на возвышенности. Получите ваш сертификат высотной отметки и попросите вашего страхового брокера подать запрос на Письмо поправки карты (LOMA), что является запросом на изменение карты наводнения и исключением вашей собственности из зоны наводнения. Если ваш LOMA будет успешным, вы сможете значительноо понизить ваши тарифы страхования от наводнения. ',
    link: 'Дополнительная информация'
  }
};
