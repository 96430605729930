import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useProvider } from '../helpers/hooks';

const Container = styled.div`
  margin-bottom: 3rem;
`;

const List = styled.ul`
  max-width: 100%;
  padding-right: 0;
  margin: 0;
  list-style: none;
  list-style-type: none;
  padding: 0;
  margin: 0;
  li + li {
    margin-top: 15px;
  }
  @media (max-width: 768px) {
    text-align: center;
    flex-direction: column;
    text-align: left;
  }
`;

const ListItem = styled.li`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  position: relative;

  span:first-child {
    margin-right: 10px;
  }
`;

const AuditApplicationReview = ({ answers }) => {
  const provider = useProvider('auditApplicationReview');

  // TODO refactor this logic.
  const formattedAnswer = (answer) => {
    let returnedAnswer;
    if (answer === 'true') {
      returnedAnswer = provider.yes;
    } else if (answer === 'false') {
      returnedAnswer = provider.no;
      // answer.name checks if the answer is a File Object and returns the name
    } else if (answer.name) {
      returnedAnswer = answer.name;
    } else if (answer === '') {
      return 'N/A';
      // check if the answer is an array
    } else if (Array.isArray(answer)) {
      returnedAnswer = answer.join(', ') || 'N/A';
    } else {
      returnedAnswer = answer;
    }
    return returnedAnswer;
  };
  // TODO refactor this logic
  const filterSurveyInfo = () => {
    const filteredInfo = [];
    answers.forEach((answerSet) => {
      Object.entries(answerSet).forEach((answer) => {
        // Do not push business-logic entries
        if (answer[0] === 'webstatus') {
          return;
        }
        if (
          !answer[0].includes('co_applicant') &&
          !answer[0].includes('household_member')
        ) {
          filteredInfo.push(answer);
        } else if (answer[1]) {
          // push entries with answers
          filteredInfo.push(answer);
        }
      });
    });
    return filteredInfo;
  };

  const renderResponses = () => {
    const filteredAnswers = filterSurveyInfo();
    const listItems = filteredAnswers.map((answerSet) => (
      <ListItem key={answerSet[0]}>
        <span>{provider[answerSet[0]]}:</span>
        <span>{formattedAnswer(answerSet[1])}</span>
      </ListItem>
    ));
    return <div>{listItems}</div>;
  };

  return (
    <Container>
      <h6>{provider.header}</h6>
      <List>{renderResponses()}</List>
    </Container>
  );
};

AuditApplicationReview.propTypes = {
  answers: PropTypes.array.isRequired
};

export default AuditApplicationReview;
