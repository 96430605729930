export default {
  multifamilyApplication: 'https://www.tfaforms.com/439873',
  addressInput: {
    placeholder: 'Search for your address',
    action: 'See If You’re Prequalified'
  },
  eligible: {
    eligible: 'Good news! You may be eligible!',
    apply: 'Apply Now'
  },
  ineligible: {
    ineligible: 'Unfortunately, your home is not in a neighborhood currently served by this program. Please learn more about your flood risk',
    callToAction: ' here.'
  },
  ineligibleOutsideNYC: {
    copy: 'Oops! This tool only works for NYC addresses. Click the button for national resources.',
    link: 'Visit FloodSmart.gov'
  },
  ineligibleTooBroad: {
    copy: "We're sorry. We were unable to find information for the address you entered. Please verify the address or try again later."
  },
  termsAndConditions: {
    title: 'Agree to Terms and Conditions and Privacy Policy',
    disclaimerOne: '1. We cannot guarantee the accuracy of your zone',
    disclaimerTwo: '2. Your flood insurance estimate may not be accurate',
    links: {
      sentenceOpener: 'Learn more in our ',
      termsLinkText: 'Terms and Conditions',
      termsLinkUrl: 'terms',
      conjunction: ' and ',
      privacyLinkText: 'Privacy Policy',
      privacyLinkUrl: 'privacy'
    },
    navigation: {
      agree: 'I Agree',
      disagree: 'I Disagree'
    }
  },
  lookupError: {
    title: 'Error',
    error: "We're sorry. We were unable to find information for the address you entered. Please verify the address or try again later.",
    tryAgain: 'Try Again'
  },
  multipleResultsError: {
    title: 'Error',
    error: "We're sorry. There are multiple matches for the address you entered. Please verify the address or try again later.",
    tryAgain: 'Try Again'
  }
};
