import reportCard from '../images/report-card.svg';
import certificate from '../images/certificate.svg';
import documents from '../images/documents.svg';
import backwaterValve from '../images/backwater-valve.svg';

const example = 'Посмотреть пример';

export default {
  header: "Что вы получите",
  list: [
    {
      header: 'Канализационный обратный клапан',
      body:
        'Наша команда, включая инженеров и сантехников, будет работать с вами, чтобы определить, поможет ли вашему дому установка канализационного обратного клапана.',
      image: backwaterValve
    },
    {
      header: 'Консультация по страхованию от наводнения',
      body:
        'Наши эксперты консультанты рассмотрят ваш страховой полис от наводнения и обсудят возможные варианты, которые помогут вам сократить расходы на страхование от наводнения путем рассмотрения сертификата о высотной отметке дома и вариантов недопущения негативных последствий. ',
      image: documents
    },
    {
      header: 'Отчет об устойчивости',
      body:
        'После того, как мы проведем инспецию вашего дома и оценим его риск затопления, мы вышлем вам детальный технический отчет, в комплекте с измерениями и информацией о том, какие модификации  (например, подъем ваших механических систем) могут сделать ваш дом более устойчивым к наводнениям.',
      image: reportCard,
      exampleLink: '/samples/technical_report_john_doe.pdf',
      example
    },
    {
      header: 'Сертификат о высотной отметке дома',
      headerLink: '/ru/elevation-certificates',
      body:
        'Мы также вышлем вам документ, сертифицирующий высотную отметку вашего дома. Если вы живете в зоне повышенного риска затопления, сертификат высотной отметки дома может удержать ваш тариф от взлета до небес. ',
      image: certificate,
      exampleLink: '/samples/elevation_certificate.pdf',
      example
    }
  ]
};
