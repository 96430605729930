export default [
  {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [-73.880111, 40.837234],
                [-73.877472, 40.836564],
                [-73.871447, 40.836123],
                [-73.867674, 40.8353],
                [-73.866529, 40.83496],
                [-73.866806, 40.834837],
                [-73.863032, 40.83371],
                [-73.862837, 40.833688],
                [-73.862869, 40.833871],
                [-73.860324, 40.833116],
                [-73.860377, 40.832926],
                [-73.848779, 40.82953],
                [-73.847178, 40.828748],
                [-73.846999, 40.828],
                [-73.859703, 40.826277],
                [-73.859529, 40.825891],
                [-73.869603, 40.824684],
                [-73.869843, 40.824925],
                [-73.872004, 40.82465],
                [-73.873595, 40.824433],
                [-73.873423, 40.824074],
                [-73.884059, 40.822666],
                [-73.884247, 40.823046],
                [-73.884137, 40.82321],
                [-73.884399, 40.824987],
                [-73.884327, 40.825928],
                [-73.883305, 40.829157],
                [-73.88348, 40.829611],
                [-73.883927, 40.829928],
                [-73.884, 40.830179],
                [-73.883379, 40.832027],
                [-73.882519, 40.832148],
                [-73.881391, 40.833539],
                [-73.880964, 40.835674],
                [-73.880111, 40.837234]
              ]
            ]
          ]
        },
        properties: {
          cartodb_id: 64,
          objectid: 64,
          postalcode: '10472',
          po_name: 'Bronx',
          state: 'NY',
          borough: 'Bronx',
          st_fips: '36',
          cty_fips: '005',
          bldgpostalcode: 0,
          shape_leng: 27006.0424107,
          shape_area: 30963245.188,
          id: 'http://nyc.pediacities.com/Resource/PostalCode/10472'
        }
      },
      {
        type: 'Feature',
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [-73.770107, 40.731779],
                [-73.765362, 40.725162],
                [-73.764422, 40.724433],
                [-73.761399, 40.722913],
                [-73.759997, 40.721759],
                [-73.753809, 40.713576],
                [-73.75246, 40.710317],
                [-73.764558, 40.707408],
                [-73.767344, 40.706636],
                [-73.767251, 40.706313],
                [-73.774181, 40.705087],
                [-73.775821, 40.705374],
                [-73.776782, 40.707729],
                [-73.778109, 40.707403],
                [-73.778384, 40.70805],
                [-73.779373, 40.707812],
                [-73.781783, 40.71314],
                [-73.773571, 40.71512],
                [-73.776815, 40.721857],
                [-73.777669, 40.72272],
                [-73.779326, 40.725398],
                [-73.781161, 40.729213],
                [-73.775097, 40.730874],
                [-73.770107, 40.731779]
              ]
            ]
          ]
        },
        properties: {
          cartodb_id: 12,
          objectid: 12,
          postalcode: '11423',
          po_name: 'Hollis',
          state: 'NY',
          borough: 'Queens',
          st_fips: '36',
          cty_fips: '081',
          bldgpostalcode: 0,
          shape_leng: 31339.8030169,
          shape_area: 45095032.1013,
          id: 'http://nyc.pediacities.com/Resource/PostalCode/11423'
        }
      },
      {
        type: 'Feature',
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [-73.80904, 40.719912],
                [-73.792875, 40.72544],
                [-73.781161, 40.729213],
                [-73.779326, 40.725398],
                [-73.777669, 40.72272],
                [-73.776815, 40.721857],
                [-73.773571, 40.71512],
                [-73.781783, 40.71314],
                [-73.779683, 40.708596],
                [-73.782952, 40.707972],
                [-73.786454, 40.707924],
                [-73.78889, 40.707421],
                [-73.79273, 40.706295],
                [-73.800395, 40.703273],
                [-73.804442, 40.702132],
                [-73.807622, 40.708731],
                [-73.808897, 40.710599],
                [-73.809046, 40.7112],
                [-73.810105, 40.71337],
                [-73.810221, 40.715706],
                [-73.810439, 40.716406],
                [-73.810952, 40.716708],
                [-73.810715, 40.716905],
                [-73.811804, 40.717675],
                [-73.812118, 40.719062],
                [-73.80904, 40.719912]
              ]
            ]
          ]
        },
        properties: {
          cartodb_id: 14,
          objectid: 14,
          postalcode: '11432',
          po_name: 'Jamaica',
          state: 'NY',
          borough: 'Queens',
          st_fips: '36',
          cty_fips: '081',
          bldgpostalcode: 0,
          shape_leng: 33123.9436723,
          shape_area: 60177575.638,
          id: 'http://nyc.pediacities.com/Resource/PostalCode/11432'
        }
      },
      {
        type: 'Feature',
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [-73.779683, 40.708596],
                [-73.779373, 40.707812],
                [-73.778384, 40.70805],
                [-73.778109, 40.707403],
                [-73.776782, 40.707729],
                [-73.775821, 40.705374],
                [-73.775074, 40.705242],
                [-73.771044, 40.69849],
                [-73.769294, 40.69665],
                [-73.777301, 40.692249],
                [-73.777281, 40.692118],
                [-73.779525, 40.69155],
                [-73.786669, 40.688704],
                [-73.790385, 40.687754],
                [-73.793908, 40.686305],
                [-73.79596, 40.689132],
                [-73.797153, 40.690406],
                [-73.798447, 40.692135],
                [-73.799748, 40.693348],
                [-73.80081, 40.694059],
                [-73.80128, 40.695581],
                [-73.802295, 40.697688],
                [-73.802768, 40.699105],
                [-73.803896, 40.700899],
                [-73.804442, 40.702132],
                [-73.800395, 40.703273],
                [-73.79273, 40.706295],
                [-73.78889, 40.707421],
                [-73.786454, 40.707924],
                [-73.782952, 40.707972],
                [-73.779683, 40.708596]
              ]
            ]
          ]
        },
        properties: {
          cartodb_id: 20,
          objectid: 20,
          postalcode: '11433',
          po_name: 'Jamaica',
          state: 'NY',
          borough: 'Queens',
          st_fips: '36',
          cty_fips: '081',
          bldgpostalcode: 0,
          shape_leng: 30603.7354116,
          shape_area: 47739432.5954,
          id: 'http://nyc.pediacities.com/Resource/PostalCode/11433'
        }
      },
      {
        type: 'Feature',
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [-73.769893, 40.696312],
                [-73.766051, 40.692159],
                [-73.766315, 40.692197],
                [-73.764727, 40.68925],
                [-73.764354, 40.689507],
                [-73.764138, 40.689081],
                [-73.763258, 40.687301],
                [-73.762949, 40.686008],
                [-73.760666, 40.682612],
                [-73.75801, 40.679239],
                [-73.75634, 40.676803],
                [-73.762486, 40.674195],
                [-73.763677, 40.674746],
                [-73.764047, 40.672847],
                [-73.762251, 40.671943],
                [-73.762934, 40.671813],
                [-73.762598, 40.670722],
                [-73.763644, 40.667163],
                [-73.766754, 40.667433],
                [-73.76713, 40.666696],
                [-73.763532, 40.666287],
                [-73.766159, 40.660085],
                [-73.766527, 40.660136],
                [-73.766961, 40.658479],
                [-73.766123, 40.658554],
                [-73.765824, 40.656304],
                [-73.767353, 40.65616],
                [-73.774237, 40.659899],
                [-73.77982, 40.662202],
                [-73.785335, 40.663856],
                [-73.787693, 40.665163],
                [-73.788629, 40.665265],
                [-73.79014, 40.665161],
                [-73.789739, 40.665512],
                [-73.789537, 40.665976],
                [-73.788063, 40.673236],
                [-73.789449, 40.674556],
                [-73.790663, 40.67667],
                [-73.791868, 40.679747],
                [-73.792673, 40.682517],
                [-73.792569, 40.68403],
                [-73.79275, 40.684742],
                [-73.793908, 40.686305],
                [-73.790385, 40.687754],
                [-73.786669, 40.688704],
                [-73.779525, 40.69155],
                [-73.777281, 40.692118],
                [-73.777301, 40.692249],
                [-73.769893, 40.696312]
              ]
            ]
          ]
        },
        properties: {
          cartodb_id: 25,
          objectid: 25,
          postalcode: '11434',
          po_name: 'Jamaica',
          state: 'NY',
          borough: 'Queens',
          st_fips: '36',
          cty_fips: '081',
          bldgpostalcode: 0,
          shape_leng: 43829.8571706,
          shape_area: 87379704.1564,
          id: 'http://nyc.pediacities.com/Resource/PostalCode/11434'
        }
      },
      {
        type: 'Feature',
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [-73.846999, 40.828],
                [-73.845801, 40.828323],
                [-73.844289, 40.827974],
                [-73.842654, 40.827987],
                [-73.841763, 40.825757],
                [-73.840425, 40.823347],
                [-73.840437, 40.823062],
                [-73.839777, 40.821554],
                [-73.840055, 40.820382],
                [-73.839944, 40.820025],
                [-73.840179, 40.819569],
                [-73.841296, 40.818897],
                [-73.84195, 40.818765],
                [-73.842189, 40.818356],
                [-73.842137, 40.817961],
                [-73.843059, 40.81656],
                [-73.843192, 40.816019],
                [-73.843743, 40.81531],
                [-73.845193, 40.811873],
                [-73.845804, 40.811574],
                [-73.846189, 40.810671],
                [-73.846916, 40.810518],
                [-73.847231, 40.810601],
                [-73.848243, 40.812492],
                [-73.848714, 40.812896],
                [-73.85043, 40.813556],
                [-73.850796, 40.814018],
                [-73.85113, 40.814009],
                [-73.851687, 40.814286],
                [-73.852446, 40.81427],
                [-73.852548, 40.814504],
                [-73.852906, 40.814503],
                [-73.852819, 40.814335],
                [-73.852639, 40.814345],
                [-73.852645, 40.813976],
                [-73.852941, 40.813826],
                [-73.852862, 40.813687],
                [-73.853528, 40.81359],
                [-73.855346, 40.814401],
                [-73.855353, 40.814199],
                [-73.854727, 40.813846],
                [-73.853656, 40.813531],
                [-73.852432, 40.813745],
                [-73.85106, 40.813526],
                [-73.849648, 40.812747],
                [-73.84906, 40.812159],
                [-73.849035, 40.811852],
                [-73.849404, 40.811291],
                [-73.849176, 40.810899],
                [-73.849746, 40.810072],
                [-73.850014, 40.809345],
                [-73.849981, 40.808622],
                [-73.849448, 40.807913],
                [-73.848534, 40.807481],
                [-73.848299, 40.807046],
                [-73.848302, 40.806359],
                [-73.847666, 40.806124],
                [-73.847462, 40.805838],
                [-73.847655, 40.805149],
                [-73.848636, 40.804718],
                [-73.848799, 40.804926],
                [-73.848999, 40.804771],
                [-73.849203, 40.804971],
                [-73.849755, 40.805065],
                [-73.85, 40.804994],
                [-73.850168, 40.804528],
                [-73.850586, 40.804468],
                [-73.855463, 40.804475],
                [-73.856557, 40.804813],
                [-73.856494, 40.804932],
                [-73.855632, 40.804688],
                [-73.855633, 40.805132],
                [-73.856204, 40.805356],
                [-73.857092, 40.805274],
                [-73.856732, 40.804913],
                [-73.857863, 40.805291],
                [-73.858951, 40.80591],
                [-73.859349, 40.806565],
                [-73.859392, 40.80765],
                [-73.859205, 40.80763],
                [-73.8591, 40.806858],
                [-73.858599, 40.806903],
                [-73.858474, 40.807236],
                [-73.858583, 40.807506],
                [-73.858433, 40.80825],
                [-73.858638, 40.80856],
                [-73.858937, 40.810227],
                [-73.859101, 40.80992],
                [-73.860085, 40.809583],
                [-73.85954, 40.80902],
                [-73.859326, 40.808996],
                [-73.859418, 40.80884],
                [-73.859267, 40.808012],
                [-73.859386, 40.807923],
                [-73.859613, 40.80878],
                [-73.860008, 40.809267],
                [-73.860633, 40.809634],
                [-73.863722, 40.810195],
                [-73.867702, 40.810603],
                [-73.868242, 40.811137],
                [-73.869769, 40.813423],
                [-73.870733, 40.814488],
                [-73.871758, 40.814935],
                [-73.877695, 40.816508],
                [-73.878932, 40.817142],
                [-73.881283, 40.818844],
                [-73.882475, 40.819791],
                [-73.882747, 40.820616],
                [-73.883678, 40.822369],
                [-73.884078, 40.822637],
                [-73.873423, 40.824074],
                [-73.873595, 40.824433],
                [-73.872004, 40.82465],
                [-73.869843, 40.824925],
                [-73.869603, 40.824684],
                [-73.859529, 40.825891],
                [-73.859703, 40.826277],
                [-73.846999, 40.828]
              ]
            ]
          ]
        },
        properties: {
          cartodb_id: 69,
          objectid: 69,
          postalcode: '10473',
          po_name: 'Bronx',
          state: 'NY',
          borough: 'Bronx',
          st_fips: '36',
          cty_fips: '005',
          bldgpostalcode: 0,
          shape_leng: 46333.3466971,
          shape_area: 59522354.5398,
          id: 'http://nyc.pediacities.com/Resource/PostalCode/10473'
        }
      },
      {
        type: 'Feature',
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [-73.750471, 40.70564],
                [-73.745283, 40.694115],
                [-73.747073, 40.691523],
                [-73.747372, 40.691476],
                [-73.748377, 40.689719],
                [-73.74973, 40.687833],
                [-73.755132, 40.685433],
                [-73.756106, 40.686762],
                [-73.76073, 40.685098],
                [-73.760424, 40.683376],
                [-73.760666, 40.682612],
                [-73.762949, 40.686008],
                [-73.763258, 40.687301],
                [-73.764354, 40.689507],
                [-73.764727, 40.68925],
                [-73.766315, 40.692197],
                [-73.766051, 40.692159],
                [-73.769893, 40.696312],
                [-73.769294, 40.69665],
                [-73.771044, 40.69849],
                [-73.775074, 40.705242],
                [-73.774181, 40.705087],
                [-73.767251, 40.706313],
                [-73.767344, 40.706636],
                [-73.764558, 40.707408],
                [-73.75246, 40.710317],
                [-73.750471, 40.70564]
              ]
            ]
          ]
        },
        properties: {
          cartodb_id: 136,
          objectid: 136,
          postalcode: '11412',
          po_name: 'Saint Albans',
          state: 'NY',
          borough: 'Queens',
          st_fips: '36',
          cty_fips: '081',
          bldgpostalcode: 0,
          shape_leng: 29899.340989,
          shape_area: 46687803.7023,
          id: 'http://nyc.pediacities.com/Resource/PostalCode/11412'
        }
      },
      {
        type: 'Feature',
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [-73.866317, 40.683357],
                [-73.866027, 40.681918],
                [-73.864101, 40.682373],
                [-73.863303, 40.679167],
                [-73.862346, 40.679165],
                [-73.860389, 40.671269],
                [-73.857633, 40.671656],
                [-73.857267, 40.670255],
                [-73.857091, 40.668429],
                [-73.856448, 40.667016],
                [-73.855685, 40.663868],
                [-73.856144, 40.663806],
                [-73.85835, 40.661191],
                [-73.859018, 40.661127],
                [-73.859023, 40.660562],
                [-73.859722, 40.660457],
                [-73.860317, 40.659995],
                [-73.860853, 40.660095],
                [-73.861225, 40.659963],
                [-73.861265, 40.659697],
                [-73.860816, 40.65942],
                [-73.860802, 40.65916],
                [-73.861815, 40.658894],
                [-73.863469, 40.658071],
                [-73.863, 40.65743],
                [-73.861733, 40.658028],
                [-73.861626, 40.657858],
                [-73.861703, 40.656649],
                [-73.86006, 40.655593],
                [-73.859364, 40.654963],
                [-73.85917, 40.654454],
                [-73.858532, 40.653688],
                [-73.858314, 40.653019],
                [-73.857712, 40.652738],
                [-73.856935, 40.652819],
                [-73.856605, 40.652667],
                [-73.856552, 40.652231],
                [-73.856375, 40.652122],
                [-73.856345, 40.651397],
                [-73.856002, 40.651319],
                [-73.855899, 40.651132],
                [-73.856966, 40.650642],
                [-73.857246, 40.650847],
                [-73.857289, 40.651242],
                [-73.858275, 40.65171],
                [-73.858413, 40.652171],
                [-73.858975, 40.652912],
                [-73.860401, 40.653879],
                [-73.861364, 40.655122],
                [-73.862437, 40.655306],
                [-73.863917, 40.656116],
                [-73.865991, 40.658862],
                [-73.875818, 40.654654],
                [-73.876907, 40.654547],
                [-73.879325, 40.655029],
                [-73.879733, 40.655563],
                [-73.878117, 40.65626],
                [-73.87915, 40.65766],
                [-73.876708, 40.658714],
                [-73.882507, 40.666469],
                [-73.882395, 40.666517],
                [-73.885277, 40.677781],
                [-73.885085, 40.677835],
                [-73.886505, 40.683349],
                [-73.888241, 40.682532],
                [-73.889941, 40.684034],
                [-73.888438, 40.685093],
                [-73.887849, 40.685906],
                [-73.884075, 40.687808],
                [-73.879674, 40.691129],
                [-73.874375, 40.693985],
                [-73.869334, 40.69487],
                [-73.868584, 40.693862],
                [-73.867472, 40.688447],
                [-73.866598, 40.68527],
                [-73.866317, 40.683357]
              ]
            ]
          ]
        },
        properties: {
          cartodb_id: 144,
          objectid: 144,
          postalcode: '11208',
          po_name: 'Brooklyn',
          state: 'NY',
          borough: 'Brooklyn',
          st_fips: '36',
          cty_fips: '047',
          bldgpostalcode: 0,
          shape_leng: 49932.2412248,
          shape_area: 79294685.4617,
          id: 'http://nyc.pediacities.com/Resource/PostalCode/11208'
        }
      },
      {
        type: 'Feature',
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [-73.903681, 40.690461],
                [-73.902113, 40.692002],
                [-73.901493, 40.691424],
                [-73.901886, 40.690811],
                [-73.900487, 40.687798],
                [-73.901189, 40.687598],
                [-73.896683, 40.682372],
                [-73.896528, 40.682368],
                [-73.895709, 40.683648],
                [-73.894394, 40.68512],
                [-73.892749, 40.683212],
                [-73.890381, 40.684597],
                [-73.889005, 40.683088],
                [-73.888241, 40.682532],
                [-73.886505, 40.683349],
                [-73.885085, 40.677835],
                [-73.885277, 40.677781],
                [-73.882395, 40.666517],
                [-73.882507, 40.666469],
                [-73.876708, 40.658714],
                [-73.87915, 40.65766],
                [-73.878117, 40.65626],
                [-73.886282, 40.652756],
                [-73.884806, 40.650765],
                [-73.886211, 40.650141],
                [-73.887675, 40.649194],
                [-73.889994, 40.651138],
                [-73.891251, 40.650026],
                [-73.893238, 40.651811],
                [-73.895205, 40.654488],
                [-73.89761, 40.653453],
                [-73.897978, 40.653659],
                [-73.898482, 40.655846],
                [-73.898725, 40.65581],
                [-73.899183, 40.657359],
                [-73.899823, 40.657291],
                [-73.899464, 40.658683],
                [-73.899531, 40.65897],
                [-73.900916, 40.663733],
                [-73.900722, 40.663779],
                [-73.901923, 40.668375],
                [-73.902227, 40.668331],
                [-73.903617, 40.673344],
                [-73.903258, 40.673396],
                [-73.903474, 40.675507],
                [-73.902408, 40.675875],
                [-73.903463, 40.676016],
                [-73.903292, 40.677868],
                [-73.903467, 40.678897],
                [-73.916046, 40.686072],
                [-73.908853, 40.693404],
                [-73.903681, 40.690461]
              ]
            ]
          ]
        },
        properties: {
          cartodb_id: 145,
          objectid: 145,
          postalcode: '11207',
          po_name: 'Brooklyn',
          state: 'NY',
          borough: 'Brooklyn',
          st_fips: '36',
          cty_fips: '047',
          bldgpostalcode: 0,
          shape_leng: 48841.6886408,
          shape_area: 73742269.4034,
          id: 'http://nyc.pediacities.com/Resource/PostalCode/11207'
        }
      },
      {
        type: 'Feature',
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [-73.866235, 40.658757],
                [-73.865991, 40.658862],
                [-73.863779, 40.655995],
                [-73.862356, 40.655276],
                [-73.861364, 40.655122],
                [-73.860401, 40.653879],
                [-73.858975, 40.652912],
                [-73.858413, 40.652171],
                [-73.858275, 40.65171],
                [-73.857272, 40.651221],
                [-73.857246, 40.650847],
                [-73.856973, 40.650591],
                [-73.85748, 40.650131],
                [-73.8578, 40.648362],
                [-73.858143, 40.647966],
                [-73.858471, 40.646964],
                [-73.858117, 40.645911],
                [-73.857761, 40.645589],
                [-73.857348, 40.644735],
                [-73.857176, 40.643807],
                [-73.857537, 40.643578],
                [-73.862536, 40.64271],
                [-73.863311, 40.642299],
                [-73.864786, 40.641076],
                [-73.867141, 40.639847],
                [-73.867746, 40.640106],
                [-73.868673, 40.640925],
                [-73.868887, 40.641599],
                [-73.869321, 40.642072],
                [-73.869292, 40.642333],
                [-73.869897, 40.643168],
                [-73.870017, 40.644071],
                [-73.87056, 40.644818],
                [-73.871218, 40.645204],
                [-73.872564, 40.645577],
                [-73.873212, 40.645409],
                [-73.873335, 40.645058],
                [-73.873251, 40.643968],
                [-73.872927, 40.643894],
                [-73.872653, 40.643326],
                [-73.872199, 40.64292],
                [-73.871892, 40.642482],
                [-73.871976, 40.642241],
                [-73.871746, 40.642309],
                [-73.870787, 40.640992],
                [-73.870685, 40.640463],
                [-73.870546, 40.640523],
                [-73.870117, 40.640231],
                [-73.869707, 40.63912],
                [-73.86963, 40.63828],
                [-73.86964, 40.638063],
                [-73.870003, 40.638397],
                [-73.872765, 40.636496],
                [-73.873481, 40.636328],
                [-73.874016, 40.636448],
                [-73.875237, 40.637084],
                [-73.875699, 40.637647],
                [-73.876954, 40.638641],
                [-73.877886, 40.638964],
                [-73.878299, 40.638749],
                [-73.878513, 40.638873],
                [-73.878676, 40.639358],
                [-73.879259, 40.640081],
                [-73.879864, 40.640339],
                [-73.880163, 40.640741],
                [-73.880249, 40.640996],
                [-73.879681, 40.641459],
                [-73.87997, 40.6419],
                [-73.880279, 40.642121],
                [-73.880529, 40.642058],
                [-73.881393, 40.642348],
                [-73.882241, 40.643453],
                [-73.882142, 40.643747],
                [-73.883296, 40.644442],
                [-73.884032, 40.644351],
                [-73.884629, 40.643982],
                [-73.885673, 40.644046],
                [-73.887447, 40.645919],
                [-73.887691, 40.646464],
                [-73.887753, 40.647131],
                [-73.887536, 40.647412],
                [-73.887678, 40.647487],
                [-73.88768, 40.647814],
                [-73.888636, 40.648024],
                [-73.888813, 40.648341],
                [-73.890091, 40.649317],
                [-73.890348, 40.649362],
                [-73.890551, 40.649178],
                [-73.891251, 40.650026],
                [-73.889994, 40.651138],
                [-73.887675, 40.649194],
                [-73.886211, 40.650141],
                [-73.884806, 40.650765],
                [-73.886282, 40.652756],
                [-73.879733, 40.655563],
                [-73.879325, 40.655029],
                [-73.876907, 40.654547],
                [-73.875818, 40.654654],
                [-73.866235, 40.658757]
              ]
            ]
          ]
        },
        properties: {
          cartodb_id: 161,
          objectid: 161,
          postalcode: '11239',
          po_name: 'Brooklyn',
          state: 'NY',
          borough: 'Brooklyn',
          st_fips: '36',
          cty_fips: '047',
          bldgpostalcode: 0,
          shape_leng: 36128.8685676,
          shape_area: 41794660.7681,
          id: 'http://nyc.pediacities.com/Resource/PostalCode/11239'
        }
      }
    ]
  }
];
