export default {
    meta: {
      title: 'Правила и условия',
      url: 'https://www.floodhelpny.org/ru/terms'
    },
    title: 'Правила и условия',
    national_flood_insurance_policy: 'Национальная программа по страхованию от наводнений',
    privacy_policy: 'Политика конфиденциальности',
    general_disclaimer: {
      title: 'Общее заявление об ограничении ответственности и пределы ответственности',
      paragraph_1:
        'Материалы и информация, содержащиеся в FloodHelpNY.org (на “Сайте”) (включая все ссылки на другие веб-сайты и содержание, найденное на связанных сайтах) предоставляются "как есть" и без какой-либо гарантии, выраженной или подразумеваемой, включая, но не ограничиваясь любыми подразумеваемыми гарантиями товарного состояния или пригодности для использования по назначению. Ни Центр для районов Нью-Йорка, Inc. ( “Центр”) ни Секретариат губернатора по восстановлению после ливневых бурь (“GOSR”) не принимают на себя никакую ответственность, будь то  контрактную, гражданскую, безусловную или какого-либо другого типа ответственность за ущерб любого рода, понесенные в результате использования Сайта. В связи со свойствами интернета Центр не гарантирует, что доступ к Сайту не будет прерываться или будет безошибочным. Центр не гарантирует или не дает каких-либо объяснений относительно полезности или ожидаемых результатов по использовании материалов, содержащихся на данном Сайте.',
      paragraph_2:
        'Мы верим, что информация, предоставленная на данном Сайте, является точной и мы прилагаем все усилия по минимизации ошибок. Однако, так как и люди, компьютеры склонны к ошибкам, и так как информация может измениться быстро, мы не может гарантировать точность предоставленной информации. Если вы обнаружите ошибку, свяжитесь с нами по  ',
      email: 'info@floodhelpny.org.',
      paragraph_3:
        'Предварительный расчет страхования и стоимость модификаций предоставляются исключительно в качестве примера. Вы ответственны за проверку точности и соответствия всех предварительных расчетов. Обратитесь за советом к лицензированным и/или компетентным лицам, прежде чем принимать решения по инвестициям или финансовому планированию. Не полагайтесь исключительно на предварительные расчеты и финансовую информацию, найденную на данном Сайте.',
      paragraph_4:
        'Обращаясь к данному Сайту вы соглашаетесь, что ни при каких обстоятельствах или теорий обязательств по международному или гражданскому, общему или статутному праву, включая но не ограничиваясь прямой ответственностью, неосторожностью или другими теориями нарушения законных прав, или договорным, патентным или авторским правам, Центр не будет ответственным за выплату компенсации за ущерб любого рода, понесенный в результате использования Сайта или любой информации, товаров или услуг, полученных на Сайте, включая прямые, косвенные, вторичные, непреднамеренные или штрафные убытки (даже если Центру указали на возможность таких убытков), во всех установленных законом случаях. Некоторые юрисдикции не позволяют исключение или ограничением определенных убытков, так что некоторые из этих ограничений могут быть к вам неприменимы.'
    },
    flood_zones: {
      title: 'Зоны наводнений',
      part_1:
        'Результаты зоны наводнения, высоты и риска наводнения для любого единичного места, показанного на Сайте,  используются только для общей информации и вы не должны полагаться на них для того, чтобы определить, необходимо ли вам приобрести страхование от наводнения или предпринять другое действие. Примите к сведению, что позиция границ зоны наводнения может быть неточной  некоторых местах и локатор адресов может быть не на 100% точным в определении вашего адреса. Владельцы недвижимости должны связаться с ',
      part_2:
        ' для получения более подробной информации или просмотра официальной копии Карт тарифов страхования от наводнения и обсудить высоту наводнения и зоны в районе их недвижимости до начали какой-либо строительной деятельности. В дополнение к этому, информация, предоставленная на Сайте обычно характерна для прибрежных наводнений, и может быть непоказательной относительно других форм риска наводнения, таких как наводнений, вызванных реками или другими причинами. Наконец, определенная информация, предоставленная на Сайте, относится к высотам и риску несения убытков в результате наводнения основаны на карте тарифов Национальной программы страхования от наводнения и других продуктов, которые не принимают во внимание будущий риск от поднятия уровня моря из-за глобального потепления. Для получения информации, как ваши личные сведения могут быть использованы, проконсультируйтесь с нашим '
    },
    flood_insurance_estimate: {
      title: 'Предварительный расчет страхования от наводнения',
      paragraph_1:
        'Выбрав использование Сайта для получения предварительных расчетов страхования от наводнения, вы обязуетесь соблюдать эти правила и условия. Если вы не принимаете эти правила и условия, вы не должны подавать запрос на предварительные расчеты страхования от наводнения.',
      paragraph_2:
        'Мы не страховая компания и мы не предлагаем или предоставляем страхование от наводнения. Ваш запрос предварительных расчетов страхования от наводнения не осуществит какой-либо расчет цены, или предложение предоставить страховое покрытие, или страховое покрытие. Никакой ответственности не будет возложено на Центр в результате вашего запроса на предварительные расчеты страхования от наводнения. Страхование от наводнения может быть получено только когда вы подадите заявление на страхование от наводнения в страховую компанию, которая предоставляет страхование от наводнения и это заявление принято провайдером страховки, о чем свидетельствует отдельный полис, выданный провайдером страховки.',
      paragraph_3:
        'Все ваши предварительные расчеты страхования от наводнения, включая предварительно рассчитанную премию и ожидаемые условия обеспечения предположительны и могут измениться без предупреждения на основании любых и всех критерий оценки кредитных рисков, ставок Руководства по оценке страховых премий, дат премий, руководств по правилам, схем оценки правил и классификаций, используемых ',
      paragraph_4_part_1: 'Прочитайте наш ',
      paragraph_4_part_2:
        ' чтобы понять, как могут быть использованы личные сведения, которые вы предоставили в вашем запросе на предварительные расчеты страхования от наводнения. В то время как Центр принимает все меры предосторожности, чтобы обезопасить информацию, которую вы передаете для получения предварительных расчетов страхования от наводнения, Центр не свидетельствует и не гарантирует, что какая-либо информация не будет перехвачена, просмотрена, передана, распространена или использована неуполномоченной третьей стороной через интернет или другим способом.',
      paragraph_5:
        'Центр оставляет за собой право контролировать данный веб-Сайт, чтобы определить несанкционированные попытки перехватить или изменить информацию или нанести ущерб. Используя Сайт вы даете согласие на такой контроль. Несанкционированные попытки перехватить или изменить информацию, или нанести ущерб строго запрещены и могут преследоваться по закону.'
    },
    home_resiliency_audit: {
      title: 'Инспекция устойчивости дома',
      paragraph_1:
        'Выбрав использование Сайта для подачи заявления и участия в программе Инспекции устойчивости дома, вы обязуетесь соблюдать эти правила и условия. Если вы не принимаете эти правила и условия, вы не должны подавать заявление на участие в программе Инспекции устойчивости дома.',
      paragraph_2:
        'Мы не можем гарантировать, что все домовладельцы, желающие получить Инспекцию устойчивости дома, получат ее. В данное время программа доступна только для соответствующих критериям домовладельцев, проживающих в определенных районах Нью-Йорка и ограничена в количестве участников, которым может быть оказана эта услуга.',
      paragraph_3:
        'Центр выбрал инженерно-технические компании, которые будут проводить Инспекцию устойчивости дома путем конкурсного процесса выдачи подрядов, определившего квалифицированные архитектурные, землеустроительные и инженерно-технические компании. Одним из критериев Центра в выборе инженерных компаний была способность продемонстрировать полное понимание того, как Федеральное Агентство по управлению в чрезвычайных ситуациях  (FEMA) Определяет премию страхования от наводнения здания в соответствии с Национальной программой по страхованию от наводнений (NFIP) и как меры предупреждения ущерба, нанесенного наводнением влияет на размер страхового взноса NFIP, примененного к зданию.',
      paragraph_4:
        'Центр сам не проводит Инспекцию устойчивости дома и не может гарантировать результаты работы, проведенной инженерно-техническими компаниями или точность предоставленной информации.',
      paragraph_5:
        'Центр также выбрал организации, предоставляющие жилищные консультативные и юридические услуги, дающие консультации по устойчивости 1-4 семейным жилищным объектам в районах Нью-Йорка с высокой подверженностью наводнениям в качестве части программы Инспекции устойчивости дома путем конкурсного процесса выдачи подрядов. Эти консультативные организации будут предоставлять индивидуальные консультации и техническую помощь службам инспектирования устойчивости. Одним из критериев, использованных Центром в выборе консультативных организаций, была их способность продемонстрировать техническую и финансовую компетенцию и опыт работы на общественном уровне.',
      paragraph_6:
        'Центр сам не предоставляет жилищные консультативные и юридические услуги и не может гарантировать точность или уровень работы, проведенной консультативными организациями.',
      paragraph_7:
        'Вы должны использовать Инспекцию устойчивости дома только в информативных и наглядных целях. Вы ответственны за проверку точности и соответствия всех предположения и расчетов, содержащихся в инспекции. Обратитесь за советом к лицензированным и/или компетентным лицам, прежде чем принимать решения по инвестициям или финансовому планированию решений или начинать реконструкцию или модификацию вашего дома. Не полагайтесь исключительно на Инспекцию устойчивости дома, финансовые расчеты или предварительные расчеты страхования от наводнения или финансовую информацию, найденную на данном Сайте.',
      paragraph_8_part_1: 'Прочитайте наш ',
      paragraph_8_part_2:
        ' чтобы понять, как могут быть использованы личные сведения, которые вы предоставили в вашем заявлении на участие в программе Инспекции устойчивости дома. В то время как Центр принимает все меры предосторожности, чтобы обезопасить информацию, которую вы передаете в вашем заявлении для участия в программе Инспекции устойчивости дома и информации, сгенерированной в рамках программы, Центр не свидетельствует и не гарантирует, что какая-либо информация не будет перехвачена, просмотрена, передана, распространена или использована неуполномоченной третьей стороной через интернет или другим способом.'
    },
    backwater_valve: {
      title: 'ПРОГРАММА УСТАНОВКИ КАНАЛИЗАЦИОННОГО ОБРАТНОГО КЛАПАНА',
      paragraph_1:
        'Выбрав использование Сайта для подачи заявления и участия в программе Установки канализационного обратного клапана, вы обязуетесь соблюдать эти правила и условия. Если вы не принимаете эти правила и условия, вы не должны подавать заявление на участие в программе Установки канализационного обратного клапана.',
      paragraph_2:
        'Мы не можем гарантировать, что все домовладельцы, желающие получить установку канализационного обратного клапана, получат ее. В данное время программа доступна только для соответствующих критериям домовладельцев с низким или средним уровнем дохода, проживающих в определенных районах Нью-Йорка и ограничена в количестве участников, которым может быть оказана эта услуга. Для того, чтобы соответствовать критериям на установку канализационного обратного клапана, домовладельцы должны пройти через Инспекцию устойчивости дома и инженеры должны определить, что собственность извлечет пользу от установки канализационного обратного клапана.',
      paragraph_3:
        'Центр сам не проводит установку канализационного обратного клапана и не может гарантировать результаты работы, проведенной компанией, которая будет устанавливать канализационные обратные клапаны. Центр определит лицензированного мастера сантехника из списка компаний, прошедших предварительный отбор, которые завершат процесс установки для соответствующих критериям домовладельцев. Центр заплатит мастеру сантехнику напрямую за затраты по установке клапана. Домовладельцы должны самостоятельно просмотреть квалификации мастера сантехника до тото, как они согласятся на какие-либо установочные работы.',
      paragraph_4:
        'В то время как установка канализационного обратного клапана может сократить риск ущерба, нанесенного противотоком канализации, Центр не гарантирует, что противоток канализации или другие формы ущерба не произойдут после тог, как клапан будет установлен. В ответственность домовладельца входит понимание того, как клапан работает, ремонт клапана, его регулярная инспекция и поддержание его в рабочем состоянии. ',
      paragraph_5:
        'Центр не будет предоставлять какие-либо ремонтные или  проверочные услуги после установки клапана. ',
      paragraph_6:
        'Существуют риски, связанные с установкой канализационного обратного клапана. Дому могут быть нанесены повреждения, если сантехника, такая как душ, раковина или туалет будут использованы во время установки клапана. В случае наводнения или сильной бури клапан может не сработать и в результате этого собственности может быть нанесен ущерб. ',
      paragraph_7: 'Домовладельцы, которые получат установку клапана должны:',
      paragraph_7_part_1:
        'Внимательно просмотреть инструкции по эксплуатации и техобслуживанию, предоставленные производителем клапана и следовать этим инструкциям.',
      paragraph_7_part_2:
        'Не смывать салфетки, бумажные салфетки, санитарно-гигиенические изделия, масла, жиры или смазочные материалы в туалете или в раковине. Эти предметы могут скапливаться и вызвать поломку клапана.',
      paragraph_7_part_3:
        'Проверьте клапан перед бурей. Клапан может не сработать если какие-либо предметы блокируют заслонку в клапане. Посмотрите, нет ли каких-либо предметов, блокирующих клапан, и удалите их. ',
      paragraph_8:
        'Домовладельцы, которые получат установку клапана должны согласиться на обеспечение их полиса страхования недвижимости за свой счет, включая дополнительное страхование от противотока из канализации, если таковая имеется. Они также должны иметь страховой полис от наводнения на уровне суммы пособия на установку.',
      paragraph_9:
        'Прочитайте нашу Политику конфиденциальности, чтобы понять, как могут быть использованы личные сведения, которые вы предоставили в вашем заявлении на участие в программе Установки канализационного обратного клапана. В то время как Центр принимает все меры предосторожности, чтобы обезопасить информацию, которую вы передаете в вашем заявлении для участия в программе Установки канализационного обратного клапана и информации, сгенерированной в рамках программы, Центр не свидетельствует и не гарантирует, что какая-либо информация не будет перехвачена, просмотрена, передана, распространена или использована неуполномоченной третьей стороной через интернет или другим способом.'
    }
  };
  