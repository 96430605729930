import profile from './Profile.ht_HT';

export default {
  meta: {
    title: 'Sitiyasyon Odit pou Tolerans Kay Mwen an',
    url: 'https://www.floodhelpny.org/ht_HT/audit-status'
  },
  previewHeader: 'Apèsi sou Rapò pou Tolerans Kay Mwen an',
  floodZoneProvider: profile.floodZoneProvider,
  auditRateProvider: {
    header: 'TARIF ASIRANS OU AN, BAZE SOU SÈTIFIKA ELEVASYON OU AN',
    current: 'Kat aktyèl yo',
    future: 'Kat konsiltatif',
    zone: 'zòn',
    perYear: 'Pou chak ane*',
    assumingMax: '*Nou sipoze yon kouvèti maksimòm',
    numbersAreEstimates:
      'Chif sa yo se estimasyon pou anpil rezon. Pou aprann plis sou yo ale sou ',
    termsAndConditions: 'Tèm ak Kondisyon nou yo'
  },
  errorText:
    'Yon bagay mal pase. <a href="mailto:info@floodhelpny.org">Kontakte nou</a> si sa kontinye rive.',
  loadingText: 'Ap telechaje...'
};
