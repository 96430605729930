import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '../../styles/variables';

const Container = styled.div`
  display: flex;
  border: 10px solid ${colors.grayScale[0]};
  align-items: center;
  justify-content: space-between;
  margin: 3rem auto;
  padding: 3rem;
  max-width: 1000px;

  > * {
    margin-right: 2rem;

    :last-child {
      margin-right: 0;

      @media (max-width: 1000px) {
        margin-bottom: 0;
      }
    }

    @media (max-width: 1000px) {
      margin: 1rem 0;
    }
  }

  @media (max-width: 1000px) {
    flex-direction: column;
    text-align: center;
    margin: 2rem 0;
  }

  @media (max-width: 500px) {
    margin: 2rem 1rem;
    padding: 1rem;
  }
`;

const CallToAction = ({ children }) => <Container>{children}</Container>;

CallToAction.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired
};

export default CallToAction;
