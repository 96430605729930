export default {
  meta: {
    title: 'Enskri',
    url: 'https://www.floodhelpny.org/ht_HT/register'
  },
  title: 'Evalyasyon Risk 2.0',
  subHeading:
    'Anrejistre pou yon seminè sou entènèt gratis pou aprann plis apwopo Evalyasyon Risk 2.0 an, epi jwenn repons ak kesyon ou yo pou ede kay ak finans ou sekirize.',
  register:
    'Enskri pou yon seminè sou entènèt gratis nan dat 13 septanm 2022 ak konseye lojman yo.',
  brought: 'Sa ki pote l pou ou an se',
  riskRating:
    'Notasyon Ris 2.0 ki se yon nouvo sistèm federal pou etabli prim asirans kont inondasyon. Plis pase 60% nan abitan New York yo ki aktyèlman gen kontra ta ka wè yon ogmantasyon nan depans asirans yo.',
  learnMore: 'Aprann plis sou Evalyasyon Risk 2.0',
  funding: {
    support: 'Ak soutyen finansman nan men FEMA',
    questions: 'Ou gen kesyon? Imèl ',
    commsEmail: 'communications@cnycn.org'
  }
};
