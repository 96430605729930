export default {
  findYourHome: {
    header: 'Encuentra tu casa en el mapa de la inundación.',
    body:
      'Las futuras zonas de inundación podrían afectar a más de 400,000 neoyorquinos: descubra cómo pueden afectarle a usted.'
  },
  estimate: {
    header: 'Obtenga un presupuesto de seguro contra inundaciones',
    body:
      'En los vecindarios propensos a las inundaciones de la Ciudad de Nueva York, el 76% de los propietarios que no tienen un certificado de elevación están pagando en exceso por el seguro contra inundaciones.'
  },
  lowerRisk: {
    header: 'Baja tu riesgo y tu tarifa.',
    body:
      'Más de 100,000 neoyorquinos han recurrido a FloodHelpNY como un lugar para aprender, actuar y ser más seguros contra las inundaciones.'
  }
};
