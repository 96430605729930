import React from 'react';
import PropTypes from 'prop-types';

const RateCalculatorNav = ({
  next,
  previous,
  provider,
  question,
  nextQuestion
}) => {
  const renderNextButton = () => {
    return (
      <button
        type="button"
        disabled={!nextQuestion}
        className="btn btn--main btn--big btn-next"
        onClick={next}
      >
        {provider.next}
      </button>
    );
  };

  const renderPreviousButton = () => {
    const disabled = question === 'numberOfResidentialUnits';

    return (
      <button
        type="button"
        disabled={disabled}
        className="btn btn--big btn--secondary--ghost btn-previous"
        onClick={previous}
      >
        {provider.previous}
      </button>
    );
  };

  return (
    <div className="navigation promo button-footer">
      {renderPreviousButton()}
      {renderNextButton()}
    </div>
  );
};

RateCalculatorNav.propTypes = {
  provider: PropTypes.object.isRequired,
  previous: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
  question: PropTypes.string.isRequired,
  nextQuestion: PropTypes.string.isRequired
};

export default RateCalculatorNav;
