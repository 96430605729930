import BuildingSizes from '../constants/BuildingSizes';
import BuildingTypes from '../constants/BuildingTypes';
import ContentLocations from '../constants/ContentLocations';
import BuildingFeatures from '../constants/BuildingFeatures';
import bungalow from '../images/bungalow.svg';
import detached from '../images/detached.svg';
import semiDetached from '../images/semi-detached.svg';
import attached from '../images/attached.svg';
import midRiseWalkUp from '../images/midrise-walk-up.svg';
import midRiseElevator from '../images/midrise-elevator.svg';
import unknown from '../images/unknown.svg';
import basement from '../images/basement-cellar.svg';
import crawlspaceWithFloodVents from '../images/crawl-space-with-flood-vents.svg';
import crawlspaceWithoutFloodVents from '../images/crawl-space-without-flood-vents.svg';
import enclosure from '../images/enclosure.svg';
import mobileHome from '../images/mobile-home.svg';
import subgradeCrawlspace from '../images/sub-grade-crawl-space.svg';
import none from '../images/cross.svg';

export default {
  navigation: {
    next: 'Следующий',
    previous: 'Предыдущий'
  },
  faq: {
    header: 'ВЫ ВОЗМОЖНО ДУМАЕТЕ'
  },
  breadcrumbs: {
    back: 'Назад к профилю'
  },
  questions: {
    numberOfResidentialUnits: {
      title: 'numberOfResidentialUnits',
      faqZone: 'rate-calculator-number-of-residential-units',
      question: 'Сколько жилых помещений в вашем здании?',
      breadcrumb: 'Размер здания',
      responseField: {
        options: [
          { value: BuildingSizes.SINGLE_FAMILY, display: 'Дом для одной семьи' },
          { value: BuildingSizes.TWO_TO_FOUR_FAMILY, display: '2-4 квартир' },
          { value: BuildingSizes.MULTI_FAMILY, display: '5 или более квартир' }
        ],
        invalidAnswers: [BuildingSizes.MULTI_FAMILY],
        invalidCopy:
          'Этот калькулятор тарифа подходит только для жилья на 1-4 семей.',
        invalidLinkCopy: 'Нажать здесь, чтобы вернуться на домашнюю страницу.',
        invalidLink: '/',
        invalidCopyAfterLink: '',
        alertAnswers: []
      }
    },
    buildingType: {
      title: 'buildingType',
      faqZone: 'rate-calculator-building-type',
      question: 'Как вы можете наилучшим образом описать тип вашего здания?',
      breadcrumb: 'Тип здания',
      responseField: {
        key: 'building_type',
        options: [
          {
            value: BuildingTypes.BUNGALOW,
            display: 'Бунгало',
            image: bungalow
          },
          {
            value: BuildingTypes.DETACHED,
            display: 'Отдельно стоящий',
            image: detached
          },
          {
            value: BuildingTypes.SEMI_DETACHED,
            display: 'Смежный',
            image: semiDetached
          },
          {
            value: BuildingTypes.ATTACHED,
            display: 'Сблокированный',
            image: attached
          },
          {
            value: BuildingTypes.MID_RISE_WALK_UP,
            display: 'Среднеэтажный без лифта',
            image: midRiseWalkUp
          },
          {
            value: BuildingTypes.MID_RISE_ELEVATOR,
            display: 'Среднеэтажный с лифтом',
            image: midRiseElevator
          },
          {
            value: BuildingTypes.UNKNOWN,
            display: "Не знаю",
            image: unknown
          }
        ]
      }
    },
    preFirm: {
      title: 'preFirm',
      faqZone: 'rate-calculator-pre-firm',
      breadcrumb: 'Pre/Post FIRM',
      preFirmQuestion:
        'Похоже, что данный объект был построен до 16 ноября 1983г. Это верно?',
      postFirmQuestion:
        'Похоже, что данный объект был построен или значительно улучшен после 16 ноября 1983г. Это верно?',
      responseField: {
        options: [
          { value: 'yes', display: 'Да' },
          { value: 'no', display: 'Нет' }
        ],
        invalidAnswers: [],
        invalidCopy: '',
        invalidLinkCopy: '',
        invalidLink: '#',
        invalidCopyAfterLink: '',
        alertAnswers: []
      },
      disclaimer:
        'Со дня принятия первой карты зоны наводнений в Нью-Йорке 16 ноября 1983г., существуют временные субсидированные тарифы для объектов, построенных до этого.'
    },
    severeRepetitiveLoss: {
      title: 'severeRepetitiveLoss',
      faqZone: 'rate-calculator-severe-repetitive-loss',
      breadcrumb: 'Значительный повторяющийся ущерб',
      floodDamageQuestion: 'Была ли эта собственность повреждена в результате наводнения в прошлом?',
      severeRepetitiveLossQuestion:
        'Были вы уведомлены FEMA или вашем провайдером страхования от наводнений, что вашему зданию был нанесен "значительный повторяющийся ущерб"?',
      floodDamageResponse: {
        options: [
          { value: true, display: 'Да' },
          { value: false, display: 'Нет' }
        ],
        invalidAnswers: [],
        invalidCopy: '',
        invalidLinkCopy: '',
        invalidLink: '#',
        invalidCopyAfterLink: '',
        alertAnswers: []
      },
      severeRepetitiveLossResponse: {
        options: [
          { value: 'yes', display: 'Да' },
          { value: 'no', display: 'Нет' },
          { value: 'unknown', display: "Не знаю" }
        ],
        invalidAnswers: [],
        invalidCopy: '',
        invalidLinkCopy: '',
        invalidLink: '#',
        invalidCopyAfterLink: '',
        alertAnswers: []
      },
      floodDamageDisclaimer:
        'Мы задаем это вопрос, потому что существуют особые тарифы страхования от наводнения, которые применимы только к объектам с определенной историей ущерба.',
      severeRepetitiveLossDisclaimer:
        'Большинство зданий в Нью-Йорке не являются зданиями со "значительным повторяющимся ущербом".'
    },
    preferredRiskRate: {
      title: 'preferredRiskRate',
      faqZone: 'rate-calculator-preferred-risk-rate',
      breadcrumb: 'Тариф предпочтительного риска',
      floodClaimQuestion:
        'Подавали ли вы когда-либо иск о возмещении ущерба в результате наводнения или получали ли пособие за наводнение?',
      floodClaimResponseField: {
        options: [
          { value: true, display: 'Да' },
          { value: false, display: 'Нет' }
        ],
        invalidAnswers: [],
        invalidCopy: '',
        invalidLinkCopy: '',
        invalidLink: '#',
        invalidCopyAfterLink: '',
        alertAnswers: []
      },
      numberOfClaimsQuestion: 'За сколько происшествий?',
      numberOfClaimsSubtitle:
        'Каждый шторм считается отдельным происшествием, так что если вы получили выплату только за Сэнди, то это считается как одно происшествие.',
      numberOfClaimsResponseField: {
        options: [
          { value: 1, display: '1' },
          { value: 2, display: '2' },
          { value: 3, display: '3 или больше' }
        ],
        invalidAnswers: [],
        invalidCopy: '',
        invalidLinkCopy: '',
        invalidLink: '#',
        invalidCopyAfterLink: '',
        alertAnswers: []
      },
      claimAmountQuestion: 'Поличили ли вы больше 1000 долларов США за каждое наводнение?',
      claimAmountResponseField: {
        options: [
          { value: 'true', display: 'Да' },
          { value: 'false', display: 'Нет' }
        ],
        invalidAnswers: [],
        invalidCopy: '',
        invalidLinkCopy: '',
        invalidLink: '#',
        invalidCopyAfterLink: '',
        alertAnswers: []
      },
      disclaimer:
        'Мы задаем это вопрос, потому что существуют особые тарифы страхования от наводнения, которые применимы только к объектам с определенной историей ущерба.'
    },
    elevatedBuilding: {
      title: 'elevatedBuilding',
      faqZone: 'rate-calculator-elevated-building',
      question: 'Ваш дом поднят?',
      breadcrumb: 'Поднят',
      responseField: {
        options: [
          { value: 'true', display: 'Да' },
          { value: 'false', display: 'Нет' }
        ],
        invalidAnswers: [],
        invalidCopy: '',
        invalidLinkCopy: '',
        invalidLink: '#',
        invalidCopyAfterLink: '',
        alertAnswers: []
      },
      disclaimer:
        'Это означает, что конструкция расположена на сваях или частичных стенах.'
    },
    contentLocation: {
      title: 'contentLocation',
      faqZone: 'rate-calculator-content-location',
      breadcrumb: 'Расположение Содержимого',
      question:
        'Где в вашем здании находится имущество, которое вы хотите застраховать?',
      responseField: {
        key: 'content_location',
        options: [
          {
            value: ContentLocations.BASEMENT_AND_ABOVE,
            display: 'В подвале (стиральная машина/сушилка/морозильник только) и выше'
          },
          {
            value: ContentLocations.ENCLOSURE_AND_ABOVE,
            display: 'В ограждающих конструкциях (стиральная машина/сушилка/морозильник только) и выше'
          },
          {
            value: ContentLocations.LOWEST_FLOOR_ONLY,
            display: 'Только на самом нижнем этаже, выше уровня земли'
          },
          {
            value: ContentLocations.LOWEST_FLOOR_AND_HIGHER,
            display: 'На нижнем этаже выше уровня земли и верхних этажах'
          },
          {
            value: ContentLocations.ABOVE_GROUND_LEVEL,
            display: 'Выше уровня земли, более чем один полный этаж'
          }
        ]
      },
      disclaimer:
        'Помните, что единственное имущество, которое вы можете застраховать в подвале или ограждающих конструкциях, это стиральная и сушильная машины и морозильная камера. Обратитесь к FAQЧасто задаваемым вопросам за более подробной информацией.'
    },
    buildingFeature: {
      title: 'buildingFeature',
      faqZone: 'rate-calculator-building-feature',
      breadcrumb: 'Элемент Здания',
      question:
        'Какие, если они есть, из следующих элементов имеются в вашей собственности?',
      responseField: {
        key: 'building_feature',
        options: [
          {
            value: BuildingFeatures.BASEMENT,
            display: 'Подвал или погреб',
            image: basement
          },
          {
            value: BuildingFeatures.ENCLOSURE,
            display: 'Ограждающие конструкции',
            image: enclosure
          },
          {
            value: BuildingFeatures.ELEVATED_ON_CRAWLSPACE_FLOOD_VENTS,
            display: 'Технический подпол с вентиляционными отверстиями',
            image: crawlspaceWithFloodVents
          },
          {
            value: BuildingFeatures.ELEVATED_ON_CRAWLSPACE_NO_FLOOD_VENTS,
            display: 'Технический подпол без вентиляционных отверстий',
            image: crawlspaceWithoutFloodVents
          },
          {
            value: BuildingFeatures.SUBGRADE_CRAWLSPACE,
            display: 'Грунтовый технический подпол',
            image: subgradeCrawlspace
          },
          {
            value: BuildingFeatures.MOBILE_HOME,
            display: 'Передвижной дом',
            image: mobileHome
          },
          {
            value: BuildingFeatures.NO_BASEMENT_ENCLOSURE,
            display: 'Никаких',
            image: none
          }
        ]
      }
    },
    basement: {
      title: 'basement',
      faqZone: 'rate-calculator-basement',
      breadcrumb: 'Подвал',
      hasBasementQuestion:
        'Имеется ли в вашем здании подвал, технический подпол или ограждающие конструкции?',
      responseField: {
        options: [
          { value: true, display: 'Да' },
          { value: false, display: 'Нет' }
        ],
        invalidAnswers: [],
        invalidCopy: '',
        invalidLinkCopy: '',
        invalidLink: '#',
        invalidCopyAfterLink: '',
        alertAnswers: []
      },
      basementHeightQuestion:
        'Какова высота от пола до потолка вашего подвала, технического подпола или ограждающей конструкции? (Большинство подвалов 8’)?',
      basementHeightUnit: 'ft'
    },
    numberOfSteps: {
      title: 'numberOfSteps',
      faqZone: 'rate-calculator-number-of-steps',
      breadcrumb: 'Ступени вверх/вниз',
      hasStepsQuestion:
        'Есть ли ступени, ведущие с уровня земли на ваш самый нижний этаж? (Если у вас есть подвал или подземный технический подпол, мы имеем в виду ступени от уровня земли снаружи вниз к вашему подвалу или техническому подполу.)',
      hasStepsResponseField: {
        options: [
          { value: 'true', display: 'Да' },
          { value: 'false', display: 'Нет' }
        ],
        invalidAnswers: [],
        invalidCopy: '',
        invalidLinkCopy: '',
        invalidLink: '#',
        invalidCopyAfterLink: '',
        alertAnswers: []
      },
      frontDoorQuestion: 'Ступени ведут вверх или вниз?',
      frontDoorResponseField: {
        options: [
          { value: 'true', display: 'Вверх' },
          { value: 'false', display: 'Вниз' }
        ],
        invalidAnswers: [],
        invalidCopy: '',
        invalidLinkCopy: '',
        invalidLink: '#',
        invalidCopyAfterLink: '',
        alertAnswers: []
      },
      numberOfStepsQuestion: 'Сколько ступеней?',
      disclaimer:
        'Обязательно ответьте на этот вопрос с самой нижней точки земли, касающейся вашего дома. Если земля находится высоко спереди дома и низко сзади, ответьте на вопрос считая сзади. Есди самый нижний этаж расположен на уровне земли с любой стороны, ответьте "нет".'
    },
    numberOfFloors: {
      title: 'numberOfFloors',
      faqZone: 'rate-calculator-number-of-floors',
      breadcrumb: 'Многоэтажный',
      question: 'В этом доме больше одного этажа?',
      responseField: {
        options: [
          { value: true, display: 'Да' },
          { value: false, display: 'Нет' }
        ],
        invalidAnswers: [],
        invalidCopy: '',
        invalidLinkCopy: '',
        invalidLink: '#',
        invalidCopyAfterLink: '',
        alertAnswers: []
      }
    },
    machineryInBasement: {
      title: 'machineryInBasement',
      faqZone: 'rate-calculator-machinery-in-basement',
      breadcrumb: 'Механизмы в подвале',
      question:
        'Находятся ли в подвале здания механизмы, такие как водонагреватель, бойлер, отопление, вентиляция и кондиционер, и т.д.?',
      responseField: {
        options: [
          { value: true, display: 'Да' },
          { value: false, display: 'Нет' }
        ],
        invalidAnswers: [],
        invalidCopy: '',
        invalidLinkCopy: '',
        invalidLink: '#',
        invalidCopyAfterLink: '',
        alertAnswers: []
      },
      disclaimer:
        'Это означает механизмы или оборудование, которое осуществляет коммунальное обслуживание дома.'
    }
  }
};
