import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import PrimaryAndAsideContainer from './layout/PrimaryAndAsideContainer';
import Card from './layout/Card';
import OtherResources from './OtherResources';
import ListWithIconAndText from './layout/ListWithIconAndText';
import RoundIcon from './RoundIcon';
import ButtonLink from './ui/ButtonLink';
import {
  HeaderSection,
  Subheader,
  Section,
  SectionDescription
} from './ui/StyledResourcePageElements';
import { useProvider } from '../helpers/hooks';

const NarrowHeaderSection = styled(HeaderSection)`
  max-width: 900px;
`;

const Separator = styled.div`
  margin-top: 2rem;
`;

const StormwaterFlooding = () => {
  const provider = useProvider('stormwaterFlooding');

  return (
    <>
      <Helmet>
        <title>{provider.meta.title}</title>
        <link rel="canonical" href={provider.meta.url} />
        <meta name="description" content={provider.meta.description} />
        <meta property="og:title" content={provider.meta.ogTitle} />
        <meta property="og:description" content={provider.meta.description} />
        <meta property="og:url" content={provider.meta.url} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <NarrowHeaderSection>
        <h1>{provider.header}</h1>
        <Subheader>{provider.subHeader}</Subheader>
      </NarrowHeaderSection>
      <Section>
        <PrimaryAndAsideContainer>
          <div>
            <div>
              <h5>{provider.whatIsStormwater.header}</h5>
              <SectionDescription>
                {provider.whatIsStormwater.body}
              </SectionDescription>
            </div>
          </div>
          <aside>
            <div>
              <h6>{provider.pollution.header}</h6>
              <p>{provider.pollution.body}</p>
            </div>
          </aside>
        </PrimaryAndAsideContainer>
      </Section>
      <Section noBorder>
        <Card>
          <RoundIcon
            src={provider.howRainFallImpacts.icon}
            alt={provider.howRainFallImpacts.iconAlt}
          />
          <div>
            <h3>{provider.howRainFallImpacts.header}</h3>
          </div>
          <ButtonLink
            text={provider.howRainFallImpacts.buttonText}
            url={provider.howRainFallImpacts.buttonUrl}
            external
          />
        </Card>
      </Section>
      <Section noBorder>
        <h5>{provider.protectHome.title}</h5>
        <SectionDescription>{provider.protectHome.subtitle}</SectionDescription>
        <ListWithIconAndText items={provider.protectHome.list} />
      </Section>
      <Section noBorder>
        <Card>
          <img
            alt={provider.homeOwnerGuide.imageAlt}
            src={provider.homeOwnerGuide.image}
          />
          <div>
            <h3>{provider.homeOwnerGuide.header}</h3>
          </div>
          <ButtonLink
            text={provider.homeOwnerGuide.buttonText}
            url={provider.homeOwnerGuide.posterUrl}
            external
          />
        </Card>
      </Section>
      <OtherResources />
    </>
  );
};

export default StormwaterFlooding;
