export default {
  homepage: 'FloodHelpNY',
  languageTitle: 'Languages',
  about: 'Apwopo Nou',
  languages: {
    english: 'English',
    spanish: 'Español',
    russian: 'Русский',
    chinese: '中文',
    haitian: 'Kreyòl ayisyen'
  },
  resources: {
    insurance: 'Asirans',
    retrofits: 'Renovasyon',
    hurricane: 'Sezon Ouragan',
    smallBusinesses: 'Ti Biznis',
    news: 'Nouvèl',
    stormwaterFlooding: 'Inondasyon Dlo Lapli',
    climateChange: 'Chanjman Klimatik'
  }
};
