export default {
  currentlyServed: 'Prioritized Areas',
  neighborhood: {
    header: 'Zipcodes: ',
    content:
      'Homeowners from all five boroughs can apply, however applicants in the following zip codes will be prioritized: 11207, 11208, and 11239; 11423 and 11412; 11432, 11433, 11434; 10472, 10473.'
  },
  priority: {
    header: 'Eligibility Criteria:',
    criteria: [
      {
        key: 'owner status',
        copy: 'You must be a homeowner or homeowner landlord.'
      },
      {
        key: 'affected',
        copy: 'Your home must have been affected by [X storm name and date].'
      },
      {
        key: 'boroughs',
        copy:
          'Your home must be an owner-occupied 1-4 unit home in one of the five boroughs of New York City'
      },
      {
        key: 'residence',
        copy:
          'Your home must have been your primary residence when the flooding event occurred. (primary residence is defined as the place where you live there most of the time)'
      },
      {
        key: 'income',
        copy:
          'Your household income must be at or below 165% AMI for New York City based on household size.'
      }
    ]
  },
  downloadEligibilityForm:
    'Download full eligibility requirements <a href="/samples/backwater-valve-eligibility.pdf">here</a>.'
};
