import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '../styles/variables';
import viewFile from '../images/view-file.svg';
import { useProvider } from '../helpers/hooks';
import ButtonLink from './ui/ButtonLink';
import RoundIcon from './RoundIcon';

const Content = styled.div`
  border: 10px solid ${colors.grayScale[0]};
  padding: 2rem 4rem;
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > * {
    margin-right: 2rem;

    :last-child {
      margin-right: 0;

      @media (max-width: 1200px) {
        margin-bottom: 0;
      }
    }

    @media (max-width: 1200px) {
      margin: 1rem 0;
      text-align: center;
    }
  }

  @media (max-width: 1200px) {
    flex-direction: column;
    padding: 2rem 1rem;
  }
`;

const AuditAttachment = ({ attachment }) => {
  const provider = useProvider('auditAttachment');

  const renderAttachment = () => {
    const { downloadUrl, docType } = attachment;

    return (
      <Content>
        <RoundIcon src={viewFile} alt="view file" />
        <div>
          <h4>{provider[docType].header}</h4>
          <p>{provider[docType].copy}</p>
        </div>
        <ButtonLink url={downloadUrl} small ghost text={provider.download} />
      </Content>
    );
  };

  return <div>{renderAttachment()}</div>;
};

AuditAttachment.propTypes = {
  attachment: PropTypes.shape({
    downloadUrl: PropTypes.string.isRequired,
    docType: PropTypes.string.isRequired
  }).isRequired
};

export default AuditAttachment;
