import reportCard from '../images/report-card.svg';
import certificate from '../images/certificate.svg';
import documents from '../images/documents.svg';
import backwaterValve from '../images/backwater-valve.svg';

const example = '查看示例';

export default {
  header: "您的收益",
  list: [
    {
      header: '回水阀',
      body:
        '我们的团队将与您合作，包括工程师和水管工，确定您的房屋是能够从回水阀中受益.',
      image: backwaterValve
    },
    {
      header: '洪水保险咨询',
      body:
        '我们的专家顾问将审查您的洪水保单，通过审核标高证明和缓解方案，讨论有助于降低洪水保险成本<a href="/zh-CN/flood-retrofits">的潜在</a>方案',
      image: documents
    },
    {
      header: '弹性报告',
      body:
        '在检查您的房屋并评估洪水风险后，我们会向您发送一份详细的技术报告，包括测量结果和信息，说明进行哪些改造（即提升机械系统）可以提高安全性。',
      image: reportCard,
      exampleLink: '/samples/technical_report_john_doe.pdf',
      example
    },
    {
      header: '高度证明',
      headerLink: '/cn/elevation-certificates',
      body:
        '我们还会向您发送一份证明房屋标高的文件。如果住在高风险的洪水区，标高证书可以防止洪水保险费率大幅上涨.',
      image: certificate,
      exampleLink: '/samples/elevation_certificate.pdf',
      example
    }
  ]
};
