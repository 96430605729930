export default {
  tellYourFriends: 'Tell your friends and neighbors',
  about: 'About',
  privacy: 'Privacy',
  termsAndConditions: 'Terms and Conditions',
  widget: 'Widget',
  multifamily: 'Multifamily properties',
  cnycn: 'Center for NYC Neighborhoods',
  facebook: 'Share on Facebook',
  twitter: 'Share on Twitter',
  email: 'Email',
  emailSubject: 'Find Your Flood Risk Today!',
  message:
    'It doesn’t matter where you live in NYC – if it can rain, it can flood. Find your flood risk today at https://www.floodhelpny.org/!'
};
