export default {
    xZone: '中等风险',
    aeZone: '高风险',
    aoZone: '高风险',
    veZone: '最高风险',
    noneZone: '最小危险',
    baseFloodElevation: '基准洪水标高(BFE)',
    xToolTip:
      'X区为中等风险区：在下一次严重风暴期间，该地区的房屋处于中等洪水风险中。',
    aeToolTip:
      'AE区是一个高风险区：在下一次严重风暴期间，该地区的房屋面临着高洪水风险。',
    aoToolTip:
      'AO区是一个高风险区：需强制购买洪水保险，预计洪水风险很高。',
    veToolTip:
      'V区是纽约市风险最高的区域：在下一场严重风暴期间，该地区的房产面临最高的洪水风险。',
    floodZoneUrl: '/zh-CN/understanding-flood-insurance',
    learnMoreAboutFloodZones: {
      copy: '了解更多有关洪水区',
      linkText: ' 的信息'
    },
    floodZoneExplanations: {
      ae: {
        header:
          '高风险（AE）区的建筑物有可能发生严重的洪水，可能超过几英尺。',
        copy:
          '如果您有抵押贷款，高风险区需要洪水保险。'
      },
      ve: {
        header:
          '生活在高风险地区的纽约人知道，损害是切实存在的，现在采取措施可以节省金钱、时间和精力。',
        copy:
          '如果您有抵押贷款，高风险区需要洪水保险。'
      },
      x: {
        header:
          '中等风险区容易发生几英寸高的洪水，而这些洪水可能导致高昂的费用。根据您的房产，仅6英寸的水就可能会造成严重损害。',
        copy:
          '由于洪水保险费率由联邦应急管理局（FEMA）制定，因此不同保险公司的费率是一致的。但许多保险代理人不太了解规则，在计费方面会出错。'
      },
      none: {
        header:
          '不断变化的洪水风险和不断变化的区域将最终影响到我们所有人。如果不采取行动，这种影响可能会在整个城市的经济中投射出来。',
        copy:
          '近20%的洪水保险理赔来自中等风险和最小风险地区。'
      },
      ao: {
        header:
          '高风险AO区的建筑物有可能在河流或溪流附近地区发生洪水，以及每年发生浅层洪水。',
        copy:
          '如果您有抵押贷款，高风险区需要洪水保险。'
      }
    }
  };
  