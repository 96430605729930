export default {
    meta: {
      title: '回水阀安装项目',
      url: 'https://www.floodhelpny.org/zh-CN/mitigation/backwater-valve',
      description: '保护您的房屋和财产免受洪水侵袭。'
    },
    title: '回水阀：您需要了解的内容',
    subtitle:
      '回水阀的设计目的是在下水道溢流时排放掉家中未经处理的污水。',
    overview: {
      header: '概述',
      copy:
        '纽约市的联合下水道系统平均每周溢出一次。雨水径流与污水汇合，下水道的容量过载时，多余的污水会通过排水管回流，流入居民家中。下水道倒流不卫生，会造成严重的健康危害，而且清理成本高昂。'
    },
    howItWorksHeader: '阀门的工作原理',
    howItWorks: {
      paragraph1:
        '回水阀连接到您房屋的水管系统，内部有一个“挡板”。如果出现下水道溢流，挡板关闭，帮助保护房屋免受未经处理的污水污染。',
      paragraph2:
        '溢流退去时，压力的变化会释放挡板，让废水从您的房屋中流入下水道。'
    },
    backwaterValveGifAlt: 'Backwater valve animation',
    affect: {
      header: '产生的影响',
      moneySavedSubheader: '预计节约资金',
      bigNumber: '$2,000 - $10,000',
      moneySavedCopy:
        '安装止回阀不会影响您为洪水保险支付的费用。然而，在下水道溢流的情况下，安装阀门可以为您节省数千元的污水清理和维修费用。',
      damageAvoidedHeader: '避免的损害',
      damageAvoidedCopy:
        '安装一个回水阀可以减少管道堵塞的可能性，因此，存放在地下室的物品都会保持安全、干燥，远离污水。'
    },
    whatItTakes: {
      header: '所需时间',
      paragraph1:
        '根据排水管的布置情况，安装回水阀可能需要2到8个小时的时间。',
      paragraph2:
        '管道工还会为您提供一些维护技巧确保阀门不会堵塞，比如如何用水冲洗阀门。',
      cost: '$600–$5,000',
      costCopy:
        '安装成本取决于排水管的位置以及适合您房屋的回水阀类型。'
    },
    whatYouCanDoNext: {
      header: '下一步操作',
      subheading: '打电话给专业的水管工',
      copy:
        '如果准备在家中安装一个回水阀，首先检查房主的保单是否涵盖了下水道倒流。接下来，打电话给专业的水管工，安排检查家中的排水管，并问几个关键问题（见下文）。您还可以在<a href="http://www.nyc.gov/dob" target="_blank" rel="noreferrer noopener">纽约市建筑部网站上验</a>证许可证信息。',
      question:
        '找水管工安装阀门时，我应该问哪些问题？',
      answer1:
        '询问许可证、在纽约或您所在社区的经验、证书以及从事过的类似项目。',
      answer2: '尝试询问三位水管工进行报价，然后进行比较。'
    }
  };
  