export default {
  meta: {
    title: 'Register',
    url: 'https://www.floodhelpny.org/en/register'
  },
  title: 'Risk Rating 2.0',
  subHeading:
    'Register for a free webinar to learn more about Risk Rating 2.0, and get your questions answered to help keep your home and finances safe',
  register:
    'Register for a free webinar on Sep 13, 2022 with housing counselors',
  brought: 'Brought to you by',
  riskRating:
    'Risk Rating 2.0 is a new federal system for setting flood insurance premiums. More than 60% of New Yorkers who currently hold policies could see an increase in their insurance costs.',
  learnMore: 'Learn more about Risk Rating 2.0',
  funding: {
    support: 'With funding support from FEMA',
    questions: 'Questions? Email ',
    commsEmail: 'communications@cnycn.org'
  }
};
