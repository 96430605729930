import sortingOptions from '../images/sorting-options.svg';
import structural from '../images/structural.svg';
import wrench from '../images/wrench.svg';
import speechBubbles from '../images/speech-bubbles.svg';

export default {
  header: 'What To Expect',
  list: [
    {
      header: 'Assess',
      imageAlt: 'building',
      body:
        'A credentialed engineering firm will evaluate your home and give you the resiliency report, which outlines what you can do to be flood-safe.',
      image: structural
    },
    {
      header: 'Discuss',
      imageAlt: 'speech bubbles',
      body:
        'We’ll schedule a time for you to speak with a resiliency counselor, who can walk you through your report and your options.',
      image: speechBubbles
    },
    {
      header: 'Review',
      imageAlt: 'controls',
      body:
        'We’ll upload your report and elevation certificate for you to review, so you can decide which steps to take.',
      image: sortingOptions
    },
    {
      header: 'Act',
      imageAlt: 'wrench',
      body:
        'Your report will include all the ways you can fortify your home and we can help connect you to any resources you need to get started.',
      image: wrench
    }
  ]
};
