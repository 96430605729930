export default {
  basicInfo: 'INFORMACIÓN DE SOLICITUD:',
  // All questions keys must be listed here with a corresponding title.
  // Use the yes or no keys as replacements for boolean answers (true, false)
  yes: 'Yes',
  no: 'No',
  co_applicant_first_name: 'Primer Nombre',
  co_applicant_last_name: 'Apellido',
  co_applicant_birthdate: 'Fecha de Nacimiento',
  co_applicant_email: 'Correo Electrónico',
  co_applicant_home_phone: 'Teléfono residencial',
  co_applicant_mobile_phone: 'Teléfono móvil',
  co_applicant_signature: 'Firma',
  co_applicant_type: 'Co Solicitante Tipo',
  e_signature: 'Firma Electrónica',
  household_member_race: 'Raza',
  household_member_income: 'Ingresos anuales',
  household_member_ethnicity: 'Familia Grupo Étnico',
  household_member_gender: 'Sexo',
  sandy_compensation: 'Indemnización por Sandy',
  sandy_insurance_types: 'Seguro que tenía durante la Supertormenta Sandy',
  tenants: 'Inquilinos',
  flood_insurance: 'Documento(s) de Seguro contra Inundación',
  homeowners_insurance: 'Documento(s) de Seguro de Propiedad',
  household_member_proofincome: 'Prueba de Ingresos para la Familia',
  backwater_valve_compensation: 'Indemnización por Reflujo de Aguas Residuales',
  applicant_race: 'Raza del Solicitante Principal',
  applicant_ethnicity: 'Etnia del Solicitante Principal',
  applicant_gender: 'Identidad de Género del Solicitante Principal',
  applicant_birthdate: 'Fecha de Nacimiento del Solicitante Principal'
};
