export default {
  changeLinkText: 'cambio',
  currentMaps: 'Mapas vigentes',
  futureMaps: 'Mapas de advertencia',
  legend: {
    label: 'CLAVE:',
    minimalHazard: 'Peligro mínimo',
    moderateRisk: 'Riesgo moderado',
    highRisk: 'Riesgo alto ',
    veryHighRisk: 'Riesgo máximo ',
    floodingKey: ['Minimal hazard', 'Moderate risk', 'High Risk', 'Highest risk (flooding & waves)'],
    stormwaterKey: ['Moderate stormwater flooding hazard', 'High stormwater flooding hazard'],
    copy:
      'El mapa muestra los mapas de inundaciones vigentes y de advertencia para la Ciudad de Nueva York según lo propuesto por ',
    linkCopy: 'FEMA',
    linkUrl: 'https://www.fema.gov'
  },
  loadingText: 'Cargando...'
};
