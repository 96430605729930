import addressInput from './AddressInput.zh-CN';

export default {
  meta: {
    title: '探索纽约市洪水区地图',
    url: 'https://www.floodhelpny.org/zh-CN/explore-map',
    description:
      "浏览纽约市的洪水区地图，了解您目前和未来的风险",
    imageAlt: '可探索地图'
  },
  loadingText: '',
  placeholder: '输入地址',
  currentMaps: '当前地图',
  futureMaps: '咨询地图',
  addressInput,
  legend: {
    label: '关键：',
    minimalHazard: '最小危险',
    moderateRisk: '中等风险',
    highRisk: '高风险',
    veryHighRisk: '最高风险（洪水和海浪）',
    copy:
      '该地图显示了纽约市的当前和咨询洪水地图 ',
    linkCopy: 'FEMA',
    linkUrl: 'https://www.fema.gov'
  },
  showPopup: '显示洪水区',
  hidePopup: '隐藏洪水区',
  errorText:
    '看起来将数据返回到我们的地图时谷歌遇到了一些问题！请稍后再试。'
};

