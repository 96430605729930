import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import OtherResources from './OtherResources';
import ButtonLink from './ui/ButtonLink';
import PrimaryAndAsideContainer from './layout/PrimaryAndAsideContainer';
import ListWithIconAndText from './layout/ListWithIconAndText';
import VideoWrapper from './ui/VideoWrapper';
import {
  HeaderSection,
  Subheader,
  Section,
  SectionDescription
} from './ui/StyledResourcePageElements';
import { useProvider, useFaq } from '../helpers/hooks';

const ElevationCertificates = () => {
  const provider = useProvider('elevationCertificates');
  const { setFaqZone } = useFaq();

  useEffect(() => {
    setFaqZone('elevation-certificates');
  }, []);

  return (
    <>
      <Helmet>
        <title>{provider.meta.title}</title>
        <link rel="canonical" href={provider.meta.url} />
        <meta name="description" content={provider.meta.description} />
        <meta property="og:title" content={provider.meta.title} />
        <meta property="og:description" content={provider.meta.ogDescription} />
        <meta property="og:url" content={provider.meta.url} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <HeaderSection>
        <h1>{provider.title}</h1>
        <Subheader>{provider.subheader}</Subheader>
      </HeaderSection>
      <Section>
        <h5>{provider.overview.header}</h5>
        <PrimaryAndAsideContainer>
          <div>
            <p>{provider.overview.body}</p>
          </div>
          <aside>
            <ButtonLink
              url="https://www.fema.gov/media-library/assets/documents/160"
              text={provider.download}
              external
            />
          </aside>
        </PrimaryAndAsideContainer>
      </Section>
      <Section>
        <ListWithIconAndText items={provider.about} />
      </Section>
      <Section>
        <h5>{provider.howToGetOne.header}</h5>
        <PrimaryAndAsideContainer>
          <div>
            <p
              dangerouslySetInnerHTML={{
                __html: provider.howToGetOne.body
              }}
            />
          </div>
          <aside>
            <div>
              <h6>{provider.howToGetOne.tip.header}</h6>
              <p>{provider.howToGetOne.tip.body}</p>
            </div>
          </aside>
        </PrimaryAndAsideContainer>
      </Section>
      <Section>
        <h5>{provider.alreadyHaveOne.header}</h5>
        <PrimaryAndAsideContainer>
          <div>
            <p>{provider.alreadyHaveOne.body}</p>
          </div>
          <aside>
            <ButtonLink
              url="https://www1.nyc.gov/assets/housingrecovery/downloads/pdf/2019/how_to_update_your_elevation_certificate_v20190424.pdf"
              external
              text={provider.update}
            />
          </aside>
        </PrimaryAndAsideContainer>
      </Section>
      <Section>
        <h5>{provider.expired.header}</h5>
        <SectionDescription>{provider.expired.body}</SectionDescription>
      </Section>
      <OtherResources />
    </>
  );
};

export default ElevationCertificates;
