import React from 'react';
import styled from 'styled-components';
import { useProvider } from '../helpers/hooks';
import eastRiver from '../images/east-river-minimized.png';
import { colors } from '../styles/variables';
import EligibilityLookup from './EligibilityLookup';

const Wrapper = styled.section`
  margin: 0;
  padding: 0;
  border-bottom: none;
  width: 100%;
  background-color: ${colors.grayScale[0]};

  @media (max-width: 768px) {
    min-height: 500px;
  }
`;

const HeroImage = styled.div`
  height: 100vh;
  min-height: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: url(${eastRiver});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  clip-path: polygon(0 0, 100% 0, 100% 93%, 54% 93%, 50% 99%, 46% 93%, 0 93%);

  @media (max-width: 850px) {
    clip-path: polygon(
      0 0,
      100% 0,
      100% 93%,
      64% 93%,
      50% 100%,
      36% 93%,
      0 93%
    );
  }

  @media (max-width: 768px) {
    height: 100vh;
    min-height: 500px;
    background-attachment: initial;
  }
`;

const HeaderWrapper = styled.div`
  margin: 0 10rem;

  @media (max-width: 768px) {
    margin: 0 2rem;
  }
`;

const Header = styled.h4`
  color: white;
  font-size: calc(3vw + 3rem);
  line-height: 1;
  font-family: 'GT-Walsheim-Pro-Bold';
  margin-bottom: 1rem;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 3rem;
  }

  @media (max-width: 420px) {
    font-size: 2rem;
  }
`;

const AddressExample = styled.div`
  font-size: 1rem;
  color: white;
  text-align: start;
  margin-top: 0.5rem;

  @media (max-width: 420px) {
    font-size: 14px;
  }
`;

const RegisterMessage = styled.a`
  color: white;
  font-size: calc(1vw + 1rem);
  font-family: 'GT-Walsheim-Pro-Bold';
  margin-bottom: 1rem;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 2rem;
  }

  @media (max-width: 420px) {
    font-size: 1rem;
  }
`;

const LandingHero = () => {
  const provider = useProvider('landingHero');

  return (
    <Wrapper>
      <HeroImage>
        <HeaderWrapper>
          <Header>{provider.callToAction}</Header>
          <EligibilityLookup />
          <AddressExample>{provider.addressExample}</AddressExample>
        </HeaderWrapper>
      </HeroImage>
    </Wrapper>
  );
};

export default LandingHero;
