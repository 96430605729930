export default {
  currentlyServed: 'Zòn ki Desèvi Aktyèlman',
  neighborhood: {
    header: 'Katye: ',
    content:
      'Bensonhurst, Bergen Beach, Brighton Beach, Canarsie, Coney Island, Gravesend, Georgetown, Gerritsen Beach, Howard Beach, Lower Manhattan, Manhattan Beach, Marine Park, Mill Basin, Mill Island, Red Hook, Rockaway East, Seagate, ak Sheepshead Bay.'
  },
  priority: {
    header: 'Kritè ki Priyorize Kritè yo:',
    criteria: [
      {
        key: 'income',
        copy: 'nivo revni'
      },
      {
        key: 'sandy',
        copy: 'kit ou te soufri domaj Sandy ou pa'
      },
      {
        key: 'certificate',
        copy: 'kit ou gen yon sètifika elevasyon ou pa'
      }
    ]
  },
  downloadEligibilityForm:
    '<a href="/samples/backwater-valve-eligibility.pdf"> Telechaje tout egzijans kalifikasyon yo la</a>.'
};
