import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import OtherResources from './OtherResources';
import CallToAction from './layout/CallToAction';
import RoundIcon from './RoundIcon';
import ButtonLink from './ui/ButtonLink';
import PrimaryAndAsideContainer from './layout/PrimaryAndAsideContainer';
import ListWithIconAndText from './layout/ListWithIconAndText';
import Card from './layout/Card';
import {
  HeaderSection,
  Subheader,
  Section,
  SectionDescription,
  SectionIntro
} from './ui/StyledResourcePageElements';
import { useProvider, useLocale, useFaq } from '../helpers/hooks';

const OverviewImage = styled.img`
  width: 100%;
  height: auto;
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ImageCaption = styled.p`
  margin-top: 0.5rem;
  font-style: italic;
  font-size: 1rem;
`;

const TwitterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
  margin-bottom: 2rem;
`;

const Tweet = styled.div`
  width: 70%;
  height: auto;
  margin-top: 2rem;
`;

const ClimateChange = () => {
  const provider = useProvider('climateChange');
  const locale = useLocale();
  const { setFaqZone } = useFaq();

  useEffect(() => {
    setFaqZone('climate-change');
  }, []);

  return (
    <>
      <Helmet>
        <title>{provider.meta.title}</title>
        <link rel="canonical" href={provider.meta.url} />
        <meta name="description" content={provider.meta.description} />
        <meta property="og:title" content={provider.meta.title} />
        <meta
          property="og:image"
          content="https://www.floodhelpny.org/images/climate-change-twitter.png"
        />
        <meta property="og:description" content={provider.meta.description} />
        <meta property="og:url" content={provider.meta.url} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image:alt" content={provider.meta.imageAlt} />
      </Helmet>
      <HeaderSection>
        <h1>{provider.title}</h1>
        <Subheader>{provider.subHeading}</Subheader>
      </HeaderSection>
      <Section>
        <SectionIntro>
          <h5>{provider.overview.header}</h5>
          <SectionDescription>{provider.overview.body}</SectionDescription>
        </SectionIntro>
        <PrimaryAndAsideContainer withIcon>
          <ImageContainer>
            <OverviewImage
              src={provider.overview.image}
              alt={provider.overview.imageAlt}
            />
            <ImageCaption>{provider.overview.imageCaption}</ImageCaption>
          </ImageContainer>
          <aside>
            <div>
              <RoundIcon
                inAside
                src={provider.overview.aside.icon}
                alt={provider.overview.aside.iconAlt}
              />
              <p>{provider.overview.aside.body}</p>
            </div>
          </aside>
        </PrimaryAndAsideContainer>
      </Section>
      <Section noBorder>
        <h5>{provider.howClimateChangeContributes.header}</h5>
        <p>{provider.howClimateChangeContributes.body.p1}</p>
        <SectionDescription>
          {provider.howClimateChangeContributes.body.p2}
        </SectionDescription>
        <ListWithIconAndText
          items={provider.howClimateChangeContributes.items}
        />
        <SectionDescription>
          {provider.howClimateChangeContributes.body.p3}
        </SectionDescription>
        <Card>
          <img
            alt={provider.climateCrisis.imageAlt}
            src={provider.climateCrisis.image}
          />
          <div>
            <h4>{provider.climateCrisis.title}</h4>
            <p>
              {provider.climateCrisis.body}
              <a
                href="https://www.srijb.org/jbfloodwatch/"
                target="_blank"
                rel="noreferrer noopener"
              />
            </p>
          </div>
          <ButtonLink
            url="https://www.srijb.org/jbfloodwatch/"
            text={provider.climateCrisis.view}
            external
          />
        </Card>
      </Section>
      <Section noBorder>
        <h5>{provider.climateChangeFlooding.header}</h5>
        <p>{provider.climateChangeFlooding.body}</p>
      </Section>
      <Section noBorder>
        <h5>{provider.severeHurricanes.header}</h5>
        <PrimaryAndAsideContainer withIconAndButton>
          <div>
            <p>{provider.severeHurricanes.body.p1}</p>
            <TwitterContainer>
              <Tweet>
                <blockquote className="twitter-tweet" tw-align-center="true">
                  <p lang="en" dir="ltr">
                    At least 14 people are dead after{' '}
                    <a href="https://twitter.com/hashtag/HurricaneIda?src=hash&amp;ref_src=twsrc%5Etfw">
                      #HurricaneIda
                    </a>{' '}
                    remnants brought devastating flooding to the northeast ⛈️.
                    The storm trapped people on the{' '}
                    <a href="https://twitter.com/hashtag/NYC?src=hash&amp;ref_src=twsrc%5Etfw">
                      #NYC
                    </a>{' '}
                    subway and first responders had to rescue people
                    <a href="https://twitter.com/hashtag/NYCFlooding?src=hash&amp;ref_src=twsrc%5Etfw">
                      #NYCFlooding
                    </a>{' '}
                    <a href="https://twitter.com/hashtag/NewsUpdates?src=hash&amp;ref_src=twsrc%5Etfw">
                      #NewsUpdates
                    </a>{' '}
                    <a href="https://t.co/sdyHMiT4i7">
                      pic.twitter.com/sdyHMiT4i7
                    </a>
                  </p>
                  &mdash; World Politic News (@WPoliticNews){' '}
                  <a href="https://twitter.com/WPoliticNews/status/1433485237749788673?ref_src=twsrc%5Etfw">
                    September 2, 2021
                  </a>
                </blockquote>
              </Tweet>
              <Tweet>
                <blockquote className="twitter-tweet" tw-align-center="true">
                  <p lang="en" dir="ltr">
                    People navigate heavy rains and flooded walkways at the
                    Billie Jean King National Tennis Center as the remnants of
                    Hurricane Ida hit the area in Flushing Meadows, New York,
                    USA.📷 epa / Justin Lane
                    <a href="https://twitter.com/hashtag/epaphotos?src=hash&amp;ref_src=twsrc%5Etfw">
                      #epaphotos
                    </a>{' '}
                    <a href="https://twitter.com/hashtag/visualizingtheworld?src=hash&amp;ref_src=twsrc%5Etfw">
                      #visualizingtheworld
                    </a>{' '}
                    <a href="https://twitter.com/hashtag/NYC?src=hash&amp;ref_src=twsrc%5Etfw">
                      #NYC
                    </a>{' '}
                    <a href="https://twitter.com/hashtag/usa?src=hash&amp;ref_src=twsrc%5Etfw">
                      #usa
                    </a>{' '}
                    <a href="https://twitter.com/hashtag/ida?src=hash&amp;ref_src=twsrc%5Etfw">
                      #ida
                    </a>{' '}
                    <a href="https://twitter.com/hashtag/hurricaneida?src=hash&amp;ref_src=twsrc%5Etfw">
                      #hurricaneida
                    </a>{' '}
                    <a href="https://t.co/uPX7uEKvBA">
                      pic.twitter.com/uPX7uEKvBA
                    </a>
                  </p>
                  &mdash; european pressphoto agency (@epaphotos){' '}
                  <a href="https://twitter.com/epaphotos/status/1433262132339085315?ref_src=twsrc%5Etfw">
                    September 2, 2021
                  </a>
                </blockquote>
              </Tweet>
              <Tweet>
                <blockquote className="twitter-tweet" tw-align-center="true">
                  <p lang="en" dir="ltr">
                    Flash Flood Emergency including New York NY, Brooklyn NY,
                    Queens NY until 11:30 PM EDT{' '}
                    <a href="https://t.co/Phpg3otRnk">
                      pic.twitter.com/Phpg3otRnk
                    </a>
                  </p>
                  &mdash; NWS New York NY (@NWSNewYorkNY){' '}
                  <a href="https://twitter.com/NWSNewYorkNY/status/1433240480578478081?ref_src=twsrc%5Etfw">
                    September 2, 2021
                  </a>
                </blockquote>
              </Tweet>
            </TwitterContainer>
            <SectionDescription>
              {provider.severeHurricanes.body.p2}
            </SectionDescription>
            <SectionDescription>
              {provider.severeHurricanes.body.p3}
            </SectionDescription>
          </div>
          <aside>
            <div>
              <RoundIcon
                inAside
                src={provider.severeHurricanes.aside.icon}
                alt={provider.severeHurricanes.aside.iconAlt}
              />
              <p>{provider.severeHurricanes.aside.body}</p>
              <ButtonLink
                url="https://www.floodnet.nyc"
                external
                text={provider.severeHurricanes.aside.link}
                small
              />
            </div>
          </aside>
        </PrimaryAndAsideContainer>
        <CallToAction>
          <RoundIcon
            src={provider.severeHurricanes.callToAction.icon}
            alt={provider.severeHurricanes.callToAction.iconAlt}
          />
          <div>
            <h3>{provider.severeHurricanes.callToAction.header}</h3>
          </div>
          <ButtonLink
            url={`/${locale}/flood-retrofits`}
            text={provider.severeHurricanes.callToAction.buttonText}
          />
        </CallToAction>
      </Section>
      <OtherResources />
    </>
  );
};

export default ClimateChange;
