import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '../styles/variables';
import { useLocale, useProvider } from '../helpers/hooks';
import EligibilityLookup from './EligibilityLookup';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: ${(props) => (props.noNavBarAddressInput ? 'none' : '2 1 auto')};

  @media (min-width: 1300px) {
    display: none;
  }
`;

const Hamburger = styled.div`
  font-size: 30px;
  flex: 1 1 auto;
  text-align: end;
`;

const Links = styled.div`
  position: absolute;
  height: calc(100vh - 89px);
  top: -40px;
  left: 0;
  width: 100%;
  background-color: white;
  border-top: 1px solid ${colors.grayScale[1]};
  transition: all 0.5s ease;
  transform: translateY(${(props) => (props.isVisible ? '89px' : '-100%')});
  opacity: ${(props) => (props.isVisible ? 1 : 0)};

  @media (min-width: 450px) {
    height: calc(100vh - 30px);
    transform: translateY(${(props) => (props.isVisible ? '120px' : '-100%')});
  }
`;

const Link = styled.a`
  border-bottom: 1px solid ${colors.grayScale[1]};
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;

  &:focus {
    text-decoration: none;
  }
`;

const MobileNav = ({ noNavBarAddressInput }) => {
  const provider = useProvider('navBar');
  const locale = useLocale();

  const [areLinksVisible, setLinksVisibility] = useState(false);

  const toggleLinks = () => {
    setLinksVisibility((prevState) => !prevState);
  };

  return (
    <Wrapper noNavBarAddressInput={noNavBarAddressInput}>
      {!noNavBarAddressInput && <EligibilityLookup navBar />}
      <Links isVisible={areLinksVisible}>
        <Link href={`/${locale}/understanding-flood-insurance`}>
          <h4>{provider.resources.insurance}</h4>
        </Link>
        <Link href={`/${locale}/flood-retrofits`}>
          <h4>{provider.resources.retrofits}</h4>
        </Link>
        <Link href={`/${locale}/small-business`}>
          <h4>{provider.resources.smallBusinesses}</h4>
        </Link>
        <Link href={`/${locale}/stormwater-flooding`}>
          <h4>{provider.resources.stormwaterFlooding}</h4>
        </Link>
        <Link href={`/${locale}/climate-change`}>
          <h4>{provider.resources.climateChange}</h4>
        </Link>
        <Link href={`/${locale}/prepare-for-hurricane-season`}>
          <h4>{provider.resources.hurricane}</h4>
        </Link>
        <Link href={`/${locale}/news`}>
          <h4>{provider.resources.news}</h4>
        </Link>
      </Links>
      <Hamburger
        role="button"
        onClick={toggleLinks}
        className="lnr lnr-menu"
        alt="Menu"
      />
    </Wrapper>
  );
};

MobileNav.propTypes = {
  noNavBarAddressInput: PropTypes.bool.isRequired
};

export default MobileNav;
