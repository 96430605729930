import backwaterValve from '../images/backwater-valve.svg';
import cookingOil from '../images/cooking-oil.svg';
import garbage from '../images/garbage.svg';
import roofing from '../images/roofing.svg';
import basement from '../images/basement.svg';
import unpaved from '../images/unpaved.svg';
import rainEventGuide from '../images/rain-event-guide.jpg';
import stormwaterFlooding from '../images/stormwater-flooding.svg';
import floodInsurance from '../images/flood-insurance.svg';

export default {
  meta: {
    title: 'Затопление дренажной водой | FloodHelpNY.org',
    ogTitle: 'Затопление дренажной водой в Нью-Йорке | FloodHelpNY.org',
    url: 'https://www.floodhelpny.org/ru/stormwater-flooding',
    description:
      'Затопление дренажной водой может оказать влияние на районы, расположенные в милях от береговой линии, предстваляя угрозу собственности и здоровью.'
  },
  header: 'Затопление дренажной водой',
  subHeader:
    'Затопление дренажной водой может оказать влияние на районы, расположенные в милях от береговой линии, предстваляя угрозу собственности и здоровью.',
  whatIsStormwater: {
    header: 'ЧТО ТАКОЕ ДРЕНАЖНАЯ ВОДА?',
    body:
      ' Дренажная вода  - это дождевая вода или растаявший снег, который стекает с газонов, улиц и других земельных поверхностей. В идеале этот поверхностный сток впитался бы растениями и почвой или добрался бы до канализации. Но в Нью-Йорке тротуары и улицы не впитывают дождевую воду. Вместо этого дождевая вода стекает в водосборные коллекторы города, откуда она направляется в канализацию. К сожалению городские водосборные коллекторы имеют ограниченную емкость. Избыточная дождевая вода неизбежно стекает на улицы и вызывает местное затопление. Канализационные воды могут также заливать дома.'
  },
  pollution: {
    header: 'ДРЕНАЖНАЯ ВОДА МОЖЕТ СПОСОБСТВОВАТЬ ЗАГРЯЗНЕНИЮ',
    body: `В дополнение к риску затопления жилья, существует также риск загрязнения окружающей среды: избыточный поверхностный сток, который не впитывается в землю, может подобрать загрязнители, такие как масла, пестициды, бактерии и мусор прежде чем стечь в ливневые стоки, дренажные канавы и в океан.`
  },
  howRainFallImpacts: {
    header:
      'Как наводнение, вызванное дождем, может отразиться на вашей улице, доме или бизнесе.',
    icon: stormwaterFlooding,
    iconAlt: 'Затопление дренажной водой',
    buttonText: 'Дополнительная информация',
    buttonUrl:
      'https://experience.arcgis.com/experience/6f4cc60710dc433585790cd2b4b5dd0e'
  },
  protectHome: {
    title: 'Защита вашего дома от ущерба, нанесенного дренажной водой',
    subtitle:
      'Если вы пережили наводнение или хотите сократить риск того, что это произойдет с вами, вот несколько моментов, которые можно принять в расчет.',
    list: [
      {
        header: 'Установить канализационный обратный клапан',
        body:
          'Канализационный обратный клапан может предотвратить канализационные воды от поднятия через городскую канализацию в ваш дом через водопроводно-канализационную систему в подвале. Во время сильного дождя, если вода из городской канализации  поднимается до уровня водопроводно-канализационной системы в подвале, клапан закроется и заблокирует канализационную воду от проникновения в ваш дом.',
        image: backwaterValve,
        imageAlt: 'Канализационный обратный клапан'
      },
      {
        header: 'Защитите зоны, находящиеся ниже уличного  уровня',
        body:
          'Когда идет дождь, вода течет в направлении и скапливается в низких зонах. Такие места, как подземные гаражи, подвалы и другие низко расположенные участки являются наиболее подверженными наводнениям. Сократите риск наводнения путем герметизации трещин в стенах фундамента и использованием барьеров, таких как мешки с песком.',
        image: basement,
        imageAlt: 'Подвал'
      },
      {
        header: 'Сократите поверхностный сток дренажной воды',
        body:
          'Вода стекает с бетона и асфальта практически немедленно и может превысить проектные лимиты канализации быстро во время дождя. Подпорка и создание зеленых и незаасфальтированных мест вокруг вашего дома может помочь сократить потоки в канализацию путем сокращения количества поверхностного стока дренажной воды, которую ваша собственность отправляет в канализацию.',
        image: unpaved,
        imageAlt: 'Посадка дерева'
      },
      {
        header: 'Не позволяйте жирам стекать в канализацию',
        body:
          'Неправильное избавление от жиров и масла для приготовления пищи может засорить внутренние трубы вашего дамо, а также и городскую канализацию. Это может вызвать разлив канализационных вод в вашем районе и доме.',
        image: cookingOil,
        imageAlt: 'Масло для приготовления пищи'
      },
      {
        header: 'Держите водосборные коллекторы чистыми',
        body: `Поддержание улиц в чистоте помогает дренажным улицам сливаться и помогает защищать городские водоемы. Город постоянно работает над тем, чтобы содержать в исправности 7500 миль канализации и 148000 водосборных коллекторов, которые собирают уличный мусор. Но если они засорятся, это может вызвать затопление на улице. Вы и ваши соседи можете помочь сократить такой тип наводнений путем правильного избавления от мусора и путем аккуратного удаления листьев и мусора из водосборных коллекторов до или во время дождя.`,
        image: garbage,
        imageAlt: 'Мусор'
      },
      {
        header: 'Установите надлежащий водоотвод с кровли',
        body:
          'Направление ливневых вод с вашей крыши в сторону от стен фундамента вашего дома необходимо для содержания вашего подвала в сухости. Регуляно проводите очистку ваших водосточных желобов, чтобы предотвратить разлив ливневых вод на стены и соедините трубы водостока с соответствующими водоотводами. Подумайте об установке дождевой бочки как альтернативного метода сбора ливневых вод.',
        image: roofing,
        imageAlt: 'Водоотвод с кровли'
      },
      {
        header: 'Приобрести страхование от наводнения',
        body:
          'Там, где может пойти дождь, может быть наводнение - уже сейчас около 20% всех исков NFIP по всей стране подаются за пределами зон затопления FEMA. Проверьте у вашего провайдера страховки и подумайте о приобретении полиса от противотока канализации или страхования от наводнения. Эти полисы могут покрыть ущерб и расходы на очистку в результате противотока канализации и наводнения, вызванного сильным дождем. Помните: ущерб, нанесенный наводнением, не входит в стандартные страховые полисы домовладельцев или арендаторов.',
        image: floodInsurance,
        imageAlt: 'Страхование от наводнения'
      }
    ]
  },
  homeOwnerGuide: {
    header: `Руководство для домовладельцев города по подготовке к ливням`,
    image: rainEventGuide,
    imageAlt: 'Руководство по ливням',
    buttonText: 'Дополнительная информация',
    posterUrl:
      'https://www1.nyc.gov/assets/dep/downloads/pdf/climate-resiliency/flood-preparedness-flyer.pdf'
  }
};
