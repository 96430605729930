import reportCard from '../images/report-card.svg';
import certificate from '../images/certificate.svg';
import documents from '../images/documents.svg';
import backwaterValve from '../images/backwater-valve.svg';

const example = 'Gade egzanp';

export default {
  header: 'Kisa Ou Ap Jwenn',
  list: [
    {
      header: 'Valv pou dlo k ap tounen',
      body:
        'Ekip nou an, ki gen ladan l enjenyè epi plonbye, pral travay ak ou pou detèmine si kay ou an ta ka benefisye yon valv pou dlo k ap tounen.',
      image: backwaterValve
    },
    {
      header: 'Konsèy pou Asirans kont Inondasyon',
      body:
        'Konseye ekspè nou yo pral analize kontra asirans kont inondasyon ou an epi diskite sou potansyèl opsyon yo pou ede redui frè asirans kont inondasyon ou an atravè analiz sètifika elevasyon an epi <a href="/en/flood-retrofits">opsyon adousisman yo</a>.',
      image: documents
    },
    {
      header: 'Rapò sou Tolerans',
      body:
        'Apre nou enspekte kay ou an epi evalye risk li pou inondasyon, nou ap voye yon rapò teknik detaye ba ou, ranpli ak mezi epi enfòmasyon sou ki renovasyon (sa vle di: elve sistèm mekanik ou yo) ka rann ou pi sekirize kont inondasyon.',
      image: reportCard,
      exampleLink: '/samples/technical_report_john_doe.pdf',
      example
    },
    {
      header: 'Sètifika Elevasyon',
      headerLink: '/ht_HT/elevation-certificates',
      body:
        'Nou pral voye ba ou tou yon dokiman ki sètifye elevasyon kay ou an. Si ou ap viv nan yon zòn ki gen gwo risk pou inondasyon, sètifika elevasyon an ka anpeche tarif asirans kont inondasyon ou an grenpe.',
      image: certificate,
      exampleLink: '/samples/elevation_certificate.pdf',
      example
    }
  ]
};
