import React from 'react';
import PropTypes from 'prop-types';
import AuditApplicationQuestionContainer from './AuditApplicationQuestionContainer';

const AuditApplicationQuestions = ({
  questions,
  answers,
  numberOfApplicants,
  setQuestions,
  setAnswers,
  setNumberOfApplicants
}) => (
  <div>
    {questions.map((question, index) => (
      <AuditApplicationQuestionContainer
        key={question.title}
        index={index}
        question={question}
        answer={answers[index]}
        questions={questions}
        answers={answers}
        numberOfApplicants={numberOfApplicants}
        setAnswers={setAnswers}
        setQuestions={setQuestions}
        setNumberOfApplicants={setNumberOfApplicants}
      />
    ))}
  </div>
);

AuditApplicationQuestions.propTypes = {
  questions: PropTypes.array.isRequired,
  answers: PropTypes.array.isRequired,
  numberOfApplicants: PropTypes.number.isRequired,
  setQuestions: PropTypes.func.isRequired,
  setAnswers: PropTypes.func.isRequired,
  setNumberOfApplicants: PropTypes.func.isRequired
};

export default AuditApplicationQuestions;
