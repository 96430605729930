import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import UtilityBar from './UtilityBar';
import UtilityBarMobile from './UtilityBarMobile';
import MobileNav from './MobileNav';
import DesktopNav from './DesktopNav';
import Modal from './layout/Modal';
import LocaleContext from '../context/LocaleContext';
import ApiContext from '../context/ApiContext';
import GoogleMapsContext from '../context/GoogleMapsContext';
import ModalContext from '../context/ModalContext';

const Wrapper = styled.nav`
  position: fixed;
  width: 100%;
  top: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
  height: 80px;
  padding: 0 2rem;
  background-color: white;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease;
  transform: translateY(${(props) => (props.isVisible ? 0 : '-120px')});

  @media (max-width: 450px) {
    height: 49px;
    padding: 0 0.5rem;
  }
`;

const HomePageLinkWrapper = styled.div`
  flex: 1 1 auto;

  @media (max-width: 350px) {
    flex: none;
  }
`;

const HomePageLink = styled.a`
  font-family: GT-Walsheim-Pro-Bold;
  margin-right: 1rem;

  &:hover {
    text-decoration: none;
  }

  @media (max-width: 350px) {
    margin-right: 5px;
  }
`;

// Top-level component called in application.html.erb
const NavBar = ({
  locale,
  buildingApi,
  googleMapsScriptUrl,
  salesforceApi
}) => {
  const provider = require(`../providers/navBar.${locale}`).default;

  const [isNavBarVisible, setIsNavBarVisible] = useState(true);
  const [noNavBarAddressInput, setNoNavBarAddressInput] = useState(true);
  const [isModalActive, setIsModalActive] = useState(false);
  const [modalChildren, setModalChildren] = useState(false);

  // TODO: refactor to not show navbar on landing page
  useEffect(() => {
    let previousScrollTop = 0;

    const toggleVisibility = () => {
      const { scrollTop } = document.body;
      const isScrollingDown = scrollTop > previousScrollTop && scrollTop > 1;
      setIsNavBarVisible(!isScrollingDown);
      previousScrollTop = scrollTop;
    };

    window.addEventListener('scroll', toggleVisibility);

    // TODO: maybe add hide-after-timeout functionality from ideo_behavior.js

    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  return (
    <LocaleContext.Provider value={locale}>
      <ApiContext.Provider value={{ buildingApi, salesforceApi }}>
        <GoogleMapsContext.Provider value={{ googleMapsScriptUrl }}>
          <ModalContext.Provider
            value={{
              isModalActive,
              setIsModalActive,
              modalChildren,
              setModalChildren
            }}
          >
            {isModalActive && (
              <Modal isModalActive={isModalActive}>{modalChildren}</Modal>
            )}
            <UtilityBar isVisible={isNavBarVisible} />
            <UtilityBarMobile isVisible={isNavBarVisible} />
            <Wrapper isVisible={isNavBarVisible}>
              <HomePageLinkWrapper>
                <HomePageLink href={`/${locale}/`}>
                  {provider.homepage}
                </HomePageLink>
              </HomePageLinkWrapper>
              <DesktopNav noNavBarAddressInput={noNavBarAddressInput} />
              <MobileNav noNavBarAddressInput={false} />
            </Wrapper>
          </ModalContext.Provider>
        </GoogleMapsContext.Provider>
      </ApiContext.Provider>
    </LocaleContext.Provider>
  );
};

NavBar.propTypes = {
  locale: PropTypes.string.isRequired,
  buildingApi: PropTypes.string.isRequired,
  googleMapsScriptUrl: PropTypes.string.isRequired,
  salesforceApi: PropTypes.string.isRequired
};

export default NavBar;
