export default {
  header: 'Dónde debe estar su propiedad',
  baseFloodElevationExplainedPre: 'Actualmente ',
  baseFloodElevationExplainedPost: ' sobre el nivel del mar',
  baseFloodElevation:
    'Este es el Nivel de Inundación del Proyecto (DFE, por sus siglas en inglés) donde usted necesita estar.',
  topOfBottomFloor:
    'Es la elevación actual de su vivienda sobre el nivel del mar.',
  freeboardCity:
    "Esto es'freeboard' requerido por la Ciudad para garantizar una elevación segura"
};
