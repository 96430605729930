export default {
  download: 'Загрузить',
  elevationCertificate: {
    header: 'Ваш сертификат высотной отметки (PDF)',
    copy: 'Вы можете его использовать, чтобы получить точный тариф страхования от наводнения '
  },
  technicalReport: {
    header: 'Ваш полный технический отчет (PDF)',
    copy: 'Полный технический отчет'
  },
  fieldReport: {
    header: 'Ваш полный отчет с места работ (PDF)',
    copy: 'Полный отчет с места работ'
  },
  homeownerGrantAgreement: {
    header: 'Ваше полное соглашения о безвозмездной ссуде домовладельцу (PDF)',
    copy: 'Полное соглашение безвозмездной ссуды домовладельцу'
  },
  triPartyAgreement: {
    header: 'Ваше полное трехстороннее соглашение (PDF)',
    copy: 'Полное трехстороннее соглашение'
  }
};
