import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '../styles/variables';
import LowerInsuranceOption from './LowerInsuranceOption';
import CallToAction from './layout/CallToAction';
import ElevateBuildingHelper from '../helpers/profileMitigation/ElevateBuildingHelper';
import AbandonFirstFloorHelper from '../helpers/profileMitigation/AbandonFirstFloorHelper';
import ElevateMechanicalsHelper from '../helpers/profileMitigation/ElevateMechanicalsHelper';
import FloodVentsHelper from '../helpers/profileMitigation/FloodVentsHelper';
import FillInYourBasementHelper from '../helpers/profileMitigation/FillInYourBasementHelper';
import abandonFirstFloorIcon from '../images/abandon-first-floor.svg';
import elevateIcon from '../images/elevate.svg';
import elevateMechanicalsIcon from '../images/elevate-mechanicals.svg';
import floodVentsIcon from '../images/flood-vents.svg';
import fillInBasementIcon from '../images/fill-in-basement.svg';
import { useLocale, useProvider } from '../helpers/hooks';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 2rem;
  flex-wrap: wrap;

  h4 {
    font-size: 1.5rem;
  }
`;

const Disclaimers = styled.div`
  border-top: solid 1px ${colors.grayScale[1]};
  padding-top: 1rem;

  p {
    font-size: 1rem;
    padding: 0.5rem;
    text-align: center;
    color: ${colors.grayScale[3]};
  }
`;

const NoMitigations = styled.div`
  border-top: solid 1px ${colors.grayScale[1]};
  width: 100%;

  h4 {
    margin-top: 40px;
    text-align: center;
  }
`;

const LowerInsurance = ({ property }) => {
  const provider = useProvider('LowerInsurance');
  const locale = useLocale();

  const renderOptions = () => {
    const options = [];
    const elevateBuilding = ElevateBuildingHelper.showMitigation(property);
    const abandonFirstFloor = AbandonFirstFloorHelper.showMitigation(property);
    const elevateMechanicals = ElevateMechanicalsHelper.showMitigation(
      property
    );
    const floodVents = FloodVentsHelper.showMitigation(property);
    const fillInBasement = FillInYourBasementHelper.showMitigation(property);

    if (elevateBuilding) {
      options.push(
        <LowerInsuranceOption
          key="elevate"
          linkUrl={`/${locale}/mitigation/elevate`}
          image={elevateIcon}
          property={property}
          provider={provider.elevateBuilding}
          helper={ElevateBuildingHelper}
        />
      );
    }

    if (abandonFirstFloor) {
      options.push(
        <LowerInsuranceOption
          key="abandon-first-floor"
          linkUrl={`/${locale}/mitigation/abandon_first_floor`}
          image={abandonFirstFloorIcon}
          property={property}
          provider={provider.abandonFirstFloor}
          helper={AbandonFirstFloorHelper}
        />
      );
    }

    if (elevateMechanicals) {
      options.push(
        <LowerInsuranceOption
          key="elevate-mechanicals"
          linkUrl={`/${locale}/mitigation/elevate_mechanicals`}
          image={elevateMechanicalsIcon}
          property={property}
          provider={provider.elevateMechanicals}
          helper={ElevateMechanicalsHelper}
        />
      );
    }

    if (floodVents) {
      options.push(
        <LowerInsuranceOption
          key="flood-vents"
          linkUrl={`/${locale}/mitigation/flood_vents`}
          image={floodVentsIcon}
          property={property}
          provider={provider.floodVents}
          helper={FloodVentsHelper}
        />
      );
    }

    if (fillInBasement) {
      options.push(
        <LowerInsuranceOption
          key="fill-in-basement"
          linkUrl={`/${locale}/mitigation/fill_in_basement`}
          image={fillInBasementIcon}
          property={property}
          provider={provider.fillInBasement}
          helper={FillInYourBasementHelper}
        />
      );
    }

    if (options.length === 0) {
      options.push(
        <NoMitigations key="no-mitigations">
          <h4>{provider.noMitigation}</h4>
        </NoMitigations>
      );
    } else {
      options.push(
        <Disclaimers key="disclaimers">
          <p>{provider.assumingMax}</p>
          <p>
            {provider.numbersAreEstimates}
            <a
              target="_blank"
              rel="noreferrer noopener"
              href={`/${locale}/terms`}
            >
              {provider.termsAndConditions}
            </a>
          </p>
        </Disclaimers>
      );
    }

    return options;
  };

  return (
    <CallToAction>
      <div>
        <Header>
          <h4>{provider.header}</h4>
          <a
            target="_blank"
            rel="noreferrer noopener"
            href={`/${locale}/flood-retrofits#mitigation-options`}
          >
            {provider.link}
          </a>
        </Header>
        {renderOptions()}
      </div>
    </CallToAction>
  );
};

LowerInsurance.propTypes = {
  property: PropTypes.object.isRequired
};

export default LowerInsurance;
