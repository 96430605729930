import styled, { css, keyframes } from 'styled-components';

const shake = keyframes`
  15%, 90% {
    transform: translateX(-1px);
  }

  30%, 75% {
    transform: translateX(2px);
  }

  45% {
    transform: translateX(-4px);
  }

  60% {
    transform: translateX(4px);
  }
`;

const animation = css`
  animation: ${shake} 0.3s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
`;

const Popup = styled.div`
  font-size: 1rem;
  position: absolute;
  bottom: -5.2rem;
  right: 0;
  border-radius: 3px;
  width: max-content;
  padding: 1rem;
  background-color: white;
  box-shadow: ${(props) => (props.isInNavBar ? '0 0 30px gray' : 'none')};
  ${(props) => props.animate && animation}

  &:before {
    content: '';
    position: absolute;
    bottom: 100%;
    right: 10%;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-bottom: 20px solid white;
    border-right: 10px solid transparent;
  }
`;

export default Popup;
