import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import RoundIcon from './RoundIcon';
import PrimaryAndAsideContainer from './layout/PrimaryAndAsideContainer';
import backwaterOverview from '../images/backwater-overview.svg';
import backwaterValveGif from '../images/backwater-valve.gif';
import damageAvoided from '../images/damage-avoided.svg';
import phone from '../images/phone.svg';
import { colors } from '../styles/variables';
import {
  HeaderSection,
  Subheader,
  Section,
  SectionDescription
} from './ui/StyledResourcePageElements';
import { useProvider, useFaq } from '../helpers/hooks';

const Container = styled.div`
  p {
    max-width: 800px;
  }
`;

const HomeDiagram = styled.aside`
  margin-left: 1rem;

  img {
    width: 352px;

    @media (max-width: 1100px) {
      width: 100%;
      max-width: 352px;
    }
  }

  @media (max-width: 1100px) {
    margin: 3rem 1rem 0 1rem;
  }
`;

const BigNumber = styled.p`
  font-size: 3.5rem;
  font-family: GT-Walsheim-Pro-Bold;
`;

const DamageAvoided = styled.aside`
  margin-left: 1rem;
  background-color: ${colors.grayScale[0]};
  max-width: 352px;
  min-width: 352px;
  height: fit-content;

  p {
    padding: 1rem;
  }

  @media (max-width: 1100px) {
    margin: 3rem 1rem 0 1rem;
  }
`;

const CostAside = styled.aside`
  margin-left: 1rem;
  background-color: ${colors.grayScale[0]};
  max-width: 352px;
  min-width: 352px;
  padding: 2rem;
  height: fit-content;

  h2 {
    font-size: 3rem;
  }

  @media (max-width: 1100px) {
    margin: 3rem 1rem 0 1rem;

    h2,
    p {
      padding-left: 2rem;
    }
  }
`;

const Content = styled.div`
  margin-left: 3rem;

  @media (max-width: 1100px) {
    margin: 3rem 0 0 0;
  }
`;

const ContentWithIcon = styled.div`
  display: flex;

  @media (max-width: 1100px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

const Popup = styled.div`
  border: 1px solid ${colors.grayScale[1]};
  padding: 1rem;
  margin-top: 2rem;
  max-width: 800px;
`;

const PopupAnswer = styled.li`
  margin-top: 1rem;
`;

const BackwaterValve = () => {
  const provider = useProvider('backwaterValve');
  const { setFaqZone } = useFaq();

  useEffect(() => {
    setFaqZone('mitigation-backwater_valve');
  }, []);

  return (
    <>
      <Helmet>
        <title>{provider.meta.title}</title>
        <link rel="canonical" href={provider.meta.url} />
        <meta name="description" content={provider.meta.description} />
        <meta property="og:title" content={provider.meta.title} />
        <meta property="og:description" content={provider.meta.description} />
        <meta property="og:url" content={provider.meta.url} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Container>
        <HeaderSection>
          <h1>{provider.title}</h1>
          <Subheader>{provider.subtitle}</Subheader>
        </HeaderSection>
        <Section>
          <PrimaryAndAsideContainer>
            <div>
              <h5>{provider.overview.header}</h5>
              <SectionDescription>{provider.overview.copy}</SectionDescription>
            </div>
            <HomeDiagram>
              <img alt="Backwater Overview" src={backwaterOverview} />
            </HomeDiagram>
          </PrimaryAndAsideContainer>
        </Section>
        <Section>
          <PrimaryAndAsideContainer>
            <div>
              <h5>{provider.howItWorksHeader}</h5>
              <SectionDescription>
                {provider.howItWorks.paragraph1}
              </SectionDescription>
              <SectionDescription>
                {provider.howItWorks.paragraph2}
              </SectionDescription>
            </div>
            <HomeDiagram>
              <img
                src={backwaterValveGif}
                alt={provider.backwaterValveGifAlt}
              />
            </HomeDiagram>
          </PrimaryAndAsideContainer>
        </Section>
        <Section>
          <PrimaryAndAsideContainer>
            <div>
              <h5>{provider.affect.header}</h5>
              <SectionDescription>
                <b>{provider.affect.moneySavedSubheader}</b>
              </SectionDescription>
              <BigNumber>{provider.affect.bigNumber}</BigNumber>
              <SectionDescription>
                {provider.affect.moneySavedCopy}
              </SectionDescription>
            </div>
            <DamageAvoided>
              <p>
                <b>{provider.affect.damageAvoidedHeader}</b>
              </p>
              <figure>
                <img alt="Damage Avoided" src={damageAvoided} />
              </figure>
              <p>{provider.affect.damageAvoidedCopy}</p>
            </DamageAvoided>
          </PrimaryAndAsideContainer>
        </Section>
        <Section>
          <PrimaryAndAsideContainer>
            <div>
              <h5>{provider.whatItTakes.header}</h5>
              <SectionDescription>
                {provider.whatItTakes.paragraph1}
              </SectionDescription>
              <SectionDescription>
                {provider.whatItTakes.paragraph2}
              </SectionDescription>
            </div>
            <CostAside>
              <h2>{provider.whatItTakes.cost}</h2>
              <p>{provider.whatItTakes.costCopy}</p>
            </CostAside>
          </PrimaryAndAsideContainer>
        </Section>
        <Section>
          <h5>{provider.whatYouCanDoNext.header}</h5>
          <ContentWithIcon>
            <RoundIcon src={phone} />
            <Content>
              <h3>{provider.whatYouCanDoNext.subheading}</h3>
              <SectionDescription
                dangerouslySetInnerHTML={{
                  __html: provider.whatYouCanDoNext.copy
                }}
              />
              <Popup>
                <p>
                  <b>{provider.whatYouCanDoNext.question}</b>
                </p>
                <ul>
                  <PopupAnswer>{provider.whatYouCanDoNext.answer1}</PopupAnswer>
                  <PopupAnswer>{provider.whatYouCanDoNext.answer2}</PopupAnswer>
                </ul>
              </Popup>
            </Content>
          </ContentWithIcon>
        </Section>
      </Container>
    </>
  );
};

export default BackwaterValve;
