import RtappApplicationState from '../constants/RtappApplicationState';

export default {
  progressBar: {
    apply: 'Apply',
    audit: 'Audit',
    review: 'Review',
    install: 'Install',
    complete: 'Complete',
    rejected: 'Ineligible',
    withdrawn: 'Withdrawn'
  },
  [RtappApplicationState.PRE_APPLICATION]: {
    title: "Thank you! We'll be in touch soon."
  },
  [RtappApplicationState.PRE_APPLICATION_APPROVED]: {
    title: 'Complete Your Backwater Valve Application'
  },
  [RtappApplicationState.APPLICATION_SUBMITTED]: {
    title: 'Thanks for Applying!'
  },
  [RtappApplicationState.HOMEOWNER_GRANT]: {
    title: "Congrats! You're eligible!"
  },
  [RtappApplicationState.AUDIT_SCHEDULED]: {
    title: 'Schedule Audit'
  },
  [RtappApplicationState.AUDIT_COMPLETE]: {
    title: 'Audit Complete'
  },
  [RtappApplicationState.FEASIBILITY_REVIEW]: {
    title: 'Feasibility Review'
  },
  [RtappApplicationState.CONSULTATION_SCHEDULED]: {
    title: 'You’ve got a counseling appointment!'
  },
  [RtappApplicationState.CONSULTATION_COMPLETE]: {
    title: 'Let’s keep going'
  },
  [RtappApplicationState.INSTALLATION_PREPARE]: {
    title: 'We’re assembling your team'
  },
  [RtappApplicationState.MEET_YOUR_TEAM]: {
    title: 'Meet Your Team'
  },
  [RtappApplicationState.INSTALLATION_TO_BE_SCHEDULED]: {
    title: 'Schedule Your Installation'
  },
  [RtappApplicationState.INSTALLATION_HAS_BEEN_SCHEDULED]: {
    title: 'Schedule Your Installation'
  },
  [RtappApplicationState.INSTALLATION_COMPLETE]: {
    title: 'Your installation has been scheduled'
  },
  [RtappApplicationState.INSTALLATION_COMPLETE]: {
    title: 'Installation Complete!'
  },
  [RtappApplicationState.REPORT_COMPLETE]: {
    title: 'Final Report'
  },
  [RtappApplicationState.REJECTED]: {
    title: 'Sorry, you aren’t eligible'
  },
  [RtappApplicationState.WITHDRAWN]: {
    title: 'Your application has been withdrawn'
  }
};
