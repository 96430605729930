import waterDroplet from '../images/water-droplet.svg';
import umbrella from '../images/umbrella.svg';
import piggyBank from '../images/piggy-bank.svg';
import moneyHouse from '../images/money-house.svg';
import couchInHouse from '../images/couch-in-house.svg';
import policyAlert from '../images/policy-alert.svg';
import checklist from '../images/checklist.svg';
import premiumChanges from '../images/premium-changes.svg';

export default {
  meta: {
    title: '了解洪水保险 | FloodHelpNY.org',
    url: 'https://www.floodhelpny.org/zh-CN/understanding-flood-insurance',
    description: `洪水保险很复杂。我们在这里帮助您理解。`,
    ogDescription:
      '试图自己计算洪水保险可能会令人沮丧。但我们会帮忙您计算。”',
    imageAlt: '波浪'
  },
  title: '了解洪水保险',
  subHeading:
    '试图自己计算洪水保险可能会令人沮丧。我们为您提供帮助!',
  inANutshell: {
    header: '在 Nutshell',
    items: [
      {
        header: '标准保险与洪水保险不同',
        body:
          '房主、租房者和商业保险不包括洪水。',
        image: waterDroplet,
        imageAlt: '水滴'
      },
      {
        header: '您可能需要购买洪水保险',
        body: '有些房主需要洪水保险。',
        image: umbrella,
        imageAlt: '雨伞'
      },
      {
        header: '利用洪水保险省钱',
        body:
          '这可能是在洪水发生时维持财务状况良好的唯一最重要的工具。',
        image: piggyBank,
        imageAlt: '存钱罐'
      }
    ]
  },
  whatIsIt: {
    header: '这是什么计划？',
    body: {
      p1:
        '国家洪水保险计划是一项由联邦政府支持的计划，始于1968年。当时，私人公司不愿承担洪水造成的损害风险，因此国会设立了NFIP，为建筑业主提供负担得起的洪水保险。纽约市于1983年加入了该计划。',
      p2:
        'NFIP由联邦应急管理局和私人保险公司管理。尽管这些公司出售洪水保险，但所有费率都由FEMA标准化。也就是说您不需要去其他地方购买保险，但您应该检查费率的准确性——毕竟，经纪人会犯错。'
    },
    aside: {
      header: 'NFIP',
      body:
        'NFIP使参与社区的房屋业主能够购买洪水损失保险，并要求为高风险洪水区建筑物担保的所有抵押贷款或信贷额度提供洪水保险。'
    }
  },
  floodInsuranceCover: {
    header: '洪水保险承保的范围是什么？',
    subheader:
      '有两种覆盖：建筑和物品。您可以选择单独的保额和免赔额。',
    items: [
      {
        header: '建筑承保',
        body:
          '仅对结构进行保险，而不对物品或景观投保。不包括地下室的饰面。',
        image: moneyHouse,
        imageAlt: '带美元符号的房屋'
      },
      {
        header: '物品承保范围',
        body:
          '为您的物品投保（但地下室或最低生活楼层以下的大部分物品除外）。',
        image: couchInHouse,
        imageAlt: '带沙发的房子'
      }
    ],
    aside: {
      header: '例外',
      body:
        '不过，也有一些例外：冰箱、烤箱和洗碗机属于建筑洪水保险，而洗衣机和烘干机则被视为物品。'
    }
  },
  shopping: {
    icon: checklist,
    iconAlt: '清单',
    header: '了解如何获得最佳保单来保护您的房屋。',
    buttonText: '立即阅读'
  },
  myOptions: {
    header: '我可以选择如何投保？',
    body:
      '您可以为结构购买的最高保额为250000美元，为物品购买的最高保额为100000美元。',
    aside: {
      header: '小企业主？',
      link: '了解更多信息',
      body:
        ' 关于洪水风险如何影响您的运营，以及您的应对措施。'
    }
  },
  floodInsurancePriced: {
    header: '洪水保险的定价方式',
    body:
      '2021 10月1日，联邦应急管理局推出了新的洪水保险定价，称为风险评级2.0，更准确地反映洪水风险。在新系统下，FEMA将不再依赖洪水图来计算洪水保费，而是使用几个变量来计算保费，例如到洪水源的距离和建筑类型。有关完整列表，请查看常见问题解答。'
  },
  premiums: {
    icon: premiumChanges,
    iconAlt: '风险评级2.0将导致保费变化',
    body:
      '风险评级2.0将导致全国范围内许多人的保费都出现变化。某些保单的保费可能会降低；一些人的保费可能上涨。对于在风险等级2.0以下增加的保单，联邦法律要求联邦应急管理局每年增加的保单不得超过18%。在纽约市，FEMA估计，如果实行风险评级2.0，约61%的纽约市居民的保费将会上涨。'
  },
  understandingMaps: {
    header: '了解洪水保险地图',
    sub_heading:
      '重要的是要记住，虽然洪水地图不再是定价的一部分，但仍然用于确定您是否需要购买洪水保险。',
    severeRiskZone: '最高风险',
    highRiskZone: '高风险',
    moderateRiskZone: '中等风险'
  },
  aboutTheZones: {
    header: '关于区域',
    body: {
      p1:
        '在高风险洪水区（AE、AO和VE），如果拥有联邦担保的抵押贷款，业主必须购买洪水保险。此外，因洪水损失而获得联邦灾难援助的财产所有者必须购买洪水保险，有资格获得未来援助。'
    },
    aside: { header: '发现风险', link: '查找区域' }
  },
  subsidizedRateFullRate: {
    header: '补贴费率与完全风险费率',
    body:
      '在高风险洪水区，有两种不同类型的保险费率：FIRM前补贴费率和标准费率。FIRM之前的补贴率仅适用于1983年11月16日之前建成的房屋业主（“FIRM之前”，因为房屋是在1983年FIRM之前建成的）。该补贴费率可能低于标准费率；然而，由于补贴正在减少，许多房产的标准房价实际上较低。',
    aside: {
      header: '年度保险增长',
      body:
        '由于最近的两项联邦立法，2012年的《Biggert水域法案》和2014年的《住房保险可负担性法案》，补贴费率每年增长15-18%。'
    }
  },
  newHomeowners: {
    header: '新房主',
    body:
      '购买新房时，请与卖家核实他们是否有洪水保险单。在房屋售出后，保单持有人可以将保单（以及折扣）转让给新业主。',
    aside: {
      header: '地图修订函',
      body:
        '如果您的住宅位于高风险洪水区，但周围的地面高程高于BFE，您可以向FEMA提交地图修正书或LOMA，这是将您的财产排除在洪水区之外的请求。您需要一份标高证明。'
    }
  },
  whyMapsChanging: {
    header: '纽约市的地图正在改变',
    body:
      '联邦应急管理局定期更全国洪水危险区地图。纽约市目前的地图是1983年采用的，并不能准确反映目前的洪水风险。目前，洪水保险费率地图不知道明确的生效时间，也不清楚地图最终将采用何种形式。我们最好的估计是，在未来两到五年内发布新地图。',
    aside: {
      header: '纽约面临的挑战',
      body_before:
        '2015年6月，纽约市对联邦应急管理局的初步洪水保险费率图提出上诉，称他们高估了五个行政区的洪水风险。2016年10月，联邦应急管理局宣布接受了纽约市的 ',
      link: '申诉',
      body_after: ' 并将根据新的数据分析修改地图。'
    }
  },
  learnMore: {
    body: '想了解更多信息吗？查看我们的文章 ',
    link: '洪水上涨，费用升高',
    href: 'https://cnycn.atavist.com/risingtides'
  }
};
