import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from '../styles/variables';
import SalesforceCommunicator from '../communicators/SalesforceCommunicator';
import RiskInfo from './RiskInfo';
import ContactUs from './ContactUs';
import FloodZoneInfo from './FloodZoneInfo';
import ProfileWhatYouCanDo from './ProfileWhatYouCanDo';
import ProfileWhatToKnow from './ProfileWhatToKnow';
import StaticMap from './StaticMap';
import OtherResources from './OtherResources';
import Error from './layout/Error';
import Loading from './sharedComponents/Loading';
import { useApi, useProvider, useLocale, useFaq } from '../helpers/hooks';

const Container = styled.div`
  margin-top: 120px;
`;

const BackLinkWrapper = styled.div`
  margin-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  span {
    color: ${colors.resilientBlue};
    font-size: 2rem;
    margin-right: 1rem;
  }
`;

const Profile = () => {
  const provider = useProvider('Profile');
  const locale = useLocale();
  const { setFaqZone } = useFaq();

  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [property, setProperty] = useState({});
  const { salesforceApi } = useApi();
  const { propertyId } = useParams();

  useEffect(() => {
    const zone = property.small_business ? 'small-business' : 'profile';
    setFaqZone(zone);
  }, [property, setFaqZone]);

  const handlePropertyInfo = (data) => {
    // Merge property and property_details data into one object
    setProperty({ ...data.property, ...data.property_details });
    setIsLoading(false);
  };

  useEffect(() => {
    const getPropertyInfo = async () => {
      try {
        // Returns an object: { property: {}, property_details: {} }
        const result = await SalesforceCommunicator.getPropertyInfo(
          salesforceApi,
          propertyId
        );
        handlePropertyInfo(result);
      } catch (error) {
        setHasError(true);
        setIsLoading(false);
      }
    };
    getPropertyInfo();
  }, [propertyId, salesforceApi]);

  const renderBackToAuditStatus = () => {
    if (property.kase_externalid) {
      return (
        <BackLinkWrapper>
          <span>‹</span>
          <a href={`/${locale}/audit-status/${property.kase_externalid}`}>
            {provider.backToApplicationLink}
          </a>
        </BackLinkWrapper>
      );
    }
    return null;
  };

  const renderFloodZoneInfo = () => {
    if (
      property.current_flood_zone !== 'None' ||
      property.future_flood_zone !== 'None'
    ) {
      return <FloodZoneInfo property={property} />;
    }
    return null;
  };

  if (isLoading) {
    return <Loading provider={provider} height="500px" />;
  }

  if (hasError) {
    return <Error provider={provider} height="50%" />;
  }

  return (
    <>
      <Helmet>
        <title>{provider.meta.title}</title>
        <link rel="canonical" href={provider.meta.url} />
        <meta name="robots" content="noindex" />
        <meta property="og:title" content={provider.meta.title} />
        <meta property="og:url" content={provider.meta.url} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Container>
        <StaticMap address={property.combined_address} />
        {renderBackToAuditStatus()}
        <RiskInfo property={property} />
        {renderFloodZoneInfo()}
        <ProfileWhatToKnow
          provider={provider.whatToKnowProvider}
          property={property}
        />
        <ProfileWhatYouCanDo property={property} />
        {!property.small_business && (
          <ContactUs smallBusiness={property.small_business} />
        )}
        <OtherResources />
      </Container>
    </>
  );
};

export default Profile;
