import lookupErrorText from './LookupErrors.es';
import arrow from '../images/arrow.svg';

export default {
  icon: arrow,
  loadingText: '',
  minimalHazardZone: 'This address is in a minimal hazard flood zone.',
  bfe: '" Base Flood Elevation (BFE)',
  button: 'Learn More',
  X: 'moderate risk flood zone',
  AE: 'high risk zone',
  VE: 'very high risk zone',
  None: 'minimal hazard zone',
  tryAnotherAddress: 'Please try another address.',
  errorText:
    'Lo sentimos. No hemos podido encontrar información con respecto a la dirección que ingresó. Por favor, verifique la dirección e inténtelo nuevamente.',
  currentlyIn: 'Esta propiedad se encuentra actualmente en un ',
  changingTo: ', pero es probable que cambie a una ',
  stayingAt: ', y es probable que se mantenga en el mismo nivel de riesgo.',
  ...lookupErrorText
};
