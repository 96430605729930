import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  height: ${(props) => props.height};
  justify-content: center;
  align-items: center;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const Content = styled.p`
  font-size: 2rem;

  @media (max-width: 600px) {
    margin-bottom: 20px;
  }
`;

const Spinner = styled.div`
  position: relative;
  margin: ${(props) => props.margin};
  text-align: center;

  &::before {
    animation: 2s linear infinite spinner;
    border: solid 3px #eee;
    border-bottom-color: #ef6565;
    border-radius: 50%;
    content: '';
    height: ${(props) => props.spinnerSize};
    width: ${(props) => props.spinnerSize};
    left: ${(props) => (props.text ? 'calc(110px + 50%)' : '50%')};
    position: absolute;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    transform-origin: center;
    will-change: transform;

    @media (max-width: 600px) {
      left: 0;
    }
  }

  @keyframes spinner {
    0% {
      transform: translate3d(-50%, -50%, 0) rotate(0deg);
    }
    100% {
      transform: translate3d(-50%, -50%, 0) rotate(360deg);
    }
  }
`;

const Loading = ({ provider, margin, height, spinnerSize }) => (
  <Container height={height}>
    {provider.loadingText && <Content> {provider.loadingText} </Content>}
    <Spinner
      margin={margin}
      spinnerSize={spinnerSize}
      text={provider.loadingText}
      role="img"
      aria-label="Loading spinner"
    />
  </Container>
);

Loading.propTypes = {
  provider: PropTypes.object,
  height: PropTypes.string,
  margin: PropTypes.string,
  spinnerSize: PropTypes.string
};

Loading.defaultProps = {
  provider: { loadingText: '' },
  height: '40px',
  margin: '5rem',
  spinnerSize: '3rem'
};

export default Loading;
