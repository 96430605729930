import handsHeart from '../images/hands-heart.png';
import buildingFlood from '../images/building-flood.png';
import floodNet from '../images/flood-net.png';
import homeAutomation from '../images/home-automation.svg';
import rainCloud from '../images/rain-cloud.png';
import storm from '../images/storm.svg';
import stormwaterFlooding from '../images/stormwater-flooding.svg';
import streetFlood from '../images/street-flood.jpeg';

export default {
  meta: {
    title: 'Climate Change in NYC | Flooding | FloodHelpNY',
    url: 'https://www.floodhelpny.org/en/climate-change',
    description:
      'Climate change has already made flooding worse in New York City. Here’s what you need to know.',
    imageAlt: 'waves'
  },
  title: 'Climate Change',
  subHeading:
    'Climate change has already made flooding worse in New York City. Here’s what you need to know.',
  overview: {
    header: 'Overview',
    body:
      'Sea levels in New York City are rising nearly twice as fast as the global average. Fierce hurricanes churning out of the Atlantic Ocean threaten to pummel the East Coast each year. And increasingly frequent major storms can, at times, produce destructive and even lethal flooding. These extreme weather patterns mean cities like ours need to learn to prepare and adapt to the new normal of the climate crisis.',
    aside: {
      body:
        'Not everyone is affected equally by the climate crisis. Communities of color are more likely to bear the brunt of the effects of sea level rise and flooding.',
      icon: buildingFlood,
      iconAlt: 'Flooding Building'
    },
    image: streetFlood,
    imageAlt: 'Flooded street of New York City',
    imageCaption: 'Courtesy of the Community Flood Watch Project'
  },
  howClimateChangeContributes: {
    header: 'How climate change contributes to increased flooding',
    body: {
      p1:
        'Around the globe, rising sea levels will continue to increase the frequency and intensity of coastal flooding. Flooding is considered to be one of the most common and costliest natural disasters, killing more people each year than tornados. As climate change is expected to worsen, so is flooding in our communities.',
      p2: 'Here’s how climate change is affecting flooding in NYC:',
      p3:
        'These are by no means the only connections between climate change and flooding, but they are critical to understanding how to protect our homes, our families, and our neighborhoods.'
    },
    items: [
      {
        header: 'Because of global warming, when it rains, it pours more',
        body:
          'Since 1900, the Earth has become 4 percent wetter, with the eastern half of the United States growing soggiest.',
        image: rainCloud,
        imageAlt: 'Rain Cloud'
      },
      {
        header: 'Stronger storms bring greater rains and winds',
        body:
          'The most destructive types of hurricanes are increasing in frequency, including in the Atlantic Ocean.',
        image: storm,
        imageAlt: 'Lightning storm'
      },
      {
        header: 'Rising sea levels',
        body:
          'Projections show that the East Coast could experience seas as much as 6.5 feet higher by 2100.',
        image: stormwaterFlooding,
        imageAlt: 'Stormwater Flooding'
      }
    ]
  },
  climateCrisis: {
    title: 'Climate action is possible',
    body:
      'The Community Flood Watch Project is a growing network of residents and organizations who report local flooding, share experiences, and access resources related to flooding in NYC coastal communities. Communities can use this shared database of images, reports and maps to communicate their needs and visions to city leaders. You can get involved, too!',
    view: 'Get Involved',
    image: handsHeart,
    imageAlt: 'Coastal Climate Resiliency Guide'
  },
  climateChangeFlooding: {
    header: 'Climate change-driven flooding affects the city unequally',
    body:
      'Throughout the country, neighborhoods that have been historically redlined and marginalized through predatory lending and zoning practices have also been at an elevated risk of exposure to climate-related sea-level rising and intense flooding. According to RedFin, 13.8% of homes in formerly redlined and yellow-lined neighborhoods, with the majority of residents people of color, are at a higher risk of flooding than other New York City neighborhoods. Investments in infrastructure, such as sewers to combat increased flooding, were prevalent in more affluent communities in New York City rather than formerly redlined neighborhoods. Congress and the Environmental Protection Agency (EPA) are continuing to work to address climate issues that disproportionately impact communities of color, by ensuring environmental justice is achieved for all people, regardless of race, gender, and income.'
  },
  severeHurricanes: {
    header: 'From severe hurricanes to extreme rainfall',
    body: {
      p1:
        'New York City residents have experienced severe storms as a result of increasing climate change, including a once-in-a-lifetime storm like Hurricane Sandy in 2012, and most recently, Hurricanes Henri and Ida in 2021. During Hurricane Ida, the National Weather Service issued its first-ever “flash flood emergency” for the five boroughs. At least 40 people in the Northeast were killed by the storm, including 13 in New York City, mostly in flooded basement apartments.',
      p2:
        'Soon after the storm, a report issued by the Mayor’s office highlighted the connection between extreme rainfall and climate change: “Increasingly, these extreme weather events are the new normal: part of an undeniable climate crisis that stretches across our nation … It is here, it is real, and it is taking lives,” the report’s authors wrote.',
      p3: `While the NYC Mayor's Office of Climate & Environmental Justice and its City agency partners continue efforts to mobilize communities and prepare for more severe storms, it’s going to take all of us to protect our neighborhoods and homes.`
    },
    aside: {
      body:
        'FloodNet is a cooperative of communities, researchers, and New York City government agencies working to better understand the frequency, severity, and impacts of flooding in New York City. The data and knowledge gained can be used by local residents, researchers, city agencies, and others to advocate around and work to reduce flood risk.',
      link: 'Visit FloodNet',
      icon: floodNet,
      iconAlt: 'Floodnet.nyc icon'
    },
    callToAction: {
      header:
        'Learn about how you can take action to protect your property through retrofits',
      icon: homeAutomation,
      iconAlt: 'Home retrofits',
      buttonText: 'Read More'
    }
  }
};
