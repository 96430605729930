export default {
  header: 'Flood Insurance Estimate',
  beta: 'BETA',
  link: 'See your full estimate',
  currentMapStart: 'Current maps ( ',
  currentMapEnd: ' zone)',
  futureMapStart: 'Advisory maps (',
  futureMapEnd: ' zone)',
  assumingMax: '*Assuming max coverage',
  numbersAreEstimates:
    'These numbers are estimates for many reasons, to learn more about them go to our ',
  termsAndConditions: 'Terms & Conditions',
  floodZoneRateProvider: {
    perYear: 'Per year*'
  },
  rateAdjusterProvider: {
    adjustYourCoverage: 'Adjust your coverage ',
    structuralCoverage: 'Structural coverage',
    structuralDeductible: 'Structural deductible',
    contentCoverage: 'Contents coverage',
    contentDeductible: 'Contents deductible',
    collapse: 'Collapse'
  }
};
