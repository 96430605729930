import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import LandingHero from './LandingHero';
import DidYouKnow from './DidYouKnow';
import ReduceYourRisk from './ReduceYourRisk';
import EmergencyNotification from './EmergencyNotification';
import AboutFHNY from './AboutFHNY';
import Modal from './layout/Modal';
import { useProvider } from '../helpers/hooks';

const Landing = () => {
  const [isModalActive, setIsModalActive] = useState(false);
  const [modalChildren, setModalChildren] = useState(false);
  const provider = useProvider('Landing');

  return (
    <>
      <Helmet>
        <meta name="description" content={provider.meta.description} />
        <meta property="og:description" content={provider.meta.description} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image:alt" content={provider.meta.imageAlt} />
      </Helmet>
      {isModalActive && (
        <Modal isModalActive={isModalActive}>{modalChildren}</Modal>
      )}
      <LandingHero />
      <ReduceYourRisk />
      <DidYouKnow />
      <AboutFHNY />
    </>
  );
};

export default Landing;
