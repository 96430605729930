import handsHeart from '../images/hands-heart.png';
import buildingFlood from '../images/building-flood.png';
import floodNet from '../images/flood-net.png';
import homeAutomation from '../images/home-automation.svg';
import rainCloud from '../images/rain-cloud.png';
import storm from '../images/storm.svg';
import stormwaterFlooding from '../images/stormwater-flooding.svg';
import streetFlood from '../images/street-flood.jpeg';

export default {
  meta: {
    title: 'Изменение климата в городе Нью-Йорк | Наводнение | FloodHelpNY',
    url: 'https://www.floodhelpny.org/ru/climate-change',
    description:
      'Изменение климата уже повлияло на ухудшение ситуации с наводнениями в городе Нью-Йорк. Вот что вам нужно знать.',
    imageAlt: 'волны'
  },
  title: 'Изменение климата',
  subHeading:
    'Изменение климата уже повлияло на ухудшение ситуации с наводнениями в городе Нью-Йорк. Вот что вам нужно знать.',
  overview: {
    header: 'Обзор',
    body:
      'В Нью-Йорке уровень моря повышается почти в два раза быстрее, чем среднемировой показатель. Свирепые ураганы, исходящие как с конвейера из Атлантического океана, угрожают обрушиваться на восточное побережье каждый год. И всё более учащающиеся масштабные бури могут временами вызывать разрушительные и даже летальные наводнения. Эти экстремальные погодные условия означают, что городам, таким как наш, необходимо научиться готовиться и адаптироваться к условиям новой реальности климатического кризиса.',
    aside: {
      body:
        'Не на всех климатический кризис влияет одинаково. Районы с цветным населением с большей долей вероятности принимают на себя удар воздействия повышения уровня моря и наводнений.',
      icon: buildingFlood,
      iconAlt: 'Затопленное здание'
    },
    image: streetFlood,
    imageAlt: 'Наводненная улица Нью-Йорка',
    imageCaption: 'Предоставлено Общественным проектом наблюдения за наводнениями'
  },
  howClimateChangeContributes: {
    header: 'Как изменение климата способствует росту наводнений',
    body: {
      p1:
        'Повсему миру растущий уровень моря будет продолжать повышать частоту и интенсивность прибрежных наводнений. Наводнения считаются наиболее распространенными и дорогостоящими стихийными бедствиями, убивающими ежегодно больше людей, чем торнадо. Так как ожидается, что изменение климата будет ухудшаться, это же касается и наводнений в наших районах.',
      p2: 'Вот как изменение климата влияет на наводнения в Нью-Йорке:',
      p3:
        'Это ни в коем случае не является единственной связью между изменением климата и наводнениями, но это очень важно для понимания как защитить наши дома, семьи и сообщества.'
    },
    items: [
      {
        header: 'Из-за глобального потепления когда идет дождь, он льет как из ведра еще больше',
        body:
          'С 1990-го года земля стала на 4 процента мокрее, причем восточная часть США становится наиболее пропитанной водой.',
        image: rainCloud,
        imageAlt: 'Дождевое облако'
      },
      {
        header: 'Более сильные ливневые бури приносят более сильные дожди и ветры',
        body:
          'Наиболее разрушительные виды ураганов стоновятся более частыми, включая Атлантический океан.',
        image: storm,
        imageAlt: 'Гроза'
      },
      {
        header: 'Повышающийся уровень моря',
        body:
          'Прогнозы показывают, что на восточном побережье море может подняться на 6,5 футов к 2100-му году.',
        image: stormwaterFlooding,
        imageAlt: 'Наводнение из-за ливневых вод'
      }
    ]
  },
  climateCrisis: {
    title: 'Действия по улучшению климата возможны',
    body:
      'Общественный проект наблюдения за наводнениями - это растущая сеть жителей и организаций, предоставляющих отчеты о местных наводнениях, делящихся своим опытом и доступом к ресурсам о наводнениях в прибрежных районах Нью-Йорка. Сообщества могут использовать эту общую базу  данных фотографий, отчетов и карт, чтобы уведомить градоначальников о своих нуждах и видении. Вы также можете быть задействованы!',
    view: 'Примите участие',
    image: handsHeart,
    imageAlt: 'Пособие по противостоянию приморскому климату'
  },
  climateChangeFlooding: {
    header: 'Вызванные изменением климата наводнения влияют на городское неравенство',
    body:
      'По всей стране районы, которые исторически были в красной зоне и лишены всяческих прав из-за хищнического кредитования и территорального зонирования, также попадают под повышенный риск быть подверженным вызванного изменением климата повышению уровня моря и сильным наводнениям. Согласно RedFin 13,8% домов в районах бывшей красной и желтой зоны, где проживает большинство цветного населения, находятся в более высокой группе риска наводнения, чем другие районы Нью-Йорка. Инвестиции в инфраструктуру, такую как канализация, чтобы противостоять наводнениям, преобладают в более зажиточных районах Нью-Йорка по сравнению с районами бывшей красной зоны. Конгресс и Агентство по охране окружающей среды (EPA) продолжают работать над решением проблемы климата, которая оказвает непропорциональное  влияние на цветные сообщества путем обеспеченя экологической справедливости для всех, вне зависимости от расы, цвета и дохода.'
  },
  severeHurricanes: {
    header: 'От мощных ураганов до экстремальных дождей',
    body: {
      p1:
        'Жители города Нью-Йорк испытали на себе сильные бури в результате роста изменения климата, включая незабываемый шторм типа урагана Сэнди в 2012-ом году и совсем недавние ураганы Генри и Ида в 2021-ом году. Во время урагана Ида Национальная метеорологическая служба объявила свою первую "чрезвычайную ситуацию из-за внезапного паводка" для пяти административых округов. По крайней мере 40 человек на северо-востоке погибли в результате бури, включая 13 в Нью-Йорке, в основном в затопленных подвалах квартир.',
      p2:
        'Вскоре после бури отчет, выпущенный секретариатом мэра, подчеркнул связь между экстремальными дождями и изменением климата: "Все больше и больше эти экстремальные погодные события становятся новой нормой: элемент неоспоримого климатического кризиса, простирающегося через всю страну... Он здесь, он реален, и он отнимает жизни", написал автор отчета.',
      p3: `В то время как секретариат мэра города Нью-Йорк по климату и экологическому правосудию и его городские агентства-партнеры продолжают прилагать усилия по мобилизации сообществ и подготовке к более сильным бурям, потребуются усилия от всех нас, чтобы защитить наши районы и дома.`
    },
    aside: {
      body:
        'FloodNet - это совместная разработка сообществ, исследователей государственных агентств города Нью-Йорк, нарпавленная на улучшение понимания частотности, силы и степени воздействия наводнений в Нью-Йорке. Полученные данные и знания могут быть использованы местными жителями, исследователями, городскими агентствами и другими для агитации и работы над сокращением риска наводнения.',
      link: 'Посетить сайт FloodNet',
      icon: floodNet,
      iconAlt: 'Floodnet.nyc icon'
    },
    callToAction: {
      header:
        'Узнайте какие меры вы можете предпринять, чтобы защитить вашу собственность путем модификации',
      icon: homeAutomation,
      iconAlt: 'Модификации дома',
      buttonText: 'Читать дальше'
    }
  }
};
