import RtappApplicationState from '../constants/RtappApplicationState';
import worker1 from '../images/worker-1.svg';
import worker2 from '../images/worker-2.svg';
import grantAgreement from '../images/grant-agreement.svg';
import pencilRuler from '../images/pencil-ruler.svg';
import maleUser from '../images/male-user.svg';
import application from '../images/application.svg';
import calendar from '../images/calendar.svg';
import feedback from '../images/feedback.svg';
import piping from '../images/piping.svg';

export default {
  [RtappApplicationState.PRE_APPLICATION]: {
    headerNext: 'Что вы можете сделать дальше',
    headerExpect: 'Чего ожидать',
    callToAction: 'Мы рассматриваем поданную вами информацию',
    body:
      "Предоставьте нашей команде некоторое время, чтобы мы выслали вам полное заявление на программу. Мы свяжемся с вами наискорейшим образом.",
    expect:
      'Если вы соответствуете критериям программы, вы получите сообщение по электронной почте с приглашением заполнить заявление. Вы можете также проверить на веб-сайте изменился ли ваш статус.',
    toProfile: 'Вернуться к моему профилю риска наводнения',
    icon: application
  },
  [RtappApplicationState.PRE_APPLICATION_APPROVED]: {
    callToAction: 'Теперь: Заполнить заявление',
    body:
      'Требуется помощь? Позвоните в центр для районов города Нью-Йорк по телефону 646-786-0888 или пошлите нам сообщение по электронной почте по адресу info@floodhelpny.org. Вы можете задать нам любые вопросы относительно нашей программы.',
    expect:
      'Наша команда усердно работает над рассмотрением всех заявлений и мы надеемся связаться с вами в течение последующих 2-3 недель. Мы понимаем, что придется долго ждать, и мы благодарны вам за ваше терпение! Мы вышлем вам сообщение по элекронной почте как только ваш статус изменится, но вы также можете продолжать проверять наличие обновлений здесь. Если вы предпочитаете заполнить заявление на бумаге, загрузите  формы, предложенные ниже, и подайте их в Центр.',
    buttonText: 'Подать заявку',
    link: 'https://www.floodhelpny.org/samples/auditApplication.pdf',
    linkText: 'Форма заявления',
    link2: 'https://www.floodhelpny.org/samples/tenantIncome.pdf',
    linkText2: 'Форма удостоверения доходов арендатора',
    icon: application
  },
  [RtappApplicationState.APPLICATION_SUBMITTED]: {
    headerNext: 'Что вы можете сделать дальше',
    headerExpect: 'Чего ожидать',
    callToAction: 'Вы сделали первый шаг!',
    body:
      'Мы сейчас рассматриваем ваше заявление на предмет  соответствия критериям и мы свяжемся с вами, если нам потребуется дополнительная информация. У вас есть вопросы? Позвоните в центр для районов города Нью-Йорк по телефону 646-786-0888 или пошлите нам сообщение по электронной почте по адресу info@floodhelpny.org.',
    expect:
      'Если вы будете одобрены на участие в программе, вы получите от нас электронное сообщение, запрашивающее подписание соглашения о безвозмездной ссуде домовладельцу, которое позволит Центру распределить федеральные средства соответствующим критериям домовладельцам.',
    icon: grantAgreement
  },
  [RtappApplicationState.HOMEOWNER_GRANT]: {
    headerExpect: 'Что вы можете сделать дальше',
    callToAction: 'Загрузите и подпишите соглашение о безвозмездной ссуде домовладельцу',
    body:
      'Вы получите копию вашего соглашения о безвозмездной ссуде домовладельцу по электронной почте. Вам нужно будет загрузить и вернуть нам соглашение о безвозмездной ссуде домовладельцу по факсу, электронной почте или почтовой связи. Соглашение должно быть подписано всеми лицами, указанными в акте недвижимости, что позволит Центру распределить федеральные средства соответствующим критериям домовладельцам, участвующим в программе.',
    faq:
      'Чтобы узнать, как вернуть нам соглашение, щелкните по ссылке Часто задаваемые вопросы с правой стороны под заголовком "Возможно вы думаете".',
    contactUs:
      'У вас все еще есть вопросы? Свяжитесь с нами по телефону 646-786-0888 или пошлите нам сообщение по электронной почте по адресу info@floodhelpny.org.',
    expect:
      'После того, как мы получим ваше соглашение о безвозмездной ссуде домовладельцу, оно будет подписано Центром для районов города Нью-Йорк и копия будет послана спонсору программы, в Секретариат губернатора штата Нью-Йорк по восстановлению после ливневых бурь. Как только документация будет рассмотрена, мы назначим вашу инспекцию. Это может занять до 4х недель.',
    icon: grantAgreement
  },
  [RtappApplicationState.REJECTED]: {
    body:
      'К сожалению в настоящий момент вы не соответствуете критериям на получение услуг через эту программу. Однако, вы все еще можете использовать ресурсы на сайте FloodHelpNY.org чтобы проверить ваш риск наводнения и узнать как стать более устойчивыми. У вас есть вопросы относительно вашего заявления? Свяжитесь с нами по телефону 646-786-0888. За дополнительной информацией о критериях соответствия обращайтесь к ссылке Часто задаваемые вопросы с правой стороны.'
  },
  [RtappApplicationState.WITHDRAWN]: {
    body:
      'Спасибо за ваш интерес к Потребительской программе по установке канализационного обратного клапана. Ваше дело было формально изъято из программы. Вы все еще можете проверять ваш риск наводнения и просматривать наши ресурсы по страхованию от наводнения. Если вы хотите продолжить работу с программой, позвоните нам по телефону 646-786-0888 или пошлите нам сообщение по электронной почте по адресу info@floodhelpny.org. Мы возобновим обработку вашего заявления.'
  },
  [RtappApplicationState.FEASIBILITY_REVIEW]: {
    headerNext: 'Что вы можете сделать дальше',
    headerExpect: 'Чего ожидать',
    scheduled: 'Ваш анализ целесообразности назначен',
    body:
      'Инженер, который провел инспекцию устойчивости вашего дома должен вернуться, чтобы получить дополнительную информацию о вашем доме для определения будет ли полезна установка канализационного обратного клапана в вашем доме. Вам нужно будет быть дома, и инженеру потребуется доступ ко всему собственности, включая подвал и открытую зону на улице.',
    icon: pencilRuler,
    expect:
      "В некоторых случаях установка канализационного обратного клапана не принесет пользу вашему дому. Ваш инженер задаст вам несколько вопросов и сделает несколько фотографий и измерений, которые ему понадобятся для принятия решения и, если потребуется, создаст план строительных работ по установке канализационного обратного клапана. Мы надеемся связаться с вами в промежутке от трех до шести недель после того, как инженер закончит анализ целесообразности.",
    noDate:
      'Позвоните нам по телефону, указанному ниже, чтобы подтвердить дату и время вашего анализа целесообразности.'
  },
  [RtappApplicationState.AUDIT_SCHEDULED]: {
    headerNext: 'Что вы можете сделать дальше',
    headerExpect: 'Чего ожидать',
    scheduled: 'Ваша инспекция назначена',
    body:
      'Сделайте все необходимое для того, чтобы быть дома в назначенное время, чтобы позволить инженеру закончить анализ. Вы можете рассчитывать на то, что инспекция займет менее двух часов. Если вы не сможете быть дома во время вашей назначенной инспекции или вам нужно будет отменить ваше посещение на дому, позвоните нам как можно скорее по телефону ',
    phone: '646-786-0888',
    phoneLink: '+6467860888',
    body2: ' в рабочее время, чтобы перенести дату.',
    expect:
      'Инженеру и сопровождающим его работникам потребуется доступ ко всем уголкам вашего дома, включая подвал или полупроходное техническое подполье и задний дворик, чтобы гарантировать, что вы получите точный отчет. Если инженер не появился в вашем доме в течение 30 минут от указанного времени, позвоните нам по телефону 646-786-0888.',
    noDate:
      'Позвоните нам по телефону, указанному ниже, чтобы подтвердить дату и время вашей инспекции.'
  },
  [RtappApplicationState.AUDIT_COMPLETE]: {
    headerNext: 'Что вы можете сделать дальше',
    headerExpect: 'Чего ожидать',
    callToAction: 'Назначьте вашу консультацию',
    body:
      'Кто-то из нашей Центральной станции домовладельцев позвонит вам, чтобы назначить консультацию с консультантом по устойчивости в течение последущих 48 часов. Если у вас все еще будут вопросы относительно программы или расписания, вы можете позвонить одному из наших представителей по телефону ',
    phone: '(646) 786-0888.',
    phoneLink: '+6467860888',
    expect:
      'Консультация будет длиться от 45 минут до часа. За это время один из наших консультантов объяснит вам информацию, соранную инженером во время его визита и обсудит компенсационные меры, которые подходят для вашего дома больше всего. Наши консультанты также обсудят варианты финансирования и помонут вам создать план защиты вашего дома, который вписывается в ваш бюджет.',
    icon: grantAgreement
  },
  [RtappApplicationState.CONSULTATION_SCHEDULED]: {
    headerNext: 'Что вы можете сделать дальше',
    headerExpect: 'Чего ожидать',
    scheduled: 'Ваша консультация назначена',
    body:
      'Консультант обсудит ваш текущий и будущий аск наводнения, а также любые действия, которые вы сможете предпринять на основании оценки инженера. Принесите следующие документы, если таковые имеются: 1) первую страницу полиса страхования от наводнения; 2) старый сертификат высотной отметки, при наличии; и 3) ипотечную декларацию или другие документы, показывающие статус ипотеки на текущий момент ',
    phone: '646-786-0888',
    phoneLink: '+6467860888',
    body2:
      ' или пошлите нам сообщение по электронной почте по адресу info@floodhelpny.org. Также дайте нам знать незамедлительно, если вам нужно будет отменить или перенести',
    expect:
      'Ваш консультант - это специалист в отрасли, который обсудит с вами специфические факторы, определяющие взнос за вашу страховку от наводнения. Он просмотрит ваш сертификат высотной отметки и технический отчет и предоставит руководство к действию на простом языке. Консультирование - это улица с двусторонним движением, вам рекомендуется задавать вопросы! В конце этой встречи у вас появится понимание сколько вам может  стоить страхование от наводнения, правильную ли оценку вы получили, какие существуют общепринятые компенсационные варианты, и будет ли полезна установка в вашем доме канализационного обратного клапана.',
    noDate:
      'Позвоните нам по телефону, указанному ниже, чтобы подтвердить дату и время вашей консультации.'
  },
  [RtappApplicationState.CONSULTATION_COMPLETE]: {
    callToAction: 'Добро пожаловать в программу установки канализационного обратного клапана',
    body:
      "Вы закончили консультацию и получили инспекцию. Теперь пришло время поговорить об установке канализационного обратного клапана. В течение нескольких последующих недель мы ознакомим вас с процессом по его установке.",
    contact:
      "Если вы хотите обсудить программу более детально, вы можете позвонить в Центр по телефону ",
    phone: '646-786-0888',
    phoneLink: '+6467860888',
    contact2: ' или послать нам сообщение по электронной почте по адресу ',
    email: 'info@floodhelpny.org.',
    images: [{ icon: piping, iconAltText: 'Трубопровод' }]
  },
  [RtappApplicationState.INSTALLATION_PREPARE]: {
    callToAction: 'Мы пытаемся найти нужных людей',
    body:
      "Мы собираем команду, чтобы гарантировать, что мы можем предоставить вам услуги наилучшим образом. Мы свяжемся с вами через шесть-восемь недель, чтобы представить вам вашу команду по установке. Между тем, последний вариант вашего отчета представлен ниже.",
    contact: 'Вы можете позвонить нам ',
    phone: '646-786-0888',
    phoneLink: '+6467860888',
    contact2: ' или послать нам сообщение по электронной почте если у вас есть вопросы по адресу ',
    email: 'info@floodhelpny.org.',
    images: [{ icon: pencilRuler, iconAltText: 'Карандаш и линейка' }]
  },
  [RtappApplicationState.MEET_YOUR_TEAM]: {
    headerNext: 'Что вы можете сделать дальше',
    headerExpect: 'Чего ожидать',
    callToAction: 'Мы сделаем это вместе!',
    body:
      'Подошло время, чтобы вы встретились с людьми, которые будут устанавливать ваш канализационный обратный клапан на предстроительном совещании. Помимо вашего инженера мы выбрали сантехника и инспектора, которые будут с вами работать. Вы можете позвонить нам по телефону 646-786-0888 или послать нам сообщение по электронной почте по адресу info@floodhelpny.org если у вас есть вопросы.',
    images: [
      { icon: worker1, iconAltText: 'Рабочий' },
      { icon: maleUser, iconAltText: 'Человек' },
      { icon: worker2, iconAltText: 'Рабочий' }
    ],
    link: 'https://www.floodhelpny.org/samples/Tri-PartyAgreement.pdf',
    linkText: 'Трехстороннее соглашение',
    scheduled: 'Пред-строительное совещание',
    expect:
      'Мы назначим время, чтобы вы встретились с нашей командой. Помимо знакомства с теми, с кем вы будете работать, вас также попросят подписать трехстороннее соглашение между вами, сантехником и Центром.',
    noDate:
      'Позвоните нам по телефону, указанному выше, чтобы подтвердить дату и время вашей установки.'
  },
  [RtappApplicationState.INSTALLATION_TO_BE_SCHEDULED]: {
    headerNext: 'Что вы можете сделать дальше',
    headerExpect: 'Чего ожидать',
    callToAction:
      'Ваш инспектор будет работать с вами, чтобы назначить установку ',
    body:
      "Будьте готовы к установке вашего канализационного обратного клапана! Обычно такая работы занимает около восьми часов, но возможно сантехнику придется вернуться в другой день, чтобы закончить работу, в зависимости от того, каков будет доступ к водоотводной трубе в вашем доме. Помните, что вам нужно быть дома для того, чтобы эта работа была выполнена. Во время установки сантехнику понадобится отключить воду в вашем доме. Это означает, что вы не сможете пользоваться водопроводом и канализацией (туалетом, душем, раковиной, посудомойкой, и т.д.) во время установки, поэтому пожалуйста поаботьтесь об альтернативе заранее. Вы можете позвонить нам по телефону 646-786-0888 или послать нам сообщение по электронной почте по адресу info@floodhelpny.org если у вас есть вопросы.",
    expect:
      'Время установки зависит от планировки вашего дома и его подключением к городской канализации, а аткже от состояния труб, подключающих ваш дом к канализации. Мы дадим вам приблизительную оценку того, столько времени по нашему мнению на это понадобится, как только сможем, но имейте, пожалуйста, в иду, что может понадобиться определенная гибкость. Сантехник, который будет устанавливать ваш клапан, покажет вам, где находится клапан, как он работает, и как содержать его в исправности. В дополнению к этому вы получите отчет с места проведения работ после завершения установки, который будет содержать определенные инструкции по приведению в действие и содержанию от производителя вашего клапана.',
    icon: calendar
  },
  [RtappApplicationState.INSTALLATION_HAS_BEEN_SCHEDULED]: {
    callToAction: 'Ваш инспектор назначил установку вашего клапана',
    body:
      'Лицензированный сантехник и инспектор назначены на установку вашего канализационного обратного клапана. Если у вас есть какие-либо вопросы относительно установки, позвоните вашему инспектору. Сделайте все возможное, чтобы быть дома во время установки.',
    contact:
      "Время установки зависит от планировки вашего дома и его подключением к городской канализации, а аткже от состояния труб, подключающих ваш дом к канализации. Мы дадим вам приблизительную оценку того, столько времени по нашему мнению на это понадобится, как только сможем, но имейте, пожалуйста, в иду, что может понадобиться определенная гибкость. Сантехник, который будет устанавливать ваш клапан, покажет вам, где находится клапан, как он работает, и как содержать его в исправности. В дополнению к этому вы получите отчет с места проведения работ после завершения установки, который будет содержать определенные инструкции по приведению в действие и содержанию от производителя вашего клапана. ",
    images: [{ icon: calendar, iconAltText: 'Календарь' }]
  },
  [RtappApplicationState.INSTALLATION_COMPLETE]: {
    headerNext: 'Что вы можете сделать дальше',
    headerExpect: 'Чего ожидать',
    callToAction: 'Расскажите нам, как все прошло',
    body:
      'Поздравляем вас с вашим новым канализационным обратным клапаном! Мы хотели вы услышать от вас, как прошел процесс. Если у вас есть три минуты, пожалуйста примите участие в опросе, предложенном ниже. Вы можете найти важные документы, касающиеся вашего клапана внизу этой страницы.  Как всегда, если у вас есть вопросы или замечания, вы можете позвонить нам по телефону 646-786-0888 или послать нам сообщение по электронной почте по адресу info@floodhelpny.org.',
    expect:
      'Дождитесь сообщения по электронной почте от нас или зайдите на веб-сайт через две-три недели, чтобы получить все ваши документы, включая информацию о том, как содержать ваш канализационный обратный клапан, а также гарантию производителя.',
    link: 'https://cnycn.tfaforms.net/12',
    linkText: 'Опрос',
    icon: feedback
  },
  [RtappApplicationState.REPORT_COMPLETE]: {
    firstBody: 'Это отчет об устойчивости для:',
    secondBody:
      ' с некоторыми техническими рекомендациями как сделать ваш дом и финансы более устойчивыми к наводнениям, а также важные документы, касающиеся вашего клапана.'
  }
};
