import reportCard from '../images/report-card.svg';
import certificate from '../images/certificate.svg';
import documents from '../images/documents.svg';
import backwaterValve from '../images/backwater-valve.svg';

const example = 'Ver ejemplo';

export default {
  header: 'Lo Que Obtendrá',
  list: [
    {
      header: 'Válvula antirretorno',
      body:
        'Nuestro equipo, que incluye ingenieros y plomeros, cooperará con usted para determinar si su hogar podría beneficiarse de tener una válvula antirretorno.',
      image: backwaterValve
    },
    {
      header: 'Asesoría de seguro de inundación',
      body:
        'Nuestros expertos asesores examinarán su póliza de seguro de inundación y hablarán de posibles opciones para ayudar a reducir sus costos de seguro de inundación por medio de un examen del certificado de elevación y de las opciones de <a href="/es/mitigation">mitigación</a>.',
      image: documents
    },
    {
      header: 'Informe de resiliencia',
      body:
        'Después de inspeccionar su casa y evaluar el riesgo de inundación, le enviaremos un informe técnico detallado y completo con medidas e información sobre qué renovaciones (por ejemplo, elevación de sus sistemas mecánicos) pueden hacerla más segura contra las inundaciones.',
      image: reportCard,
      exampleLink: '/samples/technical_report_john_doe.pdf',
      example
    },
    {
      header: 'Certificado de elevación',
      headerLink: '/es/elevation-certificates',
      body:
        'También le enviaremos un documento que certifica la elevación de su vivienda. Si vive en una zona de inundación de alto riesgo, el certificado de elevación puede evitar que su tasa de seguro contra inundaciones se dispare.',
      image: certificate,
      exampleLink: '/samples/elevation_certificate.pdf',
      example
    }
  ]
};
