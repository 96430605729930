export default {
  header: '您的房产目前',
  baseFloodElevationExplainedPre: '应位于 ',
  baseFloodElevationExplainedPost: ' 海平面以上',
  baseFloodElevation:
    '这是设计洪水标高（DFE）——您需要的位置。',
  topOfBottomFloor: '是目前您的房屋海拔高度。',
  freeboardCity:
    "这是城市确保安全标高要求的出水高。"
};
