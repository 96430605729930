import moment from 'moment';
import Matchers from '../constants/Matchers';

export default class AnswerValidator {
  static isValid(responseField, value) {
    const { type, required } = responseField;
    // if not required and empty, always return true
    if (!required && !value) {
      return true;
    }

    // account for non-required telephone on Audit Application
    if (!required && type === 'tel') {
      return true;
    }

    const checkPresenceTypes = [
      'text',
      'drop-down',
      'number',
      'file',
      'select-applicant-type',
      'drop-down',
      'multiple-drop-down'
    ];

    if (checkPresenceTypes.indexOf(type) !== -1) {
      return !!value;
    }

    if (type === 'button-single') {
      return !!value && responseField.invalidAnswers.indexOf(value) === -1;
    }

    if (type === 'email') {
      return !!value && value.search(Matchers.email) !== -1;
    }

    if (type === 'tel') {
      return !!value && value.search(Matchers.phone) !== -1;
    }

    if (type === 'date') {
      if (!value) {
        return false;
      }
      const date = moment().subtract(18, 'years');
      return moment(value) < date;
    }

    return false;
  }
}
