export default {
    meta: {
      title: '隐私政策',
      url: 'https://www.floodhelpny.org/zh-CN/privacy',
      description: null,
      imageAlt: null
    },
    title: '隐私政策',
    intro: {
      effective_date: '自2019年5月24日起生效',
      body:
        '感谢您访问FloodHelpNY.org（“网站”）。本隐私政策仅适用于本网站，该网站由纽约市社区中心有限公司（“我们”）控制。本隐私政策仅适用于我们通过网站收集的信息。使用本网站即表示您同意本隐私政策的条款，并同意我们出于下列目的处理个人信息。如果您不同意本隐私政策的条款，请不要使用本网站。',
      specific_info:
        '关于我们的数据实践具体信息如下所述。',
      info_we_collect: '我们收集的信息：个人信息和非个人信息',
      we_collect:
        '我们从网站访问者处收集两类信息：（1）个人信息；和（2）非个人信息，如，您的IP地址或cookies。'
    },
    personal_info: {
      title: '个人信息',
      body:
        '当我们使用“个人信息”这一术语时，我们指的是我们可能会收集允许其他人识别或联系您的信息。例如，如果您同意从我们处接收新闻稿和其他信息，我们可能会收集您的电子邮件地址。我们还可以将其与您提供的地址或位置以及其他个人信息进行匹配，确认您有资格接受我们的服务，我们提供的信息可能与您相关。',
      examples: '本网站收集的个人信息示例：',
      email: '电子邮件j',
      address: '住址',
      phone: '电话号码',
      homeowner: '与房产所有权相关的信息',
      no_obligation:
        '虽然您没有义务提供所需信息，但如果您拒绝提供，我们可能无法为您提供某些服务。如果您不希望我们收集您的个人信息，请不要将信息提供给我们。'
    },
    non_personal_info: {
      title: '非个人信息',
      body:
        '“非个人信息”可以是技术信息，也可以是人口统计信息，如您的年龄、性别、邮政编码或其他地理位置数据或兴趣。非个人信息不能识别您的个人身份。以下是通过网站收集的非个人信息的一些示例，以及这些信息的使用说明：',
      ip:
        '互联网协议（IP）地址——您的 IP 地址是一个数字，可以让连接到互联网的计算机知道将数据发送到何处——例如您查看的网页。我们使用这些信息根据您的要求向您提供我们的网页，根据用户的兴趣定制我们的网站，并测量网站内的流量。',
      web_beacons:
        '网络信标（也称为“清除GIF”、“网络漏洞”或“像素标记”）——“网络信标”是具有唯一标识符的微小图形，功能类似于cookie，用于让我们统计访问过网站某些页面的用户，并帮助确定我们计划和服务的有效性。用于 HTML 格式的电子邮件时，网络信标可以告诉发件人电子邮件是否已打开以及何时打开。与存储在用户计算机硬盘上的 cookie 不同，网络信标隐藏在网页上。',
      demographic:
        '人口统计信息——“人口统计信息”可能是您的性别、年龄、邮政编码和兴趣，可通过网站自动收集。我们使用这些信息为您提供个性化服务，并分析趋势，确保网站提供的信息满足您的需求。请注意，我们也认为聚集的信息不是个人可识别的，是非个人信息。',
      addresses:
        '搜索的地址和相应的洪水区信息——我们将保存在搜索工具中键入的地址及其信息结果。我们会总体使用这些信息来评估用户对特定地理区域的兴趣以及获得的总体洪水风险结果。这些信息将用来评估我们外联和网站推广策略的有效性，以及未来的研究项目。',
      above_list:
        '上述列表提供了通过网站收集的非个人信息的示例。我们可能会从网站的所有访问者处收集上述非个人信息。如果您不希望我们访问此信息，请不要访问我们的网站。'
    },
    use_of_information: {
      title: '使用通过网站收集的信息',
      personal_info: '个人信息',
      personal_info_body:
        '我们将在网站上收集您的个人信息，主要用于：',
      deliver_services: '为您提供服务',
      provide_info:
        '向您提供有关所在地区的计划和服务的信息',
      verify_email: '验证您的电子邮件地址',
      answer_questions: '回答您的问题或其他要求',
      contact: '就您使用本网站或我们的服务与您联系',
      notify_about_changes:
        '如有必要，通知您隐私政策或我们服务的重大变更',
      administer:
        '管理网站或用于您与我们的服务之间的互动',
      other_purposes:
        '出于您提供个人信息时披露的任何其他目的',
      non_personal_info: '非个人信息',
      non_personal_is_used:
        '非个人信息的使用如上所述，其使用符合适用的房率，包括将非个人信息与个人信息相结合。',
      non_personal_example:
        '例如，我们可以使用非个人信息和使用数据（i）创建营销分析和报告，显示我们的用户作为集体群体如何与我们的服务互动，（ii）制定报告，帮助我们和其他战略第三方确定我们的服务用户作为一个群体的兴趣，或（iii）总体上改进我们的计划和服务。将与纽约市社区中心和帮助我们管理本网站和服务的第三方共享这些报告。但是，此类报告不会包含有关您的任何个人信息。'
    },
    sharing_and_disclosure: {
      title: '信息的共享和披露',
      personal_info: '个人信息',
      personal_examples:
        '我们可以分享或披露您的个人信息的来：',
      fulfill:
        '为您提供服务。例如，如果您需要我们的帮助和/或申请家庭恢复力审计，我们可能会共享您的个人信息提供服务。此外，如果您联系我们或注册接收新闻稿，我们可以使用您的电子邮件地址来响应请求。',
      affiliates_home_resiliency:
        '发送给附属公司、分包商、战略合作伙伴、代理人、适用联邦当局、风暴恢复州长办公室或其指定人员，或为家庭恢复力审计提供服务的其他非关联方。这些当事方可能会使用您的个人信息联系您，进行家庭恢复力审计，并可能会将这些信息用于自己的研究、管理或业务目的。如果您不希望我们用这种方式共享您的个人信息，请不要向我们提供此信息。',
      affiliates_other:
        '向提供我们认为您可能感兴趣的计划或服务或出于研究、行政和/或内部业务目的需要您的个人信息的附属公司、战略合作伙伴、代理或其他非附属方。这些当事方可以使用您的个人信息与您联系，获得与项目或服务相关的报价，或者他们可以将这些信息用于自己的研究、管理或业务目的。如果您不希望我们用这种方式共享您的个人信息，请不要向我们提供此信息。',
      unaffiliated:
        '发送给非关联第三方服务提供商、代理人、帮助我们维护网站，并提供其他管理服务的独立承包商，（包括但不限于处理您的请求、提供客户服务、维护和分析数据以及代表我们发送客户通信）。我们力求确保这些非关联第三方不会将个人信息用于除提供管理服务以外的任何其他目的。由于此类帮助我们管理网站的非关联第三方服务提供商将访问用户的个人信息，如果您不希望我们的非关联第三方服务提供商访问您的信息，请不要注册或向我们提交任何个人信息。',
      comply_with_law:
        '为了符合法律和诚信要求或遵守我们提供服务的法律程序，我们必须采取此类行动保护和捍卫我们的权利或财产、网站或在紧急情况下采取行动以保护我们最终用户的人身安全。',
      third_part_corp:
        '作为任何公司重组过程的一部分，向第三方披露，包括但不限于合并、收购和出售我们的全部或大部分资产。',
      track_and_analyze:
        '跟踪和分析来自访客和客户的非识别和汇总使用量和数量统计信息，并向第三方提供此类信息。',
      fraud_protection:
        '为了防止潜在欺诈，我们可能会与第三方核实从网站收集的信息。在验证过程中，我们可能会从此类服务中收到您的个人信息。',
      personal_footer:
        '除了本隐私政策中所述或我们要求提供信息时，我们不会以其他方式使用、共享或以其他方式向帮助我们管理网站或服务的任何第三方披露您的个人信息。如果您不同意如上所述披露您的个人信息，请不要向我们提供。',
      non_personal_info: '非个人信息',
      non_personal_content:
        '我们以上文第B（2）节披露的方式使用网站收集的非个人信息。可能会与帮助我们管理本网站或服务的第三方共享此类非个人信息。',
      children_under_13: '13岁以下的儿童',
      children_under_13_content:
        '该网站不针对13岁以下的儿童，也不会收集这些儿童的个人信息。如果我们意识到13岁以下的儿童向我们提供了个人信息，我们会采取措施删除这些信息。',
      opt_out: '选择退出',
      opt_out_content:
        '您可以随时选择不接收我们或战略第三方的促销信息。对于电子邮件，您可以转到您从我们收到的任何电子邮件底部提供的链接，并选择不接收我们的信息。我们将尽快处理您的退订，但请注意，在某些情况下，在退订之前，您可能会收到更多消息。请注意，取消订阅促销电子邮件不会使您退出我们要求发送的交易电子邮件或电子邮件。',
      third_parties: '您向第三方披露的信息',
      third_parties_content:
        '如果您响应第三方任何互动广告或报价（无论是通过电子邮件、信息还是其他方式），或者如果您要求提供关于第三方提供的产品、计划或服务的信息，您的个人信息将被披露给该第三方。我们无法控制此类第三方收集或使用您的信息，也不对其作出任何陈述。如果您选择注册或订阅任何该等第三方产品或服务，您与该等第三方之间的任何进一步通信，包括但不限于任何由此产生的合同协议，仅限于您与该广告商/第三方之间的通信。我方不负责维护广告商/第三方作出的与此相关的任何适用条款、条件、陈述或保证，并不对贵方与第三方的互动承担任何责任。',
      correct_inaccuracies:
        '您如何更正个人信息中的任何不准确之处？',
      correct_inaccuracies_body:
        '如果您对我们收集的相关信息的准确性有任何疑问，并希望访问此类信息，请与我们联系 ',
      correct_inaccuracies_body_email: 'cnycn.org.',
      info_protected: '如何保护您的信息？',
      info_protected_body:
        '我们认为您的信息的机密性和安全性是首要的。我们使用物理、技术和管理安全措施来保护您个人信息的安全性和机密性，除非法律另有要求或本隐私政策另有规定，我们不会提供给第三方（帮助管理网站和服务的实体除外）。但是，互联网不是一个安全的环境，我们无法100%保证您信息的安全；会存在一些风险，未经授权的第三方可能会找到规避我们安全措施的方法，或者可能截获通过互联网传输的信息。',
      info_protected_body_2:
        '如果我们得知安全系统遭到破坏，我们可能会尝试以电子方式通知您，让您采取适当的保护措施。通过使用本网站或向我们提供个人信息，代表您同意我们可以通过电子方式与您沟通与您使用本网站有关的安全、隐私和管理问题。如果出现安全漏洞，我们可能会在网站上发布通知。在这种情况下，我们也可以按照您提供的电子邮件地址向您发送电子邮件，或按照您提供的家庭地址发送信函。根据您的居所，您可能有权收到书面安全违约通知。',
      third_party_sites: '第三方网站',
      third_party_sites_body:
        '本声明仅适用于在网站上收集的信息。该网站可能包含指向其他网站的链接。我们不对这些其他网站的隐私做法或内容负责。',
      do_not_track: '我们如何应对不跟踪信号？',
      do_not_track_body:
        '请注意，我们的网站不支持“请勿跟踪”浏览器设置，目前也没有参与任何“请勿跟踪”框架，这些框架允许我们响应您发出的有关收集您的个人或非个人信息的信号或其他机制。',
      assignment: '分配',
      assignment_body:
        '如果我们的全部或部分资产被另一方出售或收购，或发生合并，您授予我们转让通过本网站收集的个人和非个人信息的权利。',
      changes: '对本隐私政策的更改',
      changes_body:
        '随着我们的服务的发展和改进，我们保留修改本隐私政策的权利，或对不断变化的法律法规或业务问题作出响应的权利。我们这样做时，我们还会修改本隐私政策顶部的“生效”日期。对于本隐私政策的变更，如果对我们使用或披露您提供的个人信息的限制可能会大大降低，我们将在实施变更之前，通过存档的主要电子邮件地址发送通知或在网站上放置显著通知，尝试获得您的同意。'
    },
    questions: {
      title: '是否有问题？',
      body:
        '有关本隐私政策的查询，请发送电子邮件至 ',
      email: 'info@floodhelpny.org.'
    }
  };
  