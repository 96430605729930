import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import RoundIcon from './RoundIcon';
import ButtonLink from './ui/ButtonLink';
import CallToAction from './layout/CallToAction';
import { Section } from './ui/StyledResourcePageElements';

const Images = styled.div`
  > div {
    margin-bottom: 1rem;

    :last-of-type {
      margin-bottom: 0;
    }

    @media (max-width: 1000px) {
      margin-right: 2rem;

      :last-of-type {
        margin-right: 0;
      }
    }
  }

  @media (max-width: 1000px) {
    display: flex;
  }
`;

const Text = styled.div`
  > * {
    margin-top: 2rem;

    :first-child {
      margin-top: 0;
    }
  }
`;

const AuditStatusInfoMultipleImages = ({ provider }) => {
  return (
    <Section>
      <CallToAction>
        <Images>
          {provider.images.map((image) => (
            <RoundIcon
              key={image.iconAltText}
              src={image.icon}
              alt={image.iconAltText}
            />
          ))}
        </Images>
        <Text>
          <h3>{provider.callToAction}</h3>
          <p>{provider.body}</p>
          <p>
            {provider.contact}
            <a href={`tel:${provider.phoneLink}`}>{provider.phone}</a>
            {provider.contact2}
            <a href={`mailto:${provider.email}`}>{provider.email}</a>
          </p>
        </Text>
        {provider.link && (
          <ButtonLink url={provider.link} text={provider.linkText} external />
        )}
      </CallToAction>
    </Section>
  );
};

AuditStatusInfoMultipleImages.propTypes = {
  provider: PropTypes.object.isRequired
};

export default AuditStatusInfoMultipleImages;
