import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { borderRadius, colors } from '../styles/variables';
import ExplorableMapPopupFloodZoneInfo from './ExplorableMapPopupFloodZoneInfo';
import TermsAndConditionsModal from './TermsAndConditionsModal';
import PropertyTypeModal from './PropertyTypeModal';
import Error from './layout/Error';

// called by:
//   ExplorableMap
const Container = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  padding: 1rem;
  transition-property: bottom;
  transition-duration: 0.5s;
  background-color: #fff;
  text-align: center;
  border-top: solid 1px #ccc;
  z-index: 1000001;
  bottom: ${(props) => (props.popupOpen ? '0' : '-100%')};

  @media (min-width: 768px) {
    position: absolute;
    width: 550px;
    height: 60%;
    bottom: 110px;
    transition-property: left;
    transition-duration: 0.5s;
    border: solid 1px #ccc;
    left: ${(props) => (props.popupOpen ? '2%' : '-550px')};
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

const Header = styled.h4`
  display: ${(props) => (props.popupOpen ? 'block' : 'none')};
  margin: 1rem;
  font-size: 2rem;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  border-radius: ${borderRadius};
  border: 1px solid ${colors.almostBlack};
  background-color: transparent;
  padding: 0.25rem 0.75rem;
  font-size: 1rem;
`;

const ExplorableMapPopup = ({
  closePopup,
  address,
  popupOpen,
  propertyInfo,
  popupLoading,
  currentZone,
  futureZone,
  addressLookupError,
  propertyDetails,
  setPageStatus,
  pageStatus,
  handlePropertyTypeClick,
  handleProfileCreation,
  handleDisagree,
  provider
}) => {
  const formattedAddress = address ? address.replace(/, USA$/, '') : '';

  const renderContent = () => {
    if (pageStatus === 'propertyType') {
      return (
        <PropertyTypeModal
          handlePropertyTypeClick={handlePropertyTypeClick}
          explorable
        />
      );
    }

    if (pageStatus === 'termsAndConditions') {
      return (
        <TermsAndConditionsModal
          disagree={handleDisagree}
          agree={() => setPageStatus('floodZone')}
          explorable
        />
      );
    }

    if (pageStatus === 'error') {
      return <Error fontSize="1.3em" provider={provider} />;
    }

    if (pageStatus === 'floodZone') {
      return (
        <ExplorableMapPopupFloodZoneInfo
          propertyInfo={propertyInfo}
          popupLoading={popupLoading}
          currentZone={currentZone}
          futureZone={futureZone}
          addressLookupError={addressLookupError}
          propertyDetails={propertyDetails}
          handleProfileCreation={handleProfileCreation}
        />
      );
    }

    return null;
  };

  return (
    <Container popupOpen={popupOpen}>
      <Header popupOpen={popupOpen}>{formattedAddress}</Header>
      {renderContent()}
      <CloseButton
        type="button"
        onKeyDown={(e) => (e.key === 'Enter' ? closePopup() : null)}
        onClick={closePopup}
      >
        X
      </CloseButton>
    </Container>
  );
};

ExplorableMapPopup.propTypes = {
  closePopup: PropTypes.func.isRequired,
  address: PropTypes.string.isRequired,
  popupOpen: PropTypes.bool.isRequired,
  propertyInfo: PropTypes.object,
  popupLoading: PropTypes.bool.isRequired,
  currentZone: PropTypes.string.isRequired,
  futureZone: PropTypes.string.isRequired,
  addressLookupError: PropTypes.string,
  propertyDetails: PropTypes.object.isRequired,
  setPageStatus: PropTypes.func.isRequired,
  pageStatus: PropTypes.string,
  handleProfileCreation: PropTypes.func.isRequired,
  handlePropertyTypeClick: PropTypes.func.isRequired,
  handleDisagree: PropTypes.func.isRequired,
  provider: PropTypes.object.isRequired
};

ExplorableMapPopup.defaultProps = {
  propertyInfo: {},
  addressLookupError: ''
};

export default ExplorableMapPopup;
