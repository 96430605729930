export default {
  header: 'TARIF ASIRANS OU AN, BAZE SOU SÈTIFIKA ELEVASYON OU AN',
  current: 'Kat aktyèl yo',
  future: 'Kat konsiltatif',
  zone: 'zòn',
  perYear: 'Pou chak ane*',
  assumingMax: '*Nou sipoze yon kouvèti maksimòm',
  numbersAreEstimates:
    'Chif sa yo se estimasyon pou anpil rezon. Pou aprann plis sou yo ale sou ',
  termsAndConditions: 'Tèm ak Kondisyon nou yo'
};
