import fillInBasement from '../images/fill-in-basement.svg';
import elevate from '../images/elevate.svg';
import elevateMechanicals from '../images/elevate-mechanicals.svg';
import abandonFirstFloor from '../images/abandon-first-floor.svg';
import floodVents from '../images/flood-vents.svg';

export default {
  title: 'Adousisman',
  overview: 'Apèsi',
  affectRate: 'Kijan li ta ka afekte tarif asirans kont asirans ou an',
  howItWorks: 'Kijan l fonksyone',
  effort: 'Jefò',
  backwaterValve: 'Valv pou dlo k ap tounen: Sa ou bezwen konnen',
  elevate: {
    meta: {
      title: 'Elve Kay Ou an',
      url: 'https://www.floodhelpny.org/ht_HT/mitigation/elevate',
      description:
        'Elve kay ou an enplike leve estrikti ou an pi wo pase wotè inondasyon ki prevwa a epi plase l nou yon nouvo fondasyon oswa youn ki pwolonje.'
    },
    title: 'Elve Kay Ou an',
    subtitle:
      'Elve kay ou an enplike leve estrikti ou an pi wo pase wotè inondasyon ki prevwa a epi plase l nou yon nouvo fondasyon oswa youn ki pwolonje.',
    overview:
      'Abityèlman sa fèt nan leve kay ou an sou sipò tanporè ki depase fondasyon aktyèl li an, apresa bati oswa kreye yon nouvo fondasyon ki pi wo, ansuit bese kay lan sou nouvo fondasyon an. Elevasyon chè e li egzije pou ou relokalize pou yon ti tan, men li redui nan yon fason konsiderab alafwa risk ou pou domaj nan inondasyon ak prim asirans kont inondasyon ou an.',
    image: elevate,
    imageAlt: 'Elve',
    affectRate:
      'Elevasyon se fason ki pi efikas pou redui nan yon fason konsiderab tarif asirans kont inondasyon ou an epi kenbe l ba alavni. Si kay ou an nan yon zòn risk pou inondasyon espesyal, faktè prensipal lan nan detèmine tarif asirans kont inondasyon ou an se diferans lan ant elevasyon etaj ki pi ba nan kay ou an ak Elevasyon Debaz pou Inondasyon an. Si ou elve kay ou an pi wo pase BFE an, ou redui risk ou, sa ki minimize prim ou an. Si ou vle kalifye pou tarif ba a apre yon elevasyon, espas anba kay ou an ka sèlman sèvi pou estokaj, pakin epi aksè.',
    howItWorks:
      'Si ka ou an elve pi wo pase BFE an, espas pou viv ou an ak enstalasyon yo pral gen chans pou yo pi wo pase dlo an nan ka yon inondasyon, sa ki ap anpeche pwopriyete ou an ak objè ki gen valè ou yo sekirize kont domaj nan inondasyon. Si ou chwazi elve, ou dwe leve kay ou an omwen de pye pi wo pase BFE an nan lide pou l jistifye ensètitid nan kat inondasyon yo ak nivo lanmè k ap monte yo prevwa yo. De pye elevasyon anplis sa yo rele “fran bò” epi li fè pati kòd konstriksyon NYC an. Nouvo wotè kay lan, ansanm ak fran bò an, ta ka nan Elevasyon Inondasyon pou Konsepsyon an.',
    effort:
      'Elevasyon yon kay ka pran jiska twa mwa, toudepan kontraktyèl la ak estrikti an. Pandan tan sa a, ou ap bezwen relokalize epi retire bagay ki gen valè ou yo lakay ou. Lakay ou ap dekonekte ak sèvis piblik yo tankou dlo, egou ak elektrisite pandan konstriksyon an.'
  },
  elevateMechanicals: {
    meta: {
      title: 'Leve Ekipman Mekanik Lakay Ou yo',
      url: 'https://www.floodhelpny.org/ht_HT/mitigation/elevate-mechanicals',
      description:
        'Ekipman mekanik lakay ou yo — tankou founo, ekipman elektrik yo ak plonbri an — esansyèl.'
    },
    title: 'Leve Ekipman Mekanik Lakay Ou yo',
    subtitle:
      'Ekipman mekanik lakay ou yo — tankou founo, ekipman elektrik yo ak plonbri an — esansyèl.',
    overview:
      'Lè dlo inondasyon an atenn ekipman an, li ka sispann fonksyone kòrèkteman. Ranplase oswa repare l ka koute plizyè santèn oswa milye dola. Se pou sa youn nan opsyon ki pi efikas pou rann kay ou mwens vilnerab ak inondasyon se elve ekipman mekanik yo. Sa ka fèt nan elve ekipman yo pi wo pase kote yo plase aktyèlman oswa nan relokalize yo sot nan sousòl lan oswa espas vid lan pou mete yo nan premye etaj lan oswa pi wo. Nan kèk ka, pwopriyetè kay yo gendwa vle bati yon ti konpleman sou kay lan pi wo pase elevasyon inondasyon an antanke yon sal pou enstalasyon ekipman yo. Malrezman, elve ekipman mekanik yo ap ekonomize pou ou yon ti lajan oswa anyen ditou sou prim asirans kont inondasyon ou an. Li se tou yon opsyon ki relativman chè. ',
    image: elevateMechanicals,
    imageAlt: 'Elve Ekipman Mekanik yo',
    affectRate:
      'Elve ekipman mekanik yo lakay ou ta ka ekonomize ou ant $100 ak $200 chak ane nan prim ou an.',
    howItWorks:
      'Lè ou relokalize ekipman mekanik ou an pi wo pase elevasyon inondasyon an oswa andeyò sousòl ou an, ou redui nan yon fason enpòtan risk pou sistèm kritik ou yo andomaje pandan yon inondasyon. Non sèlman ou ap ekonomize sou reparasyon ki chè ak frè ranplasman, men ou ap kapab retounen lakay ou tou pi rapid apre yon inondasyon si sistèm chofaj ak elektrik ou yo pa touche. Sa sèlman ka ekonomize ou plizyè santèn oswa menm milye dola nan depans tanporè pou relokalizasyon.',
    effort:
      'Deplase ekipman mekanik kay ou yo ka pran ant twa jou pou rive kèk semèn, toudepan lakay ou ak kontraktyèl ou an e ki sistèm ou chwazi relokalize. Pandan tan sa a, ou ap gen aksè limite oswa okenn aksè ak ekipman chofaj, dlo cho ak elektrik ou yo.'
  },
  fillInBasement: {
    meta: {
      title: 'Ranpli yon Sousòl oswa Espas Vid',
      url: 'https://www.floodhelpny.org/ht_HT/mitigation/fill-in-basement',
      description:
        'Ranpli sousòl oswa espas vid ou an ak sab, gravye oswa lòt materyo jiska nan nivo sòl lan ka redui risk ou pou domaj nan inondasyon epi prim pou asirans kont inondasyon ou an.'
    },
    title: 'Ranpli yon Sousòl oswa Espas Vid',
    subtitle:
      'Ranpli sousòl oswa espas vid ou an ak sab, gravye oswa lòt materyo jiska nan nivo sòl lan ka redui risk ou pou domaj nan inondasyon epi prim pou asirans kont inondasyon ou an.',
    overview:
      'Ou ap bezwen enstale tou vantilasyon kont inondasyon nan pòsyon sousòl oswa espas vid ki depase sòl lan ki rete an. Pandan ranpli sousòl ou an gendwa koutè chè okòmansman, li ka finalman ekonomize pou ou plizyè milye dola nan prim pou asirans kont inondasyon ou yo pi devan. Nan kèk ka, ranpli sousòl ou an ta ka andomaje entegrite estriktirèl konstriksyon ou an, kidonk li esansyèl pou ou konsilte yon enjenyè anvan ou poze aksyon.',
    image: fillInBasement,
    imageAlt: 'Ranpli Sousòl an',
    affectRate:
      'Si kay ou an nan yon zòn risk espesyal pou inondasyon, faktè prensipal nan detèmine tarif asirans kont inondasyon ou an se diferans ant elevasyon premye etaj kay ou an (se etaj sousòl lan) ak Elevasyon pou Inondasyon Debaz lan. Lè ou ranpli sousòl ou an epi enstale vantilasyon kont inondasyon, ou diminye diferans sa a, paske pwochen etaj ou an anwo nivo sòl lan vin etaj ki pi ba nan kay ou an pou rezon asirans. Sa pral bese prim asirans kont inondasyon ou an, men dimansyon diminisyon prim lan pral varye, sou baz elevasyon sòl lan lè ou konpare l ak BFE an.',
    howItWorks:
      'Lè ou elimine sousòl ou an epi ou enstale vantilasyon kont inondasyon yo, ou ap elve elevasyon etaj ki pi ba nan kay ou an san ou pa leve kay lan anfèt — premye etaj anwo tè ou an ap vin “elevasyon etaj ki pi ba nan kay ou an.” Lè ou ranpli sousòl ou an, ou ap asire ou tout kontni ou yo lokalize pi wo nivo tè an, sa ki rann yo mwens gen chans pou yo andomaje nan ka yon inondasyon. Enstale vantilasyon kont inondasyon pwoteje fondasyon kay ou an ak mi yo kont domaj presyon dlo an. Toutfwa, nan lide pou ranpli sousòl ou an ou gendwa blije pèdi yon pati nan espas pou viv nan kay ou an, kidonk ou ta dwe konsidere si avantaj sa yo depase pèt espas sa a. ',
    effort:
      'Ranpli sousòl lan oswa espas vid lan epi enstale vantilasyon kont inondasyon yo ka pran youn a de semèn anmwayèn, apre tout pèmi yo bay epi konstriksyon an kòmanse.'
  },
  floodVents: {
    meta: {
      title: 'Enstale Vantilasyon kont Inondasyon',
      url: 'https://www.floodhelpny.org/ht_HT/mitigation/flood-vents',
      description:
        'Enstale vantilasyon kont inondasyon enplike ajoute ouvèti anba etaj ki pi ba nan kay ou an pou pèmèt dlo antre epi sòti nan ka yon inondasyon san li pa lakoz domaj nan estrikti an.'
    },
    title: 'Enstale Vantilasyon kont Inondasyon',
    subtitle:
      'Enstale vantilasyon kont inondasyon enplike ajoute ouvèti anba etaj ki pi ba nan kay ou an pou pèmèt dlo antre epi sòti nan ka yon inondasyon san li pa lakoz domaj nan estrikti an.',
    overview:
      'Sa se youn nan opsyon adousisma ki mwen chè ki disponib, men li se sèlman yon opsyon nan kay kote enstalasyon mekanik yo ak kontwòl yo anwo, oswa kapab relokalize anwo, yon wotè presi yo rele Elevasyon Inondasyon pou Konsepsyon an. Si ou vle enstale vantilasyon oswa ouvèti kont inondasyon, nenpòt kote anba Elevasyon pou Inondasyon Debaz lan dwe sèvi sèlman pou pakin, estokaj oswa aksè ak batiman an, epi zòn anba BFE an dwe gen omwen yon kote ki nan nivo sifas sòl lan oswa pi wo.',
    image: floodVents,
    imageAlt: 'Vantilasyon kont Inondasyon',
    affectRate:
      'Enstale vantilasyon kont inondasyon bese prim asirans kont inondasyon ou an si omwen youn nan etaj sousòl oswa espas vid ou an nan nivo sòl lan oswa pi wo, oswa li fèt an menm tan ak ranplisaj sousòl oswa espas vid ou an nan nivo sòl lan. Nan zòn ki gen gwo risk pou inondasyon yo, faktè prensipal nan detèmine tarif asirans ou an se diferans ant elevasyon etaj ki pi ba nan kay ou an ak BFE an. Si ou ranpli sousòl ou an epi enstale vantilasyon kont inondasyon, pwochen etaj ki pi wo an vin “etaj ki pi ba” ou an, sa ki ap bese prim asirans ou an.',
    howItWorks:
      'Lè ou ajoute ouvèti akote batiman ou an, ou redui risk domaj apati presyon dlo (fòs idwostatik ak idwodinamik). Nan ka yon inondasyon, dlo ka aantre epi soti nan kay ou an libeliben atravè vantilasyon yo, san l pa bezwen entèvansyon moun, sa ki ap redui risk pou domaj estriktirèl nan mi oswa fondasyon ou yo.',
    effort:
      'Si enstalasyon ou yo deja pi wo pase Elevasyon Inondasyon Konsepsyon an e ou pa gen yon sousòl oswa espas vid pou ranpli, enstale vantilasyon kont inondasyon relativman fasil, e li pa ta dwe pran plis pase kèk jou. Si ou bezwen ranpli yon sousòl oswa espas vid epi/oswa elve ekipman mekanik yo, pwoteksyon pasyèl yo ap vin pi konplike, pran tan epi koute kòb.'
  },
  abandonFirstFloor: {
    meta: {
      title: 'Abandone Premye Etaj Ou an',
      url: 'https://www.floodhelpny.org/ht_HT/mitigation/abandon-first-floor',
      description:
        'Abandone premye etaj pwopriyete ou an enplike rann dezyèm etaj ou an pi wo pase espas ou ap viv yo, epi itilize premye etaj ou an senpman pou estokaj, pakin oswa aksè.'
    },
    title: 'Abandone Premye Etaj Ou an',
    subtitle:
      'Abandone premye etaj pwopriyete ou an enplike rann dezyèm etaj ou an pi wo pase espas ou ap viv yo, epi itilize premye etaj ou an senpman pou estokaj, pakin oswa aksè.',
    overview:
      'Si ou gen yon sousòl oswa espas vid anba tè, ou ap bezwen ranpli sa tou. Ou ap bezwen deplase tout enstalasyon anwo Elevasyon Inondasyon Konsepsyon an oswa plase yo andeyò yon estrikti ki elve. Ou ap toujou bezwen rann premye etaj lan “non fini” (klwazon sèch) epi ajoute vantilasyon kont inondasyon oswa ouvèti pi ba pase Elevasyon Inondasyon Konsepsyon an pou pwopriyete ou. Sa ka yon estrateji chè, men li ka redui nan yon fason enpòtan prim asirans ak risk ou. Li gendwa vle di tou pou abandone yon pòsyon enpòtan nan espas pou viv lakay ou, sa ki se yon konpwomi enpòtan pou konsidere lè ou ap deside si pou adopte opsyon adousisman sa a ou pa.',
    image: abandonFirstFloor,
    imageAlt: 'Abandone Premye Etaj lan',
    affectRate:
      'Si ou abandone premye etaj nan pwopriyete ou an, ou elve nan yon fason efikas elevasyon espas pou viv ki pi ba nan kay ou an, oswa “etaj ki pi ba” pou rezon notasyon, sa ki minimize prim ou an.',
    howItWorks:
      'Abandone premye etaj nan kay ou ka elve l nan nivo BFE an oswa pi wo. Sa ogmante pwobabilite li ap pwoteje kont dlo inondasyon, anpeche pwopriyete ou an ak bagay ki gen valè ou yo ansekirite kont domaj.',
    effort:
      'Abandone premye etaj ou an ap egzije relokalizasyon tanporè ansanm ak konstriksyon anplis pou deplase ekipman mekanik ak aparèy menaje ou yo, epi pou bati yon lòt antre pou kay ou apre premye etaj ou an ranpli.'
  }
};
