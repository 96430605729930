import React from 'react';
import styled from 'styled-components';
import CallToAction from './layout/CallToAction';
import cellPhone from '../images/cell-phone.svg';
import RoundIcon from './RoundIcon';
import { useProvider } from '../helpers/hooks';

const ContactPhone = styled.div`
  min-width: 125px;
  text-align: right;

  @media (max-width: 786px) {
    text-align: left;
  }
`;

const ContactBody = styled.div`
  padding: 1rem;
`;

const Header = styled.h4`
  margin-bottom: 1rem;
`;

const ContactUs = () => {
  const provider = useProvider('ContactUs');

  return (
    <CallToAction>
      <RoundIcon src={cellPhone} alt="cell phone" />
      <ContactBody>
        <Header>{provider.header}</Header>
        <p>{provider.copy}</p>
      </ContactBody>
      <ContactPhone>
        <span>{provider.callToAction}</span>{' '}
        <a href={`tel:${provider.phoneNumber}`}>{provider.phoneNumber}</a>
      </ContactPhone>
    </CallToAction>
  );
};

export default ContactUs;
