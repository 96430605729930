import waterDroplet from '../images/water-droplet.svg';
import umbrella from '../images/umbrella.svg';
import piggyBank from '../images/piggy-bank.svg';
import moneyHouse from '../images/money-house.svg';
import couchInHouse from '../images/couch-in-house.svg';
import policyAlert from '../images/policy-alert.svg';
import checklist from '../images/checklist.svg';
import premiumChanges from '../images/premium-changes.svg';

export default {
  meta: {
    title: 'Понимание страхования от наводнения | FloodHelpNY.org',
    url: 'https://www.floodhelpny.org/ru/understanding-flood-insurance',
    description: `Страхование от наводнения довольно трудное для понимания. Мы рады помочь вам понять его.`,
    ogDescription:
      'Пытаться разобраться в страховании от наводнения самим может быть обескураживающим. Но мы рады вам помочь.',
    imageAlt: 'волны'
  },
  title: 'Понимание страхования от наводнения',
  subHeading:
    'Пытаться разобраться в страховании от наводнения самим может быть обескураживающим. Но мы рады вам помочь.',
  inANutshell: {
    header: 'Вкратце',
    items: [
      {
        header: 'Стандартная страховка, это не то же самое, что страхование от наводнения',
        body:
          'Страхование недвижимости, арендатора и страхование предпринимательской деятельности не покрывает ущерб от наводнения.',
        image: waterDroplet,
        imageAlt: 'капля воды'
      },
      {
        header: 'Возможно, вам будет необходимо иметь страхование от наводнения',
        body: 'Страхование от наводнения требуется для некоторых домовладельцев.',
        image: umbrella,
        imageAlt: 'зонтик'
      },
      {
        header: 'Сэкономьте деньги со страхованием от наводнения',
        body:
          'Это может быть наиглавнейшим инструментом, помогающим вам избежать финансовой карастрофы в случае наводнения.',
        image: piggyBank,
        imageAlt: 'копилка'
      }
    ]
  },
  whatIsIt: {
    header: 'Что это?',
    body: {
      p1:
        'Национальная программа страхования от наводнения  - это поддерживаемая на федеральном уровне программа, кторая начала свое действие в 1968г. В то время частные компании не хотели брать на себя риск возмещения ущерба, нанесенного наводнением, поэтому конгресс создал NFIP для предоставления владельцам зданий доступного страхования от наводнения. Нью-Йорк подал заявку на участие в программе в 1983г.',
      p2:
        'NFIP регулируется Федеральным агентством по управлению в чрезвычайных ситуациях и частными страховыми компаниями.  В то время как эти компании продают страхование от наводнения, все тарифы страндартизированы FEMA. Это означает, что вам не нужно сравнивать цены на страховку, но вам нужно проверить точнось вашего тарифа - как-никак брокеры делают ошибки.'
    },
    aside: {
      header: 'NFIP',
      body:
        'NFIP позволяет владельцам недвижимости в участвующих районах приобрести страховое покрытие ущерба от наводнения для всех ипотечных займов или кредитных линий, обеспеченных зданиями в зонах повышенного риска наводнения.'
    }
  },
  floodInsuranceCover: {
    header: 'Что покрывает страхование от наводнения?',
    subheader:
      'Существует два вида страхового покрытия: здания и содержимого. У вас есть возможность выбрать отдельно сумму покрытия и франшизу для каждого.',
    items: [
      {
        header: 'Страховое покрытие здания',
        body:
          'Страхует только конструкцию, но не содержимое или ландшафт. Не покрывает отделку в подвале.',
        image: moneyHouse,
        imageAlt: 'дом со значком доллара'
      },
      {
        header: 'Страховое покрытие содержимого',
        body:
          'Страхует ваше имущество (но большая часть вещей в вашем подвале или ниже вашего нижнего жилого этажа исключается).',
        image: couchInHouse,
        imageAlt: 'дом с кушеткой'
      }
    ],
    aside: {
      header: 'Неочевидные исключения',
      body:
        'Впрочем существует несколько неочевидных исключений: холодильники, кухонные плиты и посудомоечные машины покрываются страхованием от наводнения здания, в то время как стиральные и сушильные машины считаются содержимым.'
    }
  },
  shopping: {
    icon: checklist,
    iconAlt: 'перечень',
    header: 'Узнайте как получить лучший полис, чтобы защитить ваш дом',
    buttonText: 'Читать сейчас'
  },
  myOptions: {
    header: 'Какие варианты есть у меня на страховое покрытие?',
    body:
      'Максимальная сумма страхования, которую вы можете приобрести для вашего здания это 250000 долларов США, и максимальная сумма страхования, которую вы можете приобрести для вашего содержимого это 100000 долларов США',
    aside: {
      header: 'Владелец малого бизнеса?',
      link: 'Дополнительная информация',
      body:
        ' о том, как риск наводнения может отразиться на вашей деятельности - и что вы можете с этим сделать.'
    }
  },
  floodInsurancePriced: {
    header: 'Как рассчитывается страховка от наводнения',
    body:
      '1 окрября 2021г., FEMA ввело новые расценки страхования от наводнения, известные как  Оценка риска 2.0, чтобы более точно отобразить риск наводнения. При новой системе FEMA больше не будет полагаться на карты наводнения для установки цен на страхование от наводнения, а будет использовать различные переменные, чтобы рассчитать ваш взнос, такие как расстояние до источника наводнения и тип строения. Чтобы получить полный список, обратитесь к нашим Часто задаваемым вопросам.'
  },
  premiums: {
    icon: premiumChanges,
    iconAlt: 'Результатом Оценки риска 2.0 будут изменения во взносах',
    body:
      'Результатом Оценки риска 2.0 будут изменения во взносах для многих людей по всей стране. Некоторые полисы могут испытать понижение взносов; другие могут испытать повышение. Для тех полисов, которые будут повышаться под  Оценкой риска 2.0, FEMA обязывается федеральным законом не повышать полис на более чем 18% per year в год. В Нью-Йорке, по предположениям FEMA около 61% ньюйоркцев испытают повышение взноса при Оценке риска 2.0.'
  },
  understandingMaps: {
    header: 'Понимание Карт страхования от наводнения',
    sub_heading:
      'Важно помнить, что в то время как карты наводнений больше не будут  составной частью ценоопределения, они все еще будут использоваться для определения вашей необходимости иметь страхование от наводнения.',
    severeRiskZone: 'Наивысший риск',
    highRiskZone: 'Повышенный риск',
    moderateRiskZone: 'Средний рск'
  },
  aboutTheZones: {
    header: 'О зонах',
    body: {
      p1:
        'В зонах повышенного риска наводнения  (AE, AO and VE) владельцы недвижимости обязаны приобрести страхование от наводнения если у них федерально поддерживаемая ипотека. Также, владельцы недвижимости, которые получили федеральную помощь пострадашим от наводнения, обязаны приобрести страхование от наводнения, чтобы соответствовать критериям на получение помощи в будущем.'
    },
    aside: { header: 'Узнайте ваш риск', link: 'Найдите вашу зону' }
  },
  subsidizedRateFullRate: {
    header: 'Субсидированный тариф против тарифа комплексного риска',
    body:
      'В зонах повышенного риска наводнения существует два разных вида страховых тарифов: субсидированный тариф пре-FIRM и стандартный тариф. Субсидированный тариф пре-FIRM действителен только для владельцев зданий, чья собственность была простроена до 16 ноября 1983г. (“пре-FIRM” потому что дом был построен до того, как FIRM было принято в 1983г.). Субсидированный тариф может быть меньше стандартного тарифа, однако, так как субсидии сокращаются, есть множество домов, для которых стандартный тариф на самом деле менее дорогостоящий.',
    aside: {
      header: 'Ежегодное повышение страховки',
      body:
        'Из-за двух недавних федеральных законодательств, закона Biggert-Waters от 2012г. и закона о доступности страхования жилищного строительства от 2014г. субсидированные тарифы повышаются на не меньше чем 15-18% в год.'
    }
  },
  newHomeowners: {
    header: 'Новые домовладельцы',
    body:
      'Когда вы покупаете новый дом, проверьте у продавца, есть ли у них полис страхования от наводнения. Держатели полиса могут передать их полис (и любые скидки) новому владельцу после того, как дом был продан.',
    aside: {
      header: 'Письмо поправки карты',
      body:
        'Если ваш дом находится в зоне повышенного риска наводнения, но высота земли непосредственно вокруг него находится выше Базовой высоты наводнения, вы можете подать в FEMA Письмо поправки карты, или LOMA, что является запросом на исключение вашей собственности из зоны наводнения. Вам понадобится сертификат высотной отметки в качестве доказательства.'
    }
  },
  whyMapsChanging: {
    header: 'КАРТЫ НЬЮ-ЙОРКА МЕНЯЮТСЯ',
    body:
      'FEMA периодически обновляет карты, которые оно создает для зон, подверженных затоплению по всей стране. Текущие карты для Нью-Йорка были приняты в 1983г. и они не точно отражают сегодняшний риск наводнения. На данный момент неясно, когда новые Карты тарифов страхования от наводнения вступят в силу или какую форму карты примут в конечном итоге. Нашим наилучшим предположением является то, что это произойдет в следующие от двух до пяти лет.',
    aside: {
      header: 'Оспаривание Нью-Йорка',
      body_before:
        'Город Нью-Йорк обжаловал Предварительную карту тарифов страхования от наводнения FEMA в июне 2015г., аргументируя это тем, что они переоценили риск наводнения во всех пяти районах Нью-Йорка. В октябре 2016г. FEMA объявило, что оно приняло городскую ',
      link: 'жалобу',
      body_after: ' и что оно пересмотрит карты на основании нового анализа данных.'
    }
  },
  learnMore: {
    body: 'Хотите получить дополнительную информацию? Прочитайте нашу статью ',
    link: 'Повышаются приливы, повышаются тарифы',
    href: 'https://cnycn.atavist.com/risingtides'
  }
};
