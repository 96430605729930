import sortingOptions from '../images/sorting-options.svg';
import structural from '../images/structural.svg';
import wrench from '../images/wrench.svg';
import speechBubbles from '../images/speech-bubbles.svg';

export default {
  header: 'Ak Kisa Pou Ou Atann',
  list: [
    {
      header: 'Evalye',
      imageAlt: 'building',
      body:
        'Yon fim jeni ki akredite pral evalye kay ou an epi ba ou yon rapò sou tolerans, ki prezante sa ou ka fè pou ou sekirize kont inondasyon.',
      image: structural
    },
    {
      header: 'Diskite',
      imageAlt: 'speech bubbles',
      body:
        'Nou ap pwogram yon tan pou ou pou pale ak konseye sou tolerans ou an, ki ka gide ou atravè rapò ou an epi opsyon ou yo.',
      image: speechBubbles
    },
    {
      header: 'Analize',
      imageAlt: 'controls',
      body:
        'Nou ap telechaje rapò ak sètifika elevasyon ou an pou ou pou analize, pou ka deside ki etap pou pran.',
      image: sortingOptions
    },
    {
      header: 'Aji',
      imageAlt: 'wrench',
      body:
        'Rapò ou an pral gen ladan l tout fason ou ka fòtifye kay ou an epi nou ka ede konekte ou ak nenpòt resous ou bezwen pou ou demare.',
      image: wrench
    }
  ]
};
