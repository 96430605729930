export default {
  header: 'ЧТО ВЫ МОЖЕТЕ СДЕЛАТЬ, ЧТОБЫ ПОНИЗИТЬ И ВАШ РИСК, И ТАРИФ',
  assumingMax: '*Из расчета максимального покрытия',
  numbersAreEstimates:
    'Эти цифры являются приблизительными по многим причинам. Чтобы узнать более подробную информацию о них обратитесь к нашим ',
  termsAndConditions: 'Правилам и условиям',
  noRecommendations: "К сожалению мы не смогли найти никаких вариантов",
  backwaterValveProvider: {
    header: {
      inProgress: 'Установить канализационный обратный клапан',
      complete: 'О вашем канализационном обратном клапане'
    },
    completeHeader: 'Установка завершена!',
    topBody:
      'Это поможет предотвратить попадание сточных вод в ваш дом в случае разлива канализации.',
    bottomBody:
      'Вы соответствуете требованиям на бесплатную установку канализационного обратного клапана.',
    bottomBodyComplete:
      'Мы хотели бы услышать от вас, как прошел процесс. Потратьте несколько минут, чтобы заполнить нашу анкету.',
    buttons: {
      learnMore: 'Дополнительная информация',
      startProcess: 'Начать процесс',
      feedbackSurvey: 'Анкета обратной связи'
    },
    steps: {
      initiate: 'Установка выполняется',
      schedule: 'Установка выполняется',
      install: 'Установка завершена!'
    }
  },
  elevateBuildingProvider: {
    header: 'Поднимите ваш дом',
    body: 'Располагает ваш дом в полной мере выше потенциального уровня наводнения',
    link: 'Дополнительная информация',
    lowerYourRate: 'Это снизит ваш тариф.',
    auditRateDifferenceProvider: {
      currentHeader: 'Для вашей текущей зоны',
      futureHeader: 'Для вашей ориентировочной зоны',
      saved: 'сохранен',
      perYear: ' /годы*',
      nonpositiveSavingsCopy: 'Это не снизит ваш тариф'
    }
  },
  abandonFirstFloorProvider: {
    header: 'Покиньте ваш первый этаж',
    body: 'Это эффективно повышает базовую высоту вашего дома.',
    link: 'Дополнительная информация',
    lowerYourRate: 'Это снизит ваш тариф.',
    auditRateDifferenceProvider: {
      currentHeader: 'Для вашей текущей зоны',
      futureHeader: 'Для вашей ориентировочной зоны',
      saved: 'сохранен',
      perYear: ' /годы*',
      nonpositiveSavingsCopy: 'Это не снизит ваш тариф'
    }
  },
  fillInBasementProvider: {
    header: 'Засыпьте ваш подвал',
    body: 'Уменьшает вред, наносимый структурному фундаменту вашего дома.',
    link: 'Дополнительная информация',
    lowerYourRate: 'Это снизит ваш тариф.',
    auditRateDifferenceProvider: {
      currentHeader: 'Для вашей текущей зоны',
      futureHeader: 'Для вашей ориентировочной зоны',
      saved: 'сохранен',
      perYear: ' /годы*',
      nonpositiveSavingsCopy: 'Это не снизит ваш тариф'
    }
  },
  elevateMechanicalsProvider: {
    header: 'Поднимите ваши механические устройства',
    body: 'Защищает критические системы вашего дома.',
    link: 'Дополнительная информация',
    lowerYourRate: 'Это снизит ваш тариф.',
    auditRateDifferenceProvider: {
      currentHeader: 'Для вашей текущей зоны',
      futureHeader: 'Для вашей ориентировочной зоны',
      saved: 'сохранен',
      perYear: ' /годы*',
      nonpositiveSavingsCopy: 'Это не снизит ваш тариф'
    }
  }
};
