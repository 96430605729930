import greenTelephone from '../images/green-telephone.svg';
import seaWaves from '../images/sea-waves.svg';
import strategy from '../images/strategy.svg';
import training from '../images/training.svg';

export default {
  header: 'LO QUE PUEDE HACER',
  callsToAction: [
    {
      header: 'Inicie su Plan de Continuidad Comercial Hoy',
      icon: strategy,
      iconAlt: 'estrategia',
      body:
        'Utilice este marco para recuperarse rápidamente después de cualquier interrupción. Proteja sus inversiones, manténgase en contacto con empleados y proveedores y esté preparado para responder a la incertidumbre.',
      buttonText: 'Ver',
      buttonLink:
        'https://www.nyc.gov/html/nycbe/downloads/pdf/EM_SBSBCPWorksheetsMar2017.pdf'
    },
    {
      header:
        'Asegúrese de que su Negocio esté Preparado. Asista a un Taller o Seminario Web.',
      icon: training,
      iconAlt: 'formación',
      body:
        'Desde fugas de gas e incendios hasta tormentas importantes y amenazas cibernéticas, los dueños de negocios de la ciudad de Nueva York enfrentan una variedad de emergencias inesperadas. SBS ofrece seminarios web y talleres gratuitos de preparación para emergencias para empresas de la ciudad de Nueva York.',
      buttonText: 'Aprende Más Aquí',
      buttonLink:
        'https://www1.nyc.gov/nycbusiness/article/preparedness-response-recovery'
    },
    {
      icon: seaWaves,
      iconAlt: 'olas',
      header: 'Amenaza Creciente del Cambio Climático',
      body:
        'Con el aumento del nivel del mar, los fenómenos meteorológicos más extremos y frecuentes provocados por un clima cambiante, ahora es el momento de empezar a prepararse. El cambio climático es una amenaza para nuestras comunidades y, como columna vertebral de nuestras comunidades y economía, las pequeñas empresas son un elemento vital para fomentar una ciudad resiliente.',
      buttonText: 'Lee Ahora',
      buttonLink:
        'https://onenyc.cityofnewyork.us/stories/a-livable-climate/#overview'
    },
    {
      icon: greenTelephone,
      iconAlt: 'teléfono',
      header:
        'Comuníquese con la Unidad de Respuesta a Emergencias de SBS para Obtener Asistencia Inmediata',
      body:
        'Para obtener asistencia inmediata, comuníquese con la Unidad de Respuesta a Emergencias de SBS. El equipo puede proporcionarle la información de emergencia más reciente sobre cierres de calles, interrupciones de servicios públicos, seguridad y demoliciones. También pueden conectarlo con organizaciones comerciales locales para obtener ayuda adicional.',
      buttonText: 'Aprende Más',
      buttonLink:
        'https://www1.nyc.gov/nycbusiness/article/preparedness-response-recovery'
    }
  ]
};
