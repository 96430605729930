export default {
  genericError: {
    title: 'Error',
    error: "We're sorry. We were unable to find information for the address you entered. Please verify the address or try again later. If you feel you received this message in error, please call our Homeowner Hub at 646-786-0888."
  },
  multipleResultsError: {
    title: 'Error',
    error: "We're sorry. There are multiple matches for the address you entered. Please verify the address or try again later. If you feel you received this message in error, please call our Homeowner Hub at 646-786-0888."
  },
  outsideNycError: {
    title: 'Error',
    error: "We're sorry. This address doesn't appear to match publicly available data from NYC. If you feel you received this message in error, please call our Homeowner Hub at 646-786-0888."
  },
  tooBroadError: {
    title: 'Error',
    error: 'FloodHelpNY.org provides information on flood insurance and resiliency options based on individual addresses. Please enter your home address or the address of another home you’d like to look up. Or, if you feel you received this message in error, please call our Homeowner Hub at 646-786-0888.'
  },
  noRouteError: {
    title: 'Error',
    error: "We're sorry. This address does not match publicly available data from Google Maps. If you feel you received this message in error, please call our Homeowner Hub at 646-786-0888."
  }
};
