import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '../styles/variables';
import { useProvider, useLocale } from '../helpers/hooks';
import EligibilityLookup from './EligibilityLookup';

const Links = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: ${(props) => (props.noSearchBar ? 'none' : '1 1 auto')};

  @media (max-width: 1299px) {
    display: none;
  }
`;

const Link = styled.a`
  cursor: pointer;
  font-family: GT-Walsheim-Pro-Medium;
  font-size: 1rem;
  margin-left: 2rem;
  color: ${colors.grayScale[4]};

  &:focus {
    text-decoration: none;
  }

  &:hover {
    color: ${colors.resilientBlue};
    cursor: pointer;
    text-decoration: none;
  }
`;
const DesktopNav = ({ noNavBarAddressInput }) => {
  const provider = useProvider('navBar');
  const locale = useLocale();

  return (
    <Links noSearchBar={noNavBarAddressInput}>
      <Link href={`/${locale}/understanding-flood-insurance`}>
        {provider.resources.insurance}
      </Link>
      <Link href={`/${locale}/flood-retrofits`}>
        {provider.resources.retrofits}
      </Link>
      <Link href={`/${locale}/small-business`}>
        {provider.resources.smallBusinesses}
      </Link>
      <Link href={`/${locale}/stormwater-flooding`}>
        {provider.resources.stormwaterFlooding}
      </Link>
      <Link href={`/${locale}/climate-change`}>
        {provider.resources.climateChange}
      </Link>
      <Link href={`/${locale}/prepare-for-hurricane-season`}>
        {provider.resources.hurricane}
      </Link>
      <Link href={`/${locale}/news`}>{provider.resources.news}</Link>
      {!noNavBarAddressInput && <EligibilityLookup navBar />}
    </Links>
  );
};

export default DesktopNav;

DesktopNav.propTypes = {
  noNavBarAddressInput: PropTypes.bool.isRequired
};
