export default {
    genericError: {
      title: 'Ошибка',
      error: "Мы сожалеем. Мы не смогли найти информацию о введенном вами адресе. Проверьте адрес или попробуйте еще раз позже. Если вам кажется, что вы получили это сообщение по ошибке, позвоните в нашу Центральную станцию домовладельцев по телефону 646-786-0888."
    },
    multipleResultsError: {
      title: 'Ошибка',
      error: "Мы сожалеем. Существуют множественные совпадения введенного вами адреса. Проверьте адрес или попробуйте еще раз позже. Если вам кажется, что вы получили это сообщение по ошибке, позвоните в нашу Центральную станцию домовладельцев по телефону 646-786-0888."
    },
    outsideNycError: {
      title: 'Ошибка',
      error: "Мы сожалеем. Данный адрес похоже не соответствует общественно-доступным данным города Нью-Йорк. Если вам кажется, что вы получили это сообщение по ошибке, позвоните в нашу Центральную станцию домовладельцев по телефону 646-786-0888."
    },
    tooBroadError: {
      title: 'Ошибка',
      error: 'Сайт FloodHelpNY.org предоставляет информацию о страховании от наводнения и вариантах устойчивости на основании индивидуальных адресов. Введите ваш домашний адрес или адрес, который вы хотите искать. Или если вам кажется, что вы получили это сообщение по ошибке, позвоните в нашу Центральную станцию домовладельцев по телефону 646-786-0888.'
    },
    noRouteError: {
      title: 'Ошибка',
      error: "Мы сожалеем. Данный адрес не соответствует общественно-доступным данным в Google Maps. Если вам кажется, что вы получили это сообщение по ошибке, позвоните в нашу Центральную станцию домовладельцев по телефону 646-786-0888."
    }
  };
  
