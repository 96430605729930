import React, { useState } from 'react';
import Fuse from 'fuse.js';
import styled from 'styled-components';
import { colors } from '../styles/variables';
import FaqItem from './FaqItem';
import { useProvider, useFaq } from '../helpers/hooks';

const Wrapper = styled.div`
  height: calc(100% - 10rem);
  background-color: ${colors.beachBeige};
`;

const SearchBar = styled.input`
  margin: 1rem 0;
  padding: 1rem;
  width: 100%;
  border: 1px solid ${colors.grayScale[1]};
`;

const Divider = styled.hr`
  border-top: 1px solid ${colors.grayScale[1]};
`;

const Items = styled.div`
  height: calc(100% - 6rem);
  overflow-y: scroll;
  background-color: ${colors.beachBeige};
  -ms-overflow-style: none;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const Faq = () => {
  const provider = useProvider('faq');
  const { faqs, faqZone } = useFaq();
  const [searchResults, setSearchResults] = useState(faqs);
  const [searchTerm, setSearchTerm] = useState('');
  const fuse = new Fuse(faqs, { keys: ['question', 'answer'] });

  const updateSearch = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    if (term === '') {
      setSearchResults(faqs);
    } else {
      const results = fuse.search(term);
      setSearchResults(results.map((result) => result.item));
    }
  };

  const renderFaqsByZone = () => {
    let results;

    if (faqZone) {
      const zoneFaqs = searchResults.filter((faq) => faq.zone === faqZone);
      const allOtherFaqs = searchResults.filter((faq) => faq.zone !== faqZone);
      results = [...zoneFaqs, ...allOtherFaqs];
    } else {
      results = searchResults;
    }

    return results.map((item, idx) => <FaqItem key={idx} item={item} />);
  };

  return (
    <Wrapper>
      <h6>{provider.header}</h6>
      <SearchBar
        placeholder={provider.searchBarPlaceholder}
        value={searchTerm}
        onChange={updateSearch}
      />
      <Divider />
      <Items>{renderFaqsByZone()}</Items>
      <Divider />
    </Wrapper>
  );
};

export default Faq;
