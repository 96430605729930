export default {
  meta: {
    title: 'Registrate',
    url: 'https://www.floodhelpny.org/en/register'
  },
  title: 'Risk Rating 2.0',
  subHeading:
    'Regístrese para un seminario web gratuito para obtener más información sobre el Risk Rating 2.0 y entienda como mantener seguros su hogar y finanzas.',
  riskRating:
    'El Risk Rating 2.0 es un nuevo sistema federal para establecer las primas de seguros contra inundaciones. Más del 60 % de los neoyorquinos que actualmente tienen pólizas podrían ver un aumento en los costos de sus seguros.',
  learnMore: 'Obtenga más información sobre el Risk Rating 2.0',
  register:
    'Regístrese para un seminario web gratuito el 13 de Septiembre de 2022 con Consejeros de Vivienda',
  brought: 'Traído a usted por',
  funding: {
    support: 'Con apoyo financiero por parte de FEMA',
    questions: '¿Preguntas? Contáctenos ',
    commsEmail: 'communications@cnycn.org'
  }
};
