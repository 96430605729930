import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SelectElementSingle from '../ui/SelectElementSingle';

const prefirmOptions = [
  { value: 1500, display: '$1,500' },
  { value: 2000, display: '$2,000' },
  { value: 3000, display: '$3,000' },
  { value: 4000, display: '$4,000' },
  { value: 5000, display: '$5,000' },
  { value: 10000, display: '$10,000' }
];

const postfirmOptions = [
  { value: 1000, display: '$1,000' },
  { value: 1250, display: '$1,250' },
  { value: 1500, display: '$1,500' },
  { value: 2000, display: '$2,000' },
  { value: 3000, display: '$3,000' },
  { value: 4000, display: '$4,000' },
  { value: 5000, display: '$5,000' },
  { value: 10000, display: '$10,000' }
];
export default class DeductibleOptions extends Component {
  constructor(props, context) {
    super(props, context);

    this._buildResponseField = this._buildResponseField.bind(this);
  }

  _buildResponseField() {
    let options;
    if (this.props.prefirm) {
      options = prefirmOptions;
    } else {
      options = postfirmOptions;
    }

    return {
      key: this.props.responseKey,
      options
    };
  }

  render() {
    const responseField = this._buildResponseField();
    return (
      <SelectElementSingle
        responseField={responseField}
        answer={this.props.answer}
        setResponse={this.props.setResponse}
      />
    );
  }
}
