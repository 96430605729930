import FloodZones from '../../constants/FloodZones';
import FloodZoneSeverityHelper from './FloodZoneSeverityHelper';

export default class BuildingFeatureNavigator {
  static nextComponent(results) {
    const lessSevereZone = FloodZoneSeverityHelper.getLessSevereZone(results);
    const moreSevereZone = FloodZoneSeverityHelper.getMoreSevereZone(results);

    if (lessSevereZone === FloodZones.NONE || lessSevereZone === FloodZones.X) {
      return this.handleXorNoneZone(results);
    }
    if (lessSevereZone === FloodZones.AE) {
      if (moreSevereZone === FloodZones.AE) {
        return 'numberOfSteps';
      }
      return 'elevatedBuilding';
    }
    if (lessSevereZone === FloodZones.VE) {
      return 'elevatedBuilding';
    }
  }

  static handleXorNoneZone(results) {
    const moreSevereZone = FloodZoneSeverityHelper.getMoreSevereZone(results);

    if (moreSevereZone === FloodZones.X || moreSevereZone === FloodZones.NONE) {
      return null;
    }
    if (moreSevereZone === FloodZones.AE) {
      return 'numberOfSteps';
    }
    if (moreSevereZone === FloodZones.VE) {
      return 'elevatedBuilding';
    }
  }
}
