export default {
  header: 'YOUR INSURANCE RATE, BASED ON YOUR ELEVATION CERTIFICATE',
  current: 'Current maps',
  future: 'Advisory maps',
  zone: 'zone',
  perYear: 'Per year*',
  assumingMax: '*Assuming max coverage',
  numbersAreEstimates:
    'These numbers are estimates for many reasons. To learn more about them go to our ',
  termsAndConditions: 'Terms & Conditions'
};
