export default {
  header: '您的保险费率，基于标高证明',
  current: '当前地图',
  future: '咨询地图',
  zone: '区域',
  perYear: '每年*',
  assumingMax: '*假设最大承保范围',
  numbersAreEstimates:
    '出于许多原因，这些数字是估计值。要了解更多信息，请访问 ',
  termsAndConditions: '条款'
};
