import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '../styles/variables';
import AuditRateDifference from './AuditRateDifference';
import ButtonLink from './ui/ButtonLink';

const Recommendation = styled.div`
  border-bottom: solid 1px ${colors.grayScale[1]};
  padding: 2rem 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  * {
    @media (max-width: 1200px) {
      margin-top: 1rem;
      text-align: center;

      :first-child {
        margin-top: 0;
      }
    }
  }

  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;

const Content = styled.div`
  padding-left: 1.5rem;
  flex-basis: 40%;

  > * {
    margin-top: 1rem;

    :first-child {
      margin-top: 0;
    }
  }

  @media (max-width: 1200px) {
    padding-left: 0;
  }
`;

const AuditRecommendation = ({
  provider,
  image,
  linkUrl,
  applicationId,
  audit,
  property
}) => (
  <Recommendation>
    <img src={image} alt="" />
    <Content>
      <h4>{provider.header}</h4>
      <p>{provider.body}</p>
      <div>
        <AuditRateDifference
          provider={provider.auditRateDifferenceProvider}
          property={property}
          audit={audit}
          applicationId={applicationId}
          useCurrent
        />
        <AuditRateDifference
          provider={provider.auditRateDifferenceProvider}
          property={property}
          applicationId={applicationId}
          audit={audit}
          useCurrent={false}
        />
      </div>
    </Content>
    <ButtonLink href={linkUrl} text={provider.link} ghost external />
  </Recommendation>
);

AuditRecommendation.propTypes = {
  provider: PropTypes.object.isRequired,
  linkUrl: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  applicationId: PropTypes.string.isRequired,
  audit: PropTypes.object.isRequired,
  property: PropTypes.object.isRequired
};

export default AuditRecommendation;
