import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import SalesforceCommunicator from '../communicators/SalesforceCommunicator';
import Matchers from '../constants/Matchers';
import Loading from './sharedComponents/Loading';
import ButtonInput from './ui/ButtonInput';
import { useProvider, useApi } from '../helpers/hooks';

const SignupWrapper = styled.div`
  display: flex;
  font-size: 1rem;

  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

const CallToAction = styled.p`
  color: white;
`;

const EmailInput = styled.input`
  margin-right: 1rem;
  padding: 0 1rem;
  min-width: 280px;
  height: 60px;

  @media (max-width: 1200px) {
    width: 100%;
    min-width: auto;
  }

  @media (max-width: 500px) {
    margin-bottom: 1rem;
  }
`;

const Success = styled.div`
  margin: 1rem;
`;

const EmailSignup = () => {
  const provider = useProvider('EmailSignup');
  const { salesforceApi } = useApi();

  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [hasError, setHasError] = useState(false);

  const inputRef = useRef();

  const updateEmail = (e) => {
    setEmail(e.target.value);
  };

  const submitEmail = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    if (email === '') {
      inputRef.current.focus();
      return;
    }
    try {
      // Returns success or failure
      await SalesforceCommunicator.registerEmailForUpdates(salesforceApi, {
        email
      });
      setIsLoading(false);
      setIsSuccessful(true);
    } catch (error) {
      setIsLoading(false);
      setHasError(true);
    }
  };

  const renderForm = () => (
    <>
      <CallToAction>{provider.heading}</CallToAction>
      <form onSubmit={submitEmail}>
        <SignupWrapper>
          <EmailInput
            type="email"
            ref={inputRef}
            onChange={updateEmail}
            placeholder={provider.placeholder}
            value={email}
            pattern={Matchers.emailInputPattern}
          />
          {isLoading ? (
            <Loading margin="1rem" />
          ) : (
            <ButtonInput text={provider.action} />
          )}
        </SignupWrapper>
        {hasError && <div>{provider.errorMessage}</div>}
      </form>
    </>
  );

  const renderSuccess = () => <Success>{provider.successMessage}</Success>;

  return <div>{isSuccessful ? renderSuccess() : renderForm()}</div>;
};

export default EmailSignup;
