import carpet from '../images/carpet.svg';
import piping from '../images/piping.svg';
import plumbing from '../images/plumbing.svg';
import temperatureInside from '../images/temperature-inside.svg';
import bacteria from '../images/bacteria.svg';
import fillInBasement from '../images/fill-in-basement.svg';
import elevate from '../images/elevate.svg';
import elevateMechanicals from '../images/elevate-mechanicals.svg';
import abandonFirstFloor from '../images/abandon-first-floor.svg';
import floodVents from '../images/flood-vents.svg';
import backwaterValve from '../images/backwater-valve.svg';

export default {
  meta: {
    title:
      'Renovasyon apre Inondasyon | Pwoteje Pwopriyete Ou an | FloodHelpNY.org',
    description: `Renovasyon apre inondasyon yo ka ede ou bese risk pou inondasyon ou an. Men sa ou bezwen konnen.`,
    url: 'https://www.floodhelpny.org/ht_HT/flood-retrofits',
    imageAlt: 'vag nan kot yo ak Vil New York'
  },
  learnMore: 'Aprann Plis',
  title: 'Renovasyon apre Inondasyon: Pwoteje Pwopriyete Ou an',
  pageSubtitle:
    'Gen yon kantite estrateji ou ka pran pou bese riks inondasyon ou an. Kèk ladan yo se gwo modifikasyon, epi lòt ka rapid epi solisyon senp pou redui domaj potansyèl apati dlo inondasyon yo.',
  subtitle:
    'Gen yon kantite estrateji ou ka pran pou bese riks inondasyon ou an. Kèk ladan yo se gwo modifikasyon, epi lòt ka rapid epi solisyon senp pou redui domaj potansyèl apati dlo inondasyon yo.',
  overview: {
    title: 'Apèsi',
    body:
      'Katye sou bò kot nou yo se yon gwo pati nan sa ki rann Vil New York san parèy. Litoral vil lan ki yon distans 520 mil (832 kilomèt) varye anpil soti nan yon katye pou al nan yon lòt — li chaje ak bungalow an kèk zòn, pil kay ki sou ran nan lòt. Varyasyon sa a nan dansite epi kalite lojman rann yon apwòch ak tolerans ak inondasyon kote yon sèl modèl lan valab pou tout yon bagay ki pa reyalis pou Vil New York. Erezman, gen anpil fason diferan pou fòtifye katye nou yo epi ranfòse kot nou.'
  },
  options: {
    title: 'Opsyon Adousisman',
    body:
      'Kèk opsyon adousisman koute plis pase lòt, kèk egzije plis efò pou adopte e kèk founi pi gwo avantaj pase lòt. Tout konpwomi sa yo ta dwe konsidere lè ou ap chwazi ki opsyon ki bon pou ou. Pou kounya, gen sèlman yon ponyen mezi ki gen yon efè sou prim asirans yo: elevasyon; ranplisaj yon sousòl oswa espas vid (e pwoteksyon pasyèl, jan sa nesesè); enstalasyon vantilasyon kont inondasyon; epi elevasyon ekipman mekanik yo andeyò sousòl lan (opsyon sa a souvan founi rediksyon asirans ki pi piti an, men li ka ekonomize ou yon pakèt lajan nan ka yon inondasyon). Gen plizyè lòt aksyon ou ka poze ki pa bese tarif asirans yo, men yo ka ede pwoteje kay ou kont domaj nan inondasyon. Fòk nou remake, okenn nan mezi sa yo pa ka redui prim yo kèlkeswa zòn inondasyon an.',
    list: [
      {
        title: 'Ranpli sousòl ou an',
        body: 'Redui domaj yo nan fondasyon estrikti kay ou an.',
        image: fillInBasement,
        imageAlt: 'Ranpli sousòl ou an',
        link: 'fill-in-basement'
      },
      {
        title: 'Elve pwopriyete ou an',
        body:
          'Mete pwopriyete ou an totalman anwo nivo dlo inondasyon ki prevwa.',
        image: elevate,
        imageAlt: 'Elve pwopriyete ou an',
        link: 'elevate'
      },
      {
        title: 'Elve sistèm mekanik ou yo',
        body: 'Pwoeje sistèm kritik kay ou an.',
        image: elevateMechanicals,
        imageAlt: 'Elve sistèm mekanik ou yo',
        link: 'elevate-mechanicals'
      },
      {
        title: 'Abandone premye etaj ou an',
        body: 'Sa elve nan yon fason efikas elevasyon debaz kay ou an.',
        image: abandonFirstFloor,
        imageAlt: 'Abandone premye etaj ou an',
        link: 'abandon-first-floor'
      },
      {
        title: 'Enstale vantilasyon kont inondasyon',
        body:
          'Redui risk pou domaj kont dlo inondasyon lè li pèmèt li koule epi seche.',
        image: floodVents,
        imageAlt: 'Enstale vantilasyon kont inondasyon',
        link: 'flood-vents'
      },
      {
        title: 'Enstale yon valv pou dlo k ap tounen',
        body:
          'Yon valv pou dlo k ap tounen fèt pou kenbe gwo dlo ize ki sot nan kay ou nan ka egou an debòde.',
        image: backwaterValve,
        imageAlt: 'Enstale yon valv pou dlo k ap tounen',
        link: 'backwater-valve'
      }
    ]
  },
  retrofitting: {
    title: 'Gid renovasyon pou planifikasyon iben',
    body:
      'Analiz ki pi global sou opsyon renovasyon ki disponib yo pou batiman nan zòn inondab Vil New York lan jiskaprezan.',
    view: 'Gade'
  },
  budget: {
    title: 'Adousisman nan yon Bidjè',
    subtitle:
      'Gen plizyè lòt aksyon ou ka poze ki pa bese tarif asirans yo, men yo ka ede redui domaj nan inondasyon.',
    list: [
      {
        header: 'Ranplase kapèt yo ak seramik',
        body:
          'Saldeben ou an enpèmeyab — poukisa sousòl ou an pa ka konsa tou? Si sousòl ou an gen seramik olye kapèt, netwayaj lan pi fasil e ou ap anpeche kèk pous dlo inondasyon fè gwo dega.',
        image: carpet
      },
      {
        header: 'Enstale aparèy pou anpeche dlo k ap tounen',
        body:
          'Yo relativman pa chè epi fasil pou enstale, aparèy ki pou anpeche dlo k ap tounen anpeche dlo egou ak dlo lapli antre nan kay ou atravè evye, twalèt ak benwa lè sistèm ki anviwonnen lakay ou depase dlo inondasyon an.',
        image: piping
      },
      {
        header: 'Enstale yon ponp sifon',
        body:
          'Ponp sifon ede seche devèsman ak dlo k ap antre nan batiman fant nan fondasyon, sifas ki pèmeyab ak lòt penetrasyon tankou kondui ki pase anba tè.',
        image: plumbing
      },
      {
        header: 'Pwoteksyon kont inondasyon anndan sistèm konstriksyon',
        body:
          'Sa fè apèl ak batiman pou kòlmataj pou kenbe dlo an deyò apati aparèy mekanik pasif oswa aktif tankou mi ano an beton toutotou yon chodyè, pòt oswa baryè enpèmeyab.',
        image: temperatureInside
      },
      {
        header: 'Enstale materyo ki rezistan ak domaj nan inondasyon',
        body:
          'Materyo tankou plak plat san papye ak revètman sòl an kawo terrazzo pou materyo konstriksyon ak amèbleman redui domaj nan inondasyon epi akselere netwayaj apre inondasyon. Opsyon sa a ka gen ladan l tou itilizasyon amwa ki pwoteje kont inondasyon epi ranplase atik ki fèt an bwa tankou pòt an metal oswa materyo sifasman ki reziste ak dlo.',
        image: bacteria
      }
    ]
  }
};
