export default {
  heading:
    'Jwenn mizajou sou chanjman kat inondasyon yo epi egzijans pou asirans yo isit lan nan NYC',
  placeholder: 'Antre imèl ou an',
  action: 'Anrejistre',
  errorMessage:
    'Nou dezole, te gen yon pwoblèm pou anrejistre pou notifikasyon. Tanpri eseye ankò.',
  successMessage: 'Enteresan, ou anrejistre kounya pou resevwa notifikasyon.',
  successCallToAction: 'Anrejistre ak yon lòt adrès imèl'
};
