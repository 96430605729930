export default {
  tellYourFriends: '告诉您的朋友和邻居',
  about: '关于',
  privacy: '隐私',
  termsAndConditions: '条款',
  widget: '小装置',
  multifamily: '多户家庭属性',
  cnycn: '纽约社区中心',
  facebook: '在Facebook上分享',
  twitter: '在Twitter上分享',
  email: '电子邮件',
  emailSubject: '立即查找您的洪水风险！',
  message:
    '您在纽约居住的地点并不重要——如果下雨，就会发生洪水。立即在以下网站查找洪水风险https://www.floodhelpny.org/!'
};
