import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import HashLinkObserver from 'react-hash-link';
import AuditAttachment from './AuditAttachment';
import { useProvider } from '../helpers/hooks';
import { Section } from './ui/StyledResourcePageElements';

// These keys must match provider keys
const DOC_TYPES = {
  technicalReport: 'technical report',
  triPartyAgreement: 'tri-party agreement',
  elevationCertificate: 'elevation certificate',
  homeownerGrantAgreement: 'grant agreement',
  fieldReport: 'field report'
};

const AuditDocuments = ({ attachments }) => {
  const provider = useProvider('auditDocuments');
  const [attachmentObjects, setAttachmentObjects] = useState([]);

  useEffect(() => {
    attachments.forEach((attachment) => {
      const title = attachment.title.toLowerCase();
      Object.keys(DOC_TYPES).forEach((keyword) => {
        if (title.includes(DOC_TYPES[keyword])) {
          setAttachmentObjects((prevState) => [
            ...prevState,
            {
              id: attachment.id,
              downloadUrl: attachment.download_url,
              docType: keyword
            }
          ]);
        }
      });
    });
  }, [attachments]);

  // If there are no docTypes or matching docTypes early return
  if (attachmentObjects.length <= 0) {
    return null;
  }

  const renderAttachments = () =>
    attachmentObjects.map((attachment, index) => {
      return (
        <AuditAttachment
          key={attachmentObjects[index].id}
          attachment={attachmentObjects[index]}
        />
      );
    });

  return (
    <Section id="audit-documents">
      <HashLinkObserver />
      <h5>{provider.header}</h5>
      {renderAttachments()}
    </Section>
  );
};

AuditDocuments.propTypes = {
  attachments: PropTypes.array
};

AuditDocuments.defaultProps = {
  attachments: []
};

export default AuditDocuments;
