export default {
  meta: {
    title: 'FloodHelpNY Widget',
    url: 'https://www.floodhelpny.org/en/widget-information',
    description:
      'Download our FloodHelpNY widget today and help your visitors learn about their flood risk'
  },
  header: 'FloodHelpNY Widget',
  subHeader:
    'Download our FloodHelpNY widget today and help your visitors learn about their flood risk',
  overview: {
    title: 'Overview',
    subtitle: `Download our FloodHelpNY widget today and help your visitors learn about their flood risk. FloodHelpNY’s easily installable, secure widget will give current and future homeowners, renters, and small business owners a seamless way to learn more about their flood risk. FloodHelpNY’s widget offers visitors the opportunity to:`
  },
  widget: {
    title: 'THE WIDGET',
    aside: {
      description:
        'The widget is easy to install and secure. Fill out the form below to receive the embed code and instructions.'
    }
  },
  maps:
    '<b>View FEMA’s current and advisory flood maps</b> for your New York City property',
  reflection: '<b>Get a reflection</b> of their property’s flood risk',
  required:
    '<b>Find out if they are required to get flood insurance</b> if they have a federally backed mortgage',
  decisions:
    '<b>Make informed decisions</b> about whether to purchase flood insurance',
  request: {
    title: 'REQUEST THE WIDGET',
    description:
      'When downloading our widget, users will be asked to fill out a form and submit their name, organization name, and email address. Users will also be asked to agree to our terms and conditions.'
  },
  form: {
    name: 'Name',
    namePlaceholder: 'Full name',
    org: 'Organization',
    orgPlaceholder: 'Organization',
    email: 'Email',
    emailPlaceholder: 'e.g. name@example.com',
    terms: 'I agree to the',
    termsLink: ' terms and conditions',
    thankYou: 'Thank you for applying, we will contact you soon.',
    errors: {
      nameError: 'Full name is required',
      orgError: 'Organization is required',
      emailError: 'Email Address is required',
      termsError: 'Accepting terms and conditions is required',
      submitError:
        'An error occurred when submitting the form. Please try again.'
    }
  }
};
