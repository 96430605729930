export default {
  meta: {
    title: 'Hurricane season is here | FloodHelpNY.org',
    url: 'https://www.floodhelpny.org/en/prepare-for-hurricane-season',
    description:
      'Take four easy steps today to protect your home and finances from flooding'
  },
  header: 'Hurricane season is here',
  subHeader: 'Take four easy steps today to protect your home from flooding',
  getInformed:
    '<b>Get Informed</b> Register at <a href="https://a858-nycnotify.nyc.gov/notifynyc/" target="__blank">Notify NYC</a> to receive official storm updates and learn about city services available to you in 13 different languages. You can also stay informed by downloading the Notify NYC app (<a href="https://apps.apple.com/us/app/notify-nyc/id1121367950?ls=1" target=""__blank>iOS</a> and <a href="https://play.google.com/store/apps/details?id=gov.nyc.oem.notifynyc&pli=1" target=""__blank>Android</a>) or following Notify NYC on X (formerly Twitter, in <a href="https://x.com/NotifyNYC" target=""__blank>English</a>, <a href="https://twitter.com/NNYCSpanish" target=""__blank>Spanish</a>, and <a href="https://twitter.com/NNYCChinese" target=""__blank>Chinese</a>).<br/><br/>Find out how a storm will affect New York City by visiting the <NYC href="https://www.nyc.gov/site/severeweather/index.page" target=""__blank>NYC Severe Weather</a> and <a href="https://www.weather.gov/okx/" target="__blank">National Weather Service</a> websites.',
  makePlan:
    '<b>Make a Plan</b> Visit NYC’s <a href="https://maps.nyc.gov/hurricane/" target="__blank">Hurricane Evacuation Zone Finder</a> to learn if you live in a neighborhood that will be evacuated if affected by a severe storm or hurricane. Knowing your zone ahead of time will make you and your family safer and more prepared if the city issues evacuation orders.<br/><br/>Visit the city’s <a href="https://www.nyc.gov/site/em/ready/guides-resources.page" target=""__blank">Ready New York</a> page to get more help with creating an emergency storm plan.<br/><br/>A hurricane or severe storm can result in the loss of electricity or other utilities that can threaten your safety. Please read the <a href="https://www.nyc.gov/site/em/ready/utility-disruptions.page" target="__blank">NYC Emergency Management’s</a> guidance on preparing for utility disruptions. ',
  gather:
    '<b>Gather Supplies</b> If you and your family are forced to shelter-in-place or move to an evacuation center, NYC Emergency Management recommends that you maintain at least a week’s supply of food, medicine, medical equipment and other essential items.',
  reportDamage:
    '<b>Report Storm Damage.</b> If you experience property damage after a severe storm or other disaster, complete the Damage Information Form on the <a href="https://reportdamage.nyc.gov/" target="__blank">NYC Housing Recovery Operations</a> (HRO) website.',
  posterCallToAction: 'Get the poster',
  posterCopy: 'Download and print your own copy of the checklist here.',
  posterButton: 'Download',
  posterUrl: '/samples/FloodHelpNY-Prepare-Poster-2020.pdf',
  camera: 'Camera',
  waves: 'Waves'
};
