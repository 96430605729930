import FloodZones from '../constants/FloodZones';

// Used in Rate Calculator
const LomaHelper = {
  showLomaCallout: (property) => {
    const currentFloodZone = property.current_flood_zone;
    if (
      currentFloodZone === FloodZones.X ||
      currentFloodZone === FloodZones.NONE
    ) {
      return false;
    }
    if (parseFloat(property.current_bfe) < parseFloat(property.lag)) {
      return true;
    }
    return false;
  }
};

export default LomaHelper;
