import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import OtherResources from './OtherResources';
import ButtonLink from './ui/ButtonLink';
import coastline from '../images/coastline.png';
import cityPlanningAndRetrofitting from '../images/city-planning-and-retrofitting.png';
import PrimaryAndAsideContainer from './layout/PrimaryAndAsideContainer';
import { colors } from '../styles/variables';
import {
  HeaderSection,
  Subheader,
  Section,
  SectionIntro,
  SectionDescription
} from './ui/StyledResourcePageElements';
import Card from './layout/Card';
import ListWithIconAndText from './layout/ListWithIconAndText';
import { useProvider, useLocale, useFaq } from '../helpers/hooks';

const Steps = styled.ul`
  border: 10px solid ${colors.grayScale[0]};
  padding: 2rem 3rem;
  margin: 3rem 0;
  list-style-type: none;

  @media (max-width: 1200px) {
    padding: 2rem 1rem;
  }
`;

const Step = styled.li`
  display: flex;
  justify-content: space-between;
  padding: 2rem 0;
  align-items: center;
  border-bottom: 1px solid ${colors.grayScale[1]};

  :first-child {
    padding-top: 0;
  }

  :last-child {
    padding-bottom: 0;
    border: none;
  }

  @media (max-width: 1200px) {
    flex-direction: column;
    text-align: center;

    > * {
      margin: 1rem 0;
    }
  }
`;

const StepDescription = styled.div`
  width: 100%;
  flex-basis: 45%;

  @media (max-width: 1200px) {
    flex-basis: auto;
  }
`;

const DiagramWrapper = styled.div`
  display: flex;
  flex-basis: 20%;
  justify-content: center;
`;

const AsideImageWrapper = styled.aside`
  img {
    max-width: 335px;
    margin: 1rem;

    @media (max-width: 500px) {
      width: 100%;
      margin: 0;
    }
  }
`;

const Retrofits = () => {
  const provider = useProvider('retrofits');
  const locale = useLocale();
  const { setFaqZone } = useFaq();

  useEffect(() => {
    setFaqZone('resiliency');
  }, []);

  return (
    <>
      <Helmet>
        <title>{provider.meta.title}</title>
        <link rel="canonical" href={provider.meta.url} />
        <meta property="og:title" content={provider.meta.title} />
        <meta name="description" content={provider.meta.description} />
        <meta property="og:title" content={provider.meta.title} />
        <meta property="og:description" content={provider.meta.description} />
        <meta
          property="og:image"
          content="https://www.floodhelpny.org/images/retrofits.png"
        />
        <meta property="og:url" content={provider.meta.url} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image:alt" content={provider.meta.imageAlt} />
      </Helmet>
      <HeaderSection>
        <h1>{provider.title}</h1>
        <Subheader>{provider.pageSubtitle}</Subheader>
      </HeaderSection>
      <Section>
        <PrimaryAndAsideContainer>
          <SectionIntro>
            <h5>{provider.overview.title}</h5>
            <SectionDescription>{provider.overview.body}</SectionDescription>
          </SectionIntro>
          <AsideImageWrapper>
            <img src={coastline} alt="NYC coastline" />
          </AsideImageWrapper>
        </PrimaryAndAsideContainer>
      </Section>
      <Section>
        <SectionIntro>
          <h5>{provider.options.title}</h5>
          <SectionDescription>{provider.options.body}</SectionDescription>
        </SectionIntro>
        <Steps>
          {provider.options.list.map((option, idx) => (
            <Step key={idx}>
              <DiagramWrapper>
                <img alt={option.imageAlt} src={option.image} />
              </DiagramWrapper>
              <StepDescription>
                <h4>{option.title}</h4>
                <p>{option.body}</p>
              </StepDescription>
              <ButtonLink
                url={`/${locale}/mitigation/${option.link}`}
                text={provider.learnMore}
                ghost
              />
            </Step>
          ))}
        </Steps>
        <Card>
          <img alt="city planning" src={cityPlanningAndRetrofitting} />
          <div>
            <h4>{provider.retrofitting.title}</h4>
            <p>{provider.retrofitting.body}</p>
          </div>
          <ButtonLink
            url="http://www1.nyc.gov/site/planning/plans/retrofitting-buildings/retrofitting-buildings.page"
            text={provider.retrofitting.view}
            external
          />
        </Card>
      </Section>
      <Section>
        <SectionIntro>
          <h5>{provider.budget.title}</h5>
          <SectionDescription>{provider.budget.subtitle}</SectionDescription>
        </SectionIntro>
        <ListWithIconAndText items={provider.budget.list} />
      </Section>
      <OtherResources />
    </>
  );
};

export default Retrofits;
