// This emergency notificiation will automatically display on the site when the
// `text` value below is truthy. Otherwise, the notification will not show.

export default {
  text:
    'El kit de herramientas de acción comunitaria contra inundaciones está aquí.',
  url: '/es/news',
  buttonText: 'Consiga el suyo hoy',
  external: false
};
