import BuildingSizes from '../../constants/BuildingSizes';

export default class PreferredRiskRateNavigator {
  static nextComponent(results) {
    if (results.preferredRiskRate) {
      if (results.buildingSize === BuildingSizes.SINGLE_FAMILY) {
        return 'buildingFeature';
      }
      return 'contentLocation';
    }

    if (results.preFirm) {
      return 'severeRepetitiveLoss';
    }

    if (results.buildingSize === BuildingSizes.SINGLE_FAMILY) {
      return 'buildingFeature';
    }
    return 'contentLocation';
  }
}
