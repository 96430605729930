import styled from 'styled-components';
import { colors } from '../../styles/variables';

const breakpoint = '700px';

const HeaderSection = styled.section`
  padding: 150px 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 1000px;
  margin: 8rem auto 0 auto;

  > * {
    margin-bottom: 2rem;
    text-align: center;

    :last-child {
      margin-bottom: 0;
    }
  }

  @media (max-width: ${breakpoint}) {
    margin-top: 0;
    padding-bottom: 5rem;
  }
`;

// specifically for subheaders in HeaderSections
const Subheader = styled.p`
  font-size: 2rem;

  @media (max-width: ${breakpoint}) {
    font-size: 1.5rem;
  }
`;

const Section = styled.section`
  border-top: ${(props) =>
    props.noBorder ? 'none' : `1px solid ${colors.grayScale[1]}`};
  padding-top: ${(props) => (props.noBorder ? 0 : '3rem')};
  margin: 4rem 10rem;
  background-color: ${(props) => props.beigeSection && colors.beachBeige}};
  padding: ${(props) => props.beigeSection && '4rem 0rem'}};
  
  > * {
    margin: 3rem 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &:first-of-type {
    margin-top: 0;
  }

  @media (max-width: ${breakpoint}) {
    margin: 4rem 1rem;
  }
`;

const SectionIntro = styled.div`
  max-width: 1000px;

  @media (max-width: ${breakpoint}) {
    text-align: center;
  }
`;

const SectionDescription = styled.p`
  margin-top: 2rem;
`;

export { HeaderSection, Subheader, Section, SectionIntro, SectionDescription };
