import certificate from '../images/certificate.svg';
import compass from '../images/compass.svg';
import home from '../images/home.svg';

export default {
  meta: {
    title: 'Sètifika Elevasyon | FloodHelpNY.org',
    description:
      'Sètifika elevasyon ka ede pwoteje ou pou pa peye depase sou asirans kont inondasyon.',
    ogDescription:
      'Yon sètifika elevasyon se yon dokiman ki pwoteje pwopriyetè kay yo pou pa peye depase sou asirans kont inondasyon.',
    url: 'https://www.floodhelpny.org/ht_HT/elevation-certificates'
  },
  title: 'Sètifika Elevasyon yo',
  subheader:
    'Yon sètifika elevasyon ka ede ou bese tarif asirans ou an. Men ki lòt bagay ou ta dwe konnen apwopo dokiman kle sa a.',
  overview: {
    header: 'Apèsi',
    body:
      'Yon sètifika elevasyon se yon dokiman ki pwoteje pwopriyetè kay yo pou pa peye depase sou asirans kont inondasyon. FEMA reglemante l epi yon enjenyè oswa yon apantè reyalize l. Done yo ranmase nan sètifika elevasyon an pèmèt konpayi asirans yo pou evalye yon risk inondasyon pou yon pwopriyete ak plis presizyon nan verifye elevasyon kay lan epi si tout efò te fèt pou minimize efè inondasyon yo.'
  },
  about: [
    {
      header: 'Ki enfòmasyon ki sou sètifika elevasyon an',
      body:
        'Twa chan done kle nan sètifika elevasyon an se kalite ak karakteristik batiman an, etaj ki pi ba a ak anplasman pwopriyete an Faktè sa yo jwe yo tout wòl enpòtan nan detèmine prim asirans kont inondasyon ou an.',
      image: certificate
    },
    {
      header: 'Kijan pou itilize yon sètifika elevasyon',
      body:
        'Si ou swete itilize sètifika ou an, ou ta dwe founi l bay ajan asirans ou an nan espas 90 jou apati dat final ki parèt sou dokiman an. Baze sou enfòmasyon sou sètifika ou an, si ou kalifye pou yon prim ki pi ba ajan asirans ou an dwe fè ou peye prim ki pi ba a.',
      image: compass
    },
    {
      header: 'Kiyès ki bezwen anfèt yon sètifika elevasyon',
      body:
        'Ak chanjman FEMA mete sou pye yo atravè Evalyasyon Risk 2.0, sètifika elevasyon yo pa obligatwa ankò pou okenn pwopriyetè pwopriyete. Toutfwa, sètifika elevasyon yo ka toujou valab pou asire ou ap resevwa bon pri asirans kont inondasyon an. Yo ka sèvi tou pou dokimante founisè asirans ou an ak tout chanjman enpòtan nan pwopriyete an ou gendwa fè -- pa egzanp: elevasyon kay ou an, enstalasyon vantilasyon kont inondasyon oswa elevasyon sistèm ekipman yo — nenpòt nan sa yo ki gendwa ede bese pri asirans kont inondasyon ou an.',
      image: home
    }
  ],
  howToGetOne: {
    header: 'Kijan pou jwenn yon sètifika elevasyon',
    body:
      "Pri mwayen yon sètifika elevasyon ka soti nan $500 pou rive plis pase $1,000. Eseye jwenn pwofòma pri apati omwen de apantè pou konparezon. Egzanp apantè pwofesyonèl gen ladan yo yon apantè teren, enjenyè oswa achitèk ki lisansye ki otorize selon lalwa pou reyalize yon sètifika elevasyon. FEMA rekòmande pou jwenn yon apantè pwofesyonèl nan verifye <a href='https://www.nysapls.org/search/custom.asp?id=2216' target='_blank' rel='noreferrer noopener'>asosasyon pwofesyonèl nan Eta pou apantè teren</a> epi direktiv referansman apati <a href='https://www1.nyc.gov/site/housingrecovery/programs/elevation-certificate.page' target='_blank' rel='noreferrer noopener'>Depatman Batiman yo.</a>",
    tip: {
      header: 'KONSÈY',
      body:
        'Pwopriyetè kay anvan an gendwa deja gen yon sètifika; si ou nan pwosesis pou achte pwopriyete an oswa te achte pwopriyete an dènyeman, ou gendwa vle kontakte yo.'
    }
  },
  alreadyHaveOne: {
    header: 'Si ou deja gen yon sètifika elevasyon',
    body:
      'Si ou gen yon sètifika elevasyon, ou pa bezwen soumèt yon nouvo sètifika. Toutfwa, si ou te fè nenpòt chanjman nan pwopriyete an pou minimize efè inondasyon yo, ou gendwa vle yon sètifika ki ajou pou asire ou resevwa pri kòrèk lan.'
  },
  expired: {
    header: 'Sètifika mwen an di li ekspire nan lane 2018. Èske l itil?',
    body:
      'Dat ki nan kwen anwo adwat sètifika ou an se dat modèl lan, FEMA jeneralman mete l ajou chak twazan. Dat lan asire enjenyè ak apantè teren yo bay sètifika elevasyon yo apati modèl aktyèl lan. Si ou gen yon ansyen sètifika ak dat sa a, li toujou bon pou objektif evalyasyon. Si ou ap jwenn yon sètifika elevasyon pou premye fwa oswa ou ap jwenn yon sètiifika elevasyon ki ajou, ou ap vle asire ou enjenyè oswa apantè teren ou an itilize yon fòmilè ki gen dat 30 novanm 2022 an. Ou ka kontinye itilize fòmilè 30 novanm 2022 an jouk FEMA bay yon nouvo vèsyon (pafwa sa ka pran plizyè lane apre dat ekspirasyon an).'
  },
  download: 'Telechaje Sètifika a',
  update: 'Mete Sètifika Ou an Ajou'
};
