import addressInput from './AddressInput.es';

export default {
  meta: {
    title: 'Explore los mapas de la zona de inundación de Nueva York',
    url: 'https://www.floodhelpny.org/es/explore-map',
    description:
      'Explore los mapas de zonas de inundación de Nueva York para comprender su riesgo actual y futuro',
    imageAlt: 'mapa explorable'
  },
  loadingText: '',
  changeLinkText: 'cambio',
  currentMaps: 'Mapas vigentes',
  futureMaps: 'Mapas de advertencia',
  addressInput,
  legend: {
    label: 'CLAVE:',
    minimalHazard: 'Peligro mínimo',
    moderateRisk: 'Riesgo moderado',
    highRisk: 'Riesgo alto ',
    veryHighRisk: 'Riesgo máximo ',
    copy:
      'El mapa muestra los mapas de inundaciones vigentes y de advertencia para la Ciudad de Nueva York según lo propuesto por ',
    linkCopy: 'FEMA',
    linkUrl: 'https://www.fema.gov'
  },
  showPopup: 'Mostrar zona de inundación',
  hidePopup: 'Ocultar zona de inundación',
  errorText:
    '¡Vaya, parece que Google está teniendo problemas para devolver datos a nuestro mapa! Por favor, inténtelo de nuevo más tarde.'
};
