import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import HashLinkObserver from 'react-hash-link';
import house from '../images/where-your-house-should-be.svg';
import arrow from '../images/arrow.svg';
import AuditCounselorNotes from './AuditCounselorNotes';
import { useProvider } from '../helpers/hooks';
import { colors } from '../styles/variables';
import { Section } from './ui/StyledResourcePageElements';

const Content = styled.div`
  border: 10px solid ${colors.grayScale[0]};
  padding: 4rem;
  margin-top: 3rem;
`;

const HouseWrapper = styled.div`
  width: 314px;
  margin: 2rem auto;
  font-size: 1rem;
  font-family: GT-Walsheim-Pro-Medium;
  color: ${colors.grayScale[3]};
`;

const Data = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  text-align: center;
  border-top: 1px solid ${colors.grayScale[1]};
  padding-top: 2rem;

  > * {
    @media (max-width: 1500px) {
      margin-top: 2rem;
    }
  }

  @media (max-width: 1500px) {
    flex-direction: column;
    align-items: center;
    padding-top: 0;
  }
`;

const ArrowImage = styled.img`
  @media (max-width: 1500px) {
    transform: rotate(90deg);
  }
`;

const AboveSeaLevel = styled.div`
  text-align: center;
`;

const TopOfBottomFloor = styled.div`
  h1 {
    color: ${colors.grayScale[3]};
  }
`;

const BaseFloodElevation = styled.div`
  h1 {
    color: ${colors.pinkBerry};
  }
`;

const Freeboard = styled.div`
  h1 {
    color: ${colors.grayScale[5]};
  }
`;

const RedBar = styled.div`
  font-size: 1rem;
  font-family: GT-Walsheim-Pro-Medium;
  position: relative;
  border-bottom: 3px solid ${colors.pinkBerry};
  z-index: 5;
  bottom: 100px;
  color: ${colors.pinkBerry};
  text-align: right;
`;

const NGVD_1929_TO_NAVD_1988_CONVERSION = -1.1;

const AuditPropertyRecommendation = ({
  topOfBottomFloor,
  baseFloodElevation,
  freeboardCity,
  notes
}) => {
  const provider = useProvider('auditPropertyRecommendation');

  const [positiveFfeDifference, setPositiveFfeDifference] = useState(null);
  const [formattedTopOfBottomFloor, setFormattedTopOfBottomFloor] = useState(
    ''
  );
  const [roundedBfe, setRoundedBfe] = useState('');

  useEffect(() => {
    const topOfBottomFloorSum =
      topOfBottomFloor + NGVD_1929_TO_NAVD_1988_CONVERSION;
    const formattedTopOfBottomFloorSum = topOfBottomFloorSum
      ? `${Math.round(`${topOfBottomFloorSum}e+2`)}'e-2`
      : 'No Results';
    setFormattedTopOfBottomFloor(formattedTopOfBottomFloorSum);

    const roundedBfeSum =
      baseFloodElevation + NGVD_1929_TO_NAVD_1988_CONVERSION + freeboardCity;
    const formattedRoundedBfe = roundedBfeSum
      ? `${Math.round(roundedBfeSum)}'`
      : 'No Results';
    setRoundedBfe(formattedRoundedBfe);

    if (baseFloodElevation - topOfBottomFloor > 0) {
      setPositiveFfeDifference(true);
    }
    setPositiveFfeDifference(false);
  }, [baseFloodElevation, topOfBottomFloor, freeboardCity]);

  const displayRedBar = () => {
    if (!positiveFfeDifference) {
      return null;
    }

    return <RedBar>DFE {roundedBfe}</RedBar>;
  };

  return (
    <Section id="property">
      <HashLinkObserver />
      <h5>{provider.header}</h5>
      <div>
        <Content>
          <div>
            <HouseWrapper>
              <img src={house} alt="house" />
              <AboveSeaLevel>
                {`${
                  provider.baseFloodElevationExplainedPre +
                  formattedTopOfBottomFloor
                }
                ${provider.baseFloodElevationExplainedPost}`}
              </AboveSeaLevel>
              {displayRedBar()}
            </HouseWrapper>
            <Data>
              <TopOfBottomFloor>
                <h1>{formattedTopOfBottomFloor}</h1>
                <p>{provider.topOfBottomFloor}</p>
              </TopOfBottomFloor>
              <ArrowImage src={arrow} alt="arrow" />
              <BaseFloodElevation>
                <h1>{roundedBfe}</h1>
                <p>{provider.baseFloodElevation}</p>
              </BaseFloodElevation>
              <ArrowImage src={arrow} alt="arrow" />
              <Freeboard>
                <h1>{`${freeboardCity}’`}</h1>
                <p>{provider.freeboardCity}</p>
              </Freeboard>
            </Data>
          </div>
        </Content>
        <AuditCounselorNotes body={notes} />
      </div>
    </Section>
  );
};

AuditPropertyRecommendation.propTypes = {
  topOfBottomFloor: PropTypes.number.isRequired,
  baseFloodElevation: PropTypes.number.isRequired,
  freeboardCity: PropTypes.number.isRequired,
  notes: PropTypes.object.isRequired
};

export default AuditPropertyRecommendation;
