export default {
  changeLinkText: 'change',
  currentMaps: 'Current Maps',
  futureMaps: 'Advisory Maps',
  legend: {
    label: 'KEY:',
    minimalHazard: 'Minimal hazard',
    moderateRisk: 'Moderate risk',
    highRisk: 'High risk',
    veryHighRisk: 'Highest risk',
    floodingKey: ['Minimal hazard', 'Moderate risk', 'High Risk', 'Highest risk (flooding & waves)'],
    stormwaterKey: ['Moderate stormwater flooding hazard', 'High stormwater flooding hazard'],
    copy:
      'The map shows the current and advisory maps zones for New York City. Source: ',
    linkCopy: 'FEMA',
    linkUrl: 'https://www.fema.gov'
  },
  loadingText: 'Loading...'
};
