import BuildingFeatures from '../../constants/BuildingFeatures';
import DefaultCalculatorParamsHelper from './DefaultCalculatorParamsHelper';

const FillInYourBasementHelper = {
  showMitigation: (property) => {
    if (property.building_feature) {
      return (
        [
          BuildingFeatures.BASEMENT,
          BuildingFeatures.ELEVATED_ON_CRAWLSPACE_NO_FLOOD_VENTS,
          BuildingFeatures.SUBGRADE_CRAWLSPACE
        ].indexOf(property.building_feature) !== -1
      );
    }
    return property.basement === true;
  },
  formatMitigatedRate: (property, useCurrent) => {
    const formattedParams = DefaultCalculatorParamsHelper.formatCalculatorParameters(
      property,
      useCurrent
    );
    formattedParams.elevation_difference =
      formattedParams.elevation_difference +
      formattedParams.basement_height +
      1;
    if (!property.steps_up) {
      formattedParams.elevation_difference -=
        formattedParams.number_of_steps * (8 / 12);
    }
    formattedParams.building_feature = BuildingFeatures.NO_BASEMENT_ENCLOSURE;
    return formattedParams;
  }
};

export default FillInYourBasementHelper;
