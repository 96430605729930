export default {
  multifamilyApplication: 'https://www.tfaforms.com/439873',
  addressInput: {
    placeholder: 'Искать ваш адрес',
    action: 'Проверьте если вы предварительно соответствуете критериям'
  },
  eligible: {
    eligible: 'Хорошая Новость! Вы возможно соответствуете критериям!',
    apply: 'Подать заявку сейчас'
  },
  ineligible: {
    ineligible: 'К сожалению, ваш дом не находится в районе, обслуживаемом программой в данный момент. Получите дополнительную информацию о вашем риске наводнения',
    callToAction: ' здесь.'
  },
  ineligibleOutsideNYC: {
    copy: 'Оплошность! Данное программное средство работает только с адресами города Нью-Йорк. Нажмите на кнопку доступа к национальным ресурсам.',
    link: 'Посетить сайт FloodSmart.gov'
  },
  ineligibleTooBroad: {
    copy: "Мы сожалеем. Мы не смогли найти информацию о введенном вами адресе. Проверьте адрес или попробуйте еще раз позже."
  },
  termsAndConditions: {
    title: 'Принять правила и условия и политику конфиденциальности',
    disclaimerOne: '1. Мы не можем гарантировать точность вашей зоны',
    disclaimerTwo: '2. Оценка вашего страхования от наводнения может быть неточной',
    links: {
      sentenceOpener: 'Дополнительная информация в наших ',
      termsLinkText: 'Правилах и условиях',
      termsLinkUrl: 'terms',
      conjunction: ' and ',
      privacyLinkText: 'Политике конфиденциальности',
      privacyLinkUrl: 'Политике '
    },
    navigation: {
      agree: 'Я принимаю',
      disagree: 'Я не принимаю'
    }
  },
  lookupError: {
    title: 'Ошибка',
    error: "Мы сожалеем. Мы не смогли найти информацию о введенном вами адресе. Проверьте адрес или попробуйте еще раз позже.",
    tryAgain: 'Повторить попытку'
  },
  multipleResultsError: {
    title: 'Ошибка',
    error: "Мы сожалеем. Существует несколько соответствий введенному вами адресу. Проверьте адрес или попробуйте еще раз позже.",
    tryAgain: 'Повторить попытку'
  }
};
