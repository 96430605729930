export default {
  multifamilyApplication: 'https://www.tfaforms.com/439873',
  addressInput: {
    placeholder: 'Chèche adrès ou an',
    action: 'Gade Si Ou Prekalifye'
  },
  eligible: {
    eligible: 'Bon nouvèl! Ou gendwa kalifye!',
    apply: 'Aplike Kounya'
  },
  ineligible: {
    ineligible:
      'Malerezman, lakay ou pa nan yon katye pwogram sa a sèvi aktyèlman. Tanpri aprann plis apwopo risk pou inondasyon ou',
    callToAction: ' an la.'
  },
  ineligibleOutsideNYC: {
    copy:
      'Oops! Zouti sa a travay sèlman pou adrès NYC yo. Klike sou bouton pou resous nasyonal yo.',
    link: 'Vizite FloodSmart.gov'
  },
  ineligibleTooBroad: {
    copy:
      'Nou dezole. Nou pa t kapab jwenn enfòmasyon pou adrès ou te antre an. Tanpri verifye adrès lan oswa eseye ankò pita.'
  },
  termsAndConditions: {
    title: 'Dakò ak Tèm epi Kondisyon yo ak Politik sou Vi Prive',
    disclaimerOne: '1. Nou pa ka garanti presizyon zòn ou an',
    disclaimerTwo:
      '2. Estimasyon asirans kont inondasyon ou an gendwa pa presi',
    links: {
      sentenceOpener: 'Aprann plis nan ',
      termsLinkText: 'Tèm ak Kondisyon yo',
      termsLinkUrl: 'terms',
      conjunction: ' and ',
      privacyLinkText: 'Politik sou Vi Prive',
      privacyLinkUrl: 'privacy'
    },
    navigation: {
      agree: 'Mwen Dakò',
      disagree: 'Mwen Pa Dakò'
    }
  },
  lookupError: {
    title: 'Erè',
    error:
      'Nou dezole. Nou pa t kapab jwenn enfòmasyon pou adrès ou te antre an. Tanpri verifye adrès lan oswa eseye ankò pita.',
    tryAgain: 'Eseye ankò'
  },
  multipleResultsError: {
    title: 'Erè',
    error:
      'Nou dezole. Gen plizyè korespondans pou adrès ou te antre an. Tanpri verifye adrès lan oswa eseye ankò pita.',
    tryAgain: 'Eseye ankò'
  }
};
