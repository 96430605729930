export default {
  meta: {
    title: 'Зарегистрироваться',
    url: 'https://www.floodhelpny.org/ru/register'
  },
  title: 'Оценка риска 2.0',
  subHeading:
    'Зарегистрируйтесь на бесплатный вебинар, чтобы получить более подробную информацию об  Оценке риска 2.0 и получить ответы на ваши вопросы, чтобы помочь сохранить ваш дом и финансы в безопасности',
  register:
    'Зарегистрируйтесь на бесплатный вебинар с жилищными консультантами 13 сентября 2022г.',
  brought: 'Предоставлено',
  riskRating:
    'Оценка риска 2.0 это новая федеральная система для определения взносов за страхование от наводнения. Более 60% ньюйоркцев, у которых в настоящий момент имеется страховой полис, могут увидеть повышение затрат на страховку.',
  learnMore: 'Дополнительная информация об Оценке риска 2.0',
  funding: {
    support: 'С финансовой поддержкой от FEMA',
    questions: 'Вопросы? Адрес электронной почты ',
    commsEmail: 'communications@cnycn.org'
  }
};
