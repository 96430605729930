import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { colors, getZoneColor } from '../styles/variables';
import ExplorableMap from './ExplorableMap';
import OtherResources from './OtherResources';
import ButtonLink from './ui/ButtonLink';
import Card from './layout/Card';
import PrimaryAndAsideContainer from './layout/PrimaryAndAsideContainer';
import ListWithIconAndText from './layout/ListWithIconAndText';
import RoundIcon from './RoundIcon';
import CallToAction from './layout/CallToAction';
import {
  HeaderSection,
  Subheader,
  Section,
  SectionIntro,
  SectionDescription
} from './ui/StyledResourcePageElements';
import { useProvider, useFaq } from '../helpers/hooks';

const OverviewImage = styled.img`
  width: 100%;
`;

const FloodZones = styled.div`
  display: flex;
  justify-content: space-evenly;
  text-align: center;

  @media (max-width: 920px) {
    flex-direction: column;
  }
`;

const FloodZone = styled.div`
  h1 {
    font-size: 6rem;
    margin: 0 2rem;
    color: ${(props) => props.color};
  }

  @media (max-width: 800px) {
    margin-bottom: 2rem;

    :last-child {
      margin-bottom: 0;
    }
  }
`;

const Steps = styled.ul`
  border: 10px solid ${colors.grayScale[0]};
  padding: 2rem 3rem;
  margin: 3rem 0;
  list-style-type: none;

  @media (max-width: 1200px) {
    padding: 2rem 1rem;
  }
`;

const Step = styled.li`
  display: flex;
  justify-content: space-around;
  padding: 2rem 0;
  align-items: center;
  border-bottom: 1px solid ${colors.grayScale[1]};

  :first-child {
    padding-top: 0;
  }

  :last-child {
    padding-bottom: 0;
    border: none;
  }

  @media (max-width: 1200px) {
    flex-direction: column;
    text-align: center;

    > * {
      margin: 1rem 0;
    }
  }
`;

const StepDescription = styled.div`
  width: 100%;
  flex-basis: 45%;

  @media (max-width: 1200px) {
    flex-basis: auto;
  }
`;

const DiagramWrapper = styled.div`
  display: flex;
  flex-basis: 20%;
  justify-content: center;
`;

const Header = styled.h5`
  margin-bottom: 1rem;
`;

const RowOfColumns = styled.div`
  display: flex;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2rem;

  * {
    margin: 1rem;
  }

  @media (max-width: 1000px) {
    margin: 1rem;
  }
`;

const SmallBusinesses = () => {
  const provider = useProvider('smallBusinesses');
  const { setFaqZone } = useFaq();

  useEffect(() => {
    setFaqZone('small-business');
  }, [setFaqZone]);

  return (
    <>
      <Helmet>
        <title>{provider.meta.title}</title>
        <link rel="canonical" href={provider.meta.url} />
        <meta name="description" content={provider.meta.description} />
        <meta property="og:title" content={provider.meta.ogTitle} />
        <meta
          property="og:image"
          content="https://www.floodhelpny.org/images/small_business.png"
        />
        <meta property="og:description" content={provider.meta.description} />
        <meta property="og:url" content={provider.meta.url} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image:alt" content={provider.meta.imageAlt} />
      </Helmet>
      <HeaderSection>
        <h1>{provider.header}</h1>
        <Subheader>{provider.subHeader}</Subheader>
      </HeaderSection>
      <Section>
        <SectionIntro>
          <h5>{provider.overview.header}</h5>
          <SectionDescription>{provider.overview.body}</SectionDescription>
        </SectionIntro>
        <PrimaryAndAsideContainer>
          <div>
            <OverviewImage
              src={provider.attribution.image}
              alt={provider.attribution.imageAlt}
            />
          </div>
          <aside>
            <div>{provider.attribution.credit}</div>
            <a
              href="https://www1.nyc.gov/site/sbs/index.page"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                src={provider.attribution.logo}
                alt={provider.attribution.logoAlt}
              />
            </a>
          </aside>
        </PrimaryAndAsideContainer>
      </Section>
      <Section>
        <h5>{provider.whatYouCanDo.header}</h5>
        <RowOfColumns>
          <Column>
            <RoundIcon
              src={provider.whatYouCanDo.assess.icon}
              alt={provider.whatYouCanDo.assess.iconAlt}
            />
            <h4>{provider.whatYouCanDo.assess.header}</h4>
            <p>{provider.whatYouCanDo.assess.body}</p>
          </Column>
          <Column>
            <RoundIcon
              src={provider.whatYouCanDo.prepare.icon}
              alt={provider.whatYouCanDo.prepare.iconAlt}
            />
            <h4>{provider.whatYouCanDo.prepare.header}</h4>
            <p>{provider.whatYouCanDo.prepare.body}</p>
          </Column>
          <Column>
            <RoundIcon
              src={provider.whatYouCanDo.protect.icon}
              alt={provider.whatYouCanDo.protect.iconAlt}
            />
            <h4>{provider.whatYouCanDo.protect.header}</h4>
            <p>{provider.whatYouCanDo.protect.body}</p>
          </Column>
        </RowOfColumns>
      </Section>
      <CallToAction>
        <RoundIcon
          src={provider.whatYouCanDo.callToAction.icon}
          alt={provider.whatYouCanDo.callToAction.iconAlt}
        />
        <div>
          <h3>{provider.whatYouCanDo.callToAction.header}</h3>
          <p>{provider.whatYouCanDo.callToAction.body}</p>
        </div>
        <ButtonLink
          external
          url="https://www.nyc.gov/html/nycbe/downloads/pdf/EM_SBSBCPWorksheetsMar2017.pdf"
          text={provider.whatYouCanDo.callToAction.buttonText}
        />
      </CallToAction>
      <Section>
        <h5>{provider.riskZoneHeader}</h5>
        <FloodZones>
          <FloodZone color={getZoneColor('VE')}>
            <h1>VE</h1>
            <p>{provider.severeRiskZone}</p>
          </FloodZone>
          <FloodZone color={getZoneColor('AE')}>
            <h1>AE/AO</h1>
            <p>{provider.highRiskZone}</p>
          </FloodZone>
          <FloodZone color={getZoneColor('X')}>
            <h1>X</h1>
            <p>{provider.moderateRiskZone}</p>
          </FloodZone>
        </FloodZones>
      </Section>
      <ExplorableMap />
      <Section>
        <PrimaryAndAsideContainer>
          <div>
            <p
              dangerouslySetInnerHTML={{
                __html: provider.warning.primary.body
              }}
            />
          </div>
          <aside>
            <h6>{provider.warning.aside.header}</h6>
            <p>{provider.warning.aside.body}</p>
          </aside>
        </PrimaryAndAsideContainer>
      </Section>
      <Section>
        <PrimaryAndAsideContainer>
          <div>
            <Header>{provider.floodInsurance.primary.header}</Header>
            <p>{provider.floodInsurance.primary.body}</p>
          </div>
          <aside>
            <h6>{provider.floodInsurance.aside.header}</h6>
            <p>{provider.floodInsurance.aside.body}</p>
          </aside>
        </PrimaryAndAsideContainer>
      </Section>
      <Section>
        <h5>{provider.businessInsurance.header}</h5>
        <p>{provider.businessInsurance.body}</p>
      </Section>
      <Section>
        <h5>{provider.lowerYourRiskOptions.header}</h5>
        <Steps>
          {provider.lowerYourRiskOptions.list.map((option, idx) => (
            <Step key={idx}>
              <DiagramWrapper>
                <img alt={option.imageAlt} src={option.image} />
              </DiagramWrapper>
              <StepDescription>
                <h4>{option.title}</h4>
                <p>{option.body}</p>
              </StepDescription>
            </Step>
          ))}
        </Steps>
      </Section>
      <Section>
        <h5>{provider.otherMethodsToLowerRisk.title}</h5>
        <SectionDescription>
          {provider.otherMethodsToLowerRisk.subtitle}
        </SectionDescription>
        <ListWithIconAndText items={provider.otherMethodsToLowerRisk.list} />
        <Card>
          <img
            alt={provider.continuityPlan.imageAlt}
            src={provider.continuityPlan.image}
          />
          <div>
            <h4>{provider.continuityPlan.title}</h4>
            <p>{provider.continuityPlan.body}</p>
          </div>
          <ButtonLink
            url="https://www1.nyc.gov/site/planning/plans/resilient-retail/resilient-retail.page"
            text={provider.continuityPlan.view}
            external
          />
        </Card>
      </Section>
      <OtherResources />
    </>
  );
};

export default SmallBusinesses;
