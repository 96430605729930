import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '../styles/variables';
import AuditApplicationDefaultInput from './ui/AuditApplicationDefaultInput';
import RadioInput from './ui/RadioInput';
import SelectElementSingle from './ui/SelectElementSingle';
import SelectApplicantType from './ui/SelectApplicantType';
import SelectElementMultiple from './ui/SelectElementMultiple';
import Button from './ui/Button';
import FileInput from './ui/FileInput';
import DateInput from './ui/DateInput';

const Container = styled.div`
  margin-bottom: 2rem;
`;

const ButtonWrapper = styled.div`
  display: flex;

  button {
    margin: 0 1rem 1rem 0;
  }

  @media (max-width: 420px) {
    flex-direction: column;
  }
`;

const QuestionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
`;

const Question = styled.h4`
  @media (max-width: 420px) {
    margin-top: 1em;
  }
`;

const AuditApplicationQuestionContainer = ({
  index,
  question,
  answer,
  questions,
  answers,
  numberOfApplicants,
  setQuestions,
  setAnswers,
  setNumberOfApplicants,
  question: {
    headline,
    responseFields,
    addRow,
    addRowText,
    removeRow,
    removeRowText
  }
}) => {
  const formatNewQuestion = () => {
    // JSON parse needed to ensure that the newQuestion is not a deep clone of the old one
    const newQuestion = JSON.parse(JSON.stringify(question));
    newQuestion.buttonText = '';
    newQuestion.headline = '';
    newQuestion.responseFields.map((responseField) => {
      responseField.show = false;
      responseField.required = false;
    });
    newQuestion.removeRow = true;
    return newQuestion;
  };

  // TODO update so as not to mutate state directly
  // Add new answers Object to answers Array
  const addAnswer = () => {
    // JSON parse needed to ensure that the newAnswer is not a deep clone of the old one
    const newAnswer = JSON.parse(JSON.stringify(answers[index]));
    const indexForNewAnswer = numberOfApplicants + 1;
    const newAnswersArray = [...answers];
    newAnswersArray.splice(indexForNewAnswer, 0, newAnswer);
    setAnswers(newAnswersArray);
  };

  // TODO update so as not to mutate state directly
  const addApplicant = () => {
    // Create a copy of the question so as not to directly mutate it in state
    const newApplicantQuestion = formatNewQuestion();
    const indexForNewQuestion = numberOfApplicants + 1;
    // Create a shallow copy of the questions array and mutate oldApplicantQuestion
    const newQuestionsArray = [...questions];
    const oldApplicantQuestion = newQuestionsArray[index];
    oldApplicantQuestion.addRow = false;
    oldApplicantQuestion.removeRow = false;
    oldApplicantQuestion.responseFields[0].show = true;
    // Add new question at correct index
    newQuestionsArray.splice(indexForNewQuestion, 0, newApplicantQuestion);
    setQuestions(newQuestionsArray);
    // add new answer at correct index
    addAnswer();
    setNumberOfApplicants((prevState) => prevState + 1);
  };

  // TODO update so as not to mutate state directly
  const removeAnswerValues = () => {
    const newAnswersArray = [...answers];
    newAnswersArray.splice(index - 1, 1);
    setAnswers(newAnswersArray);
  };

  // TODO update so as not to mutate state directly
  const removeApplicant = () => {
    const newQuestionsArray = [...questions];
    newQuestionsArray[index - 1].addRow = true;
    newQuestionsArray[index - 1].responseFields.forEach((responseField) => {
      responseField.show = false;
      responseField.required = false;
    });
    if (index - 1 !== 0) {
      newQuestionsArray[index - 1].removeRow = true;
    }
    newQuestionsArray.splice(index, 1);
    removeAnswerValues();
    setQuestions(newQuestionsArray);
    setNumberOfApplicants((prevNumber) => prevNumber - 1);
  };

  const getResponseField = (responseField) => {
    const { key } = responseField;
    const answerValue = answer[key];
    switch (responseField.type) {
      case 'button-single':
        return (
          <RadioInput
            key={key}
            index={index}
            questionKey={key}
            responseField={responseField}
            answer={answerValue}
            answers={answers}
            setAnswers={setAnswers}
          />
        );
      case 'multiple-drop-down':
        return (
          <SelectElementMultiple
            key={key}
            index={index}
            questionKey={key}
            responseField={responseField}
            answer={answerValue}
            answers={answers}
            setAnswers={setAnswers}
          />
        );
      case 'drop-down':
        return (
          <SelectElementSingle
            key={key}
            index={index}
            questionKey={key}
            responseField={responseField}
            answer={answerValue}
            answers={answers}
            setAnswers={setAnswers}
          />
        );
      case 'file':
        return (
          <FileInput
            key={key}
            index={index}
            questionKey={key}
            responseField={responseField}
            answer={answerValue}
            answers={answers}
            setAnswers={setAnswers}
          />
        );
      case 'select-applicant-type':
        return (
          <SelectApplicantType
            key={key}
            index={index}
            questionKey={key}
            responseField={responseField}
            answer={answerValue}
            answers={answers}
            setAnswers={setAnswers}
            questions={questions}
            setQuestions={setQuestions}
          />
        );
      case 'date':
        return (
          <DateInput
            key={key}
            index={index}
            questionKey={key}
            responseField={responseField}
            answer={answerValue}
            answers={answers}
            setAnswers={setAnswers}
          />
        );
      default:
        return (
          <AuditApplicationDefaultInput
            key={key}
            index={index}
            questionKey={key}
            responseField={responseField}
            answer={answerValue}
            setAnswers={setAnswers}
            answers={answers}
          />
        );
    }
  };

  const renderResponseFields = () => {
    const formattedResponseFields = responseFields.map((responseField) => {
      return getResponseField(responseField);
    });
    return formattedResponseFields;
  };

  const renderAddRow = () => {
    if (!addRow) {
      return null;
    }
    return (
      <Button type="button" bgColor={colors.accent.main} onClick={addApplicant}>
        {addRowText}
      </Button>
    );
  };

  const renderRemoveRow = () => {
    if (!removeRow) {
      return null;
    }
    return (
      <Button
        type="button"
        bgColor={colors.pinkBerry}
        onClick={removeApplicant}
      >
        {removeRowText}
      </Button>
    );
  };

  return (
    <Container>
      <QuestionContainer>
        <Question>{headline}</Question>
      </QuestionContainer>
      {renderResponseFields()}
      <ButtonWrapper>
        {renderAddRow()}
        {renderRemoveRow()}
      </ButtonWrapper>
    </Container>
  );
};

AuditApplicationQuestionContainer.propTypes = {
  index: PropTypes.number.isRequired,
  question: PropTypes.object.isRequired,
  answer: PropTypes.object.isRequired,
  questions: PropTypes.array.isRequired,
  answers: PropTypes.array.isRequired,
  numberOfApplicants: PropTypes.number.isRequired,
  setQuestions: PropTypes.func.isRequired,
  setAnswers: PropTypes.func.isRequired,
  setNumberOfApplicants: PropTypes.func.isRequired
};

export default AuditApplicationQuestionContainer;
