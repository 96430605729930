export default {
  header: 'Your report',
  copy: [
    'This is the resiliency report for',
    'with some technical recommendations on how to make your home and finances more flood-safe.'
  ],
  links: {
    property: 'Property',
    rate: 'Insurance Rate',
    resiliency: 'Resiliency Strategies',
    documents: 'Documents'
  }
};
