import LatLngTranslator from './LatLngTranslator';
import NEIGHBORHOODS from '../../constants/Neighborhoods';
import AREAS from '../../constants/ParametricAreas';

const PolygonFormatter = {
  getNeighborhoods: () => {
    const polygons = NEIGHBORHOODS.map((feature) => {
      const paths = LatLngTranslator.translate(feature);
      const poly = new google.maps.Polygon({
        paths,
        strokeColor: '#1BD357',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#1BD357',
        fillOpacity: 0.35
      });
      return { name: feature.properties.COMMITTEE, polygon: poly };
    });
    return polygons;
  },
  servedParametric: () => {
    const polygons = AREAS[0].features.map((areaServed) => {
      const paths = LatLngTranslator.translatorParametric(areaServed);
      const poly = new google.maps.Polygon({
        paths,
        strokeColor: '#1BD357',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#1BD357',
        fillOpacity: 0.35
      });
      return { name: areaServed.properties.postalcode, polygon: poly };
    });
    return polygons;
  }
};

export default PolygonFormatter;
