export default {
    title: '同意条款',
    disclaimerOne: '我们无法保证您所在区域的准确性',
    disclaimerTwo: '您的洪水保险估计可能不准确',
    links: {
      sentenceOpener: '了解更多关于 ',
      termsLinkText: '条款',
      termsLinkUrl: '/zh-CN/terms',
      conjunction: ' 和 ',
      privacyLinkText: '隐私政策',
      privacyLinkUrl: '/zh-CN/privacy',
      submit: '单击提交即表示您同意我们的条款'
    },
    navigation: {
      agree: '我同意',
      disagree: '我不同意'
    }
  };
  