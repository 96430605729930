export default {
  multifamilyApplication: 'https://www.tfaforms.com/439873',
  addressInput: {
    placeholder: '搜索您的地址',
    action: '看看您是否通过资格预审'
  },
  eligible: {
    eligible: '好消息！您可能有资格！',
    apply: '立即申请'
  },
  ineligible: {
    ineligible: '抱歉，您的房屋不在该计划当前服务的社区。请详细了解洪水风险',
    callToAction: ' 点击此处'
  },
  ineligibleOutsideNYC: {
    copy: '哎呀！此工具仅适用于NYC地址。单击国家资源按钮。',
    link: '访问FloodSmart.gov'
  },
  ineligibleTooBroad: {
    copy: "我们很抱歉。我们无法找到您输入的地址的信息。请验证地址或稍后再试。"
  },
  termsAndConditions: {
    title: '同意条款以及隐私政策',
    disclaimerOne: '1.我们无法保证您所在区域的准确性',
    disclaimerTwo: '2.您的洪水保险估计可能不准确',
    links: {
      sentenceOpener: '了解更多关于 ',
      termsLinkText: '条款',
      termsLinkUrl: 'terms',
      conjunction: ' and ',
      privacyLinkText: '隐私政策',
      privacyLinkUrl: '隐私'
    },
    navigation: {
      agree: '我同意',
      disagree: '我不同意'
    }
  },
  lookupError: {
    title: '错误',
    error: "我们很抱歉。我们无法找到您输入的地址的信息。请验证地址或稍后再试。",
    tryAgain: '再试一次'
  },
  multipleResultsError: {
    title: '错误',
    error: "我们很抱歉。您输入的地址有多个匹配项。请验证地址或稍后再试。",
    tryAgain: '再试一次'
  }
};
