import React, { useEffect } from 'react';
import styled from 'styled-components';
import ChatHelper from '../helpers/ChatHelper';

const Button = styled.div`
  max-width: 13rem;

  img {
    width: 100%;
  }

  @media (max-width: 500px) {
    max-width: 10rem;
  }
`;

const Chat = () => {
  useEffect(() => {
    ChatHelper.init();
  }, []);

  return <Button id="__8x8-chat" role="button" aria-label="chat button" />;
};

export default Chat;
