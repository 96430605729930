export default {
    changeLinkText: 'изменить',
    currentMaps: 'Текущие карты',
    futureMaps: 'Ориентировочные карты',
    legend: {
      label: 'ЛЕГЕНДА:',
      minimalHazard: 'Минимальная опасность',
      moderateRisk: 'Средний риск',
      highRisk: 'Повышенный риск',
      veryHighRisk: 'Наивысший риск',
      floodingKey: ['Minimal hazard', 'Moderate risk', 'High Risk', 'Highest risk (flooding & waves)'],
      stormwaterKey: ['Moderate stormwater flooding hazard', 'High stormwater flooding hazard'],
      copy:
        'Карта показывает текущие и ориентировочные карты зон для города Нью-Йорк. Источник: ',
      linkCopy: 'FEMA',
      linkUrl: 'https://www.fema.gov'
    },
    loadingText: 'Загрузка…'
  };
  