import sortingOptions from '../images/sorting-options.svg';
import structural from '../images/structural.svg';
import wrench from '../images/wrench.svg';
import flood from '../images/flooded-building.svg';
import moneyHouse from '../images/money-house.svg';
import form from '../images/report-card.svg';
import mailBox from '../images/mailbox.svg';
import envelope from '../images/envelope.svg';
import documents from '../images/documents.svg';
import speechBubbles from '../images/speech-bubbles.svg';
import storm from '../images/storm.svg';

export default {
  header: 'How the Flood Recovery Fund Works',
  list: [
    {
      header: 'Storm occurs in your neighborhood',
      imageAlt: 'building',
      image: storm
    },
    {
      header: 'You’re hit with financial damage',
      imageAlt: 'Financial damage',
      image: flood
    },
    {
      header: 'You complete our application for assistance',
      imageAlt: 'wrench',
      image: form
    },
    {
      header: 'We send you additional documents to complete',
      imageAlt: 'wrench',
      image: mailBox
    },
    {
      header: 'You return all required documentation',
      imageAlt: 'speech bubbles',
      image: documents
    },
    {
      header: 'Once approved we will request your banking information',
      imageAlt: 'controls',
      image: sortingOptions
    },
    {
      header: 'Funds are sent',
      imageAlt: 'controls',
      image: moneyHouse
    }
  ],
  madePossibleBy:
    'This fund is made possible thanks to the National Science Foundation’s (NSF) Civic Innovations Grant Challenge. Please note that funding for this program is limited.',
  buttonText: 'Apply now',
  buttonUrl: 'flood-recovery-program-intake-form'
};
