import React from 'react';
import styled from 'styled-components';
import { useProvider } from '../helpers/hooks';
import { colors } from '../styles/variables';
import { Section, SectionDescription } from './ui/StyledResourcePageElements';

const List = styled.ul`
  list-style-type: disc;
  margin-block-end: 0.5rem;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 1.5rem;

  li {
    margin-bottom: 0.5rem;
  }
`;

const Table = styled.table`
  border: 5px solid ${colors.resilientBlue};

  th {
    border: 2px solid ${colors.resilientBlue};
    background: ${colors.beachBeige};
    padding: 20px 20px;
    font-family: GT-Walsheim-Pro-Bold;
    text-transform: uppercase;
  }

  td {
    border: 2px solid ${colors.resilientBlue};
    padding: 10px 55px;
    text-align: center;
  }
`;

const AboutParametricProgram = () => {
  const provider = useProvider('aboutParametricProgram');

  return (
    <Section>
      <h5>{provider.header}</h5>
      <SectionDescription>{provider.body}</SectionDescription>
      <Section>
        <h5>{provider.eligibilityHeader}</h5>
        <SectionDescription>{provider.eligibilityBody}</SectionDescription>
        <List>
          {provider.priority.criteria.map((criterion) => (
            <li key={criterion.key}>
              <p>{criterion.copy}</p>
            </li>
          ))}
        </List>
        <Table>
          {provider.ami.map((element, idx) => {
            if (idx === 0) {
              return (
                <tr>
                  <th>{element.key}</th>
                  <th>{element.ami}</th>
                </tr>
              );
            }
            return (
              <tr>
                <td>{element.key}</td>
                <td>{element.ami}</td>
              </tr>
            );
          })}
        </Table>
      </Section>
    </Section>
  );
};

export default AboutParametricProgram;
