export default {
  header: 'Maneras de reducir su tarifa',
  link: 'Infórmese sobre la mitigación',
  assumingMax: '*Suponiendo cobertura máxima',
  numbersAreEstimates:
    'Estos números son estimaciones por muchos motivos. Para más información acerca de ellos, remítase a nuestros ',
  termsAndConditions: 'Términos y Condiciones',
  noMitigation: 'Lo sentimos, no pudimos identificar ninguna opción',
  elevateBuilding: {
    header: 'Eleve su vivienda',
    body:
      'Coloca su vivienda completamente encima de los niveles de inundación pronosticados.',
    link: 'Obtenga más información',
    mitigationDifference: {
      currentHeader: 'Para su zona actual',
      futureHeader: 'Para su zona de consulta',
      saved: 'ahorró',
      perYear: ' /año*',
      nonpositiveSavingsCopy: 'Esto no reducirá su tarifa'
    }
  },
  abandonFirstFloor: {
    header: 'Abandone su primer piso',
    body: 'Esto efectivamente aumenta la elevación base de su vivienda.',
    link: 'Obtenga más información',
    mitigationDifference: {
      currentHeader: 'Para su zona actual',
      futureHeader: 'Para su zona de consulta',
      saved: 'ahorró',
      perYear: ' /año*',
      nonpositiveSavingsCopy: 'Esto no reducirá su tarifa'
    }
  },
  elevateMechanicals: {
    header: 'Eleve sus equipos mecánicos',
    body: 'Protege los sistemas esenciales de su vivienda.',
    link: 'Obtenga más información',
    mitigationDifference: {
      currentHeader: 'Para su zona actual',
      futureHeader: 'Para su zona de consulta',
      saved: 'ahorró',
      perYear: ' /año*',
      nonpositiveSavingsCopy: 'Esto no reducirá su tarifa'
    }
  },
  floodVents: {
    header: 'Instale desagües para inundaciones',
    body:
      'Reduzca el riesgo de sufrir daños como resultado del agua de inundaciones permitiéndole circular y drenar.',
    link: 'Obtenga más información',
    mitigationDifference: {
      currentHeader: 'Para su zona actual',
      futureHeader: 'Para su zona de consulta',
      saved: 'ahorró',
      perYear: ' /año*',
      nonpositiveSavingsCopy: 'Esto no reducirá su tarifa'
    }
  },
  fillInBasement: {
    header: 'Rellene su sótano',
    body: 'Reduce el daño a los cimientos estructurales de su vivienda.',
    link: 'Obtenga más información',
    mitigationDifference: {
      currentHeader: 'Para su zona actual',
      futureHeader: 'Para su zona de consulta',
      saved: 'ahorró',
      perYear: ' /año*',
      nonpositiveSavingsCopy: 'Esto no reducirá su tarifa'
    }
  }
};
