import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '../styles/variables';
import DefaultCalculatorParamsHelper from '../helpers/profileMitigation/DefaultCalculatorParamsHelper';
import RateCalculatorCommunicator from '../communicators/RateCalculatorCommunicator';
import { useApi } from '../helpers/hooks';

const DifferenceSubheader = styled.p`
  color: red;
  font-size: 1rem;
`;

const NoSavings = styled.span`
  font-size: 1rem;
`;

const Savings = styled.span`
  color: ${colors.sourApple};
  font-family: GT-Walsheim-Pro-Medium;
`;

const PerYear = styled.span`
  color: ${colors.grayScale[2]};
`;

const LowerInsuranceDifference = ({
  provider,
  property,
  helper,
  useCurrent
}) => {
  const { rateCalculatorApi } = useApi();
  const [mitigatedRate, setMitigatedRate] = useState(null);
  const [unmitigatedRate, setUnmitigatedRate] = useState(null);

  useEffect(() => {
    const getRate = async () => {
      const unmitigated = DefaultCalculatorParamsHelper.formatCalculatorParameters(
        property,
        useCurrent
      );
      const mitigated = helper.formatMitigatedRate(property, useCurrent);

      try {
        const unmitigatedResult = await RateCalculatorCommunicator.getRate(
          rateCalculatorApi,
          unmitigated
        );
        const mitigatedResult = await RateCalculatorCommunicator.getRate(
          rateCalculatorApi,
          mitigated
        );
        setUnmitigatedRate(unmitigatedResult.rate);
        setMitigatedRate(mitigatedResult.rate);
      } catch (error) {
        // TODO add error handling to this try/catch
      }
    };
    getRate();
  }, [helper, property, rateCalculatorApi, useCurrent]);

  const renderDifferenceHeader = () => {
    const zone = useCurrent
      ? property.current_flood_zone
      : property.future_flood_zone;
    const copy = useCurrent ? provider.currentHeader : provider.futureHeader;

    return <DifferenceSubheader>{`${copy} (${zone})`}</DifferenceSubheader>;
  };

  const renderAmountSaved = () => {
    if (!mitigatedRate || !unmitigatedRate) {
      return null;
    }

    const savings = unmitigatedRate - mitigatedRate;
    if (savings <= 0) {
      return <NoSavings>{provider.nonpositiveSavingsCopy}</NoSavings>;
    }

    return (
      <>
        <Savings>{`$${savings} ${provider.saved}`}</Savings>
        <PerYear>{provider.perYear}</PerYear>
      </>
    );
  };

  return (
    <>
      {renderDifferenceHeader()}
      {renderAmountSaved()}
    </>
  );
};

LowerInsuranceDifference.propTypes = {
  provider: PropTypes.object.isRequired,
  property: PropTypes.object.isRequired,
  helper: PropTypes.object.isRequired,
  useCurrent: PropTypes.bool
};

LowerInsuranceDifference.defaultProps = {
  useCurrent: false
};

export default LowerInsuranceDifference;
