import React from 'react';
import styled from 'styled-components';
import { colors } from '../styles/variables';
import { useProvider } from '../helpers/hooks';

const MapLegendContainer = styled.div`
  font-size: 14px;
  border-bottom: 1px solid ${colors.grayScale[0]};
  padding: 1rem 5rem;

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const LegendKey = styled.div`
  display: flex;

  @media (max-width: 768px) {
    margin: 1rem 0;
  }
`;

const LegendLabel = styled.span`
  font-family: 'GT-Walsheim-Pro-Medium';
`;

const LegendItems = styled.div`
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const LegendItem = styled.div`
  margin-left: 1rem;

  @media (max-width: 768px) {
    margin-left: 0.5rem;
  }
`;

const LegendIcon = styled.span`
  display: inline-block;
  border-radius: 50%;
  height: 15px;
  width: 15px;
`;

const LegendMinimalHazard = styled(LegendIcon)`
  background-color: ${colors.minimalHazard};
`;

const LegendModerateRisk = styled(LegendIcon)`
  background-color: ${colors.x};
`;

const LegendHighRisk = styled(LegendIcon)`
  background-color: ${colors.ae};
`;

const LegendVeryHighRisk = styled(LegendIcon)`
  background-color: ${colors.ve};
`;

const LegendItemLabel = styled.span`
  margin-left: 0.5rem;
  vertical-align: top;
  font-family: 'GT-Walsheim-Pro-Medium';
`;

const LegendText = styled.div`
  a {
    text-decoration: underline;
  }
`;

// called by:
//   StaticMap
//   ExplorableMap
const MapLegend = () => {
  const provider = useProvider('ExplorableMap');

  return (
    <MapLegendContainer>
      <LegendKey>
        <LegendLabel>{provider.legend.label}</LegendLabel>
        <LegendItems>
          <LegendItem>
            <LegendMinimalHazard />
            <LegendItemLabel>{provider.legend.minimalHazard}</LegendItemLabel>
          </LegendItem>
          <LegendItem>
            <LegendModerateRisk />
            <LegendItemLabel>{provider.legend.moderateRisk}</LegendItemLabel>
          </LegendItem>
          <LegendItem>
            <LegendHighRisk />
            <LegendItemLabel>{provider.legend.highRisk}</LegendItemLabel>
          </LegendItem>
          <LegendItem>
            <LegendVeryHighRisk />
            <LegendItemLabel>{provider.legend.veryHighRisk}</LegendItemLabel>
          </LegendItem>
        </LegendItems>
      </LegendKey>
      <LegendText>
        <span className="copy">{provider.legend.copy}</span>
        <a
          href={provider.legend.linkUrl}
          rel="noopener noreferrer"
          target="_blank"
        >
          {provider.legend.linkCopy}
        </a>
      </LegendText>
    </MapLegendContainer>
  );
};

export default MapLegend;