import ContentLocations from '../../constants/ContentLocations';

export default class ContentLocationHelper {
  static translateContentLocation(results) {
    if (
      results.contentLocation === null ||
      results.contentLocation === undefined
    ) {
      return ContentLocations.LOWEST_FLOOR_ONLY;
    }

    return results.contentLocation;
  }
}
