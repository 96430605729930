import carpet from '../images/carpet.svg';
import piping from '../images/piping.svg';
import plumbing from '../images/plumbing.svg';
import temperatureInside from '../images/temperature-inside.svg';
import bacteria from '../images/bacteria.svg';
import fillInBasement from '../images/fill-in-basement.svg';
import elevate from '../images/elevate.svg';
import elevateMechanicals from '../images/elevate-mechanicals.svg';
import abandonFirstFloor from '../images/abandon-first-floor.svg';
import floodVents from '../images/flood-vents.svg';
import backwaterValve from '../images/backwater-valve.svg';

export default {
  meta: {
    title:
      'Reacondicionamientos contra inundaciones | Protege tu propiedad | FloodHelpNY.org',
    description:
      'Los reacondicionamientos contra inundaciones pueden ayudarlo a reducir su riesgo de inundación. Esto es lo que necesita saber.',
    url: 'https://www.floodhelpny.org/es/flood-retrofits',
    imageAlt: 'olas costeras y la ciudad de Nueva York'
  },
  learnMore: 'Obtenga Más Información',
  title: 'Reacondicionamientos contra inundaciones: Cómo proteger su propiedad',
  pageSubtitle:
    'Hay una serie de estrategias que puede tener en cuenta para reducir su riesgo de inundaciones. Algunas son transformaciones importantes, y otras pueden ser soluciones rápidas y simples para reducir el posible daño que causa el agua que proviene de las inundaciones.',
  subtitle:
    'El riesgo de inundación puede reducirse mediante modificaciones o mediante soluciones más simples.',
  overview: {
    title: 'Resumen',
    body:
      'Nuestros vecindarios costeros constituyen uno de los grandes motivos por los cuales la Ciudad de Nueva York es única. La costa de 520 millas de la ciudad varía sustancialmente de un vecindario a otro, bordeada por bungalows en algunas partes, y repleta de viviendas adosadas en otras. Esta variación de la densidad y el tipo de viviendas hace que no sea realista tener un enfoque uniforme para proteger su propiedad contra las inundaciones en la Ciudad de Nueva York. Afortunadamente, hay diferentes formas de fortalecer nuestros vecindarios y reforzar nuestras costas. Referimos a la mitigación, y puede describir a varios métodos de modernizar su vivienda.'
  },
  options: {
    title: 'Opciones de mitigación',
    body:
      'Algunas opciones de mitigación cuestan más que otras, unas requieren de más esfuerzos y otras proporcionan mejores beneficios. Se deben considerar todos estos elementos al momento de elegir la opción más adecuada para usted. Actualmente, existen solo unas cuantas medidas que afectan las primas de seguro: la elevación de la vivienda; el relleno de un sótano o entresuelo (y a prueba de inundación húmeda, según sea necesario); desagües para inundaciones; el levantamiento del equipo mecánico fuera del sótano (esta opción normalmente ofrece la reducción de seguro más pequeña de las tres, pero puede ahorrar una gran cantidad de dinero en caso de una inundación). Existen otras medidas que usted puede tomar que no reducen las tarifas de seguro, pero que pueden ayudar a proteger su hogar de daños causados por inundaciones. Cabe destacar que cualquiera de estas medidas reduce la prima de su seguro sin importar su zona de inundación.',
    list: [
      {
        title: 'Rellene el sótano',
        body:
          'Esto reduce los daños a los cimientos estructurales de su vivienda.',
        image: fillInBasement,
        imageAlt: 'Rellene el sótano',
        link: 'fill-in-basement'
      },
      {
        title: 'Eleve su propiedad',
        body:
          'Esto ubica su propiedad completamente por encima de los niveles de agua previstos en caso de inundación.',
        image: elevate,
        imageAlt: 'Eleve su propiedad',
        link: 'elevate'
      },
      {
        title: 'Eleve sus equipos mecánicos',
        body: 'Esto protege los sistemas críticos de su vivienda.',
        image: elevateMechanicals,
        imageAlt: 'Eleve sus equipos mecánicos',
        link: 'elevate-mechanicals'
      },
      {
        title: 'Abandone el primer piso',
        body: 'Esto aumenta de manera eficaz la elevación base de su vivienda.',
        image: abandonFirstFloor,
        imageAlt: 'Abandone el primer piso',
        link: 'abandon-first-floor'
      },
      {
        title: 'Instale desagües para inundaciones',
        body:
          'Disminuye el riesgo de daños causados por el agua de las inundaciones al permitir que esta fluya y drene.',
        image: floodVents,
        imageAlt: 'Instale desagües para inundaciones',
        link: 'flood-vents'
      },
      {
        title: 'Instale una válvula antirretorno',
        body:
          'Una válvula de retención está diseñada para mantener las aguas residuales sin tratar fuera de su hogar en caso de desbordamiento del alcantarillado.',
        image: backwaterValve,
        imageAlt: 'Instale una válvula antirretorno',
        link: 'backwater-valve'
      }
    ]
  },
  retrofitting: {
    title: 'Guía de planificación de acondicionamiento de la ciudad',
    body:
      'El análisis más completo de las posibilidades de acondicionamiento disponibles para las edificaciones en las áreas de inundación de la Ciudad de Nueva York hasta la fecha.',
    view: 'Ver'
  },
  budget: {
    title: 'Mitigación accesible',
    subtitle:
      'Existen otras medidas que usted puede tomar que no reducen las tarifas de seguro, pero que podrían ayudar a reducir los daños causados por inundaciones.',
    list: [
      {
        header: 'Reemplace las alfombras por azulejos',
        body:
          'Su cuarto de baño es resistente al agua, ¿Por qué no podría serlo su sótano también? Si su sótano tiene azulejos en lugar de alfombra, limpiar sería más fácil e impedirá que unas pulgadas de agua de inundaciones ocasionen mayores daños.',
        image: carpet
      },
      {
        header: 'Instale desagües para inundaciones',
        body:
          'Los desagües para inundaciones son relativamente baratos y fáciles de instalar, impiden que los alcantarillados y las aguas pluviales entren a su vivienda a través de lavabos, inodoros y bañeras cuando los sistemas que rodean su hogar están desbordados de agua de inundaciones.',
        image: piping
      },
      {
        header: 'Instale una bomba de sumidero',
        body:
          'Las bombas de sumidero ayudan a drenar las filtraciones y el agua que entran a las edificaciones a través de grietas en cimientos, superficies porosas y otras penetraciones como los conductos subterráneos.',
        image: plumbing
      },
      {
        header:
          'Impermeabilice el interior de los sistemas de las edificaciones',
        body:
          'Esto requiere sellar las edificaciones para bloquear el agua utilizando dispositivos mecánicos activos o pasivos tales como: paredes de hormigón alrededor de una caldera, puertas o barreras a prueba de inundación.',
        image: temperatureInside
      },
      {
        header: 'Instale materiales resistentes a las inundaciones',
        body:
          'Los materiales tales como los paneles de yeso sin papel y los suelos de baldosas de terrazo para materiales de construcción y mobiliarios, reducen los daños causados por inundaciones y aceleran la limpieza tras la inundación. Esta opción puede incluir además el uso de gabinetes a prueba de inundación y el reemplazo de artículos de madera, como las puertas, por metal u otros materiales de revestimiento resistentes al agua.',
        image: bacteria
      }
    ]
  }
};
