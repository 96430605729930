export default {
  header: 'Где ваша недвижимость должна быть',
  baseFloodElevationExplainedPre: 'В настоящий момент ',
  baseFloodElevationExplainedPost: ' над уровнем моря',
  baseFloodElevation:
    'Это расчетный уровень паводка (DFE) — где вы должны быть.',
  topOfBottomFloor: 'Является величиной, указывающей что ваш дом в настоящий момент находится над уровнем моря',
  freeboardCity:
    "Это 'превышение над уровнем воды', предусмотренное городом для обеспечения безопасной высоты."
};
