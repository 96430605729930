export default {
  meta: {
    title: 'Su tarifa estimada',
    url: 'https://floodhelpny.org/es/rate',
    description: null,
    image: null,
    imageAlt: null
  },
  profileCalloutCopy: 'Hemos añadido algunas recomendaciones.',
  noMitigationCopy: 'No pudimos encontrar opciones de mitigación',
  profileCalloutLink: 'Vea su perfil de resiliencia',
  betaCalloutCopy:
    'Como puede observar, es complicado descifrar las estimaciones de los seguros contra inundaciones. De hecho, nadie en la Ciudad de Nueva York facilita una herramienta similar para que el público en general pueda calcular las tarifas. Hemos trabajado mucho para idear una herramienta que genere estimaciones informativas. No obstante, hemos lanzado la primera versión de esta herramienta a sabiendas de que no es perfecta. Con su ayuda, podremos mejorarla para todos los neoyorquinos. Si usted cree que los resultados que obtiene no son correctos, por favor envíenos un email a: ',
  betaCalloutLink: 'info@floodhelpny.org',

  headerProvider: {
    backToProfileLink: '‹ Volver al perfil',
    header: 'Su estimación de seguro contra inundaciones.',
    editAnswersLink: 'Editar respuestas',
    copy:
      'Lo asistiremos con relación a sus costos potenciales ahora y cuando cambien los mapas.'
  },
  rateCalculatorResultProvider: {
    header: 'Estimación de seguro contra inundaciones',
    beta: 'BETA',
    link: 'Vea su estimación completa',
    currentMapStart: 'Mapas vigentes ( ',
    currentMapEnd: ' zona)',
    futureMapStart: 'Mapas de advertencia (',
    futureMapEnd: ' zona)',
    assumingMax: '*Suponer la cobertura máxima',
    numbersAreEstimates:
      'Estas cifras son estimadas por muchas razones. Para obtener más información al respecto, consulte nuestros ',
    termsAndConditions: 'Términos y condiciones',
    floodZoneRateProvider: {
      perYear: 'Por año*'
    },
    rateAdjusterProvider: {
      adjustYourCoverage: 'Adapte su cobertura ',
      buildingCoverage: 'Cobertura de edificaciones',
      buildingCoverageTooltip:
        'La cobertura de edificaciones asegura solamente la estructura, no los contenidos o el jardín. Incluye algunos muebles empotrados y electrodomésticos, así como instalaciones de servicios públicos.',
      buildingDeductible: 'Deducible para edificaciones',
      buildingDeductibleTooltip:
        'Esta es la cantidad de dinero que tendrá que pagar de su bolsillo para reparar los daños a la edificación antes de que actúe su seguro contra inundaciones.',
      contentCoverage: 'Cobertura del contenido',
      contentCoverageTooltip:
        'La cobertura de contenidos asegura sus pertenencias en el interior de su vivienda. Se consideran contenidos: la ropa, las lavadoras, las secadoras, así como congeladores de alimentos. (Las estufas y los refrigeradores se aseguran mediante la "cobertura de edificaciones").',
      contentDeductible: 'Deducible del contenido',
      contentDeductibleTooltip:
        'Esta es la cantidad de dinero que tendrá que pagar de su bolsillo para reparar o reponer sus pertenencias dañadas antes de que actúe su seguro contra inundaciones.',
      collapse: 'Derrumbe'
    }
  },
  prefirmCalloutProvider: {
    copy:
      'Se eliminarán los subsidios Pre-FIRM ¡Su tarifa futura podría aumentar hasta un 18% por año!'
  },
  lomaCalloutProvider: {
    header:
      '¡Espere! Quizá su vivienda no debería estar en una zona de inundación de alto riesgo.',
    body:
      'Pareciera que su casa está en un terreno elevado. Obtenga un certificado de elevación y pídale a su agente de seguros que solicite una Carta de Enmienda de Mapa (LOMA), la cual consiste en una solicitud para cambiar el mapa de inundaciones y así excluir su propiedad de la zona de inundaciones. Si su LOMA es convincente, usted puede reducir sus tarifas de seguro contra inundaciones de forma significativa.',
    link: 'Más información'
  }
};
