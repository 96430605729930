export default {
  header: 'SU TARIFA DE SEGURO, CON BASE EN SU CERTIFICADO DE ELEVACIÓN',
  current: 'Mapas actuales',
  future: 'Mapas de consulta',
  zone: 'zona',
  perYear: 'Por año*',
  assumingMax: '*Suponiendo cobertura máxima',
  numbersAreEstimates:
    'Estos números son estimaciones por muchos motivos. Para más información acerca de ellos, remítase a nuestros ',
  termsAndConditions: 'Términos y Condiciones'
};
