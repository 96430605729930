export default {
  meta: {
    title: 'Acerca de FloodHelpNY.org',
    description:
      'FloodHelpNY.org se creó como una plataforma para involucrar e informar a los neoyorquinos sobre cómo pueden proteger sus hogares y sus finanzas de las inundaciones que se espera que empeoren con el aumento del nivel del mar causado por el cambio climático.',
    ogDescription:
      'FloodHelpNY.org es una plataforma para involucrar e informar a los neoyorquinos sobre el riesgo de inundaciones.',
    url: 'https://www.floodhelpny.org/es/about'
  },
  title: 'Nosotros',
  thisWebsite: {
    header: 'Acerca de este sitio web',
    intro:
      ' se creó como una plataforma para involucrar e informar a los propietarios de la ciudad de Nueva York acerca de cómo proteger sus viviendas y finanzas de las inundaciones, las cuales se espera que empeoren con el aumento de los niveles del mar causado por el cambio climático.',
    siteGoal:
      'El objetivo principal del sitio es poner en contacto a los propietarios que reciben ingresos bajos a moderados con los ingenieros en determinadas comunidades costeras con el fin de llevar a cabo auditorías de resiliencia para que puedan tomar decisiones fundamentadas con respecto a cómo reducir el riesgo de futuras inundaciones, lo cual los ayudará también a reducir el costo de sus seguros contra inundaciones.',
    funded: 'La plataforma está fundada por la ',
    gosr: 'Oficina de Recuperación ante Tormentas del Gobernador de Nueva York',
    and: ' y ',
    newYorkRising: 'New York Rising',
    fema: 'FEMA',
    partnership: ' en asociación con la ',
    mocej: 'Oficina de Justicia Ambiental y Climática del Alcalde'
  },
  cnycn: {
    header: 'ACERCA DEL CENTER FOR NEW YORK CITY NEIGHBORHOODS',
    about:
      'El sitio web es un proyecto del Center for New York City Neighborhoods, Inc., una organización sin fines de lucro que se compromete a promover y proteger la propiedad de viviendas asequibles en Nueva York, para que las familias de la clase media y trabajadora puedan establecer comunidades fuertes y prósperas. Más información en ',
    link: 'cnycn.org.',
    contributions: 'El Centro agradece las contribuciones de la ',
    mocej:
      'Oficina de Justicia Ambiental y Climática de la Alcaldía de la Ciudad de Nueva York (MOCEJ), ',
    forGuidanceAndEfforts:
      'tanto por su orientación especializada como por sus esfuerzos por llegar a los neoyorquinos en riesgo mediante una campaña de sensibilización y concienciación con respecto a los riesgos de inundación y los seguros contra inundaciones. La MOCEJ supervisa el programa estratificado de resiliencia climática de OneNYC. Con base en los mejores conocimientos científicos disponibles desarrollados por el Panel sobre Cambio Climático de la Ciudad de Nueva York, la MOCEJ trabaja para garantizar que los vecindarios, la economía y los servicios públicos de la ciudad estén preparados para resistir y salir fortalecidos de los impactos del cambio climático y otras amenazas del siglo XXI.',
    otherContributors:
      'En parte, el contenido de FloodHelpNY.org también ha sido posible gracias al aporte de ',
    legalServices: 'Legal Services NYC',
    legalServicesFunding:
      ' y el financiamiento por parte de Legal Services Corporation.',
    capitalOne:
      'CapitalOne Bank proporcionó el financiamiento inicial para el sitio web. '
  },
  gosr: {
    header:
      'Acerca de la Oficina de Recuperación ante Tormentas del Gobernador de Nueva York',
    body:
      'El Gobernador estableció la Oficina de Recuperación ante Tormentas, con el objetivo de atender las necesidades más urgentes de las comunidades y también para fomentar la identificación de soluciones innovadoras y permanentes con el fin de fortalecer la infraestructura y los sistemas críticos del estado. Al trabajar bajo el auspicio de New York Rising, la Oficina de Recuperación ante Tormentas del Gobernador (GOSR) utiliza aproximadamente $4.4 mil millones en financiación flexible asignados por el programa de Subvención en Bloque para el Desarrollo Comunitario del Programa de Recuperación ante Desastres (CDBG-DR), del Departamento de Vivienda y Desarrollo Urbano de Estados Unidos (HUD) para concentrar la ayuda en cuatro áreas principales. En combinación con otros fondos federales que se le han otorgado a otros organismos estatales, el programa CDBG-DR ayuda a los propietarios, a las pequeñas empresas y a comunidades enteras a reconstruir incluso mejor que antes. Y en un estado conocido por su gran resiliencia y espíritu laborioso, estas iniciativas están allanando el camino hacia una gran recuperación, la cual revitalizará la Ciudad de Nueva York y la preparará mejor ante futuros fenómenos meteorológicos extremos.'
  },
  resiliencyAdvisory: {
    header: 'Consejo de Asesoría para la Resiliencia',
    body:
      'La Oficina de Recuperación ante Tormentas del Gobernador y el Center for NYC Neighborhoods crearon un Consejo de Asesoría para la Resiliencia cuando se rediseñó FloodHelpNY.org con el propósito de reunir a una amplia variedad de organizaciones que se dedican a hacerle frente a los problemas del aumento del nivel del mar y los costos del seguro contra inundaciones, para que puedan crear una plataforma abierta común, y así compartir información y motivar a los neoyorquinos a prepararse. Nuestros socios en el Consejo son:',
    americanInstituteOfArchitects:
      'The American Institute of Architects - Staten Island Chapter',
    enterpriseCommunityPartners: 'Enterprise Community Partners',
    fema: 'La Agencia Federal para el Manejo de Emergencias',
    friendsOfRockaway: 'Friends of Rockaway',
    legalServicesNYC: 'Legal Services NYC',
    deptOfCityPlanning:
      'El Departamento de Planificación Urbana de la Ciudad de Nueva York',
    hpd:
      'El Departamento de Conservación y Desarrollo de Viviendas de la Ciudad de Nueva York',
    sbs:
      'El Departamento de Servicios para Pequeñas Empresas de la Ciudad de Nueva York',
    mor: 'La Oficina de Resiliencia de la Alcaldía de la Ciudad de Nueva York',
    stBernardProject: 'El Proyecto St. Bernard',
    hud: 'El Departamento de Vivienda y Desarrollo Urbano de Estados Unidos'
  }
};
