export default {
  header: 'Where your property should be',
  baseFloodElevationExplainedPre: 'Currently ',
  baseFloodElevationExplainedPost: ' above sea level',
  baseFloodElevation:
    'This is the Design Flood Elevation (DFE) — where you need to be.',
  topOfBottomFloor: 'Is the amount your home is above sea level currently.',
  freeboardCity:
    "This is 'freeboard' required by the City to ensure safe elevation."
};
