export default {
  whatYouToldUs: 'WHAT YOU TOLD US',
  property: 'Property',
  basicInfo: 'Basic Info',
  income: 'Income:',
  household_size: 'Household Size:',
  contactInfo: 'Contact Info',
  name: 'Name:',
  email: 'Email:',
  homePhone: 'Home Phone:'
};
