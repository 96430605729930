import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import RoundIcon from './RoundIcon';
import WaveAnimation from './WaveAnimation';
import certificate from '../images/certificate.svg';
import storm from '../images/storm.svg';
import rainCloud from '../images/rain-cloud.png';
import mapMarker from '../images/map-with-marker.svg';
import Button from './ui/Button';
import Matchers from '../constants/Matchers';
import Loading from './sharedComponents/Loading';
import axios from 'axios';
import { StyledInput } from './ui/StyledInput';
import { colors } from '../styles/variables';
import {
  HeaderSection,
  Subheader,
  Section,
  SectionDescription
} from './ui/StyledResourcePageElements';
import { useProvider, useLocale } from '../helpers/hooks';

const NarrowHeaderSection = styled(HeaderSection)`
  width: 900px;
  max-width: 90%;
  margin: 0 auto;
  position: relative;
  height: 100vh;
  min-height: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 700px) {
    width: 85%;
    min-height: 0;
  }
`;

const BackgroundImage = styled.div`
  background: ${colors.resilientBlue};
  position: relative;
  overflow: hidden;
`;

const WhiteHeader = styled.h1`
  color: white;
`;

const WhiteSubheader = styled(Subheader)`
  color: white;
`;

const IconAndTextContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  p {
    margin-left: 20px;
    font-size: 1.5rem;
  }
`;

const BeigeSection = styled.div`
  background-color: ${colors.beachBeige};
`;

const NarrowSection = styled(Section)`
  width: 1200px;
  max-width: 90%;
  margin: 4rem auto;

  @media (max-width: 700px) {
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const WidgetAndDescription = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 18rem;

  @media (max-width: 1000px) {
    justify-content: start;
    gap: 12rem;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;

    gap: 3rem;
  }
`;

const WidgetDescription = styled.div`
  flex-basis: 25%;

  @media (max-width: 768px) {
    align-self: center;
    width: 50%;
  }
`;

const FormAndInfoContainer = styled.div`
  display: flex;
  flex-flow: row-wrap;
  justify-content: space-between;
  align-items: center;
  gap: 5rem;

  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    flex-wrap: wrap:
  }
`;

const Information = styled.div`
  align-self: flex-start;
  padding-top: 2rem;
  flex-basis: 30%;
  min-width: 300px;
`;

const Form = styled.div`
  flex-basis: 50%;
  flex-shrink: 0;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding-top: 2rem;
  align-items: flex-start;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: start;
  }
`;

const FormItemTitle = styled.div`
  font-weight: bold;
  width: 30%;

  @media (max-width: 420px) {
    width: auto;
  }
`;

const InputWrapper = styled.div`
  padding-top: 5px;
  width: 70%;

  @media (max-width: 768px) {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;

const Checkbox = styled(FormItem)`
  input {
    margin-right: 1rem;
  }

  @media (max-width: 768px) {
    display: flex;
    justify-content: flex-start;
  }
`;

const SubmitWrapper = styled.div`
  padding-top: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 500px) {
    flex-direction: column-reverse;
  }
`;

const Input = styled(StyledInput)`
  width: 100%;
`;

const ErrorMessage = styled.div`
  color: ${colors.pinkBerry};
`;

const ThankYouMessage = styled.div`
  margin: 5rem auto;
`;
const WidgetLanding = () => {
  const provider = useProvider('widgetLanding');
  const locale = useLocale();

  const [name, setName] = useState('');
  const [organization, setOrganization] = useState('');
  const [email, setEmail] = useState('');
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [acceptTermsError, setAcceptTermsError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [organizationError, setOrganizationError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [submittedForm, setSubmittedForm] = useState(false);
  const csrfToken = document.getElementsByName('csrf-token')[0].content;

  const resetErrors = () => {
    setSubmitError(false);
    setNameError(false);
    setEmailError(false);
    setAcceptTermsError(false);
    setOrganizationError(false);
  };

  const resetForm = () => {
    setName('');
    setEmail('');
    setOrganization('');
    setAcceptTerms(false);
  };

  const validateResponses = () => {
    let areResponsesValid = true;

    if (name === '') {
      setNameError(true);
      areResponsesValid = false;
    }

    if (email.search(Matchers.email) === -1) {
      setEmailError(true);
      areResponsesValid = false;
    }

    if (organization === '') {
      setOrganizationError(true);
      areResponsesValid = false;
    }

    if (acceptTerms === false) {
      setAcceptTermsError(true);
      areResponsesValid = false;
    }

    return areResponsesValid;
  };

  const sendEmail = () => {
    const data = {
      name,
      organization,
      email
    };

    axios
      .post('/widget', data, {
        headers: {
          'X-CSRF-Token': csrfToken
        }
      })
      .then((response) => {
        setIsLoading(false);
        resetForm();
        setSubmitError(false);
        setSubmittedForm(true);
      })
      .catch((error) => {
        setSubmitError(true);
        setIsLoading(false);
      });
  };

  const handleSubmit = () => {
    resetErrors();

    if (validateResponses()) {
      setIsLoading(true);
      sendEmail();
    }
  };

  return (
    <>
      <Helmet>
        <title>{provider.meta.title}</title>
        <link rel="canonical" href={provider.meta.url} />
        <meta name="description" content={provider.meta.description} />
        <meta property="og:title" content={provider.meta.title} />
        <meta property="og:description" content={provider.meta.description} />
        <meta property="og:url" content={provider.meta.url} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <BackgroundImage>
        <WaveAnimation />
        <NarrowHeaderSection>
          <WhiteHeader>{provider.header}</WhiteHeader>
          <WhiteSubheader>{provider.subHeader}</WhiteSubheader>
        </NarrowHeaderSection>
      </BackgroundImage>
      <NarrowSection>
        <h5>{provider.overview.title}</h5>
        <SectionDescription>{provider.overview.subtitle}</SectionDescription>
        <IconAndTextContainer>
          <RoundIcon src={mapMarker} alt="Map with marker" />
          <p dangerouslySetInnerHTML={{ __html: provider.maps }} />
        </IconAndTextContainer>
        <IconAndTextContainer>
          <RoundIcon src={rainCloud} alt="Carpet" />
          <p dangerouslySetInnerHTML={{ __html: provider.reflection }} />
        </IconAndTextContainer>
        <IconAndTextContainer>
          <RoundIcon src={certificate} alt="Sorting Options" />
          <p dangerouslySetInnerHTML={{ __html: provider.required }} />
        </IconAndTextContainer>
        <IconAndTextContainer>
          <RoundIcon src={storm} alt="Sorting Options" />
          <p dangerouslySetInnerHTML={{ __html: provider.decisions }} />
        </IconAndTextContainer>
      </NarrowSection>
      <BeigeSection>
        <NarrowSection beigeSection noBorder>
          <WidgetAndDescription>
            <WidgetDescription>
              <h5>{provider.widget.title}</h5>
              <p>{provider.widget.aside.description}</p>
            </WidgetDescription>
            <iframe
              src="https://floodhelpnywidget.org/290"
              width="252px"
              height="294px"
            ></iframe>
          </WidgetAndDescription>
        </NarrowSection>
      </BeigeSection>
      <NarrowSection noBorder>
        <FormAndInfoContainer>
          <Information>
            <h5>{provider.request.title}</h5>
            <p>{provider.request.description}</p>
          </Information>
          {submittedForm ? (
            <ThankYouMessage>
              <h4>{provider.form.thankYou}</h4>
            </ThankYouMessage>
          ) : (
            <Form>
              <FormItem>
                <FormItemTitle>{provider.form.name}</FormItemTitle>
                <InputWrapper>
                  <Input
                    required={nameError}
                    type="text"
                    htmlFor={name}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder={provider.form.namePlaceholder}
                  />
                  {nameError && (
                    <ErrorMessage>
                      {provider.form.errors.nameError}
                    </ErrorMessage>
                  )}
                </InputWrapper>
              </FormItem>
              <FormItem>
                <FormItemTitle>{provider.form.org}</FormItemTitle>
                <InputWrapper>
                  <Input
                    required={organization}
                    type="text"
                    htmlFor={organization}
                    value={organization}
                    onChange={(e) => setOrganization(e.target.value)}
                    placeholder={provider.form.orgPlaceholder}
                  />
                  {organizationError && (
                    <ErrorMessage>{provider.form.errors.orgError}</ErrorMessage>
                  )}
                </InputWrapper>
              </FormItem>
              <FormItem>
                <FormItemTitle>{provider.form.email}</FormItemTitle>
                <InputWrapper>
                  <Input
                    type="email"
                    htmlFor={email}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder={provider.form.emailPlaceholder}
                  />
                  {emailError && (
                    <ErrorMessage>
                      {provider.form.errors.emailError}
                    </ErrorMessage>
                  )}
                </InputWrapper>
              </FormItem>
              <Checkbox>
                <label htmlFor="accept-terms">
                  <input
                    required={acceptTerms}
                    id="accept-terms"
                    type="checkbox"
                    value={acceptTerms}
                    checked={acceptTerms}
                    onChange={(e) => setAcceptTerms(e.target.checked)}
                  />
                  {provider.form.terms}
                  <a href={`/${locale}/terms`}>{provider.form.termsLink}</a>
                </label>
                {acceptTermsError && (
                  <ErrorMessage>{provider.form.errors.termsError}</ErrorMessage>
                )}
              </Checkbox>
              <SubmitWrapper>
                <Button onClick={handleSubmit} bgColor={colors.resilientBlue}>
                  {isLoading ? <Loading margin="1rem 2rem 0" /> : 'Submit'}
                </Button>
              </SubmitWrapper>
              {submitError && (
                <ErrorMessage>{provider.form.errors.submitError}</ErrorMessage>
              )}
            </Form>
          )}
        </FormAndInfoContainer>
      </NarrowSection>
    </>
  );
};

export default WidgetLanding;
