export default {
  meta: {
    title: 'Flood Recovery Fund Program',
    url: 'https://www.floodhelpny.org/en/flood-recovery-program',
    description:
      'Apply for Assistance through the Center’s Flood Recovery Fund',
    imageAlt: 'waves'
  },
  header: 'Flood Recovery Fund Intake Form',
  duplication: 'Duplication of Benefits:',
  duplicationBody:
    'If you are awarded funds from the Flood Recovery Fund, please note that choosing to accept this funding may exclude you from obtaining financial assistance elsewhere, or may result in a deduction from other available sources of flood assistance. To learn more, please review the ',
  duplicationLink: 'FEMA Duplication of Benefits',
  factSheet: ' fact sheet.',
  intakeFormHeader: 'Flood Recovery Fund (FRF) Intake form',
  formInformation:
    'Please provide the following information about your household in order to apply for Assistance. After you submit the intake form, we will send you an email asking you for a copy of your identification, a series of attestation forms and a questionnaire to confirm that you meet the following criteria. If you have any questions or issues with the form, please contact floodrecoveryfund@cnycn.org or 646-786-0888.'
};
