import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import RateCalculatorRadioInput from './RateCalculatorRadioInput';
import SevereRepetitiveLossNavigator from '../helpers/calculatorNavigation/SevereRepetitiveLossNavigator';

const RateCalculatorQuestionSevereRepetitiveLoss = ({
  provider,
  answers,
  setAnswers,
  setNextQuestion
}) => {
  useEffect(() => {
    if (answers.severeRepetitiveLoss) {
      setNextQuestion(SevereRepetitiveLossNavigator.nextComponent(answers));
    }
  }, [answers, setNextQuestion]);

  const renderFollowUp = () => {
    if (!answers.floodDamage) {
      return null;
    }
    return (
      <div className="severe-repetitive-loss-section">
        <h4 className="severe-repetitive-loss">
          {provider.severeRepetitiveLossQuestion}
          *
        </h4>
        <RateCalculatorRadioInput
          radioKey="severe-repetitive-loss"
          responseField={provider.severeRepetitiveLossResponse}
          answer={answers.severeRepetitiveLoss}
          setResponse={(e) =>
            setAnswers((prevAnswers) => ({
              ...prevAnswers,
              severeRepetitiveLoss: e.target.value
            }))}
        />

        <div className="promo severe-repetitive-loss">
          *
          {provider.severeRepetitiveLossDisclaimer}
        </div>
      </div>
    );
  };

  // TODO refactor this if/else logic
  const updateFloodDamage = (e) => {
    const value = e.target.value === 'true';
    if (value) {
      setAnswers((prevAnswers) => ({
        ...prevAnswers,
        floodDamage: true,
        severeRepetitiveLoss: ''
      }));
    } else {
      setAnswers((prevAnswers) => ({
        ...prevAnswers,
        severeRepetitiveLoss: 'no',
        floodDamage: false
      }));
    }
  };

  return (
    <div className="question severe-repetitive-loss-question">
      <h4 className="flood-damage ">
        {provider.floodDamageQuestion}
        *
      </h4>
      <RateCalculatorRadioInput
        radioKey="flood-damage"
        responseField={provider.floodDamageResponse}
        answer={answers.floodDamage}
        setResponse={updateFloodDamage}
      />

      <div className="promo flood-damage">
        *
        {provider.floodDamageDisclaimer}
      </div>
      {renderFollowUp()}
    </div>
  );
};

RateCalculatorQuestionSevereRepetitiveLoss.propTypes = {
  provider: PropTypes.object.isRequired,
  answers: PropTypes.object.isRequired,
  setAnswers: PropTypes.func.isRequired,
  setNextQuestion: PropTypes.func.isRequired
};

export default RateCalculatorQuestionSevereRepetitiveLoss;
