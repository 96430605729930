export default {
  header: '您可以采取的用来降低风险和利率的操作',
  assumingMax: '*假设最大承保范围',
  numbersAreEstimates:
    '出于多种原因，这些数字是估计值，要了解更多信息，请访问我们的 ',
  termsAndConditions: '条款',
  noRecommendations: "抱歉，我们无法确定任何选项",
  backwaterValveProvider: {
    header: {
      inProgress: '安装回水阀',
      complete: '关于您的回水阀'
    },
    completeHeader: '安装完成！',
    topBody:
      '这有助于防止下水道溢流时废水进入您的房屋。',
    bottomBody:
      '您有资格免费安装回水阀。',
    bottomBodyComplete:
      '我们很想知道具体的过程。请花几分钟填写我们的调查。',
    buttons: {
      learnMore: '了解更多信息',
      startProcess: '启动流程',
      feedbackSurvey: '反馈调查'
    },
    steps: {
      initiate: '正在安装',
      schedule: '正在安装',
      install: '安装完成！'
    }
  },
  elevateBuildingProvider: {
    header: '提升房屋高度',
    body: '让您的房屋完全高于预测洪水水位。',
    link: '了解更多信息',
    lowerYourRate: '这将降低您的费率。',
    auditRateDifferenceProvider: {
      currentHeader: '对于当前区域',
      futureHeader: '对于咨询区域',
      saved: '已保存',
      perYear: ' /年度*',
      nonpositiveSavingsCopy: '这不会降低您的费率'
    }
  },
  abandonFirstFloorProvider: {
    header: '放弃一楼',
    body: '这有效地提高了您房屋的基础标高。',
    link: '了解更多信息',
    lowerYourRate: '这将降低您的费率。',
    auditRateDifferenceProvider: {
      currentHeader: '对于当前区域',
      futureHeader: '对于咨询区域',
      saved: '已保存',
      perYear: ' /年度*',
      nonpositiveSavingsCopy: '这不会降低您的费率'
    }
  },
  fillInBasementProvider: {
    header: '填埋地下室',
    body: '减少对房屋结构基础的损坏。',
    link: '了解更多信息',
    lowerYourRate: '这将降低您的费率',
    auditRateDifferenceProvider: {
      currentHeader: '对于当前区域',
      futureHeader: '对于咨询区域',
      saved: '已保存',
      perYear: ' /年度*',
      nonpositiveSavingsCopy: '这不会降低您的费率'
    }
  },
  elevateMechanicalsProvider: {
    header: '提高机械性能',
    body: '保护您房屋的关键系统。',
    link: '了解更多信息',
    lowerYourRate: '这将降低您的费率。',
    auditRateDifferenceProvider: {
      currentHeader: '对于当前区域',
      futureHeader: '对于咨询区域',
      saved: '已保存',
      perYear: ' /年度*',
      nonpositiveSavingsCopy: '这不会降低您的费率'
    }
  }
};
