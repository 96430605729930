export default {
  xZone: 'Moderate risk',
  aeZone: 'High risk',
  aoZone: 'High risk',
  veZone: 'Highest risk',
  noneZone: 'Minimal hazard',
  baseFloodElevation: 'Base Flood Elevation (BFE)',
  xToolTip:
    'The X zone is a moderate risk zone: properties in this area are at medium risk of flooding during the next serious storm.',
  aeToolTip:
    'The AE zone is a high risk zone: properties in this area are at high risk of flooding during the next serious storm.',
  aoToolTip:
    'The AO zone is a high risk zone: flood insurance is mandatory and expect a high risk of flooding.',
  veToolTip:
    'The V is the highest risk zone in New York City: properties in this area have the greatest risk of flooding during the next serious storm.',
  floodZoneUrl: '/en/understanding-flood-insurance',
  learnMoreAboutFloodZones: {
    copy: 'Learn more about flood zones',
    linkText: ' here'
  },
  floodZoneExplanations: {
    ae: {
      header:
        'Buildings in high risk (AE) zones have the potential for severe flooding—possibly in excess of several feet.',
      copy:
        'Flood insurance is required in a High Risk zone if you have a mortgage.'
    },
    ve: {
      header:
        'New Yorkers living in high risk zones know that the potential for damage is real, and that taking steps now saves money, time, and energy later.',
      copy:
        'Flood insurance is required in a High Risk zone if you have a mortgage.'
    },
    x: {
      header:
        'Moderate risk zones are prone to several inches of flooding—and those inches can be costly. Depending on your property, just six inches of water can cause significant damage.',
      copy:
        'Since flood insurance rates are set by FEMA, the rates do not vary from one insurance company to another. But many insurance agents don’t understand the rules very well and make lots of rating errors.'
    },
    none: {
      header:
        'Changing flood risk and changing zones will ultimately affect us all. Without action, that impact can ricochet through the City’s entire economy.',
      copy:
        'Nearly 20% of flood insurance claims come from moderate-risk and minimal-hazard areas.'
    },
    ao: {
      header:
        'Buildings in high risk AO zones have the potential for flooding in areas near rivers or streams, as well as shallow flooding each year.',
      copy:
        'Flood insurance is required in a High Risk zone if you have a mortgage.'
    }
  }
};
