import umbrella from '../images/umbrella.svg';
import checklist from '../images/checklist.svg';
import map from '../images/map-with-marker.svg';
import questionBubble from '../images/question-bubble.svg';

export default {
  meta: {
    title: '购买洪水保险| FloodHelpNY',
    url: 'https://www.floodhelpny.org/zh-CN/shopping-for-flood-insurance',
    description:
      "洪水风险在增加，这意味着保险费率也会增加。不要错失良机，立即获得价格更低的保单。",
    imageAlt: '购买洪水保险| FloodHelpNY'
  },
  header: '购买洪水保险',
  subHeader:
    "洪水风险在增加，这意味着保险费率也会增加。不要错失良机，立即获得价格更低的保单。",
  overview: {
    header: '概述',
    body:
      "国家洪水保险计划（NFIP）根据您的房屋及洪水风险设定标准洪水保险费率。保险代理人应为同一份保单提供相同的报价。然而，如果代理商没有正确的信息，他们给出的报价不准确。按照以下步骤，向您的代理人提供他们所需的信息，确保您获得正确的保单。"
  },
  understandingFloodInsurance: {
    icon: umbrella,
    iconAlt: '雨伞',
    header: "不明白洪水保险是如何运作的？",
    buttonText: '单击此处了解更多信息'
  },
  stepsIntro: '为您提供最佳保单。按照以下步骤操作。',
  start: {
    step: '步骤1',
    icon: map,
    iconAlt: '带标记的地图',
    title: '开始',
    haveInsurance: {
      title: '已经有保险了吗？',
      body:
        '打电话给您的房主保险代理人，询问他们是否提供洪水保险。'
    },
    findAgent: {
      title: '需要帮助寻找代理吗？',
      body: '搜索位置 '
    }
  },
  ask: {
    step: '步骤2',
    icon: questionBubble,
    iconAlt: '带问号的语音气泡',
    title: '提出5个问题，获得最佳保单。',
    questions: [
      {
        header:
          '在当前的洪水保险费率图上，我处于哪个洪水风险区？',
        description:
          '如果处于“高风险”地区，您需要购买洪水保险。'
      },
      {
        header: '更改免赔额会对费率产生什么影响？',
        description:
          '提高免赔额可能会让年利率更低，但也意味着在洪水发生时您自己负担的费用也变高。'
      },
      {
        header: '我的洪水保险承保范围是什么？',
        description:
          '洪水保单包括两类保险。“结构性”损坏一包括对您的房屋和内置电器结构的损坏。“物品”包括一些但不是全部的个人物品。与您的经纪人谈谈什么级别的保险适合您的财务状况‘。'
      },
      {
        header: '洪水过后我能理赔的最高金额是多少？',
        description:
          '您的“最大保额”是洪水过后您能理赔的最高限额。在决定您的最大承保范围时，考虑一下您需要多少钱才能回收。'
      },
      {
        header: '还有哪些因素可能影响我支付的费用？',
        description:
          '其他因素，比如您的房屋是否靠近洪水源以及重建成本，都会影响您的保单费率和费用。有关更多信息，请参阅我们的风险评级2.0常见问题解答。'
      }
    ]
  },
  doubleCheck: {
    step: '步骤3',
    icon: checklist,
    iconAlt: '清单',
    title: '记得仔细检查至少三家代理商的报价。'
  }
};
