import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import _ from 'lodash';
import elevateBfe from '../images/elevate-bfe.svg';
import PrimaryAndAsideContainer from './layout/PrimaryAndAsideContainer';
import { colors } from '../styles/variables';
import {
  HeaderSection,
  Subheader,
  Section,
  SectionDescription
} from './ui/StyledResourcePageElements';
import { useProvider, useFaq } from '../helpers/hooks';

const Container = styled.div`
  p {
    max-width: 1000px;
  }
`;

const ImageWrapper = styled.aside`
  background-color: ${colors.grayScale[0]};
  height: fit-content;
  width: min-content;
  margin-left: 3rem;

  img {
    width: 300px;
    height: 200px;
    padding: 1rem 0;

    @media (max-width: 1200px) {
      width: 100%;
    }
  }

  @media (max-width: 1200px) {
    width: 100%;
    max-width: 300px;
  }
`;

const Mitigation = () => {
  const provider = useProvider('mitigation');
  const { mitigationOption } = useParams();
  const optionProvider = provider[_.camelCase(mitigationOption)];
  const { setFaqZone } = useFaq();

  useEffect(() => {
    setFaqZone(`mitigation-${_.snakeCase(mitigationOption)}`);
  }, []);

  return (
    <>
      <Helmet>
        <title>{optionProvider.meta.title}</title>
        <link rel="canonical" href={optionProvider.meta.url} />
        <meta name="description" content={optionProvider.meta.description} />
        <meta property="og:title" content={optionProvider.meta.title} />
        <meta
          property="og:description"
          content={optionProvider.meta.description}
        />
        <meta property="og:url" content={optionProvider.meta.url} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Container>
        <HeaderSection>
          <h1>{optionProvider.title}</h1>
          <Subheader>{optionProvider.subtitle}</Subheader>
        </HeaderSection>
        <Section>
          <PrimaryAndAsideContainer>
            <div>
              <h5>{provider.overview}</h5>
              <SectionDescription>{optionProvider.overview}</SectionDescription>
            </div>
            <ImageWrapper>
              <img alt={optionProvider.imageAlt} src={optionProvider.image} />
            </ImageWrapper>
          </PrimaryAndAsideContainer>
        </Section>
        <Section>
          <h5>{provider.affectRate}</h5>
          <SectionDescription>{optionProvider.affectRate}</SectionDescription>
        </Section>
        <Section>
          <PrimaryAndAsideContainer>
            <div>
              <h5>{provider.howItWorks}</h5>
              <SectionDescription>
                {optionProvider.howItWorks}
              </SectionDescription>
            </div>
            <ImageWrapper>
              <img alt="Elevate" src={elevateBfe} />
            </ImageWrapper>
          </PrimaryAndAsideContainer>
        </Section>
        <Section>
          <h5>{provider.effort}</h5>
          <SectionDescription>{optionProvider.effort}</SectionDescription>
        </Section>
      </Container>
    </>
  );
};

export default Mitigation;
