import backwaterValve from '../images/backwater-valve.svg';
import cookingOil from '../images/cooking-oil.svg';
import garbage from '../images/garbage.svg';
import roofing from '../images/roofing.svg';
import basement from '../images/basement.svg';
import unpaved from '../images/unpaved.svg';
import rainEventGuide from '../images/rain-event-guide.jpg';
import stormwaterFlooding from '../images/stormwater-flooding.svg';
import floodInsurance from '../images/flood-insurance.svg';

export default {
  meta: {
    title: 'Inondasyon nan Dlo Lapli | FloodHelpNY.org',
    ogTitle: 'Inondasyon nan Dlo Lapli nan NYC | FloodHelpNY.org',
    url: 'https://www.floodhelpny.org/ht_HT/stormwater-flooding',
    description:
      'Inondasyon nan dlo lapli ka afekte kominote ki sou anndan yo nan plizyè kilomèt distans parapò ak litoral lan, sa ki poze risk pou pwopriyete epi sante.'
  },
  header: 'Inondasyon Dlo Lapli',
  subHeader:
    'Inondasyon nan dlo lapli ka afekte kominote ki sou anndan yo nan plizyè kilomèt distans parapò ak litoral lan, sa ki poze risk pou pwopriyete epi sante.',
  whatIsStormwater: {
    header: 'KISA DLO LAPLI YE?',
    body:
      'Dlo lapli se dlo ki sot nan lapli oswa nèj ki fonn ki debòde sou gazon, lapli, ak lòt sifas tè. Nan yon fason ideyal, plant yo ak tè an t ap absòbe debòdman sa a oswa li t ap desann nan egou yo. Men, nan Vil New York, twotwa ak lari yo pa absòbe dlo lapli. Olyesa, dlo lapli an koule nan basen kaptaj vil lna kote li kanalize nan sistèm egou an. Malerezman, basen kaptaj vil lan tèlman gen kapasite. Dlo lapli anplis lan tonbe nan lari an e yo pa ka evite sa epi li lakoz inondasyon lokal. Dlo ize yo ka tounen nan kay yo tou.'
  },
  pollution: {
    header: 'DLO LAPLI KA AJOUTE POLISYON',
    body: `Anplis risk pou inondasyon pwopriyete an, gen tou risk pou polisyon: Debòdman anplis ki pa souse antre nan tè an ka ranmase ajan polyan tankou luil, pestisid, bakteri ak fatra anvan yo al tonbe nan kanalizasyon pou dlo lapli yo, fose drenaj yo ak oseyan an.`
  },
  howRainFallImpacts: {
    header:
      'Kijan inondasyon apati dlo lapli gendwa enpakte lri, lakay oswa biznis ou',
    icon: stormwaterFlooding,
    iconAlt: 'Inondasyon Dlo Lapli',
    buttonText: 'Aprann plis',
    buttonUrl:
      'https://experience.arcgis.com/experience/6f4cc60710dc433585790cd2b4b5dd0e'
  },
  protectHome: {
    title: 'Pwoteje kay ou kont domaj dlo lapli',
    subtitle:
      'Si ou ap viv inondasyon oswa vle redui pwobabilite li ap rive ou, sa yo se bagay enpòtan pou konsidere.',
    list: [
      {
        header: 'Enstale yon valv pou dlo k ap tounen',
        body:
          'Yon valv pou dlo k ap tounen ka anpeche dlo egou yo monte apati egou vil lan pou antre nan kay ou atravè plonbri sousòl lan. Pandan gwo lapli, si dlo nan egou vil lan monte nan nivo plonbri sousòl lan, valv lan ap fèmen epi bloke dlo egou an antre nan kay ou.',
        image: backwaterValve,
        imageAlt: 'Valv pou dlo k ap tounen'
      },
      {
        header: 'Pwoteje zòn ki pi base nivo lari an',
        body:
          'Lè li ap fè lapli, dlo an koule epi chita nan zòn ki pa yo. Espas tankou garaj anba tè, sousòl ak lòt zòn ki anba yo vilnerab ak inondasyon. Redui risk pou inondasyon nan kòlmate fant nan mi fondasyon yo epi konsidere itilizasyon baryè, tankou sak sab.',
        image: basement,
        imageAlt: 'Sousòl'
      },
      {
        header: 'Redui debòdman dlo lapli',
        body:
          'Dlo debòde sot nan beton ak asfalt prèske tousuit epi li ka depase limit egou yo te fèt pou li an byen vit pandan lapli. Kenbe epi kreye espas ekolojik ki pa betonen toutotou lakay ou ka ede redui ekoulman nan egou an nan redui lè ou redui kantite debòdman dlo lapli pwopriyete ou an voye nan egou yo.',
        image: unpaved,
        imageAlt: 'Plante pyebwa'
      },
      {
        header: 'Pa kite grès al nan egou yo',
        body:
          'Move fason ou jete grès ak luil kuison yo ka bouche tiyo entèn lakay ou yo ansanm ak egou vil yo. Sa ka lakoz refoulman egou yo nan katye ou an epi lakay ou.',
        image: cookingOil,
        imageAlt: 'Luil kuison'
      },
      {
        header: 'Kenbe basen kaptaj yo degaje',
        body: `Pa kite fatra nan lari an ede seche dlo lapli epi ede pwoteje mas dlo vil lan. Vil lan ap travay toutan pou antretni 7,500 mil (12,000 kilomèt) egou li yo ak 148,000 basen kaptaj li yo, ki yo menm ranmase fatra nan lari an. Men si sa yo bloke, li ka kreye inondasyon nan lari an. Ou menm ak vwazen ou yo ka ede redui kalite inondasyon sa a nan byen jete fatra yo epi nan retire fèy oswa fatra yo nan griy basen kaptaj yo avèk swen anvan oswa pandan yon lapli.`,
        image: garbage,
        imageAlt: 'Fatra'
      },
      {
        header: 'Enstale bon jan kanalizasyon pou twati',
        body:
          'Kondui dlo lapli pou soti apati twati ou an pou al lwen mi fondasyon kay ou an esansyèl nan kenbe sousòl ou an sèk. Netwaye goutyè yo yon fason regilye pou anpeche dlo lapli debòde sou mi yo, epi konekte tiyo pou desann dlo lapli yo ak kanalizasyon ki apwopriye. Konsidere pou enstale dwoum pou lapli kòm yon lòt metòd pou kapte dlo lapli.',
        image: roofing,
        imageAlt: 'Kanalizasyon nan twati an'
      },
      {
        header: 'Achte asirans kont Inondasyon',
        body:
          'Kote li ka fè lapli, li ka fè inondasyon—deja anviwon 20% nan tout reklamasyon nan tout peyi an rive andeyò zòn inondasyon FEMA a. Verifye founisè asirans ou an epi konsidere pou achte yon kontra asirans pou refoulman egou oswa kont inondasyon. Kontra sa yo ta ka kouvri domaj ak depans netwayaj yo ki soti nan refoulman egou yo ak inondasyon gwo lapli lakoz yo. Sonje: domaj nan inondasyon pa antre nan kontra asirans estanda pou pwopriyetè oswa lokatè.',
        image: floodInsurance,
        imageAlt: 'Asirans kont Inondasyon'
      }
    ]
  },
  homeOwnerGuide: {
    header: `Gid pou Pwopriyetè Vil lan pou Preparasyon ak Evennman Lapli yo`,
    image: rainEventGuide,
    imageAlt: 'Gid Evennman Lapli yo',
    buttonText: 'Li Plis',
    posterUrl:
      'https://www1.nyc.gov/assets/dep/downloads/pdf/climate-resiliency/flood-preparedness-flyer.pdf'
  }
};
