export default {
  tellYourFriends: 'Di zanmi ak vwazen ouyo',
  about: 'Apwopo',
  privacy: 'Konfidansyalite',
  termsAndConditions: 'Tèm ak Kondisyon yo',
  widget: 'Widget',
  multifamily: 'Pwopriyete pou plizyè fanmi',
  cnycn: 'Center for NYC Neighborhoods',
  facebook: 'Pataje sou Facebook',
  twitter: 'Pataje sou Twitter',
  email: 'Imèl',
  emailSubject: 'Dekouvri Risk Inondasyon Ou an Jodi an!',
  message:
    'Kèlkeswa kote ou ap viv NYC – si li ka fè lapli, li ka inonde. Dekouvri risk inondasyon ou an jodi an nan https://www.floodhelpny.org/!'
};
