import getRaceOptions from '../constants/RaceOptions';
import getEthnicityOptions from '../constants/EthnicityOptions';
import getGenderOptions from '../constants/GenderOptions';

const ALLOWED_ATTACHMENTS = 'Attach (PDF, JPEG, PNG)';

export default {
  nextWebstatus: 'ApplicationSubmitted',
  errorTitle: '提交申请时出错。',
  errorBody:
    '请尝试再次提交。如果问题没有解决，请联系我们info@floodhelpny.org.',
  tryAgain: '再试一次',
  loadingText: '加载中……',
  navCopy: {
    next: '审核',
    previous: '之前的',
    submit: '提交',
    checkResponses: '提交前请检查您输入的数据是否有效'
  },
  applicant: '申请人：',
  address: '地址：',
  providerQuestions: [
    {
      headline:
        '请告知您的房屋住户或可能被视为共同申请人的人（例如，契约上列出的任何人）：',
      title: '其他申请人',
      buttonText: '显示常见问题',
      addRow: true,
      addRowText: '添加申请人',
      removeRow: false,
      removeRowText: '删除申请人',
      faqZone: 'bwv-additional-applicants',
      responseFields: [
        {
          key: 'co_applicant_type',
          title: '其他申请人',
          applicant_type: '',
          show: false,
          default: '选择一个',
          type: 'select-applicant-type',
          options: [
            { value: 'Co-applicant', display: '联合申请人' },
            { value: 'Household-member', display: '家庭成员' }
          ],
          required: false
        },
        {
          key: 'co_applicant_first_name',
          title: '名',
          applicant_type: 'both',
          show: false,
          placeholder: '',
          type: 'text',
          required: false
        },
        {
          key: 'co_applicant_last_name',
          title: '姓',
          applicant_type: 'both',
          show: false,
          placeholder: '',
          type: 'text',
          required: false
        },
        {
          key: 'household_member_income',
          title: '年收入',
          applicant_type: 'Household-member',
          show: false,
          placeholder: 'e.g. 50000',
          type: 'number',
          required: false
        },
        {
          key: 'household_member_race',
          title: '种族',
          applicant_type: 'Household-member',
          show: false,
          default: 'Choose One',
          type: 'drop-down',
          options: getRaceOptions('en'),
          required: false
        },
        {
          key: 'household_member_ethnicity',
          title: '民族',
          applicant_type: 'Household-member',
          show: false,
          default: 'Choose One',
          type: 'drop-down',
          options: getEthnicityOptions('en'),
          required: false
        },
        {
          key: 'household_member_gender',
          title: '性别',
          applicant_type: 'Household-member',
          show: false,
          default: 'Choose One',
          type: 'drop-down',
          options: getGenderOptions('en'),
          required: false
        },
        {
          key: 'co_applicant_birthdate',
          title: '生日',
          applicant_type: 'Co-applicant',
          show: false,
          placeholder: 'e.g. 10/13/1987',
          type: 'date',
          minimum: '1900-01-01',
          maximum: '2020-01-01',
          required: false
        },
        {
          key: 'co_applicant_email',
          title: '电子邮件',
          applicant_type: 'Co-applicant',
          show: false,
          placeholder: 'e.g. example@example.com',
          type: 'email',
          required: false
        },
        {
          key: 'co_applicant_home_phone',
          title: '家庭电话',
          applicant_type: 'Co-applicant',
          show: false,
          placeholder: 'e.g. +1 123-456-7890',
          type: 'tel',
          required: false
        },
        {
          key: 'co_applicant_mobile_phone',
          title: '手机',
          applicant_type: 'Co-applicant',
          show: false,
          placeholder: 'e.g. +1 123-456-7890',
          type: 'tel',
          required: false
        },
        {
          key: 'co_applicant_signature',
          title: '签字',
          applicant_type: 'Co-applicant',
          show: false,
          placeholder: '',
          type: 'text',
          required: false
        }
      ]
    },
    {
      title: '飓风桑迪补偿款',
      headline:
        '您是否从FEMA或其他来源获得了与飓风桑迪有关的损失援助款项？',
      buttonText: '显示常见问题',
      faqZone: 'bwv-sandy-compensation',
      responseFields: [
        {
          key: 'sandy_compensation',
          title: '',
          show: true,
          type: 'button-single',
          options: [
            { value: 'true', display: '是' },
            { value: 'false', display: '否' }
          ],
          invalidAnswers: [],
          alertAnswers: [],
          required: true
        }
      ]
    },
    {
      title: '回水阀补偿款',
      headline:
        '您是否收到了在物业安装下水道回水阀的补偿款或其他款项？',
      buttonText: '显示常见问题',
      faqZone: 'bwv-valve-compensation',
      responseFields: [
        {
          key: 'backwater_valve_compensation',
          title: '',
          show: true,
          type: 'button-single',
          options: [
            { value: 'true', display: '是' },
            { value: 'false', display: '否' }
          ],
          invalidAnswers: [],
          alertAnswers: [],
          required: true
        }
      ]
    },
    {
      title: '飓风桑迪保险类型',
      headline: '在飓风桑迪期间，您投保了什么类型的保险？',
      buttonText: '显示常见问题',
      faqZone: 'bwv-insurance-types',
      responseFields: [
        {
          key: 'sandy_insurance_types',
          type: 'multiple-drop-down',
          size: 6,
          show: true,
          multiple: true,
          title: '多选',
          options: [
            { value: 'Homeowners Insurance', display: '屋主保险' },
            { value: 'Flood Insurance', display: '洪水保险' },
            { value: 'Hazard Insurance', display: '危险保险' },
            { value: 'Wind Insurance', display: '风力发电保险' },
            { value: 'Contents Insurance', display: '内容保险' },
            { value: 'Other Insurance', display: '其他保险' }
          ],
          required: false
        }
      ]
    },
    {
      title: '租户',
      headline: '您的住宅目前有租户吗？',
      buttonText: '显示常见问题',
      faqZone: 'bwv-tenants',
      responseFields: [
        {
          key: 'tenants',
          title: '',
          show: true,
          type: 'button-single',
          options: [
            { value: 'true', display: '是' },
            { value: 'false', display: '否' }
          ],
          invalidAnswers: [],
          alertAnswers: ['true'],
          alertCopy: '请下载 ',
          alertLinkCopy: '租户收入资格表。',
          alertLink: 'http://www.floodhelpny.org/samples/tenantIncome.pdf',
          alertCopyAfterLink:
            '您需要填写并返还给我们。',
          required: true
        }
      ]
    },
    {
      title: '房主保险证明',
      headline: '请附上房主保险证明',
      buttonText: '显示常见问题',
      faqZone: 'bwv-homeowner-insurance',
      responseFields: [
        {
          key: 'homeowners_insurance',
          title: 'homeowner',
          label: ALLOWED_ATTACHMENTS,
          show: true,
          placeholder: '',
          type: 'file',
          remove: '删除文件',
          canUploadMultipleFiles: false,
          fileTypes: '.jpeg, .png, .pdf',
          required: false
        }
      ]
    },
    {
      title: '洪水保险证明',
      headline:
        '请附上您的洪水保险证明（如果您所在的洪水区是 AE、AO 或 VE）',
      buttonText: '显示常见问题',
      faqZone: 'bwv-flood-insurance',
      responseFields: [
        {
          key: 'flood_insurance',
          title: 'flood',
          label: ALLOWED_ATTACHMENTS,
          show: true,
          placeholder: '',
          type: 'file',
          remove: '删除文件',
          canUploadMultipleFiles: false,
          fileTypes: '.jpeg, .png, .pdf',
          required: false
        }
      ]
    },
    {
      title: '收入证明',
      headline:
        '请附上申请人和所有家庭成员的收入证明',
      buttonText: '显示常见问题',
      faqZone: 'bwv-income-proof',
      responseFields: [
        {
          key: 'household_member_proofincome',
          title: 'income',
          label: ALLOWED_ATTACHMENTS,
          show: true,
          type: 'file',
          remove: '删除文件',
          canUploadMultipleFiles: false,
          fileTypes: '.jpeg, .png, .pdf',
          required: false
        }
      ]
    },
    {
      title: '种族',
      headline: '请选择主要申请人的种族',
      faqZone: '',
      responseFields: [
        {
          key: 'applicant_race',
          type: 'drop-down',
          show: true,
          multiple: false,
          default: '选择一个',
          title: '',
          options: getRaceOptions('en'),
          required: false
        }
      ]
    },
    {
      title: '民族',
      headline: '请选择主要申请人的民族',
      faqZone: '',
      responseFields: [
        {
          key: 'applicant_ethnicity',
          type: 'drop-down',
          show: true,
          multiple: false,
          default: '选择一个',
          title: '',
          options: getEthnicityOptions('en'),
          required: false
        }
      ]
    },
    {
      title: '性别',
      headline: '请选择主要申请人的性别身份',
      faqZone: '',
      responseFields: [
        {
          key: 'applicant_gender',
          type: 'drop-down',
          show: true,
          multiple: false,
          default: '选择一个',
          title: '',
          options: getGenderOptions('en'),
          required: false
        }
      ]
    },
    {
      title: '出生日期',
      headline: '请输入主要申请人的出生日期',
      faqZone: '',
      responseFields: [
        {
          key: 'applicant_birthdate',
          title: '',
          show: true,
          placeholder: 'e.g. 10/13/1987',
          type: 'date',
          minimum: '1900-01-01',
          maximum: '2020-01-01',
          required: false
        }
      ]
    },
    {
      title: '电子签名',
      headline:
        '在下面的框中输入您的姓名，表示您同意回水阀计划的条款，并证明您已经阅读我们的隐私政策',
      buttonText: '显示常见问题',
      faqZone: 'bwv-signature',
      responseFields: [
        {
          key: 'e_signature',
          title: '',
          show: true,
          placeholder: '',
          type: 'text',
          required: true
        }
      ]
    }
  ],
  // NOTE the keys in the answers array must match the keys in thq questions Array
  // and be in the same order
  providerAnswers: [
    {
      co_applicant_type: [],
      co_applicant_first_name: '',
      co_applicant_last_name: '',
      household_member_income: '',
      household_member_race: '',
      household_member_ethnicity: '',
      household_member_gender: '',
      co_applicant_birthdate: '',
      co_applicant_email: '',
      co_applicant_home_phone: '',
      co_applicant_mobile_phone: '',
      co_applicant_signature: ''
    },
    { sandy_compensation: '' },
    { backwater_valve_compensation: '' },
    { sandy_insurance_types: [] },
    { tenants: '' },
    { homeowners_insurance: '' },
    { flood_insurance: '' },
    { household_member_proofincome: '' },
    { applicant_race: '' },
    { applicant_ethnicity: '' },
    { applicant_gender: '' },
    { applicant_birthdate: '' },
    { e_signature: '' }
  ]
};
