export default {
  meta: {
    title: 'Política de privacidad',
    url: 'https://www.floodhelpny.org/es/privacy',
    description: null,
    imageAlt: null
  },
  title: 'Privacy Policy',
  intro: {
    effective_date: 'Efectivo el 26 de mayo de 2019',
    body:
      'Gracias por visitar FloodHelpNY.org (el “Sitio”). Esta Política de Privacidad se aplica solamente al Sitio, el cual está bajo el control del Center for New York City Neighborhoods, Inc. (Centro para Vecindarios en la Ciudad de Nueva York), (“nosotros”, “nuestro”). Esta Política de Privacidad se refiere solamente a información que recopilemos a través del Sitio. AL USAR EL SITIO, USTED ACEPTA LOS TÉRMINOS Y LAS CONDICIONES DE ESTA POLÍTICA DE PRIVACIDAD Y NUESTRO PROCESAMIENTO DE INFORMACIÓN PERSONAL CON LOS FINES ENUNCIADOS MÁS ABAJO.  SI NO ESTÁ DE ACUERDO CON LOS TÉRMINOS Y LAS CONDICIONES DE ESTA POLÍTICA DE PRIVACIDAD, NO UTILICE EL SITIO.',
    specific_info:
      'Más abajo se detalla información específica sobre nuestras prácticas de gestión de datos.',
    info_we_collect: 'Información que recopilamos: Personal y no personal',
    we_collect:
      'Recopilamos dos tipos de información de los usuarios que visitan el Sitio: (1) Información personal; y (2) Información no personal, como su dirección de IP o cookies.'
  },
  personal_info: {
    title: 'Información personal',
    body:
      'Cuando usamos el término “Información personal”, significa información que podemos recopilar que les permite a otros identificarle o ponerse en contacto con usted.  Por ejemplo, podemos recabar su dirección de correo electrónico si usted acepta recibir boletines y otra información de parte de nosotros.  También podremos combinar esta información con su dirección o ubicación y con otros datos personales que usted suministre para confirmar que es elegible para recibir nuestros servicios y que la información que le suministramos probablemente sea relevante para usted.',
    examples: 'Ejemplos de información personal recopilada por este Sitio: ',
    email: 'Correo electrónico',
    address: 'Dirección',
    phone: 'Número telefónico',
    homeowner: 'Información relacionada con su propiedad de la vivienda',
    no_obligation:
      'Aunque usted no está bajo ninguna obligación de suministrar la información que se solicita, si se niega a hacerlo, es posible que no podamos prestarle ciertos servicios.  Si no desea que obtengamos su información personal, por favor no nos la suministre.'
  },
  non_personal_info: {
    title: 'Información no personal',
    body:
      '“La información no personal” puede ser información de naturaleza técnica, o pueden ser datos demográficos, tales como su edad, sexo, código postal u otros datos de geolocalización, o sus intereses.  La información no personal NO le identifica a usted personalmente.  Aquí se indican algunos ejemplos de la información no personal que se recaba a través del Sitio y una descripción de cómo se utiliza esta información:',
    ip:
      'Dirección de Protocolo de Internet [Internet Protocol] (IP) – Su dirección de IP es un número que les permite a las computadoras conectadas al Internet saber adónde enviarle datos – como las páginas web que usted visita.  Utilizamos esta información para entregarle nuestras páginas web cuando usted lo solicite, para adaptar nuestro Sitio a los intereses de los usuarios, y para medir el tráfico dentro de nuestro Sitio. ',
    web_beacons:
      'Balizas web [web beacons] (también conocidas como “clear gifs,” “web bugs” o “pixel tags”) – Las “balizas web” son gráficas diminutas con un identificador único, similar en función a las cookies, y se utilizan para permitirnos contar los usuarios que han visitado ciertas páginas del Sitio y para ayudar a determinar la eficacia de nuestros programas y servicios.  Cuando se usan en mensajes de correo electrónico con formato HTML, las balizas web pueden informarle al remitente si se ha abierto un correo electrónico y cuándo.  A diferencia de las cookies, que se almacenan en el disco duro de la computadora del usuario, las balizas web están integradas de manera invisible en las páginas web.',
    demographic:
      'Información demográfica – La “Información demográfica” puede incluir su sexo, edad, código postal e intereses, que pueden ser recabados automáticamente a través del Sitio.  Utilizamos esta información para ofrecerle servicios personalizados y para analizar tendencias y garantizar que la información que ofrece el Sitio satisfaga sus necesidades.  Tenga en cuenta que también consideramos la información agregada, que no es personalmente identificable, como información no personal.',
    addresses:
      'Direcciones buscadas e información correspondiente sobre zonas de inundación – Almacenaremos las direcciones ingresadas en nuestra herramienta de búsqueda, así como los resultados de información.  Usaremos esta información a nivel agregado para evaluar el interés de los usuarios por áreas geográficas particulares, así como los resultados agregados obtenidos sobre riesgo de inundación.  Esta información se utilizará para evaluar la eficacia de nuestras actividades de enlace y estrategias de promoción del sitio web, así como para futuros proyectos de investigación.',
    above_list:
      'La lista anterior ofrece ejemplos de información no personal que se recopila a través del Sitio.  Podremos recabar la anterior información no personal de todos los usuarios que visitan nuestro Sitio.  Si no desea que tengamos acceso a esta información, por favor no visite nuestro Sitio.'
  },
  use_of_information: {
    title: 'Uso de la información recabada a través del Sitio',
    personal_info: 'INFORMACIÓN PERSONAL',
    personal_info_body:
      'Usamos su información personal que se recopila en el Sitio principalmente con los siguientes fines:',
    deliver_services: 'Para brindarle servicios',
    provide_info:
      'Para darle información sobre programas y servicios en su área',
    verify_email: 'Para verificar su dirección de correo electrónico',
    answer_questions: 'Para responder a sus preguntas u otras solicitudes',
    contact:
      'Para contactarle en conexión con su uso del Sitio o de nuestros servicios',
    notify_about_changes:
      'Para notificarle sobre un cambio sustancial en esta Política de Privacidad o en nuestros servicios, si es necesario',
    administer:
      'Para administrar el Sitio o su interacción con nuestros servicios',
    other_purposes:
      'Para cualquier otra finalidad divulgada en el momento que usted provea su información personal',
    non_personal_info: 'INFORMACIÓN NO PERSONAL ',
    non_personal_is_used:
      'La información no personal se utiliza según se describe anteriormente y de otras maneras según lo permitan las leyes aplicables, inclusive combinar información no personal con información personal.',
    non_personal_example:
      'Por ejemplo, podremos utilizar información no personal y datos de utilización para (i) crear estudios de mercado e informes para mostrar cómo nuestros usuarios, como grupos colectivos, interactúan con nuestros servicios, (ii) elaborar informes que nos ayuden a nosotros y a otras terceras entidades estratégicas a determinar qué les interesa a los usuarios de nuestros servicios como grupo, o (iii) de manera general mejorar nuestro programa y los servicios que ofrecemos.  Dichos informes se compartirán con el Center for New York City Neighborhoods (Centro para Vecindarios en la Ciudad de Nueva York), y con terceras entidades que nos ayudan a administrar este Sitio y nuestros servicios.  Sin embargo, tales informes no contendrán ninguna información personal acerca de usted. '
  },
  sharing_and_disclosure: {
    title: 'Intercambio y divulgación de información',
    personal_info: 'INFORMACIÓN PERSONAL',
    personal_examples:
      'Ejemplos de las maneras en que podremos compartir o divulgar su información personal incluyen los siguientes:',
    fulfill:
      'Para prestarle un servicio.  Por ejemplo, si usted nos pide ayuda y/o presenta una solicitud para la Auditoría de Resistencia de la Vivienda o el Programa de Instalación de Válvula Antirretorno, podremos compartir su información personal a fin de prestarle el servicio.  Además, si usted se comunica con nosotros o se inscribe para recibir nuestro boletín, podremos usar su dirección de correo electrónico para responder a su solicitud. ',
    affiliates_home_resiliency:
      'Podremos compartirla con afiliados, subcontratistas, socios estratégicos, agentes, autoridades federales aplicables, la Oficina del Gobernador para Recuperación ante Tormentas [Governor’s Office of Storm Recovery] o sus designados, u otras entidades independientes que ofrezcan servicios como parte de la Auditoría de Resistencia de la Vivienda o el Programa de Instalación de Válvula Antirretorno. Estas entidades podrán usar su información personal para comunicarse con usted respecto a la Auditoría de Resistencia de la Vivienda o el Programa de Instalación de Válvula Antirretorno, y podrán utilizar dicha información para sus propios fines de investigación, administración o negocios.  Si no desea que compartamos su información personal de esta manera, por favor no nos suministre esta información. ',
    affiliates_other:
      'Podremos compartirla con afiliados, socios estratégicos, agentes, u otras entidades independientes que ofrezcan programas o servicios que en nuestra opinión podrían interesarle a usted, o que requieran su información personal con fines de investigación, administración o negocios internos.  Estas entidades podrán utilizar su información personal para contactarle con una oferta relacionada con un programa o servicio, o podrán utilizar esta información para sus propios fines de investigación, administración o negocios. Si no desea que compartamos esta información personal de esta manera, por favor no nos suministre esta información.  ',
    unaffiliated:
      'Podremos compartirla con terceros independientes, tales como proveedores de servicios, agentes, contratistas independientes que nos ayudan a mantener nuestro Sitio y nos brindan otros servicios administrativos (inclusive, entre otros, procesar sus solicitudes, brindar servicio al cliente, mantener y analizar datos, y enviar comunicaciones a los clientes de parte de nosotros).  Procuramos garantizar que estos terceros independientes no utilicen la información personal para ningún propósito aparte de ofrecer los servicios administrativos de los que son responsables.  Debido a que tales terceros independientes proveedores de servicios que nos ayudan a administrar nuestro Sitio tendrán acceso a la información personal de los usuarios, si no desea que estos terceros independientes proveedores de servicios tengan acceso a su información, por favor no ingrese ni presente ninguna información personal.  ',
    comply_with_law:
      'Para cumplir con la ley o si creemos de buena fe que tal acción es necesaria a fin de adherirnos a los requisitos de la ley o cumplir con procesos legales en contra nuestra, proteger y defender nuestros derechos o propiedad, el Sitio, o actuar en circunstancias urgentes para proteger la seguridad personal de nuestros usuarios finales.',
    third_part_corp:
      'Podremos compartirla con terceras entidades como parte de cualquier proceso de reorganización corporativa, inclusive, entre otros, fusiones, adquisiciones y ventas de todos o prácticamente todos nuestros activos.',
    track_and_analyze:
      'Para rastrear y analizar información estadística no identificadora y agregada sobre uso y volumen de nuestros visitantes y clientes y para suministrarles dicha información a terceros. ',
    fraud_protection:
      'Para proteger contra posible fraude, podremos verificar con terceros la información recabada del Sitio.  En el curso de dicha verificación, podremos recibir información personal sobre usted de parte de dichos servicios.',
    personal_footer:
      'Excepto según se describe en esta Política de Privacidad o en el momento en que solicitemos la información, no usamos, compartimos ni de otro modo divulgamos su información personal a terceros que nos ayuden a administrar el Sitio o nuestros servicios.  Si usted no accede a que se divulgue su información personal según se describe más arriba, por favor no nos la suministre. ',
    non_personal_info: 'INFORMACIÓN NO PERSONAL',
    non_personal_content:
      'Usamos información no personal recopilada en el Sitio de la manera descrita anteriormente, en la Sección B (2). Podremos compartir esta información no personal con terceros que nos ayudan a administrar el Sitio o nuestros servicios.',
    children_under_13: 'NIÑOS MENORES DE 13 AÑOS',
    children_under_13_content:
      'El Sitio no está dirigido a niños menores de 13 años ni conscientemente recaba información personal de dichas personas.  Si nos damos cuenta de que un niño menor de 13 años nos ha proporcionado información personal, tomaremos medidas para eliminar dicha información. ',
    opt_out: 'Exclusión',
    opt_out_content:
      'Usted podrá excluirse de recibir mensajes promocionales de parte de nosotros o de terceras entidades estratégicas en cualquier momento.  Para correos electrónicos, podrá excluirse yendo al enlace que se incluye al final de cualquier correo electrónico que le enviemos y excluyéndose de recibir información de parte nuestra.  Procesaremos su solicitud de cancelación lo antes posible, pero tenga en cuenta que en algunas circunstancias usted podría recibir varios mensajes adicionales hasta que se procese la solicitud de cancelación.  Dicho esto, se le advierte que cancelar los correos electrónicos promocionales no lo excluirá de recibir correos transaccionales o correos electrónicos que estemos obligados a enviar.  ',
    third_parties: 'SU DIVULGACIÓN A TERCEROS',
    third_parties_content:
      'Si usted responde a algún anuncio interactivo u oferta de una tercera entidad (ya sea vía correo electrónico, mensaje o de otro modo), o si usted solicita información sobre un producto, programa o servicio ofrecido por una tercera entidad, entonces se le divulgará su información personal a dicha tercera entidad. No podemos controlar, y no formulamos declaraciones acerca de, la recopilación o el uso de su información por dichas terceras entidades.  Si usted decide registrarse o suscribirse a cualquier producto o servicio de dicha tercera entidad, cualquier correspondencia adicional entre usted y dicha tercera entidad, inclusive, entre otras cosas, cualesquier acuerdos contractuales que resulten, serán exclusivamente entre usted y el anunciante/la tercera entidad.  No seremos responsables de hacer cumplir cualesquier términos, condiciones, declaraciones o garantías ofrecidos por el anunciante/la tercera entidad en conexión con ello y no tendremos responsabilidad alguna en conexión con su interacción con dicha tercera entidad.',
    correct_inaccuracies:
      '¿CÓMO PUEDE CORREGIR CUALESQUIER ERRORES EN SU INFORMACIÓN PERSONAL?',
    correct_inaccuracies_body:
      'Si tiene alguna inquietud acerca de la exactitud de la información que podamos haber recabado acerca de usted y quisiera tener acceso a dicha información, comuníquese con nosotros en ',
    correct_inaccuracies_body_email: 'cnycn.org.',
    info_protected: '¿CÓMO SE PROTEGE SU INFORMACIÓN?',
    info_protected_body:
      'Consideramos primordial la confidencialidad y la seguridad de su información.  Utilizamos medidas de seguridad físicas, técnicas y administrativas para mantener su información personal segura y confidencial, y no se la proporcionaremos a terceros, excepto a aquellas entidades que ayudan a administrar el Sitio y nuestros servicios, según lo exija la ley, o según se disponga lo contrario en esta Política de Privacidad. Sin embargo, el Internet no es un entorno seguro, y no podemos garantizar 100% la seguridad de su información; existe cierto riesgo de que un tercero no autorizado podría encontrar la manera de evadir nuestras medidas de seguridad o que la información transmitida por el Internet pueda ser interceptada. ',
    info_protected_body_2:
      'Si descubrimos una violación en un sistema de seguridad, podremos intentar notificarle electrónicamente para que usted pueda tomar las medidas de protección adecuadas.  Al usar el Sitio o suministrarnos información personal, usted acepta que podremos comunicarnos con usted electrónicamente acerca de cuestiones de seguridad, privacidad y administrativas en conexión con su uso del Sitio.  Podremos colocar un aviso en nuestro Sitio si ocurre una violación de seguridad.  También podremos enviarle un correo a la dirección de correo electrónico que usted nos haya proporcionado en estas circunstancias, o una carta al domicilio suministrado.  Dependiendo de dónde usted viva, podría tener el derecho legal de recibir avisos sobre una violación de seguridad por escrito. ',
    third_party_sites: 'SITIOS WEB DE TERCEROS',
    third_party_sites_body:
      'Esta declaración se aplica exclusivamente a información recopilada en el Sitio.  El Sitio podría contener enlaces a otros sitios web.  No somos responsables por las prácticas de privacidad ni el contenido de estos otros sitios web.',
    do_not_track:
      '¿CÓMO RESPONDEMOS A SEÑALES DE “DO NOT TRACK” (NO RASTREAR)?',
    do_not_track_body:
      'Tenga en cuenta que nuestro Sitio no admite configuraciones de “Do Not Track” (No rastrear) en el navegador y actualmente no participa en ninguna plataforma de “Do Not Track” que nos permita responder a señales u otros mecanismos iniciados por usted en conexión con la recopilación de su información personal o no personal.',
    assignment: 'CESIÓN',
    assignment_body:
      'En caso de que todos o una parte de nuestros activos se vendan o sean adquiridos por otra entidad, o en caso de una fusión, usted nos otorga el derecho de ceder la información personal y no personal recabada a través del Sitio.',
    changes: 'CAMBIOS A ESTA POLÍTICA DE PRIVACIDAD',
    changes_body:
      'Nos reservamos el derecho de modificar esta Política de Privacidad a medida que nuestros servicios crezcan y mejoren, o para responder a modificaciones en las regulaciones legales o los intereses comerciales. Cuando lo hagamos, también modificaremos la fecha “efectiva” al inicio de esta Política de Privacidad. Para cambios a esta Política de Privacidad que puedan ser sustancialmente menos restrictivos sobre nuestro uso o divulgación de la información personal que usted nos haya proporcionado, trataremos de obtener su autorización antes de implementar el cambio, enviando una notificación a la dirección de correo electrónico primaria que tenemos archivada o colocando un aviso prominente en el Sitio. '
  },
  questions: {
    title: '¿Tiene preguntas?',
    body:
      'Para preguntas sobre esta Política de Privacidad, por favor envíe un correo electrónico a ',
    email: 'info@floodhelpny.org.'
  }
};
