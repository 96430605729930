import React from 'react';
import { useProvider } from '../helpers/hooks';
import { Section } from './ui/StyledResourcePageElements';
import ListWithIconAndTextAndLinks from './layout/ListWithIconAndTextAndLinks';

const AboutAuditProgramDocuments = () => {
  const provider = useProvider('aboutAuditProgramDocuments');

  return (
    <Section noBorder>
      <h5>{provider.header}</h5>
      <ListWithIconAndTextAndLinks items={provider.list} />
    </Section>
  );
};

export default AboutAuditProgramDocuments;
