import React, { Component } from 'react';
import PropTypes from 'prop-types';
export default class PrefirmCallout extends Component {

  static propTypes = {
    provider: PropTypes.object.isRequired
  };

  render() {
    const provider = this.props.provider;
    return (
      <div className="loma-callout promo promo--action">
        <span className="lnr lnr-bullhorn"/>

        <div>
          <p className='head'>{provider.header}</p>

          <p className='body'>{provider.body}</p>
        </div>
        <a className='btn btn--main btn--big'
           href='https://www.fema.gov/letter-map-amendment-letter-map-revision-based-fill-process'>{provider.link}</a>
      </div>
    );
  }
}
