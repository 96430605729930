import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import RoundIcon from '../RoundIcon';

const ListItem = styled.li`
  display: flex;
  margin-top: 4rem;

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

const StyledIcon = styled(RoundIcon)`
  margin-right: 4rem;

  @media (max-width: 800px) {
    margin: 0 0 1rem 0;
  }
`;

const TextContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;

  h4 {
    margin-bottom: 1rem;
  }

  @media (max-width: 800px) {
    max-width: 400px;
  }
`;

const ListWithIconAndText = ({ items, className }) => (
  <ul>
    {items.map((item, idx) => (
      <ListItem key={idx}>
        <StyledIcon src={item.image} alt={item.imageAlt || ''} />
        <TextContent className={className}>
          <h4>{item.header}</h4>
          <p dangerouslySetInnerHTML={{ __html: item.body }} />
        </TextContent>
      </ListItem>
    ))}
  </ul>
);

ListWithIconAndText.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string.isRequired,
      imageAlt: PropTypes.string,
      header: PropTypes.string.isRequired,
      body: PropTypes.string.isRequired
    })
  ).isRequired
};

export default ListWithIconAndText;
