export default {
    currentPrefix: 'Данная собственность в настоящий момент в ',
    futurePrefix: 'но это может измениться на ',
    xZone: 'зона среднего риска',
    aeZone: 'зона повышенного риска',
    aoZone: 'зона повышенного риска',
    veZone: 'зона наивысшего риска',
    noneZone: 'зона минимальной опасности',
    minimalHazardZone:
      'Данная собственность в настоящий момент в зоне минимальной опасности наводнения.',
    disclaimer:
      'Так как карты могут быть неточными, мы не можем быть на 100% уверены, что ваша собственность находится в одной или более из этих зон. Получите дополнительную информацию в наших ',
    disclaimerLink: 'Правилах и условиях.'
  };