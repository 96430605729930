import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import RateCalculatorCommunicator from "../../communicators/RateCalculatorCommunicator";
import accounting from "accounting";

export default class RateDisplay extends Component {
  static propTypes = {
    api: PropTypes.string.isRequired,
    results: PropTypes.object.isRequired
  };

  constructor(props, context) {
    super(props, context);
    this.state = { rate: 0 };
  }

  async componentWillMount() {
    try {
      const result = await RateCalculatorCommunicator.getRate(
        this.props.api,
        this.props.results
      );
      this.setState({ rate: result.rate });
    } catch (error) {
      // TODO add error handling to this try/catch
    }
  }

  async componentWillReceiveProps(nextProps) {
    try {
      const result = await RateCalculatorCommunicator.getRate(
        nextProps.api,
        nextProps.results
      );
      this.setState({ rate: result.rate });
    } catch (error) {
      // TODO add error handling to this try/catch
    }
  }

  render() {
    return (
      <span className="rate-display">
        {accounting.formatMoney(this.state.rate, "$", 0)}
      </span>
    );
  }
}
