import fillInBasement from '../images/fill-in-basement.svg';
import elevate from '../images/elevate.svg';
import elevateMechanicals from '../images/elevate-mechanicals.svg';
import abandonFirstFloor from '../images/abandon-first-floor.svg';
import floodVents from '../images/flood-vents.svg';

export default {
  meta: {
    title: 'Mitigación',
    url: 'https://www.floodhelpny.org/es/mitigation',
    description: 'Estrategias para mitigar su riesgo de inundaciones'
  },
  title: 'Mitigación',
  overview: 'Resumen',
  affectRate: '¿Cómo afectaría su tarifa de seguro contra inundaciones?',
  howItWorks: '¿Cómo funciona?',
  effort: 'Esfuerzo',
  backwaterValve: 'Válvulas antirretorno: lo que necesitas saber',
  elevate: {
    meta: {
      title: 'Elevar su vivienda',
      url: 'https://www.floodhelpny.org/es/mitigation/elevate',
      description:
        'Elevar su vivienda implica levantar su estructura por encima de la altura de las inundaciones proyectadas y colocarla en una base nueva o extendida.'
    },
    title: 'Elevar su vivienda',
    subtitle:
      'Elevar su vivienda implica levantar su estructura por encima de la altura de las inundaciones proyectadas y colocarla en una base nueva o extendida.',
    overview:
      'Por lo general, esto se logra levantando su vivienda en soportes temporales por encima de su base actual, luego construyendo o creando una nueva base más alta, y después bajando la vivienda en la nueva base. La elevación es costosa y requiere que usted se mude temporalmente, pero reducirá de manera considerable tanto el riesgo de daños por inundaciones como la prima de seguro contra inundaciones.',
    image: elevate,
    imageAlt: 'Elevar',
    affectRate:
      'La elevación es la manera mas eficaz de reducir su tarifa de seguro contra inundaciones y que se mantenga baja en el futuro. Si su vivienda está ubicada en una zona de riesgo de inundación especial, el factor principal para determinar su tarifa de seguro contra inundaciones es la diferencia entre la elevación del piso más bajo de su casa y el Nivel de Inundación Base. Si eleva su vivienda por encima del BFE, reduce su riesgo, lo que minimiza su prima. Si desea calificar para la tarifa baja después de una elevación, el espacio debajo de su casa se puede utilizar solo para almacenamiento, estacionamiento y acceso.',
    howItWorks:
      'Si eleva su vivienda por encima del BFE, es probable que su espacio para vivir y los servicios públicos estén por encima del agua en caso de inundación, y esto mantendrá su propiedad y objetos valiosos a salvo de los daños por inundaciones. Si usted decide elevarla, debe levantar su vivienda al menos dos pies por encima del BFE con el fin de tener en cuenta las dudas que haya en los mapas de inundación y el aumento anticipado del nivel del mar. Estos dos pies de elevación adicionales se conocen como "francobordo" y forman parte del código de construcción de NYC.  La nueva altura de la vivienda, incluyendo el francobordo, estaría al Nivel de Inundación del Proyecto (DFE).',
    effort:
      'Una elevación de vivienda puede tardar hasta tres meses, dependiendo del contratista y de la estructura. Durante ese tiempo, usted tendrá que reubicar y quitar los objetos de valor de su casa. Durante la construcción, se deshabilitarán los servicios públicos como agua, alcantarillado y electricidad de su casa.'
  },
  elevateMechanicals: {
    meta: {
      title: 'Elevar el equipo mecánico de su vivienda',
      url: 'https://www.floodhelpny.org/es/mitigation/elevate-mechanicals',
      description:
        'El equipo mecánico de su vivienda (como el horno, las instalaciones eléctricas y las cañerías) es esencial.'
    },
    title: 'Elevar el equipo mecánico de su vivienda',
    subtitle:
      'El equipo mecánico de su vivienda (como el horno, las instalaciones eléctricas y las cañerías) es esencial.',
    overview:
      'Cuando el agua de la inundación alcanza el equipo, puede dejar de funcionar correctamente. Su reemplazo o reparación puede costar cientos de miles de dólares. Es por eso que una de las opciones más eficaces para que su vivienda sea menos vulnerable a las inundaciones es levantar el equipamiento mecánico. Esto puede hacerse levantando los equipos de donde se encuentran actualmente o trasladándolos del sótano o entrepiso al primer piso o a un piso más alto. En algunos casos, es posible que los propietarios quieran construir una pequeña ampliación de sus viviendas por encima de la elevación de la inundación, como un cuarto de servicio. Desafortunadamente, levantar sus equipos mecánicos le ahorrará poco o nada de dinero en su prima de seguro contra inundaciones. Además, es una opción relativamente costosa. ',
    image: elevateMechanicals,
    imageAlt: 'Elevar mecánicas',
    affectRate:
      'Levantar los equipos mecánicos en su vivienda podría ahorrarle en su prima entre $100 y $200 por año.',
    howItWorks:
      'Cuando reubica sus equipos mecánicos por encima de la elevación de inundaciones o fuera de su sótano, usted reduce de manera considerable el riesgo de que los sistemas críticos se dañen durante una inundación. No solo ahorrará los excesivos costos de reparación y reemplazo, sino que también podrá regresar a su hogar mucho más rápido tras una inundación si sus sistemas de calefacción y electricidad están intactos. Solamente eso le puede ahorrar cientos o incluso miles de dólares en costos de reubicación temporal.',
    effort:
      'El traslado del equipamiento mecánico de su casa puede durar de tres días a algunas semanas, dependiendo de su vivienda y su contratista y de los sistemas que elija trasladar. Durante ese tiempo, usted tendrá acceso limitado o no a su calefacción, agua caliente y servicios eléctricos.'
  },
  fillInBasement: {
    meta: {
      title: 'Relleno de un sótano o entrepiso',
      url: 'https://www.floodhelpny.org/es/mitigation/fill-in-basement',
      description:
        'Llenar su sótano o entrepiso con arena, gravilla u otro material hasta el nivel del suelo puede reducir su riesgo de daños por inundaciones y su prima de seguro contra inundaciones.'
    },
    title: 'Relleno de un sótano o entrepiso',
    subtitle:
      'Llenar su sótano o entrepiso con arena, gravilla u otro material hasta el nivel del suelo puede reducir su riesgo de daños por inundaciones y su prima de seguro contra inundaciones.',
    overview:
      'También tendrá que instalar desagües para inundaciones en la parte superior restante del sótano o entrepiso. Aunque rellenar el sótano puede ser costoso al principio, puede terminar ahorrándole miles de dólares en su prima de seguro contra inundaciones a largo plazo. En algunos casos, el relleno del sótano podría dañar la integridad estructural de la edificación, por lo que es esencial que consulte a un ingeniero antes de comenzar.',
    image: fillInBasement,
    imageAlt: 'Rellenar el sótano',
    affectRate:
      'Si su vivienda está ubicada en una zona de riesgo de inundación especial, el factor principal para determinar su tarifa de seguro contra inundaciones es la diferencia entre la elevación del primer piso de su casa (este es el piso del sótano) y el Nivel de Inundación Base. Al rellenar el sótano e instalar desagües para inundaciones, usted acorta esa diferencia, ya que, para su seguro, su siguiente piso sobre el suelo se convierte en el piso más bajo de su vivienda. Esto reducirá su prima de seguro contra inundaciones, aunque la disminución de la prima variará según la elevación del suelo en comparación con el BFE.',
    howItWorks:
      'Al eliminar su sótano e instalar desagües para inundaciones, usted aumentará la elevación del piso más bajo de su vivienda sin levantar la casa, su primer piso sobre el suelo se convertirá en su "elevación del piso más bajo". Cuando rellene su sótano, asegúrese además de que todo el contenido esté situado por encima del nivel del suelo, lo que hace que tenga menos posibilidades de sufrir daños en caso de una inundación. La instalación de los desagües para inundaciones protege los cimientos y las paredes de su vivienda de los daños por la presión de agua. Sin embargo, para rellenar el sótano es posible que tenga que renunciar a parte de la zona habitable de su casa, por eso debe considerar si estos beneficios son más importantes que la pérdida de este espacio. ',
    effort:
      'El relleno del sótano o entrepiso y la instalación de desagües para inundaciones pueden tardar un promedio de una a dos semanas, una vez que se obtengan todos los permisos y comience la construcción.'
  },
  floodVents: {
    meta: {
      title: 'Instalación de desagües para inundaciones',
      url: 'https://www.floodhelpny.org/es/mitigation/flood-vents',
      description:
        'La instalación de desagües para inundaciones consiste en agregar aberturas debajo del piso más bajo de su vivienda para permitir que el agua circule en caso de una inundación sin causar daños estructurales.'
    },
    title: 'Instalación de desagües para inundaciones',
    subtitle:
      'La instalación de desagües para inundaciones consiste en agregar aberturas debajo del piso más bajo de su vivienda para permitir que el agua circule en caso de una inundación sin causar daños estructurales.',
    overview:
      'Esta es una de las opciones de mitigación menos costosa disponible, pero es solo una opción en las viviendas en las que las utilidades y los controles mecánicos están por encima, o pueden ser reubicados más arriba, a una altura específica llamada Nivel de Inundación del Proyecto. Si desea instalar desagües para inundaciones o aberturas, las áreas por debajo del Nivel de Inundación Base deben ser utilizadas solamente para estacionamiento, almacenamiento, o acceso a la edificación y el área por debajo del BFE debe tener al menos un lado que esté en o sobre la superficie del suelo.',
    image: floodVents,
    imageAlt: 'Ventilaciones de inundación',
    affectRate:
      'La instalación de desagües para inundaciones disminuye su prima de seguro contra inundaciones si al menos un lado del piso de su sótano o entrepiso está en o sobre el nivel del suelo, o si se hace en paralelo con el relleno en el sótano o entrepiso. En zonas de alto riesgo de inundación, el factor principal para determinar su tarifa de seguro es la diferencia entre la elevación del piso más bajo de su vivienda y el BFE. Si rellena su sótano e instala desagües para inundaciones, el siguiente piso más alto se convierte en su "piso más bajo", lo que reducirá su prima de seguro.',
    howItWorks:
      'Al agregar aberturas al lado de su edificación, se reduce el riesgo de daños causados por la presión de agua (fuerzas hidrostáticas e hidrodinámicas). En caso de una inundación, el agua puede circular libremente en su casa a través de las rejillas de ventilación, sin la necesidad de que alguien intervenga, lo que reducirá el riesgo de daño estructural a sus paredes o cimiento.',
    effort:
      'Si sus servicios están por encima del Nivel de Inundación del Proyecto y no tiene un sótano o entrepiso para rellenar, la instalación de desagües para inundaciones es relativamente fácil, y sólo debería tomar unos días. Si usted necesita rellenar un sótano o entrepiso y/o levantar equipamiento mecánico, hacerlo a prueba de inundación húmeda sería más complicado, costoso y tomaría mucho tiempo.'
  },
  abandonFirstFloor: {
    meta: {
      title: 'Abandone el primer piso',
      url: 'https://www.floodhelpny.org/es/mitigation/abandon-first-floor',
      description:
        'Abandonar el primer piso de su propiedad implica que su segundo piso o el más alto se convierta en su lugar para vivir y que su primer piso sea solo para almacenamiento, estacionamiento o acceso.'
    },
    title: 'Abandone el primer piso',
    subtitle:
      'Abandonar el primer piso de su propiedad implica que su segundo piso o el más alto se convierta en su lugar para vivir y que su primer piso sea solo para almacenamiento, estacionamiento o acceso.',
    overview:
      'Si tiene un sótano o entrepiso subterráneo, tendrá que rellenarlo también. Necesitará reubicar todos los servicios por encima del Nivel de Inundación del Proyecto o colocarlos afuera en una estructura elevada. Además tendrá que dejar el primer piso sin "acabado" (sin alisado) y agregar desagües para inundaciones o aberturas por debajo del Nivel de Inundación del Proyecto de su propiedad. Esto puede ser una estrategia costosa, pero puede reducir su prima de seguro y el riesgo de manera considerable. Además, esto puede significar que renuncie a una parte significativa del área habitable de su vivienda, lo que constituye un importante desafío a considerar cuando decide si adopta o no esta opción de mitigación.',
    image: abandonFirstFloor,
    imageAlt: 'Abandonar el primer piso',
    affectRate:
      'Si abandona el primer piso de su propiedad, usted aumentará de manera eficaz la elevación de su espacio habitable más bajo, o "piso más bajo" con el fin de calificar, lo que reduce su prima.',
    howItWorks:
      'Abandonar el primer piso de su vivienda puede hacer que se eleve al nivel del BFE o más. Esto aumenta la probabilidad de que esté protegido de las aguas de inundaciones, y mantiene su propiedad y sus objetos de valor a salvo ante los daños.',
    effort:
      'Abandonar su primer piso requerirá una reubicación temporal, así como una construcción adicional para mover sus equipos mecánicos y electrodomésticos, y la construcción de una entrada alternativa para su casa después que se rellene el primer piso.'
  }
};
