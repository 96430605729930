import lookupErrorText from './LookupErrors.en';
import arrow from '../images/arrow.svg';

export default {
  icon: arrow,
  loadingText: '',
  minimalHazardZone: 'Этот адрес находится в зоне минимальной опасности наводнения.',
  bfe: 'Базовая высота наводнения (BFE)',
  button: 'Дополнительная информация',
  X: 'зона среднего риска наводнения',
  AE: 'зона повышенного риска',
  VE: 'зона очень высокого риска',
  None: 'зона минимальной опасности',
  tryAnotherAddress: 'Попробуйте другой адрес.',
  errorText:
    "Мы сожалеем. Мы не смогли найти информацию о введенном вами адресе. Проверьте адрес или попробуйте еще раз позже. Если вам кажется, что вы получили это сообщение по ошибке, позвоните в нашу Центральную станцию домовладельцев по телефону 646-786-0888.",
  currentlyIn: 'Данная собственность в настоящее время в ',
  changingTo: ', но она скорее всего изменится на ',
  stayingAt: ", и вероятно останется на том же уровне риска.",
  ...lookupErrorText
};
