import sortingOptions from '../images/sorting-options.svg';
import structural from '../images/structural.svg';
import wrench from '../images/wrench.svg';
import speechBubbles from '../images/speech-bubbles.svg';

export default {
  header: 'Что можно ожидать',
  list: [
    {
      header: 'Оценка',
      imageAlt: '',
      body:
        'Аккредитованная инжиниринговая компания произведет оценку вашего дома и предоставит вам отчет об  устойчивости, который представит в общих чертах что вы можете сделать, чтобы защитить себя от наводнения.',
      image: structural
    },
    {
      header: 'Обсуждение',
      imageAlt: '',
      body:
        'Мы назначим время, чтобы вы могли поговорить с консультантом по устойчивости, который даст вам подробные объяснения по вашему отчету и вашим вариантам.',
      image: speechBubbles
    },
    {
      header: 'Рассмотрение',
      imageAlt: '',
      body:
        'Мы загрузим ваш отчет и сертификат высотной отметки на сайт для вашего рассмотрения, чтобы вы могли решить, какие шаги предпринять.',
      image: sortingOptions
    },
    {
      header: 'Действие',
      imageAlt: '',
      body:
        'Ваш отчет будет включать в себя все варианты того, как вы можете укрепить ваш дом и мы можем связать вас с любыми ресурсами, которые могут вам понадобиться, чтобы приступить к работе.',
      image: wrench
    }
  ]
};
