import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { lighten } from 'polished';
import { colors, borderRadius } from '../../styles/variables';

const Container = styled.div`
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  font-size: 1rem;
  margin-bottom: 2em;

  label {
    padding: 1rem 3rem;
    width: 100%;
  }

  @media (max-width: 769px) {
    flex-flow: column;
    text-align: left;
    width: 100%;

    label {
      text-align: center;
      padding: 1rem 70px;
    }
  }
`;

const RadioButton = styled.div`
  input[type='radio']:checked + label {
    color: ${colors.resilientBlue};
    border: 1px solid ${colors.resilientBlue};
    box-shadow: inset 0 0 0 2px ${colors.resilientBlue};
  }

  label {
    display: inline-block;
    margin: 0;
    background-color: #fff;
    border: 1px solid ${colors.grayScale[1]};
    font-size: 1rem;
    font-weight: 500;
    border-radius: ${borderRadius};
    transition: all 0.25s ease;
    overflow: hidden;
  }

  // Hides the default radio buttons
  input[type='radio'] {
    display: none;
  }

  & > * {
    display: flex;
    flex-flow: column;
    align-items: baseline;
    justify-content: flex-end;
  }

  @media (max-width: 769px) {
    width: 50%;
  }

  @media (max-width: 420px) {
    width: 100%;
  }
`;

const PromoEstimate = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  background-color: ${lighten(0.4, colors.accent.confirm)};
  padding: 30px;

  p {
    margin-right: 30px;
  }

  @media (max-width: 769px) {
    flex-direction: column;
    padding: 30px;

    p {
      margin-right: 0px;
      margin-top: 0;
      margin-bottom: 30px;
    }
  }
`;

const PromoWarning = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: top;
  justify-content: flex-start;
  background-color: ${lighten(0.4, colors.pinkBerry)};
  padding: 30px;

  p {
    margin: 0;
  }

  @media (max-width: 769px) {
    flex-direction: column;
    padding: 30px;

    p {
      margin-right: 0px;
      margin-top: 0;
      margin-bottom: 30px;
    }
  }
`;

const RadioInput = ({
  responseField,
  answer,
  questionKey,
  answers,
  setAnswers,
  index
}) => {
  const setResponse = (e) => {
    const newAnswersArray = [...answers];
    newAnswersArray[index] = { [questionKey]: e.target.value };
    setAnswers(newAnswersArray);
  };

  const renderButtons = () => {
    const buttons = responseField.options.map((option) => {
      return (
        <RadioButton key={`${questionKey}-${option.value}`}>
          <input
            value={option.value}
            id={`${questionKey}-${option.value}`}
            checked={option.value === answer}
            type="radio"
            name={`${questionKey}-radiobuttons`}
            onChange={(e) => setResponse(e)}
            required={responseField.required}
          />
          <label htmlFor={`${questionKey}-${option.value}`}>
            {option.display}
          </label>
        </RadioButton>
      );
    });
    return buttons;
  };

  const renderInvalidCopy = () => {
    const answerIsInvalid = responseField.invalidAnswers.indexOf(answer) !== -1;
    if (!answerIsInvalid) {
      return null;
    }
    return (
      <PromoWarning>
        <p>
          {responseField.invalidCopy}
          <a href={responseField.invalidLink}>
            {responseField.invalidLinkCopy}
          </a>
          {responseField.invalidCopyAfterLink}
        </p>
      </PromoWarning>
    );
  };

  const renderAlertCopy = () => {
    const alertCopy = responseField.alertAnswers;
    const answerHasAlert = alertCopy.indexOf(answer) !== -1;
    if (!answerHasAlert) {
      return null;
    }
    return (
      <PromoEstimate>
        <p>
          {responseField.alertCopy}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={responseField.alertLink}
          >
            {responseField.alertLinkCopy}
          </a>
          {responseField.alertCopyAfterLink}
        </p>
      </PromoEstimate>
    );
  };

  return (
    <div>
      <label>{responseField.title}</label>
      <Container>{renderButtons()}</Container>
      <div>
        {renderInvalidCopy()}
        {renderAlertCopy()}
      </div>
    </div>
  );
};

RadioInput.propTypes = {
  questionKey: PropTypes.string.isRequired,
  responseField: PropTypes.object.isRequired,
  answer: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.array
  ]),
  index: PropTypes.number.isRequired,
  answers: PropTypes.array.isRequired,
  setAnswers: PropTypes.func.isRequired
};

export default RadioInput;
