import APICommunicator from './APICommunicator';

const BuildingApiCommunicator = {
  checkEligibility: (eligibilityLookupParams) => {
    const endpoint = `${eligibilityLookupParams.api}check_eligibility`;
    const { address } = eligibilityLookupParams;
    return APICommunicator.get(endpoint, { address });
  }
};

export default BuildingApiCommunicator;
