import ApiCommunicator from './APICommunicator';

const ParametricSalesforceCommunicator = {
  submitIntakeForm: (apiUrl, answers) => {
    return ApiCommunicator.post(`${apiUrl}kase`, answers);
  },
  getKaseCount: (apiUrl) => {
    return ApiCommunicator.get(`${apiUrl}kase_count`);
  },
  getParametricApplication: (apiUrl, id) => {
    return ApiCommunicator.get(`${apiUrl}kase/${id}`, {});
  }
};

export default ParametricSalesforceCommunicator;
