import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { colors } from '../styles/variables';
import OtherResources from './OtherResources';
import ButtonLink from './ui/ButtonLink';
import RoundIcon from './RoundIcon';
import CallToAction from './layout/CallToAction';
import {
  HeaderSection,
  Subheader,
  Section,
  SectionIntro,
  SectionDescription
} from './ui/StyledResourcePageElements';
import { useProvider, useFaq, useLocale } from '../helpers/hooks';

const Step = styled.div`
  display: flex;
  margin-top: 4rem;

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

const StyledIcon = styled(RoundIcon)`
  margin-right: 4rem;

  @media (max-width: 800px) {
    margin: 0 0 1rem 0;
  }
`;

const StepEyebrow = styled.h5`
  font-size: 1rem;
  margin-bottom: 1rem;
`;

const StepContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;

  h4 {
    margin-bottom: 1rem;
  }

  @media (max-width: 800px) {
    max-width: 400px;
  }
`;

const StepSubsection = styled.div`
  margin: 1.5rem 0;

  h5 {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
`;

const QuestionWrapper = styled(StepSubsection)`
  display: flex;
  align-items: baseline;
  max-width: 1000px;
`;

const QuestionNumber = styled.p`
  font-size: 1.5rem;
  font-family: GT-Walsheim-Pro-Bold;
  margin-right: 1rem;
  color: ${colors.resilientBlue};
`;

const InsuranceShopping = () => {
  const provider = useProvider('insuranceShopping');
  const locale = useLocale();
  const { setFaqZone } = useFaq();

  useEffect(() => {
    setFaqZone('insurance-shopping');
  }, [setFaqZone]);

  return (
    <>
      <Helmet>
        <title>{provider.meta.title}</title>
        <link rel="canonical" href={provider.meta.url} />
        <meta name="description" content={provider.meta.description} />
        <meta property="og:title" content={provider.meta.title} />
        <meta
          property="og:image"
          content="https://www.floodhelpny.org/images/small_business.png"
        />
        <meta property="og:description" content={provider.meta.description} />
        <meta property="og:url" content={provider.meta.url} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image:alt" content={provider.meta.imageAlt} />
      </Helmet>
      <HeaderSection>
        <h1>{provider.header}</h1>
        <Subheader>{provider.subHeader}</Subheader>
      </HeaderSection>
      <Section>
        <SectionIntro>
          <h5>{provider.overview.header}</h5>
          <SectionDescription>{provider.overview.body}</SectionDescription>
        </SectionIntro>
      </Section>
      <CallToAction>
        <RoundIcon
          src={provider.understandingFloodInsurance.icon}
          alt={provider.understandingFloodInsurance.iconAlt}
        />
        <div>
          <h3>{provider.understandingFloodInsurance.header}</h3>
        </div>
        <ButtonLink
          url={`/${locale}/understanding-flood-insurance`}
          text={provider.understandingFloodInsurance.buttonText}
        />
      </CallToAction>
      <Section noBorder>
        <SectionIntro>
          <SectionDescription>{provider.stepsIntro}</SectionDescription>
        </SectionIntro>
        <Step>
          <StyledIcon src={provider.start.icon} alt={provider.start.iconAlt} />
          <StepContent>
            <StepEyebrow>{provider.start.step}</StepEyebrow>
            <h4>{provider.start.title}</h4>
            <StepSubsection>
              <h5>{provider.start.haveInsurance.title}</h5>
              <p>{provider.start.haveInsurance.body}</p>
            </StepSubsection>
            <StepSubsection>
              <h5>{provider.start.findAgent.title}</h5>
              <span>
                {provider.start.findAgent.body}
                <a
                  href="https://www.floodsmart.gov/flood-insurance-provider"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  floodsmart.gov/flood-insurance-provider
                </a>
                .
              </span>
            </StepSubsection>
          </StepContent>
        </Step>
      </Section>
      <Section>
        <Step>
          <StyledIcon src={provider.ask.icon} alt={provider.ask.iconAlt} />
          <StepContent>
            <StepEyebrow>{provider.ask.step}</StepEyebrow>
            <h4>{provider.ask.title}</h4>
            {provider.ask.questions.map((question, idx) => (
              <QuestionWrapper>
                <QuestionNumber>{idx + 1}</QuestionNumber>
                <div>
                  <h5>{question.header}</h5>
                  <p>{question.description}</p>
                </div>
              </QuestionWrapper>
            ))}
          </StepContent>
        </Step>
      </Section>
      <Section>
        <Step>
          <StyledIcon
            src={provider.doubleCheck.icon}
            alt={provider.doubleCheck.iconAlt}
          />
          <StepContent>
            <StepEyebrow>{provider.doubleCheck.step}</StepEyebrow>
            <h4>{provider.doubleCheck.title}</h4>
          </StepContent>
        </Step>
      </Section>
      <OtherResources />
    </>
  );
};

export default InsuranceShopping;
