import profile from './Profile.ru';

export default {
  meta: {
    title: 'Статус инспекции устойчивости моего дома ',
    url: 'https://www.floodhelpny.org/ru/audit-status'
  },
  previewHeader: 'Превью отчета об устойчивости дома ',
  floodZoneProvider: profile.floodZoneProvider,
  auditRateProvider: {
    header: 'ВАШ СТРАХОВОЙ ТАРИФ, ОСНОВАННЫЙ НА ВАШЕМ СЕРТИФИКАТЕ ВЫСОТНОЙ ОТМЕТКИ ',
    current: 'Текущие катры',
    future: 'Ориентировочные карты',
    zone: 'зона',
    perYear: 'В год*',
    assumingMax: '*Из расчета максимального покрытия',
    numbersAreEstimates:
      'Эти цифры являются приблизительными по многим причинам. Чтобы узнать более подробную информацию о них обратитесь к нашим ',
    termsAndConditions: 'Правилам и условиям'
  },
  errorText:
    'Произошла ошибка.  <a href="mailto:info@floodhelpny.org">Свяжитесь с нами</a> если это будет продолжаться.',
  loadingText: 'Загрузка...'
};
