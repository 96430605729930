import BuildingFeatures from '../../constants/BuildingFeatures';
import FloodZones from '../../constants/FloodZones';
import DefaultCalculatorParamsHelper from './DefaultCalculatorParamsHelper';

const FloodVentsHelper = {
  showMitigation: (property) => {
    if (
      FloodVentsHelper.notXorNone(property.current_flood_zone) ||
      FloodVentsHelper.notXorNone(property.future_flood_zone)
    ) {
      if (
        property.building_feature ===
        BuildingFeatures.ELEVATED_ON_CRAWLSPACE_NO_FLOOD_VENTS
      ) {
        return true;
      }
    }
    return false;
  },
  formatMitigatedRate: (property, useCurrent) => {
    const formattedParams = DefaultCalculatorParamsHelper.formatCalculatorParameters(
      property,
      useCurrent
    );
    formattedParams.elevation_difference =
      formattedParams.elevation_difference +
      formattedParams.basement_height +
      1;
    return formattedParams;
  },
  notXorNone: (floodZone) => {
    return floodZone !== FloodZones.X && floodZone !== FloodZones.NONE;
  }
};

export default FloodVentsHelper;
