import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import RateCalculatorNav from './RateCalculatorNav';
import RateCalculatorBreadcrumbs from './RateCalculatorBreadcrumbs';
import RateCalculatorQuestionBasement from './RateCalculatorQuestionBasement';
import RateCalculatorQuestionBuildingFeature from './RateCalculatorQuestionBuildingFeature';
import RateCalculatorQuestionBuildingType from './RateCalculatorQuestionBuildingType';
import RateCalculatorQuestionContentLocation from './RateCalculatorQuestionContentLocation';
import RateCalculatorQuestionElevatedBuilding from './RateCalculatorQuestionElevatedBuilding';
import RateCalculatorQuestionMachineryInBasement from './RateCalculatorQuestionMachineryInBasement';
import RateCalculatorQuestionNumberOfFloors from './RateCalculatorQuestionNumberOfFloors';
import RateCalculatorQuestionNumberOfResidentialUnits from './RateCalculatorQuestionNumberOfResidentialUnits';
import RateCalculatorQuestionNumberOfSteps from './RateCalculatorQuestionNumberOfSteps';
import RateCalculatorQuestionPreferredRiskRate from './RateCalculatorQuestionPreferredRiskRate';
import RateCalculatorQuestionPreFirm from './RateCalculatorQuestionPreFirm';
import RateCalculatorQuestionSevereRepetitiveLoss from './RateCalculatorQuestionSevereRepetitiveLoss';
import SalesforceCommunicator from '../communicators/SalesforceCommunicator';

const RateCalculator = ({ locale, salesforceApi, propertyId }) => {
  const provider = require(`../providers/RateCalculator.${locale}`).default;
  const [question, setQuestion] = useState('numberOfResidentialUnits');
  const [previousQuestion, setPreviousQuestion] = useState(null);
  const [nextQuestion, setNextQuestion] = useState('');
  const [answers, setAnswers] = useState({
    buildingType: '',
    buildingFeature: '',
    contentLocation: '',
    elevatedBuilding: '',
    machineryInBasement: '',
    numberOfFloors: '',
    numberOfResidentialUnits: '',
    numberOfSteps: '',
    preferredRiskRate: '',
    preFirm: '',
    severeRepetitiveLoss: ''
  });

  useEffect(() => {
    // Returns an object: { property: {}, property_details: {} }
    const getProperty = async () => {
      try {
        const result = await SalesforceCommunicator.getPropertyInfo(
          salesforceApi,
          propertyId
        );
        const { property } = result;
        setAnswers({
          ...answers,
          currentFloodZone: property.current_flood_zone,
          futureFloodZone: property.future_flood_zone,
          currentBfe: property.currentBfe,
          yearBuilt: property.year_built,
          lag: property.lag,
          futureBfe: property.futureBfe
        });
      } catch (error) {
        // TODO add an error handler for this component
      }
    };
    getProperty();
  }, []);

  // This function sends data to the SF DB, moves the person to the next component,
  // sets the nextQuestion to empty in order to disable the "next" button on the following
  // question and sets the previous question to the old current question
  const next = async () => {
    // TODO when you're on the "last" question you should go to some sort of complete screen
    // Also need to send a request to the Calculator? Also how is "complete" set
    if (nextQuestion === 'complete') {
      try {
        await SalesforceCommunicator.updateProperty(salesforceApi, propertyId, {
          rate_calculator_complete: true
        });
        window.location.href = `/${locale}/rate/${propertyId}`;
      } catch (error) {
        // TODO add an error handler for this try/catch
      }
    }
    try {
      // TODO fix the fact that currently all data is sent every time
      await SalesforceCommunicator.updateProperty(
        salesforceApi,
        propertyId,
        answers
      );
      setPreviousQuestion(question);
      setQuestion(nextQuestion);
      setNextQuestion('');
    } catch (error) {
      // TODO add an error handler for this try/catch
    }
  };

  const previous = () => {
    // TODO need to reset state/DB that holds the information if the user already answered before pressing "previous"
    // TODO figure out how to dynamically set the previous previous question
    setNextQuestion('');
    setQuestion(previousQuestion);
    // For now reset back to start if they want to go back twice
    setPreviousQuestion('numberOfResidentialUnits');
  };

  const renderQuestion = () => {
    // TODO write out each component here
    switch (question) {
      case 'numberOfResidentialUnits':
        return (
          <RateCalculatorQuestionNumberOfResidentialUnits
            provider={provider.questions[question]}
            answers={answers}
            setAnswers={setAnswers}
            setNextQuestion={setNextQuestion}
          />
        );
      case 'buildingType':
        return (
          <RateCalculatorQuestionBuildingType
            provider={provider.questions[question]}
            answers={answers}
            setAnswers={setAnswers}
            setNextQuestion={setNextQuestion}
          />
        );
      case 'preFirm':
        return (
          <RateCalculatorQuestionPreFirm
            provider={provider.questions[question]}
            answers={answers}
            setAnswers={setAnswers}
            setNextQuestion={setNextQuestion}
          />
        );
      case 'basement':
        return (
          <RateCalculatorQuestionBasement
            provider={provider.questions[question]}
            answers={answers}
            setAnswers={setAnswers}
            setNextQuestion={setNextQuestion}
          />
        );
      case 'buildingFeature':
        return (
          <RateCalculatorQuestionBuildingFeature
            provider={provider.questions[question]}
            answers={answers}
            setAnswers={setAnswers}
            setNextQuestion={setNextQuestion}
          />
        );
      case 'contentLocation':
        return (
          <RateCalculatorQuestionContentLocation
            provider={provider.questions[question]}
            answers={answers}
            setAnswers={setAnswers}
            setNextQuestion={setNextQuestion}
          />
        );
      case 'elevatedBuilding':
        return (
          <RateCalculatorQuestionElevatedBuilding
            provider={provider.questions[question]}
            answers={answers}
            setAnswers={setAnswers}
            setNextQuestion={setNextQuestion}
          />
        );
      case 'machineryInBasement':
        return (
          <RateCalculatorQuestionMachineryInBasement
            provider={provider.questions[question]}
            answers={answers}
            setAnswers={setAnswers}
            setNextQuestion={setNextQuestion}
          />
        );
      case 'numberOfFloors':
        return (
          <RateCalculatorQuestionNumberOfFloors
            provider={provider.questions[question]}
            answers={answers}
            setAnswers={setAnswers}
            setNextQuestion={setNextQuestion}
          />
        );
      case 'numberOfSteps':
        return (
          <RateCalculatorQuestionNumberOfSteps
            provider={provider.questions[question]}
            answers={answers}
            setAnswers={setAnswers}
            setNextQuestion={setNextQuestion}
          />
        );
      case 'preferredRiskRate':
        return (
          <RateCalculatorQuestionPreferredRiskRate
            provider={provider.questions[question]}
            answers={answers}
            setAnswers={setAnswers}
            setNextQuestion={setNextQuestion}
          />
        );
      case 'severeRepetitiveLoss':
        return (
          <RateCalculatorQuestionSevereRepetitiveLoss
            provider={provider.questions[question]}
            answers={answers}
            setAnswers={setAnswers}
            setNextQuestion={setNextQuestion}
          />
        );
      default:
      // TODO add some default error handling
    }
  };

  return (
    <div id="rate-calculator" className="contents-and-faqs">
      <Helmet>
        <title>{provider.title}</title>
        <link rel="canonical" href={provider.url} />
        <meta name="description" content={provider.description} />
        <meta property="og:title" content={provider.title} />
        <meta property="og:image" content={provider.image} />
        <meta property="og:description" content={provider.description} />
        <meta property="og:url" content={provider.url} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image:alt" content={provider.imageAlt} />
      </Helmet>
      <div className="breadcrumbs-container">
        <RateCalculatorBreadcrumbs
          provider={provider}
          propertyId={propertyId}
          locale={locale}
          question={question}
        />
      </div>
      {/* TODO: fix the style prop passed here to use styled components. Also
        fix all CSS in the RateCalculator components */}
      <div className="center" style={{ maxHeight: '500px' }}>
        {renderQuestion()}
        <RateCalculatorNav
          provider={provider.navigation}
          next={next}
          previous={previous}
          question={question}
          nextQuestion={nextQuestion}
        />
      </div>
    </div>
  );
};

RateCalculator.propTypes = {
  salesforceApi: PropTypes.string.isRequired,
  propertyId: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired
};

export default RateCalculator;
