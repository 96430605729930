import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const ListItem = styled.li`
  display: flex;
  margin: 1rem;

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

const List = ({ children }) => (
  <Wrapper>
    {children.map((content, idx) => (
      <ListItem key={idx}>{content}</ListItem>
    ))}
  </Wrapper>
);

List.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired
};

export default List;
