import React, { Component } from 'react';
import PropTypes from 'prop-types';
export default class PrefirmCallout extends Component {

  static propTypes = {
    provider: PropTypes.object.isRequired
  };

  render() {
    const provider = this.props.provider;
    return (
      <div className="prefirm-callout promo promo--warning">
        <span className="lnr lnr-warning"></span>
        <p>{provider.copy}</p>
      </div>
    );
  }
}
