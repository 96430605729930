import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import aboutCNYCN from '../images/about-cnycn.png';
import aboutGOSR from '../images/about-gosr.png';
import mocej from '../images/mocej-logo-color.svg';
import { colors } from '../styles/variables';
import { HeaderSection } from './ui/StyledResourcePageElements';
import { useLocale, useProvider } from '../helpers/hooks';

const Logos = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  margin: 2rem 0;
  width: 100%;

  a {
    margin: 5px;
  }

  @media (max-width: 860px) {
    flex-direction: column;
  }
`;

const Logo = styled.img`
  min-width: 160px;
  max-width: 300px;
  max-height: 70px;
  margin: 0.5rem 1rem;
`;

const Content = styled.div`
  padding: 0 30px;
  margin: 5rem auto;
  max-width: 910px;

  > div {
    margin-bottom: 3rem;
    padding-bottom: 3rem;
    border-bottom: solid 1px ${colors.grayScale[1]};

    :first-of-type {
      border-top: solid 1px ${colors.grayScale[1]};
      padding-top: 3rem;
    }

    :last-of-type {
      border: none;
    }
  }

  p {
    text-align: justify;
    margin: 1rem 0;
  }

  ul {
    @media (max-width: 500px) {
      padding-inline-start: 0;
      text-align: center;
    }
  }
`;

const About = () => {
  const provider = useProvider('about');
  const locale = useLocale();

  return (
    <>
      <Helmet>
        <title>{provider.meta.title}</title>
        <link rel="canonical" href={provider.meta.url} />
        <meta name="description" content={provider.meta.description} />
        <meta property="og:title" content={provider.meta.title} />
        <meta property="og:description" content={provider.meta.ogDescription} />
        <meta property="og:url" content={provider.meta.url} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <HeaderSection>
        <h1>{provider.title}</h1>
        <Logos>
          <Logo src={aboutCNYCN} alt="CNYCN logo" />
          <Logo
            src={aboutGOSR}
            alt="Governor's Office of Storm Recovery & NY state logos"
          />
          <Logo
            src={mocej}
            alt="Mayor's Office of Climate & Environmental Justice logo"
          />
        </Logos>
      </HeaderSection>
      <Content>
        <div>
          <h5>{provider.thisWebsite.header}</h5>
          <p>
            <a href={`/${locale}`}>FloodHelpNY.org</a>
            {provider.thisWebsite.intro}
          </p>
          <p>{provider.thisWebsite.siteGoal}</p>
          <p>
            {provider.thisWebsite.funded}
            <a
              href="http://stormrecovery.ny.gov/"
              target="_blank"
              rel="noreferrer noopener"
            >
              {provider.thisWebsite.gosr}
            </a>
            {provider.thisWebsite.and}
            <a
              href="http://stormrecovery.ny.gov/community-reconstruction-program"
              target="_blank"
              rel="noreferrer noopener"
            >
              {provider.thisWebsite.newYorkRising}
            </a>
            {provider.thisWebsite.and}
            <a
              href="https://www.fema.gov/"
              target="_blank"
              rel="noreferrer noopener"
            >
              {provider.thisWebsite.fema}
            </a>
            {provider.thisWebsite.partnership}
            <a
              href="https://www1.nyc.gov/site/orr/index.page"
              target="_blank"
              rel="noreferrer noopener"
            >
              {provider.thisWebsite.mocej}
            </a>
            .
          </p>
        </div>
        <div>
          <h5>{provider.cnycn.header}</h5>
          <p>
            {provider.cnycn.about}
            <a
              href="http://www.cnycn.org"
              target="_blank"
              rel="noreferrer noopener"
            >
              {provider.cnycn.link}
            </a>
          </p>
          <p>
            {provider.cnycn.contributions}
            <a
              href="https://www1.nyc.gov/site/orr/index.page"
              target="_blank"
              rel="noreferrer noopener"
            >
              {provider.cnycn.mocej}
            </a>
            {provider.cnycn.forGuidanceAndEfforts}
          </p>
          <p>
            {provider.cnycn.otherContributors}
            <a
              href="http://www.legalservicesnyc.org/"
              target="_blank"
              rel="noreferrer noopener"
            >
              {provider.cnycn.legalServices}
            </a>
            {provider.cnycn.legalServicesFunding}
          </p>
          <p>{provider.cnycn.capitalOne}</p>
        </div>
        <div>
          <h5>{provider.gosr.header}</h5>
          <p>{provider.gosr.body}</p>
        </div>
        <div>
          <h5>{provider.resiliencyAdvisory.header}</h5>
          <p>{provider.resiliencyAdvisory.body}</p>
          <ul>
            <p>{provider.resiliencyAdvisory.americanInstituteOfArchitects}</p>
            <p>
              <a
                href="http://www.enterprisecommunity.com/"
                target="_blank"
                rel="noreferrer noopener"
              >
                {provider.resiliencyAdvisory.enterpriseCommunityPartners}
              </a>
            </p>
            <p>
              <a
                href="http://www.fema.gov/"
                target="_blank"
                rel="noreferrer noopener"
              >
                {provider.resiliencyAdvisory.fema}
              </a>
            </p>
            <p>{provider.resiliencyAdvisory.friendsOfRockaway}</p>
            <p>
              <a
                href="http://www.legalservicesnyc.org/"
                target="_blank"
                rel="noreferrer noopener"
              >
                {provider.resiliencyAdvisory.legalServicesNYC}
              </a>
            </p>
            <p>
              <a
                href="http://www1.nyc.gov/site/planning/index.page"
                target="_blank"
                rel="noreferrer noopener"
              >
                {provider.resiliencyAdvisory.deptOfCityPlanning}
              </a>
            </p>
            <p>
              <a
                href="http://www1.nyc.gov/site/hpd/index.page"
                target="_blank"
                rel="noreferrer noopener"
              >
                {provider.resiliencyAdvisory.hpd}
              </a>
            </p>
            <p>
              <a
                href="http://www.nyc.gov/html/sbs/html/home/home.shtml"
                target="_blank"
                rel="noreferrer noopener"
              >
                {provider.resiliencyAdvisory.sbs}
              </a>
            </p>
            <p>
              <a
                href="http://www.nyc.gov/html/planyc/html/home/home.shtml"
                target="_blank"
                rel="noreferrer noopener"
              >
                {provider.resiliencyAdvisory.mor}
              </a>
            </p>
            <p>
              <a
                href="http://www.stbernardproject.org/"
                target="_blank"
                rel="noreferrer noopener"
              >
                {provider.resiliencyAdvisory.stBernardProject}
              </a>
            </p>
            <p>
              <a
                href="http://portal.hud.gov/hudportal/HUD"
                target="_blank"
                rel="noreferrer noopener"
              >
                {provider.resiliencyAdvisory.hud}
              </a>
            </p>
          </ul>
        </div>
      </Content>
    </>
  );
};

export default About;
