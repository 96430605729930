import React from 'react';
import PropTypes from 'prop-types';

// Used in Rate Calculator application
const RadioButtons = ({ answer, responseField, setResponse }) => {
  const renderButtons = () =>
    responseField.options.map((option, index) => {
      const key = `radio-${index}`;
      return (
        <div key={key} className="radio-buttons">
          <input
            id={key}
            type="radio"
            name={responseField.key}
            value={option.value}
            checked={option.value === answer}
            onChange={setResponse}
          />
          <label htmlFor={key}>
            <div className="label-contents">{option.display}</div>
          </label>
        </div>
      );
    });

  return <div>{renderButtons()}</div>;
};

RadioButtons.propTypes = {
  responseField: PropTypes.object.isRequired,
  answer: PropTypes.string,
  setResponse: PropTypes.func.isRequired
};

RadioButtons.defaultProps = {
  answer: ''
};

export default RadioButtons;
