import BuildingFeatures from '../constants/BuildingFeatures';

const AuditCalculatorFormatter = {
  formatCalculatorParams: (property, audit, useCurrent) => {
    const floodZone = useCurrent
      ? property.current_flood_zone
      : property.future_flood_zone;
    const elevationDifference = useCurrent
      ? audit.effective_bfe_difference
      : audit.prelim_bfe_difference;
    let { multifloor } = audit;
    if (audit.foundation_type === BuildingFeatures.BASEMENT) {
      multifloor = true;
    }

    return {
      flood_zone: floodZone,
      pre_firm: false,
      preferred_risk_rate: false,
      newly_mapped: false,
      building_coverage: 250000,
      content_coverage: 100000,
      building_deductible: 1000,
      content_deductible: 1000,
      severe_repetitive_loss: false,
      substantial_improvement: false,
      building_size: audit.residence_type,
      building_feature: audit.foundation_type,
      content_location: audit.contents,
      elevation_difference: parseFloat(elevationDifference),
      primary_residence: true,
      multi_floor: multifloor,
      machinery_in_basement: audit.machinery_in_basement,
      obstructed: audit.obstructed,
      enclosure_area: audit.ground_floor_area,
      appliance_elevation: parseFloat(audit.lowest_machinery_elevation),
      machinery_elevation: parseFloat(audit.lowest_machinery_elevation)
    };
  }
};

export default AuditCalculatorFormatter;
