import React from 'react';
import PropTypes from 'prop-types';

const RateCalculatorRadioInput = ({ responseField, answer, radioKey, setResponse }) => {
  const renderButtons = () => {
    const buttons = [];
    responseField.options.forEach((option) => {
      buttons.push(
        <div className="radio-button" key={`${radioKey}-${option.value}`}>
          <input
            value={option.value}
            id={`${radioKey}-${option.value}`}
            checked={option.value === answer}
            type="radio"
            name={`${radioKey}-radiobuttons`}
            onChange={setResponse}
            required={responseField.required}
          />
          <label htmlFor={`${radioKey}-${option.value}`}>
            {option.display}
          </label>
        </div>
      );
    });
    return buttons;
  };

  // Refactor for DRY
  const renderInvalidCopy = () => {
    const answerIsInvalid = responseField.invalidAnswers.indexOf(answer) !== -1;
    if (!answerIsInvalid) {
      return null;
    }
    return (
      <div className="promo promo--warning">
        <span className="lnr lnr-warning" />

        <p>
          {responseField.invalidCopy}
          <a href={responseField.invalidLink}>
            {responseField.invalidLinkCopy}
          </a>
          {responseField.invalidCopyAfterLink}
        </p>
      </div>
    );
  };

  // Refactor for DRY
  const renderAlertCopy = () => {
    const alertCopy = responseField.alertAnswers;
    const answerHasAlert = alertCopy.indexOf(answer) !== -1;
    if (!answerHasAlert) {
      return null;
    }
    return (
      <div className="promo promo--estimate">
        <p>
          {responseField.alertCopy}
          <a href={responseField.alertLink}>{responseField.alertLinkCopy}</a>
          {responseField.alertCopyAfterLink}
        </p>
      </div>
    );
  };

  return (
    <div>
      <label>{responseField.title}</label>
      <div className="button-single-response radio-group radio-group--text">
        {renderButtons()}
      </div>
      <div>
        {renderInvalidCopy()}
        {renderAlertCopy()}
      </div>
    </div>
  );
};

RateCalculatorRadioInput.propTypes = {
  radioKey: PropTypes.string.isRequired,
  responseField: PropTypes.object.isRequired,
  answer: PropTypes.string.isRequired,
  setResponse: PropTypes.func.isRequired
};

export default RateCalculatorRadioInput;
