import RtappApplicationState from '../constants/RtappApplicationState';
import worker1 from '../images/worker-1.svg';
import worker2 from '../images/worker-2.svg';
import grantAgreement from '../images/grant-agreement.svg';
import pencilRuler from '../images/pencil-ruler.svg';
import maleUser from '../images/male-user.svg';
import application from '../images/application.svg';
import calendar from '../images/calendar.svg';
import feedback from '../images/feedback.svg';
import piping from '../images/piping.svg';

export default {
  [RtappApplicationState.PRE_APPLICATION]: {
    headerNext: 'Sa Ou Ka Fè Apresa',
    headerExpect: 'Ak Kisa Pou Ou Atann',
    callToAction: 'Nou ap analize enfòmasyon ou te soumèt yo',
    body:
      'Tanpri bay ekip nou an yon ti tan pou voye ba ou yon aplikasyon konplè pou pwogram lan. Nou ap fè suivi ak ou pi bonè sa posib.',
    expect:
      'Si ou kalifye pou pwogram lan, ou ap resevwa yon imèl ak yon envitasyon pou ranpli yon aplikasyon. Mete ou alèz pou verifye sou sit entènèt lan pou wè si sitiyasyon ou chanje. ',
    toProfile: 'Mennen m sou pwofil risk mwen pou inondasyon',
    icon: application
  },
  [RtappApplicationState.PRE_APPLICATION_APPROVED]: {
    callToAction: 'Kounya: Ranpli Aplikasyon an',
    body:
      'Ou bezwen èd? Rele sant pou katye NYC yo nan 646-786-0888 oswa voye yon imèl ban nou nan info@floodhelpny.org. Mete ou alèz pou poze nenpòt kesyon ou genyen sou pwogram nou an.',
    expect:
      'Ekip nou an ap travay di pou revize tout aplikasyon yo e nou espere nou ap reponn ou nan espas pwochen 2-3 semèn yo. Nou konnen se yon atant ki long, e nou apresye pasyans ou! Nou ap imèl ou menm kote sitiyasyon ou chanje, men ou ka kontinye verifye la pou mizajou. Si ou ta pito ranpli yon fòmilè aplikasyon papye telechaje epi soumèt fòmilè anba yo bay Sant lan.',
    buttonText: 'Aplike',
    link: 'https://www.floodhelpny.org/samples/auditApplication.pdf',
    linkText: 'Fòmilè Aplikasyon',
    link2: 'https://www.floodhelpny.org/samples/tenantIncome.pdf',
    linkText2: 'Fòmilè Verifikasyon Revni Lokatè yo',
    icon: application
  },
  [RtappApplicationState.APPLICATION_SUBMITTED]: {
    headerNext: 'Sa Ou Ka Fè Apresa',
    headerExpect: 'Ak Kisa Pou Ou Atann',
    callToAction: 'Ou fè premye demach lan!',
    body:
      'Nou ap analize aplikasyon ou an pou kalifikasyon kounya epi nou pral kontakte ou si nou bezwen enfòmasyon anplis. Ou gen kesyon? Rele Center for NYC Neighborhoods nan (646) 786-0888 oswa voye yon imèl ban nou nan info@floodhelpny.org',
    expect:
      'Si ou apwouve pou pwogram lan, ou ap resevwa yon imèl nan men ou ki mande ou pou siyen yon Kontra Sibvansyon pou Pwopriyetè Kay, ki pèmèt Sant lan distriye fon federal bay pwopriyetè kay ki kalifye.',
    icon: grantAgreement
  },
  [RtappApplicationState.HOMEOWNER_GRANT]: {
    headerExpect: 'Ak Kisa Pou Ou Atann',
    callToAction:
      'Telechaje epi Siyen Kontra Sibvansyon pou Pwopriyetè Kay lan',
    body:
      'Ou pral resevwa yon kopi Kontra Sibvansyon pou Pwopriyetè Kay ou an nan imèl. Ou ap bezwen telechaje epi voye tounen kontra sibvansyon pou pwopriyetè kay lan ban nou nan faks, imèl oswa sèvis postal. Kontra a dwe pote siyati tout moun ki nan tit pwopriyete an epi pèmèt Sant lan distribiye fon federal bay pwopriyetè kay ki kalifye nan pwogram lan.',
    faq:
      'Pou aprann kijan pou voye kontra tounen ban nou, klike sou Kesyon Moun Poze Souvan yo nan bò dwat lan anba “Ou Ta Ka Ap Panse” an.',
    contactUs:
      'Ou gen kesyon toujou? Kontakte nou nan 646-786-0888 oswa voye imèl ban nou nan info@floodhelpny.org.',
    expect:
      'Apre nou resevwa Kontra Sibvansyon ou an pou Pwopriyetè Kay, Center for NYC Neighborhoods lan ap siyen l epi yo ap voye yon kopi bay donatè pwogram lan, Biwo Gouvènman Eta New York lan pou Retablisman apre Tanpèt. Yon fwa dokiman an analize, nou ap pwograme odit ou an. Sa gendwa pran jiska kat semèn.',
    icon: grantAgreement
  },
  [RtappApplicationState.REJECTED]: {
    body:
      'Malerezman, ou aktyèlman pa kalifye pou sèvis atravè pwogram sa a. Toutfwa, ou ka toujou itilize resous yo nan FloodHelpNY.org pou verifye risk pou inondasyon epi aprann fason pou vin pi toleran. Ou gen kesyon apwopo aplikasyon ou an? Kontakte nou nan 646-786-0888. Pou enfòmasyon anplis sou kalifikasyon, tanpri analize Kesyon Moun Poze Souvan yo sou bò dwat lan.'
  },
  [RtappApplicationState.WITHDRAWN]: {
    body:
      'Mèsi pou enterè ou nan Pwogram Enstalasyon Rezidansyèl Valv pou Dlo k Ap Tounen an. Dosye ou an fòmèlman soti nan pwogram sa a. Ou ka toujou verifye risk ou pou inondasyon epi analize resous nou yo sou asirans kont inondasyon. Si ou ta renmen kontinye ak pwogram lan, tanpri rele nou nan 646-786-0888 oswa voye imèl bay info@floodhelpny.org. Nou pral reprann tretman aplikasyon ou an.'
  },
  [RtappApplicationState.FEASIBILITY_REVIEW]: {
    headerNext: 'Sa Ou Ka Fè Apresa',
    headerExpect: 'Ak Kisa Pou Ou Atann',
    scheduled: 'Analiz fezabilite ou an pwograme',
    body:
      'Enjenyè ki te reyalize Odit pou Tolerans Kay ou an bezwen tounen lakay ou pou pran enfòmasyon anplis pou detèmine si kay ou ta ka benefisye apati valv pou dlo k ap tounen nan egou an ou pa. Ou ap blije lakay ou epi enjenyè an ap bezwen jwenn aksè ak tout pwopriyete ou an, ansanm ak anba lakay lan epi espas deyò an. ',
    icon: pencilRuler,
    expect:
      'Nan kèk ka, enstale yon valv pou dlo k ap tounen nan egou an pa p benefisye kay ou an. Enjenyè ou an pral poze ou kèk kesyon epi pran kèk foto epi mezi yo pral bezwen pou rann desizyon yo an, epi, si sa aplikab, kreye plan konstriksyon pou enstalasyon valv pou dlo k ap tounen nan egou ou an. Nou espere reponn ou nan twa a sis semèn apre enjenyè an fini ak analiz fezabilite an.',
    noDate:
      'Tanpri rele nou nan nimewo ki anba pou konfime dat ak lè analiz fezabilite ou an.'
  },
  [RtappApplicationState.AUDIT_SCHEDULED]: {
    headerNext: 'Sa Ou Ka Fè Apresa',
    headerExpect: 'Ak Kisa Pou Ou Atann',
    scheduled: 'Odit ou an pwograme',
    body:
      'Tanpri asire w ou lakay ou nan lè ki pwograme an pou pèmèt enjenyè an fini evalyasyon an. Ou ta dwe atann ou pou odit lan pran mwens pase dezèdtan. Si ou pa kapab lakay ou pou odit ki pwograme ou an oswa anile randevou ou an, tanpri rele nou pi bonè sa posib lan ',
    phone: '646-786-0888',
    phoneLink: '+6467860888',
    body2: ' pandan orè fonksyonnman yo pou repwograme.',
    expect:
      'Enjenyè an ak pèsonèl k ap akonpanye l lan ap bezwen jwenn aksè ak tout espas kay lan, ansanm ak anba lakay oswa espas vid lan ak lakou an pou asire w ou resevwa yon rapò presi. Si enjenyè an pa rive lakay ou nan espas 30 minit tan ou pwograme an, tanpri rele nou nan (646) 786-0888.',
    noDate:
      'Tanpri rele nou nan nimewo ki anba pou konfime dat ak lè odit ou an.'
  },
  [RtappApplicationState.AUDIT_COMPLETE]: {
    headerNext: 'Sa Ou Ka Fè Apresa',
    headerExpect: 'Ak Kisa Pou Ou Atann',
    callToAction: 'Pwograme seyans konsèy ou an',
    body:
      'Yon moun nan Platfòm Pwopriyetè Kay ou an pral rele ou pou pwograme yon randevou ak yon konseye pou tolerans nan pwochen 48 èdtan yo. Si ou toujou gen kesyon apwopo pwogram lan oswa pwogramasyon an, ou ka rele youn nan reprezantan nou yo nan ',
    phone: '(646) 786-0888.',
    phoneLink: '+6467860888',
    expect:
      'Randevou an pral dire yon bagay ant 45 minit ak inèdtan. Pandan tan sa a, youn nan konseye nou yo pral gide ou nan enfòmasyon enjenyè an te kapte pandan vizit li an epi diskite sou estrateji adousisman ki pi byen ale ak kay ou an. Konseye nou an pral diskite tou sou opsyon finansman yo epi ede ou kreye yon plan pou pwoteje kay ou an ki ale ak bidjè ou.',
    icon: grantAgreement
  },
  [RtappApplicationState.CONSULTATION_SCHEDULED]: {
    headerNext: 'Sa Ou Ka Fè Apresa',
    headerExpect: 'Ak Kisa Pou Ou Atann',
    scheduled: 'Konsiltasyon ou an pwograme',
    body:
      'Konseye an pral diskite sou risk pou inondasyon aktyèl oswa alavni ou, ansanm ak tout aksyon ou gendwa kapab poze sou baz evalyasyon enjenyè an. Tanpri pote dokiman ki suiv yo la si yo disponib: 1) paj deklarasyon asirans kont inondasyon an; 2) ansyen sètifika elevasyo an, si sa aplikab; epi 3) atestasyon ipotèk oswa lòt dokiman ki montre sitiyasyon ipotèk aktyèl ou an ',
    phone: '646-786-0888',
    phoneLink: '+6467860888',
    body2:
      ' oswa voye imèl ban nou nan info@floodhelpny.org. Epitou fè nou konnen tousuit si ou bezwen anile oswa repwograme',
    expect:
      'Konseye ou an se yon ekspè nan endistri an ki pral diskite sou faktè presi ki detèmine prim asirans kont inondasyon ou an. Yo pral analize sètifika elevasyon ak rapò teknik ou an pou founi ou direktiv ki san langaj teknik. Oryantasyon an fèt nan de sans - nou ankouraje ou pote kesyon! Nan fen randevou sa a, ou pral gen yon konpreyansyon sou sa asirans kont inondasyon an gendwa koute, si ou ap sibi bon tarif lan, opsyon adousisman komen yo, epi si yon valv pou dlo k ap tounen ta ka benefisye kay ou an.',
    noDate:
      'Tanpri rele nou nan nimewo ki anba pou konfime dat ak lè konsiltasyon ou an.'
  },
  [RtappApplicationState.CONSULTATION_COMPLETE]: {
    callToAction: 'Byenvini nan pwogram valv pou dlo k ap tounen an',
    body:
      'Ou fini oryantasyon ou an epi ou resevwa yon odit. Kounya li lè pou pale sou enstalasyon valv pou dlo k ap tounen nan egou ou an. Pandan pwochen semèn yo, nou pral gide ou nan pwosesis pou fè l enstale an.',
    contact:
      'Si ou ta renmen diskite sou pwogram an nan plis detay, ou ka rele Sant an nan ',
    phone: '646-786-0888',
    phoneLink: '+6467860888',
    contact2: ' oswa voye imèl ban nou nan ',
    email: 'info@floodhelpny.org.',
    images: [{ icon: piping, iconAltText: 'Kanalizasyon' }]
  },
  [RtappApplicationState.INSTALLATION_PREPARE]: {
    callToAction: 'Nou ap eseye jwenn bon moun yo',
    body:
      'Nou ap monte ekip ou an pou asire nou ka founi ou pi bon sèvis posib lan. Nou pral an kontak nan sis pou rive uit semèn pou prezante ou ekip enstalasyon ou an. Antretan, dènye vèsyon rapò ou an disponib anba.',
    contact: 'Mete ou alèz pou rele nan ',
    phone: '646-786-0888',
    phoneLink: '+6467860888',
    contact2: ' oswa voye imèl ban nou ak nenpòt kesyon nan ',
    email: 'info@floodhelpny.org.',
    images: [{ icon: pencilRuler, iconAltText: 'Kreyon ak Règ' }]
  },
  [RtappApplicationState.MEET_YOUR_TEAM]: {
    headerNext: 'Sa Ou Ka Fè Apresa',
    headerExpect: 'Ak Kisa Pou Ou Atann',
    callToAction: 'Nou ap fè l ansanm!',
    body:
      'Kounya li lè pou ou rankontre moun ki pral enstale valv pou dlo k ap tounen ou an nan rankont lansman ou an. Anplis enjenyè ou an, nou te chwazi yon plonbye ak enspektè pou travay ak ou. Mete ou alèz pou kontakte nou nan (646) 786-0888 oswa nan info@floodhelp.org ak nenpòt kesyon.',
    images: [
      { icon: worker1, iconAltText: 'Travayè' },
      { icon: maleUser, iconAltText: 'Moun' },
      { icon: worker2, iconAltText: 'Travayè' }
    ],
    link: 'https://www.floodhelpny.org/samples/Tri-PartyAgreement.pdf',
    linkText: 'Kontra Tri-pati',
    scheduled: 'Rankont Anvan Konstriksyon',
    expect:
      'Nou pral pwograme yon lè pou ou rankonre ekip ou an. Anplis aprann konnen moun ou pral travay ak li an, yo ap mande ou siyen yon kontra Tri-pai ant ou menm, plonbye an ak Sant lan. ',
    noDate:
      'Tanpri rele nou nan nimewo ki anwo an pou konfime dat ak lè enstalasyon ou an.'
  },
  [RtappApplicationState.INSTALLATION_TO_BE_SCHEDULED]: {
    headerNext: 'Sa Ou Ka Fè Apresa',
    headerExpect: 'Ak Kisa Pou Ou Atann',
    callToAction:
      'Enspektè ou an pral travay ak ou pou pwograme enstalasyon ou an',
    body:
      'Pare ou pou enstalasyon valv pou dlo k ap tounen ou an! Sa ap pran abityèlman anviwon uitèdtan--men plonbye an gendwa blije tounen yon lòt jou pou fini, toudepan jan kanalizasyon evakyasyon kay ou an aksesib. Sonje ou blije lakay ou pou travay sa a fèt. Pandan enstalasyon an, plonbye ou an ap bezwen fè dlo lakay ou an. Sa vle di ou pa p kapab itilize sistèm plonbri lakay ou an (twalèt, douch, evye, lavasyèt, elatriye) pandan enstalasyon an kidonk tanpri fè lòt aranjman davans. Mete ou alèz pou rele nou nan 646-786-0888 oswa imèl nou nan info@floodhelpny.org ak nenpòt kesyon.',
    expect:
      'Kantite tan enstalasyon an pran depann ak prezantasyon kay ou an ak koneksyon l ak egou vil lan, ansanm ak kondisyon kanalizasyon ki konekte kay ou an ak egou vil lan. Nou pral bay yon estimasyon sou dire nou panse sa ap pran pi bonè nou kapab, men tanpri remake kèk fleksibilite gendwa nesesè. Plonbye ki enstale valv ou an pral montre ou kote valv lan ye, kijan li fonksyone, epi fason pou fè antretyen l. Anplis, ou ap resevwa yon rapò sou teren lè enstalasyon an fini ki gen ladan l enstriksyon presi pou fonksyonnman ak antretyen nan men fabrikan valv ou an. ',
    icon: calendar
  },
  [RtappApplicationState.INSTALLATION_HAS_BEEN_SCHEDULED]: {
    callToAction: 'Enspektè ou an pwograme enstalasyon valv ou an.',
    body:
      'Plonbye ak enspektè lisansye an pwograme pou enstalasyon valv pou dlo k ap tounen nan egou ou an. Si ou gen nenpòt kesyon konsènan enstalasyon an, tanpri rele enspektè ou an. Tanpri asire w ou lakay ou pou enstalasyon an. ',
    contact:
      'Kantite tan enstalasyon an pran depann ak prezantasyon kay ou an ak koneksyon l ak egou vil lan, ansanm ak kondisyon kanalizasyon ki konekte kay ou an ak egou vil lan. Nou pral bay yon estimasyon sou dire nou panse sa ap pran pi bonè nou kapab, men tanpri remake kèk fleksibilite gendwa nesesè. Plonbye ki enstale valv ou an pral montre ou kote valv lan ye, kijan li fonksyone, epi fason pou fè antretyen l. Anplis, ou ap resevwa yon rapò sou teren lè enstalasyon an fini ki gen ladan l enstriksyon presi pou fonksyonnman ak antretyen nan men fabrikan valv ou an. ',
    images: [{ icon: calendar, iconAltText: 'Kalandriye' }]
  },
  [RtappApplicationState.INSTALLATION_COMPLETE]: {
    headerNext: 'Sa Ou Ka Fè Apresa',
    headerExpect: 'Ak Kisa Pou Ou Atann',
    callToAction: 'Di nou jan l te pase',
    body:
      'Felisitasyon pou nouvo valv pou dlo k ap tounen ou an! Nou ta renmen kòmantè ou sou fason pwosesis lan te pase. Si ou gen twa minit, tanpri pase sondaj brèf nou an ki anba. Ou ap jwenn kèk dokiman enpòtan ki lye ak valv ou an anba paj sa a. Kòm toujou, si ou gen nenpòt kesyon oswa enkyetid ou ka rele nou nan (646) 786-0888 oswa voye imèl ban nou nan info@floodhelp.org.',
    expect:
      'Ret tann yon imèl nan men nou oswa verifye sou sit entènèt sa a nan de pou rive twa semèn pou resevwa tout dokiman ou yo, ansanm ak enfòmasyon pou antretni valv pou dlo k ap tounen ou an ansanm ak garanti fabrikan an. ',
    link: 'https://cnycn.tfaforms.net/12',
    linkText: 'Sondaj',
    icon: feedback
  },
  [RtappApplicationState.REPORT_COMPLETE]: {
    firstBody: 'Sa se rapò pou tolerans lan pou: ',
    secondBody:
      ' ak kèk rekòmandasyon teknik sou fason pou rann kay ak finans ou pi sekirize kont inondasyon, ansanm ak dokiman ki enpòtan ki lye ak valv ou an.'
  }
};
