import React, { useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import GlobalStyles from '../styles/globalStyles';
import AppRouter from './AppRouter';
import Modal from './layout/Modal';
import Footer from './Footer';
import NavBar from './NavBar';
import Help from './Help';
import LocaleContext from '../context/LocaleContext';
import ApiContext from '../context/ApiContext';
import GoogleMapsContext from '../context/GoogleMapsContext';
import ModalContext from '../context/ModalContext';
import FaqContext from '../context/FaqContext';

// top-level component called in index.html.erb
const App = ({
  locale,
  buildingApi,
  parametricApi,
  googleMapsScriptUrl,
  salesforceApi,
  googleMapsKey,
  rateCalculatorApi
}) => {
  const [isModalActive, setIsModalActive] = useState(false);
  const [modalChildren, setModalChildren] = useState(false);
  const [faqZone, setFaqZone] = useState('');

  return (
    <Router>
      <GlobalStyles />
      <LocaleContext.Provider value={locale}>
        <ApiContext.Provider
          value={{
            buildingApi,
            parametricApi,
            salesforceApi,
            rateCalculatorApi
          }}
        >
          <GoogleMapsContext.Provider
            value={{ googleMapsScriptUrl, googleMapsKey }}
          >
            <ModalContext.Provider
              value={{
                isModalActive,
                setIsModalActive,
                modalChildren,
                setModalChildren
              }}
            >
              {isModalActive && (
                <Modal isModalActive={isModalActive}>{modalChildren}</Modal>
              )}
              <NavBar
                buildingApi={buildingApi}
                parametricApi={parametricApi}
                salesforceApi={salesforceApi}
                locale={locale}
                googleMapsScriptUrl="https://maps.googleapis.com/maps/api/js?key=#{Rails.application.credentials[:GOOGLE_MAPS_API_KEY]}&libraries=places&callback=resolveGoogleMapsPromise"
              />
              <Helmet>
                <meta charSet="utf-8" />
                <title>NYC Flood Zones | Flood Risk | FloodHelpNY.org</title>
                <link rel="canonical" href="https://www.floodhelpny.org" />
                <meta
                  name="description"
                  content="NYC flood zone maps can help you understand your flood risk. Learn how to lower your flood insurance rates."
                />
                <meta
                  property="og:title"
                  content="NYC Flood Zones | FloodHelpNY.org"
                />
                <meta
                  property="og:image"
                  content="https://www.floodhelpny.org/images/home.png"
                />
                <meta
                  property="og:description"
                  content="New York City's flood risk is on the rise. We can help by showing you what flood zone changes mean to you and finding ways of lowering your costs decreasing your risk."
                />
                <meta property="og:url" content="https://www.floodhelpny.org" />
                <meta name="twitter:card" content="summary_large_image" />
              </Helmet>
              <FaqContext.Provider value={{ faqZone, setFaqZone }}>
                <AppRouter />
                <Help />
              </FaqContext.Provider>
              <Footer />
            </ModalContext.Provider>
          </GoogleMapsContext.Provider>
        </ApiContext.Provider>
      </LocaleContext.Provider>
    </Router>
  );
};

App.propTypes = {
  locale: PropTypes.string.isRequired,
  buildingApi: PropTypes.string.isRequired,
  parametricApi: PropTypes.string.isRequired,
  googleMapsScriptUrl: PropTypes.string.isRequired,
  salesforceApi: PropTypes.string.isRequired,
  rateCalculatorApi: PropTypes.string.isRequired,
  googleMapsKey: PropTypes.string.isRequired
};

export default App;
