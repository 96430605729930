import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '../../styles/variables';
import RoundIcon from '../RoundIcon';
import ButtonLink from '../ui/ButtonLink';

const ListItem = styled.li`
  display: flex;
  margin-top: 4rem;

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

const StyledIcon = styled(RoundIcon)`
  margin-right: 4rem;

  @media (max-width: 800px) {
    margin: 0 0 1rem 0;
  }
`;

const TextContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;

  > * {
    margin-bottom: 1rem;

    :last-child {
      margin-bottom: 0;
    }
  }

  p {
    color: ${colors.grayScale[3]};
  }

  @media (max-width: 800px) {
    max-width: 400px;
  }
`;

const CallToActionContainer = styled.div`
  width: 100%;
`;

const ListWithIconAndTextAndLinks = ({ items }) => {
  const renderHeader = (item) =>
    item.headerLink ? <a href={item.headerLink}>{item.header}</a> : item.header;

  const renderExample = (item) =>
    item.exampleLink && <a href={item.exampleLink}>{item.example}</a>;

  const renderCallToAction = (item) =>
    item.callToActionLink && (
      <CallToActionContainer>
        <ButtonLink url={item.callToActionLink} text={item.callToAction} />
      </CallToActionContainer>
    );

  return (
    <ul>
      {items.map((item) => (
        <ListItem>
          <StyledIcon src={item.image} alt={item.imageAlt || ''} />
          <TextContent>
            <h4>{renderHeader(item)}</h4>
            <p dangerouslySetInnerHTML={{ __html: item.body }} />
            {renderExample(item)}
            {renderCallToAction(item)}
          </TextContent>
        </ListItem>
      ))}
    </ul>
  );
};

ListWithIconAndTextAndLinks.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string.isRequired,
      imageAlt: PropTypes.string,
      header: PropTypes.string.isRequired,
      body: PropTypes.string.isRequired
    })
  ).isRequired
};

export default ListWithIconAndTextAndLinks;
