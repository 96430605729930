export default {
  meta: {
    title: '您的洪水恢复力档案',
    url: 'https://www.floodhelpny.org/zh-CN/profile'
  },
  loadingText: '加载中……',
  backToApplicationLink: '返回申请',
  faqProvider: {
    header: '您可能会想：'
  },
  emailProvider: {
    heading:
      '在纽约获取有关洪水地图和保险要求变化的最新信息',
    placeholder: '输入您的电子邮件',
    action: '注册',
    errorMessage:
      "很抱歉，注册通知时出现问题。请重试。",
    successMessage: '很好，您现在已注册可接收通知。'
  },
  mapInfo: {
    instructions: 'Instructions for Use of These Maps',
    mapMarker: 'Locate the marker on the map indicating your address',
    coastalFlooding: {
      description: 'The coastal flooding maps above indicate coastal flooding risk to a particular property. Since maps can be imprecise, we can’t be 100% certain your property is in one or more of these zones.',
      bulletPoint: 'For Coastal flooding information toggle between the "Coastal Flooding" and "Coastal Flooding Advisory Maps" buttons. Map legend can be found underneath the map. More information about your risk zone is found under the "COASTAL FLOODING RISK" section below.',
      risk: 'Coastal Flooding Risk',
      riskDescription: 'Rising sea levels will continue to increase the frequency and intensity of coastal flooding. As climate change is expected to worsen, so is coastal flooding in our communities. Depending on your location, you may be at risk of coastal flooding.'
    },
    stormwaterFlooding: {
      description: "The stormwater flooding maps above show the stormwater flood risk under current and future 2050 sea level rise conditions, and relate to an area rather than a specific property. The maps are intended to show the relative risk of flooding in public areas from stormwater runoff due to rain only. Floods don't follow lines on a map, but the map helps to provide an understanding of areas of the City where your property may have a higher chance of flooding during heavy rain.",
      bulletPoint: 'For stormwater flooding inforation toggle between the "Stormwater Flooding (present day)" and "Stormwater Flooding (2050 sea level rise)" buttons. Observe the map if you are inside or near an area of stormwater flooding. The map legend will update and is also underneath the map.',
      risk: 'Stormwater Flooding Risk',
      riskDescription: 'Stormwater is rainwater or melted snow that runs off lawns, streets, and other land surfaces. Ideally, this runoff would be absorbed by plants and soil or makes its way into the sewer system. New York City’s highly impervious land cover, like sidewalks and streets, don’t absorb rainwater. Instead, the rainwater flows into the city’s catch basins, where it’s channeled into the sewer system. The city’s sewers only have so much capacity. Surplus rainwater can overwhelm this sewer system and with enough volume it can cause local flooding. Excess rainfall can also cause sewage to back up into homes or businesses. Depending on your location, topography, impervious land cover, and site conditions, you may be at risk of stormwater flooding.'
    }
  },
  errorText:
    '出错。<a href="mailto:info@floodhelpny.org">请联系我们</a> 如果继续发生这种情况。'
};
