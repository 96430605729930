import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import HashLinkObserver from 'react-hash-link';
import { colors } from '../styles/variables';
import AuditRecommendation from './AuditRecommendation';
import ElevateBuildingRecommendationHelper from '../helpers/auditRecommendation/ElevateBuildingRecommendationHelper';
import AbandonFirstFloorRecommendationHelper from '../helpers/auditRecommendation/AbandonFirstFloorRecommendationHelper';
import FillBasementRecommendationHelper from '../helpers/auditRecommendation/FillBasementRecommendationHelper';
import ElevateMechanicalsRecommendationHelper from '../helpers/auditRecommendation/ElevateMechanicalsRecommendationHelper';
import abandonFirstFloorIcon from '../images/abandon-first-floor.svg';
import elevateIcon from '../images/elevate.svg';
import elevateMechanicalsIcon from '../images/elevate-mechanicals.svg';
import fillInBasementIcon from '../images/fill-in-basement.svg';
import AuditCounselorNotes from './AuditCounselorNotes';
import { useLocale, useProvider } from '../helpers/hooks';
import { Section } from './ui/StyledResourcePageElements';

const Content = styled.div`
  border: 10px solid ${colors.grayScale[0]};
  padding: 0 4rem 2rem 4rem;
  margin-top: 3rem;

  @media (max-width: 1200px) {
    padding: 0 1rem 2rem 1rem;
  }
`;

const Disclaimers = styled.div`
  padding-top: 1rem;

  p {
    font-size: 1rem;
    padding: 0.5rem;
    text-align: center;
    color: ${colors.grayScale[3]};
  }
`;

const NoRecommendations = styled.div`
  width: 100%;
  padding-top: 2rem;

  h4 {
    text-align: center;
  }
`;

const AuditRecommendations = ({ property, audit, applicationId, notes }) => {
  const provider = useProvider('auditRecommendations');
  const locale = useLocale();

  const renderRecommendations = () => {
    const recommendations = [];
    const elevateBuilding = ElevateBuildingRecommendationHelper.showRecommendation(
      audit
    );
    const abandonFirstFloor = AbandonFirstFloorRecommendationHelper.showRecommendation(
      audit
    );
    const fillInBasement = FillBasementRecommendationHelper.showRecommendation(
      audit
    );
    const elevateMechanicals = ElevateMechanicalsRecommendationHelper.showRecommendation(
      audit
    );
    if (elevateBuilding) {
      recommendations.push(
        <AuditRecommendation
          key="elevate"
          linkUrl={`/${locale}/mitigation/elevate`}
          image={elevateIcon}
          property={property}
          audit={audit}
          provider={provider.elevateBuildingProvider}
          applicationId={applicationId}
        />
      );
    }
    if (abandonFirstFloor) {
      recommendations.push(
        <AuditRecommendation
          key="abandon-first-floor"
          linkUrl={`/${locale}/mitigation/abandon_first_floor`}
          image={abandonFirstFloorIcon}
          property={property}
          audit={audit}
          provider={provider.abandonFirstFloorProvider}
          applicationId={applicationId}
        />
      );
    }
    if (fillInBasement) {
      recommendations.push(
        <AuditRecommendation
          key="fill-basement"
          linkUrl={`/${locale}/mitigation/fill_in_basement`}
          image={fillInBasementIcon}
          property={property}
          audit={audit}
          provider={provider.fillInBasementProvider}
          applicationId={applicationId}
        />
      );
    }
    if (elevateMechanicals) {
      recommendations.push(
        <AuditRecommendation
          key="elevate-mechanicals"
          linkUrl={`/${locale}/mitigation/elevate_mechanicals`}
          image={elevateMechanicalsIcon}
          property={property}
          audit={audit}
          provider={provider.elevateMechanicalsProvider}
          applicationId={applicationId}
        />
      );
    }

    if (recommendations.length === 0) {
      recommendations.push(
        <NoRecommendations key="no-recommendations">
          <h4>{provider.noRecommendations}</h4>
        </NoRecommendations>
      );
    } else {
      recommendations.push(
        <Disclaimers key="disclaimers">
          <p>{provider.assumingMax}</p>
          <p>
            {provider.numbersAreEstimates}
            <a
              target="_blank"
              rel="noreferrer noopener"
              href={`/${locale}/terms`}
            >
              {provider.termsAndConditions}
            </a>
          </p>
        </Disclaimers>
      );
    }

    return recommendations;
  };

  return (
    <Section id="recommendations">
      <HashLinkObserver />
      <h5>{provider.header}</h5>
      <div>
        <Content>{renderRecommendations()}</Content>
        <AuditCounselorNotes body={notes} />
      </div>
    </Section>
  );
};

AuditRecommendations.propTypes = {
  property: PropTypes.object.isRequired,
  audit: PropTypes.object.isRequired,
  notes: PropTypes.object.isRequired,
  applicationId: PropTypes.string.isRequired
};

export default AuditRecommendations;
