export default {
  homepage: 'FloodHelpNY',
  languageTitle: 'Languages',
  about: 'Nosotros',
  languages: {
    english: 'English',
    spanish: 'Español',
    russian: 'Русский',
    chinese: '中文',
    haitian: 'Kreyòl ayisyen'
  },
  resources: {
    insurance: 'Seguro',
    retrofits: 'Retrofits',
    hurricane: 'Temporada de huracanes',
    smallBusinesses: 'Pequeñas empresas',
    news: 'Noticias',
    stormwaterFlooding: 'Inundaciones por aguas pluviales',
    climateChange: 'Cambio Climático'
  }
};
