import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { Section, HeaderSection } from './ui/StyledResourcePageElements';
import { useProvider, useLocale } from '../helpers/hooks';

const Container = styled.div`
  padding: 10px 0 0 0;
`;

const Terms = () => {
  const provider = useProvider('terms');
  const locale = useLocale();

  return (
    <>
      <Helmet>
        <title>{provider.meta.title}</title>
        <link rel="canonical" href={provider.meta.url} />
        <meta property="og:title" content={provider.meta.title} />
        <meta property="og:url" content={provider.meta.url} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Container>
        <HeaderSection>
          <h1>{provider.title}</h1>
        </HeaderSection>
        <Section>
          <h5>{provider.general_disclaimer.title}</h5>
          <p>{provider.general_disclaimer.paragraph_1}</p>
          <p>
            {provider.general_disclaimer.paragraph_2}
            <a href="mailto:info@floodhelpny.org">
              {provider.general_disclaimer.email}
            </a>
          </p>
          <p>{provider.general_disclaimer.paragraph_3}</p>
          <p>{provider.general_disclaimer.paragraph_4}</p>
        </Section>
        <Section>
          <h5>{provider.flood_zones.title}</h5>
          <p>
            {provider.flood_zones.part_1}
            <a href="https://www.floodsmart.gov/floodsmart/">
              {provider.national_flood_insurance_policy}
            </a>
            {provider.flood_zones.part_2}
            <a href={`/${locale}/privacy`}>{provider.privacy_policy}</a>.
          </p>
        </Section>
        <Section>
          <h5>{provider.flood_insurance_estimate.title}</h5>
          <p>{provider.flood_insurance_estimate.paragraph_1}</p>
          <p>{provider.flood_insurance_estimate.paragraph_2}</p>
          <p>
            {provider.flood_insurance_estimate.paragraph_3}
            <a href="https://www.floodsmart.gov/floodsmart/">
              {provider.national_flood_insurance_policy}
            </a>
          </p>
          <p>
            {provider.flood_insurance_estimate.paragraph_4_part_1}
            <a href={`/${locale}/privacy`}>{provider.privacy_policy}</a>
            {provider.flood_insurance_estimate.paragraph_4_part_2}
          </p>
          <p>{provider.flood_insurance_estimate.paragraph_5}</p>
        </Section>
        <Section>
          <h5>{provider.home_resiliency_audit.title}</h5>
          <p>{provider.home_resiliency_audit.paragraph_1}</p>
          <p>{provider.home_resiliency_audit.paragraph_2}</p>
          <p>{provider.home_resiliency_audit.paragraph_3}</p>
          <p>{provider.home_resiliency_audit.paragraph_4}</p>
          <p>{provider.home_resiliency_audit.paragraph_5}</p>
          <p>{provider.home_resiliency_audit.paragraph_6}</p>
          <p>{provider.home_resiliency_audit.paragraph_7}</p>
          <p>
            {provider.home_resiliency_audit.paragraph_8_part_1}
            <a href={`/${locale}/privacy`}>{provider.privacy_policy}</a>
            {provider.home_resiliency_audit.paragraph_8_part_2}
          </p>
        </Section>
        <Section>
          <h5>{provider.backwater_valve.title}</h5>
          <p>{provider.backwater_valve.paragraph_1}</p>
          <p>{provider.backwater_valve.paragraph_2}</p>
          <p>{provider.backwater_valve.paragraph_3}</p>
          <p>{provider.backwater_valve.paragraph_4}</p>
          <p>{provider.backwater_valve.paragraph_5}</p>
          <p>{provider.backwater_valve.paragraph_6}</p>
          <p>{provider.backwater_valve.paragraph_7}</p>
          <p>
            {provider.backwater_valve.paragraph_7_part_1}
            {provider.backwater_valve.paragraph_7_part_2}
            {provider.backwater_valve.paragraph_7_part_3}
          </p>
          <p>{provider.backwater_valve.paragraph_8}</p>
          <p>{provider.backwater_valve.paragraph_9}</p>
        </Section>
      </Container>
    </>
  );
};

export default Terms;
