export default {
  currentlyServed: 'Áreas Que Actualmente Reciben Servicio',
  neighborhood: {
    header: 'Vecindarios: ',
    content:
      'Bensonhurst, Bergen Beach, Brighton Beach, Canarsie, Coney Island, Gravesend, Georgetown, Gerritsen Beach, Howard Beach, Lower Manhattan, Manhattan Beach, Marine Park, Mill Basin, Mill Island, Red Hook, Rockaway East, Seagate y Sheepshead Bay.'
  },
  priority: {
    header: 'Criterios de prioridad de elegibilidad: ',
    criteria: [
      {
        key: 'income',
        copy: 'nivel de ingresos'
      },
      {
        key: 'sandy',
        copy: 'si sufrió o no daños con Sandy'
      },
      {
        key: 'certificate',
        copy: 'si ya tiene o no un certificado de elevación'
      }
    ]
  },
  downloadEligibilityForm:
    'Descargue los requisitos completos de elegibilidad <a href="/samples/backwater-valve-eligibility.pdf">aquí</a>.'
};
