import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ImageRadioButtons from './sharedComponents/ImageRadioButtons';
import BuildingFeatures from '../constants/BuildingFeatures';
import BuildingSizes from '../constants/BuildingSizes';
import ContentLocations from '../constants/ContentLocations';
import BuildingFeatureNavigator from '../helpers/calculatorNavigation/BuildingFeatureNavigator';

const RateCalculatorQuestionBuildingFeature = ({
  provider,
  answers,
  setAnswers,
  setNextQuestion
}) => {
  useEffect(() => {
    if (answers.buildingFeature) {
      setNextQuestion(BuildingFeatureNavigator.nextComponent(answers));
    }
  }, [answers, setNextQuestion]);

  const removeOption = (options, value) => {
    options.forEach((option, index) => {
      if (option.value === value) {
        options.splice(index, 1);
      }
    });
  };

  const renderImageButtons = () => {
    const { responseField } = provider;
    const filteredOptions = responseField.options.slice();
    let answer = answers.buildingFeature;

    if (answers.buildingSize === BuildingSizes.TWO_TO_FOUR_FAMILY) {
      removeOption(filteredOptions, BuildingFeatures.MOBILE_HOME);
      if (answer === BuildingFeatures.MOBILE_HOME) {
        answer = null;
      }
    }

    if (
      answers.contentLocation === ContentLocations.BASEMENT_AND_ABOVE ||
      answers.contentLocation === ContentLocations.ENCLOSURE_AND_ABOVE
    ) {
      removeOption(filteredOptions, BuildingFeatures.NO_BASEMENT_ENCLOSURE);
      if (answer === BuildingFeatures.NO_BASEMENT_ENCLOSURE) {
        answer = null;
      }
    }

    return (
      <ImageRadioButtons
        responseField={{ key: responseField.key, options: filteredOptions }}
        answer={answer}
        setResponse={(e) =>
          setAnswers((prevAnswers) => ({
            ...prevAnswers,
            buildingFeature: e.target.value
          }))}
      />
    );
  };

  return (
    <div className="question building-feature-question">
      <h4>{provider.question}</h4>
      {renderImageButtons()}
    </div>
  );
};

RateCalculatorQuestionBuildingFeature.propTypes = {
  provider: PropTypes.object.isRequired,
  answers: PropTypes.object.isRequired,
  setAnswers: PropTypes.func.isRequired,
  setNextQuestion: PropTypes.func.isRequired
};

export default RateCalculatorQuestionBuildingFeature;
