export default {
  meta: {
    title: 'Flood Risk Is Rising | News and Info on Flooding | FloodHelpNY.org',
    ogTitle: 'Flood News and Info | FloodHelpNY.org',
    description:
      'Climate change is increasing flood risk in NYC. Read the latest news about flood insurance policy, climate change',
    url: 'https://www.floodhelpny.org/en/news',
    imageAlt: 'FloodHelpNY News'
  },
  title: 'News',
  subHeading:
    'Get updates on flooding, flood risk, and extreme weather, plus tips on how to keep your property and finances flood-safe.',
  created: 'Published',
  updated: 'Updated',
  noPosts: 'No posts',
  emailSubject: 'Check out this news post on FHNY!',
  tweet: 'Read more at https://www.floodhelpny.org/en/news',
  search: 'Search news posts here',
  share: 'SHARE'
};
