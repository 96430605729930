import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '../styles/variables';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.background};
  flex-shrink: 0;
  width: 100px;
  height: 100px;
  border-radius: 50%;

  @media (max-width: 1200px) {
    margin-right: ${(props) => props.inAside && '2rem'};
  }

  img {
    width: 60%;
  }
`;

const RoundIcon = ({ src, background, alt, className, inAside }) => (
  <Container background={background} className={className} inAside={inAside}>
    <img src={src} alt={alt} />
  </Container>
);

RoundIcon.propTypes = {
  src: PropTypes.string.isRequired,
  background: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.node
};

RoundIcon.defaultProps = {
  background: colors.grayScale[0],
  alt: '',
  className: ''
};

export default RoundIcon;
