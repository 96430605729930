import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import aboutCNYCN from '../images/about-cnycn.png';
import mocej from '../images/mocej.png';
import nhs from '../images/nhs-bklyn-40.png';
import { colors } from '../styles/variables';
import { useLocale, useProvider } from '../helpers/hooks';
import {
  HeaderSection,
  Subheader,
  Section,
  SectionDescription
} from './ui/StyledResourcePageElements';

const ReducedSpacingHeader = styled(HeaderSection)`
  padding-bottom: 0px;
`;

const Logos = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-basis: 10px;
  justify-content: center;
  margin: 2rem 0;

  a {
    margin: 5px;
  }

  p {
    padding-top: 1rem;
  }

  @media (max-width: 860px) {
    flex-direction: column;
  }
`;

const Logo = styled.img`
  min-width: 200px;
  max-width: 350px;
  max-height: 100px;
  margin: 0.5rem 1rem;
`;

const Content = styled.div`
  padding: 0 30px;
  margin: 5rem auto;
  max-width: 1000px;

  > div {
    margin-bottom: 3rem;
    padding-bottom: 3rem;
    border-bottom: solid 1px ${colors.grayScale[1]};

    :first-of-type {
      border-top: solid 1px ${colors.grayScale[1]};
      padding-top: 1rem;
    }

    :last-of-type {
      border: none;
    }
  }

  p {
    text-align: justify;
    margin: 1rem 0;
  }

  }
`;

const Funding = styled.div`
  flex-basis: 100%;

  p {
    text-align: center;
  }
`;

const Resizer = styled.div`
  display: flex;
  margin: 0;
  padding: 0;
  resize: both;
  overflow: hidden;
  border: none;
`;

const Resized = styled.iframe`
  flex-grow: 1;
  margin: 0;
  padding: 1rem;
  border: 0;
`;

const Questions = styled.div`
  @media (max-width: 860px) {
    p {
      text-align: left;
    }
  }
`;

const Register = () => {
  const provider = useProvider('register');
  const locale = useLocale();

  return (
    <>
      <Helmet>
        <title>{provider.meta.title}</title>
        <link rel="canonical" href={provider.meta.url} />
        <meta property="og:title" content={provider.meta.title} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={provider.meta.url} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:image"
          content="https://www.floodhelpny.org/images/understanding_flood_insurance.png"
        />
      </Helmet>
      <ReducedSpacingHeader>
        <h1>{provider.title}</h1>
        <Subheader>{provider.subHeading}</Subheader>
      </ReducedSpacingHeader>
      <Content>
        <div>
          <p>{provider.riskRating}</p>
          <p>
            <a href={`/${locale}/understanding-flood-insurance`}>
              {provider.learnMore}
            </a>
          </p>
        </div>
        <h5>{provider.register}</h5>
        <Resizer>
          <Resized
            src="https://cnycn-org.zoom.us/meeting/register/tZUpd-qspz4jG9alMLyCCeGDK_zcwLTo_PiD"
            width="1000px"
            height="660px"
            resize="both"
            overflow="auto"
          ></Resized>
        </Resizer>
        <div>
          <h5>{provider.brought}</h5>
          <Logos>
            <Logo src={aboutCNYCN} alt="CNYCN logo" />
            <Logo src={mocej} alt="Mayor's Office of Resiliency logo" />
            <Logo src={nhs} alt="NHS Brooklyn" />
            <Funding>
              <p>{provider.funding.support}</p>
            </Funding>
          </Logos>
          <Questions>
            <p>
              {provider.funding.questions}{' '}
              <a href={`mailto:${provider.funding.commsEmail}`}>
                {provider.funding.commsEmail}
              </a>
            </p>
          </Questions>
        </div>
      </Content>
    </>
  );
};

export default Register;
