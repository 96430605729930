import RtappApplicationState from '../constants/RtappApplicationState';

export default {
  progressBar: {
    apply: 'Solicite',
    audit: 'Auditoría',
    review: 'Revisión',
    install: 'Instalar',
    complete: 'Finalización',
    rejected: 'Inelegible',
    withdrawn: 'Retirado'
  },
  [RtappApplicationState.PRE_APPLICATION]: {
    title: '¡Gracias! Estaremos en contacto pronto.'
  },
  [RtappApplicationState.PRE_APPLICATION_APPROVED]: {
    title: 'Complete su solicitud de válvula antirretorno'
  },
  [RtappApplicationState.APPLICATION_SUBMITTED]: {
    title: '¡Gracias por presentar su solicitud!'
  },
  [RtappApplicationState.HOMEOWNER_GRANT]: {
    title: '¡Felicitaciones! ¡Es elegible!'
  },
  [RtappApplicationState.REJECTED]: {
    title: 'Lo sentimos, usted no es elegible'
  },
  [RtappApplicationState.WITHDRAWN]: {
    title: 'Su solicitud se ha retirado'
  },
  [RtappApplicationState.FEASIBILITY_REVIEW]: {
    title: 'Estudio de Viabilidad'
  },
  [RtappApplicationState.AUDIT_SCHEDULED]: {
    title: 'Programar Auditoría'
  },
  [RtappApplicationState.AUDIT_COMPLETE]: {
    title: 'Auditoría Finalizada'
  },
  [RtappApplicationState.CONSULTATION_SCHEDULED]: {
    title: 'Tiene una cita para asesoramiento!'
  },
  [RtappApplicationState.CONSULTATION_COMPLETE]: {
    title: 'Continuemos'
  },
  [RtappApplicationState.INSTALLATION_PREPARE]: {
    title: 'Estamos formando su equipo'
  },
  [RtappApplicationState.MEET_YOUR_TEAM]: {
    title: 'Conozca a su equipo'
  },
  [RtappApplicationState.INSTALLATION_TO_BE_SCHEDULED]: {
    title: 'Programe su instalación'
  },
  [RtappApplicationState.INSTALLATION_HAS_BEEN_SCHEDULED]: {
    title: 'Programe su instalación'
  },
  [RtappApplicationState.INSTALLATION_COMPLETE]: {
    title: 'Se ha programado su instalación'
  },
  [RtappApplicationState.INSTALLATION_COMPLETE]: {
    title: '¡Instalación terminada!'
  },
  [RtappApplicationState.REPORT_COMPLETE]: {
    title: 'Informe final'
  }
};
