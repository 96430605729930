export default class FloodZones {
  static get X() {
    return 'X';
  }

  static get AE() {
    return 'AE';
  }

  static get VE() {
    return 'VE';
  }

  static get NONE() {
    return 'None';
  }
}
