import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import RoundIcon from './RoundIcon';
import CallToAction from './layout/CallToAction';
import applicationIcon from '../images/application.svg';
import { useLocale } from '../helpers/hooks';
import { Section } from './ui/StyledResourcePageElements';

const Text = styled.div`
  > * {
    margin-top: 2rem;

    :first-child {
      margin-top: 0;
    }
  }
`;

const AuditStatusInfoSingleImage = ({ provider, application }) => {
  const locale = useLocale();

  return (
    <>
      <Section>
        <h5>{provider.headerNext}</h5>
        <CallToAction>
          <RoundIcon src={applicationIcon} alt="application" />
          <Text>
            <h3>{provider.callToAction}</h3>
            <p>{provider.body}</p>
            <a
              href={`/${locale}/profile/${application.property_contact_detail.external_id}`}
            >
              {provider.toProfile}
            </a>
            <p>{provider.faq}</p>
            <p>{provider.contactUs}</p>
          </Text>
        </CallToAction>
      </Section>
      <Section>
        <h5>{provider.headerExpect}</h5>
        <p>{provider.expect}</p>
      </Section>
    </>
  );
};

AuditStatusInfoSingleImage.propTypes = {
  provider: PropTypes.object.isRequired,
  application: PropTypes.object.isRequired
};

export default AuditStatusInfoSingleImage;
