import MultifloorHelper from './MultifloorHelper';
import ElevationDifferenceHelper from './ElevationDifferenceHelper';
import FloodZoneSeverityHelper from './FloodZoneSeverityHelper';
import FloodZones from '../../constants/FloodZones';
import BuildingFeatures from '../../constants/BuildingFeatures';

export default class BasementNavigator {
  static nextComponent(results) {
    const propertyHasBuildingFeature =
      results.buildingFeature !== null && results.buildingFeature !== undefined;
    const propertyHasBasementBuildingFeature =
      propertyHasBuildingFeature &&
      [
        BuildingFeatures.NO_BASEMENT_ENCLOSURE,
        BuildingFeatures.MOBILE_HOME
      ].indexOf(results.buildingFeature) === -1;
    if (results.basement || propertyHasBasementBuildingFeature) {
      const { currentBfe } = results;
      const { futureBfe } = results;

      const currentDifference = ElevationDifferenceHelper.calculateElevationDifference(
        currentBfe,
        results
      );
      const futureDifference = ElevationDifferenceHelper.calculateElevationDifference(
        futureBfe,
        results
      );

      if (currentDifference < -1 || futureDifference < -1) {
        return 'machineryInBasement';
      }

      if (
        FloodZoneSeverityHelper.getMoreSevereZone(results) === FloodZones.VE
      ) {
        return 'elevatedBuilding';
      }
      return null;
    }

    if (MultifloorHelper.isMultifloor(results)) {
      if (
        FloodZoneSeverityHelper.getMoreSevereZone(results) === FloodZones.VE
      ) {
        return 'elevatedBuilding';
      }
      return null;
    }
    return 'numberOfFloors';
  }
}
