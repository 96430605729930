export default {
  meta: {
    title: 'Programa de Instalación de Válvula Antirretorno',
    url: 'https://www.floodhelpny.org/es/mitigation/backwater-valve',
    description: 'Proteja su vivienda y sus finanzas de las inundaciones.'
  },
  title: 'Válvula antirretorno: lo que necesitas saber',
  subtitle:
    'Una válvula antirretorno está diseñada para mantener las aguas residuales fuera de tu hogar en caso de desbordarse el alcantarillado.',
  overview: {
    header: 'Resumen',
    copy:
      'Una vez a la semana como promedio se desborda el sistema de alcantarillado combinado de la Ciudad de Nueva York. Cuando la marejada ciclónica se une a las aguas residuales y sobrecarga la capacidad del alcantarillado, el exceso de aquellas aguas puede fluir de regreso a través de las tuberías de desagüe y penetrar en las casas. Este reflujo causa un problema sanitario que implica graves peligros para la salud y altos costos de limpieza.'
  },
  howItWorksHeader: 'Cómo funciona la válvula',
  howItWorks: {
    paragraph1:
      'Una válvula antirretorno conecta las instalaciones de plomería y tiene un mecanismo interno denominado “flapper”. En caso de desbordarse el alcantarillado, este flapper o pestaña se cierra para proteger tu casa contra la irrupción de aguas residuales.',
    paragraph2:
      'Al cesar el desbordamiento, el cambio de presión libera el flapper o pestaña y permite que las aguas residuales fluyan desde tu casa hacia el alcantarillado.'
  },
  backwaterValveGifAlt: 'Backwater valve animation',
  affect: {
    header: 'Cómo te afecta',
    moneySavedSubheader: 'Estimación del dinero ahorrado',
    bigNumber: '$2,000 - $10,000',
    moneySavedCopy:
      'La instalación de una válvula antirretorno no afectará lo que paga por el seguro contra inundaciones. Sin embargo, en caso de desbordamiento del alcantarillado, tener la válvula en su lugar puede ahorrarle miles en costos de limpieza y restauración de aguas residuales.',
    damageAvoidedSubheader: 'Damage Avoided',
    damageAvoidedCopy:
      'Tener una válvula antirretorno reduce la probabilidad de que se acumulen tuberías de agua, por lo que todo lo que almacene en su sótano permanecerá seguro, seco y sin aguas residuales.'
  },
  whatItTakes: {
    header: 'Lo que se necesita',
    paragraph1:
      'La instalación de una válvula antirretorno puede demorar de 2 a 8 horas, dependiendo de qué tan accesibles sean las tuberías de drenaje de su hogar.',
    paragraph2:
      'El plomero también le dará algunos consejos de mantenimiento, como cómo enjuagar la válvula con agua para asegurarse de que no se bloquee.',
    cost: '$600–$5,000',
    costCopy:
      'El costo de instalación depende de la ubicación de sus tuberías de drenaje, así como del tipo de válvula antirretorno adecuada para su hogar.'
  },
  whatYouCanDoNext: {
    header: 'Qué puedes hacer a continuación',
    subheading: 'Llama a un plomero con licencia',
    copy:
      'Si estás listo para instalar en casa una válvula antirretorno, revisa primero si la póliza de seguro como propietario cubre el reflujo. Seguidamente llama a un maestro plomero con licencia, coordina una inspección de las tuberías de desagüe de tu casa y formula algunas preguntas claves (ver abajo). También puedes verificar la información sobre licencia en el sitio web del <a href="http://www.nyc.gov.dob" target="_blank" rel="noreferrer noopener">Departamento de Edificios de la Ciudad de Nueva York</a>.',
    question:
      '¿Qué preguntas debo formular al buscar un plomero para instalar esta válvula?',
    answer1:
      'Pregunta sobre licencia, experiencia en la Ciudad de Nueva York o en tu vecindario, así como sobre las certificaciones y las referencias de proyectos similares.',
    answer2:
      'Trata de obtener cotizaciones de al menos tres plomeros para compararlas.'
  }
};
