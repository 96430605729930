import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import ParametricIntakeForm from './ParametricIntakeForm';
import { HeaderSection, Section } from './ui/StyledResourcePageElements';
import { useProvider, useLocale, useFaq } from '../helpers/hooks';

const ParametricProgramApplication = () => {
  const provider = useProvider('parametricProgramApplication');
  const { setFaqZone } = useFaq();
  const locale = useLocale();

  useEffect(() => {
    setFaqZone('rtapp-landing');
  }, []);

  return (
    <>
      <Helmet>
        <title>{provider.meta.title}</title>
        <link rel="canonical" href={provider.meta.url} />
        <meta name="description" content={provider.meta.description} />
        <meta property="og:title" content={provider.meta.title} />
        <meta
          property="og:image"
          content="https://www.floodhelpny.org/images/home_resiliency_audit.png"
        />
        <meta property="og:description" content={provider.meta.description} />
        <meta property="og:url" content={provider.meta.url} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image:alt" content={provider.meta.imageAlt} />
      </Helmet>
      <HeaderSection>
        <h1>{provider.header}</h1>
      </HeaderSection>
      <Section>
        <h5>{provider.duplication}</h5>
        <p>
          {provider.duplicationBody}
          <a
            href="https://www.fema.gov/fact-sheet/duplication-benefits"
            target="_blank"
            rel="noreferrer noopener"
          >
            {provider.duplicationLink}
          </a>
          {provider.factSheet}
        </p>
      </Section>
      <Section>
        <h5>{provider.intakeFormHeader}</h5>
        <p>{provider.formInformation}</p>
      </Section>
      <ParametricIntakeForm />
    </>
  );
};

export default ParametricProgramApplication;
