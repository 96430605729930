import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import RateCalculatorRadioInput from './RateCalculatorRadioInput';
import PrefirmNavigator from '../helpers/calculatorNavigation/PrefirmNavigator';

const RateCalculatorQuestionPreFirm = ({
  provider,
  answers,
  setAnswers,
  setNextQuestion
}) => {
  const preFirm = answers.yearBuilt < 1984;

  useEffect(() => {
    if (answers.preFirm) {
      setNextQuestion(PrefirmNavigator.nextComponent(answers.preFirm));
    }
  }, [answers, setNextQuestion]);

  //  TODO figure out what the heck this is doing
  const answer = () => {
    if (!('preFirm' in answers)) {
      return '';
    }
    if (preFirm === answers.preFirm) {
      return 'yes';
    }
    return 'no';
  };

  // TODO figure out if this is rendering the correct responseFields
  const question = preFirm
    ? provider.preFirmQuestion
    : provider.postFirmQuestion;
  return (
    <div className="question prefirm-question">
      <h4>
        {question}
        *
      </h4>
      <RateCalculatorRadioInput
        radioKey="pre-firm"
        responseField={provider.responseField}
        answer={answers.preFirm}
        setResponse={(e) =>
          setAnswers((prevAnswers) => ({
            ...prevAnswers,
            preFirm: e.target.value
          }))}
      />

      <div className="promo">
        *
        {provider.disclaimer}
      </div>
    </div>
  );
};

RateCalculatorQuestionPreFirm.propTypes = {
  provider: PropTypes.object.isRequired,
  answers: PropTypes.object.isRequired,
  setAnswers: PropTypes.func.isRequired,
  setNextQuestion: PropTypes.func.isRequired
};

export default RateCalculatorQuestionPreFirm;
