import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import RateCalculatorRadioInput from './RateCalculatorRadioInput';
import BuildingSizes from '../constants/BuildingSizes';

const RateCalculatorQuestionNumberOfResidentialUnits = ({
  provider,
  answers,
  setAnswers,
  setNextQuestion
}) => {
  useEffect(() => {
    const answer = answers.numberOfResidentialUnits;
    if (answer && answer !== BuildingSizes.MULTI_FAMILY) {
      setNextQuestion('buildingType');
    }
  }, [answers, setNextQuestion]);

  return (
    <div className="question building-size-question">
      <h4>
        {provider.question}
        *
      </h4>

      <RateCalculatorRadioInput
        radioKey="number-of-residential-units"
        responseField={provider.responseField}
        answer={answers.numberOfResidentialUnits}
        setResponse={(e) =>
          setAnswers((prevAnswers) => ({
            ...prevAnswers,
            numberOfResidentialUnits: e.target.value
          }))}
      />
    </div>
  );
};

RateCalculatorQuestionNumberOfResidentialUnits.propTypes = {
  provider: PropTypes.object.isRequired,
  answers: PropTypes.object.isRequired,
  setAnswers: PropTypes.func.isRequired,
  setNextQuestion: PropTypes.func.isRequired
};

export default RateCalculatorQuestionNumberOfResidentialUnits;
