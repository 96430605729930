export default {
    genericError: {
      title: '错误',
      error: "我们很抱歉。我们无法找到您输入的地址的信息。请验证地址或稍后再试。如果您觉得收到的信息有误，请致电646-786-0888联系我们的房主中心。"
    },
    multipleResultsError: {
      title: '错误',
      error: "我们很抱歉。您输入的地址有多个匹配项。请验证地址或稍后再试。如果您觉得收到的信息有误，请致电646-786-0888联系我们的房主中心。"
    },
    outsideNycError: {
      title: '错误',
      error: "我们很抱歉。该地址似乎与纽约市公开的数据不匹配。如果您觉得收到的信息有误，请致电646-786-0888联系我们的房主中心。"
    },
    tooBroadError: {
      title: '错误',
      error: 'FloodHelpNY.org 根据个人地址提供洪水保险和弹性选项的信息。请输入您的家庭地址或要查找的其他家庭地址。如果您觉得收到的信息有误，请致电646-786-0888联系我们的房主中心。再试一次'
    },
    noRouteError: {
      title: '错误',
      error: "我们很抱歉。此地址与Google地图中公开的数据不匹配。如果您觉得收到的信息有误，请致电646-786-0888联系我们的房主中心。"
    }
  };
  