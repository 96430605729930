import React, { useState, useEffect, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import EligibilityAddressInput from './EligibilityAddressInput';
import AddressLookupError from './sharedComponents/AddressLookupError';
import BuildingApiCommunicator from '../communicators/BuildingApiCommunicator';
import SalesforceCommunicator from '../communicators/SalesforceCommunicator';
import TermsAndConditionsModal from './TermsAndConditionsModal';
import PropertyTypeModal from './PropertyTypeModal';
import { useLocale, useProvider, useApi } from '../helpers/hooks';
import ModalContext from '../context/ModalContext';

// called by:
//   LandingEligibility, MobileNav and DesktopNav
const EligibilityLookup = ({ navBar }) => {
  const provider = useProvider('EligibilityLookup');
  const locale = useLocale();
  const { buildingApi, salesforceApi } = useApi();

  const [errorProvider, setErrorProvider] = useState('lookupError');
  const [propertyInfo, setPropertyInfo] = useState({});
  const [propertyDetails, setPropertyDetails] = useState({});
  const [pageStatus, setPageStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { setIsModalActive, setModalChildren } = useContext(ModalContext);

  const handlePropertyInfo = (updatedPropertyInfo) => {
    setPropertyInfo(updatedPropertyInfo);
  };

  const handleSubmissionResponse = (response) => {
    const propertyResponse = { resiliency_audit_eligible: response.eligible };
    const combinedPropertyInfo = { ...propertyInfo, ...response };
    setPropertyInfo(combinedPropertyInfo);
    setPropertyDetails(propertyResponse);
    setPageStatus('propertyType');
    setIsLoading(false);
  };

  const handleProfileError = (error) => {
    const { data, status } = error;
    if (status === 500) {
      setErrorProvider('lookupError');
    }
    if (data.multiple_results) {
      setErrorProvider('multipleResultsError');
    }
    if (data.outside_nyc) {
      setErrorProvider('outsideNycError');
    }
    if (data.too_broad) {
      setErrorProvider('tooBroadError');
    }
  };

  const handleProfileSuccess = useCallback(
    (response) => {
      window.location.pathname = `/${locale}/profile/${response.property_details.fh_website_external_id__c}`;
    },
    [locale]
  );

  const handleProfileCreation = useCallback(async () => {
    try {
      // Returns an object { property_id: "id", property_details: {} }
      const result = await SalesforceCommunicator.createProperty(
        salesforceApi,
        propertyInfo,
        propertyDetails
      );
      handleProfileSuccess(result);
    } catch (error) {
      setPageStatus('error');
    }
  }, [handleProfileSuccess, propertyDetails, propertyInfo, salesforceApi]);

  const handleDisagree = () => {
    setPropertyInfo({});
    setPropertyDetails({});
    setPageStatus(null);
  };

  const handleErrorClick = () => {
    setIsLoading(false);
    setPageStatus(null);
  };

  const handlePropertyTypeClick = useCallback(
    async (isSmallBusiness) => {
      setPropertyInfo({ ...propertyInfo, small_business: isSmallBusiness });
      setPageStatus('termsAndConditions');
    },
    [propertyInfo]
  );

  useEffect(() => {
    setIsModalActive(pageStatus);
    pageStatus === 'propertyType' &&
      setModalChildren([
        <PropertyTypeModal handlePropertyTypeClick={handlePropertyTypeClick} />
      ]);

    pageStatus === 'termsAndConditions' &&
      setModalChildren([
        <TermsAndConditionsModal
          disagree={handleDisagree}
          agree={handleProfileCreation}
        />
      ]);

    pageStatus === 'error' &&
      setModalChildren([
        <AddressLookupError
          provider={provider[errorProvider]}
          unsetErrorState={handleErrorClick}
        />
      ]);
  }, [
    setIsModalActive,
    setModalChildren,
    handleProfileCreation,
    errorProvider,
    provider,
    pageStatus,
    handlePropertyTypeClick
  ]);

  const handleSubmitAddress = async () => {
    const eligibilityParams = {
      api: buildingApi,
      address: propertyInfo.formatted_address
    };
    try {
      // Returns {eligible: boolean}
      const result = await BuildingApiCommunicator.checkEligibility(
        eligibilityParams
      );
      handleSubmissionResponse(result);
    } catch (error) {
      handleProfileError(error.response);
      setPageStatus('error');
    }
  };

  return (
    <EligibilityAddressInput
      provider={provider}
      handleSubmitAddress={handleSubmitAddress}
      handlePropertyInfo={handlePropertyInfo}
      propertyInfo={propertyInfo}
      navBar={navBar}
      setIsLoading={setIsLoading}
      isLoading={isLoading}
    />
  );
};

EligibilityLookup.propTypes = {
  navBar: PropTypes.bool
};

EligibilityLookup.defaultProps = {
  navBar: false
};

export default EligibilityLookup;
