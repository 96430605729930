import BuildingSizes from '../constants/BuildingSizes';
import BuildingTypes from '../constants/BuildingTypes';
import ContentLocations from '../constants/ContentLocations';
import BuildingFeatures from '../constants/BuildingFeatures';
import bungalow from '../images/bungalow.svg';
import detached from '../images/detached.svg';
import semiDetached from '../images/semi-detached.svg';
import attached from '../images/attached.svg';
import midRiseWalkUp from '../images/midrise-walk-up.svg';
import midRiseElevator from '../images/midrise-elevator.svg';
import unknown from '../images/unknown.svg';
import basement from '../images/basement-cellar.svg';
import crawlspaceWithFloodVents from '../images/crawl-space-with-flood-vents.svg';
import crawlspaceWithoutFloodVents from '../images/crawl-space-without-flood-vents.svg';
import enclosure from '../images/enclosure.svg';
import mobileHome from '../images/mobile-home.svg';
import subgradeCrawlspace from '../images/sub-grade-crawl-space.svg';
import none from '../images/cross.svg';

export default {
  navigation: {
    next: 'Next',
    previous: 'Previous'
  },
  faq: {
    header: 'YOU MIGHT BE THINKING:'
  },
  breadcrumbs: {
    back: 'Back to Profile'
  },
  questions: {
    numberOfResidentialUnits: {
      title: 'numberOfResidentialUnits',
      faqZone: 'rate-calculator-number-of-residential-units',
      question: 'How many residential units does your building have?',
      breadcrumb: 'Building Size',
      responseField: {
        options: [
          { value: BuildingSizes.SINGLE_FAMILY, display: 'Single-family home' },
          { value: BuildingSizes.TWO_TO_FOUR_FAMILY, display: '2-4 units' },
          { value: BuildingSizes.MULTI_FAMILY, display: '5 or more units' }
        ],
        invalidAnswers: [BuildingSizes.MULTI_FAMILY],
        invalidCopy:
          'This rate calculator is only suited for 1-4 family properties. ',
        invalidLinkCopy: 'Click here to return home.',
        invalidLink: '/',
        invalidCopyAfterLink: '',
        alertAnswers: []
      }
    },
    buildingType: {
      title: 'buildingType',
      faqZone: 'rate-calculator-building-type',
      question: 'How would you best describe your building type?',
      breadcrumb: 'Building Type',
      responseField: {
        key: 'building_type',
        options: [
          {
            value: BuildingTypes.BUNGALOW,
            display: 'Bungalow',
            image: bungalow
          },
          {
            value: BuildingTypes.DETACHED,
            display: 'Detached',
            image: detached
          },
          {
            value: BuildingTypes.SEMI_DETACHED,
            display: 'Semi-detached',
            image: semiDetached
          },
          {
            value: BuildingTypes.ATTACHED,
            display: 'Attached',
            image: attached
          },
          {
            value: BuildingTypes.MID_RISE_WALK_UP,
            display: 'Mid-rise walk up',
            image: midRiseWalkUp
          },
          {
            value: BuildingTypes.MID_RISE_ELEVATOR,
            display: 'Mid-rise elevator',
            image: midRiseElevator
          },
          {
            value: BuildingTypes.UNKNOWN,
            display: "I don't know",
            image: unknown
          }
        ]
      }
    },
    preFirm: {
      title: 'preFirm',
      faqZone: 'rate-calculator-pre-firm',
      breadcrumb: 'Pre/Post FIRM',
      preFirmQuestion:
        'It appears this property was built before November 16, 1983. Is this correct?',
      postFirmQuestion:
        'It appears this property was built or substantially improved after November 16, 1983. Is this correct?',
      responseField: {
        options: [
          { value: 'yes', display: 'Yes' },
          { value: 'no', display: 'No' }
        ],
        invalidAnswers: [],
        invalidCopy: '',
        invalidLinkCopy: '',
        invalidLink: '#',
        invalidCopyAfterLink: '',
        alertAnswers: []
      },
      disclaimer:
        'Since the first flood zone map was adopted in New York City on November 16, 1983, there have been temporary subsidized rates for properties built before then.'
    },
    severeRepetitiveLoss: {
      title: 'severeRepetitiveLoss',
      faqZone: 'rate-calculator-severe-repetitive-loss',
      breadcrumb: 'Severe Repetitive Loss',
      floodDamageQuestion: 'Has this property had flood damage in the past?',
      severeRepetitiveLossQuestion:
        'Has FEMA or your flood insurance provider notified you that your building has had “severe repetitive loss”?',
      floodDamageResponse: {
        options: [
          { value: true, display: 'Yes' },
          { value: false, display: 'No' }
        ],
        invalidAnswers: [],
        invalidCopy: '',
        invalidLinkCopy: '',
        invalidLink: '#',
        invalidCopyAfterLink: '',
        alertAnswers: []
      },
      severeRepetitiveLossResponse: {
        options: [
          { value: 'yes', display: 'Yes' },
          { value: 'no', display: 'No' },
          { value: 'unknown', display: "I don't know" }
        ],
        invalidAnswers: [],
        invalidCopy: '',
        invalidLinkCopy: '',
        invalidLink: '#',
        invalidCopyAfterLink: '',
        alertAnswers: []
      },
      floodDamageDisclaimer:
        'We are asking this because there are special flood insurance rates that only apply to properties with a particular loss history.',
      severeRepetitiveLossDisclaimer:
        'Most buildings in New York City are not “severe repetitive loss” buildings.'
    },
    preferredRiskRate: {
      title: 'preferredRiskRate',
      faqZone: 'rate-calculator-preferred-risk-rate',
      breadcrumb: 'Preferred Risk Rate',
      floodClaimQuestion:
        'Have you ever made a flood insurance claim or received flood disaster assistance?',
      floodClaimResponseField: {
        options: [
          { value: true, display: 'Yes' },
          { value: false, display: 'No' }
        ],
        invalidAnswers: [],
        invalidCopy: '',
        invalidLinkCopy: '',
        invalidLink: '#',
        invalidCopyAfterLink: '',
        alertAnswers: []
      },
      numberOfClaimsQuestion: 'For how many events?',
      numberOfClaimsSubtitle:
        'Each storm is a separate event, so if you only received payment for Sandy that counts as one event.',
      numberOfClaimsResponseField: {
        options: [
          { value: 1, display: '1' },
          { value: 2, display: '2' },
          { value: 3, display: '3 or more' }
        ],
        invalidAnswers: [],
        invalidCopy: '',
        invalidLinkCopy: '',
        invalidLink: '#',
        invalidCopyAfterLink: '',
        alertAnswers: []
      },
      claimAmountQuestion: 'Did you receive more than $1,000 for each flood?',
      claimAmountResponseField: {
        options: [
          { value: 'true', display: 'Yes' },
          { value: 'false', display: 'No' }
        ],
        invalidAnswers: [],
        invalidCopy: '',
        invalidLinkCopy: '',
        invalidLink: '#',
        invalidCopyAfterLink: '',
        alertAnswers: []
      },
      disclaimer:
        'We are asking this because there are special flood insurance rates that only apply to properties with a particular loss history.'
    },
    elevatedBuilding: {
      title: 'elevatedBuilding',
      faqZone: 'rate-calculator-elevated-building',
      question: 'Is your home elevated?',
      breadcrumb: 'Elevated',
      responseField: {
        options: [
          { value: 'true', display: 'Yes' },
          { value: 'false', display: 'No' }
        ],
        invalidAnswers: [],
        invalidCopy: '',
        invalidLinkCopy: '',
        invalidLink: '#',
        invalidCopyAfterLink: '',
        alertAnswers: []
      },
      disclaimer:
        'This means the structure is situated on stilts or partial walls.'
    },
    contentLocation: {
      title: 'contentLocation',
      faqZone: 'rate-calculator-content-location',
      breadcrumb: 'Content Locations',
      question:
        'Where in your building are the belongings you’d like to insure?',
      responseField: {
        key: 'content_location',
        options: [
          {
            value: ContentLocations.BASEMENT_AND_ABOVE,
            display: 'Basement (washer/dryer/freezer only) & Above'
          },
          {
            value: ContentLocations.ENCLOSURE_AND_ABOVE,
            display: 'Enclosure (washer/dryer/freezer only) & Above'
          },
          {
            value: ContentLocations.LOWEST_FLOOR_ONLY,
            display: 'Lowest floor only, above ground level'
          },
          {
            value: ContentLocations.LOWEST_FLOOR_AND_HIGHER,
            display: 'Lowest floor above ground level and higher floors'
          },
          {
            value: ContentLocations.ABOVE_GROUND_LEVEL,
            display: 'Above ground level, more than one full floor'
          }
        ]
      },
      disclaimer:
        'Remember that the only contents you can insure in a basement or enclosure are a washer, drying and freezer. See the FAQs for more information.'
    },
    buildingFeature: {
      title: 'buildingFeature',
      faqZone: 'rate-calculator-building-feature',
      breadcrumb: 'Building Feature',
      question:
        'Which, if any, of the following features does your property have?',
      responseField: {
        key: 'building_feature',
        options: [
          {
            value: BuildingFeatures.BASEMENT,
            display: 'Basement or Cellar',
            image: basement
          },
          {
            value: BuildingFeatures.ENCLOSURE,
            display: 'Enclosure',
            image: enclosure
          },
          {
            value: BuildingFeatures.ELEVATED_ON_CRAWLSPACE_FLOOD_VENTS,
            display: 'Crawl space with flood vents',
            image: crawlspaceWithFloodVents
          },
          {
            value: BuildingFeatures.ELEVATED_ON_CRAWLSPACE_NO_FLOOD_VENTS,
            display: 'Crawl space without flood vents',
            image: crawlspaceWithoutFloodVents
          },
          {
            value: BuildingFeatures.SUBGRADE_CRAWLSPACE,
            display: 'Subgrade crawl space',
            image: subgradeCrawlspace
          },
          {
            value: BuildingFeatures.MOBILE_HOME,
            display: 'Mobile Home',
            image: mobileHome
          },
          {
            value: BuildingFeatures.NO_BASEMENT_ENCLOSURE,
            display: 'None',
            image: none
          }
        ]
      }
    },
    basement: {
      title: 'basement',
      faqZone: 'rate-calculator-basement',
      breadcrumb: 'Basement',
      hasBasementQuestion:
        'Does your property have a basement, crawlspace or enclosure?',
      responseField: {
        options: [
          { value: true, display: 'Yes' },
          { value: false, display: 'No' }
        ],
        invalidAnswers: [],
        invalidCopy: '',
        invalidLinkCopy: '',
        invalidLink: '#',
        invalidCopyAfterLink: '',
        alertAnswers: []
      },
      basementHeightQuestion:
        'What is the floor to ceiling height of this basement, crawlspace or enclosure? (Most basements are 8’)?',
      basementHeightUnit: 'ft'
    },
    numberOfSteps: {
      title: 'numberOfSteps',
      faqZone: 'rate-calculator-number-of-steps',
      breadcrumb: 'Steps Up/Down',
      hasStepsQuestion:
        'Are there steps leading from ground level to your lowest floor? (If you have a basement or below-ground crawlspace, we mean the steps from ground level outside down to your basement or crawlspace floor.)',
      hasStepsResponseField: {
        options: [
          { value: 'true', display: 'Yes' },
          { value: 'false', display: 'No' }
        ],
        invalidAnswers: [],
        invalidCopy: '',
        invalidLinkCopy: '',
        invalidLink: '#',
        invalidCopyAfterLink: '',
        alertAnswers: []
      },
      frontDoorQuestion: 'Do the steps lead up or down?',
      frontDoorResponseField: {
        options: [
          { value: 'true', display: 'Up' },
          { value: 'false', display: 'Down' }
        ],
        invalidAnswers: [],
        invalidCopy: '',
        invalidLinkCopy: '',
        invalidLink: '#',
        invalidCopyAfterLink: '',
        alertAnswers: []
      },
      numberOfStepsQuestion: 'How many steps are there?',
      disclaimer:
        'Be sure to answer this question from the lowest point of ground touching your house. If the ground is high at front and low at back, answer from the back. If the lowest floor is at ground level on any side, answer “no”.'
    },
    numberOfFloors: {
      title: 'numberOfFloors',
      faqZone: 'rate-calculator-number-of-floors',
      breadcrumb: 'Multi Floor',
      question: 'Is this home more than one floor?',
      responseField: {
        options: [
          { value: true, display: 'Yes' },
          { value: false, display: 'No' }
        ],
        invalidAnswers: [],
        invalidCopy: '',
        invalidLinkCopy: '',
        invalidLink: '#',
        invalidCopyAfterLink: '',
        alertAnswers: []
      }
    },
    machineryInBasement: {
      title: 'machineryInBasement',
      faqZone: 'rate-calculator-machinery-in-basement',
      breadcrumb: 'Machinery in Basement',
      question:
        'Is there building machinery in your basement such as a water heater, boiler, HVAC, etc.?',
      responseField: {
        options: [
          { value: true, display: 'Yes' },
          { value: false, display: 'No' }
        ],
        invalidAnswers: [],
        invalidCopy: '',
        invalidLinkCopy: '',
        invalidLink: '#',
        invalidCopyAfterLink: '',
        alertAnswers: []
      },
      disclaimer:
        'This means machinery or equipment that supplies utility services to the home.'
    }
  }
};
