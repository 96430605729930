import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors, borderRadius } from '../../styles/variables';
import { InputVisibilityWrapper } from './StyledInput';

const Container = styled.div`
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  font-size: 1rem;
  margin-bottom: 2em;

  label {
    padding: 1rem 3rem;
    width: 100%;
  }

  @media (max-width: 769px) {
    flex-flow: column;
    text-align: left;
    width: 100%;

    label {
      text-align: center;
      padding: 1rem 3rem;
    }
  }
`;

const CheckboxButton = styled.div`
  // Hides the default radio buttons
  input[type='checkbox'] {
    display: none;
  }

  input[type='checkbox']:checked + label {
    color: ${colors.resilientBlue};
    border: 1px solid ${colors.resilientBlue};
    box-shadow: inset 0 0 0 2px ${colors.resilientBlue};
  }

  label {
    display: inline-block;
    margin: 0;
    background-color: #fff;
    border: 1px solid ${colors.grayScale[1]};
    font-size: 1rem;
    font-weight: 500;
    border-radius: ${borderRadius};
    transition: all 0.25s ease;
    overflow: hidden;
  }

  & > * {
    display: flex;
    flex-flow: column;
    align-items: baseline;
    justify-content: flex-end;
  }

  @media (max-width: '1224px') {
    width: 100%;
  }
`;

const SelectApplicantType = ({
  answer,
  questionKey,
  index,
  responseField: { title, options, required, show },
  answers,
  setAnswers,
  questions,
  setQuestions
}) => {
  const setResponse = (e) => {
    const newAnswersArray = [...answers];
    // Either add or drop clicked answers from answers array
    if (newAnswersArray[index][questionKey].includes(e.target.value)) {
      const answerIndex = newAnswersArray[index][questionKey].indexOf(
        e.target.value
      );
      newAnswersArray[index][questionKey].splice(answerIndex, 1);
    } else {
      newAnswersArray[index][questionKey].push(e.target.value);
    }
    setAnswers(newAnswersArray);
  };

  // TODO update this function so that state is not directly mutated.
  const updateApplicantQuestions = () => {
    // Create a shallow copy of the questions array and mutate the applicantQuestion
    const newQuestionsArray = [...questions];
    const applicantInfoQuestionToBeUpdated = newQuestionsArray[index];
    const selectedApplicantType = answers[index].co_applicant_type;
    applicantInfoQuestionToBeUpdated.responseFields.map((responseField) => {
      if (responseField.type === 'select-applicant-type') {
        return null;
      }

      const updatedField = responseField;
      // Show fields that apply to any type of applicant if either is selected
      if (responseField.applicant_type === 'both') {
        updatedField.show = selectedApplicantType.length > 0;
        if (responseField.key === 'co_applicant_last_name') {
          updatedField.required = responseField.show;
        }
      } else {
        // Show fields that apply to a specific type of applicant if that type
        // is selected
        // Toggle visibility for each field
        updatedField.show = selectedApplicantType.includes(
          responseField.applicant_type
        );
      }

      // Make signature, last name and email field required if they are displayed
      if (
        responseField.key === 'co_applicant_signature' ||
        responseField.key === 'co_applicant_email' ||
        responseField.key === 'co_applicant_birthdate'
      ) {
        updatedField.required = updatedField.show;
      }

      return updatedField;
    });
    setQuestions(newQuestionsArray);
  };

  useEffect(updateApplicantQuestions, [answers]);

  const onChangeGroup = (e) => {
    setResponse(e);
  };

  const renderButtons = () => {
    const buttons = options.map((option) => (
      <CheckboxButton key={`${questionKey}'-'${option.value}`}>
        <input
          value={option.value}
          id={`${questionKey}'-'${option.value}'-'${index}`}
          checked={answer.includes(option.value)}
          type="checkbox"
          name={`${questionKey}'-radiobuttons'`}
          onChange={(e) => onChangeGroup(e)}
          required={required}
        />
        <label htmlFor={`${questionKey}'-'${option.value}'-'${index}`}>
          {option.display}
        </label>
      </CheckboxButton>
    ));
    return buttons;
  };

  return (
    <InputVisibilityWrapper show={show}>
      <label>{title}</label>
      <Container>{renderButtons()}</Container>
    </InputVisibilityWrapper>
  );
};

SelectApplicantType.propTypes = {
  questionKey: PropTypes.string.isRequired,
  responseField: PropTypes.object.isRequired,
  answer: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
  answers: PropTypes.array.isRequired,
  setAnswers: PropTypes.func.isRequired,
  questions: PropTypes.array.isRequired,
  setQuestions: PropTypes.func.isRequired
};

export default SelectApplicantType;
