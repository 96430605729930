import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { darken } from 'polished';
import { colors, borderRadius } from '../styles/variables';
import { useProvider, useLocale } from '../helpers/hooks';
import ButtonLink from './ui/ButtonLink';

const slideDown = keyframes`
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
`;

const slideUp = keyframes`
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-100%);
  }
`;

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  z-index: 3;
  height: auto;
  min-height: 120px;
  width: 100%;
  display: flex;
  justify-content: center;
  animation-name: ${(props) => (props.show ? slideDown : slideUp)};
  animation-duration: 0.5s;
  animation-fill-mode: backwards;
  animation-delay: ${(props) => (props.show ? '0.5s' : 0)};
`;

const Content = styled.div`
  position: relative;
  background-color: ${colors.emergency};
  padding: 1rem 3rem;
  margin: 1rem;
  display: flex;
  align-items: center;
  border-radius: ${borderRadius};
  border: 2px solid white;
  max-width: 1000px;

  p,
  a {
    color: #fff;
    margin: 0 1rem;
  }

  @media (max-width: 700px) {
    flex-direction: column;
    padding: 1rem;

    p {
      padding: 1rem;
    }
  }

  @media (max-width: 500px) {
    padding: 1rem 0.5rem;

    p,
    a {
      font-size: 1rem;
    }
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  border: none;
  color: #fff;
  background-color: transparent;
  font-size: 1.5rem;

  &:hover {
    color: ${darken(0.1, '#fff')};
  }

  @media (max-width: 700px) {
    top: 0.5rem;
    right: 1rem;
  }
`;

const EmergencyNotification = () => {
  const provider = useProvider('emergencyNotification');
  const [isVisible, setIsVisible] = useState(!!provider.text);
  const [show, setShow] = useState(!!provider.text);
  const locale = useLocale();

  const onAnimationEnd = () => {
    if (!show) {
      setIsVisible(false);
      sessionStorage.fhnyNotificationClosed = true;
    }
  };

  return (
    isVisible &&
    !sessionStorage.fhnyNotificationClosed && (
      <Wrapper onAnimationEnd={onAnimationEnd} show={show}>
        <Content>
          <CloseButton
            type="button"
            onClick={() => {
              setShow(false);
            }}
          >
            X
          </CloseButton>
          <p>{provider.text}</p>
          {provider.buttonText && (
            <ButtonLink
              url={provider.url}
              text={provider.buttonText}
              color="#fff"
              external={provider.external}
              ghost
            />
          )}
        </Content>
      </Wrapper>
    )
  );
};

export default EmergencyNotification;
