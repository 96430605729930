import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '../styles/variables';
import LowerInsuranceDifference from './LowerInsuranceDifference';
import ButtonLink from './ui/ButtonLink';

const Option = styled.div`
  border-top: solid 1px ${colors.grayScale[1]};
  padding: 2rem 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  * {
    @media (max-width: 1200px) {
      margin-top: 1rem;
      text-align: center;

      :first-child {
        margin-top: 0;
      }
    }
  }

  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;

const Content = styled.div`
  padding-left: 1.5rem;
  flex-basis: 40%;

  > * {
    margin-top: 1rem;

    :first-child {
      margin-top: 0;
    }
  }

  @media (max-width: 1200px) {
    padding-left: 0;
  }
`;

const LowerInsuranceOption = ({
  provider,
  property,
  linkUrl,
  helper,
  image
}) => (
  <Option>
    <img src={image} alt="" />
    <Content>
      <h4>{provider.header}</h4>
      <p>{provider.body}</p>
      <LowerInsuranceDifference
        provider={provider.mitigationDifference}
        property={property}
        helper={helper}
        useCurrent
      />
      <LowerInsuranceDifference
        provider={provider.mitigationDifference}
        property={property}
        helper={helper}
      />
    </Content>
    <ButtonLink url={linkUrl} text={provider.link} external />
  </Option>
);

LowerInsuranceOption.propTypes = {
  provider: PropTypes.object.isRequired,
  property: PropTypes.object.isRequired,
  linkUrl: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  helper: PropTypes.object.isRequired
};

export default LowerInsuranceOption;
