import handsHeart from '../images/hands-heart.png';
import buildingFlood from '../images/building-flood.png';
import floodNet from '../images/flood-net.png';
import homeAutomation from '../images/home-automation.svg';
import rainCloud from '../images/rain-cloud.png';
import storm from '../images/storm.svg';
import stormwaterFlooding from '../images/stormwater-flooding.svg';
import streetFlood from '../images/street-flood.jpeg';

export default {
  meta: {
    title: 'Chanjman Klimatik nan NYC | Inondasyon | FloodHelpNY',
    url: 'https://www.floodhelpny.org/ht_HT/climate-change',
    description:
      'Chanjman klimatik deja rann inondasyon pi grav nan Vil New York. Men sa ou bezwen konnen.',
    imageAlt: 'vag'
  },
  title: 'Chanjman Klimatik',
  subHeading:
    'Chanjman klimatik deja rann inondasyon pi grav nan Vil New York. Men sa ou bezwen konnen.',
  overview: {
    header: 'Apèsi',
    body:
      'Nivo lanmè yo nan Vil New York ap monte prèske defwa pi rapid pase mwayèn mondyal lan. Gwo ouragan ap miltipliye nan Oseyan Atlantik lan menase pou l bat Kot Lès lan chak ane. E gwo tanpèt ki vin detanzantan pi souvan ka, pafwa, pwodui inondasyon destriktif e ki ka menm fatal. Modèl meteyo ekstrèm sa yo vle di vil tankou pa nou yo bezwen aprann prepare epi adapte yo ak nouvo lavi nòmal kriz klimatik lan.',
    aside: {
      body:
        'Se pa tout moun ki afekte nan menm nivo an ak kriz klimatik lan. Kominote moun koulè yo gen plis posiblite pou pote prensipal viktim efè nivo lanmè k ap monte an epi inondasyon.',
      icon: buildingFlood,
      iconAlt: 'Batiman k Ap Inonde'
    },
    image: streetFlood,
    imageAlt: 'Lari ki inonde nan Vil New York',
    imageCaption: 'Koutwazi Pwojè Kominotè pou Siveyans Inondasyon'
  },
  howClimateChangeContributes: {
    header: 'Kijan chanjman klimatik lan kontribiye nan ogmantasyon inondasyon',
    body: {
      p1:
        'Atravè mond lan, nivo lanmè k ap monte yo ap kontinye ogmante frekans ak entansite inondasyon sou kot yo. Yo konsidere inondasyon kòm youn nan katastwòf natirèl ki pi komen epi ki koute pi chè, li tiye plis moun chak ane pase tònad. Pandan nou atann nou pou chanjman klimatik lan vin pi mal, se konsa inondasyon yo ap ye nan kominote nou yo.',
      p2: 'Men kijan chanjman klimatik lan ap afekte inondasyon nan NYC:',
      p3:
        'Nan okenn fason sa yo pa sè koneksyon ki gen ant chanjman klimatik ak inondasyon, men yo esansyèl pou konprann fason pou pwoteje kay nou yo, fanmi nou yo ak katye nou yo.'
    },
    items: [
      {
        header: 'Akoz rechofman klimatik lan, lè li fè lapli, li tonbe plis',
        body:
          'Depi 1900, Latè vin 4 pousan pi imid, ak pati lès nan Etazini k ap vin pi imid.',
        image: rainCloud,
        imageAlt: 'Nyaj Lapli'
      },
      {
        header: 'Pi gwo tanpèt pote pi gwo lapli ak van',
        body:
          'Kalite ouragan ki pot plis destriksyon yo ap ogmante nan frekans, ansanm ak nan Oseyan Atlantik lan.',
        image: storm,
        imageAlt: 'Loraj elektrik'
      },
      {
        header: 'Nivo lanmè k ap monte',
        body:
          'Pwojeksyon yo montre Kot Lès lan ta ka ekperimante lanmè ki rive 6.5 pye pi wo anvan 2100.',
        image: stormwaterFlooding,
        imageAlt: 'Inondasyon Dlo Lapli'
      }
    ]
  },
  climateCrisis: {
    title: 'Aksyon klimatik posib',
    body:
      'Pwojè Kominotè pou Siveyans Inondasyon an se yon rezo abitan ak òganizasyon k ap grandi ki rapòte inondasyon lokal, pataje eksperyans epi jwenn aksè ak resous ki lye ak inondasyon nan kominote ki bay sou kot NYC yo. Kominote yo ka itilize baz done imaj, rapò ak kat pataje sa yo pou kominike bezwen ak vizyon yo bay lidè yo nan vil lan. Ou ka enplike ou tou!',
    view: 'Enplike ou',
    image: handsHeart,
    imageAlt: 'Gid Tolerans Klimatik nan Litoral lan'
  },
  climateChangeFlooding: {
    header:
      'Inondasyon ki soti nan chanjman klimatik lan afekte vil lan nan yon fason ki pa egal',
    body:
      'Nan tout peyi an, katye ki te istorikman sou liy wouj epi majinalize yo atravè prè predatè ak pratik zonaj yo te nan yon risk ekspozisyon elve tou ak monte nivo lanmè ki lye ak klima epi inondasyon entans. Dapre RedFin, 13.8% nan kay ki te konn nan katye ki sou liy wouj ak liy jonn, ki gen majorite nan rezidan yo ki se moun koulè, nan yon pi gwo risk inondasyon pase lòt katye Vil New York yo. Envestisman nan enfrastrikti, tankou egou pou konbat ogmantasyon inondasyon, te kouran nan kominote ki pi enfliyan yo nan kominote Vil New York yo pase katye ki te konn sou liy wouj yo. Kongrè ak Ajans Pwoteksyon Anviwonnmantal (EPA) lan ap kontinye travay pou abòde sousi klimatik ki gen enpak nan fason ki pa egal kominote moun koulè yo, nan asire jistis anviwonnmantal fèt pou tout moun, kèlkeswa ras, sèks ak revni.'
  },
  severeHurricanes: {
    header: 'Soti nan ouragan ki grav pou rive nan lapli ki ekstrèm',
    body: {
      p1:
        'Abitan Vil New York yo viv tanpèt grav kòm rezilta ogmantasyon chanjman klimatik, ansanm ak yon tanpèt tankou Ouragan Sandy ki rive yon fwa nan lavi nan lane 2012, epi tou dènyeman, Ouragan Henri ak Ida nan lane 2021. Pandan Ouragan Ida, Sèvis Meteyoloji Nasyonal lan te bay premye “ijans pou inondasyon toudenkou” li an pou senk awondisman yo. Omwen 40 moun nan Nòdès lan te mouri nan tanpèt lan, ansanm ak 13 nan Vil New York, pifò nan apatman sousòl ki inonde.',
      p2:
        'Byento apre tanpèt lan, yon rapò biwo Majistra a te sòti te soulinye koneksyon ant lapli ekstrèm ak chanjman klimatik: “Nan yon fason k ap ogmante, evennman meteyolojik ekstrèm sa yo se nouvo nòmalite an; yon pati nan kriz klimatik enkontestab ki elaji l nan tout nasyon nou an ... li la, li vre epi li pran lavi,” otè rapò yo te ekri.',
      p3: `Pandan Biwo Majistra pou Klima ak Jistis Anviwonnmantal NYC an ak patnè ajans Vil li yo kontinye jefò yo pou mobilize kominote yo epi prepare pou tanpèt ki pi grav, li ap mande pou nou tout pwoteje katye ak kay nou yo.`
    },
    aside: {
      body:
        'FloodNet se yon kowoperativ kominote, chèchè ak ajans gouvènman Vil New York yo pou pi byen konprann frekans, gravite ak enpak inondasyon yo nan Vil New York. Done ak konesans ki rasanble yo ka sèvi rezidan lokal yo, chèchè, ajans vil yo ak lòt moun pou yo defann epi travay pou redui risk inondasyon.',
      link: 'Vizite FloodNet',
      icon: floodNet,
      iconAlt: 'Floodnet.nyc icon'
    },
    callToAction: {
      header:
        'Aprann osijè fason pou poze aksyon pou pwoteje pwopriyete ou an atravè renovasyon',
      icon: homeAutomation,
      iconAlt: 'Renovasyon nan kay',
      buttonText: 'Li Plis'
    }
  }
};
