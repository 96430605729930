import lookupErrorText from './LookupErrors.en';
import arrow from '../images/arrow.svg';

export default {
  icon: arrow,
  loadingText: '',
  minimalHazardZone: '该地址位于危险最小的洪水区。',
  bfe: ' 基准洪水标高（BFE）',
  button: '了解更多信息',
  X: '中等风险区',
  AE: '高风险区',
  VE: '极高风险区',
  None: '危险最小区',
  tryAnotherAddress: '请尝试其他地址。',
  errorText:
    "我们很抱歉。我们无法找到您输入的地址的信息。请验证地址或稍后再试。如果您觉得收到的信息有误，请致电646-786-0888联系我们的房主中心。",
  currentlyIn: '房屋目前位于 ',
  changingTo: ', 但很可能会变成 ',
  stayingAt: ", 很可能保持在相同的风险等级。",
  ...lookupErrorText
};