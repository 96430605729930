import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useProvider } from '../helpers/hooks';
import { Section } from './ui/StyledResourcePageElements';

const Content = styled.div`
  display: flex;
  justify-content: space-between;

  > div {
    max-width: 600px;
  }

  @media (max-width: 700px) {
    flex-direction: column;

    > div {
      margin-bottom: 20px;
    }
  }
`;

const AnchorContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Anchor = styled.a`
  font-size: 1.5rem;
  font-family: GT-Walsheim-Pro-Bold;

  @media (max-width: 1400px) {
    margin-bottom: 10px;
  }
`;

const AuditReportFromCounselor = ({ address }) => {
  const provider = useProvider('auditReportFromCounselor');

  return (
    <Section>
      <h2>{provider.header}</h2>
      <Content>
        <div>
          <p>{provider.copy[0]}</p>
          <p>
            <b>{address}</b>
          </p>
          <p>{provider.copy[1]}</p>
        </div>
        <AnchorContainer>
          <Anchor href="#property">{provider.links.property}</Anchor>
          <Anchor href="#audit-rates">{provider.links.rate}</Anchor>
          <Anchor href="#recommendations">{provider.links.resiliency}</Anchor>
          <Anchor href="#audit-documents">{provider.links.documents}</Anchor>
        </AnchorContainer>
      </Content>
    </Section>
  );
};

AuditReportFromCounselor.propTypes = {
  address: PropTypes.string.isRequired
};

export default AuditReportFromCounselor;
