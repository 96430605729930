import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';
import { colors, borderRadius } from '../../styles/variables';
import { InputVisibilityWrapper } from './StyledInput';

const Container = styled.div`
  display: flex;
  align-items: center;

  label {
    margin: 1em 1em 1em 0;
    width: 25%;

    @media (max-width: 680px) {
      width: 100%;
    }
  }

  input {
    display: inline-block;
    margin: 0;
    background-color: #fff;
    border: 1px solid ${colors.grayScale[1]};
    font-size: 1rem;
    font-weight: 500;
    border-radius: ${borderRadius};
    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease;
    overflow: hidden;
    padding: 1rem 3rem;
    width: 50%;

    :required {
      border: 1px solid ${colors.pinkBerry};
    }

    @media (max-width: 680px) {
      margin-bottom: 1rem;
      width: 100%;
    }
  }

  @media (max-width: 680px) {
    flex-direction: column;
    align-items: start;
  }
`;

const DateInput = ({
  answer,
  responseField: { minimum, maximum, title, show, required },
  answers,
  questionKey,
  setAnswers,
  index
}) => {
  const [isAnswerInvalid, setIsAnswerInvalid] = useState(required);
  const setResponse = (e) => {
    const newAnswersArray = [...answers];
    newAnswersArray[index][questionKey] = e.target.value;
    setAnswers(newAnswersArray);
  };

  useEffect(() => {
    // When the answer is invalid the input field is required. When the answer
    // has passed validation the input field is no longer required.
    // Users under 18 are not valid
    if (required) {
      const date = moment().subtract(18, 'years');
      const underEighteen = moment(answer) > date;
      answer ? setIsAnswerInvalid(underEighteen) : setIsAnswerInvalid(required);
    }
  }, [answer, required]);

  return (
    <InputVisibilityWrapper show={show}>
      <Container>
        {title && <label>{title}</label>}
        <input
          type="date"
          min={minimum}
          max={maximum}
          onChange={(e) => setResponse(e)}
          value={answer}
          required={isAnswerInvalid}
        />
      </Container>
    </InputVisibilityWrapper>
  );
};

DateInput.propTypes = {
  responseField: PropTypes.object.isRequired,
  answer: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  questionKey: PropTypes.string.isRequired,
  answers: PropTypes.array.isRequired,
  setAnswers: PropTypes.func.isRequired
};

export default DateInput;
