export default {
    title: 'Знаете ли вы…',
    flood: {
      number: '$25,000',
      copy:
        'Несколько дюймов воды от наводнения может причинить ущерб от наводнения в десятки тысяч долларов.',
      imgAlt: 'Flooded building'
    },
    risk: {
      number: '2 мили',
      copy:
        'Если вы живете в двух милях от побережья, вы можете находиться в группе риска наводнения.',
      imgAlt: 'Map with marker'
    },
    mortgage: {
      number: '1 из 4',
      copy:
        'Существует один к четырем шанс, что ваш дом будет затоплен во время вашей 30-летней ипотеки.',
      imgAlt: 'Chance of storm icon'
    }
  };

