export default {
  meta: {
    title: 'About FloodHelpNY.org',
    description:
      'FloodHelpNY.org was created as a platform for engaging and informing New Yorkers about how they can protect their homes and finances from flooding that is expected to worsen with rising sea levels caused by climate change.',
    ogDescription:
      'FloodHelpNY.org is a platform for engaging and informing New Yorkers about flood risk.',
    url: 'https://www.floodhelpny.org/en/about'
  },
  title: 'About Us',
  thisWebsite: {
    header: 'About This Website',
    intro:
      ' was created as a platform for engaging and informing New York City homeowners about how they can protect their home and finances from flooding that is expected to worsen with rising sea levels caused by climate change.',
    siteGoal:
      'A primary goal of the site is to connect eligible low- and middle-income homeowners to make informed decisions about reducing their risk to future floods that will also help to lower their flood insurance rates.',
    funded: 'The platform is funded through the ',
    gosr: 'New York Governor’s Office of Storm Recovery',
    and: ' and ',
    newYorkRising: 'New York Rising',
    fema: 'FEMA',
    partnership: ' in partnership with the ',
    mocej: "Mayor's Office of Climate & Environmental Justice"
  },
  cnycn: {
    header: 'ABOUT THE CENTER FOR NEW YORK CITY NEIGHBORHOODS',
    about:
      'The website is a project of the Center for New York City Neighborhoods, Inc., a non-profit organization committed to promoting and protecting affordable homeownership in New York so that middle– and working-class families are able to build strong, thriving communities. Learn more at ',
    link: 'cnycn.org.',
    contributions: 'The Center is grateful for the contributions of the ',
    mocej:
      'New York City Mayor’s Office of Climate and Environmental Justice (MOCEJ)',
    forGuidanceAndEfforts:
      ', both for its expert guidance and for its efforts to reach at-risk New Yorkers through an outreach and awareness campaign on flood risk and flood insurance. MOCEJ oversees the City’s multilayered OneNYC climate resiliency program. Grounded in the best available science as developed by the New York City Panel on Climate Change, MOCEJ works to ensure that the city’s neighborhoods, economy, and public services will be ready to withstand and emerge stronger from the impacts of climate change and other 21st century threats.',
    otherContributors:
      'The content of FloodHelpNY.org was also made possible in part through the expertise of ',
    legalServices: 'Legal Services NYC',
    legalServicesFunding: ' and funding from Legal Services Corporation.',
    capitalOne:
      'Early funding for the website was provided by CapitalOne Bank. '
  },
  gosr: {
    header: 'About the New York Governor’s Office of Storm Recovery',
    body:
      'Establishing the Office of Storm Recovery, the Governor aimed to address communities’ most urgent needs, while also encouraging the identification of innovative and enduring solutions to strengthen the State’s infrastructure and critical systems. Operating under the umbrella of New York Rising, the Governor’s Office of Storm Recovery (GOSR) utilizes approximately $4.4 billion in flexible funding made available by the U.S. Department of Housing & Urban Development’s (HUD) Community Development Block Grant Disaster Recovery (CDBG-DR) program to concentrate aid to four main areas. Paired with additional federal funding that has been awarded to other State agencies, the CDBG-DR program is enabling homeowners, small businesses and entire communities to build back even better than before. And in a State already known for its great resiliency and can-do spirit, the efforts are paving the way for a tremendous comeback – one that will reinvigorate New York and better prepare it for future extreme weather events that come its way.'
  },
  resiliencyAdvisory: {
    header: 'Resiliency Advisory Council',
    body:
      'The Governor’s Office of Storm Recovery and the Center for NYC Neighborhoods formed a Resiliency Advisory Council during the re-design of FloodHelpNY.org so that a wide range of organizations involved in meeting the challenges of rising sea levels and flood insurance costs could come together to create a shared and open platform for sharing information and empowering New Yorkers to prepare. Our partners in the Counsel included:',
    americanInstituteOfArchitects:
      'The American Institute of Architects - Staten Island Chapter',
    enterpriseCommunityPartners: 'Enterprise Community Partners',
    fema: 'Federal Emergency Management Agency',
    friendsOfRockaway: 'Friends of Rockaway',
    legalServicesNYC: 'Legal Services NYC',
    deptOfCityPlanning: 'New York City Department of City Planning',
    hpd: 'New York City Department of Housing Preservation and Development',
    sbs: 'New York City Department of Small Business Services',
    mor: 'Mayor’s Office of Resiliency',
    stBernardProject: 'St. Bernard Project',
    hud: 'U.S. Department of Housing and Urban Development'
  }
};
