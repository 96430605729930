import certificate from '../images/certificate.svg';
import compass from '../images/compass.svg';
import home from '../images/home.svg';

export default {
  meta: {
    title: '标高证明 | FloodHelpNY.org',
    description:
      '标高证明可以帮助您避免支付过高的洪水保险费。',
    ogDescription:
      '标高证明是保护房主免受洪水保险超额支付的文件。',
    url: 'https://www.floodhelpny.org/zh-CN/elevation-certificates'
  },
  title: '标高证明',
  subheader:
    "标高证明可以帮助您降低保险费率。以下是关于此关键文档的其他信息。",
  overview: {
    header: '概述',
    body:
      '标高证明是保护房主免受洪水保险超额支付的文件。由FEMA管理，并由专业工程师或测量师完成。标高证明收集的数据允许保险公司通过验证房屋的标高以及是否已采取任何措施最大程度降低洪水影响，从而更准确地评估财产的洪水风险。'
  },
  about: [
    {
      header: '标高证明上的信息',
      body:
        '标高证明上的三个关键数据字段是建筑类型和特征、最低楼层和房产位置。这些因素在确定洪水保险费时起着重要的作用。',
      image: certificate
    },
    {
      header: '如何使用标高证明',
      body:
        '如果希望使用证明，您应在文件上完成日期后90天内将其提供给您的保险代理人。根据证明上的信息，如果您有资格获得较低的保费，您的保险代理人必须收取较低的费率。',
      image: compass
    },
    {
      header: '实际需要标高证明的人',
      body:
        '随着FEMA通过风险评级2.0实施的变更，任何业主都不再需要标高证明。然而，标高证明对于确保您获得正确的洪水保险费率仍然很有价值。它们还可用来向保险提供商记录您可能已完成的任何财产重大变更，例如，提升您的房屋、安装泄洪洞或提升公用设施系统，其中任何一项都可能有助于降低您的洪水保险费率。',
      image: home
    }
  ],
  howToGetOne: {
    header: '如何获得标高证明',
    body:
      "标高证明的平均价格从500美元到1000美元不等。尝试咨询至少两位测量师获得报价，进行比较。专业测量师包括法律授权完成标高证明的持牌土地测量师、工程师或建筑师。FEMA 建议通过检查<a href='https://www.nysapls.org/search/custom.asp?id=2216' target='_blank' rel='noreferrer noopener'>州土地测量师专业协会</a>并参<a href='https://www1.nyc.gov/site/housingrecovery/programs/elevation-certificate.page' target='_blank' rel='noreferrer noopener'>考建筑部的指导意见</a>来寻找专业测量师。",
    tip: {
      header: '提示',
      body:
        '之前的房产所有者可能已经持有证书；如果正在购买房产或最近已经购买了房产，您可能需要联系他们。'
    }
  },
  alreadyHaveOne: {
    header: '如果您已经有标高证明',
    body:
      '如果您有标高证明，无需提交新证明。但是，如果您对房产进行了任何更改尽量减少洪水的影响，您可能需要更新证明，确保您收到正确的价格。'
  },
  expired: {
    header: '我的证明上表明在2018年到期。还有用吗？',
    body:
      '证明右上角的日期是模板日期，FEMA通常每三年更新一次。该日期确保工程师和土地测量员使用当前模板颁发标高证明。如果您的旧证书带有此日期，仍然适用于评级目的。如果是第一次获得标高证明或获得更新版标高证明，您需要确保工程师或土地测量员使用日期为2022年11月30日的表格。在联邦应急管理局发布新版本之前，您可以继续使用2022年11月30日的表格（有时可能是过期几年后）。'
  },
  download: '下载证明',
  update: '更新您的证明'
};
