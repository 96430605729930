import sortingOptions from '../images/sorting-options.svg';
import structural from '../images/structural.svg';
import wrench from '../images/wrench.svg';
import speechBubbles from '../images/speech-bubbles.svg';

export default {
  header: 'Lo Que Debe Esperar',
  list: [
    {
      header: 'Evaluación',
      imageAlt: '',
      body:
        'Una firma de ingeniería con credenciales evaluará su vivienda y le entregará el informe de resiliencia, que describe lo que puede hacer para estar protegido contra las inundaciones.',
      image: structural
    },
    {
      header: 'Discusión',
      imageAlt: '',
      body:
        'Programaremos un tiempo para que converse con un consejero de resiliencia, que puede explicarle su informe y sus opciones.',
      image: speechBubbles
    },
    {
      header: 'Revisión',
      imageAlt: '',
      body:
        'Subiremos su informe y certificado de elevación para que lo revise, de modo que pueda decidir qué pasos tomar.',
      image: sortingOptions
    },
    {
      header: 'Acción',
      imageAlt: '',
      body:
        'Su informe incluirá todas las formas en que puede reforzar su vivienda, y nosotros podemos ayudarlo a entrar en contacto con los recursos que necesita para comenzar.',
      image: wrench
    }
  ]
};
