import backwaterValve from '../images/backwater-valve.svg';
import floodproofing from '../images/floodproofing.svg';
import passFail from '../images/pass-fail.svg';
import raiseMechanicals from '../images/raise-mechanicals.svg';
import resilientRetail from '../images/resilient-retail-cover.jpg';
import assess from '../images/assess.svg';
import floodBarrier from '../images/flood-barrier.svg';
import generator from '../images/generator.svg';
import storefronts from '../images/storefronts.png';
import waterPump from '../images/water-pump.svg';
import waterproofStorage from '../images/waterproof-storage.svg';
import protect from '../images/protect.svg';
import sbsLogo from '../images/sbs-logo.svg';
import strategy from '../images/strategy.svg';

export default {
  meta: {
    title: 'Riesgo de inundación para pequeñas empresas | FloodHelpNY',
    ogTitle:
      'Explicación del riesgo de inundación para las pequeñas empresas | FloodHelpNY',
    url: 'https://www.floodhelpny.org/es/small-business',
    description:
      'Las inundaciones pueden ser devastadoras desde el punto de vista financiero para los propietarios de pequeñas empresas. Así es como puede proteger sus operaciones y su sustento.',
    imageAlt: 'Pequeñas Empresas | FloodHelpNY'
  },
  header: 'Pequeñas Empresas: Explicación del Riesgo de Inundaciones',
  subHeader:
    'Las inundaciones pueden ser devastadoras desde el punto de vista financiero para los propietarios de pequeñas empresas. Así es como puede proteger sus operaciones y su sustento.',
  overview: {
    header: 'VISIÓN GENERAL',
    body:
      'Las inundaciones pueden hacer más que dañar su inventario o interrumpir sus operaciones: pueden cerrar su negocio para siempre. De hecho, al menos el 25 por ciento de las pequeñas empresas afectadas por desastres nunca vuelven a abrir. Pero con una planificación adecuada, puede responder y recuperarse más rápidamente. Permítanos guiarlo a través de pasos simples que puede tomar para prepararse para una inundación y minimizar los daños que podrían hacer que cierre sus puertas para siempre.'
  },
  attribution: {
    logo: sbsLogo,
    logoAlt: 'New York City Small Business Services Logo',
    image: storefronts,
    imageAlt: 'Storefronts in New York City',
    credit: 'Esta página es presentada por:'
  },
  whatYouCanDo: {
    header: 'LO QUE PUEDEN HACER LOS PROPIETARIOS DE PEQUEÑAS EMPRESAS',
    assess: {
      header: 'Evaluar',
      icon: assess,
      iconAlt: 'casa',
      body:
        'Es fundamental que se tome el tiempo necesario para evaluar los riesgos que las inundaciones pueden representar para su empresa. Conozca su zona de inundación, considere todos los peligros para la salud y los riesgos para sus operaciones.'
    },
    prepare: {
      header: 'Preparar',
      icon: passFail,
      iconAlt: 'lista de verificación',
      body:
        'Asegúrese de tener un plan de emergencia para garantizar la continuidad de las operaciones y proteger a sus empleados. También es esencial que compre o renueve su seguro contra inundaciones.'
    },
    protect: {
      header: 'Proteger',
      icon: protect,
      iconAlt: 'proteger',
      body:
        'Conozca los pasos que puede tomar para proteger la estructura y el equipo de su empresa, así como los ingresos, para que pueda recuperarse de las inundaciones lo más rápido posible.'
    },
    callToAction: {
      header: 'Inicie Su Plan de Continuidad Comercial Hoy',
      icon: strategy,
      iconAlt: 'Inicie Su Plan de Continuidad Comercial Hoy',
      body:
        'Utilice este marco para recuperarse rápidamente después de cualquier interrupción. Proteja sus inversiones, manténgase en contacto con empleados y proveedores y esté preparado para responder a la incertidumbre.',
      buttonText: 'Ver'
    }
  },
  riskZoneHeader: 'ENTIENDA SU RIESGO DE INUNDACIONES',
  severeRiskZone: 'Riesgo Máximo',
  highRiskZone: 'Riesgo Alto',
  moderateRiskZone: 'Riesgo Moderado',
  warning: {
    primary: {
      body:
        'Actualmente, hay 14,500 negocios que operan en áreas con mayor riesgo de inundaciones en la ciudad de Nueva York. Según la <a href="https://www.fema.gov" target="_blank" rel="noopener noreferrer">Agencia Federal para el Manejo de Emergencias (FEMA, por sus siglas en inglés)</a>, los desastres mayores pueden afectar a cerca del 40% de las pequeñas empresas dentro de un año de una inundación, mientras que el 25% cierra después de un año de verse afectadas.'
    },
    aside: {
      header: 'CONSTRUYENDO LÍMITES DE COBERTURA',
      body:
        'La cantidad máxima de seguro contra inundaciones que puede comprar para cubrir el costo de los daños a su edificio es de $500,000.'
    }
  },
  floodInsurance: {
    primary: {
      header: 'EL SEGURO CONTRA INUNDACIONES PUEDE REDUCIR SU RIESGO',
      body:
        'El propósito de una póliza de seguro contra inundaciones es proporcionar una compensación financiera después de una pérdida por inundación, que generalmente está excluida de una póliza de seguro de propiedad. No tener una política contra inundaciones puede poner a la empresa en riesgo de sufrir pérdidas financieras en caso de una inundación, que es el desastre natural que ocurre con más frecuencia en el mundo. Y las inundaciones pueden ocurrir en cualquier lugar: más del 20 por ciento de las reclamaciones por inundaciones provienen de propiedades fuera de la zona de inundación de alto riesgo.'
    },
    aside: {
      header: 'COMPRE AHORA, AHORRE DESPUÉS',
      body:
        'Si compra un seguro contra inundaciones ahora, podría ahorrarse miles de dólares en los próximos años.'
    }
  },
  businessInsurance: {
    header: 'SEGURO POR INTERRUPCIÓN DE NEGOCIOS',
    body:
      'El seguro por interrupción del negocio (también conocido como seguro de ingresos comerciales) es un tipo de seguro que cubre la pérdida de ingresos que sufre una empresa después de un desastre. La pérdida de ingresos cubierta puede deberse al cierre de las instalaciones comerciales relacionado con el desastre o al proceso de reconstrucción después de un desastre. Es importante que los dueños de negocios comprendan que su seguro de propiedad actual puede no cubrir sus ingresos. La pérdida de ingresos generada por el negocio podría significar la diferencia entre mantener las puertas abiertas o cerradas permanentemente.'
  },
  lowerYourRiskOptions: {
    header: 'MEDIDAS FÍSICAS PARA DISMINUIR SU RIESGO DE INUNDACIONES',
    list: [
      {
        image: raiseMechanicals,
        imageAlt: 'casa',
        title: 'Sube tus equipos mecánicos',
        body:
          'La mayoría de las veces, este equipo se encuentra en el sótano de un edificio, donde puede estar expuesto a las inundaciones.'
      },
      {
        image: backwaterValve,
        imageAlt: 'válvula',
        title: 'Instalar una válvula antirretorno',
        body:
          'Esto ayuda a evitar que las aguas residuales ingresen a su edificio en caso de que se desborde el alcantarillado.'
      },
      {
        image: floodproofing,
        imageAlt: 'casa',
        title: 'Impermeabilización en seco y húmedo',
        body:
          'Si no puede reubicarse y la estructura de su edificio lo permite, debe considerar la protección contra inundaciones en seco y húmedo como opciones de protección contra inundaciones.'
      }
    ]
  },
  otherMethodsToLowerRisk: {
    title: 'OTROS MÉTODOS PARA REDUCIR EL RIESGO DE INUNDACIONES',
    subtitle:
      'Las inundaciones pueden ocurrir en cualquier momento y en áreas con poco o ningún riesgo de inundación. Ya sea un propietario o inquilino, existen medidas que puede tomar hoy para ayudarlo a mantenerse en el negocio y ponerlo en funcionamiento después de una inundación. Considere el siguiente equipo para proteger su negocio contra daños por inundaciones y para ayudarlo a recuperarse más rápido.',
    list: [
      {
        header: 'Barreras contra inundaciones portátiles',
        body:
          'Las barreras contra inundaciones portátiles que están diseñadas para la instalación / colocación temporal en puertas y otros puntos de posible penetración pueden ayudar a una empresa a proteger su espacio de inundaciones y daños por agua.',
        image: floodBarrier,
        imageAlt: 'Barreras contra inundaciones portátiles'
      },
      {
        header: 'Generador portátil',
        body:
          'Los generadores portátiles se pueden utilizar para alimentar equipos para operaciones continuas o para mantener las condiciones ambientales durante la pérdida de energía primaria.',
        image: generator,
        imageAlt: 'Generador portátil'
      },
      {
        header: 'Almacenamiento portátil a prueba de agua',
        body:
          'Una caja de almacenamiento portátil a prueba de agua puede preservar documentos esenciales, lo que permite a una empresa acceder a la información crítica necesaria para responder a un incidente de desastre y reanudar las operaciones de manera oportuna.',
        image: waterproofStorage,
        imageAlt: 'Almacenamiento portátil a prueba de agua'
      },
      {
        header: 'Bomba de agua sumergible portátil',
        body:
          'Se puede usar una bomba de agua sumergible y una manguera para evitar la acumulación de agua de la inundación que ingresa a un edificio o eliminar el agua después de una inundación, mitigando los daños al edificio o los suministros y acelerando el proceso de recuperación.',
        image: waterPump,
        imageAlt: 'Bomba de agua sumergible portátil'
      }
    ]
  },
  continuityPlan: {
    title: 'Guía minorista resiliente de planificación urbana',
    body:
      'Un recurso único para ayudar a los dueños de negocios ubicados en los corredores minoristas de la llanura aluvial de la ciudad.',
    view: 'Ver',
    image: resilientRetail,
    imageAlt: 'Guía de Resiliencia Climática Costera'
  }
};
