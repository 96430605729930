import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors, getZoneColor } from '../styles/variables';

const Zone = styled.div`
  color: ${(props) => props.color};
  font-family: 'GT-Walsheim-Pro-Bold';
  font-size: 12rem;
  line-height: 1;

  @media (max-width: 800px) {
    font-size: 5rem;
  }
`;

const Description = styled.div`
  position: relative;
`;

const ZoneDescription = styled.div`
  text-decoration: underline;
`;

const ZoneTooltip = styled.div`
  font-size: 1rem;
  background-color: white;
  margin-top: 1rem;
  visibility: hidden;
  width: 300px;
  color: ${colors.grayScale[3]};
  padding: 1rem;
  position: absolute;
  z-index: 1;
  box-shadow: -8px 7px 16px 0 ${colors.grayScale[4]};
  left: 10%;

  ${Description}:hover & {
    visibility: visible;
  }
  @media (max-width: 740px) {
    width: 100px;
  }
`;

const FloodZone = ({ provider, floodZone, bfe }) => {
  const zoneDescription = () => {
    let copy = provider[`${floodZone.toLowerCase()}Zone`];

    if (floodZone === 'AE' || floodZone === 'VE') {
      copy += `, ${bfe}' ${provider.baseFloodElevation}`;
    }

    return copy;
  };

  const renderToolTip = () => {
    if (floodZone === 'None') {
      return null;
    }
    const toolTipCopy = provider[`${floodZone.toLowerCase()}ToolTip`];

    return <ZoneTooltip>{toolTipCopy}</ZoneTooltip>;
  };

  const zoneDesignation = floodZone === 'None' ? 'X' : floodZone.toUpperCase();

  return (
    <div>
      <Zone color={getZoneColor(floodZone)}>{zoneDesignation}</Zone>
      <Description>
        <ZoneDescription>{zoneDescription()}</ZoneDescription>
        {renderToolTip()}
      </Description>
    </div>
  );
};

FloodZone.propTypes = {
  provider: PropTypes.object.isRequired,
  floodZone: PropTypes.string.isRequired,
  bfe: PropTypes.string
};

export default FloodZone;
