import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '../styles/variables';
import { useLocale, useProvider } from '../helpers/hooks';

const Wrapper = styled.div`
  margin: 8rem 2rem 2rem 2rem;
  display: flex;
  justify-content: space-between;
`;

const BackToProfile = styled.div`
  display: flex;
  align-items: center;
`;

const Arrow = styled.span`
  font-size: 2rem;
  color: ${colors.resilientBlue};
  margin-right: 1rem;
`;

const AuditHeader = ({ application }) => {
  const provider = useProvider('auditHeader');
  const locale = useLocale();

  return (
    <Wrapper>
      <h5>{provider.statusHeader}</h5>
      <BackToProfile>
        <Arrow>‹</Arrow>
        <a
          href={`/${locale}/profile/${application.property_contact_detail.external_id}`}
        >
          {provider.backToProfileLink}
        </a>
      </BackToProfile>
    </Wrapper>
  );
};

AuditHeader.propTypes = {
  application: PropTypes.object.isRequired
};

export default AuditHeader;
