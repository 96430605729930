export default class ElevationDifferenceHelper {
  static calculateElevationDifference(bfe, params) {
    const differenceBetweenLAGAndBFE = params.lag - bfe;
    const basementHeight = params.basementHeight || 0;
    const numberOfSteps = params.numberOfSteps || 0;
    if (params.stepsUp) {
      return (
        differenceBetweenLAGAndBFE + numberOfSteps * (8 / 12) - basementHeight
      );
    }

    return differenceBetweenLAGAndBFE - numberOfSteps * (8 / 12);
  }
}
