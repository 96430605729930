export default {
  meta: {
    title:
      'Risk pou Inondasyon Ap Monte | Nouvèl ak Enfòmasyon sou Inondasyon | FloodHelpNY.org',
    ogTitle: 'Nouvèl ak Enfòmasyon sou Inondasyon | FloodHelpNY.org',
    description:
      'Chanjman klimatik ap ogmante risk pou inondasyon nan NYC. Li dènye nouvèl yo apwopo politik asirans kont inondasyon yo, chanjman klimatik',
    url: 'https://www.floodhelpny.org/ht_HT/news',
    imageAlt: 'Nouvèl FloodHelpNY'
  },
  title: 'Nouvèl',
  subHeading:
    'Jwenn mizajou sou inondasyon, risk pou inondasyon ak tanperati ekstrèm, anplis konsèy sou fason pou kenbe pwopriyete ak finans yo sekirize kont inondasyon.',
  created: 'Pibliye',
  updated: 'Ajou',
  noPosts: 'Okenn piblikasyon',
  emailSubject: 'Verifye piblikasyon nouvèl sa a sou FHNY!',
  tweet: 'Li plis nan https://www.floodhelpny.org/ht_ht/news',
  search: 'Chèche nouvo piblikasyon la',
  share: 'PATAJE'
};
