import fillInBasement from '../images/fill-in-basement.svg';
import elevate from '../images/elevate.svg';
import elevateMechanicals from '../images/elevate-mechanicals.svg';
import abandonFirstFloor from '../images/abandon-first-floor.svg';
import floodVents from '../images/flood-vents.svg';

export default {
  title: '缓解措施',
  overview: '概述',
  affectRate: '如何影响您的洪水保险费率',
  howItWorks: '原理',
  effort: '工作量',
  backwaterValve: '回水阀：您需要了解的内容',
  elevate: {
    meta: {
      title: '提升您的房屋',
      url: 'https://www.floodhelpny.org/zh-CN/mitigation/elevate',
      description:
        '提升您的房屋包括将建筑物提升到预计洪水的高度以上，并将其放在新的或扩展的地基上。'
    },
    title: '提升您的房屋',
    subtitle:
      '提升您的房屋包括将建筑物提升到预计洪水的高度以上，并将其放在新的或扩展的地基上。',
    overview:
      '通常，将您的房屋提升到现有地基之上的临时支撑物上，然后建造更高的新地基，然后将房屋降低到新地基上。提升的费用很高，需要您临时搬迁，但这将大大降低您的洪水损失风险和洪水保费。',
    image: elevate,
    imageAlt: '提升',
    affectRate:
      '提升是大幅降低洪水保险费率并在未来保持较低水平的最有效方法。如果您的住宅位于特殊洪水危险区，确定洪水保险费率的主要因素是住宅最低楼层标高与基准洪水标高之间的差异。如果将房屋提升到BFE之上，您就降低了风险，也会最大程度降低保费。如果想在提升后享受低费率，您家下面的空间只能用于储物、停车和出入。',
    howItWorks:
      '如果您的房屋高于BFE，在发生洪水时，您的生活空间和公用设施可能会高于洪水，从而保护您的财产和贵重物品免受洪水损坏。如果选择提升，您必须将房屋升高到BFE上方至少两英尺，考虑洪水地图中的不确定性和预计的海平面上升。这额外两英尺的高度被称为“出水高”，是纽约市建筑规范的一部分。房屋的新高度（包括出水高）将为设计洪水标高。',
    effort:
      '根据承包商和结构的不同，房屋提升可能需要三个月的时间。在此期间，您需要重新安置并将贵重物品从家中取出。施工期间，您的房屋的水、下水道和电力等公用设施会断开。'
  },
  elevateMechanicals: {
    meta: {
      title: "提升家庭机械设备",
      url: 'https://www.floodhelpny.org/zh-CN/mitigation/elevate-mechanicals',
      description:
        "您家的机械设备是必不可少的——比如炉子、电力设施和管道。"
    },
    title: "提升家庭机械设备",
    subtitle:
      "您家的机械设备是必不可少的——比如炉子、电力设施和管道。",
    overview:
      '洪水湮没设备时，设备会停止正常运行。更换或修理可能需要花费数百美元或数千美元。因此，要让您的房屋不易受洪水侵袭，最有效的选择之一就是提高机械设备。可以通过将设备提高到当前位置的上方，或将其从地下室或夹层空间重新定位到一楼要上的位置来实现。在某些情况下，房主可能希望在洪水标高以上的房屋上建造一个小型附加设施，作为公用设施室。不幸的是，提升机械设备不会减少洪水保费。这也是一个相对昂贵的选择。',
    image: elevateMechanicals,
    imageAlt: '提升机械设备',
    affectRate:
      '提升家中的机械设备可以为您节省每年100美元到200美元的保险费。',
    howItWorks:
      '将机械设备重新安置在洪水标高以上或地下室外时，可以显著降低关键系统在洪水期间受损的风险。您不仅可以节省昂贵的维修和更换费用，如果您的热力和电气系统完好无损，在洪水过后您可以更快地回到家中。仅此一项就可以为您节省数百美元甚至数千美元的临时搬迁费用。',
    effort:
      '移动机械设备可能需要三天到几周的时间，这取决于您的房屋和您的承包商，以及您选择迁移的系统。在这段时间里，您只能有限使用或根本无法使用暖气、热水和电力设施。'
  },
  fillInBasement: {
    meta: {
      title: '回填地下室或夹层空间',
      url: 'https://www.floodhelpny.org/zh-CN/mitigation/fill-in-basement',
      description:
        '用沙子、砾石或其他材料填满地下室或夹层空间，直到地面，可以降低洪水损坏的风险和洪水保险费。'
    },
    title: '回填地下室或夹层空间',
    subtitle:
      '用沙子、砾石或其他材料填满地下室或夹层空间，直到地面，可以降低洪水损坏的风险和洪水保险费。',
    overview:
      '您还需要在地下室或夹层空间的其余地上部分安装防淹泄洪口。虽然回填地下室的费用可能很高，但从长远来看，可以为您节省数千美元的洪水保费。在某些情况下，回填地下室可能会破坏建筑物的结构完整性，因此在采取行动之前，务必咨询工程师。',
    image: fillInBasement,
    imageAlt: '回填地下室',
    affectRate:
      '如果您的住宅位于特殊的洪水危险区，确定洪水保险费率的主要因素是住宅第一层（这是地下室）的标高与基准洪水标高之间的差异。通过回填地下室并安装泄洪口，可以减少这种差异，因为出于保险目的，您的下一层地面将成为您家的最低楼层。这会降低您的洪水保费，但根据与BFE相比的地面高程，保费降低的幅度会有所不同。',
    howItWorks:
      '通过取消地下室和安装泄洪口，您可以在不实际提升房屋的情况下提高家中的最低楼层标高——地面以上第一层将成为家中的“最低楼层标高”回填地下室时，您还要确保所有物品都在地上，这样在洪水发生时，就不太可能被损坏。安装泄洪口可以保护您家的基础和墙壁免受水压的损坏。然而，为了回填地下室，您可能不得不放弃部分宜居区域，所以您应该考虑这些好处是否超过了这个空间的损失。 ',
    effort:
      '获得所有许可证并开始施工后，回填地下室或夹层空间和安装泄洪孔平均需要一到两周的时间。'
  },
  floodVents: {
    meta: {
      title: '安装泄洪口',
      url: 'https://www.floodhelpny.org/zh-CN/mitigation/flood-vents',
      description:
        '安装泄洪孔需要在家中最低楼层下方增加开口，在发生洪水时允许水进出，而不会造成结构损坏。'
    },
    title: '安装泄洪口',
    subtitle:
      '安装泄洪孔需要在家中最低楼层下方增加开口，在发生洪水时允许水进出，而不会造成结构损坏。',
    overview:
      '这是最便宜的缓解方案之一，但仅适用于机械设施和控制装置位于或能够重新安置在特定高度（称为设计洪水标高）以上的家庭。如果要安装泄洪口或开口，必须仅将基准洪水标高以下的任何区域用于停车、储存或建筑通道，BFE以下的区域必须至少有一侧位于地面或地面以上。',
    image: floodVents,
    imageAlt: '泄洪口',
    affectRate:
      '如果地下室或夹层空间的地板至少有一侧在地面以上，或者如果在回填地下室或夹层空间至地面的同时安装泄洪口，可以降低洪水保险费。在高风险洪水区，决定保险费率的主要因素是您家中最低楼层的标高与BFE之间的差异。如果您回填了地下室并安装了泄洪口，那么下一层最高的楼层将成为您的“最低楼层”，这将降低您的保险费。',
    howItWorks:
      '通过在建筑侧面添加开口，可以降低水压（静水力和水动力）造成的损坏风险。在发生洪水时，水可以通过泄洪口自由流出，无需人为干预，这将降低墙壁或基础结构损坏的风险。',
    effort:
      '如果公用设施已经高于设计洪水高程，且没有地下室或夹层空间来回填，安装泄洪口相对容易，最多需要几天时间。如果确实需要回填地下室或夹层空间和/或提升机械设备，湿式防洪会更加复杂、耗时，成本也更高。'
  },
  abandonFirstFloor: {
    meta: {
      title: '放弃一楼',
      url: 'https://www.floodhelpny.org/zh-CN/mitigation/abandon-first-floor',
      description:
        '放弃房屋一楼包括将二楼以上的地方作为您的生活区，将一楼仅仅用作储物、停车或出入。'
    },
    title: '放弃一楼',
    subtitle:
      '放弃房屋一楼包括将二楼以上的地方作为您的生活区，将一楼仅仅用作储物、停车或出入。',
    overview:
      '如果有一个地下室或地下夹层空间，您也需要将其回填。您需要将所有公用设施移动到“设计洪水标高”上方，或将其放在提升结构的外部。您还需要使第一层“未完工”（没有干式墙），并在房屋的设计洪水标高以下添加泄洪口口或开口。这种策略成本会比较高，但可以显著降低您的保费和风险。这也可能意味着放弃大部分生活区域，这是在决定是否采用这种缓解方案时需要考虑的重要权衡。',
    image: abandonFirstFloor,
    imageAlt: '放弃一楼',
    affectRate:
      '如果您放弃了房产的一楼，您就可以有效地提高最低居住空间的标高，或者为了计费的目的而提高“最低楼层”，这样会最大程度降低保费。',
    howItWorks:
      '放弃哟楼可以将其提升到BFE以上。这增加了受到洪水保护的可能性，让房屋和贵重物品免受损坏。',
    effort:
      '放弃一楼将需要临时搬迁，以及额外的建筑来移动您的机械设备和电器，并在一楼回填后建造一个通往家中的备用入口。'
  }
};