export default {
  meta: {
    title: 'Politik sou Vi Prive',
    url: 'https://www.floodhelpny.org/ht_HT/privacy',
    description: null,
    imageAlt: null
  },
  title: 'Politik sou Vi Prive',
  intro: {
    effective_date: 'Anvigè apati 24 me 2019',
    body:
      'Mèsi dèske ou vizite FloodHelpNY.org (“Sit” lan). Politik sou Vi Prive sa a aplike sèlman ak Sit lan, ki sou kontwòl Center for New York City Neighborhoods, Inc. (“nou menm”, “pa nou” oswa “nou”). Politik sou Vi Prive sa a konsène sèlman enfòmasyon nou ranmase atravè Sit lan. LÈ OU ITILIZE SIT LAN, OU BAY KONSANTMAN OU AK TÈM EPI KONDISYON POLITIK SOU VI PRIVE SA A E AK TRETMAN NOU FÈ AK ENFÒMASYON PÈSONÈL YO POU REZON KI DEKLARE ANBA YO. SI OU PA DAKÒ AK TÈM EPI KONDISYON POLITIK SOU VI PRIVE SA A, TANPRI PA ITILIZE SIT LAN.',
    specific_info:
      'Enfòmasyon presi konsènan pratik sou done nou yo detaye anba.',
    info_we_collect: 'Enfòmasyon Nou Ranmase yo: Pèsonèl e Ki Pa Pèsonèl',
    we_collect:
      'Nou ranmase de kalite enfòmasyon apati vizitè Sit lan: (1) Enfòmasyon Pèsonèl; e (2) Enfòmasyon ki Pa Pèsonèl, tankou adrès IP ou an oswa temwen yo.'
  },
  personal_info: {
    title: 'Enfòmasyon Pèsonèl',
    body:
      'Lè nou itilize tèm “Enfòmasyon Pèsonèl yo” nou vle di enfòmasyon nou gendwa ranmase ki pèmèt lòt moun idantifye oswa kontakte ou. Pa egzanp, nou gendwa ranmase adrès imèl ou si ou dakò resevwa bilten ak lòt enfòmasyon nan men nou. Nou gendwa asosye sa tou ak adrès ou oswa anplasman ou ak lòt enfòmasyon pèsonèl ou founi pou konfime ou kalifye pou resevwa sèvis nan men nou epi enfòmasyon nou founi yo ap gen plis chans pou yo apwopriye ak ou.',
    examples: 'Egzanp enfòmasyon pèsonèl Sit sa a ranmase: ',
    email: 'Imèl',
    address: 'Adrès',
    phone: 'Nimewo Telefòn',
    homeowner: 'Enfòmasyon ki lye ak pwopriyete byen ou an',
    no_obligation:
      'Pandan ou pa gen okenn obligasyon pou founi enfòmasyon nou mande yo, si ou refize fè sa, nou gendwa pa kapab founi ou sèten sèvis. Si ou pa vle nou ranmase Enfòmasyon Pèsonèl ou yo, tanpri pa founi nou l.'
  },
  non_personal_info: {
    title: 'Enfòmasyon ki Pa Pèsonèl yo',
    body:
      '“Enfòmasyon ki Pa Pèsonèl yo” ka enfòmasyon teknik oswa li ka enfòmasyon demografik, tankou laj ou, idantite seksyèl, kòd postal ou oswa lòt done jewolokalizasyon, oswa enterè ou yo. Enfòmasyon ki pa pèsonèl yo PA idantifye ou pèsonèlman. Men kèk egzanp Enfòmasyon ki Pa Pèsonèl ki ranmase apati Sit lan ak yon deskripsyon sou kijan enfòmasyon sa a sèvi:’',
    ip:
      'Adrès Pwotokòl Entènèt (IP) – Adrès IP ou an se yon nimewo ki kite òdinatè ki konekte ak entènèt yo konnen kote pou yo voye done ba ou – tankou paj wèb ou ap gade yo. Nou itilize enfòmasyon sa a pou livre paj wèb nou yo ba ou sou demann, pou adapte Sit nou an ak enterè itilizatè nou yo epi pou mezire trafik sou Sit nou an.',
    web_beacons:
      'Baliz Wèb yo (yo rele yo tou “piksèl transparan,” “tripotay wèb” oswa “baliz piksèl”) – “Baliz Wèb yo” se ti grafik tou piti ki gen yon idantifyan apa, ki sanble ak temwen yo nan fonksyon yo, e yo sèvi pou pèmèt nou konte itilizatè ki vizite sèten paj Sit lan epi ede detèmine efikasite pwogram ak sèvis nou yo. Lè l sèvi nan mesaj imèl ki nan fòma HTML, baliz wèb yo ka di moun ki voye an si e kilè yo louvri yon imèl. Kontrèman ak temwen yo, ki estoke nan disk di òdinatè yon itilizatè, baliz wèb yo entegre yon fason envizib sou paj wèb yo.',
    demographic:
      'Enfòmasyon Demografik – “Enfòmasyon Demografik yo” gendwa se idantite seksyèl, laj, kòd postal ak enterè ou yo ki gendwa ranmase otomatikman atravè Sit lan. Nou itilize enfòmasyon sa a pou founi ou sèvis pèsonalize ak pou analize tandans yo pou asire enfòmasyon Sit lan founi satisfè bezwen ou yo. Tanpri remake nou konsidere tou enfòmasyon ki regwoupe yo, ki pa pèsonèlman idantifyab yo, se Enfòmasyon ki Pa Pèsonèl.',
    addresses:
      'Adrès Yo Fè Rechèch Sou Yo ak Enfòmasyon sou Zòn Inondasyon ki Koresponn yo - Nou ap anrejistre adrès ki tape nan zouti rechèch nou an ansanm ak rezilta enfòmasyon yo. Nou pral itilize enfòmasyon sa a nan yon nivo kolektif pou evalye enterè itilizatè nan zòn jewografik anpatikilye ansanm ak rezilta global risk pou inondasyon nou jwenn yo. Enfòmasyon sa a pral sèvi pou evalye efikasite vilgarizasyon nou ak estrateji pwomosyon sit entènèt nou yo ansanm ak pou pwochen pwojè rechèch yo.',
    above_list:
      'Lis ki anwo an founi egzanp sou Enfòmasyon ki Pa Pèsonèl ki ranmase apati Sit lan. Nou gendwa ranmase Enfòmasyon ki Pa Pèsonèl ki anwo an apati tout vizitè sou Sit nou an. Si ou pa vle nou gen aksè ak enfòmasyon sa a, tanpri pa vizite Sit nou an.'
  },
  use_of_information: {
    title: 'Itilizasyon Enfòmasyon yo Ranmase Apati Sit lan',
    personal_info: 'Enfòmasyon Pèsonèl',
    personal_info_body:
      'Nou itilize Enfòmasyon Pèsonèl ou yo nou ranmase sou Sit lan dabò pou objektif ki suiv yo la:',
    deliver_services: 'Pou livre sèvis ba ou',
    provide_info:
      'Pou founi ou enfòmasyon konsènan pwogram ak sèvis nan zòn ou',
    verify_email: 'Pou verifye adrès imèl ou',
    answer_questions: 'Pou reponn kesyon ou yo ak lòt demann',
    contact: 'Pou kontakte ou konsènan itilizasyon Sit lan oswa sèvis nou yo',
    notify_about_changes:
      'Pou avize ou sou chanjman materyèl nan Politik sou Vi Prive sa a oswa Sèvis nou yo, si sa nesesè',
    administer:
      'Pou sinon administre Sit lan oswa entèraksyon ou ak sèvis nou yo',
    other_purposes:
      'Pou tout lòt objektif ki revele nan moman ou founi Enfòmasyon Pèsonèl ou yo',
    non_personal_info: 'Enfòmasyon ki Pa Pèsonèl yo',
    non_personal_is_used:
      'Enfòmasyon ki Pa Pèsonèl yo sèvi jan sa dekri anwo an e nan lòt fason lwa aplikab yo pèmèt sa, ansanm ak konbinezon Enfòmasyon ki Pa Pèsonèl ak Enfòmasyon Pèsonèl yo.',
    non_personal_example:
      'Pa egzanp, nou gendwa itilize Enfòmasyon ki Pa Pèsonël ak done sou itilizasyon yo pou (i) kreye analiz maketing ak rapò ki montre fason itilizatè nou yo, antanke gwoup kolektif, entèraji ak sèvis nou yo, (ii) devlope rapò ki ede nou ak lòt tyès pati estratejik detèmine kisa ki enterese itilizatè sèvis nou yo, antanke yon gwoup, oswa (iii) jeneralman pou apwouve pwogram ak òf sèvis nou yo. Rapò sa yo ap pataje ak Center for New York City Neighborhoods, epi tyès pati k ap ede nou administre Sit sa a ak sèvis nou yo. Toutfwa, rapò sa yo pa p gen okenn Enfòmasyon Pèsonèl sou ou.'
  },
  sharing_and_disclosure: {
    title: 'Pataje epi Revele Enfòmasyon',
    personal_info: 'Enfòmasyon Pèsonèl',
    personal_examples:
      'Egzanp sou fason nou gendwa pataje oswa revle Enfòmasyon Pèsonèl ou an gen ladan l sa ki suiv yo la:',
    fulfill:
      'Pou rann ou yon sèvis. Pa egzanp, si ou mande asistans nan men nou ak/oswa aplike Odit pou Tolerans lan, nou gendwa pataje Enfòmasyon Pèsonèl nan lide pou founi sèvis ba ou. Anplis, si ou kontakte nou oswa ou anrejistre pou ou resevwa bilten nou an, nou gendwa itilize adrès imèl ou pou reponn ak demann ou an.',
    affiliates_home_resiliency:
      'Bay afilye, soukontraktè, patnè estratejik, ajan, otorite federal ki aplikab, Biwo Gouvènè an pou Retablisman apre Tanpèt oswa moun li nonmen yo, oswa lòt pati ki afilye ki ap ofri sèvis antanke Odit pou Tolerans Kay lan. Pati sa yo gendwa itilize Enfòmasyon Pèsonèl ou yo pou kontakte ou parapò ak Odit pou Tolerans Kay lan e yo gendwa itilize enfòmasyon sa a pou pwòp rezon rechèch, administrasyon oswa komèsyal yo. Si ou pa vle nou pataje Enfòmasyon Pèsonèl ou yo nan fason sa a, tanpri pa founi nou enfòmasyon sa a.',
    affiliates_other:
      'Pou afilye, asosye estratejik, ajan oswa lòt pati ki pa afilye ki ap ofri pwogram oswa sèvis nou kwè ki gendwa enterese ou oswa ki egzije Enfòmasyon Pèsonèl ou yo pou rezon rechèch, administratif ak/oswa rezon komèsyal entèn. Pati sa yo gendwa itilize Enfòmasyon Pèsonèl ou yo pou kontakte ou ak yon òf ki lye ak yon pwogram oswa sèvis oswa yo gendwa itilize enfòmasyon sa a pou pwòp rezon rechèch, administrasyon oswa komèsyal yo. Si ou pa vle nou pataje Enfòmasyon Pèsonèl ou yo nan fason sa a, tanpri pa founi nou enfòmasyon sa a.',
    unaffiliated:
      'Pou founisè sèvis tyès pati, ajan, kontraktyèl endepandan ki pa afilye ki ede nou fè antretyen Sit nou an epi founi lòt sèvis administratif ban nou (ansanm ak, men l pa limite ak, tretman demann ou yo, founi sèvis kliyan, kenbe epi analize done, epi voye kominikasyon kliyan nan non nou). Nou chèche asire tyès pati sa yo ki pa afilye pa p itilize Enfòmasyon Pèsonèl yo pou okenn lòt rezon apa pou founi sèvis administratif yo responsab yo. Akoz founisè sèvis tyès pati sa yo ki pa afilye yo ki ede nou adminstre Sit nou an ap gen aksè ak Enfòmasyon Pèsonèl itilizatè yo, si ou pa swete founisè sèvis tyès pati nou yo ki pa afilye yo gen aksè ak enfòmasyon ou yo, tanpri pa enskri oswa soumèt okenn Enfòmasyon Pèsonèl ban nou.',
    comply_with_law:
      'Pou konfòme nou ak lalwa oswa nan bon kwayans yon aksyon konsa nesesè nan lide pou konfòme nou ak egzijans lalwa yo oswa konfòme nou ak yon pwosesis jiridik yo sinyifye nou, pwoteje epi defann dwa nou yo oswa pwopriyete nou, Sit lan oswa aji nan sikonstans ijan pou pwoteje sekirite pèsonèl itilizatè final nou yo.',
    third_part_corp:
      'Pou tyès pati nan kad tout pwosesis reyòganizasyon antrepriz ansanm ak, men san l pa limite ak, fizyon, akizisyon ak vant tout oswa prèske tout aktif nou yo.',
    track_and_analyze:
      'Pou retrase epi analize enfòmasyon sou itilizasyon ak volim ki pa idantifye e ki regwoupe yo sou vizitè ak kliyan yo epi founi enfòmasyon sa yo bay tyès pati.',
    fraud_protection:
      'Pou pwoteje nou kont potansyèl fwod, nou gendwa verifye ak tyès pati enfòmasyon yo nou ranmase sou Sit la. Pandan dewoulman verifikasyon sa a, nou gendwa resevwa Enfòmasyon Pèsonèl sou ou apati sèvis sa yo.',
    personal_footer:
      'Eksepte jan sa dekri nan Politik sou Vi Prive sa a oswa nan moman nou mande enfòmasyon an, nou pa itilize, pataje oswa sinon divilge Enfòmasyon Pèsonèl ou yo bay nenpòt tyès pati k ap ede nou administre Sit lan oswa sèvis yo. Si ou pa bay konsantman pou Enfòmasyon Pèsonèl ou yo divilge jan sa diskite anwo an, tanpri pa founi nou l.',
    non_personal_info: 'Enfòmasyon ki Pa Pèsonèl yo',
    non_personal_content:
      'Nou itilize Enfòmasyon ki Pa Pèsonèl ki ranmase sou Sit lan nan fason ki revele anwo an, nan Seksyon B (2). Nou gendwa pataje Enfòmasyon ki Pa Pèsonèl sa a ak tyès pati k ap ede nou administre Sit lan oswa sèvis nou yo.',
    children_under_13: 'Timoun ki Gen Mwens Pase 13 Lane yo',
    children_under_13_content:
      'Sit lan pa sible timoun ki gen mwens pase 13 lane yo oswa konsyan li ranmase Enfòmasyon Pèsonèl nan men moun konsa. Si nou vin okouran yon timoun ki gen mwens pase 13 lane te founi nou Enfòmasyon Pèsonèl, nou pral fè demach pou retire enfòmasyon konsa.',
    opt_out: 'Renonsiyasyon',
    opt_out_content:
      'Ou gendwa renonse pou resevwa mesaj pwomosyon nan men nou oswa tyès pati estratejik nenpòt kilè. Pou imèl, ou ka renonse lè ou al nan lyen yo bay anba tout imèl ou resevwa nan men nou epi dezenskri pou resevwa enfòmasyon nan men nou. Nou ap trete dezenskripsyon ou an pi rapid sa posib, men tanpri konnen sa ka rive ou resevwa kèk mesaj ankò jouk dezenskripsyon an trete. Kounya sa klè, tanpri remake dezabone ak imèl pwomosyon yo pa p dezenskri ou nan imèl tranzaksyon oubyen imèl nou blije voye yo.',
    third_parties: 'Divilgasyon Ou bay Tyès Pati',
    third_parties_content:
      'Si ou reponn ak nenpòt piblisite entèraktif swa òf yon tyès pati fè (kit apati yon imèl, mesaj oswa yon lòt fason), oswa si ou mande enfòmasyon sou yon pwodui, pwogram oswa sèvis yon tyès pati ofri, eben Enfòmasyon Pèsonèl ou yo ap divilge bay tyès pati sa a. Nou pa ka kontwole epi fè deklarasyon sou ranmasaj oswa itilizasyon enfòmasyon ou yo bay tyès pati konsa. Si ou chwazi enskri oswa abone ak pwodui oswa sèvis tyès pati sa a, tout lòt korespondans ant ou menm ak tyès pati sa a ansanm ak, san l pa kanpe la, tout rezilta antant kontraktyèl ki vin genyen, se sèlman ant ou menm ak piblisitè/tyès pati an. Nou pa p responsab defann okenn tèm, kondisyon, deklarasyon oswa garanti ki aplikab piblisitè/tyès pati an fè anrapò ak sa e nou pa p sinon gen okenn responsablite ki asosye ak entèraksyon ou ak tyès pati sa a.',
    correct_inaccuracies:
      'Kijan Ou Ka Korije Inegzaktitid nan Enfòmasyon Pèsonèl Ou yo?',
    correct_inaccuracies_body:
      'Si ou gen enkyetid konsènan egzaktitid enfòmasyon nou gendwa te ranmase yo sou ou e ou ta renmen gen aksè ak enfòmasyon sa yo, tanpri kontakte nou nan ',
    correct_inaccuracies_body_email: 'cnycn.org.',
    info_protected: 'Kijan Enfòmasyon Ou yo Pwoteje?',
    info_protected_body:
      'Nou konsidere konfidansyalite ak sekirite enfòmasyon ou yo trè enpòtan. Nou itilize mezi sekirite fizik, teknik ak administratif pou kenbe Enfòmasyon Pèsonèl ou yo sekirize epi konfidansyèl e nou pa p founi l bay okenn tyès pati eksepte bay antite sa yo k ap ede nou administre Sit lan ak sèvis nou yo jan lalwa egzije sa oswa sinon jan l dispoze nan Politik sou Vi Prive sa a. Toutfwa, entènèt lan pa yon anviwonnman sekirize, e nou pa ka 100% garanti sekirite enfòmasyon ou yo; gen yon sèten risk pou yon tyès pati ki pa otorize gendwa jwenn yon fason pou kontoune mezi sekirite nou yo oswa enfòmasyon sa a ki transmèt sou entènèt lan gendwa entèsepte.',
    info_protected_body_2:
      'Si nou aprann gen yon fay nan sistèm sekirite an, nou gendwa tante avize ou elektwonikman pou ou ka fè demach pwoteksyon ki apwopriye yo. Lè ou itilize Sit lan oswa founi Enfòmasyon Pèsonèl ban nou ou dakò nou ka kominike ak ou elektwonikman konsènan sousi sekirite, vi prive ak administratif ki lye ak itilizasyon ou fè ak Sit nou an. Nou gendwa afiche yon avi sou Sit nou an si gen yon fay nan sekirite an. Nou gendwa voye yon imèl ba ou nan adrès imèl ou te ban nou an nan sikonstans sa yo oswa nan adrès ou te bay lan. Toudepan kote ou ap viv, ou gendwa gen yon dwa legal pou resevwa avi sou yon fay sekirite alekri.',
    third_party_sites: 'Sit Entènèt Tyès Pati',
    third_party_sites_body:
      'Deklarasyon sa a aplike sèlman ak enfòmasyon ki ranmase sou Sit lan. Sit lan gendwa gen lyen pou al sou lòt sit entènèt. Nou pa responsab pratik sou vi prive yo oswa kontni lòt sit entènèt sa yo.',
    do_not_track: 'Kijan pou Nou Reyaji ak Siyal pou Pa Suiv yo?',
    do_not_track_body:
      'Tanpri remake Sit nou an pa sipòte paramèt navigatè “Pa Suiv” yo e li pa patisipe aktyèlman nan okenn estrikti “Pa Suiv” ki ta ka pèmèt nou reyaji ak sinyal oswa lòt mekanis ki sot bò kote ou konsènan ranmasaj Enfòmasyon Pèsonèl e Enfòmasyon ki Pa Pèsonèl ou yo.',
    assignment: 'Atribisyon',
    assignment_body:
      'Nan ka tout oswa yon pati nan aktif nou yo vann oswa yon tyès pati achte yo, oswa nan ka gen yon fizyon, ou ban nou dwa pou sede Enfòmasyon Pèsonèl e Enfòmasyon ki Pa Pèsonèl nou te ranmase yo apati Sit lan.',
    changes: 'Chanjman nan Politik sou Vi Prive sa a',
    changes_body:
      'Nou rezève nou dwa pou modifye Politik sou Vi Prive sa a pandan sèvis nou yo ap grandi epi amelyore oswa pou reyaji ak reglemantasyon jiridik oswa enkyetid biznis k ap chanje yo. Lè nou fè sa, nou pral revize tou dat li “antre anvigè” an anlè Politik sou Vi Prive sa a. Pou chanjman nan Politik sou Vi Prive sa a ki gendwa gen yon ti kras mwens restriksyon sou itilizasyon oswa divilgasyon Enfòmasyon Pèsonèl ou te founi nou yo, nou pral tante jwenn konsantman ou anvan nou adopte chanjman an nan voye yon avi nan adrès imèl prensipal nou gen nan dosye nou yo oswa nan plase yon avi moun ka byen wè sou Sit lan.'
  },
  questions: {
    title: 'Ou gen kesyon?',
    body:
      'Pou kesyon konsènan Politik sou Vi Prive sa a, tanpri voye yon imèl bay ',
    email: 'info@floodhelpny.org.'
  }
};
