export default {
  genericError: {
    title: 'Error',
    error: 'Lo sentimos. No hemos podido encontrar información con respecto a la dirección que ingresó. Por favor, verifique la dirección e inténtelo nuevamente.'
  },
  multipleResultsError: {
    title: 'Error',
    error: 'Lo sentimos. Hemos encontrado varias coincidencias con respecto a la dirección que ingresó. Por favor, verifique la dirección e inténtelo nuevamente.'
  },
  outsideNycError: {
    title: 'Error',
    error: 'Lo sentimos. Este sitio solamente contiene información relacionada con la Ciudad de Nueva York.'
  },
  tooBroadError: {
    title: '¿Puede Ud. ser más específico?',
    error: 'FloodHelpNY.org provee información sobre el seguro contra las inundaciones y opciones de resiliencia individualizada. Ponga una dirección de su casa o la dirección de otra casa de la quiere obtener información. O, si piensa que ha recibido este mensaje por error, por favor llame a nuestro Homeowner Hub al 646-786-0888.'
  },
  noRouteError: {
    title: 'Error',
    error: 'Lo sentimos. Esta dirección no coincide con los datos disponibles públicamente de Google Maps. Si cree que recibió este mensaje por error, llame a nuestro Centro de propietarios de viviendas al 646-786-0888.'
  }
};
