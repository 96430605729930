export default {
  header: 'Fason pou bese tarif ou',
  link: 'Aprann sou adousisman',
  assumingMax: '*Nou sipoze yon kouvèti maksimòm',
  numbersAreEstimates:
    'Chif sa yo se estimasyon pou anpil rezon, pou aprann plis sou yo ale sou ',
  termsAndConditions: 'Tèm ak Kondisyon yo',
  noMitigation: 'Dezole, nou pa t ka idantifye okenn opsyon',
  elevateBuilding: {
    header: 'Elve kay ou an',
    body: 'Mete kay ou an totalman anwo nivo dlo inondasyon ki prevwa.',
    link: 'Aprann Plis',
    mitigationDifference: {
      currentHeader: 'Pou zòn aktyèl ou an',
      futureHeader: 'Pou zòn konsiltasyon ou an',
      saved: 'ekonomize',
      perYear: ' /ane*',
      nonpositiveSavingsCopy: 'Sa pa p bese tarif ou.'
    }
  },
  abandonFirstFloor: {
    header: 'Abandone premye etaj ou an',
    body: 'Sa elve nan yon fason efikas elevasyon debaz kay ou an.',
    link: 'Aprann Plis',
    mitigationDifference: {
      currentHeader: 'Pou zòn aktyèl ou an',
      futureHeader: 'Pou zòn konsiltasyon ou an',
      saved: 'ekonomize',
      perYear: ' /ane*',
      nonpositiveSavingsCopy: 'Sa pa p bese tarif ou.'
    }
  },
  elevateMechanicals: {
    header: 'Elve sistèm mekanik ou yo',
    body: 'Pwoeje sistèm kritik kay ou an.',
    link: 'Aprann Plis',
    mitigationDifference: {
      currentHeader: 'Pou zòn aktyèl ou an',
      futureHeader: 'Pou zòn konsiltasyon ou an',
      saved: 'ekonomize',
      perYear: ' /ane*',
      nonpositiveSavingsCopy: 'Sa pa p bese tarif ou.'
    }
  },
  floodVents: {
    header: 'Enstale vantilasyon kont inondasyon',
    body:
      'Redui risk pou domaj kont dlo inondasyon lè li pèmèt li koule epi seche.',
    link: 'Aprann Plis',
    mitigationDifference: {
      currentHeader: 'Pou zòn aktyèl ou an',
      futureHeader: 'Pou zòn konsiltasyon ou an',
      saved: 'ekonomize',
      perYear: ' /ane*',
      nonpositiveSavingsCopy: 'Sa pa p bese tarif ou.'
    }
  },
  fillInBasement: {
    header: 'Ranpli sousòl ou an',
    body: 'Redui domaj yo nan fondasyon estrikti kay ou an.',
    link: 'Aprann Plis',
    mitigationDifference: {
      currentHeader: 'Pou zòn aktyèl ou an',
      futureHeader: 'Pou zòn konsiltasyon ou an',
      saved: 'ekonomize',
      perYear: ' /ane*',
      nonpositiveSavingsCopy: 'Sa pa p bese tarif ou.'
    }
  }
};
