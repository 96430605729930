import React from 'react';
import PropTypes from 'prop-types';
import ListWithIconAndText from './layout/ListWithIconAndText';
import FloodZones from '../constants/FloodZones';
import { useProvider } from '../helpers/hooks';
import { Section } from './ui/StyledResourcePageElements';

const ProfileWhatToKnow = ({ property }) => {
  const provider = useProvider('profileWhatToKnow');

  const renderItems = () => {
    if (property.small_business) {
      return [
        provider.whatToKnowSmallBusinessItems.rain,
        provider.whatToKnowSmallBusinessItems.smallBusiness,
        provider.whatToKnowSmallBusinessItems.understandOptions
      ];
    }

    if (property.future_flood_zone === FloodZones.NONE) {
      return [
        provider.whatToKnowHomeownerItems.rain,
        provider.whatToKnowHomeownerItems.contentsInsurance,
        provider.whatToKnowHomeownerItems.understandOptions
      ];
    }

    return [
      provider.whatToKnowHomeownerItems.increasingPremiums,
      provider.whatToKnowHomeownerItems.legacyRate,
      provider.whatToKnowHomeownerItems.currentRate
    ];
  };

  return (
    <Section>
      <h5>{provider.header}</h5>
      <ListWithIconAndText items={renderItems()} />
    </Section>
  );
};

ProfileWhatToKnow.propTypes = {
  property: PropTypes.object.isRequired
};

export default ProfileWhatToKnow;
