import sortingOptions from '../images/sorting-options.svg';
import structural from '../images/structural.svg';
import wrench from '../images/wrench.svg';
import speechBubbles from '../images/speech-bubbles.svg';

export default {
  header: '预期结果',
  list: [
    {
      header: '评估',
      imageAlt: 'building',
      body:
        '一家有资质的工程公司会对房屋进行评估，并出具一份弹性报告，概述了您可以进行哪些方面的改造在洪水灾害中确保安全。',
      image: structural
    },
    {
      header: '讨论',
      imageAlt: 'speech bubbles',
      body:
        '我们将安排时间让您与弹性顾问交谈，他可以指导您完成报告和选项。',
      image: speechBubbles
    },
    {
      header: '审核',
      imageAlt: 'controls',
      body:
        '我们将上传您的报告和标高证书进行审核，让您决定采取的步骤。',
      image: sortingOptions
    },
    {
      header: '行为',
      imageAlt: 'wrench',
      body:
        '您的报告将包括可以加固房屋的所有方法，我们可以帮助您对接到所需的任何资源。',
      image: wrench
    }
  ]
};
