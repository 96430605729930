import handsHeart from '../images/hands-heart.png';
import buildingFlood from '../images/building-flood.png';
import floodNet from '../images/flood-net.png';
import homeAutomation from '../images/home-automation.svg';
import rainCloud from '../images/rain-cloud.png';
import storm from '../images/storm.svg';
import stormwaterFlooding from '../images/stormwater-flooding.svg';
import tree from '../images/tree.png';
import streetFlood from '../images/street-flood.jpeg';

export default {
  meta: {
    title: 'Cambio Climático en NYC | Flooding | FloodHelpNY',
    url: 'https://www.floodhelpny.org/es/climate-change',
    description:
      'El Cambio climático ya ha empeorado las inundaciones en la ciudad de Nueva York. Esto es lo que necesita saber.',
    imageAlt: 'Cambio Climático'
  },
  title: 'Cambio Climático',
  subHeading:
    'El Cambio climático ya ha empeorado las inundaciones en la ciudad de Nueva York. Esto es lo que necesita saber.',
  overview: {
    header: 'Panorama',
    body:
      'El nivel del mar en la ciudad de Nueva York está incrementando casi el doble de rápido que el promedio mundial. Feroces huracanes provenientes del Océano Atlántico amenazan con impactar la costa este del país cada año. El incremento de la frecuencia de grandes tormentas puede en ocaciones producir inundaciones destructivas o hasta letales. Estos patrones climáticos extremos significan que ciudades como la nuestra deben de aprender a prepararse y adaptarse a la nueva normalidad de la crisis climática.',
    aside: {
      body:
        'No todos son afectados por la crisis climática de la misma manera. Es más probable que las comunidades de color sufran la peor parte de los efectos del aumento del nivel del mar y las inundaciones.',
      icon: buildingFlood,
      iconAlt: 'Edificio inundandose'
    },
    image: streetFlood,
    imageAlt: 'Calles inundadas de la ciudad de Nueva York',
    imageCaption:
      'Cortesía del Proyecto Comunitario de Vigilancia de Inundaciones'
  },
  howClimateChangeContributes: {
    header:
      '¿Cómo contribuye el cambio climático al aumento de las inundaciones?',
    body: {
      p1:
        'Alrededor del mundo, el aumento del nivel del mar seguirá incrementando la frecuencia e intensidad de las inundaciones costeras. Las inundaciones son consideradas como uno de los desastres naturales más comunes y costosos, matando a más personas cada año que tornados. A medida que se espera que el cambio climático empeore, también lo harán las inundaciones en nuestras comunidades.',
      p2:
        'Así es como el cambio climático está afectando las inundaciones en Nueva York:',
      p3:
        'Estas no son de ninguna manera las únicas conexiones entre el cambio climático y las inundaciones, pero son fundamentales para entender cómo proteger nuestros hogares, familias y vecindarios.'
    },
    items: [
      {
        header: 'Debido al calentamiento global, cuando llueve, llueve más',
        body:
          'Desde 1900, la Tierra se ha vuelto un 4 por ciento más húmeda, con la mitad este de los Estados Unidos siendo la más afectada.',
        image: rainCloud,
        imageAlt: 'Nube lloviendo'
      },
      {
        header: 'Tormentas más fuertes traen mayores lluvias y vientos',
        body:
          'Los tipos de huracanes más destructivos están aumentando en frecuencia, incluso en el Océano Atlántico.',
        image: storm,
        imageAlt: 'Tormenta eléctrica'
      },
      {
        header: 'Aumento del nivel del mar',
        body:
          'Proyecciones muestran que la costa Este podría experimentar aumentos del nivel del mar hasta por más de 6.5 pies para el año 2100.',
        image: stormwaterFlooding,
        imageAlt: 'Inundaciones por tomermantas'
      }
    ]
  },
  climateCrisis: {
    title: 'Acción climática es posible',
    body:
      'El proyecto de Vigilancia de Inundaciones Comunitaria, es una red creciente de residentes y organizaciones quienes reportan inundaciones locales y comparten experiencias y acceso a recursos relacionados con inundaciones costeras en la ciudad de Nueva York. Las comunidades pueden usar esta base de datos publica de imágenes, reportes y mapas para comunicar sus necesidades y visiones a los lideres de la ciudad. ¡Tú también puedes ser parte de este proyecto!',
    view: 'Involucrate',
    image: handsHeart,
    imageAlt: 'Coastal Climate Resiliency Guide'
  },
  climateChangeFlooding: {
    header:
      'Las inundaciones ocasionadas por el cambio climático afectan a la ciudad de manera desigual',
    body:
      'En todo el país, las comunidades que más han sido discriminadas y marginadas a través de practicas de zonificación y prestamos abusivos, también han tenido un mayor riesgo de ser expuestos a aumentos del nivel del mar por el cambio climático y a inundaciones intensas. De acuerdo a RedFin, el 13.8% de los hogares ubicados en zonas previamente marginadas y zonificadas, con la mayoría de los residentes siendo personas de color, corren un mayor riesgo de inundación que otros vecindarios en la ciudad de Nueva York. Inversiones en infraestructura como alcantarillados para combatir inundaciones, eran más prevalentes en las comunidades prósperas de la ciudad de Nueva York, en comparación a zonas previamente marginadas. El Congreso y la Agencia de Protección Ambiental (EPA) continúan trabajando para atacar estos problemas que afectan desproporcionadamente a comunidades de color, al garantizar que se logre la justicia ambiental para todas las personas, independientemente de su raza, género e ingresos.'
  },
  severeHurricanes: {
    header: 'De huracanes severos a lluvia extrema',
    body: {
      p1:
        'Los residentes de la cuidad de Nueva York han sido afectados por tormentas severas como resultado del aumento del cambio climático, incluyendo una tormenta unica en la vida como el huracán Sandy en 2012 y recientemente los huracanes Henri e Ida en el 2021. Durante el huracán Ida, el Servicio Metereológico Nacional emitió su primera “emergencia de inundación repentina” para los cinco condados. Al menos 40 personas en el noreste murieron a causa de la tormenta, incluyendo a 13 personas en la ciudad de Nueva York, la mayoría en sótanos inundados.',
      p2:
        'Poco después de la tormenta, un comunicado emitido por la oficina del alcalde destacó la conexión entre las lluvias extremas y el cambio climático: “Cada vez más, estos eventos climáticos extremos son la nueva normalidad: parte de una innegable crisis climática que se extiende por toda nuestra nación… Está aquí, es real y está cobrando vidas” escribieron los autores del informe.',
      p3:
        'Mientras que la Oficina de Justicia Climática y Ambiental de la Alcaldía y sus compañeros de la agencia de la ciudad continúan con sus esfuerzos para movilizar a las comunidades y prepararse para tormentas más severas, todos debemos proteger nuestros vecindarios y hogares.'
    },
    aside: {
      body:
        'FloodNet es una colaboración de comunidades, investigadores, y agencias gubernamentales de la ciudad de Nueva York que trabajan para entender mejor la frecuencia, severidad, e impactos que las inundaciones tienen en la ciudad. Los datos y el conocimiento adquirido puede ser usado por residentes, investigadores, agencias de la ciudad entre otros para alojar y trabajar para reducir el riesgo de inundación.',
      link: 'Visite FloodNet',
      icon: floodNet,
      iconAlt: 'Icono de Floodnet.nyc'
    },
    callToAction: {
      header:
        'Conozca cómo puede tomar medidas para proteger su propiedad a través de remodelaciones',
      icon: homeAutomation,
      iconAlt: 'Remodelacion hogar',
      buttonText: 'Lea más'
    }
  }
};
