export default {
  title: 'Acepto los Términos y condiciones',
  disclaimerOne: 'No podemos garantizarle la exactitud de su zona',
  disclaimerTwo:
    'Es posible que la estimación de su seguro contra inundaciones sea inexacta.',
  links: {
    sentenceOpener: 'Más información en nuestros ',
    termsLinkText: 'Términos y condiciones',
    termsLinkUrl: '/es/terms',
    conjunction: ' y ',
    privacyLinkText: 'Normativa de privacidad',
    privacyLinkUrl: '/es/privacy',
    submit:
      'Al hacer clic en enviar estás de acuerdo con nuestros términos y condiciones'
  },
  navigation: {
    agree: 'Acepto',
    disagree: 'No acepto'
  }
};
