export default class BuildingTypes {
  static get BUNGALOW() {
    return 'bungalow';
  }

  static get DETACHED() {
    return 'detached';
  }

  static get SEMI_DETACHED() {
    return 'semi-detached';
  }

  static get ATTACHED() {
    return 'attached';
  }

  static get MID_RISE_WALK_UP() {
    return 'mid-rise-walk-up';
  }

  static get MID_RISE_ELEVATOR() {
    return 'mid-rise-elevator';
  }

  static get UNKNOWN() {
    return 'unknown';
  }
}
