import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { darken } from 'polished';
import { colors } from '../../styles/variables';

const DefaultButton = styled.button`
  display: inline-flex;
  padding: 0px 40px;
  height: 60px;
  line-height: 60px;
  font-size: 1rem;
  font-family: GT-Walsheim-Pro-Light;
  border-radius: 3px;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
  color: white;
  background-color: ${(props) => props.bgColor};
  border: none;
  outline-style: none;
  transition: background-color 0.25s cubic-bezier(0.52, 0.01, 0.16, 1),
    transform 0.1s cubic-bezier(0.52, 0.01, 0.16, 1);

  &:active {
    transform: translateY(2px);
  }

  &:hover {
    background-color: ${(props) => darken(0.1, props.bgColor)};
  }

  &:disabled {
    background-color: ${colors.grayScale[1]};
    cursor: not-allowed;
  }
`;

const GhostButton = styled(DefaultButton)`
  border: 1px solid ${(props) => props.bgColor};
  color: ${(props) => props.bgColor};
  background-color: transparent;

  &:hover {
    background-color: transparent;
    border: 1px solid ${(props) => darken(0.1, props.bgColor)};
    color: ${(props) => darken(0.1, props.bgColor)};
  }

  &:disabled {
    background-color: transparent;
    border: 1px solid ${colors.grayScale[1]};
    color: ${colors.grayScale[1]};
  }
`;

const Button = ({ onClick, bgColor, ghost, className, children, disabled }) => (
  <DefaultButton
    bgColor={bgColor}
    as={ghost && GhostButton}
    className={className}
    onClick={onClick}
    disabled={disabled}
  >
    {children}
  </DefaultButton>
);

Button.propTypes = {
  onClick: PropTypes.func,
  bgColor: PropTypes.string,
  ghost: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool
};

Button.defaultProps = {
  bgColor: colors.sourApple,
  ghost: false,
  className: '',
  children: '',
  disabled: false
};

export default Button;
