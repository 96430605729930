import FloodZones from '../../constants/FloodZones';
import BuildingFeatures from '../../constants/BuildingFeatures';
import MultifloorHelper from './MultifloorHelper';
import FloodZoneSeverityHelper from './FloodZoneSeverityHelper';

export default class NumberOfStepsNavigator {
  static nextComponent(results) {
    const lessSevereZone = FloodZoneSeverityHelper.getLessSevereZone(results);
    const moreSevereZone = FloodZoneSeverityHelper.getMoreSevereZone(results);

    if (
      lessSevereZone === FloodZones.VE ||
      (moreSevereZone === FloodZones.VE &&
        (lessSevereZone === FloodZones.X || lessSevereZone === FloodZones.NONE))
    ) {
      return null;
    }

    if (results.buildingFeature === BuildingFeatures.MOBILE_HOME) {
      if (moreSevereZone === FloodZones.VE) {
        return 'elevatedBuilding';
      }
      return null;
    }
    if (
      results.buildingFeature === BuildingFeatures.NO_BASEMENT_ENCLOSURE ||
      results.buildingFeature ===
        BuildingFeatures.ELEVATED_ON_CRAWLSPACE_FLOOD_VENTS
    ) {
      if (MultifloorHelper.isMultifloor(results)) {
        if (moreSevereZone === FloodZones.VE) {
          return 'elevatedBuilding';
        }
        return null;
      }
      return 'numberOfFloors';
    }
    return 'basement';
  }
}
