import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Matchers from '../../constants/Matchers';
import { colors, borderRadius } from '../../styles/variables';
import { InputVisibilityWrapper } from './StyledInput';

const Container = styled.div`
  display: flex;
  align-items: center;

  label {
    margin: 1rem 1rem 1rem 0;
    width: 25%;

    @media (max-width: 680px) {
      width: 100%;
    }
  }

  input {
    display: inline-block;
    margin: 0;
    background-color: #fff;
    border: 1px solid ${colors.grayScale[1]};
    font-size: 1rem;
    font-weight: 500;
    border-radius: ${borderRadius};
    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease;
    overflow: hidden;
    padding: 1rem 3rem;
    width: 50%;

    :required {
      border: 1px solid ${colors.pinkBerry};
    }

    @media (max-width: 680px) {
      margin-bottom: 1rem;
      width: 100%;
    }
  }

  @media (max-width: 680px) {
    flex-direction: column;
    align-items: start;
  }
`;

const AuditApplicationDefaultInput = ({
  responseField: { placeholder, type, title, show, required },
  answer,
  setAnswers,
  index,
  questionKey,
  answers
}) => {
  const [isAnswerInvalid, setIsAnswerInvalid] = useState(required);

  useEffect(() => {
    // When the answer is invalid the input field is required. When the answer
    // has passed validation the input field is no longer required.
    if (required) {
      if (type === 'email') {
        // Email inputs have extra validation
        answer
          ? setIsAnswerInvalid(answer.search(Matchers.email) === -1)
          : setIsAnswerInvalid(required);
      } else {
        // Other text inputs just have a presence check
        answer ? setIsAnswerInvalid(!answer) : setIsAnswerInvalid(required);
      }
    }
  }, [answer, required, type]);

  const setResponse = (e) => {
    const newAnswersArray = [...answers];
    newAnswersArray[index][questionKey] = e.target.value;
    setAnswers(newAnswersArray);
  };

  return (
    <InputVisibilityWrapper show={show}>
      <Container>
        <label>{title}</label>
        <input
          type={type}
          placeholder={placeholder}
          onChange={(e) => setResponse(e)}
          min={0}
          value={answer}
          required={isAnswerInvalid}
        />
      </Container>
    </InputVisibilityWrapper>
  );
};

AuditApplicationDefaultInput.propTypes = {
  responseField: PropTypes.object.isRequired,
  answer: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]).isRequired,
  index: PropTypes.number.isRequired,
  questionKey: PropTypes.string.isRequired,
  setAnswers: PropTypes.func.isRequired,
  answers: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])
  ).isRequired
};

export default AuditApplicationDefaultInput;
