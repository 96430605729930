export default {
    meta: {
      title: 'FloodHelpNY Widget',
      url: 'https://www.floodhelpny.org/ru/widget-information',
      description:
        'Загрузите наш виджет FloodHelpNY сегодня и помогите вашим посетителям узнать об их риске наводнения'
    },
    header: 'FloodHelpNY Widget',
    subHeader:
      'Загрузите наш виджет FloodHelpNY сегодня и помогите вашим посетителям узнать об их риске наводнения',
    overview: {
      title: 'Обзор',
      subtitle: `Загрузите наш виджет FloodHelpNY сегодня и помогите вашим посетителям узнать об их риске наводнения. FloodHelpNY - это легко устанавливаемый безопасный виджет, который предоставит настоящим и будущим домовладельцам, арендаторам и владельцам малого бизнеса четкий путь узнать дополнительную информацию об их риске наводнения. Виджет FloodHelpNY предлагает посетителям возможность:`
    },
    widget: {
      title: 'THE WIDGET',
      aside: {
        description:
          'Виджет легко установить и он безопасен. Заполните предоставленную ниже форму, чтобы получить встроенный код и инструкции.'
      }
    },
    maps:
      '<b>Ваши предоставленные FEMA текущая и ориентировочная карты</b> для вашей нью-йоркской недвижимости',
    reflection: '<b>Получить отображение</b> риск наводнения для их недвижимости',
    required:
      '<b>Узнать, требуется ли им страхование от наводнения</b> если у них есть поддерживаемая на федеральном уровне ипотека',
    decisions:
      '<b>Принять информированное решение</b> стоит ли приобретать страхование от наводнения',
    request: {
      title: 'ЗАПРОСИТЬ ВИДЖЕТ',
      description:
        'При загрузке нашего виджета пользователей попросят заполнить нашу форму и указать их имя, название организации, и адрес электронной почты. Пользователей также попросят принять наши правила и условия.'
    },
    form: {
      name: 'Имя',
      namePlaceholder: 'Имя, фамилия',
      org: 'Организация',
      orgPlaceholder: 'Организация',
      email: 'Адоес электронной почты',
      emailPlaceholder: 'например name@example.com',
      terms: 'Я принимаю',
      termsLink: ' правила и условия',
      thankYou: 'Спасибо за подачу заявления, мы скоро свяжемся с вами.',
      errors: {
        nameError: 'Имя, фамилия обязательны для заполнения',
        orgError: 'Организация обязательна для заполнения',
        emailError: 'Адрес электронной почты обязателен для заполнения',
        termsError: 'Принятие правил и условий обязательно для заполнения',
        submitError:
          'При отправлении формы произошла ошибка. Попробуйте еще раз.'
      }
    }
  };
  