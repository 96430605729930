import React from 'react';

const ApiContext = React.createContext({
  buildingApi: '',
  parametricApi: '',
  salesforceApi: '',
  rateCalculatorApi: ''
});

export default ApiContext;
