export default {
  loadingText: 'Ap telechaje...',
  addressInput: {
    subHeading: '',
    placeholder: 'Antre adrès ou',
    action: 'Demare'
  },
  useAutocomplete: 'Tanpri chwazi yon adrès apati meni k ap dewoule an',
  lookupError: {
    title: 'Erè',
    error:
      'Tanpri verifye adrès lan oswa eseye ankò pita. Si ou santi ou te resevwa mesaj sa a nan erè, rele Platfòm pou Pwopriyetè nou an nan 646-786-0888.',
    tryAgain: 'Eseye ankò'
  },
  apiError: {
    title: 'Erè',
    error:
      'Nou dezole, sèvè nou yo pa kapa jwenn yon repons nan men sèvis verifikasyon adrès Google lan. Rele Platfòm pou Pwopriyetè Kay nou an nan 646-786-0888 si ou gen nenpòt kesyon.',
    tryAgain: 'Eseye ankò'
  },
  multipleResultsError: {
    title: 'Erè',
    error:
      'Nou dezole. Gen plizyè korespondans pou adrès ou te antre an. Tanpri verifye adrès lan oswa eseye ankò pita. Si ou santi ou te resevwa mesaj sa a nan erè, tanpri rele Platfòm pou Pwopriyetè nou an nan 646-786-0888.',
    tryAgain: 'Eseye ankò'
  },
  outsideNycError: {
    title: 'Erè',
    error:
      'Nou dezole. Adrès sa a pa parèt koresponn donen ki disponib piblikman apati NYC. Si ou santi ou te resevwa mesaj sa a nan erè, tanpri rele Platfòm pou Pwopriyetè nou an nan 646-786-0888.',
    tryAgain: 'Eseye Yon Lòt Adrès'
  },
  tooBroadError: {
    title: 'Èske ou ka pi presi?',
    error:
      'FloodHelpNY.org founi enfòmasyon sou asirans kont inondasyon epi opsyon pou tolerans baze sou adrès endividyèl. Tanpri antre adrès lakay ou oswa adrès yon lòt kay ou ta renmen chèche. Oswa, si ou santi ou te resevwa mesaj sa a nan erè, tanpri rele Platfòm pou Pwopriyetè Kay nou an nan 646-786-0888.',
    tryAgain: 'Eseye ankò'
  }
};
