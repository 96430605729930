export default {
  changeLinkText: 'chanje',
  currentMaps: 'Kat Aktyèl yo',
  futureMaps: 'Kat Konsiltatif',
  legend: {
    label: 'KLE:',
    minimalHazard: 'Risk Minimòm',
    moderateRisk: 'Risk Modere',
    highRisk: 'Gwo Risk',
    veryHighRisk: 'Pi Gwo Risk lan',
    floodingKey: ['Minimal hazard', 'Moderate risk', 'High Risk', 'Highest risk (flooding & waves)'],
    stormwaterKey: ['Moderate stormwater flooding hazard', 'High stormwater flooding hazard'],
    copy:
      'Kat lan montre zòn kat aktyèl ak konsiltatif yo pou Vil New York. Sous: ',
    linkCopy: 'FEMA',
    linkUrl: 'https://www.fema.gov'
  },
  loadingText: 'Ap telechaje...'
};
