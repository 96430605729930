import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import RateDisplay from './RateDisplay';
import RateCalculatorInputFormatter from '../../helpers/RateCalculatorInputFormatter';
import FloodZones from '../../constants/FloodZones';

export default class FloodZoneRate extends Component {
  static propTypes = {
    api: PropTypes.string.isRequired,
    provider: PropTypes.object.isRequired,
    calculatorInputs: PropTypes.object.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this._renderTemporallyCorrectRates = this._renderTemporallyCorrectRates.bind(this);
    this._formatResultArguments = this._formatResultArguments.bind(this);
    this._renderDisplays = this._renderDisplays.bind(this);
  }

  _formatResultArguments(floodZone, bfe) {
    if (floodZone === FloodZones.AE) {
      return RateCalculatorInputFormatter.formatAEZone(bfe, this.props.calculatorInputs, this.props.future);
    } else {
      return RateCalculatorInputFormatter.formatXZone(this.props.calculatorInputs, this.props.future);
    }
  }

  _renderTemporallyCorrectRates() {
    if (this.props.future) {
      return this._renderDisplays(this.props.calculatorInputs.future_flood_zone, this.props.calculatorInputs.future_bfe)
    } else {
      return this._renderDisplays(this.props.calculatorInputs.current_flood_zone, this.props.calculatorInputs.current_bfe)
    }
  }

  _renderDisplays(floodZone, bfe) {
    if (floodZone === FloodZones.VE) {
      var bestCaseResults = RateCalculatorInputFormatter.formatVEZone(bfe, this.props.calculatorInputs, true, this.props.future);
      var worstCaseResults = RateCalculatorInputFormatter.formatVEZone(bfe, this.props.calculatorInputs, false, this.props.future);
      return (
        <div>
          <RateDisplay api={this.props.api} results={bestCaseResults}/> - <RateDisplay api={this.props.api}
                                                                                       results={worstCaseResults}/>
        </div>
      )
    } else {
      var formattedResults = this._formatResultArguments(floodZone, bfe);
      return (<RateDisplay api={this.props.api} results={formattedResults}/>);
    }

  }

  render() {
    return (
      <div className='rate-amount'>
        {this._renderTemporallyCorrectRates()}
        <span className='per-year'>{this.props.provider.perYear}</span>
      </div>
    );
  }
}
