import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { colors } from '../styles/variables';
import SalesforceCommunicator from '../communicators/SalesforceCommunicator';
import { useProvider, useApi } from '../helpers/hooks';
import { Section } from './ui/StyledResourcePageElements';

const Header = styled.div`
  font-size: 4rem;
  font-family: GT-Walsheim-Pro-Bold;
  color: ${colors.accent.main};
  text-align: center;
  margin-bottom: 1rem;
  text-transform: none;
`;

const TotalAuditCount = () => {
  const provider = useProvider('TotalAuditCount');
  const { salesforceApi } = useApi();
  const [auditCount, setAuditCount] = useState(0);

  useEffect(() => {
    const getAuditCount = async () => {
      try {
        // Returns object { audit_count: number }
        const data = await SalesforceCommunicator.getAuditCount(
          salesforceApi,
          {}
        );
        setAuditCount(data.audit_count);
      } catch (err) {
        setAuditCount(null);
      }
    };

    getAuditCount();
  }, [salesforceApi]);

  if (!auditCount) {
    return null;
  }

  return (
    <Section>
      <Header>
        {auditCount}
        {provider.header}
      </Header>
    </Section>
  );
};

export default TotalAuditCount;
