import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { lighten, darken } from 'polished';
import { borderRadius, cubicBezier, colors } from '../../styles/variables';

const Button = styled.input`
  appearance: none;
  font-family: GT-Walsheim-Pro-Medium;
  border-radius: ${borderRadius};
  font-size: 1rem;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
  transition: transform 0.1s ${cubicBezier.ease};
  color: white;
  border: none;
  background-color: ${colors.resilientBlue};
  padding: 0 3rem;
  height: 60px;

  &:hover {
    background-color: ${darken(0.1, colors.resilientBlue)};
  }

  &:disabled {
    background-color: ${colors.resilientBlue};
    cursor: not-allowed;
  }

  &:active {
    transform: translate3d(0, 2px, 0);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px ${lighten(0.3, colors.resilientBlue)};
  }
`;

const ButtonInput = ({ text }) => <Button type="submit" value={text} />;

ButtonInput.propTypes = {
  text: PropTypes.string.isRequired
};

export default ButtonInput;
