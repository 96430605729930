export default {
  header: 'About the program',
  body:
    'Are you struggling to fix flood damage to your property from a recent storm? Do you have several financial expenses from the recent storm that you’re unable to pay? You may be eligible to receive financial assistance through the Flood Recovery Fund.',
  eligibility: 'Eligibility',
  eligibilityBody:
    'To be eligible for the Flood Recovery Fund, the applicant must meet all of the following criteria:',
  priority: {
    header: 'Eligibility Criteria:',
    criteria: [
      {
        key: 'residence',
        copy:
          'The residence must be a 1-4 unit home, condo or co-op in one the five boroughs of New York City.'
      },
      {
        key: 'owner',
        copy: 'The applicant must be an owner of the property.'
      },
      {
        key: 'primary',
        copy:
          'The property must be the homeowner’s primary residence. *As defined by HUD, primary residence means the homeowner resides there for 183 days or more per year. '
      },
      {
        key: 'hardship',
        copy:
          'The applicant and/or household has experienced financial hardship (i.e. property damage, other household expenses) as a result of the storm indicated in their application.'
      },
      {
        key: 'income',
        copy:
          'Total household income must be at or below 165% Area Median Income (AMI) for New York City based on household size. Household size is defined as the total number of  people living in the household, not including tenants (or anyone who pays rent), or temporary guests.'
      }
    ]
  },
  ami: [
    {
      key: 'Household Size',
      ami: '165% AMI'
    },
    {
      key: 1,
      ami: '$154,110'
    },
    {
      key: 2,
      ami: '$176,220'
    },
    {
      key: 3,
      ami: '$198,165'
    },
    {
      key: 4,
      ami: '$220,110'
    },
    {
      key: 5,
      ami: '$237,765'
    },
    {
      key: 6,
      ami: '$255,420'
    },
    {
      key: 7,
      ami: '$273,075'
    },
    {
      key: 8,
      ami: '$290,565'
    }
  ],
  providedBy: 'Program provided by',
  gosr: "Governor's Office of Storm Recovery"
};
