import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  font-size: ${(props) => props.fontSize};
  height: ${(props) => props.height};
  text-align: center;
  margin: 5rem 3rem;

  @media (max-width: 600px) {
    font-size: 2rem;
  }
`;

const Error = ({ provider, fontSize, height }) => {
  return (
    <Container
      fontSize={fontSize}
      height={height}
      dangerouslySetInnerHTML={{ __html: provider.errorText }}
    />
  );
};

Error.propTypes = {
  provider: PropTypes.object.isRequired,
  fontSize: PropTypes.string,
  height: PropTypes.string
};

Error.defaultProps = {
  fontSize: '3rem',
  height: 'inherit'
};

export default Error;
