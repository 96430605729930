export default {
  meta: {
    title: 'Estimasyon Tarif Ou an',
    url: 'https://floodhelpny.org/ht_HT/rate',
    description: null,
    image: null,
    imageAlt: null
  },
  profileCalloutCopy: 'Nou ajoute kèk rekòmandasyon.',
  noMitigationCopy: 'Nou pa t ka jwenn opsyon adousisman',
  profileCalloutLink: 'Gade Pwofil Tolerans Ou.',
  betaCalloutCopy:
    'Jan ou ka di l, detèmine estimasyon asirans kont inondasyon yo konplike. Anfèt, pèsòn NYC pa ka founi yon zouti parèy pou piblik jeneral lan pou estime tarif yo. Nou travay di pou nou vini ak yon zouti ki pwodui estimasyon ki bay enfòmasyon. Toutfwa, nou ap lage zouti sa a antanke yon premye vèsyon ak konesans li gen enpèfeksyon. Ak èd ou, nou ka amelyore l pou tout abitan New York yo. Si ou panse rezilta ou ap jwenn yo la fo, tanpri voye imèl ban nou nan ',
  betaCalloutLink: 'info@floodhelpny.org.',

  headerProvider: {
    backToProfileLink: '‹ Tounen nan Pwofil lan',
    header: 'Estimasyon asirans kont inondasyon ou an.',
    editAnswersLink: 'Rekòmanse',
    copy: 'Nou ap gide ou nan depans potansyèl ou yo kounay e lè kat yo chanje.'
  },
  rateCalculatorResultProvider: {
    header: 'Estimasyon pou Asirans kont Inondasyon',
    beta: 'BETA',
    link: 'Gade tout estimasyon ou an',
    currentMapStart: 'Kat aktyèl yo ( ',
    currentMapEnd: ' zòn)',
    futureMapStart: 'Kat konsiltatif (',
    futureMapEnd: ' zòn)',
    assumingMax: '*Nou sipoze yon kouvèti maksimòm',
    numbersAreEstimates:
      'Chif sa yo se estimasyon pou anpil rezon. Pou aprann plis sou yo ale sou ',
    termsAndConditions: 'Tèm ak Kondisyon yo',
    floodZoneRateProvider: {
      perYear: 'Pou chak ane*'
    },
    rateAdjusterProvider: {
      adjustYourCoverage: 'Ajiste kouvèti ou ',
      buildingCoverage: 'Kouvèti batiman',
      buildingCoverageTooltip:
        'Kouvèti batiman an asire batiman an sèlman, non pa kontni yo oswa lakou an. Li gen ladan l kèk mèb ak atik menaje ki tou vin avè l ansanm ak ekipman sèvis piblik yo.',
      buildingDeductible: 'Franchiz batiman an',
      buildingDeductibleTooltip:
        'Sa se kantite lajan ou ap blije peye nan pòch ou pou repare domaj nan batiman anvan asirans kont inondasyon ou an derape.',
      contentCoverage: 'Kouvèti kontni yo',
      contentCoverageTooltip:
        'Kouvèti kontni yo asire byen ou yo ki anndan kay ou. Aparèy pou lave epi seche rad yo ansanm ak konjelatè pou manje yo konsidere kòm kontni. (Recho ak frijidè yo jwenn asirans “kouvèti batiman an.”)',
      contentDeductible: 'Franchiz kontni yo',
      contentDeductibleTooltip:
        'Sa se kantite lajan ou ap blije peye nan pòch ou pou repare oswa ranplase byen ki andomaje yo anvan asirans kont inondasyon an derape.',
      collapse: 'Chit'
    }
  },
  prefirmCalloutProvider: {
    copy:
      'Sibvansyon anvan FIRM yo ap disparèt. Pwochen tarif ou an gendwa monte jiska 18% chak ane!'
  },
  lomaCalloutProvider: {
    header:
      'Ret tann! Kay ou an gendwa pa nan yon zòn inondasyon ki gen gwo risk.',
    body:
      'Li sanble kay ou an sou sòl ki wo. Jwenn yon sètifika elevasyon, epi mande koutye asirans ou an aplike pou yon Lèt Modifikasyon Kat (LOMA), ki se yon demann pou chanje kat inondasyon an epi mete sou kote pwopriyete ou an nan zòn inondasyon an. Si LOMA ou an reyisi, ou ka bese tarif asirans kont inondasyon ou yo nan yon fason ki enpòtan.',
    link: 'Aprann plis'
  }
};
