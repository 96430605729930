export default {
    meta: {
      title: '注册',
      url: 'https://www.floodhelpny.org/zh-CN/register'
    },
    title: '风险评级2.0',
    subHeading:
      '注册免费网络研讨会，了解更多关于风险评级2.0的信息，并回答您的问题，确保您的房屋和财务安全',
    register:
      '2022年9月13日，与房屋顾问一起注册免费网络研讨会',
    brought: '风险',
    riskRating:
      '评级2.0是一个新的联邦系统，用于设定洪水保险费。超过60%的纽约人目前持有保单，他们的保费可能会增加。',
    learnMore: '了解有关风险评级2.0的更多信息',
    funding: {
      support: 'FEMA 提供资助',
      questions: '是否有问题？电子邮件 ',
      commsEmail: 'communications@cnycn.org'
    }
  };