import addressInput from './AddressInput.en';

export default {
  meta: {
    title: 'Explore NYC Flood Zone Maps',
    url: 'https://www.floodhelpny.org/en/explore-map',
    description:
      "Explore NYC's flood zone maps to understand your current and future risk",
    imageAlt: 'explorable map'
  },
  loadingText: '',
  placeholder: 'Enter your address',
  currentMaps: 'Current Maps',
  futureMaps: 'Advisory Maps',
  addressInput,
  legend: {
    label: 'KEY:',
    minimalHazard: 'Minimal hazard',
    moderateRisk: 'Moderate risk',
    highRisk: 'High risk',
    veryHighRisk: 'Highest risk (flooding & waves)',
    copy:
      'The map shows the current and advisory flood maps for New York City as proposed by ',
    linkCopy: 'FEMA',
    linkUrl: 'https://www.fema.gov'
  },
  showPopup: 'Show flood zone',
  hidePopup: 'Hide flood zone',
  errorText:
    'Whoops, it looks like Google is having some problems returning data to our map! Please try again later.'
};
