import RtappApplicationState from '../constants/RtappApplicationState';
import worker1 from '../images/worker-1.svg';
import worker2 from '../images/worker-2.svg';
import grantAgreement from '../images/grant-agreement.svg';
import pencilRuler from '../images/pencil-ruler.svg';
import maleUser from '../images/male-user.svg';
import application from '../images/application.svg';
import calendar from '../images/calendar.svg';
import feedback from '../images/feedback.svg';
import piping from '../images/piping.svg';

export default {
  [RtappApplicationState.PRE_APPLICATION]: {
    headerNext: 'Lo que usted puede hacer a continuación',
    headerExpect: 'Lo que puede anticipar',
    callToAction: 'Estamos estudiando la información que usted presentó',
    body:
      'Por favor, dedique a nuestro equipo algo de tiempo para enviarle una solicitud completa para el programa. Nos pondremos en contacto con usted lo antes posible. ',
    expect:
      'Si usted es elegible para el programa, recibirá un correo electrónico con una invitación para llenar una solicitud. También puede chequear en la página web para ver si su estado ha cambiado. ',
    toProfile: 'Llévame a mi perfil de riesgo de inundación',
    icon: application
  },
  [RtappApplicationState.PRE_APPLICATION_APPROVED]: {
    callToAction: 'Ahora: Llene la solicitud',
    body:
      '¿Necesita ayuda? Llame al Centro para vecindarios en NYC al 646-786-0888 o envíenos un correo electrónico a info@floodhelpny.org. Haga cualesquier preguntas que tenga sobre nuestro programa.',
    expect:
      'Nuestro equipo está trabajando duro para revisar todas las solicitudes, y esperamos comunicarnos nuevamente con usted dentro de las próximas 2 a 3 semanas. Sabemos que es una espera larga, y agradecemos su paciencia. Le enviaremos un correo electrónico tan pronto como cambie su estado, pero usted también puede continuar chequeando aquí para ver actualizaciones. Si prefiere llenar un formulario de solicitud impreso, descargue y presente al Centro los formularios más abajo.',
    buttonText: 'Haga su solicitud',
    link: 'https://www.floodhelpny.org/samples/auditApplication.pdf',
    linkText: 'Formulario de Solicitud',
    link2: 'https://www.floodhelpny.org/samples/tenantIncome.pdf',
    linkText2: 'Formulario de Verificación de Ingresos de Inquilino',
    icon: application
  },
  [RtappApplicationState.APPLICATION_SUBMITTED]: {
    headerNext: 'Lo que usted puede hacer a continuación',
    headerExpect: 'Lo que puede anticipar',
    callToAction: '¡Ha dado el primer paso!',
    body:
      'Estamos revisando su solicitud para determinar su elegibilidad, y nos comunicaremos con usted si necesitamos información adicional. ¿Tiene preguntas? Llame al Centro para Vecindarios de NYC (Center for NYC Neighborhoods) al (646) 786-0888 o envíenos un correo electrónico a info@floodhelpny.org',
    expect:
      'Si se le aprueba para el programa, recibirá un correo electrónico donde le pedimos que firme un Convenio de Subvención del Propietario (Homeowner Grant Agreement), que le permite al Centro distribuir fondos federales a propietarios elegibles.',
    icon: grantAgreement
  },
  [RtappApplicationState.HOMEOWNER_GRANT]: {
    headerExpect: 'Lo que puede anticipar',
    callToAction: 'Descargue y firme el Convenio de Subvención del Propietario',
    body:
      'Recibirá una copia de su Acuerdo de Subvención para Propietarios por correo electrónico. Deberá descargar y devolvernos el acuerdo de concesión de vivienda por fax, correo electrónico o servicio postal. El acuerdo debe ser firmado por todas las personas que figuran en la escritura de la propiedad, y permite que el Centro distribuya fondos federales a propietarios elegibles en el programa.',
    faq:
      'Para saber cómo devolvernos el acuerdo, haga clic en las Preguntas frecuentes en el lado derecho debajo de "Usted podría estar pensando".',
    contactUs:
      '¿Aún tienes preguntas? Contáctenos al 646-786-0888 o envíenos un correo electrónico a info@floodhelpny.org.',
    expect:
      'Después de que hayamos recibido su Convenio de Subvención del Propietario, lo firmará el Center for NYC Neighborhoods y se le enviará una copia al financiador del programa, New York State Governor’s Office of Storm Recovery (Oficina del Gobernador del Estado de Nueva York para Recuperación ante Tormentas). Una vez que se haya revisado la documentación, programaremos su auditoría. Esto podría tomar hasta cuatro semanas.',
    icon: grantAgreement
  },
  [RtappApplicationState.REJECTED]: {
    body:
      'Desafortunadamente, actualmente no es elegible para recibir servicios a través de este programa. Sin embargo, aún puede usar los recursos en FloodHelpNY.org para verificar su riesgo de inundación y aprender sobre formas de ser más resistente. ¿Tiene preguntas sobre su solicitud? Contáctanos al 646-786-0888. Para obtener información adicional de elegibilidad, revise las preguntas frecuentes en el lado derecho.'
  },
  [RtappApplicationState.WITHDRAWN]: {
    body:
      'Gracias por su interés en el Programa de instalación de la válvula antirretornos. Su caso ha sido retirado formalmente de este programa. Aún puede verificar su riesgo de inundación y revisar nuestros recursos sobre seguro contra inundaciones. Si desea continuar con el programa, llámenos al 646-786-0888 o envíe un correo electrónico a info@floodhelpny.org. Continuaremos procesando su solicitud.'
  },
  [RtappApplicationState.FEASIBILITY_REVIEW]: {
    headerNext: 'Lo que usted puede hacer a continuación',
    headerExpect: 'Lo que puede anticipar',
    scheduled: 'Se ha programado su estudio de viabilidad',
    body:
      'El ingeniero que completó su Auditoría de Resiliencia en el Hogar debe regresar a su hogar para obtener información adicional para determinar si su hogar podría beneficiarse o no de una válvula antirretorno. Tendrá que estar en casa y el ingeniero necesitará acceso en toda su propiedad, incluido el sótano y el área al aire libre.',
    icon: pencilRuler,
    expect:
      'En algunos casos, instalar una válvula antirretorno no beneficiará a su vivienda. Su ingeniero le hará algunas preguntas y tomará algunas fotografías y medidas que necesitarán para tomar su determinación y, si corresponde, crear los planes de construcción para la instalación de su válvula antirretorno. Esperamos volver a comunicarnos con usted de tres a seis semanas después de que el ingeniero complete el estudio de viabilidad.',
    noDate:
      'Llámenos al número que figura a continuación para confirmar su fecha y hora de revisión de factibilidad.'
  },
  [RtappApplicationState.AUDIT_SCHEDULED]: {
    headerNext: 'Lo que usted puede hacer a continuación',
    headerExpect: 'Lo que puede anticipar',
    scheduled: 'Se ha programado su auditoría',
    body:
      'Asegúrese de estar en casa a la hora programada para permitirle al ingeniero completar su evaluación. La auditoría deberá tomar menos de dos horas. Si usted no puede estar en casa para su auditoría programada, o si tiene que cancelar su cita, por favor llámenos lo antes posible al ',
    phone: '646-786-0888',
    phoneLink: '+6467860888',
    body2: ' durante horas hábiles para reprogramar.',
    expect:
      'El ingeniero y el personal que lo acompaña necesitarán acceso a todas las áreas de la vivienda, inclusive el sótano o entresuelo y el patio trasero, para garantizar que usted reciba un informe preciso. Si el ingeniero no ha llegado a su casa dentro de 30 minutos de la hora programada, por favor llámenos al (646) 786-0888.',
    noDate:
      'Llámenos al número que figura a continuación para confirmar su fecha y hora de auditoría.'
  },
  [RtappApplicationState.AUDIT_COMPLETE]: {
    headerNext: 'Lo que usted puede hacer a continuación',
    headerExpect: 'Lo que puede anticipar',
    callToAction: 'Programe su sesión de asesoramiento',
    body:
      'Alguien de nuestro Núcleo de Propietarios le llamará dentro de las próximas 48 horas para programar una cita con un asesor de resistencia. Si usted todavía tiene preguntas sobre el programa o la planificación, puede llamar a uno de nuestros representantes al ',
    phone: '(646) 786-0888.',
    phoneLink: '+6467860888',
    expect:
      'La cita tomará entre 45 minutos y una hora. En ese tiempo, uno de nuestros asesores le explicará la información que el ingeniero recabó durante su visita y hablará sobre las estrategias de mitigación más adecuadas para su vivienda. Nuestro asesor también le hablará sobre opciones de financiamiento y le ayudará a crear un plan para proteger su casa que se ajuste a su presupuesto.',
    icon: grantAgreement
  },
  [RtappApplicationState.CONSULTATION_SCHEDULED]: {
    headerNext: 'Lo que usted puede hacer a continuación',
    headerExpect: 'Lo que puede anticipar',
    scheduled: 'Se ha programado su consulta',
    body:
      'El asesor le hablará sobre su riesgo actual y futuro de inundaciones, así como cualesquier acciones que usted pueda emprender con base en la evaluación del ingeniero. Por favor traiga los siguientes documentos si están disponibles: 1) Página de declaraciones de seguro contra inundaciones; 2) certificado de elevación anterior, si corresponde; y 3) estado de cuenta de hipoteca u otros documentos que muestren el estado actual de la hipoteca de su vivienda ',
    phone: '646-786-0888',
    phoneLink: '+6467860888',
    body2:
      ' o envíenos un correo electrónico a info@floodhelpny.org. También infórmenos inmediatamente si necesita cancelar o reprogramar',
    expect:
      'Su asesor es un experto en la industria que le hablará sobre los factores específicos que determinan su prima de seguro contra inundaciones. Repasará su certificado de elevación e informe técnico para darle orientación sin términos especializados. El asesoramiento es un proceso mutuo – ¡se le anima a hacer preguntas! Al final de esta cita, usted entenderá lo que podría costarle el seguro contra inundaciones, si le están tasando de manera correcta, opciones comunes de mitigación, y si una válvula antirretorno beneficiaría a su vivienda.',
    noDate:
      'Llámenos al número siguiente para confirmar la fecha y hora de su consulta.'
  },
  [RtappApplicationState.CONSULTATION_COMPLETE]: {
    callToAction:
      'Bienvenido al programa de válvula antirretorno',
    body:
      'Usted ha terminado su asesoramiento y ha recibido una auditoría. Es hora de hablar sobre instalar su válvula antirretorno.',
    contact:
      'Durante las próximas semanas, le guiaremos en el proceso de hacer que se le instale esta válvula. Si desea hablar del programa en mayor detalle, puede llamar al Centro al ',
    phone: '646-786-0888',
    phoneLink: '+6467860888',
    contact2: ' o enviarnos un correo electrónico a ',
    email: 'info@floodhelp.org.',
    images: [{ icon: piping, iconAltText: 'Tubería' }]
  },
  [RtappApplicationState.INSTALLATION_PREPARE]: {
    callToAction: 'Estamos organizando su equipo',
    body:
      'Estamos reuniendo a su equipo para garantizar que podamos brindarle el mejor servicio posible. Nos pondremos en contacto en seis u ocho semanas para presentarle a su equipo de instalación. Mientras tanto, la última versión de su informe está disponible a continuación.',
    contact: 'Puede llamarnos al ',
    phone: '646-786-0888',
    phoneLink: '+6467860888',
    contact2: ' o envíenos un correo electrónico con cualquier pregunta al ',
    email: 'info@floodhelpny.org.',
    images: [{ icon: pencilRuler, iconAltText: 'Lápiz y regla' }]
  },
  [RtappApplicationState.MEET_YOUR_TEAM]: {
    headerNext: 'Lo que usted puede hacer a continuación',
    headerExpect: 'Lo que puede anticipar',
    callToAction: '¡Vamos a hacer esto juntos!',
    body:
      'Es hora de que conozca a las personas que instalarán su válvula antirretorno en su reunión inicial. Además del ingeniero, hemos seleccionado a un plomero y un inspector para que trabajen con usted. Comuníquese con nosotros al (646) 786-0888 o enviarnos un correo electrónico a info@floodhelp.org si tiene cualquier pregunta.',
    images: [
      { icon: worker1, iconAltText: 'Trabajador' },
      { icon: maleUser, iconAltText: 'Hombre' },
      { icon: worker2, iconAltText: 'Trabajador' }
    ],
    link: 'https://www.floodhelpny.org/samples/Tri-PartyAgreement.pdf',
    linkText: 'Acuerdo Tripartito',
    scheduled: 'Reunión Previa a la Construcción',
    expect:
      'Programaremos una hora para que usted se reúna con su equipo. Además de conocer a las personas con quienes estará trabajando, también se le pedirá que firme un Acuerdo Tripartita entre usted, el plomero y el Centro.',
    noDate:
      'Llámenos al número anterior para confirmar la fecha y hora de instalación.'
  },
  [RtappApplicationState.INSTALLATION_TO_BE_SCHEDULED]: {
    headerNext: 'Lo que usted puede hacer a continuación',
    headerExpect: 'Lo que puede anticipar',
    callToAction:
      'Su inspector trabajará con usted para programar su instalación',
    body:
      '¡Prepárese para la instalación de su válvula antirretorno! Esto generalmente tomará alrededor de ocho horas, pero el plomero puede tener que regresar otro día para terminar, dependiendo de qué tan accesibles sean las tuberías de drenaje de su hogar. Recuerde que debe estar en casa para que este trabajo se lleve a cabo. Durante la instalación, su plomero deberá cerrar el agua de su hogar. Esto significa que no podrá utilizar los sistemas de plomería de su hogar (inodoros, duchas, lavabos, lavavajillas, etc.) durante la instalación, por lo tanto, haga arreglos alternativos de antemano. No dude en llamarnos al 646-786-0888 o enviarnos un correo electrónico a info@floodhelpny.org con cualquier pregunta.',
    expect:
      'La cantidad de tiempo que tomará la instalación depende de la configuración de su vivienda y su conexión con el alcantarillado de la ciudad, así como la condición del tubo que conecta su casa al alcantarillado de la ciudad. Le daremos lo antes posible una estimación de cuánto tiempo pensamos que esto tomará, pero tenga en cuenta que cierta flexibilidad podría ser necesaria. El plomero que instalará su válvula le mostrará dónde está, cómo funciona y cómo mantenerla. Además, cuando se haya completado la instalación usted recibirá un informe de campo, que contendrá instrucciones específicas de operación y mantenimiento del fabricante de la válvula.',
    icon: calendar
  },
  [RtappApplicationState.INSTALLATION_HAS_BEEN_SCHEDULED]: {
    callToAction: 'Su inspector ha programado la instalación de su válvula',
    body:
      'El plomero y el inspector con licencia están programados para la instalación de la válvula antirretorno. Si tiene alguna pregunta sobre la instalación, llame a su inspector. Asegúrese de estar en casa para la instalación. ',
    contact:
      'La cantidad de tiempo que lleva la instalación depende del diseño de su hogar y su conexión a la alcantarilla de la ciudad, así como de la condición de la tubería que conecta su hogar con la alcantarilla de la ciudad. Le daremos una estimación de cuánto tiempo creemos que esto tomará lo antes posible, pero tenga en cuenta que puede ser necesaria cierta flexibilidad. El plomero que hara la instalación le mostrará dónde está la válvula, cómo funciona y cómo mantenerla. Además, recibirá un informe de campo cuando se complete la instalación que contenga instrucciones específicas de operación y mantenimiento del fabricante de su válvula.',
    images: [{ icon: calendar, iconAltText: 'Calendar' }]
  },
  [RtappApplicationState.INSTALLATION_COMPLETE]: {
    headerNext: 'Lo que usted puede hacer a continuación',
    headerExpect: 'Lo que puede anticipar',
    callToAction: 'Díganos cómo le fue',
    body:
      'Felicitaciones por su nueva válvula antirretorno. Nos gustaría recibir sus comentarios sobre cómo fue el proceso. Si tiene tres minutos, por favor conteste nuestro breve cuestionario más abajo. Al final de esta página encontrará algunos documentos importantes relacionados con su válvula. Como siempre, si tiene preguntas o inquietudes puede llamarnos al (646) 786-0888 o enviarnos un correo electrónico a info@floodhelp.org.',
    expect:
      'Espere un correo electrónico de nuestra parte o chequee de nuevo en esta página web en dos o tres semanas para recibir todos sus documentos, inclusive información sobre cómo mantener su válvula antirretorno, así como la garantía del fabricante.',
    link: 'https://cnycn.tfaforms.net/12',
    linkText: 'Cuestionario',
    icon: feedback
  },
  [RtappApplicationState.REPORT_COMPLETE]: {
    firstBody: 'Este es el informe de resistencia para: ',
    secondBody:
      ' con algunas recomendaciones técnicas sobre cómo hacer su hogar y sus finanzas más seguros ante inundaciones, así como documentos importantes relativos a su válvula.'
  }
};
