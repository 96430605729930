export default {
  meta: {
    title: 'Tèm ak Kondisyon yo',
    url: 'https://www.floodhelpny.org/ht_HT/terms'
  },
  title: 'Tèm ak Kondisyon yo',
  national_flood_insurance_policy: 'Pwogram Nasyonal Asirans kont Inondasyon',
  privacy_policy: 'Politik sou Vi Prive',
  general_disclaimer: {
    title: 'Aci sou Non-Responsablite ak Limit Responsablite',
    paragraph_1:
      'Yo bay dokiman ak enfòmasyon yo ki nan FloodHelpNY.org (“Sit” lan) (ansanm ak nenpòt lyen pou al nan lòt sit entènèt ak kontni ou jwenn nan sit ki lye yo) bay “jan yo ye an” e san okenn garanti nan okenn fason, kit se fason klè oswa enplisit, ansanm men non pa limite ak okenn garanti enplisit sou valè komèsyal oswa adekwasyon yo pou yon itilizasyon anpatiklye. Ni Center for New York Neighborhoods, Inc.(“Sant” li) ni okenn Biwo Gouvènè an pou Retablisman apre Tanpèt (“GOSR”) pa pote okenn responsablite nan okenn fason, kit se nan kontra, nan deli, oswa responsablite strik oswa tout lòt kalite responsablite, pou tout kalite domaj akoz itilizasyon Sit sa a. Akoz nati entènèt lan, Sant lan pa garanti aksè ak Sit sa a pral fèt san kanpe oswa san erè. Sant lan pa garanti oswa defè okenn deklarasyon konsènan itilite rezilta yo atann yo sou dokiman ki sou Sit sa a.',
    paragraph_2:
      'Nou kwè enfòmasyon ki founi sou Sit sa a presi, e nou fè tout efò pou minimize erè. Toutfwa, piske ni moun ni òdinatè ka fè erè, e piske enfòmasyon ka chanje rapidman, nou paka garanti nan yon fason total kapital presizyon enfòmasyon ki founi yo. Si ou jwenn yon erè oswa yon bagay ki pa egzak, tanpri kontakte nou nan ',
    email: 'info@floodhelpny.org.',
    paragraph_3:
      'Estimasyon depans asirans yo ak depans renovasyon yo bay nan objektif pou ilistre sèlman. Ou responsab pou verifye presizyon ak adekwasyon tout estimasyon depans yo. Tanpre chèche konsèy moun ki lisansye ak/oswa konpetan anvan ou fè tout envestisman oswa desizyon pou planifikasyon finansye. Pa apiye ou sèlman sou estimasyon depans yo ak enfòmasyon ki sou Sit sa a.  ',
    paragraph_4:
      'Ou dakò lè ou aksede ak Sit sa a nan okenn sikonstans oswa teyori responsablite nan kad lwa entènasyonal oswa sivil, komen oswa statitè ki gen ladan l men ki pa limite ak responsablite strik, neglijans oswa lòt teyori deli oswa lwa sou kontra, brevè oubyen dwa dotè, Sant la pa p responsab pou domaj kèlkeswa tip lan ki rive nan itilizasyon Sit lan oswa nenpòt enfòmasyon, byen oswa sèvis yo jwenn sou Sit sa a ansanm ak domaj dirèk, endirèk, enpòtan, segondè oswa bay pinisyon (menm si yo te enfòme Sant la sou posiblite domaj konsa), nan tout mezi lalwa pèmèt sa. Kèk jiridiksyon pa otorize esklizyon oswa limit sou sèten domaj donk limit sa yo gendwa pa aplike pou ou.'
  },
  flood_zones: {
    title: 'Zòn Inondasyon yo',
    part_1:
      'Rezilta zòn inondasyon an, elevasyon ak risk pou inondasyon yo pou nenpòt anplasman endividyèl ki parèt sou Sit sa a pou enfòmasyon jeneral sèlman e ou pa ta dwe apiye ou sou yo pou detèmine si ou blije jwenn asirans kont inondasyon oswa poze nenpòt lòt aksyon. Tanpri fòk ou konnen limit zòn inondasyon yo gendwa pa presi nan kèk zòn, epi lokalizatè adrès lan gendwa pa 100% presi nan idantifikasyon adrès ou an. Pwopriyetè kay yo ta dwe kontakte ',
    part_2:
      ' pou plis enfòmasyon oswa pou gade yon kopi ofisyèl Kat Tarif Asirans kont Inondasyon yo epi diskite sou elevasyon ak zòn inondasyon yo nan katye pwopriyete yo an anvan yo kòmanse tout aktivite konstriksyon. Anplis, enfòmasyon ki bay sou Sit sa a jeneralman presi pou inondasyon sou litoral lan e li gendwa pa endike lòt fòm risk pou inondasyon, tankou inondasyon apati rivyè oswa lòt koz. Finalman, sèten enfòmasyon yo ki bay sou Sit sa a ki lye ak elevasyon epi risk domaj nan inondasyon baze sou kat tarif Pwogram Nasyonal Asirans kont Inondasyon an ak lòt pwodui, sa ki pa konte pou pwochen risk apati nivo lanmè ki monte akoz rechofman klimatik. Pou enfòmasyon apwopo fason enfòmasyon pèsonèl ou yo gendwa sèvi tanpri konsilte '
  },
  flood_insurance_estimate: {
    title: 'Estimasyon pou Asirans kont Inondasyon',
    paragraph_1:
      'Lè ou chwazi itilize Sit sa a pou jwenn yon estimasyon asirans kont inondasyon, ou dakò pou tèm ak kondisyon sa yo lye ou. Si ou pa dakò ak tèm epi kondisyon sa yo, ou pa ta dwe soumèt yon demann estimasyon tarif asirans kont inondasyon.',
    paragraph_2:
      'Nou pa yon konpayi asirans e nou pa ofri oswa founi asirans kont inondasyon. Demann ou an pou yon estimasyon tarif asirans kont inondasyon pa p mete anvigè okenn pwofòma oswa ofri pou founi kouvèti asirans oswa nenpòt kouvèti asirans. Okenn responsablite nenpòt jan l ye pa p atache Sant lan kòm konsekans demann ou an pou yon estimasyon tarif asirans kont inondasyon. Kouvèti asirans kont inondasyon an, ou gendwa jwenn li lè ou fè yon aplikasyon pou asirans kont inondasyon nan men yon konpayi asirans ki founi asirans kont inondasyon e yon founisè asirans aksepte aplikasyon sa a jan sa parèt nan yon kontra separe founisè asirans lan bay.',
    paragraph_3:
      'Totalite estimasyon asirans kont inondasyon ou an, ansanm ak prim ki estime an epi kondisyon kouvèti ki prevwa yo pwovizwa e yo ka sibi chanjman san yo pa avize ou sou baz nenpòt ak tout kritè pou souskripsyon yo, tarif manyèl yo, dat tarifikasyon yo, manyèl règleman yo, plan tarifikasyon yo ak klasifikasyon ',
    paragraph_4_part_1: 'Tanpri li ',
    paragraph_4_part_2:
      ' pou konprann fason enfòmasyon pèsonèl ou founi nan rekèt ou an pou yon estimasyon asirans kont inondasyon gendwa sèvi. Pandan Sant lan pran prekosyon pou pwoteje enfòmasyon ou transmèt yo nan demann ou an pou yon estimasyon asirans kont inondasyon, Sant lan pa garanti yon tyès pati pa p entèsepte, analize, retransmèt, distribye oswa itilize okenn enfòmasyon atravè entènèt lan oswa yon lòt fason.',
    paragraph_5:
      'Sant lan rezève l dwa pou kontwole Sit entènèt sa a pou idantifye tantativ ki pa otorize pou entèsepte oswa chanje enfòmasyon yo oswa lakoz domaj. Lè ou itilize Sit sa a , ou bay konsantman ou pou yon kontwòl konsa. Nou anpeche nan yon fason strik tantativ ki pa otorize yo pou entèsepte oswa chanje enfòmasyon oswa lakoz domaj e lalwa gendwa pini yo.'
  },
  home_resiliency_audit: {
    title: 'Odit pou Tolerans Kay',
    paragraph_1:
      'Lè ou chwazi itilize Sit sa a pou aplike pou epi patisipe nan pwogram Odit pou Tolerans Kay lan, ou dakò pou lye ak tèm epi kondisyon sa yo. Si ou pa dakò ak tèm epi kondisyon sa yo, ou pa ta dwe soumèt yon aplikasyon pou patisipe nan pwogram Odit pou Tolerans Kay lan.',
    paragraph_2:
      'Nou pa ka garanti tout pwopriyetè kay ki ta renmen resevwa yon Odit pou Tolerans Kay ap resevwa youn. Nan moman sa a, pwogram lan disponib sèlman pou pwopriyetè kay ki lokalize nan katye presi nan Vil New Yok epi li lmite nan kantite patisipan li ka resevwa.',
    paragraph_3:
      'Sant lan te chwazi fim jeni ki pral reyalize Odit pou Tolerans Kay atravè yon pwosesis akizisyon konpetitif ki te chèche fim achitekti, sondaj ak jeni ki kalifye. Yon nan kritè Sant lan itilize nan chwazi fim jeni yo se te pou fim yo demontre yon konpreyansyon trè presi sou fason Ajans Jesyon Ijans Federal lan (FEMA) detèmine prim asirans kont inondasyon yon batiman nan kad Pwogram Nasyonal Asirans kont Inondasyon (NFIP) an e fason mezi adousisman domaj nan inondasyon yo afekte tarif prim asirans NFIP an aplike ak yon batiman. ',
    paragraph_4:
      'Sant lan li menm p ap reyaize Odit pou Tolerans Kay yo e kidonk li pa ka garanti rezilta travay fim jeni yo fè oswa presizyon enfòmasyon ki founi yo.',
    paragraph_5:
      'Sant lan te chwazi konsèy sou lojman ak founisè sèvis jiridik pou founi konsèy sou tolerans bay pwopriyete rezidansyèl pou 1-4 fanmi nan katye nan Vil New York ki gen gwo enpak pou inondasyon nan kad pwogram Odit pou Tolerans Kay lan atravè yon pwosesis akizisyon ki konpetitif. Ajans konsèy sa yo ap founi konsèy pèsonalize ak asistans teknik pou sèvis odit pou tolerans. Youn nan kritè Sant lan te itilize pou chwazi ajans konsèy yo se te pou ajans konsèy demontre konpetans teknik ak finansye epi gen eksperyans nan travay nan nivo kominotè.',
    paragraph_6:
      'Sant lan li menm pa p reyalize konsèy sou lojman ak sèvis jiridik e kidonk li pa kapab garanti presizyon oswa nivo travay ajans konsèy yo founi an.',
    paragraph_7:
      'Ou ta dwe itilize Odit pou Tolerans Kay lan nan objektif enfòmasyon a egzanp sèlman. Ou responsab pou verifye presizyon ak adekwaasyon tout sipozisyon ak kalkil yo ki nan odit lan. Tanpre chèche konsèy moun ki lisansye ak/oswa konpetan anvan ou fè tout envestisman oswa desizyon pou planifikasyon finansye oswa antreprann tout rekonstriksyon oswa renovasyon nan kay ou. Pa apiye ou sèlman sou Odit pou Tolerans Kay an, kalkilatè/kalkil estimasyon tarif asirans kont inondasyon yo oswa enfòmasyon finansye ou jwenn sou Sit sa a.',
    paragraph_8_part_1: 'Tanpri li ',
    paragraph_8_part_2:
      ' pou konprann fason enfòmasyon pèsonèl ou founi nan aplikasyon ou an pou patisipe nan pwogram Odit pou Tolerans Kay lan gendwa sèvi. Pandan Sant lan pran prekosyon pou pwoteje enfòmasyon ou transmèt yo nan aplikasyon ou an pou patisipe nan pwogram Odit pou Tolerans Kay lan epi enfòmasyon ki pwodui yo nan kad pwogram lan, Sant lan pa garanti yon tyès pati pa p entèsepte, analize, retransmèt, distribye oswa itilize okenn enfòmasyon atravè entènèt lan oswa yon lòt fason.'
  },
  backwater_valve: {
    title: 'PWOGRAM ENSTALASYON VALV POU DLO K AP TOUNEN',
    paragraph_1:
      'Lè ou chwazi itilize Sit sa a pou aplike pou epi patisipe nan pwogram Enstalasyon Valv pou Dlo k Ap Tounen an, ou dakò pou lye ak tèm epi kondisyon sa yo. Si ou pa dakò ak tèm epi kondisyon sa yo, ou pa ta dwe soumèt yon aplikasyon pou patisipe nan pwogram Enstalasyon Valv pou Dlo k Ap Tounen an.',
    paragraph_2:
      'Nou pa ka garanti tout pwopriyetè kay yo ki ta renmen resevwa yon enstalasyon valv pou dlo k ap tounen an ap resevwa youn. Nan moman sa a, pwogram lan disponib sèlman pou pwopriyetè kay ki gen revni fèb pou rive modere ki lokalize nan katye presi nan Vil New Yok epi li lmite nan kantite patisipan li ka resevwa. Pou kalifye pou yon enstalasyon valv pou dlo k ap tounen, pwopriyetè yo dwe sibi yon Odit pou Tolerans Kay epi enjenyè an dwe detèmine pwopriyete an ta ka benefisye apati enstalasyon yon valv egou pou dlo k ap tounen.',
    paragraph_3:
      'Sant lan li menm pa p reyalize enstalasyon valv pou dlo k ap tounen e kidonk pa ka garanti rezilta travay fim ki pral enstale valv pou dlo k ap tounen yo fè. Sant lan pral idantifye yon plonbye anchèf ki lisansye nan yon gwoup fim ki prekalifye ki pral fini pwosesis enstalasyon an pou pwopriyetè kay ki kalifye yo. Sant lan ap peye plonbye anchèf lan dirèkteman pou depans enstalasyon valv lan. Pwopriyetè kay yo ta dwe analize kalifikasyon plonbye anchèf yo nan yon fason endepandan anvan yo dakò pèmèt tout travay enstalasyon fèt. ',
    paragraph_4:
      'Pandan enstalasyon yon valv pou dlo k ap tounen gendwa redui risk pou domaj akoz refoulman egou, Sant lan pa garanti refoulman egou oswa lòt fòm domaj pa p rive apre valv lan enstale. Si responablite pwopriyetè kay lan pou li konprann fason valv lan fonksyone, antretni valv lan, enspekte l sou yon baz regilye epi kenbe l nan yon bon eta fonksyonnman. ',
    paragraph_5:
      'Sant lan pa p founi okenn sèvis antretyen oswa enspeksyon apre enstalasyon valv lan. ',
    paragraph_6:
      'Gen risk ki asosye ak enstalasyon yon valv pou dlo k ap tounen. Domaj nan kay lan ta ka rive si dispozitif plonbri yo, tankou douch, evye ak twalèt yo sèvi pandan enstalasyon valv lan. Nan ka yon inondasyon oswa gwo tanpèt, valv lan gendwa tonbe anpann epi gen domaj ki rive nan pwopriyete an. ',
    paragraph_7: 'Pwopriyetè kay yo ki resevwa yon enstalasyon valv ta dwe:',
    paragraph_7_part_1:
      'Analize enstriksyon pou fonksyonnman ak antretyen fabrikan valv lan founi yo avèk swen epi suiv enstriksyon sa yo.',
    paragraph_7_part_2:
      'Evite flòch lenjèt, papye twalèt, pwodui asenisman, luil, matyè gra oswa grès nan twalèt oswa evye yo. Eleman sa yo ka monte epi lakoz valv lan anpann.',
    paragraph_7_part_3:
      'Verifye valv lan anvan yon tanpèt. Valv lan gendwa tonbe anpann pandan yo inondasyon si gen nenpòt eleman ki bloke klapè an nan valv lan. Chèche nenpòt eleman ki gendwa bloke valv lan epi retire yo. ',
    paragraph_8:
      'Pwopriyetè kay ki resevwa yon enstalasyon valv dwe dakò pou antretni pwòp kontra asirans pwopriyetè yo sou responsablite yo ansanm ak asirans pou refoulman egou anplis si l disponib. Yo dwe genyen tou yon asirans kont inondasyon ki rive nan nivo depans sibvansyon pou enstalasyon an.',
    paragraph_9:
      'Tanpri li Politik sou Vi Prive nou an pou konprann fason enfòmasyon pèsonèl ou founi nan aplikasyon ou an pou patisipe nan pwogram Enstalasyon Valv pou Dlo k Ap Tounen an gendwa sèvi. Pandan Sant lan pran prekosyon pou pwoteje enfòmasyon ou transmèt yo nan aplikasyon ou an pou patisipe nan pwogram Enstalasyon Valv pou Dlo k Ap tounen an epi enfòmasyon ki pwodui yo nan kad pwogram lan, Sant lan pa garanti yon tyès pati pa p entèsepte, analize, retransmèt, distribye oswa itilize okenn enfòmasyon atravè entènèt lan oswa yon lòt fason.'
  }
};
