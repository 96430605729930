import React from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useLocale } from '../helpers/hooks';
import resources from '../constants/Resources';
import ResourceLink from './ResourceLink';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
`;

const ResourceLinks = ({ withBorders }) => {
  const { pathname } = useLocation();
  const locale = useLocale();

  const renderResource = () => {
    // Filter removes empty spaces from pathElements array
    const currentPageSlug = pathname.split(`/${locale}/`).filter((e) => e)[0];
    return resources
      .filter((resource) => resource.slug !== currentPageSlug)
      .map((resource, idx) => (
        <ResourceLink
          link={`/${locale}/${resource.slug}`}
          src={resource.src}
          alt={resource.alt}
          header={resource.providerKey}
          withBorders={withBorders}
          key={idx}
        />
      ));
  };

  return <Wrapper>{renderResource()}</Wrapper>;
};

ResourceLinks.propTypes = {
  withBorders: PropTypes.bool
};

ResourceLinks.defaultProps = {
  withBorders: false
};

export default ResourceLinks;
