import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import AuditApplicationQuestions from './AuditApplicationQuestions';
import AuditApplicationNav from './AuditApplicationNav';
import AuditApplicationReview from './AuditApplicationReview';
import FormError from './layout/FormError';
import Loading from './sharedComponents/Loading';
import SalesforceCommunicator from '../communicators/SalesforceCommunicator';
import QuestionValidator from '../validators/QuestionValidator';
import { useLocale, useProvider, useApi } from '../helpers/hooks';

const Container = styled.div`
  margin: 0 auto;
  padding: 3rem;
  max-width: 1200px;

  @media (max-width: 500px) {
    padding: 1rem;
  }
`;

const AuditApplication = ({ floodZone, caseId }) => {
  const provider = useProvider('auditApplication');
  const locale = useLocale();
  const { salesforceApi } = useApi();
  const { nextWebstatus, navCopy } = provider;
  const { providerQuestions, providerAnswers } = JSON.parse(
    JSON.stringify(provider)
  );
  const [answers, setAnswers] = useState(providerAnswers);
  const [questions, setQuestions] = useState(providerQuestions);
  const [isReviewing, setIsReviewing] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [numberOfApplicants, setNumberOfApplicants] = useState(0);

  useEffect(() => {
    // If user's property is X they do not need to provide proof of flood insurance
    if (floodZone === 'X') {
      const filteredQuestions = questions.filter(
        (question) => question.title !== 'Proof of Flood Insurance'
      );
      const filteredAnswers = answers.filter(
        (answer) => Object.keys(answer)[0] !== 'flood_insurance'
      );
      setQuestions(filteredQuestions);
      setAnswers(filteredAnswers);
    }
    setIsLoading(false);
  }, []);

  // TODO remove this and update state with setAnswers instead of pushing
  const isIncludedInAnswers = (key) => {
    let isIncluded = false;
    answers.forEach((entry) => {
      if (Object.keys(entry).includes(key)) {
        isIncluded = true;
      }
    });
    return isIncluded;
  };

  const processAuditApplication = () => {
    // Add webstatus to answers object. Should be added on first submit,
    // but in case of an error, resubmitting shouldn't add it again.
    if (!isIncludedInAnswers('webstatus')) {
      answers.push({ webstatus: nextWebstatus });
    }
    // filter Files from Answers array
    const files = answers.filter((answer) => {
      const answerValues = Object.values(answer);
      if (answerValues[0].name) {
        return answerValues;
      }
      return null;
    });
    // append Files to FormData object
    const formAnswers = new FormData();
    files.forEach((file) => {
      const fileData = Object.values(file);
      const key = Object.keys(file);
      formAnswers.append(key[0], fileData[0]);
    });
    // filter Strings from Answers array
    const textAnswers = answers.filter((answer) => {
      const answerValues = Object.values(answer);
      if (!answerValues[0].name) {
        return answerValues;
      }
      return null;
    });
    // remove empty applicant created by provider and hidden from form
    textAnswers.splice(numberOfApplicants, 1);
    // append all String answers as an array to be filtered by controller
    formAnswers.append('answers', JSON.stringify(textAnswers));
    return formAnswers;
  };

  const handleSubmitFailure = () => {
    setHasError(true);
    window.location.href = '#audit-application';
  };

  const handleSubmitSuccess = (data) => {
    const statusPageUrl = `/${locale}/audit-status/${data.kase_external_id}`;
    window.location.href = statusPageUrl;
  };

  // Store answer validity in array and pass to surveyNav
  const getAnswersValidity = () =>
    questions.map((currentQuestion, index) =>
      QuestionValidator.validate(currentQuestion, answers[index])
    );

  const submit = async () => {
    setIsLoading(true);
    const processedAnswers = await processAuditApplication();
    try {
      const result = await SalesforceCommunicator.postAuditApplication(
        salesforceApi,
        caseId,
        processedAnswers
      );
      handleSubmitSuccess(result);
    } catch (error) {
      handleSubmitFailure();
    }
  };

  // Controls answer validation and action buttons
  const renderNav = () => {
    const validityArray = getAnswersValidity();
    return (
      <AuditApplicationNav
        navCopy={navCopy}
        isReviewing={isReviewing}
        answersValid={validityArray}
        setIsReviewing={setIsReviewing}
        submit={submit}
      />
    );
  };

  if (isLoading) {
    return <Loading provider={provider} height="500px" />;
  }
  if (hasError) {
    return <FormError provider={provider} setHasError={setHasError} />;
  }

  return (
    <Container id="audit-application">
      {isReviewing ? (
        <AuditApplicationReview answers={answers} />
      ) : (
        <AuditApplicationQuestions
          setAnswers={setAnswers}
          setQuestions={setQuestions}
          setNumberOfApplicants={setNumberOfApplicants}
          numberOfApplicants={numberOfApplicants}
          questions={questions}
          answers={answers}
        />
      )}
      {renderNav()}
    </Container>
  );
};

AuditApplication.propTypes = {
  floodZone: PropTypes.string.isRequired,
  caseId: PropTypes.string.isRequired
};

export default AuditApplication;
