import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { lighten, darken } from 'polished';
import { borderRadius, cubicBezier, colors } from '../../styles/variables';

const Link = styled.a.attrs((props) => ({
  target: props.external ? '_blank' : undefined,
  rel: props.external ? 'noreferrer noopener' : undefined
}))`
  display: inline-block;
  font-family: GT-Walsheim-Pro-Light, 'Helvetica Neue';
  border-radius: ${borderRadius};
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
  transition: transform 0.1s ${cubicBezier.ease};
  border: none;
  color: white;
  background-color: ${(props) => props.color};
  padding: 0 ${(props) => (props.small ? '25px' : '40px')};
  height: ${(props) => (props.small ? '40px' : '60px')};
  line-height: ${(props) => (props.small ? '40px' : '60px')};
  font-size: ${(props) => (props.small ? '1rem' : '1.1rem')};

  &:hover {
    background-color: ${(props) => darken(0.05, props.color)};
    text-decoration: none;
  }

  &:disabled {
    border: 1px solid ${colors.grayScale[1]};
    cursor: not-allowed;
    color: ${colors.grayScale[1]};
  }

  &:active {
    transform: translate3d(0, 2px, 0);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px ${lighten(0.3, colors.grayScale[1])};
  }
`;

const GhostLink = styled(Link)`
  border: 1px solid ${(props) => props.color};
  color: ${(props) => props.color};
  background-color: transparent;

  &:hover {
    background-color: transparent;
    border: 1px solid ${(props) => darken(0.1, props.color)};
    color: ${(props) => darken(0.1, props.color)};
  }

  &:disabled {
    background-color: transparent;
    border: 1px solid ${colors.grayScale[1]};
    color: ${colors.grayScale[1]};
  }
`;

const ButtonLink = ({ url, text, color, ghost, external, small }) => {
  return (
    <Link
      as={ghost && GhostLink}
      href={url}
      color={color}
      small={small}
      external={external}
    >
      {text}
    </Link>
  );
};

ButtonLink.propTypes = {
  url: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  color: PropTypes.string,
  ghost: PropTypes.bool,
  external: PropTypes.bool,
  small: PropTypes.bool
};

ButtonLink.defaultProps = {
  color: colors.resilientBlue,
  ghost: false,
  external: false,
  small: false
};

export default ButtonLink;
