import BuildingFeatures from '../../constants/BuildingFeatures';

export default class BuildingFeatureHelper {
  static translateBuildingFeature(results) {
    const { buildingFeature } = results;
    if (buildingFeature === null || buildingFeature === undefined) {
      return results.basement
        ? BuildingFeatures.BASEMENT
        : BuildingFeatures.NO_BASEMENT_ENCLOSURE;
    }

    const crawlspace =
      buildingFeature === BuildingFeatures.ELEVATED_ON_CRAWLSPACE_FLOOD_VENTS ||
      buildingFeature ===
        BuildingFeatures.ELEVATED_ON_CRAWLSPACE_NO_FLOOD_VENTS;
    return crawlspace
      ? BuildingFeatures.ELEVATED_ON_CRAWLSPACE
      : buildingFeature;
  }
}
