import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from '../ui/Button';

const Container = styled.div`
  text-align: center;
  margin: 5rem 2rem;
  font-size: 4rem;

  @media (max-width: 769px) {
    font-size: 5rem;
  }
`;

const Content = styled.p`
  max-width: none;
  font-size: 2rem;
`;

const FormError = ({ provider, setHasError }) => {
  return (
    <Container>
      <h4>{provider.errorTitle}</h4>
      <Content>{provider.errorBody}</Content>
      <Button type="button" onClick={() => setHasError(false)}>
        {provider.tryAgain}
      </Button>
    </Container>
  );
};

FormError.propTypes = {
  provider: PropTypes.object.isRequired,
  setHasError: PropTypes.func.isRequired
};

export default FormError;
