import profile from './Profile.zh-CN';

export default {
  meta: {
    title: '我的房屋弹性审计状态',
    url: 'https://www.floodhelpny.org/zh-CN/audit-status'
  },
  previewHeader: '房屋恢复力报告预览',
  floodZoneProvider: profile.floodZoneProvider,
  auditRateProvider: {
    header: '您的保险费率，基于标高证明',
    current: '当前地图',
    future: '咨询地图',
    zone: '区域',
    perYear: '每年*',
    assumingMax: '*假设最大承保范围',
    numbersAreEstimates:
      '出于许多原因，这些数字是估计值。要了解更多信息，请访问 ',
    termsAndConditions: '条款'
  },
  errorText:
    '出错。<a href="mailto:info@floodhelpny.org">请联系我们</a> 如果继续发生这种情况',
  loadingText: '加载中……'
};
