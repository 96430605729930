import React, { useEffect } from 'react';
import { Section } from './ui/StyledResourcePageElements';
import styled from 'styled-components';
import ListWithIconAndText from './layout/ListWithIconAndText';
import ButtonLink from './ui/ButtonLink';
import Card from './layout/Card';
import { useProvider, useLocale, useFaq } from '../helpers/hooks';

const ListWithoutDescription = styled(ListWithIconAndText)`
  h4 {
    margin-bottom: 0;
  }
`;

const AboutAuditProgramSteps = () => {
  const provider = useProvider('aboutParametricProgramSteps');
  const locale = useLocale();

  const { setFaqZone } = useFaq();

  useEffect(() => {
    setFaqZone('understanding-flood-insurance');
  }, []);

  return (
    <>
      <Section>
        <h5>{provider.header}</h5>
        <ListWithoutDescription items={provider.list} />
        <Card>
          <div>
            <p>{provider.madePossibleBy}</p>
          </div>
          <ButtonLink
            url={`/${locale}/flood-recovery-program-intake-form`}
            text={provider.buttonText}
          />
        </Card>
      </Section>
    </>
  );
};

export default AboutAuditProgramSteps;
