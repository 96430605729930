import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '../styles/variables';
import { useLocale, useProvider } from '../helpers/hooks';

const Badge = styled.div`
  text-align: center;
  background-color: ${colors.paleYellow};
  border-radius: 50%;
  padding-top: 40px;
  padding-left: 40px;
  padding-right: 40px;
  width: 250px;
  height: 250px;
  overflow: hidden;
  border: 15px white solid;

  p {
    font-size: 14px;
    margin: 0;
  }

  h3 {
    font-size: ${(props) => (props.locale === 'en' ? '60px' : '30px')};
    font-family: 'GT-Walsheim-Pro-Bold';
  }
`;

const FreeBadge = ({ className }) => {
  const provider = useProvider('freeBadge');
  const locale = useLocale();

  return (
    <Badge className={className} locale={locale}>
      <p>{provider.audit}</p>
      <h3>{provider.free}</h3>
      <p>{provider.value}</p>
    </Badge>
  );
};

FreeBadge.propTypes = {
  className: PropTypes.string
};

FreeBadge.defaultProps = {
  className: ''
};

export default FreeBadge;
