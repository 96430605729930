export default {
    currentlyServed: '当前服务区域',
    neighborhood: {
      header: '社区：',
      content:
        'Bensonhurst、Bergen Beach、Brighton Beach、Canarsie、Coney Island、Gravesend、Georgetown、Gerritsen Beach、Howard Beach、Lower Manhattan、Manhattan Beach、Marine Park、Mill Basin、Mill Island、Red Hook、Rockaway East、Seagate 和 Sheepshead Bay。'
    },
    priority: {
      header: '资质优先标准：',
      criteria: [
        {
          key: 'income',
          copy: '收入水平'
        },
        {
          key: 'sandy',
          copy: '无论您是否受到飓风桑迪的伤害'
        },
        {
          key: 'certificate',
          copy: '无论您是否有标高证明'
        }
      ]
    },
    downloadEligibilityForm:
      '在<a href="/samples/backwater-valve-eligibility.pdf">此处</a>下载完整的资质要求。'
  };
  