import React from 'react';
import styled from 'styled-components';
import { colors } from '../styles/variables';
import ResourceLinks from './ResourceLinks';
import { useProvider } from '../helpers/hooks';

const Wrapper = styled.section`
  padding: 4rem 7rem 7rem 7rem;
  background-color: ${colors.grayScale[0]};

  @media (max-width: 860px) {
    padding: 5rem 1rem;
  }
`;

const Header = styled.h2`
  text-align: center;
  margin-bottom: 3rem;

  @media (max-width: 860px) {
    font-size: 2rem;
  }
`;

const ReduceYourRisk = () => {
  const provider = useProvider('reduceYourRisk');

  return (
    <Wrapper>
      <Header>{provider.title}</Header>
      <ResourceLinks withBorders />
    </Wrapper>
  );
};

export default ReduceYourRisk;
