export default {
    loadingText: 'Загрузка…',
    addressInput: {
      subHeading: '',
      placeholder: 'Введите ваш адрес',
      action: 'Приступайте'
    },
    useAutocomplete: 'Выберите адрес из раскрывающегося списка',
    lookupError: {
      title: 'Ошибка',
      error:
        'Проверьте адрес или попробуйте еще раз позже. Если вам кажется, что вы получили это сообщение по ошибке, позвоните в нашу Центральную станцию домовладельцев по телефону 646-786-0888.',
      tryAgain: 'Попробуйте еще раз'
    },
    apiError: {
      title: 'Ошибка',
      error:
        "К сожалению наши серверы не смогли получить ответ от службы поиска адреса Google. Позвоните в нашу Центральную станцию домовладельцев по телефону 646-786-0888 если у вас есть вопросы.",
      tryAgain: 'Попробуйте еще раз'
    },
    multipleResultsError: {
      title: 'Ошибка',
      error:
        "Мы сожалеем. Существуют множественные совпадения введенного вами адреса. Проверьте адрес или попробуйте еще раз позже. Если вам кажется, что вы получили это сообщение по ошибке, позвоните в нашу Центральную станцию домовладельцев по телефону 646-786-0888.",
      tryAgain: 'Попробуйте еще раз'
    },
    outsideNycError: {
      title: 'Ошибка',
      error:
        "Мы сожалеем. Данный адрес похоже не соответствует общественно-доступным данным города Нью-Йорк. Если вам кажется, что вы получили это сообщение по ошибке, позвоните в нашу Центральную станцию домовладельцев по телефону 646-786-0888.",
      tryAgain: 'Попробуйте другой адрес'
    },
    tooBroadError: {
      title: 'Можно поконкретнее?',
      error:
        'Сайт FloodHelpNY.org предоставляет информацию о страховании от наводнения и вариантах устойчивости на основании индивидуальных адресов. Введите ваш домашний адрес или адрес, который вы хотите искать. Или если вам кажется, что вы получили это сообщение по ошибке, позвоните в нашу Центральную станцию домовладельцев по телефону 646-786-0888.',
      tryAgain: 'Повторите попытку'
    }
  };
