import testimonialPatti from '../images/testimonial-patti.jpg';
import testimonialCarla from '../images/testimonial-carla.jpg';
import testimonialLucia from '../images/testimonial-lucia.jpg';

export default {
  title: 'Si aumentan las mareas, aumentan las tarifas',
  subtitle:
    'Lea el informe histórico del Centro sobre el seguro contra inundaciones y la crisis de asequibilidad de la Ciudad de Nueva York',
  people: [
    {
      name: 'Patti',
      neighborhood: 'Midland Beach',
      image: testimonialPatti
    },
    {
      name: 'Carla',
      neighborhood: 'Canarsie',
      image: testimonialCarla
    },
    {
      name: 'Lucia',
      neighborhood: 'Coney Island',
      image: testimonialLucia
    }
  ],
  explanation:
    'El incremento de las tarifas del seguro contra inundaciones y el aumento de los niveles del mar tienen la posibilidad de desplazar, de manera definitiva, a los neoyorquinos de la clase media y trabajadora de los vecindarios costeros. Sin una intervención conjunta, el aumento de las primas de seguro podría conducir a un futuro en donde las costas de la ciudad sean asequibles solamente para sus residentes más ricos.',
  link: 'Más información'
};
