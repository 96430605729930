export default {
  xZone: 'Risk modere',
  aeZone: 'Gwo risk',
  aoZone: 'Gwo risk',
  veZone: 'Pi Gwo risk lan',
  noneZone: 'Risk minimòm',
  baseFloodElevation: 'Elevasyon pou Inondasyon Debaz (BFE)',
  xToolTip:
    'Zòn X lan se yon zòn risk modere: pwopriyete yo nan zòn sa a nan risk mwayen pou inondasyon pandan pwochen tanpèt grav lan.',
  aeToolTip:
    'Zòn AE an se yon zòn gwo risk: pwopriyete yo nan zòn sa a nan gwo risk inondasyon pandan pwochen tanpèt grav lan.',
  aoToolTip:
    'Zòn AO an se yon zòn gwo risk: asirans kont inondasyon an obligatwa epi nou atann yon gwo risk pou inondasyon.',
  veToolTip:
    'V an se zòn pi gwo risk lan nan Vil New York: pwopriyete yo nan zòn sa a gen pi gwo risk pou inondasyon pandan pwochen tanpèt grav lan.',
  floodZoneUrl: '/ht_HT/understanding-flood-insurance',
  learnMoreAboutFloodZones: {
    copy: 'Aprann plis apwopo zòn inondasyon yo',
    linkText: ' la'
  },
  floodZoneExplanations: {
    ae: {
      header:
        'Batiman ki nan zòn gwo risk (AE) yo gen posiblite pou inondasyon grav—ki ka depase plizyè pye.',
      copy:
        'Asirans kont inondasyon obligatwa nan yon zòn Gwo Risk si ou gen yon ipotèk.'
    },
    ve: {
      header:
        'Abitan New York k ap viv nan zòn ki gen gwo risk konnen posiblite pou domaj lan reyèl e fè demach kounya ekonomize lajan, tan ak enèji pi devan.',
      copy:
        'Asirans kont inondasyon obligatwa nan yon zòn Gwo Risk si ou gen yon ipotèk.'
    },
    x: {
      header:
        'Zòn risk moder yo gen posiblite pou jwenn plizyè pous inondasyon—e pous sa yo ka koute chè. Toudepan pwopriyete ou an, senpman sis pous dlo ka lakoz domaj enpòtan.',
      copy:
        'Piske tarif asirans kont inondasyon yo se FEMA ki etabli yo, tarif yo pa varye soti nan yon konpayi asirans pou al nan yon lòt. Men anpil ajan asirans pa konprann règ yo trè byen e fè yon pakèt erè nan evalyasyon.'
    },
    none: {
      header:
        'Chanje risk inondasyon epi chanje zòn pral finalman afekte nou tout. San aksyon, enpak sa a ka gen repèkisyon atravè tout ekonomi Vil lan.',
      copy:
        'Prèske 20% nan reklamasyon asirans kont inondasyon yo soti nan zòn risk modere ak minimòm.'
    },
    ao: {
      header:
        'Batiman ki nan zòn gwo risk AO yo gen potansyèl pou inondasyon nan zòn toupre rivyè oswa toran, ansanm ak inondasyon lejè chak ane.',
      copy:
        'Asirans kont inondasyon obligatwa nan yon zòn Gwo Risk si ou gen yon ipotèk.'
    }
  }
};
