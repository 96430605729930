export default {
  whatYouToldUs: 'LO QUE USTED NOS DIJO',
  property: 'Propiedad',
  basicInfo: 'Información básica',
  income: 'Ingresos:',
  household_size: 'Tamaño de la familia:',
  contactInfo: 'Información de Contacto',
  name: 'Nombre:',
  email: 'Correo electrónico:',
  homePhone: 'Teléfono Residencial:'
};
