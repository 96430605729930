import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useProvider } from '../helpers/hooks';

const Container = styled.div`
  margin: 0 3rem;

  @media (max-width: 500px) {
    margin: 0 1rem;
  }
`;

// called by: AuditStatusContainer
const AuditApplicationHeader = ({ application }) => {
  const provider = useProvider('auditApplication');
  const {
    contact: { first_name, last_name },
    property_contact_detail: {
      property: { combined_address }
    }
  } = application;

  return (
    <Container id="audit-application-header">
      <h5>{`${provider.applicant} ${first_name} ${last_name}`}</h5>
      <h5>{`${provider.address} ${combined_address}`}</h5>
    </Container>
  );
};

AuditApplicationHeader.propTypes = {
  application: PropTypes.object.isRequired
};

export default AuditApplicationHeader;
