import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import RateCalculatorRadioInput from './RateCalculatorRadioInput';
import ElevatedBuildingNavigator from '../helpers/calculatorNavigation/ElevatedBuildingNavigator';

const RateCalculatorQuestionElevatedBuilding = ({
  provider,
  answers,
  setAnswers,
  setNextQuestion
}) => {
  useEffect(() => {
    if (answers.elevatedBuilding) {
      setNextQuestion(ElevatedBuildingNavigator.nextComponent(answers));
    }
  }, [answers, setNextQuestion]);

  return (
    <div className="question number-of-steps-question">
      <h4>{provider.question}</h4>
      <RateCalculatorRadioInput
        radioKey="elevated-building"
        responseField={provider.responseField}
        answer={answers.elevatedBuilding}
        setResponse={(e) =>
          setAnswers((prevAnswers) => ({
            ...prevAnswers,
            elevatedBuilding: e.target.value
          }))}
      />

      <div className="promo">
        *
        {provider.disclaimer}
      </div>
    </div>
  );
};

RateCalculatorQuestionElevatedBuilding.propTypes = {
  provider: PropTypes.object.isRequired,
  answers: PropTypes.object.isRequired,
  setAnswers: PropTypes.func.isRequired,
  setNextQuestion: PropTypes.func.isRequired
};

export default RateCalculatorQuestionElevatedBuilding;
