export default class GoogleMapsGeocodeHelper {
  constructor(apiData) {
    const address = apiData.results.filter(
      loc => (loc.types.indexOf('street_address') !== -1)
    )[0];
    this.address = address;
  }

  getAddress() {
    if (!this.address) { return null; }

    return (
      {
        latLng: this.address.geometry.location,
        formattedAddress: this.address.formatted_address,
        streetNumber: this.getStreetNumber(),
        street: this.getStreet(),
        county: this.getCounty(),
        country: this.getCountry(),
        postalCode: this.getPostalCode(),
        city: this.getCity(),
        neighborhood: this.getNeighborhood()
      }
    );
  }

  findLongNameByType(type) {
    const entry = this.address.address_components.filter(
      data => data.types.includes(type)
    )[0];
    return entry && entry.long_name;
  }

  getStreetNumber() {
    return this.findLongNameByType('street_number');
  }

  getStreet() {
    return this.findLongNameByType('route');
  }

  getCounty() {
    return this.findLongNameByType('administrative_area_level_2');
  }

  getCountry() {
    return this.findLongNameByType('country');
  }

  getPostalCode() {
    return this.findLongNameByType('postal_code');
  }

  getCity() {
    return this.findLongNameByType('sublocality');
  }

  getNeighborhood() {
    return this.findLongNameByType('neighborhood');
  }
}
