export default {
    header: 'Предварительный расчет  страхования от наводнения',
    beta: 'BETA',
    link: 'Посмотрите ваш полный предварительный расчет ',
    currentMapStart: 'Текущие карты ( ',
    currentMapEnd: ' зона)',
    futureMapStart: 'Ориентировочные карты (',
    futureMapEnd: ' зона)',
    assumingMax: '*Предполагая максимальное покрытие',
    numbersAreEstimates:
      'Эти цифры являются приблизительными по многим причинам. Для получения допольнительной информации о них обратитесь к нашим ',
    termsAndConditions: 'Правилам и условиям',
    floodZoneRateProvider: {
      perYear: 'В год*'
    },
    rateAdjusterProvider: {
      adjustYourCoverage: 'Корректировка вашего страхового покрытия ',
      structuralCoverage: 'Страховое покрытие конструкции',
      structuralDeductible: 'Вычитаемая франшиза конструкции',
      contentCoverage: 'Страховое покрытие содержимого',
      contentDeductible: 'Вычитаемая франшиза содержимого',
      collapse: 'Разрушение'
    }
  };
  