import React, { useState, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import styled from 'styled-components';
import Fuse from 'fuse.js';
import NewsPost from './NewsPost';
import { HeaderSection, Subheader } from './ui/StyledResourcePageElements';
import { useProvider } from '../helpers/hooks';
import typewriter from '../images/typewriter.svg';
import { colors, borderRadius } from '../styles/variables';
import Loading from './sharedComponents/Loading';
import Button from './ui/Button';
import Pagination from './Pagination';
import magnifyingGlass from '../images/magnifying-glass.svg';

// Redefine constant based on how many posts are wanted per page
const postsPerPage = 3;

const Wrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
`;

const NewsHeader = styled(HeaderSection)`
  margin: 0 auto;
  padding-top: 200px;
  padding-bottom: 50px;
`;

const SearchBarWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 1px solid ${colors.grayScale[1]};
  border-radius: ${borderRadius};
  height: 3rem;
  width: 500px;
  margin: 0 auto 50px;

  @media (max-width: 600px) {
    width: 80%;
  }
`;

const SearchBar = styled.input`
  border: none;
  flex: 1 1 auto;
  font: inherit;
  padding-left: 1rem;
  font-size: 1rem;
  width: 90%;

  :focus {
    outline: none;
    border: 1px transparent;
    box-shadow: none;
  }

  @media (max-width: 460px) {
    font-size: 0.75rem;
    padding-left: 0.5rem;
  }
`;

const ButtonImage = styled.img`
  height: 20px;
`;

const SubmitButton = styled(Button)`
  height: auto;
  padding: 0 1rem;
`;

const NoPosts = styled.div`
  margin: 5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    margin-right: 1rem;
  }
`;

let fuse;

const News = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [allPosts, setAllPosts] = useState([]);
  const [originalAmountOfPages, setOriginalAmountOfPages] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [totalPageCount, setTotalPageCount] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const provider = useProvider('news');

  const getPosts = async () => {
    try {
      const response = await axios.get('/api/v1/news');
      if (response.status === 200) {
        const postsSortedByFeatured = response.data.sort((post1, post2) => {
          if (post1.sticky) {
            return -1;
          }

          if (post2.sticky) {
            return 1;
          }

          return 0;
        });
        fuse = new Fuse(postsSortedByFeatured, {
          keys: ['title.rendered', 'content.rendered'],
          ignoreLocation: true,
          threshold: 0.2
        });
        const pagesResponse = Math.ceil(response.data.length / postsPerPage);
        setOriginalAmountOfPages(pagesResponse);
        setTotalPageCount(pagesResponse);
        setSearchResults(postsSortedByFeatured);
        setAllPosts(postsSortedByFeatured);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getPosts();
  }, []);

  const currentPagePosts = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * postsPerPage;
    const lastPageIndex = firstPageIndex + postsPerPage;
    const pagePosts = searchResults.slice(firstPageIndex, lastPageIndex);

    return pagePosts;
  }, [currentPage, searchResults]);

  const updateSearch = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    if (term === '') {
      setSearchResults(allPosts);
      setTotalPageCount(originalAmountOfPages);
    } else {
      const results = fuse.search(term);
      setSearchResults(results.map((result) => result.item));
      setCurrentPage(1);
      setTotalPageCount(Math.ceil(results.length / postsPerPage));
    }
  };

  const updateCurrentPage = (pageNumber) => {
    setCurrentPage(pageNumber);
    scrollToTop();
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const renderPosts = () =>
    currentPagePosts.map((post) => <NewsPost key={post.id} post={post} />);

  if (isLoading) {
    return <Loading provider={provider} height="500px" />;
  }

  return (
    <>
      <Helmet>
        <title>{provider.meta.title}</title>
        <link rel="canonical" href={provider.meta.url} />
        <meta name="description" content={provider.meta.description} />
        <meta property="og:title" content={provider.meta.ogTitle} />
        <meta
          property="og:image"
          content="https://www.floodhelpny.org/images/understanding_flood_insurance.png"
        />
        <meta property="og:description" content={provider.meta.description} />
        <meta property="og:url" content={provider.meta.url} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image:alt" content={provider.meta.imageAlt} />
      </Helmet>
      <NewsHeader>
        <h1>{provider.title}</h1>
        <Subheader>{provider.subHeading}</Subheader>
      </NewsHeader>
      <SearchBarWrapper>
        <SearchBar
          placeholder={provider.search}
          value={searchTerm}
          onChange={updateSearch}
        />
        <SubmitButton bgColor="transparent" ghost>
          <ButtonImage src={magnifyingGlass} alt="magnifying glass" />
        </SubmitButton>
      </SearchBarWrapper>
      <Wrapper>
        {searchResults.length > 0 ? (
          renderPosts()
        ) : (
          <NoPosts>
            <img src={typewriter} alt="typewriter" />
            <span>{provider.noPosts}</span>
          </NoPosts>
        )}
      </Wrapper>
      <Pagination
        onPageChange={updateCurrentPage}
        currentPage={currentPage}
        totalPageCount={totalPageCount}
      />
    </>
  );
};

export default News;
