export default class ElevatedBuildingNavigator {
  static nextComponent(results) {
    // TODO the helper cannot return false. Need to speak with Aaron about
    // logic flows
    if (results.elevatedBuilding === false) {
      return null;
    }

    if (results.hasSteps !== undefined && results.hasSteps !== null) {
      return null;
    }
    return 'numberOfSteps';
  }
}
