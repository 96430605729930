export default {
    meta: {
      title: 'Политика конфиденциальности',
      url: 'https://www.floodhelpny.org/ru/privacy',
      description: null,
      imageAlt: null
    },
    title: 'Политика конфиденциальности',
    intro: {
      effective_date: 'Вступила в действие 24 мая 2019',
      body:
        'Спасибо за посещение сайта FloodHelpNY.org (“Сайта”). Данная Политика конфиденциальности применима только к Сайту, который котролируется Цетром районов города Нью-Йорк, Inc. (“мы”, “наш” or “нас”). Данная Политика конфиденциальности касается только информации, которыю мы собираем через Сайт. ИСПОЛЬЗУЯ САЙТ, ВЫ ПРИНИМАЕТЕ ПРАВИЛА И УСЛОВИЯ ДАННОЙ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ И ДАЕТЕ СОГЛАСИЕ НА ОБРАБОТКУ ЛИЧНОЙ ИНФОРМАЦИИ В ЦЕЛЯХ, УКАЗАННЫХ НИЖЕ. ЕСЛИ ВЫ НЕ ПРИНИМАЕТЕ ПРАВИЛА И УСЛОВИЯ ДАННОЙ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ, НЕ ИСПОЛЬЗУЙТЕ ЭТОТ САЙТ.',
      specific_info:
        'Конкретная информация относительно нашего порядка обращения с персональными данными детально изложена ниже.',
      info_we_collect: 'Информация, которую мы собираем: личная и не содержащая персональных данных',
      we_collect:
        'Мы собираем два вида информации у посетителей Сайта: (1) личные сведения; и (2) сведения, не содержащие персональных данных, такие как ваш IP адрес или куки.'
    },
    personal_info: {
      title: 'Личные сведения',
      body:
        'Когда мы используем термин "Личные сведения" мы подразумеваем информацию, которая позволяет другим установить вашу личность или связаться с вами. Например, мы можем взять ваш адрес электронной почты, если вы дадите согласие на получение информационной рассылки или другой информации от нас. Мы также можем соотнести его с вашим адресом или другими личными сведениями, которые вы предоставили для того, чтобы подтвердить, что вы соответствуете критериям на получение услуг от нас и что информация, которую мы предоставим, будет скорее всего иметь к вам отношение.',
      examples: 'Примеры личной информации, собираемой Сайтом: ',
      email: 'Адрес электронной почты',
      address: 'Адрес',
      phone: 'Номер телефона',
      homeowner: 'Информация, относящаяся к владению вашей собственности',
      no_obligation:
        'В то время как вы не обязаны предоставлять запрошенную информацию, если вы отказываетесь это сделать, то мы можем быть не в состоянии предоставить вам определенные услуги. Если вы не хотите, чтобы мы собирали ваши личные сведения, то не предоставлйте их нам.'
    },
    non_personal_info: {
      title: 'Сведения, не содержащие персональных данных',
      body:
        '“Сведения, не содержащие персональных данных" может быть технической или демографической информацией, такой как ваш возраст, пол, индекс или другие географические данные или интересы. Сведения, не содержащие персональных данных НЕ удостоверяют вашу личность. Вот некоторые примеры сведений, не содержащих персональных данных, которые собираются через Сайт и показывают, как эта информация используется:',
      ip:
        'Адрес Интернет Протокола  (IP)  – Ваш IP адрес это номер, который позволяет компьютерам, подсоединенным к интернету знать, куда вам посылать данные  – такие как веб-странички, которые вы просматриваете. Мы используем эту информацию, чтобы доставить наши веб-страницы вам по требованию, чтобы   подогнать наш Сайт под интересы наших пользователей и чтобы определить загруженность нашего Сайта.',
      web_beacons:
        'Веб-маяки (также известные как “прозрачные gif-файлы,” “веб-жучки” или “пиксельные теги”) – “Веб-маяки” это крошечные изображения с уникальными идентификационными кодами, схожими по функции с куки, которые используются для того, чтобы позволить нам посчитать пользователей, которые посетили определенные страницы Сайта и помочь определить эффективность наших программ и услуг. При использовании в сообщениях электронной почты в формате HTML, веб-маяки могут сообщить отправителю, была ли почта открыта.В отличие от куки, которые хранятся на жестком диске пользователя, веб-маяки незаметно вписаны в веб-страницу.',
      demographic:
        'Демографическая информация - "Демографической информацией" может быть ваш пол, возраст, индекс или интересы, которые собираются через Сайт автоматически. Мы используем эту информацию для того, чтобы предоставить вам индивидуальные услуги и чтобы проанализировать тренды для обеспечения того, чтобы информация, предоставляемая Сайтом, соответствовала вашим нуждам. Имейте в виду, что мы также считаем обобщенную информацию, которая не может устанавливать личность, не содержащей персональных данных.',
      addresses:
        'Разыскиваемые адреса и соответствующая информация о зонах наводнения - Мы сохраним адреса, впечатанные в наш поисковик, вместе с результатом. Мы будем использовать эту информацию на обобщенном уровне, чтобы повысить интерес пользователя в определенных географических зонах, а также чтобы обобщить полученные результаты риска наводнения.',
      above_list:
        'Указанный выше список предоставляет примеры информации, не содержащей персональных данных, собираемой Сайтом. Мы можем собрать указанную выше информацию, не содержащую персональных данных, у всех посетителей Сайта. Если вы не хотите, чтобы у нас был доступ к этой информации, не посещайте наш Сайт.'
    },
    use_of_information: {
      title: 'Использование информации, собранной Сайтом',
      personal_info: 'Личные сведения',
      personal_info_body:
        'Мы используем ваши личные сведения, собранные Сайтом, прежде всего в следующих целях:',
      deliver_services: 'Чтобы доставить вам услуги',
      provide_info:
        'Чтобы предоставить вам информацию относительно программ и услуг в вашем районе',
      verify_email: 'Чтобы проверить ваш адрес электронной почты',
      answer_questions: 'Чтобы ответить на ваши вопросы или отозваться на другие запросы',
      contact: 'Чтобы связаться с вами относительно вашего использования Сайта или наших служб',
      notify_about_changes:
        'Чтобы уведомить вас о значительном изменении в данной Политике конфиденциальности или наших услугах, при необходимости',
      administer:
        'Чтобы другим образом одминистрировать Сайт или ваши взаимодействия с нашими услугами',
      other_purposes:
        'По любой другой причине, оглашенной в момент предоставления вами ваших личных данных',
      non_personal_info: 'Сведения, не содержащие персональных данных',
      non_personal_is_used:
        'Сведения, не содержащие персональных данных, используются как описано выше и другим образом, разрешенным соответствующим законом, включая комбинирование сведений, не содержащих персональных данных с личными данными.',
      non_personal_example:
        'Например, мы можем использовать Сведения, не содержащие персональных данных и данные об использовании чтобы (i) создать маркетинговый анализ  и отчеты, показывающие как наши пользователи, как коллективные группы, взаимодействуют с нашими службами, (ii) разработать отчеты, которые помогут нам и другим стратегическим третьим лицам определить, что интересует пользователей наших служб, как группу, или (iii) в целом улучшить нашу программу и предлагаемые услуги. Мы поделимся этими отчетами с Центром для районов Нью-Йорка и третьими лицами, помогающими нам в администрировании Сайта и наших услуг. Однако, эти отчеты не будут содержать никаких ваших личных сведений.'
    },
    sharing_and_disclosure: {
      title: 'Совместное использование и разглашение информации',
      personal_info: 'Личные сведения',
      personal_examples:
        'Примеры того, как мы можем использовать совместно или разглашать ваши личные сведения, включают следующее:',
      fulfill:
        'Для предоставления вам услуги. Например, вы запросите у нас помощь и/или подадите заявку на инспекцию по устойчивости дома, мы может подулиться вашими личными данными, чтобы предоставить вам эту услугу. В дополнение к этому, если вы свяжетесь с нами, чтобы подписаться на наше информационное издание, мы может использовать адрес вашей электронной почты, чтобы ответить на вашу просьбу.',
      affiliates_home_resiliency:
        'Чтобы поделиться со связанными сторонами, субподрядчиками, стратегическими партнерами, агентами, соответствующими федеральными властями, Секретариатом губернатора по восстановлению после ливневых бурь или их уполномоченными лицами, или другими независимыми лицами, предлагающими услуги как часть испекции по устойчивости дома. Эти лица могут использовать ваши личные сведения для того, чтобы связаться с вами относительно испекции по устойчивости дома и могут использовать эту информацию в целях собственного исследования, администрирования или ведения бизнеса. Если вы не хотите, чтобы мы делились вашими личными сведениями таким образом, не предоставляйте нам эту информацию.',
      affiliates_other:
        'Чтобы поделиться со связанными сторонами, стратегическими партнерами, агентами, или другими независимыми лицами, предлагающими программы или услуги, которые по нашему мнению могут быть вам интересны, или с теми, кому ваши дичные свдения требуются для проведения исследования, в административных целях или для внутреннего бизнеса. Эти стороны могут использовать ваши личные сведения для того, чтобы связаться с вами относительно предлагаемых программ или услуг, или могут использовать эту информацию в целях собственного исследования, администрирования или ведения бизнеса. Если вы не хотите, чтобы мы делились вашими личными сведениями таким образом, не предоставляйте нам эту информацию.',
      unaffiliated:
        'Чтобы поделиться с независимыми поставщиками услуг, агентами, независимыми подрядчиками, помогающими нам содержить наш Сайт и предоставляющими нам другие административные услуги (включая, но не ограничиваясь ими, обработку ваших запросов, обслуживание клиентов, содержание и анализ данных и рассылку нотификаций потребителям от нашего имени). Мы стремимся к гарантированию того, чтобы эти независимые поставщики услуг не использовали личные сведения в каких-либо других целях, помимо предоставления административных услуг, за которые они отвечают. Так как эти независимые поставщики услуг, помогающие нам администрировать Сайт, будут иметь доступ к личным сведениям пользователей, если вы не хотите, чтобы у независимых поставщиков услуг был доступ к вашей информации, не регистрируйтесь или не давайте нам ваши личные сведения.',
      comply_with_law:
        'В целях соблюения закона или в добросовестном предположении, что такие действия необходимы, чтобы отвечать требованиям закона или выполнять условия юридического процесса, в котором мы участвуем, для охраны и защиты наших прав или собственности, сайта, или действуя в крайних обстоятельствах, чтобы обеспечить личную безопасность наших конечных пользователей.',
      third_part_corp:
        'Чтобы поделиться с третьими лицами, в качестве любого корпоративного реорганизационного процесса, включая, но не ограничиваясь, слияние, приобретение или продажу всех или практически всех наших активов.',
      track_and_analyze:
        'В целых отслеживания и анализа неудостоверимого и обобщенного пользования и объемной статистической информации от наших посетителей и клиентов и предоставления такой информации третьим лицам.',
      fraud_protection:
        'В целях защиты от потенциального мошенничества мы можем проверить с третьими лицами информацию, собранную с Сайта. В ходе такой проверки мы можем получить личные сведения о вас от таких служб.',
      personal_footer:
        'За исключением того, как это описано в данной Политике конфиденциальности или во время нашего запроса информации, мы никоим другим образом не используем, не делимся или как-либо разглашаем ваши личные сведения любым третьим лицам, помогающим нам администрировать Сайт или наши службы. Если вы не даете согласие на то, чтобы ваши личные сведения раглашались, как это описано выше, не предоставляйте их нам.',
      non_personal_info: 'Сведения, не содержащие персональных данных',
      non_personal_content:
        'Мы используем собранные на Сайте сведения, не содержащие персональных данных, как это указано выше, в Разделе B (2). Мы можем делиться этими сведениями, не содержащими персональных данных с третьими лицами, помогающими нам администрировать Сайт или наши службы.',
      children_under_13: 'Дети до 13 лет',
      children_under_13_content:
        'Сайт не ориентирован на детей до 13 лет и не собирает сознательно личные сведения у таких лиц. Если нам станет известно, что ребенок младше 13 лет предоставил нам личные сведения, то мы предпримем шаги по удалению такой информации.',
      opt_out: 'Отказ',
      opt_out_content:
        'Вы можете отказаться от получения маркетинговых обращений от нас или стратегических третьих сторон в любое время. Что касается электронной почты, вы можете отказаться от рассылки путем перехода по ссылке, указанной внизу любого электронного сообщения, которое вы получаете от нас, и и отказа от получения информации от нас. Мы обработаем ваш запрос на отказ от подписки как только сможем, но примите к сведению, что в некоторых случаях вы можете получить еще несколько сообщений до тех пор, пока отказ не будет обработан. Однако при этом примите к сведению, что отказ от получения маркетинговых обращений не избавит вас от получения транзакционных сообщений или электронных сообщений, которые мы обязаны рассылать.',
      third_parties: 'Разглашение третьим лицам',
      third_parties_content:
        'Если вы лтветите на какую-либо интерактивную рекламу или предложнение от третьих лиц (будь то по электронной почте, тексту или другим способом), или если вы запросите информацию о продукте, программе или услуге, предлагаемой третьей стороной, то ваши личные сведения будут разглашены этой третьей стороне. Иы не можем контролировать и предоставлять заявления о сборе и использовании вашей информации этими третьими сторонами. Если вы решите зарегистрироваться или подписаться на продукт или услугу такой третьей стороны, вся дальнейшая корреспонденция между вами и третьей стороной, без ограничения, любые возникающие в результате контрактные соглашения заключаются исключительно между вами и рекламодателем/третьей стороной. Мы не будет отвечать за выполнение соответствующих сроков, условий, заверений или гарантий, сделанных рекламодателем/третьей стороной в связи с вышеизложенным и иным образом не булут отвественны за ваше взаимодействие с такой третьей стороной.',
      correct_inaccuracies:
        'Как вы можете исправить какие-либо неточности ваших личных сведений?',
      correct_inaccuracies_body:
        'Если у вас возникло беспокойство отностельно точности информации, которую мы возможно собрали о вас и вы хотели бы получить доступ к этой информации, свяжитесь с нами по ',
      correct_inaccuracies_body_email: 'cnycn.org.',
      info_protected: 'Как ваша информация защищена?',
      info_protected_body:
        'Мы считаем конфидециальность и безопасность вашей информации чрезвычайно важными. Мы используем физические, технические и административные меры безопасности для обеспечения согранности и конфиденциальности ваших личных сведений и не будет предоставлять их третьим лицам, за исключением организаций, помогающих нам администрировать наш Сайт и службы, как это требуется в соответствии с законом или как это описано в Политике конфиденциальности. Однако, интренет - это не безопасная среда, и мы не можем дать 100% гарантию безопасности вашей информации; существует некоторый риск, что несанкционированная третья сторона может найти пути обхода наших мер безопасности или что информация, переданная по интернету, может быть перехвачена.',
      info_protected_body_2:
        'Если мы узнаем о вторжении в систему безопасности мы можем попытаться уведомить вас электронным путем, чтобы вы могли предпринять защитные меры. Используя Сайт или предоставляя нам личные сведения вы даете нам согласия на то, чтобы мы могли связаться с вами электронным образом относительно безопасности, конфиденциальности и административных вопросов, касающихся вашего использования Сайта. Мы можем поместить уведомление на нашем Сайте, если произойдет вторжение в систему безопасности. Мы таже можем послать сообщение по электронной почте на ваш адрес, который вы предоставили нам, приданных обстоятельствах или письмо на ваш предоставленный домашний адрес. В зависимости того, где вы живете, у вас может быть юридическое право на получение уведомления о вторжении в систему безопасности в письменном виде.',
      third_party_sites: 'Веб-сайты третьих лиц',
      third_party_sites_body:
        'Это заявление применимо исключительно к информации, собранной на Сайте. Сайт может содержать ссылки на другие веб-сайты. Мы не несем ответственность за правила и процедуры в отношении персональных данных или содержание этих других веб-сайтов.',
      do_not_track: 'Как мы реагируем на флаг Не отслеживать?',
      do_not_track_body:
        'Примите к сведению, что наш Сайт не поддерживает установку браузера "Не отслеживать" и в данный момент не участвует в каких-либо объектных структурах "Не отслеживать", которые позволяли бы нам отвечать на сигналы или другие механизмы от вас, относительно сбора ваших личных или не содержащих персональных данных сведений.',
      assignment: 'Передача',
      assignment_body:
        'В случае если все или часть наших активов будут проданы или приобретены другой стороной, или в случае слияния, вы предоствляете нам право на передачу ваших личных или не содержащих персональных данных сведений через Сайт.',
      changes: 'Изменения данной Политики конфиденциальности',
      changes_body:
        'Мы оставляем за собой право модифицировать данную Политику конфиденциальности по мере роста и улучшения наших услуг или в ответ на изменяющиеся юридические положения или потециальные проблемы бизнеса. Когда это произойдет, мы также изменим дату "вступления в силу" в верхней части Политики конфиденциальности. Относительно изменений в Политике конфиденциальности, которые могут  материально менее ограничивать наше использование или разглашение личныз сведений, которые вы нам предоставили, мы попытаемся получить ваше согласие до внедрения изменений путем рассылки уведомления на основной адрес электронной почты, который вы гам предоставили, или путем помещения заметного для всех уведомления на Сайте.'
    },
    questions: {
      title: 'Вопросы?',
      body:
        'Чтобы получить справки относительно Политики конфиденциальности пошлите сообщение по электронной почте по адресу ',
      email: 'info@floodhelpny.org.'
    }
  };
  