import certificate from '../images/certificate.svg';
import compass from '../images/compass.svg';
import home from '../images/home.svg';

export default {
  meta: {
    title: 'Сертификаты высотной отметки | FloodHelpNY.org',
    description:
      'Сертификаты высотной отметки могут помочь вам защитить вас от переплаты за страхование от наводнения.',
    ogDescription:
      'Сертификат высотной отметки - это документ, который защищает домовладельцев от переплаты за страхование от наводнения.',
    url: 'https://www.floodhelpny.org/ru/elevation-certificates'
  },
  title: 'Сертификаты высотной отметки',
  subheader:
    "Сертификат высотной отметки может помочь вам снизить стоимость вашей страховки. Вот что вам нужно знать об этом ключевом документе.",
  overview: {
    header: 'Обзор',
    body:
      'Сертификат высотной отметки - это документ, который защищает домовладельцев от переплаты за страхование от наводнения. Он находится в ведении FEMA и оформляется лицензированным инженером или инспектором-контролером. Данные, отображенные в сертификате, позволяют страховым компаниям оценить риск наводнения недвижимости более точно, путем подтверждения высоты над уровнем моря дома и усилий, которые были предприняты для того, чтобы минимизировать последствия от наводнений.'
  },
  about: [
    {
      header: 'Какая информация изложена на сертификате высотной отметки',
      body:
        'Три ключевых поля данных на сертификате высотной отметки это тип здания и его характеристики, самый нижний этаж и месторасположение недвижимости. Эти факторы все играют важную роль в определении вашего страхового взноса от наводнений.',
      image: certificate
    },
    {
      header: 'Как использовать сертификат высотной отметки',
      body:
        'Если вы хотите использовать ваш сертификат, вы должны предоставить его вашему страховому агенту в течение 90 дней с даты завершения инспекции, указанной в документе. На основании информации в вашем документе если вы соответствуете критериям на снижение взноса за вашу страховку, ваш агент должен изменить его на более низкий тариф.',
      image: compass
    },
    {
      header: 'Кому действительно необходим сертификат высотной отметки',
      body:
        'С изменениями, внесенными FEMA путем Оценки риска 2.0, сертификат высотной отметки больше не требуется для всех владельцев недвижимости. Однако, сертификаты высотной отметки могут все еще быть полезными для гарантии того, что вы получаете правильную расценку страхования от наводнения. Они также могут быть использованы для документации для вашего провайдера страховки любых значительных модификаций собственности, которые вы возможно произвели - например, поднятие вашего дома, установка дренажного клапана или поднятие коммуникаций - любая из которых может помочь снизить стоимость вашего страхования от наводнения.',
      image: home
    }
  ],
  howToGetOne: {
    header: 'Как получить сертификат высотной отметки',
    body:
      "Средняя стоимость сертификата высотной отметки может колебаться от 500 до 1000 долларов США. Попробуйте получить расценку от по крайней мере двух инспекторов для сравнения. Примерами профессиональных инспекторов служат лицензированный землеустроитель, инженер или архитектор, который уполномочен по закону оформить  сертификат высотной отметки. FEMA рекомендует найти профессионального инспектора путем проверки <a href='https://www.nysapls.org/search/custom.asp?id=2216' target='_blank' rel='noreferrer noopener'>профессиональной ассоциации землеустроителей</a> штата и ссылки на руководство <a href='https://www1.nyc.gov/site/housingrecovery/programs/elevation-certificate.page' target='_blank' rel='noreferrer noopener'>Управления городскими зданиями</a>.",
    tip: {
      header: 'Совет',
      body:
        'Предыдущий владелец недвижимости возможно уже имеет сертификат; если вы сейчас в процессе покупки недвижимости или недавно приобрели недвижимость, вы возможно хотели бы с ним связаться.'
    }
  },
  alreadyHaveOne: {
    header: 'Если у вас уже есть сертификат высотной отметки',
    body:
      'Если у вас уже есть сертификат высотной отметки, то вам не нужно будет подавать новый сертификат. Однако, если вы произвели какие-либо модификации недвижимости, чтобы минимизировать последствия от наводнений, вы возможно захотите получить обновленный сертификат, чтобы гарантировать получение правильного тарифа.'
  },
  expired: {
    header: 'На моем сертификате указано, что срок его действия истек в 2018 году. Он еще пригоден?',
    body:
      'Дата в верхнем правом углу вашего сертификата - это дата образца, который обычно обновляется FEMA каждые три года.  Дата гарантирует, что инженеры и землеустроители оформляют сертификаты высотной отметки используя текущий образец. Если у вас есть старый сертификат с этой датой, он все еще пригоден для определения тарифа. Если вы получаете сертификат высотной отметки в первый раз, или получаете обновленный сертификат, вы должны удостовериться, что ваш инженер или землеустроитель использует форму, датированную 30 ноября 2022г. Вы можете использовать форму, датированную 30 ноября 2022г до тех пор, пока FEMA не выпустит новую версию (иногда это может произойти через несколько лет после даты истечения срока).'
  },
  download: 'Загрузить сертификат',
  update: 'Обновить ваш сертификат'
};
