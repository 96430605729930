import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '../styles/variables';
import MapLegend from './MapLegend';
import GeocodeCommunicator from '../communicators/GeocodeCommunicator';
import Carto from '../helpers/Carto';
import GoogleMaps from '../helpers/GoogleMaps';
import { useLocale, useProvider, useGoogleMaps } from '../helpers/hooks';

const MapUI = styled.div`
  position: relative;
`;

const Container = styled.div`
  position: relative;
  flex: 1;
  height: 350px;
`;

const Map = styled.div`
  height: 350px;
`;

const AddressBox = styled.div`
  font-size: 1rem;
  position: absolute;
  left: 70px;
  top: 22px;
  padding: 1rem;
  background-color: white;
  border: 1px solid ${colors.grayScale[1]};

  a {
    margin-left: 5rem;
  }

  @media (max-width: 660px) {
    left: 0;
  }

  @media (max-width: 420px) {
    top: auto;
    bottom: 2rem;
    text-align: center;
  }
`;

const MapButtons = styled.div`
  position: absolute;
  right: 15px;
  top: 22px;
  font-size: 14px;
  display: flex;

  @media (min-width: 420px) and (max-width: 1024px) {
    right: auto;
    top: 240px;
    left: 70px;
  }

  @media (max-width: 768px) {
    right: auto;
    margin: 0 10px;
  }

  @media (max-width: 450px) {
    flex-direction: column;
  }
`;

const MapButton = styled.button`
  height: 40px;
  background-color: white;
  border-radius: 3px;
  border: none;
  padding: 0 40px;
  white-space: normal;

  @media (max-width: 420px) {
    padding: 0 20px;
  }

  &.selected {
    background-color: ${colors.resilientBlue};
    color: white;
  }

  &:last-child {
    margin-left: 1em;

    @media (max-width: 450px) {
      margin-left: 0;
    }
  }
`;

const StaticMap = ({ address }) => {
  const provider = useProvider('staticMap');
  const locale = useLocale();
  const { googleMapsKey } = useGoogleMaps();

  const [selectedMap, setSelectedMap] = useState('future');
  const [currentMapLayer, setCurrentMapLayer] = useState(null);
  const [futureMapLayer, setFutureMapLayer] = useState(null);
  const [map, setMap] = useState(null);

  const setMapLocation = (locationData) => {
    const { lat, lng } = locationData.results[0].geometry.location;
    const coordinates = { lat, lng };
    GoogleMaps.placeMarker(map, coordinates);
    map.panTo(coordinates);
    map.setZoom(15);
  };

  const createMapLayers = async () => {
    const currentLayer = await Carto.createCurrentLayer(map);
    currentLayer.hide();
    setCurrentMapLayer(currentLayer);

    const futureLayer = await Carto.createFutureLayer(map);
    futureLayer.show();
    setFutureMapLayer(futureLayer);
  };

  const initMap = useCallback((node) => {
    if (node) {
      const newMap = GoogleMaps.createMap(node);
      setMap(newMap);
    }
  }, []);

  useEffect(() => {
    if (map) {
      Carto.init()
        .then(createMapLayers)
        // Returns an object with {results: array of data, status: "status"}
        .then(() => GeocodeCommunicator.lookupAddress(googleMapsKey, address))
        .then(setMapLocation);
    }
  }, [map]);

  const showFutureMaps = () => {
    currentMapLayer.hide();
    futureMapLayer.show();
    setSelectedMap('future');
  };

  const showCurrentMaps = () => {
    futureMapLayer.hide();
    currentMapLayer.show();
    setSelectedMap('current');
  };

  return (
    <MapUI>
      <Container>
        <Map id="map" ref={initMap} />
        <AddressBox>
          <span>{address}</span>
          <a href={`/${locale}#eligibility`}>{provider.changeLinkText}</a>
        </AddressBox>
        <MapButtons>
          <MapButton
            className={`${selectedMap === 'current' && 'selected'}`}
            onClick={showCurrentMaps}
          >
            {provider.currentMaps}
          </MapButton>
          <MapButton
            className={`${selectedMap === 'future' && 'selected'}`}
            onClick={showFutureMaps}
          >
            {provider.futureMaps}
          </MapButton>
        </MapButtons>
      </Container>
      <MapLegend provider={provider} />
    </MapUI>
  );
};

StaticMap.propTypes = {
  address: PropTypes.string
};

StaticMap.defaultProps = {
  address: null
};

export default StaticMap;