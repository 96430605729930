import certificate from '../images/certificate.svg';
import compass from '../images/compass.svg';
import home from '../images/home.svg';

export default {
  meta: {
    title: 'Elevation Certificates | FloodHelpNY.org',
    description:
      'Elevation certificates can help protect you from overpaying on flood insurance.',
    ogDescription:
      'An elevation certificate is a document that protects homeowners from overpaying on flood insurance.',
    url: 'https://www.floodhelpny.org/en/elevation-certificates'
  },
  title: 'Elevation Certificates',
  subheader:
    "An elevation certificate can help you lower your insurance rate. Here's what else you should know about this key document.",
  overview: {
    header: 'Overview',
    body:
      'An elevation certificate is a document that protects homeowners from overpaying on flood insurance. It is regulated by FEMA and completed by a licensed engineer or surveyor. Data collected on the elevation certificate allows insurance companies to assess a property’s flood risk more accurately by verifying the elevation of the home and whether any effort has been made to minimize the effects of floods.'
  },
  about: [
    {
      header: 'What information is on the elevation certificate',
      body:
        'Three key data fields on the elevation certificate are the building type and characteristics, the lowest floor, and the location of the property. These factors all play important roles in determining your flood insurance premium.',
      image: certificate
    },
    {
      header: 'How to use an elevation certificate',
      body:
        'If you wish to use your certificate, you should provide it to your insurance agent within 90 days from the completion date shown on the document. Based on the information on your certificate, if you qualify for a lower premium your insurance agent must charge you the lower rate.',
      image: compass
    },
    {
      header: 'Who actually needs an elevation certificate',
      body:
        'With the changes implemented by FEMA through Risk Rating 2.0., elevation certificates are no longer required for any property owners. However, elevation certificates can still be valuable to ensure you’re receiving the right flood insurance price. They can also be used to document to your insurance provider any significant changes to the property you may have completed -- e.g., elevating your home, installing flood vents or raising utility systems — any of which may help to lower your flood insurance price.',
      image: home
    }
  ],
  howToGetOne: {
    header: 'How to get an elevation certificate',
    body:
      "The average price of an elevation certificate can range from $500 to more than $1,000. Try to get price quotes from at least two surveyors for comparison. Examples of professional surveyors include a licensed land surveyor, engineer or architect who is authorized by law to complete an elevation certificate. FEMA recommends finding a professional surveyor by checking the <a href='https://www.nysapls.org/search/custom.asp?id=2216' target='_blank' rel='noreferrer noopener'>state professional association for land surveyors</a> and referencing guidance from the <a href='https://www1.nyc.gov/site/housingrecovery/programs/elevation-certificate.page' target='_blank' rel='noreferrer noopener'>Department of Buildings</a>.",
    tip: {
      header: 'TIP',
      body:
        'The previous property owner may already have a certificate; if you are in the process of purchasing the property or recently purchased the property, you may want to contact them.'
    }
  },
  alreadyHaveOne: {
    header: 'If you already have an elevation certificate',
    body:
      'If you have an elevation certificate, you do not need to submit a new certificate. However, if you made any changes to the property to minimize the effects of flooding, you may want to get an updated certificate to ensure you receive the correct price.'
  },
  expired: {
    header: 'My certificate says it expired in 2018. Is it still useful?',
    body:
      'The date in the top right corner of your certificate is the date of the template, which are generally updated by FEMA every three years. The date ensures that the engineers and land surveyors issue elevation certificates using the current template. If you have an old certificate with this date, it is still good for rating purposes. If you’re getting an elevation certificate for the first time or getting an updated elevation certificate, you’ll want to make sure your engineer or land surveyor uses a form with the date November 30, 2022. You can continue to use the November 30, 2022, form until FEMA issues a new version (sometimes this can be several years after the expiration date).'
  },
  download: 'Download the Certificate',
  update: 'Update Your Certificate'
};
