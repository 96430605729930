export default {
  title: 'GET STARTED HERE: Protect your home from floods',
  name: "Homeowner's name",
  first: 'First',
  last: 'Last',
  email: 'Email',
  city: 'City',
  birthdate: 'Birthdate',
  exampleEmail: 'e.g. name@example.com',
  phone: 'Mobile Phone',
  homePhone: 'Home Phone',
  address: 'Address',
  addressPlaceholder: 'e.g. 123 Address Boulevard',
  cityPlaceHolder: 'e.g NYC',
  state: 'State',
  zip: 'Zipcode',
  zipPlaceholder: 'e.g. 10075',
  statePlaceholder: 'e.g NY',
  householdSize: 'Household Size',
  preferredLanguage: 'Preferred language spoken',
  propertyType: 'Property Type',
  birthdatePlaceHolder: 'e.g. 06/02/1995',
  numberOfUnits: 'How many rental units does the property have?',
  totalHouseholdSize: 'Total number of people living in the applicant’s home. ',
  primaryResidence: 'Primary Residence',
  income: 'Annual Household Income',
  organization:
    'If applicable, please state the name of the housing counseling or legal service organization that referred you.',
  contactedBy:
    'Please check this box if you would be willing to be contacted by a member of our research team at a later date.',
  submit: 'SUBMIT',
  newsletter: 'Sign me up for the FloodHelpNY newsletter',
  howDidYourHearAboutUs: 'How did you hear about the program?',
  propertyTypeDefault: '-- Select an Option --',
  propertyTypeOptions: ['Up to four unit home', 'Condo', 'Co-op'],
  bestWayToContact: 'What is the best way to contact you?',
  bestWayToContactDefault: '-- Select an Option --',
  bestWayToContactOptions: ['Email', 'Phone', 'Mail'],
  floodInsurance:
    'Do you have flood insurance? (This will not affect your eligibility for funding)',
  floodInsuranceDefault: '-- Select an Option --',
  floodInsuranceOptions: ['Yes', 'No', 'I don’t know'],
  preferredLanguageDefault: '-- Select an Option --',
  preferredLanguageOptions: [
    'English',
    'Spanish',
    'Arabic',
    'ASL',
    'Bengali',
    'Cantonese',
    'French',
    'French Creole',
    'German',
    'Greek',
    'Haitian Creole',
    'Hindi',
    'Italian',
    'Jamaican Creole/Patois',
    'Japanese',
    'Korean',
    'Mandarin',
    'Nepali',
    'Portuguese',
    'Russian',
    'Tibetan',
    'Urdu',
    'Yiddish'
  ],
  howDidYourHearAboutUsDefault: '-- Select an Option --',
  howDidYourHearAboutUsOptions: [
    'Neighbor/ friend',
    'Family',
    'Housing counselor',
    'Legal servicer',
    'Social media',
    'Other'
  ],
  loadingText: 'Loading...',
  errors: {
    name: 'Please enter your first and last name.',
    email: 'Email is invalid.',
    phone: 'Phone is invalid.',
    city: 'Please enter your city',
    state: 'Please enter your state',
    zip: 'Please enter your zipcode',
    date: 'Please enter a valid date',
    bestWayToContact: 'Please select a way we can contact you',
    preferredLanguage: 'Please select a preferred language',
    propertyType: 'Please select a property type',
    numberOfUnits: 'Please enter the number of units',
    submit: 'An error occurred when submitting the form. Please try again.',
    contact:
      'If you feel you received any validation message in error, please call our Homeowner Hub at 646-786-0888.',
    ineligible:
      'Unfortunately, your home is in NYC, our program only serves the five boroughs.',
    genericBuildingData:
      'There was an error with the address provided. Please try again.',
    outsideNyc:
      "We're sorry. This address doesn't appear to match publicly available data from NYC.",
    multipleResults:
      "We're sorry. There are multiple matches for the address you entered. Please verify the address or try again later.",
    incomeHousehold:
      "We're sorry. Based on the information provided, you are not currently eligible for this program.",
    incomeHouseholdIncomplete:
      'Please enter both income and household size, or you can decline to answer both.',
    householdSize: 'Please enter the total number of applicants living in home',
    floodInsurance: 'Please select an option for flood insurance',
    howDidYourHearAboutUs: 'Please tell us how did you hear about the program',
    contacted: 'You must agree to be contacted by a member of our research team'
  },
  terms:
    'By submitting this form you agree to our <a href="/en/terms/">terms and conditions</a> and <a href="/en/privacy/">privacy policy</a>.',
  thankYou:
    'Thank you for your submission to the Flood Recovery Fund. A member of our team will follow up with you in 3-5 business days via email.'
};
