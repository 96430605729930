import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '../../styles/variables';

const Container = styled.div`
  display: flex;

  @media (max-width: 1200px) {
    display: block;
  }

  > div {
    margin-right: 4rem;
    flex: 4 100px;

    @media (max-width: 1200px) {
      margin-right: 0;
    }
  }

  > aside {
    flex: 1 100px;
    padding-top: 1rem;

    div {
      padding-left: 2rem;
      border-left: 1px solid ${colors.grayScale[1]};
      flex-direction: ${(props) => props.withIconAndButton && 'column'};
      justify-content: ${(props) => props.withIconAndButton && 'center'};
      align-items: ${(props) =>
        (props.withIcon || props.withIconAndButton) && 'center'};

      @media (max-width: 1200px) {
        display: ${(props) =>
          (props.withIcon || props.withIconAndButton) && 'flex'};
        flex-direction: ${(props) => props.withIconAndButton && 'column'};
        justify-content: ${(props) => props.withIconAndButton && 'center'};
        align-items: ${(props) =>
          (props.withIcon || props.withIconAndButton) && 'center'};
      }

      div:first-child {
        padding-left: ${(props) =>
          props.withIcon || props.withIconAndButton ? '0rem' : '2rem'};
        margin-bottom: ${(props) =>
          props.withIcon || props.withIconAndButton ? '2rem' : '0'};
        border-style: ${(props) =>
          (props.withIcon || props.withIconAndButton) && 'none'};
      }

      @media (max-width: 1200px) {
        border-left: none;
      }
    }

    h6 {
      margin-bottom: 1rem;
    }

    p {
      font-size: 1rem;
      margin-bottom: ${(props) => props.withIconAndButton && '1rem'};
    }

    img {
      padding-left: ${(props) =>
        props.withIcon || props.withIconAndButton ? '0' : '2rem'};
    }

    @media (max-width: 1200px) {
      padding-left: 0;
      margin: 3rem auto 0 auto;
    }
  }
`;

const ContentContainer = styled.div`
`

const PrimaryAndAsideContainer = ({
  className,
  children,
  withIcon,
  withIconAndButton
}) => (
  <Container
    className={className}
    withIcon={withIcon}
    withIconAndButton={withIconAndButton}
  >
    {children.map((content, idx) => (
      <ContentContainer key={idx}>{content}</ContentContainer>
    ))}
  </Container>
);

PrimaryAndAsideContainer.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired
};

export default PrimaryAndAsideContainer;
