import BuildingFeatureHelper from './calculatorNavigation/BuildingFeatureHelper';
import ContentLocationHelper from './calculatorNavigation/ContentLocationHelper';
import MultifloorHelper from './calculatorNavigation/MultifloorHelper';
import ElevationDifferenceHelper from './calculatorNavigation/ElevationDifferenceHelper';
import FloodZones from '../constants/FloodZones';

// Used in Rate Calculator
const defaultParams = (params, ignorePrefirm) => {
  const buildingFeature = BuildingFeatureHelper.translateBuildingFeature(
    params
  );
  const contentLocation = ContentLocationHelper.translateContentLocation(
    params
  );
  const prefirmValue = ignorePrefirm ? false : params.pre_firm;
  return {
    pre_firm: prefirmValue,
    building_coverage: params.building_coverage,
    content_coverage: params.content_coverage,
    building_deductible: params.building_deductible,
    content_deductible: params.content_deductible,
    building_size: params.building_size,
    building_feature: buildingFeature,
    content_location: contentLocation,
    primary_residence: params.primary_residence,
    severe_repetitive_loss: params.severe_repetitive_loss === 'yes',
    substantial_improvement: params.substantial_improvement
  };
};
const RateCalculatorInputFormatter = {
  formatXZone: (params, ignorePrefirm) => {
    const formattedParams = defaultParams(params, ignorePrefirm);
    formattedParams.flood_zone = FloodZones.X;
    formattedParams.preferred_risk_rate = params.preferred_risk_rate;
    return formattedParams;
  },
  formatAEZone: (bfe, params, ignorePrefirm) => {
    const formattedParams = defaultParams(params, ignorePrefirm);
    formattedParams.flood_zone = FloodZones.AE;
    formattedParams.elevation_difference = ElevationDifferenceHelper.calculateElevationDifference(
      bfe,
      params
    );
    formattedParams.machinery_in_basement = params.machinery_in_basement;
    formattedParams.multi_floor = MultifloorHelper.isMultifloor(params);
    return formattedParams;
  },
  formatVEZone: (bfe, params, bestCase, ignorePrefirm) => {
    const formattedParams = defaultParams(params, ignorePrefirm);
    const elevationDifference = ElevationDifferenceHelper.calculateElevationDifference(
      bfe,
      params
    );

    formattedParams.flood_zone = FloodZones.VE;
    formattedParams.replacement_cost_ratio = params.replacement_cost_ratio;
    formattedParams.elevation_difference = elevationDifference;
    if (bestCase) {
      formattedParams.obstructed = false;
    } else {
      formattedParams.obstructed = true;
      formattedParams.appliance_elevation = elevationDifference;
      formattedParams.machinery_elevation = elevationDifference;
      formattedParams.enclosure_area = 10001;
    }
    return formattedParams;
  }
};
export default RateCalculatorInputFormatter;
