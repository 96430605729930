import DeductibleOptions from './DeductibleOptions'
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

const DEDUCTIBLE_MAX = 10000;
const CONTENT_COVERAGE_MAX = 100000;
const BUILDING_COVERAGE_MAX = 250000;

export default class RateAdjuster extends Component {
  static propTypes = {
    provider: PropTypes.object.isRequired,
    calculatorInputs: PropTypes.object.isRequired
  };

  constructor(props, context) {
    super(props, context);
    this.state = {collapsed: true, contentDeductibleDisabled: false, buildingDeductibleDisabled: false};
    this._renderAdjuster = this._renderAdjuster.bind(this);
    this._toggleCollapsed = this._toggleCollapsed.bind(this);
    this._updateBuildingCoverage = this._updateBuildingCoverage.bind(this);
    this._validateBuildingCoverage = this._validateBuildingCoverage.bind(this);
    this._updateContentCoverage = this._updateContentCoverage.bind(this);
    this._validateContentCoverage = this._validateContentCoverage.bind(this);
    this._updateBuildingDeductible = this._updateBuildingDeductible.bind(this);
    this._updateContentDeductible = this._updateContentDeductible.bind(this);
    this._renderBuildingDeductible = this._renderBuildingDeductible.bind(this);
    this._renderContentDeductible = this._renderContentDeductible.bind(this);
  }

  _toggleCollapsed() {
    this.setState({collapsed: !this.state.collapsed});
  }

  _updateBuildingCoverage(event) {
    const enteredCoverage = event.target.value;
    const isEmpty = (enteredCoverage === '');
    if (isEmpty) {
      this.refs.buildingCoverageInput.value = '';
      this.props.updateCoverage({building_coverage: ''});
    } else {
      const isNumeric = (enteredCoverage.match(/^([0-9])*$/) !== null);
      if (isNumeric) {
        const coverageNumber = parseInt(enteredCoverage);
        if (coverageNumber <= BUILDING_COVERAGE_MAX) {
          this.props.updateCoverage({building_coverage: coverageNumber});
        }
      }
    }
  }

  _validateBuildingCoverage(event) {
    if (event.target.value === '' || parseInt(event.target.value) === 0) {
      this.props.updateCoverage({building_deductible: 0});
      this.setState({buildingDeductibleDisabled: true});
    }
    else {
      if (this.state.buildingDeductibleDisabled) {
        const minDeductible = this.props.calculatorInputs.pre_firm ? 1500 : 1000;
        const contentDeductible = parseInt(this.props.calculatorInputs.content_deductible);
        let deductible;
        if (contentDeductible === DEDUCTIBLE_MAX) {
          deductible = DEDUCTIBLE_MAX;
        } else {
          deductible = (contentDeductible > minDeductible) ? contentDeductible : minDeductible;
        }
        this.props.updateCoverage({building_deductible: deductible});
      }
      this.setState({buildingDeductibleDisabled: false});
    }
  }

  _updateContentCoverage(event) {
    const enteredCoverage = event.target.value;
    const isEmpty = (enteredCoverage === '');
    if (isEmpty) {
      this.refs.contentCoverageInput.value = '';
      this.props.updateCoverage({content_coverage: enteredCoverage});
    } else {
      const isNumeric = (enteredCoverage.match(/^([0-9])*$/) !== null);
      if (isNumeric) {
        const coverageNumber = parseInt(enteredCoverage);
        if (coverageNumber <= CONTENT_COVERAGE_MAX) {
          this.props.updateCoverage({content_coverage: coverageNumber});
        }
      }
    }
  }

  _validateContentCoverage(event) {
    if (event.target.value === '' || parseInt(event.target.value) === 0) {
      this.props.updateCoverage({content_deductible: 0});
      this.setState({contentDeductibleDisabled: true});
    }
    else {
      if (this.state.contentDeductibleDisabled) {
        const minDeductible = this.props.calculatorInputs.pre_firm ? 1500 : 1000;
        const buildingDeductible = parseInt(this.props.calculatorInputs.building_deductible);
        const deductible = (buildingDeductible === DEDUCTIBLE_MAX) ? buildingDeductible : minDeductible;
        this.props.updateCoverage({content_deductible: deductible});
      }
      this.setState({contentDeductibleDisabled: false});
    }
  }

  _updateBuildingDeductible(event) {
    let updatedCoverage = {building_deductible: event.target.value};
    if (!this.state.contentDeductibleDisabled) {
      if (parseInt(event.target.value) === DEDUCTIBLE_MAX) {
        updatedCoverage.content_deductible = DEDUCTIBLE_MAX;
      }
      if (event.target.value < parseInt(this.props.calculatorInputs.content_deductible)) {
        updatedCoverage.content_deductible = event.target.value;
      }
    }
    this.props.updateCoverage(updatedCoverage);
  }

  _updateContentDeductible(event) {
    let updatedCoverage = {content_deductible: event.target.value};
    if (!this.state.buildingDeductibleDisabled) {

      if (parseInt(event.target.value) === DEDUCTIBLE_MAX) {
        updatedCoverage.building_deductible = DEDUCTIBLE_MAX;
      }
      var contentCoverageGreaterThanBuildingCoverage = event.target.value > parseInt(this.props.calculatorInputs.building_deductible);
      var contentCoverageWasPreviouslyAtMax = (parseInt(this.props.calculatorInputs.content_deductible) === DEDUCTIBLE_MAX) && (event.target.value < DEDUCTIBLE_MAX);
      if (contentCoverageGreaterThanBuildingCoverage || contentCoverageWasPreviouslyAtMax) {
        updatedCoverage.building_deductible = event.target.value;
      }

    }
    this.props.updateCoverage(updatedCoverage);
  }

  _renderBuildingDeductible() {
    const calculatorInputs = this.props.calculatorInputs;

    if (!this.state.buildingDeductibleDisabled) {
      return (<DeductibleOptions id='building-deductible-input'
                                 prefirm={calculatorInputs.pre_firm}
                                 answer={calculatorInputs.building_deductible}
                                 setResponse={this._updateBuildingDeductible}/>)
    } else {
      return (<input type='text' id='building-deductible-input' disabled={true} value='$0'/>);
    }

  }

  _renderContentDeductible() {
    const calculatorInputs = this.props.calculatorInputs;

    if (!this.state.contentDeductibleDisabled) {
      return (<DeductibleOptions id='content-deductible-input'
                                 prefirm={calculatorInputs.pre_firm}
                                 answer={calculatorInputs.content_deductible}
                                 setResponse={this._updateContentDeductible}/>)
    } else {
      return (<input type='text' id='content-deductible-input' disabled={true} value='$0'/>);
    }

  }


  _renderAdjuster() {
    const provider = this.props.provider;
    const calculatorInputs = this.props.calculatorInputs;

    if (this.state.collapsed) {
      return (
        <div className='collapsed'>
          <a onClick={this._toggleCollapsed}>{provider.adjustYourCoverage}<span className="lnr lnr-chevron-down"/></a>
        </div>
      );
    } else {
      return (
        <div className='expanded row'>
          <div className='col-md-6'>
            <div className='building-coverage'>
              <label htmlFor='building-coverage-input'>
                <div className="description">
                  <div className="adjuster-description">{provider.buildingCoverage}</div>
                  <div className="adjuster-tooltip">{provider.buildingCoverageTooltip}</div>
                </div>
              </label>
              <input
                id='building-coverage-input'
                ref='buildingCoverageInput'
                type='number'
                pattern='[0-9]*'
                min={0}
                max={BUILDING_COVERAGE_MAX}
                value={calculatorInputs.building_coverage}
                onChange={this._updateBuildingCoverage}
                onBlur={this._validateBuildingCoverage}
              />
            </div>
            <div className='building-deductible'>
              <label htmlFor='building-deductible-input'>
                <div className="description">
                  <div className="adjuster-description">{provider.buildingDeductible}</div>
                  <div className="adjuster-tooltip">{provider.buildingDeductibleTooltip}</div>
                </div>
              </label>
              {this._renderBuildingDeductible()}
            </div>
          </div>
          <div className='col-md-6'>
            <div className='content-coverage'>
              <label htmlFor='content-coverage-input'>
                <div className="description">
                  <div className="adjuster-description">{provider.contentCoverage}</div>
                  <div className="adjuster-tooltip">{provider.contentCoverageTooltip}</div>
                </div>
              </label>
              <input
                id='content-coverage-input'
                ref='contentCoverageInput'
                type='number'
                pattern='[0-9]*'
                min={0}
                max={CONTENT_COVERAGE_MAX}
                value={calculatorInputs.content_coverage}
                onChange={this._updateContentCoverage}
                onBlur={this._validateContentCoverage}
              />
            </div>
            <div className='content-deductible'>
              <label htmlFor='content-deductible-input'>
                <div className="description">
                  <div className="adjuster-description">{provider.contentDeductible}</div>
                  <div className="adjuster-tooltip">{provider.contentDeductibleTooltip}</div>
                </div>
              </label>
              {this._renderContentDeductible()}
            </div>
          </div>
          <div className='collapse-control col-md-12'>
            <a onClick={this._toggleCollapsed}>{provider.collapse}<span className="lnr lnr-chevron-up"/></a>
          </div>
        </div>
      )
    }
  }

  render() {
    return (
      <div className='rate-adjuster'>
        {this._renderAdjuster()}
      </div>
    );
  }
}
