export default {
    findYourHome: {
      header: 'Найдите ваш дом на карте наводнений',
      body:
        'Более 400000 ньюйоркцев могут оказаться под воздействием будущих зон наводнений - узнайте, как это может отразиться на вас.'
    },
    estimate: {
      header: 'Получите примерную смету страхования от наводнения',
      body:
        'В районах, подверженных затоплениям в Нью-Йорке, 76% домовладельцев, у которых нет сертификата высотной отметки, переплачивают за страхование от наводнений.'
    },
    lowerRisk: {
      header: 'Уменьшите ваш риск и понизьте тариф',
      body:
        'Более 100000 ньюйоркцев обратились к сайту FloodHelpNY, как к источнику знаний, начала действий и чтобы стать более защищенными от затопления.'
    }
  };
  