export default {
  findYourHome: {
    header: 'Dekouvri kay ou an sou kat inondasyon an',
    body:
      'Plis pase 400,000 abitan New York ta ka afekte ak pwochen zòn inondasyon yo—dekouvri fason yo gendwa afekte ou.'
  },
  estimate: {
    header: 'Jwenn yon estimasyon pou asirans kont inondasyon',
    body:
      'Nan katye NYC ki inondab, 76% pwopriyetè kay ki pa gen yon sètifika elevasyon ap peye depase pou asirans kont inondasyon.'
  },
  lowerRisk: {
    header: 'Bese risk ak tarif ou',
    body:
      'Plis pase 100,000 abitan New York vin nan FloodHelpNY antanke yon kote pou aprann, poze aksyon epi vin sekirize kont inondasyon.'
  }
};
