import React from 'react';
import styled from 'styled-components';
import { colors } from '../styles/variables';
import ResourceLinks from './ResourceLinks';
import { useProvider } from '../helpers/hooks';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 80px;
  background-color: ${colors.secondaryHeading};
  padding: 70px 0;

  @media (max-width: 1024px) {
    margin-top: 50px;
    padding: 70px 20px;
  }
`;

const Header = styled.h2`
  text-align: center;
  padding-bottom: 10px;
  color: white;
`;

const SubHeader = styled.p`
  color: white;
  font-size: 20px;
  text-align: center;
  padding: 0 20px;
  margin-bottom: 3rem;
  width: 95%;
`;

const OtherResources = () => {
  const provider = useProvider('otherResources');

  return (
    <Container>
      <Header>{provider.title}</Header>
      <SubHeader>{provider.tagline}</SubHeader>
      <ResourceLinks />
    </Container>
  );
};

export default OtherResources;
