export default {
  download: '下载',
  elevationCertificate: {
    header: '您的标高证明（PDF）',
    copy: '您可以用此证明来获得准确的洪水保险费率'
  },
  technicalReport: {
    header: '您的完整技术报告（PDF）',
    copy: '完整技术报告'
  },
  fieldReport: {
    header: '您的完整现场报告（PDF）',
    copy: '完整现场报告'
  },
  homeownerGrantAgreement: {
    header: '您的完整房主补助协议（PDF）',
    copy: '完整的房主补助协议'
  },
  triPartyAgreement: {
    header: '您的完整三方协议（PDF）',
    copy: '完整的三方协议'
  }
};
