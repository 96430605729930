import React from 'react';
import styled from 'styled-components';
import FloodZoneMap from './FloodZoneMap';
import { useProvider } from '../helpers/hooks';
import { Section } from './ui/StyledResourcePageElements';

const List = styled.ul`
  list-style-type: disc;
  margin-block-end: 0.5rem;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 1.5rem;

  li {
    margin-bottom: 0.5rem;
  }
`;

const InlineHeader = styled.p`
  font-family: GT-Walsheim-Pro-Bold;
  margin-bottom: 1rem;
`;

const ProgramLink = styled.p`
  margin-bottom: 1rem;
`;

const prioritizedAreas = () => {
  const provider = useProvider('prioritizedAreas');

  return (
    <Section>
      <h5>{provider.currentlyServed}</h5>
      <FloodZoneMap />
      <div>
        <InlineHeader>{provider.neighborhood.header}</InlineHeader>
        <p>{provider.neighborhood.content}</p>
      </div>
      <div>
        <InlineHeader>{provider.priority.header}</InlineHeader>
        <List>
          {provider.priority.criteria.map((criterion) => (
            <li key={criterion.key}>
              <p>{criterion.copy}</p>
            </li>
          ))}
        </List>
      </div>
      <ProgramLink
        dangerouslySetInnerHTML={{ __html: provider.downloadEligibilityForm }}
      />
    </Section>
  );
};

export default prioritizedAreas;
