export default {
    header: '降低费率的方法',
    link: '了解缓解措施',
    assumingMax: '*假设最大承保范围',
    numbersAreEstimates:
      '出于多种原因，这些数字是估计值，要了解更多信息，请访问我们的 ',
    termsAndConditions: '条款',
    noMitigation: "抱歉，我们无法确定任何选项",
    elevateBuilding: {
      header: '提升房屋高度',
      body: '让您的房屋完全高于预测洪水水位。',
      link: '了解更多信息',
      mitigationDifference: {
        currentHeader: '对于当前区域',
        futureHeader: '对于咨询区域',
        saved: '保存',
        perYear: ' /年*',
        nonpositiveSavingsCopy: '这不会降低您的费率'
      }
    },
    abandonFirstFloor: {
      header: '放弃一楼',
      body: '这有效地提高了您房屋的基础标高。',
      link: '了解更多信息',
      mitigationDifference: {
        currentHeader: '对于当前区域',
        futureHeader: '对于咨询区域',
        saved: '保存',
        perYear: ' /年*',
        nonpositiveSavingsCopy: '这不会降低您的费率'
      }
    },
    elevateMechanicals: {
      header: '提高机械性能',
      body: '保护您房屋的关键系统。',
      link: '了解更多信息',
      mitigationDifference: {
        currentHeader: '对于当前区域',
        futureHeader: '对于咨询区域',
        saved: '保存',
        perYear: ' /年*',
        nonpositiveSavingsCopy: '这不会降低您的费率'
      }
    },
    floodVents: {
      header: '安装泄洪口',
      body:
        '让洪水流过并排放，降低洪水造成的损害风险。',
      link: '了解更多信息',
      mitigationDifference: {
        currentHeader: '对于当前区域',
        futureHeader: '对于咨询区域',
        saved: '保存',
        perYear: ' /年*',
        nonpositiveSavingsCopy: '这不会降低您的费率'
      }
    },
    fillInBasement: {
      header: '填埋地下室',
      body: '减少对房屋结构基础的损坏。',
      link: '了解更多信息',
      mitigationDifference: {
        currentHeader: '对于当前区域',
        futureHeader: '对于咨询区域',
        saved: '保存',
        perYear: ' /年*',
        nonpositiveSavingsCopy: '这不会降低您的费率'
      }
    }
  };