import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { getZoneColor } from '../styles/variables';
import { useProvider } from '../helpers/hooks';

const Zone = styled.span`
  color: ${(props) => props.color};
  text-decoration: underline;
`;

// called by:
//   RiskInfo
const RiskZoneText = ({ floodZone }) => {
  const provider = useProvider('floodRisk');
  const copy = provider[`${floodZone.toLowerCase()}Zone`];

  return <Zone color={getZoneColor(floodZone)}>{copy}</Zone>;
};

RiskZoneText.propTypes = {
  floodZone: PropTypes.string.isRequired
};

export default RiskZoneText;
