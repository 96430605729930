import MultifloorHelper from './MultifloorHelper';
import BuildingSizes from '../../constants/BuildingSizes';
import FloodZones from '../../constants/FloodZones';
import FloodZoneSeverityHelper from './FloodZoneSeverityHelper';

export default class NumberOfFloorsNavigator {
  static nextComponent(results) {
    if (
      results.contentLocation === null ||
      results.contentLocation === undefined
    ) {
      if (results.buildingSize === BuildingSizes.TWO_TO_FOUR_FAMILY) {
        if (MultifloorHelper.isMultifloor(results)) {
          return 'contentLocation';
        }
      }
    }
    if (FloodZoneSeverityHelper.getMoreSevereZone(results) === FloodZones.AE) {
      return null;
    }
    return 'elevatedBuilding';
  }
}
