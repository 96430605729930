import FloodZones from '../../constants/FloodZones';
import FloodZoneSeverityHelper from './FloodZoneSeverityHelper';

export default class MachineryInBasementNavigator {
  static nextComponent(results) {
    if (FloodZoneSeverityHelper.getMoreSevereZone(results) === FloodZones.VE) {
      return 'elevatedBuilding';
    }
    return null;
  }
}
