export default {
  header: 'WHAT YOU COULD DO TO LOWER YOUR RISK AND RATE',
  assumingMax: '*Assuming max coverage',
  numbersAreEstimates:
    'These numbers are estimates for many reasons, to learn more about them go to our ',
  termsAndConditions: 'Terms & Conditions',
  noRecommendations: "Sorry, we couldn't identify any options",
  backwaterValveProvider: {
    header: {
      inProgress: 'Install a backwater valve',
      complete: 'About your backwater valve'
    },
    completeHeader: 'Installation complete!',
    topBody:
      'This helps prevent wastewater from entering your home in the event of sewer overflow.',
    bottomBody:
      'You’re eligible to receive a free backwater valve installation.',
    bottomBodyComplete:
      'We’d love to hear how the process went. Please take a few minutes to fill out our survey.',
    buttons: {
      learnMore: 'Learn more',
      startProcess: 'Start the Process',
      feedbackSurvey: 'Feedback Survey'
    },
    steps: {
      initiate: 'Installation in progress',
      schedule: 'Installation in progress',
      install: 'Installation complete!'
    }
  },
  elevateBuildingProvider: {
    header: 'Elevate your home',
    body: 'Puts your house completely above predicted flood water levels.',
    link: 'Learn More',
    lowerYourRate: 'This will lower your rate.',
    auditRateDifferenceProvider: {
      currentHeader: 'For your current zone',
      futureHeader: 'For your advisory zone',
      saved: 'saved',
      perYear: ' /year*',
      nonpositiveSavingsCopy: 'This will not lower your rate'
    }
  },
  abandonFirstFloorProvider: {
    header: 'Abandon your first floor',
    body: 'This effectively raises the base elevation of your home.',
    link: 'Learn More',
    lowerYourRate: 'This will lower your rate.',
    auditRateDifferenceProvider: {
      currentHeader: 'For your current zone',
      futureHeader: 'For your advisory zone',
      saved: 'saved',
      perYear: ' /year*',
      nonpositiveSavingsCopy: 'This will not lower your rate'
    }
  },
  fillInBasementProvider: {
    header: 'Fill in your basement',
    body: 'Reduces damage to your home’s structural foundation.',
    link: 'Learn More',
    lowerYourRate: 'This will lower your rate.',
    auditRateDifferenceProvider: {
      currentHeader: 'For your current zone',
      futureHeader: 'For your advisory zone',
      saved: 'saved',
      perYear: ' /year*',
      nonpositiveSavingsCopy: 'This will not lower your rate'
    }
  },
  elevateMechanicalsProvider: {
    header: 'Raise your mechanicals',
    body: 'Protects your home’s critical systems.',
    link: 'Learn More',
    lowerYourRate: 'This will lower your rate.',
    auditRateDifferenceProvider: {
      currentHeader: 'For your current zone',
      futureHeader: 'For your advisory zone',
      saved: 'saved',
      perYear: ' /year*',
      nonpositiveSavingsCopy: 'This will not lower your rate'
    }
  }
};
