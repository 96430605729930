export default {
    meta: {
      title: 'Программа установки канализационного обратного клапана',
      url: 'https://www.floodhelpny.org/ru/backwater-valve-program',
      description: 'Защитите ваш дом и финансы от наводнений.',
      imageAlt: 'строитель за работой'
    },
    title: 'Программа установки канализационного обратного клапана',
    subTitle:
      'Предотвратите дорогостоящее повреждение вашего дома от противотока канализации, который также может нанести вред здоровью.',
    description:
      "Домовладельцы в зоне высокого риска наводнения должны иметь сертификат высотной отметки, чтобы получить правильную расценку страхования от наводнений. Если вы соответствуете критериям на получение бесплатной инспекции устойчивости дома, мы пошлем к вам в дом инженера и он подготовит его для вас.",
    programClosed:
      'Программа установки канализационного обратного клапана больше не принимает заявления. Чтобы получить более подробную информацию о канализационном обратном клапане и почему он может быть вам нужен, ',
    visitResourcePage: 'ознакомьтесь с нашей ресурс страницей'
  };
  
