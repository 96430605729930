import React from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';
import styled from 'styled-components';
import { colors } from '../styles/variables';
import { useLocale, useProvider } from '../helpers/hooks';
import DateFormatter from '../helpers/DateFormatter';
import NewsShareButton from './NewsShareButton';
import facebook from '../images/facebook.svg';
import twitter from '../images/twitter.svg';
import envelope from '../images/envelope.svg';

const Wrapper = styled.div`
  margin-bottom: 5rem;
  margin-top: 8rem;

  @media (max-width: 1000px) {
    padding: 0 1rem;
    margin-top: 0;
  }
`;

const Title = styled.h2`
  margin-bottom: 1rem;
`;

const DateInfo = styled.span`
  font-size: 1rem;
  color: ${colors.grayScale[4]};
`;

const DateSeparator = styled.span`
  margin: 0 0.5rem;

  @media (max-width: 500px) {
    display: none;
  }
`;

const FeaturedFlag = styled.div`
  padding: 0.5rem 1.5rem;
  margin-bottom: 0.5rem;
  display: inline-block;
  background-color: ${colors.navyBlue};
  color: white;
  font-family: GT-Walsheim-Pro-Bold;
`;

const Content = styled.div`
  margin-top: 1rem;

  > * {
    margin: 2rem 0;
    padding: 0 8rem;
    color: ${colors.grayScale[5]};

    @media (max-width: 1000px) {
      padding: 0 4rem;
      font-size: 1rem;
    }

    @media (max-width: 600px) {
      padding: 0;
    }
  }

  iframe {
    height: 50vh;
    width: 100%;
  }

  figure {
    img {
      height: auto;
      width: 100%;
    }

    figcaption {
      margin-top: 0.5rem;
      font-style: italic;
      font-size: 1rem;
    }
  }

  ol,
  ul {
    list-style: disc;
    margin-inline-start: 1rem;

    li {
      margin: 0.5rem 0;
    }
  }
`;

const InfoBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
  border-top: 1px solid ${colors.grayScale[1]};
  border-bottom: 1px solid ${colors.grayScale[1]};

  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

const ShareButtons = styled.div`
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: ${colors.grayScale[4]};

  span {
    margin-right: 0.5rem;
  }

  a {
    margin-right: 1rem;
  }
`;

const Dates = styled.div`
  display: flex;

  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

const NewsPost = ({ post }) => {
  const provider = useProvider('news');
  const locale = useLocale();

  DOMPurify.addHook('afterSanitizeAttributes', function (node) {
    if (node.tagName === 'IFRAME') {
      node.setAttribute('sandbox', 'allow-same-origin allow-scripts');
    }
  });

  const title = DOMPurify.sanitize(post.title.rendered);
  const content = DOMPurify.sanitize(post.content.rendered, {
    ADD_TAGS: ['iframe'],
    ADD_ATTR: ['allowfullscreen', 'scrolling']
  });
  const created = DOMPurify.sanitize(post.date);
  const updated = DOMPurify.sanitize(post.modified);

  const titleCleaned = title.replace('￼', '')

  const hasBeenUpdated = () => new Date(updated) > new Date(created);

  return (
    <Wrapper>
      {post.sticky && <FeaturedFlag>FEATURED</FeaturedFlag>}
      <Title>{titleCleaned}</Title>
      <Content dangerouslySetInnerHTML={{ __html: content }} />
      <InfoBar>
        <ShareButtons>
          <span>{provider.share}</span>
          <NewsShareButton
            url={`https://www.facebook.com/sharer/sharer.php?u=https%3A//www.floodhelpny.org/${locale}/news&quote=${post.title.rendered}`}
            external
            img={facebook}
          />
          <NewsShareButton
            url={`http://twitter.com/intent/tweet?text=${post.title.rendered} - ${provider.tweet}`}
            external
            img={twitter}
          />
          <NewsShareButton
            url={`mailto:?subject=${provider.emailSubject}&body=${post.title.rendered} - ${provider.meta.url}`}
            img={envelope}
          />
        </ShareButtons>
        <Dates>
          <DateInfo>
            {provider.created} {DateFormatter.mddyyyy(created)}
          </DateInfo>
          {hasBeenUpdated && (
            <DateInfo>
              <DateSeparator>&bull;</DateSeparator>
              {provider.updated} {DateFormatter.mddyyyy(updated)}
            </DateInfo>
          )}
        </Dates>
      </InfoBar>
    </Wrapper>
  );
};

NewsPost.propTypes = {
  post: PropTypes.object.isRequired
};

export default NewsPost;
