export default {
  title: 'Agree to Terms and Conditions',
  disclaimerOne: 'We cannot guarantee the accuracy of your zone',
  disclaimerTwo: 'Your flood insurance estimate may not be accurate',
  links: {
    sentenceOpener: 'Learn more in our ',
    termsLinkText: 'Terms and Conditions',
    termsLinkUrl: '/en/terms',
    conjunction: ' and ',
    privacyLinkText: 'Privacy Policy',
    privacyLinkUrl: '/en/privacy',
    submit: 'By clicking submit you agree to our terms and conditions'
  },
  navigation: {
    agree: 'I Agree',
    disagree: 'I Disagree'
  }
};
