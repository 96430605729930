const createMap = (el) => {
  if (!el || !window.google) {
    return null;
  }

  const styles = {
    hide: [
      {
        featureType: 'poi',
        stylers: [{ visibility: 'off' }]
      },
      {
        featureType: 'transit',
        elementType: 'labels.icon',
        stylers: [{ visibility: 'off' }]
      }
    ]
  };

  return new window.google.maps.Map(el, {
    center: { lat: 40.666848, lng: -73.981299 },
    search: false,
    zoom: 12,
    https: true,
    dragging: true,
    tap: false,
    minZoom: 10,
    maxZoom: 16,
    styles: styles.hide,
    mapTypeControl: false,
    clickableIcons: false,
    streetViewControl: false,
    restriction: {
      latLngBounds: {
        east: -73,
        west: -75,
        north: 41.3,
        south: 40.3
      }
    }
  });
};

let marker = null;

const placeMarker = (map, coordinates) => {
  const { google } = window;
  const icon = {
    url: '/Pointer.svg',
    scaledSize: new google.maps.Size(60, 60)
  };

  // delete previous marker
  if (marker) {
    marker.setMap(null);
  }

  marker = new google.maps.Marker({
    position: coordinates,
    map,
    icon,
    title: 'FloodHelp Marker'
  });
};

const createAutocomplete = (inputEl) =>
  new window.google.maps.places.Autocomplete(inputEl, {
    types: ['address']
  });

export default {
  createMap,
  placeMarker,
  createAutocomplete
};
