export default {
    meta: {
      title: 'Сезон ураганов здесь | FloodHelpNY.org',
      url: 'https://www.floodhelpny.org/ru/prepare-for-hurricane-season',
      description:
        'Предпримите четыре легких шага сегодня, чтобы защитить ваш дом и финансы от наводнений'
    },
    header: 'Сезон ураганов здесь',
    subHeader: 'Предпримите четыре легких шага сегодня, чтобы защитить ваш дом от наводнений',
    photos:
      '<b>Сфотографируйте</b> ваше важное имущество, чтобы помочь ускорить рассмотрение исков страхования',
    copies:
      '<b>Сложите копии</b> важных документов в водонепроницаемый контейнер или <b>сохраните</b> в электронном виде',
    move:
      '<b>Переставьте</b> ценные вещи из подвала на верхние этажи, чтобы предотвратить ущерб от воды',
    insurance:
      '<b>Получите</b> страхование от наводнения или <b>пересмотрите</b> ваш имеющийся полис на предмет соответствия современным требованиям',
    posterCallToAction: 'Получите плакат',
    posterCopy: 'Загрузите и распечатайте вашу личную копию перечня здесь',
    posterButton: 'Загрузите',
    posterUrl: '/samples/FloodHelpNY-Prepare-Poster-2020.pdf',
    camera: 'Камера',
    waves: 'Волны'
  };
  