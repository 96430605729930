import umbrella from '../images/umbrella.svg';
import checklist from '../images/checklist.svg';
import map from '../images/map-with-marker.svg';
import questionBubble from '../images/question-bubble.svg';

export default {
  meta: {
    title: 'Shopping for Flood Insurance | FloodHelpNY',
    url: 'https://www.floodhelpny.org/en/shopping-for-flood-insurance',
    description:
      "Flood risk is increasing, which means insurance rates are, too. Don't miss your chance to lock in a cheaper policy today.",
    imageAlt: 'Shopping for Flood Insurance | FloodHelpNY'
  },
  header: 'Shopping for Flooding Insurance',
  subHeader:
    "Flood risk is increasing, which means insurance rates are, too. Don't miss your chance to lock in a cheaper policy today.",
  overview: {
    header: 'OVERVIEW',
    body:
      "The National Flood Insurance Program (NFIP) sets standard flood insurance rates based on your property and its risk to flooding. Insurance agents should give you the same quote for the same policy. However, agents may give an inaccurate quote if they don't have the right information. Follow the steps below and give your agent the information they need to ensure you get the right policy."
  },
  understandingFloodInsurance: {
    icon: umbrella,
    iconAlt: 'umbrella',
    header: "Don't understand how flood insurance works?",
    buttonText: 'Click here to learn more'
  },
  stepsIntro: 'Get the best policy for you. Follow the steps below.',
  start: {
    step: 'Step 1',
    icon: map,
    iconAlt: 'map with marker',
    title: 'Where to start',
    haveInsurance: {
      title: 'Already have insurance?',
      body:
        'Call your homeowners insurance agent and ask if they offer flood insurance.'
    },
    findAgent: {
      title: 'Need help finding an agent?',
      body: 'Search at '
    }
  },
  ask: {
    step: 'Step 2',
    icon: questionBubble,
    iconAlt: 'speech bubble with question mark',
    title: 'Ask these five questions to get the best policy for you.',
    questions: [
      {
        header:
          'What is my flood risk zone on the current Flood Insurance Rate Map?',
        description:
          'If you are in a "high risk" zone, you have a requirement to carry flood insurance.'
      },
      {
        header: 'How does changing my deductible impact my rate?',
        description:
          'A higher deductible might make your annual rate cheaper, but means you’ll pay more out of pocket in the event of a flood.'
      },
      {
        header: 'What would my flood policy cover?',
        description:
          'Flood policies include two types of coverage. "Structural" covers damage to the construction of your home and built-in appliances. "Contents" covers some, but not all, of your personal belongings. Talk to your agent about what level of coverage makes financial sense for you.'
      },
      {
        header: 'What is the highest amount I could claim after a flood?',
        description:
          'Your "maximum coverage" amount is the most you can claim after a flood. When deciding on your maximum coverage, think about how much money you would need to recover financially.'
      },
      {
        header: 'What other factors might impact how much I pay?',
        description:
          'Other factors, like whether your home is close to a flooding source and the cost to rebuild can impact your policy rate and fees. See our FAQs on Risk Rating 2.0 for more information.'
      }
    ]
  },
  doubleCheck: {
    step: 'Step 3',
    icon: checklist,
    iconAlt: 'checklist',
    title: 'Remember to double-check your quote from at least three agents.'
  }
};
