export default [
  {
      question: "Apre ou fin mezire wotè inondasyon yo, mwen kwè gen yon erè ak sètifika mwen an.",
      answer: "Enjenyè ak apantè teren yo itilize zouti espesyalize pou mezire elevasyon chak etaj nan jan l lye ak nivo lanmè an. Yon mèt ki gradye pa p mezire elevasyon pwopriyete ou an ak presizyon.",
      zone: "elevation-certificates"
  },
  {
      question: "Èske mwen kalfiye pou Pwogram Enstalasyon Valv pou Dlo k Ap Tounen?",
      answer: "Ou aktyèlman kalifye si ou ap viv nan fwontyè kominote Lower Manhattan, Rockaway East, Southeast Brooklyn Waterfront, Gravesend ak Bensonhurst, Howard Beach, Gerritsen Beach ak Sheepshead Bay ak Penensil Sid Brooklyn lan jan Biwo Gouvènè an pou Retablisman apre Tanpèt (GOSR) lan defini sa a. Apre ou ranpli aplikasyon an, nou ap analize kalifikasyon ou epi fè ou konnen kisa desizyon nou ye. Menm apresa, si plonbye oswa enjenyè an jwenn kondisyon ki egziste ki rann li fezab pou enstale yon valv yon fwa yo antre lakay ou, kalifikasyon ou ta ka fè yon ti poz jouk pwopriyetè kay lan sousi ki gen ak kondisyon ki egziste yo.",
      zone: "bwv-flood-applicants"
  },
  {
      question: "Èske mwen blije jwenn yon sètifika elevasyon?",
      answer: "Si pwopriyete ou an aktyèlman nan zòn risk espesyal pou inondasyon (SFHA yo), sètifika elevasyon an se pi bon fason pou konnen si asirans ou an evalye kòrèkteman. Anplis, si ou wè nouvo kat yo ap plase kay ou an nan yon pi gwo risk, sètifika elevasyon an ka asire ou katografye kòrèkteman.",
      zone: "understanding-flood-insurance"
  },
  {
      question: "Èske tout etap yo nan pwogram (Odit, Oryantasyon, elatriye) yo ap pran plis tan pou aplikan ki nan Canarsie oswa Red Hook yo ki deja nan pwogram lan?",
      answer: "Aplikan yo nan Canarsie ak Red Hook ki pa t patisipe nan pwogram Tolerans pou Kay lan pa kalifye pou patisipe nan moman sa a. Sila yo ki te patisipe nan pwogram lan oparavan ap pase atravè pòsyon valv pou dlo k ap tounen pwogram lan nan menm vitès ak tout lòt patisipan yo.",
      zone: "bwv-flood-applicants"
  },
  {
      question: "Èske vantilasyon kont inondasyon yo enpòtan pou pwoteje kay ou an kont domaj nan dlo?",
      answer: "Vantilasyon kont inondasyon anpeche domaj estriktirèl kont kay ou an apati fòs dlo k ap frape li an, yo rele sa presyon idwostatik. Si dlo an ka koule nan espas vid lan epi sòti, presyon sa a redui, sa ki pwoteje kay ou an. Gen vantilasyon kont inondasyon nan espas vid ou an ta ka bese tarif asirans ou an nan yon fason enpòtan, toudepan elevasyon kay ou an konpare ak Elevasyon pou Inondasyon Debaz",
      zone: "rate-calculator-building-feature"
  },
  {
      question: "Èske pwopriyetè kay yo nan zòn X aktyèl lan ki pral nan zòn X konsiltatif yo ap kalifye?",
      answer: "Wi, yo kalifye.",
      zone: "bwv-flood-applicants"
  },
  {
      question: "Èske zòn inondasyon FEMA yo diferan ak zòn evakyasyon Vil yo?",
      answer: "Wi. Ou ka aprann plis sou zòn evakyasyon Vil lan <a target=\"_blank\" href=\"http://www1.nyc.gov/assets/em/html/know-your-zone/knowyourzone.html\">here</a>.",
      zone: "understanding-flood-insurance"
  },
  {
      question: "Èske gen avantaj finansye nan pwogram lan?",
      answer: "Se yon apantè teren ki dwe reyalize sètifika elevasyon yo e yo ka koute ant $500 ak $800 pou dosye ki pa konplike yo, men frè yo ka ogmante ak plizyè milye dola pou dosye ki pi konplike. Anplis, sèvis oryantasyon yo ap travay ak ou pou ede ou idantifye demach ou ka fè pou redui tarif asirans kont inondasyon ou yo alavni.",
      zone: "rtapp-multifamily-landing rtapp-landing"
  },
  {
      question: "Èske gen diferan kalite valv pou dlo k ap tounen?",
      answer: "Wi, pami lòt, gen valv pòtay, boul ak klapè. Yon plonbye ki lisansye oswa yon enjenyè ka di ou kiyès, si genyen, ki pi bon pou kay ou an.",
      zone: "mitigation-backwater_valve"
  },
  {
      question: "Èske gen diferan kalite valv pou dlo k ap tounen nan egou?",
      answer: "Wi, pami lòt, gen valv pòtay, boul ak klapè. Valv pou dlo k ap tounen pote yon varyete non ki gen ladan yo valv verifikasyon ak valv ki gen bèk kana. Yon plonbye ki lisansye oswa yon enjenyè ka di ou kiyès, si genyen, ki pi bon pou kay ou an.",
      zone: "bwv-flood-applicants"
  },
  {
      question: "Èske mwen ka ajoute sou kay mwen an pou mwen ranplase espas mwen pèdi an?",
      answer: "Li gendwa posib pou ajoute yon lòt etaj sou kay ou an si li fè sans ekonomikman e li pa mete entegrite estrikti kay lan andanje. Tout ajou dwe konfòme yo tou ak kòd zonaj epi konstriksyon yo.",
      zone: "mitigation-abandon_first_floor"
  },
  {
      question: "Èske mwen ka aplike ak pwogram lan ankò?",
      answer: "Si ou pa satisfè egzijans kalifikasyon yo men ou enterese nan pran nouvèl nou lè nouvo pwogram tolerans pou inondasyon yo, tanpri rele nou nan (646) 786-0888 oswa voye yon imèl ban nou nan info@floodhelpny.org. Si sitiyasyon ou chanje, e ou panse ou ka satisfè egzijans kalifikasyon yo, mete ou alèz pou aplike ankò!",
      zone: "rtapp-status-Ineligible"
  },
  {
      question: "Èske mwen ka chwazi oditè kay mwen an?",
      answer: "Malerezman, akoz bezwen pou desèvi yon kokennchenn pwopriyetè kay ak pwogramasyon, ou pa ka chwazi oditè ou an.",
      zone: "rtapp-status"
  },
  {
      question: "Èske mwen ka ranpli sousòl mwen an mwen menm?",
      answer: "Ou bezwen yon enjenyè pou asire l ranplisaj sousòl ou an pa p andomaje estrikti kay ou an. Ou ap bezwen tou jwenn yon pèmi nan men Depatman Batiman NYC an. Ou gendwa kapab fè yon pati nan travay lan, men nou rekòmande pou ou angaje yon modelis ki kalifye pou ede ou. Yon modelis gendwa blije tou jwenn yon pèmi. Ou gendwa blije rekonfigire koneksyon ekipman sèvis piblik yo ak ekipman mekanik yo.",
      zone: "mitigation-fill_in_basement"
  },
  {
      question: "Èske mwen ka jwenn yon sètifika elevasyon?",
      answer: "Repons kout lan se non. Toutfwa, pwopriyetè anvan an gendwa deja gen yon sètifika; si ou nan pwosesis pou achte pwopriyete an oswa te achte pwopriyete an dènyeman, ou gendwa vle kontakte yo.",
      zone: "elevation-certificates"
  },
  {
      question: "Èske ou ka esplike finansman federal pwogram lan?",
      answer: "Pwogram lan jwenn finansman Sibvansyon Blòk pou Devlopman Kominotè pou Retablisman apre Katastwòf (CDBG-DR) Depatman Ameriken pou Lojman ak Devlopman (HUD) an. Finansman an livre atravè Pwogram Rekonstriksyon Kominotè NY Rising (NYRCR) lan, nan Biwo Gouvènè Eta New York lan pou Retablisman apre Tanpèt.\\n",
      zone: "rtapp-status-ApplicationSubmitted"
  },
  {
      question: "Èske pwopriyete mwen an blije te andomaje pandan Ouragan Sandy pou li kalifye?",
      answer: "Non ouragan an pa t blije afekte pwopriyete an pou l kalifye.",
      zone: "bwv-flood-applicants"
  },
  {
      question: "Èske mwen blije lakay mwen pou enstalasyon an?",
      answer: "Wi, pwopriyetè kay lan dwe prezan pandan vizit enjenyè an, rankont lansman an ak enstalasyon an.",
      zone: "bwv-flood-applicants"
  },
  {
      question: "Èske mwen blije rankontre ak ekip konstriksyon an?",
      answer: "Ekip konstriksyon ou an ap vin lakay ou pou rankont lansman an, sa ki ta dwe pran inèdtan pou rive dezèdtan.",
      zone: "rtapp-status-MeetYourTeam"
  },
  {
      question: "Èske mwen bezwen lakay mwen pandan evalyasyon enjenyè an?",
      answer: "Wi, ou ap bezwen lakay ou.",
      zone: "rtapp-status"
  },
  {
      question: "Èske mwen bezwen achte asirans kont inondasyon si mwen pa yon pwopriyetè kay?",
      answer: "Wi, domaj kont kontni inondasyon an pa gen chans pou asirans lokatè an kouvri l; asirans kont inondasyon an ap achte separe.",
      zone: "understanding-flood-insurance"
  },
  {
      question: "Èske mwen bezwen enstale vantilasyon kont inondasyon si mwen ranpli sousòl mwen an?",
      answer: "Wi. Vantilasyon kont inondasyon yo pwoteje kay ou nan asire l dlo pa ka monte sou yon bò nan mi an epi kreye presyon ki ta ka andomaje fondasyon ou an. Yo nesesè tou pou resevwa yon rediksyon asirans apre yon ranplisaj sousòl. Se yon enjenyè lisansye ki ta dwe enstale vantilasyon kont inondasyon yo.",
      zone: "mitigation-fill_in_basement"
  },
  {
      question: "Èske mwen bezwen peye asirans kont inondasyon an pandan kay mwen an ap elve?",
      answer: "Wi, kay ou an ap toujou vilnerab ak domaj nan inondasyon pandan l ap elve an.",
      zone: "mitigation-elevate"
  },
  {
      question: "Èske mwen bezwen relokalize padnan pwosesis elevasyon mekanik lan?",
      answer: "Sa pral depann ak ki ekipman ki relokalize, moman nan ane an ak lòt faktè. Li trè probab pou ou blije relokalize yon fason tanporè pandan omwen yon pati nan tan konstriksyon an.",
      zone: "mitigation-elevate_mechanicals"
  },
  {
      question: "Èske mwen bezwen relokalize pandan kay mwen an ap elve?",
      answer: "Wi, ekipman sèvis piblik yo tankou dlo, egou ak elektrisite ap dekonekte. Echèl ak lòt mwayen antre ap bezwen pou yo detache yo pandan konstriksyon an.",
      zone: "mitigation-elevate"
  },
  {
      question: "Èske ou gen lòt pwogram pou kay mwen an ki ta ka benefisye mwen?",
      answer: "Wi! Si ou vle konn ki lòt pwogram ak avantaj ou ta ka kalifye pou yo, tanpri kontakte nou nan (646) 786-0888.",
      zone: "rtapp-status-Ineligible rtapp-status-Withdrawn"
  },
  {
      question: "ske ou pataje enfòmasyon pwogram sa a ak Eta a?",
      answer: "Wi, nou pataje tou enfòmasyon sa a ak gouvènman Federal lan. Pwogram sa a jwenn finansman Sibvansyon Blòk pou Devlopman Kominotè pou Retablisman apre Katastwòf (CDBG-DR) Depatman Ameriken pou Lojman ak Devlopman (HUD) an epi Biwo Gouvènè an pou Retablisman apre Tanpèt (GOSR), li se yon ajans detay.",
      zone: "bwv-flood-applicants"
  },
  {
      question: "Èske yon aparèy pou lave, seche oswa refrijere konte kòm “machinri konstriksyon”?",
      answer: "Non, sila yo konte antanke kontni. Pou jwenn kouvèti pou eleman sa yo, ou blije achte asirans pou kontni.",
      zone: "rate-calculator-machinery-in-basement"
  },
  {
      question: "Èske yon sètifika elevasyon ekspire?",
      answer: "Non. Ou gendwa wè yon dat ekspirasyon nan kwen anwo adwat sètifika elevasyon ou an. Sa tou senpman vle di gen yon nouvo fòm lan. Yon sètifika elevasyon sou yon ansyen fòm valid toujou.",
      zone: "rtapp-preapp-elevation-certificate"
  },
  {
      question: "Èske travay konstriksyon Build It Back lan konte antanke yon peman?",
      answer: "Wi, asistans Build It Back jwenn finansman federal epi asistans sa a t ap rapòte.",
      zone: "understanding-flood-insurance"
  },
  {
      question: "Èske asirans pwopriyetè yo kouvri asirans kont inondasyon?",
      answer: "Asirans pou pwopriyetè kay lan pa kouvri inondasayon Èske asirans pwopriyetè yo kouvri asirans kont inondasyon?",
      zone: "rtapp-preapp-homeowner-insurance"
  },
  {
      question: "Èske revni mwen afekte kalifikasyon m pou pwogram lan?",
      answer: "Pwogram Odit pou Tolerans Kya lan ka sèlman desèvi fwaye ki gen revni fèb pou rive modere yo nan moman sa a. Dapre direktiv Depatman Lojman ak Devlopman Iben (HUD) Ameriken an, moun ki gen yon revni fanmi mwayen ki mwens pase 50 pousan Revni Mwayen Zòn (AMI) lan yo konsidere yo antanke Revni Fèb. Moun ki gen yon revni fanmi mwayen ki omwen 50 pousan e nan oswa mwens pase 80 pousan AMI an konsidere antanke Revni Modere. Si revni fwaye ou an depase paramèt sa yo, ou pa p kalifye pou pwogram sa a.",
      zone: "bwv-flood-applicants"
  },
  {
      question: "Èske pwogram sa a nan avantaj mwen?",
      answer: "Wi! Sèvis FloodHelpNY yo fèt pou ede rann fwaye yo mwens vilnerab ak inondasyon epi redui posiblite pou domaj kont kay yo, ansanm ak domaj kont apatman lokasyon yo, nan ka yon inondasyon oswa refoulman plonbri egou.",
      zone: "bwv-flood-applicants"
  },
  {
      question: "Kijan yo chwazi pwopriyetè batiman yo pou patisipe nan pwogram lan?",
      answer: "Priyorite an baze sou revni lokatè batiman yo, kit yo rete nan zòn ki gen 100 lane depi l inondab la, e kit Sipètanpèt Sandy an te andomaje batiman an.",
      zone: "rtapp-multifamily-landing"
  },
  {
      question: "Kijan yo chwazi pwopriyetè kay yo pou patisipe nan pwogram lan?",
      answer: "Pwopriyetè kay ki satisfè tout egzijans kalifikasyon yo ap kapab patisipe nan pwogram lan. Si ou enterese pou yo konsidere ou, tanpri ranpli fòmilè admisyon brèf nou an sou entènèt.",
      zone: "rtapp-landing"
  },
  {
      question: "Kijan mwen ka jwenn yon kontraktè pou ranpli sousòl mwen an?",
      answer: "Depatman Batiman NYC yo <a target=\"_blank\" href=\"http://www1.nyc.gov/site/buildings/homeowner/homeowner.page\">ka ba ou konsèy</a> sou fason pou jwenn yon modelis ak kontraktè ki gen eksperyans nan travay nan zòn inondasyon yo. Kontraktè yo ta dwe gen eksperyans, lisansye epi asire. Verifye referans.",
      zone: "mitigation-fill_in_basement"
  },
  {
      question: "Kijan mwen ka jwenn si mwen ka elve kay mwen an?",
      answer: "Ou ap bezwen angaje yon enjenyè kalifye ki ka evalye si elevasyon an se yon bon opsyon pou kay ou an.",
      zone: "mitigation-elevate"
  },
  {
      question: "Kijan mwen ka konnen si mwen ka ranpli sousòl mwen an?",
      answer: "Konsilte yon enjenyè pwofesyonèl, kontraktè ki lisansye oswa Depatman Batiman NYC yo.",
      zone: "mitigation-fill_in_basement"
  },
  {
      question: "Kijan mwen ka jwenn yon sètifika elevasyon?",
      answer: "Se yon apantè teren oswa yon enjenyè ki dwe reyalize sètifika elevasyon yo e yo ka koute ant $500 ak $800 pou dosye ki pa konplike yo, men yo ka plis pou dosye ki pi konplike.",
      zone: "understanding-flood-insurance"
  },
  {
      question: "Kijan mwen ka jwenn kouvèti sa a?",
      answer: "Pwopriyetè kay yo ka diskite sou fason pou jwenn yon avnan asirans pou refoulman dlo ize ak founisè asirans pou pwopriyetè yo. Asirans pou refoulman dlo ize jeneralman koute mwens pase $100 chak ane. Reklamasyon, kredi ak elevasyon nan nivo lari oparavan ka pafwa afekte tarif yo.",
      zone: "rtapp-preapp-sewage"
  },
  {
      question: "Kijan mwen ka aprann plis sou asirans kont inondasyon?",
      answer: "Verifye <a href=\"https://www.floodhelpny.org/en/understanding-flood-insurance\">paj resous global nou an!</a>",
      zone: "rtapp-preapp-flood-insurance"
  },
  {
      question: "Kijan mwen ka bese tarif asirans kont inondasyon mwen an?",
      answer: "Pou kounya, gen sèlman kat mezi ki gen yon efè sou prim asirans yo,: elevasyon kay lan, ranplisaj yon sousòl oswa espas vid epi enstalasyon vantilasyon kont inondasyon, abandon premye etaj ou an epi elevasyon ekipman mekanik yo nan sousòl lan. Dènye opsyon an bay pi piti rediksyon asirans nan twa yo, men li ka ekonomize ou yon pakèt lajan nan ka yon inondasyon.",
      zone: "understanding-flood-insurance"
  },
  {
      question: "Kijan mwen ka di si pwopriyete mwen an gen yon valv pou dlo k ap tounen?",
      answer: "Pi bon fason pou dekouvri si ou gen yon valv pou dlo egou k ap tounen oswa si ou bezwen youn se pou konsilte ak yon plonbye ki lisansye epi kalifye. Yon valv dlo egou k ap tounen ka difisil pou idantifye pou kont ou, espesyalman si l te enstale lontan.",
      zone: "bwv-flood-applicants"
  },
  {
      question: "Kijan mwen ka di si pwopriyete mwen an gen yon valv pou dlo k ap tounen?",
      answer: "Pi bon fason pou detèmine si ou gen yon valv pou dlo egou k ap tounen oswa si ou bezwen youn se pou konsilte ak yon plonbye ki lisansye epi kalifye. Yon valv dlo egou k ap tounen ka difisil pou idantifye pou kont ou, espesyalman si l te enstale lontan.",
      zone: "rtapp-preapp-backwater-exist"
  },
  {
      question: "Kijan mwen ka chwazi ki opsyon tolerans pou inondasyon bon pou mwen?",
      answer: "Ou ta dwe konsidere ki opsyon ki disponib pou kay ou (pa egzanp, ou pa ka elve fasilman yon kay ki atache), depans davans pou mezi adousisman an, epi potansyèl ekonomi sou prim asirans ou an ofiramezi.",
      zone: "programs-and-benefits resiliency"
  },
  {
      question: "Kijan mwen detèmine dimansyon fwaye mwen an pou pwogram lan?",
      answer: "Dimansyon fwaye an ka detèmine nan konte kantite moun ki viv ak ou, ansanm ak ou menm.",
      zone: "rtapp-preapp-household-size"
  },
  {
      question: "Kijan mwen ka dekouvri Elevasyon Inondasyon Debaz (BFE) kay mwen an?",
      answer: "Kòmanse konnen zòn ou an. Si ou nan yon zòn AE, yon sètifika elevasyon se pi bon fason pou kou konnen BFE ou an ak presizyon. Si ou nan yon zòn X, ou nan yon zòn nan kat inondasyon an ki pa gen yon elevasyon inondasyon debaz ki pwojte aktyèlman.",
      zone: "understanding-flood-insurance mitigation-flood_vents mitigation-abandon_first_floor mitigation-elevate_mechanicals"
  },
  {
      question: "Kijan mwen ka dekouvri Elevasyon Inondasyon Konsepsyon (DFE) kay mwen an?",
      answer: "DFE an detèmine elevasyon ki pi sekirize an pou konstriksyon nan zòn risk inondasyon yo. Nan NYC, DFE an se abityèlman Elevasyon Inondasyon Debaz lan plis de pye — yo konnen l antanke “franbò,”pou sekirite anplis. Modelis oswa achitèk ou an ta dwe konsilte Depatman Batiman NYC yo pandan konsepsyon lan epi pèmèt asire yo ap suiv DFE an.",
      zone: "mitigation-flood_vents mitigation-abandon_first_floor mitigation-elevate_mechanicals"
  },
  {
      question: "Kijan mwen ka dekouvri nan ki nivo ekipman mekanik mwen yo ta dwe elve?",
      answer: "Ou menm oswa kontraktè ou an ta dwe toujou konsilte Depatman Batiman NYC yo. Ideyalman, ekipman yo ta dwe elve nan Elevasyon Inondasyon Konsepsyon an, ki se Elevasyon Inondasyon Debaz lan plis de pye franbò.",
      zone: "mitigation-elevate_mechanicals"
  },
  {
      question: "Kijan mwen jwenn èd pou ranpli aplikasyon an?",
      answer: "Si ou gen nenpòt kesyon, bezwen èd pou ranpli aplikasyon an, mete ou alèz pou rele Platfòm pou Pwopriyetè Kay nou an nan (646) 786-0888.",
      zone: "rtapp-status-FullApplication"
  },
  {
      question: "Kijan mwen konnen nan ki nivo pou mwen elve kay mwen an?",
      answer: "Jeneralman, BFE plis de pye franbò obligatwa nan NYC. Men li se yon bon lide pou konsilte ak yon enjenyè kalifye ki gen eksperyans nan fè konstriksyon nan zòn inondasyon yo. Depatman Batiman NY an <a target=\"_blank\" href=\"http://www1.nyc.gov/site/buildings/homeowner/homeowner.page\">ka ede ou jwenn youn</a>.",
      zone: "mitigation-elevate"
  },
  {
      question: "Kijan mwen ka konnen si mwen gen yon “sousòl” oswa yon “espas vid”?",
      answer: "Yon sousòl se yon espas kote etaj lan pi ba pase nivo sòl lan nan tout kat kwen yo. Si li mwens pase 5 pye wotè, yo jeneralman konsidere l antanke yon espas vid.",
      zone: "rate-calculator-building-feature"
  },
  {
      question: "Kijan pou mwen konnen mwen bezwen yon valv pou dlo k ap tounen?",
      answer: "Toujou konsilte yon plonbyen lisansye pou detèmine si ou t ap tire pwofi nan yon valv pou dlo k ap tounen piske fè enstale youn nan sèten pwopriyete ka anfèt rann bagay yo pi mal.",
      zone: "mitigation-backwater_valve"
  },
  {
      question: "Kijan pou mwen konnen si mwen te pran soulajman federal oparavan?",
      answer: "Si ou te resevwa èd FEMA, SBA, Build It Back oswa New York Rising — pou reparasyon oswa rekonstriksyon kay ou an oswa pou kontni yo — akoz domaj inondasyon lakoz, eben ou te resevwa soulajman federal pou katastwòf. Pou pifò moun, sa t ap ede pou domaj Ouragan Irèn oswa Sipètanpèt Sandy lakoz.",
      zone: "understanding-flood-insurance"
  },
  {
      question: "Kijan pou mwen konnen mwen te gen refoulman plonbri?",
      answer: "Ou ta ka remake dlo ak/oswa move lodè k ap monte atravè twalèt ou, evye, benwa, kanalizasyon ki nan planche yo oswa lòt aparèy plonbri.",
      zone: "bwv-flood-applicants"
  },
  {
      question: "Kijan pou mwen konnen kibò mwen ta dwe enstale valv lan?",
      answer: "Yon plonbye ki lisansye pral evalye kondisyon an epi prezantasyon lateral egou ou an. Li pral idantifye anplasman ki pi apwopriye an pou enstale valv lan. Toujou konsilte ak yon plonbye ki lisansye pou detèmine anplasman an piske yon valv pou dlo k ap tounen ki enstale nan move kote an ka anfèt rann bagay yo pi mal.",
      zone: "mitigation-backwater_valve"
  },
  {
      question: "Kijan pou mwen konnen ki kalite valv pou dlo k ap tounen pou mwen enstale?",
      answer: "Yon plonbye ki lisansye oswa yon enjenyè ka di ou ki kalite valv pou dlo k ap tounen, si genyen, ki pi bon pou kay ou an.",
      zone: "mitigation-backwater_valve"
  },
  {
      question: "Kijan pou m antretni valv pou dlo egou k ap tounen an?",
      answer: "Mande plonbye ou an pou founi ou enstriksyon pou operasyon ak antretyen yo apati fabrikan valv ou an. Li abityèlman ase senp — ouvri pwen aksè valv ou an kèk fwa cha ane pou asire w pa gen anyen ki bouche valv ou an. Si yon bagay ap bouce l, retire l. Si klapè an bezwen librifyan, mete l. Kèk plonbye ofri pwogram antretyen e yo ap fè sa pou ou. Si ou resevwa yon valv pou dlo k ap tounen atravè pwogram nou an, plonbye ki enstale valv ou an pral montre ou kote valv lan ye, kijan li fonksyone, epi fason pou kenbe l. Anplis, ou ap resevwa yon rapò sou teren lè enstalasyon an fini ki gen ladan l enstriksyon presi pou fonksyonnman ak antretyen nan men fabrikan valv ou an.",
      zone: "bwv-flood-applicants mitigation-backwater_valve rtapp-status-InstallationComplete"
  },
  {
      question: "Kijan pou mwen kalifye pou dwa mwen pran pou “konfòmite nan konstriksyon” nan yon kontra asirans kont inondasyon?",
      answer: "Ou ka kalifye sèlman si kay ou an te konstwi apre novanm 1983 epi ou aktyèlman nan yon zòn gwo risk inondasyon. Ou dwe montre tou kay ou an te konstwi nan konfòmite ak kat aktyèl pou inondasyon an e ou pa modifye li. Si ou te konvèti yon garaj premye etaj an espas pou viv, pa egzanp, ou pa p kalifye amwenske ou retounen l jan l te ye an.",
      zone: "understanding-flood-insurance"
  },
  {
      question: "Kijan pou mwen kalifye pou dwa mwen pran pou “kouvèti pèmanan” nan yon kontra asirans kont inondasyon?",
      answer: "Asire w ou gen yon kontra anvan kat yo chanje pratikman vle ou pa gen asirans pèmanan.",
      zone: "understanding-flood-insurance"
  },
  {
      question: "Kijan pou m soumèt dokiman obligatwa aplikasyon mwen an?",
      answer: "Pou soumèt dokiman ou yo, ou gendwa:<ul><li>Eskane epi telechaje atravè paj estati ou an sou FloodHelpNY.org.</li><li>Voye faks bay Center for NYC Neighborhoods: 1-646-849-1765</li><li>Voye kopi nan lapòs pou Center for NYC Neighborhoods Attn.</li><li>FloodHelpNY, 55 Broad Street, 10th Floor, New York, NY 10004.</li></ul>",
      zone: "bwv-flood-applicants"
  },
  {
      question: "Kijan pou mwen soumèt Antant sou Sibvansyon pou Kay (HGA) lan bay Sant lan?",
      answer: "Si sèlman yon moun bezwen siyen Antant sou Sibvansyon pou Kay lan (sa vle di, gen sèlman yon moun ki sou lis papye byen an), eben ou ka siyen dokiman an elektwonikman. Apre nou detèmine kalifikasyon ou nou ap voye yon lyen ba ou pou fè sa. Si plis pase yon moun bezwen siyen Antant sou Sibvansyon pou Pwopriyetè Kay ou an, ou ka telechaje epi enprime dokiman an apati paj estati ou an. Yon fwa li ranpli, ou ka fakse l bay (646) 506-4621, imèl yon kopi bay info@floodhelpny.org oswa voye l nan lapòs apati sèvis postal lan bay FloodHelpNY, Center for NYC Neighborhoods, 55 Broad Street, 10th Floor, New York, NY 10004.",
      zone: "rtapp-status-GrantAgreement"
  },
  {
      question: "Kijan mach yo afekte tarif asirans kont inondasyon mwen an?",
      answer: "Si ou pa gen yon sousòl oswa yon espas vid ki anba nivo sòl lan, tarif ou an detèmine dapre elevasyon premye etaj ou an. Plis etaj sa a pi wo Elevasyon Inondasyon Debaz lan, plis tarif ou ap ba, pratikman.",
      zone: "rate-calculator-number-of-steps"
  },
  {
      question: "Kijan eleman sa yo nan kay lan afekte tarif asirans kont inondasyon mwen an?",
      answer: "Plis etaj ki pi ba ou an ba, plis tarif asirans kont inondasyon ou an ap wo, pratikman.",
      zone: "rate-calculator-building-feature"
  },
  {
      question: "Kijan pou ou kalifye pou yon kontra asirans kont inondasyon pou Risk Favori?",
      answer: "Ou dwe nan Zòn X oswa pa nan okenn zòn epi te sèlman resevwa yon peman plis pase $1,000 apati yon pwogram asistans federal pou katastwòf pou inondasyon separe nan dènye 10 lane yo. Asistans federal pou katastwòf vle di asirans kont inodnasyon NFIP oswa FEMA oswa SBA oswa Build It Back.",
      zone: "understanding-flood-insurance"
  },
  {
      question: "Kijan yon sousòl afekte tarif asirans kont inondasyon mwen an?",
      answer: "Si ou ap viv nan yon zòn gwo risk inondasyon, ou evalye sou baz wotè etaj ki pi ba ou an parapò ak BFE ou an. Si ou gen yon sousòl oswa espas vid, ou ap tarife sou baz elevasyon etaj sa a, menm si espas lan pa fini.",
      zone: "rate-calculator-basement"
  },
  {
      question: "Kijan gwo machin ki nan sousòl lan afekte tarif asirans kont inondasyon mwen an?",
      answer: "Gen gwo machin nan sousòl ou an ogmante tarif ou, men se pa anpil li met sou li. Men elve gwo machin sa yo nan sousòl ou an ka gen gwo enpak sou vitès ou ka restore chalè ak elektrisite nan kay ou apre yon tanpèt.",
      zone: "rate-calculator-machinery-in-basement"
  },
  {
      question: "Kijan kalite batiman afekte tarif asirans kont inondasyon mwen an?",
      answer: "Toudepan kalite batiman ou an, gen diferan opsyon tolerans ki disponib.",
      zone: "rate-calculator-building-type"
  },
  {
      question: "Kijan kontni an afekte tarif asirans kont inondasyon mwen an?",
      answer: "Asirans kontni pou sousòl ak espas fèmen yo pi chè pase etaj ki pi wo yo, e asirans lan nan sousòl ak espas fèmen yo limite ak yon aparèy pou lave, seche epi konjle. Se sa. Li gendwa pa vo pi gwo frè pou asirans kontni yo pou fè asire atik sa yo nan sousòl ou an.",
      zone: "rate-calculator-content-location"
  },
  {
      question: "Kijan elevasyon an afekte tarif asirans kont inondasyon mwen an?",
      answer: "Si ou ap viv nan yon zòn gwo risk inondasyon, ou evalye sou baz elevasyon etaj ki pi ba ou an parapò ak BFE ou an. Lè ou elve kay ou an pou li vin chita sou 2 pye pi wo pase BFE ou an, ou redui yon fason konsiderab chans pou dlo inondasyon antre lakay ou, e kidonk depans asirans lan.",
      zone: "rate-calculator-elevated-building"
  },
  {
      question: "Kijan domaj nan inondasyon oparavan afekte tarif asirans kont inondasyon mwen an?",
      answer: "Si ou te resevwa plis pase $1,000 reklamasyon nan men asirans kont inondasyon, FEMA oswa Administrasyon Ti Biznis de fwa oswa plis pou diferan inondasyon, ou pa kalifye pou yon kontra fèb Tarif Risk Favori. Sonje, peman yo dwe fèt pou diferan inondasyon, non pa de peman pou domaj Sandy yo. Menm si ou te touche $50,000 oswa $100,000 nan men FEMA ak asirans kont inondasyon pou Sandy, sa se sèlman yon reklamasyon.",
      zone: "rate-calculator-preferred-risk-rate"
  },
  {
      question: "Kijan domaj nan inondasyon oparavan afekte tarif asirans kont inondasyon mwen an?",
      answer: "Si ou te resevwa plis pase $1,000 reklamasyon nan men asirans kont inondasyon, FEMA oswa Administrasyon Ti Biznis de fwa oswa plis pou diferan inondasyon, ou pa kalifye pou yon kontra fèb Tarif Risk Favori. Sonje, peman yo dwe fèt pou diferan inondasyon, non pa de peman pou domaj Sandy yo. Menm si ou te touche $50,000 oswa $100,000 nan men FEMA ak asirans kont inondasyon pou Sandy, sa se sèlman yon reklamasyon.",
      zone: "rate-calculator-severe-repetitive-loss"
  },
  {
      question: "Kijan chanjman klimatik lan ap afekte Vil New York?",
      answer: "Chanjman klimatik lan ajoute yon eleman ki totalman diferan ak refleksyon apwopo tolerans nan Vil New York. Ou ka li plis apwopo enpak chanjman klimatik kont vil lan <a target=\"_blank\" href=\"http://onlinelibrary.wiley.com/doi/10.1111/nyas.12591/full\">here</a>.",
      zone: "resiliency rtapp-multifamily-landing"
  },
  {
      question: "Poukisa pwogram lan ap finanse aplika ki egziste deja e non pa nouvo aplikan?",
      answer: "Se sa a sèlman pou Canarsie ak Red Hook. Carnarsie se te youn nan kominote ki pi patisipe yo nan pwogram odit pou tolerans kay inisyal lan anvan enstalasyon valv pou dlo k ap tounen yo te enstale. Finansman pou odit nan Canarsie te satire, men nou resevwa finansman anplis pou enstale valv pou dlo k ap tounen depi lè an. Piske pèsòn pa ka fè yo enstale yon valv pou dlo k ap tounen san pa gen yon odit, moun nan Canarsie yo ki pa t deja gen yon odit e ki pa t ale nan oryantasyon anvan finansman fini pa p kalifye pou jwenn yon odit oswa yon valv pou dlo k ap tounen. Li posib pou alokasyon finansman yo ta ka chanje alavni, e nou ap asire nou pou nou enfòme ou si sa rive.",
      zone: "bwv-flood-applicants"
  },
  {
      question: "Konbyen tan sa ap dire pou abandone premye etaj lan?",
      answer: "Tan ap varye toudepan konstriksyon batiman an, dimansyon espas ki ap abandone an nan nivo enferyè an e konbyen ranplisaj espas ki pi ba nivo sòl yo ap egzije. Yon bon estimasyon soti nan kèk semèn pou rive 90 jou. Toutfwa, tan anplis ta dwe otorize pou konsepsyon ak pou jwenn pèmi.",
      zone: "mitigation-abandon_first_floor"
  },
  {
      question: "Konbyen tan li pran pou elve kay mwen an?",
      answer: "Li ka pran jiska twa mwa oswa pi plis pou fè travay lan li menm. Toutfwa, kreye konsepsyon an epi jwenn pèmi yo ka bwè tout delè pou elve kay ou an.",
      zone: "mitigation-elevate"
  },
  {
      question: "Konbyen tan ranplisaj sousòl mwen an pran?",
      answer: "Li ka pran kè jou pou ranpli sousòl oswa espas vid lan. Men li ka pran youn pou rive de semèn anplis anmwayèn si ou bezwen relokalize ekipman mekanik yo nan sousòl oswa espas vid lan. Ajoute sou tan li pran pou jwenn pèmi yo e li ta ka pran menm plis tan.",
      zone: "mitigation-fill_in_basement"
  },
  {
      question: "Konbyen tan elve ekipman mekanik mwen yo pran?",
      answer: "Li ka pran soti twa pou rive senk jou pou rive kèk semèn apre pèmi yo jwenn epi konstriksyon an kòmanse.",
      zone: "mitigation-elevate_mechanicals"
  },
  {
      question: "Konbyen tan pwosesis apwobasyon aplikasyon an jeneralman pran?",
      answer: "Yon fwa aplikasyon ou an ak dokiman ki anrapò yo resevwa, ou ka atann ou pou yon desizyon apwobasyon nan espas senk jou ouvrab.",
      zone: "rtapp-status-ApplicationSubmitted"
  },
  {
      question: "Konbyen pwoteksyon kont inondasyon an pran?",
      answer: "Enstale vantilasyon kont inondasyon ka fèt nan kèk jou.",
      zone: "mitigation-flood_vents"
  },
  {
      question: "Konbyen tan enstalasyon valv pou dlo k ap tounen mwen an pran?",
      answer: "Kantite tan enstalasyon an pran depann ak prezantasyon kay ou an ak koneksyon l ak egou vil lan, ansanm ak kondisyon kanalizasyon ki konekte kay ou an ak egou vil lan. Erezman, li ap pran yon vizit sèlman, men plonbyen an gendwa blije retounen yon lòt jou pou fini netwaye oswa pou fini yon enstalasyon ki difisil. Ou bezwen kanmenm prezan lakay ou.",
      zone: "rtapp-status-InstallationToBeScheduled"
  },
  {
      question: "Konbyen tan konsiltasyon an ap pran?",
      answer: "Konsiltasyon an ta dwe pran ant 45 minit pou rive inèdtan edmi.",
      zone: "rtapp-status"
  },
  {
      question: "Konbyen tan evalyasyon pou inondasyon kay lan ap pran?",
      answer: "Evalyasyon an ap pran yon maksimòm dezèdtan.",
      zone: "rtapp-status"
  },
  {
      question: "Konbyen tan pwogram sa a ap pran?",
      answer: "Li difisil pou estime, men pwosesis lan ta ka pran jiska simwa apati kòmansman pou fen an toudepan orè ou an, jan enstalasyon an ap konplike e pwosesis pou fè kontra. Mèsi pou pasyans ou!",
      zone: "bwv-flood-applicants"
  },
  {
      question: "Konbyen jou mwen bezwen pran nan travay mwen pou patisipe nan pwogram lan?",
      answer: "Si ou te deja resevwa yon Odit pou Tolerans Kay FloodHelpNY nou ap bezwen ou lakay ou pou twa bagay: analiz fezabilite enjenyè an, Rankont Lansman ak plonbye an, epi enstalasyon an. Si ou pa t resevwa yon Odit pou Tolerans Kay FloodHelpNY nou ap bezwen ou lakay ou pou odit lan, Rankont Lansman an ak enstalasyon an li menm. Nou atann nou pou odit lan pran yon maksimòm dezèdtan. Kalandriye pou Rankont Lansman an ak enstalasyon an rete menm bagay lan pou pwopriyetè kay ki te resevwa yon odit yo.",
      zone: "bwv-flood-applicants"
  },
  {
      question: "Konbyen jou mwen bezwen pran nan travay mwen pou patisipe nan pwogram lan?",
      answer: "Ou ap bezwen lakay ou pou rankont lansman an ak enstalasyon an. Rankont lansman an se lè pwopriyetè kay lan ap rankontre plonbye ak enspektè yo an, e li ta dwe pran yon maksimòm inèdtan. Yon enstalasyon tipik ta dwe pran youn oswa de jou, toudepan kay lan.",
      zone: "rtapp-status-InstallationPrepare"
  },
  {
      question: "Konbyen moun k ap vini evalye lakay mwen?",
      answer: "Yon ekip de pou rive kat enjenyè ak apantè ki sètifye ap vizite lakay ou pou evalye vilnerabilite inondasyon li epi fè rekòmandasyon pou fason pou pwoteje kay ou kont pwochen inondasyon.",
      zone: "rtapp-status-AuditScheduled"
  },
  {
      question: "Konbyen li koute pou abandone premye etaj lan?",
      answer: "Sa depan ak itilizasyon aktyèl premye etaj lan e si ou bezwen relokalize ekipman mekanik yo, ekipman sèvis piblik yo, kizin ak/oswa saldeben yo. Frè an ta ka $10,000 pou rive $100,000 oswa plis.",
      zone: "mitigation-abandon_first_floor"
  },
  {
      question: "Konbyen li koute pou ranplisaj sousòl mwen an?",
      answer: "Toudepan pwofondè sousòl lan e si ou bezwen pou relokalize sèvis piblik yo ak ekipman epi enstale vantilasyon kont inondasyon yo, li ta ka koute $8,500 pou rive $30,000.",
      zone: "mitigation-fill_in_basement"
  },
  {
      question: "Konbyen li koute pou enstale yon valv pou dlo k ap tounen?",
      answer: "Li depann ak yon kantite faktè, ansanm ak prezantasyon tiyo egou ou yo, pwofondè ak anplasman tiyo ki konekte kay ou ak egou an, si gen risk anviwonnmantal (sa vle di penti ki gen plon) nan zòn kote valv lan pral enstale an, ki kalite valv ki enstale, elatriye. Depans lan ka piti jiska $600 oswa plis pase $5,000.",
      zone: "mitigation-backwater_valve"
  },
  {
      question: "Konbyen kouvèti asirans kont inondasyon mwen blije genyen si mwen gen asistans pou katastwòf (FEMA, SBA, Build It Back)?",
      answer: "Si ou te resevwa asistans pou katastwòf pou repare oswa rekonstwi batiman ou an oswa pou kontni ki andomaje, ou dwe gen kouvèti pou omwen kantite asistans ou te resevwa. Kouvèti kontni obligatwa si ou te resevwa asistans federal pou kontni ki andomaje yo. Egzijans sa a rete ak pwopriyete an pou toutan, pou ou menm ak pwochen achtè an. Ou dwe pale yon achtè sou egzijans sa a.",
      zone: "understanding-flood-insurance"
  },
  {
      question: "Konbyen kouvèti asirans kont inondasyon mwen blije genyen si mwen gen yon ipotèk?",
      answer: "Si ou nan yon zòn ki gen gwo risk inondasyon e ou gen yon ipotèk, ou dwe gen kouvèti pou batiman pou omwen balans ki pa peye sou ipotèk lan (balans prensipal lan) pandan toutotan ou gen ipotèk lan. Kouvèti kontni yo pa obligatwa.",
      zone: "understanding-flood-insurance"
  },
  {
      question: "Konbyen asirans kont inondasyon ki obligatwa, e kilè ou bezwen achte l?\\n",
      answer: "Si aplikan an ap viv nan yon zòn AE, asirans kont inondasyon an dwe achte anvan yo aksepte yo nan pwogram lan. Kouvèti asirans kont inondasyon batiman an dwe egal ak frè pou enstalasyon valv pou dlo k ap tounen an, anmwayèn, anviwon $1,500.",
      zone: "bwv-flood-applicants"
  },
  {
      question: "Konbyen asirans pou pwopriyetè kay ki obligatwa, e kilè ou bezwen achte l?",
      answer: "Ou dwe gen asirans pou pwopriyetè kay anvan yo aksepte ou nan pwogram lan. Nou pa presize konbyen asirans pou pwopriyetè kay ou dwe genyen, senpman pou ou genyen l.",
      zone: "rtapp-preapp-homeowner-insurance"
  },
  {
      question: "Konbyen asirans pou pwopriyetè kay ki obligatwa, e kilè ou bezwen achte l?",
      answer: "Aplikan an dwe gen asirans pou pwopriyetè kay anvan yo aksepte yo nan pwogram lan. Nou pa presize konbyen asirans pou pwopriyetè kay yo dwe genyen. Yo senpman dwe genyen l anjeneral.",
      zone: "bwv-flood-applicants"
  },
  {
      question: "Konbyen elevasyon ekipman mekanik mwen yo koute?",
      answer: "Toudepan kay ou an, kontraktè e ki ekipman pou sèvis piblik ki relokalize, li ka koute ant $5,000 e $40,000.",
      zone: "mitigation-elevate_mechanicals"
  },
  {
      question: "Konbyen Pwogram Enstalasyon Rezidansyèl Valv pou Dlo k Ap Tounen an ap koute m?",
      answer: "Pwogram Enstalasyon Rezidansyèl Valv pou Dlo k Ap Tounen an ap koute an totalman gratis.",
      zone: "bwv-flood-applicants"
  },
  {
      question: "Konbyen pwoteksyon kont inondasyon an ap koute?",
      answer: "Enstale vantilasyon ka koute ant $4,000 ak $8,000.",
      zone: "mitigation-flood_vents"
  },
  {
      question: "Kijan mwen ta dwe prepare pou enstalasyon valv pou dlo k ap tounen an?",
      answer: "Anplis asire w plonbye an gen aksè fasil ak espas sousòl lan kote valv lan pral enstale an, tanpri prepare w tou pou ou lakay ou e fè plan pou lè dlo an fèmen.",
      zone: "rtapp-status-InstallationPrepare"
  },
  {
      question: "Kijan mwen ap antre lakay mwen si premye etaj lan abandone?",
      answer: "Ou ap bezwen fè konstwi nouvo eskalye pou antre. Nan kèk sikonstans, ou gendwa bezwen fè enstale yon asansè mekanik.",
      zone: "mitigation-abandon_first_floor"
  },
  {
      question: "Kòman enfòmasyon mwen yo ap pwoteje?",
      answer: "Konfidansyalite ak sekirite enfòmasyon ou yo trè esansyèl pou nou. Nou itilize mezi sekirite fizik, nimerik ak administratif pou kenbe enfòmasyon pèsonèl ou yo sekirize epi konfidansyèl. Nou pa p founi okenn enfòmasyon bay tyès pati ki pa enplike nan administre FloodHelpNY san pèmisyon ou oswa jan lalwa egzije l. Nou respekte tou egzijans konfidansyalite strik gouvènman Eta a ak federal lan etabli.",
      zone: "bwv-flood-applicants"
  },
  {
      question: "Mwen dejan gen yon sètifika elevasyon, èske gen yon bagay mwen ta dwe konsidere?",
      answer: "Si ou gen yon sètifika elevasyon, ou pa bezwen soumèt yon nouvo sètifika. Toutfwa, si ou te fè nenpòt chanjman nan pwopriyete an, tankou amelyorasyon pou minimize efè inondasyon yo, ou gendwa vle yon sètifika ki ajou pou asire ou resevwa pri kòrèk lan.",
      zone: "elevation-certificates"
  },
  {
      question: "Mwen te deja pase nan Pwogram Odit pou Tolerans Kay FloodHelpNY lan. Kijan pou mwen jwenn yon valv pou dlo k ap tounen ye?",
      answer: "Nan yon fason retwo-aktif yo ap konsidere ou pou Pwogram Enstalasyon Rezidansyèl Valv pou Dlo k Ap Tounen an. Lè pwogram lan lanse Sant lan ap imèl ou aplikasyon Valv pou Dlo k Ap Tounen an. Aplikasyon an ap blije ranpli epi voye tounen bay Sant lan pou analiz kalifikasyon pi pouse. Kalifikasyon ou ap kondisyonèl ak rezilta analiz aplikasyon ou an ak konsta enjenyè ak plonbye an lakay ou. Yon desizyon kalifye pa vle di nesesèman ou ap jwenn yon valv piske se sèlman fwaye ki gen revni ki fèb pou rive modere yo n ap desèvi nan moman sa a.",
      zone: "bwv-flood-applicants"
  },
  {
      question: "Mwen kwè mwen nan yon katye ki kalifye pou Odit pou Tolerans Kay, men mwen pa ka enskri.",
      answer: "Kontakte nou nan info@floodhelpny.org. Voye ban nou non, adrès ou epi pale nou apwopo sa k te rive lè ou te eseye aplike. Nou ap fè rechèch epi kontakte ou. Ou ka rele tou Liy Èd nou an nan 646-786-0888.",
      zone: "rtapp-landing"
  },
  {
      question: "Mwen konnen mwen te resevwa yon imèl kèk jou apre mwen aplike, men mwen pa ka jwenn li, kisa pou m fè kounya?",
      answer: "Gen anpil chans pou ou nan faz aplikasyon an. Ou ka konekte sou floodhelpny.org epi paj estati ou an ap di ou nan ki faz ou ye e sa ou bezwen fè.",
      zone: "bwv-flood-applicants"
  },
  {
      question: "Mwen toujou gen kesyon sou sètifika elevasyon oswa asirans kont inondasyon. Èske ou ka ede?",
      answer: "Floodsmart.gov se yon gwo resous pou jwenn plis enfòmasyon sou asirans kont inondasyon ak sètifika elevasyon. Ou ka santi ou alèz tou pou voye kesyon ou yo nan info@floodhelp.org.",
      zone: "elevation-certificates"
  },
  {
      question: "Si yon aplikan nan Canarsie oswa Red Hook te resevwa yon sètifika elevasyon epi ou te patisipe nan oryantasyon, èsie yo ap konsidere yo pou pwogram Valv pou Dlo k Ap Tounen an?",
      answer: "Wi, sèl abitan nan Canarsie ak Red Hook yo ap konsidere pou Pwogram Valv pou Dlo k Ap Tounen an se sila yo ki te resevwa yo odit epi te patisipe nan oryantasyon yo.",
      zone: "bwv-flood-applicants"
  },
  {
      question: "Kijan pou m jwenn yon sètifika elevasyon?",
      answer: "Sètifika Elevasyon dwe prepare epi sètifye nan men yon Apantè pou Teren ki Lisansye, Enjenyè Pwofesyonèl ki Anrejistre oswa Achitèk ki Anrejistre ki otorize dapre lwa Eta a oswa lokal lan pou sètifye enfòmasyon sou elevasyon. Mande yo lisans yo, eksperyans yo Vil New York oswa nan katye ou an, nenpòt sètifikasyon ak referans apati pwojè ki parèy. FEMA rekòmande pou jwenn yon apantè pwofesyonèl nan verifye asosasyon pwofesyonèl nan Eta ou an pou apantè teren oswa nan pale ak biwo pèmi konstriksyon lokal ou an.",
      zone: "elevation-certificates"
  },
  {
      question: "Si mwen pa konnen konbyen apatman rezidansyèl ki gen nan batiman mwen an, kijan pou m konnen?",
      answer: "Depatman Batiman Vil lan gendwa gen sètifika okipasyon batiman an anrejistre ki montre kantite inite lojman li genyen.",
      zone: "rtapp-preapp-number-of-units"
  },
  {
      question: "Si mwen pa konnen si pwopriyete an gen domaj apati inondasyon, kijan mwen ka dekouvri sa a?",
      answer: "Mande koutye asirans kont inondasyon ou an oswa FEMA sa antesedan pèt nan inondasyon batiman ou an ye.",
      zone: "rate-calculator-severe-repetitive-loss rate-calculator-preferred-risk-rate"
  },
  {
      question: "Si mwen pa konnen kilè batiman mwen an te konstwi, kijan mwen ka dekouvri sa?",
      answer: "Si ou te janm fè yon evalyasyon, li ta dwe ba ou dat konstriksyon an. Si ou gen yon kay ki pi resan, ou ka gade dat ki sou Sètifika Okipasyon an, li disponib apati Depatman Batiman yo.",
      zone: "rate-calculator-pre-firm"
  },
  {
      question: "Si mwen gen yon kontra asirans kont inondasyon Risk Favori kounya e zòn mwen an chanje pou AE oswa VE, èske mwen ka pran dwa pou pase nan tarif Kontra Risk Favori an?",
      answer: "Non, gen yon sibvansyon ki fenk katografye, men tarif lan gen chans pou l ogmante 15-18% chak ane jouk kontra rive nan montan pou risk total lan.",
      zone: "understanding-flood-insurance"
  },
  {
      question: "Si mwen nan yon zòn risk inondasyon ki fèb oswa modere kounya, men mwen pral nan yon zòn gwo risk nan kad nouvo kat yo, èske mwen ka toujou jwenn yon kontra Risk Favori?",
      answer: "Sa ap mete ajou nan yon dat ki pita.",
      zone: "understanding-flood-insurance"
  },
  {
      question: "Èske asirans kont inondasyon obligatwa pou pwogram lan?",
      answer: "Lalwa federal egzije ou gen asirans kont inondasyon si kay ou an nan yon zòn gwo risk inondasyon (AE, AO oswa VE) e ou gen yon ipotèk ki gen soutyen federal. (Prèske tout ipotèk yo jwenn soutyen federal). Epitou, si ou resevwa asistans federal pou katastwòf pou reparasyon nan batiman ou an oswa pou kontni ki pèdi, lalwa federal egzije ou gen asirans kont inondasyon. Si ou pa achte l, ou pa p kalifye pou asistans.",
      zone: "rtapp-preapp-flood-insurance"
  },
  {
      question: "Èske espas nan kay mwen an toujou konte antanke yon “sousòl” si li kout?",
      answer: "Si li pi kout pase anviwon 5 pye soti anba pou rive nan plafon e li pa plis pase 2 pye pi ba nivo sòl lan, eben li se yon “espas vid”. Si li kout men li plis pase 2 pye pi ba pase nivo sòl lan sou nenpòt ki bò, li toujou yon “sousòl.”",
      zone: "rate-calculator-basement"
  },
  {
      question: "Èske kouvèti refoulman dlo ize yo antre nan kontra asirans pou pwopriyetè kay mwen an?",
      answer: "Refoulman dlo ize jeneralman benefisye kouvèti kontra asirans estanda pou pwopriyetè kay yo. Asirans pou refoulman dlo ize se yon “aval” oswa “avnan” ki ka souvan ajoute nan asirans estanda pou pwopriyetè kay yo.",
      zone: "rtapp-preapp-sewage"
  },
  {
      question: "Èske gen yon fason pou di si klapè valv pou dlo egou k ap tounen an fèmen oswa louvri?",
      answer: "Ou ap konnen si valv ou an fèmen paske dlo an ap sispann koule nan kanalizasyon ou yo. Si ou sispèk valv lan ta ka fèmen, ou ka toujou verifye pou itilize pwen aksè ak valv plonbye an founi pandan enstalasyon an. Kèk valv gen alam ki konekte ak yo ki aktive lè valv lan fèmen, malgre yo ka koute chè.",
      zone: "mitigation-backwater_valve"
  },
  {
      question: "Èske gen yon fason pou di si klapè valv pou dlo egou k ap tounen an fèmen oswa louvri?\\n",
      answer: "Ou ap konnen si valv ou an fèmen paske dlo an ap sispann koule nan kanalizasyon ou yo. Si ou sispèk valv lan ta ka fèmen, ou ka toujou verifye pou itilize pwen aksè ak valv plonbye an founi pandan enstalasyon an. Kèk valv gen alam ki konekte ak yo ki aktive lè valv lan fèmen, malgre yo ka koute chè.",
      zone: "bwv-flood-applicants"
  },
  {
      question: "Èske gen yon bagay mwen ka fè pou akselere pwosesis enstalasyon an?",
      answer: "Malerezman, pa genyen. Monte ekip konstriksyon ou an enplike dosye entèn ak korespondans ki pouse ki dwe fèti pou asire konfòmite ak direktiv federal ak Eta yo. Nou ap kontakte ou yon fwa ekip ou an pare!",
      zone: "rtapp-status-InstallationPrepare"
  },
  {
      question: "Nan aplikasyon an, èske aplikan yo ta we mete “N/A” nan espas repons lan lè kesyon an pa aplike, oswa èske li ta ka rete vid?",
      answer: "N/A ideyal pou nou ka pran yon desizyon eklere apwopo si ou bezwen rele yon moun ou pa pou fè suivi nan nenpòt chan li gendwa ye an.",
      zone: "bwv-flood-applicants"
  },
  {
      question: "Èske ou gen tan nan wikenn/apremidi pou enstalasyon valv pou dlo k ap tounen?",
      answer: "Nou espere genyen omwen kèk randevou nan wikenn ak apremidi ki disponib, men yo ap limite.",
      zone: "bwv-flood-applicants"
  },
  {
      question: "Ki enfòmasyon anplis ki nesesè pou yon aplikasyon konplè?",
      answer: "Nou bezwen kopi ki lizib pou tout dokiman yo mande yo pou analize asirans pou pwopriyetè kay ou an, lisans, enfòmasyon sou revni anyèl brit aktyèl ou, prèv asirans kont inondasyon (si sa aplikab), ak enfòmasyon sou revni lokatè an (si sa aplikab). Nou pral mande enfòmasyon anplis si dokiman ki soumèt yo pa lizib oswa manke nan aplikasyon ou an.",
      zone: "rtapp-status-ApplicationSubmitted"
  },
  {
      question: "Kisa “vantilansyon kont inondasyon yo” ye?",
      answer: "Sa yo se ouvèti ki fèt espesyalman ki pèmèt dlo antre epi soti. Gen règ apwopo konbyen ki obligatwa e kibò yo dwe plase yo. Si ouvèti yo pa satisfè egzijans sa yo, yo pa p konte antanke vantilasyon kont inondasyon pou evalasyon asirans kont inondasyon. Li plis <a target=\"_blank\" href=\"/en/resiliency\">la</a>.",
      zone: "rate-calculator-building-feature"
  },
  {
      question: "Ki rezon komen yo pou yon moun gendwa teknikman pa kalifye pou pwogram lan?",
      answer: "Si kondisyon ki egziste yo pa pèmèt yon enstalasyon valv pou dlo k ap tounen (sa vle di pa ase espas oswa kondisyon branchman egou an twò pa bon pou plonbye an kapab koupe ladan l san l pa fè gwo reparasyon), si kay lan pa bezwen yon valv pou dlo k ap tounen (pa gen okenn endikasyon kay lan riske pou refoulman dlo ize ki baze sou elevasyon kay lan, koneksyon kay lan ak egou an, egou ki toupre, elevasyon debaz pou inondasyon, elatriye)",
      zone: "bwv-flood-applicants"
  },
  {
      question: "Kisa yo konsidere antanke “byen” nan sa ki gen pou wè ak asirans kont inondasyon?",
      answer: "Mèb, abiman, aparèy menaje ki pa ankastre, televizyon — tout bagay ou yo ki pa fè pati kay lan. Sonje sèl byen ou ka asire nan yon sousòl se aparèy pou lave, seche oswa konjle. Pa gen okenn lòt byen nan sousòl ou an ki ka benefisye kouvèti.",
      zone: "rate-calculator-content-location"
  },
  {
      question: "Kisa vantilansyon kont inondasyon yo ye?",
      answer: "Sa yo se ouvèti ki fèt espesyalman ki pèmèt dlo antre epi soti. Gen règ apwopo konbyen ki obligatwa e kibò yo dwe plase yo. Si ouvèti yo pa satisfè egzijans sa yo, yo pa p konte antanke vantilasyon kont inondasyon pou evalasyon asirans kont inondasyon. Li plis <a target=\"_blank\" href=\"/en/resiliency\">la</a>.",
      zone: "mitigation-fill_in_basement"
  },
  {
      question: "Ki kèk lòt posiblite valv pou dlo k ap tounen ki genyen?",
      answer: "Ou ta ka enstale valv pou dlo k ap tounen nan tout ekipman plonbri ki pi ba Elevasyon Debaz Inondasyon an pase ou enstale yon sou tiyo egou ki konekte lakay ou ak egou an, si yon plonbye lisansye epi kalifye jije sa apwopriye. Ou ta ka pwoteje sousòl ou an kont inondasyon epi itilize l sèlman pou pakin oswa estokaj. Dlo ize genwa toujou refoule, men byen ou yo t ap pwoteje. Depatman Pwoteksyon Anviwonnman Vil New York lan (NYCDEP) te prepare <a href=\"http://www.nyc.gov/html/dep/pdf/brochures/flood-preparedness-flyer.pdf\" target=\"_blank\">this</a> Depliyan pou Peraparasyon pou Inondasyon pou ede pwopriyetè kay yo redui chans pou kay yo an inonde pandan yon evennman lapli.",
      zone: "mitigation-backwater_valve"
  },
  {
      question: "Ki kèk lòt posiblite valv pou dlo egou k ap tounen ki genyen?",
      answer: "Ou ka pwoteje sousòl ou an kont inondasyon epi elve ekipman mekanik ou an pou sousòl lan sèvi sèlman pou pakin oswa estokaj. Dlo ize genwa toujou refoule, men byen ou yo ap pwoteje. Depatman Pwoteksyon Anviwonnman Vil New York lan (NYCDEP) te prepare Depliyan pou Peraparasyon pou Inondasyon pou ede pwopriyetè kay yo redui chans pou kay yo an inonde pandan yon evennman lapli.\\n\\nhttp://www.nyc.gov/html/dep/pdf/brochures/flood-preparedness-flyer.pdf\\n",
      zone: "bwv-flood-applicants"
  },
  {
      question: "Ak ki kèk kesyon komen mwen ta ka vle reflechi anvan oryantasyon an?",
      answer: "Tanpri pote tout kesyon ou yo sou asirans kont inondasyon ak tolerans pou tanpèt lan. Aktyèlman, kat evalyasyon asirans kont inondasyon nan Vil New York ap analize; pwochen kat inondasyon gouvènman federal lan pwopoze yo gendwa double kantite kay nan espas risk inondasyon an. Konsidere pou mande konseye ou an pou potansyèl pwochen risk inondasyon pwopriyete ou an epi fason sa ta ka afekte tarif asirans ou yo. Yo kapab diskite tou sou opsyon adousisman yo pou rann kay ou an pi toleran.",
      zone: "rtapp-status-AuditComplete rtapp-status-AuditComplete rtapp-status-CounselingScheduled rtapp-status-CounselingScheduled"
  },
  {
      question: "Ki kèk nan lòt kalite ekipman mekanik ki ka elve pou redui risk domaj yo nan ka yon inondasyon?",
      answer: "Founo, tèmoponp, pano elektrik oswa asansè.",
      zone: "rate-calculator-machinery-in-basement"
  },
  {
      question: "Kisa wòl ak responsablite Sant lan ye apre valv pou dlo k ap tounen an enstale?",
      answer: "Apre valv lan enstale, se responsablite pwopriyetè kay lan pou kenbe l. Sant lan ap founi yon Rapò sou Teren ki pral gen foto ak enfòmasyon anvan epi apre konstriksyon apati fabrikan valv lan konsènan fonksyonnman ak antretyen. Kontra gen anpil chans pou gen yon sèten garanti sou travay lan. Si gen yon sousi ak valv lan pandan peryòd garanti an, epi pwopriyetè kay lan ka rele Sant lan pou diskite sou sousi an epi potansyèlman fè plonbyen ki te enstale valv lan tounen nan kay lan pou korije sousi an.",
      zone: "bwv-flood-applicants"
  },
  {
      question: "Kisa tèm ak kondisyon prensipal Antant pou Sibvansyon Pwopriyetè Kay lan (HGA) yo ye?",
      answer: "Tanpri refere w ak dokiman sa a pou aprann tèm ak kondisyon prensipal HGA yo. Li se responsablite pwopriyetè kay lan (yo) pou analize dokiman an nan totalite l pou totalman konprann tout kondisyon li yo.",
      zone: "rtapp-status-GrantAgreement"
  },
  {
      question: "Kisa pwochen etap yo ye apre mwen ranpli pre-aplikasyon pwogram lan?",
      answer: "Apre nou analize enfòmasyon ou te soumèt yo pou prekalifye, nou ap detèmine si pou nou envite ou pou aplike ak pwogram lan. Nan ka sa a, ou ap resevwa yon imèl ki mande pou ou ranpli aplikasyon an sou entènèt. Nou ap mande ou tou pou soumèt dokiman jistifikatif ki konfime revni ou ak nenpòt asirans. Aplikasyon an ak dokiman jistifikatif yo ap sèvi pou konfime kalifikasyon ou.",
      zone: "rtapp-status-IntakeComplete"
  },
  {
      question: "Ki risk ki genyen nan enstale yon valv pou dlo k ap tounen?",
      answer: "Gen yon ti risk pou valv pou dlo k ap tounen an vin anpann. Valv pou dlo k ap tounen yo redui chans refoulman dlo ize yo lakay ou atravè tiyo egou ou an, men si valv lan pa antretni kòmsadwa, dlo ize yo ka kole epi fuite.",
      zone: "mitigation-backwater_valve"
  },
  {
      question: "Kisa mwen ka fè pou konfime tarif asirans kont inondasyon mwen an presi?",
      answer: "Yon sètifika elevasyon ka ede asire ou resevwa yon evalyasyon. Yon fwa ou gen yon sètifika elevasyon, travay ak ajan asiran ou an pou mete kontra ou an ajou. Si ou gen enkyetid konsènan fason kontra ou an te evalye apre itiilizasyon sètifika elevasyon an, kontakte sant Katografi ak Echanj FEMA (FMIX) nan 877-336-2627. Yo ap kapab diskite epi analize kontra ou an.",
      zone: "elevation-certificates"
  },
  {
      question: "Ak kisa mwen ka ranpli sousòl mwen an pou bese risk inondasyon an?",
      answer: "Ou ka itilize sab oswa wòch ki pwòp, ki konpakte.",
      zone: "mitigation-fill_in_basement"
  },
  {
      question: "Kisa ki reprezante yon enstalasyon valv pou dlo k ap tounen ki pa sekirize?",
      answer: "Yo ta ka detèmine enstalasyon an pa sekirize pou anpil rezon. Sistèm/espas lan ta ka nan yon kondisyon ki pa bon ase ki fè li t ap twò riske pou plonbye an oswa sistèm plonbri an, jere enstalasyon an. Desizyon sa a ka fèt nan nenpòt ki etap. Gen anpil chans pou plonbye an ta bezwen fè premye antay li nan planche an pou detèmine si enstalasyon an t ap “san danje”. Yo atann pou sa rive raman.",
      zone: "bwv-flood-applicants"
  },
  {
      question: "Kisa ki konte antanke yon asirans kont inondasyon pou pwogram lan?",
      answer: "Paj deklarasyon asirans kont inondasyon an gendwa atache la antanke prèv asirans.",
      zone: "rtapp-status-FullApplication"
  },
  {
      question: "Kisa ki konte antanke yon asirans pou pwopriyetè pou pwogram lan?",
      answer: "Paj deklarasyon asirans pou pwopriyetè ou an gendwa atache la antanke prèv asirans.",
      zone: "rtapp-status-FullApplication"
  },
  {
      question: "Kijan pou mwen aplike pou pwogram lan si mwen gen lokatè?",
      answer: "Pwopriyetè kay ki gen lokatè blije ranpli yon fòmilè pou Verifikasyon Revni Lokatè yo, yon fòmilè pou chak apatman. Pwopriyetè kay lan responsab pou soumèt fòmilè an ak dokiman jistifikatif bay Sant lan. Si Sant lan resevwa enfòmasyon ki endike apatman lokasyon yo an, yo ap voye on fòmilè pou Verifikasyon Lokatè ba yo apati imèl. Pou kesyon, tanpri voye imèl bay info@floodhelpny.org owa rele Sant lan nan 646-786-0888.",
      zone: "rtapp-status-FullApplication"
  },
  {
      question: "Kisa pou mwen fè lè Fòmilè Verifikasyon Revni Lokatè an ranpli?",
      answer: "Tanpri voye l nan kourye bay Sant lan nan espas 14 ou resevwa l bay:<br /><br />Center for NYC Neighborhoods, attn. FloodHelpNY<br />55 Broad Street<br />10th Floor<br />New York, NY 10004<br /><br />Ou ka eskane l tou epi voye fòmilè an nan imèl ak dokiman jistifikatif yo bay info@floodhelpny.org.",
      zone: "rtapp-status-FullApplication"
  },
  {
      question: "Kisa pou m fè ak fòmilè aplikasyon sa a lè l ranpli?",
      answer: "Tanpri voye l nan kourye bay Sant lan nan espas 14 ou resevwa l bay:\\n\\nCenter for NYC Neighborhoods, attn. FloodHelpNY\\n55 Broad Street\\n10th Floor\\nNew York, NY 10004\\n\\nOu ka eskane l tou epi voye fòmilè an nan imèl ak dokiman jistifikatif yo bay info@floodhelpny.org.",
      zone: "bwv-flood-applicants"
  },
  {
      question: "Kisa zòn inondasyon FEMA yo vle di?",
      answer: "Zòn inondasyon FEMA yo se zòn jewografik risk inondasyon karakterize. Pa egzanp, zòn ki gen gwo risk inondasyon yo gen 1% chans pou yo inonde chak ane.",
      zone: "understanding-flood-insurance profile"
  },
  {
      question: "Ki dokiman mwen ta dwe pote nan seyans oryantasyon mwen an?",
      answer: "Pou ede konseye ou an analize asirans kont inondasyon ou an epi opsyon adousisman ki disponib yo, nou mande ou pou pote paj deklarasyon asirans kont inondasyon ou an (si sa aplikab), yon ansyen atestasyon ipotèk oswa lòt dokiman ki montre sitiyasyon ipotèk aktyèl kay ou an.",
      zone: "rtapp-status-CounselingScheduled"
  },
  {
      question: "Kisa BFE vle di?",
      answer: "BFE vle di “Elevasyon Debaz Inondasyon.” Se sèl pwopriyete ki nan zòn ki gen gwo risk inondasyon ki gen yon BFE, ki montre wotè (an pye) ki pi wo pase nio lanmè an kote yo pwojte pou dlo inondasyon an monte nan yon tanpèt “100 lane” — yon tanpèt ki gen 1% chans pou l rive chak ane. Tarif lan pou chak $100 kouvèti baze sou nan ki nivo elevasyon kay ou an depase oswa anba BFE an. Tarif yo pou kay kote etaj ki pi ba nan oswa depase BFE an pa chè.",
      zone: "understanding-flood-insurance profile"
  },
  {
      question: "Kisa chak manm nan ekip Odit pou Tolerans Kay lan fè?",
      answer: "Plonbye ou an ap enstale valv pou dlo k ap tounen ou an, retounen espas travay lan nan kondisyon anvan konstriksyon yo, epi montre ou kijan pou antretni valv k ap dlo tounen ou an. Enspektè ou an ap fè yon enspeksyon final pou asire l tout travay fèt kòrèkteman e verifye yon lis kontwòl achèvman ak ou epi plonbye ou an.",
      zone: "rtapp-status-MeetYourTeam"
  },
  {
      question: "Kisa asirans kont Inondasyon an kouvri?",
      answer: "Gen de kalite kouvèti: batiman ak kontni. Ou gen opsyon pou chwazi montan ak franchiz kouvèti separe pou yo chak. <a href=\"/en/understanding-flood-insurance\">Aprann plis sou paj resous nou an.</a>",
      zone: "rtapp-preapp-flood-insurance"
  },
  {
      question: "Kisa rapò teknik mwen an gen ladan l?",
      answer: "Rapò teknik ou an gen aldan l foto anvan epi apre konstriksyon an; yon eskis ki montre anplasman valv ou an parapò ak eleman pèmanan nan kay ou an oswa sou pwopriyete ou an; fich done pwodui sou valv ou an apati fabrika an; enstriksyon pou fonksyonnman ak antretyen yo apati fabrikan valv ou an; kopi pèmi yo jwenn pou enstalasyon ou an, si sa aplikab.",
      zone: "rtapp-status-Complete"
  },
  {
      question: "Kisa sa vle di lè ou prekalifye pou pwogram lan?",
      answer: "Sa vle di ou satisfè egzijans minimòm yo pou pwogram lan, tankou gen yon kay ki nan youn nan katye ki kalifye yo. Toufwa, pou pi byen detèmine kalifikasyon ou, ou ap bezwen soumèt yon aplikasyon total.",
      zone: "rtapp-status-IntakeComplete"
  },
  {
      question: "Kisa etap analiz fezabilite nan pwogram lan enplike?",
      answer: "Ananliz fezabilite ou an ap sanble ak premye evalyasyon kay ou an, men yon an pi kout! Enjenyè an ak pèsonèl ki akonpanye l lan ap bezwen fè foto ak pran mezi kay ou an pou detèmine si li t ap tire pwofi nan yon valv pou dlo k ap tounen.",
      zone: "rtapp-status-FeasibilityReview"
  },
  {
      question: "Kisa yon valv pou dlo egou k ap tounen ye egzakteman?",
      answer: "Pifò kay nan Vil New York gen yon tiyo ki ranmase dlo ki sot nan evye ak twalèt yo an epi pote yo nan egou vil lan. Yon valv egou pou dlo k ap tounen se yon aparèy ki enstale nan tiyo sa a pou redui risk refoulman dlo ize atravè tiyo sa a epi anndan lakay ou. Li gen yon mekanis entèn ki rele klapè, ki fèmen pou ede pwotje kay ou an kont inondasyon vye dlo ize. Lè debòdman kalme l, chanjman nan presyon an degaje klapè an, sa ki pèmèt dlo ize an desann sot nan kay ou an epi al nan egou an. Pou aprann plis, gade paj resous nou an.",
      zone: "rtapp-status-Complete"
  },
  {
      question: "Kisa k rive nan yon seyans oryantasyon?",
      answer: "Konseye an ap founi yon esplikasyon pèsonalize sou risk pwopriyete ou an pou inondasyon nan analize done yo apati sètifika elevasyon an epi rapò teknik enjenyè yo te reyalize an. Nan fen seyans oryantasyon an, ou ap ale ak yon pi bon konpreyansyon sou kalifikasyon kay ou an pou valv pou dlo k ap tounen an, risk presi pou inondasyon, depans asirans ak opsyon pou redui pwochen domaj nan tanpèt yo.",
      zone: "rtapp-status-CounselingScheduled rtapp-status-AuditComplete"
  },
  {
      question: "Kisa k rive si mwen blije achte asirans kont inondasyon men mwen pa ka fè l?",
      answer: "Si ou gen yon ipotèk ki resevwa soutyen federal oswa yon prè SB, ajan ipotèk ou an oswa SBA ou an ap achte asirans kont inondasyon an epi ajoute depans lan sou peman chak mwa ou an. Si ou te resevwa asistans federal pou katastwòf epi ou pa achte asirans, ou pa p resevwa èd federal pou katastwòf pou reparasyon nan kay oswa rekonstriksyon oswa pou kontni ki andomaje pou pwochen tanpèt. (Ou t ap toujou kalifye pou lòt kalite èd, tankou èd tanporè pou lojman).",
      zone: "rtapp-preapp-flood-insurance understanding-flood-insurance"
  },
  {
      question: "Kisa k rive si mwen rate randevou odit mwen an pa aksidan?",
      answer: "Ou gendwa repwograme l toutotan ou soumèt demann ou an omwen twa jou anvan randevou ou an. Randevou yo ka repwograme sèlman yon fwa. Tanpri rele 646-786-0888 pandan orè fonksyonnman yo pou repwograme.",
      zone: "rtapp-status"
  },
  {
      question: "Kisa k rive si mwen rate randevou oryantasyon mwen an nan aksidan?",
      answer: "Ou gendwa repwograme l toutotan ou soumèt demann ou an omwen twa jou anvan randevou ou an. Randevou yo ka repwograme sèlman yon fwa. Tanpri rele 646-786-0888 pandan orè fonksyonnman yo pou repwograme.",
      zone: "rtapp-status"
  },
  {
      question: "Kisa k rive si tarif asirans kont inondasyon ki estime yo kalkile an diferan ak tarif aktyèl mwen an?",
      answer: "Si tarif ki estime yo kalkile an pi ba pase tarif aktyèl ou an, ou ta ka ap peye twòp pou asirans kont inondasyon. Kontakte koutye asirans ou an pou yon analiz kontra ou an. Verifye tout enfòmasyon yo pou asire w li kòrèk. Verifye pwopriyete an dekri kòrèkteman, li liste antanke rezidans primè ou an (si li se sa), zòn inondasyon an ak BFE an kòrèk.",
      zone: "rate-estimate"
  },
  {
      question: "Kisa k rive si gen yon lòt tanpèt e mwen pa gen asirans kont inondasyon?",
      answer: "Si ou blije gen asirans kont inondasyon e ou pa achte l, ebe ou pa p kalifye pou soulajman federal pou kont katastwòf pou domaj kont batiman ou an oswa kontni ou yo nan ka yon pwochen tanpèt. Ou gendwa toujou kalifye pou èd pou lòt objektif, tankou lojman tanporè.",
      zone: "profile"
  },
  {
      question: "E si mwen pa kapab verifye enfòmasyon mwen yo pou aplikasyon?",
      answer: "Si ou pa ka founi egzakteman sa yo ap mande an, kontakte ekip soutyen nou an. Ou ka rele nou nan lè fonksyonnman nòmal yo nan 646-786-0888.",
      zone: "rtapp-self-certify"
  },
  {
      question: "E si mwen pa ka peye asirans kont inondasyon?",
      answer: "Gade si ou ka rann li abòdab nan redui kantite kouvèti an oswa ogmante franchiz ou an. Eksplore si gen chanjman ki abòdab ou ka fè nan kay ou an ki ta ka redui depans lan, tankou ranpli yon espas vid. Kontakte ofisyèl yo vote yo pou wè si gen pwogram pou ede peye pou chanjman yo nan kay ou an ki t ap redui depans oswa si gen gwoup volontè k ap fè sa.",
      zone: "programs-and-benefits resiliency profile"
  },
  {
      question: "E si mwen pa ka pèmèt tèt mwen pou prim asirans kont inondasyon mwen an?",
      answer: "Petèt ou ka redui kouvèti ou an oswa jwenn yon franchiz ki pi wo. Yon kèlkonk kouvèti miyò pase okenn kouvèti. Si ou gen yon ipotèk nan yon zòn gwo risk, opsyon ou yo gendwa limite. Eseye eksplore chanjman ou ka fè yo nan kay ou an pou redui prim lan, tankou ranpli yon espas vid oswa sousòl epi enstale vantilasyon kont inondasyon.",
      zone: "programs-and-benefits resiliency"
  },
  {
      question: "E si mwen pa resevwa yon imèl nan men FloodHelpNY?",
      answer: "Tanpri verifye dosye spam ou an epi ajoute info@floodhelpny.org nan kontak ou yo. Ou ka rele tou Liy Èd nou an nan 646-786-0888 ak tout kesyon.",
      zone: "rtapp-status"
  },
  {
      question: "E si mwen gen anpil peman diferan pou Sandy? Èske mwen ka toujou kalifye pou yon Polis Risk Favori?",
      answer: "Wi, toutotan okenn nan peman yo pa t depase $1,000.",
      zone: "understanding-flood-insurance"
  },
  {
      question: "E si mwen gen lokatè ki ap viv nan premye etaj lakay mwen an men li vle abandone premye espas lan antanke yon opsyon adousisman?",
      answer: "Espas lan pa p disponib pou abite ankò?",
      zone: "mitigation-abandon_first_floor"
  },
  {
      question: "E si mwen ap viv nan yon kay ki atache oswa ki semi-atache e mwen enterese nan elevasyon kay lan?",
      answer: "Li teknikman pa posib oswa rantab pou elve yon kay ki atache.",
      zone: "mitigation-elevate"
  },
  {
      question: "E si mwen rate randevou pou evalye kay mwen an? Èske mwen ka repwograme?",
      answer: "Si ou bezwen repwograme randevou ou an, tanpri kontakte nou tousuit nan (646) 786-0888.\\nTanpri remake nou ka pwograme randevou ou an sèlman si ou ban nou omwen yon avi twa jou davans, e nou ka sèlman repwograme revizit ou an yon fwa.",
      zone: "rtapp-status-FeasibilityReview"
  },
  {
      question: "E si mwen bezwen chanje randevou pou Odit pou Tolerans Kay mwen an ki pwograme an?",
      answer: "Ou gendwa repwograme l toutotan ou soumèt demann ou an omwen twa jou anvan randevou ou an. Randevou yo ka repwograme sèlman yon fwa. Tanpri rele 646-786-0888 pandan orè fonksyonnman yo pou repwograme.",
      zone: "rtapp-status"
  },
  {
      question: "E si mwen bezwen chanje randevou oryantasyon mwen ki pwograme an?",
      answer: "Ou gendwa repwograme l toutotan ou soumèt demann ou an omwen twa jou anvan randevou ou an. Randevou yo ka repwograme sèlman yon fwa. Tanpri rele 646-786-0888 pandan orè fonksyonnman yo pou repwograme.",
      zone: "rtapp-status"
  },
  {
      question: "E si mwen posede oswa ap viv nan yon batiman pou plizyè fanmi men m toujou vle yon Odit pou Tolerans Kay?",
      answer: "Malerezman, ou pa kalifye pou yon Odit pou Tolerans Kay. Men ou ka toujou verifye risk pou inondasyon <a href=\"https://www.floodhelpny.org\">ou an.</a>",
      zone: "rtapp-preapp-number-of-units rtapp-landing profile"
  },
  {
      question: "E si mwen gen kesyon sou rapò tolerans mwen an?",
      answer: "Konseye ou an ap reponn kesyon ou yo apwopo rapò ou an oswa ede ou jwenn repons.",
      zone: "rtapp-status"
  },
  {
      question: "E si mwen se yon lokatè epi vle aplike nan pwogram lan?",
      answer: "Pwogram sa a disponib sèlman pou pwopriyetè batiman. Ou gendwa pataje pwogram sa a ak pwopriyetè ou an epi mande yo aplike.",
      zone: "rtapp-preapp-homeowner"
  },
  {
      question: "E si mwen ap konsidere achte yon kay men mwen vle aplike nan pwogram lan?",
      answer: "Pwogram sa a ouvri sèlman ak pwopriyetè aktyèl yo.",
      zone: "rtapp-preapp-homeowner"
  },
  {
      question: "E si kalite batiman mwen an pa liste nan fòmilè pre-aplikasyon an?",
      answer: "Chwazi batiman ou panse ki plis koresponn ak pa ou lan.",
      zone: "rate-calculator-building-type"
  },
  {
      question: "Ki enfòmasyon ki sou sètifika elevasyon an?",
      answer: "Twa chan done kle nan sètifika elevasyon an se kalite ak karakteristik batiman an, etaj ki pi ba a ak anplasman pwopriyete an Faktè sa yo jwe yo tout wòl enpòtan nan detèmine prim asirans kont inondasyon ou an.",
      zone: "elevation-certificates"
  },
  {
      question: "Kisa “dwa mwen pran” ye nan asirans kont inondasyon?",
      answer: "Lè nouvo kat inondasyon yo adopte e kay ou an plase nan yon zòn ki gen pi gwo risk inondasyon oswa BFE an pou pwopriyete an ogmante, ou ka ekonomize lajan nan règleman dwa mwen pran an. Si ou kalifye, dwa ou pran pèmèt ou verouye zòn inondasyon anvan an oswa BFE an nan objektif tarifikasyon pou asirans. Gen de kalite dwa ou pran: “kouvèti pèmanan” epi “konfòmite nan konstriksyon.” Sèlman kay bati apre Novanm 1983 yo kalifye pou “konfòmite nan konstriksyon.”",
      zone: "understanding-flood-insurance profile"
  },
  {
      question: "Kisa “pèt grav ki repete” ye?",
      answer: "Si ou menm oswa pwopriyetè anvan te resevwa omwen de peman reklamasyon asirans kont inondasyon e total konbine peman sa yo plis pase valè batiman an sou mache an, ou gen yon batiman ki gen yon “pèt grav ki repete”.",
      zone: "rate-calculator-severe-repetitive-loss"
  },
  {
      question: "Kisa yon “espas vid ki nan sousò”l ye?",
      answer: "Yon espas ki 1) pi ba pase sòl lan nan tout 4 bò yo, epi 2) ki pa pi fon pase 2 pye pi ba sòllan nenpòt kote, epi 3) pa pi wo pase 4 pye soti anba pou rive nan plafon an. Si li pi wo pase 4 pye oswa pi fon pase 2 pye anba tè an, li se yon “sousòl” nan objektif asirans kont inondasyon.",
      zone: "rate-calculator-building-feature"
  },
  {
      question: "Kisa yon kontra asirans kont inondasyon pou Risk Favori ye?",
      answer: "Risk Favori se yon kontra sibvansyone ki disponib sèlman ak zòn ki gen risk modere pou rive fèb pou inondasyon (Zòn X oswa ki pa nan yon zòn inondasyon) ki gen antesedan reklamasyon ki limite. Pri mwayen an se anviwon $500 chak ane.",
      zone: "understanding-flood-insurance profile"
  },
  {
      question: "Kisa yon valv pou dlo k ap tounen ye?",
      answer: "Pifò kay nan Vil New York gen yon tiyo ki ranmase dlo ki sot nan evye ak twalèt yo nan kay ou an epi pote yo nan egou vil lan. Yon valv pou dlo k ap tounen se yon aparèy ki enstale nan tiyo sa a pou redui risk refoulman dlo ize atravè tiyo sa a e anndan lakay ou, espesyalman pandan gwo lapli. <a href=\"https://www.floodhelpny.org/en/mitigation/backwater_valve\">Aprann plis sou valv pou dlo k ap tounen yo.</a>",
      zone: "rtapp-preapp-backwater-exist rtapp-preapp-sewage"
  },
  {
      question: "Kisa yon valv pou dlo k ap tounen ye?",
      answer: "Pifò kay nan Vil New York gen yon tiyo ki ranmase dlo ki sot nan evye ak twalèt yo nan kay ou an epi pote yo nan egou vil lan. Yon valv egou pou dlo k ap tounen se yon aparèy ki enstale nan tiyo sa a pou redui risk refoulman dlo ize atravè tiyo sa a epi anndan lakay ou, espesyalman pandan yon evennman gwo lapli.\\n",
      zone: "bwv-flood-applicants"
  },
  {
      question: "Kisa yo konsidere kòm “domaj” nan pwogram la?",
      answer: "Nou ap mande si kay ou an te andomaje nan van, lapli oswa inondasyon an antanke yon rezilta dirèk Ouragan Sandy. Anjeneral, si Depatman Batiman NYC an te make lakay ou ak yon pankat jonn oswa wouj, oswa si ou te resevwa asistans FEMA, te reklame fon asirans oswa te resevwa èd karitatif ak reparasyon akoz Sandy, nou panse kay ou an te andomaje.",
      zone: "rtapp-preapp-sandy-damage"
  },
  {
      question: "Kisa yo konsidere kòm “domaj nan inondasyon” nan pwogram la?",
      answer: "Domaj debòdman lakoz oswa yon mas dlo tankou oseyan oswa yon lak, rivyè oswa yon toran. Inondason apati yon tiyo ki pete pa konte. Yon refoulman egou nòmalman pa domaj inondasyon, amwenske se inondasyon ki lakoz li.",
      zone: "rate-calculator-severe-repetitive-loss rate-calculator-preferred-risk-rate"
  },
  {
      question: "Kisa yo konsidere kòm revni anyèl nan pwogram la?",
      answer: "<ul><li>- Montan anvan dediksyon pewòl nan apwentman, salè, peman tan siplemantè, komisyon, frè, poubwa, bonis, ak peman Lame</li><li>- Revni nèt apati operasyon biznis oswa pwofesyon</li><li>- Enterè, dividand ak lòt revni nèt tout kalite apati byen imobilye ak mobilye</li><li>- Montan peryodik ou te resevwa apati Sekirite Sosyal, rant, kontra asirans, fon retrèt, pansyon, alokasyon pou andikap, alokasyon pou lanmò, pansyon alimantè ak soutyen pou timoun</li><li>- Peman lwaye ou resevwa nan men lokatè</li><li>- Peman an tèm rantre (sa vle di konpansasyon pou andikap/chomaj, konpansasyon pou travayè, endamnite revokasyon)</li></ul>",
      zone: "rtapp-status-FullApplication"
  },
  {
      question: "Kisa asirans pou “kontni” an kouvri?",
      answer: "Asirans pou kontni yo kouvri domaj kont pwopriyete pèsonèl inondasyon lakoz. Sèl pwopriyete ki kouvri nan sousòl ou an se aparèy pou lave, seche epi konjle ou an. (Founo, chodyè, aparèy pou chofe dlo ak èkondisyone ou an benefisye asirans ouvèti pou “batiman” ou an). Lè yon inondasyon ap vini, deplase zafè ou yo nan sousòl ou an. Li se yon bon lide tou pou fè foto tout bagay anvan yon inondasyon vini, pou ka founi prèv bay konpayi asirans lan.",
      zone: "understanding-flood-insurance profile"
  },
  {
      question: "Kisa franbò an ye?",
      answer: "Franbò an se elevasyon anplis pi wo pase Elevasyon Inondasyon Debaz lan, epi li asire konstriksyon an nan yon elevasyon ki pi ansekirite kont pwochen inondasyon. Nan NYC, sa vle di ajoute de pye anplis pi wo BFE an. Anplis ogmante sekirite kont inondasyon, franbò ka pote prim asirans ki pi ba ki ka ede nan amòti depans konstriksyon yo.",
      zone: "mitigation-flood_vents"
  },
  {
      question: "Kisa refoulman plonbri ye?\\n",
      answer: "Tout dlo ize yo (dlo pou egou) ki desann nan kanalizasyon ak twalèt ou yo soti lakay ou pou ale nan egou vil lan nan tiyo. Refoulman plonbri rive lè dlo ize pa kapab bouje nan tiyo an (yo) epi retounen lakay ou. Sa ka rive pou yon kantite rezon.\\n",
      zone: "bwv-flood-applicants"
  },
  {
      question: "Kisa debòdman egou ye?",
      answer: "Egou yo fèt pou yo kapab kenbe epi transmèt yo sèten kantite dlo ize ak dlo egou. Pafwa, pandan gwo lapli ki pa abityèl oswa gwo tanpèt ki fèt, egou yo ka vin ranpli depase kapasite yo. Lè sa rive, dlo ize ka retounen lakay ou atravè tiyo ki konekte kay ou an ak egou vil lan",
      zone: "mitigation-backwater_valve"
  },
  {
      question: "Kisa ki enpòtan apwopo 1983 nan sa ki gen pou wè ak asirans kont inondasyon?",
      answer: "Vil New York te adopte premye Kat Tarif Asirans kon Inondasyon FEMA li an nan dat 16 novnam 1983, epi li te ajoute egzijans elevasyon nan kòd konstrisyon pou pwopriyete ki konstwi nan yon zòn inondasyon yo. Piske pa t gen okenn egzijans pou pwoteksyon kont inondasyon nan kòd konstriksyon an anvan sa, pwopriyete yo ki konstwi anvan sa kalifye pou yon tarif ki pi ba. Men Kongrè an te vin òdone FEMA pi devan pou eliminte tarif ki pi ba sa a, ki rele tarif “sibvansyone anvan FIRM” lan.",
      zone: "rate-calculator-pre-firm"
  },
  {
      question: "Kisa depans mwayen an ye pou yon sètifika elevasyon?",
      answer: "Depans lan ka plase l soti nan $500 pou rive nan plis pase $1,000. Eseye jwenn pwofòma pri apati omwen de apantè pou konparezon.",
      zone: "elevation-certificates"
  },
  {
      question: "Kisa depans mwayen an pou enstale pou kont mwen yon valv pou dlo k ap tounen ye?",
      answer: "Li depann ak yon kantite faktè, ansanm ak prezantasyon tiyo egou ou yo, pwofondè ak anplasman tiyo ki konekte kay ou ak egou an, si gen risk anviwonnmantal (pa egzanp penti ki gen plon) nan zòn kote valv lan pral enstale an, ki kalite valv ki enstale, elatriye. Depans lan ka piti jiska $600 oswa plis pase $5,000.",
      zone: "rtapp-status-Withdrawn rtapp-status-Ineligible"
  },
  {
      question: "Kisa vil lan ap fè pou ede pwoblèm jeneral dlo ize an?",
      answer: "Sa se yon pi gwo kesyon nou pa ka vreman pale piske nou pa p travay pou Vil lan. NYCDEP gen plizyè pwojè ki ap dewoule pou eseye abòde sousi debòdman egou ki konbine. Moun ki enterese ka vizite sit entènèt DEP an pou jwenn plis enfòmasyon pwogram sa yo. Yon inisyativ se Pwogram Enfrastrikti Vèt lan, ki enplike konstriksyon rigòl biyo-enjeniri arebò wout yo ki pral kapte premye pous dlo lapli yo epi kenbe yo andeyò egou yo.",
      zone: "bwv-flood-applicants"
  },
  {
      question: "Kisa diferans lan ye ant yon sousòl ak yon espas vid nan yon kay? Poukisa li enpòtan?",
      answer: "Nan objektf asirans kont inondasyon, yon sousòl pi ba pase nivo sòl lan nan tout kat bò yo e li pi wo pase 5 pye. Si espas lan mwens pase 5 pye wotè, se yon espas vid. Li enpòtan paske tarif yo gendwa diferan.",
      zone: "mitigation-fill_in_basement"
  },
  {
      question: "Kisa Lwa sou Aksesiblite Asirans kont Inondasyon Pwopriyetè Kay (HFIAA) lan?",
      answer: "Lwa 2014 lan sou Aksesiblite Asirans kont Inondasyon Pwopriyetè Kay la te ralanti oswa te ranvèse kèk nan chanjman yo nan Pwogram Nasyonal Asirans kont Inondasyon an Lwa 2012 Biggert-Waters lan te fè. HFIAA te restore “dwa ou pran” epi te ralanti eliminasyon lòt sibvansyon pou prim yo nan bloke ogmantasyon prim yo a 18% nan yon ane pou pifò pwopriyete. Kèk pwopriyete, tankou pwopriyete komèsyal oswa pwopriyete ki andomaje nan yon fason grav, ka toujou wè yon ogmantasyon 25% chak ane.",
      zone: "understanding-flood-insurance"
  },
  {
      question: "Kisa Kontra pou Sibvansyon Pwopriyetè Kay lan (HGA) lan ye?",
      answer: "Kontra pou Sibvansyon Pwopriyetè Kay lan pèmèt Sant lan, antanke administratè pwogram lan, bay yon pòsyon nan fon federal pou retablisman apre katastwòf yo ak pwopriyetè kay ki kalifye yo epi egzije pou pwopriyetè yo dakò ak tout tèm epi kondisyon ki detaye yo nan dokiman an.",
      zone: "rtapp-status-GrantAgreement"
  },
  {
      question: "Kisa objektif yon nsètifika elevasyon ye?",
      answer: "Yon sètifika elevasyon se yon dokiman asirans kont inondasyon enpòtan ki founi enfòmasyon apwopo risk inondasyon ak tolerans inondasyon yon pwopriyete. Sètifika elevasyon an ka sèvi pou kalkile prim asirans kont inondasyon ou an.",
      zone: "elevation-certificates"
  },
  {
      question: "isa pwogram sa a ye e ak kisa l gen pou wè?",
      answer: "Si ou ap resevwa fòmilè sa a, pwopriyetè ou an te enskri nan pwogram Odit pou Tolerans Kay FloodHelpNY lan, ki vize ranfòse kominote ki bay sou kot New York yo nan founi sèvis gratis bay pwopriyetè kay ki kalifye yo ak lokatè yo nan sèten katye ki bay sou kot yo. Pou aprann plis, tanpri vizite FloodHelpNY.org. Antanke pwogram sa a, pwopriyetè ou an gendwa kalifye pou resevwa yon valv pou dlo k ap tounen nan egou gratis. Yon valv pou dlo k ap tounen nan egou se yon aparèy ki enstale nan tiyo ki konekte enfrastrikti plonbri yon kay ak egou vil lan pou redui risk refoulman dlo ize nan kay lan pandan yon evennman inondasyon tankou gwo lapli oswa aparisyon tanpèt.",
      zone: "bwv-flood-applicants"
  },
  {
      question: "Ki ekipman esansyèl lakay mwen ki gendwa riske?",
      answer: "Ekipman ki suiv yo la gendwa riske: pano ak fil elektrik yo, sistèm mekanik tankou HVAC ak kondui yo, gwoup kondansasyon yo, sistèm chofaj yo, aparèy pou chofe dlo yo ak ekipman lesiv yo.",
      zone: "mitigation-elevate_mechanicals"
  },
  {
      question: "Ki lòt resous tolerans ak inondasyon disponib nan zòn mwen an?",
      answer: "Si ou ta renmen jwenn yon valv pou dlo k ap tounen pou kont ou, ou ka konsilte gid sa a pou asistans.",
      zone: "rtapp-status-Withdrawn"
  },
  {
      question: "Kisa ki kalifye antanke yon sousòl nan pwogram lan?",
      answer: "Nenpòt pati nan kay ou ki pi ba sòl lan nan tout kat bò yo (menm kèk pous pi ba pase sòl lan) epi li pi wo pase anviwon 5 pye soti atè an pou rive nan plafon an. Si atè an nan nivo sòl lan oswa pi wo pase nivo sòl lan sou yon bò, li pa yon sousòl.",
      zone: "rate-calculator-basement"
  },
  {
      question: "Kisa ki kalifye antanke yon rezidans primè nan pwogram lan?",
      answer: "Yon rezidans prensipal se kay yon rezidan abityèlman pase pifò tan l. Yon kay lokatè ki okipe tout ane an se yon rezidans prensipal tou.",
      zone: "rtapp-preapp-primary-residence"
  },
  {
      question: "Kisa ki kalifye antanke yon batiman rezidansyèl nan pwogram lan?",
      answer: "Yon batiman rezidansyèl se yon espas ki rezève grandman pou lojman, non pa pou objektif komèsyal.",
      zone: "rate-calculator-number-of-residential-units rtapp-preapp-number-of-units"
  },
  {
      question: "Kisa mwen ta dwe soumèt antanke yon prèv revni pou aplikasyon mwen an?",
      answer: "Atahce prèv revni pou tout manm fwaye yo ki gen plis pase 18 lane (ideyalman sa se yon Fòmilè IRS 1040 ki siyen). Si l pa disponib, nou ap konsidere twa dènye mwa souch chèk suivi yo oswa atestasyon alokasyon resan yo; nou gendwa apwouve tou lòt dokiman.",
      zone: "rtapp-status-FullApplication"
  },
  {
      question: "Kibò mwen ka rapòte yon pwoblèm dlo ize?",
      answer: "Rele 311",
      zone: "bwv-flood-applicants"
  },
  {
      question: "Pou konbyen tan anjeneral dlo an ap koupe?",
      answer: "Sa pral depann a kay lan. Nou ap fè pwopriyetè kay lan konnen pi bonè nou kapab. Pwopriyetè kay yo pa p kapab lage dlo nan kanalizasyon yo oswa flòch twalèt yo pandan plonbye an ap koute tiyo egou yo an epi enstale nouvo valv lan. Li pa p nesesèman pandan tout tan enstalasyon an piske enstalasyon an gen posiblite pou gen ladan l lòt travay tankou rapyese atè an. Li ta ka piti tankou 8 èdtan.",
      zone: "bwv-flood-applicants"
  },
  {
      question: "Kisa yon Antant Tripati ye e èske gen yon vèsyon egzanp mwen ka analize alavans rankont lan?",
      answer: "Yon vèsyon egzanp Antant Tripati an ap mansyone nan imèl konfimasyon pou dat ak lè rankont lansman ou an, e ou ka telechaje l la.",
      zone: "rtapp-status-MeetYourTeam"
  },
  {
      question: "Kisa yon “ansent” nan yon kay ye?",
      answer: "Si ou gen yon kay elve, sa se yon zòn anba espas pou viv ou an mi bare (an pati oswa totalman). Li ta ka yon garaj oswa yon espas ou itilize pou estokaj sèlman, pa yon espas pou viv.",
      zone: "rate-calculator-content-location rate-calculator-building-feature"
  },
  {
      question: "Kisa yon sètifika elevasyon ye?",
      answer: "Yon sètifika elevasyon se yon dokiman pou asirans kont inondasyon ki enpòtan ki dekri elevasyon anwo nivo lanmè chak etaj nan kay oswa batiman ou an. Li ka ede kalkile si tarif asirans kont inondasyon ou an kòèk epi li ede ou evalye tou opsyon tolerans ou yo.",
      zone: "rtapp-landing rtapp-preapp-elevation-certificate rtapp-multifamily-landing"
  },
  {
      question: "Kisa FEMA ye?",
      answer: "Federal Emergency Management Agency (Ajans Jesyon Ijans Federal lan). FEMA ap jere Pwogram Nasyonal Asirans kont Inondasyon (NFIP) an e li founi tou èd ijans apre yon katastwòf.",
      zone: "understanding-flood-insurance"
  },
  {
      question: "Kilè nouvo kat inondasyon yo ap pran efè?",
      answer: "Yo aktyèlman ap deside sou yon nouvo kat plan , men pa gen okenn repons definitif sou kilè l ap soti oswa aplike. FEMA te soumèt yon kat preliminè ki ajou apati baz referans 1983 yo an nan lane 2013, ki te elaji nan yon fason enpòtan zòn gwo risk inondasyon yo nan NYC, men li nan revizyon apre vil New York lan te goumen pou mete l sou pye jan l te ye. Kat sa a aji kounya antanke yon kat konsiltatif pou zonaj ak nouvo egzijans konstriksyon.",
      zone: "bwv-flood-applicants"
  },
  {
      question: "Kilè pwopriyetè kay lan ap konnen dire tan ki nesesè pou enstale valv pou dlo k ap tounen an/pa itilize dlo?",
      answer: "Yo ap konnen anvan yo siyen Antant Tripati an, ki se antant plonbye an Sant lan ak pwopriyetè kay lan siyen anvan enstalasyon valv lan.",
      zone: "bwv-flood-applicants"
  },
  {
      question: "Kilè m ap gen nouvèl sou sitiyasyon aplikasyon mwen yo?",
      answer: "Ou ta dwe resevwa yon imèl konfimasyon yon fwa ou soumèt fòmilè akèy ou an, ou ap gen nouvèl apresa konsènan pwochen etap yo nan espas yon semèn apre soumisyon aplikasyon ou an.",
      zone: "bwv-flood-applicants"
  },
  {
      question: "Kilè m ap gen nouvèl apati pwogram lan konsènan aplikasyon mwen an?",
      answer: "Apre aplikasyon ou an soumèt, li gendwa pran jiska twa semèn pou ou tande yo. Ou ka toujou verifye sitiyasyon ou an atravè lyen ki nan imèl ou an.",
      zone: "rtapp-preapp-review"
  },
  {
      question: "Kisa k ap rive lè aplikasyon mwen an apwouve?",
      answer: "Yon fwa ou apwouve pou resevwa sèvis nou ap kontakte ou nan espas de jou ouvrab pou pwograme Odit pou Tolerans Kay ou an nan yon lè ki bon pou ou. Sitiyasyon ou ap toujou ajou sou paj sa a oswa ou ka rele nou nan lè fonksyonnman nòmal yo nan 646-786-0888 ak kesyon konsènan aplikasyon ou an.",
      zone: "rtapp-status"
  },
  {
      question: "Kibò mwen ka jwenn yon kontraktè pou elve ekipman mekanik mwen yo?",
      answer: "Depatman Batiman <a target=\"_blank\"href=\"http://www1.nyc.gov/site/buildings/homeowner/homeowner.page\">an ka ba ou konsèy</a> sou fason pou jwenn konseptè ak kontraktè ki gen eksperyans nan zòn inondasyon yo.",
      zone: "mitigation-elevate_mechanicals"
  },
  {
      question: "Kibò mwen ka jwenn yon kontratè pou pwoteje kay mwen an kont inondasyon?",
      answer: "Ou ta dwe konsilte yon enjenyè oswa achitè ki byen kalifye anvan ou enstale vantilasyon. Depatman Batiman NYC yo ka ba ou konsèy nan chwa yon konseptè ak kontraktè ki gen <a target=\"_blank\" href=\"http://www1.nyc.gov/site/buildings/homeowner/homeowner.page\">eksperyans ak pwopriyetè kay yo</a>. DOB an resevwa tou seyans enfòmasyon chak semèn ak pwopriyetè kay yo kote abitan New York yo ka aprann sou nòm konstriksyon yo ak fason pou evalye kontraktè yo.",
      zone: "mitigation-flood_vents"
  },
  {
      question: "Kibò mwen ka deouvri si katye mwen an te rapòte egzanp refoulman dlo ize?",
      answer: "311 retrase plent refoulman dlo ize yo.",
      zone: "bwv-flood-applicants"
  },
  {
      question: "Kibò mwen ka founi kòmantè sou pwogram lan?",
      answer: "Tanpri verifye imèl ou apre konsiltasyon pou yon lyen pou founi kòmantè.",
      zone: "rtapp-status"
  },
  {
      question: "Kibò seyans oryantasyon sou tolerans lan ap fèt?",
      answer: "Konsiltasyon an ap fèt nan yon biwo nan katye ou an. Tanpri verifye imèl ou an pou detay oswa rele Liy Asistans pou Pwopriyetè Kay nou an nan 646-786-0888.",
      zone: "rtapp-status"
  },
  {
      question: "Ki opsyon adousisman ki ap anfèt bese tarif asirans mwen an?",
      answer: "Pou kounya, gen sèlman kat mezi ki gen yon efè sou prim asirans yo,: elevasyon kay lan, ranplisaj yon sousòl oswa espas vid epi enstalasyon vantilasyon kont inondasyon, abandon premye etaj ou an epi elevasyon ekipman mekanik yo nan sousòl lan. Dènye opsyon an bay pi piti rediksyon asirans nan twa yo, men li ka ekonomize ou yon pakèt lajan nan ka yon inondasyon.",
      zone: "programs-and-benefits resiliency profile"
  },
  {
      question: "Ki kesyon mwen ta dwe poze lè m ap chèche yon plonbye pou enstale valv pou dlo k ap tounen sa a?",
      answer: "Mande yo lisans yo, eksperyans yo NYC oswa nan katye ou an, nenpòt sètifikasyon ak referans apati pwojè ki parèy. Eseye jwenn pwofòma nan men twa plonbye pou konparezon.",
      zone: "mitigation-backwater_valve"
  },
  {
      question: "Kiyès oditè tolerans kay yo ye? Èske yo sibi kontwòl?",
      answer: "Oditè yo se pwofesyonèl grafik ki kalifye ki gen soutyen enjenyè ki lisansye yo chwazi atravè yon pwosesis rekritman ki konpetitif.",
      zone: "rtapp-status"
  },
  {
      question: "Kiyès konseye pwogram yo ye?",
      answer: "Konseye yo se pwofesyonèl jiridik oswa lojman apati òganizasyon kominotè ki pa pou pwofi ki gen eksperyans nan desèvi pwopriyetè kay nan kominote New York ki bò kot yo. Konseye yo resevwa fòmasyon nan sijè tolerans ak asirans kont inondasyon.",
      zone: "rtapp-landing rtapp-status"
  },
  {
      question: "Kiyès konseye pwogram yo ye?",
      answer: "Konseye pou lojman ki sètifye ki gen plis pase senk lane eksperyans ap travay ak kominote ki sou kot yo.",
      zone: "rtapp-status-CounselingScheduled rtapp-status-AuditComplete"
  },
  {
      question: "Kiyès ki ka enstale yon valv pou dlo k ap tounen?",
      answer: "Se sèl yon plonbye ki ta dwe enstale yon valv pou dlo k ap tounen ki fè lòt enstalasyon valv pou dlo k ap tounen nan katye ou an. Ou ka mande referans!",
      zone: "mitigation-backwater_valve"
  },
  {
      question: "Kiyès ki konte pou yon “Manm Fwaye” an nan yon aplikasyon pou pwogram?",
      answer: "LI GEN LADAN L: moun ki lye/pa lye k ap viv nan kay lan; tout moun ki absan pou yon ti tan nan lekòl oswa travay, tout moun ki nan prizon pou yon peryòd tan ki kout men k ap tounen nan kay lan; epi manm militè aktif ki ap tounen nan kay lan. Timoun ki sou gad pataje ta dwe sou lis lan si yo pase omwen 50% nan tan yo ak fwaye an. LI GENDWA GEN LADAN L: Aplikan an ta dwe detèmine ki manm fwaye absan nan yon fason pèmanan. Egzanp yo kapab yon manm aje ki al viv nan yon fwaye retrèt oswa yo etidyan adilt k ap viv lwen kay lan. LI PA GEN LADAN L: timoun fwaye akèy, gadyen legal ki pwòch, adilt fwaye akèy, èd ki abite avè ou yo, pitit èd ki abite avè ou yo",
      zone: "rtapp-status-FullApplication"
  },
  {
      question: "Kiyès ki se yon “Ko-Aplikan” nan yon aplikasyon pou pwogram?",
      answer: "Tout moun ki sou lis nan papye pou pwopriyete an e ki ap viv nan pwopriyete an DWE sou lis antanke yon Ko-Aplikan. Chak Ko-Aplikan blije siyen kote yo mande an nan Aplikasyon Konplè sa a, Antant pou Sibvansyon Pwopriyetè an, Antant Tripati an, e nenpòt lòt antant aplikan an dwe siyen anvan yo resevwa sèvis nan kad pwogram lan.",
      zone: "rtapp-status-FullApplication"
  },
  {
      question: "Kiyès yo konsidere antanke “Aplikan” nan yon aplikasyon pou pwogram?",
      answer: "Aplikan an dwe sou lis nan papye an, ta dwe gen plis pase 18 lane, e li ta dwe nonmen antanke chèf fwaye an (HOH) nan objektif pou detèmine kalifiasyon pou revni an. HOH lan dwe kapab siyen antant yo nan non fwaye an.",
      zone: "rtapp-status-FullApplication"
  },
  {
      question: "Kiyès ki ap founi sèvis pwogram yo?",
      answer: "Center for NYC Neighboords ki pa pou pwofi an ap travay ak plizyè patnè pou ofri sèvis sa yo. Aprann plis sou Sant lan ak patnè li yo <a target=\"_blank\" href=\"/en/about\">la</a>.",
      zone: "rtapp-landing"
  },
  {
      question: "Kiyès ki ap founi sèvis pwogram yo pou batiman ki gen plizyè fanmi?",
      answer: "Center for NYC Neighboords ki pa pou pwofi an ap travay ak plizyè patnè pou ofri sèvis sa yo. Enterprise Community Partners, Inc. ap sipòte pwopriyetè Plizyè fanmi yo. Aprann plis nan Sant lan <a target=\"_blank\" href=\"http://cnycn.org/\">here</a> and Enterprise Community Partners <a target=\"_blank\" href=\"http://www.enterprisecommunity.com\">la</a>.",
      zone: "rtapp-multifamily-landing"
  },
  {
      question: "Kiyès ki responsab pou fè antretyen valv pou dlo k ap tounen an?",
      answer: "Ou ap responsab pou antretyen valv pou dlo egou k ap tounen ou an apre fèmti l. Enstriksyon yo nan rapò teknik ou an.",
      zone: "rtapp-status-Complete"
  },
  {
      question: "Kiyès ki finanse pwogram sa a?",
      answer: "Pwogram sa a jwenn finansman Biwo Gouvènè New York lan pou Retablisman apre Tanpèt nan kad Pwogram Rekonstriksyon Kominotè NY Rising lan.",
      zone: "rtapp-multifamily-landing. rtapp-landing"
  },
  {
      question: "Kiyès k ap dirije FloodHelpNY?",
      answer: "FloodHelpNY posib gras ak finansman Biwo Gouvènè New York lan pou Retablisman apre Tanpèt atravè yon Sibvansyon Federal Global pou Devlopman Kominotè pou Retablisman apre Katastwòf apati Depatman Ameriken pou Lojman ak Devlopman Iben an. Se Center for New York City Neighborhoods ki administre pwogram lan, yon òganizasyon ki pa pou pwofi ki angaje l pou fè pwomosyon epi pwoteksyon pwopriyete abòdab nan New York pou fanmi ki nan klas mwayen ak klas ouvriyè yo kapab bati kominote ki djanm, ki ap pwospere. Aprann plis sou Sant lan nan cnycn.org",
      zone: "bwv-flood-applicants"
  },
  {
      question: "Kiyès ki siyen Kontra pou Sibvansyon Pwopriyetè Kay lan?",
      answer: "Tout moun ki sou lis papye an dwe siyen Kontra pou Sibvansyon Pwopriyetè Kay lan, menm si yo pa sou lis lan nan aplikasyon an antanke aplikan oswa ko-aplikan an.",
      zone: "rtapp-status-GrantAgreement"
  },
  {
      question: "Kiyès mwen ta dwe kontakte pou poze kesyon apwopo rapò tolerans mwen an jiskaprezan?",
      answer: "Mete ou alèz pou rele nan (646) 786-0888 oswa imèl nou nan info@floodhelp.org ak nenpòt kesyon.",
      zone: "rtapp-status-Complete"
  },
  {
      question: "Kiyès mwen ta dwe kontakte si mwen gen kesyon apwopo pwogram lan?",
      answer: "Mete ou alèz pou rele Platfòm pou Pwopriyetè Kay Sant lan nan (646) 786-0888 nenpòt lè. Demann sou randevou pou enstalasyon yo ap dirije bay enspektè an si rankont lansman yo an deja fèt.",
      zone: "rtapp-status-InstallationHasBeenScheduled. rtapp-status-InstallationToBeScheduled"
  },
  {
      question: "Poukisa mwen blije gen asirans kont inondasyon? Kiyès ki egzije l?",
      answer: "Lalwa federal egzije ou gen asirans kont inondasyon si kay ou an nan yon zòn gwo risk inondasyon (AE, AO oswa VE) e ou gen yon ipotèk ki gen soutyen federal. (Prèske tout ipotèk yo jwenn soutyen federal). Epitou, si ou resevwa asistans federal pou katastwòf pou reparasyon nan batiman ou an oswa pou kontni ki pèdi, lalwa federal egzije ou gen asirans kont inondasyon. Si ou pa achte l, ou pa p kalifye pou asistans.",
      zone: "understanding-flood-insurance"
  },
  {
      question: "Poukisa prim asirans kont inondasyon yo ap ogmante?",
      answer: "Sibvansyon yo ap fini e nouvo Kat Tarif Asirans kont Inondasyon FEMA a (FIRM) pral ogmante kantite kay ki nan zòn gwo risk inondasyon yo.",
      zone: "understanding-flood-insurance"
  },
  {
      question: "Poukisa moun enkyete apwopo monte frè asirans kont inondasyon?",
      answer: "Monte frè asirans kont inondasyon yo gendwa lakoz difikilte finansye pou fanmi ouvriye ak klas mwayen yo k ap viv nan zòn gwo risk inondasyon, ki ta ka mete yo nan yon pi gwo risk pou sezi oswa deplasman. Pou aprann plis, li rapò konplè “Rising Tides, Rising Costs” lan Center for NYC Neighborhoods fè an <a target=\"_blank\" href=\"https://cnycn.atavist.com/risingtides\">la</a>.",
      zone: "programs-and-benefits"
  },
  {
      question: "Poukisa kat zòn inondasyon yo ap chanje?",
      answer: "FEMA mete ajou peryodikman kat li kreye yo pou zòn risk pou inondasyon yo nan tout peyi an. Kat aktyèl yo pou Vil New York te adopte nan lane 1983 e yo pa montre risk inondasyon pou jounen jodi an ak presizyon.",
      zone: "understanding-flood-insurance. profile"
  },
  {
      question: "Pouksa ou ap mande m enfòmasyon sou revni mwen?",
      answer: "Nou mande enfòmasyon sou revni nan objektif pou priyorize sèvis. Fwaye yo nan tout nivo revni envite pou aplike pou yon evalyasyon vilnerabilite kay yo an ak inondasyon. Toutfwa, nou ap otomatikman desèvi fwaye ki gen revni fèb pou rive modere (jiska 80% Revni Medyan Zòn lan pou zòn Vil New York lan). Aplikasyon ki tonbe andeyò tranch sa a ap priyorize e analize nan fen chak peryòd twa semèn.",
      zone: "rtapp-preapp-income"
  },
  {
      question: "Poukisa nou ap mande m si kay mwen an te andomaje anvan?",
      answer: "Nou mande ou pou nou ka priyorize sèvis. Kay ki gen domaj oparavan yo ap resevwa sèvis priyorite. Aplikasyon ki pa gen domaj oparavan yo ap toujou envite pou aplike e yo ap jwenn konsiderasyon.",
      zone: "rtapp-preapp-sandy-damage"
  },
  {
      question: "Pouksa nou ap mande m enfòmasyon sou rezidans prensipal mwen?",
      answer: "Pou ou kalifye pou pwogram lan, pwopriyete an dwe parèt li te rezidans prensipal ou pandan Ouragan Sandy an.",
      zone: "rtapp-preapp-sandy-ownership"
  },
  {
      question: "Poukisa mwen jwenn tarif asirans kont inondasyon ki diferan nan men diferan ajan asirans?",
      answer: "Pwogram Nasyonal Asirans kont Inondasyon an etabli tarif asirans kont inondasyon e koutye asirans yo pa ka chanje yo. Sèl rezon pou ou ta jwenn diferan pwofòma apati diferan ajan se paske youn oswa toude te fè yon erè nan fason yo te dekri pwopriyete oswa zòn ou an.",
      zone: "programs-and-benefits"
  },
  {
      question: "Poukisa mwen pa kalifye pou pwogram lan?",
      answer: "Gen anpil rezon pouksa sa ka rive: Kay ou an pa p benefisye yon valv pou dlo egou k ap tounen; ou pa gen asirans kont inondasyon e ou nan yon zòn inondasyon AE; ou pa gen asirans. pou pwopriyetè kay; ou pa t posede kay ou an pandan Ouragan Sandy; ak lòt faktè Ou gen kesyon? Rele nou nan (646) 786-0898 oswa voye imèl ban nou nan info@floodhelpny.org.",
      zone: "rtapp-status-Ineligible"
  },
  {
      question: "Poukisa mwen blije founi plis enfòmasyon pou aplike ak pwogram lan?",
      answer: "Nou gendwa mande ou plis enfòmasyon si nou pa ka di baze sou pre-aplikasyon ou an si pwogram sa a disponib pou ou. Nou mande ou tou pa aze sèten kalite enfòmasyon pou nou ka kenbe pwosesis lan jis epi senp pou tout moun.",
      zone: "rtapp-self-certify"
  },
  {
      question: "Poukisa mwen blije soumèt enfòmasyon sou revni si sa se yon pwogram gratis?",
      answer: "Direktiv finansman nou yo egzije pou nou konfime revni fwaye an; enfòmasyon sa a sèvi pou detèmine kalifikasyon ou. Nan moman sa a, se sèlman fwaye ki gen revni fèb pou rive modere k ap kalifye (pi ba pase 80% AMI).",
      zone: "bwv-flood-applicants"
  },
  {
      question: "Poukisa mwen bezwen ranpli yon fòmilè ki gen enfòmasyon sou revni pou aplike pou pwogram lan?",
      answer: "FloodHelpNY ap fòse desèvi pwopriyetè kay ki gen revni modere epi fèb ak lokatè yo. Nou blije jwenn enfòmasyon sou revni fwaye an ansanm ak enfòmasyon sou revni lokatè yo nan batiman ki gen 2-4 apatman yo, pou nou ka asire nou ap desèvi sèlman fwaye ki gen revni ki pi fèb yo. Sa obligatwa dapre Depatman Ameriken pou Lojman ak Devlopman Iben ak Biwo Gouvènè Eta New York lan pou Retablisman apre Tanpèt (GOSR), ki finanse pwogram lan.",
      zone: "bwv-flood-applicants"
  },
  {
      question: "Poukisa ou bezwen nimewo telefòn mwen ak adrès imèl mwen pou aplikasyon nan pwogram lan?",
      answer: "Nou bezwen nimewo telefòn ak adrès imèl ou pou kontakte ou apwopo mizajou nan aplikasyon ou an.",
      zone: "rtapp-preapp-contact-info"
  },
  {
      question: "Poukisa nou bezwen konnen si mwen gen yon valv lè mwen aplike nan pwogram lan?",
      answer: "Nou ap eseye detèmine si ou kalifye pou enstalasyon yon valv pou dlo egou k ap tounen lakay ou.",
      zone: "bwv-flood-applicants bwv-flood-applicants rtapp-preapp-backwater-exist. rtapp-preapp-backwater-exist"
  },
  {
      question: "Poukisa nou bezwen konnen si mwen gen refoulman plonbri anvan Odit pou Tolerans Kay mwen an?",
      answer: "Li bay enjenyè nou an yon sèten lide sou sitiyasyon ou anvan yo vizite kay ou an pou Odit FloodHelpNY pou Tolerans Kay ou an.",
      zone: "bwv-flood-applicants"
  },
  {
      question: "Poukisa nou bezwen enfòme sou konpansasyon pou valv pou dlo k ap tounen an?",
      answer: "Si pwopriyetè kay yo deja resevwa asistans pou repare pwopriyete yo apati yon ansyen pwogram, Pwogram lan dwe analize avantaj sa yo pou konfime pa gen okenn repetisyon avantaj anvan yo founi sèvis Pwogram yo.",
      zone: "rtapp-status-FullApplication"
  },
  {
      question: "Poukisa nou bezwen enfòme sou konpansasyon pou Sipètanpèt Sandy an?",
      answer: "Si pwopriyetè kay yo deja resevwa asistans pou repare pwopriyete yo apati yon ansyen pwogram, Pwogram lan dwe analize avantaj sa yo pou konfime pa gen okenn repetisyon avantaj anvan yo founi sèvis Pwogram yo.",
      zone: "rtapp-status-FullApplication"
  },
  {
      question: "Poukisa li enpòtan pou pwogram lan si pwopriyete m lan konekte ak egou Vil lan?",
      answer: "Pwogram lan vize redui risk refoulman dlo ize nan kay yo pandan inondasyon debòdman egou ki konbine lakoz nan yon fason espesifik. Debòdman egou ki konbine rive lè egou vil lan vin plen depase kapasite l. Pifò pwopriyete konekte ak egou vil lan, men gen kèk pwopriyete ki ta ka toujou fos septik. Si ou pa konekste ak egou vil lan men ou ap viv refoulman dlo ize lakay ou, ebe koz lan se pa debòdman egou ki konbine, e ou ta dwe konsilte yon pwofesyonèl konsepsyon ak/oswa kontraktè pou detèmine koz ak solisyon sousi an.",
      zone: "rtapp-preapp-sewer"
  },
  {
      question: "Poukisa rezidans prensipal lan enpòtan pou kalifikasyon pou resevwa yon valv pou dlo k ap tounen?",
      answer: "Pwogram sa a fèt pou desèvi kay ki founi lojman bay rezidan New York yo pandan tout ane an kontrèman ak kay vakans yo.",
      zone: "rtapp-preapp-primary-residence"
  },
  {
      question: "Poukisa enjenyè an bezwen gen aksè ak tout pwopriyete m lan?",
      answer: "Enjenyè an bezwen pran mezi toutotou enstalasyon plonbri ou yo, foto espas kote valv lan ta dwe enstale an, epi obsève espas ozalantou kay ou an pou detèmine si enstalasyon yon valv posib. Sa pral ede plonbye an prepare pou enstalasyon an nan founi yon imaj klè espas konstriksyon an.",
      zone: "rtapp-status-FeasibilityReview"
  },
  {
      question: "Poukisa pwogram valv pou dlo k ap tounen an mande dokiman prèv?",
      answer: "Nan lide pou konfòme nou ak egzijans finansman federal yo, nou konfime tout revni, asirans kont inondasyon tout aplikan epi enfòmasyon sou lokatè.",
      zone: "rtapp-status-IntakeComplete"
  },
  {
      question: "Poukisa asirans pou pwopriyetè kay yo kouvri inondasyon?",
      answer: "Rezon Kongrè an te kreye NFIP an nan 1968 se te paske konpayi asirans prive yo pa t ap kouvri domaj nan inondasyon. Pèt yo te twò chè.",
      zone: "resiliency"
  },
  {
      question: "Poukisa enstalasyon yon valv pou dlo k ap tounen pa chanje tarif asirans mwen an?",
      answer: "NFIP pa ekri aktyèlman pou pèmèt rediksyon prim pou adousisman inondasyon sèch, e valv pou dlo k ap tounen yo nan kategori sa a. Pa egzanp, elve kay ou an ba ou yon diskont paske ou ap elve etaj ki pi ba ou an pi wo pase BFE an. Yon valv pou dlo k ap tounen redui men li pa retire risk inondasyon an.",
      zone: "mitigation-backwater_valve"
  },
  {
      question: "Poukisa enstalasyon yon valv pou dlo egou k ap tounen pa chanje tarif asirans mwen an?",
      answer: "Pwogram Nasyonal Asirans kont Inondasyon an aktyèlman pou pèmèt rediksyon prim pou estrateji adousisman inondasyon sèch, tankou valv pou dlo k ap tounen yo. Nan lòt sans, solisyon kont inondasyon, tankou elevasyon kay ou an, akoz yon diskont pou asirans kont inondasyon paske etaj ki pi ba ou an elve pi wo pase Elevasyon pou Inondasyon Debaz (BFE) an. Yon valv pou dlo egou k ap tounen genwa redui risk inondasyon, men li pa retire risk lan konplètman.",
      zone: "bwv-flood-applicants"
  },
  {
      question: "Poukisa bese risk inondayon mwen an toujou bese tarif asirans kont inondasyon mwen an?",
      answer: "Sèten mezi adousisman gendwa redui domaj yo ak depans netwayaj yo apre yon inondasyon, yo gendwa anfèt pa chanje done ki sèvi pou kalkile risk pou inondasyon lakay ou an.",
      zone: "understanding-flood-insurance"
  },
  {
      question: "Poukisa yon kopi Antant pou Sibvansyon Pwopriyetè (HGA) an te ale jwenn Biwo Gouvènè an pou Retablisman apre Tanpèt lan?",
      answer: "Piske finansman pwogram lan livre atravè Pwogram Rekonstriksyon Kominotè New York Rising (NYRCR) lan, nan Biwo Gouvènè Eta New York lan pou Retablisman apre Tanpèt (GOSR), Biwo Gouvènè an pou Retablisman apre Tanpèt lan egzije yon kopi antant lan.",
      zone: "rtapp-status-GrantAgreement"
  },
  {
      question: "Poukisa aplikasyon valv pou dlo k ap tounen an long konsa?",
      answer: "Piske se yon pwogtram ki jwenn finansman federal, nou blije rasanble dokiman apati tout pwopriyetè kay ki aplike yo. Pa enkyete ou, enfòmasyon ou yo ap estoke nan yon fason sekirize. Gade politik sou vi prive nou yo.",
      zone: "rtapp-status-FullApplication"
  },
  {
      question: "Poukisa Sant lan ap siyen Antant Tripati an?",
      answer: "Antant Tripati an gen siyati Pwopriyetè Kay lan, Sant lan ak plonbye an. Li etabli obligasyon tout twa pati yo youn ak lòt epi mete plonbye an ak pwopriyetè kay lan nan yon antant dirèk. Ou ap kapab wè yon kopi siyen Antant Tripati an nan paj estati ou an yon fwa tout pati yo siyen l.",
      zone: "rtapp-status-MeetYourTeam"
  },
  {
      question: "Poukisa tip asirans yo pètinan?",
      answer: "Pwogram lan blije analize ansyen alokasyon pou reparasyon yo pou konfime pa gen okenn repetisyon avantaj.",
      zone: "rtapp-status-FullApplication"
  },
  {
      question: "Poukisa li pa klè lè nouvo kat inondasyon yo antre anvigè?",
      answer: "Vil New York te konteste Kat Tarif Asirans kont Inondasyon Preliminè FEMA nan mwa jen 2015, li te di yo te estime nan yon fason depase risk pou inondasyon an pou senk awondisman yo. Nan mwa oktòb 2016, FEMA te anonse li te akspete kontestasyon Vil lan pou kat preliminè inondsayon FEMA yo e l ap kòmanse yon nouvo pwosesis pou revize kat inondasyon vil lan. Li ap pran omwen twa a kat lane pou FEMA pibliye nouvo kat revize. Aprann plis <a href=\"http://www1.nyc.gov/site/floodmaps/index.page\" target=\"_blank\">la</a>.",
      zone: "understanding-flood-insurance profile"
  },
  {
      question: "Poukisa mwen ta dwe enkyete apwopo tolerans?",
      answer: "Lè yon katastwòf rive, yon repons pèsonèl lan ki mal jere ka mete sekirite ak byenèt rezidan lojman yo nan risk epi ekpoze pwopriyetè lojman yo ak frè ki pa nesesè, difikilte ak potansyèl responsablite. Òganizasyon pou lojman abòdab yo konfwonte ak defi san parèy pandan evennman ijans yo. Ak enkapasite pou relokalize rezidan yo fasilman, òganizasyon lojman yo depann ak fonksyonnman san kanpe batiman yo, oswa omwen yon repriz rapid sèvis yo. Pou aprann plis ale <a target=\"_blank\" href=\"https://s3.amazonaws.com/KSPProd/ERC_Upload/0100738.pdf\">la</a>.",
      zone: "rtapp-multifamily-landing"
  },
  {
      question: "Poukisa yo te chwazi katye sayo pou pwogram valv pou dlo k ap tounen an?",
      answer: "Kominote ki te chwazi pou patisipe epi dedye fon ak Odit pou Tolerans Kay lan kalifye nan kad pwogram sa a; tout katye yo te fè pati Pwogram Rekonstriksyon Kominotè NY Rising lan.",
      zone: "rtapp-multifamily-landing rtapp-landing"
  },
  {
      question: "Poukisa yon chanjman nan BFE an t ap chanje asirans kont inondasyon mwen an?",
      answer: "Pifò kontra asirans kont inondasyon yo tarife nan konparezon elevasyon kay lan nan Elevasyon pou Inondasyon Debaz lan nan zòn ki gen gwo risk inondasyon. Plis yo konpare elevasyon yon kay ak BFE an, plis tarif asirans kont inondasyon an ap chè. Elevasyon an mezire parapò ak nivo lanmè an, non pa nivo sòl lan.",
      zone: "understanding-flood-insurance"
  },
  {
      question: "Èske yon valv pou dlo k ap tounen bese asirans kont inondasyon mwen?",
      answer: "Non. Toutfwa, li ta ka yon faktè pou konsidere lè ou ap gade kantite kouvèti kontni pou pote nan kontra asirans kont inondasyon ou an. Valv pou dlo k ap tounen an gendwa gen yon enpak sou prim asirans pwopriyetè kay ou an.",
      zone: "rtapp-status-FullApplication"
  },
  {
      question: "Èske pran yon valv pou dlo k ap tounen ap redui prim asirans kont inondasyon mwen yo?",
      answer: "Valv pou dlo egou k ap tounen yo pa gen okenn efè sou prim asirans kont inondasyon yo men yo ka ekonomize ou plizyè santèn dola oswa menm plizyè milye dola kòm domaj nan ka yon inondasyon. Li ka pwoteje eleman ak souvni ki presye ki estoke nan sousòl ou an kont domaj epi kenbe sousòl ou an san dlo ize.",
      zone: "bwv-flood-applicants"
  },
  {
      question: "Èske pwopriyetè yo nan x aktyèl lan pou al nan konsiltasyon x oswa aktyèl x pou al nan AE konsiltatif yo blije pran yon asirans kont inondasyon?",
      answer: "Se sèl pwopriyetè kay yo nan AE aktyèl lan blije pran asirans kont inondasyon nan lide pou l kalifye.\\n",
      zone: "bwv-flood-applicants"
  },
  {
      question: "Èske mwen ap bezwen fivilge enfòmasyon pèsonèl pandan seyans oryantasyon an?",
      answer: "Konseye ou an gendwa mande ou pou finans ou ak lòt enfòmasyon pèsonèl pou ou ka jwenn maksimòm nan evalyasyon kay ou an. Nou pa p pataje enfòmasyon pèsonèl ou yo san pèmisyon ou.",
      zone: "rtapp-status"
  },
  {
      question: "Èske mwen ap bezwen founi plis enfòmasyon pou enstalasyon valv pou dlo k ap tounen?",
      answer: "Nou ap bezwen dokiman jistifikatif pou pwouve revni anyèl tout moun nan fwaye ou ki gen plis pase 18 lane. Gade fòmilè ki sou pwochen paj lan pou kalite dokiman nou gendwa aksepte yo.\\n",
      zone: "bwv-flood-applicants"
  },
  {
      question: "Èske valv pou dlo k ap tounen ap redui asirans pwopriyetè kay mwen yo?",
      answer: "Sa depan. Kèk konpayi asirans ofri diskont pou asirans pwopriyetè kay. Toutfwa, kouvèt presi pou refoulman dlo ize souvan bezwen pou yo ajoute yo nan kontra asirans kay ou an nan fòm yon avnan pou refoulman dlo ize. Yon avnan konsa koute jeneralman ant $50 ak $70 chak ane e li ka rive wo jiska $175, toudepan franchiz lan e konbyen kouvèti ou bezwen. Si asirans pwopriyetè kay ou an pa ofri yon diskont, ou toujou ap pran yon etap enpòtan pou anpeche pwochen domaj refoulman egou e reparasyon ki koute chè ki ta ka vin ak li.",
      zone: "rtapp-status-Complete rtapp-status-Complete rtapp-status-InstallationPrepare rtapp-status-InstallationPrepare"
  },
  {
      question: "Èske kondisyon yo ap tounen nan fason yo te ye anvan enstalasyon valv pou dlo k ap tounen an (gazon/etaj yo)?",
      answer: "Nan nivo sa posib, plonbye an ap tounen espas travay la nan eta li te ye anvan konstriksyon an. Ta ka blije gen kèk chanjman (pa egzanp, dwe genyen yon fason pou jwenn aksè ak valv la pou antretyen, donk ta ka gen yon pò aksè ak valv lan. Si yo ap enstale l deyò, eben ranplase nenpòt nan pati nan yon gazon ki te blije detwi t ap fè pati kontra a.",
      zone: "bwv-flood-applicants"
  },
  {
      question: "Èske enjenyè an ap founi kòmantè pandan odit lan?",
      answer: "Ekip jeni ou an ap bezwen tan pou analize enfòmasyon ki rasanble lakay ou an pou ranpli rekòmandasyon pèsonalize ou yo. Akoz sa, ou pa p resevwa kòmantè menm kote an. Men pa enkyete! Konseye ou an ka reponn nenpòt kesyon ou genyen apwopo rapò ou an pandan seyans ou an.\\n",
      zone: "rtapp-status-AuditScheduled"
  },
  {
      question: "Èske enjenyè, plonbye oswa enspektè yo ap janm kontakte pwopriyetè kay lan dirèkteman apwopo valv pou dlo k ap tounen an? E si sa fèt, kilè?",
      answer: "Enspektè an ap kontakte ou pou pwograme enstalasyon valv pou dlo k ap tounen an. Si yon aplikan blije repwograme enstalasyon li an, li ta dwe kontakte enspektè li an.",
      zone: "bwv-flood-applicants"
  },
  {
      question: "Èske rezilta analiz fezailite an ap afekte aplikasyon mwen an pou valv pou dlo k ap tounen an?",
      answer: "Li ta kapab fè sa. Pandan analiz fezabilite an, enjenyè ou an pral evalye si wi ou non kay ou an t ap benefisye yon valv pou dlo k ap tounen si kondisyon lakay ou pèmèt pou valv lan enstale. Si yo detèmine kay ou an pa t ap pèmèt yo enstale yon valv, ou pa p kalifye pou resevwa yon enstalasyon pou dlo k ap tounen.",
      zone: "rtapp-status-FeasibilityReview"
  },
  {
      question: "Èske dlo an ap etenn lakay mwen pandan enstalasyon valv pou dlo k ap tounen an? Si se vre, pou konbyen tan?",
      answer: "Wi, dlo kay lan ap bezwen etenn pandan enstalasyon valv pou dlo k ap tounen an. Dire an depann ak kay lan. Nou ap fè pwopriyetè kay lan konnen pi bonè nou kapab. Pwopriyetè kay yo pa p kapab lage dlo nan kanalizasyon yo oswa flòch twalèt yo pandan plonbye an ap koute tiyo egou yo an epi enstale nouvo valv lan. Li pa p nesesèman pandan tout tan enstalasyon an piske enstalasyon an gen posiblite pou gen ladan l lòt travay tankou rapyese atè an. Li ta ka pran uitèdtan pou pi piti.",
      zone: "rtapp-status-InstallationHasBeenScheduled"
  },
  {
      question: "Ou di yon espas vid se “anviwon” 4 pye wotè. Èske ou ka sèten?",
      answer: "Definisyon egzak yon espas vid se yon okote ki pa gen plis pase 5 pye wotè soti atè an nan espas vid lan pou rive (kote kapèt lan ye an). Mezi 5 pye an gen ladan l epesè etaj ki anwo an. Si nou estime yon etaj gen anviwon yon pye epesè, sa kite yon wotè soti atè pou rive nan plafon an ki anviwon 4 pye oswa mwens pou li kalifye.",
      zone: "rate-calculator-building-feature"
  },
  {
      question: "Èske mwen ka jwenn plis kouvèti pase NFIP an ofri?",
      answer: "Wi, konpayi asirans prive yo ofri kouvèti asirans depase ki pi wo pase <a href=\"https://www.fema.gov/flood-insurance\" target=\"_blank\" rel=\"noopener noreferrer\">NFIP</a> limit yo. Rele ajan oswa koutye asirans ou an pou diskite ki opsyon ki disponib pou ede pwoteje biznis ou kont depans finansye domaj nan inondasyon.",
      zone: "small-business"
  },
  {
      question: "Èske biznis mwen an bezwen asirans kont inondasyon?",
      answer: "Domaj apati inondasyon ka rive nenpòt kilè menm si biznis ou an pa lokalize nan yon zòn ki gen gwo risk inondasyon. Kit ou lwe oswa posede espas biznis ou an, nou rekòmande pou ou achte yon kontra asirans kont inondasyon atravè <a href=\"https://www.fema.gov/flood-insurance\" target=\"_blank\" rel=\"noopener noreferrer\">NFIP an</a>.",
      zone: "small-business"
  },
  {
      question: "Konbyen tan li pran pou jwenn yon kouvèti pou inondasyon?",
      answer: "Gen yon peryòd atant 30 jou anvan kontra asirans kont inondasyon ou an pran efè. Li enpòtan pou achte yon kontra asirans kont inondasyon anvan yon evennman inondasyon afekte biznis ou an.",
      zone: "small-business"
  },
  {
      question: "Konbyen kouvèti asirans kont inondasyon mwen ka jwenn?",
      answer: "Yon kontra asirans komèsyal kont inondasyon atravè <a href=\"https://www.fema.gov/flood-insurance\" target=\"_blank\" rel=\"noopener noreferrer\">NFIP</a> founi jiska $500,000 nan konstriksyon kouvèti epi jiska $500,000 pou kouvèti kontni.",
      zone: "small-business"
  },
  {
      question: "Kisa mwen ta dwe konnen sou franchiz?",
      answer: "Chwazi bon franchiz lan enpòtan. Yon pi gwo franchiz pral bese frè asirans ou an, men li pral bese kantite lajan ou resevwa si ou te janm fè yon reklamasyon.",
      zone: "small-business"
  },
  {
      question: "Kibò mwen ka achte asirans kont inondasyon?",
      answer: "Ou ka achte asrans kont inondasyon lè ou rele ajan oswa koutye asirans ou an. Yo ap kapab ede ou achte yon kontra asirans kont inondasyon dirèkteman atravè <a href=\"https://www.fema.gov/flood-insurance\" target=\"_blank\" rel=\"noopener noreferrer\">NFIP an</a>.",
      zone: "small-business"
  },
  {
      question: "Poukisa mwen ta dwe achte yon asirans kont inondasyon lè FEMA ofri asistans pou katastwòf?",
      answer: "FEMA pa ofri sibvansyon endividyèl bay biznis yo. San yon asirans kont inondasyon, ou t ap bezwen peye tout domaj apati pwòp pòch ou oswa fòse pou pran yon prè.",
      zone: "small-business"
  },
  {
      question: "Èske kontra asirans komèsyal mwen an ap kouvri inondasyon?",
      answer: "Inondasyon jeneralman pa garanti nan kad yon kontra asirans komèsyal oswa yon kontra pou pwopriyetè biznis. Ou t ap bezwen achte yon kontra asirans kont inondasyon separe pou pwoteje biznis ou an kont frè finansye domaj inondasyon.",
      zone: "small-business"
  },
  {
      question: "Kisa asirans kont Inondasyon an kouvri?",
      answer: "Asirans kont inondasyon an ede pwoteje biznis ou kont depans finansye domaj inondasyon. Asirans kont inondasyon ka kouvri depans domaj kont batiman ak kontni ou yo inondasyon lakoz.",
      zone: "small-business"
  },
  {
      question: "Konbyen yo ap ranbouse m si kay mwen an andomaje?",
      answer: "Si ou posede yon kay pou yon sèl fanmi, li enpòtan pou ou konnen si ou asire kay ou an pou mwens pase 80% valè pri ranplasman li an, ebe ou ap sèlman resevwa sa ki rele “valè kach reyèl” (ACV) domaj inondasyon an lè ou fè yon reklamasyon. Sa vle di olye pou ou kapab resevwa asirans pou depans reparasyon kay ou an, ou ap sèlman resevwa ranbosuman pou diminisyon valè nenpòt pati estriktirèl – pout, klwazon sèch, planche, elatriye – ki te andomaje.",
      zone: "understanding-flood-insurance"
  },
  {
      question: "Kisa nouvo metòd tarifikasyon an ye nan kad Evalyasyon Risk 2.0 an ye?",
      answer: "FEMA te devlope yon sistèm ki ka difisil pou konprann. Men kisa nou konnen: Apati done ki soti nan FEMA yo ak done tyès pati ki disponib sou plan komèsyal yo, FEMA ap evalye risk inondasyon pou chak pwopriyete. Yo ap fè sa nan gade bagay tankou distans ak yon sous inondasyon, potansyèl tip inondasyon, kantite moun ki nan batiman, kalite konstriksyon an, kalite fondasyon an, elevasyon sòl lan, wotè premye etaj lan, kantite etaj yo ak reklamasyon pou asirans kont inondasyon oparavan. Lòt konsiderasyon enpòtan yo gen ladan yo depans pou rekonstriksyon an.",
      zone: "insurance-shopping"
  },
  {
      question: "Èske Evalyasyon Risk 2.0 an konsidere reklamasyon oparavan yo?",
      answer: "Wi, yon antesedan reklamasyon pou pwopriyete ap gen enpak sou prim asirans kont inondasyon yo. Men li enpòtan pou konnen FEMA pa p konsidere antesedan reklamasyon oparavan nan kalkil tarif inisyal lan. Reklamasyon oparavan ap konsidere sèlman, lè (e si) yon pwopriyete soumèt yon nouvo reklamasyon apre Evalyasyon Risk 2.0 an antre anvigè. Lè sa rive, FEMA ap konsidere eben tout reklamasyon ki gen 20 lane depi yo fèt epi mete ajou evalyasyon an nan renouvèlman anyèl kontra a.",
      zone: "insurance-shopping"
  }
]