import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '../styles/variables';
import GoogleMapsPlacesHelper from '../helpers/mapHelpers/GoogleMapsPlacesHelper';
import { useProvider, useGoogleMaps } from '../helpers/hooks';
import GoogleMaps from '../helpers/GoogleMaps';

const AddressInput = styled.div`
  min-width: 310px;
  width: 100%;

  input[type='text'] {
    width: 100%;
    padding: 0 1rem;
    margin: 0;
    border: 1px solid ${colors.grayScale[1]};
    width: 100%;
    margin-bottom: 1em;
    font-size: 16px;
    font-family: 'GT-Walsheim-Pro-Medium';
    color: ${colors.grayScale[3]};
    height: 50px;
  }
`;

const ExplorableMapAddressInput = ({
  address,
  submitAddress,
  handleAddressInputChange,
  handleGoogleAutocomplete,
  setPopup
}) => {
  const provider = useProvider('ExplorableMap');
  const inputRef = useRef(null);
  const { googleMapsScriptUrl } = useGoogleMaps();

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [autocompleteInstance, setAutocompleteInstance] = useState(null);

  useEffect(() => {
    const createAndSetAutocomplete = async () => {
      const autocomplete = await GoogleMaps.createAutocomplete(
        inputRef.current,
        googleMapsScriptUrl
      );
      setAutocompleteInstance(autocomplete);
    };
    createAndSetAutocomplete();
  }, [googleMapsScriptUrl]);

  useEffect(() => {
    const updateAddressFromListener = (autocomplete) => {
      const location = new GoogleMapsPlacesHelper(
        autocomplete.getPlace()
      ).getLocation();
      handleGoogleAutocomplete({
        formatted_address: location.formatted_address,
        lat: location.lat,
        lng: location.lng
      });
      setPopup(true);
      if (isSubmitted) {
        setIsSubmitted(false);
        submitAddress();
      }
    };

    if (autocompleteInstance) {
      autocompleteInstance.addListener('place_changed', () => {
        updateAddressFromListener(autocompleteInstance);
      });
    }
  }, [isSubmitted, autocompleteInstance]);

  const keySubmit = (e) => {
    if (e.key === 'Enter') {
      // Prevent Enter refreshing the page, which it does by default.
      e.preventDefault();
      setIsSubmitted(true);
    }
  };

  const updateAddress = (e) => {
    handleAddressInputChange(e.target.value);
  };

  return (
    <AddressInput>
      <input
        type="text"
        id="address_eligibility_input"
        placeholder={provider.addressInput.placeholder}
        ref={inputRef}
        onChange={updateAddress}
        onKeyPress={keySubmit}
        value={address}
      />
    </AddressInput>
  );
};

ExplorableMapAddressInput.propTypes = {
  address: PropTypes.string,
  submitAddress: PropTypes.func.isRequired,
  handleAddressInputChange: PropTypes.func.isRequired,
  handleGoogleAutocomplete: PropTypes.func.isRequired,
  setPopup: PropTypes.func.isRequired
};

ExplorableMapAddressInput.defaultProps = {
  address: ''
};

export default ExplorableMapAddressInput;
