export default {
  header: 'Estimasyon pou Asirans kont Inondasyon',
  beta: 'BETA',
  link: 'Gade tout estimasyon ou an',
  currentMapStart: 'Kat aktyèl yo ( ',
  currentMapEnd: ' zòn)',
  futureMapStart: 'Kat konsiltatif (',
  futureMapEnd: ' zòn)',
  assumingMax: '*Nou sipoze yon kouvèti maksimòm',
  numbersAreEstimates:
    'Chif sa yo se estimasyon pou anpil rezon, pou aprann plis sou yo ale sou ',
  termsAndConditions: 'Tèm ak Kondisyon yo',
  floodZoneRateProvider: {
    perYear: 'Pou chak ane*'
  },
  rateAdjusterProvider: {
    adjustYourCoverage: 'Ajiste kouvèti ou ',
    structuralCoverage: 'Kouvèti estriktirèl',
    structuralDeductible: 'Franchiz estriktirèl',
    contentCoverage: 'Kouvèti kontni yo',
    contentDeductible: 'Franchiz kontni yo',
    collapse: 'Chit'
  }
};
