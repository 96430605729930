import RtappApplicationState from '../constants/RtappApplicationState';

export default {
  progressBar: {
    apply: 'Aplike',
    audit: 'Odite',
    review: 'Analize',
    install: 'Enstale',
    complete: 'Fini',
    rejected: 'Inelijib',
    withdrawn: 'Retire'
  },
  [RtappApplicationState.PRE_APPLICATION]: {
    title: 'Mèsi! Nou ap an kontak byento.'
  },
  [RtappApplicationState.PRE_APPLICATION_APPROVED]: {
    title: 'Ranpli Aplikasyon Valv pou Dlo k Ap Tounen Ou an'
  },
  [RtappApplicationState.APPLICATION_SUBMITTED]: {
    title: 'Mèsi dèske ou Aplike!'
  },
  [RtappApplicationState.HOMEOWNER_GRANT]: {
    title: 'Felisitasyon! Ou kalifye!'
  },
  [RtappApplicationState.AUDIT_SCHEDULED]: {
    title: 'Pwograme Odit lan'
  },
  [RtappApplicationState.AUDIT_COMPLETE]: {
    title: 'Odit lan Fini'
  },
  [RtappApplicationState.FEASIBILITY_REVIEW]: {
    title: 'Analiz Fezabilite'
  },
  [RtappApplicationState.CONSULTATION_SCHEDULED]: {
    title: 'Ou gen yon randevou pou oryantasyon!'
  },
  [RtappApplicationState.CONSULTATION_COMPLETE]: {
    title: 'Ann kontinye vanse'
  },
  [RtappApplicationState.INSTALLATION_PREPARE]: {
    title: 'Nou ap monte ekip ou an'
  },
  [RtappApplicationState.MEET_YOUR_TEAM]: {
    title: 'Rankontre Ekip Ou an'
  },
  [RtappApplicationState.INSTALLATION_TO_BE_SCHEDULED]: {
    title: 'Pwograme Enstalasyon Ou an'
  },
  [RtappApplicationState.INSTALLATION_HAS_BEEN_SCHEDULED]: {
    title: 'Pwograme Enstalasyon Ou an'
  },
  [RtappApplicationState.INSTALLATION_COMPLETE]: {
    title: 'Enstalasyon ou an pwograme'
  },
  [RtappApplicationState.INSTALLATION_COMPLETE]: {
    title: 'Enstalasyon an Fini!'
  },
  [RtappApplicationState.REPORT_COMPLETE]: {
    title: 'Rapò Final'
  },
  [RtappApplicationState.REJECTED]: {
    title: 'Dezole, ou pa kalifye'
  },
  [RtappApplicationState.WITHDRAWN]: {
    title: 'Aplikasyon ou an retire'
  }
};
