export default {
    findYourHome: {
      header: '在洪水地图上找到您的房屋',
      body:
        '超过40万纽约人可能会受到未来洪水区的影响，看看会对您产生的影响。'
    },
    estimate: {
      header: '获取预计洪水保险',
      body:
        '在纽约市洪水频发的社区，76%没有标高证明的房主为洪水保险支付了过高的费用。'
    },
    lowerRisk: {
      header: '降低风险和费率',
      body:
        '超过10万纽约人向FloodHelpNY寻求帮助，学习、采取行动并减少洪水带来的风险。'
    }
  };
  