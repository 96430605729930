import backwaterValve from '../images/backwater-valve.svg';
import floodproofing from '../images/floodproofing.svg';
import passFail from '../images/pass-fail.svg';
import raiseMechanicals from '../images/raise-mechanicals.svg';
import resilientRetail from '../images/resilient-retail-cover.jpg';
import assess from '../images/assess.svg';
import floodBarrier from '../images/flood-barrier.svg';
import generator from '../images/generator.svg';
import storefronts from '../images/storefronts.png';
import waterPump from '../images/water-pump.svg';
import waterproofStorage from '../images/waterproof-storage.svg';
import protect from '../images/protect.svg';
import sbsLogo from '../images/sbs-logo.svg';
import strategy from '../images/strategy.svg';

export default {
  meta: {
    title: 'Риск наводнения для малого бизнеса | FloodHelpNY',
    ogTitle: 'Объяснение риска наводнения для малого бизнеса | FloodHelpNY',
    url: 'https://www.floodhelpny.org/ru/small-business',
    description:
      'Наводнение может быть финансово опустошающим для владельцев малого бизнеса. Вот как вы можете защитить вашу деятельность и средства к сущестованию.',
    imageAlt: 'Малый бизнес | FloodHelpNY'
  },
  header: 'Малый бизнес: Объяснение риска наводнения',
  subHeader:
    'Наводнение может быть финансово опустошающим для владельцев малого бизнеса. Вот как вы можете защитить вашу деятельность и средства к сущестованию.',
  overview: {
    header: 'ОБЗОР',
    body:
      'Наводнение может сделать больше, чем повредить ваш инвентарь или прервать вашу деятельность - оно может закрыть ваш безнес навсегда. На самом деле по крайней мере 25 процентов малого бизнеса, пострадавших в результате бедствия, не открываются заново. Но при помощи правильного планирования вы можете отреагировать и восстановиться быстро. Позвольте нам провести вас через некоторые шаги, которые вы можете предпринять, чтобы подготовиться к наводнению и сократить ущерб, который мог бы закрыть ваши двери навсегда.'
  },
  attribution: {
    logo: sbsLogo,
    logoAlt: 'Логотип Консультационных услуг малому бизнесу Нью-Йорка',
    image: storefronts,
    imageAlt: 'Витрины в Нью-Йорке',
    credit: 'Эта страница предоставлена:'
  },
  whatYouCanDo: {
    header: 'ЧТО МОГУТ СДЕЛАТЬ ВЛАДЕЛЬЦЫ МАЛОГО БИЗНЕСА',
    assess: {
      header: 'Оценить',
      icon: assess,
      iconAlt: 'Дом',
      body:
        "Чрезвычайно важно, чтобы вы уделили время оценке рисков, которыми наводнение может грозить вашему бизнесу. Узнайте вашу зону наводнения, учтите все опасности для здоровья и риски для деятельности."
    },
    prepare: {
      header: 'Подготовить',
      icon: passFail,
      iconAlt: 'Перечень',
      body:
        "Обязательно разработайте план действий на случай чрезвычайных обстоятельств, чтобы обеспечть непрерывность деятельности и сохранить ваших работников. Также важно, чтобы вы приобрели или возобновили ваше страхование от наводнения."
    },
    protect: {
      header: 'Защитить',
      icon: protect,
      iconAlt: 'Щит',
      body:
        "Узнайте о шагах, которые вы можете предпринять, чтобы защитить здание и оборудование вашего бизнеса, а также доходы, чтобы вы могли восстановиться после наводнения так быстро, как это возможно."
    },
    callToAction: {
      header: 'Начните разработку вашего плана обеспечения бесперебойной деятельности сегодня',
      icon: strategy,
      iconAlt: 'Начните разработку вашего плана обеспечения бесперебойной деятельности сегодня',
      body:
        'Используйте эту концепцию для того, чтобы прийти в норму после временного прекращения работы. Защитите ваши инвестиции, оставайтесь на связи со своими работниками и поставщиками, и будьте готовы отреагировать на неопределенность.',
      buttonText: 'Вид'
    }
  },
  riskZoneHeader: 'ПОЙМИТЕ ВАШ РИСК НАВОДНЕНИЯ',
  severeRiskZone: 'Наивысший риск',
  highRiskZone: 'Повышенный риск',
  moderateRiskZone: 'Средний риск',
  warning: {
    primary: {
      body:
        'В настоящее время 14500 бизнесов, которые оперируют в зонах, наиболее подверженных риску наводнения в Нью-Йорке. Согласно Федеральному агентству <a href="https://www.fema.gov" target="_blank" rel="noopener noreferrer">по управлению в чрезвычайных обстоятельствах (FEMA)</a> крупные бедствия могут сказаться на 40% малого бизнеса в течение одного года с момента наводнения, в то время как 25% закрываются через год после наводнения. '
    },
    aside: {
      header: 'Ограничения страховой защиты зданий',
      body:
        'Максимальная сумма страхования от наводнения, которую вы можете приобрести чтобы покрыть ущерб, нанесенный вашему зданию - 500000 долларов США.'
    }
  },
  floodInsurance: {
    primary: {
      header: 'СТРАХОВАНИЕ ОТ НАВОДНЕНИЯ МОЖЕТ СНИЗИТЬ ВАШ РИСК',
      body:
        'Цель полиса страхования от наводнения состоит в финансовой компенсации ущерба, нанесенного наводнением, что обычно исключается из полиса страхования имущества. Отсутствие полиса страхования от наводнения может поставить бизнес под угрозу риска финансовых потерь в случае наводнения, которое является однеим из самых распространенных стихийных бедствий в мире. И наводнения могут произайти где угодно - более 20% исков по наводнениям поступают из территорий, находящихся вне зоны аовышенного риска наводнения.'
    },
    aside: {
      header: 'Приобретите сейчас, сэкономьте позже',
      body:
        'Если вы приобретете страхование от наводнения сейчас, вы сможете сэкономить тысячи долларов в течение нескольких следующих лет.'
    }
  },
  businessInsurance: {
    header: 'СТРАХОВАНИЕ ВРЕМЕННОГО ПРЕКРАЩЕНИЯ РАБОТЫ БИЗНЕСА',
    body:
      'Страхование временного прекращения работы бизнеса (также известного как страхование предпринимательского дохода) это тип страхования, который покрывает потерю дохода, понесенного бизнесом после бедствия. Покрытие потери дохода может быть из-за закрытия объекта хозяйствования, связанного с бедствием, или из-за восстановительного процесса после бедствия. Важно, чтобы владельцы бизнеса понимали, что их текущее страхование имущества может не покрывать их доход. Потеря дохода, произведенного бизнесом, может означать разницу между тем, останутся ли двери открытыми, или закроются навсегда.'
  },
  lowerYourRiskOptions: {
    header: 'Физические меры по снижению вашего риска наводнения',
    list: [
      {
        image: raiseMechanicals,
        imageAlt: 'Дом',
        title: 'Поднимите ваши механические устройства',
        body:
          "Чаще всего это оборудование расположено в подвале здания, где оно может быть подвержено затоплению."
      },
      {
        image: backwaterValve,
        imageAlt: 'Клапан',
        title: 'Установите канализационный обратный клапан',
        body:
          'Это поможет предотвратить попадение сточных вод в ваш дом в случае переполнения канализации.'
      },
      {
        image: floodproofing,
        imageAlt: 'Дом',
        title: 'Влажная и сухая противопаводковая защита',
        body:
          'Если вы не можете переехать и конструкция вашего здания это позволяет, вы должны рассмотреть варианты влажной и сухой противопаводковой защиты в качестве защиты от наводнений.'
      }
    ]
  },
  otherMethodsToLowerRisk: {
    title: 'ДРУГИЕ МЕТОДЫ СНИЖЕНИЯ ВАШЕГО РИСКА НАВОДНЕНИЯ',
    subtitle:
      'Наводнение может случиться в любое время и в зонах с небольшим или отсутствием риска наводнения. Являетесь ли вы владельцем недвижимости или арендатором, существуют меры, которые вы можете предпринять сегодня, чтобы сохранить свой бизнес и восстановить и запустить бизнес после наводнения. Примите во внимание следующее оборудование, чтобы защитить ваш бизнес от ущерба, нанесенного наводнением и помочь вам восстановиться быстрее.',
    list: [
      {
        header: 'Портативные перемычки для защиты от наводнения',
        body:
          'Портативные перемычки для защиты от наводнения, которые предназначены для временной установки в дверях и других местах потенциального проникновения могут помочь бизнесу защитить их помещение от затопления водой и ущерба.',
        image: floodBarrier,
        imageAlt: 'Портативные перемычки для защиты от наводнения'
      },
      {
        header: 'Передвижной генератор',
        body:
          'Передвижной генератор может быть использован в качестве источника питания для непрерывной работы и для поддержания климатических условий во время потери основного источника питания.',
        image: generator,
        imageAlt: 'Передвижной генератор'
      },
      {
        header: 'Переносная водонепроницаемая камера хранения',
        body:
          'Переносной водонепроницаемый контейнер для хранения может сберечь важные документы, позволяя бизнесу доступ к критически важной инфомации для реакции на бедствие и восстановления деятельности в установленные временные показатели.',
        image: waterproofStorage,
        imageAlt: 'Переносная водонепроницаемая камера хранения'
      },
      {
        header: 'Портативный насос погружной водяной',
        body:
          'Насос погружной водяной и шланг могут быть использованы для предотвращения накопления паводковых вод, проникающих в здание, или удаления воды после наводнения, уменьшая потенциальный ущерб зданию или товарно-материальным ценностям и ускорению процесса восстановления.',
        image: waterPump,
        imageAlt: 'Портативный насос погружной водяной'
      }
    ]
  },
  continuityPlan: {
    title: 'Руководство по городскому планированию устойчивого розничного безнеса',
    body:
      'Уникальный ресурс в помощь владельцам безнеса, расположенного в розничных коридорах по всей затопляемой территории города.',
    view: 'Вид',
    image: resilientRetail,
    imageAlt: 'Руководство по устойчивости прибрежному климату'
  }
};
