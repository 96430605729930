import BuildingSizes from '../constants/BuildingSizes';
import BuildingTypes from '../constants/BuildingTypes';
import ContentLocations from '../constants/ContentLocations';
import BuildingFeatures from '../constants/BuildingFeatures';
import bungalow from '../images/bungalow.svg';
import detached from '../images/detached.svg';
import semiDetached from '../images/semi-detached.svg';
import attached from '../images/attached.svg';
import midRiseWalkUp from '../images/midrise-walk-up.svg';
import midRiseElevator from '../images/midrise-elevator.svg';
import unknown from '../images/unknown.svg';
import basement from '../images/basement-cellar.svg';
import crawlspaceWithFloodVents from '../images/crawl-space-with-flood-vents.svg';
import crawlspaceWithoutFloodVents from '../images/crawl-space-without-flood-vents.svg';
import enclosure from '../images/enclosure.svg';
import mobileHome from '../images/mobile-home.svg';
import subgradeCrawlspace from '../images/sub-grade-crawl-space.svg';
import none from '../images/cross.svg';

export default {
  navigation: {
    next: '下一个',
    previous: '之前的'
  },
  faq: {
    header: '您可能在想'
  },
  breadcrumbs: {
    back: '返回档案'
  },
  questions: {
    numberOfResidentialUnits: {
      title: 'numberOfResidentialUnits',
      faqZone: 'rate-calculator-number-of-residential-units',
      question: '您的建筑有多少个住宅单元？',
      breadcrumb: '建筑尺寸',
      responseField: {
        options: [
          { value: BuildingSizes.SINGLE_FAMILY, display: '单户房产' },
          { value: BuildingSizes.TWO_TO_FOUR_FAMILY, display: '2-4个房间' },
          { value: BuildingSizes.MULTI_FAMILY, display: '5个以上房间' }
        ],
        invalidAnswers: [BuildingSizes.MULTI_FAMILY],
        invalidCopy:
          '此费率计算器仅适用于1-4房的家庭房产。',
        invalidLinkCopy: '单击此处返回家庭。',
        invalidLink: '/',
        invalidCopyAfterLink: '',
        alertAnswers: []
      }
    },
    buildingType: {
      title: 'buildingType',
      faqZone: 'rate-calculator-building-type',
      question: '您如何描述您的建筑类型？',
      breadcrumb: '建筑物类型',
      responseField: {
        key: 'building_type',
        options: [
          {
            value: BuildingTypes.BUNGALOW,
            display: '平房',
            image: bungalow
          },
          {
            value: BuildingTypes.DETACHED,
            display: '独栋',
            image: detached
          },
          {
            value: BuildingTypes.SEMI_DETACHED,
            display: '半分离',
            image: semiDetached
          },
          {
            value: BuildingTypes.ATTACHED,
            display: '附属',
            image: attached
          },
          {
            value: BuildingTypes.MID_RISE_WALK_UP,
            display: '中层步道',
            image: midRiseWalkUp
          },
          {
            value: BuildingTypes.MID_RISE_ELEVATOR,
            display: '中层电梯',
            image: midRiseElevator
          },
          {
            value: BuildingTypes.UNKNOWN,
            display: "我不知道",
            image: unknown
          }
        ]
      }
    },
    preFirm: {
      title: 'preFirm',
      faqZone: 'rate-calculator-pre-firm',
      breadcrumb: 'Pre/Post FIRM',
      preFirmQuestion:
        '这处房产似乎在1983年11月16日之前建造的。这是正确的吗？',
      postFirmQuestion:
        '这处房产似乎是在1983年11月16日之后建造的或经过了大型翻修。这是正确的吗？',
      responseField: {
        options: [
          { value: 'yes', display: '是' },
          { value: 'no', display: '否' }
        ],
        invalidAnswers: [],
        invalidCopy: '',
        invalidLinkCopy: '',
        invalidLink: '#',
        invalidCopyAfterLink: '',
        alertAnswers: []
      },
      disclaimer:
        '自1983年11月16日纽约市采用第一张洪水区地图以来，在此之前建造的房产都有临时补贴费率。'
    },
    severeRepetitiveLoss: {
      title: 'severeRepetitiveLoss',
      faqZone: 'rate-calculator-severe-repetitive-loss',
      breadcrumb: '严重的重复性损失',
      floodDamageQuestion: '这处房产过去曾遭受洪水破坏吗？',
      severeRepetitiveLossQuestion:
        '联邦应急管理局或您的洪水保险提供商是否通知您您的建筑发生了“严重的重复性损失”？',
      floodDamageResponse: {
        options: [
          { value: true, display: '是' },
          { value: false, display: '否' }
        ],
        invalidAnswers: [],
        invalidCopy: '',
        invalidLinkCopy: '',
        invalidLink: '#',
        invalidCopyAfterLink: '',
        alertAnswers: []
      },
      severeRepetitiveLossResponse: {
        options: [
          { value: 'yes', display: '是' },
          { value: 'no', display: '否' },
          { value: 'unknown', display: "我不知道" }
        ],
        invalidAnswers: [],
        invalidCopy: '',
        invalidLinkCopy: '',
        invalidLink: '#',
        invalidCopyAfterLink: '',
        alertAnswers: []
      },
      floodDamageDisclaimer:
        '我们之所以这么问，是因为有特殊的洪水保险费率，仅适用于具有特定损失历史的财产。',
      severeRepetitiveLossDisclaimer:
        '纽约市的大多数建筑都不是遭遇“严重的重复损失”建筑。'
    },
    preferredRiskRate: {
      title: 'preferredRiskRate',
      faqZone: 'rate-calculator-preferred-risk-rate',
      breadcrumb: '首选风险率',
      floodClaimQuestion:
        '您是否曾提出过洪水保险理赔或接受过洪水灾害援助？',
      floodClaimResponseField: {
        options: [
          { value: true, display: '是' },
          { value: false, display: '否' }
        ],
        invalidAnswers: [],
        invalidCopy: '',
        invalidLinkCopy: '',
        invalidLink: '#',
        invalidCopyAfterLink: '',
        alertAnswers: []
      },
      numberOfClaimsQuestion: '多少次事件？',
      numberOfClaimsSubtitle:
        '每一场风暴都是一个单独的事件，所以如果您只收到飓风桑迪的付款，那就算作一次事件。',
      numberOfClaimsResponseField: {
        options: [
          { value: 1, display: '1' },
          { value: 2, display: '2' },
          { value: 3, display: '3个以上' }
        ],
        invalidAnswers: [],
        invalidCopy: '',
        invalidLinkCopy: '',
        invalidLink: '#',
        invalidCopyAfterLink: '',
        alertAnswers: []
      },
      claimAmountQuestion: '每一次洪水您都收到1000美元以上的理赔吗？',
      claimAmountResponseField: {
        options: [
          { value: 'true', display: '是' },
          { value: 'false', display: '否' }
        ],
        invalidAnswers: [],
        invalidCopy: '',
        invalidLinkCopy: '',
        invalidLink: '#',
        invalidCopyAfterLink: '',
        alertAnswers: []
      },
      disclaimer:
        '我们之所以这么问，是因为有特殊的洪水保险费率，只适用于有特定损失历史的财产'
    },
    elevatedBuilding: {
      title: 'elevatedBuilding',
      faqZone: 'rate-calculator-elevated-building',
      question: '您的房屋是升高了吗？',
      breadcrumb: '已经升高了',
      responseField: {
        options: [
          { value: 'true', display: '是' },
          { value: 'false', display: '否' }
        ],
        invalidAnswers: [],
        invalidCopy: '',
        invalidLinkCopy: '',
        invalidLink: '#',
        invalidCopyAfterLink: '',
        alertAnswers: []
      },
      disclaimer:
        '这意味着该结构位于支柱或部分墙壁上。'
    },
    contentLocation: {
      title: 'contentLocation',
      faqZone: 'rate-calculator-content-location',
      breadcrumb: '物品位置',
      question:
        '您想投保的物品在建筑的什么地方？',
      responseField: {
        key: 'content_location',
        options: [
          {
            value: ContentLocations.BASEMENT_AND_ABOVE,
            display: '地下室（仅限洗衣机/烘干机/冰箱）及以上'
          },
          {
            value: ContentLocations.ENCLOSURE_AND_ABOVE,
            display: '封闭空间（仅限洗衣机/烘干机/冰箱）及以上'
          },
          {
            value: ContentLocations.LOWEST_FLOOR_ONLY,
            display: '仅最低楼层，高于地面'
          },
          {
            value: ContentLocations.LOWEST_FLOOR_AND_HIGHER,
            display: '地面以上的最低楼层和较高楼层'
          },
          {
            value: ContentLocations.ABOVE_GROUND_LEVEL,
            display: '高于地面，超过一整层'
          }
        ]
      },
      disclaimer:
        '请记住，在地下室或围栏中，您可以确投保的物品是洗衣机、烘干机和冰箱。有关更多信息，请参阅常见问题解答。'
    },
    buildingFeature: {
      title: 'buildingFeature',
      faqZone: 'rate-calculator-building-feature',
      breadcrumb: '建筑特征',
      question:
        '您的房产具备以下哪些功能（如有）？',
      responseField: {
        key: 'building_feature',
        options: [
          {
            value: BuildingFeatures.BASEMENT,
            display: '地下室或地窖',
            image: basement
          },
          {
            value: BuildingFeatures.ENCLOSURE,
            display: '封闭空间',
            image: enclosure
          },
          {
            value: BuildingFeatures.ELEVATED_ON_CRAWLSPACE_FLOOD_VENTS,
            display: '带泄洪口的夹层空间',
            image: crawlspaceWithFloodVents
          },
          {
            value: BuildingFeatures.ELEVATED_ON_CRAWLSPACE_NO_FLOOD_VENTS,
            display: '没有泄洪口的夹层空间',
            image: crawlspaceWithoutFloodVents
          },
          {
            value: BuildingFeatures.SUBGRADE_CRAWLSPACE,
            display: '路基夹层空间',
            image: subgradeCrawlspace
          },
          {
            value: BuildingFeatures.MOBILE_HOME,
            display: '移动房间',
            image: mobileHome
          },
          {
            value: BuildingFeatures.NO_BASEMENT_ENCLOSURE,
            display: '无',
            image: none
          }
        ]
      }
    },
    basement: {
      title: 'basement',
      faqZone: 'rate-calculator-basement',
      breadcrumb: '地下室',
      hasBasementQuestion:
        '您的房屋是否有地下室、夹层空间或围栏？',
      responseField: {
        options: [
          { value: true, display: '是' },
          { value: false, display: '否' }
        ],
        invalidAnswers: [],
        invalidCopy: '',
        invalidLinkCopy: '',
        invalidLink: '#',
        invalidCopyAfterLink: '',
        alertAnswers: []
      },
      basementHeightQuestion:
        "这个地下室、夹层空间或围栏的地板到天花板的高度是多少？（大多数地下室是8'）？",
      basementHeightUnit: 'ft'
    },
    numberOfSteps: {
      title: 'numberOfSteps',
      faqZone: 'rate-calculator-number-of-steps',
      breadcrumb: '上/下台阶',
      hasStepsQuestion:
        '从地面到最低楼层有台阶吗？（如果您有地下室或地下夹层空间，我们指的是从室外地面到地下室或夹层空间地板的台阶。）',
      hasStepsResponseField: {
        options: [
          { value: 'true', display: '是' },
          { value: 'false', display: '否' }
        ],
        invalidAnswers: [],
        invalidCopy: '',
        invalidLinkCopy: '',
        invalidLink: '#',
        invalidCopyAfterLink: '',
        alertAnswers: []
      },
      frontDoorQuestion: '台阶是向上还是向下？',
      frontDoorResponseField: {
        options: [
          { value: 'true', display: '向上' },
          { value: 'false', display: '向下' }
        ],
        invalidAnswers: [],
        invalidCopy: '',
        invalidLinkCopy: '',
        invalidLink: '#',
        invalidCopyAfterLink: '',
        alertAnswers: []
      },
      numberOfStepsQuestion: '有多少个台阶？',
      disclaimer:
        '一定要从房屋的最低点回答这个问题。如果地面前高后低，从后面回答。如果最低楼层位于任何一侧的地面，请回答“否”。'
    },
    numberOfFloors: {
      title: 'numberOfFloors',
      faqZone: 'rate-calculator-number-of-floors',
      breadcrumb: '多层',
      question: '家里不止一层楼吗？',
      responseField: {
        options: [
          { value: true, display: '是' },
          { value: false, display: '否' }
        ],
        invalidAnswers: [],
        invalidCopy: '',
        invalidLinkCopy: '',
        invalidLink: '#',
        invalidCopyAfterLink: '',
        alertAnswers: []
      }
    },
    machineryInBasement: {
      title: 'machineryInBasement',
      faqZone: 'rate-calculator-machinery-in-basement',
      breadcrumb: '地下室机械',
      question:
        '您的地下室里有建筑机械吗，比如热水器、锅炉、暖通空调等？',
      responseField: {
        options: [
          { value: true, display: '是' },
          { value: false, display: '否' }
        ],
        invalidAnswers: [],
        invalidCopy: '',
        invalidLinkCopy: '',
        invalidLink: '#',
        invalidCopyAfterLink: '',
        alertAnswers: []
      },
      disclaimer:
        '这代表为家庭提供公共服务的机器或设备。'
    }
  }
};
