import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import PolygonFormatter from '../helpers/mapHelpers/PolygonFormatter';
import GoogleMaps from '../helpers/GoogleMaps';
import { useGoogleMaps } from '../helpers/hooks';

const Map = styled.div`
  height: 500px;
`;

const FloodZoneMap = () => {
  const mapRef = useRef(null);
  const { googleMapsScriptUrl } = useGoogleMaps();

  useEffect(() => {
    const map = GoogleMaps.createMap(mapRef.current);
    PolygonFormatter.getNeighborhoods().forEach((neighborhood) => {
      neighborhood.polygon.setMap(map);
    });
  }, [googleMapsScriptUrl]);

  return <Map role="figure" ref={mapRef} />;
};

export default FloodZoneMap;
