export default [
    {
        question: "После измерения высоты наводнения мне кажется, что в моем сертификате допущена ошибка.",
        answer: "Инженеры и землеустроители используют специализированные инструменты для измерения высоты каждого этажа по отношению к уровню моря. Мерная лента не измерит с точностью высоту над уровнем моря вашей недвижимости.",
        zone: "elevation-certificates"
    },
    {
        question: "Соотвествую ли я критериям жилищной программы на установку канализационного обратного клапана?",
        answer: "В данный момент вы соответствуете критериям если вы проживаете в пределах границ районов нижний Манхэттен, Рокавей Ист, юго-восточное побережье Бруклина, Грейвзенд и Бенсонхерст, Говард Бич, Герритсен Бич и Шипсхэд Бич, и южный полуостров Бруклина по определению Секретариата губернатора по восстановлению после ливневых бурь (GOSR). После заполнения заявления мы рассмотрим ваше соответствие критериям и ознакомим вас с нашим решением. Даже в том случае, если сантехник или инженер при осмотре вашего дома обнаружит существующие условия, которые сделают установку клапана невозможной, ваше соответствие критериям может быть приостановлено до тех пор, пока проблема с существующими условиями не будет решена домовладельцем.",
        zone: "bwv-flood-applicants"
    },
    {
        question: "Обязан ли я получить сертификат высотной отметки?",
        answer: "Если ваша собственость расположена в настоящий момент в особой зоне, подверженной затоплениям (SFHA), сертификат о высотной отметке является наилучшим способом определения правильно ли рассчитана ваша страховка. В дополнение к этому, если вы увидите, что новые карты помещают ваш дом в зону более высокого риска, сертификат о высотной отметке может гарантировать правильное расположение вашего дома на карте.",
        zone: "understanding-flood-insurance"
    },
    {
        question: "Займут ли все стадии программы (аудит, консультации, и т.д.) больше времени для заявителей в Канарси и Рэд Хук, которые уже участвуют в программе?",
        answer: "Заявители из Канарси и Рэд Хук, которые не участвовали в программе Инспекции устойчивости дома не соотвествуют критериям на участие в этот раз. Те кто раньше участвовал в программе бедет продвигаться через часть канализационного обратного клапана программы с той же скоростью, что и остальные участники.",
        zone: "bwv-flood-applicants"
    },
    {
        question: "Важны ли паводковые клапаны для защиты дома от ущерба от паводковых вод?",
        answer: "Паводковые клапаны предотвращают структурное повреждение вашего дома от силы воды, давящей на него, называемой гидростатическим давлением. Если вода может затечь в технический подпол и вытечь обратно, это давление уменьшается, защищая ваш дом. Паводковые клапаны в вашем техническом подполе могут значительно сократить ваш тариф страхования от наводнения, в зависимлсти от высоты дома по сравнению с Базовой высотой паводка.",
        zone: "rate-calculator-building-feature"
    },
    {
        question: "Будут ли домовладельцы, которые сейчас находятся в зоне Х, переходящие в ориентировочную зону Х, соотвествовать критериям?",
        answer: "Да, они соответствуют критериям.",
        zone: "bwv-flood-applicants"
    },
    {
        question: "Отличаются ли зоны наводнения FEMA от городских зон эвакуации?",
        answer: "Да. Вы можете получть более подробную ниформацию о городских зонах эвакуации <a target=\"_blank\" href=\"http://www1.nyc.gov/assets/em/html/know-your-zone/knowyourzone.html\">здесь</a>.",
        zone: "understanding-flood-insurance"
    },
    {
        question: "Есть ли какие-либо финансовые выгоды от участия в программе?",
        answer: "Сертификаты высотной отметки должны быть заполнены землеустроителем и могут стоить от 500 до 800 долларов США за несложные случаи, но стоимость может возрасти на несколько тысяч долларов в сложных случаях. В дополнение к этому с вами будут работать консультативные службы, чтобы помочь вам определить шаги, которые вы должны предпринять, чтобы сократить ваш тариф на страхование от наводнения в будущем.",
        zone: "rtapp-multifamily-landing rtapp-landing"
    },
    {
        question: "Существуют ли разные виды обратных клапанов?",
        answer: "Да, наряду с другими есть затворный, шаровой и сливной клапан. Лицензированный сантехник или инженер смогут сказать вам какой клапан, если он необходим, будет лучшим для вашего дома",
        zone: "mitigation-backwater_valve"
    },
    {
        question: "Существуют ли разные виды канализационных обратных клапанов?",
        answer: "Да, наряду с другими есть затворный, шаровой и сливной клапан. Дренажные обратные клапаны имеют несколько названий, включая запорный клапан и клапан \"утиный нос\". Лицензированный сантехник или инженер смогут сказать вам какой клапан, если он необходим, будет лучшим для вашего дома",
        zone: "bwv-flood-applicants"
    },
    {
        question: "Могу ли я пристроить к моему дому, чтобы восполнить потерянную площадь?",
        answer: "Может быть возможным надстроить еще один этаж к вашему дому, если это целесообразно в экономическом смысле и не угрожает целостности конструкции дома. Любая пристройка также должна соответствовать зональным и строительным нормам.",
        zone: "mitigation-abandon_first_floor"
    },
    {
        question: "Могу ли я подать заявку на участие в программе еще раз?",
        answer: "Если вы не соответствуете требованиям, но заинтересованы в получении информации от нас когда новые программы устойчивости от наводнения станут доступными, или если наши требования соотвествия изменятся, позвоните нам по телефону (646) 786-0888 или пошоите нам сообшение по электронной почте по адресу info@floodhelpny.org. Если ваша ситуация изменится и вам покажется, что вы соотвествуете нашим требованиям, вы можете подать заявку снова!",
        zone: "rtapp-status-Ineligible"
    },
    {
        question: "Могу ли я выбрать инспектора моего дома?",
        answer: "К сожалению из-за необходимости обслуживать широкий диапазон домовладельцев и из-за расписания вы не можете выбрать вашего инспектора.",
        zone: "rtapp-status"
    },
    {
        question: "Могу ли я сам засыпать мой подвал?",
        answer: "Вам потребуется инженер, чтобы удостовериться, что засыпка подвала не повредит строительной конструкции вашего дома. Вам также нужно будет получить разрешение от Управления городскими зданиями Нью-Йорка. Вы можете быть в состоянии выполнить некоторые работы, но мы рекомендуем вам нанять квалифицированного проектировщика, чтобы помочь вам в этом. Проектировщику может также понадобиться получение разрешения. Вам также может понадобиться изменить конфигурацию технологического присоединения к коммуникациям и механического оборудования.",
        zone: "mitigation-fill_in_basement"
    },
    {
        question: "Могу ли я получить сертификат высотной отметки бесплатно?",
        answer: "Короткий ответ - нет. Однако, у предыдущего владельца возможно уже был сертификат; если вы в процессе приобретения собственности или приобрели собственность недавно, возможно вам стоит связаться с ним.",
        zone: "elevation-certificates"
    },
    {
        question: "Можете ли вы объяснить федеральное финансирование программы?",
        answer: "Эта программа финансируется департаментом жилищного строительства и городского развития США (HUD) безвозвратной ссудой блока развития местного сообщества - восстановления после бедствия (CDBG-DR). Финансирование предоставляется через Программу Реконструкции общин восстановления Нью-Йорка (NYRCR) в составе Секретариата губернатора по восстановлению после ливневых бурь (GOSR).\\n",
        zone: "rtapp-status-ApplicationSubmitted"
    },
    {
        question: "Обязательно ли для соответствия критериям, чтобы моя собственность была повреждена во время урагана Сэнди?",
        answer: "Нет, для того, чтобы соответствовать критериям, собственность не должна быть повредлена ураганом.",
        zone: "bwv-flood-applicants"
    },
    {
        question: "Должен ли я быть дома во время установки?",
        answer: "Да, домовладелец должен присутствовать во время посещения инженера, предстроительного совещания и установки.",
        zone: "bwv-flood-applicants"
    },
    {
        question: "Должен ли я идти куда-либо для встречи с группой строителей?",
        answer: "Ваша группа строителей придет к вам домой для предстроительного совещания, которое должноо занять от одного до двух часов.",
        zone: "rtapp-status-MeetYourTeam"
    },
    {
        question: "Нужно ли мне быть дома во время инспекции инженера?",
        answer: "Да, вам нужно быть дома.",
        zone: "rtapp-status"
    },
    {
        question: "Нужно ли мне приобрести страхование от наводнения если я не домовладелец?",
        answer: "Да, ущерб, нанесенный содержимому дома наводнением скорее всего не будет покрыт страховкой арендатора; страхование от наводнения приобретается отдельно.",
        zone: "understanding-flood-insurance"
    },
    {
        question: "Нужно ли мне установить паводковые отверстия если я засыплю мой подвал?",
        answer: "Да. Паводковые отверстия защищают ваш дом путем обеспечения того, что вода не будет собираться с одной стороны стены и создавать давление, которое может повредить ваш фундамент. Они также необходимы для того, чтобы получить скидку на страховку после засыпки подвала. Паводковые отверстия должны быть установлены лицензированным инженером.",
        zone: "mitigation-fill_in_basement"
    },
    {
        question: "Нужно ли мне платить за страхование от наводнения в то время, как мой дом будут поднимать?",
        answer: "Да, ваш дом все еще будет уязвим ущербом от наводнения в то время, как его будут поднимать",
        zone: "mitigation-elevate"
    },
    {
        question: "Нужно ли мне будет переехать на время возвышения механизмов?",
        answer: "Это будет зависеть от того, какое оборудование будет перемещаться, времени года и других факторов. Весьма вероятно, что вам придется временно переехать по крайней мере на некоторое время строительства.",
        zone: "mitigation-elevate_mechanicals"
    },
    {
        question: "Нужно ли мне будет переехать на время, когда мой дом будут поднимать?",
        answer: "Да, техническое оборудование, такое как вода, канализация и электричество будут отключены. Лестницы и другие пути входа в дом будут отсоединены во время строительства.",
        zone: "mitigation-elevate"
    },
    {
        question: "Имеются ли у вас другие программы для моего дома, которые могут быть мне полезны?",
        answer: "Да! Если вы хотите узнать на какие другие программы и льготы вы можете рассчитывать, позвоните нам по телефону (646) 786-0888.",
        zone: "rtapp-status-Ineligible rtapp-status-Withdrawn"
    },
    {
        question: "Делитесь ли вы информацией об этой программе со штатом?",
        answer: "Да, мы также делимся этой информацией с федеральным правительством. Эта программа финансируется департаментом жилищного строительства и городского развития США (HUD) безвозвратной ссудой блока развития местного сообщества - восстановления после бедствия (CDBG-DR) и осуществляется Секретариатом губернатора по восстановлению после ливневых бурь (GOSR), которое является агентством штата.",
        zone: "bwv-flood-applicants"
    },
    {
        question: "Являются ли стиральная и сушильная машина и морозильная камера \"строительной техникой\"?",
        answer: "Нет, они считаются содержимым имуществом. Чтобы иметь покрытие этих предметов, вам нужно приобрести страховую защиту имущества.",
        zone: "rate-calculator-machinery-in-basement"
    },
    {
        question: "Истекает ли срок действия сертификата высотной отметки?",
        answer: "Нет. Вы можете увидеть дату истечения в верхнем правом углу вашего сертификата высотной отметки. Это просто означает, что это новая форма. Сертификат высотной отметки на старой форме все еще действителен.",
        zone: "rtapp-preapp-elevation-certificate"
    },
    {
        question: "Считается ли строительная работа по Отстроим заново пособием?",
        answer: "Да, помощь по Отстроим заново финансируется на федеральном уровне и об этой помощи необходимо сообщать.",
        zone: "understanding-flood-insurance"
    },
    {
        question: "Покрывает ли страхование недвижимости ущерб от наводнения?",
        answer: "Страхование недвижимости не покрывает ущерб от наводнения.",
        zone: "rtapp-preapp-homeowner-insurance"
    },
    {
        question: "Влияет ли мой доход на соответствие критериям программы?",
        answer: "Программа инспекции устойчивости дома может обслуживать только семьи с низким или средним доходом. Согласно департаменту жилищного строительства и городского развития США (HUD) те, у кого средний семейный доход составляет менее пятидесяти процентов медианного дохода по региону (AMI), считаются малообеспеченными. Те, у кого средний семейный доход составляет от по крайней мере 50 процентов до/включая 80 процентов медианного дохода по региону (AMI), считаются среднеобеспеченными. Если доход вашей семьи превышает эти параметры, вы не соответствуете критериям данной программы.",
        zone: "bwv-flood-applicants"
    },
    {
        question: "Выгодна ли эта программа для меня?",
        answer: "Да! Услуги FloodHelpNY направлены на то, чтобы помочь сделать дома менее уязвимыми наводнениям и чтобы сократить потенциальный ущерб, наносимый домам, включая ущерб съемным помещениям в случае наводнения или противотока из канализационного колектора.",
        zone: "bwv-flood-applicants"
    },
    {
        question: "Каким образом владельцы зданий отбираются на участие в программе?",
        answer: "Приоритетность основывается на доходе жителей здания, проживают ли они в зоне риска затопления относительно \"столетнего наводнения\", и было ли здание повреждено в результате урагана Сэнди.",
        zone: "rtapp-multifamily-landing"
    },
    {
        question: "Каким образом домовладельцы отбираются на участие в программе?",
        answer: "Домовладельцы, соответствующие всем критериям, смогут участвовать в программе. Если вы заинтересованы в том, чтобы ваша кандидатура была рассмотрена, заполните нашу короткую приемную форму на интернете.",
        zone: "rtapp-landing"
    },
    {
        question: "Как я могу найти подрядчика, чтобы засыпать мой подвал?",
        answer: "Управление городскими зданиями Нью-Йорка <a target=\"_blank\" href=\"http://www1.nyc.gov/site/buildings/homeowner/homeowner.page\">может дать вам совет</a> как найти проектировщика и подрядчиков, у которых есть опыт работы в зонах наводнения. Подрядчики должны иметь опыт, лицензию и страховку. Проверьте рекомендации.",
        zone: "mitigation-fill_in_basement"
    },
    {
        question: "Как я могу узнать, могу ли я поднять мой дом?",
        answer: "Вам нужно будет нанять квалифицированного инженера, который сможет оценить, будет ли поднятие хорошим вариантом для вашего дома.",
        zone: "mitigation-elevate"
    },
    {
        question: "Как я могу узнать, могу ли я засыпать мой подвал?",
        answer: "Проконсультируйтесь с профессиональным инженером, лицензированным подрядчиком или Управлением городскими зданиями Нью-Йорка.",
        zone: "mitigation-fill_in_basement"
    },
    {
        question: "Как я могу получть сертификат высотной отметки?",
        answer: "Сертификаты высотной отметки должны быть заполнены землеустроителем или инженером и могут стоить от 500 до 800 долларов США для несложных случаев, но могут быть больше в более затруднительных случаях.",
        zone: "understanding-flood-insurance"
    },
    {
        question: "Как я могу получить этот вид страхового покрытия?",
        answer: "Домовладельцы могут обсудить как получить дополнительное условие страхования от противотока из канализационного колектора с их провайдером страхования недвижимости. Страхование от противотока из канализационного колектора обычно стоит менее 100 долларов США в год. Предыдущие иски, кредит и высота на уровне улицы могут повлиять на тарифы.",
        zone: "rtapp-preapp-sewage"
    },
    {
        question: "Как я могу узнать дополнительную информацию о страховании от наводнения?",
        answer: "Ознакомьтесь с нашей <a href=\"https://www.floodhelpny.org/ru/understanding-flood-insurance\">всеобъемлющей страничкой ресурсов!</a>",
        zone: "rtapp-preapp-flood-insurance"
    },
    {
        question: "Как я тогу понизить мой тариф на страхование от наводнения?",
        answer: "В настоящий момент существует только четыре меры, которые повлияют на страховой взнос: поднятие дома, засыпка подвала или технического подпола и установка паводковых отверстий, отказ от вашего первого этажа, и поднятие механического оборудования из подвала. Последний вариант предоставляет наименьшее из трех сокращение страховки, но он может сэкономить вам большое количество денег в случае наводнения.",
        zone: "understanding-flood-insurance"
    },
    {
        question: "Как я могу определить, установлен ли в моей собственности канализационный обратный клапан?",
        answer: "Лучший путь выяснить, есть ли у вас канализационный обратный клапан или узнать, нужен ли он вам, это проконсультироваться с лицензированным и квалифицированным сантехником. Канализационный обратный клапан трудно обнаружить самим, особенно если он был установлен давно.",
        zone: "bwv-flood-applicants"
    },
    {
        question: "Как я могу определить, установлен ли в моей собственности канализационный обратный клапан?",
        answer: "Лучший путь выяснить, есть ли у вас канализационный обратный клапан или узнать, нужен ли он вам, это проконсультироваться с лицензированным и квалифицированным сантехником. Канализационный обратный клапан трудно обнаружить самим, особенно если он был установлен давно.",
        zone: "rtapp-preapp-backwater-exist"
    },
    {
        question: "Как я могу выбрать, какие варианты устойчивости от наводнения подходят мне?",
        answer: "Вы должны учесть какие варианты имеются в наличии для вашего дома (например, вы не сможете с легкостью поднять сблокированный дом), первоначальные затраты на меры по предотвращению или снижению рисков, и возможность сэкономить на вашем страховом взносе с течением времени.",
        zone: "programs-and-benefits resiliency"
    },
    {
        question: "Как я могу определить размер моего домохозяйства в целях программы?",
        answer: "Размер домохозяйства может быть определен путем подсчета количества людей, проживающих с вами, включая вас.",
        zone: "rtapp-preapp-household-size"
    },
    {
        question: "Как я могу найти Базовую высоту наводнения (BFE) моего дома?",
        answer: "Начните с определения вашей зоны. Если вы в зоне АЕ, сертификат высотной отметки будет наилучшим методом точного определения вашей BFE. Если вы в зоне Х, то вы находитесь в зоне карты наводнений, которая на данный момент не имеет ориентировочную базовую высоту наводнения.",
        zone: "understanding-flood-insurance mitigation-flood_vents mitigation-abandon_first_floor mitigation-elevate_mechanicals"
    },
    {
        question: "Как я могу найти Расчетный уровень паводка (DFE) моего дома?",
        answer: "DFE определяет самую безопасную высоту для строительства в зонах, подверженных наводнениям. В Нью-Форке DFE обычно это Базовая высота наводнения плюс два фута - известные как \"превышение над уровнем воды\" для дополнительной безопасности. Ваш проектировщик или архитектор должны проконсультироваться с Управлением городскими зданиями Нью-Йорка во время проетирования и получения разрешения, чтобы удостовериться, что они следуют DFE.",
        zone: "mitigation-flood_vents mitigation-abandon_first_floor mitigation-elevate_mechanicals"
    },
    {
        question: "Как я могу узнать, насколько мое механическое оборудование должно быть поднято?",
        answer: "Вы или ваш подрядчик должны всегда консультироваться с Управлением городскими зданиями Нью-Йорка. В идеале, оборудование должно быть поднято до Расчетного уровня паводка, что является Базовой высотой наводнения плюс два фута превышения над уровнем воды.",
        zone: "mitigation-elevate_mechanicals"
    },
    {
        question: "Как я могу получить помощь при заполнении заявления?",
        answer: "Если у вас есть вопросы, или вам нужна помощь при заполнении заявления, вы можете позвонить в нашу Центральную станцию домовладельцев по телефону (646) 786-0888.",
        zone: "rtapp-status-FullApplication"
    },
    {
        question: "Как я узнаю, насколько нужно поднять мой дом?",
        answer: "Обычно в Нью-Йорке требуются BFE плюс два фута превышения над уровнем воды. Но было бы неплохо проконсультироваться с квалифицированным инженером, у которого есть опыт строительства в зонах наводнений. Управление городскими зданиями Нью-Йорка <a target=\"_blank\" href=\"http://www1.nyc.gov/site/buildings/homeowner/homeowner.page\">может помочь вам найти его</a>.",
        zone: "mitigation-elevate"
    },
    {
        question: "Как я узнаю, иммется ли у меня \"подвал\" или \"технический подпол\"?",
        answer: "Подвал - это место, где пол находится ниже уровня земли со всех четырех сторон. Если это место ниже 5 метров в высоту, то оно обчно считается техническим подполом.",
        zone: "rate-calculator-building-feature"
    },
    {
        question: "Как я узнаю, нужен ли мне канализационный обратный клапан?",
        answer: "Всегда консультируйтесь с лицензированным сантехником, чтобы определить, поможет ли вам канализационный обратный клапан, так как его установка в определенных домах может только ухудшить ситуацию.",
        zone: "mitigation-backwater_valve"
    },
    {
        question: "Как я узнаю, брал ли я помощь при стихийных бедствиях раньше?",
        answer: "Если вы получали помощь от FEMA, SBA, Отстроим заново или Восстановления Нью-Йорка - на ремонт или неконструкцию вашего дома или его сожержимого - из-за ущерба, нанесенного наводнением, то вы получали помощь при стихийных бедствиях. Для большинства людей это будет помощь за ущерб, нанесенный ураганом Айрин или Сэнди.",
        zone: "understanding-flood-insurance"
    },
    {
        question: "Как я узнаю был ли противоток в моей водопроводно-канализационной системе?",
        answer: "Вы могли заметить воду и/или неприятный запах, исходящий из вашего туалета, раковины, ванной, стока в полу, или другой сантехники",
        zone: "bwv-flood-applicants"
    },
    {
        question: "Как я узнаю, где я должен установить клапан?",
        answer: "Лицензированный сантехник оценит состояние и расположение вашего канализационного коллектора третьего порядка. Он или она определит самое подходящее место для установки клапана. Всегда консультируйтесь с лицензированным сантехником, чтобы определить место, так как установленный в неправильном месте канализационный обратный клапан может только ухудшить ситуацию.",
        zone: "mitigation-backwater_valve"
    },
    {
        question: "Как я узнаю, какой вид канализационного обратного клапана нужно установить?",
        answer: "Лицензированный сантехник или инженер могут подсказать вам какой вид канализационного обратного клапана, если ог вообще нужен, лучше всего подходит для вашего дома.",
        zone: "mitigation-backwater_valve"
    },
    {
        question: "Как мне поддерживать канализационный обратный клапан в рабочем состоянии?",
        answer: "Попросите вашего сантехника предоставить вам инструкции по уходу и эксплуатации от производителя клапана. Обычно это довольно просто - откройте место доступа клапана пару раз в год, чтобы убедиться, что ваш клапан не забит. Если что-то забило ваш клапан, удалите это. Если заслонке требуется смазка, нанесите ее. Некоторые сантехники предлагают программы техобслуживания и сделают это за вас. Если вы получите канализационный обратный клапан через нашу программу, сантехник, который будет устанавливать ваш клапан, покажет вам где клапан расположен, как он работает, и как его содержать. В дополнение к этому вы получите отчет с места уогда установка будет закончена, содержащий определенные инструкции по уходу и эксплуатации от производителя клапана.",
        zone: "bwv-flood-applicants mitigation-backwater_valve rtapp-status-InstallationComplete"
    },
    {
        question: "Как я могут соответствовать критериям на сохранение прав по \"построенному в соответствии\" в полисе страхования от наводнения?",
        answer: "Вы можете соответствовать критериям только если ваш дом был построен после ноября 1983г. и вы сейчас находитесь в зоне повышенного риска наводнений. Вы также должны показать, что ваш дом был построен в соответствии с текущей картой наводнения и вы его не модифицировали. Если вы переоборудовали гараж на первом этаже в жилое помещение, например, вы не будете соответствовать требованиям, если вы не переоборудуете его обратно.",
        zone: "understanding-flood-insurance"
    },
    {
        question: "Как я могут соответствовать критериям на сохранение прав по \"непрерывному покрытию\" в полисе страхования от наводнения?",
        answer: "Удостоверяясь, что у вас есть полис до того, как карты изменятся по сути означает, что у вас нет непрерывного покрытия.",
        zone: "understanding-flood-insurance"
    },
    {
        question: "Как я могу подать требуемые для заявления документы?",
        answer: "Чтобы подать ваши документы вы можете:<ul><li>Отсканировать и загрузить через вашу страницу статуса на FloodHelpNY.org.</li><li>Отправить по факсу в Центр для районов Нью-Йорка: 1-646-849-1765</li><li>Отправить по почте в Центр для районов Нью-Йорка вним.</li><li>FloodHelpNY, 55 Broad Street, 10th Floor, New York, NY 10004.</li></ul>",
        zone: "bwv-flood-applicants"
    },
    {
        question: "Как я могу подать Соглашение о безвозмездной ссуде на дом (HGA) в Центр?",
        answer: "Если только один человек должен подписать ваше Соглашение о безвозмездной ссуде домовладельцу (то есть только один человек указан в акте недвижимости), то вы можете подписать документ электронным образом. После того, как мы определим ваше соответствие критериям, мы перешлем вам ссылку, чтобы вы могли это сделать. Если больше одного человека должны подписать ваше Соглашение о безвозмездной ссуде домовладельцу, вы можете загрузить и распечатать документ с вашей страницы статуса. После заполнения вы можете послать его по факсу по номеру (646) 506-4621, послать копию по электронной почте по адресу info@floodhelpny.org или отправить по почтовой связи по адресу FloodHelpNY, Center for NYC Neighborhoods, 55 Broad Street, 10th Floor, New York, NY 10004.",
        zone: "rtapp-status-GrantAgreement"
    },
    {
        question: "Как ступени влияют на мой тариф страхования от наводнения?",
        answer: "Если у вас нетт подвала или подземного технического подпола, ваш тариф будет определяться высотой вашего первого этажа. Другими словами. чем выше этот этаж над Базовой высотой наводнения, тем ниже будет ваш тариф.",
        zone: "rate-calculator-number-of-steps"
    },
    {
        question: "Как эти особенности дома влияют на мой тариф страхования от наводнения?",
        answer: "По сути, чем ниже расположен ваш нижний этаж, тем выше будет ваш тариф на страхование от наводнения.",
        zone: "rate-calculator-building-feature"
    },
    {
        question: "Как вы можете соответствовать критериям на полис Предпочтительного риска страхования от наводнения?",
        answer: "Вы должны быть в зоне Х или не быть ни в какой зоне и должны были получить только одну выплату в резмере более 1000 долларов США от программы федеральной помощи при стихийных бедствиях за отдельные наводнения за последние 10 лет. Федеральная помощь при стихийных бедствиях означает страхование от наводнения NFIP, или от FEMA или SBA или Отстроим заново.",
        zone: "understanding-flood-insurance"
    },
    {
        question: "Как подвал влияет на мой тариф страхования от наводнения?",
        answer: "Если вы живете в зоне повышенного риска наводнения, ваш тариф устанавливается на основе высоты вашего нижнего этажа по отношению к вашей BFE. Если у вас есть подвал или технический подпол, ваш тариф устанавливается на основе высоты этого этажа, даже если помещение без отделки.",
        zone: "rate-calculator-basement"
    },
    {
        question: "Как машинное оборудование влияет на мой тариф страхования от наводнения?",
        answer: "Машинное оборудование в вашем подвале повышает ваш тариф, но не намного. Но поднятие этого машинного оборудования может иметь влияние на то, как быстро вы сможете восстановить отопление и электричество в вашем доме после бури.",
        zone: "rate-calculator-machinery-in-basement"
    },
    {
        question: "Как тип здания влияет на мой тариф страхования от наводнения?",
        answer: "В зависимости от типа вашего здания существуют разные варианты устойчивости.",
        zone: "rate-calculator-building-type"
    },
    {
        question: "Как содержимое влияет на мой тариф страхования от наводнения?",
        answer: "Покрытие содержимого подвала и ограждающих конструкций более дорогое, чем более высоких этажей, и покрытие в подвале и пристройках ограничено стиральной и сушильной машинами и морозильником. И это все. Более высокая стоимость покрытия содержимого может не иметь смысл страхования этих предметов в вашем подвале.",
        zone: "rate-calculator-content-location"
    },
    {
        question: "Как высота влияет на мой тариф страхования от наводнения?",
        answer: "Если вы живете в зоне повышенного риска наводнения, ваш тариф устанавливается на основе высоты вашего нижнего этажа по отношению к вашей BFE. Когда вы поднимаете ваш дом, чтобы он был на 2 фута выше вашей BFE, вы значительно сокращаете шансы того, что сточные воды проникнут в ваш дом, и соответственно стоимость страховки.",
        zone: "rate-calculator-elevated-building"
    },
    {
        question: "Как предыдущий ущерб от наводнения влияет на мой тариф страхования от наводнения?",
        answer: "Если вы получили более 1000 долларов США по иску за ущерб, нанесенный наводнением от страхования от наводнения, FEMA или Администрации малого бизнеса два или более раз за различные наводнения, вы соответствуете критериям на низкий тариф полиса Предпочтительного риска страхования от наводнения. Помните, что выплаты должны быть за разные наводнения, но не две выплаты за ущерб от наводнения. Если вам было выплачено 50000 долларов или 100000 долларов от FEMA и страхования от наводнения за Сэнди, это только один иск.",
        zone: "rate-calculator-preferred-risk-rate"
    },
    {
        question: "Как предыдущий ущерб от наводнения влияет на мой тариф страхования от наводнения?",
        answer: "Если вы получили более 1000 долларов США по иску за ущерб, нанесенный наводнением от страхования от наводнения, FEMA или Администрации малого бизнеса два или более раз за различные наводнения, вы соответствуете критериям на низкий тариф полиса Предпочтительного риска страхования от наводнения. Помните, что выплаты должны быть за разные наводнения, но не две выплаты за ущерб от наводнения. Если вам было выплачено 50000 долларов или 100000 долларов от FEMA и страхования от наводнения за Сэнди, это только один иск.",
        zone: "rate-calculator-severe-repetitive-loss"
    },
    {
        question: "Как изменение климата влияет на город Нью-Йорк?",
        answer: "Изменение климата добавляет совершенно иной элемент размышлению об устойчивости в городе Нью-Йорк. Вы можете прочитать допольнительную информацию о влиянии изменения климата на город <a target=\"_blank\" href=\"http://onlinelibrary.wiley.com/doi/10.1111/nyas.12591/full\">здесь</a>.",
        zone: "resiliency rtapp-multifamily-landing"
    },
    {
        question: "Почему финансирование программы предоставляется уже существующим заявителям, но не новым?",
        answer: "Это действительно только для Канарси и Рэд Хук. Канарси было обно из самых активных участников в начальной программе инспекции устойчавости дома, прежде чем была добавлена установка канализационного обратного клапана. Финансирование для инспекций в Канарси было использовано, но с тех пор мы получили дополнительное финансирование для установления канализационного обратного клапана там. Так как никто не может получить установку канализационного обратного клапана без инспекции, те люди в Канарси, которые еще не прошли инспекцию и не получили консультацию до того, как финансирование было исчерпано, не будут соответствовать критериям на получение инспекции и канализационного обратного клапана. Возможно, что распределение финансирования может измениться в будущем, и мы обязательно уведомим об этом если это произойдет.",
        zone: "bwv-flood-applicants"
    },
    {
        question: "Сколько времени займет отказ от первого этажа?",
        answer: "Время может варьироваться в зависимости от конструкции здания, размера площади, которая будет пустовать на нижнем уровне и сколько потребуется засыпания подземной площади. Неплохим предположением будет от нескольких недель до 90 дней. Однако дополнительное время должно быть предусмотрено для прооетирования и получения разрешений.",
        zone: "mitigation-abandon_first_floor"
    },
    {
        question: "Сколько времени займет подъем моего дома?",
        answer: "Выполнение работы самостоятельно может занять до трех месяцев или больше. Однако создание проекта и получение разрешений может растянуть временные рамки поднятия вашего дома.",
        zone: "mitigation-elevate"
    },
    {
        question: "Сколько времени займет засыпание моего подвала?",
        answer: "Засыпка подвала или технического подпола может занять несколько дней. Но это может занять в среднем от одной до двух недель больше, если вам нужно будет премещать механизмы из подвала или технического подпола. Добавьте время, занятое получением разрешений, и это займет еще дольше.",
        zone: "mitigation-fill_in_basement"
    },
    {
        question: "Сколько времени займет подъем механического оборудования?",
        answer: "Это может занять от трех до пяти дней до нескольких недель после получения разрешений и начала строительства.",
        zone: "mitigation-elevate_mechanicals"
    },
    {
        question: "Сколько времени обычно занимает процесс утвреждения заявления?",
        answer: "Как только ваше заявление и соответствующие документы будут получены вы можете ожидать решения об утверждении в течение пяти рабочих дней.",
        zone: "rtapp-status-ApplicationSubmitted"
    },
    {
        question: "Сколько времени займет влажная противопаводковая защита?",
        answer: "Установка паводковых отверстий может быть сделана в течение нескольких дней.",
        zone: "mitigation-flood_vents"
    },
    {
        question: "Сколько времени займет установка канализационного обратного клапана?",
        answer: "Количество времени, которое займет установка, зависит от планировки вашего дома и его соединения с городской канализацией, а также от состояния труб, соединяюших ваш дом с городской канализацией. Хотелось бы надеяться, что понадобится только один приход, но возможно, что вашему сантехнику придется вернуться в другой день, чтобы закончить уборку или завершить сложную установку. Вам нужно будет быть дома.",
        zone: "rtapp-status-InstallationToBeScheduled"
    },
    {
        question: "Сколько времени займет консультация?",
        answer: "Консультация должна занять от 45 минут до полутора часов.",
        zone: "rtapp-status"
    },
    {
        question: "Сколько времени займет экспертная оценка?",
        answer: "Экспертная оценка может занять максимум два часа.",
        zone: "rtapp-status"
    },
    {
        question: "Сколько времени займет эта программа?",
        answer: "Трудно прогнозировать, но процесс может занять до 6 месяцев от начала до конца, в зависимости от вашего расписания, насколько сложной будет установка, и от процесса подряда. Спасибо за ваше терпение!",
        zone: "bwv-flood-applicants"
    },
    {
        question: "На сколько дней мне придется брать отгул с работы, чтобы участвовать в программе?",
        answer: "Если вы уже прошли инспекцию устойчивости дома FloodHelpNY, нам понадобится, чтобы вы были дома для трех событий: обзора целесообразности инженером, собрания о начале работ с сантехником и для установки. Если вы не прошли инспекцию устойчивости дома FloodHelpNY, нам понадобится, чтобы вы были дома для инспекции, предстроительного совещания и для самой установки. Мы предполагаем, что инспекция займет максимум два часа. Сроки для собрания о начале работ и для установки остаются теми же, что и для домовладельцев, которые прошли инспекцию.",
        zone: "bwv-flood-applicants"
    },
    {
        question: "На сколько дней мне придется брать отгул с работы, чтобы участвовать в программе?",
        answer: "Вам нужно будет быть дома для предстроительного совещания и для установки. На предстроительном совещании домовладелец познакомится с их сантехником и инспектором, и оно должно занять максимум один час. Типичная установка должна занять один-два дня, в зависимости от дома.",
        zone: "rtapp-status-InstallationPrepare"
    },
    {
        question: "Сколько человек придет оценивать мой дом?",
        answer: "Группа от двух до четырех сертифицированных инженеров и землеустроителей придут к вам в дом, чтобы оценить его подверженность наводнениям и сделать рекомендации как защитить ваш дом от наводнения в будущем.",
        zone: "rtapp-status-AuditScheduled"
    },
    {
        question: "Сколько стоит отказ от первого этажа?",
        answer: "Это зависит от использования первого этажа в данный момент и понадобится ли вам преместить механическое оборудование, сантехнику, кухню и/или ванную комнату. Стоимость может составить от 10000 доллапов до 100000 долларов США или больше.",
        zone: "mitigation-abandon_first_floor"
    },
    {
        question: "Сколько стоит засыпка подвала?",
        answer: "В зависимости от глубины подвала и понадобится ли вам преместить сантехнику и механическое оборудование и установить паводковые отверстия, это может стоить от 8500 долларов до 30000 долларов США.",
        zone: "mitigation-fill_in_basement"
    },
    {
        question: "Сколько стоит установка канализационного обратного клапана?",
        answer: "Это зависит от нескольких факторов, включая расположение ваших сливных труб, глубину и расположение трубы, которая соединяет ваш дом с канализацией, существуют ли экологически опасные вещества (т.е. свинцовосодержащие краски) в помещении, где будет установлен клапан, какого типа клапан будет установлен, и т.д. Стоимость может быть такой минимальной, как 600 долларов, или больше 5000 долларов США.",
        zone: "mitigation-backwater_valve"
    },
    {
        question: "Какую страховую защиту от наводнения я должен иметь, если я получил помощь при стихийных бедствиях (FEMA, SBA, Отстроим заново)?",
        answer: "Если вы получили федеральную помощь при стихийных бедствиях на ремонт или реконструкцию вашего здания или за поврежденное содержимое имущество, у вас должна быть стаховая защита по крайней мере на сумму, которую вы получили. Покрытие страхования содержимого имущества необходимо если вы получили федеральную помощь за поврежденное имущество. Это требование остается с недвижимостью навсегда, для вас и для любых будущих покупателей. Вы должны сказать покупателю об этом требовании.",
        zone: "understanding-flood-insurance"
    },
    {
        question: "Какую страховую защиту от наводнения я должен иметь, если у меня есть ипотека?",
        answer: "Если вы находитесь в зоне повышенного риска наводнения, у вас должно быть покрытие здания на сумму по крайней мере равной невыплаченному балансу ипотеки (остатку основной суммы долга) на всю продолжительность ипотеки. Покрытие страхования содержимого имущества не требуется.",
        zone: "understanding-flood-insurance"
    },
    {
        question: "Какую страховую защиту от наводнения я должен иметь, и когда она должна быть приобретена?\\n",
        answer: "Если заявитель проживает в зоне АЕ, страхование от наводнения должно быть приобретено до того, как они будут приняты в программу. Покрытие страхования от наводнения должно быть равным стоимости установки канализационного обратного клапана, в среднем 1500 долларов США.",
        zone: "bwv-flood-applicants"
    },
    {
        question: "На какую сумму должна быть застрахована недвижимость и когда страхование должно быть приобретено?",
        answer: "Вы должны иметь страхование недвижимости до того, как вы будете приняты в программу. Мы не устанавливаем сумму, на которую должна быть застрахована ваша недвижимость, она просто должна у вас быть.",
        zone: "rtapp-preapp-homeowner-insurance"
    },
    {
        question: "На какую сумму должна быть застрахована недвижимость и когда страхование должно быть приобретено?",
        answer: "Заявитель должен иметь страхование недвижимости до того, как он будет принят в программу. Мы не устанавливаем сумму, на которую должна быть застрахована недвижимость. Страховка просто должна быть у них в принципе.",
        zone: "bwv-flood-applicants"
    },
    {
        question: "Сколько будет стоить повышение моих механических устройств?",
        answer: "В зависимости от вашего дома, подрядчика и от того, какие устройства будут перемещенв, это может стоить между 5000 и 40000 долларов США.",
        zone: "mitigation-elevate_mechanicals"
    },
    {
        question: "Сколько мне будет стоить Жилищная программа установки канализационного обратного клапана?",
        answer: "Жилищная программа установки канализационного обратного клапана абсолютно бесплатна.",
        zone: "bwv-flood-applicants"
    },
    {
        question: "Сколько будет стоить влажная противопаводковая защита?",
        answer: "Установка клапанов может стоить между 4000 и 8000 долларов США.",
        zone: "mitigation-flood_vents"
    },
    {
        question: "Как я должен подготовиться к установке канализационного обратного клапана?",
        answer: "В дополнение к тому, чтобы удостовериться, что сантехник будет иметь достаточный досуп к тому месту в подвале, где будет установлен клапан, подготовьтесь также быть дома и сделайте план относительно времени, когда вода будет отключена.",
        zone: "rtapp-status-InstallationPrepare"
    },
    {
        question: "Как я попаду в мой дом, если первый этаж будет вывелен из эксплуатации?",
        answer: "Вам нужно будет построить новые ступени. При некоторых обстоятельствах вам нужно будет установить механический подъемник.",
        zone: "mitigation-abandon_first_floor"
    },
    {
        question: "Каким образом моя ниформация будет защищена?",
        answer: "Ваша конфиденциальность и безопасность очень важны для нас. Мы используем физические, цифровые и административные меры безопасности, чтобы сохранять вашу личную информацию в безопасности и конфиденциальности. Мы не будем предоставлять какую-либо информацию третьим лицам, которые не вовлечены в администирование FloodHelpNY без вашего разрешения или в соответствии с законом. Мы также придерживаемся жестких условий конфиденциальности, установленных правительством штата и федеральным правительством.",
        zone: "bwv-flood-applicants"
    },
    {
        question: "У меня уже есть сетрификат высотной отметки, должен ли я принять что-либо во внимание?",
        answer: "Если у вас есть сетрификат высотной отметки, вам не нужно будет подавать новый сертификат. Однако, если сделали какие-либо изменения, такие как улучшения, для того, чтобы минимизировать эффекты наводнения, возможно вам стоит получить отновленный сертификат, чтобы гарантировать получение правильной цены.",
        zone: "elevation-certificates"
    },
    {
        question: "Я уже прошел через программу инспекции устойчивости дома FloodHelpNY. Как я могу получить канализационный обратный клапан?",
        answer: "Вы будете рассматриваться на участие в Жилищной программе по установке канализационного обратного клапана задним числом. Когда программа будет запущена, Центр вышлет вам по электронной почте заявление на канализационный обратный клапан. Заявление должно быть заполнено и возвращено в Центр для последующего рассмотрения на предмет соответствия критериям. Ваше соотрветствие критериям будет зависеть от результатов нашего рассмотрения вашего заявления и результатов исследований инженеров и сантехников в вашем доме. Определение соответствия критериям необязательно означает, что вы получите клапан, так как только семьи с низким/средним достатком обслуживаются в настоящее время.",
        zone: "bwv-flood-applicants"
    },
    {
        question: "Мне кажется, что я живу в районе, соответствующем критериям на инспекцию устойчивости дома, но я не могу подписаться на нее.",
        answer: "Свяжитесь с нами по адресу info@floodhelpny.org. Вышлите нам ваше имя, адрес, и расскажите, что произошло, когда вы пытались подать заявку. Мы рассмотрим ситуацию и свяжемся с вами. Вы также можете позвонить на нашу горячую линию по телефону 646-786-0888.",
        zone: "rtapp-landing"
    },
    {
        question: "Я знаю, что я получил сообщение по электронной почте несколько дней после того, как я подал заявку, но я не могу его найти, что мне теперь делать?",
        answer: "Вы скорее всего находитесь в фазе заявления. Вы можете загрузиться в floodhelpny.org и ваша страница статуса сообщит вам, в какой фазе вы находитесь и что вам нужно делать.",
        zone: "bwv-flood-applicants"
    },
    {
        question: "У меня все еще есть вопросы относительно сертификата высотной отметки или страхования от наводнения. Вы можете мне с этим помочь?",
        answer: "Floodsmart.gov это прекрасный ресурс для получения дополнительной информации о страховании от наводнения и сертификате высотной отметки. Вы также можете послать нам свои вопросы по адресу info@floodhelp.org.",
        zone: "elevation-certificates"
    },
    {
        question: "Если заявитель из Канарси или Рэд Хук получили сертификат высотной отметки и посетили консультацию, будет ли их кандидатура рассматриваться на участие в программе канализационного обратного клапана?",
        answer: "Да, единственные заявители из Канарси или Рэд Хук, которые будут рассматриваться на участие в программе канализационного обратного клапана это те, кто получил сертификат высотной отметки и посетил консультацию.",
        zone: "bwv-flood-applicants"
    },
    {
        question: "Как я могу получить сертификат высотной отметки?",
        answer: "Сертификаты высотной отметки могут быть подготовлены и сертифицированы лицензированным землеустроителем, зарегистрированным профессиональным инженером или зарегистрированным архитектором, уполномоченным законом штата или местным законом сертифицировать информацию о высоте. Расспросите их об их лицензии, опыте работы в Нюю-Йорке или в вашем районе, любых сертификатах и рекомендаций с аналогичного проекта. FEMA рекомендует найти профессионального землеустроителя путем проверки с профессиональной ассоциации вашего штата относительно землеустроителей или поговорив с вашим местным офисом разрешений на здания.",
        zone: "elevation-certificates"
    },
    {
        question: "Если я не знаю сколько жилых помещений в моем здании, как я могу узнать об этом?",
        answer: "Управление городскими зданиями может иметь в своих файлах свидетельство о заселении, которое показывает количество жилищных единиц.",
        zone: "rtapp-preapp-number-of-units"
    },
    {
        question: "Если я не знаю, были ли наводнения в данной недвижимости, как я могу узнать об этом?",
        answer: "Спросите вашего брокера страхования от наводенинея или FEMA какова история потерь от наводнения в вашем здании.",
        zone: "rate-calculator-severe-repetitive-loss rate-calculator-preferred-risk-rate"
    },
    {
        question: "Если я не знаю когда было построено мое здание, как я могу узнать об этом?",
        answer: "Если вы когда-либо проводили оценку стоимости имущества, там должна быть указана дата построения. Если у вас более новый дом, вы можете посмотреть на дату в Свидетельстве о заселении, имеющемся в Управлении городскими зданиями.",
        zone: "rate-calculator-pre-firm"
    },
    {
        question: "Если у меня есть полис Предпочтительного риска страхования от наводнения и моя зона изменится на AE или VE, могу ли я унаследовать тариф полиса Предпочтительного риска?",
        answer: "Нет, это субсидия по новым картам, но тариф скорее всего будет повышаться на 15-18% ежегодно до тех пор, пока ваш полис не достигнет суммы полного риска.",
        zone: "understanding-flood-insurance"
    },
    {
        question: "Если я в зоне низкого или среднего риска наводнения, но буду в зоне повышенного риска при новых картах, могу ли я все еще получить полис Предпочтительного риска?",
        answer: "Это будет изменено в соответствии с новыми данными позже.",
        zone: "understanding-flood-insurance"
    },
    {
        question: "Требуется ли страхование от наводнения для участия в программе?",
        answer: "Федеральным законом требуется, чтобы у вас было страхование от наводнения если ваш дом находится в зоне повышенного риска наводненения (AE, AO или VE) и у вас есть ипотека, поддерживаемая на федеральном уровне. (Почти все ипотеки поддерживаются на федеральном уровне). Также, если вы получили федеральную помощь при стихийных бедствиях на ремонт вашего здания или за поврежденное содержимое имущество, федеральным законом требуется, чтобы у вас было страхование от наводнения. Если вы его не приобретете, вы не будете соотвествовать требованиям на получение помощи.",
        zone: "rtapp-preapp-flood-insurance"
    },
    {
        question: "Считается ли помещение в моем доме как \"подвал\", если оно низкое?",
        answer: "Если оно ниже 5 футов от пола до потолка и не более 2 футов ниже уровня земли, то это \"технический подпол\". Если оно низкое, но больше 2 футов ниже уровня земли с одной из сторон, то это все еще \"подвал\".",
        zone: "rate-calculator-basement"
    },
    {
        question: "Включено ли покрытие противотока из канализационной системы в полис моего страхования недвижимости?",
        answer: "Противоток из канализационной системы обычно не покрывается полисом стандартного страхования недвижимости. Страхование противотока из канализационной системы является \"дополнением\" или \"дополнительным условием\", которое часто может быть добавлено к стандартному страхованию недвижимости.",
        zone: "rtapp-preapp-sewage"
    },
    {
        question: "Возможно ли определить закрыта или открыта заслонка на моем канализационном обратном клапане?",
        answer: "Вы узнаете, что клапан закрыт, так как вода перестанет уходить из канализационной системы. Если вы подозреваете, что клапан закрыт, вы также можете использовать точки доступа к клапану, предоставленному сантехником во время установки. У некоторых клапанов есть подсоединеннная к ним сигнализация, которая активизируется когда клапан закрывается, хотя она может быть дорогостоящей.",
        zone: "mitigation-backwater_valve"
    },
    {
        question: "Возможно ли определить закрыта или открыта заслонка на моем канализационном обратном клапане?\\n",
        answer: "Вы узнаете, что клапан закрыт, так как вода перестанет уходить из канализационной системы. Если вы подозреваете, что клапан закрыт, вы также можете использовать точки доступа к клапану, предоставленному сантехником во время установки. У некоторых клапанов есть подсоединеннная к ним сигнализация, которая активизируется когда клапан закрывается, хотя она может быть дорогостоящей.",
        zone: "bwv-flood-applicants"
    },
    {
        question: "Есть ли что-нибудь, что я мог бы сделать, чтобы ускорить процесс установки?",
        answer: "К сожалению, нет. Подготовка вашей строительной группы включает в себя широкомасштабную внутреннюю обработку документации и корреспонденции, которая должна произведена, чтобы обеспечить соблюдение федеральных директив и рекомендаций штата. Мы свяжемся с вами немедленно, как только ваша команда будет готова!",
        zone: "rtapp-status-InstallationPrepare"
    },
    {
        question: "Должны ли заявители указать \"N/A\" в месте для ответа на заявлении, если вопрос не применим к ситуации, или должно должно ли это место остаться пустым?",
        answer: "N/A будет идеальным ответом, чтобы мы приняли информированное решение нужно ли будет нам вызвать кого-либо, чтобы осуществить последующие действия в той области, в которой это будет необходимо.",
        zone: "bwv-flood-applicants"
    },
    {
        question: "Имеется ли время в выходные дни или по вечерам для установки канализационного обратного клапана?",
        answer: "Мы надеемся, что будет по крайней мере несколько назначенных работ в выходные или вечером, но они будут ограничены.",
        zone: "bwv-flood-applicants"
    },
    {
        question: "Какая дополнительная информация требуется для того, чтобы закончить подачу заявления?",
        answer: "Нам нужны будут разборчивые копии всех запрошенных документов для рассмотрения вашего страхования недвижимости, водительских прав, текущей информации о валовом годовом доходе, доказательства страхования от наводнения (если применимо), и информации о доходе арендатора (если применимо). Мы запросим дополнительную информацию если поданные документы будут неразборчивыми или они будут отсутствовать в вашем заявлении.",
        zone: "rtapp-status-ApplicationSubmitted"
    },
    {
        question: "Что такое \"паводковые отверстия\"?",
        answer: "Это особым образом спроектированные отверстия, позволяющие воде затекать и вытекать обратно. Существуют правила, относительно сколько их требуется и где они должны быть установлены. Если отверстия не соответствуют этим требованиям, они не будут считаться паводковыми отверстиями для оценки страховой премии для страхования от наводнения. Прочитайте дополнительную информацию <a target=\"_blank\" href=\"/ru/resiliency\">здесь</a>.",
        zone: "rate-calculator-building-feature"
    },
    {
        question: "Что обычно может послужить причинами того, что кто-то технически может не соответствовать требованиям на участие в программе?",
        answer: "Если существующие условия не позволяют установку канализационного обратного клапана (т.е. недостаточно места или состояние канализационной трубы слишклм плохое для того, чтобы сантехник мог разрезать и вставить в нее без проведения основного ремонта), если дому не тредуется установка канализационного обратного клапана (нет никаких индикаторов того, что дом склонен к противотоку канализации на основании высоты дома, соединения канализации дома, канализационной системы неподалеку, базовой высоты наводнения, и т.д.)",
        zone: "bwv-flood-applicants"
    },
    {
        question: "Что считается \"имуществом\" когда речь идет о страховании от наводнения?",
        answer: "Мебель, одежда, посуда, невстроенные приборы, телевизоры - все ваше вещи, которые не являются частью дома. Помните, что единственное имущество, которое вы можете застраховать в подвале, это стиральная и сушильная машина и морозильная камера. Остальное имущество в подвале не может быть застраховано.",
        zone: "rate-calculator-content-location"
    },
    {
        question: "Что такое \"паводковые отверстия\"?",
        answer: "Это особым образом спроектированные отверстия, позволяющие воде затекать и вытекать обратно. Существуют правила, относительно сколько их требуется и где они должны быть установлены. Если отверстия не соответствуют этим требованиям, они не будут считаться паводковыми отверстиями для оценки страховой премии для страхования от наводнения. Прочитайте дополнительную информацию <a target=\"_blank\" href=\"/ru/resiliency\">здесь</a>.",
        zone: "mitigation-fill_in_basement"
    },
    {
        question: "Какие существуют альтернативы обратным клапанам?",
        answer: "Вы можете установить обратные клапаны на всей сантехнике, находящейся ниже Базовой высоты наводнения, вместо того, чтобы установить только один на канализационной трубе, соединяющей ваш дом с канализацией, если это будет сочтено уместным лицензированным и квалифицированным сантехником. Вы также можете защитить от паводка ваш подвал и использовать его только для парковки или в качестве склада. Канализация все также может сфонтанировать, но ваше имущество будет защищено. Отдел охраны окружающей среды Нью-Йорка (NYCDEP) подготовил <a href=\"http://www.nyc.gov/html/dep/pdf/brochures/flood-preparedness-flyer.pdf\" target=\"_blank\">эту</a> Брошюру подготовки к наводению, чтобы помочь домовладельцам чтобы сократить шанс наводнения в их доме во время ливня.",
        zone: "mitigation-backwater_valve"
    },
    {
        question: "Какие существуют альтернативы канализационным обратным клапанам?",
        answer: "Вы можете защитить от паводка ваш подвал поднять ваше механическое оборудование, чтобы подвал использовался только для парковки или в качестве склада. Канализация все также может сфонтанировать, но ваше имущество будет защищено. Отдел охраны окружающей среды Нью-Йорка (NYCDEP) подготовил Брошюру подготовки к наводению, чтобы помочь домовладельцам чтобы сократить шанс наводнения в их доме во время ливня.\\n\\nhttp://www.nyc.gov/html/dep/pdf/brochures/flood-preparedness-flyer.pdf\\n",
        zone: "bwv-flood-applicants"
    },
    {
        question: "Есть ли какие-либо общие вопросы, о которых я могу подумать до консультации?",
        answer: "Задайте все вопросы о вашем страховании от наводнения и устойчивости от штормов. В настоящее время карты тарифов страхования от наводнения пересматриваются; предложенные федеральным правительством будущие карты наводнения могут увеличить количество домов в зоне наводнения вдвое. Задайте вашему консультанту вопросы о потенциальном будущем риске наводнения для вашей недвижимости и как это может отрадиться на ваших тарифах страхования. Он также может обсудить меры по предотвращению или снижению рисков, чтобы сделать ваш дом более устойчивым.",
        zone: "rtapp-status-AuditComplete rtapp-status-AuditComplete rtapp-status-CounselingScheduled rtapp-status-CounselingScheduled"
    },
    {
        question: "Какие другие виды механических приспособлений можно поднять, чтобы сократить риск повреждения в случае наводнения?",
        answer: "Котлы отопления, тепловые насосы, электрические панели или лифты.",
        zone: "rate-calculator-machinery-in-basement"
    },
    {
        question: "Какова роль и ответственность Центра после того, как обратный клапан будет установлен?",
        answer: "После того, как клапан будет установлен, поддержание его в рабочем состоянии будет ответственностью домовладельца. Центр предоставит отчет с места работ, который будет содержать фотографии до и после строительства и информацию от производителя клапана об использовании и содержании. В контракте, скорее всего, будет содержаться какая-то гарантия на работу. Если проблемы с клапаном возникнут во время гарантийного срока, то домовладелец может связаться с Центром и обсудить проблему и возможно вызвать сантехника, который установил клапан опять, чтобы он устранил проблему.",
        zone: "bwv-flood-applicants"
    },
    {
        question: "Каковы основные правила и условия Соглашения о безвозмездной ссуде домовладельцу (HGA)?",
        answer: "Обратитесь к этому документу, чтобы узнать об основных правилах и условиях HGA. Домовладелец ответственен за просмотр документа во всей целостности, чтобы полностью понять все его условия.",
        zone: "rtapp-status-GrantAgreement"
    },
    {
        question: "Каковы следующие шаги после заполнения предварительной заявки в программу?",
        answer: "После того, как мы рассмотрим информацию, поданную вами для определения предварительного соответствия, мы решим приглашать ли вас для участия в программе. В этом случае вы получите сообщение по электронной почте запрашивающее, чтобы вы заполнили заявление в режиме онлайн. Мы также попросим подать сопровождающую документацию, подтверждающую ваш доход и любую страховку. Заявление и сопровождающая документация будут использованы для подтверждения вашего соответствия критериям.",
        zone: "rtapp-status-IntakeComplete"
    },
    {
        question: "Какие риски возможны при установке обратного клапана?",
        answer: "Существует небольшой риск того, что обратный клапан не сработает. Обратные клапаны сокращают шанс противотока канализации в ваш дом через канализационную трубу, но если состояние клапана не будет поддерживаться, канализация может забиться и протечь.",
        zone: "mitigation-backwater_valve"
    },
    {
        question: "Что я могу сделать, чтобы подтвердить, что мой тариф страхования от наводнения правильный?",
        answer: "Сертификат отметки высоты может помочь обеспечить правильное определение тарифа страхования от наводнения. Как только вы получите сертификат отметки высоты, начинайте работать с вашим страховым агентом над обновлением вашего полиса. Если у вас будут вопросы как ваш полис был оценен после использования сертификата отметки высоты, свяжитесь с центром FEMA по картографированию и биржи страхования (FMIX) по телефону 877-336-2627. Они смогут обсудить и рассмотреть ваш полис.",
        zone: "elevation-certificates"
    },
    {
        question: "Чем я могу засыпать мой подвал, чтобы сократить риск наводнения?",
        answer: "Вы можете использовать чистый спрессованный песок или камень.",
        zone: "mitigation-fill_in_basement"
    },
    {
        question: "Что считается небезопасной установкой обратного клапана?",
        answer: "Установка может считаться небезопасной по многим причинам. Система/площадь может быть в таком плохом состоянии, что продолжение установки будет слишком рисковано для сантехника или системы. Это решение может быть принято на любой стадии. Скорее всего сантехнику понадобится сделать начальную прорезь в полу, чтобы определить, что установка будет \"безопасной\". Ожидается, что такие случаи будут редкими.",
        zone: "bwv-flood-applicants"
    },
    {
        question: "Что считается подтверждением страхования от наводнения для программы?",
        answer: "Первая страница страхового полиса от наводнения может быть приложена в качестве доказательства страхования.",
        zone: "rtapp-status-FullApplication"
    },
    {
        question: "Что считается подтверждением страхования недвижимости для программы?",
        answer: "Первая страница страхового полиса недвижимости может быть приложена в качестве доказательства страхования.",
        zone: "rtapp-status-FullApplication"
    },
    {
        question: "Как я могу подать заявление на участие в программе если у меня есть арендаторы?",
        answer: "Домовладельцы, имеющие арендаторов, должны заполнить форму Подтверждения дохода арендатора; одну форму на арендную квартиру. Домовладелец отвественен за подачу формы и соотвествующей документации в Центр. Если Центр получит информацию об их арендных квартирах, форма Подтверждения дохода арендатора будет выслана по электронной почте. Высылайте вопросы по электронной почте по адресу info@floodhelpny.org или позвоните в Центр по телефону 646-786-0888.",
        zone: "rtapp-status-FullApplication"
    },
    {
        question: "Что я должен сделать, когда форма Подтверждения дохода арендатора будет заполнена?",
        answer: "Вышлите ее по почте в Центр в течение 14 дней с момента получения по адресу: <br /><br />Center for NYC Neighborhoods, attn. FloodHelpNY<br />55 Broad Street<br />10th Floor<br />New York, NY 10004<br /><br />Вы также можете отсканировать и выслать по электронной почте форму и соотвествующую документацию по адресу info@floodhelpny.org.",
        zone: "rtapp-status-FullApplication"
    },
    {
        question: "Что я должен делать когда эта форма заявления будет заполнена?",
        answer: "Вышлите ее по почте в Центр в течение 14 дней с момента получения по адресу: <br /><br />Center for NYC Neighborhoods, attn. FloodHelpNY<br />55 Broad Street<br />10th Floor<br />New York, NY 10004<br /><br />Вы также можете отсканировать и выслать по электронной почте форму и соотвествующую документацию по адресу info@floodhelpny.org.",
        zone: "bwv-flood-applicants"
    },
    {
        question: "Что означают зоны наводнения FEMA?",
        answer: "Зоны наводнения FEMA это географические зоны, характеризующиеся риском наводнения. Например, зоны повышенного риска наводнения имеют 1% шанс быть затопленными каждый год.",
        zone: "understanding-flood-insurance profile"
    },
    {
        question: "Какие документы я должен принести на консультацию?",
        answer: "Чтобы помочь вашему консультанту рассмотреть вашу страховку от наводнения и имеющиеся варианты мер по предотвращению и снижению рисков, мы просим, чтобы вы принесли первую страницу страхового полиса (если применимо), старый сертификат отметки высоты (если применимо) и ипотечную декларацию или другой документ, показывающий ваш статус ипотеки в настоящий момент.",
        zone: "rtapp-status-CounselingScheduled"
    },
    {
        question: "Что означает BFE?",
        answer: "BFE означает \"Базовая высота наводнения\". Только недвижимость в зонах повышенного риска имеет BFE, которая показывает высоту (в футах) выше уровня моря, на которую прогнозируется повышение паводковой воды в случае \"100-летнего\" шторма - шторма, вероятность которого составляет 1% в год. Тариф на каждые 100 долларов США покрытия основывается на том, насколько выше или ниже BFE находится высота вашего дома. Тарифы для домов, в которых нижний этаж нахолится на уровне или ниже BFE, недорогие.",
        zone: "understanding-flood-insurance profile"
    },
    {
        question: "Что делает каждый член команды инспекции устойчивости дома?",
        answer: "Ваш сантехник установит канализационный обратный клапан, вернет рабочую площадь в пред-строительное состояние и покажет, как соделжать обратный клапан. Ваш инспектор проведет финальную инспекцию, чтобы удостовериться, что все работы сделаны правильно и пройдет по перечню окончания работ с вами и вашим сантехником.",
        zone: "rtapp-status-MeetYourTeam"
    },
    {
        question: "Что покрывает страхование от наводнения?",
        answer: "Существует два вида покрытия: здания и содержимого имущества. У вас есть возможность выбрать отдельную сумму покрытия и вычитаемую франшизу для каждого. <a href=\"/ru/understanding-flood-insurance\">Дополнительная информация расположена на нашей странице ресурсов.</a>",
        zone: "rtapp-preapp-flood-insurance"
    },
    {
        question: "Что включает в себя мой технический отчет?",
        answer: "Ваш технический отчет включает в себя фотографии до и после строительства; схематический чертеж, показывающий расположение вашего клапана по отношению к встроенным предметам в вашем доме или на вашей территории; техническую спецификацию продукта от производителя на вашем клапане; инструкцию по эксплуатации и применению от произволителя вашего клапана; и копии разрешений, полученных на вашу установку, если применимо.",
        zone: "rtapp-status-Complete"
    },
    {
        question: "Что означает предварительное соответствие критериям?",
        answer: "Это означает, что вы соответствуете минимальным требованиям программы, таким как наличие дома в одном из соотвествующих рацонов. Однако для дальнейшего определния вашего соответствия вам нужно будет подать полное заявление.",
        zone: "rtapp-status-IntakeComplete"
    },
    {
        question: "Что включает в себя стадия рассмотрения целесообразности?",
        answer: "Ваше рассмотрение целесообразности будет аналонично вашей первой оценке дома, но немного короче! Инженеру и сопровождающему штату будет нужно сделать несколько фотографий и замеров вашего дома, чтобы определить, будет ли установка обратного клапана полезна вашему дому.",
        zone: "rtapp-status-FeasibilityReview"
    },
    {
        question: "Что конкретно представляет собой канализационный обратный клапан?",
        answer: "Большинство домов в Нью-Йорке имеют трубу, которая собирает отходы из раковин и туалетов и выносит их в городскую канализацию. Канализационный обратный клапан это устройство, которое устанавливается на этой трубе, чтобы сократить риск обратного потока сливных вод через эту трубу в ваш дом. В клапане имеется встроенный механизм, который называется \"заслонка\", который закрывается, чтобы защитить ваш дом от затопления неочищенными сточными водами. Когда перелив понижается, изменение давления отпускает заслонку, позволяя сливной воде течь из вашего дома в канализацию. За дополнительной информацией обращайтесь к нашей странице ресурсов.",
        zone: "rtapp-status-Complete"
    },
    {
        question: "Что происходит во время консультации?",
        answer: "Консультант предоставит индивидуализированное объяснение риска наводнения для вашей недвижимости путем рассмотрения данных из сертификата высотной отметки и технического отчета, подготовленного инженерами. В конце вашей консультации вы покинете ее с лучшим пониманием соответствия вашего дома на установку обратного клапана, конкретного риска наводнения для вашего дома, стоимости страховки и вариантов по сокращению ущерба от бурь в будущем.",
        zone: "rtapp-status-CounselingScheduled rtapp-status-AuditComplete"
    },
    {
        question: "Что произойдет, если от меня требуется приобретение страхования от наводнения, но я этого не сделаю?",
        answer: "Если у вас есть федерально поддерживаемая ипотека или заем SBA, ваша организацтя, обслуживающая ипотеку или SBA купят страхование от наводнения и добавят ее стоимость к вашим ежемесячным выплатам. Если вы получили федеральную помощь при стихийном бедствии и не приобрели страховку, вы не получите федеральную помощь при стихийном бедствии на ремонт дома или реконструкцию или за поврежденное имущество при последующих бурях. (Вы все еще сможете получить помощь другого рода, такую как помощь на временное жилище).",
        zone: "rtapp-preapp-flood-insurance understanding-flood-insurance"
    },
    {
        question: "Что произойдет, если я случайно пропущу мою назначенную инспекцию?",
        answer: "Вы можете назначить новую дату, если вы подали вашу просьбу по крайней мере за три дня до инспекции. Инспекцию можно перенести только один раз. Позвоните по телефону 646-786-0888 в рабочее время, чтобы перенести время.",
        zone: "rtapp-status"
    },
    {
        question: "Что произойдет, если я случайно пропущу мою назначенную консультацию?",
        answer: "Вы можете назначить новую дату, если вы подали вашу просьбу по крайней мере за три дня до встречи. Консультацию можно перенести только один раз. Позвоните по телефону 646-786-0888 в рабочее время, чтобы перенести время.",
        zone: "rtapp-status"
    },
    {
        question: "Что произойдет, если предварительно рассчитанный здесь тариф страхования от наводнения отличается от моего тарифа на данный момент?",
        answer: "Если предварительно рассчитанный тариф страхования от наводнения ниже вашего тарифа на данный момент, возможно вы платите за страхование от наводнения слишком много. Свяжитесь с вашим страховым брокером для пересмотра вашего полиса. Просмотрите всю информацию, чтобы убедиться, что она правильная. Проверьте, правильно ли описана недвижимость, что она указана в качестве вашего основного места проживания (если это так), и что зона наводнения и BFE правильные.",
        zone: "rate-estimate"
    },
    {
        question: "Что произойдет, если случится другой шторм, а у меня не будет страхования от наводнения?",
        answer: "Если от вас требуется, чтобы вы имели страхование от наводнения но вы его не приобрели, вы не будете соотвествовать критериям на федеральную помощь при стихийном бедствии за поврежденное здание или имущество при последующих бурях. Вы все еще сможете получить помощь другого рода, такую как помощь на временное жилище.",
        zone: "profile"
    },
    {
        question: "Что если я не в состоянии подтвердить мою информацию для заявления?",
        answer: "Если вы не в состоянии предоставить точно то, что требуется, свяжитесь с нашей группой поддержки. Вы можете нам позвонить в обычное рабочее время по телефону 646-786-0888.",
        zone: "rtapp-self-certify"
    },
    {
        question: "Что если я не могу себе позволить страхование от наводнения?",
        answer: "Посмотрите, сможете ли вы сделать ее более доступной путем сокращения суммы покрытия или повышения франшизы. Проверьте есть ли доступные по цене изменения, которые вы могли бы сделать для вашего дома, чтобы сократить затраты, такие как засыпать технический подпол. Свяжитесь с вашими избранными должностными лицами, чтобы узнать, есть ли программы, помогающие оплатить за изменения в вашем доме, которые сократили бы стоимость, или есть группы добровольцев, которые могли бы это сделать.",
        zone: "programs-and-benefits resiliency profile"
    },
    {
        question: "Что если я не могу позволить рост премии страхования от наводнения?",
        answer: "Может быть вы можете сократить сумму покрытия или получить более высокую франшизу. Хоть какое-то покрытие лучше никакого. Если у вас есть ипотека в зоне повышенного риска, то ваши варианты лимитированы. Попробуйте проверить есть ли доступные по цене изменения, которые вы могли бы сделать для вашего дома, чтобы сократить премию, такие как засыпать технический подпол или подвал и установить паводковые отверстия.",
        zone: "programs-and-benefits resiliency"
    },
    {
        question: "Что если я не получу сообщение по электронной почте от FloodHelpNY?",
        answer: "Проверьте ваше папку со спамом и добавьте info@floodhelpny.org к вашим контактам. Вы также можете позвонить на нашу Горячую линию по телефону 646-786-0888 если у вас есть вопросы.",
        zone: "rtapp-status"
    },
    {
        question: "Что если я получил много разных выплат за Сэнди? Могу ли я еще соответствовать критериям полиса Предпочтительного риска?",
        answer: "Да, если ни одна из выплат не превысила 1000 долларов США.",
        zone: "understanding-flood-insurance"
    },
    {
        question: "Что если у меня есть арендаторы, проживающие на первом этаже моего дома, а я хочу отказаться от первого этажа в качестве меры снижения риска?",
        answer: "Площадь больше не будет жилой.",
        zone: "mitigation-abandon_first_floor"
    },
    {
        question: "Что если я живу в блочном или смежном доме и хотел бы поднять дом?",
        answer: "Поднять блочный дом технически невозможно и нерентабельно.",
        zone: "mitigation-elevate"
    },
    {
        question: "Что если я пропущу назначенное время оценки дома? Могу ли я его перенести?",
        answer: "Если вам нужно перенести время, свяжитесь с нами немедленно по телефону (646) 786-0888.\\nИмейте в виду, что мы можем перенести время только если вы уведомили нас по крайней мере за три дня, и мы можем перенести посещение только один раз.",
        zone: "rtapp-status-FeasibilityReview"
    },
    {
        question: "Что если мне нужно перенести Инспекцию устойчивости дома?",
        answer: "Вы можете назначить новую дату, если вы подали вашу просьбу по крайней мере за три дня до инспекции. Инспекцию можно перенести только один раз. Позвоните по телефону 646-786-0888 в рабочее время, чтобы перенести время.",
        zone: "rtapp-status"
    },
    {
        question: "Что если мне нужно перенести консультацию?",
        answer: "Вы можете назначить новую дату, если вы подали вашу просьбу по крайней мере за три дня до консультации. Консультацию можно перенести только один раз. Позвоните по телефону 646-786-0888 в рабочее время, чтобы перенести время.",
        zone: "rtapp-status"
    },
    {
        question: "Что если я владею или проживаю в многосемейном здании, но хотел бы провести Инспекцию устойчивости дома?",
        answer: "К сожалению вы не соотвествуете критериям на проведение Инспекции устойчивости дома. Но вы все же можете проверить ваш <a href=\"https://www.floodhelpny.org\">риск наводнения.</a>",
        zone: "rtapp-preapp-number-of-units rtapp-landing profile"
    },
    {
        question: "Что если у меня все еще есть вопросы о моем отчете устойчивости?",
        answer: "Ваш консультант ответит на ваши вопросы об отчете или поможет вам получить ответы.",
        zone: "rtapp-status"
    },
    {
        question: "Что если я арендатор и хочу подать заявку на программу?",
        answer: "Эта программа предоставляется только владельцам зданий. Вы можете поделиться этой программой с вашим арендодателем и попросить их подать заявку.",
        zone: "rtapp-preapp-homeowner"
    },
    {
        question: "Что если я ищу дом для покупки, но хочу подать заявку на участие в программе?",
        answer: "Эта программа только для владельцев недвижимости в настоящее время.",
        zone: "rtapp-preapp-homeowner"
    },
    {
        question: "Что если тип моего здания не указан в форме предварительной заявки?",
        answer: "Выберите здание, которое по вашему мнению ближе всего совпадает с вашим.",
        zone: "rate-calculator-building-type"
    },
    {
        question: "Какая информация указана на сертификате высотной отметки?",
        answer: "Три группы основных данных на сертификате высотной отметки - это тип здания, самый нижний этаж и местонахождение недвижимости. Эти факторы все играют важную роль в определении премии вашего страхования от наводнения.",
        zone: "elevation-certificates"
    },
    {
        question: "Что означает \"унаследование\" в смысле страхования от наводнения?",
        answer: "Когда новые карты будут приняты и ваш дом будет помещен в более высокую зону наводнения или BFE для вашей собственности повысится, вы можете сэкономить деньги по правилу унаследования. Если вы соотвествуете критериям, то унаследование позволяет вам закрепить предыдущую зону наводнения или BFE в целях определения тарифа страхования. Существует два вида унаследования: \"непрерывное покрытие\" и \"построенное в соответствии\". Только дома построенные после ноября 1983г. соответствуют критериям по \"построенному в соответствии\".",
        zone: "understanding-flood-insurance profile"
    },
    {
        question: "Что такое \"значительный повторяющийся ущерб\"?",
        answer: "Если вы или предыдущий владелец получили по крайней мере две выплаты за иски по страхованию от наводнения и скомбинированная сумма этих выплат больше, чем рыночная стоимость здания, то ваше здание со \"значительным повторяющимся ущербом\"",
        zone: "rate-calculator-severe-repetitive-loss"
    },
    {
        question: "Что такое \"грунтовый технический подпол\"?",
        answer: "Это помещение, которое 1) под землей со всех 4 сторон, и 2) не глубже 2 футов под землей в любом месте, и 3) не выше чем 4 фута от пола до потолка. Если оно выше 4 футов или глубже 2 футов под землей, то это \"подвал\" в целях страхования от наводнения.",
        zone: "rate-calculator-building-feature"
    },
    {
        question: "Что такое полис Предпочтительного риска страхования от наводнения?",
        answer: "Предпочтительный риск - это субсидированный полис, предназначенный только для зон наводнения со средним или низким риском (Зона Х или вне зоны наводнения) с лимитированной историей исков. Его редняя стоимость приблизительно 500 долларов США в год.",
        zone: "understanding-flood-insurance profile"
    },
    {
        question: "Что такое канализационный обратный клапан?",
        answer: "Большинство домов в Нью-Йорке имеют трубу, которая собирает отходы из раковин и туалетов и выносит их в городскую канализацию. Канализационный обратный клапан - это устройство, которое устанавливается на этой трубе, чтобы сократить риск обратного потока сливных вод через эту трубу в ваш дом, особенно во время сильного дождя. <a href=\"https://www.floodhelpny.org/ru/mitigation/backwater_valve\">Дополнительная информация об обратных клапанах.</a>",
        zone: "rtapp-preapp-backwater-exist rtapp-preapp-sewage"
    },
    {
        question: "Что такое канализационный обратный клапан?",
        answer: "Большинство домов в Нью-Йорке имеют трубу, которая собирает отходы из раковин и туалетов и выносит их в городскую канализацию. Канализационный обратный клапан - это устройство, которое устанавливается на этой трубе, чтобы сократить риск обратного потока сливных вод через эту трубу в ваш дом, особенно во время сильного дождя.\\n",
        zone: "bwv-flood-applicants"
    },
    {
        question: "Что считается \"ущербом\" для программы?",
        answer: "Мы спрашиваем, был ли вашему дому нанесен ущерб ветром, дождем или наводнением в результате прямого воздействия урагана Сэнди. Как правило, если Управление городскими зданиями Нью-Йорка пометило ваш дом желтой или красной табличкой, или если вы получили помощь от FEMA, подали иск в страховой фонд, или получили филантропическую помощь на ремонт из-за Сэнди, мы считаем, что ваш дом понес ущерб.",
        zone: "rtapp-preapp-sandy-damage"
    },
    {
        question: "Что считается \"ущербом от наводнения\" для программы?",
        answer: "Ущерб, вызванный разливом водного пространства, такого как океан или озеро, река или ручей. Затопление, вызванное прорвавшей трубой не считается. Противоток канализации обычно не считается ущербом от наводнения, если он не вызван наводнением.",
        zone: "rate-calculator-severe-repetitive-loss rate-calculator-preferred-risk-rate"
    },
    {
        question: "Что считается ежегодным доходом для программы?",
        answer: "<ul><li>- Сумма до удержания из зарплаты, оклад, сверхурочные, комиссионные, вознаграждения, чаевые, бонусы, и жалование за службу в вооруженных силах</li><li>- Чистый доход от предпринимательской деятельности или профессии</li><li>- Проценты, дивиденды и другая чистая прибыль любого рода от реального или персонального имущества</li><li>- Периодические суммы, получаемые от Социального обеспечения, аннуитетов, страхового полиса, пенсионного фонда, пенсии, пособия по инвалидности, пособия в связи со смертью, пособия на ребенка, и алиментов</li><li>- Арендная плата, полученная от арендаторов</li><li>- Денежная компенсация заработка (т.е. компенсация по инвалидности/безработице, несчастному случаю на производстве, увольнению)</li></ul>",
        zone: "rtapp-status-FullApplication"
    },
    {
        question: "Что покрывается страхованием \"домашнего имущества\"?",
        answer: "Страхование домашнего имущества покрывает повреждение личного имущества, вызванное наводнением. Единственное имущество, покрытое в подвале это стиральная и сушильная машина и морозильная камера. (Ваш котел отопления, бойлерная установка, водонагреватель и кондиционер покрыты вашим страхованием \"здания\"). С приходом наводнения переместите ваше имущество из подвала. Также неплохо было бы сделать фотографии всего до прихода наводнения, чтобы вы могли предоставить доказательство страховой организации.",
        zone: "understanding-flood-insurance profile"
    },
    {
        question: "Что такое превышение над уровнем воды?",
        answer: "Превышение над уровнем воды - это дополнительное возвышение над Базовой высотой наводнения, и оно обеспечивает гарантию того, что конструкция находится на более безопасной высоте от будущего наводнения. В Нью-Йорке это означает добавление двух футов над BFE. Помимо повышения безопасности от наводнений превышение над уровнем воды может повлечь более низкую премию страхования, что может помочь в компенсации строительных затрат.",
        zone: "mitigation-flood_vents"
    },
    {
        question: "Что такое противоток в водопроводно-канализационной системе?",
        answer: "Все сточные воды (или нечистоты), спускающиеся по вашим водоотводам и туалетам, выносятся из вашего дома к городской канализации трубами. Противоток в водопроводно-канализационной системе происходит, когда эти сточные воды не в состоянии двигаться черех трубы и текут обратно в ваш дом. Это может происходить по ряду причин.\\n",
        zone: "bwv-flood-applicants"
    },
    {
        question: "Что такое переполнение канализации?",
        answer: "Канализации предназначаются для содержания и переправки определенного количества сточных вод и нечистот. Иногда, во время необычно сильных дождей или штормовых приливов, канализация может заполниться сверх ее возможности. Когда это происходит, нечистоты могут течь обратно в ваш дом по трубе, которая соединяет ваш дом с городской канализацией.",
        zone: "mitigation-backwater_valve"
    },
    {
        question: "Чем знаменателен 1983г. для страхования от наводнения?",
        answer: "Город Нью-Йорк принял первую карту тарифов страхования от наводнения от FEMA 13 ноября 1983г. и добавил требования высоты к строительному коду для недвижимости, построенной в зоне наводнения. Так как в строительном коде не было требований по защите от наводнений до этого, недвижимость, построенная до этого имеет право на более низкий тариф. Однако конгресс позже приказал FEMA поэтапно сокращать этот низкий тариф, называемый \"субсидированный пре-FIRM\" тариф.",
        zone: "rate-calculator-pre-firm"
    },
    {
        question: "Какова средняя стоимость сертификата высотной отметки?",
        answer: "Стоимость может варьироваться от 500 до более 1000 долларов США. Попробуйте получить расценки от по крайней мере двух землеустроителей для сравнения.",
        zone: "elevation-certificates"
    },
    {
        question: "Какова средняя стоимость установки обратного клапана своими средствами?",
        answer: "Это зависит от нескольких факторов, включая расположение ваших сливных труб, глубину и расположение трубы, которая соединяет ваш дом с канализацией, существуют ли экологически опасные вещества (т.е. свинцовосодержащие краски) в помещении, где будет установлен клапан, какой тип клапана будет установлен, и т.д. Стоимость может быть такой минимальной, как 600 долларов, или больше 5000 долларов США.",
        zone: "rtapp-status-Withdrawn rtapp-status-Ineligible"
    },
    {
        question: "Что делает город, для того, чтобы помочь с проблемой канализации?",
        answer: "Этот вопрос выходит за рамки нашей компетенции, так как мы не работаем на город. NYCDEP работает над несколькими проектами в настоящее время, пытаясь решить проблемы переполнения комбинированной канализации. Те, кто в этом заинтересован, могут посетить веб-сайт DEP для получения дополнительной информации об этих программах. Одной инициативой является Программа зеленой инфраструктуры, которая включает в себя строительство сконструированных биосвейлов по сторонам дороги, которые будут поглощать первый дюйм ливневого стока от ливня и не давать ему попасть в канализацию.",
        zone: "bwv-flood-applicants"
    },
    {
        question: "В чем заключается разница между подвалом и техническим подполом? Почему это важно?",
        answer: "В целях страхования от наводнения подвал должен быть ниже уровня земли со всех четырех сторон и выше 5 футов. Если помещение ниже 5 футов, то это технический подпол. Это важно, потому что тарифы могут быть разными.",
        zone: "mitigation-fill_in_basement"
    },
    {
        question: "Что такое Закон о доступности страхования жилищного строительства (HFIAA)?",
        answer: "Закон о доступности страхования жилищного строительства от 2014г. замедлил или дал обратный ход изменениям Национальной программы по страхованию от наводнений, сделанных законом Biggert-Waters от 2012г. HFIAA восстановил \"унаследование\" и замедлил исчезновение льгот по уплате страховых взносов путем ограничения повышения премий на 18% в год для большинства зданий. Некоторая недвижимость, такая как коммерческая или с серьезными повреждениями все же может быть подвержена повышению в 25% в год.",
        zone: "understanding-flood-insurance"
    },
    {
        question: "Что такое Соглашение о безвозмездной ссуде домовладельцу (HGA)?",
        answer: "Соглашение о безвозмездной ссуде домовладельцу позволяет Центру, как администратору программы, распределять часть федеральных средств восстановления после бедствия соответствующим критериям домовладельцам и требует, чтобы домовладельцы согласились со всеми правилами и условиями, изложенными в документе.",
        zone: "rtapp-status-GrantAgreement"
    },
    {
        question: "Какова цель сертификата высотной отметки?",
        answer: "Сертификат высотной отметки - это важный документ страхования от наводнения, который предоставляет информацию о риске наводнения и устойчивости недвижимости к наводнениям. Сертификат высотной отметки может быть использован для расчета взносов вашего страхования от наводнений.",
        zone: "elevation-certificates"
    },
    {
        question: "Что это за программа, и что все это значит?",
        answer: "Если вы получили эту форму, ваш арендодатель записался в программу Инспекции устойчивости дома FloodHelpNY, направленную на укрепление прибрежных районов Нью-Йорка путем предоставления бесплатных услуг соответствующим критериям домовладельцам и их арендаторам в определенных прибрежных районах. Для получения дополнительной информации посетите сайт FloodHelpNY.org. В качестве элемента программы ваш арендодатель может соответствовать требованиям на получение бесплатного канализационного обратного клапана. Канализационный обратный клапан - это устройство, которое устанавливается на трубе, соединяющей сантехнику дома с канализацией города, чтобы сократить риск обратного потока сливных вод в ваш дом во время вызывающего наводнение события, такого как сильный дождь или шторовой прилив.",
        zone: "bwv-flood-applicants"
    },
    {
        question: "Какое важнейшее оборудование в моем доме может подвергаться риску?",
        answer: "Следующее оборудование может подвергаться риску: электрические панели и провода, механические системы, такие как ОВКВ и воздуховод, конденсаторы, отопительные системы, бойлеры и стиральное оборудование.",
        zone: "mitigation-elevate_mechanicals"
    },
    {
        question: "Какие другие ресурсы устойчивости к наводнениям имеются в моем регионе?",
        answer: "Если вы хотите сами получить канализационный обратный клапан, вы можете проконсультироваться с этим руководством для получения помощи.",
        zone: "rtapp-status-Withdrawn"
    },
    {
        question: "Что подходит под определение подвала в контексте программы?",
        answer: "Любая часть вашего дома, расположенная ниже уровня земли со всех четырех сторон (даже несколько дюймов под землей) и выше 5 футов от пола до потолка. Если пол находится на уровне земли или выше уровня земли с одной стороны, то это не подвал.",
        zone: "rate-calculator-basement"
    },
    {
        question: "Что подходит под определение основного места проживания в контексте программы?",
        answer: "Основное место проживания это дом, в котором обитатель обычно проживает большую часть времени. Дом, занимаемый арендаторами круглый год также считается основным местом проживания.",
        zone: "rtapp-preapp-primary-residence"
    },
    {
        question: "Что подходит под определение жилого помещения в контексте программы?",
        answer: "Жилое помещение - это помещение, предназначенное в основном для проживания, а не для коммерческих целей.",
        zone: "rate-calculator-number-of-residential-units rtapp-preapp-number-of-units"
    },
    {
        question: "Что я дожен подать в качестве доказательства моего дохода для моего заявления?",
        answer: "Приложите доказательство дохода для всех членов семьи старше 18 лет (в идеале это подписанная форма 1040 от IRS). Если этого нет в наличии мы примем три посдедние последние квитанции о начислении заработной платы, подтверждающие последовательные платежи или недавние справки о компенсациях; мы также можем признать альтернативную документацию.",
        zone: "rtapp-status-FullApplication"
    },
    {
        question: "Где можно заявить о проблеме с канализацией?",
        answer: "Позвоните по номеру 311",
        zone: "bwv-flood-applicants"
    },
    {
        question: "Каковы типичные временные рамки отключения воды?",
        answer: "Это зависит от дома. Мы уведомим домовладельца, как только мы узнаем. Домовладельцы не смогут спускать воду по водостоку или смывать туалет, когда сантехник будет разрезать их канализационную трубу и устанавливать новый клапан. Это необязательно будет в течение всего времени установки, так как установка скорее всего включает в себя другую работу, такую как заделка пола. Это может занять всего 8 часов.",
        zone: "bwv-flood-applicants"
    },
    {
        question: "Что такое Трехстороннее соглашение и имеется ли образец, который я мог бы просмотреть перед собранием?",
        answer: "Образец Трехстороннего соглашения будет включен в электронное сообщение подтверждения времени и даты вашего предстроительного совещания и может быть загружен здесь.",
        zone: "rtapp-status-MeetYourTeam"
    },
    {
        question: "Что такое \"ограждающие конструкции\" в доме?",
        answer: "Если ваш дом поднят, то это помещение ниже вашего жилого помещения, которое ограждено стенами (частично или полностью). Это может быть гараж или помещение, которое вы используете только в качестве склада, не жилое помещение.",
        zone: "rate-calculator-content-location rate-calculator-building-feature"
    },
    {
        question: "Что такое сертификат высотной отметки?",
        answer: "Сертификатвысотной отметки это важный документ страхования от наводнения, который описывает высоту над уровнем моря каждого этажа вашего дома или здания. Он может помочь вам правильно рассчитать ваш тариф страхования от наводнения, а также поможет оценить ваши варианты устойчивости.",
        zone: "rtapp-landing rtapp-preapp-elevation-certificate rtapp-multifamily-landing"
    },
    {
        question: "Что такое FEMA?",
        answer: "Федеральное агентство по управлению в чрезвычайных ситуациях. FEMA администрирует Национальную программу по страхованию от наводнений (NFIP) а также предоставляет неотложную помощь в чрезвычайной ситуации.",
        zone: "understanding-flood-insurance"
    },
    {
        question: "Когда вступят в силу новые карты наводнения?",
        answer: "Новая карта наводнения в данный момент разрабатывается, но однозначного ответа относительно того, когда она выйдет или станет применяться, нет. FEMA подало обновленную предварительную карту с его базовой версии 1983 года в 2013 году, значительно расширив зоны повышенного риска наводнения в Нью-Йорке, но она пересматривается после того, как городские власти Нью-Йорка оспорили ее применение в том виде, в котором она была. Сейчас карта выступает в качестве ориентировочной карты для зонирования и требований к новому строительству.",
        zone: "bwv-flood-applicants"
    },
    {
        question: "Когда домовладелец узнает продолжительность времени, необходимого для установки обратного клапана/не использования воды?",
        answer: "Он узнает до подписания Трехстороннего соглашения, которое является соглашением, подписанным сантехником, Центром и домовладельцем до установки клапана.",
        zone: "bwv-flood-applicants"
    },
    {
        question: "Когда я узнаю о статусе моего заявления?",
        answer: "Вы должны получить подтверждающее электронное сообщение после того, как вы подадите ваше приемную форму, вы узнаете о следующих шагах в течение недели после подачи заявления.",
        zone: "bwv-flood-applicants"
    },
    {
        question: "Когда я получу ответ от программы относительно моего заявления?",
        answer: "Повле того, как ваше заявление будет подано, получение ответа может занять до трех недель. Вы всегда можете проверить ваш статус по ссылке в вашем электронном сообщении.",
        zone: "rtapp-preapp-review"
    },
    {
        question: "Что произойдет, когда мое заявление будет одобрено?",
        answer: "Как только вы будете одобрены на получение услуг, мы свяжемся с вами в течение двух рабочих дней, чтобы назначить вашу Инспекцию устойчивости дома в удобное для вас время. Ваш статус всегда будет в обновленном состоянии на этой странице или вы можете позвонить нам в обчное рабочее время по телефону 646-786-0888 если у вас есть вопросы относительно заявления.",
        zone: "rtapp-status"
    },
    {
        question: "Где я могу найти подрядчика, чтобы поднять мое механичекое оборудование?",
        answer: "Управление городскими зданиями <a target=\"_blank\"href=\"http://www1.nyc.gov/site/buildings/homeowner/homeowner.page\">может дать вам совет</a> относительно того, как найти проектировщиков и подрядчиков, у которых есть опыт работы в зонах наводнения.",
        zone: "mitigation-elevate_mechanicals"
    },
    {
        question: "Где я могу найти подрядчика, чтобы произвести влажную противопаводковую защиту моего дома?",
        answer: "Вы должны проконсультироваться с высококвалифициованным инженером или архитектором до установки клапанов. Управление городскими зданиями Нью-Йорка может дать вам совет по выбору проектировщика и подрядчиков с <a target=\"_blank\" href=\"http://www1.nyc.gov/site/buildings/homeowner/homeowner.page\">опытом работы с домовладельцами</a>. Управление городскими зданиями также проволит еженедельные информационные сессии с домовладельцами, на которых ньюйоркцы могут узнать о строительных стандартах и как оценить подрядчиков.",
        zone: "mitigation-flood_vents"
    },
    {
        question: "Где я могу узнать были ли отмечены случаи противотока канализации в моем районе?",
        answer: "311 ведет учет жалоб на противоток канализации.",
        zone: "bwv-flood-applicants"
    },
    {
        question: "Где я могу оставить отзыв на программу?",
        answer: "Проверьте вашу электронную почту после консультации на предмет ссылки, чтобы оставить отзыв.",
        zone: "rtapp-status"
    },
    {
        question: "Где будет проводиться косультация об устойчивости?",
        answer: "Консультация будет проводиться в офисе в вашем районе. Проверьте вашу электронную почту, чтобы получить детали или подвоните на нашу горячую линию домовладельцев по телефону 646-786-0888.",
        zone: "rtapp-status"
    },
    {
        question: "Какие меры по предотвращению или снижению рисков действительно понизят мой страховой взнос?",
        answer: "В настоящий момент существует только четыре меры, которые повлияют на страховой взнос: поднятие дома, засыпка подвала или технического подпола и установка паводковых отверстий, отказ от вашего первого этажа, и поднятие механического оборудования из подвала. Последний вариант предоставляет наименьшее из трех сокращение страховки, но он может сэкономить вам большое количество денег в случае наводнения.",
        zone: "programs-and-benefits resiliency profile"
    },
    {
        question: "Какие вопросы я должен задавать, когда я буду искать сантехника для установки обратного клапана?",
        answer: "Расспросите об их лицензии, опыте в Нью-Йорке или в вашем районе, о любых сертификатах и рекомендациях с аналогичных проектов. Попытайтесь взять расценки у трех сантехников для сравнения.",
        zone: "mitigation-backwater_valve"
    },
    {
        question: "Кто такие аудиторы устойчивости дома? Были ли они проверены при приеме на работу?",
        answer: "Аудиторы - это квалифицированные профессионалы проектировщики, поддерживаемые лицензированными инженерами, которые были отобраны на конкурсной основе.",
        zone: "rtapp-status"
    },
    {
        question: "Кто такие консультанты программы?",
        answer: "Консультанты - это правовые или жилищные профессионалы из некоммерческих общественных организаций с опытом обслуживания домовладельцев в прибрежных районах Нью-Йорка. Консультанты обучены вопросам устройчивости и страхования от наводнения.",
        zone: "rtapp-landing rtapp-status"
    },
    {
        question: "Кто такие консультанты программы?",
        answer: "Сертифицированные жилищные консультанты с более чем пятилетним опытом работы в прибрежных районах.",
        zone: "rtapp-status-CounselingScheduled rtapp-status-AuditComplete"
    },
    {
        question: "Кто может установить канализационный обратный клапан?",
        answer: "Канализационный обратный клапан должен быть установлен только лицензированным сантехником, который произвел установку других канализационных обратных клапанов в вашем районе. Вы можете попросить рекомендации!",
        zone: "mitigation-backwater_valve"
    },
    {
        question: "Кто считается \"совместно проживающими лицами\" в заявлении на программу?",
        answer: "ВКЛЮЧАЮТСЯ: родственники/не родственники, проживающие в доме; кто-либо, временно отсутствующий из-за школы или работы; кто-либо, заключенный в тюрьму на короткое время, но который вернется обратно в дом; и военнослужащие действительной военной службы, которые вернутся в дом. Дети совместной опеки должны быть указаны, если они проводят по крайней мере 50% времени в семье. МОГУТ БЫТЬ ВКЛЮЧЕНЫ: Заявитель должен определить, какие члены семьи постоянно отсутствуют. Примером может послужить пожилой член семьи, проживающий в доме престарелых или взрослый студент, проживающий вне дома. НЕ ВКЛЮЧАЮТСЯ: приемные дети, законные попечители по родословной линии, совершеннолетние под опекой, помощники с проживанием, дети помощников с проживанием",
        zone: "rtapp-status-FullApplication"
    },
    {
        question: "Кто такой \"Со-заявитель\" в заявлении на программу?",
        answer: "Все вписанные в акт недвижимости и проживающие там ДОЛЖНЫ быть указаны как Со-заявитель. Каждый со-заявитель обязан расписаться где указано в данном Полном заявлении, в Соглашении о безвозмездной ссуде домовладельцу, в Трехстороннем соглашении и в любых других документах, которые заявитель должен подписать до получения услуг в рамках программы.",
        zone: "rtapp-status-FullApplication"
    },
    {
        question: "Кто считается \"Заявителем\" в заявлении на программу?",
        answer: "Заявитель должен быть вписан в акт недвижимости, должен быть старше 18 лет, и должен быть назначен глвой семьи (ГС) в целях определения соответствия дохода. ГС должен быть в состоянии подписать соглашения от имени всей семьи.",
        zone: "rtapp-status-FullApplication"
    },
    {
        question: "Кто предоставляет услуги программы?",
        answer: "Некоммерческий Центр для районов Нью-Йорка работает с многочисленными партнерами для предложения этих услуг. Дополнительная информация о Центре и его партнерах <a target=\"_blank\" href=\"/ru/about\">размещена здесь</a>.",
        zone: "rtapp-landing"
    },
    {
        question: "Кто предоставляет услуги программы для многосемейных зданий?",
        answer: "Некоммерческий Центр для районов Нью-Йорка работает с многочисленными партнерами для предложения этих услуг. Enterprise Community Partners, Inc. будет поддерживать многосемейных домовладельцев. Дополнительная информация о Центре <a target=\"_blank\" href=\"http://cnycn.org/\">здесь</a> и Enterprise Community Partners <a target=\"_blank\" href=\"http://www.enterprisecommunity.com\">here</a>.",
        zone: "rtapp-multifamily-landing"
    },
    {
        question: "Кто отвечает за содержание канализационного обратного клапана?",
        answer: "Вы будете отвечать за содержание канализационного обратного клапана в рабочем состоянии после завершения проекта. Инструкции в вашем техническом отчете.",
        zone: "rtapp-status-Complete"
    },
    {
        question: "Кем это программа финансируется?",
        answer: "Эта программа финансируется Секретариатом губернатора по восстановлению после ливневых бурь как части Программы Реконструкции общин восстановления Нью-Йорка.",
        zone: "rtapp-multifamily-landing. rtapp-landing"
    },
    {
        question: "Кто управляет программой FloodHelpNY?",
        answer: "FloodHelpNY возможна благодаря финансированию Секретариата губернатора по восстановлению после ливневых бурь через федеральную безвозвратную ссуду блока развития местного сообщества - восстановления после бедствия от департамента жилищного строительства и городского развития США. Программа администрируется Центром районов Нью-Йорка, некоммерческой организацией, приверженной продвижению и защите доступного домовладения в Нью-Йорке, чтобы семьи среднего и рабочего класса были в состоянии построить сильное, процветающее сообщество. Дополнительная информация о Центре на сайте cnycn.org.",
        zone: "bwv-flood-applicants"
    },
    {
        question: "Кто подписывает Соглашение о безвозмездной ссуде домовладельцу?",
        answer: "Все вписанные в акт недвижимости должны подписать Соглашение о безвозмездной ссуде домовладельцу, даже если они не указаны в заявлении в качестве заявителя или со-заявителя.",
        zone: "rtapp-status-GrantAgreement"
    },
    {
        question: "С кем я могу связаться, чтобы задать вопросы относительно моего отчета по устойчивости?",
        answer: "Вы можете позвонить нам по телефону (646) 786-0888 или послать нам сообщение по электронной почте по адресу info@floodhelp.org если у вас есть вопросы. .",
        zone: "rtapp-status-Complete"
    },
    {
        question: "С кем я могу связаться, если у меня есть вопросы относительно программы?",
        answer: "Вы можете позвонить в нашу Центральную станцию по телефону (646) 786-0888 в любое время. Вопросы относительно назначения установки будут переадресованы инспектору, если предстроительное совещание уже произошло.",
        zone: "rtapp-status-InstallationHasBeenScheduled. rtapp-status-InstallationToBeScheduled"
    },
    {
        question: "Почему я обязан иметь страхование от наводнения? Кто его требует?",
        answer: "Федеральным законом требуется, чтобы у вас было страхование от наводнения если ваш дом находится в зоне повышенного риска наводненения (AE, AO или VE) и у вас есть ипотека, поддерживаемая на федеральном уровне. (Почти все ипотеки поддерживаются на федеральном уровне). Также, если вы получили федеральную помощь при стихийных бедствиях на ремонт вашего здания или за поврежденное содержимое имущество, федеральным законом требуется, чтобы у вас было страхование от наводнения. Если вы его не приобретете, вы не будете соотвествовать требованиям на получение помощи.",
        zone: "understanding-flood-insurance"
    },
    {
        question: "Почему растут взносы за страхование от наводнения?",
        answer: "Субсидии сходят на нет и новая Карта тарифов страхования от наводнения FEMA повысит количество домов в зонах повышенного риска наводнения.",
        zone: "understanding-flood-insurance"
    },
    {
        question: "Почему люди волнуются относительно повышения стоимости страхования от наводнения?",
        answer: "Повышающиеся затраты на страхование от наводнения могут вызвать тяжелое финансовое положение для семей среднего и рабочего класса, проживающих в зоне повышенного риска, что может привести к более высокому риску лишения права выкупа закладной или выселения. Для получения дополнительной информации прочитайте полный доклад \"Повышаются приливы, повышаются тарифы\" Центра для районов Нью-Йорка <a target=\"_blank\" href=\"https://cnycn.atavist.com/risingtides\">здесь</a>.",
        zone: "programs-and-benefits"
    },
    {
        question: "Почему изменяются карты зон наводнения?",
        answer: "FEMA периодически обновляет карты, которые оно создает для зон, подверженных наводнениям по всей стране. Ныне действующие карты для Нью-Йорка были приняты в 1983г. И не отражают правильно риск наводнения на сегодняшний день.",
        zone: "understanding-flood-insurance. profile"
    },
    {
        question: "Почему вы спрашиваете меня о моем доходе?",
        answer: "Мы спрашиваем о доходе в целях приоритизации услуг. Семьи всех уровней дохода могут подать заявку на оценку их дома на подверженность наводнениям. Однако, мы автоматически будем обслуживать семьи с доходом от низкого до среднего (до 80% медианного дохода по региону для Нью-Йорка). Заявления, которые выходят за пределы данного интервала будут расположены в порядке очередности и рассмотрены в конце каждого трехнедельного периода.",
        zone: "rtapp-preapp-income"
    },
    {
        question: "Почему вы спрашиваете был ли моему дому нанесен ущерб раньше?",
        answer: "Мы страшиваем только для того, чтобы мы могли приоритизировать услуги. Дома с предыдущими повреждениями получат приоритетной обслуживание. Заявления без предыдущего ущерба также могут быть поданы и будут рассмотрены.",
        zone: "rtapp-preapp-sandy-damage"
    },
    {
        question: "Почему вы спрашиваете меня об основном местожительстве?",
        answer: "Для того, чтобы соответствовать критериям программы, собственность должна была быть вашим основным местом проживания во время урагана Сэнди",
        zone: "rtapp-preapp-sandy-ownership"
    },
    {
        question: "Почему я получил разные тарифы на страхование от наводнения от разных страховых агентов?",
        answer: "Национальная программа по страхованию от наводнений (NFIP) устанавливает тарифы страхования от наводнения и страховые брокеры не могут их изменить. Единственной причиной, по которой вы можете получить разные расценки от разных агентов может являться то, что один или оба агента сделали ошибку в том, как они описали вашу собственность или вашу зону.",
        zone: "programs-and-benefits"
    },
    {
        question: "Почему я не прошел лтбор на участие в программе?",
        answer: "Это могло произойти по многим причинам: Установка канализационного обратного клапана не принесет пользу вашему дому; у вас нет страхования от наводнения и вы в зоне наводнения АЕ; у вас нет страхования недвижимости; вы не владели домом во время урагана Сэнди; и по другим факторам. У вас есть вопросы? Позвоните нам по телефону (646) 786-0898 или пошлите нам сообщение по электронной почте по адресу info@floodhelpny.org.",
        zone: "rtapp-status-Ineligible"
    },
    {
        question: "Почему я должен предоставить больше информации, чтобы подать заявление на участие в программе?",
        answer: "Мы можем запросить больше информации если мы не можем определить на основании вашей пре-квалификации доступна ли вам эта программа. Мы также произвольно запрашиваем определенную информацию, чтобы поддерживать процесс страведливым и простым для каждого.",
        zone: "rtapp-self-certify"
    },
    {
        question: "Почему я должен предоставить информацию о доходе, если это бесплатная программа?",
        answer: "Нашим руководством по финансированию требуется, чтобы мы подтверждали доход семьи; эта информация используется для определения вашего соответствия. В настоящее время только семьи с доходом от низкого до среднего будут соответствовать критериям (ниже 80% AMI).",
        zone: "bwv-flood-applicants"
    },
    {
        question: "Почему я должен заполнить форму с информацией о доходе для подачи заявки на участие в программе?",
        answer: "FloodHelpNY ставит своей целью обслуживать домовладельцев со средним и низким доходом и их арендаторов. Мы обязаны получить информацию о доходе семьи, включая информацию о доходе арендаторов в зданиях на 2-4 квартиры, чтобы мы могли удостовериться, что мы обслуживаем только вемьи с низким доходом. Это требуется Департаментом жилищного строительства и городского развития США и Секретариатом губернатора штата Нью-Йорк по восстановлению после ливневых бурь (GOSR), которые финансируют программу.",
        zone: "bwv-flood-applicants"
    },
    {
        question: "Зачем вам мой номер телефона и адрес электронной почты для заявления на программу?",
        answer: "Нам нужен ваш номер телефона и адрес электронной почты для тог, чтобы связаться с вами относительно новостей о вашем заявлении.",
        zone: "rtapp-preapp-contact-info"
    },
    {
        question: "Зачем вам нужно знать, есть ли у меня клапан, когда я подаю заявку на программу?",
        answer: "Мы пытаемся определить, подходите ли вы для установки бесплатного обратного клапана в вашем доме.",
        zone: "bwv-flood-applicants bwv-flood-applicants rtapp-preapp-backwater-exist. rtapp-preapp-backwater-exist"
    },
    {
        question: "Зачем вам нужно знать был ли у меня противоток из канализации до Инспекции устойчивости моего дома?",
        answer: "Это дает нашим инженерам некоторые сведения о вашей ситуации до того, как они посетят ваш дом для Инспекции устойчивости дома по программе FloodHelpNY.",
        zone: "bwv-flood-applicants"
    },
    {
        question: "Зачем вам нужно знать о компенсации обратного клапана?",
        answer: "Если владельцы собственности уже получили помощь на ремонт их собственности от предыдущих программ, Программа может рассмотреть эти пособия чтобы подтвердить, что дубликата пособий не происходит до того, как будут предоставлены услуги Программы.",
        zone: "rtapp-status-FullApplication"
    },
    {
        question: "Зачем вам нужно знать о компенсации урагана Сэнди?",
        answer: "Если владельцы собственности уже получили помощь на ремонт их собственности от предыдущих программ, Программа может рассмотреть эти пособия чтобы подтвердить, что дубликата пособий не происходит до того, как будут предоставлены услуги Программы.",
        zone: "rtapp-status-FullApplication"
    },
    {
        question: "Почему для программы имеет значение, связана ли моя собственность с городской канализацией?",
        answer: "Целью программы является сокращение риска противотока канализации в дома во время наводнения, конкретно вызванного переливом из общесплавной канализации. Перелив из общесплавной канализации происходит, когда городская канализация переполняется сверх ее возможности. Большинство зданий соединено с городской канализацией, но есть некоторые дома, которые все еще могут иметь отстойник. Если ваш дом не соединен с городской канализацией, но вы испытываете противоток канализации в вашем доме, то причина не в переливе из общесплавной канализации, и вы должны проконсультироваться с лицензированным профессионалом проектировщиком и/или подрядчиком, чтобы определить причину и устранить проблему.",
        zone: "rtapp-preapp-sewer"
    },
    {
        question: "Почему основное место проживания имеет значение для соответствия требованиям на получение обратного клапана?",
        answer: "Программа направлена на обслуживание домов, предоставляющих жилье для ньюйоркцев, в отличие от загородных летних домов.",
        zone: "rtapp-preapp-primary-residence"
    },
    {
        question: "Почему инженеру нужно оценить всю территорию моей собственности?",
        answer: "Инженеру понадобится сделать замеры вокруг ваших сантехнических приборов, фотографии места, где будет устанавливаться клапан, и понаблюдать за территорией вокруг вашего дома, чтобы определить, целесообразна ли установка клапана. Это поможет сантехнику подготовиться к установке путем предоставления ясной картины строительной площади.",
        zone: "rtapp-status-FeasibilityReview"
    },
    {
        question: "Почему программа обратного клапана запрашивает сопутствующую документацию?",
        answer: "Для того, чтобы соответствовать требованиям федерального финансирования мы подтверждаем доход каждого заявителя, страхование от наводнения и инормацию арендатора.",
        zone: "rtapp-status-IntakeComplete"
    },
    {
        question: "Почему страхование недвижимости не покрывает наводнения?",
        answer: "Конгресс создал NFIP в 1968г. так как частные страховые компании на покрывали ущерб от наводнения. Потери были слишком большими.",
        zone: "resiliency"
    },
    {
        question: "Почему установка обратного клапана не меняет мой страховой тариф?",
        answer: "Программой NFIP в настоящее время не предписано позволять сокращение взноса за сухие меры противопаводковой защиты, под которые попадают обратные клапаны. Например, поднятие вашего дома дает вам скидку так как вы поднимаете ваш нижний этаж выше BFE. Обратный клапан снижает, но не избавляет от риска наводнения.",
        zone: "mitigation-backwater_valve"
    },
    {
        question: "Почему установка канализационного обратного клапана не меняет мой страховой тариф?",
        answer: "Национальной программой по страхованию от наводнений в настоящее время не предписано позволять сокращение взноса за сухие меры противопаводковой защиты, такие как обратные клапаны. И наоборот, результатом влажных меры противопаводковой защиты, таких как поднятие вашего дома, будет скидка страхования от наводнения, так как ваш нижний этаж поднят выше Базовой высоты наводения (BFE). Канализационный обратный клапан может снизить риск наводнения, но он не удалит его полностью.",
        zone: "bwv-flood-applicants"
    },
    {
        question: "Почему снижение риска наводнения не всегда снижает мой тариф страхования от наводнения?",
        answer: "Некоторые меры по предотвращению и снижению рисков могут сократить ущерб и затраты на очистку после наводнения, но они могут не изменить данные, использованные для расчета риска наводнения вашего дома.",
        zone: "understanding-flood-insurance"
    },
    {
        question: "Почему копия моего Соглашения о безвозмездной ссуде домовладельцу (HGA) была послана в Секретариат губернатора по восстановлению после ливневых бурь?",
        answer: "Так как финансирование программы осуществляется через Программу Реконструкции общин восстановления Нью-Йорка (NYRCR) в составе Секретариата губернатора штата Нью-Йорк по восстановлению после ливневых бурь (GOSR), Секретариат губернатора по восстановлению после ливневых бурь требует копию соглашения.",
        zone: "rtapp-status-GrantAgreement"
    },
    {
        question: "Почему заявление на установку обратного клапана такое длинное?",
        answer: "Так как это программа, финансируемая на федеральном уровне, мы обязаны собрать документацию со всех домовладельцев, кто подал заявления. Не волнуйтесь, ваша информация будет надежно сохранена. Обратитесь к нашей политике конфиденциальности.",
        zone: "rtapp-status-FullApplication"
    },
    {
        question: "Почему Центр подписывает Трехстороннее соглашение?",
        answer: "Трехстороннее соглашение подписывается Домовладельцем, Центром и сантехником. Оно накладывает обязательства перед друг другом на все три стороны и устанавливает прямое соглашение между сантехником и домовладельцем. Вы сможете увидеть оформленный экземпляр Трехстороннего соглашения на вашей странице статуса как только все стороны его подпишут.",
        zone: "rtapp-status-MeetYourTeam"
    },
    {
        question: "Почему виды страхования имеют значение?",
        answer: "Программой требуется рассмотреть предыдущие пособия на ремонт, чтобы удостовериться, что дублирования льгот не происходит.",
        zone: "rtapp-status-FullApplication"
    },
    {
        question: "Почему неясно, когда новые карты наводнений вступят в силу?",
        answer: "Город Нью-Йорк обжаловал Предварительную карту тарифов страхования от наводнения FEMA в июне 2015г., аргументируя это тем, что они переоценили риск наводнения во всех пяти районах Нью-Йорка. В октябре 2016г. FEMA объявило, что оно приняло городскую жалобу на предварительные карты наводнений и что оно начнет новый процесс пересмотра городских карт наводнений. Выпуск новых пересмотренных карт займет у FEMA по крйней мере три или четыре года. Дополнительная информация <a href=\"http://www1.nyc.gov/site/floodmaps/index.page\" target=\"_blank\">здесь</a>.",
        zone: "understanding-flood-insurance profile"
    },
    {
        question: "Почему я должен беспокоиться об устойчивости?",
        answer: "Когда происходит стихийное бедствие, плохо скоординированный ответ штата сотрудников может подвергнуть безопасность и благополучие жителей риску, и повлечь ненужные затраты, трудности и потенциальную юридическую отвественность для домовладельцев. Организации доступного жилья сталкиваются с уникальными трудностями во время бедствий. Будучи не в состоянии переселить жителей, организации жилищного строительства полагаются на бесперебойную эксплуатацию их зданий или по крайней мере быстрого возврата к обслуживанию. За дополнительной нформацией обратитесь <a target=\"_blank\" href=\"https://s3.amazonaws.com/KSPProd/ERC_Upload/0100738.pdf\">сюда</a>.",
        zone: "rtapp-multifamily-landing"
    },
    {
        question: "Почему эти районы были выбраны на программу установки обратного клапана?",
        answer: "Сообщества, которые решили участвовать и выделять фонды на Инспекцию устойчивости дома подходят под критерии этой программы; все районы были частью Программы Реконструкции общин Восстановления Нью-Йорка.",
        zone: "rtapp-multifamily-landing rtapp-landing"
    },
    {
        question: "Почему изменение BFE изменит мое страхование от наводнения?",
        answer: "Большинство полисов страхования от наводнения рассчитываются путем сравнения высоты дома с Базовой высотой наводнения в зонах повышенного риска наводнения. Чем ниже высота дома по сравнению с BFE, тем выше будет тариф страхования от наводнения. Высота измеряется по отношению к уровню моря, а не уровню земли.",
        zone: "understanding-flood-insurance"
    },
    {
        question: "Снизит ли обратный клапан мое страхование от наводнения?",
        answer: "Нет. Однако, он может быть фактором, который вы можете учесть при рассмотрении суммы покрытия содержимого имущества вашего полиса страхования от наводнения. Обратный клапан может повлиять на ваш взнос страхования имущества.",
        zone: "rtapp-status-FullApplication"
    },
    {
        question: "Понизит ли установка канализационного обратного клапана мой взнос страхования от наводнения?",
        answer: "Канализационные обратные клапаны не имеют никакого эффекта на взносы страхования от наводнения но они могут сэкономить вам сотни или даже тысячи долларов ущерба в случае наводнения. Он также может защитить драгоценные и памятные вещи, зранимые в вашем подвале от повреждения и предохранить ваш подвал от сточных вод.",
        zone: "bwv-flood-applicants"
    },
    {
        question: "Будут ли домовладельцы, которые сейчас находятся в зоне х, переходящие в ориентировочную зону х, или находящиеся сейчас в х переходящие в АЕ обязаны иметь страхование от наводнения?",
        answer: "Только домовладельцы находящиеся сейчас в зоне АЕ будут обязаны иметь страхование от наводнения чтобы соответствовать критериям.",
        zone: "bwv-flood-applicants"
    },
    {
        question: "Нужно ли будет мне раскрывать личную информацию по время консультации?",
        answer: "Ваш консультант может спросить о ваших финансах и о другой личной информации, чтобы вы могли извлечь как можно больше из оценки вашего дома. Мы не будем делиться вашей информацией без вашего разрешения.",
        zone: "rtapp-status"
    },
    {
        question: "Нужно ли будет мне предоставить больше информации для установки обратного клапана?",
        answer: "Нам понадобится сопровождающая информация для подтверждения ежегодного дохода всех членов домовладения старше 18 лет. Обратитесь к форме на следующей странице относительно видев документов, которые мы можем принять.\\n",
        zone: "bwv-flood-applicants"
    },
    {
        question: "Понизит ли обратный клапан мое страхование недвижимости?",
        answer: "В зависимости от обстоятельств. Некоторые страховые компании предлагают скидки на страхование недвижимости. Однако, специальное покрытие от противотока из канализационного колектора асто должно быть добавлено к полису стрхования жилища в форме дополнительного условия страхования от противотока из канализационного колектора. Такое дополнительное условие обычно стоит от 50 до 70 долларов США в год и может быть таким высоким как 175 долларов США, в зависимости от франшизы и сколько покрытия вам будет нужно. Если ваше страхование недвижимости не предлагает скидку, вы еще можете предпринять важный шаг для предотвращения ущерба от противотока из канализации в будущем и дорогостоящего ремонта, который может его сопровождать.",
        zone: "rtapp-status-Complete rtapp-status-Complete rtapp-status-InstallationPrepare rtapp-status-InstallationPrepare"
    },
    {
        question: "Будет ли все приведено в то же состояние, как это было до установки обратного клапана (газон/полы)?",
        answer: "По мере возможности сантехник приведет рабочий участок в пред-строительное состояние. Возможно некоторые изменения будут необходимы (например, необходимо, чтобы был доступ к клапану для его содержания, поэтому может быть сделано отверстие для клапана. Если он будет установлен снаружи, то замена любой части газона, которая должна была быть вырезана, должна быть частью контракта.",
        zone: "bwv-flood-applicants"
    },
    {
        question: "Предоставит ли инженер комментарии во время аудита?",
        answer: "Вашей инженерной команде потребуется время для анализа информации, собранной в вашем доме, чтобы закончить ваши индивидуальные рекомендации. Поэтому вы не сможете получить комментарии на месте. Но не волнуйтесь! Ваш консультант сможет ответить на любые имеющиеся у вас вопросы о вашем отчете во время вашей встречи.\\n",
        zone: "rtapp-status-AuditScheduled"
    },
    {
        question: "Будут ли инженеры, сантехники или инспекторы когда-либо связываться с домовладельцем напрямую относительно обратного клапана? И если да, то когда?",
        answer: "Инспектор свяжется с вами, чтобы назначить установку обратного клапана. Если заявителю будет нужно перенести их установку, он должен связаться с инспектором.",
        zone: "bwv-flood-applicants"
    },
    {
        question: "Повлияют ли результаты рассмотрения целесообразности на мое заявление на обратный клапан?",
        answer: "Это возможно. Во время рассмотрения целесообразности ваш инженер оценит, принесет ли вашему дому пользу установка обратного клапана и позволяют ли условия вашего дома установить клапан. Если он определит, что установка обратного клапана не принесет пользу вашему дому, или что условия вашего дома не позволяют установить клапан, вы не будете соотвествовать критериям на установку обратного клапана.",
        zone: "rtapp-status-FeasibilityReview"
    },
    {
        question: "Будет ли вода в моем доме отключена во время установки обратного клапана? Если да, как надолго?",
        answer: "Да, воду в доме нужно будет отключить во время установки обратного клапана. Продолжительность зависит от дома. Мы уведомим домовладельца как только мы узнаем. Домовладельцы не смогут спускать воду по водостоку или смывать туалет когда сантехник будет разрезать их канализационную трубу и устанавливать новый клапан. Это необязательно будет в течение всего времени установки, так как установка скорее всего включает в себя другую работу, такую как заделка пола. Это может занять всего восемь часов.",
        zone: "rtapp-status-InstallationHasBeenScheduled"
    },
    {
        question: "Вы говорите, что технический подпол \"около\" 4 футов в высоту. Можете ли вы быть точнее?",
        answer: "Точное описание технического подпола это помещение не более 5 футов в высоту, которая измеряется от пола технического подпола до пола (так где лежит ковер) следующего этажа. Измерение в 5 футов включает толщину пола сверху. Если мы предположим, что пол примерно с фут толщиной, то это оставляет высоту от пола до потолка примерно в 4 фута для соответствия определению.",
        zone: "rate-calculator-building-feature"
    },
    {
        question: "Могу ли я получить большее покрытие, чем предлагаемое NFIP?",
        answer: "Да, частные страховые компании предлагают дополнительное страхование превышающее <a href=\"https://www.fema.gov/flood-insurance\" target=\"_blank\" rel=\"noopener noreferrer\">NFIP</a> лимит. Позвоните вашему страховому агенту или брокеру чтобы обсудить какие имеются варианты для защиты вашего бизнеса от финансовых затрат на ущерб от наводнения.",
        zone: "small-business"
    },
    {
        question: "Нужно ли страхование от наводнения моему бизнесу?",
        answer: "Ущерб от наводнения может произойти в любое время, даже если ваш бизнес не расположен в зоне высокого риска наводнения. Арендуете ли вы или владеете помещением вашего бизнеса, мы рекомендуем вам приобрести полис страхования от наводнения через <a href=\"https://www.fema.gov/flood-insurance\" target=\"_blank\" rel=\"noopener noreferrer\">NFIP</a>",
        zone: "small-business"
    },
    {
        question: "Сколько времени занимает получение страхования от наводнения?",
        answer: "Существует 30-дневный период ожидания до того, как ваш полис страхования от наводнения вступит в силу. Важно приобрести полис страхования от наводнения до того, как наводнение кажется на вашем бизнесе.",
        zone: "small-business"
    },
    {
        question: "Какую сумму покрытия от наводнения я могу получить?",
        answer: "Коммерческий полис страхования от наводнения через <a href=\"https://www.fema.gov/flood-insurance\" target=\"_blank\" rel=\"noopener noreferrer\">NFIP</a> предосталяет до 500000 долларов США в покрытии зданий и до 500000 долларов США для покрытия содержимого имущества.",
        zone: "small-business"
    },
    {
        question: "Что я должен знать относительно вычитаемой франшизы?",
        answer: "Выбор правильной вычитаемой франшизы важен. Более высокая франшиза снизит стоимость вашей страховки, но это также снизит сумму денег, которые вы получите, если подадите иск.",
        zone: "small-business"
    },
    {
        question: "Где я могу приобрести страхование от наводнения?",
        answer: "Вы можете приобрести страхование от наводнения позвонив вашему страховому агенту или брокеру. Они смогут помочь вам приобрести полис страхования от наводнения напрямую через <a href=\"https://www.fema.gov/flood-insurance\" target=\"_blank\" rel=\"noopener noreferrer\">NFIP</a>.",
        zone: "small-business"
    },
    {
        question: "Почему я должен приобрести страховку от наводнения в то время как FEMA предлагает помощь при стихийных бедствиях?",
        answer: "FEMA не предлагает индивидуальные безвозмездные ссуды бизнесам. Без страхования от наводнения вам придется платить за любой ущерб из вашего кармана или быть вынужденным брать заем.",
        zone: "small-business"
    },
    {
        question: "Будет ли мой полис коммерческого страхования покрывать наводнение?",
        answer: "Наводнение обычно не покрывается полисом коммерческого страхования или полисом владельца безнеса. Вам нужно будет приобрести отдельный полис страхования от наводнения, чтобы защитить ваш бизнес от финансовых затрат ущерба от наводненеия.",
        zone: "small-business"
    },
    {
        question: "Что покрывает страхование от наводнения?",
        answer: "Страхование от наводнения помогает защитить ваш безнес от финансовых затрат на ущерб от наводнения. Страхование от наводнения может покрыть сумму повреждения здания и содержимого, вызванного наводнением.",
        zone: "small-business"
    },
    {
        question: "Какая сумма будет мне возмещена если моему дому будет нанесен ущерб?",
        answer: "Если вы владеете домом на одну семью, важно знать, что если вы застрахуете ваш дом на не менее чем 80% его возмещаемой стоимости (RCV), то вы получите только то, что называется \"фактической стоимостью наличными\" (ACV) от ущерба от наводнения когда вы подадите иск. Это означает, что вместо того, чтобы получить страховую сумму на затраты по ремонту дома, вы только получите компенсицию стоимости с учетом обесценения каких-либо структурных частей - перекладин, панелей для внутренних стен и потолков, полов, и т.д.",
        zone: "understanding-flood-insurance"
    },
    {
        question: "Какова новая методология расценок при Оценке риска 2.0?",
        answer: "FEMA разработало систему, которую трудно понять. Вот что мы знаем: Используя данные из источников FEMA и тмеющихся коммерческих данных третьих сторон, FEMA оценит риск наводнения для каждой недвижимости. Они сделают это путем рассмотрения таких вещей, как дистанция до источника наводнения, потенциальные типы наводнения, заполненность здания, тип строительства, тип фундамента, высотная нулевая отметка, высота первого этажа и предыдущие иски страхования от наводнения. Другие важные факторы, которые нужно учесть, включают затраты на восстановительные работы.",
        zone: "insurance-shopping"
    },
    {
        question: "Принимает ли Оценка риска 2.0 во внимание предыдущие иски?",
        answer: "Да, история исков недвижимости отразится на взносах страхования от наводнения. Но важно знать, что FEMA не будет учитывать историю предыдущих исков для начального расчета тарифа. Предыдущие иски будут учитываться только когда (и если) недвижимость подаст новый иск после того, как Оценка риска 2.0. вступит в силу. Когда это произойдет FEMA учтет все иски за последние 20 лет и обновит оценку в момент ежегодного обновления полиса.",
        zone: "insurance-shopping"
    }
];

