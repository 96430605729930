import carpet from '../images/carpet.svg';
import piping from '../images/piping.svg';
import plumbing from '../images/plumbing.svg';
import temperatureInside from '../images/temperature-inside.svg';
import bacteria from '../images/bacteria.svg';
import fillInBasement from '../images/fill-in-basement.svg';
import elevate from '../images/elevate.svg';
import elevateMechanicals from '../images/elevate-mechanicals.svg';
import abandonFirstFloor from '../images/abandon-first-floor.svg';
import floodVents from '../images/flood-vents.svg';
import backwaterValve from '../images/backwater-valve.svg';

export default {
  meta: {
    title: 'Flood Retrofits | Protect Your Property | FloodHelpNY.org',
    description: `Flood retrofits can help you lower your risk of flooding. Here's what you need to know.`,
    url: 'https://www.floodhelpny.org/en/flood-retrofits',
    imageAlt: 'coastal waves and New York City'
  },
  learnMore: 'Learn More',
  title: 'Flood Retrofits: Protect Your Property',
  pageSubtitle:
    'There are a number of strategies you can take to lower your risk of flooding. Some are major alterations, and others can be quick and simple solutions to reduce potential damage from flood waters.',
  subtitle:
    'There are a number of strategies you can take to lower your risk of flooding. Some are major alterations, and others can be quick and simple solutions to reduce potential damage from flood waters.',
  overview: {
    title: 'Overview',
    body:
      'Our coastal neighborhoods are a big part of what makes New York City unique. The city’s 520-mile coastline varies dramatically from one neighborhood to the next — dotted with bungalows in some areas, densely packed with row homes in others. This variation in density and housing type makes a one-size-fits-all approach to flood resiliency unrealistic for New York City. Luckily, there are many different ways to fortify our neighborhoods and strengthen our coast.'
  },
  options: {
    title: 'Mitigation Options',
    body:
      'Some resiliency options cost more than others, some require more effort to adopt, and some provide greater benefits than others. All of these tradeoffs should be considered when choosing which option is right for you. As of now, there are only a handful of measures that have an effect on insurance premiums: elevation; filling in a basement or crawl space (and wet floodproofing, as needed); installing flood vents; and raising mechanical equipment out of the basement (this option often provides the smallest insurance reduction, but it can save you a great deal of money in the event of a flood). There are several other actions you can take that do not lower insurance rates, but can help protect your home from flood damage. Notably, any of these measures can reduce premiums regardless of flood zone.',
    list: [
      {
        title: 'Fill in your basement',
        body: "Reduces damage to your home's structural foundation.",
        image: fillInBasement,
        imageAlt: 'Fill in your basement',
        link: 'fill-in-basement'
      },
      {
        title: 'Elevate your property',
        body:
          'Puts your property completely above predicted flood water levels.',
        image: elevate,
        imageAlt: 'Elevate your property',
        link: 'elevate'
      },
      {
        title: 'Raise your mechanicals',
        body: 'Protects your home’s critical systems.',
        image: elevateMechanicals,
        imageAlt: 'Raise your mechanicals',
        link: 'elevate-mechanicals'
      },
      {
        title: 'Abandon your first floor',
        body: 'This effectively raises the base elevation of your home.',
        image: abandonFirstFloor,
        imageAlt: 'Abandon your first floor',
        link: 'abandon-first-floor'
      },
      {
        title: 'Install flood vents',
        body:
          'Reduce the risk of damage from flood water by allowing it to flow through and drain.',
        image: floodVents,
        imageAlt: 'Install flood vents',
        link: 'flood-vents'
      },
      {
        title: 'Install a backwater valve',
        body:
          'A backwater valve is designed to keep raw sewage out of your home in the event of sewer overflow.',
        image: backwaterValve,
        imageAlt: 'Install a backwater valve',
        link: 'backwater-valve'
      }
    ]
  },
  retrofitting: {
    title: 'City planning retrofitting guide',
    body:
      'The most comprehensive analysis of retrofit options available for buildings in the New York City floodplain to date.',
    view: 'View'
  },
  budget: {
    title: 'Mitigation on a Budget',
    subtitle:
      'There are several other actions you can take that do not lower insurance rates, but can help reduce flood damage.',
    list: [
      {
        header: 'Replacing carpet with tiles',
        body:
          'Your bathroom is waterproof — why can’t your basement be, too? If your basement has tiles instead of carpet, clean up will be easier and you’ll prevent a few inches of flood water from doing major damage.',
        image: carpet
      },
      {
        header: 'Install backwater preventers',
        body:
          'Relatively inexpensive and easy to install, backflow preventers stop sewer and stormwater from entering your home through sinks, toilets, and bathtubs when the systems surrounding your home are overwhelmed with flood water.',
        image: piping
      },
      {
        header: 'Install a sump pump',
        body:
          'Sump pumps help to drain seepage and water entering buildings through cracks in foundations, porous surfaces and other penetrations such as underground conduits.',
        image: plumbing
      },
      {
        header: 'Floodproof the interior of building systems',
        body:
          'This calls for sealing buildings to keep water out using passive or active mechanical devices such as concrete ring walls around a boiler, floodproof doors or barriers.',
        image: temperatureInside
      },
      {
        header: 'Install flood damage resistant materials',
        body:
          'Materials such as non-paper-faced gypsum board and terrazzo tile flooring for building materials and furnishings reduces flood damage and speeds post-flood clean up. This option can also include the use of floodproof cabinets and replacing wooden items such as doors with metal or other surfacing water-resistant materials.',
        image: bacteria
      }
    ]
  }
};
