import ApiCommunicator from './APICommunicator';

const SalesforceCommunicator = {
  registerEmailForUpdates: (apiUrl, payload) => {
    return ApiCommunicator.post(`${apiUrl}email_registration/updates`, payload);
  },
  submitIntakeForm: (apiUrl, answers) => {
    return ApiCommunicator.post(`${apiUrl}kase`, answers);
  },
  getAuditApplication: (apiUrl, id) => {
    return ApiCommunicator.get(`${apiUrl}kase/${id}`, {});
  },
  // This API call is used in AuditRate and AuditRateDifference to update the
  // application based on the rate calculator API results.
  updateAuditApplication: (apiUrl, id, data) => {
    return ApiCommunicator.put(`${apiUrl}kase/${id}`, {
      property_details: data
    });
  },
  postAuditApplication: (apiUrl, id, data) => {
    return ApiCommunicator.formDataPost(`${apiUrl}bwv_application/${id}`, data);
  },
  getPropertyInfo: (apiUrl, id) => {
    return ApiCommunicator.get(`${apiUrl}property/${id}`, {});
  },
  // This API call is used in RateCalculator and RateHeader components and will
  // be ignored until we redo the rate calc stuff
  updateProperty: (apiUrl, id, updates) => {
    return ApiCommunicator.put(`${apiUrl}property/${id}`, {
      property_details: updates
    });
  },
  createProperty: (apiUrl, propertyInfo, propertyDetails) => {
    return ApiCommunicator.post(`${apiUrl}properties`, {
      property_info: propertyInfo,
      property_details: propertyDetails
    });
  },
  getAuditCount: (apiUrl) => {
    return ApiCommunicator.get(`${apiUrl}audit_count`);
  }
};

export default SalesforceCommunicator;
