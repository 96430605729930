export default {
  title: 'Dakò ak Tèm epi Kondisyon yo',
  disclaimerOne: 'Nou pa ka garanti presizyon zòn ou an',
  disclaimerTwo: 'Estimasyon asirans kont inondasyon ou an gendwa pa presi',
  links: {
    sentenceOpener: 'Aprann plis nan ',
    termsLinkText: 'Tèm ak Kondisyon yo',
    termsLinkUrl: '/ht_HT/terms',
    conjunction: ' ak ',
    privacyLinkText: 'Politik sou Vi Prive',
    privacyLinkUrl: '/ht_HT/privacy',
    submit: 'Lè ou klike sou soumèt ou dakò ak tè epi kondisyon nou yo'
  },
  navigation: {
    agree: 'Mwen Dakò',
    disagree: 'Mwen Pa Dakò'
  }
};
