import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors, borderRadius } from '../../styles/variables';
import Button from '../ui/Button';

const Container = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  text-align: center;
  width: 75%;
  position: relative;
  margin: 20vh auto;
  padding: 4rem;
  border-radius: ${borderRadius};
  background-color: white;

  @media (max-width: 540px) {
    margin: 0;
    width: auto;
    height: 100%;
    overflow-y: scroll;
  }
`;

const Header = styled.h4`
  text-align: center;
`;

const ErrorMessage = styled.p`
  margin-bottom: 1em;
`;

const AddressLookupError = ({ provider, unsetErrorState }) => {
  return (
    <Container>
      <Header>{provider.title}</Header>
      <ErrorMessage>{provider.error}</ErrorMessage>
      {unsetErrorState && (
        <Button onClick={unsetErrorState} bgColor={colors.resilientBlue}>
          {provider.tryAgain}
        </Button>
      )}
    </Container>
  );
};

AddressLookupError.propTypes = {
  provider: PropTypes.object.isRequired,
  unsetErrorState: PropTypes.func
};

AddressLookupError.defaultProps = {
  unsetErrorState: null
};

export default AddressLookupError;
