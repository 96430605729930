import addressInput from './AddressInput.ht_HT';

export default {
  meta: {
    title: 'Eksplore Kat Zòn Inondasyon NYC yo',
    url: 'https://www.floodhelpny.org/ht_HT/explore-map',
    description:
      'Eksplore kat zòn inondasyon NYC yo pou konprann risk aktyèl ak demen ou yo',
    imageAlt: 'kat ki ka eksplore'
  },
  loadingText: '',
  placeholder: 'Antre adrès ou',
  currentMaps: 'Kat Aktyèl yo',
  futureMaps: 'Kat Konsiltatif',
  addressInput,
  legend: {
    label: 'KLE:',
    minimalHazard: 'Risk minimòm',
    moderateRisk: 'Risk modere',
    highRisk: 'Gwo risk',
    veryHighRisk: 'Pi gwo risk (inondasyon ak vag)',
    copy:
      'Kat lan montre kat inondasyon aktyèl ak konsiltatif yo pou Vil New York jan sa pwopoze selon ',
    linkCopy: 'FEMA',
    linkUrl: 'https://www.fema.gov'
  },
  showPopup: 'Montre zòn inondasyon an',
  hidePopup: 'Kache zòn inondasyon an',
  errorText:
    'Waw, li sanble Google gen kèk pwoblèm pou li done yo sou kat nou an! Tanpri eseye ankò pita.'
};
