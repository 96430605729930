import React from 'react';
import styled from 'styled-components';
import { useProvider } from '../helpers/hooks';
import { colors } from '../styles/variables';
import { Section } from './ui/StyledResourcePageElements';

const Stat = styled.h1`
  text-align: center;
  color: ${colors.resilientBlue};
`;

const Description = styled.p`
  max-width: 900px;
  text-align: center;
  font-size: 2rem;
  margin: 2rem auto;

  @media (max-width: 500px) {
    font-size: 1.5rem;
  }
`;

const AboutAuditProgramStats = () => {
  const provider = useProvider('aboutAuditProgramStats');

  return (
    <Section>
      <Stat>{provider.header}</Stat>
      <Description dangerouslySetInnerHTML={{ __html: provider.copy }} />
    </Section>
  );
};

export default AboutAuditProgramStats;
