export default {
  whatYouToldUs: 'SA OU TE DI NOU',
  property: 'Pwopriyete',
  basicInfo: 'Enfòmasyon Debaz',
  income: 'Revni:',
  household_size: 'Dimansyon Fwaye an:',
  contactInfo: 'Enfòmasyon Kontak',
  name: 'Non:',
  email: 'Imèl:',
  homePhone: 'Telefòn Kay:'
};
