import lookupErrorText from './LookupErrors.ht_HT';
import arrow from '../images/arrow.svg';

export default {
  icon: arrow,
  loadingText: '',
  minimalHazardZone: 'Adrès sa se yon zòn inondasyon ki gen risk minimòm.',
  bfe: '" Elevasyon pou Inondasyon Debaz (BFE)',
  button: 'Aprann Plis',
  X: 'zòn inondasyon ki gen risk modere',
  AE: 'zòn gwo risk',
  VE: 'zòn trè gwo risk',
  None: 'zòn risk minimòm',
  tryAnotherAddress: 'Tanpri eseye yon lòt adrès.',
  errorText:
    'Nou dezole. Nou pa t kapab jwenn enfòmasyon pou adrès ou te antre an. Tanpri verifye adrès lan oswa eseye ankò pita. Si ou santi ou te resevwa mesaj sa a nan erè, tanpri rele Platfòm pou Pwopriyetè nou an nan 646-786-0888.',
  currentlyIn: 'Pwopriyete sa a aktyèlman nan yon ',
  changingTo: ', men li gen chans pou l chanje nan yon ',
  stayingAt: ', e li gen chans pou l rete nan menm nivo risk lan.',
  ...lookupErrorText
};
