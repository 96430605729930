import BuildingSizes from '../constants/BuildingSizes';
import BuildingTypes from '../constants/BuildingTypes';
import ContentLocations from '../constants/ContentLocations';
import BuildingFeatures from '../constants/BuildingFeatures';
import bungalow from '../images/bungalow.svg';
import detached from '../images/detached.svg';
import semiDetached from '../images/semi-detached.svg';
import attached from '../images/attached.svg';
import midRiseWalkUp from '../images/midrise-walk-up.svg';
import midRiseElevator from '../images/midrise-elevator.svg';
import unknown from '../images/unknown.svg';
import basement from '../images/basement-cellar.svg';
import crawlspaceWithFloodVents from '../images/crawl-space-with-flood-vents.svg';
import crawlspaceWithoutFloodVents from '../images/crawl-space-without-flood-vents.svg';
import enclosure from '../images/enclosure.svg';
import mobileHome from '../images/mobile-home.svg';
import subgradeCrawlspace from '../images/sub-grade-crawl-space.svg';
import none from '../images/cross.svg';

export default {
  navigation: {
    next: 'Pwochen',
    previous: 'Anvan'
  },
  faq: {
    header: 'OU GENDWA AP PANSE:'
  },
  breadcrumbs: {
    back: 'Tounen nan Pwofil lan'
  },
  questions: {
    numberOfResidentialUnits: {
      title: 'numberOfResidentialUnits',
      faqZone: 'rate-calculator-number-of-residential-units',
      question: 'Konbyen apatman rezidansyèl batiman ou an genyen?',
      breadcrumb: 'Dimansyon Batiman an',
      responseField: {
        options: [
          {
            value: BuildingSizes.SINGLE_FAMILY,
            display: 'Kay pou yon grenn fanmi'
          },
          { value: BuildingSizes.TWO_TO_FOUR_FAMILY, display: '2-4 inite' },
          { value: BuildingSizes.MULTI_FAMILY, display: '5 apatman oswa plis' }
        ],
        invalidAnswers: [BuildingSizes.MULTI_FAMILY],
        invalidCopy:
          'Kalkilatè tarif sa a bon sèlman pou pwopriyete 1-4 fanmi. ',
        invalidLinkCopy: 'Klike la pou tounen nan akèy lan.',
        invalidLink: '/',
        invalidCopyAfterLink: '',
        alertAnswers: []
      }
    },
    buildingType: {
      title: 'buildingType',
      faqZone: 'rate-calculator-building-type',
      question: 'Kijan ou t ap pi byen dekri kalite batiman ou an?',
      breadcrumb: 'Kalite Batiman an',
      responseField: {
        key: 'building_type',
        options: [
          {
            value: BuildingTypes.BUNGALOW,
            display: 'Bungalow',
            image: bungalow
          },
          {
            value: BuildingTypes.DETACHED,
            display: 'Detache',
            image: detached
          },
          {
            value: BuildingTypes.SEMI_DETACHED,
            display: 'Mi-detache',
            image: semiDetached
          },
          {
            value: BuildingTypes.ATTACHED,
            display: 'Atache',
            image: attached
          },
          {
            value: BuildingTypes.MID_RISE_WALK_UP,
            display: 'Batiman tay mwayen',
            image: midRiseWalkUp
          },
          {
            value: BuildingTypes.MID_RISE_ELEVATOR,
            display: 'Batiman ki gen wotè mwayen',
            image: midRiseElevator
          },
          {
            value: BuildingTypes.UNKNOWN,
            display: 'M pa konnen',
            image: unknown
          }
        ]
      }
    },
    preFirm: {
      title: 'preFirm',
      faqZone: 'rate-calculator-pre-firm',
      breadcrumb: 'Pre/Post FIRM',
      preFirmQuestion:
        'Li sanble pwopriyete sa a te bati anvan 16 novanm 1983. Èske sa kòrèk?',
      postFirmQuestion:
        'Li sanble pwopriyete sa a te bati oswa amelyore nan yon fason konsiderab apre 16 novanm 1983. Èske sa kòrèk?',
      responseField: {
        options: [
          { value: 'yes', display: 'Wi' },
          { value: 'no', display: 'Non' }
        ],
        invalidAnswers: [],
        invalidCopy: '',
        invalidLinkCopy: '',
        invalidLink: '#',
        invalidCopyAfterLink: '',
        alertAnswers: []
      },
      disclaimer:
        'Piske premye kat zòn inondasyon an te adopte nan Vil New York nan dat 16 novanm 1983, te gen tarif tanporè ki sibvansyone pou pwopriyete ki bati anvan lè sa.'
    },
    severeRepetitiveLoss: {
      title: 'severeRepetitiveLoss',
      faqZone: 'rate-calculator-severe-repetitive-loss',
      breadcrumb: 'Pèt Grav ki Repete',
      floodDamageQuestion:
        'Èske pwopriyete sa a te gen domaj nan inondasyon anvan sa a?',
      severeRepetitiveLossQuestion:
        'Èske FEMA oswa founisè asirans kont inondasyon ou an te avize ou batiman ou an te gen “pèt grav ki repete”?',
      floodDamageResponse: {
        options: [
          { value: true, display: 'Wi' },
          { value: false, display: 'Non' }
        ],
        invalidAnswers: [],
        invalidCopy: '',
        invalidLinkCopy: '',
        invalidLink: '#',
        invalidCopyAfterLink: '',
        alertAnswers: []
      },
      severeRepetitiveLossResponse: {
        options: [
          { value: 'yes', display: 'Wi' },
          { value: 'no', display: 'Non' },
          { value: 'unknown', display: 'M pa konnen' }
        ],
        invalidAnswers: [],
        invalidCopy: '',
        invalidLinkCopy: '',
        invalidLink: '#',
        invalidCopyAfterLink: '',
        alertAnswers: []
      },
      floodDamageDisclaimer:
        'Nou ap mande sa paske gen tarif asirans kont inondasyon espesyal ki aplike sèlman ak pwopriyete ki gen yon istwa pèt anpatikilye.',
      severeRepetitiveLossDisclaimer:
        'Pifò batiman nan Vil New York pa batiman ki sibi “pèt grav ki repete”.'
    },
    preferredRiskRate: {
      title: 'preferredRiskRate',
      faqZone: 'rate-calculator-preferred-risk-rate',
      breadcrumb: 'Tarif Risk Favori',
      floodClaimQuestion:
        'Èske ou te fè yon reklamasyon pou asirans kont inondasyon oswa resevwa asistans pou katastwòf inondasyon?',
      floodClaimResponseField: {
        options: [
          { value: true, display: 'Wi' },
          { value: false, display: 'Non' }
        ],
        invalidAnswers: [],
        invalidCopy: '',
        invalidLinkCopy: '',
        invalidLink: '#',
        invalidCopyAfterLink: '',
        alertAnswers: []
      },
      numberOfClaimsQuestion: 'Pou konbyen evennman?',
      numberOfClaimsSubtitle:
        'Chak tanpèt se yon evennman separe, kidonk si ou te sèlman resevwa peman pou Sandy ki konte antanke yon evennman.',
      numberOfClaimsResponseField: {
        options: [
          { value: 1, display: '1' },
          { value: 2, display: '2' },
          { value: 3, display: '3 oswa plis' }
        ],
        invalidAnswers: [],
        invalidCopy: '',
        invalidLinkCopy: '',
        invalidLink: '#',
        invalidCopyAfterLink: '',
        alertAnswers: []
      },
      claimAmountQuestion:
        'Èske ou te resevwa plis pase $1,000 pou chak inondasyon?',
      claimAmountResponseField: {
        options: [
          { value: 'true', display: 'Wi' },
          { value: 'false', display: 'Non' }
        ],
        invalidAnswers: [],
        invalidCopy: '',
        invalidLinkCopy: '',
        invalidLink: '#',
        invalidCopyAfterLink: '',
        alertAnswers: []
      },
      disclaimer:
        'Nou ap mande sa paske gen tarif asirans kont inondasyon espesyal ki aplike sèlman ak pwopriyete ki gen yon istwa pèt anpatikilye.’'
    },
    elevatedBuilding: {
      title: 'elevatedBuilding',
      faqZone: 'rate-calculator-elevated-building',
      question: 'Èske kay ou an elve?',
      breadcrumb: 'Elve',
      responseField: {
        options: [
          { value: 'true', display: 'Wi' },
          { value: 'false', display: 'Non' }
        ],
        invalidAnswers: [],
        invalidCopy: '',
        invalidLinkCopy: '',
        invalidLink: '#',
        invalidCopyAfterLink: '',
        alertAnswers: []
      },
      disclaimer: 'Sa vle di estrikti an tabli sou piloti oswa mi pasyèl.'
    },
    contentLocation: {
      title: 'contentLocation',
      faqZone: 'rate-calculator-content-location',
      breadcrumb: 'Anplasman Kontni yo',
      question: 'Kibò nan batiman ou an byen ou ta renmen asire yo ye?',
      responseField: {
        key: 'content_location',
        options: [
          {
            value: ContentLocations.BASEMENT_AND_ABOVE,
            display: 'Sousòl (aparèy pou lave/seche/konjelatè sèlman) epi Pi Wo'
          },
          {
            value: ContentLocations.ENCLOSURE_AND_ABOVE,
            display:
              'Espas anndan (aparèy pou lave/seche/konjelatè sèlman) epi Pi Wo'
          },
          {
            value: ContentLocations.LOWEST_FLOOR_ONLY,
            display: 'Etaj ki pi ba sèlman, pi wo pase nivo sòl lan'
          },
          {
            value: ContentLocations.LOWEST_FLOOR_AND_HIGHER,
            display: 'Etaj ki pi ba anwo nivo sòl lan epi etaj ki pi wo'
          },
          {
            value: ContentLocations.ABOVE_GROUND_LEVEL,
            display: 'Pi wo nivo sòl lan, plis pase yon etaj antye'
          }
        ]
      },
      disclaimer:
        'Sonje sèl kontni ou ka asire nan yon sousòl oswa espas anndan se aparèy pou lave, seche ak konjelatè. Gade Kesyon Moun Poze Souvan yo pou plis enfòmasyon.'
    },
    buildingFeature: {
      title: 'buildingFeature',
      faqZone: 'rate-calculator-building-feature',
      breadcrumb: 'Karakteristik Batiman an',
      question:
        'Kiyès, si genyen, nan karakteristik ki suiv yo la pwopriyete ou an genyen?',
      responseField: {
        key: 'building_feature',
        options: [
          {
            value: BuildingFeatures.BASEMENT,
            display: 'Sousòl oswa Kav',
            image: basement
          },
          {
            value: BuildingFeatures.ENCLOSURE,
            display: 'Espas anndan',
            image: enclosure
          },
          {
            value: BuildingFeatures.ELEVATED_ON_CRAWLSPACE_FLOOD_VENTS,
            display: 'Espas vid ki gen vantilasyon kont inondasyon',
            image: crawlspaceWithFloodVents
          },
          {
            value: BuildingFeatures.ELEVATED_ON_CRAWLSPACE_NO_FLOOD_VENTS,
            display: 'Espas vid ki san vantilasyon kont inondasyon',
            image: crawlspaceWithoutFloodVents
          },
          {
            value: BuildingFeatures.SUBGRADE_CRAWLSPACE,
            display: 'Espas vid ki nan sousòl',
            image: subgradeCrawlspace
          },
          {
            value: BuildingFeatures.MOBILE_HOME,
            display: 'Mezon Mobil',
            image: mobileHome
          },
          {
            value: BuildingFeatures.NO_BASEMENT_ENCLOSURE,
            display: 'Okenn',
            image: none
          }
        ]
      }
    },
    basement: {
      title: 'basement',
      faqZone: 'rate-calculator-basement',
      breadcrumb: 'Sousòl',
      hasBasementQuestion:
        'Èske pwopriyete ou an gen yon sousòl, espas vid oswa espas anndan?',
      responseField: {
        options: [
          { value: true, display: 'Wi' },
          { value: false, display: 'Non' }
        ],
        invalidAnswers: [],
        invalidCopy: '',
        invalidLinkCopy: '',
        invalidLink: '#',
        invalidCopyAfterLink: '',
        alertAnswers: []
      },
      basementHeightQuestion:
        'Ki wotè pou soti atè an pou rive nan twati sousòl, espas vid oswa espas anndan sa a? (Pifò batiman yo se 8’)?',
      basementHeightUnit: 'ft'
    },
    numberOfSteps: {
      title: 'numberOfSteps',
      faqZone: 'rate-calculator-number-of-steps',
      breadcrumb: 'Mach pou Monte/Desann',
      hasStepsQuestion:
        'Èske gen mach ki soti nan nivo sòl lan pou rive nan etaj ki pi ba ou an? (Si ou gen yon sousòl oswa espas vid ki anba nivo sòl lan, nou vle di mach yo apati nivo sòl deyò an pou desann nan sousòl oswa etaj espas vid ou an.)',
      hasStepsResponseField: {
        options: [
          { value: 'true', display: 'Wi' },
          { value: 'false', display: 'Non' }
        ],
        invalidAnswers: [],
        invalidCopy: '',
        invalidLinkCopy: '',
        invalidLink: '#',
        invalidCopyAfterLink: '',
        alertAnswers: []
      },
      frontDoorQuestion: 'Èske mach yo monte oswa desann?',
      frontDoorResponseField: {
        options: [
          { value: 'true', display: 'Monte' },
          { value: 'false', display: 'Desann' }
        ],
        invalidAnswers: [],
        invalidCopy: '',
        invalidLinkCopy: '',
        invalidLink: '#',
        invalidCopyAfterLink: '',
        alertAnswers: []
      },
      numberOfStepsQuestion: 'Konbyen mach ki genyen?',
      disclaimer:
        'Asire w pou reponn kesyon sa a apati pwen ki pi ba nan sòl lan ki touche kay ou an. Si sòl lan wo devan epi ba pa dèyè, reponn apati do an. Si etaj ki pi ba a nan nivo sòl lan sou nenpòt kibò, reponn “non”.'
    },
    numberOfFloors: {
      title: 'numberOfFloors',
      faqZone: 'rate-calculator-number-of-floors',
      breadcrumb: 'Plizyè Etaj',
      question: 'Èske kay sa a gen plis pase yon etaj?',
      responseField: {
        options: [
          { value: true, display: 'Wi' },
          { value: false, display: 'Non' }
        ],
        invalidAnswers: [],
        invalidCopy: '',
        invalidLinkCopy: '',
        invalidLink: '#',
        invalidCopyAfterLink: '',
        alertAnswers: []
      }
    },
    machineryInBasement: {
      title: 'machineryInBasement',
      faqZone: 'rate-calculator-machinery-in-basement',
      breadcrumb: 'Gwo Aparèy nan Sousòl lan',
      question:
        'Èske gen gwo machin konstriksyon nan sousòl ou an tankou yon chofaj dlo, chodyè, HVAC, elatriye?',
      responseField: {
        options: [
          { value: true, display: 'Wi' },
          { value: false, display: 'Non' }
        ],
        invalidAnswers: [],
        invalidCopy: '',
        invalidLinkCopy: '',
        invalidLink: '#',
        invalidCopyAfterLink: '',
        alertAnswers: []
      },
      disclaimer:
        'Sa vle di gwo machin oswa ekipman ki founi sèvis piblik yo nan kay lan.'
    }
  }
};
