import DefaultCalculatorParamsHelper from './DefaultCalculatorParamsHelper';
import FloodZones from '../../constants/FloodZones';
import ContentLocations from '../../constants/ContentLocations';
import BuildingFeatures from '../../constants/BuildingFeatures';

const AbandonFirstFloorHelper = {
  showMitigation: (property) => {
    if (
      property.current_flood_zone === FloodZones.AE ||
      property.future_flood_zone === FloodZones.AE
    ) {
      const buildingFeature = property.building_feature;
      const buildFeatureUndefined =
        buildingFeature === null || buildingFeature === undefined;
      if (
        buildingFeature === BuildingFeatures.NO_BASEMENT_ENCLOSURE ||
        (buildFeatureUndefined && !property.basement)
      ) {
        const hasMultiStoryContentLocations =
          [
            ContentLocations.ABOVE_GROUND_LEVEL,
            ContentLocations.LOWEST_FLOOR_AND_HIGHER
          ].indexOf(property.content_location) !== -1;
        if (property.more_than_one_floor || hasMultiStoryContentLocations) {
          return true;
        }
      }
    }
    return false;
  },
  formatMitigatedRate: (property, useCurrent) => {
    const formattedParams = DefaultCalculatorParamsHelper.formatCalculatorParameters(
      property,
      useCurrent
    );

    const zone = useCurrent
      ? property.current_flood_zone
      : property.future_flood_zone;
    if (zone !== FloodZones.VE) {
      formattedParams.elevation_difference += 8;
    }
    return formattedParams;
  }
};

export default AbandonFirstFloorHelper;
