import RtappApplicationState from '../constants/RtappApplicationState';

export default {
  progressBar: {
    apply: 'Подать заявку',
    audit: 'Инспекция',
    review: 'Обзор',
    install: 'Установка',
    complete: 'Завершено',
    rejected: 'Не соответствует критериям',
    withdrawn: 'Изъято'
  },
  [RtappApplicationState.PRE_APPLICATION]: {
    title: "Спасибо! Мы скоро с вами свяжемся."
  },
  [RtappApplicationState.PRE_APPLICATION_APPROVED]: {
    title: 'Закончите ваше заявление на установку канализационного обратного клапана'
  },
  [RtappApplicationState.APPLICATION_SUBMITTED]: {
    title: 'Спасибо за подачу заявления!'
  },
  [RtappApplicationState.HOMEOWNER_GRANT]: {
    title: "Поздравляем! Вы соответствуете критериям!"
  },
  [RtappApplicationState.AUDIT_SCHEDULED]: {
    title: 'Назначить инспекцию'
  },
  [RtappApplicationState.AUDIT_COMPLETE]: {
    title: 'Инспекция завершена'
  },
  [RtappApplicationState.FEASIBILITY_REVIEW]: {
    title: 'Анализ целесообразности'
  },
  [RtappApplicationState.CONSULTATION_SCHEDULED]: {
    title: 'Вам назначена консультация!'
  },
  [RtappApplicationState.CONSULTATION_COMPLETE]: {
    title: 'Давайте продолжим'
  },
  [RtappApplicationState.INSTALLATION_PREPARE]: {
    title: 'Вы собираем вашу команду'
  },
  [RtappApplicationState.MEET_YOUR_TEAM]: {
    title: 'Познакомьтесь с вашей командой'
  },
  [RtappApplicationState.INSTALLATION_TO_BE_SCHEDULED]: {
    title: 'Назначить вашу установку'
  },
  [RtappApplicationState.INSTALLATION_HAS_BEEN_SCHEDULED]: {
    title: 'Назначить вашу установку'
  },
  [RtappApplicationState.INSTALLATION_COMPLETE]: {
    title: 'Ваша установка была назначена'
  },
  [RtappApplicationState.INSTALLATION_COMPLETE]: {
    title: 'Установка завершена!'
  },
  [RtappApplicationState.REPORT_COMPLETE]: {
    title: 'Финальный отчет'
  },
  [RtappApplicationState.REJECTED]: {
    title: 'К сожалению, вы не соответствуете критериям'
  },
  [RtappApplicationState.WITHDRAWN]: {
    title: 'Ваше заявление было изъято'
  }
};
