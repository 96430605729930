export default {
    header: '预计洪水保险',
    beta: '测试版',
    link: '查看您的完整预计',
    currentMapStart: '当前地图（',
    currentMapEnd: ' 区域）',
    futureMapStart: '咨询地图（',
    futureMapEnd: ' 区域）',
    assumingMax: '*假设最大承保范围',
    numbersAreEstimates:
      '出于多种原因，这些数字是估计值，要了解更多信息，请访问我们的 ',
    termsAndConditions: '条款',
    floodZoneRateProvider: {
      perYear: '每年*'
    },
    rateAdjusterProvider: {
      adjustYourCoverage: '调整承保范围 ',
      structuralCoverage: '结构承保范围',
      structuralDeductible: '结构免赔额',
      contentCoverage: '物品承保范围',
      contentDeductible: '物品免赔额',
      collapse: '崩溃'
    }
  };
  