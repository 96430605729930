export default class RtappApplicationState {
  static get PRE_APPLICATION() {
    return 'IntakeComplete';
  }

  static get PRE_APPLICATION_APPROVED() {
    return 'FullApplication';
  }

  static get APPLICATION_SUBMITTED() {
    return 'ApplicationSubmitted';
  }

  static get HOMEOWNER_GRANT() {
    return 'GrantAgreement';
  }

  static get REJECTED() {
    return 'Ineligible';
  }

  static get WITHDRAWN() {
    return 'Withdrawn';
  }

  static get FEASIBILITY_REVIEW() {
    return 'FeasibilityReview';
  }

  static get AUDIT_SCHEDULED() {
    return 'AuditScheduled';
  }

  static get AUDIT_COMPLETE() {
    return 'AuditComplete';
  }

  static get CONSULTATION_SCHEDULED() {
    return 'CounselingScheduled';
  }

  static get CONSULTATION_COMPLETE() {
    return 'CounselingComplete';
  }

  static get INSTALLATION_PREPARE() {
    return 'InstallationPrepare';
  }

  static get MEET_YOUR_TEAM() {
    return 'MeetYourTeam';
  }

  static get INSTALLATION_TO_BE_SCHEDULED() {
    return 'InstallationToBeScheduled';
  }

  static get INSTALLATION_HAS_BEEN_SCHEDULED() {
    return 'InstallationHasBeenScheduled';
  }

  static get INSTALLATION_COMPLETE() {
    return 'InstallationComplete';
  }

  static get REPORT_COMPLETE() {
    return 'Complete';
  }
}
