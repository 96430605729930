import carpet from '../images/carpet.svg';
import piping from '../images/piping.svg';
import plumbing from '../images/plumbing.svg';
import temperatureInside from '../images/temperature-inside.svg';
import bacteria from '../images/bacteria.svg';
import fillInBasement from '../images/fill-in-basement.svg';
import elevate from '../images/elevate.svg';
import elevateMechanicals from '../images/elevate-mechanicals.svg';
import abandonFirstFloor from '../images/abandon-first-floor.svg';
import floodVents from '../images/flood-vents.svg';
import backwaterValve from '../images/backwater-valve.svg';

export default {
  meta: {
    title: '洪水改造|保护您的房产|FloodHelpNY.org',
    description: `洪水改造可以帮助您降低洪水风险。这是您需要了解的信息。`,
    url: 'https://www.floodhelpny.org/zh-CN/flood-retrofits',
    imageAlt: '海浪与纽约市'
  },
  learnMore: '了解更多信息',
  title: '洪水改造：保护您的房产',
  pageSubtitle:
    '您可以采取一些策略来降低洪水的风险。有些是重大的改装，有些则是快速简单的解决方案，减少洪水造成的潜在损害。',
  subtitle:
    '您可以采取一些策略来降低洪水的风险。有些是重大的改装，有些则是快速简单的解决方案，减少洪水造成的潜在损害。',
  overview: {
    title: '概述',
    body:
      '我们的沿海社区是纽约独特之处的重要组成部分。这座城市有520英里的海岸线，各个社区之间有着巨大的差异——一些地区的平房星罗棋布，另一些地区的排屋密密麻麻。密度和住房类型都存在差异，因此纽约市无法采用统一洪水改造方法。幸运的是，有很多不同的方法来加固我们的社区和海岸。'
  },
  options: {
    title: '缓解方案',
    body:
      '一些恢复力选项的成本比其他选项高，一些更耗费精力，另外的选项能带来更多的好处。在选择适合的选项时，应考虑所有这些权衡。到目前为止，对保险费有影响的措施屈指可数：提高；回填地下室或夹层空间（根据需要进行防潮）；安装溢流口；以及将机械设备抬出地下室（这一选项减免的保险最少，但在发生洪水时可以为您节省大量资金）。您可以采取其他一些措施，不会降低保险费率，但有助于保护您的房屋免受洪水的破坏。值得注意的是，无论在哪个洪水区，任何这些措施都可以降低保费。',
    list: [
      {
        title: '填埋地下室',
        body: "减少对房屋结构基础的损坏。",
        image: fillInBasement,
        imageAlt: '填埋地下室',
        link: 'fill-in-basement'
      },
      {
        title: '提升您的房产',
        body:
          '让您的房产完全高于预测洪水水位。',
        image: elevate,
        imageAlt: '提升您的房产',
        link: 'elevate'
      },
      {
        title: '提高机械性能',
        body: '保护您房屋的关键系统。',
        image: elevateMechanicals,
        imageAlt: '提高机械性能',
        link: 'elevate-mechanicals'
      },
      {
        title: '放弃一楼',
        body: '这有效地提高了您房屋的基础标高。',
        image: abandonFirstFloor,
        imageAlt: '放弃一楼',
        link: 'abandon-first-floor'
      },
      {
        title: '安装泄洪口',
        body:
          '让洪水流过并排放，降低洪水造成的损害风险。',
        image: floodVents,
        imageAlt: '安装泄洪口',
        link: 'flood-vents'
      },
      {
        title: '安装回水阀',
        body:
          '回水阀的设计目的是在下水道溢流时排放掉家中未经处理的污水。',
        image: backwaterValve,
        imageAlt: '安装回水阀',
        link: 'backwater-valve'
      }
    ]
  },
  retrofitting: {
    title: '城市规划改造指南',
    body:
      '迄今为止，对纽约市洪泛区建筑物可用的改造方案进行了最全面的分析。',
    view: '建议'
  },
  budget: {
    title: '预算缓解措施',
    subtitle:
      '您可以采取其他一些措施，不会降低保险费率，但有助于减少洪水损失。',
    list: [
      {
        header: '用瓷砖代替地毯',
        body:
          '您的浴室是防水的——为什么您的地下室不能防水呢？如果您的地下室采用瓷砖而不是地毯，清理会更容易，您可以防止几英寸的洪水造成严重破坏。',
        image: carpet
      },
      {
        header: '安装回水防止器',
        body:
          '您家周围的系统被洪水淹没时，相对便宜且易于安装的防回流阀可防止下水道和雨水通过水槽、马桶和浴缸进入您的房屋。',
        image: piping
      },
      {
        header: '安装污水泵',
        body:
          '污水泵有助于通过地基裂缝、多孔表面和其他贯穿件（如地下管道）排出渗漏和进入建筑物的水。',
        image: plumbing
      },
      {
        header: '建筑系统内部防水',
        body:
          '这就需要使用被动或主动的机械装置（如锅炉周围的混凝土环墙、防淹门或屏障）来密封建筑物防止洪水进入。',
        image: temperatureInside
      },
      {
        header: '安装防洪材料',
        body:
          '用于建筑材料和家具的非纸面石膏板和水磨石瓷砖地板等材料减少了洪水的破坏，并加快了洪水后的清理。还可以包括使用防水柜，用金属或其他表面防水材料替换木制物品，如门。',
        image: bacteria
      }
    ]
  }
};
