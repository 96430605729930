import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Accounting from 'accounting';
import RateCalculatorCommunicator from '../communicators/RateCalculatorCommunicator';
import SalesforceCommunicator from '../communicators/SalesforceCommunicator';
import { useApi } from '../helpers/hooks';

const AuditRate = ({ applicationId, rateKey, results }) => {
  const { salesforceApi, rateCalculatorApi } = useApi();
  const [rate, setRate] = useState(0);

  useEffect(() => {
    // the mounted var ensures that setRate only acts if this component is mounted
    let mounted = true;
    const getRate = async () => {
      try {
        const rateResult = await RateCalculatorCommunicator.getRate(
          rateCalculatorApi,
          results
        );
        SalesforceCommunicator.updateAuditApplication(
          salesforceApi,
          applicationId,
          { [rateKey]: rateResult.rate }
        );
        if (mounted) {
          setRate(rateResult.rate);
        }
      } catch (error) {
        // TODO add error handling to try/catch
      }
    };
    getRate();
    // set the mounted var to false on cleanup
    return () => {
      mounted = false;
    };
  }, [applicationId, rateCalculatorApi, rateKey, results, salesforceApi]);

  // TODO replace the Accounting library imported here?
  return (
    <span className="rate-display">{Accounting.formatMoney(rate, '$', 0)}</span>
  );
};

AuditRate.propTypes = {
  applicationId: PropTypes.string.isRequired,
  rateKey: PropTypes.string.isRequired,
  results: PropTypes.object.isRequired
};

export default AuditRate;
