export default {
  download: 'Download',
  elevationCertificate: {
    header: 'Your Elevation Certificate (PDF)',
    copy: 'You can use this to get an accurate flood insurance rate'
  },
  technicalReport: {
    header: 'Your full technical report (PDF)',
    copy: 'The full technical report'
  },
  fieldReport: {
    header: 'Your full field report (PDF)',
    copy: 'The full field report'
  },
  homeownerGrantAgreement: {
    header: 'Your full homeowner grant agreement (PDF)',
    copy: 'The full homeowner grant agreement'
  },
  triPartyAgreement: {
    header: 'Your full tri-party agreement (PDF)',
    copy: 'The full tri-party agreement'
  }
};
