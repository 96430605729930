export default {
  header: 'Ways to lower your rate',
  link: 'Learn about mitigation',
  assumingMax: '*Assuming max coverage',
  numbersAreEstimates:
    'These numbers are estimates for many reasons, to learn more about them go to our ',
  termsAndConditions: 'Terms & Conditions',
  noMitigation: "Sorry, we couldn't identify any options",
  elevateBuilding: {
    header: 'Elevate your home',
    body: 'Puts your house completely above predicted flood water levels.',
    link: 'Learn More',
    mitigationDifference: {
      currentHeader: 'For your current zone',
      futureHeader: 'For your advisory zone',
      saved: 'saved',
      perYear: ' /year*',
      nonpositiveSavingsCopy: 'This will not lower your rate'
    }
  },
  abandonFirstFloor: {
    header: 'Abandon your first floor',
    body: 'This effectively raises the base elevation of your home.',
    link: 'Learn More',
    mitigationDifference: {
      currentHeader: 'For your current zone',
      futureHeader: 'For your advisory zone',
      saved: 'saved',
      perYear: ' /year*',
      nonpositiveSavingsCopy: 'This will not lower your rate'
    }
  },
  elevateMechanicals: {
    header: 'Raise your mechanicals',
    body: 'Protects your home’s critical systems.',
    link: 'Learn More',
    mitigationDifference: {
      currentHeader: 'For your current zone',
      futureHeader: 'For your advisory zone',
      saved: 'saved',
      perYear: ' /year*',
      nonpositiveSavingsCopy: 'This will not lower your rate'
    }
  },
  floodVents: {
    header: 'Install flood vents',
    body:
      'Reduce the risk of damage from flood water by allowing it to flow through and drain.',
    link: 'Learn More',
    mitigationDifference: {
      currentHeader: 'For your current zone',
      futureHeader: 'For your advisory zone',
      saved: 'saved',
      perYear: ' /year*',
      nonpositiveSavingsCopy: 'This will not lower your rate'
    }
  },
  fillInBasement: {
    header: 'Fill in your basement',
    body: 'Reduces damage to your home’s structural foundation.',
    link: 'Learn More',
    mitigationDifference: {
      currentHeader: 'For your current zone',
      futureHeader: 'For your advisory zone',
      saved: 'saved',
      perYear: ' /year*',
      nonpositiveSavingsCopy: 'This will not lower your rate'
    }
  }
};
