import waterDroplet from '../images/water-droplet.svg';
import umbrella from '../images/umbrella.svg';
import piggyBank from '../images/piggy-bank.svg';
import moneyHouse from '../images/money-house.svg';
import couchInHouse from '../images/couch-in-house.svg';
import policyAlert from '../images/policy-alert.svg';
import checklist from '../images/checklist.svg';
import premiumChanges from '../images/premium-changes.svg';

export default {
  meta: {
    title: 'Cómo entender el seguro contra inundaciones | FloodHelpNY.org',
    url: 'https://www.floodhelpny.org/es/understanding-flood-insurance',
    description:
      'El seguro contra inundaciones es complicado. Estamos aquí para ayudarte a entenderlo.',
    ogDescription:
      'Tratar de entender el seguro contra inundaciones por su cuenta puede ser frustrante. Pero estamos aquí para ayudar.',
    imageAlt: 'olas'
  },
  title: 'Cómo entender el seguro contra inundaciones',
  subHeading:
    'Tratar de entender el seguro contra inundaciones por su cuenta puede ser frustrante. Pero estamos aquí para ayudar.',
  inANutshell: {
    header: 'En pocas palabras',
    items: [
      {
        header: 'El seguro estándar no es igual al seguro contra inundaciones',
        body:
          'El seguro para los propietarios de viviendas, inquilinos, y de uso comercial no cubre los daños causados por las inundaciones.',
        image: waterDroplet,
        imageAlt: ''
      },
      {
        header:
          'Es posible que se le exija tener un seguro contra inundaciones.',
        body:
          'El seguro contra inundaciones se exige para algunos propietarios de viviendas.',
        image: umbrella,
        imageAlt: 'Paraguas'
      },
      {
        header: 'Ahorre dinero con un seguro contra inundaciones',
        body:
          'Puede ser la herramienta más importante para mantener sus finanzas a flote en caso de una inundación.',
        image: piggyBank,
        imageAlt: ''
      }
    ]
  },
  whatIsIt: {
    header: '¿Qué es esto?',
    body: {
      p1:
        'El Programa Nacional de Seguro contra Inundaciones (NFIP) es un programa respaldado por las autoridades federales que se inició en 1968. En esa época, las empresas privadas no estaban dispuestas a asumir el riesgo de daños causados por inundaciones, por lo cual el Congreso creó el NFIP con el fin de proporcionar un seguro contra inundaciones asequible a los propietarios de edificios. La Ciudad de Nueva York entró al programa en 1983.',
      p2:
        'El NFIP es administrado por la Agencia Federal para el Manejo de Emergencias y por compañías de seguro privadas. Si bien estas compañías venden seguros contra inundaciones, todas las tarifas están reguladas por FEMA. Esto significa que usted no tiene que buscar la mejor oferta en seguros, sino que debería comprobar la precisión de su tarifa.'
    },
    aside: {
      header: 'NFIP',
      body:
        'El NFIP permite a los propietarios de vivienda de las comunidades participantes que compren seguros contra pérdidas por inundaciones y exige que todos los préstamos hipotecarios o líneas de crédito que estén garantizados por edificios en zonas de inundaciones de alto riesgo tengan seguro.'
    }
  },
  floodInsuranceCover: {
    header: '¿Qué cubre el seguro contra inundaciones?',
    subheader:
      'Existen dos tipos de cobertura: edificación y contenidos. Tiene la posibilidad de elegir las cifras de cobertura y deducibles separado para cada uno.',
    items: [
      {
        header: 'Cobertura de la edificación',
        body:
          'Asegura solamente la estructura, no el contenido o la zona del jardín. No cubre el acabado en el sótano.',
        image: moneyHouse,
        imageAlt: ''
      },
      {
        header: 'Cobertura del contenido',
        body:
          'Asegura sus pertenencias (pero la mayoría de los artículos en su sótano o por debajo de su piso más bajo están excluidos).',
        image: couchInHouse,
        imageAlt: ''
      }
    ],
    aside: {
      header: 'Excepciones inesperadas',
      body:
        'Sin embargo, existen algunas excepciones inesperadas: los refrigeradores, las estufas y lavavajillas están cubiertos por el seguro contra inundaciones de la edificación, mientras que las lavadoras y secadoras se consideran como contenido.'
    }
  },
  shopping: {
    icon: checklist,
    iconAlt: 'lista de verificación',
    header: 'Aprenda cómo obtener la mejor póliza para proteger su hogar.',
    buttonText: 'Lee ahora'
  },
  myOptions: {
    header: '¿Cuáles son mis opciones de cobertura?',
    body:
      'La cifra máxima de seguro que usted puede comprar para la estructura es de $250,000, y de $100,000 para el contenido.',
    aside: {
      header: 'Propietario de un pequeño negocio',
      link: 'Obtener más información',
      body:
        ' sobre cómo el riesgo de inundaciones puede afectar sus operaciones y qué puede hacer al respecto.'
    }
  },
  floodInsurancePriced: {
    header: '¿Cómo se cotiza el seguro contra inundaciones?',
    body:
      'El 1 de octubre del 2021, FEMA introdujo nuevos precios de seguros contra inundaciones conocidas como “Risk Rating 2.0” para reflejar con mayor precisión el riesgo de inundación. Bajo este nuevo sistema, FEMA ya no dependerá de los mapas de inundaciones para establecer los precios de seguros, sin embargo, utilizará múltiples variables para calcular el deducible, por ejemplo, la distancia a una fuente de inundacion y tipo de construcciones. Para una lista completa de estas variables consulte nuestra sección de preguntas frecuentes.'
  },
  premiums: {
    icon: premiumChanges,
    iconAlt: 'Risk Rating 2.0 resultara en cambios de primas de seguro',
    body:
      'El “Risk Rating 2.0” resultara en un cambio de primas de seguro para muchas personas a nivel nacional. Algunas pólizas de seguro disminuiran de precio y otras incrementaran. Para esas polizas que suban de precio bajo el “Risk Rating 2.0” FEMA esta requiriendo por ley federal no incrementar el precio de una póliza por mas del 18% por año. En la Ciudad de Nueva York FEMA estima que aproximadamente el 61% de los residentes de NYC experimentaran un incremento de su prima de seguro debido al “Risk Rating 2.0”.'
  },
  understandingMaps: {
    header: 'Entendiendo los mapas de inundaciones',
    sub_heading:
      'Es importante recordar que, aunque los mapas de inundaciones ya no serán una pieza clave para calcular las tarifas de los seguros, se seguirán usando para determinar si es necesario que usted tenga un seguro contra inundaciones.',
    severeRiskZone: 'Riesgo máximo',
    highRiskZone: 'Riesgo alto',
    moderateRiskZone: 'Riesgo moderado'
  },
  aboutTheZones: {
    header: 'Acerca de las zonas',
    body: {
      p1:
        'En las zonas de alto riesgo de inundaciones (AE, AO y VE), si los propietarios tienen una hipoteca respaldada por las autoridades federales, se les exigirá que compren un seguro contra inundaciones. Además, a los propietarios que hayan recibido, por parte de las autoridades federales, asistencia en caso de desastres por daños causados por inundaciones, se les exigirá que compren un seguro contra inundaciones para que puedan ser elegibles para recibir asistencia en un futuro.'
    },
    aside: { header: 'Conozca su riesgo.', link: 'Encuentre su zona' }
  },
  subsidizedRateFullRate: {
    header: 'Tarifa subvencionada vs. Tarifa de riesgo completo',
    body:
      'En las zonas de alto riesgo de inundación, existen dos diferentes tipos de tarifas de seguro disponibles: la tarifa subvencionada pre-FIRM y la tarifa estándar. La tarifa subvencionada pre-FIRM está disponible solo para propietarios cuyas propietarios de edificios fueron construidas antes del 16 de noviembre de 1983 ("pre-FIRM", ya que la vivienda se construyó antes de que FIRM fuera aprobada en 1983). La tarifa subvencionada puede ser inferior a la estándar; sin embargo, los subsidios están disminuyendo, hay muchas propiedades cuyas tarifas estándares son menos costosas.',
    aside: {
      header: 'Incrementos anuales de seguro',
      body:
        'Gracias a dos nuevas leyes federales, la Ley Biggert-Waters de 2012 y la Ley de Asequibilidad de Seguros de Viviendas de 2014, las tarifas subvencionadas están en aumento del 15% al 18% por año.'
    }
  },
  newHomeowners: {
    header: 'Nuevos proprietarios de viviendas',
    body:
      'Cuando compre una nueva casa, confirme con el vendedor si tiene una póliza de seguro contra inundaciones. Las personas con seguro pueden transferir estas pólizas (incluyendo descuentos) al nuevo proprietario una vez que se venda hogar.',
    aside: {
      header: 'Carta de enmienda del mapa',
      body:
        'Si su hogar se encuentra en una zona de alto riesgo de inundación pero la elevación del suelo inmediata que la rodea está por encima del BFE, usted podrá enviarle a la FEMA una carta de enmienda del mapa o LOMA y, de esta manera, solicitar que excluyan su propiedad de la zona de inundaciones. Necesitará un certificado de elevación como prueba.'
    }
  },
  whyMapsChanging: {
    header: 'LOS MAPAS DE NYC ESTÁN CAMBIANDO',
    body:
      'FEMA actualiza regularmente los mapas que crea para las zonas en peligro de inundación en todo el país. Los mapas vigentes para la Ciudad de Nueva York fueron aprobados en 1983 y no reflejan con exactitud los riesgos de inundación actuales. En este momento, no está claro cuándo entrarán en vigor los nuevos mapas de tarifas de seguro contra inundaciones o qué forma finalmente tendrán. Nuestra mejor estimación es que esto ocurrirá en los próximos dos a cuatro años.',
    aside: {
      header: 'Desafío NYC',
      body_before:
        'La Ciudad de Nueva York presentó una apelación con respecto al Mapa Preliminar de Tarifas de Seguro contra Inundaciones de la FEMA en junio 2015, y argumentaron que ellos sobreestimaron los riesgos de inundación en los cinco condados. En octubre de 2016, la FEMA declaró que había aceptado la apelación ',
      link: 'de la Ciudad',
      body_after:
        ' y que examinaría los mapas basados en el análisis de los nuevos datos.'
    }
  },
  learnMore: {
    body: '¿Desea obtener más información? Vea nuestro artículo ',
    link: 'Si aumentan las mareas, aumentan las tarifas',
    href: 'https://cnycn.atavist.com/risingtides'
  }
};
