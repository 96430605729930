export default {
    xZone: 'Средний риск',
    aeZone: 'Повышенный риск',
    aoZone: 'Повышенный риск',
    veZone: 'Наивысший риск',
    noneZone: 'Минимальная опасность',
    baseFloodElevation: 'Базовая высота наводнения (BFE)',
    xToolTip:
      'Зона X - это зона среднего риска: собственность в этой зоне подвержена среднему риску наводнения во время следующего серьезного шторма.',
    aeToolTip:
      'Зона AE - это зона повышенного риска: собственность в этой зоне подвержена высокому риску наводнения во время следующего серьезного шторма.',
    aoToolTip:
      'Зона AO - это зона повышенного риска: страхование от наводнения обязательно и ожидайте высокий риск наводнения.',
    veToolTip:
      'Зона V - это зона наивысшего риска в городе Нью-Йорк: собственность в этой зоне подвержена самому высокому риску наводнения во время следующего серьезного шторма.',
    floodZoneUrl: '/ru/understanding-flood-insurance',
    learnMoreAboutFloodZones: {
      copy: 'Дополнительная информация о зонах наводнения',
      linkText: ' здесь'
    },
    floodZoneExplanations: {
      ae: {
        header:
          'Здания в зонах повышенного риска (AE) могут подвергнуться сильному затоплению - возможно более нескольких футов.',
        copy:
          'Страхование от наводнения в зоне повышенного риска обязательно, если у вас есть ипотека.'
      },
      ve: {
        header:
          'Нью-йоркцы, живущие в зонах повышенного риска, знают, что угроза нанесения ущерба реальна, и что предпринятые шаги сегодня сэкономят деньги, время и энергию в будущем.',
        copy:
          'Страхование от наводнения в зоне повышенного риска обязательно, если у вас есть ипотека.'
      },
      x: {
        header:
          'Зоны среднего риска предрасположены к затоплению в несколько дюймов - и эти дюймы могут быть дорогостоящими. В зависимости от вашей собственности всего несколько дюймов воды могут нанести значительный ущерб.',
        copy:
          'Так как тарифы страхования от наводнений устанавливаются FEMA, тарифы не изменяются от одной страховой компании к другой. Но многие страховые агенты не очень хорошо понимают правила и допускают много ошибок с тарифами.'
      },
      none: {
        header:
          'Изменение риска наводнения и изменение зон в конечном счете окажет влияние на всех нас. Если не предпринимать никаких действий, это влияние ударит рикошетом по экономике всего города.',
        copy:
          'Почти 20% страховых исков, связанных с затоплением, приходятся на районы со средним риском и минимальной опасности.'
      },
      ao: {
        header:
          'Здания в зонах повышенного риска AO могут подвергнуться  затоплению в районах возле рек или потоков, а также мелководным наводнениям каждый год.',
        copy:
          'Страхование от наводнения в зоне повышенного риска обязательно, если у вас есть ипотека.'
      }
    }
  };