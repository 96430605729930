import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors, borderRadius } from '../styles/variables';
import { useProvider } from '../helpers/hooks';
import Button from './ui/Button';

const Container = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  text-align: center;
  width: ${(props) => (props.explorable ? 'auto' : '75%')};
  position: relative;
  margin: ${(props) => (props.explorable ? '0' : '20vh auto')};
  padding: ${(props) => (props.explorable ? '2rem' : '4rem')};
  border-radius: ${borderRadius};
  background-color: white;

  @media (max-width: 540px) {
    margin: 0;
    width: auto;
    height: 100%;
    overflow-y: scroll;
  }
`;

const Header = styled.h5`
  margin: 1rem;
`;

const Links = styled.div`
  margin-top: 1rem;
  font-size: 1rem;
`;

const Actions = styled.div`
  display: flex;
  justify-content: space-evenly;

  button {
    margin: 1rem 2rem;
  }

  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;

// called by:
//  EligibilityLookup & ExplorableMap
const PropertyTypeModal = ({ handlePropertyTypeClick, explorable }) => {
  const provider = useProvider('propertyType');

  return (
    <Container explorable={explorable}>
      <Header>{provider.description}</Header>
      <Links>
        <Actions>
          <Button
            bgColor={colors.resilientBlue}
            ghost
            onClick={() => handlePropertyTypeClick(true)}
          >
            {provider.options.business}
          </Button>
          <Button
            bgColor={colors.resilientBlue}
            ghost
            onClick={() => handlePropertyTypeClick(false)}
          >
            {provider.options.homeowner}
          </Button>
        </Actions>
      </Links>
    </Container>
  );
};

PropertyTypeModal.propTypes = {
  handlePropertyTypeClick: PropTypes.func.isRequired,
  explorable: PropTypes.bool
};

PropertyTypeModal.defaultProps = {
  explorable: false
};
export default PropertyTypeModal;
