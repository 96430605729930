import handsHeart from '../images/hands-heart.png';
import buildingFlood from '../images/building-flood.png';
import floodNet from '../images/flood-net.png';
import homeAutomation from '../images/home-automation.svg';
import rainCloud from '../images/rain-cloud.png';
import storm from '../images/storm.svg';
import stormwaterFlooding from '../images/stormwater-flooding.svg';
import streetFlood from '../images/street-flood.jpeg';

export default {
  meta: {
    title: '纽约气候变化 | 洪水 |  FloodHelpNY',
    url: 'https://www.floodhelpny.org/zh-CN/climate-change',
    description:
      '气候变化让纽约市的洪水愈发严重。您需要了解这些信息。',
    imageAlt: '波浪'
  },
  title: '气候变化',
  subHeading:
    '气候变化让纽约市的洪水愈发严重。您需要了解这些信息。',
  overview: {
    header: '概述',
    body:
      '纽约市的海平面上升速度几乎是全球平均水平的两倍。大西洋上汹涌猛烈的飓风每年都有可能袭击东海岸。越来越频繁的大风暴有时会产生破坏性甚至致命的洪水。这些极端天气模式意味着纽约这样的城市需要学会准备和适应气候危机的新常态。',
    aside: {
      body:
        '气候危机对每个人产生的影响不同。有色人种群体更容易受到海平面上升和洪水的影响。',
      icon: buildingFlood,
      iconAlt: '淹没建筑物'
    },
    image: streetFlood,
    imageAlt: '纽约市被洪水淹没的街道',
    imageCaption: '由社区洪水观测项目提供'
  },
  howClimateChangeContributes: {
    header: '气候变化如何导致出现更多的洪水',
    body: {
      p1:
        '在全球范围内，海平面上升将继续增加沿海洪水的频率和强度。洪水是最常见的自然灾害之一，会导致大量的财产损失，每年造成的死亡人数超过龙卷风。随着气候变化预计将恶化，我们社区的洪水也会恶化。',
      p2: '这里是气候变化对纽约洪水产生的影响：',
      p3:
        '这些绝不是气候变化与洪水之间的唯一联系，但对于理解如何保护我们的房屋、家人和社区至关重要。'
    },
    items: [
      {
        header: '由于全球变暖，下倾盆大雨的几率也更大',
        body:
          '自1900年以来，地球降水增加了4%，美国东半部地区变得最潮湿。',
        image: rainCloud,
        imageAlt: '雨云'
      },
      {
        header: '更强的风暴带来更猛烈的风雨',
        body:
          '最具破坏性的飓风出现的频率在增加，包括在大西洋。',
        image: storm,
        imageAlt: '雷电风暴'
      },
      {
        header: '海平面上升',
        body:
          '预测显示，到2100年，东海岸的海平面可能会高达6.5英尺。',
        image: stormwaterFlooding,
        imageAlt: '暴雨洪水'
      }
    ]
  },
  climateCrisis: {
    title: '可以采取气候行动',
    body:
      '社区洪水监测项目是一个持续扩大的居民和组织网络，他们报告当地洪水，分享经验，并获取与纽约市沿海社区洪水相关的资源。社区可以使用这个共享的图片、报告和地图数据库，向城市领导人传达他们的需求和愿景。您也可以参与进来!',
    view: '参与其中',
    image: handsHeart,
    imageAlt: '海岸气候弹性指南'
  },
  climateChangeFlooding: {
    header: '气候变化导致的洪水对城市的影响是不均衡的',
    body:
      '在全国各地，由于掠夺性放贷和分区做法，历史上被划为红线和边缘化的社区也面临着与气候相关的海平面上升和严重洪水的风险。根据 RedFin 数据，13.8%的房屋位于红色和黄色区域内，大多数居民是有色人种，与纽约市其他社区相比，他们面临更高的洪水风险。和之前的划红线社区相比，在纽约市较富裕的社区对基础设施投资更多应对日益严重的洪水，如下水道。国会和环境保护局（EPA）正在继续努力解决气候问题，这些问题对有色人种社区造成了不成比例的影响，确保所有人都能实现环境正义，而不分种族、性别和收入。'
  },
  severeHurricanes: {
    header: '从严重飓风到极端降雨',
    body: {
      p1:
        '由于气候变化加剧，纽约市居民经历了严重的风暴，包括2012年的桑迪飓风，以及最近的2021的亨利飓风和艾达飓风。在飓风“艾达”期间，美国国家气象局在五个自治区发布了有史以来第一次“突发洪水紧急状态”。东北部至少有40人在风暴中丧生，其中纽约市有13人，大部分人住在被洪水淹没的地下室公寓。',
      p2:
        '风暴过后不久，市长办公室发布的一份报告强调了极端降雨与气候变化之间的联系：“这些极端天气事件越来越成为新常态：这是一场席卷我们国家的气候危机……极端天气就在这里，真实存在，正在夺走人们的生命，”报告的作者写道。',
      p3: `尽管纽约市市长气候与环境正义办公室及其城市机构合作伙伴继续努力动员社区，为更严重的风暴做好准备，但所有人都将保护我们的社区和家园。`
    },
    aside: {
      body:
        'FloodNet 是一个由社区、研究人员和纽约市政府机构组成的合作组织，致力于更好地了解纽约市洪水的频率、严重程度和影响。获得的数据和知识可供当地居民、研究人员、城市机构和其他人使用，宣传并降低洪水风险。',
      link: '访问 FloodNet',
      icon: floodNet,
      iconAlt: 'Floodnet.nyc icon'
    },
    callToAction: {
      header:
        '了解如何采取行动通过改造保护您的财产',
      icon: homeAutomation,
      iconAlt: '房屋改造',
      buttonText: '阅读更多信息'
    }
  }
};
