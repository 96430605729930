import fillInBasement from '../images/fill-in-basement.svg';
import elevate from '../images/elevate.svg';
import elevateMechanicals from '../images/elevate-mechanicals.svg';
import abandonFirstFloor from '../images/abandon-first-floor.svg';
import floodVents from '../images/flood-vents.svg';

export default {
  title: 'Меры по предотвращению или снижению рисков',
  overview: 'Обзор',
  affectRate: 'Как это отразится на вашем тарифе страхования от наводнения',
  howItWorks: 'Как это работает',
  effort: 'Усилие',
  backwaterValve: 'Канализационные обратные клапаны: что вам необходимо знать',
  elevate: {
    meta: {
      title: 'Поднятие вашего дома',
      url: 'https://www.floodhelpny.org/ru/mitigation/elevate',
      description:
        'Поднятие вашего дома заключается в поднятии вашего сооружения выше предполагаемой высоты наводнения и размещении его на новом или расширенном фундаменте.'
    },
    title: 'Поднятие вашего дома',
    subtitle:
      'Поднятие вашего дома заключается в поднятии вашего сооружения выше предполагаемой высоты наводнения и размещении его на новом или расширенном фундаменте.',
    overview:
      'Обычно это достигается путем размещения вашего дома на временные опоры выше его теперешнего фундамента, затем надстраиванием или созданием нового более высокого фундамента, затем опусканием дома на новый фундамент. Поднятие дорогой процесс, и он требует вашего временного переселения, но он значительно сократит как ваш риск повреждения от наводнения, так и ваш страховой взнос.',
    image: elevate,
    imageAlt: 'Поднятие',
    affectRate:
      'Возвышение - это наиболее эффективный способ значительного сокращения вашего тарифа страхования от наводнения и уделжания его на низком уровне в будущем. Если ваш дом расположен с особо опасной зоне наводнения, основным фактором в определении ставки вашего страхованияот наводнения является разница между самой нижней точкой вашего дома и Базовой высотой наводнения. Если вы поднимете ваш дом выше Базовой высоты наводнения вы сократите ваш риск, что понизит ваш взнос. Если вы будете соответствовать критериям на более низкий тариф после возвышения, то место под вашим домом  модет быть использовано только в качестве склада, парковки машины и подъездного пути.',
    howItWorks:
      'Если ваш дом поднят выше Базовой высоты наводнения, то ваши жилые помещения и коммуникации будут скорее всего выше уровня воды в случае наводнения, защищая вашу собственность и ценные вещи от повреждения наводнением. Если вы выберете вариант поднятия, вы должны будете поднять ваш дом по крайней мере на два фута выше Базовой высоты наводнения, чтобы учитывать фактор неопределенности на картах наводнений и предполагаемого повышения уровня моря. Эти два дополнительных фута возвышения известны как "превышение над уровнем воды" и являются частью строительного кодекса города Нью-Йорк. Новая высота дома, включая превышение над уровнем воды, будет находиться на Расчетном уровне паводка.',
    effort:
      'Поднятие дома может занять до трех месяцев, в завасимости от подрядчика и конструкции. В это время вам нужно будет переехать и вывезти ценные вещи из вашего дома. Во время строительства в вашем доме будут отключены коммуникации, такие как вода, канализация и электричество.'
  },
  elevateMechanicals: {
    meta: {
      title: "Поднятие механического оборудования вашего дома",
      url: 'https://www.floodhelpny.org/ru/mitigation/elevate-mechanicals',
      description:
        "Механическое оборудование вашего дома - такое как котел отопления, система энергоснабжения и водопровод - являются жизненно важными."
    },
    title: "Поднятие механического оборудования вашего дома",
    subtitle:
      "Механическое оборудование вашего дома - такое как котел отопления, система энергоснабжения и водопровод - являются жизненно важными.",
    overview:
      'Когда сточные воды попадают в оборудование, оно может перестать правильно функционировать. Его замена или ремонт могут стоить сотни или тысячи долларов. Поэтому одним из наиболее эффективных вариантов сделать ваш дом менее подверженным наводнению является поднятие механического оборудования. Это может быть сделано путем поднятия оборудования выше уровня, на котором оно расположено на данный момент или переносом его из подвала или технического подпола на первый этаж или выше. В некоторых случаях домовдладельцы могут захотеть построить небольшую пристройку к их дому в качестве технического помещения выше высотной точки наводнения. К сожалению поднятие механических устройств сэкономит вам немного или не сэкономит ничего от вашего страхового взноса. Это также является относительно дорогим вариантом. ',
    image: elevateMechanicals,
    imageAlt: 'Поднятие механических устройств',
    affectRate:
      'Поднятие механического оборудования в вашем доме может сэкономить вам от 100 до 200 долларов США в год за ваш страховой взнос.',
    howItWorks:
      'Когда вы переместите ваше механическое оборудование выше высотной точки наводнения или из вашего подвала, вы значительно сократите риск нанесения повреждения вашим критическим системам во время наводнения. Вы не только сэкономите на дорогостоящем ремонте и стоимости замены, но также сможете вернуться домой после наводнения если ваши отопительные и электрические системы не повреждены. Одно это может сэкономить вам сотни и тысячи долларов затрат на временный переезд.',
    effort:
      'Перемещение механического оборудования вашего дома может занять от трех дней до нескольких недель, в завосимлсти от вашего дома и вашего подрядчика и какие системы вы решите переместить. В это время у вас будет лимитированный доступ или не будет доступа вообще к отоплению, горячей воде и электричеству.'
  },
  fillInBasement: {
    meta: {
      title: 'Засыпка подвала или технического подпола',
      url: 'https://www.floodhelpny.org/ru/mitigation/fill-in-basement',
      description:
        'Засыпка подвала или технического подпола песком, гравием или другим материалом до уровня земли может сократить ваш риск повреждения от наводнения и снизить ваш страховой взнос от наводнения.'
    },
    title: 'Засыпка подвала или технического подпола',
    subtitle:
      'Засыпка подвала или технического подпола песком, гравием или другим материалом до уровня земли может сократить ваш риск повреждения от наводнения и снизить ваш страховой взнос от наводнения.',
    overview:
      'Вам также понадобится установить паводковые отверстия в оставшейся надземной части подвала или технического подпола. В то время как засыпка вашего подвала может сначала показаться дорогостоящей, в конечном итоге это может сэкономить вам тысячи долларов вашего страхового взноса от наводнения. В некоторых случаях засыпка вашего подвала может повредить целостность конструкции вашего здания, поэтому чрезвычайно важно, чтобы вы проконсультировались с инженером, прежде чем начинать действовать.',
    image: fillInBasement,
    imageAlt: 'Засыпка подвала',
    affectRate:
      'Если ваш дом расположен в особом районе, подверженном затоплениям, главный фактором в определении вашего страхового взноса является разница между высотой первого этажа вашего дома (это ваш подвал) и Базовой высотой наводнения. Засыпая ваш подвал и устанавливая паводковые отверстия, вы сокращаете эту разницу, потому что ваш следующий этаж над уровнем земли становится самым нижним этажом вашего дома в целях страхования. Это снизит ваш страховой взнос от наводнения, но размер взноса будет варьироваться в зависимости от высоты земной поверхности по сравнению с Базовой высотой наводнения.',
    howItWorks:
      'Ликвидируя ваш подвал и устанавливая паводковые отверстия вы поднимаете высоту вашего самого нижнего этажа без фактического поднятия вашего дома - ваш первый этаж над землей станет "высотой нижнего этажа" вашего дома. Когда вы засыпаете ваш подвал вы также гарантируете, что все ваше содержимое находится над уровнем земли, что делает его менее подверженным повреждению в случае наводнения. Установка паводковых отверстий защищает фундамент и стены вашего дома от повреждения давлением воды. Однако, для того, чтобы засыпать подвал, вам придется отказаться от части жилой площади вашего дома, так что вам нужно подумать, перевешивают ли эти положительные стороны потерю этой площади. ',
    effort:
      'Засыпка подвала или технического подпола и установка паводковых отверстий может занять в среднем от одной до двух недель, после того, как все разрешения будут получены и строительство начнется.'
  },
  floodVents: {
    meta: {
      title: 'Установка паводковых отверстий',
      url: 'https://www.floodhelpny.org/ru/mitigation/flood-vents',
      description:
        'Установка паводковых отверстий заключается в добавлении отверстий под самым нижним этажом вашего дома, позволяющих воде проникать внутрь и вытекать в случае наводнения без нанесения повреждения несущих конструкций.'
    },
    title: 'Установка паводковых отверстий',
    subtitle:
      'Установка паводковых отверстий заключается в добавлении отверстий под самым нижним этажом вашего дома, позволяющих воде проникать внутрь и вытекать в случае наводнения без нанесения повреждения несущих конструкций.',
    overview:
      'Это одни из вамых недорогих имеющихся вариантов мер предотвращения, но этот вариант возможен только для тех домов, в которых механические коммуникации и контроли находятся выше, или могут быть перемещены выше специфической высоты, называемой Расчетным уровнем паводка. Если вы хотите установить паводковые клапаны или отверстия, любое помещение находящееся ниже Базовой высоты наводнения должно быть использовано только в качестве парковки, склада или подъезда к зданию, а также по крайней мере одна сторона помещения, находящегося ниже Базовой высоты наводнения, должна быть на уровне или выше поверхности земли.',
    image: floodVents,
    imageAlt: 'Паводковые отверстия',
    affectRate:
      'Установка паводковых отверстий понизит ваш страховой взнос от наводнения в том случае, если хотя бы одна сторона пола вашего подвала или технического подпола находится на уровне или выше уровня земли, или если это сделано в тандеме с засыпанием вашего подвала или технического подпола до уровня земли. В зонах высокого риска наводнения основным фактором  в определении вашего страхового взноса является разница между высотой первого этажа вашего дома и Базовой высотой наводнения. Если вы засыпете ваш подвал и установите паводковые отверстия, ваш следующий верхний этаж становится вашим "самым нижним этажом", что понизит ваш страховой взнос.',
    howItWorks:
      'Добавляя отверстия к сторонам вашего здания вы сокращаете риск повреждения давлением воды (гидростатическими и гидродинамическими силами). В случае наводнения вода может свободно втекать и вытекать из вашего дома через отверстия, без необходимости привлечения человека, что сокращает риск повреждения несущих конструкций ваших стен или фундамента.',
    effort:
      'Если ваши коммуникации уже расположены выше Расчетного уровня паводка и у вас нет подвала или технического подпола, которые вам нужно засыпать, установка паводковых отверстий производится довольно легко и не должна занять более нескольких дней. Если вам будет нужно засыпать подвал или технический подпол и/или поднять механическое оборудование, влажная противопаводковая защита станет более сложной, продолжительной и дорогой.'
  },
  abandonFirstFloor: {
    meta: {
      title: 'Отказ от вашего первого этажа',
      url: 'https://www.floodhelpny.org/ru/mitigation/abandon-first-floor',
      description:
        'Отказ от первого этажа вашей недвижимости заключается в том, что вы будете использовать второй этаж или еще более высокий в качестве вашего жилого помещения, и использовать первый этаж в качестве склада, стоянки для машины или подъезда.'
    },
    title: 'Отказ от вашего первого этажа',
    subtitle:
      'Отказ от первого этажа вашей недвижимости заключается в том, что вы будете использовать второй этаж или еще более высокий в качестве вашего жилого помещения, и использовать первый этаж в качестве склада, стоянки для машины или подъезда.',
    overview:
      'If you have a basement or sub-ground crawlspace, you’ll need to fill that in, too. You’ll need to move all the utilities above the Design Flood Elevation or place them outside in an elevated structure. You will also need to make the first floor “unfinished” (with no drywall) and add flood vents or openings below the Design Flood Elevation for your property. This can be an expensive strategy, but it can significantly reduce your insurance premium and risk. It also may mean giving up a significant portion of your home’s living area, which is an important trade off to consider when deciding whether or not to adopt this mitigation option.',
    image: abandonFirstFloor,
    imageAlt: 'Отказ от первого этажа',
    affectRate:
      'Если у вас есть подвал или технический подпол ниже уровня земли, то вам нужно будет их тоже заполнить. Вам нужно будет переместить все коммуникации выше Расчетного уровня паводка или разместить их снаружи в надземном сооружении. Вам также нужно будет сделать первый этаж "незаконченным" (без сухой штукатурки) и добавить паводковые клапаны или отверстия ниже Расчетного уровня паводка для вашего дома. Это может быть дорогой стратегией, но это может значительно сократить ваш страховой взнос и риск. Это также может означать отказ от значительной части жилой площади вашего дома, что является важным минусом, который нужно принять во внимание при принятии решения выбирать или нет этот вариант мер предотвращения.',
    howItWorks:
      'Отказ от первого этажа вашего дома может поднять его до или выше Базовой высоты наводнения. Это повышает вероятность того, что он будет защищен от паводковых вод, сохраняя вашу недвижимость и ваши ценные вещи от подвреждения.',
    effort:
      'Отказ от вашего первого этажа требует временного переселения, а также дополнительного строительства по передвижению вашего механического оборудования и бытовых приборов, и строительства альтернативного входа в ваш дом после того, как ваш первый этаж будет заполнен.'
  }
};