export default {
  header: 'APPLICATION INFO:',
  // All questions keys must be listed here with a corresponding title.
  // Use the yes or no keys as replacements for boolean answers (true, false)
  yes: 'Yes',
  no: 'No',
  co_applicant_first_name: 'First Name',
  co_applicant_last_name: 'Last Name',
  co_applicant_birthdate: 'Birth Date (YYYY-MM-DD)',
  co_applicant_email: 'Email',
  co_applicant_home_phone: 'Home Phone',
  co_applicant_mobile_phone: 'Mobile Phone',
  co_applicant_signature: 'Signature',
  co_applicant_type: 'Co Applicant Type',
  e_signature: 'E-Signature',
  household_member_race: 'Race',
  household_member_income: 'Annual Income',
  household_member_ethnicity: 'Ethnicity',
  household_member_gender: 'Gender',
  sandy_compensation: 'Sandy Compensation',
  sandy_insurance_types: 'Insurance Held During Superstorm Sandy',
  tenants: 'Tenants',
  flood_insurance: 'Flood Insurance Document(s)',
  homeowners_insurance: 'Homeowners Insurance Document(s)',
  household_member_proofincome: 'Proof of Income for Household',
  backwater_valve_compensation: 'Backwater Compensation',
  applicant_race: 'Main Applicant Race',
  applicant_ethnicity: 'Main Applicant Ethnicity',
  applicant_gender: 'Main Applicant Gender Identity',
  applicant_birthdate: 'Main Applicant Birthdate'
};
