import getRaceOptions from '../constants/RaceOptions';
import getEthnicityOptions from '../constants/EthnicityOptions';
import getGenderOptions from '../constants/GenderOptions';

const ALLOWED_ATTACHMENTS = 'Attach (PDF, JPEG, PNG)';

export default {
  nextWebstatus: 'ApplicationSubmitted',
  errorTitle: 'Yon bagay pase mal lè ou ap soumèt aplikasyon ou an.',
  errorBody:
    'Tanpri eseye soumèt li ankò. Si pwoblèm lan pèsiste, kontakte nou nan info@floodhelpny.org.',
  tryAgain: 'Eseye ankò',
  loadingText: 'Ap telechaje...',
  navCopy: {
    next: 'Analize',
    previous: 'Anvan',
    submit: 'Soumèt',
    checkResponses: 'Verifye done ou te antre yo valid anvan ou soumèt yo'
  },
  applicant: 'Aplikan:',
  address: 'Adrès:',
  providerQuestions: [
    {
      headline:
        'Tanpri fè nou konnen kiyès k ap viv nan fwaye ou an oswa kiyès nou gendwa konsidere antanke yon ko-aplikan (pa egzanp: tout moun ki sou lis tit pwopriyete an):',
      title: 'Aplikan Anplis',
      buttonText: 'Montre Kesyon Yo Poze Pi Souvan yo',
      addRow: true,
      addRowText: 'Ajoute Aplikan',
      removeRow: false,
      removeRowText: 'Retire Aplikan',
      faqZone: 'bwv-additional-applicants',
      responseFields: [
        {
          key: 'co_applicant_type',
          title: 'Lòt Aplikan',
          applicant_type: '',
          show: false,
          default: 'Chwazi Youn',
          type: 'select-applicant-type',
          options: [
            { value: 'Co-applicant', display: 'Ko-Aplikan' },
            { value: 'Household-member', display: 'Manm Fwaye an' }
          ],
          required: false
        },
        {
          key: 'co_applicant_first_name',
          title: 'Prenon',
          applicant_type: 'both',
          show: false,
          placeholder: '',
          type: 'text',
          required: false
        },
        {
          key: 'co_applicant_last_name',
          title: 'Siyati',
          applicant_type: 'both',
          show: false,
          placeholder: '',
          type: 'text',
          required: false
        },
        {
          key: 'household_member_income',
          title: 'Revni Anyèl',
          applicant_type: 'Household-member',
          show: false,
          placeholder: 'e.g. 50000',
          type: 'number',
          required: false
        },
        {
          key: 'household_member_race',
          title: 'Ras',
          applicant_type: 'Household-member',
          show: false,
          default: 'Chwazi Youn',
          type: 'drop-down',
          options: getRaceOptions('ht_HT'),
          required: false
        },
        {
          key: 'household_member_ethnicity',
          title: 'Etnisite',
          applicant_type: 'Household-member',
          show: false,
          default: 'Chwazi Youn',
          type: 'drop-down',
          options: getEthnicityOptions('ht_HT'),
          required: false
        },
        {
          key: 'household_member_gender',
          title: 'Sèks',
          applicant_type: 'Household-member',
          show: false,
          default: 'Chwazi Youn',
          type: 'drop-down',
          options: getGenderOptions('ht_HT'),
          required: false
        },
        {
          key: 'co_applicant_birthdate',
          title: 'Dat Nesans',
          applicant_type: 'Co-applicant',
          show: false,
          placeholder: 'e.g. 10/13/1987',
          type: 'date',
          minimum: '1900-01-01',
          maximum: '2020-01-01',
          required: false
        },
        {
          key: 'co_applicant_email',
          title: 'Imèl',
          applicant_type: 'Co-applicant',
          show: false,
          placeholder: 'e.g. example@example.com',
          type: 'email',
          required: false
        },
        {
          key: 'co_applicant_home_phone',
          title: 'Telefòn Kay',
          applicant_type: 'Co-applicant',
          show: false,
          placeholder: 'e.g. +1 123-456-7890',
          type: 'tel',
          required: false
        },
        {
          key: 'co_applicant_mobile_phone',
          title: 'Telefòn Mobil',
          applicant_type: 'Co-applicant',
          show: false,
          placeholder: 'e.g. +1 123-456-7890',
          type: 'tel',
          required: false
        },
        {
          key: 'co_applicant_signature',
          title: 'Siyati',
          applicant_type: 'Co-applicant',
          show: false,
          placeholder: '',
          type: 'text',
          required: false
        }
      ]
    },
    {
      title: 'Konpansasyonpou Sandy',
      headline:
        'Èske ou te resevaw asistans ki lye ak Sipètanpèt Sandy nan men FEMA oswa lòt sous pou domaj kont pwopriyete an?',
      buttonText: 'Montre Kesyon Yo Poze Pi Souvan yo',
      faqZone: 'bwv-sandy-compensation',
      responseFields: [
        {
          key: 'sandy_compensation',
          title: '',
          show: true,
          type: 'button-single',
          options: [
            { value: 'true', display: 'Wi' },
            { value: 'false', display: 'Non' }
          ],
          invalidAnswers: [],
          alertAnswers: [],
          required: true
        }
      ]
    },
    {
      title: 'Konpansasyon pou Valv pou Dlo k ap Tounen',
      headline:
        'Èske ou te resevwa sibvansyon oswa lòt fon pou enstalasyon yon valv dlo k ap tounen nan egou nan pwopriyete an?',
      buttonText: 'Montre Kesyon Yo Poze Pi Souvan yo',
      faqZone: 'bwv-valve-compensation',
      responseFields: [
        {
          key: 'backwater_valve_compensation',
          title: '',
          show: true,
          type: 'button-single',
          options: [
            { value: 'true', display: 'Wi' },
            { value: 'false', display: 'Non' }
          ],
          invalidAnswers: [],
          alertAnswers: [],
          required: true
        }
      ]
    },
    {
      title: 'Kalite Asirans pou Sandy',
      headline: 'Ki kalite asirans ou te genyen pandan Sipètanpèt Sandy an?',
      buttonText: 'Montre Kesyon Yo Poze Pi Souvan yo',
      faqZone: 'bwv-insurance-types',
      responseFields: [
        {
          key: 'sandy_insurance_types',
          type: 'multiple-drop-down',
          size: 6,
          show: true,
          multiple: true,
          title: 'Chwazi Plizyè',
          options: [
            {
              value: 'Homeowners Insurance',
              display: 'Asirans pou Pwopriyetè Kay'
            },
            { value: 'Flood Insurance', display: 'Asirans kont Inondasyon' },
            { value: 'Hazard Insurance', display: 'Asirans kont Danje' },
            { value: 'Wind Insurance', display: 'Asirans kont Van' },
            { value: 'Contents Insurance', display: 'Asirans kont Kontni' },
            { value: 'Other Insurance', display: 'Lòt Asirans' }
          ],
          required: false
        }
      ]
    },
    {
      title: 'Lokatè',
      headline: 'Èske ou gen lokatè k ap viv aktyèlman nan rezidans ou an?',
      buttonText: 'Montre Kesyon Yo Poze Pi Souvan yo',
      faqZone: 'bwv-tenants',
      responseFields: [
        {
          key: 'tenants',
          title: '',
          show: true,
          type: 'button-single',
          options: [
            { value: 'true', display: 'Wi' },
            { value: 'false', display: 'Non' }
          ],
          invalidAnswers: [],
          alertAnswers: ['true'],
          alertCopy: 'Tanpri telechaje ',
          alertLinkCopy: 'fòmilè kalifikasyon pou revni lokatè an. ',
          alertLink: 'http://www.floodhelpny.org/samples/tenantIncome.pdf',
          alertCopyAfterLink: 'Ou pral bezwen ranpli sa epi tounen l ban nou.',
          required: true
        }
      ]
    },
    {
      title: 'Prèv Asirans pou Pwopriyetè Kay',
      headline: 'Tanpri atache prèv asirans ou pou pwopriyetè kay',
      buttonText: 'Montre Kesyon Yo Poze Pi Souvan yo',
      faqZone: 'bwv-homeowner-insurance',
      responseFields: [
        {
          key: 'homeowners_insurance',
          title: 'homeowner',
          label: ALLOWED_ATTACHMENTS,
          show: true,
          placeholder: '',
          type: 'file',
          remove: 'Retire Fichye',
          canUploadMultipleFiles: false,
          fileTypes: '.jpeg, .png, .pdf',
          required: false
        }
      ]
    },
    {
      title: 'Prèv Asirans kont Inondasyon',
      headline:
        'Tanpri atache prèv asirans kont inondasyon ou an (si zòn inondasyonou an se AE, AO oswa VE)',
      buttonText: 'Montre Kesyon Yo Poze Pi Souvan yo',
      faqZone: 'bwv-flood-insurance',
      responseFields: [
        {
          key: 'flood_insurance',
          title: 'flood',
          label: ALLOWED_ATTACHMENTS,
          show: true,
          placeholder: '',
          type: 'file',
          remove: 'Retire Fichye',
          canUploadMultipleFiles: false,
          fileTypes: '.jpeg, .png, .pdf',
          required: false
        }
      ]
    },
    {
      title: 'Prèv Revni',
      headline: 'Tanpri atache prèv revni pou aplikan an ak tout manm fwaye yo',
      buttonText: 'Montre Kesyon Yo Poze Pi Souvan yo',
      faqZone: 'bwv-income-proof',
      responseFields: [
        {
          key: 'household_member_proofincome',
          title: 'income',
          label: ALLOWED_ATTACHMENTS,
          show: true,
          type: 'file',
          remove: 'Retire Fichye',
          canUploadMultipleFiles: false,
          fileTypes: '.jpeg, .png, .pdf',
          required: false
        }
      ]
    },
    {
      title: 'Ras',
      headline: 'Tanpri chwazi ras aplikan prensipal lan',
      faqZone: '',
      responseFields: [
        {
          key: 'applicant_race',
          type: 'drop-down',
          show: true,
          multiple: false,
          default: 'Chwazi Youn',
          title: '',
          options: getRaceOptions('ht_HT'),
          required: false
        }
      ]
    },
    {
      title: 'Etnisite',
      headline: 'Tanpri chwazi etnisite aplikan prensipal lan',
      faqZone: '',
      responseFields: [
        {
          key: 'applicant_ethnicity',
          type: 'drop-down',
          show: true,
          multiple: false,
          default: 'Chwazi Youn',
          title: '',
          options: getEthnicityOptions('ht_HT'),
          required: false
        }
      ]
    },
    {
      title: 'Sèks',
      headline: 'Tanpri chwazi idantite seksyèl aplikan prensipal lan',
      faqZone: '',
      responseFields: [
        {
          key: 'applicant_gender',
          type: 'drop-down',
          show: true,
          multiple: false,
          default: 'Chwazi Youn',
          title: '',
          options: getGenderOptions('en'),
          required: false
        }
      ]
    },
    {
      title: 'Dat nesans',
      headline: 'Tanpri anre dat nesans aplikan prensipal lan',
      faqZone: '',
      responseFields: [
        {
          key: 'applicant_birthdate',
          title: '',
          show: true,
          placeholder: 'e.g. 10/13/1987',
          type: 'date',
          minimum: '1900-01-01',
          maximum: '2020-01-01',
          required: false
        }
      ]
    },
    {
      title: 'Siyati elektwonik',
      headline:
        'Lè ou tape non ou nan bwat ki anba a, ou dakò ak tèm epi kondisyon pwogram Valv pou Dlo k Ap Tounen an epi sètifye ou te li politik sou konfidansyalite nou an',
      buttonText: 'Montre Kesyon Yo Poze Pi Souvan yo',
      faqZone: 'bwv-signature',
      responseFields: [
        {
          key: 'e_signature',
          title: 'Siyati elektwonik',
          show: true,
          placeholder: '',
          type: 'text',
          required: true
        }
      ]
    }
  ],
  // NOTE the keys in the answers array must match the keys in thq questions Array
  // and be in the same order
  providerAnswers: [
    {
      co_applicant_type: [],
      co_applicant_first_name: '',
      co_applicant_last_name: '',
      household_member_income: '',
      household_member_race: '',
      household_member_ethnicity: '',
      household_member_gender: '',
      co_applicant_birthdate: '',
      co_applicant_email: '',
      co_applicant_home_phone: '',
      co_applicant_mobile_phone: '',
      co_applicant_signature: ''
    },
    { sandy_compensation: '' },
    { backwater_valve_compensation: '' },
    { sandy_insurance_types: [] },
    { tenants: '' },
    { homeowners_insurance: '' },
    { flood_insurance: '' },
    { household_member_proofincome: '' },
    { applicant_race: '' },
    { applicant_ethnicity: '' },
    { applicant_gender: '' },
    { applicant_birthdate: '' },
    { e_signature: '' }
  ]
};
