export default {
  genericError: {
    title: 'Erè',
    error:
      'Nou dezole. Nou pa t kapab jwenn enfòmasyon pou adrès ou te antre an. Tanpri verifye adrès lan oswa eseye ankò pita. Si ou santi ou te resevwa mesaj sa a nan erè, tanpri rele Platfòm pou Pwopriyetè nou an nan 646-786-0888.'
  },
  multipleResultsError: {
    title: 'Erè',
    error:
      'Nou dezole. Gen plizyè korespondans pou adrès ou te antre an. Tanpri verifye adrès lan oswa eseye ankò pita. Si ou santi ou te resevwa mesaj sa a nan erè, tanpri rele Platfòm pou Pwopriyetè nou an nan 646-786-0888.'
  },
  outsideNycError: {
    title: 'Erè',
    error:
      'Nou dezole. Adrès sa a pa parèt koresponn donen ki disponib piblikman apati NYC. Si ou santi ou te resevwa mesaj sa a nan erè, tanpri rele Platfòm pou Pwopriyetè nou an nan 646-786-0888.'
  },
  tooBroadError: {
    title: 'Erè',
    error:
      'FloodHelpNY.org founi enfòmasyon sou asirans kont inondasyon epi opsyon pou tolerans baze sou adrès endividyèl. Tanpri antre adrès lakay ou oswa adrès yon lòt kay ou ta renmen chèche. Oswa, si ou santi ou te resevwa mesaj sa a nan erè, tanpri rele Platfòm pou Pwopriyetè Kay nou an nan 646-786-0888.'
  },
  noRouteError: {
    title: 'Erè',
    error:
      'Nou dezole. Adrès sa a pa koresponn ak done ki disponib piblikman apati Google Maps. Si ou santi ou te resevwa mesaj sa a nan erè, tanpri rele Platfòm pou Pwopriyetè nou an nan 646-786-0888.'
  }
};
