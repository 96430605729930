import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import RateCalculatorRadioInput from './RateCalculatorRadioInput';
import NumberOfFloorsNavigator from '../helpers/calculatorNavigation/NumberOfFloorsNavigator';

const RateCalculatorQuestionNumberOfFloors = ({
  provider,
  answers,
  setAnswers,
  setNextQuestion
}) => {
  useEffect(() => {
    if (answers) {
      setNextQuestion(NumberOfFloorsNavigator.nextComponent(answers));
    }
  }, [answers, setNextQuestion]);

  return (
    <div className="question number-of-floors-question">
      <h4>{provider.question}</h4>
      <RateCalculatorRadioInput
        radioKey="number-of-floors"
        responseField={provider.responseField}
        answer={answers.moreThanOneFloor}
        setResponse={(e) =>
          setAnswers((prevAnswers) => ({
            ...prevAnswers,
            moreThanOneFloor: e.target.value === 'true'
          }))}
      />
    </div>
  );
};

RateCalculatorQuestionNumberOfFloors.propTypes = {
  provider: PropTypes.object.isRequired,
  answers: PropTypes.object.isRequired,
  setAnswers: PropTypes.func.isRequired,
  setNextQuestion: PropTypes.func.isRequired
};

export default RateCalculatorQuestionNumberOfFloors;
