import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import HashLinkObserver from 'react-hash-link';
import Matchers from '../constants/Matchers';
import SalesforceCommunicator from '../communicators/SalesforceCommunicator';
import BuildingApiCommunicator from '../communicators/BuildingApiCommunicator';
import ParametricSalesforceCommunicator from '../communicators/ParametricCommunicator';
import GoogleMapsPlacesHelper from '../helpers/mapHelpers/GoogleMapsPlacesHelper';
import Loading from './sharedComponents/Loading';
import Button from './ui/Button';
import {
  useLocale,
  useProvider,
  useApi,
  useGoogleMaps
} from '../helpers/hooks';
import { colors, borderRadius } from '../styles/variables';
import { StyledInput } from './ui/StyledInput';
import AuditIncomeThresholds from '../constants/AuditIncomeThresholds';
import GoogleMaps from '../helpers/GoogleMaps';
import { Section } from './ui/StyledResourcePageElements';

const ThankYouContainer = styled.div`
  border: 10px solid ${colors.resilientBlue};
  padding: 2rem;
  max-width: 1000px;
  margin: 0 auto;
`;

const Form = styled.div`
  border: 10px solid ${colors.resilientBlue};
  padding: 2rem;
  max-width: 1000px;
  margin: 0 auto;
`;

const FormTitle = styled.div`
  font-weight: bold;
  border-bottom: 1px solid #ccc;
`;

const FormItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 2rem;
  align-items: center;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const FormItemTitle = styled.div`
  font-weight: bold;
  width: 40%;
  padding-right: 1.5rem;

  @media (max-width: 768px) {
    text-align: center;
  }

  @media (max-width: 420px) {
    width: auto;
  }
`;

const FormSubItemContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  width: 60%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const FormSubItem = styled.div`
  width: 45%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const InputWrapper = styled.div`
  width: 60%;

  @media (max-width: 768px) {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;

const InputDescription = styled.span`
  padding-left: 5px;
`;

const Checkbox = styled(FormItem)`
  justify-content: center;
`;

const CheckboxField = styled(FormItem)`
  label {
    max-width: 70%;
    padding-right: 3rem;
  }

  input {
    margin-left: 60px;
    width: 30px;
    height: 30px;
  }
`;

const SubmitWrapper = styled.div`
  padding-top: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 500px) {
    flex-direction: column-reverse;
  }
`;

const Terms = styled.span`
  font-size: small;
  text-align: right;
  margin-right: 1rem;
  width: 50%;

  @media (max-width: 500px) {
    margin: 1rem 0 0 0;
    width: 100%;
    text-align: center;
  }
`;

const Input = styled(StyledInput)`
  width: 100%;
`;

const SelectWrapper = styled.div`
  width: 60%;
`;

const Select = styled.select`
  display: block;
  padding: 0 15px;
  border: 1px solid ${colors.grayScale[1]};
  border-radius: ${borderRadius};
  background-color: #fff;
  font: inherit;
  outline: none;
  transition: all 0.25s ease;
  height: 60px;
  width: 100%;

  &:focus {
    border: 1px solid ${colors.accent.main};
    box-shadow: inset 0 0 0 1px ${colors.accent.main};
  }

  &:required {
    border: 1px solid ${colors.pinkBerry};
  }

  &:disabled {
    border: 1px solid ${colors.grayScale[1]};
    cursor: not-allowed;
  }

  & + label {
    margin-top: 30px;
  }

  @media (max-width: 767px) {
    width: 100%;

    & + button {
      margin-top: 15px;
    }
  }
`;

const ErrorMessage = styled.div`
  color: ${colors.pinkBerry};
`;

const ParametricIntakeForm = () => {
  const provider = useProvider('ParametricIntakeForm');
  const locale = useLocale();
  const { googleMapsScriptUrl } = useGoogleMaps();
  const addressRef = useRef(null);
  const { parametricApi, buildingApi } = useApi();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [secondPhone, setSecondPhone] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');
  const [birthdate, setBirthdate] = useState('');
  const [stormDate, setStormDate] = useState('');
  const [bestWayToContact, setBestWayToContact] = useState('');
  const [preferredLanguage, setPreferredLanguage] = useState('');
  const [propertyType, setPropertyType] = useState('');
  const [numberOfUnits, setNumberOfUnits] = useState('');
  const [householdSize, setHouseholdSize] = useState('');
  const [income, setIncome] = useState('');
  const [floodInsurance, setFloodInsurance] = useState('');
  const [organization, setOrganization] = useState('');
  const [property, setProperty] = useState({});
  const [address, setAddress] = useState('');
  const [howDidYourHearAboutUs, setHowDidYourHearAboutUs] = useState('');
  const [willSignUpForNewsletter, setWillSignupForNewsletter] = useState(false);
  const [contacted, setContacted] = useState(false);
  const [addressError, setAddressError] = useState(null);
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [stateError, setStateError] = useState(false);
  const [zipError, setZipError] = useState(false);
  const [birthdateError, setBirthdateError] = useState(false);
  const [stormDateError, setStormDateError] = useState(false);
  const [bestWayToContactError, setBestWayToContactError] = useState(false);
  const [preferredLanguageError, setPreferredLanguageError] = useState(false);
  const [propertyTypeError, setPropertyTypeError] = useState(false);
  const [numberOfUnitsError, setNumberOfUnitsError] = useState(false);
  const [householdSizeError, setHouseholdSizeError] = useState(false);
  const [incomeHouseholdError, setIncomeHouseholdError] = useState(false);
  const [incomeHouseholdIncomplete, setIncomeHouseholdIncomplete] = useState(
    false
  );
  const [floodInsuranceError, setFloodInsuranceError] = useState(false);
  const [howDidYourHearAboutUsError, setHowDidYourHearAboutUsError] = useState(
    false
  );
  const [contactedError, setContactedError] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [thankYouMessage, setThankyouMessage] = useState(false);

  const updateAddressFromListener = (autocomplete) => {
    const location = new GoogleMapsPlacesHelper(
      autocomplete.getPlace()
    ).getLocation();
    setAddress(location.formatted_address);
    setProperty(location);
  };

  useEffect(() => {
    const addressInput = addressRef.current;
    const autocomplete = GoogleMaps.createAutocomplete(addressInput);
    autocomplete.addListener('place_changed', () => {
      updateAddressFromListener(autocomplete);
    });
  }, [googleMapsScriptUrl, addressRef]);

  const handleEligibilityLookupError = (error) => {
    let errorMessage = provider.errors.genericBuildingData;
    const { data } = error;
    if (data.outside_nyc) {
      errorMessage = provider.errors.outsideNyc;
    } else if (data.multiple_results) {
      errorMessage = provider.errors.multipleResults;
    }
    setIsLoading(false);
    setAddressError(errorMessage);
  };

  const resetErrors = () => {
    setAddressError(null);
    setSubmitError(false);
    setNameError(false);
    setEmailError(false);
    setCityError(false);
    setStateError(false);
    setZipError(false);
    setPhoneError(false);
    setBirthdateError(false);
    setStormDateError(false);
    setBestWayToContactError(false);
    setPreferredLanguageError(false);
    setPropertyTypeError(false);
    setNumberOfUnitsError(false);
    setIncomeHouseholdError(false);
    setIncomeHouseholdIncomplete(false);
    setFloodInsuranceError(false);
    setHowDidYourHearAboutUsError(false);
    setContactedError(false);
    setHouseholdSizeError(false);
  };

  const validateResponses = () => {
    let areResponsesValid = true;

    if (firstName === '' || lastName === '') {
      setNameError(true);
      areResponsesValid = false;
    }

    if (city === '') {
      setCityError(true);
      areResponsesValid = false;
    }

    if (address === '') {
      setAddressError(true);
      areResponsesValid = false;
    }

    if (state === '') {
      setStateError(true);
      areResponsesValid = false;
    }

    if (email.search(Matchers.email) === -1) {
      setEmailError(true);
      areResponsesValid = false;
    }

    if (zip.search(Matchers.zipcode) && zip.length !== 5) {
      setZipError(true);
      areResponsesValid = false;
    }

    if (phone.search(Matchers.phone) === -1) {
      setPhoneError(true);
      areResponsesValid = false;
    }

    if (!Matchers.date.test(birthdate)) {
      setBirthdateError(true);
      areResponsesValid = false;
    }

    if (!Matchers.date.test(stormDate)) {
      setStormDateError(true);
      areResponsesValid = false;
    }

    if (bestWayToContact === '') {
      setBestWayToContactError(true);
      areResponsesValid = false;
    }

    if (preferredLanguage === '') {
      setPreferredLanguageError(true);
      areResponsesValid = false;
    }

    if (propertyType === '') {
      setPropertyTypeError(true);
      areResponsesValid = false;
    }

    if (numberOfUnits === '') {
      setNumberOfUnitsError(true);
      areResponsesValid = false;
    }

    if (householdSize === '') {
      setHouseholdSizeError(true);
      areResponsesValid = false;
    }

    if (floodInsurance === '') {
      setFloodInsuranceError(true);
      areResponsesValid = false;
    }

    if (howDidYourHearAboutUs === '') {
      setHowDidYourHearAboutUsError(true);
      areResponsesValid = false;
    }

    if (contacted === false) {
      setContactedError(true);
      areResponsesValid = false;
    }

    return areResponsesValid;
  };

  const hasValidationErrors = () =>
    nameError ||
    addressError ||
    cityError ||
    stateError ||
    zipError ||
    emailError ||
    phoneError ||
    birthdateError ||
    stormDateError ||
    bestWayToContactError ||
    preferredLanguageError ||
    propertyTypeError ||
    numberOfUnitsError ||
    incomeHouseholdError ||
    incomeHouseholdIncomplete ||
    floodInsuranceError ||
    howDidYourHearAboutUsError ||
    contactedError ||
    householdSizeError;

  const getFormattedAnswers = () => {
    return {
      contact: {
        first_name: firstName,
        last_name: lastName,
        email: email,
        mobile_phone: phone,
        home_phone: secondPhone,
        birthdate: birthdate,
        mailing_street: address,
        mailing_postal_code: zip,
        mailing_city: city,
        mailing_state: state,
        communication_preference: bestWayToContact,
        primary_language: preferredLanguage,
        property_type: propertyType,
        how_many_rental_units: numberOfUnits,
        household_size_ages_unknown: householdSize
      },
      kase: {
        date_of_storm: stormDate,
        flood_insurance: floodInsurance,
        how_did_you_hear_about_us: howDidYourHearAboutUs,
        survey_opt_in: contacted,
        organization: organization
      }
    };
  };

  const handleSubmitError = () => {
    setSubmitError(true);
    setIsLoading(false);
  };

  const handleSubmitSuccess = (response) => {
    setThankyouMessage(true);
  };

  const handleSalesforceFormSubmission = async () => {
    const answers = getFormattedAnswers();

    try {
      const result = await ParametricSalesforceCommunicator.submitIntakeForm(
        parametricApi,
        answers
      );
      setIsLoading(false);
      handleSubmitSuccess(result);
    } catch (error) {
      handleSubmitError();
    }
  };

  const handleInNyc = (response) => {
    if (response.outside_nyc) {
      setAddressError(provider.errors.ineligible);
      setIsLoading(false);
      return;
    } else {
      handleSalesforceFormSubmission();
    }
  };

  const handleSubmit = async (e) => {
    const eligibilityParams = {
      api: buildingApi,
      address
    };
    resetErrors();

    if (validateResponses()) {
      setIsLoading(true);
      try {
        const result = await BuildingApiCommunicator.checkEligibility(
          eligibilityParams
        );
        handleInNyc(result);
      } catch (error) {
        handleEligibilityLookupError(error.response);
      }
    }
  };

  return (
    <Section noBorder id="intake-form">
      <HashLinkObserver />
      {!thankYouMessage ? (
        <Form data-testid="intake-form">
          <FormTitle>FLOOD RECOVERY FUND (FRF) INTAKE FORM</FormTitle>
          <FormItem>
            <FormItemTitle>{provider.name}</FormItemTitle>
            <InputWrapper>
              <FormSubItemContainer>
                <FormSubItem>
                  {/* For all inputs that have a required boolean it is set to true
                  if custom validation fails in the handleSubmit function */}
                  <Input
                    required={nameError}
                    type="text"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                  <InputDescription>{provider.first}</InputDescription>
                </FormSubItem>
                <FormSubItem>
                  <Input
                    required={nameError}
                    type="text"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                  <InputDescription>{provider.last}</InputDescription>
                </FormSubItem>
              </FormSubItemContainer>
              {nameError && <ErrorMessage>{provider.errors.name}</ErrorMessage>}
            </InputWrapper>
          </FormItem>

          <FormItem>
            <FormItemTitle>{provider.address}</FormItemTitle>
            <InputWrapper>
              <Input
                required={addressError}
                ref={addressRef}
                type="text"
                placeholder={provider.addressPlaceholder}
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
              {addressError && <ErrorMessage>{addressError}</ErrorMessage>}
            </InputWrapper>
          </FormItem>
          <FormItem>
            <FormItemTitle>{provider.city}</FormItemTitle>
            <InputWrapper>
              <Input
                required={cityError}
                type="text"
                placeholder={provider.cityPlaceHolder}
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
              {cityError && <ErrorMessage>{provider.errors.city}</ErrorMessage>}
            </InputWrapper>
          </FormItem>
          <FormItem>
            <FormItemTitle>{provider.state}</FormItemTitle>
            <InputWrapper>
              <Input
                required={stateError}
                type="text"
                placeholder={provider.statePlaceholder}
                value={state}
                onChange={(e) => setState(e.target.value)}
              />
              {stateError && (
                <ErrorMessage>{provider.errors.state}</ErrorMessage>
              )}
            </InputWrapper>
          </FormItem>

          <FormItem>
            <FormItemTitle>{provider.zip}</FormItemTitle>
            <InputWrapper>
              <Input
                required={zipError}
                type="number"
                placeholder={provider.zipPlaceholder}
                value={zip}
                onChange={(e) => setZip(e.target.value)}
              />
              {zipError && <ErrorMessage>{provider.errors.zip}</ErrorMessage>}
            </InputWrapper>
          </FormItem>

          <FormItem>
            <FormItemTitle>{provider.email}</FormItemTitle>
            <InputWrapper>
              <Input
                required={emailError}
                type="email"
                htmlFor={email}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder={provider.exampleEmail}
              />
              {emailError && (
                <ErrorMessage>{provider.errors.email}</ErrorMessage>
              )}
            </InputWrapper>
          </FormItem>
          <FormItem>
            <FormItemTitle>{provider.phone}</FormItemTitle>
            <InputWrapper>
              <Input
                required={phoneError}
                type="tel"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                placeholder="e.g. 123-456-7890"
              />
              {phoneError && (
                <ErrorMessage>{provider.errors.phone}</ErrorMessage>
              )}
            </InputWrapper>
          </FormItem>
          <FormItem>
            <FormItemTitle>{provider.homePhone}</FormItemTitle>
            <InputWrapper>
              <Input
                type="tel"
                value={secondPhone}
                onChange={(e) => setSecondPhone(e.target.value)}
                placeholder="e.g. 123-456-7890"
              />
            </InputWrapper>
          </FormItem>
          <FormItem>
            <FormItemTitle>{provider.birthdate}</FormItemTitle>
            <InputWrapper>
              <Input
                required={birthdateError}
                type="text"
                placeholder={provider.birthdatePlaceHolder}
                value={birthdate}
                onChange={(e) => setBirthdate(e.target.value)}
              />
              {birthdateError && (
                <ErrorMessage>{provider.errors.date}</ErrorMessage>
              )}
            </InputWrapper>
          </FormItem>
          <FormItem>
            <FormItemTitle>Date of storm</FormItemTitle>
            <InputWrapper>
              <Input
                required={stormDateError}
                type="text"
                placeholder="e.g. 06/02/2023"
                value={stormDate}
                onChange={(e) => setStormDate(e.target.value)}
              />
              {stormDateError && (
                <ErrorMessage>{provider.errors.date}</ErrorMessage>
              )}
            </InputWrapper>
          </FormItem>
          <FormItem>
            <FormItemTitle>{provider.bestWayToContact}</FormItemTitle>
            <SelectWrapper>
              <Select
                id="bestWayToContact"
                required={bestWayToContactError}
                onChange={(e) => setBestWayToContact(e.target.value)}
              >
                <option hidden disabled selected value>
                  {provider.bestWayToContactDefault}
                </option>
                {provider.bestWayToContactOptions.map((option) => (
                  <option value={option}>{option}</option>
                ))}
              </Select>
              {bestWayToContactError && (
                <ErrorMessage>{provider.errors.bestWayToContact}</ErrorMessage>
              )}
            </SelectWrapper>
          </FormItem>
          <FormItem>
            <FormItemTitle>{provider.preferredLanguage}</FormItemTitle>
            <SelectWrapper>
              <Select
                id="preferredLanguage"
                onChange={(e) => setPreferredLanguage(e.target.value)}
              >
                <option hidden disabled selected value>
                  {provider.preferredLanguageDefault}
                </option>
                {provider.preferredLanguageOptions.map((option) => (
                  <option value={option}>{option}</option>
                ))}
              </Select>
              {preferredLanguageError && (
                <ErrorMessage>{provider.errors.preferredLanguage}</ErrorMessage>
              )}
            </SelectWrapper>
          </FormItem>
          <FormItem>
            <FormItemTitle>{provider.propertyType}</FormItemTitle>
            <SelectWrapper>
              <Select
                id="propertyType"
                onChange={(e) => setPropertyType(e.target.value)}
              >
                <option hidden disabled selected value>
                  {provider.propertyTypeDefault}
                </option>
                {provider.propertyTypeOptions.map((option) => (
                  <option value={option}>{option}</option>
                ))}
              </Select>
              {propertyTypeError && (
                <ErrorMessage>{provider.errors.propertyType}</ErrorMessage>
              )}
            </SelectWrapper>
          </FormItem>
          <FormItem>
            <FormItemTitle>{provider.numberOfUnits}</FormItemTitle>
            <InputWrapper>
              <Input
                required={numberOfUnitsError}
                type="number"
                value={numberOfUnits}
                min={0}
                placeholder="e.g. 3"
                onChange={(e) => setNumberOfUnits(e.target.value)}
              />
              {numberOfUnitsError && (
                <ErrorMessage>{provider.errors.numberOfUnits}</ErrorMessage>
              )}
            </InputWrapper>
          </FormItem>

          <FormItem>
            <FormItemTitle>{provider.totalHouseholdSize}</FormItemTitle>
            <InputWrapper>
              <Input
                required={householdSizeError}
                type="number"
                value={householdSize}
                min={0}
                placeholder="e.g. 5"
                onChange={(e) => setHouseholdSize(e.target.value)}
              />
              {householdSizeError && (
                <ErrorMessage>{provider.errors.householdSize}</ErrorMessage>
              )}
            </InputWrapper>
          </FormItem>
          <FormItem>
            <FormItemTitle>{provider.floodInsurance}</FormItemTitle>
            <SelectWrapper>
              <Select
                id="floodInsurance"
                onChange={(e) => setFloodInsurance(e.target.value)}
              >
                <option hidden disabled selected value>
                  {provider.floodInsuranceDefault}
                </option>
                {provider.floodInsuranceOptions.map((option) => (
                  <option value={option}>{option}</option>
                ))}
              </Select>
              {floodInsuranceError && (
                <ErrorMessage>{provider.errors.floodInsurance}</ErrorMessage>
              )}
            </SelectWrapper>
          </FormItem>
          <FormItem>
            <FormItemTitle>{provider.howDidYourHearAboutUs}</FormItemTitle>
            <SelectWrapper>
              <Select
                id="howDidYourHearAboutUs"
                onChange={(e) => setHowDidYourHearAboutUs(e.target.value)}
              >
                <option disabled selected value>
                  {provider.howDidYourHearAboutUsDefault}
                </option>
                {provider.howDidYourHearAboutUsOptions.map((option) => (
                  <option value={option}>{option}</option>
                ))}
              </Select>
              {howDidYourHearAboutUsError && (
                <ErrorMessage>
                  {provider.errors.howDidYourHearAboutUs}
                </ErrorMessage>
              )}
            </SelectWrapper>
          </FormItem>
          <FormItem>
            <FormItemTitle>{provider.organization}</FormItemTitle>
            <InputWrapper>
              <Input
                type="text"
                placeholder="Organization"
                value={organization}
                onChange={(e) => setOrganization(e.target.value)}
              />
            </InputWrapper>
          </FormItem>
          <CheckboxField>
            <label htmlFor="contacted-by">{provider.contactedBy}</label>
            <input
              id="contacted-by"
              type="checkbox"
              value="contacted"
              checked={contacted}
              onChange={(e) => setContacted(e.target.checked)}
            />
          </CheckboxField>
          <SubmitWrapper>
            <Terms dangerouslySetInnerHTML={{ __html: provider.terms }} />
            <Button onClick={handleSubmit} bgColor={colors.resilientBlue}>
              {isLoading ? <Loading margin="1rem 2rem 0" /> : provider.submit}
            </Button>
          </SubmitWrapper>
          {submitError && <ErrorMessage>{provider.errors.submit}</ErrorMessage>}
          {hasValidationErrors() && (
            <ErrorMessage>{provider.errors.contact}</ErrorMessage>
          )}
        </Form>
      ) : (
        <ThankYouContainer>
          <h3>{provider.thankYou}</h3>
        </ThankYouContainer>
      )}
    </Section>
  );
};

export default ParametricIntakeForm;
