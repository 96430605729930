import React, { useState } from 'react';
import styled from 'styled-components';
import { colors } from '../styles/variables';
import HelpButton from './HelpButton';
import Faq from './Faq';
import Chat from './Chat';
import CloseButton from './CloseButton';

const DialogWrapper = styled.div`
  position: fixed;
  height: 100vh;
  min-width: 500px;
  max-width: 650px;
  width: 50vw;
  top: 0;
  right: 0;
  transform: translateX(
    ${(props) => (props.isVisible ? 0 : 'calc(100% + 15px)')}
  );
  transition: transform 0.3s ease;
  background-color: ${colors.beachBeige};
  box-shadow: 0 0 15px 0 ${colors.grayScale[6]};
  z-index: 3;
  padding: 3rem 2rem 0 2rem;

  @media (max-width: 650px) {
    min-width: inherit;
    max-width: inherit;
    width: 100vw;
  }
`;

const ChatWrapper = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  color: ${colors.grayScale[5]};
  justify-content: center;
`;

const Help = () => {
  const [isDialogVisible, setIsDialogVisible] = useState(false);

  const toggleDialogVisibility = () => {
    setIsDialogVisible((prevState) => !prevState);
  };

  return (
    <>
      <HelpButton onClick={toggleDialogVisibility} />
      <DialogWrapper isVisible={isDialogVisible}>
        <CloseButton onClick={toggleDialogVisibility} />
        <Faq />
        <ChatWrapper onClick={toggleDialogVisibility}>
          <Chat />
        </ChatWrapper>
      </DialogWrapper>
    </>
  );
};

export default Help;
