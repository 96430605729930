export default class ContentLocations {
  static get BASEMENT_AND_ABOVE() {
    return 'Basement & Above';
  }

  static get ENCLOSURE_AND_ABOVE() {
    return 'Enclosure & Above';
  }

  static get LOWEST_FLOOR_ONLY() {
    return 'Lowest Floor Only - Above Ground Level';
  }

  static get LOWEST_FLOOR_AND_HIGHER() {
    return 'Lowest Floor Above Ground Level and Higher Floors';
  }

  static get ABOVE_GROUND_LEVEL() {
    return 'Above Ground Level - More Than 1 Full Floor';
  }
}
