const raceOptions = [
  {
    en: 'White',
    es: 'Blanco'
  },
  {
    en: 'Black or African American',
    es: 'Negro o Afroamericano'
  },
  {
    en: 'Black/African American and White',
    es: 'Negro/Afroamericano y Blanco'
  },
  {
    en: 'Asian',
    es: 'Asiático'
  },
  {
    en: 'Asian and Black',
    es: 'Asiático y Negro'
  },
  {
    en: 'Asian and White',
    es: 'Asiático y Blanco'
  },
  {
    en: 'American Indian/Alaskan Native',
    es: 'Indígena Americano/Nativo de Alaska'
  },
  {
    en: 'American Indian/Alaskan Native and Black',
    es: 'Indígena Americano/Nativo de Alaska y Negro'
  },
  {
    en: 'American Indian/Alaskan Native and White',
    es: 'Indígena Americano/Nativo de Alaska y Blanco'
  },
  {
    en: 'Native Hawaiian/Pacific Islander',
    es: 'Nativo de Hawai/Isleño del Pacífico'
  },
  {
    en: 'Native Hawaiian/Pacific Islander and Black',
    es: 'Nativo de Hawai/Isleño del Pacífico'
  },
  {
    en: 'Native Hawaiian/White',
    es: 'Nativo de Hawai/Blanco'
  },
  {
    en: 'Multi-Racial',
    es: 'Multirracial'
  },
  {
    en: 'Other',
    es: 'Otro'
  },
  {
    en: 'Declined to Answer',
    es: 'Se negó a responder'
  }
];

const getRaceOptions = (language) =>
  raceOptions.map((option) => ({
    value: option.en,
    display: option[language]
  }));

export default getRaceOptions;
