import AuditCalculatorFormatter from '../AuditCalculatorFormatter';

const ElevateBuildingRecommendationHelper = {
  showRecommendation: (audit) => {
    return audit.feasible_to_lift_this_structure === 'Yes';
  },
  formatMitigatedRate: (property, audit, useCurrent) => {
    const formattedParams = AuditCalculatorFormatter.formatCalculatorParams(
      property,
      audit,
      useCurrent
    );
    formattedParams.elevation_difference = 2;
    return formattedParams;
  }
};

export default ElevateBuildingRecommendationHelper;
