import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { colors } from '../styles/variables';
import GeocodeCommunicator from '../communicators/GeocodeCommunicator';
import SalesforceCommunicator from '../communicators/SalesforceCommunicator';
import ExplorableMapPopup from './ExplorableMapPopup';
import ExplorableMapAddressInput from './ExplorableMapAddressInput';
import MapLegend from './MapLegend';
import GoogleMapsGeocodeHelper from '../helpers/mapHelpers/GoogleMapsGeocodeHelper';
import BuildingApiCommunicator from '../communicators/BuildingApiCommunicator';
import AddressLookupErrors from '../constants/AddressLookupErrors';
import Carto from '../helpers/Carto';
import GoogleMaps from '../helpers/GoogleMaps';
import Error from './layout/Error';
import {
  useApi,
  useProvider,
  useGoogleMaps,
  useLocale
} from '../helpers/hooks';

const Wrapper = styled.div`
  position: relative;
  margin-top: 89px;

  @media (max-width: 450px) {
    margin-top: 55px;
  }
`;

const CartoMap = styled.div`
  padding: 0;
  border-top: 1px solid ${colors.grayScale[1]};
`;

const NavWrapper = styled.div`
  position: absolute;
  max-width: 300px;
  top: 5%;
  left: 5%;
`;

const ToggleButton = styled.button`
  height: 40px;
  padding: 5px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #fff;
  margin: 0 auto;
`;

const MapContainer = styled.div`
  position: relative;
  flex: 1;
  height: calc(100vh - 150px);
`;

const Map = styled.div`
  height: calc(100vh - 150px);
`;

const MapButtons = styled.div`
  font-size: 14px;
  margin: 0 0 1rem 0;

  @media (max-width: 420px) {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }
`;

const MapButton = styled.button`
  height: 40px;
  background-color: white;
  border-radius: 3px;
  border: none;
  width: 142px;
  padding: 0;
  box-shadow: 4px 6px 15px #ccc;
  font-size: 1em;
  white-space: normal;

  &:focus {
    outline: none;
  }

  &.selected {
    background-color: ${colors.resilientBlue};
    color: white;
  }

  &:last-child {
    margin-left: 1em;
  }

  @media (max-width: 420px) {
    padding: 0 20px;
  }
`;

const ExplorableMap = () => {
  const provider = useProvider('ExplorableMap');
  const locale = useLocale();
  const { buildingApi, salesforceApi } = useApi();
  const { googleMapsKey } = useGoogleMaps();

  const [address, setAddress] = useState('');
  const [pageStatus, setPageStatus] = useState(null);
  const [popupOpen, setPopupOpen] = useState(false);
  const [popupLoading, setPopupLoading] = useState(false);
  const [currentZone, setCurrentZone] = useState('');
  const [futureZone, setFutureZone] = useState('');
  const [propertyInfo, setPropertyInfo] = useState({});
  const [propertyDetails, setPropertyDetails] = useState({});
  const [hasError, setHasError] = useState(false);
  const [addressLookupError, setAddressLookupError] = useState('');
  const [map, setMap] = useState(null);
  const [selectedMap, setSelectedMap] = useState('future');
  const [currentMapLayer, setCurrentMapLayer] = useState(null);
  const [futureMapLayer, setFutureMapLayer] = useState(null);

  const createMapLayers = async () => {
    const currentLayer = await Carto.createCurrentLayer(map);
    currentLayer.hide();
    setCurrentMapLayer(currentLayer);

    const futureLayer = await Carto.createFutureLayer(map);
    futureLayer.show();
    setFutureMapLayer(futureLayer);
  };

  const buildingApiSuccess = (data) => {
    setCurrentZone(data.current_flood_zone);
    setFutureZone(data.future_flood_zone);
    setPropertyDetails({ resiliency_audit_eligible: data.eligible });
    setPropertyInfo((prevState) => ({ ...prevState, ...data }));
    setAddressLookupError(null);
    setPopupLoading(false);
  };

  const buildingApiFailure = (error) => {
    const { response } = error;
    if (response.multiple_results) {
      setAddressLookupError(AddressLookupErrors.multipleResults);
    } else if (response.outside_nyc) {
      setAddressLookupError(AddressLookupErrors.outsideNYC);
    } else if (response.too_broad) {
      setAddressLookupError(AddressLookupErrors.tooBroad);
    } else {
      setAddressLookupError(AddressLookupErrors.generic);
    }
    setPopupLoading(false);
  };

  const handleGeocodeSuccess = async (locationData) => {
    setPageStatus('propertyType');
    const geocodeAddress = new GoogleMapsGeocodeHelper(
      locationData
    ).getAddress();
    if (geocodeAddress) {
      setAddress(geocodeAddress.formattedAddress);
      setPopupOpen(true);
      setPopupLoading(true);
      setPropertyInfo((prevState) => ({
        ...prevState,
        street_address: `${geocodeAddress.streetNumber} ${geocodeAddress.street}`,
        county: geocodeAddress.county,
        country: geocodeAddress.country,
        postal_code: geocodeAddress.postalCode,
        city: geocodeAddress.city,
        neighborhood: geocodeAddress.neighborhood
      }));
      try {
        // Returns {eligible: boolean}
        const result = await BuildingApiCommunicator.checkEligibility({
          api: buildingApi,
          address: geocodeAddress.formattedAddress
        });
        buildingApiSuccess(result);
      } catch (error) {
        buildingApiFailure(error);
      }
    } else {
      setAddress('No Street Address');
      setPopupOpen(true);
      setAddressLookupError('noRouteError');
    }
  };

  const handleMapClick = async (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    const coords = { lat, lng };
    GoogleMaps.placeMarker(map, coords);

    try {
      // returns object { plus_code: {}, results: [{}, {}], status: "status" }
      const result = await GeocodeCommunicator.lookupLatLng(
        googleMapsKey,
        `${lat}, ${lng}`
      );
      handleGeocodeSuccess(result);
    } catch (error) {
      setHasError(true);
    }
  };

  const initMap = useCallback((node) => {
    if (node) {
      const newMap = GoogleMaps.createMap(node);
      setMap(newMap);
    }
  }, []);

  useEffect(() => {
    if (map) {
      Carto.init()
        .then(createMapLayers)
        .then(() => {
          map.addListener('click', handleMapClick);
        });
    }
  }, [map]);

  const handlePropertyTypeClick = useCallback(
    async (isSmallBusiness) => {
      setPropertyInfo({ ...propertyInfo, small_business: isSmallBusiness });
      setPageStatus('termsAndConditions');
    },
    [propertyInfo]
  );

  const handleDisagree = () => {
    setPageStatus('');
    setAddress('');
    setPopupOpen(false);
  };

  const handleProfileSuccess = useCallback(
    (response) => {
      const newPath = `/${locale}/profile/${response.property_details.fh_website_external_id__c}`;
      window.location.pathname = newPath;
    },
    [locale]
  );

  const handleProfileCreation = useCallback(async () => {
    try {
      // Returns an object { property_id: "id", property_details: {} }
      const result = await SalesforceCommunicator.createProperty(
        salesforceApi,
        propertyInfo,
        propertyDetails
      );
      handleProfileSuccess(result);
    } catch (error) {
      setPageStatus('error');
    }
  }, [handleProfileSuccess, propertyDetails, propertyInfo, salesforceApi]);

  const handleAddressInputChange = (inputValue) => {
    setPopupOpen(false);
    setAddress(inputValue || '');
  };

  const submitAddress = async (lat, lng) => {
    try {
      const result = await GeocodeCommunicator.lookupLatLng(
        googleMapsKey,
        `${lat}, ${lng}`
      );
      handleGeocodeSuccess(result);
    } catch (error) {
      setHasError(true);
    }
  };

  const handleGoogleAutocomplete = (data) => {
    const formattedAddress = data.formatted_address || '';
    const { lat, lng } = data;
    if (lat && lng) {
      setAddress(formattedAddress);
      submitAddress(lat, lng);
      const coords = { lat, lng };
      GoogleMaps.placeMarker(map, coords);
    }
  };

  const renderToggle = () => {
    if (!address) {
      return null;
    }

    return (
      <ToggleButton
        popupOpen={popupOpen}
        address={address}
        onClick={() => {
          setPopupOpen((prevState) => !prevState);
        }}
      >
        {popupOpen ? provider.hidePopup : provider.showPopup}
      </ToggleButton>
    );
  };

  const showFutureMaps = () => {
    currentMapLayer.hide();
    futureMapLayer.show();
    setSelectedMap('future');
  };

  const showCurrentMaps = () => {
    futureMapLayer.hide();
    currentMapLayer.show();
    setSelectedMap('current');
  };

  if (hasError) {
    return <Error provider={provider} height="50%" />;
  }

  return (
    <>
      <Wrapper>
        <CartoMap>
          <MapContainer>
            <Map id="map" ref={initMap} />
            <NavWrapper>
              <ExplorableMapAddressInput
                address={address}
                submitAddress={submitAddress}
                handleAddressInputChange={handleAddressInputChange}
                handleGoogleAutocomplete={handleGoogleAutocomplete}
                setPopup={(isVisible) => {
                  setPopupOpen(isVisible);
                }}
              />
              <MapButtons>
                <MapButton
                  className={` ${selectedMap === 'current' && 'selected'}`}
                  onClick={showCurrentMaps}
                >
                  {provider.currentMaps}
                </MapButton>
                <MapButton
                  className={`${selectedMap === 'future' && 'selected'}`}
                  onClick={showFutureMaps}
                >
                  {provider.futureMaps}
                </MapButton>
              </MapButtons>
              {renderToggle()}
            </NavWrapper>
          </MapContainer>
          <MapLegend />
        </CartoMap>
        <ExplorableMapPopup
          address={address}
          popupOpen={popupOpen}
          closePopup={() => setPopupOpen(false)}
          propertyInfo={propertyInfo}
          propertyDetails={propertyDetails}
          currentZone={currentZone}
          futureZone={futureZone}
          addressLookupError={addressLookupError}
          popupLoading={popupLoading}
          setPageStatus={setPageStatus}
          handleDisagree={handleDisagree}
          handlePropertyTypeClick={handlePropertyTypeClick}
          handleProfileCreation={handleProfileCreation}
          provider={provider}
          pageStatus={pageStatus}
        />
      </Wrapper>
    </>
  );
};

export default ExplorableMap;