import FloodZoneSeverityHelper from './FloodZoneSeverityHelper';
import FloodZones from '../../constants/FloodZones';

export default class ContentLocationNavigator {
  static nextComponent(results) {
    if (
      results.moreThanOneFloor !== null &&
      results.moreThanOneFloor !== undefined
    ) {
      if (
        FloodZoneSeverityHelper.getMoreSevereZone(results) === FloodZones.AE
      ) {
        return null;
      }
      return 'elevatedBuilding';
    }
    return 'buildingFeature';
  }
}
