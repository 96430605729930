export default {
  header: 'Estimación de seguro contra inundación',
  beta: 'BETA',
  link: 'Vea su estimación completa',
  currentMapStart: 'Mapas actuales ( ',
  currentMapEnd: ' zona)',
  futureMapStart: 'Mapas de consulta (',
  futureMapEnd: ' zona)',
  assumingMax: '*Suponiendo cobertura máxima',
  numbersAreEstimates:
    'Estos números son estimaciones por muchos motivos. Para más información acerca de ellos, remítase a nuestros ',
  termsAndConditions: 'Términos y Condiciones',
  floodZoneRateProvider: {
    perYear: 'Por año*'
  },
  rateAdjusterProvider: {
    adjustYourCoverage: 'Cobertura para la estructura ',
    structuralCoverage: 'Cobertura para la estructura',
    structuralDeductible: 'Deducible para la estructura',
    contentCoverage: 'Cobertura para el contenido',
    contentDeductible: 'Deducible para el contenido',
    collapse: 'Derrumbe'
  }
};
