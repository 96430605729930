import greenTelephone from '../images/green-telephone.svg';
import seaWaves from '../images/sea-waves.svg';
import strategy from '../images/strategy.svg';
import training from '../images/training.svg';

export default {
  header: 'SA OU KA FÈ',
  callsToAction: [
    {
      header: 'Kòmanse Plan Kontinwite Biznis Ou an Jodi an',
      icon: strategy,
      iconAlt: 'Estrateji',
      body:
        'Itilize kad sa a pou rebalanse rapid apre tout enteripsyon. Pwoteje envestisman ou yo, ret an kontak ak anplwaye epi founisè yo epi pare ou pou reponn ak ensètitid.',
      buttonText: 'Gade',
      buttonLink:
        'https://www.nyc.gov/html/nycbe/downloads/pdf/EM_SBSBCPWorksheetsMar2017.pdf'
    },
    {
      header:
        'Asire Ou Biznis Ou an Prepare. Asite ak yon Atelye oswa Seminè sou Entènèt.',
      icon: training,
      iconAlt: 'Fòmasyon',
      body:
        'Soti nan fuit gaz ak dife, pou rive nan gwo tanpèt epi menas sou entènèt, pwopriyetè biznis NYC yo konfwonte ak yon varyete ijans yo pa t atann. SBS ofri atelye preparasyon pou ijans gratis epi seminè sou entènèt nan tout Vil New York.',
      buttonText: 'Aprann Plis La',
      buttonLink:
        'https://www1.nyc.gov/nycbusiness/article/preparedness-response-recovery'
    },
    {
      icon: seaWaves,
      iconAlt: 'vag',
      header: 'Menas Chanjman Klimatik k Ap Grandi',
      body:
        'Ak nivo lanmè yo k ap monte, evennman metewolojik ki pi ekstrèm epi frekan yon chanjman klimatik pote, kounya se moman pou kòmanse prepare. Chanjman klimatik se yon menas pou kominote nou yo epi antanke pilye kominote nou yo ak ekonomi nou, ti biznis yo se yon eleman vital nan ranfòsman yon vil toleran.',
      buttonText: 'Li Kounya',
      buttonLink:
        'https://onenyc.cityofnewyork.us/stories/a-livable-climate/#overview'
    },
    {
      icon: greenTelephone,
      iconAlt: 'telefòn',
      header: 'Kontakte Inite SBS pou Repons ak Ijans lan pou Asistans Tousuit',
      body:
        'Pou asitans tousuit, kontakte Initie SBS pou Repons Ijans lan. Ekip lan ka founi ou dènye enfòmasyon ijans yo sou fèmti ri yo, pètibasyon sèvis piblik yo, sekirite ak demolisyon. Yo ka konekte ou tou ak òganizasyon biznis lokal yo pou asistans anplis.',
      buttonText: 'Aprann Plis',
      buttonLink:
        'https://www1.nyc.gov/nycbusiness/article/preparedness-response-recovery'
    }
  ]
};
