import BuildingSizes from '../constants/BuildingSizes';
import BuildingTypes from '../constants/BuildingTypes';
import ContentLocations from '../constants/ContentLocations';
import BuildingFeatures from '../constants/BuildingFeatures';
import bungalow from '../images/bungalow.svg';
import detached from '../images/detached.svg';
import semiDetached from '../images/semi-detached.svg';
import attached from '../images/attached.svg';
import midRiseWalkUp from '../images/midrise-walk-up.svg';
import midRiseElevator from '../images/midrise-elevator.svg';
import unknown from '../images/unknown.svg';
import basement from '../images/basement-cellar.svg';
import crawlspaceWithFloodVents from '../images/crawl-space-with-flood-vents.svg';
import crawlspaceWithoutFloodVents from '../images/crawl-space-without-flood-vents.svg';
import enclosure from '../images/enclosure.svg';
import mobileHome from '../images/mobile-home.svg';
import subgradeCrawlspace from '../images/sub-grade-crawl-space.svg';
import none from '../images/cross.svg';

export default {
  navigation: {
    next: 'Siguiente',
    previous: 'Anterior'
  },
  faq: {
    header: 'USTED PODRÍA ESTAR PENSANDO:'
  },
  breadcrumbs: {
    back: 'Volver al perfil'
  },
  numberOfResidentialUnits: {
    title: 'numberOfResidentialUnits',
    faqZone: 'rate-calculator-number-of-residential-units',
    question: '¿Cuántas unidades residenciales tiene su edificación?',
    breadcrumb: 'Tamaño de la edificación',
    responseField: {
      options: [
        { value: BuildingSizes.SINGLE_FAMILY, display: 'Vivienda unifamiliar' },
        {
          value: BuildingSizes.TWO_TO_FOUR_FAMILY,
          display: 'De 2 a 4 unidades'
        },
        { value: BuildingSizes.MULTI_FAMILY, display: '5 o más unidades' }
      ],
      invalidAnswers: [BuildingSizes.MULTI_FAMILY],
      invalidCopy:
        'Este calculador de tarifas solo es apto para propiedades familiares de 1 a 4 unidades. ',
      invalidLinkCopy: 'Haga clic aquí para volver al inicio.',
      invalidLink: '/',
      invalidCopyAfterLink: '',
      alertAnswers: []
    }
  },
  buildingType: {
    title: 'buildingType',
    faqZone: 'rate-calculator-building-type',
    question: 'Describa lo mejor posible su tipo de edificación.',
    breadcrumb: 'Tipo de edificación',
    responseField: {
      key: 'building_type',
      options: [
        { value: BuildingTypes.BUNGALOW, display: 'Cabaña', image: bungalow },
        {
          value: BuildingTypes.DETACHED,
          display: 'No adosada',
          image: detached
        },
        {
          value: BuildingTypes.SEMI_DETACHED,
          display: 'Semiadosada',
          image: semiDetached
        },
        { value: BuildingTypes.ATTACHED, display: 'Adosada', image: attached },
        {
          value: BuildingTypes.MID_RISE_WALK_UP,
          display: 'Mediana altura, sin ascensor',
          image: midRiseWalkUp
        },
        {
          value: BuildingTypes.MID_RISE_ELEVATOR,
          display: 'Mediana altura, con ascensor',
          image: midRiseElevator
        },
        { value: BuildingTypes.UNKNOWN, display: 'No lo sé', image: unknown }
      ]
    }
  },
  preFirm: {
    title: 'preFirm',
    faqZone: 'rate-calculator-pre-firm',
    breadcrumb: 'Pre/Post FIRM',
    preFirmQuestion:
      'Al parecer esta propiedad se construyó antes del 16 de noviembre de 1983. ¿Es correcto?',
    postFirmQuestion:
      'Al parecer esta propiedad se construyó o se mejoró considerablemente después del 16 de noviembre de 1983. ¿Es correcto?',
    responseField: {
      options: [
        { value: 'yes', display: 'Sí' },
        { value: 'no', display: 'No' }
      ],
      invalidAnswers: [],
      invalidCopy: '',
      invalidLinkCopy: '',
      invalidLink: '#',
      invalidCopyAfterLink: '',
      alertAnswers: []
    },
    disclaimer:
      'Debido a que el primer mapa de zonas de inundaciones se aprobó el 16 de noviembre de 1983, ha habido tarifas subsidiadas temporales para las propiedades construidas antes de esta fecha.'
  },
  severeRepetitiveLoss: {
    title: 'severeRepetitiveLoss',
    faqZone: 'rate-calculator-severe-repetitive-loss',
    breadcrumb: 'Pérdidas repetitivas severas',
    floodDamageQuestion:
      '¿Ha tenido esta propiedad daños por inundación anteriormente?',
    severeRepetitiveLossQuestion:
      '¿Alguna vez le ha notificado FEMA o su compañía aseguradora que su edificación tuvo "pérdidas repetitivas severas"?',
    floodDamageResponse: {
      options: [
        { value: true, display: 'Sí' },
        { value: false, display: 'No' }
      ],
      invalidAnswers: [],
      invalidCopy: '',
      invalidLinkCopy: '',
      invalidLink: '#',
      invalidCopyAfterLink: '',
      alertAnswers: []
    },
    severeRepetitiveLossResponse: {
      options: [
        { value: 'yes', display: 'Sí' },
        { value: 'no', display: 'No' },
        { value: 'unknown', display: 'No lo sé' }
      ],
      invalidAnswers: [],
      invalidCopy: '',
      invalidLinkCopy: '',
      invalidLink: '#',
      invalidCopyAfterLink: '',
      alertAnswers: []
    },
    floodDamageDisclaimer:
      'Le hacemos estas preguntas porque existen tarifas especiales de seguro contra inundaciones que solo aplican para propiedades con un historial de pérdidas en particular.',
    severeRepetitiveLossDisclaimer:
      'La mayoría de las edificaciones en la Ciudad de Nueva York no son edificaciones con "pérdidas repetitivas severas".'
  },
  preferredRiskRate: {
    title: 'preferredRiskRate',
    faqZone: 'rate-calculator-preferred-risk-rate',
    breadcrumb: 'Tarifa de Riesgo Preferencial',
    floodClaimQuestion:
      '¿Alguna vez ha presentado una reclamación de seguro contra inundaciones o ha recibido asistencia en caso de desastres por inundaciones?',
    floodClaimResponseField: {
      options: [
        { value: true, display: 'Sí' },
        { value: false, display: 'No' }
      ],
      invalidAnswers: [],
      invalidCopy: '',
      invalidLinkCopy: '',
      invalidLink: '#',
      invalidCopyAfterLink: '',
      alertAnswers: []
    },
    numberOfClaimsQuestion: '¿Para cuántos casos?',
    numberOfClaimsSubtitle:
      'Cada tormenta es un caso por separado, por lo tanto, si usted solo recibió un pago por Sandy, eso cuenta como un solo caso.',
    numberOfClaimsResponseField: {
      options: [
        { value: 1, display: '1' },
        { value: 2, display: '2' },
        { value: 3, display: '3 o más' }
      ],
      invalidAnswers: [],
      invalidCopy: '',
      invalidLinkCopy: '',
      invalidLink: '#',
      invalidCopyAfterLink: '',
      alertAnswers: []
    },
    claimAmountQuestion: '¿Recibió usted más de $1,000 por cada inundación?',
    claimAmountResponseField: {
      options: [
        { value: 'true', display: 'Sí' },
        { value: 'false', display: 'No' }
      ],
      invalidAnswers: [],
      invalidCopy: '',
      invalidLinkCopy: '',
      invalidLink: '#',
      invalidCopyAfterLink: '',
      alertAnswers: []
    },
    disclaimer:
      'Le hacemos estas preguntas porque existen tarifas especiales de seguro contra inundaciones que solo aplican para propiedades con un historial de pérdidas en particular.'
  },
  elevatedBuilding: {
    title: 'elevatedBuilding',
    faqZone: 'rate-calculator-elevated-building',
    breadcrumb: 'Elevado',
    question: '¿Su vivienda está elevada?',
    responseField: {
      options: [
        { value: 'true', display: 'Sí' },
        { value: 'false', display: 'No' }
      ],
      invalidAnswers: [],
      invalidCopy: '',
      invalidLinkCopy: '',
      invalidLink: '#',
      invalidCopyAfterLink: '',
      alertAnswers: []
    },
    disclaimer:
      'Esto significa que la estructura se encuentra sobre pilotes o muros parciales.'
  },
  contentLocation: {
    title: 'contentLocation',
    faqZone: 'rate-calculator-content-location',
    breadcrumb: 'Ubicaciones del contenido',
    question:
      '¿En qué lugar de la edificación se encuentran las pertenencias que desea asegurar?',
    responseField: {
      key: 'content_location',
      options: [
        {
          value: ContentLocations.BASEMENT_AND_ABOVE,
          display: 'Sótano (solo lavadora/secadora/congelador) y arriba'
        },
        {
          value: ContentLocations.ENCLOSURE_AND_ABOVE,
          display: 'Cerramiento (solo lavadora/secadora/congelador) y arriba'
        },
        {
          value: ContentLocations.LOWEST_FLOOR_ONLY,
          display: 'Solo el piso más bajo, sobre el nivel del suelo'
        },
        {
          value: ContentLocations.LOWEST_FLOOR_AND_HIGHER,
          display:
            'El piso más bajo, sobre el nivel del suelo y pisos superiores'
        },
        {
          value: ContentLocations.ABOVE_GROUND_LEVEL,
          display: 'Sobre el nivel del suelo, más de un piso completo'
        }
      ]
    },
    disclaimer:
      'Recuerde que los únicos contenidos que puede asegurar en el sótano y en el cerramiento son la lavadora, secadora y congelador. Vea la sección de preguntas frecuentes para obtener más información.'
  },
  buildingFeature: {
    title: 'buildingFeature',
    faqZone: 'rate-calculator-building-feature',
    breadcrumb: 'Características de la edificación',
    question:
      'Indique si su propiedad presenta alguna de estas características.',
    responseField: {
      key: 'building_feature',
      options: [
        {
          value: BuildingFeatures.BASEMENT,
          display: 'Sótano o bodega',
          image: basement
        },
        {
          value: BuildingFeatures.ENCLOSURE,
          display: 'Cerramiento',
          image: enclosure
        },
        {
          value: BuildingFeatures.ELEVATED_ON_CRAWLSPACE_FLOOD_VENTS,
          display: 'Semisótano con desaguaderos para inundaciones',
          image: crawlspaceWithFloodVents
        },
        {
          value: BuildingFeatures.ELEVATED_ON_CRAWLSPACE_NO_FLOOD_VENTS,
          display: 'Semisótano sin desaguaderos para inundaciones',
          image: crawlspaceWithoutFloodVents
        },
        {
          value: BuildingFeatures.SUBGRADE_CRAWLSPACE,
          display: 'Semisótano de base',
          image: subgradeCrawlspace
        },
        {
          value: BuildingFeatures.MOBILE_HOME,
          display: 'Casa rodante',
          image: mobileHome
        },
        {
          value: BuildingFeatures.NO_BASEMENT_ENCLOSURE,
          display: 'Ninguno',
          image: none
        }
      ]
    }
  },
  basement: {
    title: 'basement',
    faqZone: 'rate-calculator-basement',
    breadcrumb: 'Sótano',
    hasBasementQuestion:
      '¿Tiene en su propiedad un sótano, semisótano o cerramiento?',
    responseField: {
      options: [
        { value: true, display: 'Sí' },
        { value: false, display: 'No' }
      ],
      invalidAnswers: [],
      invalidCopy: '',
      invalidLinkCopy: '',
      invalidLink: '#',
      invalidCopyAfterLink: '',
      alertAnswers: []
    },
    basementHeightQuestion:
      '¿Cuál es la altura desde el suelo hasta el techo de este sótano, semisótano o cerramiento? (La mayoría de los sótanos tienen 8 pies)',
    basementHeightUnit: 'ft'
  },
  numberOfSteps: {
    title: 'numberOfSteps',
    faqZone: 'rate-calculator-number-of-steps',
    breadcrumb: 'Escalones hacia arriba/abajo',
    hasStepsQuestion:
      '¿Estos escalones van desde el nivel del suelo hasta el piso más bajo? (Si usted tiene un sótano o un semisótano bajo el nivel del suelo, nos referimos a los escalones que van desde el nivel del suelo en el exterior y bajan hacia el sótano o el semisótano).',
    hasStepsResponseField: {
      options: [
        { value: 'true', display: 'Sí' },
        { value: 'false', display: 'No' }
      ],
      invalidAnswers: [],
      invalidCopy: '',
      invalidLinkCopy: '',
      invalidLink: '#',
      invalidCopyAfterLink: '',
      alertAnswers: []
    },
    frontDoorQuestion: '¿Los escalones son hacia arriba o hacia abajo?',
    frontDoorResponseField: {
      options: [
        { value: 'true', display: 'Hacia arriba' },
        { value: 'false', display: 'Hacia abajo' }
      ],
      invalidAnswers: [],
      invalidCopy: '',
      invalidLinkCopy: '',
      invalidLink: '#',
      invalidCopyAfterLink: '',
      alertAnswers: []
    },
    numberOfStepsQuestion: '¿Cuántos escalones hay?',
    disclaimer:
      'Asegúrese de responder esta pregunta teniendo en cuenta el punto más bajo del terreno que toca su casa. Si el terreno es más alto en frente y más bajo atrás, responda teniendo en cuenta la parte de atrás. Si el nivel más bajo es el nivel del suelo en cualquier lado, la respuesta es "no".'
  },
  numberOfFloors: {
    title: 'numberOfFloors',
    faqZone: 'rate-calculator-number-of-floors',
    breadcrumb: 'Múltiples pisos',
    question: '¿Esta vivienda tiene más de un piso?',
    responseField: {
      options: [
        { value: true, display: 'Sí' },
        { value: false, display: 'No' }
      ],
      invalidAnswers: [],
      invalidCopy: '',
      invalidLinkCopy: '',
      invalidLink: '#',
      invalidCopyAfterLink: '',
      alertAnswers: []
    }
  },
  machineryInBasement: {
    title: 'machineryInBasement',
    faqZone: 'rate-calculator-machinery-in-basement',
    breadcrumb: 'Maquinaria en el sótano',
    question:
      '¿Tiene algún tipo de maquinaria en el sótano como calentador de agua, caldera, sistema HVAC, etc.?',
    responseField: {
      options: [
        { value: true, display: 'Sí' },
        { value: false, display: 'No' }
      ],
      invalidAnswers: [],
      invalidCopy: '',
      invalidLinkCopy: '',
      invalidLink: '#',
      invalidCopyAfterLink: '',
      alertAnswers: []
    },
    disclaimer:
      'Nos referimos a las maquinarias o equipos que suministran los servicios públicos a la vivienda.'
  }
};
