export default {
  meta: {
    title: 'Flood Recovery Fund Program',
    url: 'https://www.floodhelpny.org/en/flood-recovery-program',
    description: 'Apply for Assistance through the Center’s Flood Recovery Fund'
  },
  title: 'Flood Recovery Fund Program',
  subTitle: 'Apply for Assistance through the Center’s Flood Recovery Fund',
  description: 'Apply for Assistance through the Center’s Flood Recovery Fund',
  eligibilityLink: 'Apply Now'
};
