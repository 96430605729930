import { createGlobalStyle } from 'styled-components';
import { reset } from 'styled-reset';
import { colors, borderRadius } from './variables';
import downCaret from '../images/down-caret.svg';

export default createGlobalStyle`
  ${reset}

  * {
    box-sizing: border-box;
  }

  html, body {
    height: 100%;
    width: 100%;
  }

  body {
    font-family: GT-Walsheim-Pro-Light;
    font-size: 20px;
    line-height: normal; // reset override

    @media (max-width: 1023px) {
      font-size: 18px;
    }

    @media (max-width: 767px) {
      font-size: 16px;
    }
  }

  h1, h2, h3, h4, h5, p {
    max-width: 100%;
  }

  h1, h2, h3, h4 {
    font-weight: normal;
  }

  h2, h3, h4, h5 {
    color: ${colors.navyBlue};
  }

  h1 {
    font-size: 5.5rem;
    font-family: GT-Walsheim-Pro-Bold;
    color: ${colors.accent.main};
    line-height: 110%;

    @media (max-width: 850px) and (min-width: 768px) {
      font-size: 4.5rem;
    }

    @media (max-width: 767px) {
      font-size: 2.5rem;
    }

    @media (max-width: 375px) {
      font-size: 2rem;
    }
  }

  h2 {
    font-size: 3.5rem;
    font-family: GT-Walsheim-Pro-Medium;

    @media (max-width: 767px) {
      font-size: 2rem;
    }

    @media (max-width: 375px) {
      font-size: 1.5rem;
    }
  }

  h3 {
    font-size: 2rem;
    font-family: GT-Walsheim-Pro-Medium;
  }

  h4 {
    font-size: 2rem;
    font-family: GT-Walsheim-Pro-Medium;
  }

  h5 {
    font-size: 1.5rem;
    font-family: GT-Walsheim-Pro-Bold;
    text-transform: uppercase;
  }

  h6 {
    font-family: GT-Walsheim-Pro-Medium;
    font-size: 14px;
    color: ${colors.grayScale[5]};
    text-transform: uppercase;
  }

  p {
    font-size: 20px;
    color: ${colors.grayScale[5]};
  }

  p b {
    font-weight: normal;
    font-family: GT-Walsheim-Pro-Medium;
    color: ${colors.grayScale[5]};
  }

  a {
    text-decoration: none;
    cursor: pointer;
    color: ${colors.accent.main};
    transition: all 0.25s ease;

    &:hover {
      text-decoration: underline;
    }
  }

  a[href^='tel'] {
    color: ${colors.heading};
    font-weight: 500;
    &:hover {
      text-decoration: none;
    }
  }

  button {
    cursor: pointer;
    font-family: "GT-Walsheim-Pro-Light", "Helvetica Neue";
    display: "inline-block";
    border-radius: ${borderRadius};
    text-align: center;
    white-space: nowrap;
    transition: background-color 0.25s $ease, transform 0.1s $ease;
  }

  select {
    -webkit-appearance: none;
    background: url(${downCaret}) no-repeat right #fff;
    background-position-x: 90%;
  }

  footer {
    font-size: small;
    padding-bottom: 30px;
    background-color: ${colors.footerBackground};
    color: ${colors.contentCopy};

    > img {
      display: none;
    }
  }
  `;
