import React from 'react';
import styled from 'styled-components';
import { colors } from '../styles/variables';
import { useProvider } from '../helpers/hooks';

const Wrapper = styled.section`
  text-align: center;
  background-color: ${colors.beachBeige};
  padding: 5rem 1rem;
`;

const HeaderWrapper = styled.div`
  margin: 3rem 0;

  h2 {
    margin-bottom: 1rem;
  }

  p {
    max-width: 640px;
    margin: 0 auto;
  }
`;

const People = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 2rem 0;
`;

const Person = styled.div`
  text-align: center;
  margin: 2rem;

  img {
    border: 8px solid white;
    border-radius: 50%;
    margin-bottom: 1rem;
  }
`;

const Neighborhood = styled.p`
  font-family: GT-Walsheim-Pro-Medium;
  font-weight: bold;
`;

const Explanation = styled.p`
  margin: 2rem auto;
  max-width: 800px;
  color: ${colors.grayScale[3]};
`;

const RisingTide = () => {
  const provider = useProvider('risingTide');

  return (
    <Wrapper>
      <HeaderWrapper>
        <h2>{provider.title}</h2>
        <p>{provider.subtitle}</p>
      </HeaderWrapper>
      <People>
        {provider.people.map((person) => (
          <Person key={person.name}>
            <img src={person.image} alt={person.name} />
            <p>{person.name}</p>
            <Neighborhood>{person.neighborhood}</Neighborhood>
          </Person>
        ))}
      </People>
      <Explanation>
        {provider.explanation}{' '}
        <a
          target="_blank"
          rel="noreferrer noopener"
          href="https://cnycn.atavist.com/risingtides"
        >
          {provider.link}
        </a>
      </Explanation>
    </Wrapper>
  );
};

export default RisingTide;
