import React from 'react';
import styled from 'styled-components';
import { colors } from '../styles/variables';
import mocejWhite from '../images/mocej-logo-white.svg';
import cnycnLogo from '../images/cnycn-logo.svg';
import gosrLogo from '../images/gosr-logo.svg';
import stateLogo from '../images/state-logo.svg';
import { useProvider } from '../helpers/hooks';

const Container = styled.div`
  padding: 4rem 0;
  background-color: ${colors.secondaryHeading};

  h2 {
    padding: 0;
    text-align: center;
  }

  h2,
  p {
    color: white;
    margin: 2rem auto 0 auto;
    max-width: 1000px;
    padding: 0 3rem;
  }
`;

const Logos = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  a {
    margin: 1rem 0;
  }

  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const Logo = styled.img`
  min-width: 160px;
  min-height: 21px;
  max-height: 70px;
  margin: 0 1.5rem;
`;

const AboutFHNY = () => {
  const provider = useProvider('aboutFHNY');

  return (
    <Container>
      <h2>{provider.header}</h2>
      <p>{provider.paragraph}</p>
      <Logos>
        <a
          href="https://www1.nyc.gov/site/orr/index.page"
          target="_blank"
          rel="noreferrer noopener"
        >
          <Logo src={mocejWhite} alt="Mayor's Office logo" />
        </a>
        <a href="http://cnycn.org" target="_blank" rel="noreferrer noopener">
          <Logo src={cnycnLogo} alt="CNYCN logo" />
        </a>
        <a
          href="http://stormrecovery.ny.gov"
          target="_blank"
          rel="noreferrer noopener"
        >
          <Logo src={gosrLogo} alt="GOSR logo" />
        </a>
        <a href="http://www.ny.gov" target="_blank" rel="noreferrer noopener">
          <Logo src={stateLogo} alt="NY state logo" />
        </a>
      </Logos>
    </Container>
  );
};

export default AboutFHNY;
