export default [
  {
    question: '¿Cuál es el propósito de un certificado de elevación?',
    answer:
      'Un certificado de elevación es un documento importante para el seguro contra inundaciones ya que contiene información sobre el riesgo y resistencia a inundaciones de una propiedad. El certificado de elevación puede usarse para calcular su prima de seguro de inundaciones. \n',
    zone: 'elevation-certificates'
  },
  {
    question: '¿Qué información aparece en el certificado de elevación?',
    answer:
      'Tres áreas de datos clave en el certificado de elevación son el tipo y las características de la edificación, el piso más bajo y la ubicación de la propiedad. Estos factores juegan un rol importante al determinar sus primas de seguro contra inundaciones. \n',
    zone: 'elevation-certificates'
  },
  {
    question: '¿Cómo obtengo un certificado de elevación?',
    answer:
      'Los certificados de elevación deben prepararlos un agrimensor, ingeniero o arquitecto profesional registrado y autorizado por las leyes estatales o locales para certificar información de elevación. Pídale su licencia, su experiencia en la Ciudad de Nueva York o en su vecindario, cualesquier certificaciones, y referencias de proyectos similares. FEMA recomienda buscar un inspector profesional en la asociación estatal profesional de agrimensores, o hablar con su oficina local de permisos de construcción. \n',
    zone: 'elevation-certificates'
  },
  {
    question: '¿Cuál es el costo promedio de un certificado de elevación? ',
    answer:
      'El costo puede oscilar entre $500 y más de $1,000.  Trate de obtener cotizaciones de por lo menos dos inspectores para comparar.  \n',
    zone: 'elevation-certificates'
  },
  {
    question: '¿Puedo obtener un certificado de elevación gratis? ',
    answer:
      'La respuesta breve es “no”.  Sin embargo, el propietario anterior ya podría tener un certificado; si usted está en proceso de comprar la propiedad o si recientemente ha comprado la propiedad, podría comunicarse con él o ella.     \n',
    zone: 'elevation-certificates'
  },
  {
    question:
      'Ya tengo un certificado de elevación, ¿hay algo que deba considerar? ',
    answer:
      'Si ya tiene un certificado de elevación no necesita pedir un certificado nuevo. Sin embargo, sí realiza cambios en la propiedad, como mejoras para reducir los efectos de inundaciones, seria recomendable pedir un certificado actualizado para asegurarse de recibir los precios correctos en su seguro. \n',
    zone: 'elevation-certificates'
  },
  {
    question:
      'Después de medir los niveles de inundación, creo que mi certificado contiene un error. ',
    answer:
      'Los ingenieros y agrimensores utilizan instrumentos especializados para medir la elevación de cada piso relativa al nivel del mar.  No es posible calcular con precisión la elevación de su propiedad con una cinta de medir.\n',
    zone: 'elevation-certificates'
  },
  {
    question:
      '¿Qué puedo hacer para verificar que mi tasa de seguro contra inundación sea la correcta? ',
    answer:
      'Un certificado de elevación puede ayudar a asegurar que reciba una calificación precisa en su seguro contra inundaciones. Una vez que tenga un certificado de elevación, trabaje con su agente de seguro para actualizar su póliza. Si tiene inquietudes sobre cómo se califico su póliza después de su usar el certificado de elevación, comuníquese con el centro de Intercambio de Seguros y Mapeo de FEMA (FMIX) al 877-336-2627. Ellos podrán discutir y revisar su póliza. \n',
    zone: 'elevation-certificates'
  },
  {
    question:
      'Todavía tengo preguntas sobre el certificado de elevación o el seguro de inundación.  ¿Pueden ayudarme? ',
    answer:
      'Floodsmart.gov es un magnífico recurso para encontrar más información sobre seguros contra inundación y certificados de elevación.  También puede enviar sus preguntas a info@floodhelp.org. \n',
    zone: 'elevation-certificates'
  },
  {
    question: '¿Qué sucede si soy inquilino?',
    answer:
      'Este programa está disponible solamente para dueños de edificios. Puede compartir este programa con su casero y pedirle que aplique.',
    zone: 'rtapp-preapp-homeowner'
  },
  {
    question: '¿Qué pasa si estoy pensando en comprar?',
    answer: 'Este programa está abierto solamente para dueños actuales.',
    zone: 'rtapp-preapp-homeowner'
  },
  {
    question: '¿Qué califica como residencia principal?',
    answer:
      'Una residencia principal es la vivienda donde el residente generalmente vive la mayor parte del tiempo. Una vivienda ocupada por inquilinos todo el año también es una residencia principal.',
    zone: 'rtapp-preapp-primary-residence'
  },
  {
    question: '¿Por qué importa la residencia principal?',
    answer:
      'Este programa está ideado para residencias que sirven de vivienda para neoyorquinos todo el año, y no para residencias vacacionales.',
    zone: 'rtapp-preapp-primary-residence'
  },
  {
    question: '¿Qué es un certificado de elevación?',
    answer:
      'Un certificado de elevación es un documento importante de seguro contra inundaciones que describe la elevación por encima del nivel del mar de cada piso de su casa o edificio. Puede ayudar a calcular la tarifa correcta del seguro contra inundaciones y también a evaluar sus opciones de resiliencia.',
    zone: 'rtapp-preapp-elevation-certificate'
  },
  {
    question: '¿El certificado de elevación tiene fecha de vencimiento?',
    answer:
      'No. Es posible que vea una fecha de vencimiento en la esquina superior derecha del certificado de elevación. Pero eso solo significa que hay un nuevo formulario. Un certificado de elevación en un formulario viejo sigue siendo válido.',
    zone: 'rtapp-preapp-elevation-certificate'
  },
  {
    question: '¿Por qué me preguntan sobre mis ingresos?',
    answer:
      'Le preguntamos sobre los ingresos para priorizar el servicio. Los núcleos residenciales con cualquier nivel de ingresos pueden solicitar una evaluación de la vulnerabilidad a inundaciones de su residencia. Sin embargo, atenderemos automáticamente los núcleos residenciales de ingresos bajos a moderados (hasta un 80% del ingreso medio para el área de la Ciudad de Nueva York). Las solicitudes que no estén dentro de este rango se priorizarán y revisarán al final de cada período de tres semanas.',
    zone: 'rtapp-preapp-income'
  },
  {
    question: '¿Qué se considera “daño”?',
    answer:
      'Le estamos preguntando si su vivienda fue dañada por vientos, lluvias o inundaciones como resultado directo del Huracán Sandy. En general, si el Departamento de Edificios de NYC (NYC Department of Buildings, DOB) marcó su residencia con un cartel amarillo o rojo, o si usted recibió asistencia de la FEMA, reclamó fondos del seguro o recibió ayuda de una organización benéfica para reparaciones debido a Sandy, creemos que su residencia tuvo daños.',
    zone: 'rtapp-preapp-sandy-damage'
  },
  {
    question: '¿Por qué me preguntan si mi vivienda tuvo daños?',
    answer:
      'Solo lo preguntamos para priorizar el servicio. Las residencias con daños anteriores recibirán servicios priorizados. También se pueden enviar solicitudes sin daños anteriores, ya que también se tendrán en cuenta.',
    zone: 'rtapp-preapp-sandy-damage'
  },
  {
    question: '¿Qué califica como unidad residencial?',
    answer:
      'Una unidad residencial es un espacio reservado, principalmente, para vivienda, no para fines comerciales.',
    zone: 'rtapp-preapp-number-of-units'
  },
  {
    question: '¿Qué califica como unidad residencial?',
    answer:
      'Una unidad residencial es un espacio reservado, principalmente, para vivienda, no para fines comerciales. ',
    zone: 'rtapp-preapp-number-of-units'
  },
  {
    question:
      'Si desconozco cuántas unidades residenciales hay en mi edificio, ¿cómo puedo averiguarlo?',
    answer:
      'El Departamento de Edificios de la Ciudad podría tener en sus archivos el certificado de ocupación del edificio que muestra la cantidad de unidades de vivienda.',
    zone: 'rtapp-preapp-number-of-units'
  },
  {
    question: '¿Qué sucede si soy dueño o vivo en un edificio multifamiliar? ',
    answer:
      "Puede solicitar la Auditoría de Resiliencia de Edificios para edificios multifamiliares \u003ca href='http://www.floodhelpny.org/multifamily' target='_blank'\u003eaquí\u003c/a\u003e.",
    zone: 'rtapp-preapp-number-of-units'
  },
  {
    question: '¿Qué sucede si soy dueño o vivo en un edificio multifamiliar? ',
    answer:
      "Puede solicitar la Auditoría de Resiliencia de Edificios para edificios multifamiliares \u003ca href='http://www.floodhelpny.org/es/multifamily' target='_blank'\u003eaquí\u003c/a\u003e.",
    zone: 'rtapp-preapp-number-of-units'
  },
  {
    question: '¿Qué sucede si soy dueño o vivo en un edificio multifamiliar? ',
    answer:
      'Desafortunadamente, no puede solicitar para una Auditoría de Resiliencia\u2028 de las Viviendas. Pero todavía puede conocer su \u003ca href="https://www.floodhelpny.org/es/#eligibility"\u003eriesgo\u003c/a\u003e\n\n',
    zone: 'rtapp-preapp-number-of-units'
  },
  {
    question: '¿Por qué necesitan mi número de teléfono y dirección de email?',
    answer:
      'Necesitamos su número de teléfono y dirección de email para contactarlo con actualizaciones sobre su solicitud.',
    zone: 'rtapp-preapp-contact-info'
  },
  {
    question: '¿Cuándo tendré una respuesta? (solicitud previa)',
    answer:
      'Después de presentar su solicitud, es posible que tenga que esperar tres semanas para tener una respuesta. Siempre puede chequear su estatus a través del enlace en el email.',
    zone: 'rtapp-preapp-review'
  },
  {
    question: '¿Qué califica como sótano?',
    answer:
      'Cualquier parte de su casa que esté debajo del suelo en los cuatro lados (incluso unas pocas pulgadas debajo del suelo) y sea más alta que aproximadamente 5 pies de piso a techo. Si el piso está a nivel del suelo o sobre el nivel del suelo en un lado, no es un sótano.\n',
    zone: 'rate-calculator-basement'
  },
  {
    question: '¿Es un “sótano” si el espacio tiene poca altura?',
    answer:
      'Si es más corto que aproximadamente 5 pies del piso al techo y no está a más de 2 pies debajo del suelo, entonces es un "espacio de arrastre". Si es corto pero a más de 2 pies debajo del suelo en cualquier lado, sigue siendo un "sótano".\n',
    zone: 'rate-calculator-basement'
  },
  {
    question: '¿Cómo afecta un sótano a mi tarifa del seguro?',
    answer:
      'Si vive en una zona de inundación de alto riesgo, su calificación se basa en la altura de su piso más bajo en relación con su BFE. Si tiene un sótano o un espacio subterráneo, su calificación se basa en la elevación de este piso, incluso si el espacio no está terminado.\n',
    zone: 'rate-calculator-basement'
  },
  {
    question: '¿Qué es un “recinto cerrado”?',
    answer:
      'Si tiene una vivienda elevada, es el área por debajo del espacio habitable que está cerrada con paredes (parcial o totalmente). Podría ser un garaje o un espacio no habitable que solo se usa como almacenamiento.',
    zone: 'rate-calculator-building-feature'
  },
  {
    question: '¿Cómo sé si tengo un “sótano” o un “semisótano”?',
    answer:
      'Un sótano es un área donde el piso está por debajo del nivel del suelo en los cuatro lados. Si tiene menos de 5 pies de altura, generalmente se considera un espacio de rastreo.\n',
    zone: 'rate-calculator-building-feature'
  },
  {
    question:
      'Ustedes dicen que un semisótano tiene una altura “aproximada” de 4 pies. ¿Pueden ser precisos?',
    answer:
      'La definición precisa de un semisótano es un área de no más de 5 pies de altura desde el piso del semisótano hasta el piso (donde se encuentra la alfombra) del próximo piso. La medida de 5 pies incluye el grosor del piso de arriba. Si calculamos que un piso tiene un grosor de un pie aproximadamente, entonces deben quedar unos 4 pies o menos de altura del piso al techo para que califique como tal.',
    zone: 'rate-calculator-building-feature'
  },
  {
    question: '¿Qué son los “desagües para inundaciones”?',
    answer:
      "Son aberturas especialmente diseñadas para que el agua fluya libremente. Hay reglas respecto a la cantidad requerida y al lugar donde deben colocarse. Si las aberturas no cumplen con estos requisitos, no se consideran desagües para inundaciones para efectos de la tarifa del seguro contra inundaciones. Más información \u003ca target='_blank' href='/es/resiliency'\u003eaquí\u003c/a\u003e.",
    zone: 'rate-calculator-building-feature'
  },
  {
    question: '¿Los desagües para inundaciones son importantes?',
    answer:
      'Los desagües para inundaciones previenen daños estructurales que la presión del agua causa a la vivienda, denominada presión hidrostática. Si el agua puede entrar al semisótano y salir, esta presión se reduce, protegiendo así su casa. Tener desagües para inundaciones en su semisótano podría reducir significativamente la tarifa del seguro contra inundaciones, dependiendo de la elevación de la vivienda en comparación con el Nivel Base de Inundación.',
    zone: 'rate-calculator-building-feature'
  },
  {
    question: '¿Qué es un “subsuelo”?',
    answer:
      'Un espacio que 1) está por debajo del nivel del suelo en todos sus 4 lados, 2) no está a más de 2 pies por debajo del nivel del suelo, en cualquier parte, y 3) tiene una altura de menos de 4 pies (del piso al techo). Si tiene más de 4 pies de altura o más de 2 pies de profundidad, para fines del seguro contra inundaciones, es un “sótano”.',
    zone: 'rate-calculator-building-feature'
  },
  {
    question: '¿Cómo afectan estas características a mi tarifa del seguro?',
    answer:
      'Básicamente, mientras más profundo sea su piso más bajo, más alta será la tarifa del seguro contra inundaciones.',
    zone: 'rate-calculator-building-feature'
  },
  {
    question: '¿Cómo afecta el tipo de edificio a mi tarifa del seguro?',
    answer:
      'Dependiendo del tipo de edificio, hay diferentes opciones de resiliencia disponibles.',
    zone: 'rate-calculator-building-type'
  },
  {
    question: '¿Qué sucede si mi tipo de edificio no está incluido aquí?',
    answer: 'Escoja el edificio que considere que más coincide con el suyo.',
    zone: 'rate-calculator-building-type'
  },
  {
    question: '¿Qué se considera “pertenencias”?',
    answer:
      'Muebles, ropa, vajilla, electrodomésticos que no están empotrados, televisores: todo lo que no es parte de la casa. Recuerde que las únicas pertenencias que puede asegurar en un sótano son la lavadora, la secadora y el congelador. El seguro no cubre ninguna otra pertenencia en el sótano.',
    zone: 'rate-calculator-content-location'
  },
  {
    question: '¿Cómo afecta el contenido a la tarifa de mi seguro?',
    answer:
      'La cobertura de contenido para sótanos y recintos cerrados es más costosa que para pisos más altos, y la cobertura en sótanos y recintos cerrados se limita a lavadoras, secadoras y congeladores. Nada más. Puede que no valga la pena pagar el costo más alto por la cobertura de contenidos para asegurar estos artículos en el sótano.',
    zone: 'rate-calculator-content-location'
  },
  {
    question: '¿Qué es un “recinto cerrado”?',
    answer:
      'Si tiene una vivienda elevada, es el área por debajo del espacio habitable que está cerrada con paredes (parcial o totalmente). Podría ser un garaje o un espacio no habitable que solo se usa como almacenamiento.',
    zone: 'rate-calculator-content-location'
  },
  {
    question: '¿Cómo afecta la elevación a mi tarifa del seguro?',
    answer:
      'Si vive en una zona de inundación de alto riesgo, su calificación se basa en la elevación de su piso más bajo en relación con su BFE. Cuando eleva su casa para que se asiente 2 pies por encima de su BFE, reduce drásticamente las posibilidades de que el agua de inundación ingrese a la casa y, por lo tanto, el costo del seguro.\n',
    zone: 'rate-calculator-elevated-building'
  },
  {
    question: '¿Cuáles son algunos otros tipos de equipos mecánicos?',
    answer: 'Hornos, bombas de calor, paneles eléctricos o ascensores.',
    zone: 'rate-calculator-machinery-in-basement'
  },
  {
    question:
      '¿La lavadora, la secadora o el congelador se consideran “maquinaria del edificio”?',
    answer:
      'No, esos cuentan como “contenido”. Para cubrir esos artículos, tiene que comprar cobertura de contenidos.',
    zone: 'rate-calculator-machinery-in-basement'
  },
  {
    question: '¿Cómo afecta la maquinaria del sótano a mi tarifa del seguro?',
    answer:
      'La maquinaria que tenga en el sótano aumenta la tarifa, pero no mucho. Pero sacar esta maquinaria del sótano, podría impactar considerablemente cuán rápido podrá restaurar la calefacción y la electricidad en su casa después de una tormenta.',
    zone: 'rate-calculator-machinery-in-basement'
  },
  {
    question: '¿Qué califica como unidad residencial?',
    answer:
      'Una unidad residencial es un espacio reservado, principalmente, para vivienda, no para fines comerciales.',
    zone: 'rate-calculator-number-of-residential-units'
  },
  {
    question: '¿Qué califica como unidad residencial?',
    answer:
      'Una unidad residencial es un espacio reservado, principalmente, para vivienda, no para fines comerciales. ',
    zone: 'rate-calculator-number-of-residential-units'
  },
  {
    question: '¿Las escaleras afectan mi tarifa del seguro?',
    answer:
      'Si no tiene un sótano ni semisótano debajo del suelo, la elevación del primer piso determinará la tarifa. Básicamente, mientras más alto esté este piso por encima del Nivel Base de Inundación, más baja será la tarifa.',
    zone: 'rate-calculator-number-of-steps'
  },
  {
    question:
      '¿Cómo puedo saber si esta propiedad tuvo daños por inundaciones?',
    answer:
      'Consulte con su agente de seguros contra inundaciones o FEMA sobre los antecedentes de pérdidas por inundaciones de su edificio.',
    zone: 'rate-calculator-preferred-risk-rate'
  },
  {
    question: '¿Qué se considera “daños por inundaciones”?',
    answer:
      'Los daños causados por desbordamiento o por un cuerpo de agua, como el océano o un lago, río o arroyo. Los daños causados por cañerías rotas no cuentan. El atasco y desbordamiento del drenaje generalmente no es un daño por inundación, salvo que haya sido causado por una inundación.',
    zone: 'rate-calculator-preferred-risk-rate'
  },
  {
    question:
      '¿Cómo afecta un daño por inundación anterior a la tarifa de mi seguro?',
    answer:
      'Si usted recibió más de $1,000 en una reclamación por daños por inundaciones bajo una póliza de seguro contra inundaciones, o de parte de la FEMA o la Administración de Pequeñas Empresas (SBA) dos o más veces por motivo de diferentes inundaciones, no es elegible para una póliza con Tarifa de Riesgo Preferencial baja. Recuerde que los pagos deben ser por inundaciones diferentes, no dos pagos por los daños de Sandy. Aun si usted recibió $50,000 o $100,000 de la FEMA o bajo una póliza de seguro contra inundaciones por los daños de Sandy, eso cuenta como solo una reclamación.',
    zone: 'rate-calculator-preferred-risk-rate'
  },
  {
    question:
      'Si desconozco la fecha en que se construyó mi edificio, ¿cómo puedo averiguarlo?',
    answer:
      'Si alguna vez su edificio fue objeto de una tasación, allí debería aparecer la fecha de construcción. Si su residencia es más nueva, puede encontrar esa fecha en el certificado de ocupación, disponible en el Departamento de Edificios.',
    zone: 'rate-calculator-pre-firm'
  },
  {
    question: '¿Por qué es importante el año 1983?',
    answer:
      'La Ciudad de Nueva York adoptó su primer Mapa de Tarifas de Seguro contra Inundaciones de la FEMA el 16 de noviembre de 1983 y añadió requisitos de elevación al código de construcción para propiedades construidas en una zona de inundación. Como no había requisitos de protección contra inundaciones en el código de construcción antes de esa fecha, las propiedades construidas antes de ese momento califican para tener una tarifa más baja. Pero luego el Congreso ordenó a la FEMA que eliminara esa tarifa más baja, denominada tarifa “subvencionada pre-FIRM”.',
    zone: 'rate-calculator-pre-firm'
  },
  {
    question: '¿Qué es una “pérdida repetitiva grave”?',
    answer:
      'Si usted o un propietario anterior recibieron al menos dos pagos por reclamaciones de seguro contra inundaciones y el total combinado de esos pagos es más alto que el valor de mercado del edificio, este es na edificio con “pérdidas repetitivas graves”.',
    zone: 'rate-calculator-severe-repetitive-loss'
  },
  {
    question:
      '¿Cómo puedo saber si esta propiedad tuvo daños por inundaciones?',
    answer:
      'Consulte con su agente de seguros contra inundaciones o FEMA sobre los antecedentes de pérdidas por inundaciones de su edificio.',
    zone: 'rate-calculator-severe-repetitive-loss'
  },
  {
    question: '¿Qué se considera “daños por inundaciones”?',
    answer:
      'Los daños causados por desbordamiento o por un cuerpo de agua, como el océano o un lago, río o arroyo. Los daños causados por cañerías rotas no cuentan. El atasco y desbordamiento del drenaje generalmente no es un daño por inundación, salvo que haya sido causado por una inundación.',
    zone: 'rate-calculator-severe-repetitive-loss'
  },
  {
    question:
      '¿Cómo afecta un daño por inundación anterior a la tarifa de mi seguro?',
    answer:
      'Si usted recibió más de $1,000 en una reclamación por daños por inundaciones bajo una póliza de seguro contra inundaciones, o de parte de la FEMA o la Administración de Pequeñas Empresas (SBA) dos o más veces por motivo de diferentes inundaciones, no es elegible para una póliza con Tarifa de Riesgo Preferencial baja. Recuerde que los pagos deben ser por inundaciones diferentes, no dos pagos por los daños de Sandy. Aun si usted recibió $50,000 o $100,000 de la FEMA o bajo una póliza de seguro contra inundaciones por los daños de Sandy, eso cuenta como solo una reclamación.',
    zone: 'rate-calculator-severe-repetitive-loss'
  },
  {
    question: '¿Quién presta estos servicios?',
    answer:
      "El Center for NYC Neighborhoods (Centro para Vecindarios de NYC) sin fines de lucro está trabajando con varios socios para ofrecer estos servicios. Puede obtener más información sobre el centro y sus socios \u003ca target='_blank' '/es/about'\u003eaquí\u003c/a\u003e.",
    zone: 'rtapp-landing'
  },
  {
    question: '¿Quién financia este programa?',
    answer:
      'Este programa piloto de dos años está financiado por la Oficina de Recuperación ante Tormentas del Gobernador de Nueva York, como parte del Programa de Reconstrucción para Comunidades NY Rising.',
    zone: 'rtapp-landing'
  },
  {
    question: '¿Quién financia este programa?',
    answer:
      'Este programa está financiado por la Oficina de Recuperación ante Tormentas del Gobernador de Nueva York, como parte del Programa de Reconstrucción para Comunidades NY Rising.',
    zone: 'rtapp-landing'
  },
  {
    question: '¿Por qué se eligieron estos barrios para el programa piloto?',
    answer:
      'El Programa de Reconstrucción Comunitaria NY Rising es una iniciativa participativa de recuperación y resiliencia establecida para proporcionar asistencia a 124 comunidades severamente dañadas por los huracanes Irene y Sandy, y la tormenta tropical Lee. Los miembros de la comunidad trabajaron con el equipo del estado para desarrollar planes de reconstrucción de comunidades que permitan identificar oportunidades para que sus comunidades sean más resilientes desde el punto de vista físico, económico y social. Según este programa piloto, son elegibles las comunidades que optaron por participar y destinar fondos a la Auditoría de Resiliencia de la Vivienda.',
    zone: 'rtapp-landing'
  },
  {
    question: '¿Por qué se eligieron estos barrios para el programa?',
    answer:
      'Las comunidades que eligieron participar y dedicar fondos a la Auditoría de Resiliencia en el Hogar son elegibles para este programa; Todos los vecindarios fueron parte del Programa de Reconstrucción de la Comunidad NY Rising.\n',
    zone: 'rtapp-landing'
  },
  {
    question:
      '¿Cómo se eligen los dueños de casa para que participen en el programa?',
    answer:
      'Los propietarios de viviendas que cumplan con todos los requisitos de elegibilidad podrán participar en el programa. Si está interesado en ser considerado, complete nuestro breve formulario de admisión en línea.\n',
    zone: 'rtapp-landing'
  },
  {
    question: '¿Hay beneficios financieros?',
    answer:
      'Los certificados de elevación deben ser completados por un agrimensor, y pueden costar entre $ 500 y $ 800 en casos sin complicaciones, pero pueden aumentar a varios miles de dólares en casos más complicados. Además, los servicios de consejería le ayudarán a identificar los pasos que debe tomar para reducir sus tarifas de seguro contra inundaciones en el futuro.',
    zone: 'rtapp-landing'
  },
  {
    question: '¿Qué es un certificado de elevación?',
    answer:
      'Un certificado de elevación es un documento importante de seguro contra inundaciones que describe la elevación por encima del nivel del mar de cada piso de su casa o edificio. Puede ayudar a calcular la tarifa correcta del seguro contra inundaciones y también a evaluar sus opciones de resiliencia.',
    zone: 'rtapp-landing'
  },
  {
    question: '¿Quiénes son los consejeros? ',
    answer:
      'Los consejeros son profesionales especialistas en viviendas o asuntos legales de organizaciones sin fines de lucro, basadas en la comunidad, con experiencia atendiendo a los dueños de casa en las comunidades costeras de Nueva York. Los consejeros están capacitados en temas de resiliencia y seguro contra inundaciones.',
    zone: 'rtapp-landing'
  },
  {
    question: '¿Qué sucede si soy dueño o vivo en un edificio multifamiliar? ',
    answer:
      "Puede solicitar la Auditoría de Resiliencia de Edificios para edificios multifamiliares \u003ca href='http://www.floodhelpny.org/multifamily' target='_blank'\u003eaquí\u003c/a\u003e.",
    zone: 'rtapp-landing'
  },
  {
    question: '¿Qué sucede si soy dueño o vivo en un edificio multifamiliar? ',
    answer:
      "Puede solicitar la Auditoría de Resiliencia de Edificios para edificios multifamiliares \u003ca href='http://www.floodhelpny.org/es/multifamily' target='_blank'\u003eaquí\u003c/a\u003e.",
    zone: 'rtapp-landing'
  },
  {
    question: '¿Qué sucede si soy dueño o vivo en un edificio multifamiliar? ',
    answer:
      'Desafortunadamente, no puede solicitar para una Auditoría de Resiliencia\u2028 de las Viviendas. Pero todavía puede conocer su \u003ca href="https://www.floodhelpny.org/es/#eligibility"\u003eriesgo\u003c/a\u003e\n\n',
    zone: 'rtapp-landing'
  },
  {
    question:
      'Creo que estoy en un barrio que califica para Auditorías de Resiliencia de la Vivienda, pero no puedo inscribirme.',
    answer:
      '\nContáctenos en info@floodhelpny.org. Envíenos su nombre y dirección, y díganos lo que pasó cuando intentó solicitar. Investigaremos y le responderemos. También puede llamar a nuestra línea de ayuda en el 646-786-0888. ',
    zone: 'rtapp-landing'
  },
  {
    question: '¿Por qué tengo que proporcionar más información?',
    answer:
      'Podríamos pedirle más información si no podemos saber, basándonos en su solicitud preliminar, si este programa está disponible para usted. También pedimos al azar determinados tipos de información, para así mantener el proceso justo y sencillo para todos.',
    zone: 'rtapp-self-certify'
  },
  {
    question: '¿Qué sucede si no puedo verificar mi información?',
    answer:
      'Si no puede proporcionar exactamente lo que le pedimos, contacte a nuestro equipo de ayuda. Puede llamarnos durante el horario normal de atención al 646-786-0888.',
    zone: 'rtapp-self-certify'
  },
  {
    question: '¿Cuándo tendré una respuesta? (solicitud)',
    answer:
      'Una vez que haya sido aprobado para recibir servicios, lo contactaremos dentro del lazo de dos días hábiles para programar su Auditoría de Resiliencia de la Vivienda en una fecha que le resulte conveniente. Su estatus siempre estará al día en esta página; también puede llamarnos durante el horario normal de atención al 646-786-0888 si tiene preguntas sobre su solicitud. ',
    zone: 'rtapp-status'
  },
  {
    question: '¿Necesito estar en casa durante la evaluación del ingeniero?',
    answer: 'Sí, tendrá que estar en su casa.',
    zone: 'rtapp-status'
  },
  {
    question:
      '¿Cuánto demora la evaluación de riesgo de inundación residencial?',
    answer: 'La evaluación llevará dos horas como máximo.',
    zone: 'rtapp-status'
  },
  {
    question: '¿Puedo elegir mi auditor?',
    answer:
      'Lamentablemente, debido a la necesidad de atender a una amplia variedad de dueños de casa y de citas programadas, usted no puede elegir su auditor.',
    zone: 'rtapp-status'
  },
  {
    question: '¿Quiénes son los auditores? ¿Están aprobados?',
    answer:
      'Los auditores son profesionales en diseño calificados, que tienen el respaldo de ingenieros con licencia y fueron seleccionados a través de un proceso de reclutamiento competitivo.',
    zone: 'rtapp-status'
  },
  {
    question: '¿Qué sucede si tengo que cambiar mi cita de auditoría? ',
    answer:
      'Usted puede volver a programar su cita siempre que lo solicite por lo menos tres días antes de la cita programada. Las citas se pueden volver a programar solo una vez. Llame al 646-786-0888 durante el horario de atención para volver a programarla.',
    zone: 'rtapp-status'
  },
  {
    question: '¿Qué pasa si pierdo mi cita de auditoría por error?',
    answer:
      'Usted puede volver a programar su cita siempre que lo solicite por lo menos tres días antes de la cita programada. Las citas se pueden volver a programar solo una vez. Llame al 646-786-0888 durante el horario de atención para volver a programarla.',
    zone: 'rtapp-status'
  },
  {
    question: '¿Quiénes son los consejeros? ',
    answer:
      'Los consejeros son profesionales especialistas en viviendas o asuntos legales de organizaciones sin fines de lucro, basadas en la comunidad, con experiencia atendiendo a los dueños de casa en las comunidades costeras de Nueva York. Los consejeros están capacitados en temas de resiliencia y seguro contra inundaciones.',
    zone: 'rtapp-status'
  },
  {
    question: '¿Cuánto demorará la consulta?',
    answer: 'La consulta debería demorar entre 45 minutos y una hora y media.',
    zone: 'rtapp-status'
  },
  {
    question: '¿Qué pasa si tengo preguntas sobre mi informe?',
    answer:
      'Su consejero contestará las preguntas que usted tenga sobre su informe o le ayudará a obtener las respuestas que necesita.',
    zone: 'rtapp-status'
  },
  {
    question:
      '¿Tendré que proporcionar información personal durante la consulta?',
    answer:
      'Su consejero podría preguntarle sobre sus finanzas y otra información personal, para que usted pueda aprovechar al máximo la evaluación de su residencia. No compartiremos su información personal sin su permiso.',
    zone: 'rtapp-status'
  },
  {
    question: '¿Dónde se realizará la consulta?',
    answer:
      'La consulta se realizará en una oficina en su barrio. Revise su email para obtener más detalles o llame a nuestra línea de ayuda para propietarios de viviendas en el 646-786-0888.',
    zone: 'rtapp-status'
  },
  {
    question:
      '¿Qué sucede si tengo que cambiar mi cita programada de consejería? ',
    answer:
      'Usted puede volver a programar su cita siempre que lo solicite por lo menos tres días antes de la cita programada. Las citas se pueden volver a programar solo una vez. Llame al 646-786-0888 durante el horario de atención para volver a programarla.',
    zone: 'rtapp-status'
  },
  {
    question: '¿Qué sucede si pierdo mi cita de consejería por error? ',
    answer:
      'Usted puede volver a programar su cita siempre que lo solicite por lo menos tres días antes de la cita programada. Las citas se pueden volver a programar solo una vez. Llame al 646-786-0888 durante el horario de atención para volver a programarla.',
    zone: 'rtapp-status'
  },
  {
    question: '¿Dónde puedo dar mis opiniones y comentarios?',
    answer:
      'Revise su email después de la consulta y busque el enlace para proporcionar sus opiniones y comentarios.',
    zone: 'rtapp-status'
  },
  {
    question: '¿Qué sucede si no recibo un email? ',
    answer:
      'Revise su carpeta de correo no deseado (“spam”) y añada info@floodhelpny.org a sus contactos. También puede llamar a nuestra línea de ayuda en el 646-786-0888 si tiene preguntas. ',
    zone: 'rtapp-status'
  },
  {
    question: '¿Qué significan las zonas de inundación de la FEMA?',
    answer:
      'Las zonas de inundación de la FEMA son áreas geográficas que se caracterizan por su riesgo de inundación. Por ejemplo, las zonas con alto riesgo de inundación tienen una probabilidad de inundarse cada año del 1%.',
    zone: 'profile'
  },
  {
    question: '¿Por qué cambian los mapas de zonas de inundación?',
    answer:
      'FEMA actualiza periódicamente los mapas que crea para las zonas con peligro de inundación en todo el país. Los mapas actuales de la Ciudad de Nueva York se adoptaron en 1983 y no reflejan con precisión el riesgo de inundación actual.',
    zone: 'profile'
  },
  {
    question:
      '¿Por qué no está claro cuándo entrarán en vigor los mapas nuevos?',
    answer:
      "La Ciudad de Nueva York apeló el mapa preliminar de tarifas de seguro contra inundaciones de FEMA en junio de 2015, argumentando que se había sobrestimado el riesgo de inundación en cinco distritos. En octubre de 2016 FEMA, anunció que había aceptado la apelación de la ciudad sobre los mapas preliminares de inundaciones de FEMA y comenzará un nuevo proceso para revisar los mapas de inundaciones de la ciudad. Se necesitarán por lo menos tres o cuatro años para que la FEMA publique los nuevos mapas revisados. Más información \u003ca href='http://www1.nyc.gov/site/floodmaps/index.page' target='_blank'\u003eaquí\u003c/a\u003e.",
    zone: 'profile'
  },
  {
    question: '¿Qué significa “BFE”?',
    answer:
      'BFE es el acrónimo en inglés de “Nivel Base de Inundación”. Solo las propiedades en zonas con alto riesgo de inundación tienen un BFE, que refleja la altura (en pies) sobre el nivel del mar que se proyecta que se elevarán las aguas de una crecida en una tormenta de “100 años”, es decir, una tormenta que tiene 1% de probabilidad de ocurrir cada año. La tarifa por cada $100 de cobertura se basa en la distancia por encima o por debajo del BFE que tiene la elevación de su casa. Las tarifas para residencias donde el piso inferior está en el BFE o por encima este, son económicas.',
    zone: 'profile'
  },
  {
    question: '¿Qué es una póliza de riesgo preferencial?',
    answer:
      'Una póliza de riesgo preferencial es una póliza económica para propiedades que se encuentran en zonas con riesgo bajo y moderado de inundación (zona X o en ninguna) con un historial de reclamaciones limitado. Una póliza total cuesta menos de $500 al año. Si obtiene menos cobertura, la prima es más barata.',
    zone: 'profile'
  },
  {
    question: '¿Qué es una póliza de riesgo preferencial?',
    answer:
      'Preferred Risk es una política subsidiada solo disponible para zonas de inundación de riesgo moderado y bajo (Zona X o no en una zona de inundación) con historial de reclamos limitado. El costo promedio es de aproximadamente $ 500 anuales.\n',
    zone: 'profile'
  },
  {
    question: '¿Qué es una “exención por derechos adquiridos”?',
    answer:
      'Cuando se adopten los nuevos mapas de inundación, si su residencia queda ubicada en una zona con mayor riesgo de inundación o el BFE de su propiedad aumenta, usted puede ahorrar dinero bajo la regla de exención por derechos adquiridos. Si usted es elegible, la exención por derechos adquiridos le permite fijar la zona de inundación o el BFE anterior para fines relacionados con la cotización de seguros. Hay dos tipos de exención por derechos adquiridos: “cobertura continua” y “construida en conformidad”. Solo las viviendas construidas después de noviembre de 1983 son elegibles para la exención “construida en conformidad”.',
    zone: 'profile'
  },
  {
    question:
      '¿Qué pasa si hay otra tormenta y no tengo seguro contra inundaciones?',
    answer:
      'Si usted está obligado a tener seguro contra inundaciones y no lo compra, no será elegible para recibir ayuda federal ante desastre por daños a su edificio o sus contenidos, en caso de una tormenta futura. Sin embargo, sigue siendo elegible para otros tipos de ayuda, por ejemplo, vivienda temporal.',
    zone: 'profile'
  },
  {
    question: '¿Qué cubre el seguro de “contenidos”?',
    answer:
      'El seguro de contenidos cubre los daños a los bienes personales, causados por una inundación. Las únicas pertenencias cubiertas del sótano son su lavadora, secadora y congelador. (Su horno, caldera, calentador de agua y aire acondicionado están protegidos por la cobertura de su “edificio”). Cuando se aproxime una inundación, saque sus pertenencias del sótano. También es una buena idea tomar fotos de todo antes de que ocurra una inundación para que pueda dar pruebas a la compañía de seguro.',
    zone: 'profile'
  },
  {
    question: '¿Qué sucede si no puedo pagar un seguro contra inundaciones?',
    answer:
      'Vea si puede pagarlo reduciendo el monto de cobertura o aumentando su deducible. Analice si puede hacer cambios económicos en su casa que reducirían el costo, como rellenar un semisótano. Comuníquese con funcionarios electos para saber si hay programas que ayuden a pagar cambios a su residencia que reducirían el costo o si hay grupos de voluntarios que los hacen.',
    zone: 'profile'
  },
  {
    question: '¿Qué sucede si soy dueño o vivo en un edificio multifamiliar? ',
    answer:
      "Puede solicitar la Auditoría de Resiliencia de Edificios para edificios multifamiliares \u003ca href='http://www.floodhelpny.org/multifamily' target='_blank'\u003eaquí\u003c/a\u003e.",
    zone: 'profile'
  },
  {
    question: '¿Qué sucede si soy dueño o vivo en un edificio multifamiliar? ',
    answer:
      "Puede solicitar la Auditoría de Resiliencia de Edificios para edificios multifamiliares \u003ca href='http://www.floodhelpny.org/es/multifamily' target='_blank'\u003eaquí\u003c/a\u003e.",
    zone: 'profile'
  },
  {
    question: '¿Qué sucede si soy dueño o vivo en un edificio multifamiliar? ',
    answer:
      'Desafortunadamente, no puede solicitar para una Auditoría de Resiliencia\u2028 de las Viviendas. Pero todavía puede conocer su \u003ca href="https://www.floodhelpny.org/es/#eligibility"\u003eriesgo\u003c/a\u003e\n\n',
    zone: 'profile'
  },
  {
    question: '¿Quién presta estos servicios? (multifamiliar)',
    answer:
      "El Center for NYC Neighborhoods (Centro para Vecindarios de NYC) sin fines de lucro está trabajando con varios socios para ofrecer estos servicios. Enterprise Community Partners, Inc. brindará apoyo a los dueños de viviendas multifamiliares. Puede obtener más información sobre el centro \u003ca target='_blank' href='http://www.enterprisecommunity.com'\u003eaquí\u003c/a\u003e y sobre Enterprise Community Partners \u003ca target='_blank' href='http://cnycn.org/'\u003eaquí\u003c/a\u003e.",
    zone: 'rtapp-multifamily-landing'
  },
  {
    question: '¿Quién financia este programa?',
    answer:
      'Este programa piloto de dos años está financiado por la Oficina de Recuperación ante Tormentas del Gobernador de Nueva York, como parte del Programa de Reconstrucción para Comunidades NY Rising.',
    zone: 'rtapp-multifamily-landing'
  },
  {
    question: '¿Quién financia este programa?',
    answer:
      'Este programa está financiado por la Oficina de Recuperación ante Tormentas del Gobernador de Nueva York, como parte del Programa de Reconstrucción para Comunidades NY Rising.',
    zone: 'rtapp-multifamily-landing'
  },
  {
    question: '¿Por qué se eligieron estos barrios para el programa piloto?',
    answer:
      'El Programa de Reconstrucción Comunitaria NY Rising es una iniciativa participativa de recuperación y resiliencia establecida para proporcionar asistencia a 124 comunidades severamente dañadas por los huracanes Irene y Sandy, y la tormenta tropical Lee. Los miembros de la comunidad trabajaron con el equipo del estado para desarrollar planes de reconstrucción de comunidades que permitan identificar oportunidades para que sus comunidades sean más resilientes desde el punto de vista físico, económico y social. Según este programa piloto, son elegibles las comunidades que optaron por participar y destinar fondos a la Auditoría de Resiliencia de la Vivienda.',
    zone: 'rtapp-multifamily-landing'
  },
  {
    question: '¿Por qué se eligieron estos barrios para el programa?',
    answer:
      'Las comunidades que eligieron participar y dedicar fondos a la Auditoría de Resiliencia en el Hogar son elegibles para este programa; Todos los vecindarios fueron parte del Programa de Reconstrucción de la Comunidad NY Rising.\n',
    zone: 'rtapp-multifamily-landing'
  },
  {
    question:
      '¿Cómo se eligen los dueños de casa que participarán en el programa?',
    answer:
      'La prioridad se basa en el ingreso de los inquilinos de edificios, ya sea que residan en el terreno inundable de 100 años o que el edificio fue dañado por el Huracán Sandy.',
    zone: 'rtapp-multifamily-landing'
  },
  {
    question: '¿Hay beneficios financieros?',
    answer:
      'Los certificados de elevación deben ser completados por un agrimensor, y pueden costar entre $ 500 y $ 800 en casos sin complicaciones, pero pueden aumentar a varios miles de dólares en casos más complicados. Además, los servicios de consejería le ayudarán a identificar los pasos que debe tomar para reducir sus tarifas de seguro contra inundaciones en el futuro.',
    zone: 'rtapp-multifamily-landing'
  },
  {
    question: '¿Qué es un certificado de elevación?',
    answer:
      'Un certificado de elevación es un documento importante de seguro contra inundaciones que describe la elevación por encima del nivel del mar de cada piso de su casa o edificio. Puede ayudar a calcular la tarifa correcta del seguro contra inundaciones y también a evaluar sus opciones de resiliencia.',
    zone: 'rtapp-multifamily-landing'
  },
  {
    question: '¿Por qué debería preocuparme por la resiliencia?',
    answer:
      "Cuando ocurre un desastre, una respuesta mal administrada, de parte del personal, puede poner en peligro la seguridad y el bienestar de los residentes de viviendas, y exponer a los dueños de viviendas a dificultades y costos innecesarios, así como al posible riesgo de responsabilidad civil. Las organizaciones de viviendas asequibles enfrentan desafíos particulares durante eventos de emergencias. Estas organizaciones, al no poder reubicar fácilmente a los residentes, dependen de la operación continua de sus edificios, o por lo menos, de una rápida recuperación del servicio. Encontrará más información \u003ca target='_blank' href='https://s3.amazonaws.com/KSPProd/ERC_Upload/0100738.pdf'\u003eaquí\u003c/a\u003e.",
    zone: 'rtapp-multifamily-landing'
  },
  {
    question:
      '¿Cómo está afectando el cambio climático a la Ciudad de Nueva York?',
    answer:
      "El cambio climático aporta un elemento completamente distinto a cómo considerar la resiliencia en la Ciudad de Nueva York. Puede leer más sobre los impactos del cambio climático a la ciudad \u003ca target='_blank' href='http://onlinelibrary.wiley.com/doi/10.1111/nyas.12591/full'\u003eaquí\u003c/a\u003e.",
    zone: 'rtapp-multifamily-landing'
  },
  {
    question:
      '¿Cómo está afectando el cambio climático a la Ciudad de Nueva York?',
    answer:
      "El cambio climático aporta un elemento completamente distinto a cómo considerar la resiliencia en la Ciudad de Nueva York. Puede leer más sobre los impactos del cambio climático a la ciudad \u003ca target='_blank' href='http://onlinelibrary.wiley.com/doi/10.1111/nyas.12591/full'\u003eaquí\u003c/a\u003e.",
    zone: 'resiliency'
  },
  {
    question: '¿Cómo elijo la opción de resiliencia adecuada para mí?',
    answer:
      'Debe considerar qué opciones están disponibles para su residencia (por ejemplo, no puede elevar fácilmente una casa adosada), el costo inicial de la medida de mitigación y los posibles ahorros en su prima de seguro con el tiempo.',
    zone: 'resiliency'
  },
  {
    question:
      '¿Qué opciones de mitigación realmente reducirán la tarifa de mi seguro?',
    answer:
      'A la fecha, hay solo tres medidas que afectan las primas de seguro: elevar la residencia, rellenar un sótano o semisótano e instalar desagües para inundaciones, y elevar los equipos mecánicos fuera del sótano. De las tres opciones, la última proporciona la menor reducción de seguro, pero puede ahorrarle bastante dinero en caso de una inundación.',
    zone: 'resiliency'
  },
  {
    question:
      '¿Qué opciones de mitigación realmente reducirán la tarifa de mi seguro?',
    answer:
      'A partir de ahora, solo hay cuatro medidas que tienen un efecto en las primas de seguro: elevación de la casa, llenar un sótano o gatear e instalar respiraderos, abandonar su primer piso y levantar equipos mecánicos del sótano. La última opción proporciona la reducción de seguro más pequeña de las tres, pero puede ahorrarle una gran cantidad de dinero en caso de inundación.\n',
    zone: 'resiliency'
  },
  {
    question: '¿Qué sucede si no puedo pagar un seguro contra inundaciones?',
    answer:
      'Vea si puede pagarlo reduciendo el monto de cobertura o aumentando su deducible. Analice si puede hacer cambios económicos en su casa que reducirían el costo, como rellenar un semisótano. Comuníquese con funcionarios electos para saber si hay programas que ayuden a pagar cambios a su residencia que reducirían el costo o si hay grupos de voluntarios que los hacen.',
    zone: 'resiliency'
  },
  {
    question:
      '¿Qué sucede si no puedo pagar mi prima de seguro contra inundaciones cuando aumente?',
    answer:
      'Quizás pueda reducir su cobertura u obtener un deducible más alto. Tener algo de cobertura es mejor que no tener ninguna. Si tiene una hipoteca en una zona con alto riesgo, sus opciones pueden ser limitadas. Trate de analizar qué cambios puede hacer en su casa para reducir la prima, como rellenar un semisótano o sótano e instalar desagües para inundaciones.',
    zone: 'resiliency'
  },
  {
    question: '¿Por qué el seguro para dueños de casa no cubre inundaciones?',
    answer:
      'La razón por la cual el Congreso creó el NFIP en 1968 fue porque las compañías de seguros privadas rehusaban cubrir daños por inundación. Las pérdidas eran demasiado costosas. ',
    zone: 'resiliency'
  },
  {
    question:
      '¿Cuál es la diferencia entre sótano y semisótano? ¿Por qué importa?',
    answer:
      'Para las compañías de seguros contra inundaciones, un sótano está por debajo del nivel del suelo por sus cuatro lados, y tiene más de 5 pies de altura. Si el espacio es inferior a 5 pies, se considera semisótano. Es importante porque las tarifas pueden ser diferentes.',
    zone: 'mitigation-fill_in_basement'
  },
  {
    question: '¿Cuánto cuesta rellenar mi sótano?',
    answer:
      'Dependiendo de la profundidad del sótano y si usted necesita reubicar servicios públicos (luz, gas, teléfono o cable) y equipos, e instalar desagües para inundaciones, podría costar entre $8,500 y $30,000.',
    zone: 'mitigation-fill_in_basement'
  },
  {
    question: '¿Cuánto tiempo toma rellenar mi sótano?',
    answer:
      'Puede tomar varios días para rellenar el sótano o el semisótano. Pero puede tardar una o dos semanas más en promedio si necesita reubicar equipos mecánicos que estén en el sótano o el semisótano. Si agregamos el tiempo que toma obtener los permisos, podría tardar aún más.',
    zone: 'mitigation-fill_in_basement'
  },
  {
    question: '¿Con qué puedo rellenar mi sótano?',
    answer: 'Puede usar piedra o arena limpia y compactada.',
    zone: 'mitigation-fill_in_basement'
  },
  {
    question: '¿Qué son los desagües para inundaciones?',
    answer:
      'Estas son aberturas especialmente diseñadas que permiten que el agua entre y salga. Hay reglas sobre cuántos se requieren y dónde deben colocarse. Si las aberturas no cumplen con estos requisitos, no contarán como respiraderos contra inundaciones para la calificación del seguro contra inundaciones. Lea más \u003ca target="_blank" href="/en/resiliency"\u003e aquí \u003c/a\u003e.\n',
    zone: 'mitigation-fill_in_basement'
  },
  {
    question: '¿Puedo rellenar el sótano yo mismo?',
    answer:
      'Necesita un ingeniero para asegurar que el relleno del sótano no dañará la estructura de la casa. También tendrá que obtener un permiso del Departamento de Edificios de NYC. Quizás usted pueda hacer parte del trabajo, pero le recomendamos que contrate un diseñador calificado para que le ayude. Es posible que también necesite un diseñador para obtener el permiso. También puede necesitar reconfigurar las conexiones de servicios públicos y equipos mecánicos.',
    zone: 'mitigation-fill_in_basement'
  },
  {
    question: '¿Debo instalar desagües para inundaciones si relleno mi sótano?',
    answer:
      'Sí. Las rejillas de ventilación contra inundaciones protegen su hogar al garantizar que el agua no se acumule en un lado de la pared y cree presión que podría dañar sus cimientos. También son necesarios para recibir una reducción de seguro después de un relleno del sótano. Los respiraderos contra inundaciones deben ser instalados por un ingeniero con licencia.\n',
    zone: 'mitigation-fill_in_basement'
  },
  {
    question: '¿Cómo puedo averiguar si puedo rellenar mi sótano?',
    answer:
      'Consulte con un ingeniero profesional, un contratista con licencia o con el Departamento de Edificios de NYC.',
    zone: 'mitigation-fill_in_basement'
  },
  {
    question: '¿Cómo puedo encontrar un contratista para rellenar mi sótano?',
    answer:
      "El Departamento de Edificios de NYC \u003ca target='_blank' href='http://www1.nyc.gov/site/buildings/homeowner/homeowner.page'\u003epuede aconsejarle\u003c/a\u003e sobre cómo encontrar un diseñador y contratistas que tengan experiencia trabajando en zonas de inundación. Los contratistas deben tener experiencia, licencia y seguro. Chequee sus referencias.",
    zone: 'mitigation-fill_in_basement'
  },
  {
    question: '¿Cuánto tiempo toma la elevación?',
    answer:
      'Hacer el trabajo en sí puede tardar hasta tres meses o más. Sin embargo, crear el diseño y obtener los permisos puede prolongar el plazo para elevar su vivienda.',
    zone: 'mitigation-elevate'
  },
  {
    question: '¿Debo mudarme mientras se realiza la elevación de mi casa?',
    answer:
      'Sí, se desconectarán servicios públicos como agua, alcantarillado y electricidad. Las escaleras y otros medios de entrada tendrán que ser separadas durante la construcción.',
    zone: 'mitigation-elevate'
  },
  {
    question: '¿Cómo puedo averiguar si puedo elevar mi casa?',
    answer:
      'Deberá contratar a un ingeniero calificado que pueda evaluar si la elevación es una buena opción para su casa.',
    zone: 'mitigation-elevate'
  },
  {
    question: '¿Cómo sé cuánto hay que elevar la casa?',
    answer:
      "Generalmente, en la Ciudad de Nueva York se exige el BFE más dos pies de francobordo. Pero es aconsejable consultar con un ingeniero calificado con experiencia en obras de construcción en zonas de inundación. El Departamento de Edificios de NYC \u003ca target='_blank' href='http://www1.nyc.gov/site/buildings/homeowner/homeowner.page'\u003epuede ayudarle a encontrar uno\u003c/a\u003e.",
    zone: 'mitigation-elevate'
  },
  {
    question:
      '¿Debo pagar un seguro contra inundaciones mientras se eleva mi casa?',
    answer:
      'Sí, su casa seguirá siendo vulnerable a los daños de inundaciones mientras se realiza la elevación.',
    zone: 'mitigation-elevate'
  },
  {
    question: '¿Qué sucede si vivo en una casa adosada o semiadosada?',
    answer:
      'No es posible desde el punto de vista técnico ni rentable elevar una casa adosada.',
    zone: 'mitigation-elevate'
  },
  {
    question: '¿Cuánto costará elevar mis equipos mecánicos?',
    answer:
      'Dependiendo de su casa, el contratista y qué servicios públicos se reubicarán, puede costar entre $ 5,000 y $ 40,000.',
    zone: 'mitigation-elevate_mechanicals'
  },
  {
    question: '¿Cuánto tiempo toma elevar mis equipos mecánicos?',
    answer:
      'Puede tardar desde tres a cinco días hasta unas semanas, después de obtener los permisos y comenzar la construcción.',
    zone: 'mitigation-elevate_mechanicals'
  },
  {
    question: '¿Debo mudarme durante el proceso?',
    answer:
      'Eso dependerá de qué equipo será reubicado, la época del año y otros factores. Es muy probable que tenga que mudarse temporalmente durante al menos parte del tiempo de construcción.',
    zone: 'mitigation-elevate_mechanicals'
  },
  {
    question:
      '¿Cómo puedo averiguar cuánto se deben elevar mis equipos mecánicos?',
    answer:
      'Usted o su contratista siempre pueden consultar con el Departamento de Edificios de NYC. Lo ideal es elevar el equipo al Nivel de Inundación del Proyecto, que es el Nivel Base de Inundación más dos pies de francobordo.',
    zone: 'mitigation-elevate_mechanicals'
  },
  {
    question:
      '¿Dónde puedo encontrar un contratista para elevar mis equipos mecánicos?',
    answer:
      "El Departamento de Edificios de NYC \u003ca target='_blank' href='http://www1.nyc.gov/site/buildings/homeowner/homeowner.page'\u003epuede aconsejarle\u003c/a\u003e sobre cómo encontrar un diseñador y contratistas que tengan experiencia trabajando en zonas de inundación.",
    zone: 'mitigation-elevate_mechanicals'
  },
  {
    question: '¿Qué otro equipo crítico podría estar en riesgo?',
    answer:
      'Los siguientes equipos pueden estar en riesgo: paneles y cables eléctricos, sistemas mecánicos como HVAC y red de conductos, unidades de condensación, sistemas de calefacción, calentadores de agua y equipo de lavandería.',
    zone: 'mitigation-elevate_mechanicals'
  },
  {
    question: '¿Cómo calculo mi Nivel Base de Inundación (BFE)?',
    answer:
      'Ingrese su dirección en la página de inicio de FloodHelpNY.org para encontrar sus zonas de inundación y BFE actuales y futuros. ',
    zone: 'mitigation-elevate_mechanicals'
  },
  {
    question: '¿Cómo calculo mi Nivel Base de Inundación (BFE)?',
    answer:
      'Comience por conocer su zona. Si está en una zona AE, un certificado de elevación es la mejor manera de conocer con precisión su BFE. Si se encuentra en una zona X, se encuentra en un área del mapa de inundación que actualmente no tiene una elevación de inundación de base proyectada.\n',
    zone: 'mitigation-elevate_mechanicals'
  },
  {
    question:
      '¿Cómo calculo mi Nivel de Inundación del Proyecto (DFE, en inglés)?',
    answer:
      'El DFE determina la elevación más segura para la construcción en zonas con peligro de inundación. En la Ciudad de Nueva York, el DFE generalmente es el Nivel Base de Inundación más dos pies, conocido como “francobordo”, para mayor seguridad. Su diseñador o arquitecto deben consultar con el Departamento de Edificios de NYC durante el proceso de diseño y la tramitación de permisos para asegurarse de que están siguiendo el DFE.',
    zone: 'mitigation-elevate_mechanicals'
  },
  {
    question: '¿Cuánto cuesta vaciar el primer piso?',
    answer:
      'Depende del uso actual del primer piso y si necesita reubicar los equipos mecánicos, servicios públicos, la cocina y/o los baños. El costo podría ser de $ 10,000 a $ 100,000 o más.',
    zone: 'mitigation-abandon_first_floor'
  },
  {
    question: '¿Cuánto tiempo toma vaciar el primer piso?',
    answer:
      'El tiempo variará según la construcción del edificio, el tamaño del área que se abandonará en el nivel inferior y cuánto relleno de áreas subterráneas será necesario. Un buen estimado es de unas semanas a 90 días. Sin embargo, se puede necesitar más tiempo para el diseño y obtener los permisos.',
    zone: 'mitigation-abandon_first_floor'
  },
  {
    question: '¿Cómo voy a entrar a mi casa si se vacía el primer piso?',
    answer:
      'Necesitará que se construya una nueva escalera de entrada. En algunos casos, es posible que necesite instalar un ascensor mecánico.',
    zone: 'mitigation-abandon_first_floor'
  },
  {
    question: '¿Qué sucede si tengo inquilinos en el primer piso de mi casa?',
    answer: 'El espacio ya no será habitable. ',
    zone: 'mitigation-abandon_first_floor'
  },
  {
    question: '¿Puedo ampliar mi casa para compensar el espacio perdido?',
    answer:
      'Puede que sea posible añadir otro piso a su casa, si tiene sentido desde el punto de vista económico y no pone en peligro la integridad estructural de la casa. Además, cualquier ampliación debe cumplir con los códigos de zonificación y construcción.',
    zone: 'mitigation-abandon_first_floor'
  },
  {
    question: '¿Cómo calculo mi Nivel Base de Inundación (BFE)?',
    answer:
      'Ingrese su dirección en la página de inicio de FloodHelpNY.org para encontrar sus zonas de inundación y BFE actuales y futuros. ',
    zone: 'mitigation-abandon_first_floor'
  },
  {
    question: '¿Cómo calculo mi Nivel Base de Inundación (BFE)?',
    answer:
      'Comience por conocer su zona. Si está en una zona AE, un certificado de elevación es la mejor manera de conocer con precisión su BFE. Si se encuentra en una zona X, se encuentra en un área del mapa de inundación que actualmente no tiene una elevación de inundación de base proyectada.\n',
    zone: 'mitigation-abandon_first_floor'
  },
  {
    question:
      '¿Cómo calculo mi Nivel de Inundación del Proyecto (DFE, en inglés)?',
    answer:
      'El DFE determina la elevación más segura para la construcción en zonas con peligro de inundación. En la Ciudad de Nueva York, el DFE generalmente es el Nivel Base de Inundación más dos pies, conocido como “francobordo”, para mayor seguridad. Su diseñador o arquitecto deben consultar con el Departamento de Edificios de NYC durante el proceso de diseño y la tramitación de permisos para asegurarse de que están siguiendo el DFE.',
    zone: 'mitigation-abandon_first_floor'
  },
  {
    question:
      '¿Cuánto cuesta un sistema contra inundaciones que permite entrar y salir el agua?',
    answer: 'Instalar desagües puede costar entre $ 4,000 y $ 8,000.',
    zone: 'mitigation-flood_vents'
  },
  {
    question:
      '¿Cuánto tiempo toma instalar un sistema contra inundaciones que permite entrar y salir el agua?',
    answer:
      'La instalación de desagües para inundaciones se puede hacer en unos días.',
    zone: 'mitigation-flood_vents'
  },
  {
    question: '¿Cómo calculo mi Nivel Base de Inundación (BFE)?',
    answer:
      'Ingrese su dirección en la página de inicio de FloodHelpNY.org para encontrar sus zonas de inundación y BFE actuales y futuros. ',
    zone: 'mitigation-flood_vents'
  },
  {
    question: '¿Cómo calculo mi Nivel Base de Inundación (BFE)?',
    answer:
      'Comience por conocer su zona. Si está en una zona AE, un certificado de elevación es la mejor manera de conocer con precisión su BFE. Si se encuentra en una zona X, se encuentra en un área del mapa de inundación que actualmente no tiene una elevación de inundación de base proyectada.\n',
    zone: 'mitigation-flood_vents'
  },
  {
    question:
      '¿Cómo calculo mi Nivel de Inundación del Proyecto (DFE, en inglés)?',
    answer:
      'El DFE determina la elevación más segura para la construcción en zonas con peligro de inundación. En la Ciudad de Nueva York, el DFE generalmente es el Nivel Base de Inundación más dos pies, conocido como “francobordo”, para mayor seguridad. Su diseñador o arquitecto deben consultar con el Departamento de Edificios de NYC durante el proceso de diseño y la tramitación de permisos para asegurarse de que están siguiendo el DFE.',
    zone: 'mitigation-flood_vents'
  },
  {
    question: '¿Qué es un francobordo?',
    answer:
      'El francobordo es una elevación adicional por encima del Nivel Base de Inundación, que asegura que la construcción está a un nivel más seguro contra inundaciones futuras. En la Ciudad de Nueva York, esto significa dos pies adicionales por encima del BFE. Además de aumentar la seguridad ante inundaciones, el francobordo puede reducir las primas de seguro, lo que ayuda a recuperar los costos de construcción.',
    zone: 'mitigation-flood_vents'
  },
  {
    question:
      '¿Dónde puedo encontrar un contratista para instalar un sistema contra inundaciones que permite entrar y salir el agua?',
    answer:
      "Debe consultar con un ingeniero o arquitecto calificado antes de instalar desagües. El Departamento de Edificios de NYC puede aconsejarle sobre cómo elegir un diseñador y contratistas que tengan \u003ca target='_blank' href='http://www1.nyc.gov/site/buildings/homeowner/homeowner.page'\u003eexperiencia trabajando con dueños de casa\u003c/a\u003e. Además, el DOB organiza sesiones de información semanales con dueños de casa donde los neoyorquinos pueden aprender sobre los estándares de construcción y cómo evaluar contratistas.",
    zone: 'mitigation-flood_vents'
  },
  {
    question: '¿Cómo elijo la opción de resiliencia adecuada para mí?',
    answer:
      'Debe considerar qué opciones están disponibles para su residencia (por ejemplo, no puede elevar fácilmente una casa adosada), el costo inicial de la medida de mitigación y los posibles ahorros en su prima de seguro con el tiempo.',
    zone: 'programs-and-benefits'
  },
  {
    question:
      '¿Qué opciones de mitigación realmente reducirán la tarifa de mi seguro?',
    answer:
      'A la fecha, hay solo tres medidas que afectan las primas de seguro: elevar la residencia, rellenar un sótano o semisótano e instalar desagües para inundaciones, y elevar los equipos mecánicos fuera del sótano. De las tres opciones, la última proporciona la menor reducción de seguro, pero puede ahorrarle bastante dinero en caso de una inundación.',
    zone: 'programs-and-benefits'
  },
  {
    question:
      '¿Qué opciones de mitigación realmente reducirán la tarifa de mi seguro?',
    answer:
      'A partir de ahora, solo hay cuatro medidas que tienen un efecto en las primas de seguro: elevación de la casa, llenar un sótano o gatear e instalar respiraderos, abandonar su primer piso y levantar equipos mecánicos del sótano. La última opción proporciona la reducción de seguro más pequeña de las tres, pero puede ahorrarle una gran cantidad de dinero en caso de inundación.\n',
    zone: 'programs-and-benefits'
  },
  {
    question: '¿Qué sucede si no puedo pagar un seguro contra inundaciones?',
    answer:
      'Vea si puede pagarlo reduciendo el monto de cobertura o aumentando su deducible. Analice si puede hacer cambios económicos en su casa que reducirían el costo, como rellenar un semisótano. Comuníquese con funcionarios electos para saber si hay programas que ayuden a pagar cambios a su residencia que reducirían el costo o si hay grupos de voluntarios que los hacen.',
    zone: 'programs-and-benefits'
  },
  {
    question:
      '¿Qué sucede si no puedo pagar mi prima de seguro contra inundaciones cuando aumente?',
    answer:
      'Quizás pueda reducir su cobertura u obtener un deducible más alto. Tener algo de cobertura es mejor que no tener ninguna. Si tiene una hipoteca en una zona con alto riesgo, sus opciones pueden ser limitadas. Trate de analizar qué cambios puede hacer en su casa para reducir la prima, como rellenar un semisótano o sótano e instalar desagües para inundaciones.',
    zone: 'programs-and-benefits'
  },
  {
    question:
      '¿Por qué la gente está preocupada por el aumento del costo del seguro contra inundaciones?',
    answer:
      "El aumento en los costos de seguros contra inundaciones puede causar dificultades financieras a las familias de clase obrera y de clase media que viven en zonas con alto riesgo de inundación, lo cual podría ponerlos en mayor riesgo de ejecución hipotecaria o desalojo. Para obtener más información, lea el informe “Rising Tides, Rising Costs” (“Suben las mareas, y suben los costos) completo del Center for NYC Neighborhoods \u003ca target='_blank' href='https://cnycn.atavist.com/risingtides'\u003eaquí\u003c/a\u003e.",
    zone: 'programs-and-benefits'
  },
  {
    question:
      '¿Por qué recibí diferentes tarifas de seguro contra inundaciones de diferentes agentes de seguros?',
    answer:
      'El Programa Nacional de Seguro contra Inundaciones establece las tarifas de seguro contra inundaciones y los corredores de seguros no pueden cambiarlas. La única razón por la que podría obtener diferentes cotizaciones de diferentes agentes es que uno o ambos cometieron un error al describir su propiedad o su zona.',
    zone: 'programs-and-benefits'
  },
  {
    question:
      '¿Necesito comprar seguro contra inundaciones si no soy un dueño de casa?',
    answer:
      'Sí, no es probable que el seguro del inquilino cubra los daños causados ​​por las inundaciones; El seguro contra inundaciones se compraría por separado.\n',
    zone: 'understanding-flood-insurance'
  },
  {
    question:
      '¿Por qué se me exige a tener seguro contra inundaciones? ¿Quién lo exige?',
    answer:
      'La ley federal exige que usted tenga seguro contra inundaciones si su vivienda se encuentra en una zona con alto riesgo de inundación (AE, AO o VE) y tiene una hipoteca respaldada por el gobierno federal. (Casi todas las hipotecas están respaldadas por el gobierno federal). Además, si ha recibido ayuda federal por un desastre para hacer reparaciones en su edificio o por pérdida de contenidos, la ley federal exige que usted tenga seguro contra inundaciones. Si no lo compra, no calificará para recibir ayuda.',
    zone: 'understanding-flood-insurance'
  },
  {
    question:
      '¿Qué sucede si se me exige que compre seguro contra inundaciones y no lo hago?',
    answer:
      'Si tiene una hipoteca respaldada por el gobierno federal o un préstamo de la SBA, el agente hipotecario o la SBA comprará el seguro contra inundaciones y agregará ese costo a su pago mensual. Si recibió ayuda federal ante desastres y no compra seguro, no recibirá ayuda federal para reparar o reconstruir su casa, ni para reponer los contenidos dañados por futuras tormentas. (Usted seguiría siendo elegible para otros tipos de ayuda, como la ayuda para vivienda temporal).',
    zone: 'understanding-flood-insurance'
  },
  {
    question: '¿Por qué aumentan las primas del seguro contra inundaciones?',
    answer:
      'Se están eliminando gradualmente los subsidios y el nuevo Mapa de Tasas de Seguro contra Inundaciones de FEMA (FIRM) aumentará el número de hogares en zonas de inundación de alto riesgo.\n',
    zone: 'understanding-flood-insurance'
  },
  {
    question:
      '¿Por qué disminuir mi riesgo de inundación no siempre reduce la tarifa de mi seguro contra inundaciones?',
    answer:
      'Ciertas medidas de mitigación pueden reducir el daño y el costo de la limpieza después de una inundación, en realidad no pueden cambiar los datos utilizados para calcular el riesgo de inundación de su hogar.\n',
    zone: 'understanding-flood-insurance'
  },
  {
    question:
      '¿Cómo puedo disminuir la tarifa de mi seguro contra inundaciones?',
    answer:
      'A partir de ahora, solo hay cuatro medidas que tienen un efecto en las primas de seguro: elevación de la casa, llenar un sótano o gatear e instalar respiraderos, abandonar su primer piso y levantar equipos mecánicos del sótano. La última opción proporciona la reducción de seguro más pequeña de las tres, pero puede ahorrarle una gran cantidad de dinero en caso de inundación.\n',
    zone: 'understanding-flood-insurance'
  },
  {
    question: '¿Qué es FEMA?',
    answer:
      'La Agencia Federal de Manejo de Emergencias. FEMA dirige el Programa Nacional de Seguro contra Inundaciones (NFIP) y también proporciona ayuda de emergencia después de un desastre.\n',
    zone: 'understanding-flood-insurance'
  },
  {
    question: '¿Qué cubre el seguro de “contenidos”?',
    answer:
      'El seguro de contenidos cubre los daños a los bienes personales, causados por una inundación. Las únicas pertenencias cubiertas del sótano son su lavadora, secadora y congelador. (Su horno, caldera, calentador de agua y aire acondicionado están protegidos por la cobertura de su “edificio”). Cuando se aproxime una inundación, saque sus pertenencias del sótano. También es una buena idea tomar fotos de todo antes de que ocurra una inundación para que pueda dar pruebas a la compañía de seguro.',
    zone: 'understanding-flood-insurance'
  },
  {
    question: '¿Qué significan las zonas de inundación de la FEMA?',
    answer:
      'Las zonas de inundación de la FEMA son áreas geográficas que se caracterizan por su riesgo de inundación. Por ejemplo, las zonas con alto riesgo de inundación tienen una probabilidad de inundarse cada año del 1%.',
    zone: 'understanding-flood-insurance'
  },
  {
    question:
      '¿Las zonas de inundación de FEMA son diferentes a las zonas de evacuación de la ciudad?',
    answer:
      "Sí. Puede obtener más información sobre las zonas de evacuación de la ciudad \u003ca target='_blank' href='http://www1.nyc.gov/assets/em/html/know-your-zone/knowyourzone.html'\u003eaquí\u003c/a\u003e.",
    zone: 'understanding-flood-insurance'
  },
  {
    question: '¿Qué es una póliza de riesgo preferencial?',
    answer:
      'Una póliza de riesgo preferencial es una póliza económica para propiedades que se encuentran en zonas con riesgo bajo y moderado de inundación (zona X o en ninguna) con un historial de reclamaciones limitado. Una póliza total cuesta menos de $500 al año. Si obtiene menos cobertura, la prima es más barata.',
    zone: 'understanding-flood-insurance'
  },
  {
    question: '¿Qué es una póliza de riesgo preferencial?',
    answer:
      'Preferred Risk es una política subsidiada solo disponible para zonas de inundación de riesgo moderado y bajo (Zona X o no en una zona de inundación) con historial de reclamos limitado. El costo promedio es de aproximadamente $ 500 anuales.\n',
    zone: 'understanding-flood-insurance'
  },
  {
    question:
      'Si ahora estoy en una zona con riesgo bajo o moderado pero voy a estar en una zona con alto riesgo según los mapas nuevos, ¿aún puedo obtener una póliza de riesgo preferencial?',
    answer: 'Esto se actualizará en una fecha posterior.\n',
    zone: 'understanding-flood-insurance'
  },
  {
    question:
      '¿Cómo se califica para una póliza de riesgo preferencial?",\n        "answer": "Debe estar en la zona X o en una zona sin riesgo y haber recibido solo un pago de más de $1,000 de un programa federal de ayuda ante desastres por distintas inundaciones en los últimos 10 años. La ayuda federal ante desastres se refiere al seguro contra inundaciones del NFIP o bien la FEMA o la SBA o "Build It Back".',
    answer: null,
    zone: 'understanding-flood-insurance'
  },
  {
    question:
      '¿Qué sucede si tengo muchos pagos diferentes por Sandy? ¿Sigo siendo elegible para una póliza de riesgo preferencial?',
    answer: 'Sí, siempre y cuando ningún pago supere los $ 1,000.\n',
    zone: 'understanding-flood-insurance'
  },
  {
    question:
      '¿La obra de construcción de "Build it Back" se cuenta como un pago?',
    answer:
      'Sí, la asistencia de Build it Back está financiada por el gobierno federal y esa asistencia debería informarse.\n',
    zone: 'understanding-flood-insurance'
  },
  {
    question:
      '¿Qué es la Ley de Asequibilidad de Seguros contra Inundaciones para Dueños de Vivienda?',
    answer:
      'La Ley de Asequibilidad de Seguros contra Inundaciones para Dueños de Vivienda de 2014 atenuó o revirtió algunos de los cambios del Programa Nacional de Seguro contra Inundaciones implementados por la Ley Biggert-Waters de 2012. HFIAA restableció la “exención por derecho adquirido” y frenó la eliminación gradual de otros subsidios de primas al limitar los aumentos de las primas al 18% por año para la mayoría de las propiedades. Algunas, como las propiedades comerciales o las gravemente dañadas, aún pueden tener un aumento de 25% al año.',
    zone: 'understanding-flood-insurance'
  },
  {
    question: '¿Qué significa “BFE”?',
    answer:
      'BFE es el acrónimo en inglés de “Nivel Base de Inundación”. Solo las propiedades en zonas con alto riesgo de inundación tienen un BFE, que refleja la altura (en pies) sobre el nivel del mar que se proyecta que se elevarán las aguas de una crecida en una tormenta de “100 años”, es decir, una tormenta que tiene 1% de probabilidad de ocurrir cada año. La tarifa por cada $100 de cobertura se basa en la distancia por encima o por debajo del BFE que tiene la elevación de su casa. Las tarifas para residencias donde el piso inferior está en el BFE o por encima este, son económicas.',
    zone: 'understanding-flood-insurance'
  },
  {
    question:
      '¿Por qué un cambio en el BFE cambiaría mi seguro contra inundaciones?',
    answer:
      'La mayoría de las pólizas de seguro contra inundaciones se cotizan comparando la elevación de la residencia con el Nivel Base de Inundación en la zona con alto riesgo de inundación. Cuanto menor sea la elevación de una residencia en comparación con el BFE, más cara será la tarifa del seguro contra inundaciones. La elevación se mide contra el nivel del mar, no el nivel del suelo.',
    zone: 'understanding-flood-insurance'
  },
  {
    question: '¿Cómo calculo mi Nivel Base de Inundación (BFE)?',
    answer:
      'Ingrese su dirección en la página de inicio de FloodHelpNY.org para encontrar sus zonas de inundación y BFE actuales y futuros. ',
    zone: 'understanding-flood-insurance'
  },
  {
    question: '¿Cómo calculo mi Nivel Base de Inundación (BFE)?',
    answer:
      'Comience por conocer su zona. Si está en una zona AE, un certificado de elevación es la mejor manera de conocer con precisión su BFE. Si se encuentra en una zona X, se encuentra en un área del mapa de inundación que actualmente no tiene una elevación de inundación de base proyectada.\n',
    zone: 'understanding-flood-insurance'
  },
  {
    question: '¿Cómo puedo obtener un certificado de elevación?',
    answer:
      'Los certificados de elevación deben ser completados por un agrimensor o ingeniero y pueden costar entre $ 500 y $ 800 en casos sencillos, pero pueden costar más en casos complicados.',
    zone: 'understanding-flood-insurance'
  },
  {
    question: '¿Estoy obligado a obtener un certificado de elevación?',
    answer:
      'Si su propiedad se encuentra actualmente en las áreas especiales de peligro de inundación (SFHA), el certificado de elevación es la mejor manera de saber si su seguro está calificado correctamente. Además, si ve que los nuevos mapas pondrán a su hogar en un riesgo mayor, el certificado de elevación puede garantizar que esté correctamente mapeado.\n',
    zone: 'understanding-flood-insurance'
  },
  {
    question: '¿Por qué cambian los mapas de zonas de inundación?',
    answer:
      'FEMA actualiza periódicamente los mapas que crea para las zonas con peligro de inundación en todo el país. Los mapas actuales de la Ciudad de Nueva York se adoptaron en 1983 y no reflejan con precisión el riesgo de inundación actual.',
    zone: 'understanding-flood-insurance'
  },
  {
    question:
      '¿Por qué no está claro cuándo entrarán en vigor los mapas nuevos?',
    answer:
      "La Ciudad de Nueva York apeló el mapa preliminar de tarifas de seguro contra inundaciones de FEMA en junio de 2015, argumentando que se había sobrestimado el riesgo de inundación en cinco distritos. En octubre de 2016 FEMA, anunció que había aceptado la apelación de la ciudad sobre los mapas preliminares de inundaciones de FEMA y comenzará un nuevo proceso para revisar los mapas de inundaciones de la ciudad. Se necesitarán por lo menos tres o cuatro años para que la FEMA publique los nuevos mapas revisados. Más información \u003ca href='http://www1.nyc.gov/site/floodmaps/index.page' target='_blank'\u003eaquí\u003c/a\u003e.",
    zone: 'understanding-flood-insurance'
  },
  {
    question: '¿Qué es una “exención por derechos adquiridos”?',
    answer:
      'Cuando se adopten los nuevos mapas de inundación, si su residencia queda ubicada en una zona con mayor riesgo de inundación o el BFE de su propiedad aumenta, usted puede ahorrar dinero bajo la regla de exención por derechos adquiridos. Si usted es elegible, la exención por derechos adquiridos le permite fijar la zona de inundación o el BFE anterior para fines relacionados con la cotización de seguros. Hay dos tipos de exención por derechos adquiridos: “cobertura continua” y “construida en conformidad”. Solo las viviendas construidas después de noviembre de 1983 son elegibles para la exención “construida en conformidad”.',
    zone: 'understanding-flood-insurance'
  },
  {
    question:
      '¿Cómo puedo ser elegible para la exención por derecho adquirido de “cobertura continua”?',
    answer:
      'Asegurarse de tener una política antes de que los mapas cambien básicamente significa que no tiene cobertura continua.\n',
    zone: 'understanding-flood-insurance'
  },
  {
    question:
      '¿Cómo puedo ser elegible para la exención por derecho adquirido de “construida en conformidad”?',
    answer:
      'Puede ser elegible si su residencia se construyó antes de noviembre de 1983 y actualmente se encuentra en una zona con alto riesgo de inundación. Además, debe demostrar que su casa se construyó en conformidad con el mapa de inundación actual y que no la ha modificado. Por ejemplo, si convirtió un garaje en planta baja en un espacio habitable, no será elegible a menos que lo convierta a garaje de nuevo.',
    zone: 'understanding-flood-insurance'
  },
  {
    question:
      'Si tengo una póliza de riesgo preferencial ahora y mi zona cambia a AE o VE, ¿puedo obtener la tarifa de póliza de riesgo preferencial bajo la exención por derecho adquirido?',
    answer:
      'No, hay un subsidio recientemente asignado, pero la tasa probablemente aumentará 15-18% anualmente hasta que su póliza alcance el monto de riesgo total.\n',
    zone: 'understanding-flood-insurance'
  },
  {
    question:
      '¿Cuánta cobertura de seguro contra inundaciones debo tener si obtuve ayuda por una catástrofe (FEMA, SBA, "Build it Back")?',
    answer:
      'Si usted recibió ayuda federal ante desastres para reparar o reconstruir su edificio o por contenidos dañados, debe tener cobertura por al menos el monto de la ayuda que recibió. La cobertura de contenidos se exige si usted recibió ayuda federal por contenidos dañados. Este requisito se mantiene con la propiedad para siempre, para usted y cualquier futuro comprador. Debe informar al comprador de este requisito.',
    zone: 'understanding-flood-insurance'
  },
  {
    question:
      '¿Cuánta cobertura de seguro contra inundaciones debo tener si tengo una hipoteca?',
    answer:
      'Si usted se encuentra en una zona con alto riesgo de inundación y tiene una hipoteca, debe tener cobertura de edificio por al menos el saldo impago de la hipoteca (el saldo de capital) mientras tenga la hipoteca. La cobertura de contenidos no es requerida.',
    zone: 'understanding-flood-insurance'
  },
  {
    question: '¿Cómo sé si recibí ayuda federal ante desastres en el pasado?',
    answer:
      'Si recibió ayuda de FEMA, SBA, "Build it Back" o New York Rising, para reparar o reconstruir su residencia o por los contenidos, debido a daños causados por una inundación, entonces usted ha recibido ayuda federal ante desastres. Para la mayoría de las personas esto sería ayuda para los daños causados ​​por el huracán Irene o Sandy.',
    zone: 'understanding-flood-insurance'
  },
  {
    question: '¿Cómo determino el tamaño de mi núcleo residencial?',
    answer:
      'El tamaño del núcleo residencial se puede determinar contando la cantidad de personas que viven con usted, incluyéndose.',
    zone: 'rtapp-preapp-household-size'
  },
  {
    question:
      '¿Qué sucede si la tarifa estimada calculada aquí es diferente a mi tarifa actual?',
    answer:
      'Si la tarifa estimada calculada es más baja que su tarifa actual, podría estar pagando demasiado por el seguro contra inundaciones. Comuníquese con su corredor de seguros para revisar su póliza. Revise toda la información para asegurarse de que es correcta. Compruebe que la propiedad está descrita correctamente, que está listada como su residencia principal (si es así), y que la zona de inundación y el BFE son los correctos.',
    zone: 'rate-estimate'
  },
  {
    question: '¿Por qué se desborda el alcantarillado?',
    answer:
      'Los alcantarillados se diseñan para retener y conducir cierta cantidad de aguas residuales y albañales. A veces, durante fuertes lluvias inusuales o marejadas ciclónicas, los alcantarillados pueden recibir volúmenes de agua más allá de sus capacidades. Al sobrevenir un desbordamiento, esas aguas pueden entrar de contraflujo en tu hogar a través de la tubería que conecta la casa al alcantarillado de la ciudad.',
    zone: 'mitigation-backwater_valve'
  },
  {
    question: '¿Cuánto cuesta instalar una válvula antirretorno?',
    answer:
      'Eso depende de ciertos factores, como la disposición espacial de las tuberías de alcantarillado, la profundidad y ubicación de esas tuberías, los peligros medioambientales (por ejemplo., pintura con plomo) en el área donde se instalará la válvula, qué tipo de válvula se instalará y otros. El costo puede ser tan bajo como $600 o por encima de $5,000.',
    zone: 'mitigation-backwater_valve'
  },
  {
    question: '¿Hay diferentes tipos de válvulas antirretorno?',
    answer:
      'Sí, existen válvulas de compuerta, esféricas y de flapper (o pestaña). Un ingeniero o plomero con licencia pueden indicarte, en su caso, cuál es la mejor para tu hogar.',
    zone: 'mitigation-backwater_valve'
  },
  {
    question: '¿Cómo sé qué tipo de válvula antirretorno instalar?',
    answer:
      'Un ingeniero o plomero con licencia pueden indicarte, en su caso, cuál es la mejor válvula antirretorno para tu hogar.',
    zone: 'mitigation-backwater_valve'
  },
  {
    question: '¿Qué riesgos se enfrentan al instalar una válvula antirretorno?',
    answer:
      'Existe un pequeño riesgo de que falle la válvula antirretorno. Las válvulas de aguas residuales reducen la posibilidad de que las aguas residuales ingresen a su hogar a través de su tubería de alcantarillado, pero si la válvula no se mantiene adecuadamente, las aguas residuales pueden atascarse y filtrarse.\n',
    zone: 'mitigation-backwater_valve'
  },
  {
    question:
      '¿Hay alguna forma de saber si el flapper está cerrado o abierto?',
    answer:
      'Sabrás si tu válvula está cerrada porque el agua cesará de irse por tus desagües. Si sospechas que la válvula pudiera estar cerrada, puedes revisar también usando el punto de acceso a la válvula que proveyó el plomero durante la instalación. Algunas válvulas tienen alarmas conectadas que se activan cuando aquellas se cierran, pero estas pueden ser costosas.',
    zone: 'mitigation-backwater_valve'
  },
  {
    question: '¿Cómo doy mantenimiento a la válvula antirretorno?',
    answer:
      'Solicita a tu plomero que te muestre y provea las instrucciones del fabricante sobre operación y mantenimiento de la válvula. Suele ser muy sencillo: abre un par de veces al año el punto de acceso a tu válvula para asegurarte de que no hay obstrucción. Si algo está obstruyendo, elimínalo. Si la compuerta necesita lubricante, aplícalo. Algunos plomeros ofrecen programas de mantenimiento y harán estas cosas para ti.',
    zone: 'mitigation-backwater_valve'
  },
  {
    question: '¿Cómo sé dónde debo instalar la válvula?',
    answer:
      'Un plomero con licencia evaluará la condición y disposición espacial de tu cloaca sanitaria. Así precisará el lugar más apropiado para instalar la válvula. Consulta siempre a un plomero con licencia para determinar ese lugar, ya que una válvula antirretorno instalada en lugar equivocado puede empeorar las cosas.',
    zone: 'mitigation-backwater_valve'
  },
  {
    question: '¿Quién puede instalar una válvula antirretorno?',
    answer:
      'Solo un plomero con licencia debe instalar una válvula antirretorno que haya completado otras instalaciones de válvulas antirretorno en su vecindario. ¡Puedes pedir referencias!\n',
    zone: 'mitigation-backwater_valve'
  },
  {
    question: '¿Qué alternativas tengo para instalar válvulas antirretorno?',
    answer:
      'Puedes montar válvulas antirretorno en todas las instalaciones de plomería por debajo del nivel básico de inundación en vez de instalar una en la tubería que conecta tu casa al alcantarillado, si se considera apropiado por un plomero calificado y con licencia. También puedes acondicionar tu sótano a prueba de inundaciones y usarlo sólo para estacionar o almacenar. Aunque haya reflujo del alcantarillado, tus pertenencias estarían protegidas.  \nEl Departamento de Protección Medioambiental de la Ciudad de Nueva York (NYCDEP) preparó \u003ca href="http://www.nyc.gov/html/dep/pdf/brochures/flood-preparedness-flyer.pdf" target="_blank"\u003eeste\u003c/a\u003e Folleto de Preparación contra Inundaciones para ayudar a los propietarios de viviendas a reducir las probabilidades que estas se inunden en caso de lluvias intensas.',
    zone: 'mitigation-backwater_valve'
  },
  {
    question:
      '¿Por qué instalar una válvula antirretorno no cambia mi tasa de seguro?',
    answer:
      'La redacción actual del Programa Nacional de Seguro contra Inundaciones (NFIP) no permite reducciones de las pólizas por mitigación en seco del riesgo de inundación y la válvula antirretorno cae en este acápite. Por ejemplo, elevar tu casa propicia un descuento porque estás elevando tu planta más baja por encima del nivel básico de inundación (BFE). Una válvula antirretorno reduce, pero no elimina el riesgo de inundación.',
    zone: 'mitigation-backwater_valve'
  },
  {
    question:
      '¿Qué preguntas debo formular al buscar un plomero para instalar esta válvula?',
    answer:
      'Pregunta sobre licencia, experiencia en la Ciudad de Nueva York o en tu vecindario, así como sobre las certificaciones y las referencias de proyectos similares. Trata de obtener cotizaciones de al menos tres plomeros para compararlas.',
    zone: 'mitigation-backwater_valve'
  },
  {
    question: '¿Cómo sé si necesito una válvula antirretorno?',
    answer:
      'Siempre consulte con un plomero con licencia para determinar si se beneficiaría de una válvula antirretorno ya que tener una instalada en ciertas propiedades puede empeorar las cosas.\n',
    zone: 'mitigation-backwater_valve'
  },
  {
    question:
      '¿Por qué importa si mi propiedad está conectado a la alcantarilla de la ciudad? ',
    answer:
      'El programa intenta reducir el riesgo del respaldo de alcantarillado en las casas durante las inundaciones causado por el rebalso del alcantarillado. El rebalso combinado del alcantarillado es una condición que ocurre cuando el sistema del alcantarillado de la ciudad está completamente lleno. La mayoría de las propiedadas estan conectados a ese sistema, pero hay algunos propiedades que todavía tiene tanques sépticos. Si no esta conectado al sistema de la ciudad y esta experimentando el respaldo de alcantarillado, la causa es otra, y debería consultar con una profesional licencido para determinar el origen del problema para resolvarlo. ',
    zone: 'rtapp-preapp-sewer'
  },
  {
    question:
      ' ¿Se incluye la cobertura de la red de alcantarillado en la póliza de seguro de mi casa?',
    answer:
      '\nEl servicio de alcantarillado no suele estar cubierto por las pólizas de seguro estándar para propietarios de viviendas. El seguro de alcantarillado es un "endoso" o "jinete" que a menudo se puede agregar al seguro estándar de propietarios.',
    zone: 'rtapp-preapp-sewage'
  },
  {
    question: ' ¿Cómo puedo obtener esta cobertura?',
    answer:
      '\nLos propietarios de viviendas pueden hablar sobre cómo obtener una cláusula adicional de seguro de alcantarillado con su proveedor de seguros de propietarios. El seguro de alcantarillado normalmente cuesta menos de $ 100 por año. Las reclamaciones anteriores, el crédito y la elevación a nivel de la calle a veces pueden afectar las tasas.',
    zone: 'rtapp-preapp-sewage'
  },
  {
    question: ' ¿Qué es una válvula antirretorno?',
    answer:
      '\nLa mayoría de los hogares en la ciudad de Nueva York tienen una tubería que recolecta los desechos de los lavamanos e inodoros de su hogar y los lleva a la alcantarilla de la ciudad. Una válvula antirretorno es un dispositivo que se instala en esta tubería para reducir el riesgo de que las aguas residuales retrocedan a través de esa tubería hasta su casa, especialmente durante las fuertes lluvias. \u003ca href="https://www.floodhelpny.org/en/mitigation/backwater_valve"\u003e\nObtenga más información sobre las válvulas antirretorno.\u003c/a\u003e',
    zone: 'rtapp-preapp-sewage'
  },
  {
    question: '¿Qué es una válvula antirretorno?',
    answer:
      'Most homes in New York City have a pipe that collects waste from the sinks and toilets in your home and carries it to the city sewer. A sewer backwater valve is a device that is installed on this pipe to reduce the risk of sewage backing up through that pipe and into your home, especially during heavy rain. \u003ca href="https://www.floodhelpny.org/en/mitigation/backwater_valve"\u003e\nObtenga más información sobre las válvulas antirretorno.\u003c/a\u003e',
    zone: 'rtapp-preapp-backwater-exist'
  },
  {
    question:
      ' ¿Cómo puedo saber si mi propiedad tiene una válvula antirretorno?',
    answer:
      '\nLa mejor manera de determinar si tiene una válvula antirretorno o si necesita una es consultar con un plomero autorizado y calificado. Una válvula antirretorno puede ser difícil de identificar por su cuenta, especialmente si se instaló hace mucho tiempo.',
    zone: 'rtapp-preapp-backwater-exist'
  },
  {
    question: ' ¿Por qué necesitas saber si tengo una válvula?',
    answer:
      '\nEstamos tratando de determinar si usted es elegible para la instalación de una válvula antirretorno gratuita en su hogar.',
    zone: 'rtapp-preapp-backwater-exist'
  },
  {
    question: '¿Por qué se me exige a tener seguro contra inundaciones?',
    answer:
      'La ley federal exige que usted tenga seguro contra inundaciones si su vivienda se encuentra en una zona con alto riesgo de inundación (AE, AO o VE) y tiene una hipoteca respaldada por el gobierno federal. (Casi todas las hipotecas están respaldadas por el gobierno federal). Además, si ha recibido ayuda federal por un desastre para hacer reparaciones en su edificio o por pérdida de contenidos, la ley federal exige que usted tenga seguro contra inundaciones. Si no lo compra, no calificará para recibir ayuda.',
    zone: 'rtapp-preapp-flood-insurance'
  },
  {
    question: '¿Que cubre el seguro contra las inundaciones?',
    answer:
      'Existen dos tipos de cobertura: edificación y contenidos. Tiene la posibilidad de elegir las cifras de cobertura y deducibles separado para cada uno. \u003ca href="https://www.floodhelpny.org/es/understanding-flood-insurance"\u003eAprender más en nuestra pagina sobre el tema\u003c/a\u003e. ',
    zone: 'rtapp-preapp-flood-insurance'
  },
  {
    question:
      '¿Qué sucede si se me exige que compre seguro contra inundaciones y no lo hago?',
    answer:
      'Si tiene una hipoteca respaldada por el gobierno federal o un préstamo de la SBA, el agente hipotecario o la SBA comprará el seguro contra inundaciones y agregará ese costo a su pago mensual. Si recibió ayuda federal ante desastres y no compra seguro, no recibirá ayuda federal para reparar o reconstruir su casa, ni para reponer los contenidos dañados por futuras tormentas. (Usted seguiría siendo elegible para otros tipos de ayuda, como la ayuda para vivienda temporal).\n',
    zone: 'rtapp-preapp-flood-insurance'
  },
  {
    question: '¿Cómo aprender más sobre el seguro contra las inundaciones? ',
    answer:
      'Puede visitar \u003ca href="https://www.floodhelpny.org/es/understanding-flood-insurance"\u003enuestra pagina completa de recursos sobre el tema\u003c/a\u003e',
    zone: 'rtapp-preapp-flood-insurance'
  },
  {
    question: ' ¿El seguro de propietario de vivienda cubre las inundaciones?',
    answer: '\nEl seguro de propietarios no cubre las inundaciones.',
    zone: 'rtapp-preapp-homeowner-insurance'
  },
  {
    question:
      '¿Cuánto seguro de vivienda se requiere, y cuándo se debe comprar?',
    answer:
      '\nDebe tener un seguro de vivienda antes de ser aceptado en el programa. No especificamos cuánto seguro de propietario de vivienda debe tener, solo que lo tenga.',
    zone: 'rtapp-preapp-homeowner-insurance'
  },
  {
    question: '¿Por qué me están preguntando? ',
    answer:
      'Para ser eligible para el programa, la propiedad hubiera sido su residencia primaria durante el huracán Sandy.',
    zone: 'rtapp-preapp-sandy-ownership'
  },
  {
    question: '¿Quién cuenta como un "miembro del hogar"?',
    answer:
      'INCLUYE: personas relacionadas / no relacionadas que viven en la casa; cualquier persona temporalmente ausente de la escuela o el trabajo; cualquiera encarcelado por un corto período de tiempo pero que volverá a la casa; y militares activos que regresarán a la casa. Los niños con custodia compartida deben figurar en la lista si pasan al menos el 50% de su tiempo con el hogar.',
    zone: 'rtapp-status-FullApplication'
  },
  {
    question: '¿Qué debemos presentar como prueba de ingresos?',
    answer:
      'Adjunte comprobante de ingresos de todos los miembros del hogar mayores de 18 años (idealmente, este es un Formulario 1040 del IRS firmado). Si no está disponible, consideraremos los últimos tres meses consecutivos de recibos de pago o declaraciones de beneficios recientes; También podemos aprobar documentación alternativa.',
    zone: 'rtapp-status-FullApplication'
  },
  {
    question: '¿Qué se considera ingreso anual?',
    answer:
      'Cantidad antes de deducciones de nómina de sueldos, salarios, pago de horas extras, comisiones, tarifas, propinas, bonificaciones y pago de las Fuerzas Armadas\nIngresos netos por operación de negocio o profesión\nIntereses, dividendos y otros ingresos netos de cualquier tipo de bienes inmuebles o personales.\nMontos periódicos recibidos del Seguro Social, anualidades, pólizas de seguro, fondos de jubilación, pensiones, beneficios por discapacidad, beneficios por fallecimiento, manutención infantil y pensión alimenticia\nPagos de alquiler recibidos de inquilinos\nPagos en lugar de ganancias (es decir, compensación por discapacidad / desempleo, compensación de trabajadores, indemnización por despido)',
    zone: 'rtapp-status-FullApplication'
  },
  {
    question: '¿Qué cuenta como prueba de seguro contra inundaciones?',
    answer:
      'La página de declaración de seguro contra inundaciones puede adjuntarse aquí como prueba de seguro.\n',
    zone: 'rtapp-status-FullApplication'
  },
  {
    question: '¿Qué cuenta como prueba de seguro de vivienda?',
    answer:
      'Su página de declaración de seguro de propietarios se puede adjuntar aquí como prueba de seguro.\n',
    zone: 'rtapp-status-FullApplication'
  },
  {
    question: '¿Qué hago si tengo inquilinos?',
    answer:
      'Los propietarios de viviendas con inquilinos deben completar un formulario de Verificación de ingresos del inquilino; Una forma por unidad. El propietario es responsable de enviar el formulario con la documentación de respaldo al Centro. Si el Centro recibe información que indique sus unidades de alquiler, se enviará un formulario de verificación de inquilinos por correo electrónico. Para preguntas, envíe un correo electrónico a info@floodhelpny.org o llame al Centro al 646-786-0888.\n',
    zone: 'rtapp-status-FullApplication'
  },
  {
    question:
      '¿Qué hago cuando se completa el Formulario de verificación de ingresos del inquilino?',
    answer:
      'Envíelo por correo al Centro dentro de los 14 días posteriores a la recepción a:\n\nCenter for NYC Neighborhoods, attn. FloodHelpNY\n55 Broad Street\n10th Floor\nNew York, NY 10004\n\nTambién puede escanear y enviar por correo electrónico el formulario y los documentos de respaldo a info@floodhelpny.org.',
    zone: 'rtapp-status-FullApplication'
  },
  {
    question: '¿Por qué son relevantes los tipos de seguros?',
    answer:
      'El Programa debe revisar los beneficios de reparaciones anteriores para confirmar que no haya duplicación de beneficios.\n',
    zone: 'rtapp-status-FullApplication'
  },
  {
    question:
      '¿Por qué necesita saber sobre la compensación de la válvula antirretorno?',
    answer:
      'Si los propietarios ya han recibido asistencia para reparar sus propiedades de un programa anterior, el Programa debe revisar estos beneficios para confirmar que no hay duplicación de beneficios antes de que se brinden los servicios del Programa.',
    zone: 'rtapp-status-FullApplication'
  },
  {
    question:
      '¿Por qué necesita saber sobre la compensación por Huracán Sandy?',
    answer:
      'Si los propietarios ya han recibido asistencia para reparar sus propiedades de un programa anterior, el Programa debe revisar estos beneficios para confirmar que no hay duplicación de beneficios antes de que se brinden los servicios del Programa.',
    zone: 'rtapp-status-FullApplication'
  },
  {
    question: '¿Quién se considera el "solicitante"?',
    answer:
      'El solicitante debe figurar en la escritura, debe ser mayor de 18 años y debe ser designado como el jefe del hogar (HOH) a los efectos de determinar la elegibilidad de ingresos. HOH debe poder firmar acuerdos en nombre del hogar.',
    zone: 'rtapp-status-FullApplication'
  },
  {
    question: '¿Quién es un cosolicitante?',
    answer:
      'Todas las personas que figuran en la escritura de la propiedad y que viven en la propiedad DEBEN figurar como Co-Solicitante. Cada cosolicitante debe firmar donde se solicite en esta Solicitud completa, el Acuerdo de subvención para propietarios de viviendas, el Acuerdo tripartito y cualquier otro acuerdo que el Solicitante debe firmar antes de recibir los servicios del Programa.\n',
    zone: 'rtapp-status-FullApplication'
  },
  {
    question: '¿Qué significa estar precalificado? ',
    answer:
      'Significa que cumple con los requisitos mínimos para el programa, como tener una casa en uno de los vecindarios elegibles. Sin embargo, para determinar aún más su elegibilidad, deberá presentar una solicitud completa.\n',
    zone: 'rtapp-status-IntakeComplete'
  },
  {
    question:
      '¿Cuáles son los próximos pasos para presentar una solicitud para el programa? ',
    answer:
      'Después de revisar la información que usted presentó para precalificar, determinaremos si le invitaremos a hacer una solicitud para el programa. En ese caso, usted recibirá un correo electrónico solicitando que usted llene el formulario de solicitud en línea. También le pediremos que presente documentación de respaldo para confirmar sus ingresos y seguro, de haberlo. La solicitud y la documentación de respaldo se utilizarán para confirmar su elegibilidad. ',
    zone: 'rtapp-status-IntakeComplete'
  },
  {
    question: '¿Por qué el programa pide documentación de respaldo? ',
    answer:
      'De conformidad con los requisitos federales de financiación, confirmamos los ingresos, el seguro contra inundaciones y la información de inquilino de cada solicitante. ',
    zone: 'rtapp-status-IntakeComplete'
  },
  {
    question: '¿Cómo obtengo ayuda para llenar la solicitud?',
    answer:
      'Si tiene preguntas o necesita ayuda para llenar la solicitud, llame a nuestro Núcleo de Propietarios al (646) 786-0888.',
    zone: 'rtapp-status-FullApplication'
  },
  {
    question: '¿Por qué es tan larga la solicitud?',
    answer:
      'Debido a que este es un programa financiado federalmente, estamos obligados a obtener información de todos los propietarios de vivienda que presenten solicitud. No se preocupe, su información se guardará de manera segura. Vea nuestra política de privacidad.',
    zone: 'rtapp-status-FullApplication'
  },
  {
    question:
      '¿Tener una válvula antirretorno reducirá el costo de mi seguro contra inundaciones?',
    answer:
      'No. Sin embargo, podría ser un factor a considerar cuando decida la cantidad de cobertura para contenido de la vivienda que necesita en su póliza de seguro contra inundaciones. La válvula antirretorno podrá tener un impacto sobre sus primas de seguro de propiedad.',
    zone: 'rtapp-status-FullApplication'
  },
  {
    question: '¿Qué información adicional necesitan?',
    answer:
      'Necesitamos copias legibles de todos los documentos que se solicitan para revisar su seguro de propiedad, licencia de conductor, información actual sobre ingresos brutos anuales, prueba de seguro contra inundaciones (si corresponde), e información sobre ingresos de inquilinos (si corresponde). Le pediremos información adicional si los documentos presentados son ilegibles o si no vienen con su solicitud. ',
    zone: 'rtapp-status-ApplicationSubmitted'
  },
  {
    question: '¿Pueden explicar la financiación federal del programa?',
    answer:
      'El programa está financiado por una Subvención en Bloque para Desarrollo Comunitario-Recuperación ante Desastres [Community Development Block Grant–Disaster Recovery (CDBG-DR)] del Departamento de Viviendas y Desarrollo Urbano de los EE.UU. [U.S. Department of Housing and Urban Development (HUD)]. La financiación se provee a través del Programa de Reconstrucción Comunitaria de Nueva York [NY Rising Community Reconstruction (NYRCR) Program], que forma parte de la Oficina del Gobernador del Estado de Nueva York para Recuperación ante Tormentas [New York State Governor’s Office of Storm Recovery (GOSR).]',
    zone: 'rtapp-status-ApplicationSubmitted'
  },
  {
    question: 'Generalmente, ¿cuánto tarda el proceso de aprobación?',
    answer:
      'Una vez que se reciban su solicitud y los documentos adicionales, puede esperar que se tome una determinación sobre la aprobación dentro de cinco días hábiles.',
    zone: 'rtapp-status-ApplicationSubmitted'
  },
  {
    question: '¿Qué es el Convenio de Subvención del Propietario?',
    answer:
      'El Convenio de Subvención del Propietario [Homeowner Grant Agreement (HGA)] le permite al Centro, como administrador del programa, adjudicar una porción de los fondos federales para recuperación ante desastres a propietarios elegibles, y requiere que el (los) propietario(s) acepten todos los términos y las condiciones especificados en el documento.',
    zone: 'rtapp-status-GrantAgreement'
  },
  {
    question: '¿Quién lo firma?\n',
    answer:
      'Todas las personas nombradas en la escritura de propiedad deberán firmar el Convenio de Subvención del Propietario, aún si no aparecen en la solicitud como solicitante o co-solicitante.\n',
    zone: 'rtapp-status-GrantAgreement'
  },
  {
    question:
      '¿Cuáles son los términos y las condiciones principales del HGA?\n',
    answer:
      'Remítase a este documento para informarse sobre los términos y las condiciones principales del HGA. El (los) propietario(s) tiene(n) la responsabilidad de estudiar el documento en su totalidad para entender plenamente todas las condiciones del mismo.\n',
    zone: 'rtapp-status-GrantAgreement'
  },
  {
    question: '¿Cómo le presento el HGA al Centro?\n',
    answer:
      'Si solo una persona tiene que firmar su Convenio de Subvención del Propietario (es decir, solo hay una persona nombrada en la escritura de propiedad), entonces usted puede firmar el documento electrónicamente. Después de que determinemos su elegibilidad, le enviaremos un enlace para hacer esto. Si más de una persona tiene que firmar su Convenio de Subvención del Propietario, puede bajar e imprimir el documento de su página de estado. Una vez que esté lleno, puede enviarlo por fax al (646) 506-4621, enviar una copia por correo electrónico a info@floodhelpny.org o mandarlo vía servicio postal a FloodHelpNY, Center for NYC Neighborhoods, 55 Broad Street, 10th Floor, New York, NY 10004. \n',
    zone: 'rtapp-status-GrantAgreement'
  },
  {
    question:
      '¿Por qué se envía una copia de mi HGA a la Oficina del Gobernador del Estado de Nueva York para Recuperación ante Tormentas?\n',
    answer:
      'Debido que la financiación del programa se proporciona a través del Programa de Reconstrucción Comunitaria de Nueva York [NY Rising Community Reconstruction (NYRCR) Program], que forma parte de la Oficina del Gobernador del Estado de Nueva York para Recuperación ante Tormentas [New York State Governor’s Office of Storm Recovery (GOSR)] la Oficina del Gobernador del Estado de Nueva York para Recuperación ante Tormentas exige una copia del convenio. \n',
    zone: 'rtapp-status-GrantAgreement'
  },
  {
    question: '¿Por qué no califiqué?\n',
    answer:
      'Hay muchas razones por las que esto puede suceder: Su vivienda no se beneficiará con la instalación de una válvula antirretorno; usted no tiene seguro contra inundaciones y está en una zona de inundación AE; no tiene seguro de propiedad; no era propietario de su vivienda durante el Huracán Sandy, y otros factores. ¿Tiene preguntas? Llámenos al (646) 786-0898 o contáctenos por correo electrónico a info@floodhelpny.org.\n',
    zone: 'rtapp-status-Ineligible'
  },
  {
    question: '¿Puedo volver a presentar una solicitud?\n',
    answer:
      'Si usted no reúne los requisitos de elegibilidad pero está interesado en que le contactemos cuando estén disponibles nuevos programas de resistencia a inundaciones o si cambian nuestros requisitos de elegibilidad, llámenos al (646) 786-0888 o envíenos un correo electrónico a info@floodhelpny.org. Si su situación cambia, y cree que puede satisfacer los requisitos de elegibilidad, puede presentar una nueva solicitud. \n',
    zone: 'rtapp-status-Ineligible'
  },
  {
    question: '¿Tienen otros programas que pudieran beneficiarme?\n',
    answer:
      '¡Sí! Si desea saber para cuáles otros programas y prestaciones podría ser elegible, por favor comuníquese con nosotros al (646) 786-0888. \n',
    zone: 'rtapp-status-Ineligible'
  },
  {
    question: '¿Cuál es el costo promedio de hacer esto por mi cuenta?\n',
    answer:
      'Depende de varios factores, inclusive la configuración de sus tuberías de alcantarillado, la profundidad y ubicación del tubo que conecta su vivienda con el alcantarillado, si existen peligros ambientales (por ejemplo, pintura con plomo) en el área donde se instalará la válvula, el tipo de válvula que instale, etc. El costo puede oscilar entre $600 y más de $5,000.\n',
    zone: 'rtapp-status-Ineligible'
  },
  {
    question: '¿Cuál es el costo promedio de hacer esto por mi cuenta?\n',
    answer:
      'Depende de varios factores, inclusive la configuración de sus tuberías de alcantarillado, la profundidad y ubicación del tubo que conecta su vivienda con el alcantarillado, si existen peligros ambientales (por ejemplo, pintura con plomo) en el área donde se instalará la válvula, el tipo de válvula que se instale, etc. El costo puede oscilar entre $600 y más de $5,000.\n',
    zone: 'rtapp-status-Ineligible'
  },
  {
    question: '¿Tienen otros programas que pudieran beneficiarme?\n',
    answer:
      '¡Sí! Si desea saber para cuáles otros programas y prestaciones podría ser elegible, por favor comuníquese con nosotros al (646) 786-0888. \n',
    zone: 'rtapp-status-Withdrawn'
  },
  {
    question: '¿Cuál es el costo promedio de hacer esto por mi cuenta?\n',
    answer:
      'Depende de varios factores, inclusive la configuración de sus tuberías de alcantarillado, la profundidad y ubicación del tubo que conecta su vivienda con el alcantarillado, si existen peligros ambientales (por ejemplo, pintura con plomo) en el área donde se instalará la válvula, el tipo de válvula que instale, etc. El costo puede oscilar entre $600 y más de $5,000.\n',
    zone: 'rtapp-status-Withdrawn'
  },
  {
    question: '¿Cuál es el costo promedio de hacer esto por mi cuenta?\n',
    answer:
      'Depende de varios factores, inclusive la configuración de sus tuberías de alcantarillado, la profundidad y ubicación del tubo que conecta su vivienda con el alcantarillado, si existen peligros ambientales (por ejemplo, pintura con plomo) en el área donde se instalará la válvula, el tipo de válvula que se instale, etc. El costo puede oscilar entre $600 y más de $5,000.\n',
    zone: 'rtapp-status-Withdrawn'
  },
  {
    question: '¿Qué otros recursos hay disponibles en mi área?\n',
    answer:
      'Si usted desea obtener una válvula antirretorno por su cuenta, puede consultar esta guía para que le ayude.\n',
    zone: 'rtapp-status-Withdrawn'
  },
  {
    question: '¿Cuántas personas vendrán a evaluar mi vivienda?\n',
    answer:
      'Un equipo de entre dos y cuatro ingenieros e inspectores certificados visitarán su vivienda para evaluar sus vulnerabilidades ante inundaciones y hacer recomendaciones sobre cómo proteger su casa contra inundaciones en el futuro.\n',
    zone: 'rtapp-status-AuditScheduled'
  },
  {
    question: '¿Dará el ingeniero resultados durante la auditoría?\n',
    answer:
      'Su equipo de ingenieros necesitará tiempo para analizar la información obtenida en su vivienda para completar sus recomendaciones personalizadas. Por este motivo, usted no recibirá resultados en el momento. Pero no se preocupe. Su asesor podrá responder a cualesquier preguntas que usted tenga sobre su informe durante la sesión. \n',
    zone: 'rtapp-status-AuditScheduled'
  },
  {
    question: '¿Qué sucede en una sesión de asesoramiento?\n',
    answer:
      'El asesor le dará una explicación personalizada del riesgo de inundación de su propiedad, repasando los datos del certificado de elevación y el informe técnico que han reparado los ingenieros. Al final de su sesión de asesoramiento, usted tendrá un mejor entendimiento de la elegibilidad de su casa para una válvula antirretorno, riesgos específicos de inundación, costo de seguro, y opciones para reducir los daños futuros debido a tormentas.\n',
    zone: 'rtapp-status-AuditComplete'
  },
  {
    question: '¿Qué sucede en una sesión de asesoramiento? \n',
    answer:
      'El asesor le dará una explicación personalizada del riesgo de inundación de su propiedad, repasando los datos del certificado de elevación y el informe técnico que han reparado los ingenieros. Al final de su sesión de asesoramiento, usted tendrá un mejor entendimiento de la elegibilidad de su casa para una válvula antirretorno, riesgos específicos de inundación, costo de seguro, y opciones para reducir los daños futuros debido a tormentas.\n',
    zone: 'rtapp-status-AuditComplete'
  },
  {
    question: '¿Quiénes son los asesores?\n',
    answer:
      'Son asesores de vivienda certificados con más de cinco años de experiencia con comunidades costeras.\n',
    zone: 'rtapp-status-AuditComplete'
  },
  {
    question: '¿Quiénes son los asesores?\n',
    answer:
      'Son asesores de vivienda certificados con más de cinco años de experiencia con comunidades costeras. \n',
    zone: 'rtapp-status-AuditComplete'
  },
  {
    question:
      '¿Cuáles son algunas preguntas comunes que yo podría tener en mente antes del asesoramiento?\n',
    answer:
      'Por favor traiga consigo todas las preguntas que tenga sobre seguro contra inundaciones y resistencia a tormentas. Actualmente, los mapas de clasificación para seguro contra inundaciones en la Ciudad de Nueva York están bajo revisión; los mapas futuros propuestos por el gobierno federal para inundaciones podrán indicar que el doble de las viviendas están en el área de peligro de inundación. Considere preguntarle a su asesor sobre el posible riesgo de inundación de su propiedad en el futuro, y cómo esto podría afectar sus primas de seguro. Ellos también podrán hablar sobre opciones de mitigación para hacer su vivienda más resistente.\n',
    zone: 'rtapp-status-AuditComplete'
  },
  {
    question: '¿Qué sucede en una sesión de asesoramiento?\n',
    answer:
      'El asesor le dará una explicación personalizada del riesgo de inundación de su propiedad, repasando los datos del certificado de elevación y el informe técnico que han reparado los ingenieros. Al final de su sesión de asesoramiento, usted tendrá un mejor entendimiento de la elegibilidad de su casa para una válvula antirretorno, riesgos específicos de inundación, costo de seguro, y opciones para reducir los daños futuros debido a tormentas.\n',
    zone: 'rtapp-status-CounselingScheduled'
  },
  {
    question: '¿Quiénes son los asesores?\n',
    answer:
      'Son asesores de vivienda certificados con más de cinco años de experiencia con comunidades costeras.\n',
    zone: 'rtapp-status-CounselingScheduled'
  },
  {
    question: '¿Quiénes son los asesores?\n',
    answer:
      'Son asesores de vivienda certificados con más de cinco años de experiencia con comunidades costeras. \n',
    zone: 'rtapp-status-CounselingScheduled'
  },
  {
    question:
      '¿Cuáles son algunas preguntas comunes que yo podría tener en mente antes del asesoramiento?\n',
    answer:
      'Por favor traiga consigo todas las preguntas que tenga sobre seguro contra inundaciones y resistencia a tormentas. Actualmente, los mapas de clasificación para seguro contra inundaciones en la Ciudad de Nueva York están bajo revisión; los mapas futuros propuestos por el gobierno federal para inundaciones podrán indicar que el doble de las viviendas están en el área de peligro de inundación. Considere preguntarle a su asesor sobre el posible riesgo de inundación de su propiedad en el futuro, y cómo esto podría afectar sus primas de seguro. Ellos también podrán hablar sobre opciones de mitigación para hacer su vivienda más resistente.\n',
    zone: 'rtapp-status-CounselingScheduled'
  },
  {
    question:
      '¿Cuáles son algunas preguntas comunes que yo podría tener en mente antes del asesoramiento?\n',
    answer:
      'Por favor traiga consigo todas las preguntas que tenga sobre seguro contra inundaciones y resistencia a tormentas. Actualmente, los mapas de clasificación para seguro contra inundaciones en la Ciudad de Nueva York están bajo revisión; los mapas futuros propuestos por el gobierno federal para inundaciones podrán indicar que el doble de las viviendas están en el área de peligro de inundación. Considere preguntarle a su asesor sobre el posible riesgo de inundación de su propiedad en el futuro, y cómo esto podría afectar sus primas de seguro. Ellos también podrán hablar sobre opciones de mitigación para hacer su vivienda más resistente. \n',
    zone: 'rtapp-status-CounselingScheduled'
  },
  {
    question: '¿Qué documentos debo traer?\n',
    answer:
      'Para ayudar a su asesor a estudiar su seguro contra inundaciones y las opciones de mitigación disponibles, le pedimos que traiga su página de declaraciones de seguro contra inundaciones (si corresponde), su certificado de elevación anterior (si corresponde), y un estado de cuenta de hipoteca u otro documento que muestre el estado actual de la hipoteca de su vivienda.\n',
    zone: 'rtapp-status-CounselingScheduled'
  },
  {
    question: '¿Qué hace cada integrante del equipo?\n',
    answer:
      'El plomero instalará su válvula antirretorno, dejará el área de trabajo en las mismas condiciones existentes antes de la construcción, y le enseñará cómo mantener su válvula antirretorno. El inspector realizará una inspección final para cerciorarse de que todo el trabajo se haya hecho correctamente y revisará una lista de verificación de cierre con usted y el plomero.\n',
    zone: 'rtapp-status-MeetYourTeam'
  },
  {
    question: '¿Por qué el Centro firma el acuerdo?\n',
    answer:
      'El Acuerdo Tripartita lo firman el propietario, el Centro y el plomero. El acuerdo enuncia las obligaciones mutuas de las tres partes y celebra un acuerdo directo entre el plomero y el propietario. Usted podrá ver una copia firmada del Acuerdo Tripartita en su página de estado una vez que todas las partes lo hayan firmado. \n',
    zone: 'rtapp-status-MeetYourTeam'
  },
  {
    question:
      '¿Qué es un Acuerdo Tripartita, y hay una versión de muestra que yo pueda estudiar antes de la reunión?\n',
    answer:
      'Se incluirá una versión de muestra del Acuerdo Tripartita en su correo electrónico de confirmación de la fecha y la hora de su reunión inicial, y puede descargarla aquí. \n',
    zone: 'rtapp-status-MeetYourTeam'
  },
  {
    question:
      '¿Tengo que viajar para reunirme con el equipo de construcción?\n',
    answer:
      'Su equipo de construcción vendrá a su casa para la reunión inicial, la cual debe tomar entre una y dos horas.\n',
    zone: 'rtapp-status-MeetYourTeam'
  },
  {
    question:
      '¿Tener una válvula antirretorno reducirá el costo de mi seguro de propiedad?\n',
    answer:
      'Depende. Algunas compañías de seguro ofrecen descuentos en el seguro de propiedad. Sin embargo, a menudo es necesario añadirle a su póliza de seguro de propiedad cobertura específica contra el reflujo de aguas residuales en un endoso para reflujo de aguas residuales. Dicho endoso generalmente cuesta entre $50 y $70 al año, y puede costar hasta $175, dependiendo del deducible y de cuánta cobertura usted necesita. Aún si su seguro de propiedad no ofrece un descuento, usted estará dando un paso importante para prevenir daños futuros debido a reflujo de aguas residuales y las costosas reparaciones que podrían acarrear.\n',
    zone: 'rtapp-status-InstallationPrepare'
  },
  {
    question: '¿Hay algo que yo pueda hacer para acelerar el proceso?\n',
    answer:
      'Desafortunadamente, no. Organizar su equipo de construcción involucra extensa documentación y correspondencia interna que deben completarse para garantizar el cumplimiento de las regulaciones federales y estatales. Cuando su equipo esté listo nos comunicaremos con usted inmediatamente.\n',
    zone: 'rtapp-status-InstallationPrepare'
  },
  {
    question: '¿Cómo debo prepararme para la instalación?\n',
    answer:
      'Además de asegurarse de que el plomero tenga fácil acceso al área del sótano donde se instalará la válvula, también deberá estar preparado para estar en casa y planificar para cuando sea necesario cortar el paso del agua.\n',
    zone: 'rtapp-status-InstallationPrepare'
  },
  {
    question: '¿Cuántos días tendré que dejar de trabajar?\n',
    answer:
      'Tendrá que estar en casa para la reunión inicial y para la instalación. La reunión inicial es cuando el propietario de la vivienda se reunirá con el plomero y el inspector, y deberá durar un máximo de una hora. Una instalación típica debe tomar de uno a dos días, dependiendo de la vivienda. \n',
    zone: 'rtapp-status-InstallationPrepare'
  },
  {
    question: '¿Con quién debo comunicarme si tengo preguntas?\n',
    answer:
      'Puede llamar al Núcleo de Propietarios del Centro al (646) 786-0888 en cualquier momento. Las consultas sobre citas para instalación se remitirán al inspector si ya ha tenido lugar la reunión inicial.\n',
    zone: 'rtapp-status-InstallationToBeScheduled'
  },
  {
    question:
      '¿Se cerrará el paso del agua en mi vivienda? De ser así, ¿por cuánto tiempo?\n',
    answer:
      'Puede llamar al Núcleo de Propietarios del Centro al (646) 786-0888 en cualquier momento. Las consultas sobre citas para instalación se remitirán al inspector si ya ha tenido lugar la reunión inicial.\n',
    zone: 'rtapp-status-InstallationToBeScheduled'
  },
  {
    question: '¿Cuánto tiempo tomará mi instalación?\n',
    answer:
      'El tiempo que tomará la instalación depende de la configuración de su vivienda y su conexión con el alcantarillado de la ciudad, así como de las condiciones del tubo que conecta su casa al alcantarillado de la ciudad. Esperamos que solo se requiera una visita, pero es posible que el plomero tenga que regresar otro día para terminar la limpieza o finalizar una instalación difícil. Usted necesita estar presente en su casa. \n',
    zone: 'rtapp-status-InstallationToBeScheduled'
  },
  {
    question: '¿Con quién debo comunicarme si tengo preguntas?\n',
    answer:
      'Puede llamar al Núcleo de Propietarios del Centro al (646) 786-0888 en cualquier momento. Las consultas sobre citas para instalación se remitirán al inspector si ya ha tenido lugar la reunión inicial.\n',
    zone: 'rtapp-status-InstallationHasBeenScheduled'
  },
  {
    question:
      '¿Se cerrará el paso del agua en mi vivienda? De ser así, ¿por cuánto tiempo?\n',
    answer:
      'Puede llamar al Núcleo de Propietarios del Centro al (646) 786-0888 en cualquier momento. Las consultas sobre citas para instalación se remitirán al inspector si ya ha tenido lugar la reunión inicial.\n',
    zone: 'rtapp-status-InstallationHasBeenScheduled'
  },
  {
    question:
      '¿Se cerrará el paso del agua en mi vivienda? De ser así, ¿por cuánto tiempo?\n',
    answer:
      'Sí, será necesario cerrar el paso del agua a la vivienda durante la instalación de la válvula antirretorno. La duración del cierre dependerá de la vivienda. Le informaremos al propietario tan pronto como nos sea posible. Los propietarios no podrán echar agua por los desagües ni descargar los inodoros mientras que el plomero esté cortando su tubo de alcantarillado e instalando la nueva válvula. Esto no tendrá necesariamente que ser durante todo el tiempo que dure la instalación, y a que la instalación probablemente incluya otros tipos de trabajo, como reparar el piso. Podría tomar un mínimo de ocho horas.',
    zone: 'rtapp-status-InstallationHasBeenScheduled'
  },
  {
    question: '¿Cómo mantengo mi válvula antirretorno?\n',
    answer:
      'El plomero que instale su válvula le mostrará dónde está la válvula, cómo funciona, y cómo mantenerla. Además, usted recibirá un informe de campo cuando haya terminado la instalación, el cual contendrá instrucciones específicas de operación y mantenimiento del fabricante de su válvula. La válvula podría fallar durante una inundación si hay algún objeto que obstruya la chapaleta. Si usted sabe que se avecina una tormenta, realice una inspección visual de la válvula para cerciorarse de que no haya nada obstruyendo la chapaleta. Las instrucciones de operación y mantenimiento del fabricante de la válvula le explicará cómo quitar tales objetos o purgar la válvula.\n\n',
    zone: 'rtapp-status-InstallationComplete'
  },
  {
    question: '¿Qué implica esto?\n',
    answer:
      'Su estudio de viabilidad será similar a la primera evaluación de su vivienda, pero algo más breve. El ingeniero y el personal que lo acompaña tendrán que tomar algunas fotografías y medidas de su vivienda para determinar si ésta se beneficiará con la instalación de una válvula antirretorno.\n',
    zone: 'rtapp-status-FeasibilityReview'
  },
  {
    question: '¿Afectarán los resultados mi solicitud?\n',
    answer:
      'Es posible. Durante el estudio de viabilidad, su ingeniero evaluará si su vivienda se beneficiará con la instalación de una válvula antirretorno y si las condiciones de su vivienda permitirán que se instale dicha válvula. Si determinan que su vivienda no se beneficiará con la instalación de una válvula antirretorno, o que las condiciones de su vivienda no permitirán que se instale esta válvula, usted no será elegible para recibir la instalación de una válvula antirretorno.\n',
    zone: 'rtapp-status-FeasibilityReview'
  },
  {
    question:
      '¿Por qué el ingeniero necesita tener acceso a toda mi propiedad?\n',
    answer:
      'El ingeniero necesita tomar medidas alrededor de sus accesorios de plomería, fotos del área donde se instalaría la válvula, y tiene que observar el espacio alrededor de su vivienda para determinar si es factible instalar una válvula. Esto ayudará al plomero a prepararse para la instalación, ofreciendo una clara perspectiva del área de construcción.\n',
    zone: 'rtapp-status-FeasibilityReview'
  },
  {
    question: '¿Qué sucede si pierdo mi cita? ¿Puedo reprogramarla?\n',
    answer:
      'Si necesita reprogramar su cita, comuníquese con nosotros inmediatamente al (646) 786-0888. Tenga en cuenta que solo podemos reprogramar su cita si nos avisa con un mínimo de tres días de anticipación, y solo podemos reprogramar su visita una vez. \n',
    zone: 'rtapp-status-FeasibilityReview'
  },
  {
    question:
      '¿Exactamente qué es una válvula antirretorno [sewer backwater valve]?\n',
    answer:
      'La mayoría de las viviendas en la Ciudad de Nueva York tienen un tubo que recoge el agua de sus fregaderos e inodoros y la lleva al alcantarillado de la ciudad. Una válvula antirretorno es un dispositivo que se instala en este tubo para reducir el riesgo de que el agua del alcantarillado se regrese por ese tubo y entre a su casa. Tiene un mecanismo interno que se llama “chapaleta” [flapper] que se cierra para evitar que su casa se inunde con agua residual. Cuando el exceso disminuye, el cambio de presión suelta la chapaleta, permitiendo que el agua de desecho fluya de su casa hacia la alcantarilla. Para más información, vea nuestra página de recursos.\n',
    zone: 'rtapp-status-Complete'
  },
  {
    question:
      'Tener una válvula antirretorno, ¿reducirá el costo de mi seguro de propiedad?\n',
    answer:
      'Depende. Algunas compañías de seguro ofrecen descuentos en el seguro de propiedad. Sin embargo, a menudo es necesario añadirle a su póliza de seguro de propiedad cobertura específica contra el reflujo de aguas residuales en un endoso para reflujo de aguas residuales. Dicho endoso generalmente cuesta entre $50 y $70 al año, y puede costar hasta $175, dependiendo del deducible y de cuánta cobertura usted necesita. Aún si su seguro de propiedad no ofrece un descuento, usted estará dando un paso importante para prevenir daños futuros debido a reflujo de aguas residuales y las costosas reparaciones que podrían acarrear.\n',
    zone: 'rtapp-status-Complete'
  },
  {
    question:
      '¿Con quién debo comunicarme para hacer preguntas sobre mi informe hasta el momento? \n',
    answer:
      'Puede llamarnos al (646) 786-0888 o enviarnos un correo electrónico a info@floodhelp.org para cualesquier preguntas.\n',
    zone: 'rtapp-status-Complete'
  },
  {
    question: '¿Qué incluye mi informe técnico?\n',
    answer:
      'Su informe técnico incluye fotografías pre- y post-construcción; un diagrama que muestra la ubicación de la válvula relativa a elementos permanentes en su casa o en su propiedad; hojas de información de producto sobre su válvula del fabricante; instrucciones de operación y mantenimiento del fabricante de la válvula; y copias de permisos obtenidos para la instalación, si corresponde.\n',
    zone: 'rtapp-status-Complete'
  },
  {
    question:
      '¿Quién es responsable del mantenimiento de la válvula antirretorno?\n',
    answer:
      'Usted será responsable del mantenimiento de su válvula antirretorno después del cierre de la gestión. Las instrucciones están en su informe técnico.\n',
    zone: 'rtapp-status-Complete'
  },
  {
    question: ' ¿Por qué necesitas saber si tengo una válvula?',
    answer:
      '\nEstamos tratando de determinar si usted es elegible para la instalación de una válvula antirretorno gratuita en su hogar.',
    zone: 'bwv-flood-applicants'
  },
  {
    question: '¿Qué es la copia de seguridad de fontanería?\n\n',
    answer:
      'Todas las aguas residuales (o aguas residuales) que bajan por sus desagües y baños se llevan desde su casa hacia las alcantarillas de la ciudad. La copia de seguridad de la plomería se produce cuando estas aguas residuales no pueden moverse a través de la (s) tubería (s) y regresan a su casa. Esto puede ocurrir por varias razones.',
    zone: 'bwv-flood-applicants'
  },
  {
    question: '¿Cómo puedo saber si he tenido plomería de respaldo?',
    answer:
      'Es posible que haya notado que el agua y / o los olores desagradables suben por el inodoro, el fregadero, la bañera, el desagüe del piso u otros accesorios de plomería.',
    zone: 'bwv-flood-applicants'
  },
  {
    question: '¿Por qué necesita saber si he tenido plomería de respaldo?\n',
    answer:
      'Le brinda a nuestro ingeniero algunos antecedentes sobre su situación antes de que visiten su hogar para su Auditoría de Resiliencia Doméstica FloodHelpNY.\n',
    zone: 'bwv-flood-applicants'
  },
  {
    question: '¿Qué es una válvula antirretorno?',
    answer:
      'La mayoría de los hogares en la ciudad de Nueva York tienen una tubería que recolecta los desechos de los lavamanos e inodoros de su hogar y los lleva a la alcantarilla de la ciudad. Una válvula antirretorno es un dispositivo que se instala en esta tubería para reducir el riesgo de que las aguas residuales retrocedan a través de esa tubería hasta su casa, especialmente durante un evento de lluvia intensa.\n',
    zone: 'bwv-flood-applicants'
  },
  {
    question: '¿Hay diferentes tipos de válvulas antirretorno?',
    answer:
      'Sí, existen válvulas de compuerta, bola y compuerta. Las válvulas antirretorno tienen una variedad de nombres que incluyen válvula check y válvula retención. Un plomero o ingeniero con licencia puede decirle cuál es el mejor para su hogar, si corresponde.',
    zone: 'bwv-flood-applicants'
  },
  {
    question: '¿Cómo puedo saber si tengo una válvula antirretorno?\n',
    answer:
      'La mejor manera de averiguar si tiene una válvula antirretorno o si necesita una es consultar con un plomero calificado y con licencia. Una válvula antirretorno puede ser difícil de identificar por su cuenta, especialmente si se instaló hace mucho tiempo.\n',
    zone: 'bwv-flood-applicants'
  },
  {
    question: '¿Por qué necesitas saber si tengo una válvula?\n',
    answer:
      'We’re trying to determine whether you’re eligible for the installation of a free sewer backwater valve at your home.',
    zone: 'bwv-flood-applicants'
  },
  {
    question:
      '¿Hay alguna manera de saber si la tapa de mi válvula antirretorno está cerrada o abierta?\n',
    answer:
      'Sabrá si su válvula está cerrada porque el agua dejará de descender por los desagües. Si sospecha que la válvula podría estar cerrada, también puede verificar el uso del punto de acceso a la válvula provisto por el plomero durante la instalación. Algunas válvulas tienen alarmas conectadas a ellas que se activan cuando la válvula se cierra, aunque pueden ser costosas.\n',
    zone: 'bwv-flood-applicants'
  },
  {
    question: '¿Cómo mantengo la válvula antirretorno?',
    answer:
      'Pídale a su plomero que le brinde las instrucciones de operación y mantenimiento del fabricante de su válvula. Por lo general, es bastante simple: abra el punto de acceso a la válvula un par de veces al año para asegurarse de que nada obstruya la válvula. Si algo lo está obstruyendo, quítalo. Si la solapa necesita lubricante, aplícala. Algunos plomeros ofrecen programas de mantenimiento y lo harán por usted. Si recibe una válvula antirretorno a través de nuestro programa, el plomero que instala su válvula le mostrará dónde está la válvula, cómo funciona y cómo mantenerla. Además, cuando finalice la instalación, recibirá un informe de campo que contiene instrucciones específicas de operación y mantenimiento del fabricante de su válvula.\n',
    zone: 'bwv-flood-applicants'
  },
  {
    question: '¿Cuáles son algunas alternativas a las válvulas antirretorno?\n',
    answer:
      'Puede impermeabilizar su sótano y elevar su equipo mecánico para que el sótano solo se use para estacionamiento o almacenamiento. Las aguas residuales todavía pueden retroceder, pero sus pertenencias estarán protegidas. El Departamento de Protección Ambiental de la Ciudad de Nueva York (NYCDEP, por sus siglas en inglés) preparó un folleto de preparación para inundaciones para ayudar a los propietarios a reducir las posibilidades de inundaciones en sus hogares durante un evento de lluvia.\n\nhttp://www.nyc.gov/html/dep/pdf/brochures/flood-preparedness-flyer.pdf\n',
    zone: 'bwv-flood-applicants'
  },
  {
    question:
      '¿Soy elegible para el Programa de Instalación de la Válvula Antirretorno?\n',
    answer:
      'Usted es elegible actualmente si vive dentro de los límites de la comunidad de Lower Manhattan, Rockaway East, Southeast Brooklyn Waterfront, Gravesend y Bensonhurst, Canarsie, Howard Beach, Red Hook, Gerritsen Beach y Sheepshead Bay, y la península sur de Brooklyn como lo define el Gobernador Oficina de Recuperación de Tormentas (GOSR). Hay requisitos de elegibilidad adicionales más allá de esto que se detallan en la solicitud del programa. Después de completar la solicitud, revisaremos su elegibilidad y le haremos saber cuál es nuestra determinación. Incluso entonces, si el plomero o el ingeniero encuentran condiciones existentes que hacen que no sea posible instalar una válvula una vez que ingresen a su hogar, su elegibilidad podría quedar en espera hasta que el propietario del edificio solucione el problema con las condiciones existentes.\n',
    zone: 'bwv-flood-applicants'
  },
  {
    question: '¿Mi ingreso afecta mi elegibilidad?\n',
    answer:
      'El Programa de auditoría de resistencia de hogares solo puede atender a hogares de ingresos bajos a moderados en este momento. Según el Departamento de Vivienda y Desarrollo Urbano (HUD) de EE. UU., Las personas que tienen un ingreso familiar promedio de menos del 50 por ciento del Ingreso Medio del Área (AMI) se consideran Ingresos Bajos. Los individuos que tienen un ingreso familiar promedio de al menos el 50 por ciento y en o menos del 80 por ciento del AMI se consideran ingresos moderados. Si los ingresos de su hogar superan estos parámetros, no será elegible para este programa.\n',
    zone: 'bwv-flood-applicants'
  },
  {
    question:
      '¿Cuánto seguro de inundación se requiere y cuándo se debe comprar?\n',
    answer:
      'Si el solicitante vive en una zona AE, el seguro contra inundaciones se debe comprar antes de que sean aceptados en el programa. La cobertura del seguro contra inundaciones del edificio debe ser igual al costo de la instalación de la válvula antirretorno, en promedio, alrededor de $ 1,500.\n',
    zone: 'bwv-flood-applicants'
  },
  {
    question:
      '¿Cuánto seguro de vivienda se requiere, y cuándo se debe comprar?\n',
    answer:
      'El solicitante debe tener un seguro de propietario antes de ser aceptado en el programa. No especificamos la cantidad de seguro de propietario que deben tener. Solo tienen que tenerlo en general.\n',
    zone: 'bwv-flood-applicants'
  },
  {
    question:
      '¿Cuáles son las razones comunes por las que alguien puede ser técnicamente inelegible para el programa?\n',
    answer:
      'Si las condiciones existentes no permiten la instalación de una válvula antirretorno (es decir, no hay suficiente espacio o la condición del lateral de la alcantarilla es demasiado mala para que el fontanero pueda cortarla sin hacer reparaciones importantes), si la casa no lo hace necesita una válvula antirretorno (no hay indicios de que la casa corra el riesgo de que las aguas residuales se acumulen debido a las elevaciones de la casa, a la conexión de la alcantarilla, al alcantarillado cercano, a la base de inundaciones, etc.)\n',
    zone: 'bwv-flood-applicants'
  },
  {
    question:
      '¿Son elegibles los propietarios de las zonas X actuales que van a las zonas X de asesoramiento?\n',
    answer: 'Sí, serían elegibles.\n',
    zone: 'bwv-flood-applicants'
  },
  {
    question:
      '¿Se requerirá que los propietarios de viviendas en la x actual a la advertencia x, o la x actual a la asesoría AE tengan seguro contra inundaciones?\n',
    answer:
      'Solo los propietarios de viviendas en el AE actual deberán llevar un seguro contra inundaciones para ser elegibles.\n',
    zone: 'bwv-flood-applicants'
  },
  {
    question:
      '¿La propiedad tenía que ser su residencia principal durante Sandy o solo tenían que poseerla? (es decir, quizás era una propiedad de alquiler en ese momento pero se mudaron de nuevo desde entonces)\n',
    answer:
      'La propiedad tenía que ser su residencia principal durante el huracán Sandy.\n',
    zone: 'bwv-flood-applicants'
  },
  {
    question:
      '¿La propiedad tuvo que recibir daños durante el huracán Sandy?\n',
    answer:
      'No, la propiedad no tenía que verse afectada por el huracán para ser elegible.\n',
    zone: 'bwv-flood-applicants'
  },
  {
    question:
      'Ya pasé por el Programa de Auditoría de Resiliencia del Hogar FloodHelpNY. ¿Cómo consigo una válvula antirretorno?\n',
    answer:
      'Se lo considerará de manera retroactiva para el Programa de instalación de válvula antirretorno residencial. Cuando se inicie el programa, el Centro le enviará por correo electrónico la aplicación Backwater Valve. La solicitud deberá completarse y devolverse al Centro para una revisión adicional de la elegibilidad. Su elegibilidad dependerá del resultado de nuestra revisión de su solicitud y de los hallazgos del ingeniero y del plomero en su hogar. Una determinación elegible no significa necesariamente que recibirá una válvula ya que solo se atenderá a hogares de ingresos bajos a moderados en este momento.\n',
    zone: 'bwv-flood-applicants'
  },
  {
    question: '¿Cuánto tiempo tomará este programa?\n',
    answer:
      'Es difícil de estimar, pero el proceso puede tardar hasta seis meses desde el principio hasta el final, según su programación, la complejidad de la instalación y el proceso de contratación. ¡Gracias por su paciencia!\n',
    zone: 'bwv-flood-applicants'
  },
  {
    question:
      '¿Cuándo recibiré una respuesta sobre el estado de mis solicitudes?\n',
    answer:
      'Debería recibir un correo electrónico de confirmación una vez que envíe su formulario de admisión, luego le responderemos sobre los próximos pasos dentro de una semana de haber enviado su solicitud.\n',
    zone: 'bwv-flood-applicants'
  },
  {
    question:
      'Sé que recibí un correo electrónico unos días después de la solicitud, pero no puedo encontrarlo, ¿qué hago ahora?\n',
    answer:
      'You are most likely in the application phase. You can log onto floodhelpny.org and your status page will tell you what phase you are in and what you need to do.',
    zone: 'bwv-flood-applicants'
  },
  {
    question: '¿Cómo presento mis documentos requeridos?\n',
    answer:
      '\nPara enviar sus documentos, usted puede:\nEscanee y cargue a través del enlace en su correo electrónico o en su página de estado a MyDocsOnline.\nEnvíe un fax al Centro de Vecindarios de la Ciudad de Nueva York: 1-646-849-1765\nEnvíe por correo copias al Centro para Vecindarios de la Ciudad de Nueva York\nFloodHelpNY, 55 Broad Street, 10th Floor, Nueva York, NY 10004.',
    zone: 'bwv-flood-applicants'
  },
  {
    question:
      '¿Cuáles son las funciones y responsabilidades del Centro después de la instalación?\n',
    answer:
      'Después de instalar la válvula, es responsabilidad del propietario mantenerla. El Centro proporcionará un Informe de campo que contendrá fotos previas y posteriores a la construcción e información del fabricante de la válvula con respecto a la operación y el mantenimiento. Es probable que el contrato contenga algún grado de garantía en el trabajo. Si hay un problema con la válvula durante el período de garantía, el propietario puede llamar al Centro para discutir el problema y posiblemente hacer que el plomero que instaló la válvula vuelva a la casa para corregir el problema.\n',
    zone: 'bwv-flood-applicants'
  },
  {
    question:
      'En la solicitud, ¿deben los solicitantes poner N / A en el espacio de respuesta cuando la pregunta no se aplica o podría dejarse en blanco?\n',
    answer:
      'N / A es ideal para que podamos tomar una decisión informada sobre si debemos o no llamar a alguien para hacer un seguimiento de lo que sea el campo.\n',
    zone: 'bwv-flood-applicants'
  },
  {
    question: '¿Tengo que estar en casa para la instalación?\n',
    answer:
      'Sí, el propietario debe estar presente durante la revisión del ingeniero (si corresponde), la reunión de inicio y la instalación.\n',
    zone: 'bwv-flood-applicants'
  },
  {
    question: '¿Cuántos días debo salir del trabajo?\n',
    answer:
      'Si ya recibió una Auditoría de Resiliencia Doméstica FloodHelpNY, necesitaremos que esté en su hogar por tres cosas: la revisión de factibilidad del ingeniero, la Reunión de inicio con el plomero y la instalación. Si no ha recibido una auditoría de resiliencia doméstica de FloodHelpNY, necesitaremos que esté en su hogar para la auditoría, la reunión de inicio y la instalación en sí. Esperamos que la auditoría tome un máximo de dos horas. La línea de tiempo para la reunión de inicio y la instalación siguen siendo las mismas para los propietarios que han recibido una auditoría.\n',
    zone: 'bwv-flood-applicants'
  },
  {
    question:
      '¿Cuándo sabrá el propietario la cantidad de tiempo necesario para instalar / no usar el agua?\n',
    answer:
      'Lo sabrán antes de firmar el Acuerdo tripartito, que es el acuerdo firmado por el plomero, el Centro y el propietario antes de instalar la válvula.\n',
    zone: 'bwv-flood-applicants'
  },
  {
    question:
      '¿Cuál es el período de tiempo típico en el que se cerrará el agua?\n',
    answer:
      'Esto dependerá de la casa. Le haremos saber al propietario tan pronto como podamos. Los propietarios no podrán tirar el agua por los desagües ni descargar el inodoro mientras el fontanero está cortando la tubería de alcantarillado e instalando la nueva válvula. No será necesariamente el tiempo completo de la instalación, ya que es probable que la instalación incluya otros trabajos, como parchar el piso. Podría ser tan poco como 8 horas.\n',
    zone: 'bwv-flood-applicants'
  },
  {
    question:
      '¿Qué pasa con los fines de semana / tarde para las instalaciones?\n',
    answer:
      'Esperamos tener al menos algunas citas disponibles los fines de semana y por la noche, pero serán limitadas.\n',
    zone: 'bwv-flood-applicants'
  },
  {
    question:
      '¿Los ingenieros, plomeros o inspectores se pondrán en contacto directamente con el propietario? Y si es así, ¿cuándo?\n',
    answer:
      'El inspector se pondrá en contacto para programar la instalación de la válvula antirretorno. Si un solicitante tiene que reprogramar su instalación, debe comunicarse con su inspector.\n',
    zone: 'bwv-flood-applicants'
  },
  {
    question:
      'Si se necesita más información, ¿quién se comunicará con el propietario?\n',
    answer:
      'El propietario debe sentirse libre de llamar al Centro de Propietarios de Vivienda del Centro si tiene alguna pregunta durante el curso del programa. Los propietarios de viviendas que llamen al Centro de Propietarios para solicitar información sobre su cita de instalación serán llamados a llamar a su Inspector si su Reunión de Inicio ya ha tenido lugar.\n',
    zone: 'bwv-flood-applicants'
  },
  {
    question:
      '¿Volverán las condiciones a la forma en que fueron preinstaladas en la instalación (césped / pisos)?\n',
    answer:
      'En la medida de lo posible, el plomero devolverá el área de trabajo a las condiciones previas a la construcción. Puede que tenga que haber algunos cambios (por ejemplo, tiene que haber una manera de acceder a la válvula para el mantenimiento, por lo que puede haber un puerto de acceso a la válvula. Si se está instalando en el exterior, se debe reemplazar cualquier parte del césped que haya Ser arrancado sería parte del contrato.\n',
    zone: 'bwv-flood-applicants'
  },
  {
    question: '¿Qué constituye una instalación insegura?\n',
    answer:
      'La instalación podría ser considerada insegura por muchas razones. El sistema / espacio podría estar en una condición lo suficientemente mala como para que fuera muy riesgoso para el plomero o para el sistema de plomería, para continuar con la instalación. Esta determinación se puede hacer en cualquier etapa. Es probable que el plomero tenga que hacer su primer corte en el piso para determinar si la instalación sería "segura". Se espera que esto sea una ocurrencia rara.\n',
    zone: 'bwv-flood-applicants'
  },
  {
    question: '¿Cuándo entrarán en vigor los nuevos mapas de inundación?\n',
    answer:
      'Actualmente se está deliberando sobre un nuevo mapa de inundaciones, pero no hay una respuesta definitiva sobre cuándo saldrá o se implementará. FEMA presentó un mapa preliminar actualizado de su línea de base de 1983 en 2013, que expandió significativamente las zonas de inundación de alto riesgo en la Ciudad de Nueva York, pero se encuentra en proceso de revisión después de que la ciudad de Nueva York luchara para implementarlo tal como era. Este mapa ahora actúa como un mapa de asesoría para los requisitos de zonificación y nueva construcción.\n',
    zone: 'bwv-flood-applicants'
  },
  {
    question:
      '¿Dónde puedo averiguar si mi vecindario informó casos de copias de seguridad de aguas residuales?\n',
    answer:
      '311 realiza un seguimiento de las quejas de copia de seguridad de aguas residuales.\n',
    zone: 'bwv-flood-applicants'
  },
  {
    question:
      '¿Qué está haciendo la ciudad para ayudar con el problema general de las aguas residuales?\n',
    answer:
      'Esta es una pregunta más amplia con la que realmente no podemos hablar ya que no trabajamos para la Ciudad. NYCDEP tiene varios proyectos en curso para tratar los problemas de desbordamiento de alcantarillado combinado. Las personas interesadas pueden visitar el sitio web de DEP para obtener más información sobre estos programas. Una iniciativa es el Programa de Infraestructura Verde, que consiste en la construcción de bioswales diseñados en los lados de las carreteras que capturarán la primera pulgada de escorrentía de aguas pluviales de las tormentas y lo mantendrán fuera de las alcantarillas.\n',
    zone: 'bwv-flood-applicants'
  },
  {
    question:
      '¿Cuál es la salida para quejarse de los problemas de alcantarillado?\n',
    answer: 'Llamar al 311',
    zone: 'bwv-flood-applicants'
  },
  {
    question:
      'Como Asociado de Alcance, ¿debo informar a los solicitantes de FHNY que su auditoría puede demorar un tiempo en programar debido a la financiación?\n',
    answer:
      'La demora en las auditorías se debe a que nosotros elaboramos contratos con nuestros proveedores y finalizamos el papeleo con nuestra fuente de financiamiento. Esperemos que podamos comenzar las auditorías nuevamente a principios del nuevo año, pero no podemos decirlo con seguridad. Definitivamente, deberíamos ser francos con las personas y hacerles saber que, si bien estamos aceptando solicitudes de una población piloto y permitiéndoles que envíen sus formularios de admisión, podría pasar un tiempo antes de que se pueda programar su auditoría. Procesaremos las solicitudes en el orden en que se reciban.\n',
    zone: 'bwv-flood-applicants'
  },
  {
    question:
      '¿Cuánto me costará el Programa de Instalación de Válvula Antirretorno?\n',
    answer:
      'El Programa de Instalación de Válvula Antirretorno es totalmente gratuito.\n',
    zone: 'bwv-flood-applicants'
  },
  {
    question:
      '¿Por qué tengo que enviar información de ingresos si este es un programa gratuito?\n',
    answer:
      'Nuestros financiadores requieren que solo prestemos servicios a hogares de ingresos bajos a moderados en este momento. Esta información se utilizará para determinar si usted califica.\n',
    zone: 'bwv-flood-applicants'
  },
  {
    question: '¿Compartes esta información con el Estado?\n',
    answer:
      'Sí, también compartimos esta información con el gobierno federal. Este programa es financiado por una Subvención en Bloque para el Desarrollo Comunitario – Recuperación de Desastres (CDBG-DR) del Departamento de Vivienda y Desarrollo Urbano de los Estados Unidos (HUD) y se implementa a través de la Oficina del Gobernador para la Recuperación de Tormentas (GOSR), que es una agencia estatal.\n',
    zone: 'bwv-flood-applicants'
  },
  {
    question:
      '¿Reducir las primas de mi seguro contra inundaciones al obtener una válvula antirretorno?\n',
    answer:
      'Sewer backwater valves have no effect on flood insurance premiums but they can save you hundreds, or even thousands of dollars in damage in the event of a flood.  It can also protect precious items and mementos stored in your basement from damage and keep your basement sewage free.',
    zone: 'bwv-flood-applicants'
  },
  {
    question:
      'Why doesn’t installing a sewer backwater valve change my insurance rate?',
    answer:
      'El Programa Nacional de Seguro contra Inundaciones no está redactado actualmente para permitir reducciones de primas para estrategias de mitigación a prueba de inundaciones secas, como las válvulas antirretorno. En contraste, las soluciones a prueba de inundaciones húmedas, como elevar su casa, dan como resultado un descuento en el seguro contra inundaciones debido a que su piso más bajo se eleva por encima de la Elevación de Inundación Base (BFE). Una válvula antirretorno puede reducir el riesgo de inundación, pero no elimina el riesgo por completo.\n',
    zone: 'bwv-flood-applicants'
  },
  {
    question:
      '¿Qué sucede con los solicitantes / propietarios de viviendas que actualmente están en el programa y viven en Canarsie o Red Hook (Verificación, Solicitud completa, Auditoría de horarios / Asesoramiento, etc.)?\n',
    answer:
      'Los solicitantes que han completado todo el programa RTAPP, ahora son elegibles para obtener una válvula antirretorno. Aquellos en cualquier otra etapa del programa serán puestos en una lista de espera. En caso de que haya fondos adicionales disponibles para Canarsie o Red Hook, los solicitantes en esta lista de espera serán considerados primero.\n',
    zone: 'bwv-flood-applicants'
  },
  {
    question:
      '\nSi un solicitante de Canarsie o Red Hook recibió un certificado de elevación y asistió al asesoramiento, ¿será considerado para el programa Backwater Valve?',
    answer:
      '\nSí, los únicos residentes de Canarsie y Red Hook que serán considerados para el Programa de Válvulas Antirretorno son aquellos que recibieron una auditoría y asistieron a asesoramiento.\n',
    zone: 'bwv-flood-applicants'
  },
  {
    question:
      '\n¿Todas las etapas (Auditoría, Asesoramiento, etc.) tomarán más tiempo para los solicitantes en Canarsie o Red Hook que ya están en el programa?',
    answer:
      'Los solicitantes en Canarsie y Red Hook que no participaron en el programa de Auditoría de Resiliencia en el Hogar no son elegibles para participar en este momento. Aquellos que participaron en el programa anteriormente se moverán a través de la parte de la válvula antirretorno del programa al mismo ritmo que todos los demás participantes.\n',
    zone: 'bwv-flood-applicants'
  },
  {
    question:
      '\n¿Cómo se financian los solicitantes existentes pero no los solicitantes nuevos?',
    answer:
      'Esto es solo en Canarsie y Red Hook. Canarsie fue una de las comunidades más participativas en el programa inicial de auditoría de resiliencia del hogar antes de que se agregaran las instalaciones de válvula antirretorno. La financiación para las auditorías en Canarsie se agotó, pero desde entonces hemos recibido fondos adicionales para instalar válvulas antirretorno allí. Como nadie puede instalar una válvula antirretorno sin una auditoría, las personas en Canarsie que aún no se hayan sometido a una auditoría y hayan consultado antes de que se agoten los fondos no serán elegibles para obtener una auditoría o una válvula de retorno. Es posible que las asignaciones de fondos puedan cambiar en el futuro, y nos aseguraremos de establecer la base si eso sucede.\n',
    zone: 'bwv-flood-applicants'
  },
  {
    question: '\n¿Qué es este programa y de qué se trata?',
    answer:
      'Si está recibiendo este formulario, su propietario se ha inscrito en el programa de Auditoría de Resiliencia de Hogares de FloodHelpNY, destinado a fortalecer las comunidades costeras de Nueva York mediante la prestación de servicios gratuitos a propietarios de viviendas elegibles y sus inquilinos en ciertos vecindarios costeros. Para obtener más información, visite FloodHelpNY.org. Como parte de este programa, su arrendador puede ser elegible para recibir una válvula antirretorno gratuita. Una válvula antirretorno es un dispositivo que se instala en la tubería que conecta los accesorios de plomería de una casa a la red de alcantarillado de la ciudad para reducir el riesgo de que las aguas residuales ingresen a la casa durante un evento de inundación, como lluvia intensa o marejada ciclónica.\n',
    zone: 'bwv-flood-applicants'
  },
  {
    question: '¿Por qué necesito llenar este formulario?',
    answer:
      'FloodHelpNY se esfuerza por servir a los propietarios de viviendas de ingresos moderados y bajos y sus inquilinos. Estamos obligados a obtener información sobre los ingresos de los hogares, incluida la información sobre los ingresos de los inquilinos en edificios de 2 a 4 unidades, para que podamos asegurarnos de que solo prestamos servicios a los hogares de menores ingresos. Esto es requerido por el Departamento de Vivienda y Desarrollo Urbano de los Estados Unidos y la Oficina de Recuperación de Tormentas (GOSR) del Gobernador del Estado de Nueva York, que financia el programa.\n',
    zone: 'bwv-flood-applicants'
  },
  {
    question: '\n¿Me beneficia este programa?',
    answer:
      '\n¡Sí! Los servicios de FloodHelpNY están diseñados para ayudar a que las viviendas sean menos vulnerables a las inundaciones y para reducir el potencial de daños a las viviendas, incluidos los daños en las unidades de alquiler, en el caso de una copia de seguridad de la plomería de la inundación o alcantarillado.',
    zone: 'bwv-flood-applicants'
  },
  {
    question: '\n¿Cómo se protegerá mi información?',
    answer:
      '\nLa confidencialidad y seguridad de su información es fundamental para nosotros. Utilizamos medidas de seguridad físicas, digitales y administrativas para mantener su información personal segura y confidencial. No proporcionaremos ninguna información a terceros que no estén involucrados en la administración de FloodHelpNY sin su permiso o según lo exija la ley. También cumplimos con los estrictos requisitos de privacidad establecidos tanto por el estado como por el gobierno federal.',
    zone: 'bwv-flood-applicants'
  },
  {
    question: '\n¿Quién dirige FloodHelpNY?',
    answer:
      'FloodHelpNY es posible gracias a los fondos de la Oficina de Recuperación de Tormentas del Gobernador del Estado de Nueva York a través de una Subvención Global de Desarrollo Comunitario para la Recuperación de Desastres del Departamento de Vivienda y Desarrollo Urbano de los Estados Unidos. El programa es administrado por el Center for NYC Neighborhoods, una organización sin fines de lucro comprometida con la promoción y protección de la compra de viviendas asequibles en Nueva York para que las familias de clase media y trabajadora puedan construir comunidades fuertes y prósperas. Obtenga más información sobre el Centro en cnycn.org.\n',
    zone: 'bwv-flood-applicants'
  },
  {
    question: '\n¿Tendré que proporcionar más información?',
    answer:
      '\nNecesitaremos documentos de respaldo para demostrar el ingreso anual de todas las personas en su hogar mayores de 18 años. Consulte el formulario en la página siguiente para ver los tipos de documentos que podemos aceptar.',
    zone: 'bwv-flood-applicants'
  },
  {
    question: '¿Qué hago con este formulario cuando está completo?',
    answer:
      'Por favor, envíelo por correo al Centro dentro de los 14 días posteriores a la recepción a:\n\nCentro para los barrios de Nueva York, a la atención. Ayuda de inundación\n55 Broad Street\npiso 10\nNueva York, NY 10004\n\nTambién puede escanear y enviar por correo electrónico el formulario y los documentos de respaldo a info@floodhelpny.org. \n',
    zone: 'bwv-flood-applicants'
  },
  {
    question:
      '¿Soy elegible para el programa de instalación de válvula antirretorno residencial?',
    answer:
      'Actualmente es elegible si vive dentro de los límites de la comunidad del Bajo Manhattan, Rockaway East, Southeast Brooklyn Waterfront, Gravesend y Bensonhurst, Howard Beach, Gerritsen Beach y Sheepshead Bay, y el sur de la península de Brooklyn, según lo define la Oficina de Recuperación de Tormentas del Gobernador ( GOSR). Después de completar la solicitud, revisaremos su elegibilidad y le informaremos cuál es nuestra determinación. Incluso entonces, si el plomero o ingeniero encuentra condiciones existentes que hacen que no sea factible instalar una válvula una vez que ingresan a su hogar, su elegibilidad podría quedar en espera hasta que el propietario solucione el problema con las condiciones existentes.\n',
    zone: 'bwv-flood-applicants'
  },
  {
    question:
      '¿Son elegibles los propietarios de viviendas en las zonas X actuales que van a las zonas X de asesoramiento?',
    answer: 'Sí, son elegibles.\n',
    zone: 'bwv-flood-applicants'
  },
  {
    question: '¿Cómo presento mis documentos requeridos?',
    answer:
      'Para enviar sus documentos, puede:\nEscanee y cargue a través de su página de estado en FloodHelpNY.org.\nEnvíe un fax al Centro para vecindarios de Nueva York: 1-646-849-1765\nEnvíe copias al Centro de Atención a Vecindarios de Nueva York.\nFloodHelpNY, 55 Broad Street, 10th Floor, Nueva York, NY 10004.',
    zone: 'bwv-flood-applicants'
  },
  {
    question: '¿Tengo que estar en casa para la instalación?',
    answer:
      'Sí, el propietario debe estar presente durante la visita del ingeniero, la reunión inicial y la instalación.\n',
    zone: 'bwv-flood-applicants'
  },
  {
    question:
      '¿Por qué debo enviar información sobre ingresos si este es un programa gratuito?',
    answer:
      'Nuestras pautas de financiación requieren que confirmemos los ingresos del hogar; Esta información se utiliza para determinar su elegibilidad. En este momento, solo serán elegibles los hogares de ingresos bajos a moderados (por debajo del 80% del AMI).\n',
    zone: 'bwv-flood-applicants'
  },
  {
    question: '¿Mi póliza de seguro comercial cubrirá las inundaciones?',
    answer:
      'Las inundaciones generalmente no están cubiertas por una póliza de seguro comercial o una póliza de propietario de negocio. Debería comprar una póliza de seguro contra inundaciones por separado para proteger su negocio de los costos financieros de los daños por inundaciones.',
    zone: 'small-business'
  },
  {
    question: '¿Mi empresa necesita un seguro contra inundaciones?',
    answer:
      'Los daños por inundaciones pueden ocurrir en cualquier momento, incluso si su negocio no está ubicado en un área de inundación de alto riesgo. Ya sea que alquile o sea propietario de su espacio comercial, le recomendamos que compre una póliza de seguro contra inundaciones a través del <a href="https://www.fema.gov/flood-insurance" target="_blank" rel="noopener noreferrer">NFIP</a>.',
    zone: 'small-business'
  },
  {
    question:
      '¿Por qué debería comprar un seguro contra inundaciones cuando FEMA ofrece asistencia por desastre?',
    answer:
      'FEMA no ofrece subvenciones individuales a empresas. Sin un seguro contra inundaciones, tendría que pagar cualquier daño de su propio bolsillo o se vería obligado a solicitar un préstamo.',
    zone: 'small-business'
  },
  {
    question: '¿Qué cubre el seguro contra inundaciones?',
    answer:
      'El seguro contra inundaciones ayuda a proteger su negocio de los costos financieros de los daños por inundaciones. El seguro contra inundaciones puede cubrir los costos de los daños a su edificio y el contenido causado por las inundaciones.',
    zone: 'small-business'
  },
  {
    question: '¿Qué debo saber sobre los deducibles?',
    answer:
      'Es importante elegir el deducible correcto. Un deducible más alto reducirá el costo de su seguro, pero también reducirá la cantidad de dinero que recibe si alguna vez presenta un reclamo.',
    zone: 'small-business'
  },
  {
    question:
      '¿Cuánto tiempo se tarda en obtener cobertura contra inundaciones?',
    answer:
      'Hay un período de espera de 30 días antes de que su póliza de seguro contra inundaciones entre en vigencia. Es importante comprar una póliza de seguro contra inundaciones antes de que una inundación afecte su negocio.',
    zone: 'small-business'
  },
  {
    question: '¿Cuánta cobertura contra inundaciones puedo obtener?',
    answer:
      'Una póliza comercial de seguro contra inundaciones a través del <a href="https://www.fema.gov/flood-insurance" target="_blank" rel="noopener noreferrer">NFIP</a> proporciona hasta $500,000 en cobertura de edificios y hasta $500,000 para cobertura de contenido.',
    zone: 'small-business'
  },
  {
    question: '¿Puedo obtener más cobertura que la que ofrece el NFIP?',
    answer:
      'Sí, las compañías de seguros privadas ofrecen un exceso de cobertura de seguro por encima de los límites del <a href="https://www.fema.gov/flood-insurance" target="_blank" rel="noopener noreferrer">NFIP</a>. Llame a su agente o corredor de seguros para analizar las opciones disponibles para ayudar a proteger su negocio de los costos financieros de los daños por inundaciones.',
    zone: 'small-business'
  },
  {
    question: '¿Dónde puedo comprar un seguro contra inundaciones?',
    answer:
      'Puede comprar un seguro contra inundaciones llamando a su agente o corredor de seguros. Podrán ayudarlo a comprar una póliza de seguro contra inundaciones directamente a través del <a href="https://www.fema.gov/flood-insurance" target="_blank" rel="noopener noreferrer">NFIP</a>.',
    zone: 'small-business'
  },
  {
    question: '¿Cuanto me reembolsaran si se daña mi hogar?',
    answer:
      'Si es dueño de una vivienda unifamiliar, es importante que sepa que si usted asegura su casa por menos del 80% del valor del costo de reposición (RCV), entonces solo recibirá lo que se llama "valor efectivo real" (ACV) por el daño causado por las inundaciones cuando haga una reclamación. Esto significa que en lugar de recibir un seguro por el costo de reparación de su casa, solo recibirá reembolsos por el valor depreciado de cualquier parte de la estructura que fue dañada: vigas, láminas de yeso, pisos, etc.',
    zone: 'understanding-flood-insurance'
  },
  {
    question:
      '¿Cual es la nueva metodología de cotización bajo el Risk Rating 2.0?',
    answer:
      'FEMA ha desarrollado un sistema que puede ser difícil de entender. Aquí esta lo que sabemos: utilizando data que proviene de FEMA junto con data comercialmente disponible proveniente de terceros, FEMA evaluará el riesgo de inundación de cada propiedad. FEMA hará esto analizando diferentes factores como la distancia de una fuente de inundaciones, tipos de inundaciones, uso de suelo, tipo de construcción, tipo de fundación, elevación del suelo, altura del primer piso, número de pisos, y reclamos previos de seguro contra inundaciones. Otro factor importante incluye el costo de reconstrucción.',
    zone: 'insurance-shopping'
  },
  {
    question: '¿El Risk Rating 2.0 toma en cuenta reclamos previos?',
    answer:
      'Si, el historial de reclamos de seguros de una propiedad impactará las primas de seguro contra inundaciones. Sin embargo, es importante saber que FEMA no considerará el historial de reclamos en la cotización inicial. Reclamos anteriores solo serán considerados, siempre y cuando una propiedad presente un nuevo reclamo después de que el Risk Rating 2.0 entre en vigor. Cuando esto suceda, FEMA considerará todos los reclamos que datan desde hace 20 años y actualizara la tarifa en la renovación anual de la póliza.',
    zone: 'insurance-shopping'
  }
];
