export default {
  meta: {
    title: 'Backwater Valve Installation Program',
    url: 'https://www.floodhelpny.org/en/mitigation/backwater-valve',
    description: 'Protect your home and finances from floods.'
  },
  title: 'Backwater valves: What you need to know',
  subtitle:
    'A backwater valve is designed to keep raw sewage out of your home in the event of sewer overflow.',
  overview: {
    header: 'Overview',
    copy:
      'New York City’s combined sewer system overflows, on average, once per week. When stormwater runoff joins with sewage and overloads the sewer’s capacity, the excess wastewater can flow backward through drainpipes and into people’s homes. Plumbing backups are unsanitary and can cause serious health hazards, and they’re costly to clean up.'
  },
  howItWorksHeader: 'How the valve works',
  howItWorks: {
    paragraph1:
      'A backwater valve connects to your home’s plumbing and features an internal mechanism called the “flapper.” In the event of sewer overflow, the flapper closes to help protect your home from filling with raw sewage.',
    paragraph2:
      'When the overflow subsides, the change in pressure releases the flapper, allowing wastewater to flow from your home and into the sewer.'
  },
  backwaterValveGifAlt: 'Backwater valve animation',
  affect: {
    header: 'How it affects you',
    moneySavedSubheader: 'Estimated Money Saved',
    bigNumber: '$2,000 - $10,000',
    moneySavedCopy:
      'Installing a check valve will not affect what you pay for flood insurance. However, in the event of sewer overflow, having the valve in place can save you thousands in sewage cleanup and restoration costs.',
    damageAvoidedHeader: 'Damage Avoided',
    damageAvoidedCopy:
      'Having a backwater valve reduces the likelihood of plumbing backups, so anything you store in your basement will stay safe, dry, and sewage-free.'
  },
  whatItTakes: {
    header: 'What it takes',
    paragraph1:
      'Installing a backwater valve can take 2–8 hours, depending on how accessible your home’s drainpipes are.',
    paragraph2:
      'The plumber will also give you a few maintenance tips, such as how to flush the valve with water to make sure it doesn’t get blocked.',
    cost: '$600–$5,000',
    costCopy:
      'Installation cost depends on the location of your drainpipes as well as which type of backwater valve is right for your home.'
  },
  whatYouCanDoNext: {
    header: 'What you can do next',
    subheading: 'Call a licensed plumber',
    copy:
      'If you’re ready to have a backwater valve installed in your home, first check if your homeowner’s policy covers plumbing backup. Next, call a licensed master plumber, arrange an inspection of your home’s drainpipe, and ask a few key questions (see below). You can also verify license information on the <a href="http://www.nyc.gov/dob" target="_blank" rel="noreferrer noopener">NYC Department of Buildings website</a>.',
    question:
      'Which questions should I ask when looking for a plumber to install this valve?',
    answer1:
      'Ask for their license, experience in NYC or your neighborhood, any certifications, and references from similar projects.',
    answer2: 'Try to get quotes from three plumbers for comparison.'
  }
};
