export default {
  meta: {
    title: 'Pwogram Enstalasyon Valv pou Dlo k Ap Tounen',
    url: 'https://www.floodhelpny.org/ht_HT/backwater-valve-program',
    description: 'Pwoteje kay ak finans ou kont inondasyon.',
    imageAlt: 'travayè konstriksyon ap travay'
  },
  title: 'Pwogram Enstalasyon Valv pou Dlo k Ap Tounen',
  subTitle:
    'Anpeche domaj ki chè lakay ou apati refoulman egou ki ka poze risk pou sante.',
  description:
    'Pwopriyetè kay nan zòn ki gen gwo risk inondasyon yo bezwen yon sètifika elevasyon pou jwenn yon pwofòma asirans kont inondasyon ki presi. Si ou kalifye pou yon Odit pou Tolerans Kay gratis, nou ap fè yon enjenyè vizite kay ou epi prepare youn pou ou.',
  programClosed:
    'Pwogram Enstalasyon Valv k Ap Pote Dlo Tounen an pa p aksepte aplikasyon ankò. Pou plis enfòmasyon sou valv k ap pote dlo tounen e rezon ou gendwa bezwen youn, ',
  visitResourcePage: 'vizite paj resous nou an.'
};
