import waterDroplet from '../images/water-droplet.svg';
import umbrella from '../images/umbrella.svg';
import piggyBank from '../images/piggy-bank.svg';
import moneyHouse from '../images/money-house.svg';
import couchInHouse from '../images/couch-in-house.svg';
import policyAlert from '../images/policy-alert.svg';
import checklist from '../images/checklist.svg';
import premiumChanges from '../images/premium-changes.svg';

export default {
  meta: {
    title: 'Understanding Flood Insurance | FloodHelpNY.org',
    url: 'https://www.floodhelpny.org/en/understanding-flood-insurance',
    description: `Flood insurance is complicated. We're here to help you understand it.`,
    ogDescription:
      'Trying to figure out flood insurance on your own can be frustrating. But we’re here to help.',
    imageAlt: 'waves'
  },
  title: 'Understanding Flood Insurance',
  subHeading:
    'Trying to figure out flood insurance on your own can be frustrating. But we’re here to help.',
  inANutshell: {
    header: 'In A Nutshell',
    items: [
      {
        header: 'Standard insurance is not the same as flood insurance',
        body:
          'Homeowner, renter and business insurance does not cover flooding.',
        image: waterDroplet,
        imageAlt: 'water droplet'
      },
      {
        header: 'You may be required to have flood insurance',
        body: 'Flood Insurance is required for some homeowners.',
        image: umbrella,
        imageAlt: 'umbrella'
      },
      {
        header: 'Save money with flood insurance',
        body:
          'It may be the single most important tool for keeping your finances above water in the event of a flood.',
        image: piggyBank,
        imageAlt: 'piggy bank'
      }
    ]
  },
  whatIsIt: {
    header: 'What is it?',
    body: {
      p1:
        'The National Flood Insurance Program is a federally-backed program that began in 1968. At the time, private companies were unwilling to take on the risk of damage caused by floods, so Congress created the NFIP to provide building owners with affordable flood insurance. New York City opted into the program in 1983.',
      p2:
        'The NFIP is administered by the Federal Emergency Management Agency and by private insurance companies. While these companies sell flood insurance, all rates are standardized by FEMA. This means you don’t need to shop around for insurance, but you should check the accuracy of your rate – after all, brokers make mistakes.'
    },
    aside: {
      header: 'NFIP',
      body:
        'The NFIP enables property owners in participating communities to purchase insurance protection against losses from flooding, and requires flood insurance for all mortgage loans or lines of credit that are secured by buildings in high-risk flood zones.'
    }
  },
  floodInsuranceCover: {
    header: 'What Does Flood Insurance Cover?',
    subheader:
      'There are two kinds of coverage: building and contents. You have the option to select separate coverage amounts and deductibles for each.',
    items: [
      {
        header: 'Building coverage',
        body:
          'Insures the structure only, not the contents or the landscaping. It does not cover finishing in the basement.',
        image: moneyHouse,
        imageAlt: 'house with dollar sign'
      },
      {
        header: 'Contents coverage',
        body:
          'Insures your belongings (but most items in your basement or below your lowest living floor are excluded).',
        image: couchInHouse,
        imageAlt: 'house with couch'
      }
    ],
    aside: {
      header: 'Counter intuitive exceptions',
      body:
        'There are some counterintuitive exceptions, though: refrigerators, stoves, and dishwashers are covered under building flood insurance, while washers and dryers are considered contents.'
    }
  },
  shopping: {
    icon: checklist,
    iconAlt: 'checklist',
    header: 'Learn how to get the best policy to protect your home.',
    buttonText: 'Read now'
  },
  myOptions: {
    header: 'What Are My Options For Coverage?',
    body:
      'The maximum amount of insurance you can buy for your structure is $250,000, and the maximum amount of insurance you can buy for your contents is $100,000.',
    aside: {
      header: 'Small business owner?',
      link: 'Learn more',
      body:
        ' about how flood risk can affect your operations — and what you can do about it.'
    }
  },
  floodInsurancePriced: {
    header: 'How Flood Insurance Is Priced',
    body:
      'On Oct. 1, 2021, FEMA introduced new flood insurance pricing, known as Risk Rating 2.0 to more accurately reflect flood risk. Under the new system, FEMA will no longer rely on the flood maps for flood insurance prices, but will use several variables to calculate your premium, such as distance to flooding source and construction types. For a full list, check out our FAQs.'
  },
  premiums: {
    icon: premiumChanges,
    iconAlt: 'Risk Rating 2.0 will result in premium changes',
    body:
      'Risk Rating 2.0 will result in premium changes for many people nationwide. Some policies may experience premium decreases; some may experience premium increases. For those policies that are increasing under Risk Rating 2.0, FEMA is required by federal law to not increase a policy by more than 18% per year. In New York City FEMA estimates about 61% of NYC residents will experience a premium increase under Risk Rating 2.0.'
  },
  understandingMaps: {
    header: 'Understanding Flood Insurance Maps',
    sub_heading:
      'It’s important to remember that while the flood maps will no longer be integral for pricing, they are still used to determine if you are required to carry flood insurance.',
    severeRiskZone: 'Highest risk',
    highRiskZone: 'High risk',
    moderateRiskZone: 'Moderate risk'
  },
  aboutTheZones: {
    header: 'About the zones',
    body: {
      p1:
        'In the high risk flood zones (AE, AO and VE), property owners are required to buy flood insurance if they have a federally backed mortgage. Also, property owners who have received federal disaster assistance for flood damage are required to buy flood insurance in order to be eligible for future assistance.'
    },
    aside: { header: 'Discover your risk', link: 'Find Your Zone' }
  },
  subsidizedRateFullRate: {
    header: 'Subsidized Rate vs. Full Risk Rate',
    body:
      'In high risk flood zones, there are two different types of insurance rates available: the pre-FIRM subsidized rate, and the standard rate. The pre-FIRM subsidized rate is only available for building owners whose properties were built before November 16, 1983 (“pre-FIRM” because the home was built before the FIRM was adopted in 1983). This subsidized rate may be lower than the standard rate; however, because the subsidies are decreasing, there are many properties where the standard rate is actually less expensive.',
    aside: {
      header: 'Annual Insurance Increases',
      body:
        'Due to two recent pieces of federal legislation, the Biggert-Waters Act of 2012 and the Housing Insurance Affordability Act of 2014, subsidized rates are increasing by as much as 15-18% per year.'
    }
  },
  newHomeowners: {
    header: 'New Homeowners',
    body:
      'When you buy a new home, check with the seller about whether they have a flood insurance policy. Policyholders are able to transfer their policies (and any discounts) to a new owner after a home has been sold.',
    aside: {
      header: 'Letter of Map Amendment',
      body:
        'If your home is in a high risk flood zone but the elevation of the ground immediately around it is above the BFE, you can submit to FEMA a Letter of Map Amendment, or LOMA, which is a request to exclude your property from the flood zone. You need an elevation certificate as proof.'
    }
  },
  whyMapsChanging: {
    header: 'NYC’S MAPS ARE CHANGING',
    body:
      'FEMA periodically updates the maps that it creates for flood hazard zones across the country. The current maps for New York City were adopted in 1983 and do not accurately reflect today’s risk of flooding. At this point, it is unclear when the new Flood Insurance Rate Maps will go into effect or what form the maps will ultimately take. Our best estimate is that it will happen in the next two to five years.',
    aside: {
      header: 'NYC Challenge',
      body_before:
        'New York City appealed FEMA’s Preliminary Flood Insurance Rate Map in June 2015, arguing that they overestimated flood risk in the five boroughs. In October 2016, FEMA announced that it has accepted the City’s ',
      link: 'appeal',
      body_after: ' and will revise the maps based on new data analyses.'
    }
  },
  learnMore: {
    body: 'Want to learn more? Check out our article ',
    link: 'Rising Tides Rising Rates',
    href: 'https://cnycn.atavist.com/risingtides'
  }
};
