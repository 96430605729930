import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { usePagination, useWindowWidth, useProvider } from '../helpers/hooks';
import { DOTS } from '../helpers/PaginationHelper';
import Button from './ui/Button';
import { colors } from '../styles/variables';

const PaginationWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
`;

const ListItem = styled(Button)`
  margin: 1rem;
  padding: 0 1.5rem;
  background-color: ${(props) => props.selected && colors.grayScale[1]};

  @media (max-width: 900px) {
    margin: 0.5rem;
  }
`;

const PageNavButton = styled(Button)`
  margin-right: ${(props) => props.previous && '5rem'};
  margin-left: ${(props) => props.next && '5rem'};
  padding: 0px 50px;

  @media (max-width: 1100px) {
    margin-right: ${(props) => props.previous && '1rem'};
    margin-left: ${(props) => props.next && '1rem'};
    padding: 0 1.5rem;
  }
`;

const Pagination = ({ onPageChange, currentPage, totalPageCount }) => {
  const provider = useProvider('pagination');

  let paginationRange = usePagination({
    totalPageCount,
    currentPage
  });
  const width = useWindowWidth();

  if (currentPage === 0 || paginationRange.length < 1) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  const lastPage = paginationRange[paginationRange.length - 1];

  if (totalPageCount >= 10 && width <= 780) {
    paginationRange = [currentPage];
  } else if (width <= 600) {
    paginationRange = [currentPage];
  }

  return (
    <PaginationWrapper>
      <PageNavButton
        disabled={currentPage === 1}
        previous
        onClick={onPrevious}
        bgColor={colors.grayScale[4]}
        ghost
      >
        {provider.previous}
      </PageNavButton>

      {paginationRange.map((pageNumber, idx) => {
        if (pageNumber === DOTS) {
          return (
            <ListItem key={idx} bgColor={colors.grayScale[4]} ghost>
              ...
            </ListItem>
          );
        }

        return (
          <ListItem
            key={idx}
            selected={pageNumber === currentPage}
            onClick={() => onPageChange(pageNumber)}
            bgColor={colors.grayScale[4]}
            ghost
          >
            {pageNumber}
          </ListItem>
        );
      })}

      <PageNavButton
        next
        disabled={currentPage === lastPage}
        onClick={onNext}
        bgColor={colors.grayScale[4]}
        ghost
      >
        {provider.next}
      </PageNavButton>
    </PaginationWrapper>
  );
};

Pagination.propTypes = {
  onPageChange: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  totalPageCount: PropTypes.number.isRequired
};

export default Pagination;
