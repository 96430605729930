import backwaterValve from '../images/backwater-valve.svg';
import cookingOil from '../images/cooking-oil.svg';
import garbage from '../images/garbage.svg';
import roofing from '../images/roofing.svg';
import basement from '../images/basement.svg';
import unpaved from '../images/unpaved.svg';
import rainEventGuide from '../images/rain-event-guide.jpg';
import stormwaterFlooding from '../images/stormwater-flooding.svg';
import floodInsurance from '../images/flood-insurance.svg';

export default {
  meta: {
    title: 'Stormwater Flooding | FloodHelpNY.org',
    ogTitle: 'Stormwater Flooding in NYC | FloodHelpNY.org',
    url: 'https://www.floodhelpny.org/en/stormwater-flooding',
    description:
      'Stormwater flooding can affect inland communities miles from the shoreline, posing risks to property and health.'
  },
  header: 'Stormwater Flooding',
  subHeader:
    'Stormwater flooding can affect inland communities miles from the shoreline, posing risks to property and health.',
  whatIsStormwater: {
    header: 'WHAT IS STORMWATER?',
    body:
      'Stormwater is rainwater or melted snow that runs off lawns, streets, and other land surfaces. Ideally, this runoff would be absorbed by plants and soil or make its way down the sewer. But, in New York City, the sidewalks and streets don’t absorb rainwater. Instead, the rainwater flows into the city’s catch basins, where it’s channeled into the sewer system. Unfortunately, the city’s catch basins only have so much capacity. Surplus rainwater inevitably flows out into streets and causes local flooding. Sewage can also back up into homes.'
  },
  pollution: {
    header: 'STORMWATER CAN ADD TO POLLUTION',
    body: `In addition to the risk of property flooding, there is also a risk of pollution: The excess runoff that doesn't soak into the ground can pick up pollutants such as oil, pesticides, bacteria, and trash before flowing into storm drains, drainage ditches, and the ocean.`
  },
  howRainFallImpacts: {
    header:
      'How rainfall-based flooding may impact your street, home, or business',
    icon: stormwaterFlooding,
    iconAlt: 'Stormwater Flooding',
    buttonText: 'Learn more',
    buttonUrl:
      'https://experience.arcgis.com/experience/6f4cc60710dc433585790cd2b4b5dd0e'
  },
  protectHome: {
    title: 'Protecting your home from stormwater damage',
    subtitle:
      'If you have experienced flooding or want to reduce the likelihood that it will happen to you, these are some things to consider.',
    list: [
      {
        header: 'Install a backwater valve',
        body:
          'A backwater valve can prevent sewer water from rising up from the city sewer and into your home through basement plumbing. During heavy rain, if water from the city sewer rises to the level of basement plumbing, the valve will close and block sewer water from entering your home.',
        image: backwaterValve,
        imageAlt: 'Backwater valve'
      },
      {
        header: 'Protect areas below street level',
        body:
          'When it rains, water flows to and pools in low areas. Spaces such as underground garages, basements, and other low-lying areas are the most vulnerable to flooding. Reduce the risk of flooding by sealing cracks in foundation walls and considering the use of barriers, such as sandbags.',
        image: basement,
        imageAlt: 'Basement'
      },
      {
        header: 'Reduce stormwater runoff',
        body:
          'Water runs off concrete and asphalt almost immediately and can exceed the design limits of sewers quickly during rains. Retaining and creating green and unpaved spaces around your home can help reduce flows into the sewer by reducing the amount of stormwater runoff your property sends to the sewers.',
        image: unpaved,
        imageAlt: 'Planting tree'
      },
      {
        header: 'Keep grease out of the sewers',
        body:
          'Improper disposal of grease and cooking oil can clog your home’s internal pipes as well as city sewers. This can cause sewer backups in your neighborhood and home.',
        image: cookingOil,
        imageAlt: 'Cooking oil'
      },
      {
        header: 'Keep catch basins clear',
        body: `Keeping litter off the street helps stormwater drain and helps protect the city's water bodies. The City is constantly working to maintain the 7,500 miles of sewers and 148,000 catch basins, which collect street litter. But if those are blocked, it can create street flooding. You and your neighbors can help reduce this type of flooding by disposing of litter properly and by carefully removing leaves or trash from catch basin grates before or during a rain event.`,
        image: garbage,
        imageAlt: 'Trash'
      },
      {
        header: 'Install proper roof drainage',
        body:
          'Directing stormwater from your roof away from the foundation walls of your home is critical in keeping your basement dry. Clean your gutters regularly to prevent stormwater from overflowing onto walls, and connect downspouts to appropriate drains. Consider installing a rain barrel as an alternative method to capture stormwater.',
        image: roofing,
        imageAlt: 'Roof drainage'
      },
      {
        header: 'Purchase flood insurance',
        body:
          'Where it can rain, it can flood—already about 20% of all NFIP claims nationwide occur outside of the FEMA floodplain. Check with your insurance provider and consider purchasing a sewer backup or flood insurance policy. These policies could cover the damage and cleanup costs resulting from sewer backups and flooding caused by heavy rain. Remember: flood damage is not included in standard homeowners or renters insurance policies.',
        image: floodInsurance,
        imageAlt: 'Flood Insurance'
      }
    ]
  },
  homeOwnerGuide: {
    header: `The City's Homeowner's Guide to Rain Event Preparedness`,
    image: rainEventGuide,
    imageAlt: 'Rain Event Guide',
    buttonText: 'Read More',
    posterUrl:
      'https://www1.nyc.gov/assets/dep/downloads/pdf/climate-resiliency/flood-preparedness-flyer.pdf'
  }
};
