import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import List from './layout/List';
import { HeaderSection, Section } from './ui/StyledResourcePageElements';
import { useProvider } from '../helpers/hooks';

const Container = styled.div`
  padding: 10px 0 0 0;
`;

const Privacy = () => {
  const provider = useProvider('privacy');

  return (
    <>
      <Helmet>
        <title>{provider.meta.title}</title>
        <link rel="canonical" href={provider.meta.url} />
        <meta property="og:title" content={provider.meta.title} />
        <meta property="og:url" content={provider.meta.url} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Container>
        <HeaderSection>
          <h1>{provider.title}</h1>
        </HeaderSection>
        <Section>
          <p>{provider.intro.effective_date}</p>
          <p>{provider.intro.body}</p>
          <p>{provider.intro.specific_info}</p>
          <p>{provider.intro.info_we_collect}</p>
          <p>{provider.intro.we_collect}</p>
        </Section>
        <Section>
          <h4>{provider.personal_info.title}</h4>
          <p>{provider.personal_info.body}</p>
          <p>{provider.personal_info.examples}</p>
          <List>
            {provider.personal_info.email}
            {provider.personal_info.address}
            {provider.personal_info.phone}
            {provider.personal_info.homeowner}
          </List>
          <p>{provider.personal_info.no_obligation}</p>
        </Section>
        <Section>
          <h4>{provider.non_personal_info.title}</h4>
          <p>{provider.non_personal_info.body}</p>
          <List>
            {provider.non_personal_info.ip}
            {provider.non_personal_info.web_beacons}
            {provider.non_personal_info.demographic}
            {provider.non_personal_info.addresses}
          </List>
          <p>{provider.non_personal_info.above_list}</p>
        </Section>
        <Section>
          <h4>{provider.use_of_information.title}</h4>
          <h5>{provider.use_of_information.personal_info}</h5>
          <p>{provider.use_of_information.personal_info_body}</p>
          <List>
            {provider.use_of_information.deliver_services}
            {provider.use_of_information.provide_info}
            {provider.use_of_information.verify_email}
            {provider.use_of_information.answer_questions}
            {provider.use_of_information.contact}
            {provider.use_of_information.notify_about_changes}
            {provider.use_of_information.administer}
            {provider.use_of_information.other_purposes}
          </List>
          <h5>{provider.use_of_information.non_personal_info}</h5>
          <p>{provider.use_of_information.non_personal_is_used}</p>
          <p>{provider.use_of_information.non_personal_example}</p>
        </Section>
        <Section>
          <h4>{provider.sharing_and_disclosure.title}</h4>
          <h5>{provider.sharing_and_disclosure.personal_info}</h5>
          <p>{provider.sharing_and_disclosure.personal_examples}</p>
          <List>
            {provider.sharing_and_disclosure.fListfill}
            {provider.sharing_and_disclosure.affiliates_home_resiliency}
            {provider.sharing_and_disclosure.affiliates_other}
            {provider.sharing_and_disclosure.unaffiliated}
            {provider.sharing_and_disclosure.comply_with_law}
            {provider.sharing_and_disclosure.third_part_corp}
            {provider.sharing_and_disclosure.track_and_analyze}
            {provider.sharing_and_disclosure.fraud_protection}
          </List>
          <p>{provider.sharing_and_disclosure.personal_footer}</p>
          <h5>{provider.sharing_and_disclosure.non_personal_info}</h5>
          <p>{provider.sharing_and_disclosure.non_personal_content}</p>
          <h5>{provider.sharing_and_disclosure.children_under_13}</h5>
          <p>{provider.sharing_and_disclosure.children_under_13_content}</p>
          <h5>{provider.sharing_and_disclosure.opt_out}</h5>
          <p>{provider.sharing_and_disclosure.opt_out_content}</p>
          <h5>{provider.sharing_and_disclosure.third_parties}</h5>
          <p>{provider.sharing_and_disclosure.third_parties_content}</p>
          <h5>{provider.sharing_and_disclosure.correct_inaccuracies}</h5>
          <p>
            {provider.sharing_and_disclosure.correct_inaccuracies_body}
            <a href="http://cnycn.org">
              {provider.sharing_and_disclosure.correct_inaccuracies_body_email}
            </a>
          </p>
          <h5>{provider.sharing_and_disclosure.info_protected}</h5>
          <p>{provider.sharing_and_disclosure.info_protected_body}</p>
          <p>{provider.sharing_and_disclosure.info_protected_body_2}</p>
          <h5>{provider.sharing_and_disclosure.third_party_sites}</h5>
          <p>{provider.sharing_and_disclosure.third_party_sites_body}</p>
          <h5>{provider.sharing_and_disclosure.do_not_track}</h5>
          <p>{provider.sharing_and_disclosure.do_not_track_body}</p>
          <h5>{provider.sharing_and_disclosure.assignment}</h5>
          <p>{provider.sharing_and_disclosure.assignment_body}</p>
          <h5>{provider.sharing_and_disclosure.changes}</h5>
          <p>{provider.sharing_and_disclosure.changes_body}</p>
        </Section>
        <Section>
          <h4>{provider.questions.title}</h4>
          <p>
            {provider.questions.body}
            <a href="mailto:info@floodhelpny.org">{provider.questions.email}</a>
          </p>
        </Section>
      </Container>
    </>
  );
};

export default Privacy;
