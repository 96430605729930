export default [
  {
    question:
      'After measuring the flood heights, I believe there is an error with my certificate.',
    answer:
      'Engineers and land surveyors use specialized tools to measure the elevation of each floor as it relates to sea-level. A measuring tape will not accurately measure your property’s elevation.',
    zone: 'elevation-certificates'
  },
  {
    question:
      'Am I eligible for Residential Backwater Valve Installation Program?',
    answer:
      'You are currently eligible if you live within the community boundaries of Lower Manhattan, Rockaway East, Southeast Brooklyn Waterfront, Gravesend and Bensonhurst, Howard Beach, Gerritsen Beach and Sheepshead Bay, and the Southern Brooklyn Peninsula as defined by the Governor’s Office of Storm Recovery (GOSR). After filling out the application, we’ll review your eligibility and let you know what our determination is. Even then, if the plumber or engineer find existing conditions that make it infeasible to install a valve once they get into your home, your eligibility could be put on hold until the issue with the existing conditions are fixed by the homeowner.',
    zone: 'bwv-flood-applicants'
  },
  {
    question: 'Am I required to get an elevation certificate?',
    answer:
      'If your property is currently in the special flood hazard areas (SFHAs), the elevation certificate is the best way to know if your insurance is rated correctly. Additionally, if you see that the new maps will place your home in a higher risk, the elevation certificate can ensure you are mapped correctly.',
    zone: 'understanding-flood-insurance'
  },
  {
    question:
      'Are all program stages (Audit, Counseling, etc.) going to take longer for applicants in Canarsie or Red Hook who are already in the program?',
    answer:
      'Applicants in Canarsie and Red Hook who did not participate in the Home Resiliency Audit program are not eligible to participate at this time. Those who participated in the program previously will move through the backwater valve portion of the program at the same pace as all other participants.',
    zone: 'bwv-flood-applicants'
  },
  {
    question:
      'Are flood vents important to protect your home from water damage?',
    answer:
      'Flood vents prevent structural damage to your home from the force of water pushing against it, called hydrostatic pressure. If the water can flow into the crawl space and back out, this pressure is reduced, protecting your home. Having flood vents in your crawl space could significantly lower your flood insurance rate, depending on the home’s elevation compared to the Base Flood Elevation.',
    zone: 'rate-calculator-building-feature'
  },
  {
    question:
      'Will homeowners in current X zones going to advisory X zones be eligible?',
    answer: 'Yes, they are eligible.',
    zone: 'bwv-flood-applicants'
  },
  {
    question:
      'Are the FEMA flood zones different from the City’s evacuation zones?',
    answer:
      'Yes. You can learn more about the City’s evacuation zones <a target="_blank" href="http://www1.nyc.gov/assets/em/html/know-your-zone/knowyourzone.html">here</a>.',
    zone: 'understanding-flood-insurance'
  },
  {
    question: 'Are there any financial benefits to the program?',
    answer:
      'Elevation certificates must be completed by a land surveyor and can cost between $500 and $800 for uncomplicated cases, but costs can increase by several thousand dollars for more complicated cases. Additionally, counseling services will work with you to help identify steps you can take to reduce your flood insurance rates in the future.',
    zone: 'rtapp-multifamily-landing  rtapp-landing'
  },
  {
    question: 'Are there different types of backwater valves?',
    answer:
      'Yes, among others, there are gate, ball, and flapper valves. A licensed plumber or engineer can tell you which, if any, is best for your home.',
    zone: 'mitigation-backwater_valve'
  },
  {
    question: 'Are there different types of sewer backwater valves?',
    answer:
      'Yes, among others, there are gate, ball, and flapper valves. Backwater valves go by a variety of names including check valve and duckbill valve. A licensed plumber or engineer can tell you which, if any, is best for your home.',
    zone: 'bwv-flood-applicants'
  },
  {
    question: 'Can I add on to my home to make up for the lost space?',
    answer:
      'It may be possible to add another story to your home, if it makes sense economically and doesn’t endanger the structural integrity of the house. Any addition also has to be compliant with zoning and building codes.',
    zone: 'mitigation-abandon_first_floor'
  },
  {
    question: 'Can I apply to the program again?',
    answer:
      'If you don’t meet the eligibility requirements but are interested in hearing from us when new flood resiliency programs become available or if our eligibility requirements change, please give us a call at (646) 786-0888 or email us at info@floodhelpny.org. If your situation changes, and you think you can meet the eligibility requirements feel free to apply again!',
    zone: 'rtapp-status-Ineligible'
  },
  {
    question: "Can I choose my home's auditor?",
    answer:
      'Unfortunately, due to the need to serve a wide variety of homeowners and scheduling, you cannot choose your auditor.',
    zone: 'rtapp-status'
  },
  {
    question: 'Can I fill my basement myself?',
    answer:
      'You need an engineer to make sure that filling your basement won’t damage your home’s structure. You will also need to obtain a permit from the NYC Department of Buildings. You may be able to do some of the work, but we recommend you hire a qualified designer to help you. A designer may also be required to obtain a permit. You also may need to reconfigure utility connections and mechanical equipment.',
    zone: 'mitigation-fill_in_basement'
  },
  {
    question: 'Can I get an elevation certificate for free?',
    answer:
      'The short answer is no. However, the previous owner may already have a certificate; if you are in the process of purchasing the property or recently purchased the property, you may want to contact them.',
    zone: 'elevation-certificates'
  },
  {
    question: 'Can you explain the program’s federal funding?',
    answer:
      'The program is funded  by a U.S. Department of Housing and Urban Development (HUD) Community Development Block Grant–Disaster Recovery (CDBG-DR).  Funding is delivered through the NY Rising Community Reconstruction (NYRCR) Program, within the New York State Governor’s Office of Storm Recovery (GOSR).\\n',
    zone: 'rtapp-status-ApplicationSubmitted'
  },
  {
    question:
      'Does my property have to have been damaged during Hurricane Sandy to be eligible?',
    answer:
      'No the property did not have to be affected by the hurricane in order to be eligible.',
    zone: 'bwv-flood-applicants'
  },
  {
    question: 'Do I have to be home for the installation?',
    answer:
      'Yes, the homeowner must be present during the engineer’s visit, kick-off meeting, and the installation.',
    zone: 'bwv-flood-applicants'
  },
  {
    question: 'Do I have to travel to meet with the construction team?',
    answer:
      'Your construction team will come to your home for the kick-off meeting, which should take one to two hours.',
    zone: 'rtapp-status-MeetYourTeam'
  },
  {
    question: "Do I need to be home during the engineer's assessment?",
    answer: 'Yes, you will need to be home.',
    zone: 'rtapp-status'
  },
  {
    question: 'Do I need to buy flood insurance if I’m not a homeowner?',
    answer:
      "Yes, damage to contents from flooding is not likely to be covered by renter's insurance; the flood insurance would be purchased separately. ",
    zone: 'understanding-flood-insurance'
  },
  {
    question: 'Do I need to install flood vents if I fill in my basement?',
    answer:
      'Yes. Flood vents protect your home by ensuring that water does not build up on one side of the wall and create pressure that could damage your foundation. They are also necessary in order to receive an insurance reduction following a basement in-fill. Flood vents should be installed by a licensed engineer.',
    zone: 'mitigation-fill_in_basement'
  },
  {
    question:
      'Do I need to pay flood insurance while my home is being elevated?',
    answer:
      'Yes, your home will still be vulnerable to damage from flooding while it is being elevated.',
    zone: 'mitigation-elevate'
  },
  {
    question: 'Do I need to relocate during the mechanical elevation process?',
    answer:
      'That will depend on which equipment is relocated, the time of year and other factors. It is very likely that you will have to relocate temporarily during at least some of the construction time.',
    zone: 'mitigation-elevate_mechanicals'
  },
  {
    question: 'Do I need to relocate while my home is being elevated?',
    answer:
      'Yes, utilities such as water, sewer and electricity will be disconnected. Stairs and other means of entry will need to be detached during construction.',
    zone: 'mitigation-elevate'
  },
  {
    question: 'Do you have other programs for my home that might benefit me?',
    answer:
      'Yes! If you want to find out what other programs and benefits you might be eligible for, please contact us at (646) 786-0888.',
    zone: 'rtapp-status-Ineligible rtapp-status-Withdrawn'
  },
  {
    question: 'Do you share this program information with the State?',
    answer:
      'Yes, we also share this information with the Federal government. This program is funded  by a U.S. Department of Housing and Urban Development (HUD) Community Development Block Grant–Disaster Recovery (CDBG-DR) and implemented through the Governor’s Office of Storm Recovery (GOSR), which is a state agency.',
    zone: 'bwv-flood-applicants'
  },
  {
    question: 'Does a washer, dryer or freezer count as “building machinery”?',
    answer:
      'No, those count as contents. To have coverage for these items, you have to buy contents coverage.',
    zone: 'rate-calculator-machinery-in-basement'
  },
  {
    question: 'Does an elevation certificate expire?',
    answer:
      'No. You may see an expiration date in the upper right corner of your elevation certificate. That just means there is a new form. An elevation certificate on an old form is still valid.',
    zone: 'rtapp-preapp-elevation-certificate'
  },
  {
    question: 'Does Build It Back construction work count as a payment?',
    answer:
      'Yes, Build it Back assistance is federally funded and that assistance would need to be reported.',
    zone: 'understanding-flood-insurance'
  },
  {
    question: "Does homeowners' insurance cover flooding?",
    answer: 'Homeowners insurance does not cover flooding.',
    zone: 'rtapp-preapp-homeowner-insurance'
  },
  {
    question: 'Does my income affect my eligibility for the program?',
    answer:
      'The Home Resiliency Audit Program can only serve low to moderate income households at this time. According to the U.S. Department of Housing and Urban Development (HUD), individuals having a median family income less than 50 percent of the Area Median Income (AMI) are considered Low Income. Individuals having a median family income of at least 50 percent and at or less than 80 percent of the AMI are considered Moderate Income.  If your household income exceeds these parameters, you will not be eligible for this program.',
    zone: 'bwv-flood-applicants'
  },
  {
    question: 'Does this program benefit me?',
    answer:
      'Yes! FloodHelpNY services are designed to help make homes less vulnerable to flooding and to reduce the potential for damage to homes, including damage to rental units, in the event of a flood or sewer plumbing backup.',
    zone: 'bwv-flood-applicants'
  },
  {
    question: 'How are building owners selected to participate in the program?',
    answer:
      'Priority is based on income of building tenants, whether they reside in the 100-year floodplain, and whether the building was damaged by Superstorm Sandy.',
    zone: 'rtapp-multifamily-landing'
  },
  {
    question: 'How are homeowners selected to participate in the program?',
    answer:
      'Homeowners who meet all of the eligibility requirements will be able to participate in the program. If you are interested in being considered, please fill out our brief intake form online.',
    zone: 'rtapp-landing'
  },
  {
    question: 'How can I find a contractor to fill in my basement?',
    answer:
      'The NYC Department of Buildings <a target="_blank" href="http://www1.nyc.gov/site/buildings/homeowner/homeowner.page">can give you advice</a> on how to find a designer and contractors who have experience working in flood zones. Contractors should be experienced, licensed and insured. Check references.',
    zone: 'mitigation-fill_in_basement'
  },
  {
    question: 'How can I find out if I can elevate my home?',
    answer:
      'You will need to hire a qualified engineer who can assess whether elevation is a good option for your home.',
    zone: 'mitigation-elevate'
  },
  {
    question: 'How can I find out if I can fill in my basement?',
    answer:
      'Consult a professional engineer, licensed contractor or the NYC Department of Buildings.',
    zone: 'mitigation-fill_in_basement'
  },
  {
    question: 'How can I get an elevation certificate?',
    answer:
      'Elevation certificates must be completed by a land surveyor or engineer and can cost between $500 and $800 for uncomplicated cases, but can be more for more complicated cases.',
    zone: 'understanding-flood-insurance'
  },
  {
    question: 'How can I get this coverage?',
    answer:
      'Homeowners can discuss how to get a sewage backup insurance rider with their homeowners insurance provider. Sewage backup insurance typically costs less than $100 per year. Previous claims, credit and street-level elevation can sometimes affect rates.',
    zone: 'rtapp-preapp-sewage'
  },
  {
    question: 'How can I learn more about flood insurance?',
    answer:
      'Check out our <a href="https://www.floodhelpny.org/en/understanding-flood-insurance">comprehensive resource page!</a>',
    zone: 'rtapp-preapp-flood-insurance'
  },
  {
    question: 'How can I lower my flood insurance rate?',
    answer:
      'As of now, there are only four measures that have an effect on insurance premiums: home elevation, filling in a basement or crawl space and installing flood vents, abandoning your first floor, and raising mechanical equipment out of the basement. The last option provides the smallest insurance reduction of the three, but it can save you a great deal of money in the event of a flood.',
    zone: 'understanding-flood-insurance'
  },
  {
    question: 'How can I tell if my property has a sewer backwater valve?',
    answer:
      'The best way to find out if you have a sewer backwater valve or if you need one is to consult with a licensed and qualified plumber. A sewer backwater valve can be difficult to identify on your own, especially if it was installed a long time ago.',
    zone: 'bwv-flood-applicants'
  },
  {
    question: 'How can I tell if my property has a sewer backwater valve?',
    answer:
      'The best way to determine if you have a sewer backwater valve or if you need one is to consult with a licensed and qualified plumber. A sewer backwater valve can be difficult to identify on your own, especially if it was installed a long time ago.',
    zone: 'rtapp-preapp-backwater-exist'
  },
  {
    question:
      'How do I choose which flood resiliency options are right for me?',
    answer:
      'You should consider which options are available for your home (for example, you can’t easily elevate an attached home), the upfront cost of the mitigation measure, and possible savings on your insurance premium over time.',
    zone: 'programs-and-benefits resiliency'
  },
  {
    question: 'How do I determine my household size for the program?',
    answer:
      'Household size can be determined by counting the number of people who live with you, including yourself.',
    zone: 'rtapp-preapp-household-size'
  },
  {
    question: "How do I find my home's Base Flood Elevation (BFE)?",
    answer:
      'Start by knowing your zone. If you are in an AE zone, an elevation certificate is the best way to accurately know you BFE. If you are in an X zone, you are in an area of the flood map that currently does not have a projected base flood elevation.',
    zone:
      'understanding-flood-insurance     mitigation-flood_vents       mitigation-abandon_first_floor     mitigation-elevate_mechanicals'
  },
  {
    question: "How do I find my home's Design Flood Elevation (DFE)?",
    answer:
      'The DFE determines the safest elevation for construction in flood hazard zones. In NYC, the DFE is usually the Base Flood Elevation plus two feet — known as “freeboard,” for added safety. Your designer or architect should consult the NYC Department of Buildings during design and permitting to ensure that they are following the DFE.',
    zone:
      'mitigation-flood_vents    mitigation-abandon_first_floor  mitigation-elevate_mechanicals'
  },
  {
    question:
      'How do I find out how much my mechanical equipment should be raised by?',
    answer:
      'You or your contractor should always consult the NYC Department of Buildings. Ideally, equipment should be raised to the Design Flood Elevation, which is the Base Flood Elevation plus two feet of freeboard.',
    zone: 'mitigation-elevate_mechanicals'
  },
  {
    question: 'How do I get help filling out the application?',
    answer:
      'If you have any questions, or need help filling out the application, feel free to call our Homeowner Hub at (646) 786-0888.',
    zone: 'rtapp-status-FullApplication'
  },
  {
    question: 'How do I know how much to elevate my home?',
    answer:
      'Typically, BFE plus two feet of freeboard is required in NYC. But it’s a good idea to consult with a qualified engineer who has experience doing construction in flood zones. The NYC Department of Buildings <a target="_blank" href="http://www1.nyc.gov/site/buildings/homeowner/homeowner.page">can help you find one</a>.',
    zone: 'mitigation-elevate'
  },
  {
    question: 'How do I know if I have a “basement” or a “crawl space”?',
    answer:
      'A basement is an area where the floor is below ground level on all four sides. If it is less than 5 feet high, it is generally considered a crawl space.',
    zone: 'rate-calculator-building-feature'
  },
  {
    question: 'How do I know if I need a backwater valve?',
    answer:
      'Always consult with a licensed plumber to determine if you would benefit from a backwater valve since having one installed in on certain properties can actually make things worse.',
    zone: 'mitigation-backwater_valve'
  },
  {
    question: 'How do I know if I took federal disaster relief in the past?',
    answer:
      'If you received help from FEMA, the SBA, Build It Back, or New York Rising — for repairs or reconstruction of your home or for the contents — because of damage caused by flooding, then you have received federal disaster relief. For most people, this would be help for damage caused by Hurricane Irene or Superstorm Sandy.',
    zone: 'understanding-flood-insurance'
  },
  {
    question: 'How do I know if I’ve had plumbing backup?',
    answer:
      'You might have noticed water and/or foul odors coming up through your toilet, sink, bathtub, floor drain, or other plumbing fixtures.',
    zone: 'bwv-flood-applicants'
  },
  {
    question: 'How do I know where I should install the valve?',
    answer:
      'A licensed plumber will assess the condition and layout of your sewer lateral. He or she will identify the most appropriate location to install the valve. Always consult with a licensed plumber to determine the location since a backwater valve installed in the wrong place can actually make things worse.',
    zone: 'mitigation-backwater_valve'
  },
  {
    question: 'How do I know which type of backwater valve to install?',
    answer:
      'A licensed plumber or engineer can tell you which type of backwater valve, if any, is best for your home.',
    zone: 'mitigation-backwater_valve'
  },
  {
    question: 'How do I maintain the sewer backwater valve?',
    answer:
      "Ask your plumber to provide you with the operation and maintenance instructions from your valve’s manufacturer. It's usually pretty simple — open your valve access point a couple of times per year to make sure nothing is clogging your valve. If something is clogging it, remove it. If the flap needs lubricant, apply it. Some plumbers offer maintenance programs and will do this for you. If you receive a sewer backwater valve through our program, the plumber who installs your valve will show you where the valve is, how it works, and how to maintain it. In addition, you’ll receive a field report when the installation is complete containing specific operation and maintenance instructions from the manufacturer of your valve.",
    zone:
      'bwv-flood-applicants  mitigation-backwater_valve  rtapp-status-InstallationComplete'
  },
  {
    question:
      'How do I qualify for “built in compliance” grandfathering in a flood insurance policy?',
    answer:
      'You can only qualify if your home was built after November 1983 and you are currently in a high-risk flood zone. You must also show that your home was built in compliance with the current flood map and you haven’t modified it. If you converted a first-floor garage into living space, for instance, you won’t qualify unless you change it back.',
    zone: 'understanding-flood-insurance'
  },
  {
    question:
      'How do I qualify for “continuous coverage” grandfathering in a flood insurance policy?',
    answer:
      'Making sure you have a policy before the maps change basically means you do not have continuous coverage.',
    zone: 'understanding-flood-insurance'
  },
  {
    question: 'How do I submit my required application documents?',
    answer:
      'To submit your documents, you may:<ul><li>Scan and upload through your status page on FloodHelpNY.org.</li><li>Fax the Center for NYC Neighborhoods: 1-646-849-1765</li><li>Mail copies to the Center for NYC Neighborhoods Attn.</li><li>FloodHelpNY, 55 Broad Street, 10th Floor, New York, NY 10004.</li></ul>',
    zone: 'bwv-flood-applicants'
  },
  {
    question: 'How do I submit the Home Grant Agreement (HGA) to the Center?',
    answer:
      'If only one individual needs to sign your Homeowner Grant Agreement (that is, there is only one person listed on the deed for the property), then you can electronically sign the document. After we determine your eligibility, we’ll send you a link to do this. If more than one person needs to sign your Homeowner Grant Agreement, you can download and print the document from your status page. Once completed, you can fax it to (646) 506-4621, email a copy to info@floodhelpny.org or mail it via the postal service to FloodHelpNY, Center for NYC Neighborhoods, 55 Broad Street, 10th Floor, New York, NY 10004.',
    zone: 'rtapp-status-GrantAgreement'
  },
  {
    question: 'How do steps affect my flood insurance rate?',
    answer:
      'If you don’t have a basement or below-ground crawlspace, your rate is determined by the elevation of your first floor. The higher this floor is above the Base Flood Elevation, the lower your rate will be, basically.',
    zone: 'rate-calculator-number-of-steps'
  },
  {
    question: 'How do these home features affect my flood insurance rate?',
    answer:
      'The lower your lowest floor is, the higher your flood insurance rate, basically.',
    zone: 'rate-calculator-building-feature'
  },
  {
    question: 'How do you qualify for a Preferred Risk flood insurance policy?',
    answer:
      'You must be in Zone X or no zone and have only received one payment of over $1,000 from a federal disaster assistance program for separate floods in the past 10 years. Federal disaster assistance means NFIP flood insurance or FEMA or SBA or Build It Back.',
    zone: 'understanding-flood-insurance'
  },
  {
    question: 'How does a basement affect my flood insurance rate?',
    answer:
      'If you live in a high risk flood zone, you are rated based on the height of your lowest floor in relation to your BFE. If you have a basement or crawlspace, you are rated based on the elevation of this floor, even if the space is unfinished.',
    zone: 'rate-calculator-basement'
  },
  {
    question: 'How does basement machinery affect my flood insurance rate?',
    answer:
      'Having machinery in your basement increases your rate, but not by much. But raising this machinery out of your basement can have a big impact on how quickly you can restore heat and electricity to your home after a storm.',
    zone: 'rate-calculator-machinery-in-basement'
  },
  {
    question: 'How does building type affect my flood insurance rate?',
    answer:
      'Depending on your building type, there are different resiliency options available.',
    zone: 'rate-calculator-building-type'
  },
  {
    question: 'How does content affect my flood insurance rate?',
    answer:
      'Contents coverage for basements and enclosures is more expensive than for higher floors, and the coverage in basements and enclosures is limited to a washer, dryer and freezer. That’s it. It may not be worth the higher cost for contents coverage to have these items in your basement insured.',
    zone: 'rate-calculator-content-location'
  },
  {
    question: 'How does elevation affect my flood insurance rate?',
    answer:
      'If you live in a high risk flood zone, you are rated based on the elevation of your lowest floor in relation to your BFE. When you elevate your home so that it sits 2 feet above your BFE, you drastically reduce the chances of floodwater entering the home, and therefore the cost of insurance.',
    zone: 'rate-calculator-elevated-building'
  },
  {
    question: 'How does previous flood damage affect my flood insurance rate?',
    answer:
      'If you received over $1,000 on a flood damage claim by flood insurance, FEMA, or the Small Business Administration two or more times for different floods, you’re not eligible for a low Preferred Risk Rate policy. Remember, the payments must be for different floods, not two payments for Sandy damage. Even if you were paid $50,000 or $100,000 from FEMA and flood insurance for Sandy, that’s only one claim.',
    zone: 'rate-calculator-preferred-risk-rate'
  },
  {
    question: 'How does previous flood damage affect my flood insurance rate?',
    answer:
      'If you received over $1,000 on a flood damage claim by flood insurance, FEMA, or the Small Business Administration two or more times for different floods, you’re not eligible for a low Preferred Risk Rate policy. Remember, the payments must be for different floods, not two payments for Sandy damage. Even if you were paid $50,000 or $100,000 from FEMA and flood insurance for Sandy, that’s only one claim.',
    zone: 'rate-calculator-severe-repetitive-loss'
  },
  {
    question: 'How is climate change affecting New York City?',
    answer:
      'Climate change adds a whole other element to thinking about resiliency in New York City. You can read more about the impacts of climate change to the city <a target="_blank" href="http://onlinelibrary.wiley.com/doi/10.1111/nyas.12591/full">here</a>.',
    zone: 'resiliency rtapp-multifamily-landing'
  },
  {
    question:
      'Why is there program funding for existing applicants but not new applicants?',
    answer:
      'This is only in Canarsie and Red Hook. Canarsie was one of the most participative communities in the initial home resiliency audit program before backwater valve installations were added. Funding for audits in Canarsie was used up, but we have since received additional funding to install backwater valves there. Since nobody can get a backwater valve installed without having an audit, people in Canarsie who have not already had an audit and gone to counseling before funding ran out will not be eligible to get an audit or a backwater valve. It is possible that funding allocations could change in the future, and we will be sure to touch base if that happens.',
    zone: 'bwv-flood-applicants'
  },
  {
    question: 'How long does abandoning the first floor take?',
    answer:
      'Timing will vary depending on the construction of the building, the size of the area that will be abandoned on the lower level and how much filling of below-ground areas will be required. A good estimate is from a few weeks to 90 days. However, additional time should be allowed for design and obtaining permits.',
    zone: 'mitigation-abandon_first_floor'
  },
  {
    question: 'How long does elevating my home take?',
    answer:
      'It can take up to three months or longer to do the work itself. However, creating the design and obtaining permits can draw out the timeline for elevating your home.',
    zone: 'mitigation-elevate'
  },
  {
    question: 'How long does filling my basement take?',
    answer:
      'It can take a few days to fill in the basement or crawlspace. But it can take one to two week longer on average if you need to relocate mechanicals in the basement or crawlspace. Add on the time it takes to obtain permits and it could take even longer.',
    zone: 'mitigation-fill_in_basement'
  },
  {
    question: 'How long does raising my mechanicals take?',
    answer:
      'It can take anywhere from three to five days to a few weeks, after permits are obtained and construction begins.',
    zone: 'mitigation-elevate_mechanicals'
  },
  {
    question: 'How long does the application approval process generally take?',
    answer:
      'Once your application and subsequent documents are received, you can expect an approval determination within five business days.',
    zone: 'rtapp-status-ApplicationSubmitted'
  },
  {
    question: 'How long does wet floodproofing take?',
    answer: 'Installing flood vents can be done in a few days.',
    zone: 'mitigation-flood_vents'
  },
  {
    question: 'How long will my backwater valve installation take?',
    answer:
      'The amount of time the installation takes depends on the layout of your home and its connection to the city sewer, as well as the condition of the pipe connecting your home to the city sewer. Hopefully, it will only take one visit, but the plumber may have to return another day to finish cleaning up or to complete a tough installation. You do need to be present at your home.',
    zone: 'rtapp-status-InstallationToBeScheduled'
  },
  {
    question: 'How long will the consultation take?',
    answer:
      'The consultation should take between 45 minutes to an hour and a half.',
    zone: 'rtapp-status'
  },
  {
    question: 'How long will the home flood assessment take?',
    answer: 'The assessment will take a maximum of two hours.',
    zone: 'rtapp-status'
  },
  {
    question: 'How long will this program take?',
    answer:
      'It’s hard to estimate, but the process could take up to six months from beginning to end depending on your schedule, how complex the installation will be, and the contracting process. Thank you for your patience!',
    zone: 'bwv-flood-applicants'
  },
  {
    question:
      'How many days do I need to take off of work to participate in the program?',
    answer:
      'If you have already received a FloodHelpNY Home Resiliency Audit we’ll need you to be home for three things:  the engineer’s feasibility review, the Kick-Off Meeting with the plumber, and the installation.  If you have not received a FloodHelpNY Home Resiliency Audit we’ll need you to be home for the audit, the Kick-Off Meeting, and the installation itself.  We expect the audit to take a maximum of two hours.  The timeline for the Kick-Off Meeting and the installation remain the same for homeowners who have received an audit.',
    zone: 'bwv-flood-applicants'
  },
  {
    question:
      'How many days do I need to take off work to participate in the program?',
    answer:
      "You'll need to be home for the kick-off meeting and the installation. The kick-off meeting is when the homeowner will meet their plumber and inspector, and should take a maximum of one hour. A typical installation should take one to two days, depending on the home. ",
    zone: 'rtapp-status-InstallationPrepare'
  },
  {
    question: 'How many people will be coming to evaluate my home?',
    answer:
      'A team of two to four  certified engineers and surveyors will visit your home to assess its flooding vulnerabilities and make recommendations for how to protect your home against future flooding.',
    zone: 'rtapp-status-AuditScheduled'
  },
  {
    question: 'How much does abandoning the first flood cost?',
    answer:
      'That depends on the current use of the first floor and if you need to relocate mechanical equipment, utilities, the kitchen and/or bathrooms. The cost could be $10,000 to $100,000 or more.',
    zone: 'mitigation-abandon_first_floor'
  },
  {
    question: 'How much does filling my basement cost?',
    answer:
      'Depending on the depth of the basement and whether you need to relocate utilities and equipment and install flood vents, it could cost $8,500 to $30,000.',
    zone: 'mitigation-fill_in_basement'
  },
  {
    question: 'How much does it cost to install a backwater valve?',
    answer:
      'It depends on a number of factors, including the layout of your sewer pipes, the depth and location of the pipe that connects your home to the sewer, if there are environmental hazards (i.e. lead paint) in the area where the valve will be installed, what type of valve is installed, etc. The cost can be as little as $600 or more than $5,000.',
    zone: 'mitigation-backwater_valve'
  },
  {
    question:
      'How much flood insurance coverage am I required to have if I got disaster assistance (FEMA, SBA, Build It Back)?',
    answer:
      'If you received federal disaster assistance to repair or rebuild your building or for damaged contents, you must have coverage for at least the amount of assistance you received. Contents coverage is required if you received federal assistance for damaged contents. This requirement stays with the property forever, for you and any future buyer. You must tell a buyer about this requirement.',
    zone: 'understanding-flood-insurance'
  },
  {
    question:
      'How much flood insurance coverage am I required to have if I have a mortgage?',
    answer:
      'If you are in a high-risk flood zone and you have a mortgage, you must have building coverage for at least the unpaid balance on the mortgage (the principal balance) for as long as you have the mortgage. Contents coverage isn’t required.',
    zone: 'understanding-flood-insurance'
  },
  {
    question:
      'How much flood insurance is required, and when does it need to be purchased?\\n',
    answer:
      'If the applicant lives in an AE zone, flood insurance must be purchase before they will be accepted into the program. The building flood insurance coverage must be equal to the cost of the backwater valve installation, on average, around $1,500.',
    zone: 'bwv-flood-applicants'
  },
  {
    question:
      'How much homeowners insurance is required, and when does it need to be purchased?',
    answer:
      'You must have homeowners insurance before you will be accepted to the program. We do not specify how much homeowners insurance you must have, just that you have it.',
    zone: 'rtapp-preapp-homeowner-insurance'
  },
  {
    question:
      'How much homeowners insurance is required, and when does it need to be purchased?',
    answer:
      'The applicant must have homeowners insurance before they will be accepted to the program.  We do not specify how much homeowners insurance they must have.  They just have to have it in general.',
    zone: 'bwv-flood-applicants'
  },
  {
    question: 'How much will raising my mechanicals cost?',
    answer:
      'Depending upon your home, contractor and which utilities are relocated, it can cost between $5,000 and $40,000.',
    zone: 'mitigation-elevate_mechanicals'
  },
  {
    question:
      'How much will the Residential Backwater Valve Installation Program cost me?',
    answer:
      'The Residential Backwater Valve Installation Program is completely free.',
    zone: 'bwv-flood-applicants'
  },
  {
    question: 'How much will wet floodproofing cost?',
    answer: 'Installing vents can cost between $4,000 and $8,000.',
    zone: 'mitigation-flood_vents'
  },
  {
    question: 'How should I prepare for the backwater valve installation?',
    answer:
      'In additional to making sure the plumber has easy access to the  basement area where the valve will be installed, please also be prepared to be home and make plans for when the water is shut off.',
    zone: 'rtapp-status-InstallationPrepare'
  },
  {
    question: 'How will I get into my home if the first floor is abandoned?',
    answer:
      'You’ll need to have new entry stairs constructed. In some circumstances, you may need to have a mechanical lift installed.',
    zone: 'mitigation-abandon_first_floor'
  },
  {
    question: 'How will my information be protected?',
    answer:
      'The confidentiality and security of your information is critical to us. We use physical, digital, and administrative security measures to keep your personal information secure and confidential. We will not provide any information to third parties who are not involved in administering FloodHelpNY without your permission or as required by law.  We also adhere to strict privacy requirements set by both the state and the federal government.',
    zone: 'bwv-flood-applicants'
  },
  {
    question:
      'I already have an elevation certificate, is there anything I should consider?',
    answer:
      'If you have an elevation certificate, you do not need to submit a new certificate. However, if you make any changes to the property, such as improvements to minimize the effects of flooding, you may want to get an updated certificate to ensure you receive the correct price.',
    zone: 'elevation-certificates'
  },
  {
    question:
      'I already went through the FloodHelpNY Home Resiliency Audit Program. How do I get a sewer backwater valve?',
    answer:
      'You will be retroactively considered for the Residential Backwater Valve Installation Program. When the program launches the Center will email you the Backwater Valve application.  The application will have to be completed and returned to the Center for further eligibility review.  Your eligibility will be contingent upon the outcome of our review of your application and the engineer’s and plumber’s findings in your home.  An eligible determination does not necessarily mean that you will get a valve since only low-to-moderate income households will be served at this time.',
    zone: 'bwv-flood-applicants'
  },
  {
    question:
      'I believe I am in a neighborhood that is eligible for Home Resiliency Audits, but I can’t sign up.',
    answer:
      'Reach out to us at info@floodhelpny.org. Send us your name, address, and tell us about what happened when you tried to apply. We’ll research and get back to you. You can also call our Helpline at 646-786-0888.',
    zone: 'rtapp-landing'
  },
  {
    question:
      'I know I received an email a few days after applying, but I can’t find it, what do I do now?',
    answer:
      'You are most likely in the application phase. You can log onto floodhelpny.org and your status page will tell you what phase you are in and what you need to do.',
    zone: 'bwv-flood-applicants'
  },
  {
    question:
      'I still have elevation certificate or flood insurance questions. Can you assist?',
    answer:
      'Floodsmart.gov is a great resource to find more information about flood insurance and elevation certificates. You can also feel free to send your questions to info@floodhelp.org.',
    zone: 'elevation-certificates'
  },
  {
    question:
      'If an applicant from Canarsie or Red Hook received an elevation certificate and attended counseling, will they be considered for the Backwater Valve program?',
    answer:
      'Yes, the only residents from Canarsie and Red Hook that will be considered for the Backwater Valve Program are those who have received an audit and attended counseling.',
    zone: 'bwv-flood-applicants'
  },
  {
    question: 'How do I acquire an elevation certificate?',
    answer:
      'Elevation Certificates must be prepared and certified by a Licensed Land Surveyor, Registered Professional Engineer or Registered Architect who are authorized by state or local law to certify elevation information. Ask for their license, experience in New York City or your neighborhood, any certifications, and references from similar projects. FEMA recommends finding a professional surveyor by checking your state professional association for land surveyors or by talking to your local building permit office.',
    zone: 'elevation-certificates'
  },
  {
    question:
      'If I don’t know how many residential units are in my building, how can I find out?',
    answer:
      'City’s Department of Buildings may have the building’s certificate of occupancy on file that shows the number of dwelling units.',
    zone: 'rtapp-preapp-number-of-units'
  },
  {
    question:
      'If I don’t know if this property has had flood damage, how can I find out?',
    answer:
      'Ask your flood insurance broker or FEMA what the flood loss history on your building is.',
    zone:
      'rate-calculator-severe-repetitive-loss rate-calculator-preferred-risk-rate'
  },
  {
    question: 'If I don’t know when my building was built, how can I find out?',
    answer:
      'If you’ve ever had an appraisal, it should give the date of construction. If you have a newer home, you can look at the date on the Certificate of Occupancy, available from the Department of Buildings.',
    zone: 'rate-calculator-pre-firm'
  },
  {
    question:
      'If I have a Preferred Risk flood insurance policy now and my zone changes to AE or VE, can I grandfather into the Preferred Risk Policy rate?',
    answer:
      'No, there is a newly mapped subsidy, but the rate will likely increase 15-18% annually until your policy reaches the full-risk amount.',
    zone: 'understanding-flood-insurance'
  },
  {
    question:
      'If I’m in a low- or moderate-risk flood zone now, but going to be high-risk under the new maps, can I still get a Preferred Risk Policy?',
    answer: 'This will be updated at a later date.',
    zone: 'understanding-flood-insurance'
  },
  {
    question: 'Is flood insurance required for the program?',
    answer:
      'Federal law requires you to have flood insurance if your home is in a high-risk flood zone (AE, AO or VE) and you have a federally-backed mortgage. (Almost all mortgages are federally backed). Also, if you have received federal disaster assistance for repairs to your building or for lost contents, federal law requires you to have flood insurance. If you don’t buy it, you will not qualify for assistance.',
    zone: 'rtapp-preapp-flood-insurance'
  },
  {
    question:
      'Does the space in my home still count as a “basement” if it is short?',
    answer:
      'If it is shorter than about 5 feet from floor-to-ceiling and it is no more than 2 feet below ground, then it is a “crawl space.” If it is short but more than 2 feet below ground on any side, it is still a “basement.”',
    zone: 'rate-calculator-basement'
  },
  {
    question:
      "Is sewage backup coverage included in my homeowners' insurance policy? ",
    answer:
      'Sewage backup is not typically covered by standard homeowners insurance policies. Sewage backup insurance is an “endorsement” or “rider” that can often be added to standard homeowners insurance.',
    zone: 'rtapp-preapp-sewage'
  },
  {
    question:
      'Is there a way to tell whether the flapper of my sewer backwater valve is closed or open?',
    answer:
      "You'll know if your valve is closed because water will stop going down your drains. If you suspect the valve might be closed, you can also check using the valve access point provided by the plumber during the installation. Some valves have alarms connected to them that are activated when the valve closes, though they can be costly.",
    zone: 'mitigation-backwater_valve'
  },
  {
    question:
      'Is there a way to tell whether the flapper of my sewer backwater valve is closed or open?\\n',
    answer:
      "You'll know if your valve is closed because water will stop going down your drains.  If you suspect the valve might be closed, you can also check using the valve access point provided by the plumber during the installation. Some valves have alarms connected to them that are activated when the valve closes, though they can be costly.",
    zone: 'bwv-flood-applicants'
  },
  {
    question:
      'Is there anything I can do to speed-up the installation process?',
    answer:
      'Unfortunately, there isn’t. Putting together your construction team involves extensive internal paperwork and correspondence that must be completed to ensure compliance with federal and state guidelines. We will contact you the minute your team is ready!',
    zone: 'rtapp-status-InstallationPrepare'
  },
  {
    question:
      'On the application, should applicants put "N/A" in the answer space when the question doesn\'t apply, or could it could be left blank?',
    answer:
      'N/A is ideal so we can make an informed decision about whether or not we need to call someone to follow up on whatever the field may be.',
    zone: 'bwv-flood-applicants'
  },
  {
    question:
      'Do you have weekend/evening times for backwater valve installations?',
    answer:
      'We’re hoping to have at least some weekend and evening appointments available, but they’ll be limited.',
    zone: 'bwv-flood-applicants'
  },
  {
    question:
      'What additional information is needed for a complete application?',
    answer:
      "We need legible copies of all of the requested documents to review your homeowners insurance, driver's license, current gross annual income information, proof of flood insurance (if applicable), and tenant income information (if applicable). We will ask for additional information if the documents submitted are illegible or are missing from your application. ",
    zone: 'rtapp-status-ApplicationSubmitted'
  },
  {
    question: 'What are “flood vents”?',
    answer:
      'These are specially designed openings that allow water to flow in and out. There are rules about how many are required and where they must be placed. If the openings don’t meet these requirements, they won’t count as flood vents for flood insurance rating. Read more <a target="_blank" href="/en/resiliency">here</a>.',
    zone: 'rate-calculator-building-feature'
  },
  {
    question:
      'What are common reasons why someone may be technically ineligible for the program?',
    answer:
      "If the existing conditions don't allow for a backwater valve installation (i.e. not enough space or the condition of the sewer lateral is too poor for the plumber to be able to cut into it without making major repairs), if the home doesn't need a backwater valve (there is no indication that the home is at risk of sewage backup based on elevations of the home, sewer house connection, nearby sewer, base flood elevation, etc.) ",
    zone: 'bwv-flood-applicants'
  },
  {
    question:
      'What are considered “belongings” when it comes to flood insurance?',
    answer:
      'Furniture, clothing, dishes, appliances that are not built in, TVs — all your stuff that isn’t part of the house. Remember that the only belongings you can insure in a basement are a washer, a dryer, and a freezer. No other belongs in your basement can be covered.',
    zone: 'rate-calculator-content-location'
  },
  {
    question: 'What are flood vents?',
    answer:
      'These are specially designed openings that allow water to flow in and out. There are rules about how many are required and where they must be placed. If the openings don’t meet these requirements, they won’t count as flood vents for flood insurance rating. Read more <a target="_blank" href="/en/resiliency">here</a>.',
    zone: 'mitigation-fill_in_basement'
  },
  {
    question: 'What are some alternatives to backwater valves?',
    answer:
      'You could install backwater valves on all plumbing fixtures that are below the Base Flood Elevation rather than installing one on the sewer pipe connecting your home to the sewer, if deemed appropriate by a licensed and qualified plumber. You could also flood-proof your basement and use it only for parking or storage. Sewage may still back up, but your belongings would be protected. The New York City Department of Environmental Protection (NYCDEP) prepared <a href="http://www.nyc.gov/html/dep/pdf/brochures/flood-preparedness-flyer.pdf" target="_blank">this</a> Flood Preparedness Flyer to help homeowners reduce the chances of their home flooding during a rain event.',
    zone: 'mitigation-backwater_valve'
  },
  {
    question: 'What are some alternatives to sewer backwater valves?',
    answer:
      'You can flood-proof your basement and elevate your mechanical equipment so that the basement is only used for parking or storage. Sewage may still back up, but your belongings will be protected. The New York City Department of Environmental Protection (NYCDEP) prepared a Flood Preparedness Flyer to help homeowners reduce the chances of their home flooding during a rain event.\\n\\nhttp://www.nyc.gov/html/dep/pdf/brochures/flood-preparedness-flyer.pdf\\n',
    zone: 'bwv-flood-applicants'
  },
  {
    question:
      'What are some common questions I might want to think about before counseling?',
    answer:
      'Please bring all of your flood insurance and storm resiliency questions. Currently, the flood insurance rating maps in New York City are under review; the federal government’s proposed future flood maps may double the amount of homes in the flood hazard area. Consider asking your counselor about your property’s potential future flood risk and how this could affect your insurance rates.  They are also able to discuss mitigation options to make your home more resilient.',
    zone:
      'rtapp-status-AuditComplete rtapp-status-AuditComplete rtapp-status-CounselingScheduled  rtapp-status-CounselingScheduled'
  },
  {
    question:
      'What are some other types of mechanicals that can be raised to reduce the risk of damage in the event of a flood?',
    answer: 'Furnaces, heat pumps, electrical panels or elevators.',
    zone: 'rate-calculator-machinery-in-basement'
  },
  {
    question:
      "What is the Center's role and responsibility after the backwater valve is installed? ",
    answer:
      'After the valve is installed, it’s the homeowner’s responsibility to maintain it. The Center will provide a Field Report that will contain pre- and post-construction photos and information from the valve manufacturer regarding operation and maintenance. The contract is likely to contain some extent of warranty on the work. If there is an issue with the valve during the warranty period, then the homeowner can call the Center to discuss the issue and possibly get the plumber who installed the valve back out to the home to correct the issue.',
    zone: 'bwv-flood-applicants'
  },
  {
    question:
      'What are the main terms and conditions of the Homeowner Grant Agreement (HGA)?',
    answer:
      'Please refer to this document to learn about the main terms and conditions of the HGA. It is the responsibility of the homeowner(s) to review the document in its entirety to fully understand all of its conditions.',
    zone: 'rtapp-status-GrantAgreement'
  },
  {
    question:
      'What are the next steps after I fill out the program pre-application?',
    answer:
      'After we review the information you submitted to prequalify, we will determine whether to invite you to apply to the program. In that case, you’ll receive an email requesting that you to fill out the online application. We will also ask you to submit supporting documentation that confirms your income and any insurance. The application and supporting documentation will be used to confirm your eligibility.',
    zone: 'rtapp-status-IntakeComplete'
  },
  {
    question: 'What are the risks involved with installing a backwater valve?',
    answer:
      'There is a small risk that the backwater valve will fail. Backwater valves reduce the chance of sewage backing up into your home through your sewer pipe, but if the valve is not maintained properly, sewage can get stuck and leak through.',
    zone: 'mitigation-backwater_valve'
  },
  {
    question: 'What can I do to confirm my flood insurance rate is accurate?',
    answer:
      'An elevation certificate can help ensure you receive an accurate flood insurance rating. Once you have an elevation certificate, work with your insurance agent to update your policy. If, you have concerns about how your policy has been rated after using the elevation certificate, contact the FEMA Mapping and Insurance eXchange (FMIX) center at 877-336-2627. They will be able to discuss and review your policy.',
    zone: 'elevation-certificates'
  },
  {
    question: 'What can I fill my basement with to mitigate flood risk?',
    answer: 'You can use clean, compacted sand or stone.',
    zone: 'mitigation-fill_in_basement'
  },
  {
    question: 'What constitutes an unsafe backwater valve installation?',
    answer:
      'The installation could be determined unsafe for many reasons. The system/space could be in poor enough condition that it would be too risky for the plumber or for the plumbing system, to proceed with the installation.  This determination can be made at any stage.  It is likely that the plumber would need to make their initial cut in the floor to determine if the installation would be “safe”.  This is expected to be a rare occurrence.',
    zone: 'bwv-flood-applicants'
  },
  {
    question: 'What counts as proof of flood insurance for the program?',
    answer:
      'The flood insurance declaration page may be attached here as proof of insurance.',
    zone: 'rtapp-status-FullApplication'
  },
  {
    question: 'What counts as proof of homeowners insurance for the program?',
    answer:
      'Your homeowners insurance declaration page may be attached here as proof of insurance.',
    zone: 'rtapp-status-FullApplication'
  },
  {
    question: 'How do I apply for the program if I have tenants?',
    answer:
      'Homeowners with tenants are required to complete a Tenant Income Verification form; one form per unit. The homeowner is responsible for submitting the form with supporting documentation to the Center. If the Center receives information indicating their rental units, a Tenant Verification form will be sent via email. For questions, please email info@floodhelpny.org or call the Center at 646-786-0888.',
    zone: 'rtapp-status-FullApplication'
  },
  {
    question:
      'What do I do when the Tenant Income Verification Form is completed?',
    answer:
      'Please send it by mail to the Center within 14 days of receipt to:<br /><br />Center for NYC Neighborhoods, attn. FloodHelpNY<br />55 Broad Street<br />10th Floor<br />New York, NY 10004<br /><br />You can also scan and email the form and supporting documents to info@floodhelpny.org.',
    zone: 'rtapp-status-FullApplication'
  },
  {
    question: 'What do I do with this application form when it is completed?',
    answer:
      'Please send it by mail to the Center within 14 days of receipt to:\\n\\nCenter for NYC Neighborhoods, attn. FloodHelpNY\\n55 Broad Street\\n10th Floor\\nNew York, NY 10004\\n\\nYou can also scan and email the form and supporting documents to info@floodhelpny.org.',
    zone: 'bwv-flood-applicants'
  },
  {
    question: 'What do the FEMA flood zones mean?',
    answer:
      'The FEMA flood zones are geographic areas characterized by their risk of flooding. For example, high-risk flood zones have a 1% chance of being flooded each year.',
    zone: 'understanding-flood-insurance profile'
  },
  {
    question: 'What documents should I bring to my counseling session?',
    answer:
      'To help your counselor review your flood insurance and the available mitigations options, we ask that you bring your flood insurance declaration page (if applicable), old elevation certificate (if applicable), and a mortgage statement or other document showing your home’s current mortgage status.',
    zone: 'rtapp-status-CounselingScheduled'
  },
  {
    question: 'What does BFE mean?',
    answer:
      'BFE stands for “Base Flood Elevation.” Only properties in high-risk flood zones have a BFE, which reflects the height (in feet) above sea level that flood water is projected to rise in a “100-year” storm — a storm that has a 1% per year chance of occurring. The rate for each $100 of coverage is based on how far above or below the BFE your home’s elevation is. Rates for homes where the lowest floor is at or above the BFE are inexpensive.',
    zone: 'understanding-flood-insurance profile'
  },
  {
    question: 'What does each Home Resiliency Audit team member do?',
    answer:
      'Your plumber will install your backwater valve, return the work area to pre-construction conditions, and show you how to maintain your backwater valve. Your inspector will do a final inspection to make sure all work is done correctly and go through a closeout checklist with you and your plumber.',
    zone: 'rtapp-status-MeetYourTeam'
  },
  {
    question: 'What does flood insurance cover?',
    answer:
      'There are two kinds of coverage: building and contents. You have the option to select separate coverage amounts and deductibles for each. <a href="/en/understanding-flood-insurance">Learn more on our resource page.</a>',
    zone: 'rtapp-preapp-flood-insurance'
  },
  {
    question: 'What does my technical report include?',
    answer:
      'Your technical report includes pre- and post-construction photos; a sketch showing the location of your valve relative to permanent items in your home or on your property; product data sheets on your valve from the manufacturer; operation and maintenance instructions from your valve’s manufacturer; and copies of permits obtained for your installation, if applicable.',
    zone: 'rtapp-status-Complete'
  },
  {
    question: 'What does prequalified for the program mean?',
    answer:
      'It means you meet the minimum requirements for the program, such as having a home in one of the eligible neighborhoods. However, to further determine your eligibility, you will need to submit a full application.',
    zone: 'rtapp-status-IntakeComplete'
  },
  {
    question: 'What does the feasibility review stage of the program involve?',
    answer:
      'Your feasibility review will be similar to your first home assessment, but a little shorter! The engineer and accompanying staff will need to take some photos and measurements of your home to determine whether it would benefit from a backwater valve.',
    zone: 'rtapp-status-FeasibilityReview'
  },
  {
    question: 'What exactly is a sewer backwater valve?',
    answer:
      'Most homes in New York City have a pipe that collects waste from their sinks and toilets and carries it to the city sewer. A sewer backwater valve is a device that is installed on this pipe to reduce the risk of sewage backing up through that pipe and into your home.  It has an internal mechanism called the “flapper,” which  closes to help protect your home from flooding with raw sewage. When the overflow subsides, the change in pressure releases the flapper, allowing wastewater to flow from your home and into the sewer. To learn more, see our resource page.',
    zone: 'rtapp-status-Complete'
  },
  {
    question: 'What happens at a counseling session?',
    answer:
      "The counselor will provide a customized explanation of your property’s flood risk by reviewing the data from the elevation certificate and technical report the engineers have put together. At the end of your counseling session, you will leave with a better understanding of your home's backwater valve eligibility, specific flood risk, insurance cost, and options to reduce future storm damage. ",
    zone: 'rtapp-status-CounselingScheduled  rtapp-status-AuditComplete'
  },
  {
    question:
      "What happens if I am required to purchase flood insurance but I don't do it?",
    answer:
      'If you have a federally-backed mortgage or an SBA loan, your mortgage servicer or SBA will buy the flood insurance and add the cost to your monthly payment. If you received federal disaster assistance and don’t buy insurance, you won’t receive federal disaster aid for home repair or reconstruction or for damaged contents for future storms. (You would still be eligible for other kinds of help, like temporary housing aid).',
    zone: 'rtapp-preapp-flood-insurance  understanding-flood-insurance'
  },
  {
    question: 'What happens if I miss my audit appointment by accident?',
    answer:
      'You may reschedule as long as you submit your request at least three days prior to your appointment. Appointments can be rescheduled only one time. Please call 646-786-0888 during business hours to reschedule.',
    zone: 'rtapp-status'
  },
  {
    question: 'What happens if I miss my counseling appointment by accident?',
    answer:
      'You may reschedule as long as you submit your request at least three days prior to your appointment. Appointments can be rescheduled only one time. Please call 646-786-0888 during business hours to reschedule.',
    zone: 'rtapp-status'
  },
  {
    question:
      'What happens if the estimated flood insurance rate calculated here is different than my current rate?',
    answer:
      'If the estimated rate calculated is lower than your current rate, you could be paying too much for flood insurance. Contact your insurance broker for a review of your policy. Go through all the information to make sure it is correct. Check that the property is described correctly, that it’s listed as your primary residence (if it is), and that the flood zone and BFE are correct.',
    zone: 'rate-estimate'
  },
  {
    question:
      "What happens if there’s another storm and I don't have flood insurance?",
    answer:
      'If you are required to have flood insurance and don’t purchase it, then you will not be eligible for federal disaster relief for damage to your building or contents in the case of a future storm.  You may still be eligible for help for other purposes, like temporary housing.',
    zone: 'profile'
  },
  {
    question:
      'What if I am unable to verify my information for the application?',
    answer:
      'If you can’t provide exactly what is being asked, contact our support team. You can call us during normal business hours at 646-786-0888.',
    zone: 'rtapp-self-certify'
  },
  {
    question: "What if I can't afford flood insurance?",
    answer:
      'See if you can make it affordable by reducing the amount of coverage or increasing your deductible. Explore whether there are affordable changes you can make to your house that could reduce the cost, like filling a crawlspace. Contact your elected officials to see if there are programs to help pay for changes to your home that would reduce cost, or if there are volunteer groups doing that.',
    zone: 'programs-and-benefits  resiliency  profile'
  },
  {
    question:
      "What if I can't afford for my flood insurance premium to increase?",
    answer:
      'Maybe you can reduce your coverage or a get a higher deductible. Some coverage is better than no coverage. If you have a mortgage in a high-risk zone, your options may be limited. Try exploring changes you can make to your house to reduce the premium, like filling in a crawlspace or basement and installing flood vents.',
    zone: 'programs-and-benefits resiliency'
  },
  {
    question: "What if I don't receive an email from FloodHelpNY? ",
    answer:
      'Please check your spam folder and add info@floodhelpny.org to your contacts. You can also call our Helpline at 646-786-0888 with any questions.',
    zone: 'rtapp-status'
  },
  {
    question:
      'What if I got a lot of different payments for Sandy? Can I still qualify for a Preferred Risk Policy?',
    answer: 'Yes, as long as no one payment exceeded $1,000.',
    zone: 'understanding-flood-insurance'
  },
  {
    question:
      'What if I have tenants living in the first floor of my home but want to abandon the first floor as a mitigation option?',
    answer: 'The space will no longer be habitable.',
    zone: 'mitigation-abandon_first_floor'
  },
  {
    question:
      'What if I live in an attached or semi-attached house and am interested in home elevation?',
    answer:
      'It’s not technically possible or cost effective to elevate an attached home.',
    zone: 'mitigation-elevate'
  },
  {
    question:
      'What if I miss the appointment to assess my home? Can I reschedule?',
    answer:
      'If you need to reschedule your appointment, please contact us right away at (646) 786-0888.\\nPlease note that we can only reschedule your appointment if you give us at least three days’ notice, and we can only reschedule your revisit once.',
    zone: 'rtapp-status-FeasibilityReview'
  },
  {
    question:
      'What if I need to change my scheduled Home Resiliency Audit appointment?',
    answer:
      'You may reschedule as long as you submit your request at least three days prior to your appointment. Appointments can be rescheduled only one time. Please call 646-786-0888 during business hours to reschedule.',
    zone: 'rtapp-status'
  },
  {
    question: 'What if I need to change my scheduled counseling appointment?',
    answer:
      'You may reschedule as long as you submit your request at least three days prior to your appointment. Appointments can be rescheduled only one time. Please call 646-786-0888 during business hours to reschedule.',
    zone: 'rtapp-status'
  },
  {
    question:
      'What if I own or am living in a multifamily building but still want a Home Resiliency Audit?',
    answer:
      'Unfortunately, you are not eligible for a Home Resiliency Audit. But you can still check out your <a href="https://www.floodhelpny.org">flood risk.</a>',
    zone: 'rtapp-preapp-number-of-units rtapp-landing   profile'
  },
  {
    question: 'What if I still have questions about my resiliency report?',
    answer:
      'Your counselor will answer your questions about your report or help you get answers.',
    zone: 'rtapp-status'
  },
  {
    question: 'What if I’m a renter and want to apply to the program?',
    answer:
      'This program is only available to building owners. You may share this program with your landlord and ask them to apply.',
    zone: 'rtapp-preapp-homeowner'
  },
  {
    question:
      'What if I’m looking to buy a home but want to apply to the program?',
    answer: 'This program is only open to current owners.',
    zone: 'rtapp-preapp-homeowner'
  },
  {
    question:
      'What if my building type isn’t listed on the pre-application form?',
    answer: 'Pick the building that you think most closely matches yours.',
    zone: 'rate-calculator-building-type'
  },
  {
    question: 'What information is on the elevation certificate?',
    answer:
      'Three key data fields on the elevation certificate are the building type and characteristics, the lowest floor, and the location of the property. These factors all play important roles in determining your flood insurance premium.',
    zone: 'elevation-certificates'
  },
  {
    question: 'What is "grandfathering" in flood insurance?',
    answer:
      'When the new flood maps are adopted and your home is placed into a higher risk flood zone or the BFE for your property increases, you can save money under the grandfathering rule. If you qualify, grandfathering allows you to lock in the previous flood zone or BFE for insurance rating purposes. There are two kinds of grandfathering: “continuous coverage” and “built in compliance.” Only homes built after November 1983 qualify for “built in compliance.”',
    zone: 'understanding-flood-insurance profile'
  },
  {
    question: 'What is “severe repetitive loss”?',
    answer:
      'If you or a prior owner has received at least two flood insurance claim payments and the combined total of those payments is more than the building’s market value, you have a “severe repetitive loss” building.',
    zone: 'rate-calculator-severe-repetitive-loss'
  },
  {
    question: 'What is a “subgrade crawl space”?',
    answer:
      'A space that is 1) below ground on all 4 sides, and 2) no deeper than 2 feet below ground anywhere, and 3) no taller than about 4 feet floor-to-ceiling. If it is taller than 4 feet or deeper than 2 feet below ground, it is a “basement” for flood insurance purposes.',
    zone: 'rate-calculator-building-feature'
  },
  {
    question: 'What is a Preferred Risk flood insurance policy?',
    answer:
      'Preferred Risk is subsidized policy only available to moderate- and low-risk flood zones (Zone X or not in a flood zone) with limited claims history. The average cost is approximately $500 annually.',
    zone: 'understanding-flood-insurance  profile'
  },
  {
    question: 'What is a sewer backwater valve?',
    answer:
      'Most homes in New York City have a pipe that collects waste from the sinks and toilets in your home and carries it to the city sewer. A sewer backwater valve is a device that is installed on this pipe to reduce the risk of sewage backing up through that pipe and into your home, especially during heavy rain. <a href="https://www.floodhelpny.org/en/mitigation/backwater_valve">Learn more about backwater valves.</a>',
    zone: 'rtapp-preapp-backwater-exist   rtapp-preapp-sewage'
  },
  {
    question: 'What is a sewer backwater valve?',
    answer:
      'Most homes in New York City have a pipe that collects waste from the sinks and toilets in your home and carries it to the city sewer. A sewer backwater valve is a device that is installed in this pipe to reduce the risk of sewage backing up through that pipe and into your home, especially during a heavy rain event.\\n',
    zone: 'bwv-flood-applicants'
  },
  {
    question: 'What is considered “damage” to the program?',
    answer:
      'We are asking whether your home was damaged by wind, rain, or flood as a direct result of Hurricane Sandy. In general, if the NYC Department of Buildings tagged your home with a yellow or red placard, or if you received FEMA assistance, claimed insurance funds, or received charitable help with repairs due to Sandy, we think your home was damaged.',
    zone: 'rtapp-preapp-sandy-damage'
  },
  {
    question: 'What is considered “flood damage” to the program?',
    answer:
      'Damage caused by overflow or a body of water like the ocean or a lake, river or stream. Flooding from a burst pipe doesn’t count. A sewer back-up normally isn’t flood damage, unless it is caused by flooding.',
    zone:
      'rate-calculator-severe-repetitive-loss  rate-calculator-preferred-risk-rate'
  },
  {
    question: 'What is considered annual income to the program?',
    answer:
      "<ul><li>- Amount before payroll deductions of wages, salaries, overtime pay, commissions, fees, tips, bonuses, and Armed Forces pay</li><li>- Net income from operation of business or profession</li><li>- Interests, dividends, and other net income of any kind from real or personal property</li><li>- Periodic amounts received from Social Security, annuities, insurance policies, retirement funds, pensions, disability benefits, death benefits, child support, and alimony</li><li>- Rent payments received from tenants</li><li>- Payments in lieu of earnings (i.e. disability/unemployment compensation, workers' comp., severance)</li></ul>",
    zone: 'rtapp-status-FullApplication'
  },
  {
    question: 'What is covered by "contents" insurance?',
    answer:
      'Contents insurance covers damage to personal property caused by flooding. The only belongings covered in your basement are your washer, dryer and freezer. (Your furnace, boiler, water heater, and air conditioner are covered by your “building” coverage). When a flood is coming, move your belongings out of your basement. It’s also a good idea to take pictures of everything before a flood comes, so you can provide proof to the insurer.',
    zone: 'understanding-flood-insurance  profile'
  },
  {
    question: 'What is freeboard?',
    answer:
      'Freeboard is additional elevation above the Base Flood Elevation, and ensures that construction is at a safer elevation from future flooding. In NYC, this means adding two additional fees above the BFE. Besides increasing safety from floods, freeboard can lead to lower insurance premiums that can help in recouping construction costs.',
    zone: 'mitigation-flood_vents'
  },
  {
    question: 'What is plumbing backup?\\n',
    answer:
      'All the wastewater (or sewage) that goes down your drains and toilets is carried away from your house toward the city’s sewer by pipes. Plumbing backup occurs when this wastewater is unable to move through the pipe(s) and backs up into your house. This can occur for a number of reasons.\\n',
    zone: 'bwv-flood-applicants'
  },
  {
    question: 'What is sewer overflow?',
    answer:
      'Sewers are designed to be able to hold and convey a certain amount of wastewater and sewage. Sometimes, during unusually heavy rains or storm surges, sewers can become full beyond their capacity. When this happens, sewage can back up into your home through the pipe that connects your home to the city sewer.',
    zone: 'mitigation-backwater_valve'
  },
  {
    question:
      'What is significant about 1983 when it comes to flood insurance?',
    answer:
      'New York City adopted its first FEMA Flood Insurance Rate Map on November 16, 1983, and added elevation requirements to the building code for properties built in a flood zone. Since there were no flood-protection requirements in the building code before then, properties built before then are eligible for a lower rate. But Congress later ordered FEMA to phase out that lower rate, called the “pre-FIRM subsidized” rate.',
    zone: 'rate-calculator-pre-firm'
  },
  {
    question: 'What is the average cost for an elevation certificate?',
    answer:
      'The cost can range from $500 to more than $1,000. Try to get price quotes from at least two surveyors for comparison.',
    zone: 'elevation-certificates'
  },
  {
    question:
      'What is the average cost of installing a backwater valve on my own?',
    answer:
      'It depends on a number of factors, including the layout of your sewer pipes, the depth and location of the pipe that connects your home to the sewer, if there are environmental hazards (e.g., lead paint) in the area where the valve will be installed, what type of valve is installed, etc. The cost can be as little as $600 or more than $5,000.',
    zone: 'rtapp-status-Withdrawn   rtapp-status-Ineligible'
  },
  {
    question: 'What is the city doing to help the overall sewage problem?',
    answer:
      'This is a larger question that we can’t really speak to since we don’t work for the City. NYCDEP has several ongoing projects to try to address issues of combined sewer overflow. Interested people can visit DEP’s website to get more information on these programs. One initiative is the Green Infrastructure Program, which involves the construction of engineered bioswales on the sides of roads that will capture the first inch of stormwater runoff from rainstorms and keep it out of the sewers.',
    zone: 'bwv-flood-applicants'
  },
  {
    question:
      'What is the difference between a basement and a crawl space in a home? Why does it matter?',
    answer:
      'For the purposes of flood insurance, a basement is lower than ground level on all four sides, and is taller than 5 feet. If the space is less than 5 feet high, it is a crawlspace. It matters because the rates may be different.',
    zone: 'mitigation-fill_in_basement'
  },
  {
    question:
      'What is the Homeowner Flood Insurance Affordability Act (HFIAA)?',
    answer:
      'The Homeowner Flood Insurance Affordability Act of 2014 slowed down or reversed some of the changes to the National Flood Insurance Program made by the Biggert-Waters Act of 2012. HFIAA restored “grandfathering” and slowed the phase-out of other premium subsidies by capping the premium increases at 18% per year for most properties. Some properties, such as commercial properties or severely damaged properties, can still see an increase of 25% per year.',
    zone: 'understanding-flood-insurance'
  },
  {
    question: 'What is the Homeowner Grant Agreement (HGA)?',
    answer:
      'The Homeowner Grant Agreement allows the Center, as the program administrator, to award a portion of federal disaster recovery funds to eligible homeowner(s) and requires that the homeowner(s) agree to all of the terms and conditions detailed in the document.',
    zone: 'rtapp-status-GrantAgreement'
  },
  {
    question: 'What is the purpose of an elevation certificate?',
    answer:
      'An elevation certificate is an important flood insurance document that provides information about the flood risk and flood resilience of a property. The elevation certificate can be used to calculate your flood insurance premium.',
    zone: 'elevation-certificates'
  },
  {
    question: 'What is this program, and what is it all about?',
    answer:
      'If you’re receiving this form, your landlord has enrolled in the FloodHelpNY Home Resiliency Audit program, aimed at strengthening New York’s coastal communities by providing free services to eligible homeowners and their tenants in certain coastal neighborhoods. To learn more, please visit FloodHelpNY.org. As part of this program, your landlord may be eligible to receive a free sewer backwater valve. A sewer backwater valve is a device that is installed on the pipe that connects a home’s plumbing fixtures to the city’s sewer to reduce the risk of sewage backup into the home during a flood event such as heavy rain or storm surge.',
    zone: 'bwv-flood-applicants'
  },
  {
    question: 'What critical equipment in my home may be at risk?',
    answer:
      'The following equipment may be at risk: electrical panels and wiring, mechanical systems such as HVAC and ductwork, condensing units, heating systems, water heaters, and laundry equipment.',
    zone: 'mitigation-elevate_mechanicals'
  },
  {
    question: 'What other flood resiliency resources are available in my area?',
    answer:
      'If you would like to get a backwater valve on your own, you can consult this guide for assistance.',
    zone: 'rtapp-status-Withdrawn'
  },
  {
    question: 'What qualifies as a basement in the program?',
    answer:
      'Any part of your house that is below ground on all four sides (even a few inches below ground) and is taller than about 5 feet floor-to-ceiling. If the floor is at ground level or above ground level on one side, it is not a basement.',
    zone: 'rate-calculator-basement'
  },
  {
    question: 'What qualifies as a primary residence in the program?',
    answer:
      'A primary residence is the home that a resident ordinarily lives in most of the time. A home that is occupied by tenants year-round is also a primary residence.',
    zone: 'rtapp-preapp-primary-residence'
  },
  {
    question: 'What qualifies as a residential unit in the program?',
    answer:
      'A residential unit is a space reserved largely for dwelling, not for commercial purposes.',
    zone:
      'rate-calculator-number-of-residential-units  rtapp-preapp-number-of-units'
  },
  {
    question: 'What should I submit as proof of income for my application?',
    answer:
      'Attach proof of income for all household members over the age of 18 (ideally this is a signed IRS Form 1040). If unavailable, we will consider the last three consecutive months of pay stubs or recent benefits statements; we may also approve alternative documentation.',
    zone: 'rtapp-status-FullApplication'
  },
  {
    question: 'Where can I report a sewage problem? ',
    answer: 'Call 311',
    zone: 'bwv-flood-applicants'
  },
  {
    question: "What's the typical timeframe that water will be shut off for?",
    answer:
      'This will depend on the home. We’ll let the homeowner know as soon as we can. Homeowners will be unable to put water down their drains or flush their toilet while the plumber is cutting into their sewer pipe and installing the new valve. It will not necessarily be the entire time of the installation since the installation is likely to include other work such as patching the floor.  It could be as little as 8 hours.',
    zone: 'bwv-flood-applicants'
  },
  {
    question:
      'What’s a Tri-Party Agreement and is there a sample version I can review in advance of the meeting?',
    answer:
      'A sample version of the Tri-Party Agreement will be included in your kick-off meeting date and time confirmation email, and can be downloaded here.',
    zone: 'rtapp-status-MeetYourTeam'
  },
  {
    question: 'What’s an “enclosure” in a home?',
    answer:
      'If you have an elevated home, this is the area below your living quarters that is enclosed by walls (partly or fully). It could be a garage or a space you use for storage only, not a living space.',
    zone: 'rate-calculator-content-location   rate-calculator-building-feature'
  },
  {
    question: 'What’s an elevation certificate?',
    answer:
      'An elevation certificate is an important flood insurance document that describes the elevation above sea level of each story of your home or building. It can help calculate your flood insurance rated correctly and also help you evaluate your resiliency options.',
    zone:
      'rtapp-landing  rtapp-preapp-elevation-certificate rtapp-multifamily-landing'
  },
  {
    question: 'What’s FEMA?',
    answer:
      'The Federal Emergency Management Agency. FEMA runs the National Flood Insurance Program (NFIP) and also provides emergency aid after a disaster.',
    zone: 'understanding-flood-insurance'
  },
  {
    question: 'When are the new flood maps going into effect?',
    answer:
      'A new flood map is currently being deliberated, but there is no definitive answer as to when it will come out or be implemented.  FEMA submitted an updated preliminary map from their 1983 baseline in 2013, significantly expanding high-risk flood zones in NYC, but is under revision after the city of New York fought implementing it as it was. This map now acts as an advisory map for zoning and new construction requirements.',
    zone: 'bwv-flood-applicants'
  },
  {
    question:
      'When will the homeowner know the length of time needed to install the backwater valve/not use water?',
    answer:
      'They’ll know before signing the Tri-Party Agreement, which is the agreement signed by the plumber, the Center, and the homeowner prior to installing the valve.',
    zone: 'bwv-flood-applicants'
  },
  {
    question: 'When will I hear back on the status of my applications?',
    answer:
      'You should receive a confirmation email once you submit your intake form, you will then hear back regarding next steps within a week of submitting your application.',
    zone: 'bwv-flood-applicants'
  },
  {
    question: 'When will I hear back from the program about my application?',
    answer:
      'After your application is submitted, it may take up to three weeks to hear back. You can always check your status through the link in your email.',
    zone: 'rtapp-preapp-review'
  },
  {
    question: 'What will happen when my application is approved?',
    answer:
      'Once you’re approved to receive services, we will contact you within two business days to schedule your Home Resiliency Audit at a time that is convenient to you. Your status will always be up to date on this page or you call call us during normal business hours at 646-786-0888 with questions about your application.',
    zone: 'rtapp-status'
  },
  {
    question: 'Where can I find a contractor to raise my mechanical equipment?',
    answer:
      'The Department of Building <a target="_blank"href="http://www1.nyc.gov/site/buildings/homeowner/homeowner.page">can give you advice</a> on how to find designers and contractors who have experience working in flood zones.',
    zone: 'mitigation-elevate_mechanicals'
  },
  {
    question: 'Where can I find a contractor to wet floodproof my home?',
    answer:
      'You should consult a well-qualified engineer or architect before installing vents. The NYC Department of Buildings can give you advice on selecting a designer and contractors with <a target="_blank" href="http://www1.nyc.gov/site/buildings/homeowner/homeowner.page">experience working with homeowners</a>. The DOB also hosts weekly information sessions with homeowners where New Yorkers can learn about construction standards and how to assess contractors.',
    zone: 'mitigation-flood_vents'
  },
  {
    question:
      'Where can I find out if my neighborhood reported instances of sewage backup?',
    answer: '311 keeps track of sewage backup complaints.',
    zone: 'bwv-flood-applicants'
  },
  {
    question: 'Where can I provide feedback on the program?',
    answer:
      'Please check your email after your consultation for a link to provide feedback.',
    zone: 'rtapp-status'
  },
  {
    question: 'Where will the resiliency counseling session take place?',
    answer:
      'The consultation will take place in an office in your neighborhood. Please check your email for details or call our Homeowner Hotline at 646-786-0888.',
    zone: 'rtapp-status'
  },
  {
    question: 'Which mitigation options will actually lower my insurance rate?',
    answer:
      'As of now, there are only four measures that have an effect on insurance premiums: home elevation, filling in a basement or crawl space and installing flood vents, abandoning your first floor, and raising mechanical equipment out of the basement. The last option provides the smallest insurance reduction of the three, but it can save you a great deal of money in the event of a flood.',
    zone: 'programs-and-benefits  resiliency  profile'
  },
  {
    question:
      'Which questions should I ask when looking for a plumber to install the backwater valve?',
    answer:
      'Ask for their license, experience in NYC or your neighborhood, any certifications, and references from similar projects. Try to get quotes from three plumbers for comparison.',
    zone: 'mitigation-backwater_valve'
  },
  {
    question: 'Who are the home resiliency auditors? Are they vetted?',
    answer:
      'Auditors are qualified design professionals backed by licensed engineers who have been selected through a competitive recruitment process.',
    zone: 'rtapp-status'
  },
  {
    question: "Who are the program's counselors?",
    answer:
      'Counselors are legal or housing professionals from non-profit community-based organizations with experience serving homeowners in New York’s coastal communities. Counselors are trained in resiliency topics and flood insurance.',
    zone: 'rtapp-landing  rtapp-status'
  },
  {
    question: "Who are the program's counselors?",
    answer:
      'Certified housing counselors with over five years of experience working with coastal communities.',
    zone: 'rtapp-status-CounselingScheduled   rtapp-status-AuditComplete'
  },
  {
    question: 'Who can install a backwater valve?',
    answer:
      'A backwater valve should only be installed by a licensed plumber who has completed other backwater valve installations in your neighborhood. You can ask for references!',
    zone: 'mitigation-backwater_valve'
  },
  {
    question: 'Who counts as a "Household Member" in a program application?',
    answer:
      'INCLUDES: related/unrelated people living in the house; anyone temporarily absent from school or work; anyone incarcerated for a short period of time but who will return to the house; and active military members who will return to the house. Shared-Custody children should be listed if they spend at least 50% of their time with the household. MAY INCLUDE: Applicant should determine which household member(s) are permanently absent. Examples can be an elderly member who has gone to live in a nursing home or an adult student living away from home. DOES NOT INCLUDE: foster children, legal kinship guardians, foster adults, live-in aides, live-in aides’ children',
    zone: 'rtapp-status-FullApplication'
  },
  {
    question: 'Who is a "Co-Applicant" in a program application?',
    answer:
      'All individuals who are listed on the deed for the property and who live at the property MUST be listed as a Co-Applicant. Each Co-Applicant is required to sign where requested in this Full Application, the Homeowner Grant Agreement, the Tri-Party Agreement, and any other agreements that the applicant must sign prior to receiving services under the program.',
    zone: 'rtapp-status-FullApplication'
  },
  {
    question: 'Who is considered the “Applicant” in a program application?',
    answer:
      'The Applicant must be listed on the deed, should be 18+, and should be designated as the head of the household (HOH) for purposes of determining income eligibility. HOH must be able to sign agreements on behalf of household.',
    zone: 'rtapp-status-FullApplication'
  },
  {
    question: "Who is providing the program's services?",
    answer:
      'The non-profit Center for NYC Neighborhoods is working with multiple partners to offer these services. Learn more about the Center and its partners <a target="_blank" href="/en/about">here</a>.',
    zone: 'rtapp-landing'
  },
  {
    question:
      "Who is providing the program's services for multifamily buildings?",
    answer:
      'The non-profit Center for NYC Neighborhoods is working with multiple partners to offer these services. Enterprise Community Partners, Inc. will be supporting the Multifamily owners. Learn more about the Center <a target="_blank" href="http://cnycn.org/">here</a> and Enterprise Community Partners <a target="_blank" href="http://www.enterprisecommunity.com">here</a>.',
    zone: 'rtapp-multifamily-landing'
  },
  {
    question: 'Who is responsible for maintaining the backwater valve?',
    answer:
      'You’ll be responsible for maintaining your sewer backwater valve after closeout. The instructions are in your technical report.',
    zone: 'rtapp-status-Complete'
  },
  {
    question: 'Who is this program funded by?',
    answer:
      'This program is funded by the New York Governor’s Office of Storm Recovery as part of the NY Rising Community Reconstruction Program.',
    zone: 'rtapp-multifamily-landing. rtapp-landing'
  },
  {
    question: 'Who runs FloodHelpNY?',
    answer:
      'FloodHelpNY is made possible by funding from the New York State Governor’s Office of Storm Recovery through a federal Community Development Block Grant for Disaster Recovery from the U.S. Department of Housing and Urban Development. The program is administered by the Center for NYC  Neighborhoods, a non-profit organization committed to promoting and protecting affordable homeownership in New York so that middle– and working-class families are able to build strong, thriving communities. Learn more about the Center at cnycn.org.',
    zone: 'bwv-flood-applicants'
  },
  {
    question: 'Who signs the Homeowner Grant Agreement?',
    answer:
      'All individuals listed on the deed must sign the Homeowner Grant Agreement, even if they are not listed on the application as the applicant or co-applicant.',
    zone: 'rtapp-status-GrantAgreement'
  },
  {
    question:
      'Who should I contact to ask questions about my resiliency report so far?',
    answer:
      'Feel free to call at  (646) 786-0888 or email us info@floodhelp.org with any questions.',
    zone: 'rtapp-status-Complete'
  },
  {
    question: 'Who should I contact if I have questions about the program?',
    answer:
      'Feel free to call the Center’s Homeowner Hub at (646) 786-0888 at any time. Inquires about installation appointment will be directed the inspector if their kick-off meeting has already taken place.',
    zone:
      'rtapp-status-InstallationHasBeenScheduled. rtapp-status-InstallationToBeScheduled'
  },
  {
    question: 'Why am I required to have flood insurance? Who requires it?',
    answer:
      'Federal law requires you to have flood insurance if your home is in a high-risk flood zone (AE, AO or VE) and you have a federally-backed mortgage. (Almost all mortgages are federally backed). Also, if you have received federal disaster assistance for repairs to your building or for lost contents, federal law requires you to have flood insurance. If you don’t buy it, you will not qualify for assistance.',
    zone: 'understanding-flood-insurance'
  },
  {
    question: 'Why are flood insurance premiums increasing?',
    answer:
      'Subsidies are being phased out and the new FEMA Flood Insurance Rate Map (FIRM) will increase the number of homes in high-risk flood zones.',
    zone: 'understanding-flood-insurance'
  },
  {
    question:
      'Why are people concerned about the rising cost of flood insurance?',
    answer:
      'Rising flood insurance costs may cause financial hardship for working- and middle-class families living in the high-risk flood zone, which could put them at greater risk of foreclosure or displacement. To learn more, read the full “Rising Tides, Rising Costs” report by the Center for NYC Neighborhoods <a target="_blank" href="https://cnycn.atavist.com/risingtides">here</a>.',
    zone: 'programs-and-benefits'
  },
  {
    question: 'Why are the flood zone maps changing?',
    answer:
      'FEMA periodically updates the maps that it creates for flood hazard zones across the country. The current maps for New York City were adopted in 1983 and do not accurately reflect today’s risk of flooding.',
    zone: 'understanding-flood-insurance. profile'
  },
  {
    question: 'Why are you asking me about my income?',
    answer:
      'We ask for income for purposes of prioritizing service. Households at all income levels are welcome to apply for an assessment of their home’s vulnerability to flooding. However, we will automatically serve low- to moderate-income households (up to 80% of Area Median Income for the New York City area). Applications that fall outside of this range will be prioritized and reviewed at the end of each three-week period.',
    zone: 'rtapp-preapp-income'
  },
  {
    question: 'Why are you asking me if my home has been damaged before?',
    answer:
      'We only ask so that we can prioritize service. Homes with previous damage will receive priority service. Applications without previous damage are still welcome to apply and will be considered.',
    zone: 'rtapp-preapp-sandy-damage'
  },
  {
    question: 'Why are you asking me about primary residency?',
    answer:
      'To be eligible for the program, the property must have been your primary residence during Hurricane Sandy',
    zone: 'rtapp-preapp-sandy-ownership'
  },
  {
    question:
      'Why did I get different flood insurance rates from different insurance agents?',
    answer:
      'The National Flood Insurance Program sets flood insurance rates, and insurance brokers can’t change them. The only reason you would get different quotes from different agents is because one or both of them made a mistake in how they described your property or your zone.',
    zone: 'programs-and-benefits'
  },
  {
    question: 'Why didn’t I qualify for the program?',
    answer:
      'There are many reasons why this can happen: Your home will not benefit from a sewer backwater valve; you do not have flood insurance and you are in an AE flood zone; you do not have homeowners insurance; you did not own your home during Hurricane Sandy; and other factors. Have questions? Call us at (646) 786-0898 or email us at info@floodhelpny.org.',
    zone: 'rtapp-status-Ineligible'
  },
  {
    question:
      'Why do I have to provide more information to apply to the program?',
    answer:
      'We may ask you for more information if we can’t tell based on your pre-application whether this program is available to you. We also randomly ask for certain kinds of information so we can keep the process fair and simple for everyone.',
    zone: 'rtapp-self-certify'
  },
  {
    question:
      'Why do I have to submit income information if this is a free program?',
    answer:
      'Our funding guidances require that we confirm household income; this information is used to determine your eligibility. At this time, only low-to-moderate-income households will be eligible (below 80% AMI).',
    zone: 'bwv-flood-applicants'
  },
  {
    question:
      'Why do I need to fill out a form with income information to apply for the program?',
    answer:
      'FloodHelpNY strives to serve moderate- and low-income homeowners and their tenants. We are required to obtain household income information, including income information of tenants in 2-4 unit buildings, so that we can make sure we are only serving to lower-income households. This is required by the U.S. Department of Housing and Urban Development and the New York State Governor’s Office of Storm Recovery (GOSR), which fund the program.',
    zone: 'bwv-flood-applicants'
  },
  {
    question:
      'Why do you need my phone number and email address for the program application?',
    answer:
      'We need your phone number and email address to contact you about updates to your application.',
    zone: 'rtapp-preapp-contact-info'
  },
  {
    question:
      'Why do you need to know if I have a valve when I apply to the program?',
    answer:
      'We’re trying to determine whether you’re eligible for the installation of a free sewer backwater valve at your home.',
    zone:
      'bwv-flood-applicants bwv-flood-applicants   rtapp-preapp-backwater-exist. rtapp-preapp-backwater-exist'
  },
  {
    question:
      "Why do you need to know if I've had plumbing backup before my Home Resilency Audit?",
    answer:
      'It gives our engineer some background on your situation before they visit your home for your FloodHelpNY Home Resiliency Audit.',
    zone: 'bwv-flood-applicants'
  },
  {
    question: 'Why do you need to know about backwater valve compensation?',
    answer:
      'If property owners have already received assistance to repair their properties from a past program, the Program must review these benefits to confirm that there is no duplication of benefits before Program services are provided.',
    zone: 'rtapp-status-FullApplication'
  },
  {
    question: 'Why do you need to know about Superstorm Sandy compensation?',
    answer:
      'If property owners have already received assistance to repair their properties from a past program, the Program must review these benefits to confirm that there is no duplication of benefits before Program services are provided.',
    zone: 'rtapp-status-FullApplication'
  },
  {
    question:
      "Why does it matter to the program whether my property is connected to the City's sewer? ",
    answer:
      "The program aims to reduce the risk of sewage backup into homes during flooding caused by combined sewer overflow specifically. Combined sewer overflow occurs when the city's sewer becomes full beyond its capacity. Most properties are connected to the city sewer, but there are some properties that might still have septic tanks. If you're not connected to the city sewer but you are experiencing sewage backup in your home, then the cause is not combined sewer overflow, and you should consult with a licensed design professional and/or contractor to determine the cause and remedy the issue.",
    zone: 'rtapp-preapp-sewer'
  },
  {
    question:
      'Why does primary residence matter for eligibility to receive a backwater valve?',
    answer:
      'This program is intended to serve homes that provide housing to New Yorkers throughout the year as opposed to vacation homes.',
    zone: 'rtapp-preapp-primary-residence'
  },
  {
    question:
      'Why does the engineer need to have access to my entire property?',
    answer:
      'The engineer needs to take measurements around your plumbing fixtures, photos of the area where the valve would be installed, and observe the space around your home to determine if installing a valve is feasible. This will help the plumber prepare for the installation by providing a clear picture of the construction area.',
    zone: 'rtapp-status-FeasibilityReview'
  },
  {
    question:
      'Why does the backwater valve program ask for supporting documentation?',
    answer:
      "In order to comply with the program’s federal funding requirements, we confirm every applicant's income, flood insurance, and tenant information. ",
    zone: 'rtapp-status-IntakeComplete'
  },
  {
    question: "Why doesn't homeowners insurance cover floods?",
    answer:
      'The reason Congress created the NFIP in 1968 was because private insurance companies wouldn’t cover damage from flooding. The losses were too expensive.',
    zone: 'resiliency'
  },
  {
    question:
      'Why doesn’t installing a backwater valve change my insurance rate?',
    answer:
      "NFIP is not currently written to allow premium reductions for dry flood proofing mitigation, which backwater valves fall under. For example, elevating your home gives you a discount because you're raising your lowest floor above the BFE. A backwater valve reduces but does not remove the risk of flooding.",
    zone: 'mitigation-backwater_valve'
  },
  {
    question:
      'Why doesn’t installing a sewer backwater valve change my insurance rate?',
    answer:
      'The National Flood Insurance Program is not currently written to allow premium reductions for dry flood proofing mitigation strategies, such as backwater valves. In contrast, wet floodproofing solutions, like elevating your home, result in a flood insurance discount because your lowest floor is raised above the Base Flood Elevation (BFE). A sewer backwater valve may reduce flood risk, but does not remove the risk completely.',
    zone: 'bwv-flood-applicants'
  },
  {
    question:
      'Why doesn’t lowering my flood risk always lower my flood insurance rate?',
    answer:
      "Certain mitigation measures may reduce the damage and the cost of clean up after a flood, they may not actually change the data used to calculate your home's flood risk. ",
    zone: 'understanding-flood-insurance'
  },
  {
    question:
      'Why is a copy of my Homeowner Grant Agreement (HGA) sent to the Governor’s Office of Storm Recovery?',
    answer:
      'Since the program funding is delivered through the New York Rising Community Reconstruction (NYRCR) Program, within the New York State Governor’s Office of Storm Recovery (GOSR), the Governor’s Office of Storm Recovery requires a copy of the agreement.',
    zone: 'rtapp-status-GrantAgreement'
  },
  {
    question: 'Why is the backwater valve application so long?',
    answer:
      "Since this is a federally-funded program, we are required to gather documentation from all homeowners who apply. Don't worry, your information will be stored securely. See our privacy policy. ",
    zone: 'rtapp-status-FullApplication'
  },
  {
    question: 'Why is the Center signing the Tri-Party Agreement?',
    answer:
      'The Tri-Party Agreement is signed by the Homeowner, the Center, and the plumber. It sets the obligations of all three parties to each other and puts the plumber and the homeowner into a direct agreement. You’ll be able to see an executed copy of the Tri-Party Agreement on your status page once all parties have signed it.',
    zone: 'rtapp-status-MeetYourTeam'
  },
  {
    question: 'Why are insurance types relevant? ',
    answer:
      'The Program is required to review past repair benefits to confirm that there is no duplication of benefits.',
    zone: 'rtapp-status-FullApplication'
  },
  {
    question: 'Why isn’t it clear when the new flood maps will go into effect?',
    answer:
      'New York City appealed FEMA’s Preliminary Flood Insurance Rate Map in June 2015, arguing that they overestimated flood risk in the five boroughs. In October 2016, FEMA announced it had accepted the City’s appeal of the FEMA preliminary flood maps and will begin a new process to revise the city’s flood maps. It will take at least three to four years for FEMA to release new revised maps. Learn more <a href="http://www1.nyc.gov/site/floodmaps/index.page" target="_blank">here</a>.',
    zone: 'understanding-flood-insurance   profile'
  },
  {
    question: 'Why should I be concerned about resiliency?',
    answer:
      'When a disaster occurs, a poorly managed staff response can put the safety and well-being of housing residents at risk, and expose housing owners to unnecessary costs, difficulties and potential liability. Affordable housing organizations face unique challenges during emergency events. Unable to easily relocate residents, housing organizations depend on the continuous operation of their buildings, or at least a rapid return to service. To learn more go <a target="_blank" href="https://s3.amazonaws.com/KSPProd/ERC_Upload/0100738.pdf">here</a>.',
    zone: 'rtapp-multifamily-landing'
  },
  {
    question:
      'Why were these neighborhoods chosen for the backwater valve program?',
    answer:
      'The communities that elected to participate and dedicate funds to the Home Resiliency Audit are eligible under this program; all neighborhoods were part of the NY Rising Community Reconstruction Program.',
    zone: 'rtapp-multifamily-landing  rtapp-landing'
  },
  {
    question: 'Why would a change in the BFE change my flood insurance?',
    answer:
      'Most flood insurance policies are rated by comparing the elevation of the home to the Base Flood Elevation In the high-risk flood zone. The lower a home’s elevation is compared to the BFE, the more expensive the flood insurance rate will be. Elevation is measured against sea level, not ground level.',
    zone: 'understanding-flood-insurance'
  },
  {
    question: 'Will a backwater valve lower my flood insurance?',
    answer:
      'No. However, it might be a factor to consider when you are looking at the amount of contents coverage to carry in your flood insurance policy. The backwater valve may have an impact on your homeowner’s insurance premium.',
    zone: 'rtapp-status-FullApplication'
  },
  {
    question:
      'Will getting a sewer backwater valve reduce my flood insurance premiums?',
    answer:
      'Sewer backwater valves have no effect on flood insurance premiums but they can save you hundreds, or even thousands of dollars in damage in the event of a flood.  It can also protect precious items and mementos stored in your basement from damage and keep your basement sewage free.',
    zone: 'bwv-flood-applicants'
  },
  {
    question:
      'Will homeowners in current x to advisory x, or current x to advisory AE be required to carry flood insurance?',
    answer:
      'Only homeowners in current AE will be required to carry flood insurance in order to be eligible.\\n',
    zone: 'bwv-flood-applicants'
  },
  {
    question:
      'Will I need to disclose personal information during the counseling session?',
    answer:
      'Your counselor may ask about your finances and other personal information so that you can get the most out of your home’s assessment. We will not share your personal information without your permission.',
    zone: 'rtapp-status'
  },
  {
    question:
      'Will I need to provide more information for the backwater valve installation?',
    answer:
      'We will need supporting documents to prove the annual income of all individuals in your household over the age of 18.  See the form on the next page for the types of documents we may accept.\\n',
    zone: 'bwv-flood-applicants'
  },
  {
    question: 'Will the backwater valve reduce my homeowners insurance?',
    answer:
      'It depends. Some insurance companies offer homeowners insurance discounts. However, specific coverage for sewage backups often need to be added to your home insurance policy in the form of a sewage backup rider. Such a rider generally costs between $50 to $70 per year and can be as high as $175, depending on the deductible and how much coverage you need. If your homeowners insurance does not offer a discount, you are still taking an important step to prevent future sewer backup damage and the costly repairs that could come with it.',
    zone:
      'rtapp-status-Complete  rtapp-status-Complete   rtapp-status-InstallationPrepare  rtapp-status-InstallationPrepare'
  },
  {
    question:
      'Will the conditions be returned to the way they were pre-backwater valve installation (lawn/floors)?',
    answer:
      "To the extent possible, the plumber will return the work area to pre-construction conditions. There might have to be some changes (for example, there has to be a way to access the valve for maintenance, so there might be an access port to the valve. If it's being installed outside, then replacing any part of a lawn that had to be torn up would be part of the contract.",
    zone: 'bwv-flood-applicants'
  },
  {
    question: 'Will the engineer provide feedback during the audit?',
    answer:
      "Your engineering team will need time to analyze the information gathered at your home to complete your personalized recommendations. Because of this, you will not receive feedback on the spot. But don't worry! Your counselor can answer any questions you have about your report during your session.\\n",
    zone: 'rtapp-status-AuditScheduled'
  },
  {
    question:
      'Will the engineers, plumbers, or inspectors ever contact the homeowner directly about the backwater valve? And if so, when?',
    answer:
      'The inspector will be in touch to schedule the backwater valve installation.  If an applicant has to reschedule their installation, they should contact their inspector.',
    zone: 'bwv-flood-applicants'
  },
  {
    question:
      'Will the results of the feasibility review affect my backwater valve application?',
    answer:
      'It could. During the feasibility review, your engineer will evaluate whether or not your home would benefit from a backwater valve and if the conditions of your home allow for the valve to be installed.  If they determine that your home would not benefit from a backwater valve, or that the conditions of your home do not allow a valve to be installed, you will be ineligible to receive a backwater valve installation.',
    zone: 'rtapp-status-FeasibilityReview'
  },
  {
    question:
      'Will the water be shut off in my home during backwater valve installation? If so, for how long?',
    answer:
      'Yes, the home’s water will need to be shut off during the backwater valve installation. The duration depends on the home. We’ll let the homeowner know as soon as we can. Homeowners will be unable to put water down their drains or flush their toilet while the plumber is cutting into their sewer pipe and installing the new valve. It will not necessarily be the entire time of the installation since the installation is likely to include other work such as patching the floor. It could take as little as eight hours.',
    zone: 'rtapp-status-InstallationHasBeenScheduled'
  },
  {
    question:
      'You say a crawl space is “about” 4 feet high. Can’t you be exact?',
    answer:
      'The exact definition of a crawl space is an area that is no more than 5 feet high measured from the floor of the crawl space to the floor (where the rug lies) of the next story. The 5 feet measurement includes the thickness of the floor above. If we estimate that a floor is about a foot thick, that leaves a floor-to-ceiling height of about 4 feet or less for it to qualify.',
    zone: 'rate-calculator-building-feature'
  },
  {
    question: 'Can I get more coverage than the NFIP offers?',
    answer:
      'Yes, private insurance companies offer excess insurance coverage over the <a href="https://www.fema.gov/flood-insurance" target="_blank" rel="noopener noreferrer">NFIP</a> limits. Call your insurance agent or broker to discuss what options are available to help protect your business from the financial costs of flood damage.',
    zone: 'small-business'
  },
  {
    question: 'Does my business need flood insurance?',
    answer:
      'Damage from flooding can occur at any time even if your business is not located in a high-risk flood area. Whether you rent or own your business space, we recommend that you purchase a flood insurance policy through the <a href="https://www.fema.gov/flood-insurance" target="_blank" rel="noopener noreferrer">NFIP</a>.',
    zone: 'small-business'
  },
  {
    question: 'How long does it take to get flood coverage?',
    answer:
      'There is a 30-day waiting period before your flood insurance policy goes into effect. It is important to purchase a flood insurance policy before a flooding event affects your business.',
    zone: 'small-business'
  },
  {
    question: 'How much flood coverage can I get?',
    answer:
      'A commercial flood insurance policy through <a href="https://www.fema.gov/flood-insurance" target="_blank" rel="noopener noreferrer">NFIP</a> provides up to $500,000 in building coverage and up to $500,000 for content coverage.',
    zone: 'small-business'
  },
  {
    question: 'What should I know about deductibles?',
    answer:
      'Choosing the right deductible is important. A higher deductible will lower your insurance cost, but it will also lower the amount of money you receive if you ever make a claim.',
    zone: 'small-business'
  },
  {
    question: 'Where can I buy flood insurance?',
    answer:
      'You can buy flood insurance by calling your insurance agent or broker. They will be able to help you buy a flood insurance policy directly through the <a href="https://www.fema.gov/flood-insurance" target="_blank" rel="noopener noreferrer">NFIP</a>.',
    zone: 'small-business'
  },
  {
    question:
      'Why should I purchase flood insurance when FEMA offers disaster assistance?',
    answer:
      'FEMA does not offer individual grants to businesses. Without flood insurance, you would need to pay for any damage out of your own pocket or be forced to take out a loan.',
    zone: 'small-business'
  },
  {
    question: 'Will my commercial insurance policy cover flooding?',
    answer:
      'Flooding is typically not covered under a commercial insurance policy or a business owner’s policy.  You would need to purchase a separate flood insurance policy in order to protect your business from the financial costs of flood damage.',
    zone: 'small-business'
  },
  {
    question: 'What does flood insurance cover?',
    answer:
      'Flood insurance helps protect your business from the financial costs of flood damage. Flood insurance can cover the costs of damage to your building and contents caused by flooding.',
    zone: 'small-business'
  },
  {
    question: 'How much will I be reimbursed if my home is damaged?',
    answer:
      'If you own a single-family home, it is important to know that if you insure your home for less than 80% of its replacement cost value (RCV), then you will only receive what’s called the “actual cash value” (ACV) of the flood damage when you make a claim. This means that instead of being able to receive insurance for the cost of repairing your home, you will only receive reimbursements for the depreciated value of whatever structural parts – beams, drywall, floors, etc. – were damaged.',
    zone: 'understanding-flood-insurance'
  },
  {
    question: 'What is the new pricing methodology under Risk Rating 2.0?',
    answer:
      'FEMA has developed a system that can be difficult to understand. Here’s what we know: Using FEMA-sourced data and commercially available third-party data, FEMA will assess the flood risk for each property. They’ll do this by looking at things like distance to a flooding source, potential flood types, building occupancy, construction type, foundation type, ground elevation, first-floor height, number of floors, and prior flood insurance claims. Other important considerations include the cost to rebuild.',
    zone: 'insurance-shopping'
  },
  {
    question: 'Does Risk Rating 2.0 take into account prior claims?',
    answer:
      'Yes, a property’s claim history will impact flood insurance premiums. But, it’s important to know FEMA will not consider prior claims history in the initial rate calculation. Prior claims will only be considered, when (and if) a property submits a new claim after Risk Rating 2.0. goes into effect. When this happens, FEMA will then consider all claims dating back 20 years and update the rating upon annual renewal of the policy.',
    zone: 'insurance-shopping'
  }
];
