import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { borderRadius, colors } from '../styles/variables';

const Wrapper = styled.div`
  margin-top: 20px;
  font-size: 1rem;

  * {
    border-radius: ${borderRadius};
    padding: 10px 15px;
    background-color: #fff;
  }
`;

const Question = styled.div`
  background-color: ${(props) =>
    props.showAnswer ? colors.accent.main : '#fff'};
  color: ${(props) => (props.showAnswer ? '#fff' : colors.accent.main)};
  cursor: pointer;
  transition: all 0.25s cubic-bezier(0.52, 0.01, 0.16, 1);
`;

const Answer = styled.div`
  position: ${(props) => (props.showAnswer ? 'static' : 'absolute')};
  margin-top: 10px;
  transform: ${(props) => props.showAnswer || 'translateX(50px) scaleY(0)'};
  opacity: ${(props) => (props.showAnswer ? '1' : '0')};
  transition: transform 0.25s ease, opacity 0.25s ease -0.1s;
`;

const FaqItem = ({ item }) => {
  const [showAnswer, setshowAnswer] = useState(false);
  return (
    <Wrapper>
      <Question
        onClick={() => setshowAnswer((prevState) => !prevState)}
        showAnswer={showAnswer}
      >
        {item.question}
      </Question>
      <Answer
        dangerouslySetInnerHTML={{ __html: item.answer }}
        showAnswer={showAnswer}
      />
    </Wrapper>
  );
};

FaqItem.propTypes = {
  item: PropTypes.object.isRequired
};

export default FaqItem;
