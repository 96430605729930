export default {
  meta: {
    title:
      'Telechaje widget FloodHelpNY nou an jodi an epi ede vizitè ou yo aprann sou risk pou inondasyon yo an',
    url: 'https://www.floodhelpny.org/ht_HT/widget-information',
    description:
      'Telechaje widget FloodHelpNY nou an jodi an epi ede vizitè ou yo aprann sou risk pou inondasyon yo an'
  },
  header: 'FloodHelpNY Widget',
  subHeader:
    'Telechaje widget FloodHelpNY nou an jodi an epi ede vizitè ou yo aprann sou risk pou inondasyon yo an',
  overview: {
    title: 'Apèsi',
    subtitle: `Telechaje widget FloodHelpNY nou an jodi an epi ede vizitè ou yo aprann sou risk pou inondasyon yo an. Widget FloodHelpNY an ki ka enstale fasilman, ki sekirize an ap bay pwopriyetè kay, lokatè ak pwopriyetè ti biznis aktyèl ak sa k ap vini yon fason transparan pou aprann plis sou risk pou inondasyon yo an. Widget FloodHelpNY lan ofri vizitè yo opòtinite pou:`
  },
  widget: {
    title: 'THE WIDGET',
    aside: {
      description:
        'Widget lan fasil pou enstale e li sekirize. Ranpli fòmilè ki anba pou resevwa kòd anbake an ak enstriksyon yo.'
    }
  },
  maps:
    '<b>Gade kat pou inondasyon aktyèl ak konsiltatif FEMA yo</b> pou pwopriyete ou an nan Vil New York lan',
  reflection:
    '<b>Jwenn yon refleksyon</b> risk pou inondasyon pwopriyete yo an',
  required:
    '<b>Dekouvri si yo blije pran yon asirans kont inondasyon</b> si ou gen yon ipotèk ki jwenn soutyen federal',
  decisions:
    '<b>Pran desizyon eklere</b> apwopo si pou yo achte asirans kont inondasyon',
  request: {
    title: 'MANDE WIDGET LAN',
    description:
      'Lè yo telechaje widget nou an, yo ap mande itilizatè yo pou ranpli yon fòmilè epi soumèt non, non òganizasyon, epi adrès imèl yo. Yo ap mande itilizatè pou dakò ak tèm epi kondisyon nou yo.'
  },
  form: {
    name: 'Non',
    namePlaceholder: 'Non konplè',
    org: 'Òganizasyon',
    orgPlaceholder: 'Òganizasyon',
    email: 'Imèl',
    emailPlaceholder: 'pa egzanp: name@example.com',
    terms: 'Mwen dakò ak',
    termsLink: ' tèm ak kondisyon yo',
    thankYou: 'Mèsi dèske ou aplike, nou ap kontakte ou byento.',
    errors: {
      nameError: 'Non konplè obligatwa',
      orgError: 'Òganizasyon an obligatwa',
      emailError: 'Adrès Imèl obligatwa',
      termsError: 'Aksepte tèm ak kondisyon yo obligatwa',
      submitError:
        'Yon erè te rive lè ou ap soumèt fòmilè an. Tanpri eseye ankò.'
    }
  }
};
