export default {
  whatYouToldUs: '您需要提供的信息',
  property: '房产',
  basicInfo: '基本信息',
  income: '收入：',
  household_size: '家庭规模：',
  contactInfo: '联系方式',
  name: '姓名：',
  email: '电子邮件:',
  homePhone: '家庭电话：'
};
