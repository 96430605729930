export default {
    meta: {
      title: 'Риск наводнений повышается | Новости и информация о наводнениях | FloodHelpNY.org',
      ogTitle: 'Новости и информация о наводнениях | FloodHelpNY.org',
      description:
        'Изменение климата повышает риск наводнений в Нью-Йорке. Прочитайте последние новости о правилах страхования от наводнения, изменении климата',
      url: 'https://www.floodhelpny.org/ru/news',
      imageAlt: 'НовостиFloodHelpNY '
    },
    title: 'Новости',
    subHeading:
      'Получайте новые данные о наводнениях, риске наводнения, и экстремальных погодных условиях, а также полезные советы о том, как защитить вашу собственность и финансы от наводнений.',
    created: 'Опубликовано',
    updated: 'Обновлено',
    noPosts: 'Нет заметок',
    emailSubject: 'Прочитайте эту новостную заметку в FHNY!',
    tweet: 'Читайте дальше в https://www.floodhelpny.org/ru/news',
    search: 'Поиск новостных заметок здесь',
    share: 'ПОДЕЛИТЬСЯ'
  };
  