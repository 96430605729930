import React from 'react';
import styled from 'styled-components';
import FreeBadge from './FreeBadge';
import PrimaryAndAsideContainer from './layout/PrimaryAndAsideContainer';
import { useProvider } from '../helpers/hooks';
import { colors } from '../styles/variables';
import {
  Section,
  SectionIntro,
  SectionDescription
} from './ui/StyledResourcePageElements';

const BadgeWrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ProvidedBy = styled.p`
  color: ${colors.grayScale[3]};
`;

const GOSR = styled.p`
  font-family: GT-Walsheim-Pro-Bold;
`;

const ParametricProgramFunder = () => {
  const provider = useProvider('parametricProgramFunder');

  return (
    <Section>
      <h5>{provider.header}</h5>
      <SectionDescription>{provider.body}</SectionDescription>
    </Section>
  );
};

export default ParametricProgramFunder;
