export default {
  title: 'Did you know...',
  flood: {
    number: '$25,000',
    copy:
      'A few inches of flood water can cause tens of thousands of dollars worth of flood damage.',
    imgAlt: 'Flooded building'
  },
  risk: {
    number: '2 miles',
    copy:
      'Even if you live two miles from the coast, you could be at risk of flooding.',
    imgAlt: 'Map with marker'
  },
  mortgage: {
    number: '1 in 4',
    copy:
      'There’s a one in four chance that your home will be flooded during your 30-year mortgage.',
    imgAlt: 'Chance of storm icon'
  }
};
