import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import RateCalculatorRadioInput from './RateCalculatorRadioInput';
import MachineryInBasementNavigator from '../helpers/calculatorNavigation/MachineryInBasementNavigator';

const RateCalculatorQuestionMachineryInBasement = ({
  provider,
  answers,
  setAnswers,
  setNextQuestion
}) => {
  useEffect(() => {
    if (answers.machineryInBasement) {
      setNextQuestion(MachineryInBasementNavigator.nextComponent(answers));
    }
  }, [answers, setNextQuestion]);

  return (
    <div className="question machinery-in-basement-question">
      <h4>{provider.question}</h4>
      <RateCalculatorRadioInput
        radioKey="machinery-in-basement"
        responseField={provider.responseField}
        answer={answers.machineryInBasement}
        setResponse={(e) =>
          setAnswers((prevAnswers) => ({
            ...prevAnswers,
            machineryInBasement: e.target.value === 'true'
          }))}
      />
    </div>
  );
};

RateCalculatorQuestionMachineryInBasement.propTypes = {
  provider: PropTypes.object.isRequired,
  answers: PropTypes.object.isRequired,
  setAnswers: PropTypes.func.isRequired,
  setNextQuestion: PropTypes.func.isRequired
};

export default RateCalculatorQuestionMachineryInBasement;
