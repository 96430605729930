export default {
  meta: {
    title: 'Your Rate Estimate',
    url: 'https://floodhelpny.org/en/rate',
    description: null,
    image: null,
    imageAlt: null
  },
  profileCalloutCopy: 'We have added some recommendations.',
  noMitigationCopy: "We couldn't find any mitigation options",
  profileCalloutLink: 'View your Resiliency Profile.',
  betaCalloutCopy:
    "As you can tell, figuring out flood insurance estimates is complicated. In fact, nobody in NYC provides a similar tool for the general public to estimate rates. We've worked hard to come up with a tool that produces informative estimates. However, we are releasing this tool as a first version with the knowledge that it is imperfect. With your help, we can improve it for all New Yorkers. If you think the results you are getting are wrong, please email us at ",
  betaCalloutLink: 'info@floodhelpny.org.',

  headerProvider: {
    backToProfileLink: '‹ Back to Profile',
    header: 'Your flood insurance estimate.',
    editAnswersLink: 'Start over',
    copy:
      'We’ll walk you through your potential costs right now and when the maps change.'
  },
  rateCalculatorResultProvider: {
    header: 'Flood Insurance Estimate',
    beta: 'BETA',
    link: 'See your full estimate',
    currentMapStart: 'Current maps ( ',
    currentMapEnd: ' zone)',
    futureMapStart: 'Advisory maps (',
    futureMapEnd: ' zone)',
    assumingMax: '*Assuming max coverage',
    numbersAreEstimates:
      'These numbers are estimates for many reasons. To learn more about them go to our ',
    termsAndConditions: 'Terms & Conditions',
    floodZoneRateProvider: {
      perYear: 'Per year*'
    },
    rateAdjusterProvider: {
      adjustYourCoverage: 'Adjust your coverage ',
      buildingCoverage: 'Building coverage',
      buildingCoverageTooltip:
        'Building coverage insures the building only, not the contents or the yard. It includes some built-in furniture and appliances as well as utility equipment.',
      buildingDeductible: 'Building deductible',
      buildingDeductibleTooltip:
        'This is the amount of money that you will have to pay out of pocket to repair building damage before your flood insurance kicks in.',
      contentCoverage: 'Contents coverage',
      contentCoverageTooltip:
        'Contents coverage insures your belongings inside of your home. Clothes washers and dryers as well as food freezers are considered contents. (Stoves and refrigerators are insured by “building coverage.”)',
      contentDeductible: 'Contents deductible',
      contentDeductibleTooltip:
        'This is the amount of money that you will have to pay out of pocket to repair or replace damaged belongings before your flood insurance kicks in.',
      collapse: 'Collapse'
    }
  },
  prefirmCalloutProvider: {
    copy:
      'Pre-FIRM subsidies are going away. Your future rate may be going up as much as 18% per year!'
  },
  lomaCalloutProvider: {
    header: 'Wait! Your home probably shouldn’t be in a high risk flood zone.',
    body:
      'It looks like your house is on high ground. Get an elevation certificate, and ask your insurance broker to apply for a Letter of Map Amendment (LOMA), which is a request to change the flood map and exclude your property from the flood zone. If your LOMA is successful, you can significantly lower your flood insurance rates.',
    link: 'Learn more'
  }
};
