export default {
    meta: {
      title: 'Программа установки канализационного обратного клапана',
      url: 'https://www.floodhelpny.org/ru/mitigation/backwater-valve',
      description: 'Защитите ваш дом и финансы от наводнений.'
    },
    title: 'Канализационный обратный клапан: что вам нужно знать',
    subtitle:
      'Канализационный обратный клапан предназначен для того, чтобы не допустить проникновение неочищенных сточных вод в ваш дом в случае переполнения канализации.',
    overview: {
      header: 'Обзор',
      copy:
        'Объединенная канализационная система города Нью-Йорк переполняется в среднем раз в неделю. Когда дренажный сток присоединяется к канализации и перегружает пропускную способность канализации, избыточная сточная вода может вытекать обратно через сливные трубы и затекать в дома людей. Противоток в водопроводно-канализационной системе является антисанитарией и может представлять серьезную угрозу для здоровья, и очистка довольно дорогая.'
    },
    howItWorksHeader: 'Как работает клапан',
    howItWorks: {
      paragraph1:
        'Канализационный обратный клапан подсоединяется к вашей водопроводно-канализационной системе и он оснащен внутренним механизмом, который называется "заслонка". При переполнении канализации заслонка закрывается, чтобы помочь защитить ваш дом от заполнения нечистотами.',
      paragraph2:
        'Когда перенаплыв снижается, изменение в давлении отпускает заслонку, позволяя сточным водам течь из вашего дома в канализацию.'
    },
    backwaterValveGifAlt: 'Backwater valve animation',
    affect: {
      header: 'Как это повлияет на вас',
      moneySavedSubheader: 'Вы экономите',
      bigNumber: '$2,000 - $10,000',
      moneySavedCopy:
        'Установка обратного клапана не отразится на том, сколько вы будете платить за страхование от наводнения. Однако, при переполнении канализации наличие установленного клапана может сэкономить вам тысячи долларов, которые вы бы потратили на очистку и восстановление канализации.',
      damageAvoidedHeader: 'Ущерб предотвращен',
      damageAvoidedCopy:
        'Наличие канализационного обратного клапана снижает вероятность противотока в водопроводно-канализационной системе, так что все, что вы храните в подвале останется в безопасности, сухим и незатронутым сточными водами.'
    },
    whatItTakes: {
      header: 'Что потребуется',
      paragraph1:
        'Установка канализационного обратного клапана может занять от 2 до 8 часов, в зависимоти от того, каков будет доступ к сливным трубам в вашем доме.',
      paragraph2:
        'Сантехник также даст вам несколько советов по содержанию в исправности, например как промывать клапан водой, чтобы он не  засорился.',
      cost: '$600–$5,000',
      costCopy:
        'Стоимость установки зависит от расположения ваших сливных труб, а также от того, какой вид канализационного обратного клапана подходит для вашего дома.'
    },
    whatYouCanDoNext: {
      header: 'Что вы можете сделать дальше',
      subheading: 'Позвонить лицензированному сантехнику',
      copy:
        'Если вы готовы к установке канализационного обратного клапана в вашем доме, прежде всего проверьте, покрывает ли ваша страховка недвижимости противоток в водопроводно-канализационной системе. Затем позвоните лицензированному мастеру-сантехнику, договоритесь об инспекции сливных труб вашего дома, и задайте несколько ключевых вопросов (изложенных ниже). Вы также можете подтвердить информацию о лицензии на <a href="http://www.nyc.gov/dob" target="_blank" rel="noreferrer noopener">веб-сайте Управления городскими зданиями города Нью-Йорк.</a>',
      question:
        'Какие вопросы я должен задавать, когда я буду искать сантехника для установки клапана?',
      answer1:
        'Расспросите об их лицензии, опыте в Нью-Йорке или в вашем районе, о любых сертификатах и рекомендациях с аналогичных проектов.',
      answer2: 'Попытайтесь взять расценки у трех сантехников для сравнения.'
    }
  };
  