import getRaceOptions from '../constants/RaceOptions';
import getEthnicityOptions from '../constants/EthnicityOptions';
import getGenderOptions from '../constants/GenderOptions';

const ALLOWED_ATTACHMENTS = 'Attach (PDF, JPEG, PNG)';

export default {
  nextWebstatus: 'ApplicationSubmitted',
  errorTitle: 'Something went wrong when submitting your application.',
  errorBody:
    'Please try submitting again. If the problem persists, contact us at info@floodhelpny.org.',
  tryAgain: 'Try Again',
  loadingText: 'Loading...',
  navCopy: {
    next: 'Review',
    previous: 'Previous',
    submit: 'Submit',
    checkResponses: 'Check that the data you entered is valid before submitting'
  },
  applicant: 'Applicant:',
  address: 'Address:',
  providerQuestions: [
    {
      headline:
        'Please let us know who lives in your household or who may be considered a co-applicant (e.g., anyone listed on the deed):',
      title: 'Additional Applicant',
      buttonText: 'Show FAQ',
      addRow: true,
      addRowText: 'Add Applicant',
      removeRow: false,
      removeRowText: 'Remove Applicant',
      faqZone: 'bwv-additional-applicants',
      responseFields: [
        {
          key: 'co_applicant_type',
          title: 'Other Applicants',
          applicant_type: '',
          show: false,
          default: 'Choose One',
          type: 'select-applicant-type',
          options: [
            { value: 'Co-applicant', display: 'Co-Applicant' },
            { value: 'Household-member', display: 'Household Member' }
          ],
          required: false
        },
        {
          key: 'co_applicant_first_name',
          title: 'First Name',
          applicant_type: 'both',
          show: false,
          placeholder: '',
          type: 'text',
          required: false
        },
        {
          key: 'co_applicant_last_name',
          title: 'Last Name',
          applicant_type: 'both',
          show: false,
          placeholder: '',
          type: 'text',
          required: false
        },
        {
          key: 'household_member_income',
          title: 'Annual Income',
          applicant_type: 'Household-member',
          show: false,
          placeholder: 'e.g. 50000',
          type: 'number',
          required: false
        },
        {
          key: 'household_member_race',
          title: 'Race',
          applicant_type: 'Household-member',
          show: false,
          default: 'Choose One',
          type: 'drop-down',
          options: getRaceOptions('en'),
          required: false
        },
        {
          key: 'household_member_ethnicity',
          title: 'Ethnicity',
          applicant_type: 'Household-member',
          show: false,
          default: 'Choose One',
          type: 'drop-down',
          options: getEthnicityOptions('en'),
          required: false
        },
        {
          key: 'household_member_gender',
          title: 'Gender',
          applicant_type: 'Household-member',
          show: false,
          default: 'Choose One',
          type: 'drop-down',
          options: getGenderOptions('en'),
          required: false
        },
        {
          key: 'co_applicant_birthdate',
          title: 'Date of Birth',
          applicant_type: 'Co-applicant',
          show: false,
          placeholder: 'e.g. 10/13/1987',
          type: 'date',
          minimum: '1900-01-01',
          maximum: '2020-01-01',
          required: false
        },
        {
          key: 'co_applicant_email',
          title: 'Email',
          applicant_type: 'Co-applicant',
          show: false,
          placeholder: 'e.g. example@example.com',
          type: 'email',
          required: false
        },
        {
          key: 'co_applicant_home_phone',
          title: 'Home Phone',
          applicant_type: 'Co-applicant',
          show: false,
          placeholder: 'e.g. +1 123-456-7890',
          type: 'tel',
          required: false
        },
        {
          key: 'co_applicant_mobile_phone',
          title: 'Mobile Phone',
          applicant_type: 'Co-applicant',
          show: false,
          placeholder: 'e.g. +1 123-456-7890',
          type: 'tel',
          required: false
        },
        {
          key: 'co_applicant_signature',
          title: 'Signature',
          applicant_type: 'Co-applicant',
          show: false,
          placeholder: '',
          type: 'text',
          required: false
        }
      ]
    },
    {
      title: 'Sandy Compensation',
      headline:
        'Have you received Superstorm Sandy related assistance from FEMA or other sources for damages to the property?',
      buttonText: 'Show FAQ',
      faqZone: 'bwv-sandy-compensation',
      responseFields: [
        {
          key: 'sandy_compensation',
          title: '',
          show: true,
          type: 'button-single',
          options: [
            { value: 'true', display: 'Yes' },
            { value: 'false', display: 'No' }
          ],
          invalidAnswers: [],
          alertAnswers: [],
          required: true
        }
      ]
    },
    {
      title: 'Backwater Valve Compensation',
      headline:
        'Have you received grants or other funds for installing a sewer backwater valve at the property?',
      buttonText: 'Show FAQ',
      faqZone: 'bwv-valve-compensation',
      responseFields: [
        {
          key: 'backwater_valve_compensation',
          title: '',
          show: true,
          type: 'button-single',
          options: [
            { value: 'true', display: 'Yes' },
            { value: 'false', display: 'No' }
          ],
          invalidAnswers: [],
          alertAnswers: [],
          required: true
        }
      ]
    },
    {
      title: 'Sandy Insurance Types',
      headline: 'What types of insurance did you have during Superstorm Sandy?',
      buttonText: 'Show FAQ',
      faqZone: 'bwv-insurance-types',
      responseFields: [
        {
          key: 'sandy_insurance_types',
          type: 'multiple-drop-down',
          size: 6,
          show: true,
          multiple: true,
          title: 'Select Multiple',
          options: [
            { value: 'Homeowners Insurance', display: 'Homeowners Insurance' },
            { value: 'Flood Insurance', display: 'Flood Insurance' },
            { value: 'Hazard Insurance', display: 'Hazard Insurance' },
            { value: 'Wind Insurance', display: 'Wind Insurance' },
            { value: 'Contents Insurance', display: 'Contents Insurance' },
            { value: 'Other Insurance', display: 'Other Insurance' }
          ],
          required: false
        }
      ]
    },
    {
      title: 'Tenants',
      headline: 'Do you have tenants currently living in your residence?',
      buttonText: 'Show FAQ',
      faqZone: 'bwv-tenants',
      responseFields: [
        {
          key: 'tenants',
          title: '',
          show: true,
          type: 'button-single',
          options: [
            { value: 'true', display: 'Yes' },
            { value: 'false', display: 'No' }
          ],
          invalidAnswers: [],
          alertAnswers: ['true'],
          alertCopy: 'Please download the ',
          alertLinkCopy: 'tenant income eligibility form. ',
          alertLink: 'http://www.floodhelpny.org/samples/tenantIncome.pdf',
          alertCopyAfterLink:
            'You will need to fill this out and return it to us.',
          required: true
        }
      ]
    },
    {
      title: 'Proof of Homeowners Insurance',
      headline: 'Please attach proof of your homeowners insurance',
      buttonText: 'Show FAQ',
      faqZone: 'bwv-homeowner-insurance',
      responseFields: [
        {
          key: 'homeowners_insurance',
          title: 'homeowner',
          label: ALLOWED_ATTACHMENTS,
          show: true,
          placeholder: '',
          type: 'file',
          remove: 'Remove File',
          canUploadMultipleFiles: false,
          fileTypes: '.jpeg, .png, .pdf',
          required: false
        }
      ]
    },
    {
      title: 'Proof of Flood Insurance',
      headline:
        'Please attach proof of your flood insurance (if your flood zone is AE, AO or VE)',
      buttonText: 'Show FAQ',
      faqZone: 'bwv-flood-insurance',
      responseFields: [
        {
          key: 'flood_insurance',
          title: 'flood',
          label: ALLOWED_ATTACHMENTS,
          show: true,
          placeholder: '',
          type: 'file',
          remove: 'Remove File',
          canUploadMultipleFiles: false,
          fileTypes: '.jpeg, .png, .pdf',
          required: false
        }
      ]
    },
    {
      title: 'Proof of Income',
      headline:
        'Please attach proof of income for the applicant and all household members',
      buttonText: 'Show FAQ',
      faqZone: 'bwv-income-proof',
      responseFields: [
        {
          key: 'household_member_proofincome',
          title: 'income',
          label: ALLOWED_ATTACHMENTS,
          show: true,
          type: 'file',
          remove: 'Remove File',
          canUploadMultipleFiles: false,
          fileTypes: '.jpeg, .png, .pdf',
          required: false
        }
      ]
    },
    {
      title: 'Race',
      headline: 'Please select the race of the main applicant',
      faqZone: '',
      responseFields: [
        {
          key: 'applicant_race',
          type: 'drop-down',
          show: true,
          multiple: false,
          default: 'Choose One',
          title: '',
          options: getRaceOptions('en'),
          required: false
        }
      ]
    },
    {
      title: 'Ethnicity',
      headline: 'Please select the ethnicity of the main applicant',
      faqZone: '',
      responseFields: [
        {
          key: 'applicant_ethnicity',
          type: 'drop-down',
          show: true,
          multiple: false,
          default: 'Choose One',
          title: '',
          options: getEthnicityOptions('en'),
          required: false
        }
      ]
    },
    {
      title: 'Gender',
      headline: 'Please select the gender identity of the main applicant',
      faqZone: '',
      responseFields: [
        {
          key: 'applicant_gender',
          type: 'drop-down',
          show: true,
          multiple: false,
          default: 'Choose One',
          title: '',
          options: getGenderOptions('en'),
          required: false
        }
      ]
    },
    {
      title: 'Birthdate',
      headline: 'Please input the birthdate of the main applicant',
      faqZone: '',
      responseFields: [
        {
          key: 'applicant_birthdate',
          title: '',
          show: true,
          placeholder: 'e.g. 10/13/1987',
          type: 'date',
          minimum: '1900-01-01',
          maximum: '2020-01-01',
          required: false
        }
      ]
    },
    {
      title: 'E-Signature',
      headline:
        'By typing your name into the box below, you agree to the terms and conditions of the Backwater Valve program and certify that you have read our privacy policy',
      buttonText: 'Show FAQ',
      faqZone: 'bwv-signature',
      responseFields: [
        {
          key: 'e_signature',
          title: 'E-Signature',
          show: true,
          placeholder: '',
          type: 'text',
          required: true
        }
      ]
    }
  ],
  // NOTE the keys in the answers array must match the keys in thq questions Array
  // and be in the same order
  providerAnswers: [
    {
      co_applicant_type: [],
      co_applicant_first_name: '',
      co_applicant_last_name: '',
      household_member_income: '',
      household_member_race: '',
      household_member_ethnicity: '',
      household_member_gender: '',
      co_applicant_birthdate: '',
      co_applicant_email: '',
      co_applicant_home_phone: '',
      co_applicant_mobile_phone: '',
      co_applicant_signature: ''
    },
    { sandy_compensation: '' },
    { backwater_valve_compensation: '' },
    { sandy_insurance_types: [] },
    { tenants: '' },
    { homeowners_insurance: '' },
    { flood_insurance: '' },
    { household_member_proofincome: '' },
    { applicant_race: '' },
    { applicant_ethnicity: '' },
    { applicant_gender: '' },
    { applicant_birthdate: '' },
    { e_signature: '' }
  ]
};
