import getRaceOptions from '../constants/RaceOptions';
import getEthnicityOptions from '../constants/EthnicityOptions';
import getGenderOptions from '../constants/GenderOptions';

const ALLOWED_ATTACHMENTS = 'Attach (PDF, JPEG, PNG)';

export default {
  nextWebstatus: 'ApplicationSubmitted',
  errorTitle: 'При подаче вашего заявления произошла ошибка.',
  errorBody:
    'Попробуйте отправить еще раз. При повторных сбоях свяжитесь с нами по адресу info@floodhelpny.org.',
  tryAgain: 'Повторите попытку',
  loadingText: 'Загрузка...',
  navCopy: {
    next: 'Обзор',
    previous: 'Предыдущий',
    submit: 'Отправить',
    checkResponses: 'Проверьте перед отправлением все ли введенные вами данные являются правильными'
  },
  applicant: 'Заявитель:',
  address: 'Алрес:',
  providerQuestions: [
    {
      headline:
        'Уведомьте нас о том, кто проживает в вашем доме или кто может считаться со-заявителем (например, любой вписанный в акт недвижимости):',
      title: 'Дополнительный заявитель',
      buttonText: 'Показать часто задаваемые вопросы',
      addRow: true,
      addRowText: 'Добавить заявителя',
      removeRow: false,
      removeRowText: 'Удалить заявителя',
      faqZone: 'bwv-additional-applicants',
      responseFields: [
        {
          key: 'co_applicant_type',
          title: 'Другие заявители',
          applicant_type: '',
          show: false,
          default: 'Выбрать один',
          type: 'select-applicant-type',
          options: [
            { value: 'Co-applicant', display: 'Со-заявитель' },
            { value: 'Household-member', display: 'Член семьи' }
          ],
          required: false
        },
        {
          key: 'co_applicant_first_name',
          title: 'Имя',
          applicant_type: 'both',
          show: false,
          placeholder: '',
          type: 'text',
          required: false
        },
        {
          key: 'co_applicant_last_name',
          title: 'Фамилия',
          applicant_type: 'both',
          show: false,
          placeholder: '',
          type: 'text',
          required: false
        },
        {
          key: 'household_member_income',
          title: 'Годовой доход',
          applicant_type: 'Household-member',
          show: false,
          placeholder: 'e.g. 50000',
          type: 'number',
          required: false
        },
        {
          key: 'household_member_race',
          title: 'Раса',
          applicant_type: 'Household-member',
          show: false,
          default: 'Choose One',
          type: 'drop-down',
          options: getRaceOptions('en'),
          required: false
        },
        {
          key: 'household_member_ethnicity',
          title: 'Этническая принадлежность',
          applicant_type: 'Household-member',
          show: false,
          default: 'Choose One',
          type: 'drop-down',
          options: getEthnicityOptions('en'),
          required: false
        },
        {
          key: 'household_member_gender',
          title: 'Пол',
          applicant_type: 'Household-member',
          show: false,
          default: 'Choose One',
          type: 'drop-down',
          options: getGenderOptions('en'),
          required: false
        },
        {
          key: 'co_applicant_birthdate',
          title: 'Дата рождения',
          applicant_type: 'Co-applicant',
          show: false,
          placeholder: 'e.g. 10/13/1987',
          type: 'date',
          minimum: '1900-01-01',
          maximum: '2020-01-01',
          required: false
        },
        {
          key: 'co_applicant_email',
          title: 'Адрес электронной почты',
          applicant_type: 'Co-applicant',
          show: false,
          placeholder: 'e.g. example@example.com',
          type: 'email',
          required: false
        },
        {
          key: 'co_applicant_home_phone',
          title: 'Домашний телефон',
          applicant_type: 'Co-applicant',
          show: false,
          placeholder: 'e.g. +1 123-456-7890',
          type: 'tel',
          required: false
        },
        {
          key: 'co_applicant_mobile_phone',
          title: 'Мобильный телефон',
          applicant_type: 'Co-applicant',
          show: false,
          placeholder: 'e.g. +1 123-456-7890',
          type: 'tel',
          required: false
        },
        {
          key: 'co_applicant_signature',
          title: 'Подпись',
          applicant_type: 'Co-applicant',
          show: false,
          placeholder: '',
          type: 'text',
          required: false
        }
      ]
    },
    {
      title: 'Компенсация после урагана Сэнди',
      headline:
        'Получили ли вы помощь, связанную с супер ураганом Сэнди от FEMA или от других источников за причинение вреда имуществу?',
      buttonText: 'Показать часто задаваемые вопросы',
      faqZone: 'bwv-sandy-compensation',
      responseFields: [
        {
          key: 'sandy_compensation',
          title: '',
          show: true,
          type: 'button-single',
          options: [
            { value: 'true', display: 'Да' },
            { value: 'false', display: 'Нет' }
          ],
          invalidAnswers: [],
          alertAnswers: [],
          required: true
        }
      ]
    },
    {
      title: 'Компенсация за канализационный обратный клапан',
      headline:
        'Получили ли вы гранты или другое финансирование на установку канализационного обратного клапана в вашем объекте недвижимости?',
      buttonText: 'Показать часто задаваемые вопросы',
      faqZone: 'bwv-valve-compensation',
      responseFields: [
        {
          key: 'backwater_valve_compensation',
          title: '',
          show: true,
          type: 'button-single',
          options: [
            { value: 'true', display: 'Да' },
            { value: 'false', display: 'Нет' }
          ],
          invalidAnswers: [],
          alertAnswers: [],
          required: true
        }
      ]
    },
    {
      title: 'Виды страхования за Сэнди',
      headline: 'Какие виды страхования у вас имелись на момент супер урагана Сэнди?',
      buttonText: 'Показать часто задаваемые вопросы',
      faqZone: 'bwv-insurance-types',
      responseFields: [
        {
          key: 'sandy_insurance_types',
          type: 'multiple-drop-down',
          size: 6,
          show: true,
          multiple: true,
          title: 'Выбрать несколько',
          options: [
            { value: 'Homeowners Insurance', display: 'Страхование недвижимости' },
            { value: 'Flood Insurance', display: 'Страхование от наводнения' },
            { value: 'Hazard Insurance', display: 'Страхование от ветра' },
            { value: 'Wind Insurance', display: 'Страхование от стихийных бедствий' },
            { value: 'Contents Insurance', display: 'Страхование домашнего имущества' },
            { value: 'Other Insurance', display: 'Другое страхование' }
          ],
          required: false
        }
      ]
    },
    {
      title: 'Арендаторы',
      headline: 'Проживают ли в данный момент арендаторы в вашем жилище?',
      buttonText: 'Показать часто задаваемые вопросы',
      faqZone: 'bwv-tenants',
      responseFields: [
        {
          key: 'tenants',
          title: '',
          show: true,
          type: 'button-single',
          options: [
            { value: 'true', display: 'Да' },
            { value: 'false', display: 'Нет' }
          ],
          invalidAnswers: [],
          alertAnswers: ['true'],
          alertCopy: 'Загрузите ',
          alertLinkCopy: 'форму соответствия критериям по доходу арендатора. ',
          alertLink: 'http://www.floodhelpny.org/samples/tenantIncome.pdf',
          alertCopyAfterLink:
            'Вам нужно будет это заполнить и вернуть нам.',
          required: true
        }
      ]
    },
    {
      title: 'Доказательство страхования недвижимости',
      headline: 'Приложите доказательство вашего страхования недвижимости ',
      buttonText: 'Показать часто задаваемые вопросы',
      faqZone: 'bwv-homeowner-insurance',
      responseFields: [
        {
          key: 'homeowners_insurance',
          title: 'homeowner',
          label: ALLOWED_ATTACHMENTS,
          show: true,
          placeholder: '',
          type: 'file',
          remove: 'Удалить файл',
          canUploadMultipleFiles: false,
          fileTypes: '.jpeg, .png, .pdf',
          required: false
        }
      ]
    },
    {
      title: 'Доказательство страхования от наводнения',
      headline:
        'Приложите доказательство вашего страхования от наводнения (если вашей зоной затопления является AE, AO или VE)',
      buttonText: 'Показать часто задаваемые вопросы',
      faqZone: 'bwv-flood-insurance',
      responseFields: [
        {
          key: 'flood_insurance',
          title: 'flood',
          label: ALLOWED_ATTACHMENTS,
          show: true,
          placeholder: '',
          type: 'file',
          remove: 'Удалить файл',
          canUploadMultipleFiles: false,
          fileTypes: '.jpeg, .png, .pdf',
          required: false
        }
      ]
    },
    {
      title: 'Доказательство дохода',
      headline:
        'Приложите доказательство дохода заявителя и всех членов семьи ',
      buttonText: 'Показать часто задаваемые вопросы',
      faqZone: 'bwv-income-proof',
      responseFields: [
        {
          key: 'household_member_proofincome',
          title: 'income',
          label: ALLOWED_ATTACHMENTS,
          show: true,
          type: 'file',
          remove: 'Удалить файл',
          canUploadMultipleFiles: false,
          fileTypes: '.jpeg, .png, .pdf',
          required: false
        }
      ]
    },
    {
      title: 'Раса',
      headline: 'Выберите расовую принадлежность главного заявителя',
      faqZone: '',
      responseFields: [
        {
          key: 'applicant_race',
          type: 'drop-down',
          show: true,
          multiple: false,
          default: 'Выбрать один',
          title: '',
          options: getRaceOptions('en'),
          required: false
        }
      ]
    },
    {
      title: 'Этническая принадлежность',
      headline: 'Выберите этническую принадлежность главного заявителя',
      faqZone: '',
      responseFields: [
        {
          key: 'applicant_ethnicity',
          type: 'drop-down',
          show: true,
          multiple: false,
          default: 'Выбрать один',
          title: '',
          options: getEthnicityOptions('en'),
          required: false
        }
      ]
    },
    {
      title: 'Пол',
      headline: 'Выберите половую идентичность главного заявителя',
      faqZone: '',
      responseFields: [
        {
          key: 'applicant_gender',
          type: 'drop-down',
          show: true,
          multiple: false,
          default: 'Выбрать один',
          title: '',
          options: getGenderOptions('en'),
          required: false
        }
      ]
    },
    {
      title: 'Дата рождения',
      headline: 'Введите дату рождения главного заявителя',
      faqZone: '',
      responseFields: [
        {
          key: 'applicant_birthdate',
          title: '',
          show: true,
          placeholder: 'e.g. 10/13/1987',
          type: 'date',
          minimum: '1900-01-01',
          maximum: '2020-01-01',
          required: false
        }
      ]
    },
    {
      title: 'Электронная подпись',
      headline:
        'Впечатав ваше имя и фамилию в ячейку ниже, вы выражаете согласие с правилами и условиями программы канализационного обратного клапана и подтверждаете, что вы прочитали нашу политику конфиденциальности',
      buttonText: 'Показать часто задаваемые вопросы',
      faqZone: 'bwv-signature',
      responseFields: [
        {
          key: 'e_signature',
          title: 'Электронная подпись',
          show: true,
          placeholder: '',
          type: 'text',
          required: true
        }
      ]
    }
  ],
  // NOTE the keys in the answers array must match the keys in thq questions Array
  // and be in the same order
  providerAnswers: [
    {
      co_applicant_type: [],
      co_applicant_first_name: '',
      co_applicant_last_name: '',
      household_member_income: '',
      household_member_race: '',
      household_member_ethnicity: '',
      household_member_gender: '',
      co_applicant_birthdate: '',
      co_applicant_email: '',
      co_applicant_home_phone: '',
      co_applicant_mobile_phone: '',
      co_applicant_signature: ''
    },
    { sandy_compensation: '' },
    { backwater_valve_compensation: '' },
    { sandy_insurance_types: [] },
    { tenants: '' },
    { homeowners_insurance: '' },
    { flood_insurance: '' },
    { household_member_proofincome: '' },
    { applicant_race: '' },
    { applicant_ethnicity: '' },
    { applicant_gender: '' },
    { applicant_birthdate: '' },
    { e_signature: '' }
  ]
};
