import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors, borderRadius } from '../../styles/variables';

const Container = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 420px) {
    flex-direction: column;
  }
`;

const SelectBox = styled.select`
  background: none;
  padding: 1rem;
  margin: 1rem;
  font-size: 1rem;
  color: ${colors.bodyBlue};
  border-radius: ${borderRadius};
`;

const SelectElementMultiple = ({
  responseField,
  answer,
  index,
  questionKey,
  answers,
  setAnswers
}) => {
  const setResponse = (e) => {
    const newAnswersArray = [...answers];
    // Either add or drop clicked answers from answers array
    if (newAnswersArray[index][questionKey].includes(e.target.value)) {
      const answerIndex = newAnswersArray[index][questionKey].indexOf(
        e.target.value
      );
      newAnswersArray[index][questionKey].splice(answerIndex, 1);
    } else {
      newAnswersArray[index] = {
        [questionKey]: [...answer, e.target.value]
      };
    }
    setAnswers(newAnswersArray);
  };

  return (
    <Container>
      <label>{responseField.title}</label>
      <SelectBox
        value={answer}
        size={responseField.size}
        multiple={responseField.multiple}
        onChange={(e) => setResponse(e)}
      >
        {responseField.options.map((field) => {
          return (
            <option key={field.value} value={field.value}>
              {field.display}
            </option>
          );
        })}
      </SelectBox>
    </Container>
  );
};

SelectElementMultiple.propTypes = {
  responseField: PropTypes.object.isRequired,
  answer: PropTypes.array.isRequired,
  answers: PropTypes.array.isRequired,
  setAnswers: PropTypes.func.isRequired,
  questionKey: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired
};

export default SelectElementMultiple;
