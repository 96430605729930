import certificate from '../images/certificate.svg';
import compass from '../images/compass.svg';
import home from '../images/home.svg';

export default {
  meta: {
    title: 'Certificados de Elevación | FloodHelpNY.org',
    description:
      'Los certificados de elevación pueden ayudar a protegerlo de sobrepagar su seguro contra inundaciones.',
    ogDescription:
      'Un certificado de elevación es un documento que protege a los propietarios de viviendas de sobrepagar en su seguro contra inundaciones.',
    url: 'https://www.floodhelpny.org/es/elevation-certificates'
  },
  title: 'Certificados de Elevación',
  subheader:
    'Un certificado de elevación puede ayudarle a reducir su tarifa de seguro. He aquí otras cosas que debe saber sobre este documento clave.',
  overview: {
    header: 'Resumen',
    body:
      'Un certificado de elevación es un documento que protege a los propietarios de vivienda para evitar que paguen demasiado por el seguro contra inundación. Está regulado por la Agencia Federal para el Manejo de Emergencias (FEMA, por sus siglas en inglés) y lo prepara un ingeniero o agrimensor con licencia. Los datos recopilados en el certificado de elevación les permiten a las compañías aseguradoras calcular con mayor precisión el riesgo de inundación de una propiedad, verificando la elevación de la vivienda sobre el nivel del mar y si se han hecho esfuerzos para reducir el efecto de inundaciones.'
  },
  about: [
    {
      header: 'Información que aparece en el certificado de elevación',
      body:
        'Tres áreas de datos clave en el certificado de elevación son el tipo y las características de la edificación, el piso más bajo y la ubicación de la propiedad. Estos factores juegan un rol importante al determinar sus primas de seguro contra inundaciones.',
      image: certificate
    },
    {
      header: 'Cómo usar un certificado de elevación',
      body:
        'Si desea usar su certificado, debe proporcionárselo a su agente de seguros dentro de 90 días a partir de la fecha de finalización que aparece en el documento. Con base en la información en su certificado, si usted reúne los requisitos para obtener una prima más baja, su agente de seguros deberá cobrarle la tasa más baja.',
      image: compass
    },
    {
      header: 'Quién necesita un certificado de elevación',
      body:
        'Con los cambios implementados por FEMA a través del Risk Rating 2.0., los certificados de elevación ya no son necesarios para ningún propietario. Sin embargo, los certificados de elevación todavía pueden ser importantes para asegurarse de que está recibiendo el precio correcto en su seguro contra inundaciones. También pueden ser usadas para documentar a su proveedor de seguros cualquier cambio importante que se le haya hecho a la propiedad, por ejemplo, elevar su propiedad, instalación de respiraderos de inundación o la elevación de su equipo mecánico, cualquiera de estas pueden reducir el precio de su seguro de inundación.',
      image: home
    }
  ],
  howToGetOne: {
    header: 'Cómo obtener un certificado de elevación',
    body:
      "El costo promedio de un certificado de elevación puede oscilar entre $500 y más de $1,000. Trate de obtener cotizaciones de por lo menos dos inspectores para comparar. Ejemplos de inspectores profesionales incluyen agrimensores, ingenieros o arquitectos con licencia y autorizados por ley para preparar un certificado de elevación. FEMA recomienda buscar un inspector profesional en la <a href='https://www.nysapls.org/search/custom.asp?id=2216' target='_blank' rel='noreferrer noopener'>asociación estatal profesional de agrimensores</a> y obtener orientación del <a href='https://www1.nyc.gov/site/housingrecovery/programs/elevation-certificate.page' target='_blank' rel='noreferrer noopener'>Departamento de Construcción.</a>",
    tip: {
      header: 'RECOMENDACIÓN',
      body:
        'El propietario anterior ya podría tener un certificado; si usted está en proceso de comprar la propiedad o si recientemente ha comprado la propiedad, podría comunicarse con él o ella.'
    }
  },
  alreadyHaveOne: {
    header: 'Si usted ya tiene un certificado de elevación',
    body:
      'Si ya tiene un certificado de elevación, no necesita pedir un nuevo certificado. Sin embargo, si ha hecho cambios importantes en la propiedad para reducir los riesgos contra inundación, seria recomendable obtener un certificado actualizado para asegurarse de recibir el precio correcto en su seguro.'
  },
  expired: {
    header: 'Mi certificado dice que caducó en 2018. ¿Todavía es útil?',
    body:
      'La fecha que aparece en la parte superior derecha de su certificado es la fecha del formulario, el cual generalmente FEMA actualiza cada tres años. La fecha asegura que los ingenieros y agrimensores otorguen certificados de elevación usando el formato correcto. Sí tiene un certificado con esta fecha todavía es valida para calificar su propiedad. Si esta obteniendo un certificado de elevación por primera vez, deberá asegurarse que su ingeniero o agrimensor use una forma con la fecha 30 de noviembre, 2022 hasta que FEMA emita una nueva versión (a veces esto puede tardar varios años después de la fecha de vencimiento).'
  },
  download: 'Descargar el Certificado',
  update: 'Actualizar Su Certificado'
};
