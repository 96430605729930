import GeocodeCommunicator from '../../communicators/GeocodeCommunicator';

const MarkerHelper = {
  placeMarker: (key, address, map, callback) => {
    // TODO remove callback and use async/await
    const success = (response) => {
      const marker = MarkerHelper.setPropertyMarker(response.results[0], map);
      callback(marker);
    };
    // Returns an object with {results: [array of address data], status: "status"}
    GeocodeCommunicator.lookupAddress(key, address, success);
  },
  setPropertyMarker: (result, map) => {
    const latLng = result.geometry.location;
    const marker = new google.maps.Marker({
      position: latLng,
      map,
      title: 'Your location'
    });
    return marker;
  }
};

export default MarkerHelper;
