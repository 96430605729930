import React from 'react';
import PropTypes from 'prop-types';
import calendar from '../images/calendar.svg';
import RoundIcon from './RoundIcon';
import CallToAction from './layout/CallToAction';
import { Section } from './ui/StyledResourcePageElements';

const AuditStatusRejected = ({ provider }) => {
  return (
    <Section>
      <CallToAction>
        <RoundIcon src={calendar} alt="calendar" />
        <p>{provider.body}</p>
      </CallToAction>
    </Section>
  );
};

AuditStatusRejected.propTypes = {
  provider: PropTypes.object.isRequired
};

export default AuditStatusRejected;
