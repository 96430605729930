export default {
  header: 'Tu informe',
  copy: [
    'Este informe de resiliencia es para',
    'con algunos recomendaciones técnicos sobre opciones para fortalecer su casa y sus finanzas contra las inundaciones.'
  ],
  links: {
    property: 'Propiedad',
    plumbing: 'Plomería',
    rate: 'Tarifa de seguro',
    resiliency: 'Estrategias de resiliencia',
    documents: 'Documentos'
  }
};
