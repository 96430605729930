import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '../../styles/variables';
import Button from '../ui/Button';

// TODO fix this styling
const Container = styled.div`
  background: white;
  padding: 50px 0;
  margin-right: 18%;
  margin-left: 18%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 760px) {
    margin: 0;
  }
`;

const Header = styled.h4`
  text-align: center;
`;

const ErrorMessage = styled.p`
  margin-bottom: 1em;
`;

const LookupError = ({ provider, unsetErrorState, navBar }) => {
  return (
    <Container>
      <Header>{provider.title}</Header>
      <ErrorMessage>{provider.error}</ErrorMessage>
      {unsetErrorState && (
        <Button onClick={unsetErrorState} bgColor={colors.resilientBlue}>
          {provider.tryAgain}
        </Button>
      )}
    </Container>
  );
};

LookupError.propTypes = {
  provider: PropTypes.object.isRequired,
  unsetErrorState: PropTypes.func,
  navBar: PropTypes.bool
};

LookupError.defaultProps = {
  unsetErrorState: null,
  navBar: false
};

export default LookupError;
