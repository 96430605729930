export default {
  header: '申请信息：',
  // All questions keys must be listed here with a corresponding title.
  // Use the yes or no keys as replacements for boolean answers (true, false)
  yes: 'Yes',
  no: 'No',
  co_applicant_first_name: '名',
  co_applicant_last_name: '姓',
  co_applicant_birthdate: '出生日期（年月日)',
  co_applicant_email: '电子邮件',
  co_applicant_home_phone: '家庭电话',
  co_applicant_mobile_phone: '手机',
  co_applicant_signature: '签字',
  co_applicant_type: '共同申请人类型',
  e_signature: '电子签名',
  household_member_race: '种族',
  household_member_income: '年收入',
  household_member_ethnicity: '民族',
  household_member_gender: '性别',
  sandy_compensation: '飓风桑迪补偿款',
  sandy_insurance_types: '飓风桑迪期间持有的保险',
  tenants: '租户',
  flood_insurance: '洪水保险文件',
  homeowners_insurance: '房主保险文件',
  household_member_proofincome: '家庭收入证明',
  backwater_valve_compensation: '回水补偿款',
  applicant_race: '主要申请人种族',
  applicant_ethnicity: '主要申请人民族',
  applicant_gender: '主要申请人性别',
  applicant_birthdate: '主要申请人性别'
};
