import React from 'react';
import PropTypes from 'prop-types';
import { useProvider } from '../helpers/hooks';
import { colors } from '../styles/variables';
import { Section } from './ui/StyledResourcePageElements';
import RoundIcon from './RoundIcon';
import CallToAction from './layout/CallToAction';
import ButtonLink from './ui/ButtonLink';

const ProfileWhatYouCanDo = ({ property }) => {
  const provider = useProvider('ProfileWhatYouCanDo');

  return (
    property.small_business && (
      <Section>
        <h5>{provider.header}</h5>
        {provider.callsToAction.map((callToAction) => (
          <div>
            <CallToAction>
              <RoundIcon src={callToAction.icon} icon={callToAction.iconAlt} />
              <div>
                <h3>{callToAction.header}</h3>
                <p>{callToAction.body}</p>
              </div>
              <ButtonLink
                url={callToAction.buttonLink}
                text={callToAction.buttonText}
                color={colors.sourApple}
                external
              />
            </CallToAction>
          </div>
        ))}
      </Section>
    )
  );
};

ProfileWhatYouCanDo.propTypes = {
  property: PropTypes.object.isRequired
};

export default ProfileWhatYouCanDo;
