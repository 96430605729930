import React from 'react';
import PropTypes from 'prop-types';
import RtappApplicationState from '../constants/RtappApplicationState';
import AuditStatusInfoSingleImage from './AuditStatusInfoSingleImage';
import AuditStatusComplete from './AuditStatusComplete';
import AuditStatusRejected from './AuditStatusRejected';
import AuditStatusAppointment from './AuditStatusAppointment';
import AuditApplicationHeader from './AuditApplicationHeader';
import AuditApplication from './AuditApplication';
import AuditStatusInfoMultipleImages from './AuditStatusInfoMultipleImages';
import AuditStatusScheduleCounseling from './AuditStatusScheduleCounseling';
import { useProvider } from '../helpers/hooks';

const AuditStatusContainer = ({ applicationState, application }) => {
  const provider = useProvider('auditStatusContainer')[applicationState];
  const caseId = application.external_id;
  const statusComponents = {
    [RtappApplicationState.PRE_APPLICATION]: (
      <AuditStatusInfoSingleImage
        provider={provider}
        application={application}
      />
    ),
    [RtappApplicationState.PRE_APPLICATION_APPROVED]: [
      <AuditApplicationHeader key="Header" application={application} />,
      <AuditApplication
        key="AuditApplication"
        floodZone={
          application.property_contact_detail.property.current_flood_zone
        }
        caseId={caseId}
      />
    ],
    [RtappApplicationState.REJECTED]: (
      <AuditStatusRejected provider={provider} />
    ),
    [RtappApplicationState.WITHDRAWN]: (
      <AuditStatusRejected provider={provider} />
    ),
    [RtappApplicationState.APPLICATION_SUBMITTED]: (
      <AuditStatusInfoSingleImage
        provider={provider}
        application={application}
      />
    ),
    [RtappApplicationState.HOMEOWNER_GRANT]: (
      <AuditStatusInfoSingleImage
        provider={provider}
        application={application}
      />
    ),
    [RtappApplicationState.FEASIBILITY_REVIEW]: (
      <AuditStatusAppointment
        provider={provider}
        appointmentDate={
          application.selected_resiliency_audit.audit_appointment_time
        }
      />
    ),
    [RtappApplicationState.AUDIT_SCHEDULED]: (
      <AuditStatusAppointment
        provider={provider}
        appointmentDate={
          application.selected_resiliency_audit.audit_appointment_time
        }
      />
    ),
    [RtappApplicationState.AUDIT_COMPLETE]: (
      <AuditStatusScheduleCounseling provider={provider} />
    ),
    [RtappApplicationState.CONSULTATION_SCHEDULED]: (
      <AuditStatusAppointment
        provider={provider}
        appointmentDate={
          application.resiliency_counseling.counselor_appointment_time
        }
      />
    ),
    [RtappApplicationState.CONSULTATION_COMPLETE]: (
      <AuditStatusInfoMultipleImages provider={provider} />
    ),
    [RtappApplicationState.INSTALLATION_PREPARE]: (
      <AuditStatusInfoMultipleImages provider={provider} />
    ),
    [RtappApplicationState.MEET_YOUR_TEAM]: [
      <AuditStatusInfoMultipleImages key="Info" provider={provider} />,
      <AuditStatusAppointment
        key="Appointment"
        provider={provider}
        appointmentDate={application.triparty_appointment_time}
      />
    ],
    [RtappApplicationState.INSTALLATION_TO_BE_SCHEDULED]: (
      <AuditStatusInfoSingleImage
        provider={provider}
        application={application}
      />
    ),
    [RtappApplicationState.INSTALLATION_HAS_BEEN_SCHEDULED]: (
      <AuditStatusInfoMultipleImages provider={provider} />
    ),
    [RtappApplicationState.INSTALLATION_COMPLETE]: (
      <AuditStatusInfoSingleImage
        provider={provider}
        application={application}
      />
    ),
    [RtappApplicationState.REPORT_COMPLETE]: (
      <AuditStatusComplete
        address={application.property_contact_detail.property.combined_address}
        provider={provider}
      />
    )
  };

  return <>{statusComponents[applicationState]}</>;
};

AuditStatusContainer.propTypes = {
  applicationState: PropTypes.string.isRequired,
  application: PropTypes.object.isRequired
};

export default AuditStatusContainer;
