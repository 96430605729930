import React from 'react';
import PropTypes from 'prop-types';
import mailbox from '../images/mailbox.svg';
import RoundIcon from './RoundIcon';
import CallToAction from './layout/CallToAction';
import { Section } from './ui/StyledResourcePageElements';

const AuditStatusComplete = ({
  address,
  provider: { firstBody, secondBody }
}) => {
  return (
    <Section>
      <CallToAction>
        <RoundIcon src={mailbox} alt="mailbox" />
        <div>
          <p>{firstBody}</p>
          <p>
            <b>{address}</b>
          </p>
          <p>{secondBody}</p>
        </div>
      </CallToAction>
    </Section>
  );
};

AuditStatusComplete.propTypes = {
  address: PropTypes.string.isRequired,
  provider: PropTypes.object.isRequired
};

export default AuditStatusComplete;
