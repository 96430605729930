// This component is currently not called anywhere but will be used when the
// RateCalculator is fixed
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { lighten, darken } from 'polished';
import { colors, borderRadius, cubicBezier } from '../styles/variables';
import calculator from '../images/calculator.svg';
import timeToComplete from '../images/time-to-complete.svg';
import RoundIcon from './RoundIcon';
import CallToAction from './layout/CallToAction';

const ButtonWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
`;

const Button = styled.a`
  font-family: GT-Walsheim-Pro-Light;
  border-radius: ${borderRadius};
  font-size: 1em;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
  transition: transform 0.1s ${cubicBezier.ease};
  color: white;
  border: none;
  background-color: ${colors.sourApple};
  padding: 0 40px;
  height: 60px;
  line-height: 60px;

  &:hover {
    background-color: ${darken(0.1, colors.sourApple)};
    text-decoration: none;
  }

  &:disabled {
    background-color: ${colors.sourApple};
    cursor: not-allowed;
  }

  &:active {
    transform: translate3d(0, 2px, 0);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px ${lighten(0.3, colors.sourApple)};
  }
`;

const AsideMessage = styled.aside`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  font-size: 1rem;
  margin-top: 10px;
`;

const ProfileRateCalculatorCallToAction = ({ property, locale }) => {
  const provider = require(`../providers/ProfileRateCalculatorCallToAction.${locale}`)
    .default;

  return (
    <CallToAction>
      <RoundIcon
        src={calculator}
        background={colors.lightPink}
        alt="calculator"
      />
      <div>
        <h3>
          {provider.header} {provider.beta}
        </h3>
        <p>{provider.body}</p>
      </div>
      <ButtonWrapper>
        <Button href={`/${locale}/rate-calculator/${property.external_id}`}>
          {provider.action}
        </Button>
        <AsideMessage>
          <img src={timeToComplete} alt="Clock" />
          <span>{provider.time}</span>
        </AsideMessage>
      </ButtonWrapper>
    </CallToAction>
  );
};

ProfileRateCalculatorCallToAction.propTypes = {
  property: PropTypes.object.isRequired,
  locale: PropTypes.string
};

ProfileRateCalculatorCallToAction.defaultProps = {
  locale: 'en'
};

export default ProfileRateCalculatorCallToAction;
