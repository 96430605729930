import DefaultCalculatorParamsHelper from './DefaultCalculatorParamsHelper';

const ElevateMechanicalsHelper = {
  showMitigation: (property) => {
    return property.machinery_in_basement;
  },
  formatMitigatedRate: (property, useCurrent) => {
    const formattedParams = DefaultCalculatorParamsHelper.formatCalculatorParameters(
      property,
      useCurrent
    );
    formattedParams.machinery_in_basement = false;
    return formattedParams;
  }
};

export default ElevateMechanicalsHelper;
