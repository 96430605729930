export default {
    currentlyServed: 'Регионы, обслуживаемые в данное время',
    neighborhood: {
      header: 'Районы: ',
      content:
        'Бенсонхерст, Берген Бич, Брайтон Бич, Канарси, Кони Айленд, Грейсенд, Джорджтаун, Герритсен Бич, Говард Бич, нижний Манхэттен, Манхэттан Бич, Марин Парк, Мил Бэйсин, Мил Айленд, Рэд Хук, Рокавей Ист, Сигейт, и Шепард Бэй.'
    },
    priority: {
      header: 'Параметры приоритетности соответствия критериям:',
      criteria: [
        {
          key: 'income',
          copy: 'уровень дохода'
        },
        {
          key: 'sandy',
          copy: 'понесли ли вы убытки от Сэнди'
        },
        {
          key: 'certificate',
          copy: 'есть ли у вас сертификат высотной отметки'
        }
      ]
    },
    downloadEligibilityForm:
      'Загрузите полный список соответствия критериям <a href="/samples/backwater-valve-eligibility.pdf">здесь</a>.'
  };
  