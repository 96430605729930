export default {
  meta: {
    title: 'Pwogram Enstalasyon Valv pou Dlo k Ap Tounen',
    url: 'https://www.floodhelpny.org/ht_HT/mitigation/backwater-valve',
    description: 'Pwoteje kay ak finans ou kont inondasyon.'
  },
  title: 'Valv pou dlo k ap tounen: Sa ou bezwen konnen',
  subtitle:
    'Yon valv pou dlo k ap tounen fèt pou kenbe gwo dlo ize ki sot nan kay ou nan ka egou an debòde.',
  overview: {
    header: 'Apèsi',
    copy:
      'Sistèm egou konbine Vil New York lan debòde, anmwayèn, yon fwa chak semèn. Lè dlo lapli an kontre ak dlo ize yo epi depase kapasite egou an, dlo ize anplis lan ka debòde nan kanalizasyon yo e nan kay moun yo. Refoulman plonbri yo pa pwòp ditou e yo ka koze risk grav pou sante, e yo koute chè pou netwaye.'
  },
  howItWorksHeader: 'Kijan valv an fonksyone',
  howItWorks: {
    paragraph1:
      'Yon valv pou dlo k ap tounen konekte ak plonbri kay ou an epi li prezante yon mekanis entèn ki rele “klapè”. Nan ka egou yo debòde, klapè an fèmen pou ede pwoteje kay ou an pou l pa ranpli ak dlo ize.',
    paragraph2:
      'Lè debòdman kalme l, chanjman nan presyon an degaje klapè an, sa ki pèmèt dlo ize an desann sot nan kay ou an epi al nan egou an.'
  },
  backwaterValveGifAlt: 'Backwater valve animation',
  affect: {
    header: 'Kijan l afekte ou',
    moneySavedSubheader: 'Estimasyon Lajan Sove',
    bigNumber: '$2,000 - $10,000',
    moneySavedCopy:
      'Enstale yon valv pou verifikasyon pa p afekte sa ou peye pou asirans kont inondasyon an. Toutfwa, nan ka egou an debòde, genyen valv lan anplas ka fè ou ekonomize plizyè milye dola nan netwayaj dlo ize ak depans pou restorasyon.',
    damageAvoidedHeader: 'Domaj ki Evite',
    damageAvoidedCopy:
      'Gen yon valv pou dlo k ap tounen redui posiblite pou refoulman plonbri yo, kidonk nenpòt bagay ou estoke anba lakay ou an ap rete sekirize epi pwoteje kont dlo ize.'
  },
  whatItTakes: {
    header: 'Kisa sa mande',
    paragraph1:
      'Enstale yon valv pou dlo k ap tounen ka pran 2-8 èdtan, toudepan fason kanalizasyon lakay ou yo aksesib.',
    paragraph2:
      'Plonbyen an pral ba ou kèk konsèy sou antretyen, tankou kijan pou flòch valv lan ak dlo pou rann li pa bloke.',
    cost: '$600–$5,000',
    costCopy:
      'Depans enstalasyon an depann ak anplasman kanalizasyon ou yo ansanm ak ki kalite valv pou dlo k ap tounen ki bon pou lakay ou.'
  },
  whatYouCanDoNext: {
    header: 'Sa ou ka fè apresa',
    subheading: 'Rele yon plonbye ki lisansye',
    copy:
      'Si ou pare pou fè enstale yon valv pou dlo k ap toune lakay ou, dabò verifye si kontra pwopriyetè kay ou an kouvri ranplasman plonbri. Ansuit, rele yon chèf plonbye ki lisansye, aranje yon enspeksyon tiyo drenaj lakay ou, epi poze kèk kesyon kle (gade anba). Ou ka verifye tou enfòmasyon sou lisans yo sou sit entènèt <a href="http://www.nyc.gov/dob" target="_blank" rel="noreferrer noopener">Depatman Batiman NYC yo</a>.',
    question:
      'Ki kesyon mwen ta dwe poze lè m ap chèche yon plonbye pou enstale valv sa a?',
    answer1:
      'Mande yo lisans yo, eksperyans yo NYC oswa nan katye ou an, nenpòt sètifikasyon ak referans apati pwojè ki parèy.',
    answer2: 'Eseye jwenn pwofòma nan men twa plonbye pou konparezon.'
  }
};
