import FloodZones from '../../constants/FloodZones';
import RateCalculatorInputFormatter from '../RateCalculatorInputFormatter';

const DefaultCalculatorParamsHelper = {
  formatCalculatorParameters: (property, useCurrent) => {
    const zone = useCurrent
      ? property.current_flood_zone
      : property.future_flood_zone;
    const calculatorInputs = Object.assign(property, {
      building_coverage: 250000,
      content_coverage: 100000,
      building_deductible: 2000,
      content_deductible: 2000
    });

    let formattedParams;
    if (zone === FloodZones.X || zone === FloodZones.NONE) {
      formattedParams = RateCalculatorInputFormatter.formatXZone(
        calculatorInputs,
        !useCurrent
      );
    }
    if (zone === FloodZones.AE) {
      const bfe = useCurrent ? property.current_bfe : property.future_bfe;
      formattedParams = RateCalculatorInputFormatter.formatAEZone(
        bfe,
        calculatorInputs,
        !useCurrent
      );
    }
    if (zone === FloodZones.VE) {
      const bfe = useCurrent ? property.current_bfe : property.future_bfe;
      formattedParams = RateCalculatorInputFormatter.formatVEZone(
        bfe,
        calculatorInputs,
        false,
        !useCurrent
      );
    }
    return formattedParams;
  }
};

export default DefaultCalculatorParamsHelper;
