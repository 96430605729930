export default {
    tellYourFriends: 'Расскажите вашим друзьям и соседям',
    about: 'О',
    privacy: 'Конфиденциальность',
    termsAndConditions: 'Правила и условия',
    widget: 'Виджет',
    multifamily: 'Многоквартирные дома',
    cnycn: 'Центр для районов Нью-Йорка',
    facebook: 'Поделиться на Facebook',
    twitter: 'Поделиться на Twitter',
    email: 'Электронная почта',
    emailSubject: 'Узнайте ваш риск наводнения сегодня!',
    message:
      'Не важно, где вы живете в Нью-Йорке - если может идти дождь, значит может быть наводнение. Узнайте  ваш риск наводнения сегодня на сайте https://www.floodhelpny.org/! '
  };
