import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import RtappApplicationState from '../constants/RtappApplicationState';
import { useProvider } from '../helpers/hooks';
import { Section } from './ui/StyledResourcePageElements';
import { colors } from '../styles/variables';

const Content = styled.div`
  border: 10px solid ${colors.grayScale[0]};
  padding: 0 4rem 2rem 4rem;
  margin-top: 3rem;

  @media (max-width: 1200px) {
    padding: 0 1rem 2rem 1rem;
  }
`;

const Property = styled.div`
  margin: 2rem 0;

  h3 {
    margin-top: 1rem;
  }
`;

const BasicAndContactInfo = styled.div`
  display: flex;

  > div {
    flex-grow: 2;

    @media (max-width: 1200px) {
      margin-top: 2rem;

      :first-child {
        margin-top: 0;
      }
    }
  }

  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;

const List = styled.ul`
  max-width: 75%;

  li {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-top: 1rem;
  }
`;

const ListLabel = styled.span`
  color: ${colors.grayScale[3]};
`;

const ListValue = styled.span``;

const AuditReview = ({
  application: {
    contact,
    property_contact_detail: { property },
    income,
    webstatus
  }
}) => {
  const provider = useProvider('auditReview');

  const renderBasicInfo = () => {
    if (webstatus === RtappApplicationState.PRE_APPLICATION) {
      return null;
    }

    return (
      <div>
        <h6>{provider.basicInfo}</h6>
        <List>
          <li>
            <ListLabel>{provider.income}</ListLabel>
            <ListValue>{income}</ListValue>
          </li>
          <li>
            <ListLabel>{provider.household_size}</ListLabel>
            <ListValue>{contact.household_size}</ListValue>
          </li>
        </List>
      </div>
    );
  };

  return (
    <Section>
      <h5>{provider.whatYouToldUs}</h5>
      <div>
        <Content>
          <Property>
            <h6>{provider.property}</h6>
            <h3>{property.combined_address}</h3>
          </Property>
          <BasicAndContactInfo>
            {renderBasicInfo()}
            <div>
              <h6>{provider.contactInfo}</h6>
              <List>
                <li>
                  <ListLabel>{provider.name}</ListLabel>
                  <ListValue>{`${contact.first_name} ${contact.last_name}`}</ListValue>
                </li>
                <li>
                  <ListLabel>{provider.email}</ListLabel>
                  <ListValue>{contact.email}</ListValue>
                </li>
                <li>
                  <ListLabel>{provider.homePhone}</ListLabel>
                  <ListValue>{contact.home_phone}</ListValue>
                </li>
              </List>
            </div>
          </BasicAndContactInfo>
        </Content>
      </div>
    </Section>
  );
};

AuditReview.propTypes = {
  application: PropTypes.object.isRequired
};

export default AuditReview;
