import ApiCommunicator from './APICommunicator';

const GEO_CODE_URL = 'https://maps.googleapis.com/maps/api/geocode/json';

const GeoCodeCommunicator = {
  lookupAddress: (apiKey, address) => {
    const data = { address, key: apiKey };
    return ApiCommunicator.get(GEO_CODE_URL, data);
  },
  lookupLatLng: (apiKey, latlng) => {
    const data = { latlng, key: apiKey };
    return ApiCommunicator.get(GEO_CODE_URL, data);
  }
};

export default GeoCodeCommunicator;
