import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { colors, getZoneColor } from '../styles/variables';
import ExplorableMap from './ExplorableMap';
import RisingTide from './RisingTide';
import OtherResources from './OtherResources';
import CallToAction from './layout/CallToAction';
import RoundIcon from './RoundIcon';
import ButtonLink from './ui/ButtonLink';
import VideoWrapper from './ui/VideoWrapper';
import videoPoster from '../images/rising-tides-video-poster.jpg';
import PrimaryAndAsideContainer from './layout/PrimaryAndAsideContainer';
import ListWithIconAndText from './layout/ListWithIconAndText';
import {
  HeaderSection,
  Subheader,
  Section,
  SectionDescription
} from './ui/StyledResourcePageElements';
import { useProvider, useLocale, useFaq } from '../helpers/hooks';

const FloodZones = styled.div`
  display: flex;
  justify-content: space-evenly;
  text-align: center;

  @media (max-width: 920px) {
    flex-direction: column;
  }
`;

const FloodZone = styled.div`
  h1 {
    font-size: 6rem;
    margin: 0 2rem;
    color: ${(props) => props.color};
  }

  @media (max-width: 800px) {
    margin-bottom: 2rem;

    :last-child {
      margin-bottom: 0;
    }
  }
`;

const Video = styled.video`
  width: 100%;

  :focus {
    outline: none;
  }
`;

const UnderstandingFloodInsurance = () => {
  const provider = useProvider('understandingFloodInsurance');
  const locale = useLocale();
  const { setFaqZone } = useFaq();

  useEffect(() => {
    setFaqZone('understanding-flood-insurance');
  }, []);

  return (
    <>
      <Helmet>
        <title>{provider.meta.title}</title>
        <link rel="canonical" href={provider.meta.url} />
        <meta name="description" content={provider.meta.description} />
        <meta property="og:title" content={provider.meta.title} />
        <meta
          property="og:image"
          content="https://www.floodhelpny.org/images/understanding_flood_insurance.png"
        />
        <meta property="og:description" content={provider.meta.ogDescription} />
        <meta property="og:url" content={provider.meta.url} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image:alt" content={provider.meta.imageAlt} />
      </Helmet>
      <HeaderSection>
        <h1>{provider.title}</h1>
        <Subheader>{provider.subHeading}</Subheader>
      </HeaderSection>
      <Section>
        <h5>{provider.inANutshell.header}</h5>
        <ListWithIconAndText items={provider.inANutshell.items} />
      </Section>
      <Section noBorder>
        <VideoWrapper>
          <iframe
            src="https://www.youtube-nocookie.com/embed/BltyQmEkZ8o"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </VideoWrapper>
      </Section>
      <Section noBorder>
        <h5>{provider.whatIsIt.header}</h5>
        <PrimaryAndAsideContainer>
          <div>
            <p>{provider.whatIsIt.body.p1}</p>
            <SectionDescription>{provider.whatIsIt.body.p2}</SectionDescription>
          </div>
          <aside>
            <div>
              <h6>{provider.whatIsIt.aside.header}</h6>
              <p>{provider.whatIsIt.aside.body}</p>
            </div>
          </aside>
        </PrimaryAndAsideContainer>
      </Section>
      <Section>
        <h5>{provider.floodInsuranceCover.header}</h5>
        <PrimaryAndAsideContainer>
          <div>
            <p>{provider.floodInsuranceCover.subheader}</p>
            <ListWithIconAndText items={provider.floodInsuranceCover.items} />
          </div>
          <aside>
            <div>
              <h6>{provider.floodInsuranceCover.aside.header}</h6>
              <p>{provider.floodInsuranceCover.aside.body}</p>
            </div>
          </aside>
        </PrimaryAndAsideContainer>
      </Section>
      <CallToAction>
        <RoundIcon
          src={provider.shopping.icon}
          alt={provider.shopping.iconAlt}
        />
        <div>
          <h3>{provider.shopping.header}</h3>
        </div>
        <ButtonLink
          url={`/${locale}/shopping-for-flood-insurance`}
          text={provider.shopping.buttonText}
        />
      </CallToAction>
      <Section noBorder>
        <h5>{provider.myOptions.header}</h5>
        <PrimaryAndAsideContainer>
          <div>
            <p>{provider.myOptions.body}</p>
          </div>
          <aside>
            <div>
              <h6>{provider.myOptions.aside.header}</h6>
              <p>
                <a href={`/${locale}/small-business`}>
                  {provider.myOptions.aside.link}
                </a>
                {provider.myOptions.aside.body}
              </p>
            </div>
          </aside>
        </PrimaryAndAsideContainer>
      </Section>
      <Section>
        <h5>{provider.floodInsurancePriced.header}</h5>
        <p>{provider.floodInsurancePriced.body}</p>
      </Section>
      <CallToAction>
        <RoundIcon
          src={provider.premiums.icon}
          alt={provider.premiums.iconAlt}
        />
        <div>
          <p>{provider.premiums.body}</p>
        </div>
      </CallToAction>
      <Section noBorder>
        <h5>{provider.understandingMaps.header}</h5>
        <p>{provider.understandingMaps.sub_heading}</p>
        <FloodZones>
          <FloodZone color={getZoneColor('VE')}>
            <h1>VE</h1>
            <p>{provider.understandingMaps.severeRiskZone}</p>
          </FloodZone>
          <FloodZone color={getZoneColor('AE')}>
            <h1>AE/AO</h1>
            <p>{provider.understandingMaps.highRiskZone}</p>
          </FloodZone>
          <FloodZone color={getZoneColor('X')}>
            <h1>X</h1>
            <p>{provider.understandingMaps.moderateRiskZone}</p>
          </FloodZone>
        </FloodZones>
      </Section>
      <ExplorableMap />
      <Section noBorder>
        <h5>{provider.aboutTheZones.header}</h5>
        <PrimaryAndAsideContainer>
          <div>
            <p>{provider.aboutTheZones.body.p1}</p>
          </div>
          <aside>
            <div>
              <h6>{provider.aboutTheZones.aside.header}</h6>
              <ButtonLink
                url={`/${locale}#eligibility`}
                color={colors.sourApple}
                text={provider.aboutTheZones.aside.link}
                small
              />
            </div>
          </aside>
        </PrimaryAndAsideContainer>
      </Section>
      <Section>
        <h5>{provider.subsidizedRateFullRate.header}</h5>
        <PrimaryAndAsideContainer>
          <div>
            <p>{provider.subsidizedRateFullRate.body}</p>
          </div>
          <aside>
            <div>
              <h6>{provider.subsidizedRateFullRate.aside.header}</h6>
              <p>{provider.subsidizedRateFullRate.aside.body}</p>
            </div>
          </aside>
        </PrimaryAndAsideContainer>
      </Section>
      <Section>
        <h5>{provider.newHomeowners.header}</h5>
        <PrimaryAndAsideContainer>
          <div>
            <p>{provider.newHomeowners.body}</p>
          </div>
          <aside>
            <div>
              <h6>{provider.newHomeowners.aside.header}</h6>
              <p>{provider.newHomeowners.aside.body}</p>
            </div>
          </aside>
        </PrimaryAndAsideContainer>
      </Section>
      <Section noBorder>
        <Video controls poster={videoPoster} name="media">
          <source
            src="https://dh1rvgpokacch.cloudfront.net/atavist/14824/video/iphone/10marktrey-1411057181-100.mov.mp4"
            type="video/mp4"
          />
        </Video>
      </Section>
      <Section noBorder>
        <h5>{provider.whyMapsChanging.header}</h5>
        <PrimaryAndAsideContainer>
          <div>
            <p>{provider.whyMapsChanging.body}</p>
          </div>
          <aside>
            <div>
              <h6>{provider.whyMapsChanging.aside.header}</h6>
              <p>
                {provider.whyMapsChanging.aside.body_before}
                <a
                  href="http://nyc.gov/floodmaps"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {provider.whyMapsChanging.aside.link}
                </a>
                {provider.whyMapsChanging.aside.body_after}
              </p>
            </div>
          </aside>
        </PrimaryAndAsideContainer>
      </Section>
      <RisingTide />
      <OtherResources />
    </>
  );
};

export default UnderstandingFloodInsurance;
