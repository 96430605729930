import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import OtherResources from './OtherResources';
import AboutParametricProgram from './AboutParametricProgram';
import AboutParametricProgramSteps from './AboutParametricProgramSteps';
import ButtonLink from './ui/ButtonLink';
import { HeaderSection, Subheader } from './ui/StyledResourcePageElements';
import { useProvider, useLocale, useFaq } from '../helpers/hooks';

const ParametricProgram = () => {
  const provider = useProvider('parametricProgram');
  const locale = useLocale();
  const { setFaqZone } = useFaq();

  useEffect(() => {
    setFaqZone('rtapp-landing');
  }, []);

  return (
    <>
      <Helmet>
        <title>{provider.meta.title}</title>
        <link rel="canonical" href={provider.meta.url} />
        <meta name="description" content={provider.meta.description} />
        <meta property="og:title" content={provider.meta.title} />
        <meta
          property="og:image"
          content="https://www.floodhelpny.org/images/home_resiliency_audit.png"
        />
        <meta property="og:description" content={provider.meta.description} />
        <meta property="og:url" content={provider.meta.url} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image:alt" content={provider.meta.imageAlt} />
      </Helmet>
      <HeaderSection>
        <h1>{provider.title}</h1>
        <Subheader>{provider.subTitle}</Subheader>
        <ButtonLink
          url={`/${locale}/flood-recovery-program-intake-form`}
          text={provider.eligibilityLink}
        />
      </HeaderSection>
      <AboutParametricProgram />
      <AboutParametricProgramSteps />
      <OtherResources />
    </>
  );
};

export default ParametricProgram;
