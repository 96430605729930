/* eslint-disable no-underscore-dangle */

const chatScriptId = '8x8-chat-script';

const init = () =>
  new Promise((resolve) => {
    const __8x8Chat = {
      uuid: 'script_108424793957adfb89cfb4a9.39358443',
      tenant: 'Y2VudGVyZm9ybnljMDE',
      channel: 'RTAPP',
      domain: 'https://vcc-na17.8x8.com',
      path: '/.',
      buttonContainerId: '__8x8-chat',
      align: 'right',
      onInit: resolve
    };

    // chat.js needs a global variable with this exact name
    window.__8x8Chat = __8x8Chat;

    const chatScriptSrc = `${
      __8x8Chat.domain + __8x8Chat.path
    }/CHAT/common/js/chat.js`;

    if (document.getElementById(chatScriptId)) {
      resolve();
      return;
    }

    const chatScript = document.createElement('script');
    chatScript.type = 'text/javascript';
    chatScript.src = chatScriptSrc;
    chatScript.id = chatScriptId;
    chatScript.async = true;
    document.body.appendChild(chatScript);
  });

export default {
  init
};
