import React, {useState} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '../styles/variables';
import { useLocale, useProvider } from '../helpers/hooks';
import DropDownArrow from '../images/arrow-drop-down.svg'

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 3;
  height: 40px;
  padding: 0 2rem;
  background-color: ${colors.grayScale[6]};
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease;
  transform: translateY(${(props) => (props.isVisible ? 0 : '-40px')});

  @media (min-width: 1300px) {
    display: none;
  }
`;

const Link = styled.a`
  color: ${colors.mutedGray};
  font-size: 1rem;
  padding: .5rem;

  @media (max-width: 450px) {
    display: flex;
  }
`;

const AboutLink = styled.a`
  color: ${colors.mutedGray};
  font-size: 1rem;
  padding-left: .5rem;

  @media (max-width: 450px) {
    display: flex;
  }
`;

const LanguagesParent = styled.div`
  position: absolute;
  top: 0;
  width: 125px;
  margin-right: ${(props) => props.localeInfo === 'ht_HT' ? '8rem' : '6rem'}
`

const LanguagesList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${colors.grayScale[6]};
`

const DropDownComponent = styled.img`
  height: 10px;
  width: 20px;
  margin-top: .5rem;
  margin-left: .2rem;
  transform: ${(props) => (props.rotate === "false" ? '' : `rotate(180deg)`)};
  transition-duration: .3s;
`

const LanguagesTitle = styled.p`
  display: flex;
  flex-direction: row;
  color: ${colors.mutedGray};
  font-size: 1rem;
  padding: .5rem;
  width: 90px
  column-gap: .5rem;
`

const Divider = styled.span`
  color: ${colors.mutedGray};
  margin: 0 1rem;
`;

const UtilityBarMobile = ({ isVisible }) => {
  const [dropdown, setDropdown] = useState(false)
  const provider = useProvider('navBar');
  const locale = useLocale();
  const localesArray = ['en', 'es', 'ru', 'zh-CN', 'ht_HT'];

  const localPath = (languagePosition) => {
    const pathElements = window.location.pathname.split('/');
    pathElements.splice(1, 1, localesArray[languagePosition]);
    return pathElements.join('/');
  };

  const showEmergencyNotification = () => {
    sessionStorage.removeItem('fhnyNotificationClosed');
    setDropdown(false)
  };

  return (
    <Wrapper isVisible={isVisible}>
      <LanguagesParent localeInfo={locale}>
      <LanguagesTitle onClick={() => setDropdown(!dropdown)}>
        {provider.languageTitle}
        <DropDownComponent alt="Dropdown Menu" rotate={dropdown.toString()} src={DropDownArrow} 
      /></LanguagesTitle>
      {dropdown && <LanguagesList>
      <Link
        href={localPath(0)}
        onClick={showEmergencyNotification}
        data-turbolinks="false"
      >
        {provider.languages.english}
      </Link>
      <Link
        href={localPath(1)}
        onClick={showEmergencyNotification}
        data-turbolinks="false"
      >
        {provider.languages.spanish}
      </Link>
      <Link
        href={localPath(2)}
        onClick={showEmergencyNotification}
        data-turbolinks="false"
      >
        {provider.languages.russian}
      </Link>
      <Link
        href={localPath(3)}
        onClick={showEmergencyNotification}
        data-turbolinks="false"
      >
        {provider.languages.chinese}
      </Link>
      <Link
        href={localPath(4)}
        onClick={showEmergencyNotification}
        data-turbolinks="false"
      >
        {provider.languages.haitian}
      </Link>
      </LanguagesList>}
      </LanguagesParent>
      <Divider>|</Divider>
      <AboutLink href={`/${locale}/about`}>{provider.about}</AboutLink>
    </Wrapper>
  );
};

UtilityBarMobile.propTypes = {
  isVisible: PropTypes.bool.isRequired
};

export default UtilityBarMobile;
