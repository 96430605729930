import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from './ui/Button';
import { colors } from '../styles/variables';
import { useProvider, useLocale } from '../helpers/hooks';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  a,
  button {
    margin: 1rem;
  }
`;

const Links = styled.div`
  display: flex;
  justify-content: center;
`;

const ErrorMessage = styled.p`
  color: ${colors.pinkBerry};
  text-align: center;
`;

const AuditApplicationNav = ({
  navCopy,
  answersValid,
  setIsReviewing,
  isReviewing,
  submit
}) => {
  const [reviewEnabled, setReviewEnabled] = useState(false);
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const provider = useProvider('footer');
  const locale = useLocale();

  useEffect(() => {
    const areAllAnswersValid = answersValid.every((answer) => answer === true);
    setReviewEnabled(areAllAnswersValid);
    setSubmitEnabled(areAllAnswersValid && isReviewing);
  }, [answersValid, isReviewing]);

  const handleClick = () => {
    document.getElementById('audit-application-header').scrollIntoView({
      behavior: 'smooth'
    });
    setIsReviewing((prevState) => !prevState);
  };

  return (
    <Wrapper>
      <div>
        <Links>
          <a href={`/${locale}/privacy`}>{provider.privacy}</a>
          <a href={`/${locale}/terms`}>{provider.termsAndConditions}</a>
        </Links>
        <div>
          <Button
            type="button"
            bgColor={isReviewing ? colors.pinkBerry : colors.accent.confirm}
            onClick={handleClick}
            disabled={!reviewEnabled}
          >
            {isReviewing ? navCopy.previous : navCopy.next}
          </Button>
          <Button
            type="button"
            bgColor={colors.accent.confirm}
            onClick={submit}
            disabled={!submitEnabled}
          >
            {navCopy.submit}
          </Button>
        </div>
      </div>
      {!reviewEnabled && <ErrorMessage>{navCopy.checkResponses}</ErrorMessage>}
    </Wrapper>
  );
};

AuditApplicationNav.propTypes = {
  navCopy: PropTypes.object.isRequired,
  answersValid: PropTypes.array.isRequired,
  setIsReviewing: PropTypes.func.isRequired,
  isReviewing: PropTypes.bool.isRequired,
  submit: PropTypes.func.isRequired
};

export default AuditApplicationNav;
