import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '../../styles/variables';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem;
  margin: 0 auto;
  background-color: ${colors.beachBeige};
  max-width: 900px;

  img {
    max-height: 150px;
    max-width: 115px;
  }

  @media (max-width: 1200px) {
    flex-direction: column;
    text-align: center;
    margin: 0 1rem;
  }
`;

const Element = styled.div`
  max-width: 640px;
  margin: 1rem;

  @media (max-width: 1000px) {
    max-width: auto;
  }
`;

const Card = ({ children }) => (
  <Container>
    {children.map((content, idx) => (
      <Element key={idx}>{content}</Element>
    ))}
  </Container>
);

Card.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired
};

export default Card;
