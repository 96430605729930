import $ from 'jquery';
import FloodZones from '../../constants/FloodZones';
import BuildingTypes from '../../constants/BuildingTypes';
import BuildingFeatures from '../../constants/BuildingFeatures';
import DefaultCalculatorParamsHelper from './DefaultCalculatorParamsHelper';

const ElevateBuildingHelper = {
  showMitigation: (property) => {
    if (!$.isEmptyObject(property)) {
      if (
        ElevateBuildingHelper.notXorNone(property.current_flood_zone) ||
        ElevateBuildingHelper.notXorNone(property.future_flood_zone)
      ) {
        if (
          property.building_type !== BuildingTypes.ATTACHED &&
          property.building_type !== BuildingTypes.SEMI_DETACHED
        ) {
          if (property.building_feature !== BuildingFeatures.MOBILE_HOME) {
            return true;
          }
        }
      }
    }
    return false;
  },
  formatMitigatedRate: (property, useCurrent) => {
    const formattedParams = DefaultCalculatorParamsHelper.formatCalculatorParameters(
      property,
      useCurrent
    );

    formattedParams.elevation_difference = 2;
    return formattedParams;
  },
  notXorNone: (floodZone) => {
    return floodZone !== FloodZones.X && floodZone !== FloodZones.NONE;
  }
};

export default ElevateBuildingHelper;
