export default class BuildingFeatures {
  static get NO_BASEMENT_ENCLOSURE() {
    return 'No Basement / Enclosure';
  }

  static get BASEMENT() {
    return 'With Basement';
  }

  static get ENCLOSURE() {
    return 'With Enclosure';
  }

  static get ELEVATED_ON_CRAWLSPACE() {
    return 'Elevated on Crawlspace';
  }

  static get ELEVATED_ON_CRAWLSPACE_FLOOD_VENTS() {
    return 'Elevated on Crawlspace Flood Vents';
  }

  static get ELEVATED_ON_CRAWLSPACE_NO_FLOOD_VENTS() {
    return 'Elevated on Crawlspace No Flood Vents';
  }

  static get SUBGRADE_CRAWLSPACE() {
    return 'Non-Elevated with Subgrade Crawlspace';
  }

  static get MOBILE_HOME() {
    return 'Manufactured (Mobile) Home';
  }
}
