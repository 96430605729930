import AnswerValidator from './AnswerValidator';

export default class QuestionValidator {
  static validate(question, answers) {
    let answersValid = true;
    question.responseFields.map((responseField) => {
      const { key } = responseField;
      const answer = answers[key];
      const valid = AnswerValidator.isValid(responseField, answer);
      if (!valid) {
        answersValid = false;
        return false;
      }
    });
    return answersValid;
  }
}
