export default {
  meta: {
    title: 'Privacy Policy',
    url: 'https://www.floodhelpny.org/en/privacy',
    description: null,
    imageAlt: null
  },
  title: 'Privacy Policy',
  intro: {
    effective_date: 'Effective as of May 24th, 2019',
    body:
      'Thank you for visiting FloodHelpNY.org (the “Site”). This Privacy Policy applies only to the Site, which is controlled by the Center for New York City Neighborhoods, Inc. (“we”, “our” or “us”). This Privacy Policy pertains only to information that we collect through the Site. BY USING THE SITE, YOU CONSENT TO THE TERMS AND CONDITIONS OF THIS PRIVACY POLICY AND TO OUR PROCESSING OF PERSONAL INFORMATION FOR THE PURPOSES STATED BELOW. IF YOU DO NOT AGREE TO THE TERMS AND CONDITIONS OF THIS PRIVACY POLICY, PLEASE DO NOT USE THE SITE.',
    specific_info:
      'Specific information regarding our data practices is detailed below.',
    info_we_collect: 'Information that We Collect: Personal and Non-personal',
    we_collect:
      'We collect two types of information from visitors to the Site: (1) Personal Information; and (2) Non-Personal Information, such as your IP address or cookies.'
  },
  personal_info: {
    title: 'Personal Information',
    body:
      'When we use the term “Personal Information” we mean information we may collect that allows others to identify or contact you. For instance, we may collect your email address if you agree to receive newsletters and other information from us. We may also pair that with your address or location and other personal information that you provide to confirm that you are eligible to receive services from us and that the information we provide will likely be relevant to you.',
    examples: 'Examples of personal information collected by this Site: ',
    email: 'Email',
    address: 'Address',
    phone: 'Phone Number',
    homeowner: 'Information related to homeownership of your property',
    no_obligation:
      'While you’re under no obligation to provide the information requested, if you decline to do so, we may be unable to provide you with certain services. If you do not want us to collect your Personal Information, please do not provide it to us.'
  },
  non_personal_info: {
    title: 'Non-Personal Information',
    body:
      '“Non-Personal Information” can be technical information or it can be demographic information, such as your age, gender, zip code or other geolocation data, or interests. Non-Personal Information does NOT identify you personally. Here are some examples of the Non-Personal Information that is collected via the Site and a description of how this information is used:',
    ip:
      'Internet Protocol (IP) address – Your IP address is a number that lets computers attached to the Internet know where to send you data – such as the web pages you view. We use this information to deliver our web pages to you upon request, to tailor our Site to the interests of our users and to measure traffic within our Site.',
    web_beacons:
      'Web Beacons (also known as “clear gifs,” “web bugs” or “pixel tags”) – “Web Beacons” are tiny graphics with a unique identifier, similar in function to cookies, and are used to allow us to count users who have visited certain pages of the Site and to help determine the effectiveness of our programs and services. When used in HTML-formatted email messages, web beacons can tell the sender whether and when an email has been opened. In contrast to cookies, which are stored on a user’s computer hard drive, web beacons are embedded invisibly on web pages.',
    demographic:
      'Demographic Information – “Demographic Information” may be your gender, age, zip code and interests which may be automatically collected through the Site. We use this information to provide you with personalized services and to analyze trends to ensure the information provided by the Site meets your needs. Please note that we also consider aggregated information, which is not personally identifiable, to be Non-Personal Information.',
    addresses:
      'Addresses Searched and Corresponding Flood Zone Information – We will save addresses typed into our search tool along with their information results. We will use this information at the aggregate level to evaluate user interest in particular geographical areas as well as aggregate flood risk results obtained. This information will be used to evaluate the effectiveness of our outreach and website promotion strategies as well as for future research projects.',
    above_list:
      'The above list provides examples of the Non-Personal Information that is collected via the Site. We may collect the above Non-Personal Information from all visitors to our Site. If you do not want us to have access to this information, please do not visit our Site.'
  },
  use_of_information: {
    title: 'Use of Information Collected Via the Site',
    personal_info: 'Personal Information',
    personal_info_body:
      'We use your Personal Information that is collected on the Site primarily for the following purposes:',
    deliver_services: 'To deliver services to you',
    provide_info:
      'To provide you with information regarding programs and services in your area',
    verify_email: 'To verify your email address',
    answer_questions: 'To answer your questions or other requests',
    contact: 'To contact you regarding your use of the Site or our services',
    notify_about_changes:
      'To notify you about a material change to this Privacy Policy or our services, if necessary',
    administer:
      'To otherwise administer the Site or your interaction with our services',
    other_purposes:
      'For any other purpose disclosed at the time you provide your Personal Information',
    non_personal_info: 'Non-Personal Information',
    non_personal_is_used:
      'Non-Personal Information is used as described above and in other ways as permitted by applicable laws, including combining Non-Personal Information with Personal Information.',
    non_personal_example:
      'For example, we may use Non-Personal Information and usage data to (i) create marketing analyses and reports showing how our users, as collective groups, interact with our services, (ii) develop reports that help us and other strategic third parties determine what interests our service users, as a group, or (iii) generally improve our program and service offerings. Such reports will be shared with the Center for New York City Neighborhoods, and third parties helping us to administer this Site and our services. However, such reports will not contain any Personal Information about you.'
  },
  sharing_and_disclosure: {
    title: 'Sharing and Disclosure of Information',
    personal_info: 'Personal Information',
    personal_examples:
      'Examples of ways in which we may share or disclose your Personal Information includes the following:',
    fulfill:
      'To fulfill a service to you.  For example, if you request assistance from us and/or apply to the Home Resiliency Audit, we may share your Personal Information in order to provide the service to you.  In addition, if you contact us or sign up to receive our newsletter, we may use your email address to respond to your request.',
    affiliates_home_resiliency:
      'To affiliates, subcontractors, strategic partners, agents, applicable federal authorities, Governor’s Office of Storm Recovery or their designees, or other unaffiliated parties who are offering services as part of the Home Resiliency Audit.  These parties may use your Personal Information to contact you with respect to the Home Resilient Audit and may use such information for their own research, administration, or business purposes.  If you do not want us to share your Personal Information in this manner, please do not provide us with this information.',
    affiliates_other:
      'To affiliates, strategic partners, agents, or other unaffiliated parties who are offering programs or services that we believe may be of interest to you or who require your Personal Information for research, administrative and/or internal business purposes.  These parties may use your Personal Information to contact you with an offer related to a program or service, or they may use such information for their own research, administration or business purposes.  If you do not want us to share your Personal Information in this manner, please do not provide us with this information.',
    unaffiliated:
      'To unaffiliated third-party service providers, agents, independent contractors who help us maintain our Site and provide other administrative services to us (including, but not limited to, processing your requests, providing customer service, maintaining and analyzing data, and sending customer communications on our behalf).  We seek to ensure that these unaffiliated third parties will not use the Personal Information for any other purpose than to provide the administrative services for which they are responsible.  Because such unaffiliated third-party service providers that help us administer our Site will have access to users’ Personal Information, if you do not wish for our unaffiliated third-party service providers to have access to your information, please do not register or submit any Personal Information to us.',
    comply_with_law:
      'To comply with the law or in the good faith belief that such action is necessary in order to conform to the requirements of law or comply with legal process served on us, protect and defend our rights or property, the Site or act in urgent circumstances to protect the personal safety of our end users.',
    third_part_corp:
      'To third parties as part of any corporate reorganization process including, but not limited to, mergers, acquisitions, and sales of all or substantially all of our assets.',
    track_and_analyze:
      'To track and analyze non-identifying and aggregate usage and volume statistical information from our visitors and customers and provide such information to third parties.',
    fraud_protection:
      'To protect against potential fraud, we may verify with third parties the information collected from the Site.  In the course of such verification, we may receive Personal Information about you from such services.',
    personal_footer:
      'Except as described in this Privacy Policy or at the time we request the information, we do not otherwise use, share or otherwise disclose your Personal Information to any third parties helping us administer the Site or our services. If you do not consent to your Personal Information being disclosed as discussed above, please do not provide it to us.',
    non_personal_info: 'Non-Personal Information',
    non_personal_content:
      'We use Non-Personal Information collected on the Site in the manner disclosed above, in Section B (2). We may share this Non-Personal Information with third parties helping us administer the Site or our services.',
    children_under_13: 'Children Under Age 13',
    children_under_13_content:
      'The Site does not target children under the age of 13 or knowingly collect Personal Information from such individuals. If we become aware that a child under 13 has provided us with Personal Information, we will take steps to remove such information.',
    opt_out: 'Opt-Out',
    opt_out_content:
      'You may opt-out of receiving promotional messages from us or strategic third parties at any time. For e-mails, you can opt-out by going to the link provided at the bottom of any e-mail you receive from us and opting out of receiving information from us. We will process your unsubscribe as soon as possible, but please be aware that in some circumstances you may receive a few more messages until the unsubscribe is processed. That said, please note that unsubscribing from promotional e-mails will not opt you out of transactional e-mails or e-mails that we are required to send.',
    third_parties: 'Your Disclosure to Third Parties',
    third_parties_content:
      'If you respond to any interactive advertisement or offer by a third party (whether via an e-mail, message or otherwise), or if you request information about a product, program or service offered by a third party, then your Personal Information will be disclosed to such third party. We cannot control, and make no representations about, the collection or use of your information by such third parties. If you choose to register for or subscribe to any such third party product or service, any further correspondence between you and such third party including, without limitation, any resulting contractual agreements, are solely between you and the advertiser/third party. We will not be responsible for upholding any applicable terms, conditions, representations or warranties made by the advertiser/third party in connection therewith and shall otherwise have no liability associated with your interaction with such third party.',
    correct_inaccuracies:
      'How Can You Correct Any Inaccuracies in Your Personal Information?',
    correct_inaccuracies_body:
      'If you have any concerns regarding the accuracy of information that we may have collected about you and would like access to such information, please contact us at ',
    correct_inaccuracies_body_email: 'cnycn.org.',
    info_protected: 'How Is Your Information Protected?',
    info_protected_body:
      'We consider the confidentiality and security of your information to be paramount. We use physical, technical and administrative security measures to keep your Personal Information secure and confidential and will not provide it to third parties except to those entities helping administer the Site and our services, as required by law or as otherwise provided in this Privacy Policy. However, the Internet is not a secure environment, and we cannot 100% guarantee the security of your information; there is some risk that an unauthorized third party may find a way to circumvent our security measures or that information transmitted over the Internet may be intercepted.',
    info_protected_body_2:
      'If we learn of a security system breach, we may attempt to notify you electronically so that you can take appropriate protective steps. By using the Site or providing Personal Information to us you agree that we can communicate with you electronically regarding security, privacy and administrative issues relating to your use of the Site. We may post a notice on our Site if a security breach occurs. We may also send an email to you at the e-mail address you have provided to us in these circumstances or letter to the home address provided. Depending on where you live, you may have a legal right to receive notice of a security breach in writing.',
    third_party_sites: 'Third Party Web Sites',
    third_party_sites_body:
      'This statement applies solely to information collected on the Site. The Site may contain links to other web sites. We are not responsible for the privacy practices or the content of these other web sites.',
    do_not_track: 'How Do We Respond To Do Not Track Signals?',
    do_not_track_body:
      'Please note that our Site does not support “Do Not Track” browser settings and does not currently participate in any “Do Not Track” frameworks that would allow us to respond to signals or other mechanisms from you regarding the collection of your Personal or Non-Personal Information.',
    assignment: 'Assignment',
    assignment_body:
      'In the event that all or part of our assets are sold or acquired by another party, or in the event of a merger, you grant us the right to assign the Personal and Non-Personal Information collected via the Site.',
    changes: 'Changes to this Privacy Policy',
    changes_body:
      'We reserve the right to modify this Privacy Policy as our services grow and improve or to respond to changing legal regulations or business concerns.  When we do, we will also revise the “effective” date at the top of this Privacy Policy.  For changes to this Privacy Policy that may be materially less restrictive on our use or disclosure of Personal Information you have provided to us, we will attempt to obtain your consent before implementing the change by sending a notice to the primary e-mail address we have on file or by placing a prominent notice on the Site.'
  },
  questions: {
    title: 'Questions?',
    body:
      'For inquiries regarding this Privacy Policy, please send an email to ',
    email: 'info@floodhelpny.org.'
  }
};
