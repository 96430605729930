import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors, getZoneColor } from '../styles/variables';
import LookupError from './sharedComponents/LookupError';
import Loading from './sharedComponents/Loading';
import Button from './ui/Button';
import { useProvider } from '../helpers/hooks';

// called by:
//   ExplorableMapPopup
const FloodZones = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

const BaseFloodElevation = styled.span`
  color: ${colors.heading};
  font-size: 0.8rem;
  bottom: 26px;
  margin-left: 15px;
  width: 100px;
  text-align: left;

  @media (max-width: '768px') {
    display: block;
    position: relative;
  }
`;

const ArrowImage = styled.img`
  align-self: center;
  width: 50px;
  margin: 0 20px;
`;

const LearnMoreContainer = styled.div`
  text-align: center;
  p {
    max-width: none;
  }
`;

const Zone = styled.span`
  color: ${(props) => props.color};
  font-size: 5.8rem;
  font-family: 'GT-Walsheim-Pro-Bold';
  position: relative;
  margin: 0;
`;

const ZoneCopy = styled.span`
  color: ${(props) => props.color};
`;

const ExplorableMapPopupFloodZoneInfo = ({
  propertyInfo,
  futureZone,
  currentZone,
  addressLookupError,
  popupLoading,
  handleProfileCreation
}) => {
  const provider = useProvider('popupFloodZoneInfo');

  const renderCopy = () => {
    if (currentZone !== futureZone) {
      return (
        <p>
          {provider.currentlyIn}
          <ZoneCopy color={getZoneColor(currentZone)}>
            {provider[currentZone]}
          </ZoneCopy>
          {provider.changingTo}
          <ZoneCopy color={getZoneColor(futureZone)}>
            {provider[futureZone]}.
          </ZoneCopy>
        </p>
      );
    }
    return (
      <p>
        {provider.currentlyIn}
        <ZoneCopy color={getZoneColor(currentZone)}>
          {provider[currentZone]}
        </ZoneCopy>
        {provider.stayingAt}
      </p>
    );
  };

  const renderBfe = () => {
    const { future_bfe } = propertyInfo;

    if (future_bfe && future_bfe !== -9999) {
      return (
        <BaseFloodElevation>
          {future_bfe}
          {provider.bfe}
        </BaseFloodElevation>
      );
    }
    return null;
  };

  const renderFloodZoneTransition = () => (
    <>
      <FloodZones>
        <Zone color={getZoneColor(currentZone)} className="zone current-zone">
          {currentZone === 'None' ? 'X' : currentZone}
        </Zone>
        <ArrowImage src={provider.icon} alt="arrow pointing right" />
        <Zone color={getZoneColor(futureZone)} className="zone future-zone">
          {futureZone === 'None' ? 'X' : futureZone}
        </Zone>
        {renderBfe()}
      </FloodZones>
      <LearnMoreContainer>
        {renderCopy()}
        <Button bgColor={colors.resilientBlue} onClick={handleProfileCreation}>
          {provider.button}
        </Button>
      </LearnMoreContainer>
    </>
  );

  if (popupLoading) {
    return <Loading provider={provider} />;
  }

  if (addressLookupError) {
    const errorProvider = provider[addressLookupError];
    return <LookupError provider={errorProvider} />;
  }

  if (currentZone && futureZone) {
    return renderFloodZoneTransition();
  }

  return null;
};

ExplorableMapPopupFloodZoneInfo.propTypes = {
  propertyInfo: PropTypes.object.isRequired,
  futureZone: PropTypes.string.isRequired,
  currentZone: PropTypes.string.isRequired,
  handleProfileCreation: PropTypes.func.isRequired,
  addressLookupError: PropTypes.string,
  popupLoading: PropTypes.bool
};

ExplorableMapPopupFloodZoneInfo.defaultProps = {
  addressLookupError: '',
  popupLoading: true
};

export default ExplorableMapPopupFloodZoneInfo;
