import testimonialPatti from '../images/testimonial-patti.jpg';
import testimonialCarla from '../images/testimonial-carla.jpg';
import testimonialLucia from '../images/testimonial-lucia.jpg';

export default {
  title: 'Повышаются приливы, повышаются тарифы',
  subtitle:
    "Прочитайте важнейших доклад Центра о страховании от наводнения и кризисе доступности Нью-Йорка",
  people: [
    {
      name: 'Патти',
      neighborhood: 'Мидлэнд Бич',
      image: testimonialPatti
    },
    {
      name: 'Карла',
      neighborhood: 'Канарси',
      image: testimonialCarla
    },
    {
      name: 'Люсия',
      neighborhood: 'Кони Айленд',
      image: testimonialLucia
    }
  ],
  explanation:
    "Растущие тарифы на страхование от наводнения и повышающийся уровень моря имеют потенциал окончательно выселить рабочий и средний класс ньюйоркцев из прибрежных районов. Без согласованного вмешательства растущие страховые взносы могут повлечь будущее, в котором побережье города будет доступно только самым богатым жителям.",
  link: 'Дополнительная информация'
};
