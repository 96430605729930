import RtappApplicationState from '../constants/RtappApplicationState';
import worker1 from '../images/worker-1.svg';
import worker2 from '../images/worker-2.svg';
import grantAgreement from '../images/grant-agreement.svg';
import pencilRuler from '../images/pencil-ruler.svg';
import maleUser from '../images/male-user.svg';
import application from '../images/application.svg';
import calendar from '../images/calendar.svg';
import feedback from '../images/feedback.svg';
import piping from '../images/piping.svg';

export default {
  [RtappApplicationState.PRE_APPLICATION]: {
    headerNext: '下一步',
    headerExpect: '预期结果',
    callToAction: '我们正在审查您提交的信息',
    body:
      "请给我们的团队一些时间向您发送计划的完整申请。我们将尽快与您联系。",
    expect:
      '如果您有资格参加该计划，您将收到一封电子邮件，邀请您填写申请表。您也可以随时在网站上查看您的状态是否发生了变化。',
    toProfile: '带我查看洪水风险简介',
    icon: application
  },
  [RtappApplicationState.PRE_APPLICATION_APPROVED]: {
    callToAction: '现在：填写申请表',
    body:
      '需要帮助吗？致电纽约市社区中心，646-786-0888 或发送电子邮件至info@floodhelpny.org.如果对计划有任何疑问，请随时联系我们。',
    expect:
      '我们的团队正在努力审查所有申请，希望在未来2-3周内回复您。我们知道这是一个漫长的等待，感谢您的耐心！一旦您的状态发生变化，我们将立即向您发送电子邮件，但您也可以继续查看此处的更新信息。如果您想填写纸质申请表，请下载并将以下表格提交给中心。',
    buttonText: '申请',
    link: 'https://www.floodhelpny.org/samples/auditApplication.pdf',
    linkText: '申请表',
    link2: 'https://www.floodhelpny.org/samples/tenantIncome.pdf',
    linkText2: '租户收入核实表',
    icon: application
  },
  [RtappApplicationState.APPLICATION_SUBMITTED]: {
    headerNext: '下一步',
    headerExpect: '预期结果',
    callToAction: '您完成了第一步！',
    body:
      '我们正在审查您的申请资格，如果需要更多信息，我们将与您联系。有问题吗？致电纽约市邻里中心（646）786-0888或发送电子邮件至info@floodhelpny.org',
    expect:
      '如果计划获得批准，您将收到我们的电子邮件，要求您签署房主补助协议，让中心向符合条件的房主分配联邦资金。',
    icon: grantAgreement
  },
  [RtappApplicationState.HOMEOWNER_GRANT]: {
    headerExpect: '预期结果',
    callToAction: '下载并签署房主补助协议',
    body:
      '您将通过电子邮件收到房主补助协议副本。您需要下载并通过传真、电子邮件或快递将房主补助协议返还给我们。房产契约上列出的所有个人必须签署协议这样，中心将联邦资金分配给计划中符合条件的房主。',
    faq:
      '要了解如何将协议返还给我们，请单击右侧“您可能正在思考”下的常见问题。',
    contactUs:
      '还有问题吗？请致电646-786-0888或发送电子邮件至info@floodhelpny.org.',
    expect:
      '在收到您的房主补助协议后，纽约市邻里中心签署协议，并将一份副本发送给该计划的资助者——纽约州州长风暴恢复办公室。文件审核后，我们将安排您的审计。这可能需要长达四周的时间。',
    icon: grantAgreement
  },
  [RtappApplicationState.REJECTED]: {
    body:
      '很抱歉地通知您，您目前无法通过此计划获得服务。但是，您仍然可以使用FloodHelpNY.org 的资源来检查洪水风险，并学习如何变得更有弹性。对您的申请有疑问吗？请致电646-786-0888与我们联系。有关其他资格信息，请查看右侧的常见问题解答。'
  },
  [RtappApplicationState.WITHDRAWN]: {
    body:
      '感谢您对住宅回水阀安装项目的兴趣。您的案例已经正式退出此项目。您仍然可以检查洪水风险，并查看我们的洪水保险资源。如果您想继续该计划，请致电646-786-0888或发送电子邮件至info@floodhelpny.org.我们将继续处理您的申请。'
  },
  [RtappApplicationState.FEASIBILITY_REVIEW]: {
    headerNext: '下一步',
    headerExpect: '预期结果',
    scheduled: '已安排您的可行性审查',
    body:
      '完成房屋弹性审计的工程师需要回到您的房屋中，获取更多信息，确定您的房屋是否可以受益于下水道回水阀。您必须在家里，工程师需要进入整个房屋，包括地下室和室外区域。',
    icon: pencilRuler,
    expect:
      "在某些情况下，安装下水道回水阀对房屋不会带来益处。您的工程师会询问一些问题，并拍摄照片和测量结果，便于他们做出决定，如果适用，他们会为安装下水道回水阀制定施工计划。我们希望在工程师完成可行性审核后的三到六周内回复您。",
    noDate:
      '请拨打电话与我们联系，确认您的可行性审核日期和时间。'
  },
  [RtappApplicationState.AUDIT_SCHEDULED]: {
    headerNext: '下一步',
    headerExpect: '预期结果',
    scheduled: '已安排您的审核',
    body:
      '请确保您在约定时间回家，让工程师完成评估。预计审计所需时间不到两个小时。如果您无法回家进行预定的审计或取消预约，请尽快拨打电话通知我们，',
    phone: '646-786-0888',
    phoneLink: '+6467860888',
    body2: ' 这样我们可以在工作时间内重新安排。',
    expect:
      '工程师和随行人员需要进入家中的所有区域，包括地下室或夹层和后院，确保您收到准确的报告。如果工程师未在预定时间的30分钟内到达您家，请致电（646）786-0888。',
    noDate:
      '请拨打以下电话联系我们，确认您的审核日期和时间。'
  },
  [RtappApplicationState.AUDIT_COMPLETE]: {
    headerNext: '下一步',
    headerExpect: '预期结果',
    callToAction: '安排咨询会议',
    body:
      '我们的房主中心会有人打电话给您，让您在接下来的48小时内预约弹性顾问。如果您对计划或日程安排仍有疑问，请致电',
    phone: '(646) 786-0888.',
    phoneLink: '+6467860888',
    expect:
      '预约将持续45分钟到一小时。在此期间，我们的一位顾问将向您介绍工程师在访问期间获取的信息，并讨论最适合您家的解决策略。我们的顾问还将讨论资金方案，并帮助您制定一个符合预算的计划来保护您的房屋。',
    icon: grantAgreement
  },
  [RtappApplicationState.CONSULTATION_SCHEDULED]: {
    headerNext: '下一步',
    headerExpect: '预期结果',
    scheduled: '已安排您的咨询',
    body:
      '顾问将讨论您目前和未来的洪水风险，以及您根据工程师的评估可能采取的任何行动。请携带以下文件（如有）：1）洪水保险申报页面；2） 旧的标高证明（如适用）；以及3）注明当前抵押状态的抵押声明或其他文件',
    phone: '646-786-0888',
    phoneLink: '+6467860888',
    body2:
      ' 或发送电子邮件至info@floodhelpny.org.如果您需要取消或重新安排，请立即通知我们',
    expect:
      '您的顾问是一位行业专家，将讨论决定洪水保险费的具体因素。他们将审查您的标高证明和技术报告，为您提供言简意赅的指导。咨询是双向的，欢迎您提出问题！在本次预约结束时，您将了解洪水保险的费用，评定是否正确，常见的缓解方案，以及回水阀是否对您的住房有益。',
    noDate:
      '请拨打电话联系我们，确认您的咨询日期和时间。'
  },
  [RtappApplicationState.CONSULTATION_COMPLETE]: {
    callToAction: '欢迎参加回水阀项目',
    body:
      "您已经完成了咨询并接受了审计。现在是时候谈一谈安装下水道回水阀了。在接下来的几周内，我们将指导您完成安装过程。",
    contact:
      "如果想更详细地讨论该计划，请致电中心 ",
    phone: '646-786-0888',
    phoneLink: '+6467860888',
    contact2: ' 或发送电子邮件 ',
    email: 'info@floodhelpny.org.',
    images: [{ icon: piping, iconAltText: '管道' }]
  },
  [RtappApplicationState.INSTALLATION_PREPARE]: {
    callToAction: '我们正在努力寻找合适的人',
    body:
      "我们将您的团队团结在一起，确保我们能够提供最好的服务。我们将在六到八周内联系您，介绍您的安装团队。同时，您最新的报告如下。",
    contact: '请随时拨打 ',
    phone: '646-786-0888',
    phoneLink: '+6467860888',
    contact2: ' 或通过电子邮件向我们提出任何问题 ',
    email: 'info@floodhelpny.org.',
    images: [{ icon: pencilRuler, iconAltText: '铅笔和尺子' }]
  },
  [RtappApplicationState.MEET_YOUR_TEAM]: {
    headerNext: '下一步',
    headerExpect: '预期结果',
    callToAction: '我们共同开展工作！',
    body:
      '现在是时候在启动会议上与安装回水阀的人见面了。除了工程师，我们还挑选了一名水管工和检查员共同开展工作。如有任何问题，请随时与我们联系，电话：（646）786-0888或，电子邮件：info@floodhelp.org',
    images: [
      { icon: worker1, iconAltText: '工人' },
      { icon: maleUser, iconAltText: '男性' },
      { icon: worker2, iconAltText: '工人' }
    ],
    link: 'https://www.floodhelpny.org/samples/Tri-PartyAgreement.pdf',
    linkText: '三方协议',
    scheduled: '施工前会议',
    expect:
      '我们将安排一个时间与您的团队会面。除了了解合作的人员之外，您和水管工以及中心还要签署三方协议。',
    noDate:
      '请拨打上述电话联系我们，确认您的安装日期和时间。'
  },
  [RtappApplicationState.INSTALLATION_TO_BE_SCHEDULED]: {
    headerNext: '下一步',
    headerExpect: '预期结果',
    callToAction:
      '检查员将与您一起安排安装',
    body:
      "准备好安装回水阀！通常需要大约八个小时的时间，但根据您家中的排水管状况，水管工可能需要另一天才能完成工作。记住，您必须在家才能完成这项工作。在安装过程中，水管工需要关闭家中的供水。也就是说，在安装过程中，您无法使用家中的管道系统（马桶、淋浴器、水槽、洗碗机等），因此请提前做出安排。如有任何问题，欢迎致电646-786-0888或发送电子邮件至info@floodhelpny.org。",
    expect:
      '安装所需的时间取决于您房屋的布局及其与城市下水道的连接，以及将您家与城市下水道连接的管道状况。我们将尽早估计这需要的时间，但请注意，时间可能会有一些变动。安装阀门的管道工会告诉您阀门的位置，工作原理，以及维护方法。此外，安装完成后，您会收到一份现场报告，包含阀门制造商提供的具体运维说明。',
    icon: calendar
  },
  [RtappApplicationState.INSTALLATION_HAS_BEEN_SCHEDULED]: {
    callToAction: '您的检验员已安排了阀门安装',
    body:
      '专业的水管工和检验员将为您安装下水道回水阀。如果您对安装有任何疑问，请致电检验员。请确保安装的时候您在家中。',
    contact:
      "安装所需的时间取决于您房屋的布局及其与城市下水道的连接，以及将您家与城市下水道连接的管道状况。我们将尽早估计这需要的时间，但请注意，时间可能会有一些变动。安装阀门的管道工会告诉您阀门的位置，工作原理，以及维护方法。此外，安装完成后，您会收到一份现场报告，包含阀门制造商提供的具体运维说明。",
    images: [{ icon: calendar, iconAltText: '日历' }]
  },
  [RtappApplicationState.INSTALLATION_COMPLETE]: {
    headerNext: '下一步',
    headerExpect: '预期结果',
    callToAction: '告诉我们项目进展',
    body:
      '祝贺您已经安装了新回水阀！我们希望您对流程进行反馈。如果您有三分钟的时间，请进行下面的简短调查。您将在本页底部找到与您的阀门相关的一些重要文件。一如既往，如果您有任何问题或疑虑，请致电（646）786-0888或发送电子邮件至info@floodhelp.org.',
    expect:
      '等待我们的电子邮件，或两到三周后查看本网站，接收您的所有文件，包括如何维护回水阀的信息以及制造商保修。',
    link: 'https://cnycn.tfaforms.net/12',
    linkText: '调查',
    icon: feedback
  },
  [RtappApplicationState.REPORT_COMPLETE]: {
    firstBody: '弹性报告如下：',
    secondBody:
      ' 提供一些关于如何使您的房屋和财产更加安全的技术建议，以及与您阀门相关的重要文件。'
  }
};
