import axios from 'axios';

const APICommunicator = {
  get: async (url, params) => {
    const request = await axios.get(url, { params });
    const { data } = request;
    return data;
  },
  post: async (url, payload) => {
    const request = await axios.post(url, payload, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    const { data } = request;
    return data;
  },
  put: async (url, payload) => {
    const request = await axios.put(url, payload, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    const { data } = request;
    return data;
  },
  formDataPost: async (url, payload) => {
    const request = await axios.post(url, payload, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    const { data } = request;
    return data;
  }
};

export default APICommunicator;
