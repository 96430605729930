export default {
  meta: {
    title: 'Terminos y Condiciones',
    url: 'https://www.floodhelpny.org/es/terms'
  },
  title: 'TÉRMINOS Y CONDICIONES',
  national_flood_insurance_policy: 'National Flood Insurance Program',
  privacy_policy: 'Privacy Policy',
  general_disclaimer: {
    title:
      'DESCARGO GENERAL DE RESPONSABILIDAD Y LIMITACIÓN DE RESPONSABILIDAD',
    paragraph_1:
      'Los materiales e información contenidos en FloodHelpNY.org (el "Sitio") (que incluyan cualquier enlace a otros sitios web y contenido encontrado en los sitios enlazados) se proporcionan "tal cual" y garantías de cualquier índole, ya sea en forma explícita o implícita, incluyendo, entre otros, cualquier garantía implícita de comercialización o de adaptabilidad con un fin especifico. Ni el Center for New York City Neighborhoods, Inc. (el "Centro") ni la Oficina de Recuperación ante Tormentas del Gobernador ("GOSR") asumen ninguna responsabilidad de ningún tipo, ya sea contractual, de agravio, de responsabilidad objetiva o de cualquier otro tipo, por daños y perjuicios de cualquier tipo ocasionados como consecuencia del uso de este Sitio. Debido a la naturaleza de Internet, el Centro no garantiza que el acceso a este Sitio sea sin interrupciones o sin errores. El Centro no garantiza ni realiza ninguna manifestación con respecto a la utilidad o los resultados esperados del material contenido en este Sitio.',
    paragraph_2:
      'Creemos que la información provista en este sitio es correcta y hacemos todo lo posible para reducir los errores. Sin embargo, como tanto los humanos como las computadoras están sujetos a errores, y como la información puede cambiar rápidamente, no podemos garantizar absolutamente la exactitud de la información provista. Si encuentra un error o una inexactitud, póngase en contacto con nosotros por correo electrónico a ',
    email: 'info@floodhelpny.org.',
    paragraph_3:
      'Las estimaciones de costo de seguro y los costos de retroadaptación se proveen para propósitos ilustrativos solamente. Usted es responsable de verificar la exactitud e idoneidad de todas las estimaciones de costos. Pida el consejo de personas certificadas y/o competentes antes de realizar cualquier tipo de inversión o decisiones económicas de planificación. No se base solamente en las estimaciones de costos y la información económica que hay en este Sitio.',
    paragraph_4:
      'Usted acepta, al acceder a este Sitio, que bajo ninguna circunstancia ni teorías de responsabilidad legal de ningún tipo bajo leyes internacionales o civiles, ordinarias o estatutarias, incluyendo sin limitación la responsabilidad legal estricta, la negligencia u otras teorías de derechos de daños o leyes de contratos, patentes o derechos de autor se hará el Centro legalmente responsable de daños y perjuicios de ningún tipo que se den como resultado del uso del Sitio o de cualquier información, bienes o servicios obtenidos en este Sitio, incluyendo daños directos, indirectos, consecuentes, incidentales o punitivos (incluso si se ha advertido al Centro de la posibilidad de tales daños), hasta el máximo grado permitido por la ley. Algunas jurisdicciones no permiten la exclusión o limitación de ciertos daños y perjuicios, así puede que algunas de estas limitaciones no le apliquen a usted.'
  },
  flood_zones: {
    title: 'ZONAS DE INUNDACIÓN',
    part_1:
      'Los resultados de zona de inundación, elevación, y riesgo de inundación de las ubicaciones específicas que se muestran en este Sitio son para información general únicamente y usted no debe basarse en ello para determinar si se le requiere que obtenga seguro de inundación o que emprenda algún otro tipo de acción. Tenga en cuenta que la posición de los límites de las zonas de inundación puede ser inexactos en algunas áreas, y puede que el localizador de direcciones no sea exacto al 100% para identificar su dirección. Los propietarios deben ponerse en contacto con el  ',
    part_2:
      ' para mayor información o para ver una copia oficial de los Mapas de Precios de Seguro de Inundación y hablar de las elevaciones y zonas de inundación en las cercanías de su propiedad antes de empezar cualquier tipo de actividad de construcción.  Además, por lo general, la información provista en este Sitio es específicamente para las inundaciones costeras y es posible que no sea indicativa de otras formas de riesgo de inundación, tales como inundaciones de ríos u otras causas. Finalmente, cierta información provista en este Sitio relacionada con las elevaciones y el riesgo de daños de inundación se basa en el mapa de precios del Programa Nacional de Seguro de Inundación y otros productos que no tienen en cuenta riesgos futuros debidos a la elevación del nivel del mar debido al calentamiento global. Para obtener información acerca de la manera en que puede utilizarse su información personal, consulte nuestra '
  },
  flood_insurance_estimate: {
    title: 'ESTIMACIÓN DE SEGURO DE INUNDACIÓN',
    paragraph_1:
      'Al elegir el uso de este Sitio para obtener una estimación de seguro de inundación, accede a vincularse a estos términos y condiciones. Si no acepta estos términos y condiciones, no debe enviar una solicitud de estimación de precio de seguro de inundación.',
    paragraph_2:
      'No somos una compañía de seguros y no ofrecemos ni proveemos seguro de inundación. Su solicitud de estimación de precio de seguro de inundación no resultará en la entrada en vigor de ninguna cotización u oferta de proveer cobertura de seguro ni ningún tipo de cobertura de seguro. No se le imputará al Centro ningún tipo de responsabilidad legal como resultado de su solicitud de estimación de precio de seguro de inundación. La cobertura de seguro de inundación puede obtenerse solamente cuando haga una solicitud de seguro de inundación a una compañía de seguros que provea seguro de inundación y un proveedor de seguros acepte su solicitud, demonstrándolo por medio de una póliza específica emitida por el proveedor de seguros.',
    paragraph_3:
      'Toda su estimación de seguro de inundación, incluyendo la prima estimada y los posibles términos de cobertura son tentativos y están sujetos a cambios sin aviso según todos y cada uno de los criterios de contratación de seguros, precios de referencia, fechas de precios, manuales de normas, planes de precios, y clasificaciones utilizadas por el ',
    paragraph_4_part_1: 'Lea nuestra ',
    paragraph_4_part_2:
      ' para entender la manera en que puede utilizarse la información personal que provea en su solicitud de estimación de seguro de inundación. Aunque el Centro ha tomado precauciones para proteger la información que transmita en su solicitud de estimación de seguro de inundación, el Centro no garantiza que la información de ningún tipo no vaya a ser interceptada, examinada, retransmitida, diseminada o utilizada por terceros no autorizados por medio de la internet o de alguna otra manera.',
    paragraph_5:
      'El Centro se reserva el derecho de monitorizar este Sitio web para identificar intentos no autorizados de interceptar o cambiar información o causar daños. Al utilizar este Sitio, usted da su consentimiento para tal monitorización. Los intentos no autorizados de interceptar o cambiar información, o de causar daños están estrictamente prohibidos y pueden resultar en sanciones legales.'
  },
  home_resiliency_audit: {
    title: 'AUDITORÍA DE RESILIENCIA DE VIVIENDAS',
    paragraph_1:
      'Al elegir el uso de este Sitio para solicitar y tomar parte en el programa de Auditoría de Resiliencia de Viviendas, accede a vincularse a estos términos y condiciones. Si no acepta estos términos y condiciones, no debe enviar una solicitud para tomar parte en el programa de Auditoría de Resiliencia de Viviendas.',
    paragraph_2:
      'No podemos garantizar que todos los propietarios de viviendas que deseen recibir una Auditoría de Resiliencia de Viviendas vayan a recibirla. En el presente, el programa está disponible solamente para los propietarios de viviendas elegibles situadas en vecindarios específicos de la Ciudad de Nueva York y tiene limitaciones respecto al número de participantes que pueden acomodarse.',
    paragraph_3:
      'El Centro ha seleccionado las firmas de ingeniería que llevarán a cabo las Auditorías de Resiliencia de Viviendas por medio de un proceso competitivo de adquisición que buscó firmas cualificadas de arquitectura, topografía, e ingeniería. Uno de los criterios utilizados por el Centro a la hora de seleccionar firmas de ingeniería fue que las firmas demostraran un entendimiento exhaustivo de la manera en que la Agencia Federal de Administración de Emergencias (Federal Emergency Management Agency - FEMA) determina la prima de seguro de inundación de los edificios bajo el Programa Nacional de Seguro de Inundación (National Flood Insurance Program - NFIP) y la manera en que las medidas de mitigación de daños de inundación afectan el precio de la prima de seguro de NFIP que se aplica a un edificio.',
    paragraph_4:
      'No es el Centro mismo el que va a llevar a cabo las Auditorías de Resiliencia de Viviendas y, por consiguiente, no puede garantizar los resultados del trabajo llevado a cabo por las firmas de ingeniería ni la exactitud de la información provista.',
    paragraph_5:
      'El Centro también ha seleccionado proveedores de asesoramiento y servicios legales para proporcionar asesoramiento a propiedades residenciales de 1-4 familias en los vecindarios de la Ciudad de Nueva York con alto impacto de inundación como parte del programa de Auditoría de Resiliencia de Viviendas a través de un proceso competitivo de adquisición. Estas agencias de asesoramiento proporcionarán consejos y ayuda técnica individualmente para los servicios de auditoría de resiliencia. Uno de los criterios utilizados por el Centro para seleccionar las agencias de asesoramiento fue que las agencias de asesoramiento demostraran competencia técnica y económica y que tuvieran experiencia con el trabajo a nivel comunitario.',
    paragraph_6:
      'No es el Centro mismo el que va a proveer el asesoramiento de viviendas ni los servicios legales y, por consiguiente, no puede garantizar la exactitud o el nivel de trabajo provisto por las agencias de asesoramiento.',
    paragraph_7:
      'Usted debe utilizar la Auditoría de Resiliencia de Viviendas con propósitos informativos e ilustrativos solamente. Usted es responsable de verificar la exactitud e idoneidad de todas las suposiciones y todos los cálculos contenidos en la auditoría. Pida el consejo de personas certificadas y/o competentes antes de realizar cualquier tipo de inversión o decisión económica de planificación. No se base únicamente en la Auditoría de Resiliencia de Viviendas, las calculadoras/los cálculos de estimaciones económicas o de precios de seguro de inundación, o la información económica que hay en este Sitio.',
    paragraph_8_part_1: 'Lea nuestra ',
    paragraph_8_part_2:
      ' para entender la manera en que puede utilizarse la información personal que provea en su solicitud para tomar parte en el programa de Auditoría de Resiliencia de Viviendas. Aunque el Centro ha tomado precauciones para proteger la información que transmita en su solicitud para tomar parte en el programa de Auditoría de Resiliencia de Viviendas y la información generada como parte del programa, el Centro no garantiza que la información no vaya a ser interceptada, examinada, retransmitida, diseminada o utilizada por terceros no autorizados a través de la internet o de alguna otra manera.'
  },
  backwater_valve: {
    title: 'PROGRAMA DE INSTALACIÓN DE VÁLVULAS ANTIRRETORNO',
    paragraph_1:
      'Al elegir el uso de este Sitio para solicitar y tomar parte en el programa de Instalación de Válvulas Antirretorno, accede a vincularse a estos términos y condiciones. Si no acepta estos términos y condiciones, no debe enviar una solicitud para tomar parte en el programa de Instalación de Válvulas Antirretorno.',
    paragraph_2:
      'No podemos garantizar que todos los propietarios de viviendas que deseen recibir una instalación de válvula antirretorno vayan a recibirla. En el presente, el programa está disponible solamente para los propietarios de viviendas elegibles con ingresos bajos a moderados con viviendas situadas en vecindarios específicos de la Ciudad de Nueva York y tiene limitaciones respecto al número de participantes que pueden acomodarse. Para ser elegibles para una instalación de una válvula antirretorno, los propietarios de viviendas deben someterse a una Auditoría de Resiliencia de Viviendas y el ingeniero debe determinar que la propiedad podría beneficiarse de la instalación de una válvula antirretorno.',
    paragraph_3:
      'No es el Centro mismo el que va a llevar a cabo las instalaciones de válvulas antirretorno y, por consiguiente, no puede garantizar los resultados del trabajo llevado a cabo por las firmas que van a instalar las válvulas antirretorno. El Centro identificará un fontanero maestro certificado de un conjunto de firmas pre-cualificadas para que complete el proceso de instalación para los propietarios de viviendas elegibles. El Centro le pagará al fontanero maestro directamente el costo de instalar la válvula. Los propietarios de viviendas deben examinar las cualificaciones de los fontaneros maestros independientemente antes de acceder a permitir que se lleve a cabo cualquier tipo de instalación.',
    paragraph_4:
      'Aunque la instalación de una válvula antirretorno puede reducir el riesgo de daños debidos a atascamientos de alcantarillado, el Centro no garantiza que los atascamientos de alcantarillado u otras formas de daños no vayan a ocurrir después de que se instale la válvula. El propietario de vivienda tiene la responsabilidad de entender la manera en que opera la válvula, mantener la válvula, inspeccionarla regularmente, y mantenerla en buenas condiciones de operación.',
    paragraph_5:
      'El Centro no proveerá servicios de mantenimiento o inspección después de la instalación de la válvula.',
    paragraph_6:
      'Hay riesgos asociados con la instalación de una válvula antirretorno. Pueden darse daños a la vivienda si los dispositivos de fontanería, tales como las duchas, los lavaderos, y los inodoros se utilizan durante la instalación de la válvula. En caso de inundación o tormenta fuerte, la válvula puede fallar y pueden darse daños a la propiedad como resultado de ello.',
    paragraph_7:
      'Los propietarios de viviendas que reciban una instalación de válvula deben:',
    paragraph_7_part_1:
      'Estudiar detalladamente las instrucciones de operación y mantenimiento provistas por el fabricante de la válvula y seguir estas instrucciones.',
    paragraph_7_part_2:
      'Evitar poner pañuelitos, toallas de papel, productos sanitarios, aceites, o grasas en los inodoros o lavaderos. Estos artículos se pueden acumular y hacer que falle la válvula.',
    paragraph_7_part_3:
      'Inspeccionar la válvula antes de una tormenta. La válvula puede fallar durante una inundación si hay artículos bloqueando la clapeta de la válvula. Busque artículos que puedan bloquear la válvula y quítelos. ',
    paragraph_8:
      'Los propietarios de viviendas que reciban una instalación de válvula deben comprometerse a mantener su póliza de seguro de propietario de vivienda a su propio cargo, incluyendo seguro de atascamientos de alcantarillado, si está disponible. También deben tener seguro de inundación que cubra el costo del subsidio de la instalación. ',
    paragraph_9:
      'Lea nuestra Política de Privacidad para entender la manera en que puede utilizarse la información personal que provee en su solicitud para tomar parte en el programa de Instalación de Válvulas Antirretorno. Aunque el Centro ha tomado precauciones para proteger la información que transmita en su solicitud para tomar parte en el programa de Instalación de Válvulas Antirretorno y la información generada como parte del programa, el Centro no garantiza que la información no vaya a ser interceptada, examinada, retransmitida, diseminada o utilizada por terceros no autorizados a través de la internet o de alguna otra manera.'
  }
};
