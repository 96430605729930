import RtappApplicationState from '../constants/RtappApplicationState';
import worker1 from '../images/worker-1.svg';
import worker2 from '../images/worker-2.svg';
import grantAgreement from '../images/grant-agreement.svg';
import pencilRuler from '../images/pencil-ruler.svg';
import maleUser from '../images/male-user.svg';
import application from '../images/application.svg';
import calendar from '../images/calendar.svg';
import feedback from '../images/feedback.svg';
import piping from '../images/piping.svg';

export default {
  [RtappApplicationState.PRE_APPLICATION]: {
    headerNext: 'What You Can Do Next',
    headerExpect: 'What To Expect',
    callToAction: 'We’re reviewing the information you submitted',
    body:
      "Please give our team some time to send you a full application for the program. We'll follow up with you as soon as possible.",
    expect:
      'If you are eligible for the program, you will receive an email with an invitation to fill out an application. Feel free to also check back on the website to see if your status has changed. ',
    toProfile: 'Take me to my flood risk profile',
    icon: application
  },
  [RtappApplicationState.PRE_APPLICATION_APPROVED]: {
    callToAction: 'Now: Fill Out the Application',
    body:
      'Need help? Call the center for NYC neighborhoods at 646-786-0888 or email us at info@floodhelpny.org. Feel free to ask any questions you have about our program.',
    expect:
      'Our team is working hard to review all of the applications, and we hope to get back to you within the next 2-3 weeks. We know it’s a long wait, and we appreciate your patience! We will email you as soon as your status changes, but you can also keep checking here for updates. If you would rather fill out a paper application form download and submit the forms below to the Center.',
    buttonText: 'Apply',
    link: 'https://www.floodhelpny.org/samples/auditApplication.pdf',
    linkText: 'Application Form',
    link2: 'https://www.floodhelpny.org/samples/tenantIncome.pdf',
    linkText2: 'Tenant Income Verification Form',
    icon: application
  },
  [RtappApplicationState.APPLICATION_SUBMITTED]: {
    headerNext: 'What You Can Do Next',
    headerExpect: 'What To Expect',
    callToAction: 'You’ve taken the first step!',
    body:
      'We’re reviewing your application for eligibility now and will contact you if we need additional information. Have questions? Call the Center for NYC Neighborhoods at (646) 786-0888 or email us at info@floodhelpny.org',
    expect:
      'If you’re approved for the program, you’ll receive an email from us asking you to sign a Homeowner Grant Agreement, which allows the Center to distribute federal funds to eligible homeowners.',
    icon: grantAgreement
  },
  [RtappApplicationState.HOMEOWNER_GRANT]: {
    headerExpect: 'What To Expect',
    callToAction: 'Download and Sign the Homeowner Grant Agreement',
    body:
      'You will receive a copy of your Homeowner Grant Agreement by email. You will need to download and return the homeowner grant agreement to us by fax, email, or postal service. The agreement must be signed by all individuals listed on the deed for the property, and allows the Center to distribute federal funds to eligible homeowners in the program.',
    faq:
      'To learn how to return the agreement to us, click on the FAQ on the right-hand side under the "You Might be Thinking".',
    contactUs:
      'Still have questions? Contact us at 646-786-0888 or email us at info@floodhelpny.org.',
    expect:
      'After we have received your Homeowner Grant Agreement, it will be signed by the Center for NYC Neighborhoods and a copy will be sent to the funder of the program, the New York State Governor’s Office of Storm Recovery. Once the documentation has been reviewed, we will schedule your audit. This may take up to four weeks.',
    icon: grantAgreement
  },
  [RtappApplicationState.REJECTED]: {
    body:
      'Unfortunately, you are currently ineligible for services through this program.  However, you can still use the resources at FloodHelpNY.org to check your flood risk and to learn about ways to become more resilient. Have questions about your application? Contact us at 646-786-0888. For additional eligibility information, please review the FAQs on the right-hand side.'
  },
  [RtappApplicationState.WITHDRAWN]: {
    body:
      'Thank you for your interest in the Residential Backwater Valve Installation Program. Your case has been formally withdrawn from this program. You can still check your flood risk and review our resources on flood insurance. If you would like to continue with the program, please call us at 646-786-0888 or email info@floodhelpny.org. We will resume processing your application.'
  },
  [RtappApplicationState.FEASIBILITY_REVIEW]: {
    headerNext: 'What You Can Do Next',
    headerExpect: 'What To Expect',
    scheduled: 'Your feasibility review is scheduled',
    body:
      'The engineer who completed your Home Resiliency Audit needs to return to your home to get some additional information to determine whether or not your home could benefit from a sewer backwater valve. You will have to be home and the engineer will need access throughout your property, including the basement and outdoor area. ',
    icon: pencilRuler,
    expect:
      "In some cases, installing a sewer backwater valve will not benefit your home. Your engineer will ask you some questions and take some photos and measurements that they'll need to make their determination and, if applicable, create the construction plans for your sewer backwater valve installation. We hope to get back to you three to six weeks after the engineer completes the feasibility review.",
    noDate:
      'Please call us at the number below to confirm your feasibility review date and time.'
  },
  [RtappApplicationState.AUDIT_SCHEDULED]: {
    headerNext: 'What You Can Do Next',
    headerExpect: 'What To Expect',
    scheduled: 'Your audit is scheduled',
    body:
      'Please make sure you are home at your scheduled time to allow the engineer to complete the assessment. You should expect the audit to take less than two hours. If you are unable to be home for your scheduled audit or cancel your appointment, please call us as soon as possible at ',
    phone: '646-786-0888',
    phoneLink: '+6467860888',
    body2: ' during business hours to reschedule.',
    expect:
      'The engineer and accompanying staff will need access to all areas of the home, including the basement or crawlspace and backyard in order to ensure that you receive an accurate report. If the engineer has not arrived at your home within 30 minutes of your scheduled time, please call us at (646) 786-0888.',
    noDate:
      'Please call us at the number below to confirm your audit date and time.'
  },
  [RtappApplicationState.AUDIT_COMPLETE]: {
    headerNext: 'What You Can Do Next',
    headerExpect: 'What To Expect',
    callToAction: 'Schedule your counseling session',
    body:
      'Someone from our Homeowner Hub will call you to schedule an appointment with a resiliency counselor in the next 48 hours. If you still have questions about the program or scheduling, you can call one of our representatives at ',
    phone: '(646) 786-0888.',
    phoneLink: '+6467860888',
    expect:
      'The appointment will last somewhere between 45 minutes and an hour. During that time, one of our counselors will talk you through the information that the engineer captured during their visit and discuss the mitigation strategies that are best suited for your home. Our counselor will also discuss financing options and help you to create a plan to protect your home that fits within your budget.',
    icon: grantAgreement
  },
  [RtappApplicationState.CONSULTATION_SCHEDULED]: {
    headerNext: 'What You Can Do Next',
    headerExpect: 'What To Expect',
    scheduled: 'Your consultation is scheduled',
    body:
      'The counselor will discuss your current and future flood risk, as well as any actions you may be able to take based on the engineer’s assessment. Please bring the following documents if available: 1) Flood insurance declaration page; 2) old elevation certificate, if applicable; and 3) mortgage statement or other documents showing current mortgage status ',
    phone: '646-786-0888',
    phoneLink: '+6467860888',
    body2:
      ' or email us at info@floodhelpny.org. Also let us know immediately if you need to cancel or reschedule',
    expect:
      'Your counselor is an industry expert who will discuss the specific factors that determine your flood insurance premium. They will review your elevation certificate and technical report to provide you with jargon-free guidance. Counseling is a two-way street — you are encouraged to bring questions! At the end of this appointment, you will have an understanding of what flood insurance may cost, if you are being rated correctly, common mitigation options, and if a backwater valve would benefit your home.',
    noDate:
      'Please call us at the number below to confirm your consultation date and time.'
  },
  [RtappApplicationState.CONSULTATION_COMPLETE]: {
    callToAction: 'Welcome to the backwater valve program',
    body:
      "You've finished your counseling and received an audit. Now it's time to talk about installing your sewer backwater valve. Over the next few weeks, we'll walk you through the process of getting it installed.",
    contact:
      "If you'd like to discuss the program in more detail, you can call the Center at ",
    phone: '646-786-0888',
    phoneLink: '+6467860888',
    contact2: ' or email us at ',
    email: 'info@floodhelpny.org.',
    images: [{ icon: piping, iconAltText: 'Piping' }]
  },
  [RtappApplicationState.INSTALLATION_PREPARE]: {
    callToAction: 'We’re trying to find the right people',
    body:
      "We are putting your team together to ensure we can provide you with the best service possible. We'll be in touch in six to eight weeks to introduce your installation team. In the meantime, the latest version of your report is available below.",
    contact: 'Feel free to call at ',
    phone: '646-786-0888',
    phoneLink: '+6467860888',
    contact2: ' or email us with any questions at ',
    email: 'info@floodhelpny.org.',
    images: [{ icon: pencilRuler, iconAltText: 'Pencil and Ruler' }]
  },
  [RtappApplicationState.MEET_YOUR_TEAM]: {
    headerNext: 'What You Can Do Next',
    headerExpect: 'What To Expect',
    callToAction: 'We’ll do this together!',
    body:
      'Now it’s time for you to meet the people who will install your backwater valve at your kick-off meeting. Besides your engineer, we have selected a plumber and inspector to work with you. Feel free to contact us at (646) 786-0888 or at info@floodhelp.org with any questions.',
    images: [
      { icon: worker1, iconAltText: 'Worker' },
      { icon: maleUser, iconAltText: 'Man' },
      { icon: worker2, iconAltText: 'Worker' }
    ],
    link: 'https://www.floodhelpny.org/samples/Tri-PartyAgreement.pdf',
    linkText: 'Triparty Agreement',
    scheduled: 'Pre-Construction Meeting',
    expect:
      'We’ll schedule a time for you to meet your team. Besides getting to know who you’ll be working with, you’ll also be asked to sign a Tri-Party agreement between you, the plumber, and the Center. ',
    noDate:
      'Please call us at the number above to confirm your installation date and time.'
  },
  [RtappApplicationState.INSTALLATION_TO_BE_SCHEDULED]: {
    headerNext: 'What You Can Do Next',
    headerExpect: 'What To Expect',
    callToAction:
      'Your inspector will work with you to schedule your installation',
    body:
      "Get ready for your backwater valve installation! This will usually take about eight hours--but the plumber may have to return another day to finish up, depending on how accessible your home's drain pipes are. Remember that you have to be home for this work to take place. During the installation, your plumber will need to shut off your home's water. This means you won't be able to use your home's plumbing systems (toilets, showers, sinks, dishwashers, etc.) during the installation so please make alternative arrangements beforehand. Feel free to call us at 646-786-0888 or email us at info@floodhelpny.org with any questions.",
    expect:
      'The amount of time the installation takes depends on the layout of your home and its connection to the city sewer, as well as the condition of the pipe connecting your home to the city sewer. We’ll give an estimate of how long we think this will take as early as we can, but please note that some flexibility may be needed. The plumber who installs your valve will show you where the valve is, how it works, and how to maintain it. In addition, you’ll receive a field report when the installation is complete containing specific operation and maintenance instructions from the manufacturer of your valve. ',
    icon: calendar
  },
  [RtappApplicationState.INSTALLATION_HAS_BEEN_SCHEDULED]: {
    callToAction: 'Your inspector has scheduled your valve installation',
    body:
      'The licensed plumber and inspector are scheduled for your sewer backwater valve installation. If you have any questions regarding the installation, please call your inspector. Please make sure you will be home for the installation. ',
    contact:
      "The amount of time the installation takes depends on the layout of your home and its connection to the city sewer, as well as the condition of the pipe connecting your home to the city sewer. We'll give an estimate of how long we think this will take as early as we can, but please note that some flexibility may be needed. The plumber who installs your valve will show you were the valve is, how it works, and how to maintain it. In addition, you'll receive a field report when the installation is complete containing specific operation and maintenance instructions from the manufacturer of your valve. ",
    images: [{ icon: calendar, iconAltText: 'Calendar' }]
  },
  [RtappApplicationState.INSTALLATION_COMPLETE]: {
    headerNext: 'What You Can Do Next',
    headerExpect: 'What To Expect',
    callToAction: 'Tell us how it went',
    body:
      'Congratulations on your new backwater valve! We’d love your feedback on how the process went. If you have three minutes, please take our brief survey below. You’ll find some important documents related to your valve at the bottom of this page. As always, if you have any questions or concerns you can call us at (646) 786-0888 or email us at info@floodhelp.org.',
    expect:
      'Wait for an email from us or check back on this website in two to three weeks to receive all your documents, including information on how to maintain your backwater valve as well as the manufacturer’s warranty. ',
    link: 'https://cnycn.tfaforms.net/12',
    linkText: 'Survey',
    icon: feedback
  },
  [RtappApplicationState.REPORT_COMPLETE]: {
    firstBody: 'This is the resiliency report for: ',
    secondBody:
      ' with some technical recommendations on how to make your home and finances more flood-safe, as well as important documents related to your valve.'
  }
};
