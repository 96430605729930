import testimonialPatti from '../images/testimonial-patti.jpg';
import testimonialCarla from '../images/testimonial-carla.jpg';
import testimonialLucia from '../images/testimonial-lucia.jpg';

export default {
  title: 'Mare k Ap Monte, Tarif k Ap Monte',
  subtitle:
    'Li rapò istorik Sant lan sou asirans kont inondasyon epi kriz aksesibilite Vil New York lan',
  people: [
    {
      name: 'Patti',
      neighborhood: 'Midland Beach',
      image: testimonialPatti
    },
    {
      name: 'Carla',
      neighborhood: 'Canarsie',
      image: testimonialCarla
    },
    {
      name: 'Lucia',
      neighborhood: 'Coney Island',
      image: testimonialLucia
    }
  ],
  explanation:
    'Tarif asirans k ap monte yo ak nivo lanmè k ap monte gen potansyèl pou deplase nan yon fason pèmanan abitan New York nan klas ouvriyè ak klas mwayen yo nan katye litoral yo. San yon entèvansyon konjwen, prim asirans k ap monte yo ta ka mennen nan yon demen kote kot vil lan aksesib sèlman ak rezidan ki pi rich li yo.',
  link: 'Aprann plis'
};
