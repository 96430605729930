import React from 'react';
import styled from 'styled-components';
import { colors } from '../styles/variables';
import EmailSignup from './EmailSignup';
import ButtonLink from './ui/ButtonLink';
import cnycnLogo from '../images/cnycn-logo.svg';
import gosrLogo from '../images/gosr-logo.svg';
import mocejWhite from '../images/mocej-logo-white.svg';
import stateLogo from '../images/state-logo.svg';
import { useProvider, useLocale } from '../helpers/hooks';

const Wrapper = styled.footer`
  color: white;
  font-size: small;
  padding: 5rem;
  display: flex;
  justify-content: center;
  background-color: ${colors.footerBackground};

  @media (max-width: 420px) {
    padding: 3rem 1rem;
  }
`;

const ContentWrapper = styled.div`
  max-width: 1250px;

  @media (max-width: 420px) {
    width: 100%;
  }
`;

const TopRow = styled.div`
  display: flex;
  margin-bottom: 2rem;

  p {
    margin-bottom: 1rem;
  }

  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;

const Social = styled.div`
  p {
    color: white;
  }

  @media (max-width: 1200px) {
    margin-bottom: 2rem;
  }
`;

const ShareButtons = styled.div`
  display: flex;
  flex-flow: row nowrap;

  a {
    margin-right: 1rem;

    @media (max-width: 768px) {
      margin: 1rem;
      width: 250px;
    }

    @media (max-width: 500px) {
      margin: 1rem;
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const BottomRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: center;
  }
`;

const InternalLinks = styled.div`
  display: flex;

  * {
    color: ${colors.mutedGray};
    margin-right: 0.7rem;
  }
`;

const ExternalLinks = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;

  a {
    margin: 1rem 0;
  }

  @media (max-width: 420px) {
    flex-direction: column;
  }
`;

const Logo = styled.img`
  min-width: 130px;
  max-height: 35px;
  min-height: 17px;
  margin: 0 0.8rem;

  @media (max-width: 420px) {
    max-height: 70px;
  }
`;

const Footer = () => {
  const provider = useProvider('footer');
  const locale = useLocale();

  return (
    <Wrapper>
      <ContentWrapper>
        <TopRow>
          <Social>
            <p>{provider.tellYourFriends}</p>
            <ShareButtons>
              <ButtonLink
                url={`https://www.facebook.com/sharer/sharer.php?u=https%3A//floodhelpny.org&quote=${provider.message}`}
                text={provider.facebook}
                color="#fff"
                ghost
              />
              <ButtonLink
                url={`http://twitter.com/intent/tweet?text=${provider.message}`}
                text={provider.twitter}
                color="#fff"
                ghost
              />
              <ButtonLink
                url={`mailto:?subject=${provider.emailSubject}&body=${provider.message}`}
                text={provider.email}
                color="#fff"
                ghost
              />
            </ShareButtons>
          </Social>
          <EmailSignup />
        </TopRow>
        <BottomRow>
          <InternalLinks>
            <a href={`/${locale}/about`}>{provider.about}</a>
            <a href={`/${locale}/privacy`}>{provider.privacy}</a>
            <a href={`/${locale}/terms`}>{provider.termsAndConditions}</a>
            <a href={`/${locale}/widget-information`}>{provider.widget}</a>
            <span>
              &copy; {new Date().getFullYear()} {provider.cnycn}
            </span>
          </InternalLinks>
          <ExternalLinks>
            <a
              href="http://cnycn.org"
              target="_blank"
              rel="noreferrer noopener"
            >
              <Logo
                alt="Center for New York City Neighborhoods"
                src={cnycnLogo}
              />
            </a>
            <a
              href="http://stormrecovery.ny.gov"
              target="_blank"
              rel="noreferrer noopener"
            >
              <Logo alt="Governors Office of Storm Recovery" src={gosrLogo} />
            </a>
            <a
              href="http://www.ny.gov"
              target="_blank"
              rel="noreferrer noopener"
            >
              <Logo alt="State of New York" src={stateLogo} />
            </a>
            <a
              href="https://www1.nyc.gov/site/orr/index.page"
              target="_blank"
              rel="noreferrer noopener"
            >
              <Logo
                src={mocejWhite}
                alt="Mayor's Office of Climate & Environmental Justice logo"
              />
            </a>
          </ExternalLinks>
        </BottomRow>
      </ContentWrapper>
    </Wrapper>
  );
};

export default Footer;
