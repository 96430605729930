import greenTelephone from '../images/green-telephone.svg';
import seaWaves from '../images/sea-waves.svg';
import strategy from '../images/strategy.svg';
import training from '../images/training.svg';

export default {
  header: 'WHAT YOU CAN DO',
  callsToAction: [
    {
      header: 'Start Your Business Continuity Plan Today',
      icon: strategy,
      iconAlt: 'Strategy',
      body:
        'Use this framework to quickly bounce back after any interruption. Protect your investments, stay in touch with employees and vendors, and be ready to respond to uncertainty.',
      buttonText: 'View',
      buttonLink:
        'https://www.nyc.gov/html/nycbe/downloads/pdf/EM_SBSBCPWorksheetsMar2017.pdf'
    },
    {
      header:
        'Make Sure Your Business is Prepared. Attend a Workshop or Webinar.',
      icon: training,
      iconAlt: 'Training',
      body:
        'From gas leaks and fires, to major storms and cyber threats, NYC business owners face a variety of unexpected emergencies. SBS offers free emergency preparedness workshops and webinars for businesses across New York City.',
      buttonText: 'Learn More Here',
      buttonLink:
        'https://www1.nyc.gov/nycbusiness/article/preparedness-response-recovery'
    },
    {
      icon: seaWaves,
      iconAlt: 'waves',
      header: 'Growing Threat of Climate Change',
      body:
        'With rising sea levels, more extreme and frequent weather events brought on by a changing climate, now is the time to start preparing. Climate change is a threat to our communities and as the backbone of our communities and economy, small businesses are a vital element to fostering a resilient city.',
      buttonText: 'Read Now',
      buttonLink:
        'https://onenyc.cityofnewyork.us/stories/a-livable-climate/#overview'
    },
    {
      icon: greenTelephone,
      iconAlt: 'telephone',
      header: 'Contact SBS Emergency Response Unit for Immediate Assistance',
      body:
        'For immediate assistance, contact SBS’ Emergency Response Unit. The team can provide you with the latest emergency information on street closures, utilities disruptions, security, and demolitions. They can also connect you to local business organizations for additional assistance.',
      buttonText: 'Learn More',
      buttonLink:
        'https://www1.nyc.gov/nycbusiness/article/preparedness-response-recovery'
    }
  ]
};
