import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '../../styles/variables';
import FloodZoneRate from './FloodZoneRate';
import RateAdjuster from './RateAdjuster';
import { useLocale, useProvider, useApi } from '../../helpers/hooks';

const Header = styled.div`
  border-bottom: solid 1px ${colors.grayScale[1]};
  width: 100%;
  padding: 10px 0;
  margin-bottom: 1em;
  align-items: baseline;
`;

const RateCalculatorResult = ({ showSummary, property }) => {
  const provider = useProvider('rateCalculatorResult');
  const locale = useLocale();
  const { rateCalculatorApi } = useApi();

  const minimumDeductible = property.pre_firm ? 1500 : 1000;

  const [calculatorInputs, setCalculatorInputs] = useState({
    ...property,
    building_coverage: 250000,
    content_coverage: 100000,
    building_deductible: minimumDeductible,
    content_deductible: minimumDeductible,
    primary_residence: true
  });

  const updateCoverage = (updates) => {
    const updatedInputs = {
      ...calculatorInputs,
      ...updates
    };
    setCalculatorInputs(updatedInputs);
  };

  const renderFuture =
    property.current_flood_zone !== property.future_flood_zone ||
    property.current_bfe !== property.future_bfe;
  let arrow;
  let futureRate;
  let adjuster;
  let summaryHeader;
  if (renderFuture) {
    arrow = (
      <div className="arrow-icon">
        <span className="lnr lnr-arrow-right" />
      </div>
    );
    futureRate = (
      <div className="rate future-rate">
        <div className="map-type">
          {provider.futureMapStart +
            property.future_flood_zone +
            provider.futureMapEnd}
        </div>

        <FloodZoneRate
          provider={provider.floodZoneRateProvider}
          calculatorApi={rateCalculatorApi}
          calculatorInputs={calculatorInputs}
          future
        />
      </div>
    );
  }

  if (!showSummary) {
    adjuster = (
      <RateAdjuster
        provider={provider.rateAdjusterProvider}
        calculatorInputs={calculatorInputs}
        updateCoverage={updateCoverage}
      />
    );
  } else {
    summaryHeader = (
      <Header className="promo">
        <h4>{provider.header}</h4>
        <a href={`/${locale}/rate/${property.external_id}`}>{provider.link}</a>
      </Header>
    );
  }

  return (
    <div>
      <div className="promo promo--rate">
        {summaryHeader}
        <h4 className="beta">{provider.beta}</h4>

        <div className="rate current-rate">
          <div className="map-type">
            {provider.currentMapStart +
              property.current_flood_zone +
              provider.currentMapEnd}
          </div>
          <FloodZoneRate
            provider={provider.floodZoneRateProvider}
            calculatorApi={rateCalculatorApi}
            calculatorInputs={calculatorInputs}
            future={false}
          />
        </div>
        {arrow}
        {futureRate}
        <p className="micro">{provider.assumingMax}</p>

        <p className="micro">
          {provider.numbersAreEstimates}
          <a
            target="_blank"
            rel="noreferrer noopener"
            href={`/${locale}/terms`}
          >
            {provider.termsAndConditions}
          </a>
        </p>
      </div>
      {adjuster}
    </div>
  );
};

RateCalculatorResult.propTypes = {
  property: PropTypes.object.isRequired,
  showSummary: PropTypes.bool.isRequired
};

export default RateCalculatorResult;
