import carpet from '../images/carpet.svg';
import piping from '../images/piping.svg';
import plumbing from '../images/plumbing.svg';
import temperatureInside from '../images/temperature-inside.svg';
import bacteria from '../images/bacteria.svg';
import fillInBasement from '../images/fill-in-basement.svg';
import elevate from '../images/elevate.svg';
import elevateMechanicals from '../images/elevate-mechanicals.svg';
import abandonFirstFloor from '../images/abandon-first-floor.svg';
import floodVents from '../images/flood-vents.svg';
import backwaterValve from '../images/backwater-valve.svg';

export default {
  meta: {
    title: 'Модификации от наводнения | Защитите вашу собственность | FloodHelpNY.org',
    description: `Модификации от наводнения могут помочь вам внизить ваш раск наводнения. Вот что вам нужно знать.`,
    url: 'https://www.floodhelpny.org/ru/flood-retrofits',
    imageAlt: 'прибрежные волны и город Нью-Йорк'
  },
  learnMore: 'Дополнительная информация',
  title: 'Модификации от наводнения: Защитите вашу собственность',
  pageSubtitle:
    'Существует несколько стратегий, которые вы можете предпринять, чтобы снизить ваш риск наводнения. Некоторые являются серьезными изменениями, а другие могут быть быстрыми и простымии решениями по сокращению потенциального ущерба, наненсенного сточными водами.',
  subtitle:
    'Существует несколько стратегий, которые вы можете предпринять, чтобы снизить ваш риск наводнения. Некоторые являются серьезными изменениями, а другие могут быть быстрыми и простымии решениями по сокращению потенциального ущерба, наненсенного сточными водами.',
  overview: {
    title: 'Обзор',
    body:
      'Наши прибрежные районы являются большой частью того, что делает Нью-Йорк уникальным. Городская береговая линия, простирающаяся на 520 миль, существенноо изменяется от одного района к другому - усеянная бунгало в некоторых зонах, плотно упакованная рядами домов в других. Это изменение в плотности и типах домов делает унифицированный подход к устойчивости против наводнения нереалистичным для Нью-Йорка. К счастью, существует множество разных путей поддержания наших районов и укрепления нашего побережья.'
  },
  options: {
    title: 'Варианты мер по предотвращению или снижению рисков',
    body:
      'Некоторые варианты устойчивости треубуют больших затрат, чем другие, некоторые требуют больших усилий для внедрения, а некоторые дают больший эффект, чем другие. Все эти копмпромиссы должны быть учтены при выборе правильного для вас варианта. В настоящее время есть только небольшое количество мер, которые влияют на страховые взносы: подъем; засыпка подвала или технического подпола (и сырой противопаводковой защиты, при необходимости); установка паводковых отверстий; и подъем механического оборудования из подвала (данный вариант дает наименьшее сокращение страховки, но может сэкономить вам большую сумму в случае наводнения). Есть несколько других действий, которые вы можете предпринять, и они могут не понизить ваши страховые тарифы, но могут помочь защитить ваш дом от ущерба, нанесенного наводнением. Примечательно, что лбюая из этих мер может сократить взносы вне зависимости от зоны наводнения.',
    list: [
      {
        title: 'Засыпьте ваш подвал',
        body: "Сокращает повреждение фундамента вашего дома.",
        image: fillInBasement,
        imageAlt: 'Засыпьте ваш подвал',
        link: 'fill-in-basement'
      },
      {
        title: 'Поднимите вашу собственность',
        body:
          'Располагает ваш дом в полной мере выше потенциального уровня наводнения.',
        image: elevate,
        imageAlt: 'Поднимите вашу собственность',
        link: 'elevate'
      },
      {
        title: 'Поднимите ваши механические устройства',
        body: 'Защищает критические системы вашего дома.',
        image: elevateMechanicals,
        imageAlt: 'Поднимите ваши механические устройства',
        link: 'elevate-mechanicals'
      },
      {
        title: 'Покиньте ваш первый этаж',
        body: 'Это эффективно повышает базовую высоту вашего дома.',
        image: abandonFirstFloor,
        imageAlt: 'Покиньте ваш первый этаж',
        link: 'abandon-first-floor'
      },
      {
        title: 'Установите паводковые отверстия',
        body:
          'Сократите риск повреждения сточными водами, предоставляя им возможность протекать насквозь и вытекать.',
        image: floodVents,
        imageAlt: 'Установите паводковые отверстия',
        link: 'flood-vents'
      },
      {
        title: 'Установите канализационный обратный клапан',
        body:
          'Канализационный обратный клапан предназначен для того, чтобы не давать возможность неочищенным сточным водам попадать в ваш дом в случае разлива канализации.',
        image: backwaterValve,
        imageAlt: 'Установите канализационный обратный клапан',
        link: 'backwater-valve'
      }
    ]
  },
  retrofitting: {
    title: 'Инструкция модификации городского планирования',
    body:
      'Наиболее полный анализ вариантов модификаций, имеющихся для зданий в зоне затоплений Нью-Йорка на данные момент. ',
    view: 'Вид'
  },
  budget: {
    title: 'Меры по предотвращению или снижению рисков без лишних затрат',
    subtitle:
      'Есть несколько других действий, которые вы можете предпринять, и они могут не понизить страховые тарифы, но могут помочь сократить ущерб от наводнения.',
    list: [
      {
        header: 'Замена ковра плиткой',
        body:
          'Ваша ванная комната защищена от воды - почему подвал не может быть тоже? Если в вашем подвале будет плитка вместо ковра, уборка будет легче, и вы предотвратите нанесение серьезного ущерба несколькими дюймами сточной воды.',
        image: carpet
      },
      {
        header: 'Установите устройства для предотвращения обратного течения воды',
        body:
          'Относительно недорогие и простые в установке устройства для предотвращения обратного течения воды предотвращают канализационные и дренажные воды от проникновения в ваш дом через раковины, туалеты и ванные, когда системы вокруг вашего дома переполнены паводковой водой.',
        image: piping
      },
      {
        header: 'Установите насос для откачки сточных вод',
        body:
          ' Насос для откачки сточных вод помогает откачивать утечку и воду, проникающую в здания через трещины в фундаменте, пористые поверхности и другие места проникновения, такие как подземные кабельные каналы.',
        image: plumbing
      },
      {
        header: 'Защита от наводнения внутренних систем здания',
        body:
          'Для этого требуется герметизация зданий, чтобы не пропускать воду, используя пассивные или активные устройства, такие как бетонные кольцевые стены вокруг отопительного котла, укрепленные против наводнения двери или барьеры.',
        image: temperatureInside
      },
      {
        header: 'Установите материалы, устойчивые к ущербу от наводнения',
        body:
          'Материалы, такие как гипсикартон без бумажного покрытия и пол из бетонно-мозаичной плитки в качестве строительных материалов и отделки сокращают ущерб от наводнения и ускоряют очистку после наводнения. Этот вариант может также включать в себя использование шкафчиков, устойчивых к наводнениям и замена деревянных предметов, таких как двери, металлом или другими материалами с водонепроницаемым покрытием.',
        image: bacteria
      }
    ]
  }
};
