import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ImageRadioButtons from './sharedComponents/ImageRadioButtons';

const RateCalculatorQuestionBuildingType = ({
  provider,
  answers,
  setAnswers,
  setNextQuestion
}) => {
  useEffect(() => {
    if (answers.buildingType) {
      setNextQuestion('preFirm');
    }
  }, [answers, setNextQuestion]);

  return (
    <div className="question building-type-question">
      <h4>{provider.question}</h4>

      <ImageRadioButtons
        responseField={provider.responseField}
        answer={answers.buildingType}
        setResponse={(e) =>
          setAnswers((prevAnswers) => ({
            ...prevAnswers,
            buildingType: e.target.value
          }))}
      />
    </div>
  );
};

RateCalculatorQuestionBuildingType.propTypes = {
  provider: PropTypes.object.isRequired,
  answers: PropTypes.object.isRequired,
  setAnswers: PropTypes.func.isRequired,
  setNextQuestion: PropTypes.func.isRequired
};

export default RateCalculatorQuestionBuildingType;
