import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import RadioButtons from './sharedComponents/RadioButtons';
import ContentLocations from '../constants/ContentLocations';
import ContentLocationNavigator from '../helpers/calculatorNavigation/ContentLocationNavigator';

const RateCalculatorQuestionContentLocation = ({
  provider,
  answers,
  setAnswers,
  setNextQuestion
}) => {
  useEffect(() => {
   if (answers.contentLocation) {
      setNextQuestion(ContentLocationNavigator.nextComponent(answers));
    }
  }, [answers, setNextQuestion]);

  const removeOption = (options, value) => {
    options.forEach((option, index) => {
      if (option.value === value) {
        options.splice(index, 1);
      }
    });
  };

  const renderRadioButtons = () => {
    const { responseField } = provider;
    const filteredOptions = responseField.options.slice();
    const hasMoreThanOneFloor = answers.moreThanOneFloor;
    if (hasMoreThanOneFloor !== null && hasMoreThanOneFloor !== undefined) {
      removeOption(filteredOptions, ContentLocations.BASEMENT_AND_ABOVE);
      removeOption(filteredOptions, ContentLocations.ENCLOSURE_AND_ABOVE);
    }
    return (
      <RadioButtons
        responseField={{ key: responseField.key, options: filteredOptions }}
        answer={answers.contentLocation}
        setResponse={(e) =>
          setAnswers((prevAnswers) => ({
            ...prevAnswers,
            contentLocation: e.target.value
          }))}
      />
    );
  };

  return (
    <div className="question building-feature-question">
      <h4>{provider.question}</h4>
      {renderRadioButtons()}
      <div className="promo">
        *
        {provider.disclaimer}
      </div>
    </div>
  );
};

RateCalculatorQuestionContentLocation.propTypes = {
  provider: PropTypes.object.isRequired,
  answers: PropTypes.object.isRequired,
  setAnswers: PropTypes.func.isRequired,
  setNextQuestion: PropTypes.func.isRequired
};

export default RateCalculatorQuestionContentLocation;
