export default {
    meta: {
      title: '洪水风险正在上升|洪水新闻和信息|FloodHelpNY.org',
      ogTitle: '洪水新闻和信息|FloodHelpNY.org',
      description:
        '气候变化增加了纽约的洪水风险。阅读有关洪水保险政策、气候变化的最新新闻',
      url: 'https://www.floodhelpny.org/zh-CN/news',
      imageAlt: 'FloodHelpNY 新闻'
    },
    title: '新闻',
    subHeading:
      '获取有关洪水、洪水风险和极端天气的最新信息，以及如何确保财产和财务安全的提示。',
    created: '已出版',
    updated: '已更新',
    noPosts: '没有发布',
    emailSubject: '看看FHNY上的这篇新闻吧！',
    tweet: '更多信息请访问 https://www.floodhelpny.org/zh-CN/news',
    search: '在此处搜索新闻帖',
    share: '分享'
  };