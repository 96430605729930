import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors, cubicBezier } from '../styles/variables';
import RoundIcon from './RoundIcon';
import { useProvider } from '../helpers/hooks';

const Link = styled.a`
  border-radius: 2px;
  text-align: center;
  max-width: 300px;
  min-width: 250px;
  height: 240px;
  background-color: #fff;
  transition: box-shadow 0.25s ${cubicBezier.ease};
  cursor: pointer;
  border: ${(props) =>
    props.withBorders ? `1px ${colors.grayScale[1]} solid` : 'none'};
  padding: 1.5rem;
  margin: 1.5rem;

  &:hover {
    text-decoration: none;
    box-shadow: inset 0 0 0 10px ${colors.accent.main};
  }
`;

const Icon = styled(RoundIcon)`
  margin: 0 auto 1.5rem;
`;

const Header = styled.h4`
  margin: 0 auto;
  max-width: 200px;
  min-width: 100px;
  font-size: 1.5rem;
`;

const ResourceLink = ({ link, src, alt, header, withBorders }) => {
  const provider = useProvider('resources');

  return (
    <Link href={link} withBorders={withBorders}>
      <Icon src={src} alt={alt} />
      <Header>{provider[header]}</Header>
    </Link>
  );
};

ResourceLink.propTypes = {
  link: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  withBorders: PropTypes.bool
};

ResourceLink.defaultProps = {
  withBorders: false
};
export default ResourceLink;
