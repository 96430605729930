export default {
  header: '您的报告',
  copy: [
    '这份弹性报告提供了',
    '一些关于如何使您的房屋和财产变得更加安全的技术建议。'
  ],
  links: {
    property: '房产',
    rate: '保险费率',
    resiliency: '弹性策略',
    documents: '文件'
  }
};
