import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Landing from './Landing';
import Profile from './Profile';
import Audit from './Audit';
import About from './About';
import Privacy from './Privacy';
import StormwaterFlooding from './StormwaterFlooding';
import HurricaneSeason from './HurricaneSeason';
import BackwaterValveProgram from './BackwaterValveProgram';
import BackwaterValve from './BackwaterValve';
import ExplorableMap from './ExplorableMap';
import Retrofits from './Retrofits';
import Mitigation from './Mitigation';
import Terms from './Terms';
import Rate from './Rate';
import RateCalculator from './RateCalculator';
import SmallBusinesses from './SmallBusinesses';
import News from './News';
import InsuranceShopping from './InsuranceShopping';
import UnderstandingFloodInsurance from './UnderstandingFloodInsurance';
import ClimateChange from './ClimateChange';
import ElevationCertificates from './ElevationCertificates';
import NotFound from './NotFound';
import { useLocale } from '../helpers/hooks';
import Register from './Register';
import WidgetLanding from './WidgetLanding';
import ParametricProgram from './ParametricProgram';
import ParametricProgramApplication from './ParametricProgramApplication';
// This is a list of all of our routes and the corresponding content to be
// rendered on that route

const AppRouter = () => {
  const locale = useLocale();
  return (
    <Switch>
      <Route path={`/${locale}/about`}>
        <About locale={locale} />
      </Route>
      <Route path={`/${locale}/audit-status/:applicationId`}>
        <Audit />
      </Route>
      <Route path={`/${locale}/mitigation/backwater-valve`}>
        <BackwaterValve />
      </Route>
      <Route path={`/${locale}/backwater-valve-program`}>
        <BackwaterValveProgram />
      </Route>
      <Route path={`/${locale}/explore-map`}>
        <ExplorableMap />
      </Route>
      <Route path={`/${locale}/prepare-for-hurricane-season`}>
        <HurricaneSeason />
      </Route>
      <Route path={`/${locale}/stormwater-flooding`}>
        <StormwaterFlooding />
      </Route>
      <Route exact path={`/${locale}`}>
        <Landing />
      </Route>
      <Route exact path="/">
        <Landing />
      </Route>
      <Route
        path={`/${locale}/mitigation/:mitigationOption(abandon-first-floor|flood-vents|fill-in-basement|elevate-mechanicals|elevate)`}
      >
        <Mitigation />
      </Route>
      <Route path={`/${locale}/privacy`}>
        <Privacy />
      </Route>
      <Route path={`/${locale}/profile/:propertyId`}>
        <Profile />
      </Route>
      <Route path={`/${locale}/flood-retrofits`}>
        <Retrofits />
      </Route>
      <Route path={`/${locale}/terms`}>
        <Terms />
      </Route>
      <Route path={`/${locale}/understanding-flood-insurance`}>
        <UnderstandingFloodInsurance />
      </Route>
      <Route path={`/${locale}/shopping-for-flood-insurance`}>
        <InsuranceShopping />
      </Route>
      <Route path={`/${locale}/small-business`}>
        <SmallBusinesses locale={locale} />
      </Route>
      <Route path={`/${locale}/news`}>
        <News />
      </Route>
      <Route path={`/${locale}/climate-change`}>
        <ClimateChange />
      </Route>
      <Route path={`/${locale}/risk-rating`}>
        <Register />
      </Route>
      <Route path={`/${locale}/prepare-for-hurricane-season`}>
        <HurricaneSeason />
      </Route>
      <Route path={`/${locale}/elevation-certificates`}>
        <ElevationCertificates />
      </Route>
      <Route path={`/${locale}/widget-information`}>
        <WidgetLanding />
      </Route>
      {/* Rate Calc Routes that are not currently used
        // TODO add propertyId urlParam to rateCalc and rate componnets
      <Route path={`/${locale}/rate-calculator/:propertyId`}>
        <RateCalculator locale={locale} />
      </Route>
      <Route path={`/${locale}/rate/:propertyId`}>
        <Rate locale={locale} />
      </Route> */}
      {/* Legacy URLs */}
      <Redirect
        from={`/${locale}/prepare`}
        to={`/${locale}/prepare-for-hurricane-season`}
      />
      <Redirect
        from={`/${locale}/resiliency`}
        to={`/${locale}/flood-retrofits`}
      />
      <Redirect
        from={`/${locale}/homeowners`}
        to={`/${locale}/backwater-valve-program`}
      />
      <Redirect
        from={`/${locale}/rtapp_status/:applicationId`}
        to={`/${locale}/audit-status/:applicationId`}
      />
      <Redirect from={`/${locale}/explore_map`} to={`/${locale}/explore-map`} />
      <Redirect from="/homeowners" to="/en/backwater-valve-program" />
      <Redirect from="/privacy" to="/en/privacy" />
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
};

export default AppRouter;
