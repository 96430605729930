import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors, borderRadius } from '../styles/variables';
import { useProvider } from '../helpers/hooks';
import Button from './ui/Button';

const Container = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  text-align: center;
  width: ${(props) => (props.explorable ? 'auto' : '75%')};
  position: relative;
  margin: ${(props) => (props.explorable ? '0' : '20vh auto')};
  padding: ${(props) => (props.explorable ? '2rem' : '4rem')};
  border-radius: ${borderRadius};
  background-color: white;

  @media (max-width: 540px) {
    margin: 0;
    width: auto;
    height: 100%;
    overflow-y: scroll;
  }
`;

const Header = styled.h5`
  margin: 1rem;
`;

const Disclaimer = styled.p`
  margin: 0 0 10px;
  color: black;
`;

const Links = styled.div`
  margin-top: 1rem;
  font-size: 1rem;
`;

const Actions = styled.div`
  display: flex;
  justify-content: space-evenly;

  button {
    margin: 1rem 2rem;
  }

  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;

// called by:
//  EligibilityLookup & ExplorableMap
const TermsAndConditionsModal = ({ agree, disagree, explorable }) => {
  const provider = useProvider('termsAndConditions');

  return (
    <Container explorable={explorable}>
      <Header>{provider.title}</Header>
      <Disclaimer>{provider.disclaimerOne}</Disclaimer>
      <Disclaimer>{provider.disclaimerTwo}</Disclaimer>
      <Links>
        <span>{provider.links.sentenceOpener}</span>
        <a
          target="_blank"
          rel="noreferrer noopener"
          href={provider.links.termsLinkUrl}
        >
          {provider.links.termsLinkText}
        </a>
        <span>{provider.links.conjunction}</span>
        <a
          target="_blank"
          rel="noreferrer noopener"
          href={provider.links.privacyLinkUrl}
        >
          {provider.links.privacyLinkText}
        </a>
        <Actions>
          <Button bgColor={colors.pinkBerry} ghost onClick={disagree}>
            {provider.navigation.disagree}
          </Button>
          <Button onClick={agree}>{provider.navigation.agree}</Button>
        </Actions>
      </Links>
    </Container>
  );
};

TermsAndConditionsModal.propTypes = {
  agree: PropTypes.func.isRequired,
  disagree: PropTypes.func.isRequired,
  explorable: PropTypes.bool
};

TermsAndConditionsModal.defaultProps = {
  explorable: false
};

export default TermsAndConditionsModal;
