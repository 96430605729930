import { useContext, useMemo, useState, useEffect } from 'react';
import LocaleContext from '../context/LocaleContext';
import ApiContext from '../context/ApiContext';
import GoogleMapsContext from '../context/GoogleMapsContext';
import FaqContext from '../context/FaqContext';
import { range, DOTS } from './PaginationHelper';

// Hooks that use Context only work properly when called in a component that is
// nested in a <Context.Provider> tag somewhere up the tree. If not, the default
// "value" for that Context will be returned.
const useLocale = () => useContext(LocaleContext);

const useProvider = (fileName) => {
  const locale = useLocale();

  const provider = require(`../providers/${fileName}.${locale}`).default;
  return provider;
};

const useApi = () => useContext(ApiContext);

const useGoogleMaps = () => useContext(GoogleMapsContext);

const useFaq = () => {
  const faqData = useProvider('faqData');
  return {
    faqs: faqData,
    ...useContext(FaqContext)
  };
};

const usePagination = ({ totalPageCount, siblingCount = 1, currentPage }) => {
  const paginationRange = useMemo(() => {
    // Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
    const totalPageNumbers = siblingCount + 5;

    // Case 1:
    // If the number of pages is less than the page numbers we want to show in our
    // paginationComponent, we return the range [1..totalPageCount]
    if (totalPageNumbers >= totalPageCount) {
      return range(1, totalPageCount);
    }

    // Calculate left and right sibling index and make sure they are within range 1 and totalPageCount
    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(
      currentPage + siblingCount,
      totalPageCount
    );

    // We do not show dots just when there is just one page number to be inserted between the extremes of sibling and the page limits i.e 1 and totalPageCount.
    // Hence we are using leftSiblingIndex > 2 and rightSiblingIndex < totalPageCount - 2
    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;
    const firstPageNumber = 1;
    const lastPageNumber = totalPageCount;

    // Case 2: No left dots to show, but rights dots to be shown
    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftItemCount = 3 + 2 * siblingCount;
      const leftRange = range(1, leftItemCount);

      return [...leftRange, DOTS, totalPageCount];
    }

    // Case 3: No right dots to show, but left dots to be shown
    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightItemCount = 3 + 2 * siblingCount;
      const rightRange = range(
        totalPageCount - rightItemCount + 1,
        totalPageCount
      );
      return [firstPageNumber, DOTS, ...rightRange];
    }

    // Case 4: Both left and right dots to be shown
    if (shouldShowLeftDots && shouldShowRightDots) {
      const middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageNumber, DOTS, ...middleRange, DOTS, lastPageNumber];
    }
  }, [siblingCount, currentPage, totalPageCount]);

  return paginationRange;
};

const useWindowWidth = () => {
  const [width, setWidth] = useState(window.innerWidth);
  let isMounted = true;

  useEffect(() => {
    window.addEventListener('resize', () => {
      if (isMounted) {
        setWidth(window.innerWidth);
      }
    });

    return () => {
      window.removeEventListener('resize', () => {
        setWidth(window.innerWidth);
      });
      isMounted = false;
    };
  }, []);

  return width;
};

export {
  useLocale,
  useProvider,
  useApi,
  useGoogleMaps,
  useFaq,
  usePagination,
  useWindowWidth
};
