export default {
  homepage: 'FloodHelpNY',
  languageTitle: 'Languages',
  about: 'О нас',
  languages: {
    english: 'English',
    spanish: 'Español',
    russian: 'Русский',
    chinese: '中文',
    haitian: 'Kreyòl ayisyen'
  },
  resources: {
    insurance: 'Страховка',
    retrofits: 'Модификации',
    hurricane: 'Сезон ураганов',
    smallBusinesses: 'Малый бизнес',
    news: 'Новости',
    stormwaterFlooding: 'Ливневые паводки',
    climateChange: 'Изменение климата'
  }
};

