import lookupErrorText from './LookupErrors.en';
import arrow from '../images/arrow.svg';

export default {
  icon: arrow,
  loadingText: '',
  minimalHazardZone: 'This address is in a minimal hazard flood zone.',
  bfe: '" Base Flood Elevation (BFE)',
  button: 'Learn More',
  X: 'moderate risk flood zone',
  AE: 'high risk zone',
  VE: 'very high risk zone',
  None: 'minimal hazard zone',
  tryAnotherAddress: 'Please try another address.',
  errorText:
    "We're sorry. We were unable to find information for the address you entered. Please verify the address or try again later. If you feel you received this message in error, please call our Homeowner Hub at 646-786-0888.",
  currentlyIn: 'This property is currently in a ',
  changingTo: ', but it’s likely changing to a ',
  stayingAt: ", and it's likely staying at the same risk level.",
  ...lookupErrorText
};
