import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '../styles/variables';
import AuditCalculatorFormatter from '../helpers/AuditCalculatorFormatter';
import RateCalculatorCommunicator from '../communicators/RateCalculatorCommunicator';
import SalesforceCommunicator from '../communicators/SalesforceCommunicator';
import ElevateBuildingRecommendationHelper from '../helpers/auditRecommendation/ElevateBuildingRecommendationHelper';
import { useApi } from '../helpers/hooks';

const DifferenceSubheader = styled.p`
  color: red;
  font-size: 1rem;
`;

const NoSavings = styled.span`
  font-size: 1rem;
`;

const Savings = styled.span`
  color: ${colors.sourApple};
  font-family: GT-Walsheim-Pro-Medium;
`;

const PerYear = styled.span`
  color: ${colors.grayScale[2]};
`;

const AuditRateDifference = ({
  provider,
  property,
  audit,
  applicationId,
  useCurrent
}) => {
  const { rateCalculatorApi, salesforceApi } = useApi();
  const [unmitigatedRate, setUnmitigatedRate] = useState(null);
  const [mitigatedRate, setMitigatedRate] = useState(null);

  useEffect(() => {
    // TODO may need to add async/await the two const's below
    const unmitigated = AuditCalculatorFormatter.formatCalculatorParams(
      property,
      audit,
      useCurrent
    );
    const mitigated = ElevateBuildingRecommendationHelper.formatMitigatedRate(
      property,
      audit,
      useCurrent
    );
    const getRateCalculatorData = async () => {
      const rateKey = useCurrent
        ? 'current_elevate_building_rate'
        : 'advisory_elevate_building_rate';
      try {
        const unmitigatedRateResult = await RateCalculatorCommunicator.getRate(
          rateCalculatorApi,
          unmitigated
        );
        const mitigatedRateResult = await RateCalculatorCommunicator.getRate(
          rateCalculatorApi,
          mitigated
        );
        SalesforceCommunicator.updateAuditApplication(
          salesforceApi,
          applicationId,
          { [rateKey]: mitigatedRateResult.rate }
        );
        setMitigatedRate(mitigatedRateResult.rate);
        setUnmitigatedRate(unmitigatedRateResult.rate);
      } catch (error) {
        // TODO add error handling to try/catch
      }
    };
    getRateCalculatorData();
  }, [
    applicationId,
    audit,
    property,
    rateCalculatorApi,
    salesforceApi,
    useCurrent
  ]);

  const renderDifferenceHeader = () => {
    const zone = useCurrent
      ? property.current_flood_zone
      : property.future_flood_zone;
    const copy = useCurrent ? provider.currentHeader : provider.futureHeader;

    return <DifferenceSubheader>{`${copy} (${zone})`}</DifferenceSubheader>;
  };

  const renderSaved = () => {
    if (!mitigatedRate || !unmitigatedRate) {
      return null;
    }

    const savings = unmitigatedRate - mitigatedRate;

    if (savings <= 0) {
      return <NoSavings>{provider.nonpositiveSavingsCopy}</NoSavings>;
    }

    return (
      <>
        <Savings>{`$${savings} ${provider.saved}`}</Savings>
        <PerYear>{provider.perYear}</PerYear>
      </>
    );
  };

  return (
    <>
      {renderDifferenceHeader()}
      {renderSaved()}
    </>
  );
};

AuditRateDifference.propTypes = {
  provider: PropTypes.object.isRequired,
  property: PropTypes.object.isRequired,
  audit: PropTypes.object.isRequired,
  applicationId: PropTypes.string.isRequired,
  useCurrent: PropTypes.bool.isRequired
};

export default AuditRateDifference;
