import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import RateCalculatorRadioInput from './RateCalculatorRadioInput';
import NumberOfStepsNavigator from '../helpers/calculatorNavigation/NumberOfStepsNavigator';

const RateCalculatorQuestionNumberOfSteps = ({
  provider,
  answers,
  setAnswers,
  setNextQuestion
}) => {
  useEffect(() => {
    if (answers.hasSteps) {
      setNextQuestion(NumberOfStepsNavigator.nextComponent(answers));
    }
  }, [answers, setNextQuestion]);

  const updateNumberOfSteps = (e) => {
    const { value } = e.target;
    let numberOfSteps = null;
    if (!isNaN(value) && value !== '') {
      numberOfSteps = parseInt(value);
    }
    setAnswers((prevAnswers) => ({ ...prevAnswers, numberOfSteps }));
  };

  const renderAdditionalQuestions = () => {
    if (!answers.hasSteps || answers.hasSteps === 'false') {
      return null;
    }
    return (
      <div className="additional-questions">
        <h4 className="front-door-question">{provider.frontDoorQuestion}</h4>
        <RateCalculatorRadioInput
          radioKey="front-door-location"
          responseField={provider.frontDoorResponseField}
          answer={answers.stepsUp}
          setResponse={(e) =>
            setAnswers((prevAnswers) => ({
              ...prevAnswers,
              stepsUp: e.target.value
            }))}
        />
        <h4 className="number-of-steps">{provider.numberOfStepsQuestion}</h4>
        <input
          type="number"
          onChange={updateNumberOfSteps}
          value={answers.numberOfSteps}
          min="1"
          step="1"
        />
      </div>
    );
  };

  return (
    <div className="question number-of-steps-question">
      <h4 className="has-steps-question">
        {provider.hasStepsQuestion}
        *
      </h4>
      <RateCalculatorRadioInput
        radioKey="has-steps"
        responseField={provider.hasStepsResponseField}
        answer={answers.hasSteps}
        setResponse={(e) =>
          setAnswers((prevAnswers) => ({
            ...prevAnswers,
            hasSteps: e.target.value
          }))}
      />
      {renderAdditionalQuestions()}
      <div className="promo">
        *
        {provider.disclaimer}
      </div>
    </div>
  );
};

RateCalculatorQuestionNumberOfSteps.propTypes = {
  provider: PropTypes.object.isRequired,
  answers: PropTypes.object.isRequired,
  setAnswers: PropTypes.func.isRequired,
  setNextQuestion: PropTypes.func.isRequired
};

export default RateCalculatorQuestionNumberOfSteps;
