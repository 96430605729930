import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '../styles/variables';
import { useLocale, useProvider } from '../helpers/hooks';

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 2;
  height: 40px;
  padding: 0 2rem;
  background-color: ${colors.grayScale[6]};
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease;
  transform: translateY(${(props) => (props.isVisible ? 0 : '-40px')});
`;

const Link = styled.a`
  color: ${colors.mutedGray};
  font-size: 1rem;

  @media (max-width: 450px) {
    display: flex;
  }
`;

const Divider = styled.span`
  color: ${colors.mutedGray};
  margin: 0 1rem;
`;

const UtilityBar = ({ isVisible }) => {
  const provider = useProvider('navBar');
  const locale = useLocale();
  const localesArray = ['en', 'es', 'ru', 'zh-CN', 'ht_HT'];

  const localPath = (languagePosition) => {
    const pathElements = window.location.pathname.split('/');
    pathElements.splice(1, 1, localesArray[languagePosition]);
    return pathElements.join('/');
  };

  const showEmergencyNotification = () => {
    sessionStorage.removeItem('fhnyNotificationClosed');
  };

  return (
    <Wrapper isVisible={isVisible}>
      <Link
        href={localPath(0)}
        onClick={showEmergencyNotification}
        data-turbolinks="false"
      >
        {provider.languages.english}
      </Link>
      <Divider>|</Divider>
      <Link
        href={localPath(1)}
        onClick={showEmergencyNotification}
        data-turbolinks="false"
      >
        {provider.languages.spanish}
      </Link>
      <Divider>|</Divider>
      <Link
        href={localPath(2)}
        onClick={showEmergencyNotification}
        data-turbolinks="false"
      >
        {provider.languages.russian}
      </Link>
      <Divider>|</Divider>
      <Link
        href={localPath(3)}
        onClick={showEmergencyNotification}
        data-turbolinks="false"
      >
        {provider.languages.chinese}
      </Link>
      <Divider>|</Divider>
      <Link
        href={localPath(4)}
        onClick={showEmergencyNotification}
        data-turbolinks="false"
      >
        {provider.languages.haitian}
      </Link>
      <Divider>|</Divider>
      <Link href={`/${locale}/about`}>{provider.about}</Link>
    </Wrapper>
  );
};

UtilityBar.propTypes = {
  isVisible: PropTypes.bool.isRequired
};

export default UtilityBar;
