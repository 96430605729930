export default {
  title: '您知道吗……',
  flood: {
    number: '$25,000',
    copy: '几英寸的洪水会造成数万美元的损失。',
    imgAlt: '浸水建筑物'
  },
  risk: {
    number: '2英里',
    copy: '即使住在离海岸两英里的地方，您也可能面临洪水的风险。',
    imgAlt: '带标记的地图'
  },
  mortgage: {
    number: '四分之一',
    copy: '在30年的抵押贷款期间，您的房屋有四分之一的可能被水淹没。',
    imgAlt: '风暴机会图标'
  }
};
