import addressInput from './AddressInput.ru';

export default {
  meta: {
    title: 'Исследуйте карты зон наводнения города Нью-Йорк',
    url: 'https://www.floodhelpny.org/ru/explore-map',
    description:
      "Исследуйте карты зон наводнения города Нью-Йорк, чтобы понять ваш текущий и будущий раск",
    imageAlt: 'карта для исследования'
  },
  loadingText: '',
  placeholder: 'Введите ваш адрес',
  currentMaps: 'Текущие катры',
  futureMaps: 'Ориентировочные карты',
  addressInput,
  legend: {
    label: 'ЛЕГЕНДА:',
    minimalHazard: 'Минимальная опасность',
    moderateRisk: 'Средний риск',
    highRisk: 'Повышенный риск',
    veryHighRisk: 'Наивысший риск (наводнения и волн)',
    copy:
      'Карта показывает текущие и ориентировочные карты наводнений для города Нью-Йорк по представлению ',
    linkCopy: 'FEMA',
    linkUrl: 'https://www.fema.gov'
  },
  showPopup: 'Показать зону наводнения',
  hidePopup: 'Спрятать зону наводнения',
  errorText:
    'Похоже Google испытывает проблемы с возвращением данных нашей карте! Попробуйте еще раз позже.'
};
