export default {
  currentPrefix: 'Pwopriyete sa a aktyèlman nan ',
  futurePrefix: 'men li gendwa chanje an ',
  xZone: 'yon zòn pou risk modere',
  aeZone: 'yon zòn gwo risk',
  aoZone: 'yon zòn gwo risk',
  veZone: 'yon zòn ki gen pi gwo risk',
  noneZone: 'yon zòn risk minimòm',
  minimalHazardZone:
    'Pwopriyete sa a aktyèlman nan yon zòn risk inondasyon minimòm.',
  disclaimer:
    'Piske kat yo ka pa presi, nou pa ka sèten a 100% pwopriyete ou an nan youn nan zòn sa yo oswa plis. Aprann Plis nan ',
  disclaimerLink: 'Tèm ak Kondisyon nou yo.'
};
