import BuildingSizes from '../../constants/BuildingSizes';

export default class SevereRepetitiveLossNavigator {
  static nextComponent(results) {
    if (results.buildingSize === BuildingSizes.SINGLE_FAMILY) {
      return 'buildingFeature';
    }
    return 'contentLocation';
  }
}
