export default {
  meta: {
    title: 'Backwater Valve Installation Program',
    url: 'https://www.floodhelpny.org/en/backwater-valve-program',
    description: 'Protect your home and finances from floods.',
    imageAlt: 'construction worker at work'
  },
  title: 'Backwater Valve Installation Program',
  subTitle:
    'Prevent costly damage to your home from sewage backups that can pose health risks.',
  description:
    "Homeowners in high-risk flood zones need an elevation certificate to get an accurate flood insurance quote. If you're eligible for a free Home Resiliency Audit, we’ll have an engineer visit your house and prepare one for you.",
  programClosed:
    'The Backwater Valve Installation Program is no longer accepting applications. For more about backwater valves and why you may need one, ',
  visitResourcePage: 'visit our resource page'
};
