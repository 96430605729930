import greenTelephone from '../images/green-telephone.svg';
import seaWaves from '../images/sea-waves.svg';
import strategy from '../images/strategy.svg';
import training from '../images/training.svg';

export default {
  header: '您的工作',
  callsToAction: [
    {
      header: '立即启动您的企业连续性计划',
      icon: strategy,
      iconAlt: '策略',
      body:
        '使用此框架可以在任何中断后快速恢复。保护您的投资，与员工和供应商保持联系，并做好应对不确定性的准备。',
      buttonText: '建议',
      buttonLink:
        'https://www.nyc.gov/html/nycbe/downloads/pdf/EM_SBSBCPWorksheetsMar2017.pdf'
    },
    {
      header:
        '确保您的企业准备就绪。参加研讨会或网络研讨会。',
      icon: training,
      iconAlt: '培训',
      body:
        '从天然气泄漏和火灾，到重大风暴和网络威胁，纽约市的企业主面临各种意想不到的紧急情况。SBS为纽约市的企业提供免费的应急准备研讨会和网络研讨会。',
      buttonText: '在此处了解更多信息',
      buttonLink:
        'https://www1.nyc.gov/nycbusiness/article/preparedness-response-recovery'
    },
    {
      icon: seaWaves,
      iconAlt: '波浪',
      header: '气候变化的威胁越来越大',
      body:
        '随着海平面上升，气候变化带来更多极端和频繁的天气事件，现在是开始准备的时候了。气候变化对我们的社区是一种威胁，作为我们社区和经济的支柱，小型企业是创建弹性城市的重要因素。',
      buttonText: '立即阅读',
      buttonLink:
        'https://onenyc.cityofnewyork.us/stories/a-livable-climate/#overview'
    },
    {
      icon: greenTelephone,
      iconAlt: '电话',
      header: '联系SBS应急响应部门寻求立即援助',
      body:
        '如需立即协助，请联系SBS的应急响应部门。团队可以为您提供有关街道封锁、公共设施中断、安全和拆除的最新紧急信息。他们还可以将您与本地企业组织联系起来，获得更多帮助。',
      buttonText: '了解更多信息',
      buttonLink:
        'https://www1.nyc.gov/nycbusiness/article/preparedness-response-recovery'
    }
  ]
};
