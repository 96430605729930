export default {
  header: 'Kibò pwopriyete ou an ta dwe ye',
  baseFloodElevationExplainedPre: 'Aktyèlman ',
  baseFloodElevationExplainedPost: ' anwo nivo lanmè an',
  baseFloodElevation:
    'Sa se Elevasyon pou Inondasyon Konsepsyon an (DFE) — kote ou dwe ye an.',
  topOfBottomFloor: 'Se kantite kay ou an depase nivo lanmè an aktyèlman.',
  freeboardCity:
    '“Sa se ‘fran bò’ Vil lan egzije an pou asire elevasyon ki san danje.”'
};
