export default {
    changeLinkText: '改变',
    currentMaps: '当前地图',
    futureMaps: '咨询地图',
    legend: {
      label: '关键：',
      minimalHazard: '最小危害',
      moderateRisk: '中等风险',
      highRisk: '高风险',
      veryHighRisk: '最高风险',
      floodingKey: ['Minimal hazard', 'Moderate risk', 'High Risk', 'Highest risk (flooding & waves)'],
      stormwaterKey: ['Moderate stormwater flooding hazard', 'High stormwater flooding hazard'],
      copy:
        '地图显示了纽约市的当前和咨询地图区域。来源: ',
      linkCopy: 'FEMA',
      linkUrl: 'https://www.fema.gov'
    },
    loadingText: '加载中……'
  };
  
