import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: ${(props) => (props.show ? 1000002 : -2)};
  opacity: ${(props) => (props.show ? 1 : 0)};
  transform: ${(props) => (props.show ? 'translateX(0)' : 'translateX(-100%)')};
  transition: all 0.3s ease-in;
`;

const Modal = ({ children, isModalActive }) => {
  return <Container show={isModalActive}>{children}</Container>;
};

Modal.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  isModalActive: PropTypes.bool.isRequired
};

export default Modal;
