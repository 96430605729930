import FloodZones from '../../constants/FloodZones';

const severities = [
  FloodZones.NONE,
  FloodZones.X,
  FloodZones.AE,
  FloodZones.VE
];

export default class FloodZoneSeverityHelper {
  static getLessSevereZone(result) {
    const currentIndex = severities.indexOf(result.currentFloodZone);
    const futureIndex = severities.indexOf(result.futureFloodZone);

    if (futureIndex > currentIndex) {
      return result.currentFloodZone;
    }

    return result.futureFloodZone;
  }

  static getMoreSevereZone(result) {
    const currentIndex = severities.indexOf(result.currentFloodZone);
    const futureIndex = severities.indexOf(result.futureFloodZone);

    if (futureIndex > currentIndex) {
      return result.futureFloodZone;
    }

    return result.currentFloodZone;
  }
}
