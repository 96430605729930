export default {
  header: 'LO QUE USTED PODRÍA HACER PARA REDUCIR SU RIESGO Y SU TARIFA',
  assumingMax: '*Suponiendo cobertura máxima',
  numbersAreEstimates:
    'Estos números son estimaciones por muchos motivos. Para más información acerca de ellos, remítase a nuestros ',
  termsAndConditions: 'Términos y Condiciones',
  noRecommendations: 'Lo sentimos, no pudimos identificar ninguna opción',
  backwaterValveProvider: {
    header: {
      inProgress:
        'Instalar una válvula antirretorno',
      complete:
        'Acerca de su válvula antirretorno'
    },
    completeHeader: '¡Instalación finalizada!',
    topBody:
      'Esto ayuda a evitar que las aguas residuales entren a su vivienda en caso de desbordamiento del alcantarillado.',
    bottomBody:
      'Usted es elegible para recibir la instalación gratuita de una válvula antirretorno.',
    bottomBodyComplete:
      'Nos gustaría saber cómo fue el proceso. Por favor tome unos minutos para llenar nuestro cuestionario.',
    buttons: {
      learnMore: 'Obtenga más información',
      startProcess: 'Inicie el proceso',
      feedbackSurvey: 'Cuestionario de opinión'
    },
    steps: {
      initiate: 'Instalación en progreso',
      schedule: 'Instalación en progreso',
      install: '¡Instalación finalizada!'
    }
  },
  elevateBuildingProvider: {
    header: 'Eleve su vivienda',
    body:
      'Coloca su vivienda completamente encima de los niveles de inundación.',
    link: 'Obtenga más información',
    lowerYourRate: 'Esto reducirá su tarifa.',
    auditRateDifferenceProvider: {
      currentHeader: 'Para su zona actual',
      futureHeader: 'Para su zona de consulta',
      saved: 'guardado',
      perYear: ' /año*',
      nonpositiveSavingsCopy: 'Esto no reducirá su tarifa'
    }
  },
  abandonFirstFloorProvider: {
    header: 'Abandone su primer piso',
    body: 'Esto efectivamente aumenta la elevación base de su vivienda.',
    link: 'Obtenga más información',
    lowerYourRate: 'Esto reducirá su tarifa.',
    auditRateDifferenceProvider: {
      currentHeader: 'Para su zona actual',
      futureHeader: 'Para su zona de consulta',
      saved: 'guardado',
      perYear: ' /año*',
      nonpositiveSavingsCopy: 'Esto no reducirá su tarifa'
    }
  },
  fillInBasementProvider: {
    header: 'Rellene su sótano',
    body: 'Reduce los daños a los cimientos estructurales de su vivienda.',
    link: 'Obtenga más información',
    lowerYourRate: 'Esto reducirá su tarifa.',
    auditRateDifferenceProvider: {
      currentHeader: 'Para su zona actual',
      futureHeader: 'Para su zona de consulta',
      saved: 'guardado',
      perYear: ' /año*',
      nonpositiveSavingsCopy: 'Esto no reducirá su tarifa'
    }
  },
  elevateMechanicalsProvider: {
    header: 'Eleve sus equipos mecánicos',
    body: 'Protege los sistemas críticos de su vivienda.',
    link: 'Obtenga más información',
    lowerYourRate: 'Esto reducirá su tarifa.',
    auditRateDifferenceProvider: {
      currentHeader: 'Para su zona actual',
      futureHeader: 'Para su zona de consulta',
      saved: 'guardado',
      perYear: ' /año*',
      nonpositiveSavingsCopy: 'Esto no reducirá su tarifa'
    }
  }
};
