import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import GoogleMapsPlacesHelper from '../helpers/mapHelpers/GoogleMapsPlacesHelper';
import Button from './ui/Button';
import Loading from './sharedComponents/Loading';
import magnifyingGlass from '../images/magnifying-glass.svg';
import { useProvider, useGoogleMaps } from '../helpers/hooks';
import { colors, borderRadius } from '../styles/variables';
import GoogleMaps from '../helpers/GoogleMaps';
import AddressInputPopup from './ui/AddressInputPopup';

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;
  background-color: white;
  height: ${(props) => (props.navBar ? '40px' : '5rem')};
  border: 1px solid ${colors.grayScale[1]};
  border-radius: ${borderRadius};
  margin-left: ${(props) => (props.navBar ? '2rem' : 0)};

  @media (max-width: 1135px) {
    margin-left: 0;
  }

  @media (max-width: 420px) {
    height: ${(props) => (props.navBar ? '40px' : '3rem')};
  }
`;

const Input = styled.input`
  border: none;
  flex: 1 1 auto;
  font: inherit;
  padding-left: ${(props) => (props.navBar ? '1rem' : '2rem')};
  font-size: ${(props) => (props.navBar ? '1rem' : 'inherit')};

  :focus {
    outline: none;
    border: 1px transparent;
    box-shadow: none;
  }

  @media (max-width: 460px) {
    font-size: ${(props) => (props.navBar ? '0.75rem' : 'inherit')};
    padding-left: 0.5rem;
  }
`;

const SubmitButton = styled(Button)`
  height: ${(props) => (props.navBar ? '20px' : 'auto')};
  padding: 0 1rem;

  @media (max-width: 460px) {
    padding: 0 ${(props) => (props.navBar ? '0.5rem' : '1rem')};
  }
`;

const ButtonImage = styled.img`
  height: ${(props) => (props.navBar ? '20px' : 'auto')};

  @media (max-width: 450px) {
    height: 20px;
  }
`;

// called by:
//   EligibilityLookup
const EligibilityAddressInput = ({
  handlePropertyInfo,
  propertyInfo,
  handleSubmitAddress,
  navBar,
  isLoading,
  setIsLoading
}) => {
  const inputRef = useRef();
  const provider = useProvider('EligibilityLookup');
  const { googleMapsScriptUrl } = useGoogleMaps();

  const [addressAutoCompleted, setAddressAutoCompleted] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [runAnimation, setRunAnimation] = useState(false);

  // Following eslint's exhaustive-deps rule here causes a new 'pac-container'
  // div to appear in the DOM per state change. So, the dependency array here is
  // incomplete to add this listener just once.
  useEffect(() => {
    const updateAddressFromListener = (autocomplete) => {
      const location = new GoogleMapsPlacesHelper(
        autocomplete.getPlace()
      ).getLocation();

      if (location) {
        setAddressAutoCompleted(true);
        setIsPopupVisible(false);
        handlePropertyInfo(location);
      } else {
        setAddressAutoCompleted(false);
        setIsPopupVisible(true);
      }
    };

    const autocomplete = GoogleMaps.createAutocomplete(inputRef.current);
    autocomplete.addListener('place_changed', () => {
      updateAddressFromListener(autocomplete);
    });
  }, [googleMapsScriptUrl]);

  const onSubmit = () => {
    if (addressAutoCompleted) {
      setIsLoading(true);
      setIsPopupVisible(false);
      handleSubmitAddress();
    } else {
      // Delay in place to prevent popup momentarily displaying between
      // selecting the autocomplete suggestion and addressAutoCompleted state
      // updating. There's probably a better way to do this, though!
      setTimeout(() => {
        if (addressAutoCompleted) {
          setIsPopupVisible(true);
          setAddressAutoCompleted(false);
        }
      }, 100);

      setRunAnimation(true);
    }
  };

  const onKeyPressSubmit = (e) => {
    if (e.key === 'Enter') {
      onSubmit();
    }
  };

  const onClickSubmit = (e) => {
    // Prevent page from reloading
    e.preventDefault();
    onSubmit();
  };

  const onChangeInput = (e) => {
    setIsPopupVisible(false);
    setAddressAutoCompleted(false);
    handlePropertyInfo({ formatted_address: e.target.value });
  };

  const renderLoadingSpinner = () => {
    return navBar ? (
      <Loading height="20px" spinnerSize="20px" margin="0" />
    ) : (
      <Loading margin="0 2rem" />
    );
  };

  return (
    <InputWrapper navBar={navBar}>
      <Input
        type="text"
        placeholder={provider.addressInput.placeholder}
        ref={inputRef}
        onChange={onChangeInput}
        onKeyPress={onKeyPressSubmit}
        value={propertyInfo.formatted_address || ''}
        navBar={navBar}
      />
      <SubmitButton
        navBar={navBar}
        bgColor="transparent"
        ghost
        onClick={onClickSubmit}
      >
        {isLoading ? (
          renderLoadingSpinner()
        ) : (
          <ButtonImage
            navBar={navBar}
            src={magnifyingGlass}
            alt="magnifying glass"
          />
        )}
      </SubmitButton>
      {isPopupVisible && (
        <AddressInputPopup
          animate={runAnimation}
          onAnimationEnd={() => {
            setRunAnimation(false);
          }}
          isInNavBar={navBar}
        >
          {provider.useAutocomplete}
        </AddressInputPopup>
      )}
    </InputWrapper>
  );
};

EligibilityAddressInput.propTypes = {
  handlePropertyInfo: PropTypes.func.isRequired,
  propertyInfo: PropTypes.object.isRequired,
  handleSubmitAddress: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  navBar: PropTypes.bool
};

EligibilityAddressInput.defaultProps = {
  navBar: false
};

export default EligibilityAddressInput;
