import umbrella from '../images/umbrella.svg';
import checklist from '../images/checklist.svg';
import map from '../images/map-with-marker.svg';
import questionBubble from '../images/question-bubble.svg';

export default {
  meta: {
    title: 'Achte pou Asirans kont Inondasyon | FloodHelpNY',
    url: 'https://www.floodhelpny.org/ht_HT/shopping-for-flood-insurance',
    description:
      'Risk inondasyon an ap ogmante, sa ki vle di tarif yo tou. Pa rate chans ou pou ou sezi yon kontra ki pi bon mache jodi an.',
    imageAlt: 'Achte pou Asirans kont Inondasyon | FloodHelpNY'
  },
  header: 'Achte pou Asirans kont Inondasyon',
  subHeader:
    'Risk inondasyon an ap ogmante, sa ki vle di tarif yo tou. Pa rate chans ou pou ou sezi yon kontra ki pi bon mache jodi an.',
  overview: {
    header: 'APÈSI',
    body:
      'Pwogram Nasyonal Asirans kont Inondasyon (NFIP) an etabli nòm tarif asirans kont inondasyon yo baze sou pwopriyete ou an epi risk li pou inondasyon. Ajan asirans yo ta dwe ba ou menm pwofòma pou menm kontra a. Toutfwa, ajan yo gendwa bay yon pwofòma ki pa presi si yo pa gen bon enfòmasyon an. Suiv etap ki anba yo epi bay ajan ou an enfòmasyon yo bezwen pou asire w ou jwenn bon kontra a.'
  },
  understandingFloodInsurance: {
    icon: umbrella,
    iconAlt: 'parapli',
    header: 'Ou pa konprann kijan asirans kont inondasyon an fonksyone?',
    buttonText: 'Klike la pou aprann plis'
  },
  stepsIntro: 'Jwenn pi bon kontra a pou ou. Suiv etap yo anba.',
  start: {
    step: 'Etap 1',
    icon: map,
    iconAlt: 'kat ak makè',
    title: 'Kote pou demare',
    haveInsurance: {
      title: 'Ou deja gen asirans?',
      body:
        'Rele ajan asirans pou pwopriyetè kay ou an epi mande l si yo ofri asirans kont inondasyon.'
    },
    findAgent: {
      title: 'Ou bezwen èd pou jwenn yon ajan?',
      body: 'Chèche nan '
    }
  },
  ask: {
    step: 'Etap 2',
    icon: questionBubble,
    iconAlt: 'boul dyalòg lan ki gen pwen entèwogasyon an',
    title: 'Poze senk kesyon sa yo pou jwenn pi bon kontra a pou ou.',
    questions: [
      {
        header:
          'Kisa zòn risk inondasyon mwen an ye sou Kat Tarif Asirans kont Inondasyon aktyèl lan?',
        description:
          'Si ou nan yon zòn “gwo risk”, ou gen yon egzijans pou pran asirans kont inondasyon.'
      },
      {
        header: 'Kijan chanje franchiz mwen an gen enpak sou tarif mwen an?',
        description:
          'Yon pi gwo franchiz ta ka rann tarif anyèl ou an pi bon mache, men li vle di ou ap pye plis nan pòch ou nan ka yon inondasyon.'
      },
      {
        header: 'Kisa kontra inondasyon mwen an t ap kouvri?',
        description:
          'Kontra inondasyon yo gen ladan yo de kalite kouvèti. “Estriktirèl” kouvri domaj nan konstriksyon lakay ou an ak aparèy ki ankastre yo. “Kontni” kouvri kèk, men pa tout, nan bagay pèsonèl ou. Pale ak ajan ou an apwopo ki nivo kouvèti fè sans sou plan finansye pou ou.'
      },
      {
        header: 'Ki pi gwo montan mwen ta ka reklame apre yon inondasyon?',
        description:
          'Montan “kouvèti maksimòm” ou an se pi plis ou ka reklame apre yon inondasyon. Lè ou ap deside sou kouvèti maksimòm ou an, panse ak konbyen lajan ou t ap bezwen pou ou retabli sou plan finansye.'
      },
      {
        header: 'Ki lòt faktè ki ta ka gen enpak sou konbyen mwen peye?',
        description:
          'Lòt faktè, tankou si kay ou an pre ak yon sous inondasyon e pri pou rebati an ka gen enpak sou tarif ak frè kontra ou an. Gade Kesyon Moun Poze Souvan yo nan Evalyasyon Risk 2.0 pou plis enfòmasyon.'
      }
    ]
  },
  doubleCheck: {
    step: 'Etap 3',
    icon: checklist,
    iconAlt: 'lis kontwòl',
    title: 'Sonje pou reverifye pwofòma ou nan mwen omwen twa ajan.'
  }
};
