export default {
  meta: {
    title: 'Programa de Instalación de Válvulas Antirretorno',
    url: 'https://www.floodhelpny.org/es/backwater-valve-program',
    description: 'Proteja su vivienda y sus finanzas de las inundaciones.',
    imageAlt: 'Trabajador de la construcción en el trabajo'
  },
  title: 'Programa de instalación de válvulas antirretorno',
  subTitle:
    'Evite daños costosos en su hogar causados por atascamientos de alcantarillado que puedan suponer riesgos de salud',
  description:
    'Los propietarios en zonas de inundación de alto riesgo necesitan un "certificado de elevación" para obtener una estimación precisa del seguro contra inundaciones. Si usted es elegible para una Auditoría de Resiliencia de la Vivienda gratuita, uno de nuestros ingenieros lo visitará en su casa y la realizará.',
  programClosed:
    'El programa de instalación de válvulas antirretorno, ya no está aceptando solicitudes. Para obtener más información sobre las válvulas antirretorno y por qué es posible que necesite una, ',
  visitResourcePage: 'visite nuestra página de recursos'
};
