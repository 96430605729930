export default {
  meta: {
    title: 'Sezon ouragan rive | FloodHelpNY.org',
    url: 'https://www.floodhelpny.org/ht_HT/prepare-for-hurricane-season',
    description:
      'Fè kat demach fasil jodi an pou pwoteje kay ou ak finans ou kont inondasyon'
  },
  header: 'Sezon ouragan rive',
  subHeader: 'Fè kat demach fasil jodi an pou pwoteje kay ou kont inondasyon',
  photos:
    '<b>Pran foto</b> byen enpòtan ou yo pou ede akselere reklamasyon asirans yo',

  copies:
    '<b>Mete kopi </b>dokiman enpòtan yo nan yon resipyan ki enpèmeyab oswa <b>anrejistre</b> l elektwonikman',
  move:
    '<b>Retire</b> atik ki gen valè yo nan sousòl lan pou mete yo nan etaj ki pi wo yo pou anpeche domaj nan dlo',
  insurance:
    '<b>Pran</b> asirans kont inondasyon oswa <b>analize</b> kontra aktyèl ou an pou asire w li ajou',
  posterCallToAction: 'Pran afich lan',
  posterCopy: 'Telechaje epi enprime pwòp kopi ou sou lis kontwòl lan ki la.',
  posterButton: 'Telechaje',
  posterUrl: '/samples/FloodHelpNY-Prepare-Poster-2020.pdf',
  camera: 'Kamera',
  waves: 'Vag'
};
