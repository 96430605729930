export default {
  download: 'Telechaje',
  elevationCertificate: {
    header: 'Sètifika Elevasyon Ou an (PDF)',
    copy:
      'Ou ka itilize sa pou jwenn yon tarif asirans kont inondasyon ki presi'
  },
  technicalReport: {
    header: 'Rapò teknik konplè ou an (PDF)',
    copy: 'Rapò teknik konplè an'
  },
  fieldReport: {
    header: 'Rapò sou teren konplè ou an (PDF)',
    copy: 'Rapò sou teren konplè an (PDF)'
  },
  homeownerGrantAgreement: {
    header: 'Kontra konplè pou sibvansyon pwopriyetè kay ou an (PDF)',
    copy: 'Kontra konplè pou sibvansyon pwopriyetè kay lan'
  },
  triPartyAgreement: {
    header: 'Kontra konplè tri-pati ou an (PDF)',
    copy: 'Kontra konplè tri-pati an'
  }
};
