import backwaterValve from '../images/backwater-valve.svg';
import floodproofing from '../images/floodproofing.svg';
import passFail from '../images/pass-fail.svg';
import raiseMechanicals from '../images/raise-mechanicals.svg';
import resilientRetail from '../images/resilient-retail-cover.jpg';
import assess from '../images/assess.svg';
import floodBarrier from '../images/flood-barrier.svg';
import generator from '../images/generator.svg';
import storefronts from '../images/storefronts.png';
import waterPump from '../images/water-pump.svg';
import waterproofStorage from '../images/waterproof-storage.svg';
import protect from '../images/protect.svg';
import sbsLogo from '../images/sbs-logo.svg';
import strategy from '../images/strategy.svg';

export default {
  meta: {
    title: 'Risk pou Inondasyon Pou Ti Biznis | FloodHelpNY',
    ogTitle: 'Esplikasyon Risk pou Inondasyon pou Ti Biznis | FloodHelpNY',
    url: 'https://www.floodhelpny.org/ht_HT/small-business',
    description:
      'Inondasyon ka epouvantab sou plan finansye pou pwopriyetè ti biznis. Men kijan ou ka pwoteje operasyon ou yo ak mwayen pou viv.',
    imageAlt: 'Ti Biznis | FloodHelpNY'
  },
  header: 'Ti Biznis: Esplikasyon sou Risk pou Inondasyon',
  subHeader:
    'Inondasyon ka epouvantab sou plan finansye pou pwopriyetè ti biznis. Men kijan ou ka pwoteje operasyon ou yo ak mwayen pou viv.',
  overview: {
    header: 'APÈSI',
    body:
      'Inondasyon ka fè plis pase koze domaj nan stòk ou oswa entewonp operasyon ou yo — li ka fèmen biznis ou an pou tout bon. Anfèt, omwen 25 pousan nan ti biznis katastwòf afekte yo pa janm relouvri. Men ak bon jan planifikasyon, ou ka reponn epi retabli pi vit. Ann gide ou atravè demach senp ou ka poze pou prepare pou inondasyon epi pou minimize domaj ki ta ka fè ou fèmen pòt ou pou tout bon.'
  },
  attribution: {
    logo: sbsLogo,
    logoAlt: 'Logo pou Sèvis Ti Biznis Vil New York yo',
    image: storefronts,
    imageAlt: 'Vitrin yo nan Vil New York',
    credit: 'Sa ki pote paj sa a pou ou an se:'
  },
  whatYouCanDo: {
    header: 'SA PWOPRIYETÈ TI BIZNIS YO KA FÈ',
    assess: {
      header: 'Evalye',
      icon: assess,
      iconAlt: 'Kay',
      body:
        'Li esansyèl pou ou pran tan pou evalye risk pou inondasyon ka poze nan biznis ou an. Aprann zòn inondasyon ou an, konsidere tout risk ak sante yo epi risk pou operasyon ou yo.'
    },
    prepare: {
      header: 'Prepare',
      icon: passFail,
      iconAlt: 'Lis kontwòl',
      body:
        'Asire w ou gen yon plan ijans pou asire kontinwite operasyon yo epi pou pwoteje anplwaye ou yo. Li esansyèl tou pou ou achte oswa renouvle asirans kont inondasyon.'
    },
    protect: {
      header: 'Pwoteje',
      icon: protect,
      iconAlt: 'Vit',
      body:
        'Aprann sou demach ou ka poze pou pwoteje estrikti ak ekipman biznis ou an, ansanm ak revni, pou ou ka retabli nan inondasyon pi rapid sa posib.'
    },
    callToAction: {
      header: 'Kòmanse Plan Kontinwite Biznis Ou an Jodi an',
      icon: strategy,
      iconAlt: 'Kòmanse Plan Kontinwite Biznis Ou an Jodi an',
      body:
        'Itilize kad sa a pou rebalanse rapid apre tout enteripsyon. Pwoteje envestisman ou yo, ret an kontak ak anplwaye epi founisè yo epi pare ou pou reponn ak ensètitid.',
      buttonText: 'Gade'
    }
  },
  riskZoneHeader: 'KONPRANN RISK POU INONDASYON OU AN',
  severeRiskZone: 'Pi Gwo Risk lan',
  highRiskZone: 'Gwo Risk',
  moderateRiskZone: 'Risk Modere',
  warning: {
    primary: {
      body:
        'Aktyèlman, gen 14,500 biznis ki fonksyone nan pifò nan zòn ki pi riske yo nan inondasyon Vil New York. Dapre Ajans Jesyon Ijans <a href="https://www.fema.gov" target="_blank" rel="noopener noreferrer">Federal lan (FEMA)</a>, gwo katastwòf ka afekte prèske 40% nan ti biznis yo nan espas yon lane yon evennman inondasyon, pandan 25% echwe apre yon lane apre yo afekte.'
    },
    aside: {
      header: 'Limit kouvèti batiman an',
      body:
        'Kantite maksimòm asirans kont inondasyon ou ka achte pou kouvri depans domaj nan batiman ou an se $500,000.'
    }
  },
  floodInsurance: {
    primary: {
      header: 'ASIRANS KONT INONDASYON KA BESE RISK OU AN',
      body:
        'Objektif yon politik asirans kont inondasyon se pou founi konpansasyon finansye apre yon pèt nan inondasyon, ki nòmalman soti nan yon politik asirans pwopriyete. Pa gen yon kontra pou inondasyon anplas ka mete biznis lan nan risk pèt finansye nan ka yon inondasyon, ki se katastwòf natirèl ki rive pi souvan nan mond lan. Epi inondasyon ka rive nenpòt kote--plis pase 20 pousan nan reklamasyon inondasyon yo soti nan pwopriyete ki andeyò zòn pou risk pou inondasyon an.'
    },
    aside: {
      header: 'Achte kounya, ekonomize pita',
      body:
        'Si ou achte asirans kont inondasyon kounya, ou ta ka ekonomize pou tèt ou plizyè milye dola sou pwochen ane yo.'
    }
  },
  businessInsurance: {
    header: 'ASIRANS POU ENTERIPSYON BIZNIS LAN',
    body:
      'Asirans pou enteripsyon biznis lan (yo konnen l tou antanke asirans pou revni biznis lan) se yon kalite asirans ki kouvri pèt revni yon biznis fè apre yon katastwòf. Pèt revni ki kouvri an gendwa soti nan fèmti enfrastrikti biznis lan ki lye ak katastwòf oswa nan pwosesis rekonstriksyon an apre yon katastwòf. Li enpòtan pwopriyetè biznis yo konprann asirans pou pwopriyete aktyèl yo an gendwa pa kouvri revni yo. Pèt revni biznis lan pwodui an ta ka vle di diferans ant kenbe pòt ou yo ouvri oswa fèmen.'
  },
  lowerYourRiskOptions: {
    header: 'Mezi Fizik pou Bese Risk pou Inondasyon ou an',
    list: [
      {
        image: raiseMechanicals,
        imageAlt: 'Kay',
        title: 'Elve sistèm mekanik ou yo',
        body:
          'Pi souvan, ekipman sa a nan yon sousòl batiman kote li ka ekspoze ak dlo inondasyon.'
      },
      {
        image: backwaterValve,
        imageAlt: 'Valv',
        title: 'Enstale yon valv pou dlo k ap tounen',
        body:
          'Sa ede anpeche dlo ize antre nan batiman ou an nan ka yon egou devide.'
      },
      {
        image: floodproofing,
        imageAlt: 'Kay',
        title: 'Pwoteksyon kont inondasyon imid ak sèch',
        body:
          'Si ou pa ka relokalize epi estrikti batiman ou an pèmèt li, ou ta dwe konsidere pwoteksyon kont inondasyon imid ak sèch antanke opsyon pwoteksyon kont inondasyon.'
      }
    ]
  },
  otherMethodsToLowerRisk: {
    title: 'LÒT METÒD BESE POU BESE RISK POU INONDASYON OU AN',
    subtitle:
      'Inondasyon an ka rive nenpòt kilè epi nan zòn ki gen ti risk pou inondasyon oswa pa ditou. Kit ou se yon pwopriyetè kay oswa yon lokatè, gen mezi ou ta ka pran jodi an pou ede ou rete nan biznis epi mete ou sou pye ou apre yon evennman inondasyon. Konsidere ekipman ki suiv yo la pou pwoteje biznis ou an kont domaj nan inondasyon epi pou ede ou retabli pi vit.',
    list: [
      {
        header: 'Baryè pòtab kont inondasyon',
        body:
          'Baryè pòtab kont inondasyon yo fèt pou enstalasyon/plasman tanporè nan pòt yo ak lòt pwen pou penetrasyon potansyèl ka ede yon biznis pwoteje espas li kont inondasyon ak domaj nan dlo.',
        image: floodBarrier,
        imageAlt: 'Baryè pòtab kont inondasyon'
      },
      {
        header: 'Dèlko pòtab',
        body:
          'Dèlko pòtab ka sèvi pou mache ekipman pou fonksyonman pèmanan oswa pou antretni kondisyon anviwonnmantal yo pandan pèt kouran prensipal lan.',
        image: generator,
        imageAlt: 'Dèlko pòtab'
      },
      {
        header: 'Estokaj pòtab enpèmeyab',
        body:
          'Yon bwat estokaj pòtab enpèmeyab ka prezève dokiman esansyèl, pèmèt yon biznis jwenn aksè ak enfòmasyon kritik obligatwa pou reponn ak yon ensidan katastwòf epi reprann fonksyonnman yo atan.',
        image: waterproofStorage,
        imageAlt: 'Estokaj pòtab enpèmeyab'
      },
      {
        header: 'Ponp dlo pòtab ki ka ret anba dlo',
        body:
          'Yon ponp dlo pòtab ki ka ret anba dlo epi tiyo ka sèvi pou anpeche dlo lapli k ap monte antre nan yon batiman, oswa retire dlo apre yon evennman inondasyon, adousi domaj nan batiman oswa founiti y o epi akselere pwosesis retablisman an.',
        image: waterPump,
        imageAlt: 'Ponp dlo pòtab ki ka ret anba dlo'
      }
    ]
  },
  continuityPlan: {
    title: 'Gid ibanis komèsyal toleran',
    body:
      'Yon resous san parèy pou ede pwopriyetè biznis ki loaklize nan koridò komèsyal nan tout zòn inondasyon vil lan.',
    view: 'Gade',
    image: resilientRetail,
    imageAlt: 'Gid Tolerans Klimatik nan Litoral lan'
  }
};
