export default {
  homepage: 'FloodHelpNY',
  languageTitle: 'Languages',
  about: '关于我们',
  languages: {
    english: 'English',
    spanish: 'Español',
    russian: 'Русский',
    chinese: '中文',
    haitian: 'Kreyòl ayisyen'
  },
  resources: {
    insurance: '保险',
    retrofits: '改装',
    hurricane: '飓风季节',
    smallBusinesses: '小型企业',
    news: '新闻',
    stormwaterFlooding: '暴雨洪水',
    climateChange: '气候变化'
  }
};
