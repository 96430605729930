export default {
  currentPrefix: 'This property is currently in ',
  futurePrefix: 'but it may change to ',
  xZone: 'a moderate risk zone',
  aeZone: 'a high risk zone',
  aoZone: 'a high risk zone',
  veZone: 'a highest risk zone',
  noneZone: 'a minimal hazard zone',
  minimalHazardZone:
    'This property is currently in an area of minimal flood hazard.',
  disclaimer:
    'Since maps can be imprecise, we can’t be 100% certain your property is in one or more of these zones. Learn More in our ',
  disclaimerLink: 'Terms & Conditions.'
};
