const ethnicityOptions = [
  {
    en: 'Hispanic or Latino',
    es: 'Hispano o Latino'
  },
  {
    en: 'Not Hispanic or Latino',
    es: 'No Hispano o Latino'
  },
  {
    en: 'Declined to Answer',
    es: 'Se negó a responder'
  }
];

const getEthnicityOptions = (language) =>
  ethnicityOptions.map((option) => ({
    value: option.en,
    display: option[language]
  }));

export default getEthnicityOptions;
