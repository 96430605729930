import backwaterValve from '../images/backwater-valve.svg';
import cookingOil from '../images/cooking-oil.svg';
import garbage from '../images/garbage.svg';
import roofing from '../images/roofing.svg';
import basement from '../images/basement.svg';
import unpaved from '../images/unpaved.svg';
import rainEventGuide from '../images/rain-event-guide.jpg';
import stormwaterFlooding from '../images/stormwater-flooding.svg';
import floodInsurance from '../images/flood-insurance.svg';

export default {
  meta: {
    title: '暴雨洪水 | FloodHelpNY.org',
    ogTitle: '纽约暴雨洪水 | FloodHelpNY.org',
    url: 'https://www.floodhelpny.org/zh-CN/stormwater-flooding',
    description:
      '暴雨洪水会影响离海岸线数英里的内陆社区，对财产和健康构成威胁。'
  },
  header: '暴雨洪水',
  subHeader:
    '暴雨洪水会影响离海岸线数英里的内陆社区，对财产和健康构成威胁。',
  whatIsStormwater: {
    header: '什么是暴雨雨水？',
    body:
      '暴雨雨水是从草坪、街道和其他陆地表面流出的雨水或融化的雪。理想情况下，这些雨水会被植物和土壤吸收，或者顺着下水道流走。但是，在纽约市，人行道和街道无法吸收雨水。相反，雨水流入城市的集水池，被引入下水道系统。不幸的是，该市的集水池容量有限。多余的雨水不可避免地流入街道，并导致当地洪水泛滥。污水也会回流到家庭中。'
  },
  pollution: {
    header: '雨水会增加污染',
    body: `除了房产面临洪水的风险外，还有污染的风险：未渗入地面的多余径流可能会在流入雨水沟、排水沟和海洋之前收集石油、农药、细菌和垃圾等污染物。`
  },
  howRainFallImpacts: {
    header:
      '降雨引发的洪水如何影响您的街道、家庭或企业',
    icon: stormwaterFlooding,
    iconAlt: '暴雨洪水',
    buttonText: '了解更多信息',
    buttonUrl:
      'https://experience.arcgis.com/experience/6f4cc60710dc433585790cd2b4b5dd0e'
  },
  protectHome: {
    title: '保护您的房屋免受雨水破坏',
    subtitle:
      '如果您经历过洪水，或者想减少洪水发生的可能性，这些都是需要考虑的因素。',
    list: [
      {
        header: '安装回水阀',
        body:
          '回水阀可以防止污水从城市下水道上升，并通过地下管道进入您的房屋。在大雨期间，如果城市下水道的水上升到地下室管道的高度，阀门关闭，阻止下水道污水进入您的房屋。',
        image: backwaterValve,
        imageAlt: '回水阀'
      },
      {
        header: '保护街道以下的区域',
        body:
          '下雨时，水流到低洼地区并形成水池。地下车库、地下室和其他低洼地区等空间最容易被洪水淹没。通过密封基础墙中的裂缝并使用沙袋等屏障，降低洪水风险。',
        image: basement,
        imageAlt: '地下室'
      },
      {
        header: '减少雨水径流',
        body:
          '水立即从混凝土和沥青中流出，下雨时可能会很快超过下水道的设计限制。在您的房屋周围保留和创造绿色和未铺砌的空间，可以减少房屋排入下水道的雨水径流，从而减少流入下水道的流量。',
        image: unpaved,
        imageAlt: '种植树木'
      },
      {
        header: '防止油脂进入下水道',
        body:
          '油脂和食用油处理不当会堵塞您家的内部管道和城市下水道。这可能会导致您的邻居和家中的下水道堵塞。',
        image: cookingOil,
        imageAlt: '食用油'
      },
      {
        header: '保持集水池清洁',
        body: `保持街道上清洁有助于雨水排放，并有助于保护城市的水体。该市一直在努力维护7500英里长的下水道和148000个收集街道垃圾的集水池。但如果这些道路被堵塞，可能会引发街道洪水。您和您的邻居可以在下雨之前或下雨期间，通过妥善处理垃圾，小心清除集水池格栅上的树叶或垃圾，帮助减少这种类型的洪水。`,
        image: garbage,
        imageAlt: '垃圾'
      },
      {
        header: '安装适当的屋顶排水系统',
        body:
          '将屋顶的雨水引向远离家中基础墙的地方对于保持地下室干燥至关重要。定期清理排水沟，防止雨水漫到墙上，并将落水管连接到适当的排水沟。考虑安装雨水桶收集雨水。',
        image: roofing,
        imageAlt: '屋顶排水'
      },
      {
        header: '购买洪水保险',
        body:
          '在可能下雨的地方，就会发生洪水，全国约20%的NFIP理赔发生在FEMA洪泛区之外。请咨询您的保险提供商，并考虑购买下水道倒流或洪水保险。这些保单可能涵盖下水道堵塞和暴雨造成的洪水造成的损害和清理费用。记住：洪水损失不包括在标准房主或租户保单中。',
        image: floodInsurance,
        imageAlt: '洪水保险'
      }
    ]
  },
  homeOwnerGuide: {
    header: `《城市房主降水事件准备指南》`,
    image: rainEventGuide,
    imageAlt: '降水事件指南',
    buttonText: '阅读更多信息',
    posterUrl:
      'https://www1.nyc.gov/assets/dep/downloads/pdf/climate-resiliency/flood-preparedness-flyer.pdf'
  }
};
