export default {
  header: 'ИНФОРМАЦИЯ ЗАЯВЛЕНИЯ:',
  // All questions keys must be listed here with a corresponding title.
  // Use the yes or no keys as replacements for boolean answers (true, false)
  yes: 'Yes',
  no: 'No',
  co_applicant_first_name: 'Имя',
  co_applicant_last_name: 'Фамилия',
  co_applicant_birthdate: 'Дата рождения (ГОД-МЕСЯЦ-ДЕНЬ)',
  co_applicant_email: 'Адрес электронной почты',
  co_applicant_home_phone: 'Домашний телефон',
  co_applicant_mobile_phone: 'Мобильный телефон',
  co_applicant_signature: 'Подпись',
  co_applicant_type: 'Вид со-заявителя',
  e_signature: 'Электронная подпись',
  household_member_race: 'Раса',
  household_member_income: 'Годовой доход',
  household_member_ethnicity: 'Этническая принадлежность',
  household_member_gender: 'Пол',
  sandy_compensation: 'Компенсация за Сэнди',
  sandy_insurance_types: 'Имеющееся страхование на момент супер урагана Сэнди ',
  tenants: 'Арендаторы',
  flood_insurance: 'Документ(ы) страхования от наводнений ',
  homeowners_insurance: 'Документ(ы) страхования недвижимости',
  household_member_proofincome: 'Доказательство дохода семьи',
  backwater_valve_compensation: 'Компенсация за канализационный обратный клапан',
  applicant_race: 'Раса главного заявителя',
  applicant_ethnicity: 'Этническая принадлежность главного заявителя',
  applicant_gender: 'Половая идентификация главного заявителя',
  applicant_birthdate: 'Дата рождения главного заявителя'
};
