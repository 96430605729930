export default {
  tellYourFriends: 'Cuente a sus amigos y vecinos',
  about: 'Nosotros',
  privacy: 'Privacidad',
  termsAndConditions: 'Términos y condiciones',
  widget: 'Widget',
  multifamily: 'Propiedades multifamiliares',
  cnycn: 'Center for NYC Neighborhoods',
  facebook: 'Comparta en Facebook',
  twitter: 'Comparta en Twitter',
  email: 'Email',
  emailSubject: 'Encuentra tu riesgo de inundación hoy!',
  message:
    'No importa dónde vivas en Nueva York: si puede llover, puede inundarse. ¡Encuentre su riesgo de inundación hoy en https://www.floodhelpny.org/!'
};
