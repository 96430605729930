const ElevateMechanicalsRecommendationHelper = {
  showRecommendation: (audit) => {
    const keys = [
      'can_elevate_ahu',
      'can_elevate_condenser',
      'can_elevate_cooling_system',
      'can_elevate_heating_system',
      'distribution_system_can_be_elevated',
      'pumps_can_be_elevated',
      'water_treatment_system_can_be_elevated'
    ];
    let canElevate = true;
    keys.some((key) => {
      if (!(audit[key] === 'Yes' || audit[key] === 'N/A')) {
        canElevate = false;
        return false;
      }
    });
    return canElevate;
  }
};

export default ElevateMechanicalsRecommendationHelper;
