import umbrella from '../images/umbrella.svg';
import checklist from '../images/checklist.svg';
import map from '../images/map-with-marker.svg';
import questionBubble from '../images/question-bubble.svg';

export default {
  meta: {
    title: 'В поисках страхования от наводнения | FloodHelpNY',
    url: 'https://www.floodhelpny.org/ru/shopping-for-flood-insurance',
    description:
      "Риск наводнений увеличивается, что означает страховые взносы увеличиваются тоже. Не упустите ваш шанс обеспечить более дешевый полис сегодня.",
    imageAlt: 'В поисках страхования от наводнения | FloodHelpNY'
  },
  header: 'В поисках страхования от наводнения',
  subHeader:
    "Риск наводнений увеличивается, что означает страховые взносы увеличиваются тоже. Не упустите ваш шанс обеспечить более дешевый полис сегодня.",
  overview: {
    header: 'ОБЗОР',
    body:
      "Национальная программа по страхованию от наводнений (NFIP) устанавливает стандартные тарифы страхования от наводнения основываясь на вашей недвижимости и ее риску наводнения. Страховые агенты должны давать вам одинаковые расценки на тот же самый полис. Однако агенты могут дать неправильную расценку если у них нет правильной информации. Выполните шаги, указанные ниже, и предоставьте вашему агенту информацию, необходимую ему для гарантирования, что вы получите правильный полис."
  },
  understandingFloodInsurance: {
    icon: umbrella,
    iconAlt: 'зонтик',
    header: "Не понимаете, как работает страхование от наводнения?",
    buttonText: 'Нажмите здесь для получения дополнительной информации'
  },
  stepsIntro: 'Найдите лучший для вас полис. Выполните шаги, указанные ниже.',
  start: {
    step: 'Шаг 1',
    icon: map,
    iconAlt: 'карта с маркером',
    title: 'Где начать',
    haveInsurance: {
      title: 'У вас уже есть страховка?',
      body:
        'Позвоните вашему агенту страхования недвижимости и спросите, предлагают ли они страхование от наводнения.'
    },
    findAgent: {
      title: 'Нужна помощь с поиском агента?',
      body: 'Поиск в '
    }
  },
  ask: {
    step: 'Шаг 2',
    icon: questionBubble,
    iconAlt: 'речевой пузырек со знаком вопроса',
    title: 'Задайте эти пять вопросов, чтобы получить лучший для вас полис.',
    questions: [
      {
        header:
          'В какой зоне риска наводнений я нахожусь на текущей карте тарифов страхования от наводнения?',
        description:
          'Если вы в зоне "высокого риска", вы обязаны иметь страховку от наводнения.'
      },
      {
        header: 'Как изменение франшизы отразится на моем тарифе?',
        description:
          'Более высокая франшиза может понизить вашу годовую ставку, но это означает, что вам придется заплатить больше из собственного кармана в случае наводнения.'
      },
      {
        header: 'Что будет покрыто моим страховым полисом от наводнения?',
        description:
          'Полисы страхования от наводнения включают два вида страхования. "Структурное" покрывает ущерб, нанесенный строению вашего дома и встроенных бытовых приборов. "Содержимое" покрывает некоторое, но не все ваше личное имущество. Поговорите с вашим агентом, какой уровень страхования имеет финансовый смысл для вас.'
      },
      {
        header: 'Какова наибольшая сумма, на которую я могу подать иск после наводнения?',
        description:
          'Сумма вашего "максимального покрытия" это самая большая выплата иска, на которую вы можете рассчитывать после наводнения. Когда вы будете принимать решение о вашем максимальном покрытии, подумайте о том, сколько денег вам понадобится на то, чтобы восстановиться финансово.'
      },
      {
        header: 'Какие другие факторы могут повлиять на то, сколько я буду платить?',
        description:
          'Другие факторы, такие как расположен ли ваш дом вблизи источника наводнения и стоимость восстановления могут повлиять на ставки и сборы полиса. Обратитесь к Часто задаваемым вопросам по Оценке риска 2.0 для получения более подробной информации.'
      }
    ]
  },
  doubleCheck: {
    step: 'Шаг 3',
    icon: checklist,
    iconAlt: 'контрольный список',
    title: 'Не забудьте проверить вашу котировку у по крайней мере трех агентов.'
  }
};
