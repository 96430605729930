import RtappApplicationState from '../constants/RtappApplicationState';

export default {
  progressBar: {
    apply: '申请',
    audit: '审计',
    review: '审核',
    install: '安装',
    complete: '完成',
    rejected: '不符合要求',
    withdrawn: '撤回'
  },
  [RtappApplicationState.PRE_APPLICATION]: {
    title: "非常感谢！我们很快就会联系您。"
  },
  [RtappApplicationState.PRE_APPLICATION_APPROVED]: {
    title: '完成您的回水阀申请'
  },
  [RtappApplicationState.APPLICATION_SUBMITTED]: {
    title: '感谢您的申请！'
  },
  [RtappApplicationState.HOMEOWNER_GRANT]: {
    title: "恭喜！您通过审核！"
  },
  [RtappApplicationState.AUDIT_SCHEDULED]: {
    title: '计划审计'
  },
  [RtappApplicationState.AUDIT_COMPLETE]: {
    title: '审计完成'
  },
  [RtappApplicationState.FEASIBILITY_REVIEW]: {
    title: '可行性审核'
  },
  [RtappApplicationState.CONSULTATION_SCHEDULED]: {
    title: '您有一个咨询预约！'
  },
  [RtappApplicationState.CONSULTATION_COMPLETE]: {
    title: '让我们继续'
  },
  [RtappApplicationState.INSTALLATION_PREPARE]: {
    title: '我们正在组建您的团队'
  },
  [RtappApplicationState.MEET_YOUR_TEAM]: {
    title: '和您的团队会面'
  },
  [RtappApplicationState.INSTALLATION_TO_BE_SCHEDULED]: {
    title: '安排进行安装'
  },
  [RtappApplicationState.INSTALLATION_HAS_BEEN_SCHEDULED]: {
    title: '安排进行安装'
  },
  [RtappApplicationState.INSTALLATION_COMPLETE]: {
    title: '已经安排安装'
  },
  [RtappApplicationState.INSTALLATION_COMPLETE]: {
    title: '安装完成！'
  },
  [RtappApplicationState.REPORT_COMPLETE]: {
    title: '最终报告'
  },
  [RtappApplicationState.REJECTED]: {
    title: '抱歉，您不符合要求'
  },
  [RtappApplicationState.WITHDRAWN]: {
    title: '您的申请已被撤回'
  }
};
