import waterDroplet from '../images/water-droplet.svg';
import umbrella from '../images/umbrella.svg';
import piggyBank from '../images/piggy-bank.svg';
import moneyHouse from '../images/money-house.svg';
import couchInHouse from '../images/couch-in-house.svg';
import policyAlert from '../images/policy-alert.svg';
import checklist from '../images/checklist.svg';
import premiumChanges from '../images/premium-changes.svg';

export default {
  meta: {
    title: 'Konprann Asirans kont Inondasyon an | FloodHelpNY.org',
    url: 'https://www.floodhelpny.org/ht_HT/understanding-flood-insurance',
    description: `Asirans kont inondasyon an konplike. Nou la pou ede ou konprann li.`,
    ogDescription:
      'Eseye detèmine asirans kont inondasyon an pou kò ou ka pote fistrasyon. Men nou la pou ede.’',
    imageAlt: 'vag'
  },
  title: 'Konprann Asirans kont Inondasyon an',
  subHeading:
    'Eseye detèmine asirans kont inondasyon an pou kò ou ka pote fistrasyon. Men nou la pou ede',
  inANutshell: {
    header: 'An Rezime',
    items: [
      {
        header: 'Asirans estanda a pa menm ak asirans kont inondasyon an',
        body:
          'Pwopriyetè kay, lokatè ak asirans kont biznis pa kouvri inondasyon.',
        image: waterDroplet,
        imageAlt: 'ti gout dlo'
      },
      {
        header: 'Ou gendwa blije gen asirans kont inondasyon',
        body: 'Asirans kont inondasyon obligatwa pou kèk pwopiryetè kay.',
        image: umbrella,
        imageAlt: 'parapli'
      },
      {
        header: 'Ekonomize lajan ak asirans kont inondasyon',
        body:
          'Li genswa se sèl grenn zouti ki pi enpòtan pou sove finans ou nan ka yon inondasyon.',
        image: piggyBank,
        imageAlt: 'bwat sekrè'
      }
    ]
  },
  whatIsIt: {
    header: 'Sa l ye?',
    body: {
      p1:
        'Pwogram Nasyon Asirans kont Inondasyon an se yon pwogram ki jwenn soutyen federal ki te kòmanse an 1968. Nan moman an konpayi prive yo te vle pote risk domaj nan inondasyon yo lakoz, kidonk Kongrè an te kreye NFIP an pou founi pwopriyetè batiman yo asirans kont inondasyon ki abòdab. Vil New York te antre nan pwogram lan an 1983.',
      p2:
        'Ajans Jesyon Ijans Federal lan ak konpayi asirans prive yo administre NFIP an. Pandan konpayi sa yo vann asirans kont inondasyon, FEMA nòmalize tout tarif yo. Sa vle di ou pa bezwen mache verifye pri pou asirans, men ou ta dwe verifye presizyon tarif ou an - apre tou, koutye yo konn fè erè.'
    },
    aside: {
      header: 'NFIP',
      body:
        'NFIP an pèmèt pwopriyetè yo ki nan kominote k ap patisipe pou achte pwoteksyon asirans kont pèt apati inondasyon, epi egzije asirans kont inondasyon pou tout prè pou ipotèk oswa liy kredi batiman ki nan zòn inondasyon ki gen gwo risk yo sekirize.'
    }
  },
  floodInsuranceCover: {
    header: 'Kisa Asirans kont Inondasyon an Kouvri?',
    subheader:
      'Gen de kalite kouvèti: batiman ak kontni. Ou gen opsyon pou chwazi montan kouvèti ak franchiz separe pou yo chak.',
    items: [
      {
        header: 'Kouvèti batiman',
        body:
          'Asire w estrikti an sèlman, pa kontni yo oswa peyizaj lan. Li pa kouvri finisman nan sousòl lan.',
        image: moneyHouse,
        imageAlt: 'kay ki gen siy dola'
      },
      {
        header: 'Kouvèti kontni yo',
        body:
          'Asire byen ou yo (men pifò eleman nan sousòl ou an oswa ki pi ba pase etaj espas pou viv ki pi ba ou an pa ladan l).',
        image: couchInHouse,
        imageAlt: 'kay ki gen yon kanape'
      }
    ],
    aside: {
      header: 'Eksepsyon ki kont-entuitif',
      body:
        'Gen kèk ekspepsyon kont-entuitif: frijidè, recho ak aparèy pou lave asyèt garanti nan kad asirans kont inondasyon, pandan aparèy pou lave ak chofe yo konsidere antanke kontni.'
    }
  },
  shopping: {
    icon: checklist,
    iconAlt: 'lis kontwòl',
    header: 'Aprann fasonpou jwenn pi bon kontra a pou pwoteje kay ou.',
    buttonText: 'Li kounya'
  },
  myOptions: {
    header: 'Kisa Opsyon Mwen yo Ye Pou Kouvèti?',
    body:
      'Kantite maksimòm asirans ou ka achte pou estrikti ou an se $250,000, epi kantite maksimòm asirans ou ka achte pou kontni ou yo se $100,000.',
    aside: {
      header: 'Ou se pwopriyetè yon ti biznis?',
      link: 'Aprann plis',
      body:
        ' apwopo fason risk pou inondasyon ka afekte operasyon ou yo — e kisa ou ka fè pou li.'
    }
  },
  floodInsurancePriced: {
    header: 'Kijan Asirans kont Inondasyon lan Tarife.',
    body:
      'Nan dat 1ye oktòb 2021, FEMA te prezante yon nouvo tarifikasyon pou asirans kont inondasyon, yo konnen antanke Evalyasyon Risk 2.0 pou montre risk inondasyon yo ak plis presizyon. Nan kad nouvo sistèm lan, FEMA pa p apiye ankò sou kat inondasyon yo pou pri asirans kont inondasyon yo, men li ap itilize plizyè varyab pou kalkile prim ou an, tankou distans parapò ak sous inondasyon an ak kalite konstriksyon yo. Pou yon lis konplè, verifye Kesyon Moun Poze Souvan nou yo.'
  },
  premiums: {
    icon: premiumChanges,
    iconAlt: 'Evalasyon Risk 2.0 pral lakoz chanjman nan prim yo.',
    body:
      'Evalasyon Risk 2.0 pral lakoz chanjman nan prim pou anpil moun nan tout peyi an. Kèk kontra gendwa eksperimante diminisyon nan prim; kèk gendwa eksperimante diminisyon nan prim yo. Pou kontra sa yo ki ap ogmante nan kad Evalyasyon Risk 2.0, FEMA oblije dapre lalwa federal pou l pa ogmante yon kontra ak plis pase 18% nan yon ane. Nan Vil New York FEMA estime anviwon 61% nan rezidan NYC yo pral eksperimante yon ogmantasyon prim nan kad Evalyasyon Risk 2.0.'
  },
  understandingMaps: {
    header: 'Konprann Kat Asirans kont Inondasyon yo',
    sub_heading:
      'Li enpòtan pou sonje pandan kat inondasyon pa p entegral ankò pou tarifikasyon, yo ap toujou sèvi pou detèmine si ou blije genyen yon asirans kont inondasyon.',
    severeRiskZone: 'Pi Gwo risk lan',
    highRiskZone: 'Gwo Risk',
    moderateRiskZone: 'Risk Modere'
  },
  aboutTheZones: {
    header: 'Apwopo zòn yo',
    body: {
      p1:
        'Nan zòn ki gen pi gwo risk yo (AE, AO ak VE), pwopriyetè kay yo blije achte asirans kont inondasyon si yo gen yon ipotèk ki jwenn soutyen federal. Epitou, pwopriyetè kay yo ki te resevwa asistans federal pou katastwòf pou domaj nan inondasyon blije achte asirans kont inondasyon nan lide pou yo kalifye pou asistans pi devan.'
    },
    aside: { header: 'Dekouvri risk ou', link: 'Jwenn Zòn Ou an' }
  },
  subsidizedRateFullRate: {
    header: 'Tarif Sibvansyone konpare ak Tarif pou Risk Total',
    body:
      'Nan zòn ki gen gwo risk pou inondasyon, gen de kalite tarif asirans diferan ki disponib: tarif sibvansyon anvan FIRM lan, ak tarif estanda a. Tarif sibvansyon anvan FIRM lan disponib sèlman pou pwopriyetè batiman pwopriyete yo te bati anvan 16 novanm 1983 (“anvan FIRM” akoz kay lan te bati anvan FIRM lan te adopte an 1983). Tarif sibvansyone sa a gendwa pi ba pase tarif estanda a; toutfwa, akoz sibvansyon yo ap diminye, gen anpil pwopriyete kote tarif estanda a anfèt mwen chè.',
    aside: {
      header: 'Ogmantasyon Asirans Anyèl yo',
      body:
        'Akoz de lejislasyon federal resan, Lwa Biggert-Waters 2012 lan ak Lwa 2014 lan pou Aksesibilite Asirans pou Lojman an, tarif sibvansyone yo ap ogmante nan nivo 15-18% chak ane.'
    }
  },
  newHomeowners: {
    header: 'Nouvo Pwopriyetè kay yo',
    body:
      'Lè ou achte yon nouvo kay, verifye ak moun k ap vann lan konsènan si yo gen yon kontra asirans kont inondasyon. Moun ki gen kontra yo kapab transfere kontra yo (ak nenpòt rabè) bay yon nouvo pwopriyetè apre yon kay vann.',
    aside: {
      header: 'Lèt pou Modifikasyon Kat lan',
      body:
        'Si kay ou an nan yon zòn ki gen gwo risk pou inondasyon men elevasyon sòl ki vin tousuit toutotou li pi wo pase BFE an, ou ka soumèt yon Lèt pou Modifikasyon Kat lan bay FEMA, oswa LOMA, ki se yon demann pou retire pwopriyete ou an nan zòn inondasyon an. Ou bezwen yon sètifika elevasyon kòm prèv.'
    }
  },
  whyMapsChanging: {
    header: 'KAT NYC YO AP CHANJE',
    body:
      'FEMA mete ajou peryodikman kat li kreye yo pou zòn risk pou inondasyon yo nan tout peyi an. Kat aktyèl yo pou Vil New York te adopte nan lane 1983 e yo pa montre risk inondasyon pou jounen jodi an ak presizyon. Nan pwen sa a, li pa klè lè nouvo Kat Tarif Asirans kont Inondasyon yo ap antre anvigè oswa ki fòm kat yo ap finalman pran. Pi bon estimasyon nou se li ap rive nan pwochen de pou rive senk lane yo.',
    aside: {
      header: 'Defi NYC',
      body_before:
        'Vil New York te konteste Kat Tarif Asirans kont Inondasyon Preliminè FEMA nan mwa jen 2015, li te di yo te estime nan yon fason depase risk pou inondasyon an pou senk awondisman yo. Nan mwa oktòb 2016, FEMA te anonse li aksepte kontestasyon ',
      link: 'Vil lan',
      body_after: ' e li pral revize kat yo sou baz nouvo done analiz.'
    }
  },
  learnMore: {
    body: 'Ou vle aprann plis? Verifye atik nou an ',
    link: 'Mare k Ap Monte Tarif k Ap Monte',
    href: 'https://cnycn.atavist.com/risingtides'
  }
};
