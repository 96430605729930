import React from 'react';
import { useProvider } from '../helpers/hooks';
import { Section } from './ui/StyledResourcePageElements';
import ListWithIconAndText from './layout/ListWithIconAndText';

const AboutAuditProgramSteps = () => {
  const provider = useProvider('aboutAuditProgramSteps');

  return (
    <Section>
      <h5>{provider.header}</h5>
      <ListWithIconAndText items={provider.list} />
    </Section>
  );
};

export default AboutAuditProgramSteps;
