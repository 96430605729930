import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import clock from '../images/clock.svg';
import RoundIcon from './RoundIcon';
import CallToAction from './layout/CallToAction';
import { Section } from './ui/StyledResourcePageElements';

const Explanation = styled.div`
  h3 {
    margin-bottom: 1rem;
  }
`;

const AuditStatusAppointment = ({ provider, appointmentDate }) => {
  const formatAppointment = (date) => {
    if (!date) {
      return provider.noDate;
    }
    const start = new Date(date);
    const finish = new Date(date);
    finish.setHours(finish.getHours() + 2);

    const timeOptions = { hour: 'numeric', minute: '2-digit' };
    const startFormatted = start.toLocaleTimeString('en-us', timeOptions);
    const finishFormatted = finish.toLocaleTimeString('en-us', timeOptions);

    const dateOptions = { month: 'numeric', day: 'numeric', year: '2-digit' };
    const formattedDate = start.toLocaleDateString('en-us', dateOptions);
    return `${startFormatted} - ${finishFormatted} ${formattedDate}`;
  };

  return (
    <div>
      <Section>
        <CallToAction>
          <RoundIcon src={clock} alt="clock" />
          <Explanation>
            <h3>{provider.scheduled}</h3>
            <h3>{formatAppointment(appointmentDate)}</h3>
            <p>
              {provider.body}
              <a href={`tel:${provider.phoneLink}`}>{provider.phone}</a>
              {provider.body2}
            </p>
          </Explanation>
        </CallToAction>
      </Section>
      <Section>
        <h5>{provider.headerExpect}</h5>
        <p>{provider.expect}</p>
      </Section>
    </div>
  );
};

AuditStatusAppointment.propTypes = {
  provider: PropTypes.object.isRequired,
  appointmentDate: PropTypes.string
};

AuditStatusAppointment.defaultProps = {
  appointmentDate: ''
};

export default AuditStatusAppointment;
