import styled from 'styled-components';
import { colors, borderRadius } from '../../styles/variables';

const StyledInput = styled.input`
  display: block;
  padding: 0 15px;
  border: 1px solid ${colors.grayScale[1]};
  border-radius: ${borderRadius};
  background-color: #fff;
  font: inherit;
  outline: none;
  transition: all 0.25s ease;
  height: 60px;

  &:focus {
    border: 1px solid ${colors.accent.main};
    box-shadow: inset 0 0 0 1px ${colors.accent.main};
  }

  &:invalid {
    border: 1px solid ${colors.pinkBerry};
  }

  &:disabled {
    border: 1px solid ${colors.grayScale[1]};
    cursor: not-allowed;
  }

  & + label {
    margin-top: 30px;
  }

  @media (max-width: 767px) {
    width: 100%;

    & + button {
      margin-top: 15px;
    }
  }
`;

const InputVisibilityWrapper = styled.div`
  opacity: ${(props) => (props.show ? '1' : '0')};
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  width: ${(props) => (props.show ? '100%' : '0')};
  height: ${(props) => (props.show ? 'auto' : '0')};
  transition: width 0.5s, height 0.5s, opacity 0.5s;
`;

export { StyledInput, InputVisibilityWrapper };
