import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '../styles/variables';
import RiskZoneText from './RiskZoneText';
import { useLocale, useProvider } from '../helpers/hooks';

const Container = styled.div`
  padding: 70px 0;
  margin: 15px 90px;
  text-align: center;

  h3 {
    font-size: 2.6em;
  }

  @media (max-width: 768px) {
    margin: 0 20px;
    padding: 30px 0;
  }
`;

const Disclaimer = styled.div`
  font-size: 1rem;
  color: ${colors.grayScale[3]};
  margin-top: 1rem;
`;

// called by:
//   Profile
//   RtappStatusPage
const RiskInfo = ({ property }) => {
  const provider = useProvider('floodRisk');
  const locale = useLocale();

  const zonesChange = () =>
    property.current_flood_zone !== property.future_flood_zone;

  const renderFutureZone = () => {
    if (!zonesChange()) {
      return null;
    }

    return (
      <div>
        {provider.futurePrefix}
        <RiskZoneText floodZone={property.future_flood_zone} />
        <span>.</span>
      </div>
    );
  };

  const renderBody = () => {
    if (
      property.current_flood_zone === 'None' &&
      property.future_flood_zone === 'None'
    ) {
      return <h3>{provider.minimalHazardZone}</h3>;
    }

    return (
      <h3>
        <div>
          {provider.currentPrefix}
          <RiskZoneText floodZone={property.current_flood_zone} />
          <span>{zonesChange() ? ',' : '.'}</span>
        </div>
        {renderFutureZone()}
      </h3>
    );
  };

  return (
    <Container>
      {renderBody()}
      <Disclaimer>
        {provider.disclaimer}
        <a target="_blank" rel="noreferrer noopener" href={`/${locale}/terms`}>
          {provider.disclaimerLink}
        </a>
      </Disclaimer>
    </Container>
  );
};

RiskInfo.propTypes = {
  property: PropTypes.object.isRequired
};

export default RiskInfo;
