import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '../styles/variables';
import FloodZone from './FloodZone';
import arrow from '../images/arrow.svg';
import { useProvider } from '../helpers/hooks';

const Container = styled.div`
  border-top: 1px solid ${colors.grayScale[2]};
  margin: 0 5rem;
  padding: 5rem 0;
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;

  @media (max-width: 1200px) {
    margin: 0 1rem;
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 768px) {
    margin: 0 1rem;
    padding: 2rem 0;
  }
`;

const ZoneChange = styled.div`
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-right: 2rem;

  @media (max-width: 1200px) {
    flex-wrap: nowrap;
    align-items: center;
    margin-right: 0;
    margin-bottom: 2rem;
  }
`;

const ZoneContainer = styled.div`
  flex-grow: 1;
  text-align: center;
  display: flex;
  align-items: center;

  @media (max-width: 1200px) {
    width: 40%;
  }
`;

const Explanation = styled.div`
  flex-grow: 1;
  flex-basis: 23%;

  @media (max-width: 1200px) {
    text-align: center;
  }
`;

const FloodZoneInfo = ({ property }) => {
  const provider = useProvider('FloodZoneInfo');
  const currentZone = property.current_flood_zone;
  const futureZone = property.future_flood_zone;
  const { floodZoneExplanations } = provider;

  const zoneOrBfeChange = () =>
    currentZone !== futureZone || property.current_bfe !== property.future_bfe;

  const renderTransitionArrow = () =>
    zoneOrBfeChange() && <img src={arrow} alt="arrow" />;

  const renderFutureZone = () =>
    zoneOrBfeChange() && (
      <FloodZone
        provider={provider}
        floodZone={futureZone}
        bfe={property.future_bfe}
      />
    );

  const renderFloodZoneChange = () => (
    <ZoneChange>
      <ZoneContainer>
        <FloodZone
          provider={provider}
          floodZone={currentZone}
          bfe={property.current_bfe}
        />
        {renderTransitionArrow()}
        {renderFutureZone()}
      </ZoneContainer>
    </ZoneChange>
  );

  const getExplanation = () => {
    return floodZoneExplanations[currentZone.toLowerCase()];
  };

  const renderExplanation = () => {
    const explanation = getExplanation();
    if (!explanation) {
      return null;
    }

    const { learnMoreAboutFloodZones, floodZoneUrl } = provider;

    return (
      <Explanation>
        <h4>{explanation.header}</h4>
        <p>{explanation.copy}</p>
        <p>
          <span>{learnMoreAboutFloodZones.copy}</span>
          <a href={floodZoneUrl}>{learnMoreAboutFloodZones.linkText}</a>.
        </p>
      </Explanation>
    );
  };

  return (
    <Container>
      {renderFloodZoneChange()}
      {renderExplanation()}
    </Container>
  );
};

FloodZoneInfo.propTypes = {
  property: PropTypes.object.isRequired
};

export default FloodZoneInfo;
