import umbrella from '../images/umbrella.svg';
import checklist from '../images/checklist.svg';
import map from '../images/map-with-marker.svg';
import questionBubble from '../images/question-bubble.svg';

export default {
  meta: {
    title: 'Compra de Seguro contra Inundaciones | FloodHelpNY',
    url: 'https://www.floodhelpny.org/es/shopping-for-flood-insurance',
    description:
      'El riesgo de las inundaciones va en aumento, lo cual significa que las primas de los seguros también aumentan. No pierda la oportunidad de obtener una prima de seguro más económica hoy mismo.',
    imageAlt: 'Compra de Seguro contra Inundaciones | FloodHelpNY'
  },
  header: 'Compra de Seguro contra Inundaciones',
  subHeader:
    'El riesgo de las inundaciones va en aumento, lo cual significa que las primas de los seguros también aumentan. No pierda la oportunidad de obtener una prima de seguro más económica hoy mismo.',
  overview: {
    header: 'RESUMEN',
    body:
      'El Programa Nacional de Seguro contra Inundaciones [National Flood Insurance Program] (NFIP) establece las tasas estándar de seguros contra inundaciones basadas en su propiedad y su riesgo de inundación. Los agentes de seguros deben darle la misma cotización por la misma póliza. Sin embargo, los agentes podrían darle una cotización errónea si no tienen la información correcta. Siga los pasos a continuación y dele a su agente la información que necesita para garantizar que usted obtenga la póliza correcta.'
  },
  understandingFloodInsurance: {
    icon: umbrella,
    iconAlt: 'paraguas',
    header: '¿No entiende cómo funciona el seguro contra inundaciones?',
    buttonText: 'Pulse aquí para obtener más información'
  },
  stepsIntro:
    'Obtenga la mejor póliza para usted. Siga los pasos a continuación.',
  start: {
    step: 'Paso 1',
    icon: map,
    iconAlt: 'mapa con marcador',
    title: 'Donde empezar',
    haveInsurance: {
      title: '¿Ya tiene seguro?',
      body:
        'Llame a su agente de seguro de propiedad y pregúntele si ofrecen seguro contra inundaciones.'
    },
    findAgent: {
      title: '¿Necesita ayuda para encontrar un agente?',
      body: 'Busque en '
    }
  },
  ask: {
    step: 'Paso 2',
    icon: questionBubble,
    iconAlt: 'burbuja de diálogo con signo de interrogación',
    title:
      'Haga estas cinco preguntas para obtener la mejor póliza para usted.',
    questions: [
      {
        header:
          '¿Cuál es mi zona de riesgo de inundación en el actual Mapa de Primas de Seguro de Inundación?',
        description:
          'Si actualmente está en una zona de "alto riesgo", es obligatorio tener un seguro contra inundaciones .'
      },
      {
        header: '¿Cómo un cambio en mi deducible afectará mi tarifa de seguro?',
        description:
          'Un deducible más alto podría hacer su tarifa anual más económica, pero esto significa que pagará más de su bolsillo en caso de una inundación.'
      },
      {
        header: '¿Qué cubre mi póliza de seguro contra inundación?',
        description:
          'Las pólizas de seguro contra inundación incluyen dos tipos de cobertura. La cobertura "Estructural" cubre daños a la construcción de su vivienda y sus electrodomésticos empotrados. La cobertura de "Contenidos" cubre algunas, pero no todas sus pertenencias personales. Hable con su agente sobre el mejor nivel de cobertura para usted desde el punto de vista económico.'
      },
      {
        header:
          '¿Cuál es la cantidad máxima que puedo reclamar después de una inundación?',
        description:
          'Su "cobertura máxima" es la mayor cantidad que usted puede reclamar después de una inundación. Al decidir sobre su cobertura máxima, piense en cuánto dinero necesitaría para recuperarse económicamente.'
      },
      {
        header: '¿Qué otros factores podrían afectar la cantidad que pago?',
        description:
          'Otros factores, como si su vivienda esta cerca de una fuente de inundaciones y el costo de reconstrucción pueden impactar la tasa y cargos de su póliza. Consulte nuestras preguntas frecuentes sobre el Risk Rating 2.0 para obtener mas información.'
      }
    ]
  },
  doubleCheck: {
    step: 'Paso 3',
    icon: checklist,
    iconAlt: 'lista de verificación',
    title:
      'Recuerde verificar cuidadosamente las cotizaciones que obtenga de por lo menos tres agentes.'
  }
};
