export default {
    meta: {
      title: '条款',
      url: 'https://www.floodhelpny.org/zh-CN/terms'
    },
    title: '条款',
    national_flood_insurance_policy: '全国洪水保险计划',
    privacy_policy: '隐私政策',
    general_disclaimer: {
      title: '一般免责声明和责任限制',
      paragraph_1:
        'FloodHelpNY.org（以下简称“网站”）中包含的材料和信息（包括任何子域、指向其他网站的链接以及在链接网站上找到的内容）按“原样”提供，无任何明示或暗示的担保，包括但不限于对适销性或特定用途适用性的任何默示保证。纽约市邻里中心（“中心”）和州长风暴恢复办公室（“GOSR”）均不对因使用本网站而产生的任何类型的损害承担任何类型的责任，无论是合同责任、侵权责任、严格责任还是任何其他类型的责任。由于互联网的性质，中心不担保对本网站的访问不会中断或无错误。中心不对本网站所含资料的有用性或预期结果作出任何保证或陈述。',
      paragraph_2:
        '我们相信本网站上提供的信息是准确的，我们会竭尽全力减少错误。然而，由于人类和计算机都容易出错，信息变化很快，我们无法保证所提供信息的绝对准确性。如果您发现错误或任何不准确之处，请与我们联系 ',
      email: 'info@floodhelpny.org.',
      paragraph_3:
        '保险成本估算和改装成本仅用于说明目的。您负责验证所有成本估算的准确性和适用性。在做出任何投资或财务规划决策之前，请咨询持牌和/或有资格的个人。不要仅仅依赖本网站上的成本估算和财务信息。  ',
      paragraph_4:
        '通过访问本网站，您同意在任何情况下或任何国际法或民法、普通法或成文法下的责任理论，包括但不限于严格责任、疏忽或其他侵权理论或合同、专利法或版权法，SN 将在法律允许的最大范围内，对因使用本网站或在本网站上获得的任何信息、商品或服务而产生的任何类型的损害负责，包括直接、间接、后果性、附带或惩罚性损害（即使已经告知了中心此类损害的可能性）。某些司法管辖区不排除或限制某些损害赔偿，因此某些限制可能不适用于您。'
    },
    flood_zones: {
      title: '洪水区',
      part_1:
        '本网站上显示的任何单个位置的洪水区、标高和洪水风险结果仅供参考，您不应依赖这些结果来确定是否需要购买洪水保险或采取任何其他措施。请注意，在某些地区，洪水区边界的位置可能不准确，地址定位器可能无法100%准确地识别您的地址。业主应联系 ',
      part_2:
        ' 了解更多信息或查看洪水保险费率地图的正式副本，并在开始任何施工活动之前讨论房产附近的洪水标高和区域。此外，本网站上提供的信息通常针对沿海洪水，可能不代表其他形式的洪水风险，如河流洪水或其他原因。最后，本网站提供的与标高和洪水损害风险相关的某些信息基于国家洪水保险计划的费率图和其他产品，这些数据没有考虑全球变暖导致海平面上升的未来风险。有关如何使用您的个人信息，请咨询我们 '
    },
    flood_insurance_estimate: {
      title: '预计洪水保险',
      paragraph_1:
        '选择使用本网站获取洪水保险估价，即表示您同意受这些条款的约束。如果您不同意这些条款和条件，不应提交洪水保险费率估算请求。',
      paragraph_2:
        '我们不是保险公司，也不提供洪水保险。您对洪水保险费率估计的请求不会使任何报价或报价生效，提供承保范围。中心不会因您要求洪水保险费率估算而承担任何责任。只有您向提供洪水保险的保险公司申请洪水保险，且保险公司接受了该申请，并由保险公司出具了单独的保单证明，您才能获得洪水保险。',
      paragraph_3:
        '您的整个洪水保险预估都是暂定的，包括预估保费和预期承保条款，可能会根据任何和所有承保标准、人工费率、评级日期、规则手册、评级计划和分类进行更改，恕不另行通知 ',
      paragraph_4_part_1: '请阅读我们的 ',
      paragraph_4_part_2:
        ' 了解如何使用您在洪水保险估价申请中提供的个人信息。尽管中心已经采取预防措施保护您在洪水保险估算请求中传输的信息，但中心不保证或保证任何信息不会被未经授权的第三方通过互联网或其他方式拦截、审查、转发、传播或使用。',
      paragraph_5:
        '中心保留监控本网站的权利，识别未经授权的拦截或更改信息或造成损害的企图。使用本网站即表示您同意此类监控。未经授权，不得试图截取或更改信息，或造成损害，可能受到法律制裁。'
    },
    home_resiliency_audit: {
      title: '家庭恢复力审计',
      paragraph_1:
        '选择使用本网站申请并参与家庭恢复力审计计划，即表示您同意受这些条款的约束。如果您不同意这些条款，您不应提交申请参加家庭恢复力审计计划。',
      paragraph_2:
        '我们不能保证所有希望接受家庭恢复力审计的房主都会收到一份。目前，该计划仅适用于位于纽约市特定社区的符合条件的房主，且能够参与的房主数量有限。',
      paragraph_3:
        '该中心选择了工程公司，通过竞争性采购流程进行国内恢复力审计，寻找合格的建筑公司、测量公司和工程公司。该中心在选择工程公司时，规定这些公司应充分了解联邦应急管理局（FEMA）如何根据国家洪水保险计划（NFIP）确定建筑物的洪水保险费，以及洪水损失缓解措施如何影响适用于建筑物的NFIP保险费率。 ',
      paragraph_4:
        '该中心本身并不进行国内恢复力审计，因此不能保证工程公司所做工作的结果或提供信息的准确性。',
      paragraph_5:
        '该中心还选择了住房咨询和法律服务提供商，通过竞争性采购流程，作为家庭恢复力审计计划的一部分，为受高洪水影响的纽约市社区的1-4户住宅提供恢复力咨询。这些咨询机构为恢复力审计服务提供一对一咨询和技术援助。该中心在选择咨询机构时使用的标准之一是，咨询机构必须具备技术和财务能力，并具有在社区一级工作的经验。',
      paragraph_6:
        '该中心本身并不提供住房咨询和法律服务，因此无法保证咨询机构提供的工作的准确性或水平。',
      paragraph_7:
        '您应仅将家庭恢复力审计用于信息和说明目的。您负责验证审计中所有假设和计算的准确性和适用性。在做出任何投资或财务决策或对您的房屋进行任何改造或翻新之前，请咨询持牌和/或有资格的个人。请勿仅仅依赖本网站上的房屋恢复力审计、财务或洪水保险费率估算计算器/计算或财务信息。',
      paragraph_8_part_1: '请阅读我们的 ',
      paragraph_8_part_2:
        ' 了解您在申请中提供的个人信息如何用于家庭恢复力审计计划。尽管中心已采取预防措施保护您在申请参加家庭恢复力审计计划时传输的信息以及计划部分信息，但中心不保证或保证任何信息不会被截取、审查、转发，未经授权的第三方通过互联网或其他方式传播或使用。'
    },
    backwater_valve: {
      title: '回水阀安装项目',
      paragraph_1:
        '选择使用本网站申请并参与回水阀安装计划，即表示您同意受这些条款的约束。如果您不同意这些条款，您不应提交申请参加回水阀安装计划。',
      paragraph_2:
        '我们不能保证所有希望接受回水阀安装审计的房主都会收到一份。目前，该计划仅适用于位于纽约市特定社区的符合条件的中低收入房主，且能够参与的房主数量有限。为了有资格安装回水阀，房主必须接受住宅恢复力审计，工程师必须确定该物业可以从安装下水道回水阀中受益。',
      paragraph_3:
        '该中心本身不会进行回水阀安装，因此无法保证将安装回水阀的公司的施工结果。中心将从一批经过资格预审的公司中挑选持证的水管工师傅，他们将为合格的房主完成安装过程。中心会直接向水管工支付安装阀门的费用。房主在同意进行任何安装工作之前，应独立审查水管工的资质。 ',
      paragraph_4:
        '虽然安装回水阀可以降低因下水道倒流而造成的损坏风险，但中心不保证在安装阀门后不会发生下水道倒流或其他形式的损坏。房主有责任了解阀门的工作原理，维护阀门，定期检查阀门，保持良好的工作状态。 ',
      paragraph_5:
        '阀门安装后，中心不提供任何维护或检查服务。 ',
      paragraph_6:
        '安装回水阀存在风险。如果在阀门安装过程中使用了淋浴器、水槽和马桶等管道设备，可能会损坏房屋。如果发生洪水或暴雨，阀门可能会失效，并可能对房屋造成损害。 ',
      paragraph_7: '接受阀门安装的房主应：',
      paragraph_7_part_1:
        '仔细阅读阀门制造商提供的运维说明，并遵循这些说明。',
      paragraph_7_part_2:
        '避免冲洗湿巾、纸巾、卫生用品、油、脂肪或油脂。这些物品可能积聚并导致阀门故障。',
      paragraph_7_part_3:
        '暴风雨前检查阀门。如果阀门中有任何杂物堵塞挡板，阀门可能在洪水期间发生故障。查找可能堵塞阀门的任何物品并将其移走。 ',
      paragraph_8:
        '接受阀门安装的房主必须同意自费维护自己的房主保险单，包括额外的下水道倒流保险（如有）。他们还必须购买洪水保险，保险金额不得超过安装补助金的成本。',
      paragraph_9:
        '请阅读我们的隐私政策，了解如何使用您在申请中提供的个人信息，参与回水阀安装计划。尽管中心已采取预防措施保护您在申请参加回水阀安装计划时传输的信息以及计划部分信息，但中心不保证或保证任何信息不会被截取、审查、转发，未经授权的第三方通过互联网或其他方式传播或使用。'
    }
  };
  