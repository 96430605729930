const Matchers = {
  // this email regex is from SalesForce docs
  email: /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/,
  phone: /^[0-9\-().+\sx]{10,}$/,
  zipcode: /(^\d{5}$)|(^\d{5}-\d{4}$)/,
  // fix to only accept 1 - 12 for months | currently accepts 0 - 19
  date: /(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])\/((19|20)\d\d)/,

  // the start/end forward slashes are removed
  // for <input pattern="">
  emailInputPattern:
    "^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$",
  phoneInputPattern: '^[0-9-().+sx]{10,}$'
};

export default Matchers;
