export default {
  title: 'Èske ou te konnen...',
  flood: {
    number: '$25,000',
    copy:
      'Kèk pous dlo inondasyon ka lakoz plizyè milye dola domaj inondasyon.',
    imgAlt: 'Batiman ki inonde'
  },
  risk: {
    number: '2 mil',
    copy:
      'Menm si ou ap viv de mil apati kot lan, ou ta ka nan risk pou inondasyon.',
    imgAlt: 'Kat ak makè'
  },
  mortgage: {
    number: '1 nan 4',
    copy:
      'Gen yon chans sou kat pou kay ou an inonde pandan ipotèk 30 lane ou an.',
    imgAlt: 'Ikonn chans pou tanpèt'
  }
};
