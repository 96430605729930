export default {
    header: 'Варианты снижения вашего тарифа',
    link: 'Узнать о мерах по предотвращению или снижению рисков',
    assumingMax: '*Из расчета максимального покрытия',
    numbersAreEstimates:
      'Эти цифры являются приблизительными по многим причинам. Чтобы узнать более подробную информацию о них обратитесь к нашим ',
    termsAndConditions: 'Правилам и условиям',
    noMitigation: "К сожалению мы не смогли найти никаких вариантов",
    elevateBuilding: {
      header: 'Поднимите ваш дом',
      body: 'Располагает ваш дом в полной мере выше потенциального уровня наводнения',
      link: 'Дополнительная информация',
      mitigationDifference: {
        currentHeader: 'Для вашей текущей зоны',
        futureHeader: 'Для вашей ориентировочной зоны',
        saved: 'сохранено',
        perYear: ' /в год*',
        nonpositiveSavingsCopy: 'Это не снизит ваш тариф'
      }
    },
    abandonFirstFloor: {
      header: 'Покиньте ваш первый этаж',
      body: 'Это эффективно повышает базовую высоту вашего дома.',
      link: 'Дополнительная информация',
      mitigationDifference: {
        currentHeader: 'Для вашей текущей зоны',
        futureHeader: 'Для вашей ориентировочной зоны',
        saved: 'сохранено',
        perYear: ' /в год*',
        nonpositiveSavingsCopy: 'Это не снизит ваш тариф'
      }
    },
    elevateMechanicals: {
      header: 'Поднимите ваши механические устройства',
      body: 'Защищает критические системы вашего дома.',
      link: 'Дополнительная информация',
      mitigationDifference: {
        currentHeader: 'Для вашей текущей зоны',
        futureHeader: 'Для вашей ориентировочной зоны',
        saved: 'сохранено',
        perYear: ' /в год*',
        nonpositiveSavingsCopy: 'Это не снизит ваш тариф'
      }
    },
    floodVents: {
      header: 'Установка паводковых отверстий',
      body:
        'Сокращает риск повреждения сточными водами, предоставляя им возможность протекать насквозь и вытекать.',
      link: 'Дополнительная информация',
      mitigationDifference: {
        currentHeader: 'Для вашей текущей зоны',
        futureHeader: 'Для вашей ориентировочной зоны',
        saved: 'сохранено',
        perYear: ' /в год*',
        nonpositiveSavingsCopy: 'Это не снизит ваш тариф'
      }
    },
    fillInBasement: {
      header: 'Засыпьте ваш подвал',
      body: 'Уменьшает вред, наносимый структурному фундаменту вашего дома.',
      link: 'Дополнительная информация',
      mitigationDifference: {
        currentHeader: 'Для вашей текущей зоны',
        futureHeader: 'Для вашей ориентировочной зоны',
        saved: 'сохранено',
        perYear: ' /в год*',
        nonpositiveSavingsCopy: 'Это не снизит ваш тариф'
      }
    }
  };
  