import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import Card from './layout/Card';
import OtherResources from './OtherResources';
import RoundIcon from './RoundIcon';
import WaveAnimation from './WaveAnimation';
import ButtonLink from './ui/ButtonLink';
import camera from '../images/camera.svg';
import certificate from '../images/certificate.svg';
import carpet from '../images/carpet.svg';
import sortingOptions from '../images/sorting-options.svg';
import hurricanePosterPreview from '../images/hurricane-poster-preview.png';
import PlanIcon from '../images/hurricane-icons-make-a-plan.png';
import SuppliesIcon from '../images/hurricane-icons-gather-supplies.png';
import InformedIcon from '../images/hurricane-icons-get-informed.png'

import { colors } from '../styles/variables';
import {
  HeaderSection,
  Subheader,
  Section
} from './ui/StyledResourcePageElements';
import { useProvider } from '../helpers/hooks';

const NarrowHeaderSection = styled(HeaderSection)`
  width: 900px;
  max-width: 90%;
  margin: 0 auto;
  position: relative;
  height: 100vh;
  min-height: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 700px) {
    width: 85%;
    min-height: 0;
  }
`;

const NarrowSection = styled(Section)`
  width: 900px;
  max-width: 90%;
  margin: 4rem auto;

  @media (max-width: 700px) {
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const BackgroundImage = styled.div`
  background: ${colors.resilientBlue};
  position: relative;
  overflow: hidden;
`;

const WhiteHeader = styled.h1`
  color: white;
`;

const WhiteSubheader = styled(Subheader)`
  color: white;
`;

const PosterPreview = styled.img`
  height: 110px;
  width: auto;
`;

const IconAndTextContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  p {
    margin-left: 20px;
    font-size: 1.5rem;
  }
`;

const HurricaneSeason = () => {
  const provider = useProvider('hurricaneSeason');

  return (
    <>
      <Helmet>
        <title>{provider.meta.title}</title>
        <link rel="canonical" href={provider.meta.url} />
        <meta name="description" content={provider.meta.description} />
        <meta property="og:title" content={provider.meta.title} />
        <meta property="og:description" content={provider.meta.description} />
        <meta property="og:url" content={provider.meta.url} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <BackgroundImage>
        <WaveAnimation />
        <NarrowHeaderSection>
          <WhiteHeader>{provider.header}</WhiteHeader>
          <WhiteSubheader>{provider.subHeader}</WhiteSubheader>
        </NarrowHeaderSection>
      </BackgroundImage>
      <NarrowSection>
        <IconAndTextContainer>
          <RoundIcon src={InformedIcon} alt="Informed" />
          <p dangerouslySetInnerHTML={{ __html: provider.getInformed }} />
        </IconAndTextContainer>
        <IconAndTextContainer>
          <RoundIcon src={PlanIcon} alt="Plans" />
          <p dangerouslySetInnerHTML={{ __html: provider.makePlan }} />
        </IconAndTextContainer>
        <IconAndTextContainer>
          <RoundIcon src={SuppliesIcon} alt="Supplies" />
          <p dangerouslySetInnerHTML={{ __html: provider.gather }} />
        </IconAndTextContainer>
        <IconAndTextContainer>
          <p dangerouslySetInnerHTML={{ __html: provider.reportDamage }} />
        </IconAndTextContainer>
      </NarrowSection>
      <NarrowSection>
        <Card>
          <PosterPreview src={hurricanePosterPreview} alt="Hurricane Poster" />
          <div>
            <h4>{provider.posterCallToAction}</h4>
            <p>{provider.posterCopy}</p>
          </div>
          <ButtonLink
            text={provider.posterButton}
            url={provider.posterUrl}
            external
          />
        </Card>
      </NarrowSection>
      <OtherResources />
    </>
  );
};

export default HurricaneSeason;
