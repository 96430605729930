export default {
  loadingText: 'Loading...',
  addressInput: {
    subHeading: '',
    placeholder: 'Enter your address',
    action: 'Get Started'
  },
  useAutocomplete: 'Please select an address from the drop-down',
  lookupError: {
    title: 'Error',
    error:
      'Please verify the address or try again later. If you feel you received this message in error, call our Homeowner Hub at 646-786-0888.',
    tryAgain: 'Try Again'
  },
  apiError: {
    title: 'Error',
    error:
      "We're sorry, our servers are unable to get a response from the Google address lookup service. Call our Homeowner Hub at 646-786-0888 if you have any questions.",
    tryAgain: 'Try Again'
  },
  multipleResultsError: {
    title: 'Error',
    error:
      "We're sorry. There are multiple matches for the address you entered. Please verify the address or try again later. If you feel you received this message in error, please call our Homeowner Hub at 646-786-0888.",
    tryAgain: 'Try Again'
  },
  outsideNycError: {
    title: 'Error',
    error:
      "We're sorry. This address doesn't appear to match publicly available data from NYC. If you feel you received this message in error, please call our Homeowner Hub at 646-786-0888.",
    tryAgain: 'Try Another Address'
  },
  tooBroadError: {
    title: 'Can you be more specific?',
    error:
      'FloodHelpNY.org provides information on flood insurance and resiliency options based on individual addresses. Please enter your home address or the address of another home you’d like to look up. Or, if you feel you received this message in error, please call our Homeowner Hub at 646-786-0888.',
    tryAgain: 'Try Again'
  }
};
