export default {
  header: 'ВАШ СТРАХОВОЙ ТАРИФ, ОСНОВАННЫЙ НА ВАШЕМ СЕРТИФИКАТЕ ВЫСОТНОЙ ОТМЕТКИ ',
  current: 'Текущие катры',
  future: 'Ориентировочные карты',
  zone: 'зона',
  perYear: 'В год*',
  assumingMax: '*Из расчета максимального покрытия',
  numbersAreEstimates:
    'Эти цифры являются приблизительными по многим причинам. Чтобы узнать более подробную информацию о них обратитесь к нашим ',
  termsAndConditions: 'Правилам и условиям'
};
