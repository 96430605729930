import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import speechBubble from '../images/speech-bubble-white.svg';
import { useProvider } from '../helpers/hooks';
import { colors } from '../styles/variables';

const Wrapper = styled.div`
  background-color: ${colors.statusBarGreen};
  padding: 2rem;
  display: flex;
  margin-bottom: 3rem;
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.sourApple};
  flex-shrink: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;

  img {
    width: 40%;
  }
`;

const Content = styled.div`
  margin-left: 3rem;

  p {
    color: ${colors.grayScale[3]};
  }
`;

const AuditCounselorNotes = ({ body }) => {
  const provider = useProvider('auditCounselorNotes');

  return (
    <Wrapper>
      <ImageWrapper>
        <img src={speechBubble} alt="speech bubble" />
      </ImageWrapper>
      <Content>
        <p>{provider.header}</p>
        <p>{body}</p>
      </Content>
    </Wrapper>
  );
};

AuditCounselorNotes.propTypes = {
  body: PropTypes.string.isRequired
};

export default AuditCounselorNotes;
