const genderOptions = [
  {
    en: 'Male',
    es: 'Masculino'
  },
  {
    en: 'Female',
    es: 'Femenino'
  },
  {
    en: 'Non-binary',
    es: 'No-Binario'
  },
  {
    en: 'Other',
    es: 'Otro'
  },
  {
    en: 'Decline to Answer',
    es: 'Negarse a Contestar'
  }
];

const getGenderOptions = (language) =>
  genderOptions.map((option) => ({
    value: option.en,
    display: option[language]
  }));

export default getGenderOptions;
