export default {
  header: 'Ваш отчет',
  copy: [
    'Это отчет об устойчивости для',
    'с некоторыми техническими рекомендациями относительно того, как вы можете сделать ваш дом и финансы более устойчивым к наводнениям.'
  ],
  links: {
    property: 'Недвижимость',
    rate: 'Тариф страхования',
    resiliency: 'Стратегии устойчивости',
    documents: 'Документы'
  }
};
