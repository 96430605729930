import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors, borderRadius } from '../../styles/variables';
import { InputVisibilityWrapper } from './StyledInput';

const Container = styled.div`
  display: flex;
  align-items: center;

  label {
    margin: 1rem 1rem 1rem 0;
    width: 25%;

    @media (max-width: 680px) {
      width: 100%;
    }
  }

  select {
    display: inline-block;
    margin: 0;
    background-color: #fff;
    border: 1px solid ${colors.grayScale[1]};
    font-size: 1rem;
    font-weight: 500;
    border-radius: ${borderRadius};
    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease;
    overflow: hidden;
    padding: 1rem 3rem;
    width: 50%;

    @media (max-width: 680px) {
      margin-bottom: 1rem;
      width: 100%;
    }
  }

  @media (max-width: 680px) {
    flex-direction: column;
    align-items: start;
  }
`;

const SelectElementSingle = ({
  responseField,
  answer,
  answers,
  questionKey,
  setAnswers,
  index
}) => {
  const setResponse = (e) => {
    const newAnswersArray = [...answers];
    newAnswersArray[index][questionKey] = e.target.value;
    setAnswers(newAnswersArray);
  };

  const renderDefault = () => {
    if (!responseField.default) {
      return null;
    }
    return <option value="">{responseField.default}</option>;
  };
  return (
    <InputVisibilityWrapper show={responseField.show}>
      <Container>
        {responseField.title && <label>{responseField.title}</label>}
        <select value={answer} onChange={(e) => setResponse(e)}>
          {renderDefault()}
          {responseField.options.map((field) => {
            return (
              <option key={field.value} value={field.value}>
                {field.display}
              </option>
            );
          })}
        </select>
      </Container>
    </InputVisibilityWrapper>
  );
};

SelectElementSingle.propTypes = {
  responseField: PropTypes.object.isRequired,
  answer: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  questionKey: PropTypes.string.isRequired,
  answers: PropTypes.array.isRequired,
  setAnswers: PropTypes.func.isRequired
};

export default SelectElementSingle;
