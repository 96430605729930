import backwaterValve from '../images/backwater-valve.svg';
import floodproofing from '../images/floodproofing.svg';
import passFail from '../images/pass-fail.svg';
import raiseMechanicals from '../images/raise-mechanicals.svg';
import resilientRetail from '../images/resilient-retail-cover.jpg';
import assess from '../images/assess.svg';
import floodBarrier from '../images/flood-barrier.svg';
import generator from '../images/generator.svg';
import storefronts from '../images/storefronts.png';
import waterPump from '../images/water-pump.svg';
import waterproofStorage from '../images/waterproof-storage.svg';
import protect from '../images/protect.svg';
import sbsLogo from '../images/sbs-logo.svg';
import strategy from '../images/strategy.svg';

export default {
  meta: {
    title: 'Flood Risk For Small Businesses | FloodHelpNY',
    ogTitle: 'Small Business Flood Risk Explained | FloodHelpNY',
    url: 'https://www.floodhelpny.org/en/small-business',
    description:
      'Flooding can be financially devastating for small business owners. Here’s how you can protect your operations and your livelihood.',
    imageAlt: 'Small Business | FloodHelpNY'
  },
  header: 'Small Business: Flood Risk Explained',
  subHeader:
    'Flooding can be financially devastating for small business owners. Here’s how you can protect your operations and your livelihood.',
  overview: {
    header: 'OVERVIEW',
    body:
      'Flooding can do more than cause damage to your inventory or interrupt your operations — it can shut down your business for good. In fact, at least 25 percent of small businesses affected by disasters never reopen. But with proper planning, you can respond and recover more quickly. Let us guide you through simple steps you can take to prepare for flooding and to minimize damage that could have you closing your doors for good.'
  },
  attribution: {
    logo: sbsLogo,
    logoAlt: 'New York City Small Business Services Logo',
    image: storefronts,
    imageAlt: 'Storefronts in New York City',
    credit: 'This page is brought to you by:'
  },
  whatYouCanDo: {
    header: 'WHAT SMALL BUSINESS OWNERS CAN DO',
    assess: {
      header: 'Assess',
      icon: assess,
      iconAlt: 'House',
      body:
        "It's essential that you take the time to assess the risks that flooding can pose to your business. Learn your flood zone, consider all hazards to health and risks to your operations."
    },
    prepare: {
      header: 'Prepare',
      icon: passFail,
      iconAlt: 'Checklist',
      body:
        "Be sure you have an emergency plan to ensure continuity of operations and to safeguard your employees. It's also essential that you purchase or renew your flood insurance."
    },
    protect: {
      header: 'Protect',
      icon: protect,
      iconAlt: 'Shield',
      body:
        "Learn about steps you can take to protect your business's structure and equipment, as well as income, so that you can recover from flooding as quickly as possible."
    },
    callToAction: {
      header: 'Start Your Business Continuity Plan Today',
      icon: strategy,
      iconAlt: 'Start Your Business Continuity Plan Today',
      body:
        'Use this framework to quickly bounce back after any interruption. Protect your investments, stay in touch with employees and vendors, and be ready to respond to uncertainty.',
      buttonText: 'View'
    }
  },
  riskZoneHeader: 'UNDERSTAND YOUR FLOOD RISK',
  severeRiskZone: 'Highest Risk',
  highRiskZone: 'High Risk',
  moderateRiskZone: 'Moderate Risk',
  warning: {
    primary: {
      body:
        'Currently, there are 14,500 businesses that operate in areas most at risk for flooding in New York City. According to the <a href="https://www.fema.gov" target="_blank" rel="noopener noreferrer">Federal Emergency Management Agency (FEMA)</a>, major disasters can affect close to 40% of small businesses within one year of a flooding event, while 25% fail after one year of being affected.'
    },
    aside: {
      header: 'Building coverage limits',
      body:
        'The maximum amount of flood insurance you can buy to cover the cost of damage to your building is $500,000.'
    }
  },
  floodInsurance: {
    primary: {
      header: 'FLOOD INSURANCE CAN LOWER YOUR RISK',
      body:
        'The purpose of a flood insurance policy is to provide financial compensation after a flood loss, which is typically excluded from a property insurance policy. Not having a flood policy in place can put the business at risk of financial loss in the event of a flood, which is the most commonly occurring natural disaster in the world. And floods can happen anywhere--more than 20 percent of flood claims come from properties outside the high-risk flood zone.'
    },
    aside: {
      header: 'Buy now, save later',
      body:
        'If you buy flood insurance now, you could save yourself thousands of dollars over the next few years.'
    }
  },
  businessInsurance: {
    header: 'BUSINESS INTERRUPTION INSURANCE',
    body:
      'Business interruption insurance (also known as business income insurance) is a type of insurance that covers the loss of income that a business suffers after a disaster. The income loss covered may be due to disaster-related closing of the business facility or due to the rebuilding process after a disaster. It’s important that business owners understand that their current property insurance may not cover their revenues. The loss of income generated by the business could mean the difference between keeping your doors open or closing permanently.'
  },
  lowerYourRiskOptions: {
    header: 'Physical Measures to Lower your Flood Risk',
    list: [
      {
        image: raiseMechanicals,
        imageAlt: 'House',
        title: 'Raise your mechanicals',
        body:
          "Most often, this equipment is in a building's basement where it can be exposed to floodwater."
      },
      {
        image: backwaterValve,
        imageAlt: 'Valve',
        title: 'Install a backwater valve',
        body:
          'This helps prevent wastewater from entering your building in the event of a sewer overflow.'
      },
      {
        image: floodproofing,
        imageAlt: 'House',
        title: 'Wet and dry floodproofing',
        body:
          'If you cannot relocate and your building structure allows it, you should consider wet and dry floodproofing as flood protection options.'
      }
    ]
  },
  otherMethodsToLowerRisk: {
    title: 'OTHER METHODS OF LOWERING YOUR FLOOD RISK',
    subtitle:
      'Flooding can happen at any time and in areas with little or no risk of flooding. Whether you are a property owner or a tenant, there are measures you could take today to help you stay in business and get you up and running after a flood event. Consider the following equipment to protect your business against flood damage and to help you to recover faster.',
    list: [
      {
        header: 'Portable flood barriers',
        body:
          'Portable flood barriers that are designed for temporary installation/placement at doors and other points of potential penetration can help a business protect its space from water inundation and damage.',
        image: floodBarrier,
        imageAlt: 'Portable flood barriers'
      },
      {
        header: 'Portable generator',
        body:
          'Portable generators can be used to power equipment for continued operations or to maintain environmental conditions during primary power loss.',
        image: generator,
        imageAlt: 'Portable generator'
      },
      {
        header: 'Portable waterproof storage',
        body:
          'A portable waterproof storage box can preserve essential documents, enabling a business to access critical information required for responding to a disaster incident and resuming operations in a timely manner.',
        image: waterproofStorage,
        imageAlt: 'Portable waterproof storage'
      },
      {
        header: 'Portable submersible water pump',
        body:
          'A submersible water pump and hose can be used to prevent accumulation of floodwater entering a building, or remove water after a flood event, mitigating damage to the building or supplies and expediting the recovery process.',
        image: waterPump,
        imageAlt: 'Portable submersible water pump'
      }
    ]
  },
  continuityPlan: {
    title: 'City planning resilient retail guide',
    body:
      'A unique resource to help business owners located in retail corridors throughout the city’s flood plain.',
    view: 'View',
    image: resilientRetail,
    imageAlt: 'Coastal Climate Resiliency Guide'
  }
};
