import testimonialPatti from '../images/testimonial-patti.jpg';
import testimonialCarla from '../images/testimonial-carla.jpg';
import testimonialLucia from '../images/testimonial-lucia.jpg';

export default {
  title: 'Rising Tides, Rising Rates',
  subtitle:
    "Read the Center's landmark report on flood Insurance and New York City's affordability crisis",
  people: [
    {
      name: 'Patti',
      neighborhood: 'Midland Beach',
      image: testimonialPatti
    },
    {
      name: 'Carla',
      neighborhood: 'Canarsie',
      image: testimonialCarla
    },
    {
      name: 'Lucia',
      neighborhood: 'Coney Island',
      image: testimonialLucia
    }
  ],
  explanation:
    "Rising flood insurance rates and rising sea levels have the potential to permanently displace working- and middle-class New Yorkers from coastal neighborhoods. Without a concerted intervention, rising insurance premiums could lead to a future where the city's coasts are affordable only to its wealthiest residents.",
  link: 'Learn more'
};
