export default {
  header: 'SA OU TA KA FÈ POU BESE RISK AK TARIF OU',
  assumingMax: '*Nou sipoze yon kouvèti maksimòm',
  numbersAreEstimates:
    'Chif sa yo se estimasyon pou anpil rezon, pou aprann plis sou yo ale sou ',
  termsAndConditions: 'Tèm ak Kondisyon nou yo',
  noRecommendations: 'Dezole, nou pa t ka idantifye okenn opsyon',
  backwaterValveProvider: {
    header: {
      inProgress: 'Enstale yon valv pou dlo k ap tounen',
      complete: 'Apwopo valv pou dlo k ap tounen ou an'
    },
    completeHeader: 'Enstalasyon an fini!',
    topBody: 'Sa ede anpeche dlo ize antre lakay ou nan ka yon egou devide.',
    bottomBody:
      'Ou kalifye pou resevwa yon enstalasyon valv pou dlo k ap tounen gratis.',
    bottomBodyComplete:
      'Nou ta renmen tande kijan pwosesis lan te ye. Tanpri pran kèk minit pou ranpli sondaj nou an.',
    buttons: {
      learnMore: 'Aprann plis',
      startProcess: 'Kòmanse Pwosesis lan',
      feedbackSurvey: 'Sondaj sou Kòmantè yo'
    },
    steps: {
      initiate: 'Enstalasyon an ap dewoule',
      schedule: 'Enstalasyon an ap dewoule',
      install: 'Enstalasyon an fini!'
    }
  },
  elevateBuildingProvider: {
    header: 'Elve kay ou an',
    body: 'Mete kay ou an totalman anwo nivo dlo inondasyon ki prevwa.',
    link: 'Aprann Plis',
    lowerYourRate: 'Sa pral bese tarif ou.',
    auditRateDifferenceProvider: {
      currentHeader: 'Pou zòn aktyèl ou an',
      futureHeader: 'Pou zòn konsiltasyon ou an',
      saved: 'sove',
      perYear: ' /ane*',
      nonpositiveSavingsCopy: 'Sa pa p bese tarif ou.'
    }
  },
  abandonFirstFloorProvider: {
    header: 'Abandone premye etaj ou an',
    body: 'Sa elve nan yon fason efikas elevasyon debaz kay ou an.',
    link: 'Aprann Plis',
    lowerYourRate: 'Sa pral bese tarif ou.',
    auditRateDifferenceProvider: {
      currentHeader: 'Pou zòn aktyèl ou an',
      futureHeader: 'Pou zòn konsiltasyon ou an',
      saved: 'sove',
      perYear: ' /ane*',
      nonpositiveSavingsCopy: 'Sa pa p bese tarif ou.'
    }
  },
  fillInBasementProvider: {
    header: 'Ranpli sousòl ou an',
    body: 'Redui domaj yo nan fondasyon estrikti kay ou an.',
    link: 'Aprann Plis',
    lowerYourRate: 'Sa pral bese tarif ou.',
    auditRateDifferenceProvider: {
      currentHeader: 'Pou zòn aktyèl ou an',
      futureHeader: 'Pou zòn konsiltasyon ou an',
      saved: 'sove',
      perYear: ' /ane*',
      nonpositiveSavingsCopy: 'Sa pa p bese tarif ou.'
    }
  },
  elevateMechanicalsProvider: {
    header: 'Elve sistèm mekanik ou yo',
    body: 'Pwoeje sistèm kritik kay ou an.',
    link: 'Aprann Plis',
    lowerYourRate: 'Sa pral bese tarif ou.',
    auditRateDifferenceProvider: {
      currentHeader: 'Pou zòn aktyèl ou an',
      futureHeader: 'Pou zòn konsiltasyon ou an',
      saved: 'sove',
      perYear: ' /ane*',
      nonpositiveSavingsCopy: 'Sa pa p bese tarif ou.'
    }
  }
};
