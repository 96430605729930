export default class FloodZones {
  static get SINGLE_FAMILY() {
    return 'Single Family';
  }

  static get TWO_TO_FOUR_FAMILY() {
    return '2-4 Family';
  }

  static get MULTI_FAMILY() {
    return '5+';
  }
}
