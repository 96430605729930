const grayScale = [
  '#F3F4F7',
  '#CBD1DC',
  '#A3ADC2',
  '#7B8AA7',
  '#5A6987',
  '#3F4A5F',
  '#252B37'
];
const resilientBlue = '#0047FF';
const sourApple = '#1BD357';
const almostBlack = '#252B37';
const internalActionColor = '#58CBB1';
const mutedGray = '#A3ADC2';
const minimalHazard = '#F2EADA';

const colors = {
  beachBeige: '#F6F5F1',
  resilientBlue,
  deepBlue: '#1F4097',
  sourApple,
  pinkBerry: '#F11E54',
  winterGreen: '#46D1B5',
  tideCanvasBackground: '#EAF1FA',
  lightPink: '#ef7f9b',
  statusBarGreen: '#c3f7d4',
  grayScale,
  heading: '#3F4A5F',
  secondaryHeading: '#5A6987',
  mutedGray,
  tagline: mutedGray,
  contentCopy: '#000',
  internalAction: internalActionColor,
  melrose: '#ADA7FF',
  gorse: '#FCFA51',
  navyBlue: '#1F3153',
  denim: '#4990E2',
  emergency: '#203252',
  paleYellow: '#FFF577',
  almostBlack,
  footerNavHover: 'white',
  footerBackground: almostBlack,
  successNoticeBackground: 'rgba(88,203,177,0.12)',
  successNotice: 'rgb(88,203,177)',
  failureNoiceBackground: 'rgba(241,114,54,0.12)',
  failureNotice: 'rgb(241,114,54)',
  link: '#4C54C0',
  x: '#FFC900',
  ae: '#F57898',
  ao: '#F57898',
  ve: '#C24D6B',
  minimalHazard,
  buttonSuccessBackground: internalActionColor,
  navLinkHoverBackground: 'transparent',
  bodyBlue: '#1e3053',
  accent: {
    main: resilientBlue,
    secondary: grayScale[1],
    confirm: sourApple
  }
};

const borderRadius = '3px';

const cubicBezier = {
  ease: 'cubic-bezier(0.52, 0.01, 0.16, 1)',
  hard: 'cubic-bezier(0, 0, 0, 1)'
};

const getZoneColor = (floodZone) => {
  if (floodZone === 'None') {
    return minimalHazard;
  }
  return colors[floodZone.toLowerCase()];
};

export { getZoneColor, colors, cubicBezier, borderRadius };
