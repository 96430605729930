import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import HashLinkObserver from 'react-hash-link';
import { colors } from '../styles/variables';
import AuditRate from './AuditRate';
import AuditCounselorNotes from './AuditCounselorNotes';
import arrow from '../images/arrow.svg';
import AuditCalculatorFormatter from '../helpers/AuditCalculatorFormatter';
import { useLocale, useProvider } from '../helpers/hooks';
import { Section } from './ui/StyledResourcePageElements';

const Content = styled.div`
  border: 10px solid ${colors.grayScale[0]};
  padding: 4rem;
  margin-top: 3rem;
`;

const MapType = styled.div`
  color: ${colors.pinkBerry};
`;

const RateAmount = styled.div`
  font-size: 3rem;
  font-family: GT-Walsheim-Pro-Bold;
  margin-bottom: 1rem;
`;

const PerYear = styled.span`
  padding-left: 1rem;
  font-size: 1rem;
`;

const Data = styled.div`
  display: flex;
  max-width: 700px;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  margin: 0 auto;

  > * {
    @media (max-width: 1200px) {
      margin-top: 2rem;

      :first-child {
        margin-top: 0;
      }
    }
  }

  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: center;
    padding-top: 0;
  }
`;

const ArrowImage = styled.img`
  @media (max-width: 1200px) {
    transform: rotate(90deg);
    max-height: 50px;
    max-width: 50px;
  }
`;

const Notes = styled.div`
  text-align: center;
  border-top: 1px solid ${colors.grayScale[1]};

  p {
    font-size: 1rem;
    margin-top: 1rem;
  }
`;

const AuditRates = ({ application, notes }) => {
  const provider = useProvider('auditRates');
  const locale = useLocale();

  const { property } = application.property_contact_detail;
  const audit = application.selected_resiliency_audit;
  const currentInputs = AuditCalculatorFormatter.formatCalculatorParams(
    property,
    audit,
    true
  );
  const futureInputs = AuditCalculatorFormatter.formatCalculatorParams(
    property,
    audit,
    false
  );

  return (
    <Section id="audit-rates">
      <HashLinkObserver />
      <h5>{provider.header}</h5>
      <div>
        <Content>
          <Data>
            <div>
              <MapType>
                {`${provider.current} (${property.current_flood_zone} ${provider.zone})`}
              </MapType>
              <RateAmount>
                <AuditRate
                  results={currentInputs}
                  applicationId={application.external_id}
                  rateKey="current_rate"
                />
                <PerYear>{provider.perYear}</PerYear>
              </RateAmount>
            </div>
            <ArrowImage src={arrow} alt="arrow" />
            <div>
              <MapType>
                {`${provider.future} (${property.future_flood_zone} ${provider.zone})`}
              </MapType>
              <RateAmount>
                <AuditRate
                  results={futureInputs}
                  applicationId={application.external_id}
                  rateKey="advisory_rate"
                />
                <PerYear>{provider.perYear}</PerYear>
              </RateAmount>
            </div>
          </Data>
          <Notes>
            <p>{provider.assumingMax}</p>
            <p>
              {provider.numbersAreEstimates}
              <a
                target="_blank"
                rel="noreferrer noopener"
                href={`/${locale}/terms`}
              >
                {provider.termsAndConditions}
              </a>
            </p>
          </Notes>
        </Content>
        <AuditCounselorNotes body={notes} />
      </div>
    </Section>
  );
};

AuditRates.propTypes = {
  application: PropTypes.object.isRequired,
  notes: PropTypes.object.isRequired
};

export default AuditRates;
