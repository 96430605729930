import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import RateHeader from './rateComponents/RateHeader';
import PrefirmCallout from './rateComponents/PrefirmCallout';
import LomaCallout from './rateComponents/LomaCallout';
import RateCalculatorResult from './rateComponents/RateCalculatorResult';
import LomaHelper from '../helpers/LomaHelper';
import ElevateBuildingHelper from '../helpers/profileMitigation/ElevateBuildingHelper';
import AbandonFirstFloorHelper from '../helpers/profileMitigation/AbandonFirstFloorHelper';
import ElevateMechanicalsHelper from '../helpers/profileMitigation/ElevateMechanicalsHelper';
import FloodVentsHelper from '../helpers/profileMitigation/FloodVentsHelper';
import FillInYourBasementHelper from '../helpers/profileMitigation/FillInYourBasementHelper';
import SalesforceCommunicator from '../communicators/SalesforceCommunicator';

const Rate = ({ locale, salesforceApi, calculatorApi, propertyId }) => {
  const provider = require(`../providers/Rate.${locale}`).default;
  const [property, setProperty] = useState({});

  useEffect(() => {
    // Returns an object: { property: {}, property_details: {} }
    const getProperty = async () => {
      try {
        const result = await SalesforceCommunicator.getPropertyInfo(
          salesforceApi,
          propertyId
        );
        const propertyInfo = Object.assign(
          result.property,
          result.property_details
        );
        setProperty(propertyInfo);
      } catch (error) {
        // TODO add an error handler for this component
      }
    };
    getProperty();
  }, []);

  const getBackToProfileCopy = () => {
    const elevateBuilding = ElevateBuildingHelper.showMitigation(property);
    const abandonFirstFloor = AbandonFirstFloorHelper.showMitigation(property);
    const raiseMechanicals = ElevateMechanicalsHelper.showMitigation(property);
    const floodVents = FloodVentsHelper.showMitigation(property);
    const fillInBasement = FillInYourBasementHelper.showMitigation(property);
    if (
      elevateBuilding ||
      abandonFirstFloor ||
      raiseMechanicals ||
      floodVents ||
      fillInBasement
    ) {
      return provider.profileCalloutCopy;
    }
    return provider.noMitigationCopy;
  };

  const renderLomaCallout = () => {
    if (!LomaHelper.showLomaCallout(property)) {
      return null;
    }
    return <LomaCallout provider={provider.lomaCalloutProvider} />;
  };

  const renderPrefirmCallout = () => {
    if (!property.pre_firm) {
      return null;
    }
    return <PrefirmCallout provider={provider.prefirmCalloutProvider} />;
  };

  if (!property) {
    return null;
  }

  return (
    <div className="rate-contents">
      <Helmet>
        <title>{provider.title}</title>
        <link rel="canonical" href={provider.url} />
        <meta name="description" content={provider.description} />
        <meta property="og:title" content={provider.title} />
        <meta property="og:image" content={provider.image} />
        <meta property="og:description" content={provider.description} />
        <meta property="og:url" content={provider.url} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image:alt" content={provider.imageAlt} />
      </Helmet>
      <RateHeader
        provider={provider.headerProvider}
        property={property}
        locale={locale}
        salesforceApi={salesforceApi}
      />
      <RateCalculatorResult
        locale={locale}
        provider={provider.rateCalculatorResultProvider}
        property={property}
        api={calculatorApi}
        showSummary={false}
      />
      {renderPrefirmCallout()}
      {renderLomaCallout()}
      <div className="beta-callout">
        <p>
          {provider.betaCalloutCopy}
          <a href="mailto:info@floodhelpny.org">{provider.betaCalloutLink}</a>
        </p>
      </div>
      <div className="promo promo--resiliency-profile">
        <p>{getBackToProfileCopy()}</p>
        <a
          className="btn btn--main--ghost btn--big"
          href={`/${locale}/profile/${property.external_id}`}
        >
          {provider.profileCalloutLink}
        </a>
      </div>
    </div>
  );
};

Rate.propTypes = {
  locale: PropTypes.string.isRequired,
  salesforceApi: PropTypes.string.isRequired,
  calculatorApi: PropTypes.string.isRequired,
  propertyId: PropTypes.string.isRequired
};

export default Rate;
