import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import RtappApplicationState from '../constants/RtappApplicationState';
import SalesforceCommunicator from '../communicators/SalesforceCommunicator';
import StaticMap from './StaticMap';
import FloodZoneInfo from './FloodZoneInfo';
import RiskInfo from './RiskInfo';
import AuditStatusTracker from './AuditStatusTracker';
import AuditReview from './AuditReview';
import AuditHeader from './AuditHeader';
import AuditPropertyRecommendation from './AuditPropertyRecommendation';
import AuditReportFromCounselor from './AuditReportFromCounselor';
import AuditRates from './AuditRates';
import AuditRecommendations from './AuditRecommendations';
import AuditDocuments from './AuditDocuments';
import AuditStatusContainer from './AuditStatusContainer';
import ContactUs from './ContactUs';
import Error from './layout/Error';
import Loading from './sharedComponents/Loading';
import { useApi, useProvider, useFaq } from '../helpers/hooks';

const Audit = () => {
  const provider = useProvider('Audit');
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [application, setApplication] = useState({});
  const [applicationState, setApplicationState] = useState('');

  const { salesforceApi } = useApi();
  const { applicationId } = useParams();
  const { setFaqZone } = useFaq();

  useEffect(() => {
    setFaqZone('rtapp-status');
  }, []);

  useEffect(() => {
    const getApplicationData = async () => {
      try {
        const result = await SalesforceCommunicator.getAuditApplication(
          salesforceApi,
          applicationId
        );
        setApplication(result.kase);

        const webStatus = result.kase.webstatus;
        setApplicationState(webStatus);
        if (webStatus === '' || !webStatus) {
          setFaqZone('rtapp-status');
        } else {
          setFaqZone(`rtapp-status-${webStatus}`);
        }
        setIsLoading(false);
      } catch (error) {
        setHasError(true);
        setIsLoading(false);
      }
    };
    getApplicationData();
  }, [applicationId, salesforceApi]);

  const renderFloodZoneContainer = () => {
    const { property } = application.property_contact_detail;
    return <FloodZoneInfo property={property} />;
  };

  const renderFloodZoneInfo = () => {
    const { property } = application.property_contact_detail;

    if (applicationState !== RtappApplicationState.PRE_APPLICATION) {
      return null;
    }

    return (
      <div>
        <StaticMap address={property.combined_address} />
        <RiskInfo property={property} />
        {renderFloodZoneContainer()}
      </div>
    );
  };

  const renderReportComponents = () => {
    const DEFAULT_FREEBOARD = 2;
    const address = application
      ? application.property_contact_detail.property.combined_address
      : '';
    const pagesWithExtraComponents = [
      RtappApplicationState.CONSULTATION_SCHEDULED,
      RtappApplicationState.CONSULTATION_COMPLETE,
      RtappApplicationState.INSTALLATION_PREPARE,
      RtappApplicationState.MEET_YOUR_TEAM,
      RtappApplicationState.INSTALLATION_TO_BE_SCHEDULED,
      RtappApplicationState.INSTALLATION_HAS_BEEN_SCHEDULED,
      RtappApplicationState.INSTALLATION_COMPLETE,
      RtappApplicationState.REPORT_COMPLETE
    ];
    const shouldDisplayExtraComponents = pagesWithExtraComponents.includes(
      applicationState
    );
    // Give the Application object empty audit and counseling objects if
    // it does not have any value for those two objects
    const formattedApplication = application;
    if (!application.selected_resiliency_audit) {
      formattedApplication.selected_resiliency_audit = {};
    }

    if (!application.resiliency_counseling) {
      formattedApplication.resiliency_counseling = {};
    }

    if (!shouldDisplayExtraComponents) {
      return null;
    }
    const reportComponents = [
      <AuditReportFromCounselor key="your-profile" address={address} />,
      <AuditPropertyRecommendation
        key="where-your-property-should-be"
        topOfBottomFloor={
          formattedApplication.selected_resiliency_audit.top_of_bottom_floor
        }
        baseFloodElevation={
          formattedApplication.selected_resiliency_audit.base_flood_elevation
        }
        freeboardCity={
          formattedApplication.selected_resiliency_audit.freeboard_city ||
          DEFAULT_FREEBOARD
        }
        notes={
          formattedApplication.resiliency_counseling.counselor_notes_elevation
        }
      />,
      <AuditRates
        key="audit-rates"
        application={formattedApplication}
        notes={formattedApplication.resiliency_counseling.counselor_notes_rate}
      />,
      <AuditRecommendations
        key="recommendations"
        property={formattedApplication.property_contact_detail}
        audit={formattedApplication.selected_resiliency_audit}
        applicationId={formattedApplication.external_id}
        notes={
          formattedApplication.resiliency_counseling.counselor_notes_mitigation
        }
      />
    ];
    return reportComponents;
  };

  const getFaqZone = () => {
    if (applicationState === RtappApplicationState.PRE_APPLICATION_APPROVED) {
      return faqZone;
    }

    return `rtapp-status-${applicationState}`;
  };

  if (hasError) {
    return <Error provider={provider} height="50%" />;
  }
  if (isLoading) {
    return <Loading provider={provider} height="500px" />;
  }

  return (
    <>
      <Helmet>
        <title>{provider.meta.title}</title>
        <link rel="canonical" href={provider.meta.url} />
        <meta property="og:title" content={provider.meta.title} />
        <meta property="og:url" content={provider.meta.url} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <AuditHeader application={application} />
      <AuditStatusTracker applicationState={applicationState} />
      {renderFloodZoneInfo()}
      <AuditStatusContainer
        applicationState={applicationState}
        application={application}
      />
      {renderReportComponents()}
      <AuditDocuments attachments={application.case_attachment_downloads} />
      <AuditReview application={application} />
      <ContactUs />
    </>
  );
};

export default Audit;
