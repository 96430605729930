import React from 'react';
import PropTypes from 'prop-types';

// Used in Rate Calculator application
const ImageRadioButtons = ({ answer, responseField, setResponse }) => {
  const buttons = responseField.options.map((option, index) => {
    const key = `radio-${index}`;
    return (
      <div key={key} className="radio-button">
        <input
          id={key}
          type="radio"
          name={responseField.key}
          value={option.value}
          checked={option.value === answer}
          onChange={setResponse}
        />
        <label htmlFor={key}>
          <div className="label-contents">
            <img src={option.image} alt={option.display} />
            <p>{option.display}</p>
          </div>
        </label>
      </div>
    );
  });

  return <div className="radio-group radio-group--images">{buttons}</div>;
};

ImageRadioButtons.propTypes = {
  responseField: PropTypes.object.isRequired,
  answer: PropTypes.string,
  setResponse: PropTypes.func.isRequired
};

ImageRadioButtons.defaultProps = {
  answer: ''
};

export default ImageRadioButtons;
