import SalesforceCommunicator from '../../communicators/SalesforceCommunicator';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
export default class RateHeader extends Component {
  static propTypes = {
    provider: PropTypes.object.isRequired,
    property: PropTypes.object.isRequired,
    locale: PropTypes.string.isRequired,
    salesforceApi: PropTypes.string.isRequired
  };

  constructor(props, context) {
    super(props, context);
    this.navigateToCalculator = this.navigateToCalculator.bind(this);
  }

  async navigateToCalculator() {
    const propertyId = this.props.property.external_id;
    const editAnswersUrl =
      '/' + this.props.locale + '/rate-calculator/' + propertyId;

    var clearRateCalculatorUpdate = {
      rate_calculator_complete: false,
      basement: null,
      basement_height: null,
      building_feature: null,
      building_type: null,
      content_location: null,
      elevated: null,
      machinery_in_basement: null,
      more_than_one_flood: null,
      building_size: null,
      has_steps: null,
      steps_up: null,
      number_of_steps: null,
      preferred_risk_rate: null,
      number_of_claims: null,
      flood_claim: null,
      claim_amount: null,
      pre_firm: null,
      severe_repetitive_loss: null,
      flood_damage: null,
      house_value: null,
      renovation_value: null,
      substantial_improvement: null
    };
    var success = () => {
      window.location.href = editAnswersUrl;
    };
    try {
      const result = await SalesforceCommunicator.updateProperty(
        this.props.salesforceApi,
        propertyId,
        clearRateCalculatorUpdate
      );
      // TODO add success handling to this try/catch
    } catch (error) {
      // TODO add error handling to this try/catch
    }
  }

  render() {
    const property = this.props.property;
    const provider = this.props.provider;
    const toProfileUrl =
      '/' + this.props.locale + '/profile/' + property.external_id;
    return (
      <div className="rate-header">
        <a className="back" href={toProfileUrl}>
          {provider.backToProfileLink}
        </a>

        <div className="heading">
          <h2>{provider.header}</h2>
          <a className="edit" href="#" onClick={this.navigateToCalculator}>
            {provider.editAnswersLink}
          </a>
        </div>
        <h5>{property.formatted_address}</h5>

        <p>{provider.copy}</p>
      </div>
    );
  }
}
