import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { colors } from '../styles/variables';
import { useProvider } from '../helpers/hooks';

const Container = styled.div`
  text-align: center;
  margin: 10rem 0;
`;

const HeaderCode = styled.h1`
  font-size: 20rem;
  margin: 0;
`;

const HeaderText = styled.h2`
  color: ${colors.resilientBlue};
  margin: 0;
  padding: 0;
  font-size: 3rem;
`;

const NotFound = () => {
  const provider = useProvider('NotFound');

  return (
    <>
      <Helmet>
        <title>{provider.meta.title}</title>
        <link rel="canonical" href={provider.meta.url} />
        <meta name="description" content={provider.meta.description} />
        <meta property="og:title" content={provider.meta.title} />
        <meta property="og:description" content={provider.meta.description} />
        <meta property="og:url" content={provider.meta.url} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Container>
        <HeaderText>{provider.header}</HeaderText>
        <HeaderCode>404</HeaderCode>
      </Container>
    </>
  );
};

export default NotFound;
