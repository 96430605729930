import fillInBasement from '../images/fill-in-basement.svg';
import elevate from '../images/elevate.svg';
import elevateMechanicals from '../images/elevate-mechanicals.svg';
import abandonFirstFloor from '../images/abandon-first-floor.svg';
import floodVents from '../images/flood-vents.svg';

export default {
  title: 'Mitigation',
  overview: 'Overview',
  affectRate: 'How it would affect your flood insurance rate',
  howItWorks: 'How it works',
  effort: 'Effort',
  backwaterValve: 'Backwater valves: What you need to know',
  elevate: {
    meta: {
      title: 'Elevating Your Home',
      url: 'https://www.floodhelpny.org/en/mitigation/elevate',
      description:
        'Elevating your home involves lifting your structure above the height of the projected flooding and placing it on a new or extended foundation.'
    },
    title: 'Elevating Your Home',
    subtitle:
      'Elevating your home involves lifting your structure above the height of the projected flooding and placing it on a new or extended foundation.',
    overview:
      'Usually this is accomplished by lifting your home on temporary supports above its current foundation, then building onto or creating a new foundation that is higher, then lowering the home onto the new foundation. Elevation is expensive and requires that you temporarily relocate, but it will dramatically reduce both your risk of flood damage and your flood insurance premium.',
    image: elevate,
    imageAlt: 'Elevate',
    affectRate:
      'Elevation is the most effective way to dramatically reduce your flood insurance rate and keep it low into the future. If your home is in a special flood hazard area, the primary factor in determining your flood insurance rate is the difference between your home’s lowest floor elevation and the Base Flood Elevation. If you elevate your home above the BFE, you reduce your risk, which minimizes your premium. If you want to qualify for the low rate after an elevation, the space beneath your home can only be used for storage, parking and access.',
    howItWorks:
      'If your home is elevated above the BFE, your living space and utilities will likely be above water in the event of a flood, keeping your property and your valuables safe from flood damage. If you choose to elevate, you must raise your home at least two feet above the BFE in order to account for uncertainties in the flood maps and anticipated sea level rise. This extra two feet of elevation is known as “freeboard” and is part of the NYC building code.  The new height of the home, including freeboard, would be at Design Flood Elevation.',
    effort:
      'A home elevation can take up to three months, depending on the contractor and the structure. During that time, you will need to relocate and remove your valuables from your home. Your home will be disconnected from utilities like water, sewer and electricity during construction.'
  },
  elevateMechanicals: {
    meta: {
      title: "Raising Your Home's Mechanical Equipment",
      url: 'https://www.floodhelpny.org/en/mitigation/elevate-mechanicals',
      description:
        "Your home's mechanical equipment — such as your furnace, electrical utilities and plumbing — is essential."
    },
    title: "Raising Your Home's Mechanical Equipment",
    subtitle:
      "Your home's mechanical equipment — such as your furnace, electrical utilities and plumbing — is essential.",
    overview:
      'When flood water reaches the equipment, it can stop functioning correctly. Replacing or repairing it can cost hundreds or thousands of dollars. That’s why one of the most effective options for making your home less vulnerable to flooding is to raise the mechanical equipment. This can be done by elevating the equipment above where it is currently located or by relocating it from the basement or crawl space to the first floor or higher. In some cases, homeowners may want to build a small addition to their house above the flood elevation as a utilities room. Unfortunately, raising mechanicals will save you little or no money on your flood insurance premium. It is also a relatively expensive option. ',
    image: elevateMechanicals,
    imageAlt: 'Elevate Mechanicals',
    affectRate:
      'Raising the mechanical equipment in your home could save you between $100 and $200 per year in your premium.',
    howItWorks:
      'When you relocate your mechanical equipment above the flood elevation or out of your basement, you significantly reduce the risk of critical systems being damaged during a flood. Not only will you save on expensive repair and replacement costs, but you will also be able to return to your home much faster after a flood if your heat and electrical systems are intact. That alone can save you hundreds or even thousands of dollars in temporary relocation costs.',
    effort:
      'Moving your home’s mechanical equipment can take anywhere from three days to a few weeks, depending on your home and your contractor and which systems you choose to relocate. During that time, you will have limited or no access to your heat, hot water, and electrical utilities.'
  },
  fillInBasement: {
    meta: {
      title: 'Filling in a Basement or Crawlspace',
      url: 'https://www.floodhelpny.org/en/mitigation/fill-in-basement',
      description:
        'Filling in your basement or crawl space with sand, gravel or another material up to ground level can reduce your risk of flood damage and your flood insurance premium.'
    },
    title: 'Filling in a Basement or Crawlspace',
    subtitle:
      'Filling in your basement or crawl space with sand, gravel or another material up to ground level can reduce your risk of flood damage and your flood insurance premium.',
    overview:
      'You will also need to install flood vents in the remaining aboveground portion of the basement or crawlspace. While filling in your basement may be costly up front, it can end up saving you thousands of dollars on your flood insurance premiums in the long run. In some cases, filling in your basement could damage the structural integrity of your building, so it is essential that you consult an engineer before taking action.',
    image: fillInBasement,
    imageAlt: 'Fill in Basement',
    affectRate:
      'If your home is in a special flood hazard area, the primary factor in determining your flood insurance rate is the difference between the elevation of the first floor of your home (this is the basement floor) and the Base Flood Elevation. By filling in your basement and installing flood vents, you decrease that difference, because your next floor above ground becomes your home’s lowest floor for insurance purposes. This will lower your flood insurance premium, but the size of the premium decrease will vary, based on the elevation of the ground compared to the BFE.',
    howItWorks:
      'By eliminating your basement and installing flood vents, you will raise your home’s lowest floor elevation without actually lifting the house — your first floor above ground will become your home’s “lowest floor elevation.” When you fill in your basement, you also ensure that all of your contents are located above ground level, making them less likely to be damaged in the event of a flood. Installing flood vents protects your home’s foundation and walls from being damaged by water pressure. However, in order to fill your basement you may have to give up part of the livable area of your home, so you should consider whether these benefits outweigh the loss of this space. ',
    effort:
      'Filling the basement or crawl space and installing flood vents can be expected to take one to two weeks on average, after all permits are obtained and construction begins.'
  },
  floodVents: {
    meta: {
      title: 'Installing Flood Vents',
      url: 'https://www.floodhelpny.org/en/mitigation/flood-vents',
      description:
        'Installing flood vents involves adding openings beneath the lowest floor of your home to allow water to enter and exit in the event of a flood without causing structural damage.'
    },
    title: 'Installing Flood Vents',
    subtitle:
      'Installing flood vents involves adding openings beneath the lowest floor of your home to allow water to enter and exit in the event of a flood without causing structural damage.',
    overview:
      'This is one of the least expensive mitigation options available, but it is only an option in homes where the mechanical utilities and controls are above, or able to be relocated above, a specific height called the Design Flood Elevation. If you want to install flood vents or openings, any area below the Base Flood Elevation must be used only for parking, storage, or building access, and the area below the BFE must have at least one side that is at or above ground surface.',
    image: floodVents,
    imageAlt: 'Flood Vents',
    affectRate:
      'Installing flood vents lower your flood insurance premium if at least one side of the floor of your basement or crawl space is at or above ground level, or if it is done in tandem with filling in your basement or crawl space to ground level. In high-risk flood zones, the primary factor in determining your insurance rate is the difference between the elevation of the lowest floor in your home and the BFE. If you fill in your basement and install flood vents, the next highest floor becomes your “lowest-floor,” which will lower your insurance premium.',
    howItWorks:
      'By adding openings to the side of your building, you reduce the risk of damage from water pressure (hydrostatic and hydrodynamic forces). In the event of a flood, water can flow into and out of your home freely through the vents, with no need for human intervention, which will reduce the risk of structural damage to your walls or foundation.',
    effort:
      'If your utilities are already above the Design Flood Elevation and you do not have a basement or crawlspace to fill in, installing flood vents is relatively easy, and shouldn’t take more than a few days. If you do need to fill in a basement or crawlspace and/or raise mechanical equipment, wet floodproofing will be more complicated, time consuming and costly.'
  },
  abandonFirstFloor: {
    meta: {
      title: 'Abandoning Your First Floor',
      url: 'https://www.floodhelpny.org/en/mitigation/abandon-first-floor',
      description:
        'Abandoning the first floor of your property involves making your second floor or higher your living quarters, and using your first floor just for storage, parking or access.'
    },
    title: 'Abandoning Your First Floor',
    subtitle:
      'Abandoning the first floor of your property involves making your second floor or higher your living quarters, and using your first floor just for storage, parking or access.',
    overview:
      'If you have a basement or sub-ground crawlspace, you’ll need to fill that in, too. You’ll need to move all the utilities above the Design Flood Elevation or place them outside in an elevated structure. You will also need to make the first floor “unfinished” (with no drywall) and add flood vents or openings below the Design Flood Elevation for your property. This can be an expensive strategy, but it can significantly reduce your insurance premium and risk. It also may mean giving up a significant portion of your home’s living area, which is an important trade off to consider when deciding whether or not to adopt this mitigation option.',
    image: abandonFirstFloor,
    imageAlt: 'Abandon First Floor',
    affectRate:
      'If you abandon the first floor of your property, you effectively raise the elevation of your home’s lowest living-space, or “lowest floor” for rating purposes, which minimizes your premium.',
    howItWorks:
      'Abandoning the first floor of your home can raise it to or above the BFE. This increases the likelihood it will be protected from flood waters, keeping your property and your valuables safe from damage.',
    effort:
      'Abandoning your first floor will require temporary relocation as well as additional construction to move your mechanical equipment and appliances, and to build an alternate entrance to your home after your first floor is filled in.'
  }
};
