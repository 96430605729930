import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Section } from './ui/StyledResourcePageElements';
import CallToAction from './layout/CallToAction';
import structural from '../images/structural.svg';
import RoundIcon from './RoundIcon';

const Text = styled.div`
  > * {
    margin-top: 2rem;

    :first-child {
      margin-top: 0;
    }
  }
`;

const AuditStatusScheduleCounseling = ({ provider }) => (
  <>
    <Section>
      <h5>{provider.headerNext}</h5>
      <CallToAction>
        <RoundIcon src={structural} alt="structural" />
        <Text>
          <h3>{provider.callToAction}</h3>
          <p>
            {provider.body}
            <a href={`tel:${provider.phoneLink}`}>{provider.phone}</a>
          </p>
        </Text>
      </CallToAction>
    </Section>
    <Section>
      <h5>{provider.headerExpect}</h5>
      <p>
        <span>{provider.expect}</span>
      </p>
    </Section>
  </>
);

AuditStatusScheduleCounseling.propTypes = {
  provider: PropTypes.object.isRequired
};

export default AuditStatusScheduleCounseling;
