import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import RateCalculatorRadioInput from './RateCalculatorRadioInput';
import BasementNavigator from '../helpers/calculatorNavigation/BasementNavigator';

const RateCalculatorQuestionBasement = ({
  provider,
  setAnswers,
  answers,
  setNextQuestion
}) => {
  useEffect(() => {
    if (answers.basement) {
      setNextQuestion(BasementNavigator.nextComponent(answers));
    }
  }, [answers, setNextQuestion]);

  const updateHasBasement = (e) => {
    const value = e.target.value === 'true';
    const basementHeight = value ? null : 0;
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      basement: value,
      basementHeight
    }));
  };

  const updateBasementHeight = (e) => {
    const { value } = e.target;
    let basementHeight = null;
    if (!isNaN(value) && value !== '') {
      basementHeight = parseInt(value);
    }
    setAnswers((prevAnswers) => ({ ...prevAnswers, basementHeight }));
  };

  const renderHasBasementQuestion = () => {
    if (answers.buildingFeature) {
      return null;
    }
    return (
      <div className="has-basement-section">
        <h4 className="has-basement">{provider.hasBasementQuestion}</h4>
        <RateCalculatorRadioInput
          radioKey="has-basement"
          responseField={provider.responseField}
          answer={answers.basement}
          setResponse={updateHasBasement}
        />
      </div>
    );
  };

  const renderBasementHeightQuestion = () => {
    if (!answers.buildingFeature || !answers.basement) {
      return null;
    }
    return (
      <div className="basement-height-section">
        <h4 className="basement-height">{provider.basementHeightQuestion}</h4>
        <input
          id="basement-height-input"
          type="number"
          value={answers.basementHeight}
          onChange={updateBasementHeight}
        />
        {provider.basementHeightUnit}
      </div>
    );
  };

  return (
    <div className="question">
      {renderHasBasementQuestion()}
      {renderBasementHeightQuestion()}
    </div>
  );
};

RateCalculatorQuestionBasement.propTypes = {
  provider: PropTypes.object.isRequired,
  answers: PropTypes.object.isRequired,
  setAnswers: PropTypes.func.isRequired,
  setNextQuestion: PropTypes.func.isRequired
};

export default RateCalculatorQuestionBasement;
