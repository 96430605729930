export default {
  title: 'Sabías que...',
  flood: {
    number: '$25,000',
    copy:
      'Unos pocos centímetros de agua de inundación pueden causar decenas de miles de dólares en daños por inundaciones.',
    imgAlt: 'Flooded building'
  },
  risk: {
    number: '2 millas',
    copy:
      'Incluso si vive a dos millas de la costa, podría correr el riesgo de inundarse.',
    imgAlt: 'Map with marker'
  },
  mortgage: {
    number: '1 en 4',
    copy:
      'Existe una posibilidad entre uno y cuatro de que su vivienda se inundará durante su hipoteca a 30 años.',
    imgAlt: 'Chance of storm icon'
  }
};
