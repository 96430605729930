import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '../styles/variables';
import closeButton from '../images/close-button.svg';
import { useProvider } from '../helpers/hooks';

const Wrapper = styled.button`
  position: absolute;
  color: ${colors.grayScale[5]};
  right: 1rem;
  top: 1rem;
  border: none;
  background: none;

  :focus {
    outline: none;
  }
`;

const CloseButton = ({ onClick }) => {
  const provider = useProvider('help');

  return (
    <Wrapper onClick={onClick}>
      <img src={closeButton} alt={provider.closeButtonAlt} />
    </Wrapper>
  );
};

CloseButton.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default CloseButton;
