export default {
    meta: {
      title: 'FloodHelpNY Widget',
      url: 'https://www.floodhelpny.org/zh-CN/widget-information',
      description:
        '立即下载我们的FloodHelpNY小组件，帮助访客了解他们的洪水风险'
    },
    header: 'FloodHelpNY Widget',
    subHeader:
      '立即下载我们的FloodHelpNY小组件，帮助访客了解他们的洪水风险',
    overview: {
      title: '概述',
      subtitle: `立即下载我们的FloodHelpNY小组件，帮助访客了解他们的洪水风险。FloodHelpNY易于安装、安全的小组件将为当前和未来的房主、租户和小企业主提供一种无缝的方式，了解更多关于洪水风险的信息。FloodHelpNY的小组件为访客提供了以下机会：`
    },
    widget: {
      title: 'THE WIDGET',
      aside: {
        description:
          '小组件易于安装，确保安全。填写下表，接收嵌入代码和说明。'
      }
    },
    maps:
      '<b>查看FEMA当前和纽约市房产的洪水地图</b>',
    reflection: '<b>了解房产的洪水风险</b>',
    required:
      '<b>了解他们是否需要获得洪水保险</b>，是否有联邦支持的抵押贷款',
    decisions:
      '<b>决定是否购买洪水保险</b>',
    request: {
      title: '请求小组件',
      description:
        '下载我们的小组件时，要求用户填写表格并提交他们的姓名、组织名称和电子邮件地址。也要求用户同意我们的条款。'
    },
    form: {
      name: '姓名',
      namePlaceholder: '全名',
      org: '组织',
      orgPlaceholder: '组织',
      email: '电子邮件',
      emailPlaceholder: '例如name@example.com',
      terms: '我同意',
      termsLink: ' 条款',
      thankYou: '感谢您的申请，我们将很快与您联系。',
      errors: {
        nameError: '全名是必填项',
        orgError: '需要组织',
        emailError: '需要电子邮件地址',
        termsError: '需要接受条款',
        submitError:
          '提交表单时出错。请重试。'
      }
    }
  };
  
