import React from 'react';

const ModalContext = React.createContext({
  isModalActive: false,
  setIsModalActive: () => {},
  modalChildren: null,
  setModalChildren: () => {}
});

export default ModalContext;
