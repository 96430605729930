export default [
    {
        question: "测量了洪水高度后，我认为我的证明有误。",
        answer: "工程师和土地测量员使用专门的工具测量每一层楼与海平面相关的高度。卷尺无法准确测量房产的标高。",
        zone: "elevation-certificates"
    },
    {
        question: "我有资格参加住宅回水阀安装项目吗？",
        answer: "如果您居住在 Lower Manhattan、Rockaway East、Southeast Brooklyn Waterfront、Gravesend 和 Bensonhurst、Howard Beach、Gerritsen Beach 和 Sheepshead Bay，以及风暴恢复州长办公室（GOSR）规定的Southern Brooklyn Peninsula 的社区边界内，您目前有资格参与项目。填写完申请后，我们将审查您的资格，并通知您我们的决定。即便如此，如果水管工或工程师在进入您的房屋后发现无法安装阀门，那么在房主解决现有问题之前，您的资格可能会被搁置。",
        zone: "bwv-flood-applicants"
    },
    {
        question: "我需要获得标高证明吗？",
        answer: "如果您的房产目前处于特殊洪水危险区（SFHA），标高证明是了解您的保险评级是否正确的最佳方式。此外，如果在新地图中看到您的房屋处于更高的风险中，标高证明可以确保您正确绘制了地图。",
        zone: "understanding-flood-insurance"
    },
    {
        question: "对于 Canarsie 或 Red Hook 已经参加该项目的申请人来说，所有项目阶段（审计、咨询等）是否都需要更长的时间？",
        answer: "如果 Canarsie 和 Red Hook 的申请人没有参加家庭弹性审核计划，目前没有资格参加。之前参加该项目的人申请回水阀项目的速度和其他参与者速度相同。",
        zone: "bwv-flood-applicants"
    },
    {
        question: "泄洪口对保护您的房屋免受水损害很重要吗？",
        answer: "泄洪口可防止水压力（称为静水压力）对房屋造成结构性破坏。如果水可以流入夹层并流出，压力会降低，从而保护您的房屋。根据房屋的海拔与基准洪水海拔的对比，在您的夹层设置泄洪口可能会大大降低您的洪水保险率。",
        zone: "rate-calculator-building-feature"
    },
    {
        question: "当前X区的房主是否有资格咨询X区的项目？",
        answer: "是的，他们有资格。",
        zone: "bwv-flood-applicants"
    },
    {
        question: "联邦应急管理局的洪水区与纽约市的疏散区是否不同？",
        answer: "是的。您可以了解更多有关城市疏散区的信息<a target=“_blank”href=“http://www1.nyc.gov/assets/em/html/know-your-zone/knowyourzone.html“>点击此处</a>。",
        zone: "understanding-flood-insurance"
    },
    {
        question: "该计划是否有任何经济利益？",
        answer: "标高证明必须由土地测量师完成，对于不复杂的情况，费用可能在500美元至800美元之间，但如果情况更加复杂，费用可能会增加到数千美元。此外，咨询服务将与您合作，帮助确定您可以采取的措施，降低未来的洪水保险费率。",
        zone: "rtapp-multifamily-landing rtapp-landing"
    },
    {
        question: "回水阀的类型是否有所不同？",
        answer: "是的，其中包括闸阀、球阀和挡板阀。专业的水管工或工程师可以告诉您哪种类型最适合您的房屋。",
        zone: "mitigation-backwater_valve"
    },
    {
        question: "下水道回水阀的类型是否有所不同？",
        answer: "是的，其中包括闸阀、球阀和挡板阀。回水阀有多种名称，包括止回阀和鸭嘴阀。专业的水管工或工程师可以告诉您哪种类型最适合您的房屋。",
        zone: "bwv-flood-applicants"
    },
    {
        question: "我能在家中再添一层来弥补损失的空间吗？",
        answer: "如果在经济上没有问题，在不危及房屋结构完整性的情况下，您可以再添加一层。任何附加设施也必须符合分区和建筑规范。",
        zone: "mitigation-abandon_first_floor"
    },
    {
        question: "我可以再次申请该计划吗？",
        answer: "如果您不符合资格要求，但希望在新的洪水弹性计划可用时收到我们的回复，或者如果我们的资格要求发生变化，请致电（646）786-0888或发送电子邮件至info@floodhelpny.org.如果您的情况发生了变化，并且您认为可以满足项目资格要求，请随时再次申请！",
        zone: "rtapp-status-Ineligible"
    },
    {
        question: "我可以选择我的房屋审计师吗？",
        answer: "抱歉，由于需要为各种各样的房主提供服务和日程安排，您无法选择审计员。",
        zone: "rtapp-status"
    },
    {
        question: "我能自己回填地下室吗？",
        answer: "您需要一名工程师来确保回填地下室不会损坏您的房屋结构。您还需要获得纽约市建筑部的许可证。您也许可以做一些工作，但我们建议您雇佣一位有资质的设计师提供帮助。设计师也可能需要获得许可证。您还可能需要重新配置公用设施连接和机械设备。",
        zone: "mitigation-fill_in_basement"
    },
    {
        question: "我可以免费获得标高证明吗？",
        answer: "不能。然而，之前的房主可能已经拥有证明；如果您正在购买房产或最近购置的房产，您可能需要联系他们。",
        zone: "elevation-certificates"
    },
    {
        question: "您能解释一下关于该项目的联邦资金吗？",
        answer: "该项目由美国住房和城市发展部（HUD）社区发展专项拨款——灾后恢复（CDBG-DR）资助。纽约州州长风暴恢复办公室（GOSR）内的纽约崛起社区重建（NYRCR）计划提供资金。\\n",
        zone: "rtapp-status-ApplicationSubmitted"
    },
    {
        question: "我的房产是否必须在飓风桑迪期间受损才有资格申请？",
        answer: "不，房产不必受飓风影响，也可以获得资格。",
        zone: "bwv-flood-applicants"
    },
    {
        question: "安装时我必须在场吗？",
        answer: "是的，在工程师参观、开工会议和安装期间，房主必须在场。",
        zone: "bwv-flood-applicants"
    },
    {
        question: "我必须出门去和施工团队会面吗？",
        answer: "施工团队将在您的家中参加启动会议，需要一到两个小时。",
        zone: "rtapp-status-MeetYourTeam"
    },
    {
        question: "工程师评估期间我需要在家吗？",
        answer: "是的，您需要在家。",
        zone: "rtapp-status"
    },
    {
        question: "如果我不是房主，我需要购买洪水保险吗？",
        answer: "是的，洪水造成的内容物损坏不太可能包含在承租人的保险范围内；将单独购买洪水保险。",
        zone: "understanding-flood-insurance"
    },
    {
        question: "如果回填了地下室，我需要安装泄洪口吗？",
        answer: "是的。泄洪口确保水不会积聚在墙壁的一侧并产生可能损坏基础的压力，来保护您的房屋。为了在回填地下室后获得保险减免，也需要安装泄洪口。泄洪口应由专业工程师安装。",
        zone: "mitigation-fill_in_basement"
    },
    {
        question: "我的房屋被加高时，需要支付洪水保险吗？",
        answer: "是的，您的房屋加高时，仍然很容易受到洪水的破坏。",
        zone: "mitigation-elevate"
    },
    {
        question: "在机械提升过程中是否需要重新定位？",
        answer: "这将取决于搬迁的设备、一年中的时间和其他因素。很有可能至少在部分施工期间，您不得不临时搬迁。",
        zone: "mitigation-elevate_mechanicals"
    },
    {
        question: "我的房屋被加高时，我需要重新安置吗？",
        answer: "是的，水、下水道和电力等公用设施将被切断。施工期间，需要拆除楼梯和其他入口。",
        zone: "mitigation-elevate"
    },
    {
        question: "还有其他可能让我的房屋收益的项目吗？",
        answer: "有！如果想了解您可能有资格享受的其他计划和福利，请致电（646）786-0888与我们联系。",
        zone: "rtapp-status-Ineligible rtapp-status-Withdrawn"
    },
    {
        question: "您是否与州政府共享此计划信息？",
        answer: "是的，我们还与联邦政府分享这些信息。该项目由美国住房和城市发展部（HUD）社区发展街区拨款——灾后恢复（CDBG-DR）资助，并通过州政府机构——州长风暴恢复办公室（GOSR）实施。",
        zone: "bwv-flood-applicants"
    },
    {
        question: "洗衣机、烘干机或冰箱是否算作“建筑机械”？",
        answer: "不，这些属于房屋内部物品。要获得这些项目的保险，您必须购买物品保险。",
        zone: "rate-calculator-machinery-in-basement"
    },
    {
        question: "标高证明过期了吗？",
        answer: "没有。您可以在标高证明的右上角看到过期日期。这代表有一个新表。旧表的标高证明仍然有效。",
        zone: "rtapp-preapp-elevation-certificate"
    },
    {
        question: "建筑工程是否算作付款？",
        answer: "是的，由联邦政府资助重建援助，该援助需要报告。",
        zone: "understanding-flood-insurance"
    },
    {
        question: "房主的保险包括洪水吗？",
        answer: "房主保险不包括洪水。",
        zone: "rtapp-preapp-homeowner-insurance"
    },
    {
        question: "我的收入是否影响我参加该计划的资格？",
        answer: "家庭弹性审计计划目前只能为中低收入家庭服务。据美国住房和城市发展部（HUD）称，家庭收入中位数低于地区收入中位数（AMI）50%的个人被视为低收入者。家庭收入中位数至少为50%，且达到或低于AMI 80%的个人被视为中等收入。如果家庭收入超过这一标准，您没有资格参加此计划。",
        zone: "bwv-flood-applicants"
    },
    {
        question: "这个计划对我有好处吗？",
        answer: "有！FloodHelpNY 服务旨在帮助家庭减少遭受洪水的风险，并在洪水或下水道管道溢流的情况下减少房屋受损的可能性，包括租赁单元受损。",
        zone: "bwv-flood-applicants"
    },
    {
        question: "如何选择建筑业主参与该计划？",
        answer: "根据建筑租户的收入，他们是否居住在100年一遇的洪泛区，以及建筑是否被飓风桑迪破坏确定优先权。",
        zone: "rtapp-multifamily-landing"
    },
    {
        question: "如何选择房主参与该计划？",
        answer: "符合所有资格要求的房主能够参加该计划。如果您想参与计划，请在线填写我们的简要申请表。",
        zone: "rtapp-landing"
    },
    {
        question: "如何才能找到承包商来回填我的地下室？",
        answer: "纽约市建筑部<a target=“_blank”href=“http://www1.nyc.gov/site/buildings/homeowner/homeowner.page“>可以为您提供有关如何找到有洪水区工作经验的设计师和承包商的建议</a>。承包商应具有经验、执照和保险。检查参考文件。",
        zone: "mitigation-fill_in_basement"
    },
    {
        question: "我如何发现是否能升高房屋？",
        answer: "您需要聘请一位合格的工程师，他可以评估您的房屋是否适合选择的高度。",
        zone: "mitigation-elevate"
    },
    {
        question: "我怎么知道是否能回填地下室？",
        answer: "咨询专业工程师、持证承包商或纽约市建筑部。",
        zone: "mitigation-fill_in_basement"
    },
    {
        question: "我怎样才能获得标高证明？",
        answer: "标高证明必须由土地测量师或工程师填写，对于不复杂的情况，其费用可能在500美元至800美元之间，但对于更复杂的情况，费用可能更高。",
        zone: "understanding-flood-insurance"
    },
    {
        question: "我如何获得这个保险？",
        answer: "房主可以与房主保险提供商讨论如何获得污水倒流保险附加条款。污水倒流保险每年的费用通常不到100美元。之前的理赔、信贷和街道级别的提升有时会影响费率。",
        zone: "rtapp-preapp-sewage"
    },
    {
        question: "我如何了解洪水保险的更多信息？",
        answer: "查看<a href=“https://www.floodhelpny.org/zh-CN/understanding-flood-insurance“>综合资源页！</a>",
        zone: "rtapp-preapp-flood-insurance"
    },
    {
        question: "我如何降低洪水保险费率？",
        answer: "目前为止，只有四项措施对保险费有影响：房屋标高、地下室回填或夹层、安装泄洪孔、放弃一楼以及将机械设备抬出地下室。最后一个选项提供了最小的保险减免，但在发生洪水时可以为您节省大量资金。",
        zone: "understanding-flood-insurance"
    },
    {
        question: "我如何知道自己的房产是否有下水道回水阀？",
        answer: "要了解是否有下水道回水阀或是否需要阀门，最好的方法是咨询专业的有资质水管工。如果是很久以前安装的，很难单独识别下水道回水阀。",
        zone: "bwv-flood-applicants"
    },
    {
        question: "我如何知道自己的房产是否有下水道回水阀？",
        answer: "要确定是否有下水道回水阀或是否需要阀门，最好的方法是咨询专业的有资质水管工。如果是很久以前安装的，很难单独识别下水道回水阀。",
        zone: "rtapp-preapp-backwater-exist"
    },
    {
        question: "我如何选择适合的洪水弹性选项？",
        answer: "您应该考虑您的房屋有哪些选择（例如，您不能轻易升高附属的房子），缓解措施的前期成本，以及随着时间的推移可能节省的保险费。",
        zone: "programs-and-benefits resiliency"
    },
    {
        question: "我如何确定该计划的家庭规模？",
        answer: "可以通过计算您的同住人数来确定家庭规模，包括您自己。",
        zone: "rtapp-preapp-household-size"
    },
    {
        question: "如何查找我家的基准洪水高程（BFE）？",
        answer: "从了解您的区域开始。如果处于AE区域，标高证明是准确了解 BFE 的最佳方式。如果位于X区域，您所处的洪水地图区域当前没有投影的基准洪水标高。",
        zone: "understanding-flood-insurance mitigation-flood_vents mitigation-abandon_first_floor mitigation-elevate_mechanicals"
    },
    {
        question: "如何找到我家的设计洪水标高（DFE）？",
        answer: "DFE确定洪水危险区施工的最安全标高。在纽约市，DFE通常是基准洪水标高加上两英尺，称为“出水高”，增加安全性。您的设计师或建筑师应在设计和许可期间咨询纽约市建筑部，确保他们遵守DFE。",
        zone: "mitigation-flood_vents mitigation-abandon_first_floor mitigation-elevate_mechanicals"
    },
    {
        question: "我如何知道将机械设备应该提高多少？",
        answer: "您或您的承包商应始终咨询纽约市建筑部。理想情况下，设备应提升至设计洪水标高，即基准洪水标高加上两英尺出水高。",
        zone: "mitigation-elevate_mechanicals"
    },
    {
        question: "填表时如何获得帮助？",
        answer: "如有任何问题，或需要帮助填写申请表，请随时致电我们的房主中心（646）786-0888。",
        zone: "rtapp-status-FullApplication"
    },
    {
        question: "我如何知道将房屋要提升多少？",
        answer: "通常，纽约市要求 BFE 加上两英尺出水高。但最好咨询一位有洪水区施工经验的合格工程师。纽约市建筑部<a target=“_blank”href=“http://www1.nyc.gov/site/buildings/homeowner/homeowner.page“>可以帮助您找到一名工程师</a>。",
        zone: "mitigation-elevate"
    },
    {
        question: "我如何知道是否有“地下室”还是“夹层”？",
        answer: "地下室是指四面墙均低于地面的区域。如果高度小于5英尺，则通常被视为夹层空间。",
        zone: "rate-calculator-building-feature"
    },
    {
        question: "我如何知道是否需要回水阀？",
        answer: "请务必咨询专业水管工，确定您是否会从回水阀中受益，因为在某些房屋上安装回水阀实际上会使情况变得更糟。",
        zone: "mitigation-backwater_valve"
    },
    {
        question: "我如果知道过去是否接受过联邦救灾的援助？",
        answer: "如果因洪水造成的损坏而从FEMA、SBA、Build It Back或纽约崛起获得了房屋维修或重建的帮助，代表您已经获得了联邦救灾的援助。对大多数人而言，可能是飓风艾琳或飓风桑迪造成的破坏的援助。",
        zone: "understanding-flood-insurance"
    },
    {
        question: "我怎么知道是否存在下水道倒流？",
        answer: "您可能已经注意到从您的马桶、水槽、浴缸、地漏或其他卫浴设备中涌上来水和/或难闻的气味。",
        zone: "bwv-flood-applicants"
    },
    {
        question: "我如何知道阀门的安装位置？",
        answer: "专业的水管工会评估您下水道的状况和布局。他们会确定最合适安装阀门的位置。始终咨询专业的水管工确定位置，因为回水阀安装位置错误实际上会使情况变得更糟。",
        zone: "mitigation-backwater_valve"
    },
    {
        question: "我如何知道要安装的回水阀类型？",
        answer: "专业水管工或工程师可以告诉您哪种回水阀（如有）最适合您的房屋。",
        zone: "mitigation-backwater_valve"
    },
    {
        question: "如何维护下水道回水阀？",
        answer: "请水管工向您提供阀门制造商的运维说明。这通常很简单——每年打开几次阀门维修门，确保没有任何东西堵塞阀门。如果有东西堵塞，将其移除。如果封盖需要润滑油，请涂抹。一些水管工提供维护程序，并将为您提供这些服务。如果通过我们的项目收到下水道回水阀，安装阀门的水管工将向您展示阀门的位置、工作原理和维护方法。此外，安装完成后，您会收到一份现场报告，包含阀门制造商提供的具体运维说明。",
        zone: "bwv-flood-applicants mitigation-backwater_valve rtapp-status-InstallationComplete"
    },
    {
        question: "我如何有资格获得洪水保险单中的“合规建造”祖父条款？",
        answer: "只有您的房屋建于1983年11月之后，且目前处于高风险洪水区时，您才能获得资格。您还必须证明您的房屋是按照当时的洪水图建造的，没有修改。例如，如果您将一楼的车库改建成生活空间，除非您改回去，否则就不符合资格。",
        zone: "understanding-flood-insurance"
    },
    {
        question: "我如何有资格享受洪水保险单中的“连续承保”？",
        answer: "确保在地图更改之前制定保单基本上意味着您没有连续承保范围。",
        zone: "understanding-flood-insurance"
    },
    {
        question: "我如何提交所需的申请文件？",
        answer: "要提交文件，您可以：<ul><li>扫描并通过FloodHelpNY.org 状态页面上传。</li><li><li>传真至纽约市邻里中心：1-646-849-1765</li></li>将副本邮寄至纽约市社区中心收件人</li><li>FloodHelpNY，55 Broad Street，10th Floor，New York，NY 10004。</li></ul>",
        zone: "bwv-flood-applicants"
    },
    {
        question: "如何向中心提交住房补助协议（HGA）？",
        answer: "如果只有一个人需要签署您的房屋补助协议（即，房产契约上只列出一个人），那么您可以通过电子方式签署文件。在确定资格后，我们将向您发送一个链接执行操作。如果不止一个人需要签署房主补助协议，您可以从状态页面下载并打印该文件。完成后，您可以将其传真至（646）506-4621，并将副本发送至info@floodhelpny.org或通过邮政服务邮寄至纽约市社区中心FloodHelpNY，地址：55 Broad Street，10th Floor，New York，NY 10004。",
        zone: "rtapp-status-GrantAgreement"
    },
    {
        question: "步骤如何影响我的洪水保险费率？",
        answer: "如果没有地下室或地下夹层，您的费率由一楼的标高决定。基本上，该楼层高于基准洪水标高越高，费率就越低。",
        zone: "rate-calculator-number-of-steps"
    },
    {
        question: "这些房屋特征如何影响我的洪水保险费率？",
        answer: "基本上，最低楼层越低，洪水保险费率就越高。",
        zone: "rate-calculator-building-feature"
    },
    {
        question: "您如何获得优先风险洪水保险？",
        answer: "您必须位于X区，在过去10年中，仅从联邦灾难援助计划中收到一笔超过1000美元的款项，用于单独的洪水援助。联邦灾难援助是指NFIP洪水保险或FEMA或SBA或重建。",
        zone: "understanding-flood-insurance"
    },
    {
        question: "地下室会对我的洪水保险费率产生什么影响？",
        answer: "如果您生活在高风险洪水区，您的评级是根据最低楼层与BFE之间的高度。如果有地下室或夹层空间，即使该空间未完工，也会根据该楼层的标高对您进行评级。",
        zone: "rate-calculator-basement"
    },
    {
        question: "地下室机械会对我的洪水保险费率产生什么影响？",
        answer: "在地下室里有机器可能会提升费率，但不会太高。但是，将机器从地下室搬出来，会对风暴过后您能恢复家中热量和电力的速度产生重大影响。",
        zone: "rate-calculator-machinery-in-basement"
    },
    {
        question: "建筑类型会对我的洪水保险费率产生什么影响？",
        answer: "根据您的建筑类型，可选择有不同的弹性选项。",
        zone: "rate-calculator-building-type"
    },
    {
        question: "物品对我的洪水保险费率会产生什么影响？",
        answer: "地下室内的物品投保比较高楼层的物品报废更高，地下室的物品投保单位仅限于洗衣机、烘干机和冰箱。就是这样。为地下室的这些物品投保可能不值得支付更高的保费。",
        zone: "rate-calculator-content-location"
    },
    {
        question: "标高对我的洪水保险费率会产生什么影响？",
        answer: "如果生活在高风险洪水区，您的评级是根据最低楼层与BFE之间的高度。当您的房屋标高比BFE高2英尺时，会大大减少洪水进入房子的机会，从而降低保险费用。",
        zone: "rate-calculator-elevated-building"
    },
    {
        question: "之前的洪水损失对我的洪水保险费率会产生什么影响？",
        answer: "如果您因洪水保险、联邦应急管理局（FEMA）或小企业管理局两次或多次针对不同的洪水收到超过1000美元的洪水损失理赔，您没有资格享受优先风险率保低单。记住，付款必须是针对不同的洪水，而不是针对桑迪造成的破坏进行两次付款。即使从联邦应急管理局和飓风桑迪洪水保险中获得了5万或10万美元的理赔，也属于一项理赔。",
        zone: "rate-calculator-preferred-risk-rate"
    },
    {
        question: "之前的洪水损失对我的洪水保险费率会产生什么影响？",
        answer: "如果您因洪水保险、联邦应急管理局（FEMA）或小企业管理局两次或多次针对不同的洪水收到超过1000美元的洪水损失理赔，您没有资格享受优先风险率保低单。记住，付款必须是针对不同的洪水，而不是针对桑迪造成的破坏进行两次付款。即使从联邦应急管理局和飓风桑迪洪水保险中获得了5万或10万美元的理赔，也属于一项理赔。",
        zone: "rate-calculator-severe-repetitive-loss"
    },
    {
        question: "气候变化对纽约市产生了什么影响？",
        answer: "气候变化为纽约市的弹性增加了一个全新的因素。您可以阅读关于气候变化对城市的影响的更多信息<a target=“_blank”href=“http://onlinelibrary.wiley.com/doi/10.1111/nyas.12591/full“>点击此处</a>。",
        zone: "resiliency rtapp-multifamily-landing"
    },
    {
        question: "为什么为现有申请人而不是新申请人提供项目资金？",
        answer: "这只是针对 Canarsie 和 Red Hook。在加装回水阀之前， Canarsie 是初始家庭弹性审计项目中参与度最高的社区之一。 Canarsie 审计的资金已经用完，但我们已经收到了其他资金，用于安装回水阀。由于必须通过审计才能安装回水阀，所以 Canarsie 在资金耗尽之前还没有进行审计和咨询的人将没有资格获得审计或回水阀。未来资金分配可能会发生变化，如果发生这种情况，我们一定会联系您。",
        zone: "bwv-flood-applicants"
    },
    {
        question: "放弃一楼需要多长时间？",
        answer: "根据建筑物的建造、在较低层废弃的区域以及需要对地下区域进行多少回填，所需的时间有所不同。估计是从几周到90天。但是，设计和获得许可证还需要额外的时间。",
        zone: "mitigation-abandon_first_floor"
    },
    {
        question: "升高房屋需要多长时间？",
        answer: "这项工作本身可能需要三个月以上的时间。然而，创建设计并获得许可之后就可以为升高房屋制定时间表了。",
        zone: "mitigation-elevate"
    },
    {
        question: "回填地下室需要多长时间？",
        answer: "回填地下室或夹层空间可能需要几天的时间。但如果您需要在地下室或夹层空间重新安置机械，平均需要一到两周的时间。再加上获得许可所需的时间，可能需要更长的时间。",
        zone: "mitigation-fill_in_basement"
    },
    {
        question: "提升我的机械需要多长时间？",
        answer: "在获得许可证并开始施工后，可能需要三到五天到几周的时间。",
        zone: "mitigation-elevate_mechanicals"
    },
    {
        question: "申请审批流程通常需要多长时间？",
        answer: "收到申请和后续文件后，我们会在五个工作日内做出批准决定。",
        zone: "rtapp-status-ApplicationSubmitted"
    },
    {
        question: "湿式防洪需要多长时间？",
        answer: "几天内泄洪孔即可安装完毕。",
        zone: "mitigation-flood_vents"
    },
    {
        question: "安装回水阀需要多长时间？",
        answer: "安装所需的时间取决于您房屋的布局及其与城市下水道的连接，以及将您家与城市下水道连接的管道状况。希望只需要一次参观，但水管工可能还需要一天时间完成清理或艰难的安装。您确实需要在家中。",
        zone: "rtapp-status-InstallationToBeScheduled"
    },
    {
        question: "申请过程需要多长时间？",
        answer: "咨询时间应该在45分钟到1.5 小时之间。",
        zone: "rtapp-status"
    },
    {
        question: "家庭洪水评估需要多长时间？",
        answer: "评估最多需要两个小时的时间。",
        zone: "rtapp-status"
    },
    {
        question: "这个计划需要多长时间？",
        answer: "这很难估计，但从开始到结束，这一过程可能需要长达六个月的时间，具体取决于您的时间表、安装的复杂程度以及合同流程。谢谢您的耐心！",
        zone: "bwv-flood-applicants"
    },
    {
        question: "我需要请假多少天才能参加该计划？",
        answer: "如果您已经收到FloodHelpNY 家庭弹性审计，我们需要您在家做三件事：工程师的可行性审查、与水管工的启动会议以及安装。如果您尚未收到FloodHelpNY 家庭弹性审核，我们需要您在家参加审核、启动会议和安装。我们预计审计最多需要两个小时的时间。对于接受审计的房主来说，启动会议和安装的时间安排保持不变。",
        zone: "bwv-flood-applicants"
    },
    {
        question: "我需要请假多少天才能参加该计划？",
        answer: "您需要在家参加启动会议和安装。房主在启动会议上会见水管工和检查员，最多需要一个小时的时间。根据房屋状况，一般安装需要一到两天。",
        zone: "rtapp-status-InstallationPrepare"
    },
    {
        question: "有多少人会来评估我的房屋？",
        answer: "一个由两到四名认证工程师和测量员组成的团队参观您的房屋，评估洪水脆弱性，并就如何保护您的房屋免受未来洪水的侵袭提出建议。",
        zone: "rtapp-status-AuditScheduled"
    },
    {
        question: "放弃一楼需要多少钱？",
        answer: "这取决于一楼的当前使用情况，以及是否需要重新安置机械设备、公用设施、厨房和/或浴室。费用可能在1万至10万美元以上。",
        zone: "mitigation-abandon_first_floor"
    },
    {
        question: "回填地下室要多少钱？",
        answer: "根据地下室的深度，以及是否需要重新安置公用设施和设备以及安装泄洪口，这可能需要8500美元至30000美元。",
        zone: "mitigation-fill_in_basement"
    },
    {
        question: "安装回水阀需要多少钱？",
        answer: "这取决于许多因素，包括下水道管道的布局、将您的房屋连接到下水道的管道的深度和位置、安装阀门的区域是否存在环境危害（即含铅油漆）、安装的阀门类型等。成本最低可能为600美元，也可能超过5000美元。",
        zone: "mitigation-backwater_valve"
    },
    {
        question: "如果得到灾难援助（FEMA、SBA、Build It Back），我需要缴纳多少洪水保险？",
        answer: "如果您收到了联邦灾难援助，修复或重建您的建筑或损坏的物品，您必须至少为收到的援助金额投保。如果您收到损坏物品的联邦援助，需要对物品进行投保。这一要求始终适用于您和任何未来的买家。您必须告诉买家这一要求。",
        zone: "understanding-flood-insurance"
    },
    {
        question: "如果有抵押贷款，我需要投保多少洪水保险？",
        answer: "如果处于高风险洪水区，并且有抵押贷款，只要您有抵押，就必须至少为抵押贷款的未付余额（本金余额）提供建筑保险。不需要物品投保。",
        zone: "understanding-flood-insurance"
    },
    {
        question: "洪水保费是多少？需要何时购买？\\n",
        answer: "如果申请人居住在AE区，必须购买洪水保险，然后才能被纳入计划范围。建筑洪水承保范围必须等于回水阀安装的成本，平均约为1500美元。",
        zone: "bwv-flood-applicants"
    },
    {
        question: "需要缴纳多少房主保险，需要何时购买？",
        answer: "您必须先购买房主保险，然后才能被纳入计划范围。我们没有规定您必须购买多少房主保险，您只要有保险即可。",
        zone: "rtapp-preapp-homeowner-insurance"
    },
    {
        question: "需要缴纳多少房主保险，需要何时购买？",
        answer: "申请人必须购买房主保险，才能被纳入计划范围。我们没有规定房主必须购买多少保险。只要有保险即可。",
        zone: "bwv-flood-applicants"
    },
    {
        question: "升高机械需要要多少费用？",
        answer: "根据您的房屋、承包商和搬迁的公共设施，费用可能在5000美元至40000美元之间。",
        zone: "mitigation-elevate_mechanicals"
    },
    {
        question: "住宅回水阀安装项目的费用是多少？",
        answer: "住宅回水阀安装项目完全免费。",
        zone: "bwv-flood-applicants"
    },
    {
        question: "湿式防洪费用是多少？",
        answer: "安装泄洪口的费用可能在4000美元至8000美元之间。",
        zone: "mitigation-flood_vents"
    },
    {
        question: "我应该如何准备安装回水阀？",
        answer: "除了确保水管工可以方便地进入安装阀门的地下室区域外，还请在家中做好准备，并制定停水计划。",
        zone: "rtapp-status-InstallationPrepare"
    },
    {
        question: "如果放弃了一楼，我该如何进入房屋？",
        answer: "您需要建造新的入口楼梯。在某些情况下，您可能需要安装机械升降机。",
        zone: "mitigation-abandon_first_floor"
    },
    {
        question: "如何保护我的信息？",
        answer: "您的信息的机密性和安全性对我们而言至关重要。我们使用物理、数字和管理安全措施来保护您的个人信息安全，并进行保密。未经您的许可或法律要求，我们不会向未参与管理FloodHelpNY的第三方提供任何信息。我们还遵守州和联邦政府制定的严格隐私要求。",
        zone: "bwv-flood-applicants"
    },
    {
        question: "我已经有了标高证明，还需要提交其他文件吗？",
        answer: "如果您有标高证明，无需提交新证明。但是，如果您对物业进行了任何更改，例如进行优化尽量减少洪水的影响，您可能需要获得更新的证书，确保您收到正确的价格。",
        zone: "elevation-certificates"
    },
    {
        question: "我已经完成了 FloodHelpNY 家庭弹性审计计划。如何获得下水道回水阀？",
        answer: "您将追溯考虑参加住宅回水阀安装计划。项目启动时，中心将通过电子邮件向您发送回水阀申请。申请表必须填写完毕并交回中心进行进一步资格审查。您的资格将取决于我们对申请的审查结果以及工程师和水管工在您家中的调查结果。符合条件也不意味着您会得到一个阀门，因为此时只有中低收入家庭才会得到服务。",
        zone: "bwv-flood-applicants"
    },
    {
        question: "我相信我所在的社区有资格参加家庭弹性审计，但我无法注册。",
        answer: "联系我们，邮件：info@floodhelpny.org.请将您的姓名、地址发送给我们，并告诉我们您尝试申请时发生的情况。我们会对此进行研究并回复您。您也可以拨打帮助热线：646-786-0888。",
        zone: "rtapp-landing"
    },
    {
        question: "我在申请几天后收到了一封电子邮件，但我找不到了，我现在该怎么办？",
        answer: "您很可能处于申请阶段。您可以登录 floodhelpny.org，状态页面将告诉您目前所处的阶段以及您需要开展的行动。",
        zone: "bwv-flood-applicants"
    },
    {
        question: "我还有标高证明或洪水保险问题。您能提供帮助吗？",
        answer: "Floodsmart.gov 是查找更多洪水保险和标高证明信息的绝佳资源。您也可以随时将问题发送到info@floodhelp.org.",
        zone: "elevation-certificates"
    },
    {
        question: "如果来自 Canarsie 或 Red Hook 的申请人获得了标高证明并参加了咨询，是否会考虑让他们参加回水阀项目？",
        answer: "是的，Canarsie 和 Red Hook 的居民只有接受过审计并参加过咨询的人会被考虑参加回水阀项目。",
        zone: "bwv-flood-applicants"
    },
    {
        question: "如何获取标高证明？",
        answer: "标高证明必须由州或地方法律授权的持证土地测量师、注册专业工程师或注册建筑师编制和认证，确认标高信息。询问他们的许可证、在纽约市或您所在社区的经验、任何证书以及类似项目的推荐人。FEMA 建议通过检查州土地测量师专业协会或与本地建筑许可办公室交谈来寻找专业测量师。",
        zone: "elevation-certificates"
    },
    {
        question: "如果我不知道楼里有多少个住宅单元，我如何了解相关信息？",
        answer: "市建筑局可能会将注明住宅单元数量的建筑占用证明存档。",
        zone: "rtapp-preapp-number-of-units"
    },
    {
        question: "如果我不知道这处房产是否遭受了洪水的破坏，我如何了解相关信息？",
        answer: "询问您的洪水保险经纪人或联邦应急管理局，您的建筑物是否遭受过洪水的破坏。",
        zone: "rate-calculator-severe-repetitive-loss rate-calculator-preferred-risk-rate"
    },
    {
        question: "如果我不知道我的房屋建筑时间，我如何了解相关信息？",
        answer: "如果您曾经做过评估，应该给出建造日期。如果您有一所新房子，您可以查看房产证上的日期，可以从建筑部获得。",
        zone: "rate-calculator-pre-firm"
    },
    {
        question: "如果我现在有优先风险洪水保险单，并且我所在的地区变为了AE或VE，我是否可以加入优先风险保险费率？",
        answer: "不，有一个新的补贴，但在您的保单达到全部风险金额之前，每年的费率可能会增加15-18%。",
        zone: "understanding-flood-insurance"
    },
    {
        question: "如果我现在处于低风险或中等风险洪水区，但在新的地图下会是高风险区，我还能获得优先风险保单吗？",
        answer: "稍后会提供最新信息。",
        zone: "understanding-flood-insurance"
    },
    {
        question: "项目是否需要洪水保险？",
        answer: "联邦法律要求，如果您的房屋处于高风险洪水区（AE、AO或VE），且您有联邦支持的抵押贷款，您必须购买洪水保险。（几乎所有的抵押贷款都由联邦政府提供支持）。此外，如果您已收到联邦灾难援助，修复建筑物或丢失的物品，联邦法律要求您购买洪水保险。如果您不投保，就没有资格获得援助。",
        zone: "rtapp-preapp-flood-insurance"
    },
    {
        question: "我家里的空间如果很矮，还算是“地下室”吗？",
        answer: "如果从地面到天花板的距离小于5英尺，并且低于地面不超过2英尺，那么这是一个“夹层空间”如果空间很矮，但任何一侧低于地面2英尺以上，仍然是一个“地下室”",
        zone: "rate-calculator-basement"
    },
    {
        question: "我的房主保险单中是否包含污水倒流保险？",
        answer: "污水倒流通常不包含在标准房主保险单中。污水倒流保险是一种“背书”或“附加条款”，通常可以添加到标准房主保险中。",
        zone: "rtapp-preapp-sewage"
    },
    {
        question: "是否有办法判断下水道回水阀的挡板是关闭的还是打开的？",
        answer: "如果阀门关闭了，您会知道，因为水会停止从下水道流下来。如果您怀疑阀门可能关闭，也可以在安装过程中使用管道工提供的阀门接入点进行检查。有些阀门上连接有报警装置，阀门关闭时，报警装置会启动，但费用可能很高。",
        zone: "mitigation-backwater_valve"
    },
    {
        question: "是否有没有办法判断下水道回水阀的挡板是关闭的还是打开的？\\n个",
        answer: "如果阀门关闭了，您会知道，因为水会停止从下水道流下来。如果您怀疑阀门可能关闭，也可以在安装过程中使用管道工提供的阀门接入点进行检查。有些阀门上连接有报警装置，阀门关闭时，报警装置会启动，但费用可能很高。",
        zone: "bwv-flood-applicants"
    },
    {
        question: "我能做一些工作来加快安装过程吗？",
        answer: "很抱歉，没有。组建施工团队需要大量的内部文书和通信，必须完成这些文书和通信才能确保符合联邦和州的指导方针。我们将在团队准备就绪后立即与您联系！",
        zone: "rtapp-status-InstallationPrepare"
    },
    {
        question: "在申请表上，问题不适用时，申请人应该在答案处填写“N/A”，还是可以留空？",
        answer: "最好写N/A，这样我们就可以做出明智的决定，决定是否需要给人打电话跟进现场的情况。",
        zone: "bwv-flood-applicants"
    },
    {
        question: "回水阀安装会安排在周末/晚上的时间吗？",
        answer: "我们希望至少有一些周末和晚上，但会受到限制。",
        zone: "bwv-flood-applicants"
    },
    {
        question: "完整的申请需要哪些附加信息？",
        answer: "我们需要所有要求的文件的清晰副本，审核您的房主保险、驾驶执照、当前年总收入信息、洪水保险证明（如果适用）和租户收入信息（如果适用）。如果提交的文件难以辨认或在申请中缺失，我们将要求提供更多信息。",
        zone: "rtapp-status-ApplicationSubmitted"
    },
    {
        question: "什么是“泄洪口”？",
        answer: "这些是专门设计的、让水自由流动的开口。关于泄洪口的数量和位置有相关的规定。如果开口不符合这些要求，将不会被视为洪水保险等级的泄洪口。<a target=\"_blank\" href=\"/zh-CN/resiliency\">点击此处</a>.阅读更多信息。",
        zone: "rate-calculator-building-feature"
    },
    {
        question: "从技术上来说，可能不符合该计划的常见原因是什么？",
        answer: "如果现有条件无法安装回水阀（即，没有足够的空间或下水道侧面的条件太差，如果家中不需要回水阀（根据房屋标高、下水道连接、附近下水道、基础洪水标高等，没有迹象表明家中存在污水回流风险）",
        zone: "bwv-flood-applicants"
    },
    {
        question: "谈到洪水保险，哪些物品被认为是“财产”？",
        answer: "家具、衣服、盘子、非内嵌式电器、电视——所有您的物品都不是房屋的一部分。请记住，在地下室里，您可以投保的物品只有洗衣机、烘干机和冰箱。您的地下室里的其他物品不在投保范围内。",
        zone: "rate-calculator-content-location"
    },
    {
        question: "什么是“泄洪口”？",
        answer: "这些是专门设计的、让水自由流动的开口。关于泄洪口的数量和位置有相关的规定。如果开口不符合这些要求，将不会被视为洪水保险等级的泄洪口。<a target=\"_blank\" href=\"/zh-CN/resiliency\">点击此处</a>.阅读更多信息。",
        zone: "mitigation-fill_in_basement"
    },
    {
        question: "回水阀有哪些替代方案？",
        answer: "如果专业的有资质水管工认为可以，您可以在低于基准洪水标高的所有管道装置上安装回水阀，而不是在连接您家和下水道的下水道管道上安装回水阀门。您也可以在地下室做好防水，只用于停车或储存。污水可能还会回流，但您的物品会得到保护。纽约市环境保护部（NYCDEP）编制了<a href=“http://www.nyc.gov/html/dep/pdf/brochures/flood-preparedness-flyer.pdf“target=”_blank“></a>洪水防范传单，帮助房主减少下雨时家中发生洪水的可能性。",
        zone: "mitigation-backwater_valve"
    },
    {
        question: "下水道回水阀有哪些替代方案？",
        answer: "您可以对地下室进行防洪，升高机械设备，使地下室仅用于停车或储存。污水可能仍会回流，但您的物品将得到保护。纽约市环境保护部（NYCDEP）制定了一份洪水防范传单，帮助房主减少下雨时家中发生洪水的可能性。\\n\\nhttp://www.nyc.gov/html/dep/pdf/brochures/flood-preparedness-flyer.pdf\\n",
        zone: "bwv-flood-applicants"
    },
    {
        question: "在咨询之前，我可能会有什么常见问题？",
        answer: "请带上您所有的洪水保险和风暴弹性问题。目前，纽约市的洪水保险评级图正在审查中；联邦政府发布的未来的洪水地图可能会使洪水危险区的房屋数量增加一倍。考虑向您的顾问咨询您的财产未来潜在的洪水风险，以及这会对您的保险费率产生的影响。他们还能够讨论缓解方案，提高房屋的恢复力。",
        zone: "rtapp-status-AuditComplete rtapp-status-AuditComplete rtapp-status-CounselingScheduled rtapp-status-CounselingScheduled"
    },
    {
        question: "在洪水发生时，还可以使用哪些其他类型的机械设备来降低损坏风险？",
        answer: "熔炉、热泵、配电盘或电梯。",
        zone: "rate-calculator-machinery-in-basement"
    },
    {
        question: "回水阀安装后，中心的作用和责任是什么？",
        answer: "阀门安装后，房主负责维护阀门。中心将提供一份现场报告，包含施工前和施工后的照片以及阀门制造商关于运维的信息。合同可能包含某种程度的工程保证。如果阀门在保修期内出现问题，房主可以致电中心讨论问题，并可能让安装阀门的水管工回到家中解决问题。",
        zone: "bwv-flood-applicants"
    },
    {
        question: "房主补助协议（HGA）的主要条款是什么？",
        answer: "请参考本文件了解HGA的主要条款和条件。房主负责全面审查文件，充分了解所有条件。",
        zone: "rtapp-status-GrantAgreement"
    },
    {
        question: "填写项目预申请后，下一步要做什么？",
        answer: "在审查您提交的资格预审信息后，我们将决定是否邀请您申请该计划。在这种情况下，您会收到一封电子邮件，需要填写在线申请。我们还要求您提交证明文件，确认收入和任何现有的保险。申请和证明文件将用于确认您的资格。",
        zone: "rtapp-status-IntakeComplete"
    },
    {
        question: "安装回水阀有哪些风险？",
        answer: "回水阀发生故障的风险很小。回水阀可减少污水通过下水道管道回流到家中的机会，但如果阀门维护不当，污水可能会回流不畅并泄漏。",
        zone: "mitigation-backwater_valve"
    },
    {
        question: "我可以做什么来确认洪水保险费率是准确的？",
        answer: "标高证明有助于确保您获得准确的洪水保险评级。获得标高证明后，请与您的保险代理人共同更新您的保单。如果对使用标高证明后的保单评级有疑问，请致电877-336-2627联系FEMA测绘和保险eXchange（FMIX）中心。他们能够讨论并审查您的保单。",
        zone: "elevation-certificates"
    },
    {
        question: "我可以用什么材料回填地下室来减轻洪水风险？",
        answer: "您可以使用干净、压实的沙子或石头。",
        zone: "mitigation-fill_in_basement"
    },
    {
        question: "哪些因素会导致回水阀安装不安全？",
        answer: "导致安装可能被确定为不安全的因素很多。系统/空间的状况可能很差，对管道工或管道系统来说，继续安装的风险太大。可以在任何阶段作出决策。水管工可能需要在地板上进行初步切割，确定安装是否“安全”。预计这种情况很少见。",
        zone: "bwv-flood-applicants"
    },
    {
        question: "该项目的洪水保险证明是什么？",
        answer: "洪水保险申报页面可作为保险证明附于此处。",
        zone: "rtapp-status-FullApplication"
    },
    {
        question: "该计划的房主保险证明是什么？",
        answer: "您的房主保险声明页面可能会附在此处作为保险证明。",
        zone: "rtapp-status-FullApplication"
    },
    {
        question: "如果有租户，我应该如何申请该计划？",
        answer: "有租户的房主需要填写租户收入核实表；每个单元填一个表格。房主负责向中心提交表格和支持文件。如果中心收到信息说明是租赁房屋，将通过电子邮件发送租户验证表。如有疑问，请发送电子邮件info@floodhelpny.org或致电中心646-786-0888。",
        zone: "rtapp-status-FullApplication"
    },
    {
        question: "填写租户收入核实表后我应该如何操作？",
        answer: "请在收到后14天内邮寄至：<br/><br/>纽约社区中心，地址：。FloodHelpNY<br/>55 Broad Street<br/>10楼<br/>纽约，NY 10004<br/><br/>您还可以扫描表单和支持文档并发送至info@floodhelpny.org.",
        zone: "rtapp-status-FullApplication"
    },
    {
        question: "填写完这份申请表后，我应该如何操作？",
        answer: "请在收到后14天内邮寄至中心：\\n\\nCenter for NYC NeighborhoodsFloodHelpNY\\n55 Broad Street\\n10th Floor\\nNew York, NY 10004\\n\\n您也可以扫描表单和支持文档并发送到info@floodhelpny.org.",
        zone: "bwv-flood-applicants"
    },
    {
        question: "FEMA洪水区是什么意思？",
        answer: "FEMA洪水区是以洪水风险为特征的地理区域。例如，高风险洪水区每年有1%的机会被洪水淹没。",
        zone: "understanding-flood-insurance profile"
    },
    {
        question: "我应该带哪些文件参加咨询会议？",
        answer: "为了帮助您的顾问审查洪水保险和可用的缓解方案，我们要求您携带洪水保险申报页面（如适用）、旧的标高证明（如适用）以及显示您房屋当前抵押状态的抵押声明或其他文件。",
        zone: "rtapp-status-CounselingScheduled"
    },
    {
        question: "BFE是什么意思？",
        answer: "BFE代表“基准洪水标高”只有高风险洪水区的财产具有BFE，反映了“100年”风暴中洪水预计上升的海平面高度（单位：英尺），这种风暴每年有1%的可能性发生。根据您房屋的BFE标高之上或之下的距离，保费会有100美元的浮动。最低楼层在BFE以上的房屋保费较低。",
        zone: "understanding-flood-insurance profile"
    },
    {
        question: "家庭弹性审计团队成员的工作包括什么？",
        answer: "水管工安装回水阀，将施工区域恢复到施工前的状态，并向您展示如何维护回水阀。检验员将进行最终检查，确保所有工作都正确完成，并与您和水管工一起完成收尾检查表。",
        zone: "rtapp-status-MeetYourTeam"
    },
    {
        question: "洪水保险承保的范围是什么？",
        answer: "有两种覆盖：建筑和物品。您可以选择单独的保险金额和免赔额<a href=“/zh-CN/了解洪水保险”>在我们的资源页了解更多信息</a>",
        zone: "rtapp-preapp-flood-insurance"
    },
    {
        question: "我的技术报告包括什么内容？",
        answer: "您的技术报告包括施工前和施工后的照片；显示阀门相对于家中或财产中永久性物品的位置的草图；制造商提供的阀门产品数据表；阀门制造商的运维说明；安装许可证副本（如适用）。",
        zone: "rtapp-status-Complete"
    },
    {
        question: "项目资格预审意味着什么？",
        answer: "代表您满足了该项目的最低要求，例如您的家位于符合条件的社区之一。然而，要进一步确定资格，您需要提交完整的申请。",
        zone: "rtapp-status-IntakeComplete"
    },
    {
        question: "项目的可行性审查阶段涉及什么？",
        answer: "您的可行性评估将与第一次家庭评估类似，但略短一些！工程师和随行人员需要对您的房屋进行一些拍照和测量，确定是否能够从回水阀中受益。",
        zone: "rtapp-status-FeasibilityReview"
    },
    {
        question: "下水道回水阀到底是什么装置？",
        answer: "纽约市的大多数家庭都有一个管道，用来收集水槽和厕所的污水，并将其输送到城市下水道。下水道回水阀是安装在这条管道上的一种装置，用来降低污水通过这条管道回流到您家中的风险。回水阀有一个内部“挡板”，可以关闭，帮助保护您的房屋免受未经处理的污水淹没。溢流退去时，压力的变化会释放挡板，让废水从您的房屋中流入下水道。要了解更多信息，请访问我们的资源页面。",
        zone: "rtapp-status-Complete"
    },
    {
        question: "咨询会上会发生什么？",
        answer: "通过查看工程师提供的标高证明和技术报告中的数据，顾问为您的房屋洪水风险进行具体讲解。咨询结束时，您会更好地了解您家是否适合安装回水阀、具体的洪水风险、保险费用以及减少未来风暴损失的选项。",
        zone: "rtapp-status-CounselingScheduled rtapp-status-AuditComplete"
    },
    {
        question: "如果我需要购买洪水保险，但没有买，会出现什么情况？",
        answer: "如果您有联邦支持的抵押贷款或SBA贷款，您的抵押贷款服务商或SBA将购买洪水保险，并将费用添加到您的每月付款中。如果您得到了联邦灾难援助，但没有购买保险，您就无法得到联邦灾难援助来进行房屋维修或重建，也不会得到未来风暴中受损物品的补偿。（您仍有资格获得其他帮助，如临时住房援助）。",
        zone: "rtapp-preapp-flood-insurance understanding-flood-insurance"
    },
    {
        question: "如果我意外错过了审计预约，会发生什么？",
        answer: "只要在预约前至少三天提交申请，您就可以重新安排。只能重新安排一次预约。请在工作时间致电646-786-0888重新预约。",
        zone: "rtapp-status"
    },
    {
        question: "如果我意外错过了咨询预约，会出现什么情况？",
        answer: "只要在预约前至少三天提交申请，您就可以重新安排。只能重新安排一次预约。请在工作时间致电646-786-0888重新预约。",
        zone: "rtapp-status"
    },
    {
        question: "如果此处计算的洪水保险费率与当前的费率不同，会发生什么？",
        answer: "如果计算出的预估费率低于您当前的费率，您可能为洪水保险支付了过多的费用。请联系您的保险经纪人查看保单。仔细检查所有信息，确保正确无误。检查房产描述是否正确，是否列为您的主要住所（如果是），以及洪水区和BFE是否正确。",
        zone: "rate-estimate"
    },
    {
        question: "如果又出现一场风暴，而我没有洪水保险，会发生什么？",
        answer: "如果要求您购买洪水保险而您没有购买，那么在未来的风暴中，您没有资格获得联邦灾难针对您的建筑物或物品受损的援助。您可能仍有资格获得其他援助，如临时住房。",
        zone: "profile"
    },
    {
        question: "如果我无法验证申请的信息，该怎么办？",
        answer: "如果您无法准确提供所要求的内容，请联系我们的支持团队。您可以在正常的工作时间致电646-786-0888。",
        zone: "rtapp-self-certify"
    },
    {
        question: "如果我无法负担洪水保险怎么办？",
        answer: "看看您是否可以通过减少保险金额或增加免赔额来让自己可以负担。查看是否有负担得起的改变降低成本，比如回填夹层空间。联系您的民选官员，看看是否有项目可以帮助您支付房屋改造的费用，从而降低成本，或者是否有志愿者团体可以这样做。",
        zone: "programs-and-benefits resiliency profile"
    },
    {
        question: "如果我无法负担洪水保险费增加怎么办？",
        answer: "也许您可以减少承保范围或获得更高的免赔额。有保险总比没有保险好。如果在高风险地区有抵押贷款，您的选择可能会受到限制。尝试探索对您的房屋进行改装，降低保费，比如回填夹层空间或地下室，以及安装泄洪口。",
        zone: "programs-and-benefits resiliency"
    },
    {
        question: "如果没有收到FloodHelpNY的电子邮件，我应该怎么办？",
        answer: "请检查垃圾邮件文件夹并将info@floodhelpny.org添加为您的联系人。如果有任何问题，也可以拨打我们的帮助热线646-786-0888。",
        zone: "rtapp-status"
    },
    {
        question: "如果由于飓风桑迪我已经得到了很多不同的款项，那会出现什么情况？我还有资格享受优先风险保单吗？",
        answer: "是的，只要单笔款项没有超过1000美元。",
        zone: "understanding-flood-insurance"
    },
    {
        question: "如果有租户住在我家一楼，但我想放弃一楼作为缓解选择，该怎么办？",
        answer: "这个空间将不再适合居住。",
        zone: "mitigation-abandon_first_floor"
    },
    {
        question: "如果我住在附属或半附属的房子里，还想对房屋进行整体提升，应该怎么办？",
        answer: "从技术上来说，提升附属房屋是不可能的，也不符合成本效益。",
        zone: "mitigation-elevate"
    },
    {
        question: "如果我错过了我房屋的评估预约怎么办？我可以重新安排吗？",
        answer: "如果需要重新安排约会，请立即联系我们，电话：（646）只有您至少提前三天通知我们，我们才会重新安排预约，并且我们只能重新安排一次预约。",
        zone: "rtapp-status-FeasibilityReview"
    },
    {
        question: "如果需要更改计划的家庭恢复力审计预约，我应该怎么办？",
        answer: "只要在预约前至少三天提交申请，您就可以重新安排。只能重新安排一次预约。请在工作时间致电646-786-0888重新预约。",
        zone: "rtapp-status"
    },
    {
        question: "如果需要更改我的预约咨询，应该怎么办？",
        answer: "只要在预约前至少三天提交申请，您就可以重新安排。只能重新安排一次预约。请在工作时间致电646-786-0888重新预约。",
        zone: "rtapp-status"
    },
    {
        question: "如果我拥有或居住在一栋多户型建筑中，但仍然想接受家庭恢复力审计，该怎么办？",
        answer: "抱歉，您没有资格接受家庭恢复力审计。但您仍然可以查看<a href=“https://www.floodhelpny.org“>洪水风险</a>",
        zone: "rtapp-preapp-number-of-units rtapp-landing profile"
    },
    {
        question: "如果对我的恢复力报告还有一些疑问怎么办？",
        answer: "您的顾问会回答关于报告的问题或帮您得到答案。",
        zone: "rtapp-status"
    },
    {
        question: "如果我是租户，想申请这个项目，应该怎么办？",
        answer: "此项目仅适用于建筑业主。您可以和房东分享此计划，并要求他们申请。",
        zone: "rtapp-preapp-homeowner"
    },
    {
        question: "如果我想买房，但又想申请该计划怎么办？",
        answer: "此项目仅针对当前业主开放。",
        zone: "rtapp-preapp-homeowner"
    },
    {
        question: "如果在预申请表上没有我的建筑类型怎么办？",
        answer: "选择您认为最接近的建筑类型。",
        zone: "rate-calculator-building-type"
    },
    {
        question: "标高证明上有哪些信息？",
        answer: "标高证明上的三个关键数据字段是建筑类型和特征、最低楼层和房产位置。这些因素在确定洪水保险费时起着重要的作用。",
        zone: "elevation-certificates"
    },
    {
        question: "洪水保险中的“祖父条款”指的是什么？",
        answer: "如果采用新的洪水地图，并且您的房屋被置于风险更高的洪水区，或者您的财产的BFE增加时，您可以根据祖父条款来节省费用。如果符合资格，祖父条款允许您锁定之前的洪水区或BFE，进行保险计费。有两种祖父条款：“持续承保”和“合规构建”只有在1983年11月之后建造的房屋才符合“合规构建”的要求",
        zone: "understanding-flood-insurance profile"
    },
    {
        question: "什么是“严重的重复性损失”？",
        answer: "如果您或之前的业主至少收到了两次洪水保险理赔，且理赔总和超过了建筑物的市场价值，您的建筑物属于“严重的重复损失”建筑物。",
        zone: "rate-calculator-severe-repetitive-loss"
    },
    {
        question: "什么是“路基夹层空间”？",
        answer: "指的是1）所有4面都低于地面的空间，2）任何地方都不低于地面2英尺的空间，3）地板到天花板不高于约4英尺的空间。如果比地面高4英尺或深2英尺，就是洪水保险中的的“地下室”。",
        zone: "rate-calculator-building-feature"
    },
    {
        question: "什么是优先风险洪水保险单？",
        answer: "优先风险是仅适用于索赔历史有限的中风险和低风险洪水区（X区或不在洪水区）的补贴政策。平均成本约为每年500美元。",
        zone: "understanding-flood-insurance profile"
    },
    {
        question: "什么是下水道回水阀？",
        answer: "纽约市的大多数家庭都有一个管道，可以收集家中水槽和厕所污水，并将其排放到城市下水道。下水道回水阀是安装在这条管道上的一种装置，用于降低污水通过管道回流到您家中的风险，尤其是在大雨期间<a href=“https://www.floodhelpny.org/zh-CN/mitigation/backwater_valve“>了解有关回水阀的更多信息</a>",
        zone: "rtapp-preapp-backwater-exist rtapp-preapp-sewage"
    },
    {
        question: "什么是下水道回水阀？",
        answer: "纽约市的大多数家庭都有一个管道，可以收集家中水槽和厕所污水，并将其排放到城市下水道。下水道回水阀是安装在该管道中的一种装置，用于降低污水通过该管道回流到您家中的风险，尤其是在大雨期间。\\n",
        zone: "bwv-flood-applicants"
    },
    {
        question: "什么是对项目的“损害”？",
        answer: "我们正在询问您的房屋是否因飓风桑迪而受到风、雨或洪水的直接破坏。一般而言，如果纽约市建筑部在您的房屋贴上用黄色或红色标牌，或者如果您收到了联邦紧急事务管理局（FEMA）的援助、理赔保险基金，或者收到了飓风桑迪的慈善帮助，我们认为您的房屋曾经遭受过损坏。",
        zone: "rtapp-preapp-sandy-damage"
    },
    {
        question: "什么被认为是对项目的“洪水造成的破坏”？",
        answer: "溢流或水体（如海洋、湖泊、河流或溪流）造成的损害。管道爆裂的泛滥不包括在内。如果下水道倒流不是洪水造成的，通常不会造成洪水破坏。",
        zone: "rate-calculator-severe-repetitive-loss rate-calculator-preferred-risk-rate"
    },
    {
        question: "该项目的年收入指的是哪些收入？",
        answer: "<ul><li>-工资、薪金、加班费、佣金、费用、小费、奖金和武装部队薪酬扣除前的金额</li><li>-企业或职业运营的净收入</li></li>-不动产或个人财产的利息、股息和其他任何类型的净收入</li><li>>-社会保障、年金、保险单、退休基金、，养老金、残疾福利金、死亡福利金、子女抚养费和赡养费</li><li>-从租户处收到的租金</li></li>-代替收入的付款（即残疾/失业补偿金、工人补偿金、遣散费）</li>//ul>",
        zone: "rtapp-status-FullApplication"
    },
    {
        question: "“物品”保险的承保范围指的是什么？",
        answer: "物品保险包括洪水造成的个人财产损失。地下室里只能对洗衣机、烘干机和冰箱进行投保。（您的炉子、锅炉、热水器和空调都在您的“建筑”范围内）。洪水来临时，将您的物品搬出地下室。在洪水来临之前，最好拍摄所有照片，这样您就可以向保险公司提供证据。",
        zone: "understanding-flood-insurance profile"
    },
    {
        question: "出水高是什么？",
        answer: "出水高是基准洪水高标高之上的附加高度，确保施工处于更安全的高程，避免未来的洪水。在纽约，这意味着在BFE之上增加两项额外费用。除了提高洪水的安全性，出水高还可以降低保险费，从而有助于收回建筑成本。",
        zone: "mitigation-flood_vents"
    },
    {
        question: "什么是下水道倒流？\\n",
        answer: "所有从下水道和厕所排出的废水（或污水）都会通过管道从您的房屋排放到城市的下水道。废水无法通过下水道排出而返回到房屋中时，会产生废水倒流。这种情况可能是由多种原因造成的。\\n",
        zone: "bwv-flood-applicants"
    },
    {
        question: "什么是下水道溢流？",
        answer: "下水道的设计能够容纳和输送一定量的废水和污水。有时，在不寻常的大雨或风暴潮期间，下水道可能会爆满。这种情况发生时，污水可以通过连接您家和城市下水道的管道回流到您家中。",
        zone: "mitigation-backwater_valve"
    },
    {
        question: "1983年的洪水保险有什么意义？",
        answer: "1983年11月16日，纽约市通过了第一份联邦应急管理局洪水保险费率地图，并在洪水区建筑规范中增加了高度要求。由于在此之前的建筑规范中没有防洪要求，因此在此之前建造的房产有资格享受较低的费率。但国会后来要求联邦应急管理局逐步取消较低的利率，称为“FIRM前补贴”利率。",
        zone: "rate-calculator-pre-firm"
    },
    {
        question: "标高证明的平均费用是多少？",
        answer: "从500美元到1000美元不等。尝试咨询至少两位测量师获得报价，进行比较。",
        zone: "elevation-certificates"
    },
    {
        question: "我自己安装回水阀的平均费用是多少？",
        answer: "这取决于许多因素，包括下水道管道的布局、将您的房屋连接到下水道管道的深度和位置、阀门安装区域是否存在环境危害（例如含铅油漆）、安装的阀门类型等。成本最低可能为600美元，也可能超过5000美元。",
        zone: "rtapp-status-Withdrawn rtapp-status-Ineligible"
    },
    {
        question: "城市正在采取哪些措施来解决整体污水问题？",
        answer: "这是一个更大的问题，我们无法真正谈论，因为我们不为这座城市工作。NYCDEP有几个正在进行的项目，试图解决下水道溢流的综合问题。如果感兴趣，可以访问DEP的网站，获取有关这些计划的更多信息。其中一项倡议是绿色基础设施计划，该计划涉及在道路两侧修建工程化的生物护坡，收集暴雨产生的第一英寸雨水径流，并将其排除在下水道之外。",
        zone: "bwv-flood-applicants"
    },
    {
        question: "在家里，地下室和夹层空间有什么区别？有什么重要性？",
        answer: "对于洪水保险的目的，地下室的四面都低于地面，且高度超过5英尺。如果空间高度小于5英尺，则为夹层空间。这很重要，因为费率可能因此而不同。",
        zone: "mitigation-fill_in_basement"
    },
    {
        question: "什么是房主洪水保险可保性法案（HFIAA）？",
        answer: "2014年《房主洪水保险可保性法案》减缓或扭转了2012年《Biggert水域法案》对国家洪水保险计划做出的一些改变。HFIAA恢复了“祖父条款”，通过将大多数房产的保费增幅限制在每年18%，减缓了其他保费补贴的逐步取消。一些房产每年仍有25%的增长，如商业物业或严重受损的物业。",
        zone: "understanding-flood-insurance"
    },
    {
        question: "什么是《房主补助协议》（HGA）？",
        answer: "《房主补助协议》允许中心作为项目管理人，将联邦灾难恢复基金的一部分授予符合条件的房主，并要求房主同意文件中详细规定的所有条款。",
        zone: "rtapp-status-GrantAgreement"
    },
    {
        question: "标高证明的目的是什么？",
        answer: "标高证明是一份重要的洪水保险文件，提供有关财产洪水风险和洪水恢复能力的信息。标高证明可用于计算洪水保险费。",
        zone: "elevation-certificates"
    },
    {
        question: "这个计划是什么？是关于什么的？",
        answer: "如果您收到这张表格，说明您的房东已经参加了FloodHelpNY 房屋恢复性审计计划，该计划旨在通过向符合条件的房主及其某些沿海社区的租户提供免费服务来加强纽约的沿海社区。要了解更多信息，请访问FloodHelpNY.org。作为计划的一部分，您的房东可能有资格获得免费的下水道回水阀。下水道回水阀是一种安装在管道上的装置，将房屋水管装置连接到城市的下水道，减少在暴雨或风暴潮等洪水事件期间污水回流到家中的风险。",
        zone: "bwv-flood-applicants"
    },
    {
        question: "我家里的哪些关键设备可能存在风险？",
        answer: "以下设备可能存在风险：配电盘和电线、暖通空调和管道系统等机械系统、冷凝装置、加热系统、热水器和洗衣设备。",
        zone: "mitigation-elevate_mechanicals"
    },
    {
        question: "我所在的地区还有哪些其他洪水弹性资源可用？",
        answer: "如果您想自行安装回水阀，可以查阅本指南获取帮助。",
        zone: "rtapp-status-Withdrawn"
    },
    {
        question: "项目的地下室有什么特点？",
        answer: "四面都低于地面（甚至低于地面几英寸）的房屋任意空间从地板到天花板的高度超过5英尺。如果地板位于地面或一侧高于地面，不属于地下室。",
        zone: "rate-calculator-basement"
    },
    {
        question: "计划的主要居所指的是什么？",
        answer: "主要居所是居民通常大部分时间居住的住宅。租户全年居住的住宅也是主要居所。",
        zone: "rtapp-preapp-primary-residence"
    },
    {
        question: "计划中的住宅单元指的是什么？",
        answer: "住宅单元是主要用于居住而非商业用途的空间。",
        zone: "rate-calculator-number-of-residential-units rtapp-preapp-number-of-units"
    },
    {
        question: "我应该提交什么文件作为申请的收入证明？",
        answer: "附上所有18岁以上家庭成员的收入证明（最好是一份已签署的IRS表格1040）。如果不可用，您可以考虑最近提供连续三个月的工资存根或最近的福利报表；我们也可以批准其他文件。",
        zone: "rtapp-status-FullApplication"
    },
    {
        question: "我可以在哪里报告污水问题？",
        answer: "拨打311",
        zone: "bwv-flood-applicants"
    },
    {
        question: "一般会停水多长时间？",
        answer: "这将取决于不同的家庭情况。我们会尽快通知房主。管道工在下水道管道上安装新阀门时，房主将无法将水排入下水道或冲洗厕所。这不一定是整个安装过程，因为安装可能包括其他施工，如修补地板。可能只需要8小时。",
        zone: "bwv-flood-applicants"
    },
    {
        question: "什么是三方协议？在会议之前是否可以查看范本？",
        answer: "在您的启动会议日期和时间确认电子邮件中包括三方协议的范本，可在此处下载。",
        zone: "rtapp-status-MeetYourTeam"
    },
    {
        question: "房屋的“围墙”指的是什么？",
        answer: "如果您有一个升高的住宅，这指的是您的居住区下面被墙（部分或全部）包围的区域。可能是一个车库或只用来存放物品的空间，而不是一个生活空间。",
        zone: "rate-calculator-content-location rate-calculator-building-feature"
    },
    {
        question: "什么是标高证明？",
        answer: "标高证明是一份重要的洪水保险文件，描述了房屋或建筑物每层的海拔高度。可以帮助您正确计算洪水保险评级，也可以帮助您评估恢复力选项。",
        zone: "rtapp-landing rtapp-preapp-elevation-certificate rtapp-multifamily-landing"
    },
    {
        question: "什么是FEMA？",
        answer: "联邦应急管理局。联邦应急管理局负责国家洪水保险计划（NFIP），并在灾难发生后提供紧急援助。",
        zone: "understanding-flood-insurance"
    },
    {
        question: "新的洪水地图何时生效？",
        answer: "目前正在审议一份新的洪水图，但对于发布或实施时间还没有明确的答案。2013年，联邦应急管理局根据1983年的基础提交了一份最新的初步地图，大大扩大了纽约市的高风险洪水区范围，但在纽约市仍在修订地图。现在，该地图是分区和新建筑要求的咨询地图。",
        zone: "bwv-flood-applicants"
    },
    {
        question: "房主什么时候能知道安装回水阀/挺熟所需的时间？",
        answer: "他们会在签署三方协议之前知道，这是水管工、中心和房主在安装阀门之前签署的协议。",
        zone: "bwv-flood-applicants"
    },
    {
        question: "我什么时候才能收到申请状态？",
        answer: "提交申请表后，您应收到一封确认电子邮件，然后将在提交申请后一周内收到有关下一步的回复。",
        zone: "bwv-flood-applicants"
    },
    {
        question: "我什么时候才能收到我项目申请信息？",
        answer: "提交申请后，可能需要三周的时间才能收到回复。您可以随时通过电子邮件中的链接检查申请状态。",
        zone: "rtapp-preapp-review"
    },
    {
        question: "我的申请被批准后会发生什么？",
        answer: "一旦获准接受服务，我们将在两个工作日内与您联系，安排在方便的时间进行家庭恢复力审计。您的状态将始终显示在此页面上，或者可以在正常工作时间致电646-786-0888，询问有关申请的问题。",
        zone: "rtapp-status"
    },
    {
        question: "我在哪里可以找到承包商来升高机械设备？",
        answer: "建筑部<a target=“_blank”href=“http://www1.nyc.gov/site/buildings/homeowner/homeowner.page“>可以为您提供有关如何找到在洪水区工作经验的设计师和承包商的建议</a>。",
        zone: "mitigation-elevate_mechanicals"
    },
    {
        question: "在哪里可以找到承包商来为我家进行湿式防水？",
        answer: "在安装泄洪口之前，应咨询合格的工程师或建筑师。纽约市建筑部可以为您提供选择设计师和承包商的建议http://www1.nyc.gov/site/buildings/homeowner/homeowner.page“>与房主合作的经验</a>。DOB还每周与房主举行信息会议，让纽约人了解建筑标准和如何评估承包商。",
        zone: "mitigation-flood_vents"
    },
    {
        question: "如果我的邻居上报了污水倒流，我在哪里可以找到？",
        answer: "311跟踪污水倒流的投诉。",
        zone: "bwv-flood-applicants"
    },
    {
        question: "我可以在哪里提供有关该计划的反馈？",
        answer: "请在咨询后查看电子邮件，获取提供反馈的链接。",
        zone: "rtapp-status"
    },
    {
        question: "在哪里举行恢复力咨询会议？",
        answer: "在您所在社区的办公室进行咨询。请查看您的电子邮件了解详细信息，或致电我们的房主热线646-786-0888。",
        zone: "rtapp-status"
    },
    {
        question: "哪些缓解措施实际上会降低我的保险费率？",
        answer: "目前为止，只有四项措施对保险费有影响：房屋标高、地下室回填或夹层、安装泄洪孔、放弃一楼以及将机械设备抬出地下室。最后一个选项提供了最小的保险减免，但在发生洪水时可以为您节省大量资金。",
        zone: "programs-and-benefits resiliency profile"
    },
    {
        question: "找水管工安装回水阀时，我应该问哪些问题？",
        answer: "询问许可证、在纽约或您所在社区的经验、证书以及从事过的类似项目。尝试询问三位水管工进行报价，然后进行比较。",
        zone: "mitigation-backwater_valve"
    },
    {
        question: "谁是家庭恢复力审计师？他们是否经过了审查？",
        answer: "审计员是合格的设计专业人员，由通过竞争性招聘程序选出的持证工程师支持。",
        zone: "rtapp-status"
    },
    {
        question: "该项目的顾问是谁？",
        answer: "顾问是非营利社区组织的法律或住房专业人员，具有为纽约沿海社区房主提供服务的经验。顾问接受了恢复力主题和洪水保险方面的培训。",
        zone: "rtapp-landing rtapp-status"
    },
    {
        question: "该项目的顾问是谁？",
        answer: "具有五年以上沿海社区工作经验的认证房屋顾问。",
        zone: "rtapp-status-CounselingScheduled rtapp-status-AuditComplete"
    },
    {
        question: "谁负责安装安装回水阀？",
        answer: "只能由在附近完成其他回水阀安装的专业管道工安装回水阀。您可以要求推荐人选！",
        zone: "mitigation-backwater_valve"
    },
    {
        question: "在项目申请中， “家庭成员”指的是哪些人？",
        answer: "包括：住在房子里的亲戚/其他人；任何暂时没有上学或工作的人；任何被短期监禁但将返回房屋的人；以及将返回住宅的现役军人。如果共享监护权的孩子至少有50%的时间与家人在一起，应将其列入名单。可能包括：申请人应确定一直不在家的家庭成员。例如，去养老院居住的老年人或一位离家居住的成年学生。不包括：寄养儿童、法定亲属监护人、寄养成年人、生活助理、生活助理的子女",
        zone: "rtapp-status-FullApplication"
    },
    {
        question: "项目申请中的“共同申请人”指的是哪些人？",
        answer: "房产契约上列出的所有个人以及居住在房产上的所有个人必须被列为共同申请人。每位共同申请人必须在本完整申请书、房主补助协议、三方协议以及申请人在接受本计划下的服务之前必须签署的任何其他协议中要求的地方签名。",
        zone: "rtapp-status-FullApplication"
    },
    {
        question: "在项目申请中，“申请人”指的是哪些人？",
        answer: "申请人必须列在契约上，年满18岁，被指定为户主（HOH），确定收入资格。HOH必须能够代表家庭签署协议。",
        zone: "rtapp-status-FullApplication"
    },
    {
        question: "该计划服务的提供方是谁？",
        answer: "非营利的纽约社区中心正与多个合作伙伴合作，提供这些服务。在此处了解有关中心及其合作伙伴的更多信息</a target=“_blank”href=“/zh-CN/about”></a>。",
        zone: "rtapp-landing"
    },
    {
        question: "哪些人为多户建筑提供该项目的服务？",
        answer: "非营利的纽约社区中心正与多个合作伙伴合作，提供这些服务。Enterprise Community Partners，Inc.将为多户家庭的房主提供支持。了解有关中心的更多信息<a target=“_blank”href=“http://cnycn.org/“>点击此处</a>和企业社区合作伙伴<a target=”_blank“href=”http://www.enterprisecommunity.com“>点击此处</a>。",
        zone: "rtapp-multifamily-landing"
    },
    {
        question: "哪些人负责维护回水阀？",
        answer: "项目结束后，您将负责维护下水道回水阀。在技术报告中提供了维护说明。",
        zone: "rtapp-status-Complete"
    },
    {
        question: "这个项目的资助方是谁？",
        answer: "该计划由纽约州州长风暴恢复办公室资助，是纽约崛起社区重建计划的一部分。",
        zone: "rtapp-multifamily-landing. rtapp-landing"
    },
    {
        question: "FloodHelpNY的经营方是谁？",
        answer: "FloodHelpNY 由纽约州州长风暴恢复办公室通过美国住房和城市发展部的联邦社区发展街区灾后恢复拨款资助。注意：此项目由 NYC社区中心运营，该中心旨在促进和保护纽约可负担的房屋所有权，以便中产阶级和工薪阶层的房主能够建立强大、繁荣的社区。在cnycn.org上了解有关该中心的更多信息。",
        zone: "bwv-flood-applicants"
    },
    {
        question: "房主补助协议的签署方是哪些人？",
        answer: "即使他们没有作为申请人或共同申请人在申请中列出，契约上列出的所有个人都必须签署《房主补助协议》。",
        zone: "rtapp-status-GrantAgreement"
    },
    {
        question: "到目前为止，我应该联系哪些人来询问恢复力报告的问题？",
        answer: "如有任何问题，欢迎致电（646）786-0888或发送电子邮件给我们info@floodhelp.org。",
        zone: "rtapp-status-Complete"
    },
    {
        question: "如果对该计划有任何疑问，我应该联系谁？",
        answer: "随时拨打中心的房主中心电话（646）786-0888。如果检查员已经召开了启动会议，将指示检查员询问安装预约。",
        zone: "rtapp-status-InstallationHasBeenScheduled. rtapp-status-InstallationToBeScheduled"
    },
    {
        question: "为什么我要购买洪水保险？是谁要求这么做呢？",
        answer: "联邦法律要求，如果您的房屋处于高风险洪水区（AE、AO或VE），且您有联邦支持的抵押贷款，您必须购买洪水保险。（几乎所有的抵押贷款都由联邦政府提供支持）。此外，如果您已收到联邦灾难援助，修复建筑物或丢失的物品，联邦法律要求您购买洪水保险。如果您不投保，就没有资格获得援助。",
        zone: "understanding-flood-insurance"
    },
    {
        question: "为什么洪水保险费在增加？",
        answer: "补贴正在逐步取消，新的联邦应急管理局洪水保险费率地图（FIRM）会增加高风险洪水区的住房数量。",
        zone: "understanding-flood-insurance"
    },
    {
        question: "为什么人们担心洪水保险费用的上涨？",
        answer: "不断上涨的洪水保险费用可能会给生活在高风险洪水区的工薪阶层和中产阶级家庭造成资金困难，这可能会使他们面临更大的丧失赎回权或流离失所的风险。要了解更多信息，请阅读纽约市邻里中心的完整“洪水上涨，成本增加”报告<a target=“_blank”href=“https://cnycn.atavist.com/risingtides“>此处</a>。",
        zone: "programs-and-benefits"
    },
    {
        question: "为什么会改变洪水区地图？",
        answer: "联邦应急管理局定期更全国洪水危险区地图。纽约市目前的地图是1983年采用的，并不能准确反映目前的洪水风险。",
        zone: "understanding-flood-insurance. profile"
    },
    {
        question: "您为什么要询问我的收入？",
        answer: "我们询问收入是为了提供优先服务。欢迎所有收入水平的家庭申请评估其房屋是否无法抵挡洪水。然而，我们将自动为中低收入家庭提供服务（高达纽约市地区收入中值的80%）。超出此范围的申请将在每三周结束时进行优先排序和审查。",
        zone: "rtapp-preapp-income"
    },
    {
        question: "为什么要询问我的房屋以前有没有损坏？",
        answer: "我们这样做只是为了能够优先提供服务。之前受损的房屋将获得优先服务。我们仍然欢迎并考虑之前没有损坏的房屋申请。",
        zone: "rtapp-preapp-sandy-damage"
    },
    {
        question: "您为什么要询问主要居所的问题？",
        answer: "为了有资格参加该计划，该房产必须是在飓风桑迪期间您的主要住所",
        zone: "rtapp-preapp-sandy-ownership"
    },
    {
        question: "为什么不同的保险代理人的洪水保险费率不同？",
        answer: "国家洪水保险计划设定了洪水保险费率，保险经纪人无法更改。不同的保险经纪人报价不同的唯一原因是，他们有人在描述您的房产或区域时出现了错误。",
        zone: "programs-and-benefits"
    },
    {
        question: "为什么我没有资格参加这个项目？",
        answer: "这可能有多种原因：您的房屋不会从下水道回水阀中受益；您没有洪水保险，并且您处于AE洪水区；您没有房主保险；飓风桑迪期间，您没有自己的房子；以及其他因素。有问题吗？请致电（646）786-0898或发送电子邮件至info@floodhelpny.org.",
        zone: "rtapp-status-Ineligible"
    },
    {
        question: "为什么我必须提供更多信息来申请该计划？",
        answer: "如果我们无法根据预申请确定您是否可以参与此项目，我们可能会要求您提供更多的信息。我们还随机询问某些类型的信息，这样就可以为每个人保持过程的公平和简单。",
        zone: "rtapp-self-certify"
    },
    {
        question: "如果是免费项目，为什么我必须提交收入信息？",
        answer: "资助指南要求我们确认家庭收入；此信息用于确定您的资格。此时，只有中低收入家庭才有资格参与项目（AMI低于80%）。",
        zone: "bwv-flood-applicants"
    },
    {
        question: "为什么需要填写一份包含收入信息的表格来申请该计划？",
        answer: "FloodHelpNY致力于为中低收入房主及其租户提供服务。我们需要获得家庭收入信息，包括2-4个单元楼租户的收入信息，确保我们只为低收入家庭服务。这是美国住房和城市发展部和纽约州州长风暴恢复办公室（GOSR）的要求，办公室为该项目提供资金。",
        zone: "bwv-flood-applicants"
    },
    {
        question: "为什么您需要我的电话号码和电子邮件地址来申请项目？",
        answer: "我们需要您的电话号码和电子邮件地址与您联系，向您告知申请的最新信息。",
        zone: "rtapp-preapp-contact-info"
    },
    {
        question: "申请该项目时，为什么您需要知道我是否安装了阀门？",
        answer: "我们正在努力确定您是否有资格在家中安装免费的下水道回水阀。",
        zone: "bwv-flood-applicants bwv-flood-applicants rtapp-preapp-backwater-exist. rtapp-preapp-backwater-exist"
    },
    {
        question: "为什么在我的家庭住址审计之前您要知道是否有下水道倒流？",
        answer: "在我们的工程师访问您的房屋进行FloodHelpNY 家庭恢复力审计之前，他们会了解您的情况。",
        zone: "bwv-flood-applicants"
    },
    {
        question: "为什么您要了解回水阀补偿？",
        answer: "如果业主已经从过去的项目中获得了房屋维修的资助，项目必须审查这些福利，确认在提供项目服务之前没有重复提供资助。",
        zone: "rtapp-status-FullApplication"
    },
    {
        question: "为什么您需要了解飓风桑迪的赔偿？",
        answer: "如果业主已经从过去的项目中获得了房屋维修的资助，项目必须审查这些福利，确认在提供项目服务之前没有重复提供资助。",
        zone: "rtapp-status-FullApplication"
    },
    {
        question: "为什么我的房屋是否与城市下水道相连对项目而言很重要？",
        answer: "该计划旨在降低污水在洪水期间回流到家庭中的风险，特别是下水道溢流造成的风险。城市的下水道已满，超出其容量时，就会出现合流下水道溢流。大多数房产都与城市下水道相连，但有些房屋可能仍有化粪池。如果没有连接到城市下水道，但您的房屋出现了污水回流，原因不是下水道溢流，您应咨询专业设计人员和/或承包商，确定原因并补救问题。",
        zone: "rtapp-preapp-sewer"
    },
    {
        question: "为什么主要居所对获得回水阀的资格至关重要？",
        answer: "这项计划旨在服务于全年为纽约人提供住房的家庭，而不是度假用的房屋。",
        zone: "rtapp-preapp-primary-residence"
    },
    {
        question: "为什么工程师需要了解我的整个房屋？",
        answer: "工程师需要在您的卫浴装置周围进行测量，拍摄阀门安装区域的照片，并观察您家周围的空间，确定安装阀门的可行性。通过提供施工区域的清晰图片，帮助水管工为安装做好准备。",
        zone: "rtapp-status-FeasibilityReview"
    },
    {
        question: "为什么回水阀计划要求提供证明文件？",
        answer: "为了符合该计划的联邦资金要求，我们确认每个申请人的收入、洪水保险和租户信息。",
        zone: "rtapp-status-IntakeComplete"
    },
    {
        question: "为什么房主保险不包括洪水？",
        answer: "国会在1968年设立NFIP的原因是，私人保险公司不会对洪水造成的损失进行承保。损失太大了。",
        zone: "resiliency"
    },
    {
        question: "为什么安装回水阀不会改变我的保险费率？",
        answer: "目前，还没有编写NFIP为允许减少回水阀所属的干防洪缓解措施的保费。例如，提升您的房子会产生折扣，因为您的最低楼层会提升到BFE之上。回水阀可以降低但不能消除洪水风险。",
        zone: "mitigation-backwater_valve"
    },
    {
        question: "为什么安装下水道回水阀不会改变我的保险费率？",
        answer: "目前，国家洪水保险计划不允许减少干旱防洪缓解策略（如回水阀）的保费。相比之下，湿式防洪解决方案，如提升房屋，会导致洪水保险折扣，因为您的最低楼层高于基准洪水标高（BFE）。下水道回水阀可以降低洪水风险，但不能完全消除风险。",
        zone: "bwv-flood-applicants"
    },
    {
        question: "为什么降低洪水风险不一定会降低我的洪水保险费率？",
        answer: "某些缓解措施可能会减少洪水后的破坏和清理成本，但实际上可能不会改变计算您家洪水风险的数据。",
        zone: "understanding-flood-insurance"
    },
    {
        question: "为什么我的房主补助协议（HGA）副本发送到风暴恢复州长办公室？",
        answer: "由于该计划资金是通过纽约州风暴恢复州长办公室（GOSR）内的纽约崛起社区重建（NYRCR）计划提供的，因此，风暴恢复州长需要一份协议副本。",
        zone: "rtapp-status-GrantAgreement"
    },
    {
        question: "为什么回水阀的申请时间这么长？",
        answer: "由于这是一个联邦资助的项目，我们需要从所有申请的房主那里收集文件。别担心，我们会安全存储您的信息。请参阅我们的隐私政策。",
        zone: "rtapp-status-FullApplication"
    },
    {
        question: "为什么中心要签署三方协议？",
        answer: "三方协议由房主、中心和水管工签署。规定了三方相互之间的义务，让水管工和房主达成直接协议。各方签署了三方协议后，您就可以在状态页面上看到该协议的已执行副本。",
        zone: "rtapp-status-MeetYourTeam"
    },
    {
        question: "为什么保险类型相关？",
        answer: "该计划需要审查过去的维修福利，确认没有重复援助。",
        zone: "rtapp-status-FullApplication"
    },
    {
        question: "为什么不明确新的洪水地图生效时间？",
        answer: "2015年6月，纽约市对联邦应急管理局的初步洪水保险费率图提出上诉，称他们高估了五个行政区的洪水风险。2016年10月，联邦应急管理局宣布已经接受了对联邦应急管理署初步洪水图的上诉，并将开始一个新的流程来修改纽约的洪水地图。FEMA发布新的修订地图至少需要三到四年的时间。了解更多<a href=“http://www1.nyc.gov/site/floodmaps/index.page“target=”_blank“>点击此处</a>。",
        zone: "understanding-flood-insurance profile"
    },
    {
        question: "为什么我应该关注恢复力？",
        answer: "灾难发生时，管理不善的员工应对措施可能会危及住房居民的安全和福祉，并使住房业主面临不必要的成本、困难和潜在的责任。适用住房组织在紧急事件期间面临着独特的挑战。住房组织无法轻易搬迁居民，只能依靠建筑的持续运营，或至少快速恢复服务。要了解更多信息，请访问<a target=“_blank”href=“https://s3.amazonaws.com/KSPProd/ERC_Upload/0100738.pdf“>此处</a>。",
        zone: "rtapp-multifamily-landing"
    },
    {
        question: "为什么选择这些社区进行回水阀项目？",
        answer: "选择参与家庭恢复力审计并将资金用于家庭恢复力审计的社区有资格参与该项目；所有社区都是纽约崛起社区重建计划的一部分。",
        zone: "rtapp-multifamily-landing rtapp-landing"
    },
    {
        question: "为什么BFE的变化会改变我的洪水保险？",
        answer: "大多数洪水保险单都是通过比较房屋的海拔高度与高风险洪水区的基准洪水海拔高度进行评定的。与BFE相比，房屋的海拔越低，洪水保险费率就越高。标高是根据海平面而不是地面测量的。",
        zone: "understanding-flood-insurance"
    },
    {
        question: "回水阀会降低我的洪水保险吗？",
        answer: "不会。然而，您在查看洪水保险单中包含的物品保险金额时，这可能是一个需要考虑的因素。回水阀可能会影响房主的保险费。",
        zone: "rtapp-status-FullApplication"
    },
    {
        question: "安装下水道回水阀会降低我的洪水保险费吗？",
        answer: "下水道回水阀对洪水保险费没有影响，但在发生洪水时，可以为您节省数百美元甚至数千美元的损失。还可以保护储存在地下室的珍贵物品和纪念品免受损坏，并保持地下室没有污水。",
        zone: "bwv-flood-applicants"
    },
    {
        question: "目前在X区咨询x或在X区至顾问AE的房主是否需要购买洪水保险？",
        answer: "只有当前位于AE中的房主才有资格购买洪水保险。\\n",
        zone: "bwv-flood-applicants"
    },
    {
        question: "在咨询期间我是否需要披露个人信息？",
        answer: "顾问可能会询问您的财务状况和其他个人信息，这样您就可以充分利用家庭评估结果。未经许可，我们不会共享您的个人信息。",
        zone: "rtapp-status"
    },
    {
        question: "我需要为回水阀安装提供更多信息吗？",
        answer: "我们需要证明文件来证明您家中所有年满18岁个人的年收入。有关我们可以接受的文档类型，请参阅下一页的表单。\\n",
        zone: "bwv-flood-applicants"
    },
    {
        question: "回水阀会降低我的房主保险吗？",
        answer: "根据情况而定。一些保险公司为房主提供保险折扣。然而，污水倒流的特定保险范围通常需要以污水倒流附加条款的形式添加到您的房屋保险单中。这样，每年的费用通常在50美元到70美元之间，可能高达175美元，具体取决于免赔额和您需要的承保范围。如果您的房主保险不提供折扣，您仍可以采取重要的步骤，防止未来下水道倒流造成的损坏和随之而来的昂贵维修。",
        zone: "rtapp-status-Complete rtapp-status-Complete rtapp-status-InstallationPrepare rtapp-status-InstallationPrepare"
    },
    {
        question: "条件是否会恢复到回水阀安装前的状态（草坪/地板）？",
        answer: "如有可能，管道工会将施工区域恢复到施工前的状态。可能需要进行一些更改（例如，必须有一种方法接近阀门进行维护，因此可能会设计一个通向阀门的入口。如果安装在室外，那么合同会包含更换草坪的任何部分。",
        zone: "bwv-flood-applicants"
    },
    {
        question: "工程师是否会在审核期间提供反馈？",
        answer: "您的工程团队需要时间分析在家中收集的信息，完成个性化建议。因此，您不会在现场收到反馈。但是别担心！您的顾问可以在会面期间回答您对报告的任何问题。\\n",
        zone: "rtapp-status-AuditScheduled"
    },
    {
        question: "工程师、水管工或检验员是否会就回水阀直接联系房主？如果可以，是什么时候？",
        answer: "检验员将联系安排回水阀的安装。如果申请人必须重新安排安装，他们应联系检验员。",
        zone: "bwv-flood-applicants"
    },
    {
        question: "可行性审查的结果是否会影响我的回水阀申请？",
        answer: "会。在可行性审查期间，工程师将评估您的住宅是否会受益于回水阀，以及住宅条件是否可以安装回水阀。如果他们确定您的房屋不会从回水阀中受益，或者您家的条件不适合安装阀门，您将没有资格安装回水阀。",
        zone: "rtapp-status-FeasibilityReview"
    },
    {
        question: "在安装回水阀期间，我家的供水会被关闭吗？如果会，持续多久？",
        answer: "会关闭，在安装回水阀期间，需要关闭家中的供水。持续时间取决于家庭的情况。我们会尽快通知房主。管道工在下水道管道上安装新阀门时，房主将无法将水排入下水道或冲洗厕所。这不一定是整个安装过程，因为安装可能包括其他施工，如修补地板。这可能需要八个小时。",
        zone: "rtapp-status-InstallationHasBeenScheduled"
    },
    {
        question: "您说夹层空间“大约”4英尺高。您还能再准确一些吗？",
        answer: "夹层空间的确切定义是，从夹层空间的地板到下一层的地板（地毯所在地），测量高度不超过5英尺的区域。5英尺的测量包括上面地板的厚度。如果我们估计地板大约有一英尺厚，地板到天花板的高度大约要低于4英尺，才符合要求。",
        zone: "rate-calculator-building-feature"
    },
    {
        question: "我能获得比NFIP提供范围更大的承保吗？",
        answer: "可以，私人保险公司可以提供超出<a href=“https://www.fema.gov/flood-insurance“target=”_blank“rel=”noopener noreferrer“>NFIP</a>限值的承保。致电您的保险代理人或经纪人，讨论有哪些选项可帮助保护您的企业免受洪水灾害造成的财务损失。",
        zone: "small-business"
    },
    {
        question: "我的企业需要洪水保险吗？",
        answer: "即使您的企业不在高风险洪水地区，洪水也可能随时造成损失。无论是租用还是自有办公地，我们建议您通过<a href=“https://www.fema.gov/flood-insurance“target=”_blank“rel=”noopener noreferrer“>NFIP</a>购买洪水保险。",
        zone: "small-business"
    },
    {
        question: "获得洪水保单需要多长时间？",
        answer: "洪水保险单生效前有30天的等待期。在洪水影响到您的企业之前购买洪水保险是很重要的。",
        zone: "small-business"
    },
    {
        question: "我的洪水保险保额是多少？",
        answer: "通过<A href=“https://www.fema.gov/flood-insurance“target=”_blank“rel=”noopener noreferrer“>NFIP</a>建筑的保额最高为500000美元，物品的保额最高为500000美元。",
        zone: "small-business"
    },
    {
        question: "关于免赔额，我应该了解什么？",
        answer: "选择正确的免赔额很重要。较高的免赔额将降低您的保险成本，但如果您提出理赔，您收到的金额也会减少。",
        zone: "small-business"
    },
    {
        question: "我可以在哪里购买洪水保险？",
        answer: "您可以通过致电保险代理人或经纪人购买洪水保险。他们将能够帮助您直接通过<a href=“https://www.fema.gov/flood-insurance“target=”_blank“rel=”noopener noreferrer“>NFIP</a>购买洪水保险。",
        zone: "small-business"
    },
    {
        question: "当联邦应急管理局提供灾难援助时，我为什么要购买洪水保险？",
        answer: "联邦应急管理局不向企业提供个人补助。如果没有洪水保险，您需要自行支付任何损失，或者被迫贷款。",
        zone: "small-business"
    },
    {
        question: "我的商业保险单承保范围包括洪水吗？",
        answer: "洪水通常不包含在商业保险单或企业所有者的保单中。您需要购买单独的洪水保险单，保护您的企业免受洪水损害的财务成本。",
        zone: "small-business"
    },
    {
        question: "洪水保险承保的范围是什么？",
        answer: "洪水保险有助于保护您的企业免受洪水的财产损失。洪水保险可以支付洪水对建筑物和物品造成的损失。",
        zone: "small-business"
    },
    {
        question: "如果我的房屋发生损坏，我可以获得多少补偿？",
        answer: "如果您拥有一套独栋住宅，很重要的一点是，如果您为住宅投保的金额低于重置成本价值（RCV）的80%，进行理赔时，您只会收到洪灾的“实际现金价值”（ACV）。这意味着，您无法获得房屋维修费用的保险，而只能获得任何结构部件（梁、干墙、地板等）损坏的折旧价补偿。",
        zone: "understanding-flood-insurance"
    },
    {
        question: "风险评级2.0下的新定价方法是什么？",
        answer: "FEMA开发了一个很难理解的系统。我们理解的内容如下：使用FEMA提供的数据和商业上可获得的第三方数据，FEMA将评估每个房产的洪水风险。通过查看与洪水源的距离、潜在洪水类型、建筑物占用率、建筑类型、地基类型、地面标高、一楼高度、楼层数以及之前的洪水保险理赔等信息来实现这一点。其他重要的考虑因素包括重建成本。",
        zone: "insurance-shopping"
    },
    {
        question: "风险评级2.0是否考虑了之前的理赔？",
        answer: "是的，房屋理赔的历史会影响洪水保险费率。但是，重要的是要知道，在最初的费率计算中，联邦应急管理局不会考虑之前的理赔历史。只有在风险评级2.0生效后，当（如果）物业提交新的理赔时，才会考虑之前的理赔。发生这种情况时，联邦应急管理局将考虑20年前的所有理赔，在每年更新保单时更新费率。",
        zone: "insurance-shopping"
    }
];
