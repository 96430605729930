import React from 'react';
import styled from 'styled-components';
import floodedBuilding from '../images/flooded-building.svg';
import mapWithMarkerYellow from '../images/map-with-marker.svg';
import chanceOfStorm from '../images/chance-of-storm.svg';
import { useProvider } from '../helpers/hooks';
import { colors } from '../styles/variables';

const Wrapper = styled.section`
  margin: 70px;
  padding-bottom: 70px;

  @media (max-width: 480px) {
    margin: 60px 20px;
  }
`;

const Header = styled.h2`
  text-align: center;
  margin-bottom: 50px;

  @media (max-width: 767px) {
    font-size: 3rem;
  }
`;

const List = styled.ul`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;

  @media (max-width: 950px) {
    flex-direction: column;
  }
`;

const ListItem = styled.li`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 1rem;
`;

const ListImage = styled.img`
  height: 100px;
`;

const Stat = styled.h5`
  color: ${colors.resilientBlue};
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 4rem;
  text-transform: none;
  line-height: 1.1;
`;

const StatDescription = styled.p`
  max-width: 500px;
  font-size: 24px;
  margin: 0;
`;

const DidYouKnow = () => {
  const provider = useProvider('didYouKnow');

  return (
    <Wrapper>
      <Header>{provider.title}</Header>
      <List>
        <ListItem>
          <ListImage src={floodedBuilding} alt={provider.flood.imgAlt} />
          <Stat>{provider.flood.number}</Stat>
          <StatDescription>{provider.flood.copy}</StatDescription>
        </ListItem>
        <ListItem>
          <ListImage src={mapWithMarkerYellow} alt={provider.risk.imgAlt} />
          <Stat>{provider.risk.number}</Stat>
          <StatDescription>{provider.risk.copy}</StatDescription>
        </ListItem>
        <ListItem>
          <ListImage src={chanceOfStorm} alt={provider.mortgage.imgAlt} />
          <Stat>{provider.mortgage.number}</Stat>
          <StatDescription>{provider.mortgage.copy}</StatDescription>
        </ListItem>
      </List>
    </Wrapper>
  );
};

export default DidYouKnow;
