import React from 'react';
import styled from 'styled-components';
import bluePinpoint from '../images/blue-pinpoint.svg';
import blueCalculator from '../images/blue-calculator.svg';
import blueGraph from '../images/blue-graph.svg';
import { useProvider } from '../helpers/hooks';
import { colors } from '../styles/variables';

const Wrapper = styled.section`
  margin: 0 4rem;
  border-bottom: 1px ${colors.grayScale[1]} solid;
  padding: 4rem 0;

  @media (max-width: 480px) {
    margin: 0 1rem 4rem 1rem;
  }
`;

const Row = styled.div`
  display: flex;

  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 2rem;
  flex-basis: 100%;
  text-align: center;
  max-width: 500px;

  &:first-of-type {
    padding-left: 0;
  }

  &:last-of-type {
    padding-right: 0;
  }

  @media (max-width: 1200px) {
    margin: 2rem 0;

    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

const Icon = styled.img`
  height: 5rem;
  width: 5rem;
  margin: 1rem auto;
`;

const LandingFeatures = () => {
  const provider = useProvider('landingFeatures');

  return (
    <Wrapper>
      <Row>
        <Item>
          <Icon src={bluePinpoint} alt="blue pinpoint" />
          <h4>{provider.findYourHome.header}</h4>
          <p>{provider.findYourHome.body}</p>
        </Item>
        <Item>
          <Icon src={blueCalculator} alt="blue calculator" />
          <h4>{provider.estimate.header}</h4>
          <p>{provider.estimate.body}</p>
        </Item>
        <Item>
          <Icon src={blueGraph} alt="blue graph" />
          <h4>{provider.lowerRisk.header}</h4>
          <p>{provider.lowerRisk.body}</p>
        </Item>
      </Row>
    </Wrapper>
  );
};

export default LandingFeatures;
