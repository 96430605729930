import getRaceOptions from '../constants/RaceOptions';
import getEthnicityOptions from '../constants/EthnicityOptions';
import getGenderOptions from '../constants/GenderOptions';

const ALLOWED_ATTACHMENTS = 'Adjuntar (PDF, JPEG, PNG)';

export default {
  nextWebstatus: 'ApplicationSubmitted',
  errorTitle: 'Algo salió mal al enviar su solicitud.',
  errorBody:
    'Por favor, intente enviar de nuevo. Si el problema persiste, contáctenos info@floodhelpny.org.',
  tryAgain: 'Inténtalo de Nuevo',
  loadingText: 'Cargando...',
  navCopy: {
    next: 'Revisar',
    previous: 'Anterior',
    submit: 'Enviar',
    checkResponses:
      'Verifique que los datos que ingresó sean válidos antes de enviar'
  },
  applicant: 'Solicitante:',
  address: 'Habla a:',
  providerQuestions: [
    {
      headline:
        'Háganos saber quién vive en su hogar o quién puede ser considerado un co-solicitante (por ejemplo, cualquier persona que figura en la escritura):',
      title: 'Solicitante adicional',
      buttonText: 'Show FAQ',
      addRow: true,
      addRowText: 'Añadir solicitante',
      removeRow: false,
      removeRowText: 'Retire al solicitante',
      faqZone: 'bwv-additional-applicants',
      responseFields: [
        {
          key: 'co_applicant_type',
          title: 'Other Applicants',
          applicant_type: '',
          show: false,
          default: 'Elija uno',
          type: 'select-applicant-type',
          options: [
            { value: 'Co-applicant', display: 'Co-solicitante' },
            { value: 'Household-member', display: 'Miembro del hogar' }
          ],
          required: false
        },
        {
          key: 'co_applicant_first_name',
          title: 'Primer Nombre',
          applicant_type: 'both',
          show: false,
          placeholder: '',
          type: 'text',
          required: false
        },
        {
          key: 'co_applicant_last_name',
          title: 'Apellido',
          applicant_type: 'both',
          show: false,
          placeholder: '',
          type: 'text',
          required: false
        },
        {
          key: 'household_member_income',
          title: 'Ingresos anuales',
          applicant_type: 'Household-member',
          show: false,
          placeholder: 'e.g. 50000',
          type: 'number',
          required: false
        },
        {
          key: 'household_member_race',
          title: 'Raza',
          applicant_type: 'Household-member',
          show: false,
          default: 'Elija uno',
          type: 'drop-down',
          options: getRaceOptions('es'),
          required: false
        },
        {
          key: 'household_member_ethnicity',
          title: 'Familia Grupo Étnico',
          applicant_type: 'Household-member',
          show: false,
          default: 'Elija uno',
          type: 'drop-down',
          options: getEthnicityOptions('es'),
          required: false
        },
        {
          key: 'household_member_gender',
          title: 'Sexo',
          applicant_type: 'Household-member',
          show: false,
          default: 'Elige uno',
          type: 'drop-down',
          options: getGenderOptions('es'),
          required: false
        },
        {
          key: 'co_applicant_birthdate',
          title: 'Fecha de Nacimiento',
          applicant_type: 'Co-applicant',
          show: false,
          placeholder: 'e.g. 10/13/1987',
          type: 'date',
          minimum: '1900-01-01',
          maximum: '2020-01-01',
          required: false
        },
        {
          key: 'co_applicant_email',
          title: 'Correo Electrónico',
          applicant_type: 'Co-applicant',
          show: false,
          placeholder: 'e.g. example@example.com',
          type: 'email',
          required: false
        },
        {
          key: 'co_applicant_home_phone',
          title: 'Teléfono residencial',
          applicant_type: 'Co-applicant',
          show: false,
          placeholder: 'e.g. +1 123-456-7890',
          type: 'tel',
          required: false
        },
        {
          key: 'co_applicant_mobile_phone',
          title: 'Teléfono móvil',
          applicant_type: 'Co-applicant',
          show: false,
          placeholder: 'e.g. +1 123-456-7890',
          type: 'tel',
          required: false
        },
        {
          key: 'co_applicant_signature',
          title: 'Firma',
          applicant_type: 'Co-applicant',
          show: false,
          placeholder: '',
          type: 'text',
          required: false
        }
      ]
    },
    {
      title: 'Sandy Compensation',
      headline:
        '¿Ha recibido asistencia relacionada con la supertormenta Sandy de FEMA o de otras fuentes para daños a la propiedad?',
      buttonText: 'Show FAQ',
      faqZone: 'bwv-sandy-compensation',
      responseFields: [
        {
          key: 'sandy_compensation',
          title: '',
          show: true,
          type: 'button-single',
          options: [
            { value: 'true', display: 'Sí' },
            { value: 'false', display: 'No' }
          ],
          invalidAnswers: [],
          alertAnswers: [],
          required: true
        }
      ]
    },
    {
      title: 'Backwater Valve Compensation',
      headline:
        '¿Ha recibido subvenciones u otros fondos para instalar una válvula de prevención del regreso del agua del alcantarillado a las tuberías en la propiedad?',
      buttonText: 'Show FAQ',
      faqZone: 'bwv-valve-compensation',
      responseFields: [
        {
          key: 'backwater_valve_compensation',
          title: '',
          show: true,
          type: 'button-single',
          options: [
            { value: 'true', display: 'Sí' },
            { value: 'false', display: 'No' }
          ],
          invalidAnswers: [],
          alertAnswers: [],
          required: true
        }
      ]
    },
    {
      title: 'Sandy Insurance Types',
      headline: '¿Qué tipos de seguro tenía durante la supertormenta Sandy?',
      buttonText: 'Show FAQ',
      faqZone: 'bwv-insurance-types',
      responseFields: [
        {
          key: 'sandy_insurance_types',
          type: 'multiple-drop-down',
          size: 6,
          show: true,
          multiple: true,
          title: 'Seleccione todas las que correspondan',
          options: [
            { value: 'Homeowners Insurance', display: 'Seguro de propiedad' },
            { value: 'Flood Insurance', display: 'Seguro contra inundación' },
            { value: 'Hazard Insurance', display: 'Seguro de riesgo' },
            { value: 'Wind Insurance', display: 'Seguro contra viento' },
            { value: 'Contents Insurance', display: 'Seguro de contenido' },
            { value: 'Other Insurance', display: 'Otro seguro' }
          ],
          required: false
        }
      ]
    },
    {
      title: 'Tenants',
      headline: '¿Tiene inquilinos viviendo actualmente en su residencia?',
      buttonText: 'Show FAQ',
      faqZone: 'bwv-tenants',
      responseFields: [
        {
          key: 'tenants',
          title: '',
          show: true,
          type: 'button-single',
          options: [
            { value: 'true', display: 'Sí' },
            { value: 'false', display: 'No' }
          ],
          invalidAnswers: [],
          alertAnswers: ['true'],
          alertCopy: 'Descargue el ',
          alertLinkCopy: 'formulario de elegibilidad de ingresos de inquilino.',
          alertLink: 'http://www.floodhelpny.org/samples/tenantIncome.pdf',
          alertCopyAfterLink: 'Usted deberá llenar esto y devolvérnoslo.',
          required: true
        }
      ]
    },
    {
      title: 'Proof of Homeowners Insurance',
      headline: 'Adjunte prueba de seguro de propiedad',
      buttonText: 'Show FAQ',
      faqZone: 'bwv-homeowner-insurance',
      responseFields: [
        {
          key: 'homeowners_insurance',
          title: 'homeowner',
          label: ALLOWED_ATTACHMENTS,
          show: true,
          placeholder: '',
          type: 'file',
          remove: 'Remover archivo',
          canUploadMultipleFiles: false,
          fileTypes: '.jpeg, .png, .pdf',
          required: false
        }
      ]
    },
    {
      title: 'Proof of Flood Insurance',
      headline:
        'Adjunte prueba de seguro contra inundación (si su zona de inundación es AE, AO o VE)',
      buttonText: 'Show FAQ',
      faqZone: 'bwv-flood-insurance',
      responseFields: [
        {
          key: 'flood_insurance',
          title: 'flood',
          label: ALLOWED_ATTACHMENTS,
          show: true,
          placeholder: '',
          type: 'file',
          remove: 'Remover archivo',
          canUploadMultipleFiles: false,
          fileTypes: '.jpeg, .png, .pdf',
          required: false
        }
      ]
    },
    {
      title: 'Proof of Income',
      headline:
        'Adjunte prueba de ingresos para el solicitante y todos los integrantes de la familia',
      buttonText: 'Show FAQ',
      faqZone: 'bwv-income-proof',
      responseFields: [
        {
          key: 'household_member_proofincome',
          title: 'income',
          label: ALLOWED_ATTACHMENTS,
          show: true,
          type: 'file',
          remove: 'Remover archivo',
          canUploadMultipleFiles: false,
          fileTypes: '.jpeg, .png, .pdf',
          required: false
        }
      ]
    },
    {
      title: 'Race',
      headline: 'Seleccione la raza del solicitante principal',
      faqZone: '',
      responseFields: [
        {
          key: 'applicant_race',
          type: 'drop-down',
          show: true,
          multiple: false,
          default: 'Elija uno',
          title: '',
          options: getRaceOptions('es'),
          required: false
        }
      ]
    },
    {
      title: 'Ethnicity',
      headline: 'Seleccione la etnia del solicitante principal',
      faqZone: '',
      responseFields: [
        {
          key: 'applicant_ethnicity',
          type: 'drop-down',
          show: true,
          multiple: false,
          default: 'Elija uno',
          title: '',
          options: getEthnicityOptions('es'),
          required: false
        }
      ]
    },
    {
      title: 'Gender',
      headline: 'Seleccione la identidad de género del solicitante principal',
      faqZone: '',
      responseFields: [
        {
          key: 'applicant_gender',
          type: 'drop-down',
          show: true,
          multiple: false,
          default: 'Elija uno',
          title: '',
          options: getGenderOptions('es'),
          required: false
        }
      ]
    },
    {
      title: 'Birthdate',
      headline: 'Ingrese la fecha de nacimiento del solicitante principal',
      faqZone: '',
      responseFields: [
        {
          key: 'applicant_birthdate',
          title: '',
          show: true,
          placeholder: 'p.ej. 10/13/1987',
          type: 'date',
          minimum: '1900-01-01',
          maximum: '2020-01-01',
          required: false
        }
      ]
    },
    {
      title: 'E-Signature',
      headline:
        'Al ingresar su nombre en la casilla a continuación, usted accede a los términos y las condiciones del programa de válvula de prevención del regreso del agua a las tuberías y certifica que ha leído nuestra política de privacidad',
      buttonText: 'Show FAQ',
      faqZone: 'bwv-signature',
      responseFields: [
        {
          key: 'e_signature',
          title: 'Firma Electrónica',
          show: true,
          placeholder: '',
          type: 'text',
          required: true
        }
      ]
    }
  ],
  // NOTE the keys in the answers array must match the keys in thq questions Array
  // and be in the same order
  providerAnswers: [
    {
      co_applicant_type: [],
      co_applicant_first_name: '',
      co_applicant_last_name: '',
      household_member_income: '',
      household_member_race: '',
      household_member_ethnicity: '',
      household_member_gender: '',
      co_applicant_birthdate: '',
      co_applicant_email: '',
      co_applicant_home_phone: '',
      co_applicant_mobile_phone: '',
      co_applicant_signature: ''
    },
    { sandy_compensation: '' },
    { backwater_valve_compensation: '' },
    { sandy_insurance_types: [] },
    { tenants: '' },
    { homeowners_insurance: '' },
    { flood_insurance: '' },
    { household_member_proofincome: '' },
    { applicant_race: '' },
    { applicant_ethnicity: '' },
    { applicant_gender: '' },
    { applicant_birthdate: '' },
    { e_signature: '' }
  ]
};
