export default [
  {
    type: 'Feature',
    properties: {
      OBJECTID: 14,
      COMMITTEE: 'Canarsie',
      Shape_Leng: 13351.2397475,
      Shape_Area: 9475787.0371199995
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-73.892306000405995, 40.620556000191556],
          [-73.896680000343736, 40.622306000311752],
          [-73.898260999677149, 40.622989000231257],
          [-73.901779999898224, 40.624815999690355],
          [-73.904251999602252, 40.626032000168273],
          [-73.906513999670835, 40.627131000153511],
          [-73.908480000130609, 40.627979000200945],
          [-73.91692200020735, 40.63166899966599],
          [-73.918604000573055, 40.632096000147918],
          [-73.918763000545056, 40.633637000028315],
          [-73.918851999603774, 40.634482999831498],
          [-73.919808999483493, 40.633865000407127],
          [-73.920411999901745, 40.633473999587054],
          [-73.920682999740691, 40.63332999954941],
          [-73.921618000082319, 40.633276000315398],
          [-73.922586999584851, 40.633208999671616],
          [-73.922831999972871, 40.635542999720691],
          [-73.921907999547912, 40.636075000377105],
          [-73.921017000382861, 40.636528000437011],
          [-73.920227999570045, 40.636932000304789],
          [-73.920118999865664, 40.636987999789575],
          [-73.919171999748187, 40.637469999894961],
          [-73.919290000175721, 40.638595999576935],
          [-73.919414999460216, 40.63977499961841],
          [-73.919462000060008, 40.640238999959571],
          [-73.91955600013317, 40.641184999565318],
          [-73.9196169996207, 40.641658999598839],
          [-73.9196620005184, 40.642103999988464],
          [-73.91972899962181, 40.642724999939581],
          [-73.919906999437416, 40.644329999908507],
          [-73.919950999498269, 40.644707000408786],
          [-73.919820999763047, 40.644784000181915],
          [-73.919430000137197, 40.645061000413364],
          [-73.918924000005205, 40.645397999697664],
          [-73.918302000288449, 40.64581199978425],
          [-73.917647999457131, 40.646223000239431],
          [-73.916937000583985, 40.646683000300392],
          [-73.916209000416018, 40.647150999878527],
          [-73.915501000202568, 40.64760499959359],
          [-73.914731999713652, 40.648101000441073],
          [-73.913960999808907, 40.648600999593057],
          [-73.913253000445323, 40.649061000316088],
          [-73.912535000530184, 40.64952099973312],
          [-73.911823000114254, 40.649983999651582],
          [-73.911101000348367, 40.650447999849845],
          [-73.910230000558428, 40.651014000041506],
          [-73.909448999884717, 40.651521999891195],
          [-73.908558000057951, 40.652095999734108],
          [-73.90933499950475, 40.652809000187446],
          [-73.909726000348698, 40.65431299998469],
          [-73.910123000336, 40.655851999750432],
          [-73.909176999461891, 40.655995000382269],
          [-73.908244999798825, 40.656132999784148],
          [-73.907306999598418, 40.656272999610543],
          [-73.906915999580576, 40.654731999644277],
          [-73.905056999942545, 40.655012999884796],
          [-73.904142000269928, 40.655146999705117],
          [-73.903173000477778, 40.655300999810173],
          [-73.902310999744799, 40.655792000030758],
          [-73.900303000464973, 40.657263000186632],
          [-73.899966999696261, 40.657284999868033],
          [-73.899040999629932, 40.657372000032936],
          [-73.897966000421135, 40.657531999979433],
          [-73.897045000130063, 40.657668000341054],
          [-73.896109999747182, 40.657754000033293],
          [-73.895381999526549, 40.657720000106316],
          [-73.894630999691614, 40.656692999625669],
          [-73.893952000414529, 40.655778000411594],
          [-73.893546999666043, 40.655201999594745],
          [-73.892457000564747, 40.653711000097978],
          [-73.891365999933925, 40.652364999727546],
          [-73.889993999835596, 40.65113800022371],
          [-73.891252000351685, 40.650025999568093],
          [-73.889586999498789, 40.648111000285965],
          [-73.88655699953452, 40.645086999649045],
          [-73.886186000383248, 40.644771000124059],
          [-73.884324000534917, 40.642936999575454],
          [-73.883735000459382, 40.642450999759497],
          [-73.883113000207771, 40.641873999599227],
          [-73.882515999757402, 40.64135100006861],
          [-73.881901000074066, 40.640835000251251],
          [-73.880968000552258, 40.639453000426712],
          [-73.879541000523915, 40.638861999567318],
          [-73.87890100019132, 40.638038000358947],
          [-73.876299999825548, 40.635192000209557],
          [-73.873956999775444, 40.633773000078477],
          [-73.881822999422667, 40.627941999622756],
          [-73.882389999661314, 40.627541999896124],
          [-73.892306000405995, 40.620556000191556]
        ]
      ]
    }
  },
  {
    type: 'Feature',
    properties: {
      OBJECTID: 24,
      COMMITTEE: 'Gravesend and Bensonhurst',
      Shape_Leng: 16716.038095899999,
      Shape_Area: 7748857.14592
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-73.966614000227864, 40.584574999877034],
          [-73.967815000448297, 40.584538999889155],
          [-73.969063000334444, 40.584000000104723],
          [-73.969087000048006, 40.584000999866504],
          [-73.969404000372592, 40.58400499986994],
          [-73.969649000253028, 40.5839959995687],
          [-73.969715000284225, 40.58399400010731],
          [-73.970045000039065, 40.583965000255503],
          [-73.970310000168013, 40.583928999552441],
          [-73.970590999744729, 40.583877999969644],
          [-73.970845999755127, 40.583819999837573],
          [-73.971745000205729, 40.58366300013973],
          [-73.973084999877983, 40.583430000047315],
          [-73.974563000385643, 40.583123999795134],
          [-73.977516999796038, 40.582744999639111],
          [-73.980690999755353, 40.583059999800213],
          [-73.980839000435452, 40.583313999867798],
          [-73.981754000002894, 40.583584000396627],
          [-73.982812000554418, 40.583791999625291],
          [-73.983876000123658, 40.583975999610239],
          [-73.984820999957975, 40.584168000390427],
          [-73.98492799944998, 40.584596000366936],
          [-73.985480999652481, 40.584518999793112],
          [-73.985860999940144, 40.584524999797544],
          [-73.986275999576165, 40.584479999547163],
          [-73.986715999612855, 40.584568000425335],
          [-73.987084000266762, 40.584619000253689],
          [-73.986881999685167, 40.58427100020166],
          [-73.986547999476656, 40.583663999991487],
          [-73.987581000323601, 40.583564999945544],
          [-73.988299000567679, 40.583466000391084],
          [-73.988530999931257, 40.582727000349763],
          [-73.989545000014402, 40.581932999680078],
          [-73.989262999798243, 40.580628000278402],
          [-73.989398000215402, 40.580463000229898],
          [-73.992316999602764, 40.581486999890352],
          [-73.992394000348611, 40.581962000096695],
          [-73.990645999815968, 40.584742000286369],
          [-73.995875999437175, 40.582472000156542],
          [-74.000418000236763, 40.58354599985244],
          [-74.000486999464997, 40.584499999672722],
          [-73.998424000238941, 40.585653000297611],
          [-73.99953799981121, 40.586087000231444],
          [-73.999532000570284, 40.586481000254516],
          [-73.996926000576366, 40.587484000435168],
          [-73.994298999563611, 40.588495999620683],
          [-73.994464000287806, 40.588816999755167],
          [-73.997787000503692, 40.587388999923412],
          [-73.997938000503723, 40.587619999612762],
          [-74.00042599974735, 40.58669200037928],
          [-74.000484000464994, 40.587377000171095],
          [-73.9980309997609, 40.588465999827427],
          [-73.99836000023781, 40.589281000205212],
          [-73.998976000144395, 40.589010999572871],
          [-73.999299999488017, 40.58938400034549],
          [-73.998379000584578, 40.589770999585724],
          [-73.998586000063483, 40.590543000379597],
          [-73.997111000392081, 40.591278000132661],
          [-73.997538000252916, 40.591812000190586],
          [-73.999704000468142, 40.591189000012569],
          [-73.999951000246298, 40.591637000274595],
          [-73.998610999582809, 40.592527999901421],
          [-73.999004000064204, 40.592407999562965],
          [-73.999151999411737, 40.592943000388999],
          [-73.998289000578012, 40.593572999864747],
          [-74.000949999768949, 40.592513999891537],
          [-74.001865999698595, 40.594219000305884],
          [-74.003446000576957, 40.596179000143415],
          [-74.004116000367603, 40.596473000424716],
          [-74.005387000544317, 40.597030999910793],
          [-74.006253999993618, 40.597410999747119],
          [-74.008053000397254, 40.598607999596716],
          [-74.009525000370161, 40.599527999870567],
          [-74.010384999900253, 40.600015999949051],
          [-74.011234000509674, 40.600498999651222],
          [-74.011755999652706, 40.600795000075841],
          [-74.011248999966867, 40.601285999947208],
          [-74.010868000021844, 40.60165700037328],
          [-74.010017000108519, 40.602470000135092],
          [-74.010750999933407, 40.602915999973661],
          [-74.009779999958965, 40.603860999704864],
          [-74.008368999795849, 40.605217000122181],
          [-74.007625000393233, 40.60476800038105],
          [-74.006910000013661, 40.604338000306484],
          [-74.006171999703085, 40.603886999619967],
          [-74.007458999614968, 40.602644999813016],
          [-74.00678099962964, 40.602291000318232],
          [-74.005923999973746, 40.601839999732853],
          [-74.005139999544923, 40.60143200012174],
          [-74.004353999521115, 40.60102199965138],
          [-74.003601999465417, 40.600628000053483],
          [-74.002830000114926, 40.600203999743371],
          [-74.002121999872557, 40.599719000112884],
          [-74.001449999460817, 40.599248999598693],
          [-74.000738000573406, 40.5987660000593],
          [-73.99945500033003, 40.600005000013056],
          [-73.998749000429797, 40.599519999799824],
          [-73.998070999544993, 40.599056000400289],
          [-73.997339999620422, 40.598551999745851],
          [-73.995594000427076, 40.600232999628197],
          [-73.993811000481401, 40.601949999901549],
          [-73.993200999678336, 40.602533999889559],
          [-73.992619000000801, 40.603089999602354],
          [-73.992035999450039, 40.603650999973141],
          [-73.991454999788417, 40.604208999596196],
          [-73.990872999661619, 40.604769999960595],
          [-73.990289999762609, 40.605329000359689],
          [-73.989713000539112, 40.605887999775142],
          [-73.989132000286759, 40.606455999570819],
          [-73.988551000292688, 40.607001999969832],
          [-73.988423000105342, 40.607119000150924],
          [-73.987255000285458, 40.607244999659336],
          [-73.986257000420693, 40.607356000166661],
          [-73.985942999648529, 40.607389999679285],
          [-73.985332000582531, 40.607457999578841],
          [-73.984655999437976, 40.607531000234239],
          [-73.984403000115833, 40.607558999672321],
          [-73.983476999883138, 40.607662999644752],
          [-73.982547999763767, 40.607764000109036],
          [-73.981623999435953, 40.607866000198051],
          [-73.981195999887504, 40.607912999839598],
          [-73.980695000052322, 40.607969000068238],
          [-73.979696999664114, 40.60807699976489],
          [-73.978699000073078, 40.608187999712698],
          [-73.977773999894296, 40.608288999784264],
          [-73.976846999660282, 40.608392999813624],
          [-73.975918999926947, 40.608494999826164],
          [-73.974994999724103, 40.608595999981439],
          [-73.974031000227541, 40.608702000236519],
          [-73.973219999763074, 40.608788999599334],
          [-73.972993999495301, 40.608813999758489],
          [-73.971912999828675, 40.608935999645396],
          [-73.970895999501025, 40.60904999970105],
          [-73.969969999605212, 40.609150000363066],
          [-73.969041000479763, 40.609253999975678],
          [-73.967667999983973, 40.60940400038308],
          [-73.967490999943678, 40.608475999899738],
          [-73.967261000121383, 40.607259000378619],
          [-73.966959000208661, 40.605662000081601],
          [-73.966796000324919, 40.604802999630245],
          [-73.96635700027916, 40.602479999859824],
          [-73.965903999415318, 40.600090000236534],
          [-73.965477000336961, 40.597834000200955],
          [-73.965076000275545, 40.595715999990254],
          [-73.964833999671924, 40.594434000104883],
          [-73.964637999502585, 40.593328000287379],
          [-73.964794000286616, 40.592580999754674],
          [-73.964957000006819, 40.591862000152638],
          [-73.965129000480488, 40.591108000279824],
          [-73.965301999992121, 40.590357999825301],
          [-73.965474000451422, 40.589607999965537],
          [-73.965641000456444, 40.588887999726268],
          [-73.965814000023727, 40.588133000185422],
          [-73.965975000519634, 40.587431999752852],
          [-73.966150000185564, 40.586668999698851],
          [-73.96633000030215, 40.585896000264491],
          [-73.966496999902262, 40.585141999588252],
          [-73.966614000227864, 40.584574999877034]
        ]
      ]
    }
  },
  {
    type: 'Feature',
    properties: {
      OBJECTID: 26,
      COMMITTEE: 'Howard Beach',
      Shape_Leng: 14815.7612302,
      Shape_Area: 6778067.77599
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-73.825336999914825, 40.644248000249803],
          [-73.835663999633169, 40.644057999597209],
          [-73.839998999564344, 40.644142999843453],
          [-73.848337999856795, 40.643521000039378],
          [-73.85493800035151, 40.642649999863231],
          [-73.855021000380034, 40.643097999667894],
          [-73.856148000323046, 40.649197999832744],
          [-73.856651999842811, 40.6501290001559],
          [-73.857527000194963, 40.651411000120085],
          [-73.858386000501653, 40.65267100027301],
          [-73.859872000192027, 40.654026000083846],
          [-73.860490000529438, 40.654654999652792],
          [-73.860541999554201, 40.654718000348041],
          [-73.860594000326955, 40.654780000191664],
          [-73.860635000540952, 40.654823999661637],
          [-73.860675999610493, 40.654868000006495],
          [-73.860963999450291, 40.655180000002737],
          [-73.861658000312545, 40.656110000114751],
          [-73.862193000172851, 40.65682900036267],
          [-73.862438000239862, 40.657257999627888],
          [-73.863171000138848, 40.658277000360187],
          [-73.861507999752604, 40.659050000104799],
          [-73.86121299960206, 40.659154000082189],
          [-73.860742999805694, 40.659293000295371],
          [-73.860273000430212, 40.659432000390368],
          [-73.860173999758459, 40.659461999690841],
          [-73.860073999865051, 40.659490999907135],
          [-73.859658999698283, 40.659581999997201],
          [-73.85924799958174, 40.659672000332733],
          [-73.859007999535336, 40.659730000146148],
          [-73.858769000537478, 40.659789000288221],
          [-73.858125999617073, 40.659883000101054],
          [-73.857481999686712, 40.659976999950629],
          [-73.857681000375464, 40.660772999592119],
          [-73.857879000157482, 40.661563999995522],
          [-73.858031999417193, 40.662036000094396],
          [-73.858047999625185, 40.662095000038455],
          [-73.85832500044755, 40.663113000387845],
          [-73.858338000546027, 40.663161000072982],
          [-73.858359000583533, 40.663236999786058],
          [-73.858012000174611, 40.66328200039149],
          [-73.857619000438859, 40.663332000355091],
          [-73.856727999433744, 40.663489000055812],
          [-73.856082000145193, 40.663679999867647],
          [-73.855659999514884, 40.663801999840807],
          [-73.856062999936256, 40.665510999816917],
          [-73.856114999722649, 40.665728999765818],
          [-73.856424999614717, 40.667032999978723],
          [-73.856459000550871, 40.667158999742014],
          [-73.856847000428303, 40.668602999952128],
          [-73.857267000256101, 40.670254999565266],
          [-73.857633000523037, 40.671656000185145],
          [-73.858555000538388, 40.671533000408182],
          [-73.859390999549362, 40.671394000357353],
          [-73.859473999921974, 40.671381000438934],
          [-73.860389000111581, 40.671268999963281],
          [-73.860774999453085, 40.672805999891672],
          [-73.861023999920704, 40.674004999857857],
          [-73.861064000516222, 40.674342999813049],
          [-73.861318000135029, 40.675020999715208],
          [-73.860295999485615, 40.674772000244843],
          [-73.859592999741736, 40.674556000118145],
          [-73.859283000520165, 40.674453000141519],
          [-73.858251999643841, 40.674136000388955],
          [-73.856933000160751, 40.673399000053195],
          [-73.856486999448535, 40.673376999625823],
          [-73.852837999662697, 40.672279000103337],
          [-73.851635000191308, 40.671929999701568],
          [-73.850964999601942, 40.671720000245983],
          [-73.848477999508148, 40.67091700026311],
          [-73.847869999733902, 40.670779999698226],
          [-73.847413999729127, 40.670629999965975],
          [-73.845825000512917, 40.67014399992653],
          [-73.842434000145317, 40.669289999870159],
          [-73.842069999602572, 40.669119000058707],
          [-73.841764999973321, 40.66897500028314],
          [-73.840711999790088, 40.668701000158023],
          [-73.839628000434985, 40.668418000114833],
          [-73.839278999865726, 40.668201999903232],
          [-73.838343999459084, 40.66798899970788],
          [-73.837614000359636, 40.667886000203012],
          [-73.837418000249002, 40.667832999947962],
          [-73.836667000426061, 40.667624999993919],
          [-73.835699000016433, 40.667383999770259],
          [-73.834957999596369, 40.667222000355871],
          [-73.834649999482423, 40.667114999798038],
          [-73.832906000522854, 40.666651000328329],
          [-73.833216000106759, 40.666092000261941],
          [-73.83271699976288, 40.665693000153091],
          [-73.832618999652595, 40.665511000141784],
          [-73.832547000491857, 40.665377999732797],
          [-73.83264399964483, 40.665184000157062],
          [-73.832246000027837, 40.664982000419435],
          [-73.831780999813617, 40.663673999940883],
          [-73.830964000111052, 40.662100000431643],
          [-73.83022200052136, 40.660525999873656],
          [-73.829870000435818, 40.659967000419357],
          [-73.829757000138159, 40.659785999823349],
          [-73.828492999841984, 40.657252999575597],
          [-73.828256000292242, 40.656033999998066],
          [-73.826815000374722, 40.65089999986391],
          [-73.826500000483804, 40.649573000080274],
          [-73.826474999578082, 40.649205000152961],
          [-73.826410000399704, 40.648261000065631],
          [-73.825531000515852, 40.64501800013759],
          [-73.825336999914825, 40.644248000249803]
        ],
        [
          [-73.847405999820111, 40.669877999606861],
          [-73.847934000298864, 40.67004499980532],
          [-73.850117000033791, 40.669557000373061],
          [-73.851097999472998, 40.669412000371096],
          [-73.85072100013339, 40.667860999923583],
          [-73.850337999557667, 40.6663229995482],
          [-73.849918000438322, 40.664627000313189],
          [-73.849102000002674, 40.663186000420353],
          [-73.848877999927083, 40.66289600009457],
          [-73.845038000245893, 40.664721000279378],
          [-73.842371000381007, 40.665838000071538],
          [-73.841605000425332, 40.666145000264109],
          [-73.841743999567598, 40.666659999838174],
          [-73.8419119998062, 40.667468999872369],
          [-73.842031999783728, 40.66789899990502],
          [-73.842136000242363, 40.66826899961363],
          [-73.84531399967409, 40.669286000016143],
          [-73.846332999404382, 40.669560000432426],
          [-73.847405999820111, 40.669877999606861]
        ]
      ]
    }
  },
  {
    type: 'Feature',
    properties: {
      OBJECTID: 32,
      COMMITTEE: 'Lower Manhattan',
      Shape_Leng: 25808.755259099999,
      Shape_Area: 11167599.3783
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-74.006970000383149, 40.741627999736991],
          [-74.005366000329985, 40.740944999812967],
          [-74.005158000073038, 40.740857999806401],
          [-74.005062999655621, 40.740817999554295],
          [-74.002743999881176, 40.739844999724959],
          [-74.002522999835094, 40.73975300006795],
          [-73.999683999490685, 40.738551999811122],
          [-73.999219999568183, 40.738358000016902],
          [-73.996840000518063, 40.737361000101956],
          [-73.993617000503562, 40.736005000296863],
          [-73.991692000424962, 40.735204000184559],
          [-73.991197999690726, 40.735010999674309],
          [-73.990753999650707, 40.73483899967183],
          [-73.989937999455762, 40.73447299997391],
          [-73.988778999894478, 40.733966000242987],
          [-73.987179999880624, 40.733292000002415],
          [-73.984940999983564, 40.732346999767572],
          [-73.982563999664052, 40.7313609998359],
          [-73.980338000183608, 40.730447999562536],
          [-73.978001000117231, 40.72942899992951],
          [-73.975696000206767, 40.728474999640746],
          [-73.973291000073004, 40.727433999838908],
          [-73.97204100022887, 40.72687499967909],
          [-73.971874999616688, 40.726803000267076],
          [-73.971396000170373, 40.726662000428746],
          [-73.971488999931339, 40.726136999722335],
          [-73.972751000015833, 40.721058000082969],
          [-73.972869000163939, 40.720725000377513],
          [-73.973491000222694, 40.718971999572048],
          [-73.973634999442126, 40.718612000282754],
          [-73.97440199958892, 40.716700999905022],
          [-73.975160000383411, 40.714740999593296],
          [-73.976167000320132, 40.712932999757093],
          [-73.976624000516082, 40.712224000315089],
          [-73.977020000066688, 40.711648999903765],
          [-73.977140999831249, 40.711571000319758],
          [-73.977207999724001, 40.711505999713012],
          [-73.977251000126969, 40.711439999982133],
          [-73.977317000495304, 40.711374000422325],
          [-73.977382000036314, 40.711291000172572],
          [-73.977446999869528, 40.711225999720121],
          [-73.977533999605114, 40.711126999739101],
          [-73.97759999987889, 40.711078999844837],
          [-73.977685999919814, 40.711028999697071],
          [-73.977773999835037, 40.71094700017391],
          [-73.977881000507736, 40.71091500011638],
          [-73.977968000486584, 40.710864999601711],
          [-73.9780979998324, 40.710800000437231],
          [-73.978489000130381, 40.710669999872316],
          [-73.978739999626924, 40.71059400027503],
          [-73.979801000326447, 40.710275000235761],
          [-73.980655999867324, 40.710245000190724],
          [-73.981278000011045, 40.710224000113058],
          [-73.981240000023632, 40.709824999950257],
          [-73.984546999949671, 40.70949500033273],
          [-73.986546000505456, 40.709297000103355],
          [-73.989534000020626, 40.708846999944726],
          [-73.989386999845436, 40.709890999664694],
          [-73.989762999725272, 40.709842000388392],
          [-73.990423000460581, 40.709741999919764],
          [-73.990226000245428, 40.708804999829262],
          [-73.990517999520051, 40.708802000116506],
          [-73.990791000210038, 40.709691000401925],
          [-73.991896000295569, 40.709535000106762],
          [-73.996004999467303, 40.708894999871269],
          [-73.997642999692928, 40.708641000429672],
          [-73.999228000153053, 40.708176999682202],
          [-73.999067000419899, 40.707941000160226],
          [-74.001272999579939, 40.706930999597716],
          [-74.001445999984639, 40.706054999823181],
          [-74.000974000013287, 40.705557999896193],
          [-74.001217000452215, 40.705408999662957],
          [-74.002165999734473, 40.706315999672924],
          [-74.002552999640102, 40.706065000405985],
          [-74.002116999552271, 40.705617999840165],
          [-74.001685999480927, 40.705145000000584],
          [-74.00192800041826, 40.705006999787848],
          [-74.002839999520731, 40.705887000256212],
          [-74.003207000369244, 40.705669999720534],
          [-74.00230099954932, 40.704809999666629],
          [-74.002547999648385, 40.704650000261758],
          [-74.003052999734138, 40.705117000406467],
          [-74.003456000196806, 40.705511000295182],
          [-74.003778999429159, 40.705313999753166],
          [-74.002679000596487, 40.704384999591042],
          [-74.002881000375083, 40.704204000113656],
          [-74.003595999946967, 40.704784999913571],
          [-74.004031000350309, 40.70514900008839],
          [-74.004337999459722, 40.70496499969822],
          [-74.004551999429992, 40.704837999541965],
          [-74.004206000507679, 40.704282999860986],
          [-74.003626999599035, 40.703299999758443],
          [-74.00395100031632, 40.703148000214505],
          [-74.004358000115815, 40.703813999907098],
          [-74.004742999868498, 40.704466000210488],
          [-74.005227000092503, 40.704171999985746],
          [-74.004964000448837, 40.703720000222987],
          [-74.004442000554306, 40.702786999831318],
          [-74.004702999508922, 40.702574999583156],
          [-74.005974999766323, 40.703375000029006],
          [-74.006708000000955, 40.702947000077806],
          [-74.006323999737546, 40.702555000043908],
          [-74.005522999622357, 40.701829000420112],
          [-74.005787999926611, 40.701689000146715],
          [-74.006566000113708, 40.702472000387623],
          [-74.007014999497216, 40.7029179998991],
          [-74.007531000253209, 40.702596000069263],
          [-74.00698099955062, 40.70214399977862],
          [-74.006065999794714, 40.701359000281499],
          [-74.006521999671335, 40.701163000012656],
          [-74.007365999951588, 40.701806999638059],
          [-74.007962999982595, 40.702245000147641],
          [-74.010196999739946, 40.701070000356673],
          [-74.009826999908725, 40.700376000149419],
          [-74.009500000300292, 40.699718999856458],
          [-74.009762000381727, 40.699670000067258],
          [-74.010146999619295, 40.700333000283386],
          [-74.01052300036163, 40.700994999561495],
          [-74.011252000534697, 40.700847999663431],
          [-74.01097300020551, 40.699940999835491],
          [-74.011000999966484, 40.699869999563113],
          [-74.01104800004849, 40.699859000161496],
          [-74.011116000239426, 40.69988500020537],
          [-74.011146999439461, 40.699934999915065],
          [-74.011395999722112, 40.700635999875324],
          [-74.011550000535223, 40.700609000419469],
          [-74.011438999778406, 40.700221000367861],
          [-74.01143699960214, 40.70018599960877],
          [-74.011480999735625, 40.700166000067817],
          [-74.011542999659937, 40.700178999651733],
          [-74.011572999576657, 40.700220000200737],
          [-74.011695000173489, 40.700592999651008],
          [-74.011844000129287, 40.700553000054015],
          [-74.011750999841084, 40.700186000062502],
          [-74.011764999973252, 40.700133000362698],
          [-74.011845999690905, 40.700121999823757],
          [-74.011891000356087, 40.700162999877847],
          [-74.012057999675449, 40.700526000021711],
          [-74.012435999770361, 40.70046200039269],
          [-74.012229000491757, 40.699775999951207],
          [-74.012272000360099, 40.699699000130948],
          [-74.012367000476445, 40.699670000207725],
          [-74.012457999549142, 40.699699999622688],
          [-74.012899999501698, 40.700370999557009],
          [-74.012976999738925, 40.700395999850059],
          [-74.013028999550698, 40.700381000275854],
          [-74.013054000501498, 40.700332999605976],
          [-74.013023999694127, 40.699903000420427],
          [-74.013067000029636, 40.699879000054274],
          [-74.013151999831621, 40.699927999721794],
          [-74.013339000492721, 40.700365000046084],
          [-74.013582999725784, 40.700371000413703],
          [-74.013405999964178, 40.699351000422645],
          [-74.01341699959319, 40.699320999710821],
          [-74.013453999508556, 40.699312000228922],
          [-74.013502999854964, 40.699316999813036],
          [-74.013518000155145, 40.699343000435704],
          [-74.013738000358927, 40.700342000381681],
          [-74.013853999969655, 40.700333000068653],
          [-74.013772000310695, 40.699576000253273],
          [-74.013780999521032, 40.699548000322594],
          [-74.013831000215049, 40.699526999852367],
          [-74.013870000385751, 40.699564000351209],
          [-74.013973999565906, 40.700307999565517],
          [-74.014036000180766, 40.700489000152608],
          [-74.014619999607277, 40.700542000292472],
          [-74.014671000530143, 40.700183999771099],
          [-74.015369000123357, 40.700370999558551],
          [-74.015388000196623, 40.700393999988123],
          [-74.015531000538743, 40.700273000309842],
          [-74.01684599954379, 40.701328999697559],
          [-74.018053999651798, 40.703179999575326],
          [-74.017358000020508, 40.704303999881013],
          [-74.017300999598191, 40.704456999690734],
          [-74.018251000055997, 40.704292000205641],
          [-74.018296999782393, 40.70442600011485],
          [-74.017246999659392, 40.704578999734515],
          [-74.017255999723801, 40.704794000273829],
          [-74.019168999551553, 40.704773999873986],
          [-74.01924099975146, 40.704684999562588],
          [-74.019603000332907, 40.705503999862714],
          [-74.019595999428773, 40.705638000404512],
          [-74.019546000183325, 40.706552999668766],
          [-74.019475999575207, 40.706962999841586],
          [-74.019225000417308, 40.708447000175468],
          [-74.019126000028749, 40.709026999736501],
          [-74.018917000263585, 40.710262999676758],
          [-74.018864999423116, 40.710566999710487],
          [-74.018588999483228, 40.71220099994332],
          [-74.017788000533656, 40.712043999983692],
          [-74.016867999999477, 40.711931999941825],
          [-74.016508000427677, 40.71322399983606],
          [-74.017367999655676, 40.713378000355668],
          [-74.018309999654093, 40.713562000116909],
          [-74.018143999437513, 40.714290999959502],
          [-74.017977000240833, 40.71501900003026],
          [-74.016863999815115, 40.719611000344692],
          [-74.013045999480624, 40.71908600020592],
          [-74.012910000555223, 40.719716999907405],
          [-74.013446999756781, 40.719782000034456],
          [-74.013390000292745, 40.72004300007513],
          [-74.016380999570671, 40.720446999725254],
          [-74.016302000175855, 40.72075799994785],
          [-74.012783999472973, 40.720311999635236],
          [-74.012668999835142, 40.720855999900635],
          [-74.016221999830336, 40.721275000088092],
          [-74.016153000019898, 40.72162999990163],
          [-74.013147000010818, 40.721257000200936],
          [-74.013062000108022, 40.721579000207946],
          [-74.012539999715912, 40.721503000218419],
          [-74.012481000190903, 40.721774000030443],
          [-74.012376000102606, 40.722387000219342],
          [-74.01197800008984, 40.724333000001209],
          [-74.015989999917352, 40.724766000087271],
          [-74.015727999988869, 40.725422999626147],
          [-74.011820000567496, 40.725082999604169],
          [-74.011652999668357, 40.725907000228823],
          [-74.015406999633427, 40.72635900001643],
          [-74.015332999462856, 40.726777000303166],
          [-74.011568999726649, 40.726444999787795],
          [-74.011376000518581, 40.728232999856566],
          [-74.014885999698095, 40.728495000088294],
          [-74.014886999788786, 40.728495999555214],
          [-74.014864999559776, 40.728669000395271],
          [-74.014357000558292, 40.728611999877614],
          [-74.014232999770755, 40.729524999868893],
          [-74.014072000010714, 40.730688999754463],
          [-74.011163999957674, 40.730443999628051],
          [-74.011094000239936, 40.731257999680373],
          [-74.014203999475612, 40.731505000081881],
          [-74.01415200000838, 40.731693000282654],
          [-74.011076999500816, 40.731448999981062],
          [-74.010996999820605, 40.73232099977411],
          [-74.010929000117457, 40.733023000056342],
          [-74.014021000151359, 40.733059000285252],
          [-74.014013000124095, 40.733331999875645],
          [-74.010903000438347, 40.733297000027129],
          [-74.010874000450826, 40.733617000261575],
          [-74.010841999880768, 40.734003000415782],
          [-74.01361900039818, 40.734139999663761],
          [-74.013605000160297, 40.734413000139746],
          [-74.01082700001686, 40.734282999635511],
          [-74.010760999966791, 40.734918000133263],
          [-74.013476999512179, 40.735026999740988],
          [-74.013482000196589, 40.735371999576813],
          [-74.010716000006425, 40.735343999776006],
          [-74.010658999611238, 40.736021999801586],
          [-74.013006999827198, 40.73612000011326],
          [-74.013019999521589, 40.736451999743707],
          [-74.010631000212072, 40.736333999885638],
          [-74.010577999658935, 40.736970999653799],
          [-74.010483999828963, 40.738069000164202],
          [-74.011284000530864, 40.73809999987774],
          [-74.011263000110901, 40.738400000361878],
          [-74.010458999932411, 40.738364999783187],
          [-74.010393999595749, 40.739135999683157],
          [-74.011662000095797, 40.739224000009969],
          [-74.011636999648275, 40.739564999725438],
          [-74.011633000099266, 40.739589000007413],
          [-74.011481000340083, 40.74047400037832],
          [-74.011456000149053, 40.740817999792434],
          [-74.009663999934418, 40.740741000062393],
          [-74.009577999966439, 40.741211000051287],
          [-74.012525999419978, 40.741513999551096],
          [-74.012443000458504, 40.741782000413096],
          [-74.00952800030241, 40.741485999575247],
          [-74.009365999792436, 40.742299000173098],
          [-74.012331000128412, 40.742618000120373],
          [-74.012266999883195, 40.742873000252587],
          [-74.009331000232095, 40.742571999615024],
          [-74.008810000163706, 40.742378000374856],
          [-74.008204999742645, 40.74214400014953],
          [-74.006970000383149, 40.741627999736991]
        ]
      ]
    }
  },
  {
    type: 'Feature',
    properties: {
      OBJECTID: 39,
      COMMITTEE: 'Red Hook',
      Shape_Leng: 10433.6882317,
      Shape_Area: 2511300.9323999998
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-74.005497000083324, 40.685847000212824],
          [-74.004852999905111, 40.685667999592361],
          [-74.004504000285934, 40.686300999650641],
          [-74.002363999955648, 40.685736999721165],
          [-74.001501000511226, 40.685492000149587],
          [-74.001003000195951, 40.685350000100357],
          [-74.000890999970139, 40.685301000107472],
          [-74.000642000206369, 40.685246999926306],
          [-74.000714000449818, 40.685101000448164],
          [-74.000957999475418, 40.684578999892956],
          [-74.001281000522596, 40.68390900012335],
          [-74.001608999949411, 40.683234999553981],
          [-74.001930999618537, 40.682564999923493],
          [-74.002251999419585, 40.681893999719449],
          [-74.002565000445614, 40.68122800032139],
          [-74.002894000415367, 40.680558999590268],
          [-74.00325599974876, 40.679562000078072],
          [-74.003042000580848, 40.679147000200203],
          [-74.002781000338402, 40.678870000150212],
          [-74.002228000251975, 40.678257000106512],
          [-74.002161000519862, 40.678180999781205],
          [-74.001539000152377, 40.67726800040964],
          [-74.001029999948869, 40.676413000369386],
          [-74.000628000317008, 40.675547999625749],
          [-74.000852000372973, 40.675623000369228],
          [-74.001124000167749, 40.675695999892746],
          [-74.000603999950556, 40.67482000011448],
          [-73.999979000462702, 40.673906000304726],
          [-73.999907999521653, 40.673737000342761],
          [-73.998958000566361, 40.672177999815439],
          [-73.998658999712546, 40.671704999706037],
          [-73.998782000332341, 40.671594000165726],
          [-73.999021000232347, 40.671429000098122],
          [-73.999238999565762, 40.670729999959647],
          [-73.999456999941913, 40.670140000028582],
          [-74.000175000506488, 40.668601000179102],
          [-74.001056999524266, 40.668944999643564],
          [-74.003711000174135, 40.668006000192648],
          [-74.004442000286573, 40.667723999777678],
          [-74.005838999854333, 40.666285000071973],
          [-74.005243999543751, 40.668717000309279],
          [-74.004754000185756, 40.670721000108699],
          [-74.005440000372786, 40.670921999829019],
          [-74.01167499948231, 40.665081000045518],
          [-74.015749000178033, 40.664568999589392],
          [-74.016903000295969, 40.66484700011948],
          [-74.017887000252202, 40.665849999832254],
          [-74.018575999544552, 40.67039300042444],
          [-74.018764000424198, 40.671075999949672],
          [-74.01933900010134, 40.671625000200457],
          [-74.017723999530588, 40.670939999698923],
          [-74.016688000404116, 40.665437000152473],
          [-74.012008999694444, 40.665623000160963],
          [-74.010695999644014, 40.668971000380537],
          [-74.011542999458072, 40.669784999717351],
          [-74.011886000132648, 40.670438000073517],
          [-74.014632000052032, 40.671949999755633],
          [-74.017378999761434, 40.673461000319243],
          [-74.018427000163058, 40.674512999658653],
          [-74.019849000115215, 40.676498000230865],
          [-74.02002800055493, 40.67701600035565],
          [-74.019398999517378, 40.679572999641451],
          [-74.019347000130992, 40.679547999773412],
          [-74.01921100011613, 40.67965600004554],
          [-74.019076999906702, 40.67976300008165],
          [-74.018072000385644, 40.680210000369954],
          [-74.017206999882205, 40.680597000429756],
          [-74.015973999844903, 40.6806599999057],
          [-74.015475999473125, 40.681913999713124],
          [-74.013047000214726, 40.683348000003875],
          [-74.012741999734075, 40.683544999572007],
          [-74.012247000165274, 40.68386499956226],
          [-74.011347000266269, 40.684447000314599],
          [-74.011067999539847, 40.684594999948573],
          [-74.009363000429985, 40.685496999586874],
          [-74.008545999474876, 40.685930000215237],
          [-74.008521000501247, 40.685992000198709],
          [-74.008384999524552, 40.686327999580108],
          [-74.008363000192162, 40.68635200030505],
          [-74.008117000161192, 40.686614999740549],
          [-74.007734999763144, 40.686037000124934],
          [-74.005497000083324, 40.685847000212824]
        ]
      ]
    }
  },
  {
    type: 'Feature',
    properties: {
      OBJECTID: 40,
      COMMITTEE: 'Rockaway East',
      Shape_Leng: 28236.420032099999,
      Shape_Area: 11289035.139
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-73.763454000455212, 40.61368999973115],
          [-73.762083999739488, 40.612732000226273],
          [-73.76002100034934, 40.611348999694485],
          [-73.759630999792051, 40.611296999634341],
          [-73.759334000476784, 40.611258000390187],
          [-73.757546000323302, 40.611037000171216],
          [-73.757234999840804, 40.610993000115727],
          [-73.755196999994894, 40.610593999559761],
          [-73.755186000397202, 40.610302000431645],
          [-73.755172999925222, 40.609984000047199],
          [-73.753458000592275, 40.610519999573057],
          [-73.75065200000995, 40.611453000133125],
          [-73.75008799979301, 40.611640999999075],
          [-73.749120000304117, 40.612036000250299],
          [-73.748298999717733, 40.612218000219237],
          [-73.747992999412119, 40.612310000376262],
          [-73.747614000134234, 40.611997999614772],
          [-73.747451000384714, 40.612071999696767],
          [-73.747215999622668, 40.612179000404367],
          [-73.746942000201003, 40.611775000147254],
          [-73.746854999891184, 40.611574000427609],
          [-73.745761000299609, 40.611992000146991],
          [-73.745663000132126, 40.61181199963859],
          [-73.745632999955603, 40.611756000267505],
          [-73.745368000379571, 40.611204000384795],
          [-73.744567000106784, 40.610116999573776],
          [-73.743870000345851, 40.608893000405651],
          [-73.743546999859973, 40.608423000416487],
          [-73.743379999731147, 40.608179000177472],
          [-73.743206000421182, 40.608013000343497],
          [-73.743069999962159, 40.607886000015284],
          [-73.743355000459772, 40.607499000340738],
          [-73.743147000321528, 40.60726000014143],
          [-73.741148000298324, 40.605459999558157],
          [-73.740570999766618, 40.604880000000989],
          [-73.740290999609357, 40.604597999983625],
          [-73.739225000519085, 40.60414200036891],
          [-73.738151000443324, 40.602710000411776],
          [-73.737996999808587, 40.601600999852728],
          [-73.73829500034104, 40.597990999602018],
          [-73.738300999829661, 40.597795999841246],
          [-73.738392000296599, 40.597054000071154],
          [-73.738329999851999, 40.596827999578615],
          [-73.738097000456477, 40.595982000415212],
          [-73.73814400011554, 40.594651000402258],
          [-73.738150000017342, 40.594538999783012],
          [-73.738389000227684, 40.594638000335159],
          [-73.738560000463764, 40.594708999673109],
          [-73.739406999445038, 40.594856999900585],
          [-73.740172999995892, 40.595048999926448],
          [-73.740905999983752, 40.594782999698431],
          [-73.742620000182924, 40.594850000167433],
          [-73.743727999961365, 40.594621000140307],
          [-73.745429999440219, 40.595086000335684],
          [-73.747130000132884, 40.594147999942393],
          [-73.748114000030029, 40.593411000015905],
          [-73.750901000107149, 40.592510999663986],
          [-73.751112999555843, 40.592361000146859],
          [-73.752513000464887, 40.591373999937112],
          [-73.752594000121832, 40.591317000294431],
          [-73.753559000146126, 40.590902999956441],
          [-73.754075999982632, 40.590947999756061],
          [-73.755269000213147, 40.59089500000249],
          [-73.756366999862465, 40.590846999828877],
          [-73.757608000574621, 40.590853999625082],
          [-73.760035000172437, 40.590843999625676],
          [-73.76357599942078, 40.59081700017007],
          [-73.766107000286837, 40.590574000326527],
          [-73.769804000182461, 40.590920999975026],
          [-73.770197000256388, 40.590913000358739],
          [-73.773254999488358, 40.590851000227786],
          [-73.774659000010615, 40.59079599976441],
          [-73.776059999969561, 40.590570000366931],
          [-73.782371000376614, 40.589260000295639],
          [-73.784061999870588, 40.58900999963388],
          [-73.787185000143779, 40.588419999671643],
          [-73.788770999771089, 40.587726000086661],
          [-73.788837999843352, 40.587715999613899],
          [-73.791257000214699, 40.587366999613288],
          [-73.792444000264254, 40.587149000111161],
          [-73.79539100056931, 40.586796000447443],
          [-73.795689000048597, 40.586749999633021],
          [-73.800450999641072, 40.586007999962497],
          [-73.801473000576252, 40.5858489997417],
          [-73.80450499999526, 40.585434999627175],
          [-73.806365000158806, 40.585181000136849],
          [-73.807021000429771, 40.584990999581414],
          [-73.809581000441199, 40.584251000204247],
          [-73.80998499984014, 40.584146999944195],
          [-73.811110000416207, 40.583857000418902],
          [-73.812159000029197, 40.583433000075047],
          [-73.815506000253492, 40.582508000112632],
          [-73.81583699973973, 40.583276000219215],
          [-73.815910999931347, 40.583447999597581],
          [-73.816113999805509, 40.583645000325532],
          [-73.817229000253022, 40.585686999702091],
          [-73.817744000037123, 40.586467000224125],
          [-73.818045999718478, 40.58704500028324],
          [-73.818131999899862, 40.587218000206036],
          [-73.818261999945804, 40.587353999758932],
          [-73.818383999596577, 40.587414000149494],
          [-73.81848499984747, 40.5874429996502],
          [-73.818834999885595, 40.587616000400288],
          [-73.818952999913378, 40.587751999871529],
          [-73.819048000019862, 40.587939000391607],
          [-73.819092999833828, 40.588138999793443],
          [-73.819068000472654, 40.588158000040352],
          [-73.818773000141462, 40.588324999735882],
          [-73.818657000448169, 40.58837899992254],
          [-73.8179819996422, 40.588696000286546],
          [-73.817254999409627, 40.589048000343361],
          [-73.815869999700297, 40.5898130001762],
          [-73.813522999589807, 40.591062000133817],
          [-73.813212999651213, 40.591459999594107],
          [-73.812523999703799, 40.591668000201729],
          [-73.811597000368579, 40.591947000347034],
          [-73.811132999679089, 40.591261999984546],
          [-73.810315000413738, 40.59234400032782],
          [-73.810030999731921, 40.59218500010001],
          [-73.810736000527939, 40.593411000302936],
          [-73.810605000451972, 40.593406000133221],
          [-73.810555000138791, 40.59347200001659],
          [-73.809807000527783, 40.592263999790831],
          [-73.809821999621178, 40.59306199971639],
          [-73.810227000059911, 40.593748999938853],
          [-73.809818000232099, 40.594069999894167],
          [-73.806471999698786, 40.592504999712943],
          [-73.80627500025065, 40.592412999636629],
          [-73.805317000334426, 40.591768000447743],
          [-73.804431000174972, 40.591570999948409],
          [-73.804429999420378, 40.591867999565601],
          [-73.80882100013288, 40.593951999613857],
          [-73.806817000402191, 40.596422000247102],
          [-73.804428999948229, 40.595060999813555],
          [-73.804012999884762, 40.593412999865933],
          [-73.802696000482484, 40.592784999595992],
          [-73.801150999724811, 40.593277000216304],
          [-73.801472999668192, 40.593959000038616],
          [-73.802410000141464, 40.594581999772849],
          [-73.803507999639052, 40.594734000163065],
          [-73.802628999495724, 40.595260000420474],
          [-73.804546999548606, 40.595869999761561],
          [-73.804622000172273, 40.596056000313105],
          [-73.804919999758425, 40.596600000094377],
          [-73.804867000488599, 40.59667700010943],
          [-73.804640000214548, 40.597216999875535],
          [-73.804908999573698, 40.598443000009695],
          [-73.803699999875562, 40.599766999773671],
          [-73.801174999899175, 40.59962599954919],
          [-73.800992000084051, 40.599328000150159],
          [-73.798785999747793, 40.599219000009398],
          [-73.797732000294104, 40.599535000092075],
          [-73.79770200025844, 40.599543999933154],
          [-73.796779000321123, 40.599647999852181],
          [-73.793652000093786, 40.602622999670366],
          [-73.789057999974148, 40.605621000405158],
          [-73.787224999566803, 40.604519000028127],
          [-73.786085999503769, 40.60328099997254],
          [-73.787347000460116, 40.602442000224016],
          [-73.787952999935015, 40.601209999566038],
          [-73.790172000272108, 40.599589999855468],
          [-73.7905470003696, 40.598710999941794],
          [-73.791154000002777, 40.59729000038687],
          [-73.791251999819579, 40.59662399994302],
          [-73.791328000252605, 40.596116999920213],
          [-73.791268000265603, 40.595358999733421],
          [-73.791039999623663, 40.595152000032854],
          [-73.79063799944565, 40.594787000182258],
          [-73.790212999565398, 40.594758999843116],
          [-73.789559000409824, 40.594955000390073],
          [-73.789567999902616, 40.595255999570028],
          [-73.789603999984664, 40.596485000314388],
          [-73.789629999611847, 40.596535999991225],
          [-73.789870000274988, 40.597011000361157],
          [-73.789471000122361, 40.597611000393172],
          [-73.788498999886002, 40.598875999997333],
          [-73.785881999643507, 40.601591000401768],
          [-73.78519400015206, 40.602422000179786],
          [-73.784750999829953, 40.603061000192838],
          [-73.783690999480228, 40.603994999855466],
          [-73.784650999823228, 40.60520499958406],
          [-73.781604000424196, 40.607500999639065],
          [-73.780743000325884, 40.60965700004158],
          [-73.778485000446523, 40.610233999579151],
          [-73.776544999711632, 40.610102999674105],
          [-73.774903999851063, 40.610026999803694],
          [-73.773678999884993, 40.608927999770557],
          [-73.773162000233725, 40.607198999651793],
          [-73.774485000256504, 40.605807999702854],
          [-73.775818999522414, 40.602522999844354],
          [-73.776380999752305, 40.602048000359368],
          [-73.778232999987324, 40.600489999569028],
          [-73.780352000405415, 40.600348000027566],
          [-73.78179000015615, 40.599187000239993],
          [-73.780422000309784, 40.597764999580463],
          [-73.779531000083566, 40.597775999759051],
          [-73.778316999889952, 40.598382000400221],
          [-73.776927999803547, 40.60019999986902],
          [-73.776310999590564, 40.601116000379172],
          [-73.775929999471728, 40.60168300002838],
          [-73.775440999958548, 40.601535000027468],
          [-73.775119000396259, 40.601739999804686],
          [-73.774558000248661, 40.600389999741871],
          [-73.774036000194457, 40.599021999973559],
          [-73.77338799968841, 40.598221999726739],
          [-73.77264699982716, 40.597950000289401],
          [-73.772537000381902, 40.597865000400354],
          [-73.772448000574769, 40.597796000283033],
          [-73.771352000353787, 40.598297999600071],
          [-73.770077000090026, 40.597898999639376],
          [-73.769078000114277, 40.59793100022749],
          [-73.767987000251978, 40.597595999870322],
          [-73.768416000292447, 40.598451000205522],
          [-73.769138000252568, 40.598949999923583],
          [-73.769931000379586, 40.598874999891002],
          [-73.769991999536785, 40.599902000256463],
          [-73.770016000470036, 40.60029500044373],
          [-73.770157000367817, 40.602657000261758],
          [-73.770338999609521, 40.603402000400486],
          [-73.770690000496117, 40.60484299983267],
          [-73.770199999581578, 40.605610999997062],
          [-73.769919999641928, 40.606596000172445],
          [-73.769747999542176, 40.607199999980281],
          [-73.769897000163326, 40.608094000294507],
          [-73.769122000002966, 40.609397999954034],
          [-73.770020999976239, 40.609984999866874],
          [-73.771482999749438, 40.610573999792962],
          [-73.771624000585547, 40.610620000008048],
          [-73.771698000013615, 40.610645000225681],
          [-73.771848999582531, 40.611379000427469],
          [-73.773332000181341, 40.611327000322426],
          [-73.773894000488056, 40.613003000017095],
          [-73.773620999911486, 40.613276999835556],
          [-73.772918000003799, 40.613985999873634],
          [-73.771085000466016, 40.613888999817362],
          [-73.767020999937287, 40.614218000041824],
          [-73.764957000242134, 40.61363300027265],
          [-73.764955999501069, 40.613531999548009],
          [-73.763454000455212, 40.61368999973115]
        ]
      ]
    }
  },
  {
    type: 'Feature',
    properties: {
      OBJECTID: 48,
      COMMITTEE: 'Southeast Brooklyn Waterfront',
      Shape_Leng: 27049.2367295,
      Shape_Area: 20433921.551199999
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-73.892306000405995, 40.620556000191556],
          [-73.887051000028407, 40.616319999926596],
          [-73.884455999862652, 40.609439999898981],
          [-73.891952999783342, 40.605524999872848],
          [-73.898664999542191, 40.60460299980906],
          [-73.898777000194471, 40.603890000138911],
          [-73.886671999874082, 40.60459899955287],
          [-73.88493200040871, 40.604702000104609],
          [-73.8841659997818, 40.602921000406596],
          [-73.882072999604944, 40.598057999914523],
          [-73.878554999496927, 40.589884000238193],
          [-73.878245000528835, 40.589207999592404],
          [-73.875939999488224, 40.584186000429888],
          [-73.879283000225982, 40.580361999768151],
          [-73.88759500042454, 40.578078999842546],
          [-73.896169999546359, 40.576872000076094],
          [-73.89975500011613, 40.579337000119523],
          [-73.901772000292638, 40.580811000394242],
          [-73.905265000428102, 40.581948000229559],
          [-73.911464999565254, 40.581330999674556],
          [-73.912774999807681, 40.581254000039735],
          [-73.914757999735556, 40.581991000410717],
          [-73.915039000235026, 40.580766999968219],
          [-73.918753999472614, 40.582064000022392],
          [-73.926304000034548, 40.583573999897219],
          [-73.928300999828195, 40.583860000281909],
          [-73.929651000046235, 40.583439999670901],
          [-73.931107999920428, 40.582824000231597],
          [-73.931841999491411, 40.582920000343528],
          [-73.932042000154709, 40.584233999799807],
          [-73.931077999989455, 40.584388000322662],
          [-73.930095999964266, 40.58450199972804],
          [-73.930191000128147, 40.585086000252424],
          [-73.931255999776297, 40.585431999985488],
          [-73.931315999445388, 40.585674000325405],
          [-73.931368999466983, 40.58607099984831],
          [-73.930280000462133, 40.585743999734859],
          [-73.929427999766276, 40.585488999679619],
          [-73.928366999503481, 40.585161999635446],
          [-73.928397000019871, 40.585267999811926],
          [-73.928169000325582, 40.585167000001036],
          [-73.927207000511885, 40.584801999633278],
          [-73.926426000403495, 40.584817999799633],
          [-73.926104000143283, 40.584824999731453],
          [-73.925996000238968, 40.584776000201558],
          [-73.925824000209303, 40.584742000253016],
          [-73.925564999674478, 40.584724999972991],
          [-73.92536999975303, 40.584690999803264],
          [-73.925089999876008, 40.584592000099597],
          [-73.924700999957054, 40.584523999931832],
          [-73.924378000019715, 40.584490000027245],
          [-73.924119000313411, 40.584455999909153],
          [-73.923903000294288, 40.584455000304267],
          [-73.923644000499038, 40.584470999789687],
          [-73.923470000041718, 40.584503000236687],
          [-73.923276000501943, 40.584453000204299],
          [-73.923060999462052, 40.584387000172264],
          [-73.922974000436867, 40.584370000443037],
          [-73.922845000291986, 40.584417999996802],
          [-73.922714000161662, 40.584533999602606],
          [-73.922627999929347, 40.58459900018174],
          [-73.922540999450135, 40.584631999876386],
          [-73.922390000515747, 40.584582000392196],
          [-73.922194999877306, 40.584580999820673],
          [-73.922065999504483, 40.584547999998712],
          [-73.921743000189437, 40.584547000396029],
          [-73.921483000456107, 40.584579000358097],
          [-73.921266000472428, 40.584577999994941],
          [-73.921115000496982, 40.584561000192345],
          [-73.920749000096961, 40.584575999974604],
          [-73.920532000421616, 40.58462399983614],
          [-73.920338000242594, 40.584606999826306],
          [-73.919970000331418, 40.584720999622178],
          [-73.919840000198874, 40.584836999887884],
          [-73.919211000463122, 40.585147000261209],
          [-73.919060000131353, 40.585179000260723],
          [-73.918798999589455, 40.585375999641464],
          [-73.918582999596453, 40.585474000420163],
          [-73.918431000106281, 40.585555999891874],
          [-73.91834399983162, 40.585555999568854],
          [-73.918283000380541, 40.585591999775822],
          [-73.918188000502553, 40.585675000276261],
          [-73.918056999961564, 40.585858999840838],
          [-73.917871999451862, 40.585974999729352],
          [-73.917656000310004, 40.586138999640923],
          [-73.917708999903866, 40.586268999937857],
          [-73.9178960005449, 40.586441999920972],
          [-73.917990999540507, 40.586533999647081],
          [-73.919725000139792, 40.587986999926976],
          [-73.920960000441426, 40.589094999577235],
          [-73.922307999697068, 40.590307999989399],
          [-73.923321000581822, 40.591212000258452],
          [-73.923638000564651, 40.591496999834497],
          [-73.924055000329517, 40.591872000280411],
          [-73.924404000426904, 40.592185000083653],
          [-73.925472000410224, 40.593149000072358],
          [-73.926533000157519, 40.594104000220568],
          [-73.92760000028953, 40.595061000254958],
          [-73.928669000101991, 40.596020999792323],
          [-73.929569000299566, 40.59682000023826],
          [-73.928790999969181, 40.597322999560255],
          [-73.928131000256499, 40.597777999840524],
          [-73.929921999634431, 40.599437000386459],
          [-73.931733999592197, 40.601062000240844],
          [-73.933550999971445, 40.602686999668926],
          [-73.934263000358072, 40.602230000136636],
          [-73.935031999537046, 40.601734000024166],
          [-73.936556999462297, 40.603095000365286],
          [-73.936827000342518, 40.603343999873729],
          [-73.937737999809187, 40.604160999863467],
          [-73.938675000200107, 40.605000999952004],
          [-73.938916000305255, 40.605223000368184],
          [-73.939210000135432, 40.605479000054075],
          [-73.940030999452446, 40.606202999710817],
          [-73.940507999693324, 40.606642000043927],
          [-73.941168000433763, 40.607243999912754],
          [-73.941546999642483, 40.607581999962377],
          [-73.942312000322644, 40.60826899960049],
          [-73.943547000139858, 40.609346000103407],
          [-73.943641000089045, 40.60985899962855],
          [-73.943699000026086, 40.610214000134633],
          [-73.943800999652979, 40.61071900001356],
          [-73.944055000104939, 40.611992999871902],
          [-73.944174000066951, 40.612721999751486],
          [-73.944307999659074, 40.613410999591807],
          [-73.944573999706563, 40.614804000373312],
          [-73.944827000053664, 40.616045999967078],
          [-73.945736999530013, 40.615362999587546],
          [-73.945829000168189, 40.615847999719648],
          [-73.946067000291606, 40.617104000346643],
          [-73.9465139998551, 40.619493999858008],
          [-73.945515999668601, 40.619606000246243],
          [-73.944518000049769, 40.619714000289264],
          [-73.943591000235813, 40.619816000066557],
          [-73.942664000008548, 40.619917999592523],
          [-73.941726999871804, 40.620022999884164],
          [-73.941156000135663, 40.620170000339584],
          [-73.940804999650709, 40.620124999651246],
          [-73.941630000143817, 40.619461999599913],
          [-73.942415999458802, 40.618592999768197],
          [-73.943193999739179, 40.617677999846336],
          [-73.943470000227308, 40.61738499985735],
          [-73.943145000348039, 40.61742599975625],
          [-73.942211999432828, 40.617526000433429],
          [-73.941282000450997, 40.617630000179332],
          [-73.940789999664375, 40.617696999796308],
          [-73.940452999929562, 40.617929000386233],
          [-73.940369999432477, 40.617991999903232],
          [-73.939716000441109, 40.618405999722597],
          [-73.93949299983737, 40.6185500003741],
          [-73.939002000196609, 40.618867000022341],
          [-73.938647000480188, 40.619097000232458],
          [-73.938288000264123, 40.619329000299224],
          [-73.93781399990948, 40.619652000381834],
          [-73.93757600025296, 40.619795999847433],
          [-73.936991999863082, 40.620178999677997],
          [-73.936867000408981, 40.620252999592296],
          [-73.936336000055846, 40.62061099954694],
          [-73.93614900054115, 40.62071799992038],
          [-73.935380000562574, 40.621215999683891],
          [-73.934542999611224, 40.62175799993679],
          [-73.934006999651444, 40.622113999726572],
          [-73.933760000396134, 40.622275000132234],
          [-73.933099999531763, 40.622693999939237],
          [-73.932194999892772, 40.623268999937359],
          [-73.931249999611097, 40.623321000180631],
          [-73.930281999808017, 40.623380999971033],
          [-73.929310999993689, 40.623440999857834],
          [-73.928375999717076, 40.623498999626591],
          [-73.927369000560518, 40.623562000290192],
          [-73.927135000105864, 40.621358000026341],
          [-73.926878999797864, 40.618949999929725],
          [-73.925874999664842, 40.619013000389927],
          [-73.924943999972825, 40.619070999905759],
          [-73.923975000557746, 40.619130999898346],
          [-73.923007999574253, 40.61919300038624],
          [-73.923262000130549, 40.621599999811288],
          [-73.922329999935116, 40.621658000293962],
          [-73.921358999472659, 40.621718000033361],
          [-73.921102000478527, 40.619309000310047],
          [-73.92013399971917, 40.619369999842803],
          [-73.919200999789979, 40.619426000160921],
          [-73.918266000449819, 40.619485000188867],
          [-73.917265000530961, 40.619547999758844],
          [-73.917412999828585, 40.620958000352282],
          [-73.917469999850326, 40.621513000433112],
          [-73.917514999755639, 40.621941000258225],
          [-73.917708999651495, 40.623717000244937],
          [-73.917694000303271, 40.624102999847842],
          [-73.917995000526346, 40.626457999952841],
          [-73.918161999759405, 40.628005000112978],
          [-73.918241999728124, 40.628770000020168],
          [-73.918604000573055, 40.632096000147918],
          [-73.91692200020735, 40.63166899966599],
          [-73.908480000130609, 40.627979000200945],
          [-73.906513999670835, 40.627131000153511],
          [-73.904251999602252, 40.626032000168273],
          [-73.901779999898224, 40.624815999690355],
          [-73.898260999677149, 40.622989000231257],
          [-73.896680000343736, 40.622306000311752],
          [-73.892306000405995, 40.620556000191556]
        ]
      ]
    }
  },
  {
    type: 'Feature',
    properties: {
      OBJECTID: 23,
      COMMITTEE: 'Gerritsen Beach and Sheepshead Bay',
      Shape_Leng: 15342.489283299999,
      Shape_Area: 9477669.7621400002,
      Latitude: 40.595499032200003,
      Longitude: -73.946919621700005
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-73.931841999491411, 40.582920000343528],
          [-73.931946000251983, 40.582021999763548],
          [-73.940438999667123, 40.581879000387616],
          [-73.940947000232299, 40.581912999568864],
          [-73.944308000313484, 40.58220100030038],
          [-73.949409000004408, 40.582435000409731],
          [-73.951081999709302, 40.582514000027913],
          [-73.953419000068621, 40.582608999673639],
          [-73.953813000257142, 40.582542999619399],
          [-73.954031999622032, 40.582517000292938],
          [-73.954086000289479, 40.582795000127604],
          [-73.954143000433817, 40.583104999714287],
          [-73.954364999981138, 40.583080999826144],
          [-73.955173999748183, 40.582916000299029],
          [-73.956041000203442, 40.582736000103246],
          [-73.95628799949516, 40.582696999626123],
          [-73.956536000287642, 40.582659000421252],
          [-73.956763000248273, 40.582829000241361],
          [-73.957353000342579, 40.583023000446261],
          [-73.959007000023021, 40.58283799967645],
          [-73.960030999627392, 40.583283999854686],
          [-73.960087000435607, 40.583485999863875],
          [-73.960235000267687, 40.584522000231019],
          [-73.960230999967422, 40.584675999674431],
          [-73.960256999954069, 40.584839000365044],
          [-73.961617999767114, 40.584883000176191],
          [-73.961941999601734, 40.584763000130586],
          [-73.962551999438631, 40.584782999951308],
          [-73.963442000361866, 40.584676000274769],
          [-73.966614000227864, 40.584574999877034],
          [-73.966496999902262, 40.585141999588252],
          [-73.96633000030215, 40.585896000264491],
          [-73.966150000185564, 40.586668999698851],
          [-73.965975000519634, 40.587431999752852],
          [-73.965814000023727, 40.588133000185422],
          [-73.965641000456444, 40.588887999726268],
          [-73.965474000451422, 40.589607999965537],
          [-73.965301999992121, 40.590357999825301],
          [-73.965129000480488, 40.591108000279824],
          [-73.964957000006819, 40.591862000152638],
          [-73.964794000286616, 40.592580999754674],
          [-73.964637999502585, 40.593328000287379],
          [-73.964833999671924, 40.594434000104883],
          [-73.965076000275545, 40.595715999990254],
          [-73.965477000336961, 40.597834000200955],
          [-73.965903999415318, 40.600090000236534],
          [-73.96635700027916, 40.602479999859824],
          [-73.966796000324919, 40.604802999630245],
          [-73.966959000208661, 40.605662000081601],
          [-73.967261000121383, 40.607259000378619],
          [-73.967490999943678, 40.608475999899738],
          [-73.967667999983973, 40.60940400038308],
          [-73.966298000555653, 40.609555000447699],
          [-73.96522399990188, 40.609674000387905],
          [-73.96429599946417, 40.609776999583175],
          [-73.96336999988138, 40.609879000302499],
          [-73.962379999834511, 40.609986000326302],
          [-73.962305000311673, 40.609993999963272],
          [-73.961373000167214, 40.610098999963178],
          [-73.960443999873149, 40.610201999698454],
          [-73.95951999972452, 40.61030200019016],
          [-73.958593000142187, 40.610403000418799],
          [-73.958226999637489, 40.610441000380284],
          [-73.957669000285975, 40.610504000448515],
          [-73.956704999556237, 40.610611999869839],
          [-73.955741000312685, 40.61071499956865],
          [-73.954795999687434, 40.610820000008871],
          [-73.953598999427399, 40.610950000392144],
          [-73.952577000311521, 40.611037000128967],
          [-73.951633000111656, 40.611122999862502],
          [-73.950705999985573, 40.611250000393859],
          [-73.949784000460568, 40.611372000424602],
          [-73.949962999943835, 40.612316000153463],
          [-73.949992999597328, 40.612479999936333],
          [-73.950012000195159, 40.61258099977632],
          [-73.95026500011528, 40.613923999963454],
          [-73.949305999550774, 40.614030999953172],
          [-73.949152000242762, 40.613228000034816],
          [-73.949118999526988, 40.613053000263349],
          [-73.948819999454997, 40.611476999803429],
          [-73.94786200019044, 40.611582999811858],
          [-73.946933000007135, 40.611683999764431],
          [-73.946001000092394, 40.611785999943216],
          [-73.946573999924013, 40.614789000429973],
          [-73.94638299999869, 40.614918999894535],
          [-73.945736999530013, 40.615362999587546],
          [-73.944827000053664, 40.616045999967078],
          [-73.944573999706563, 40.614804000373312],
          [-73.944307999659074, 40.613410999591807],
          [-73.944174000066951, 40.612721999751486],
          [-73.944055000104939, 40.611992999871902],
          [-73.943800999652979, 40.61071900001356],
          [-73.943699000026086, 40.610214000134633],
          [-73.943641000089045, 40.60985899962855],
          [-73.943547000139858, 40.609346000103407],
          [-73.942312000322644, 40.60826899960049],
          [-73.941546999642483, 40.607581999962377],
          [-73.941168000433763, 40.607243999912754],
          [-73.940507999693324, 40.606642000043927],
          [-73.940030999452446, 40.606202999710817],
          [-73.939210000135432, 40.605479000054075],
          [-73.938916000305255, 40.605223000368184],
          [-73.938675000200107, 40.605000999952004],
          [-73.937737999809187, 40.604160999863467],
          [-73.936827000342518, 40.603343999873729],
          [-73.936556999462297, 40.603095000365286],
          [-73.935031999537046, 40.601734000024166],
          [-73.934263000358072, 40.602230000136636],
          [-73.933550999971445, 40.602686999668926],
          [-73.931733999592197, 40.601062000240844],
          [-73.929921999634431, 40.599437000386459],
          [-73.928131000256499, 40.597777999840524],
          [-73.928790999969181, 40.597322999560255],
          [-73.929569000299566, 40.59682000023826],
          [-73.928669000101991, 40.596020999792323],
          [-73.92760000028953, 40.595061000254958],
          [-73.926533000157519, 40.594104000220568],
          [-73.925472000410224, 40.593149000072358],
          [-73.924404000426904, 40.592185000083653],
          [-73.924055000329517, 40.591872000280411],
          [-73.923638000564651, 40.591496999834497],
          [-73.923321000581822, 40.591212000258452],
          [-73.922307999697068, 40.590307999989399],
          [-73.920960000441426, 40.589094999577235],
          [-73.919725000139792, 40.587986999926976],
          [-73.917990999540507, 40.586533999647081],
          [-73.9178960005449, 40.586441999920972],
          [-73.917708999903866, 40.586268999937857],
          [-73.917656000310004, 40.586138999640923],
          [-73.917871999451862, 40.585974999729352],
          [-73.918056999961564, 40.585858999840838],
          [-73.918188000502553, 40.585675000276261],
          [-73.918283000380541, 40.585591999775822],
          [-73.91834399983162, 40.585555999568854],
          [-73.918431000106281, 40.585555999891874],
          [-73.918582999596453, 40.585474000420163],
          [-73.918798999589455, 40.585375999641464],
          [-73.919060000131353, 40.585179000260723],
          [-73.919211000463122, 40.585147000261209],
          [-73.919840000198874, 40.584836999887884],
          [-73.919970000331418, 40.584720999622178],
          [-73.920338000242594, 40.584606999826306],
          [-73.920532000421616, 40.58462399983614],
          [-73.920749000096961, 40.584575999974604],
          [-73.921115000496982, 40.584561000192345],
          [-73.921266000472428, 40.584577999994941],
          [-73.921483000456107, 40.584579000358097],
          [-73.921743000189437, 40.584547000396029],
          [-73.922065999504483, 40.584547999998712],
          [-73.922194999877306, 40.584580999820673],
          [-73.922390000515747, 40.584582000392196],
          [-73.922540999450135, 40.584631999876386],
          [-73.922627999929347, 40.58459900018174],
          [-73.922714000161662, 40.584533999602606],
          [-73.922845000291986, 40.584417999996802],
          [-73.922974000436867, 40.584370000443037],
          [-73.923060999462052, 40.584387000172264],
          [-73.923276000501943, 40.584453000204299],
          [-73.923470000041718, 40.584503000236687],
          [-73.923644000499038, 40.584470999789687],
          [-73.923903000294288, 40.584455000304267],
          [-73.924119000313411, 40.584455999909153],
          [-73.924378000019715, 40.584490000027245],
          [-73.924700999957054, 40.584523999931832],
          [-73.925089999876008, 40.584592000099597],
          [-73.92536999975303, 40.584690999803264],
          [-73.925564999674478, 40.584724999972991],
          [-73.925824000209303, 40.584742000253016],
          [-73.925996000238968, 40.584776000201558],
          [-73.926104000143283, 40.584824999731453],
          [-73.926426000403495, 40.584817999799633],
          [-73.927207000511885, 40.584801999633278],
          [-73.928169000325582, 40.585167000001036],
          [-73.928397000019871, 40.585267999811926],
          [-73.928366999503481, 40.585161999635446],
          [-73.929427999766276, 40.585488999679619],
          [-73.930280000462133, 40.585743999734859],
          [-73.931368999466983, 40.58607099984831],
          [-73.931315999445388, 40.585674000325405],
          [-73.931255999776297, 40.585431999985488],
          [-73.930191000128147, 40.585086000252424],
          [-73.930095999964266, 40.58450199972804],
          [-73.931077999989455, 40.584388000322662],
          [-73.932042000154709, 40.584233999799807],
          [-73.931841999491411, 40.582920000343528]
        ]
      ]
    }
  },
  {
    type: 'Feature',
    properties: {
      OBJECTID: 49,
      COMMITTEE: 'Southern Brooklyn Peninsula',
      Shape_Leng: 18081.956254799999,
      Shape_Area: 7158038.9388300003,
      Latitude: 40.577762216099998,
      Longitude: -73.974589826
    },
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [-73.931946000251983, 40.582021999763548],
          [-73.932451999658753, 40.581125999817871],
          [-73.931384999864207, 40.577127000339544],
          [-73.931116000353526, 40.57611700023142],
          [-73.931525000423747, 40.57568400033508],
          [-73.934946999898656, 40.575271999980963],
          [-73.934965999808782, 40.575483000366326],
          [-73.935049999489721, 40.575517999644994],
          [-73.935351999522325, 40.57558500000939],
          [-73.935763000199159, 40.575715999673321],
          [-73.936246999975111, 40.575825999832162],
          [-73.936463000142609, 40.575836000231746],
          [-73.936561999693126, 40.575818999793967],
          [-73.936603999487872, 40.575771999997748],
          [-73.936621999924313, 40.575723000039545],
          [-73.93669000016591, 40.57560599993279],
          [-73.936690999416996, 40.575446999941427],
          [-73.937664999972739, 40.57543399966503],
          [-73.938476999646312, 40.575423999635483],
          [-73.942508999441358, 40.575372999872918],
          [-73.942600000185422, 40.575439999790433],
          [-73.942759999446736, 40.57553500041297],
          [-73.942927000293935, 40.575611999813091],
          [-73.943104999519448, 40.575674000183277],
          [-73.943291999574214, 40.575720000353478],
          [-73.943461999420222, 40.575748000362616],
          [-73.943643000489089, 40.575762000368684],
          [-73.943855999664365, 40.575762000419012],
          [-73.943963000401567, 40.575754999740724],
          [-73.944077000460439, 40.575734000421235],
          [-73.94419799961203, 40.575692999963771],
          [-73.944291000112045, 40.575645999561168],
          [-73.944387999716298, 40.575575999571498],
          [-73.944462000228853, 40.575501000326625],
          [-73.944511000572675, 40.575426999572571],
          [-73.944548999897492, 40.575344000417758],
          [-73.944565999458931, 40.575269000051279],
          [-73.94464800007627, 40.575261000060465],
          [-73.944665999667549, 40.575274000059139],
          [-73.944745999865589, 40.575320000386043],
          [-73.944838999518623, 40.575359999819234],
          [-73.944960999914855, 40.575395000303899],
          [-73.945077999711884, 40.575411999818684],
          [-73.945196999902635, 40.575414999997875],
          [-73.945314000002554, 40.575404000342495],
          [-73.945422000308653, 40.575380999937373],
          [-73.945494999932322, 40.575374999971586],
          [-73.945587999811636, 40.575357000111083],
          [-73.945687000141206, 40.575321999737476],
          [-73.945782999986022, 40.575267999642548],
          [-73.945854999593053, 40.575206999619319],
          [-73.94590899959671, 40.575139000419824],
          [-73.946822000173157, 40.575153000298343],
          [-73.947795999831285, 40.575063000338844],
          [-73.947871000549114, 40.574860000233066],
          [-73.948412999675853, 40.574801000364744],
          [-73.949189000381338, 40.57471600006199],
          [-73.949290999450923, 40.574691000065251],
          [-73.949514000246296, 40.574634999877901],
          [-73.94981599988283, 40.574487000292535],
          [-73.950099000011917, 40.574406000366167],
          [-73.950187000031264, 40.574395999690893],
          [-73.950378999569608, 40.574374000361729],
          [-73.950595000198405, 40.574391000187113],
          [-73.950832000390335, 40.574441999885892],
          [-73.951049000275873, 40.574392999611391],
          [-73.951106999569035, 40.574393000182283],
          [-73.951265000516173, 40.574393999683231],
          [-73.951393999970875, 40.574443999609109],
          [-73.951654000358616, 40.574427999934564],
          [-73.951805000195677, 40.574329000172348],
          [-73.952085999980824, 40.574183000371384],
          [-73.95232500001778, 40.574133999984753],
          [-73.952578999894371, 40.574136999839851],
          [-73.952794000177889, 40.574172000220301],
          [-73.952989000443978, 40.57422100036905],
          [-73.953118000516113, 40.574288000444589],
          [-73.953397999459199, 40.574486999761888],
          [-73.953284999428504, 40.57429800001718],
          [-73.953269000442077, 40.57425599985114],
          [-73.953526999806328, 40.574297000010077],
          [-73.953743000022371, 40.57429699998756],
          [-73.953806999536098, 40.574362999727981],
          [-73.953848000366364, 40.574291999869963],
          [-73.953933999678171, 40.574243000397111],
          [-73.954022000543233, 40.574230999581623],
          [-73.954064000571833, 40.57428099965874],
          [-73.954086000298872, 40.574395999967678],
          [-73.954466999638996, 40.57450400021547],
          [-73.955223999512043, 40.574506000284103],
          [-73.95572099997031, 40.574441999776752],
          [-73.956022999852422, 40.574393000028579],
          [-73.956078000211946, 40.574242000060664],
          [-73.956035999935338, 40.574076999595086],
          [-73.95607999973582, 40.574027999991969],
          [-73.956161000394587, 40.574065000017271],
          [-73.956226000273148, 40.574163999929453],
          [-73.956290000195494, 40.57424700037474],
          [-73.956462000072221, 40.574285999820454],
          [-73.957519999475892, 40.574306000432991],
          [-73.95849199990262, 40.574176999867028],
          [-73.958555000080253, 40.573937000262106],
          [-73.958535000380778, 40.573788000246957],
          [-73.958557000252924, 40.573688999579538],
          [-73.958643999610317, 40.573655999597513],
          [-73.958729999664342, 40.573954999835671],
          [-73.95883800033512, 40.574005000361353],
          [-73.959028000512603, 40.574059000025471],
          [-73.959221999517922, 40.574109000422673],
          [-73.959395000418425, 40.574076999955317],
          [-73.959854000181281, 40.574011999672273],
          [-73.960372000158728, 40.574013000039287],
          [-73.960652999698567, 40.573932000329741],
          [-73.960717999568402, 40.573882999618313],
          [-73.960805000033716, 40.57391600007621],
          [-73.960912000525923, 40.573932999900556],
          [-73.962127999492068, 40.573867999783467],
          [-73.963120999670195, 40.573804999599233],
          [-73.964094000158326, 40.573775000380436],
          [-73.96523900037316, 40.573630000328755],
          [-73.965886999841715, 40.573533999884326],
          [-73.966666000391157, 40.573503999626368],
          [-73.967053999860354, 40.573439000352174],
          [-73.967083000073956, 40.573277999822778],
          [-73.967083000568692, 40.573196000127716],
          [-73.967170000167869, 40.573146999696782],
          [-73.967277000061941, 40.573221999606261],
          [-73.967363000272485, 40.573304999676175],
          [-73.967406000010527, 40.5733370000316],
          [-73.967557000080006, 40.573338000233484],
          [-73.968313999760653, 40.573224999733768],
          [-73.968810999551735, 40.573110999576365],
          [-73.96898499954402, 40.573028999961593],
          [-73.968954999874498, 40.572880999744058],
          [-73.968891000226591, 40.572699999916516],
          [-73.968891000529638, 40.572568000224102],
          [-73.968956000149774, 40.572519000243332],
          [-73.969031000205817, 40.572595000248818],
          [-73.969094999826766, 40.572744000267967],
          [-73.969181000049076, 40.572893000193439],
          [-73.969310000292381, 40.573008000311276],
          [-73.969459999746221, 40.573057999919214],
          [-73.969589999796369, 40.573074999606533],
          [-73.970182999655336, 40.573015000164531],
          [-73.970551000535451, 40.572933999684736],
          [-73.970746000007381, 40.572852999838034],
          [-73.970853000190445, 40.572771000360916],
          [-73.970814000150853, 40.572591999920085],
          [-73.97075000042642, 40.572443999899626],
          [-73.970728999919217, 40.572310999579862],
          [-73.970837999968609, 40.572246000193061],
          [-73.970861999649316, 40.57229599959814],
          [-73.970926000117686, 40.572492999589926],
          [-73.971097999703133, 40.57265899967198],
          [-73.971226999583607, 40.572725000340434],
          [-73.971545999563745, 40.572741999922975],
          [-73.971804999970502, 40.572725999985337],
          [-73.97249699948209, 40.572646000299862],
          [-73.97292899976658, 40.572548999928657],
          [-73.973144999462576, 40.572484000178704],
          [-73.973231999598042, 40.572418000281694],
          [-73.973225999682413, 40.572242000160479],
          [-73.973161999837146, 40.57196199991273],
          [-73.973163000558344, 40.571829000096876],
          [-73.973271000441557, 40.571814000315499],
          [-73.973387000333076, 40.571858000037416],
          [-73.973409000570683, 40.571957000103573],
          [-73.973428999611514, 40.572103999563808],
          [-73.97347100016826, 40.5722529998814],
          [-73.973558000287312, 40.57231900036863],
          [-73.97374800004151, 40.572305999891405],
          [-73.974007000566431, 40.57227500005402],
          [-73.974417999701899, 40.57221000008721],
          [-73.975001999634941, 40.571981000230316],
          [-73.975261000180978, 40.571816999657834],
          [-73.975328000126794, 40.571719000259762],
          [-73.975314000363994, 40.571589999624869],
          [-73.975336000557277, 40.571523999862833],
          [-73.975379000384748, 40.571507999894159],
          [-73.975464999794752, 40.571507999657719],
          [-73.975509000045548, 40.571524000157254],
          [-73.975516999484654, 40.571694000132617],
          [-73.975603000218925, 40.571859000178371],
          [-73.975687999583897, 40.571926000209501],
          [-73.975925999646591, 40.571959000317996],
          [-73.976207000125811, 40.57192699962679],
          [-73.976877999906463, 40.571780999783051],
          [-73.977244999425679, 40.571617999904639],
          [-73.977439999970997, 40.571519000162603],
          [-73.977441000008028, 40.571320999890432],
          [-73.977681000081176, 40.571294000017325],
          [-73.977854000317677, 40.57129500037118],
          [-73.977984000153072, 40.571344000273896],
          [-73.977903999927889, 40.571384999950681],
          [-73.977797000153032, 40.571401000273859],
          [-73.977666999869342, 40.571400000240864],
          [-73.977686999938655, 40.57156500017345],
          [-73.977751999604493, 40.571680000144525],
          [-73.978127000370876, 40.571754000314073],
          [-73.978494000473958, 40.571804999608013],
          [-73.978710000260861, 40.571821999992927],
          [-73.978904000047848, 40.571822999762894],
          [-73.979055000186463, 40.571806999943092],
          [-73.979275999740764, 40.571692000436435],
          [-73.979385000526094, 40.571594000437585],
          [-73.979428999901543, 40.571512000238663],
          [-73.97938499999546, 40.571446000316399],
          [-73.979319999488865, 40.571428999545873],
          [-73.979211999519549, 40.571380000070398],
          [-73.979254000328467, 40.571351000200671],
          [-73.979340999784185, 40.571334000215103],
          [-73.97946999961691, 40.57135099976378],
          [-73.979643000447922, 40.571417999591617],
          [-73.97990100021056, 40.571567000072022],
          [-73.979987000271379, 40.571584000371239],
          [-73.980246000115429, 40.571601000113944],
          [-73.980635000124636, 40.571602000325008],
          [-73.981045999779795, 40.57156999987879],
          [-73.981262000539161, 40.571506000188528],
          [-73.981306000163443, 40.571455999929427],
          [-73.981240999633044, 40.57142299990759],
          [-73.981220000332087, 40.571373000262327],
          [-73.981289000544209, 40.571342000012436],
          [-73.981462000495085, 40.57130999973765],
          [-73.981677999477199, 40.571326999967972],
          [-73.981849999758197, 40.571359999696838],
          [-73.982064999870502, 40.571460000058224],
          [-73.982239000049901, 40.571478000249719],
          [-73.982498000078635, 40.571495000349422],
          [-73.982820999711436, 40.571462000384344],
          [-73.983080999455026, 40.571430999776645],
          [-73.983253999536487, 40.571381000159008],
          [-73.983424000585572, 40.571382000003801],
          [-73.983606999540072, 40.571451999808737],
          [-73.983714999850861, 40.571436000198368],
          [-73.983780000587018, 40.571386999943208],
          [-73.983822999851256, 40.571321000394526],
          [-73.983909999554356, 40.571304999632204],
          [-73.984083000379954, 40.571403999795415],
          [-73.984211999874603, 40.571454000084131],
          [-73.984579000460954, 40.571503999911258],
          [-73.984988999552428, 40.571505999745874],
          [-73.985378000295682, 40.57147399956861],
          [-73.985614999716987, 40.571424999962396],
          [-73.985789000052677, 40.571294000083817],
          [-73.985854000024517, 40.57121199981534],
          [-73.98596199955216, 40.571096999791315],
          [-73.986268999621259, 40.571246000033952],
          [-73.986571000105855, 40.571329000273131],
          [-73.987261999639699, 40.571330000351857],
          [-73.987607999760044, 40.571282999948032],
          [-73.987868000467714, 40.571151000231154],
          [-73.98803999951248, 40.571101999936012],
          [-73.988011000001521, 40.570859000378341],
          [-73.988053999746668, 40.570810000441504],
          [-73.988131999457508, 40.570827000267265],
          [-73.988239999474317, 40.571009000254143],
          [-73.988346999947865, 40.571141000351481],
          [-73.988454999809548, 40.571207999679622],
          [-73.988812000342406, 40.571257000325403],
          [-73.989178000572167, 40.571258000136098],
          [-73.989502999501212, 40.571161000312813],
          [-73.989718999442957, 40.571078999809401],
          [-73.989827999655233, 40.571029999875243],
          [-73.990060999624177, 40.570987000081878],
          [-73.990341999997156, 40.570856999925724],
          [-73.990731999859278, 40.570594000321201],
          [-73.990667000213804, 40.570421999652403],
          [-73.990667000452007, 40.570323000416579],
          [-73.990711000320857, 40.570289999549971],
          [-73.99089800000975, 40.570771000284651],
          [-73.991318999453114, 40.570900999875953],
          [-73.99164300004, 40.57090299984047],
          [-73.991901000254202, 40.570854000364633],
          [-73.992356000443223, 40.570739999653092],
          [-73.992636999589948, 40.570624999660836],
          [-73.992746000336865, 40.570542999918572],
          [-73.992681000028568, 40.570366999598363],
          [-73.992660000434256, 40.570267000136312],
          [-73.992703999515683, 40.570201999615861],
          [-73.992808000393609, 40.570312999926401],
          [-73.992872000035163, 40.570527000035966],
          [-73.992861000051903, 40.570626999733172],
          [-73.993099000046072, 40.570742000341397],
          [-73.99340100035009, 40.570760000330559],
          [-73.9939840001107, 40.570762000009466],
          [-73.994330000191368, 40.570713000226235],
          [-73.994741000497243, 40.570583000361651],
          [-73.994912999436849, 40.570550000056869],
          [-73.994902000249851, 40.570336000086073],
          [-73.994860000063497, 40.570170999816021],
          [-73.994902999479891, 40.570104999695452],
          [-73.994982999936326, 40.570190000034721],
          [-73.995025999882515, 40.570354999949735],
          [-73.995046999515381, 40.570470000271527],
          [-73.995331000563596, 40.570605000308134],
          [-73.995740999560738, 40.570705999947634],
          [-73.996086999975404, 40.570672999746321],
          [-73.996540999577391, 40.57057600025535],
          [-73.996821999950399, 40.570527000382789],
          [-73.996952000286342, 40.57049499981386],
          [-73.996971000585177, 40.57030400026094],
          [-73.996950000208713, 40.570205999679594],
          [-73.996991999481892, 40.570157000311767],
          [-73.997052000280974, 40.570224000059021],
          [-73.997072999976339, 40.570356000337682],
          [-73.997116000507916, 40.570487999736365],
          [-73.997295000364716, 40.570604000139269],
          [-73.997488999787237, 40.570704000371819],
          [-73.997791000242856, 40.570755000351951],
          [-73.998221999925178, 40.570771999742817],
          [-73.998546000383854, 40.570805999947908],
          [-73.998761999740765, 40.570807000323391],
          [-73.998956999970332, 40.570773999671104],
          [-73.999108000469747, 40.570724999976299],
          [-73.99923800036666, 40.570708999878228],
          [-73.999229000395474, 40.570603000349607],
          [-73.999208000390524, 40.570472000417396],
          [-73.999229999654034, 40.570389000197395],
          [-73.999272999428456, 40.570433000042598],
          [-73.999297000516506, 40.570674999597543],
          [-73.999285999484698, 40.570751999821852],
          [-73.999420000404157, 40.570853000397669],
          [-73.999742999689701, 40.570951999835493],
          [-74.000152999477393, 40.571035999744204],
          [-74.000520000340316, 40.571102999709083],
          [-74.000757000456773, 40.571120000037467],
          [-74.001102999928207, 40.571088000081097],
          [-74.001427000192649, 40.571088999657498],
          [-74.001479000152372, 40.571046000218864],
          [-74.001457999474766, 40.57093000016669],
          [-74.001393000008704, 40.570814999884021],
          [-74.001437000067583, 40.570765999976452],
          [-74.00149800024721, 40.570781999716353],
          [-74.001540999868922, 40.570814999999357],
          [-74.00158400024776, 40.570913999793554],
          [-74.001604000500777, 40.571030000185765],
          [-74.001669000531109, 40.571145000352189],
          [-74.001754000513685, 40.571260000350932],
          [-74.001903999596209, 40.571524000030308],
          [-74.001968999974679, 40.571607000232994],
          [-74.002032999610179, 40.571706000206504],
          [-74.002206000135629, 40.571756000224241],
          [-74.005205000462965, 40.572249999749651],
          [-74.006889999826626, 40.572991999567869],
          [-74.008921000065115, 40.573334000292512],
          [-74.010304999462377, 40.573623000152004],
          [-74.011406000415462, 40.573977000001925],
          [-74.012209000522759, 40.574772999882789],
          [-74.0124319996808, 40.575604000320098],
          [-74.01261599960354, 40.576636000364353],
          [-74.012930999924095, 40.577484999814544],
          [-74.012750000429463, 40.578556999830681],
          [-74.012046000533431, 40.579116000284678],
          [-74.010474999536811, 40.58043899972057],
          [-74.00660400039969, 40.580881000175346],
          [-74.006245999990384, 40.581736999626685],
          [-74.004835999941818, 40.581854999669815],
          [-74.003948000300753, 40.581365999993288],
          [-74.004062999630619, 40.582324000117751],
          [-74.004167999918849, 40.583192999907837],
          [-74.003058999784017, 40.582935000132565],
          [-73.990679000029061, 40.580059999843428],
          [-73.988221999949374, 40.58028099976476],
          [-73.989398000215402, 40.580463000229898],
          [-73.989262999798243, 40.580628000278402],
          [-73.989545000014402, 40.581932999680078],
          [-73.988530999931257, 40.582727000349763],
          [-73.988299000567679, 40.583466000391084],
          [-73.987581000323601, 40.583564999945544],
          [-73.986547999476656, 40.583663999991487],
          [-73.986881999685167, 40.58427100020166],
          [-73.987084000266762, 40.584619000253689],
          [-73.986715999612855, 40.584568000425335],
          [-73.986275999576165, 40.584479999547163],
          [-73.985860999940144, 40.584524999797544],
          [-73.985480999652481, 40.584518999793112],
          [-73.98492799944998, 40.584596000366936],
          [-73.984820999957975, 40.584168000390427],
          [-73.983876000123658, 40.583975999610239],
          [-73.982812000554418, 40.583791999625291],
          [-73.981754000002894, 40.583584000396627],
          [-73.980839000435452, 40.583313999867798],
          [-73.980690999755353, 40.583059999800213],
          [-73.977516999796038, 40.582744999639111],
          [-73.974563000385643, 40.583123999795134],
          [-73.973084999877983, 40.583430000047315],
          [-73.971745000205729, 40.58366300013973],
          [-73.970845999755127, 40.583819999837573],
          [-73.970590999744729, 40.583877999969644],
          [-73.970310000168013, 40.583928999552441],
          [-73.970045000039065, 40.583965000255503],
          [-73.969715000284225, 40.58399400010731],
          [-73.969649000253028, 40.5839959995687],
          [-73.969404000372592, 40.58400499986994],
          [-73.969087000048006, 40.584000999866504],
          [-73.969063000334444, 40.584000000104723],
          [-73.967815000448297, 40.584538999889155],
          [-73.966614000227864, 40.584574999877034],
          [-73.963442000361866, 40.584676000274769],
          [-73.962551999438631, 40.584782999951308],
          [-73.961941999601734, 40.584763000130586],
          [-73.961617999767114, 40.584883000176191],
          [-73.960256999954069, 40.584839000365044],
          [-73.960230999967422, 40.584675999674431],
          [-73.960235000267687, 40.584522000231019],
          [-73.960087000435607, 40.583485999863875],
          [-73.960030999627392, 40.583283999854686],
          [-73.959007000023021, 40.58283799967645],
          [-73.957353000342579, 40.583023000446261],
          [-73.956763000248273, 40.582829000241361],
          [-73.956536000287642, 40.582659000421252],
          [-73.95628799949516, 40.582696999626123],
          [-73.956041000203442, 40.582736000103246],
          [-73.955173999748183, 40.582916000299029],
          [-73.954364999981138, 40.583080999826144],
          [-73.954143000433817, 40.583104999714287],
          [-73.954086000289479, 40.582795000127604],
          [-73.954031999622032, 40.582517000292938],
          [-73.953813000257142, 40.582542999619399],
          [-73.953419000068621, 40.582608999673639],
          [-73.951081999709302, 40.582514000027913],
          [-73.949409000004408, 40.582435000409731],
          [-73.944308000313484, 40.58220100030038],
          [-73.940947000232299, 40.581912999568864],
          [-73.940438999667123, 40.581879000387616],
          [-73.931946000251983, 40.582021999763548]
        ]
      ]
    }
  }
];
