export default {
    meta: {
      title: '回水阀安装项目',
      url: 'https://www.floodhelpny.org/zh-CN/backwater-valve-program',
      description: '保护您的房屋和财产免受洪水侵袭。',
      imageAlt: '施工人员'
    },
    title: '回水阀安装项目',
    subTitle:
      '防止污水对您的房屋造成昂贵的损害，这可能会造成健康风险。',
    description:
      "高风险洪水区的房主需要一份标高证明，才能获得准确的洪水保险报价。如果您有资格获得免费的家庭弹性审计，我们会让一名工程师参观房屋并为您准备一份报价。",
    programClosed:
      '回水阀安装计划不再接受申请。有关回水阀的更多信息以及您可能需要的原因，',
    visitResourcePage: '访问我们的资源页'
  };
  