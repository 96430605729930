export default {
  header: 'Rapò ou an',
  copy: [
    'Sa se rapò pou tolerans lan pou',
    'ak kèk rekòmandasyon teknik sou fason pou rann kay ak finans ou pi sekirize kont inondasyon.'
  ],
  links: {
    property: 'Pwopriyete',
    rate: 'Tarif Asirans',
    resiliency: 'Estrateji Tolerans',
    documents: 'Dokiman'
  }
};
