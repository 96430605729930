import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { cubicBezier, colors } from '../styles/variables';

const blue = colors.resilientBlue;
const gray = colors.grayScale[1];

const Link = styled.a.attrs((props) => ({
  target: props.external ? '_blank' : undefined,
  rel: props.external ? 'noreferrer noopener' : undefined
}))`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: GT-Walsheim-Pro-Light, 'Helvetica Neue';
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
  transition: transform 0.1s ${cubicBezier.ease};
  border: 1px solid ${gray};
  color: ${gray};
  background-color: transparent;

  &:hover {
    background-color: transparent;
    border: 1px solid ${blue};
    color: ${blue};
  }

  &:disabled {
    cursor: not-allowed;
    background-color: transparent;
    border: 1px solid ${gray};
    color: ${gray};
  }

  &:active {
    transform: translate3d(0, 2px, 0);
  }
`;

const ShareIcon = styled.img`
  padding: 0.5rem;
`;

const NewsShareButton = ({ url, external, img }) => {
  return (
    <Link href={url} external={external} img={img}>
      <ShareIcon src={img} />
    </Link>
  );
};

NewsShareButton.propTypes = {
  url: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  external: PropTypes.bool
};

NewsShareButton.defaultProps = {
  external: false
};

export default NewsShareButton;
