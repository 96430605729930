export default {
  meta: {
    title: 'FloodHelpNY Widget',
    url: 'https://www.floodhelpny.org/es/widget-information',
    description:
      'Descarge nuestro widget de FloodHelpNY hoy y ayude a sus usuarios a conocer su riesgo de inundación.'
  },
  header: 'FloodHelpNY Widget',
  subHeader:
    'Descarge nuestro widget de FloodHelpNY hoy y ayude a sus usuarios a conocer su riesgo de inundación.',
  overview: {
    title: 'Resumen',
    subtitle: `Descarge nuestro widget de FloodHelpNY hoy y ayude a sus usuarios a conocer su riesgo de inundación. El widget de FloodHelpNY es seguro y fácil de instalar. Este brindará a los proprietarios de viviendas, inquilinos y proprietarios de pequeñas empresas una forma sencilla de aprender más sobre su riesgo de inundación. El widget de FloodHelpNY ofrece a sus usarios la oportunidad de: `
  },
  widget: {
    title: 'WIDGET',
    aside: {
      description:
        'El widget es fácil de instalar y seguro. Complete el siguiente formulario para recibir el código de inserción y las instrucciones.'
    }
  },
  maps:
    '<b>Ver los mapas de inundación actuales y de advertencia de FEMA </b> para su propiedad en la ciudad de Nueva York',
  reflection:
    '<b>Obtener un reflejo</b> del riesgo de inundación de su propiedad',
  required:
    '<b>Averiguar si están obligados a obtener un seguro contra inundaciones</b> si tienen una hipoteca respaldada por el gobierno federal',
  decisions:
    '<b>Tomar decisiones informadas</b> sobre si comprar un seguro contra inundaciones',
  request: {
    title: 'APLICAR PARA EL WIDGET',
    description:
      'Antes de descargar nuestro widget, se le pedirá que complete un formulario con su nombre, nombre de su organización y su correo electrónico. También se le pedirá aceptar nuestros términos y condiciones.'
  },
  form: {
    name: 'Nombre',
    namePlaceholder: 'Nombre completo',
    org: 'Organización',
    orgPlaceholder: 'Organización',
    email: 'Correo',
    emailPlaceholder: 'p. ej. nombre@ejemplo.com',
    terms: 'Acepto los',
    termsLink: ' términos y condiciones',
    thankYou: 'Gracias por aplicar, nos pondremos en contacto con usted pronto',
    errors: {
      nameError: 'Nombre es un campo obligatorio',
      orgError: 'Organización es un campo obligatorio',
      emailError: 'Correo es un campo obligatorio',
      termsError: 'Se requiere aceptar los términos y condiciones',
      submitError:
        'Ocurrió un error al enviar el formulario. Inténtalo de nuevo.'
    }
  }
};
