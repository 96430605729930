import backwaterValve from '../images/backwater-valve.svg';
import floodproofing from '../images/floodproofing.svg';
import passFail from '../images/pass-fail.svg';
import raiseMechanicals from '../images/raise-mechanicals.svg';
import resilientRetail from '../images/resilient-retail-cover.jpg';
import assess from '../images/assess.svg';
import floodBarrier from '../images/flood-barrier.svg';
import generator from '../images/generator.svg';
import storefronts from '../images/storefronts.png';
import waterPump from '../images/water-pump.svg';
import waterproofStorage from '../images/waterproof-storage.svg';
import protect from '../images/protect.svg';
import sbsLogo from '../images/sbs-logo.svg';
import strategy from '../images/strategy.svg';

export default {
  meta: {
    title: '小型企业的洪水风险 | FloodHelpNY',
    ogTitle: '小型企业洪水风险解释 | FloodHelpNY',
    url: 'https://www.floodhelpny.org/zh-CN/small-business',
    description:
      '洪水可能对小型企业主造成财务损失。以下是如何保护您的运营和生计。',
    imageAlt: '小型企业 | FloodHelpNY'
  },
  header: '小型企业：洪水风险解释',
  subHeader:
    '洪水可能对小型企业主造成财务损失。以下是如何保护您的运营和生计。',
  overview: {
    header: '概述',
    body:
      '洪水不仅会破坏库存或中断运营，还会让您的企业永久关闭。事实上，至少25%的受灾害影响的小型企业不会再次开业。但有了适当的计划，您可以更快地做出反应和恢复。让我们指导您采取简单的步骤，为洪水做好准备，将可能导致永久停业的损害降至最低。'
  },
  attribution: {
    logo: sbsLogo,
    logoAlt: '纽约市小型企业服务标志',
    image: storefronts,
    imageAlt: '纽约市的店面',
    credit: '此页面提供：'
  },
  whatYouCanDo: {
    header: '小型企业主可以进行的申请',
    assess: {
      header: '评估',
      icon: assess,
      iconAlt: '房屋',
      body:
        "您必须花时间评估洪水可能给企业带来的风险。了解您的洪水区，考虑对健康的所有危害和对运营的风险。"
    },
    prepare: {
      header: '准备',
      icon: passFail,
      iconAlt: '清单',
      body:
        "确保您有一个应急计划，确保运营的连续性并保护您的员工。购买或续保洪水保险也很重要。"
    },
    protect: {
      header: '保护',
      icon: protect,
      iconAlt: '防护',
      body:
        "了解您可以采取哪些措施来保护企业的结构和设备以及收入，让您能够尽快从洪水中恢复过来。"
    },
    callToAction: {
      header: '立即启动您的企业连续性计划',
      icon: strategy,
      iconAlt: '立即启动您的企业连续性计划',
      body:
        '使用此框架可以在任何中断后快速恢复。保护您的投资，与员工和供应商保持联系，并做好应对不确定性的准备。',
      buttonText: '建议'
    }
  },
  riskZoneHeader: '了解洪水风险',
  severeRiskZone: '最高风险',
  highRiskZone: '高风险',
  moderateRiskZone: '中等风险',
  warning: {
    primary: {
      body:
        '目前，纽约市有14500家企业在洪水风险最大的地区运营。根据 <a href="https://www.fema.gov" target="_blank" rel="noopener noreferrer">联邦紧急事务管理局（FEMA）</a>的数据，洪水事件发生后一年内，近40%的小企业会受到重大灾害的影响，而25%的小企业在受灾一年后会倒闭。'
    },
    aside: {
      header: '建筑承保范围限制',
      body:
        '您可以购买的洪水保险的最高金额是50万美元，支付建筑物损坏的费用。'
    }
  },
  floodInsurance: {
    primary: {
      header: '洪水保险可以降低您的风险',
      body:
        '洪水保单的目的是在洪水损失后提供经济补偿，通常财产保险单不包括这一项。如果发生洪水（这是世界上最常见的自然灾害），如果没有制定洪水政策，企业可能会面临财务损失的风险。洪水可能发生在任何地方——超过20%的洪水理赔来自高风险洪水区以外的房屋 。'
    },
    aside: {
      header: '立即购买，稍后保存',
      body:
        '如果现在购买洪水保险，您可以在未来几年里为自己节省数千美元。'
    }
  },
  businessInsurance: {
    header: '企业中断保险',
    body:
      '企业中断保险（也称为企业收入保险）是一种承保企业在遭受灾难后收入损失的保险。承保的收入损失可能是由于灾难相关的企业设施关闭或灾难后的重建过程造成的。重要的是，企业主要明白，他们目前的财产保险可能无法覆盖收入。企业产生的收入损失可能意味着保持营业或永久停业的区别。'
  },
  lowerYourRiskOptions: {
    header: '降低洪水风险的物理措施',
    list: [
      {
        image: raiseMechanicals,
        imageAlt: '房屋',
        title: '提高机械性能',
        body:
          "最常见的情况是，这种设备位于建筑物的地下室，可能会暴露在洪水中。"
      },
      {
        image: backwaterValve,
        imageAlt: '阀门',
        title: '安装回水阀',
        body:
          '这有助于防止下水道溢流时废水进入建筑物。'
      },
      {
        image: floodproofing,
        imageAlt: '房屋',
        title: '干湿防洪',
        body:
          '如果不能重新安置，并且建筑结构允许，您应该考虑进行干湿防洪。'
      }
    ]
  },
  otherMethodsToLowerRisk: {
    title: '降低洪水风险的其他方法',
    subtitle:
      '洪水随时可能发生在几乎没有洪水风险的地区。无论您是房产业主还是租户，现在可以采取一些措施来帮助您继续营业，并在洪水事件后让您恢复正常。考虑以下设备，保护您的业务免受洪水破坏，并帮助您更快地恢复。',
    list: [
      {
        header: '便携式防洪堤',
        body:
          '设计用于临时安装/放置在门和其他潜在渗透点的便携式防洪屏障可以帮助企业保护空间免受洪水淹没和破坏。',
        image: floodBarrier,
        imageAlt: '便携式防洪堤'
      },
      {
        header: '便携式发电机',
        body:
          '便携式发电机可用于为设备提供持续运行的动力，或在主电源损失期间维持环境条件。',
        image: generator,
        imageAlt: '便携式发电机'
      },
      {
        header: '便携式防水箱',
        body:
          '便携式防水箱可以保存重要文件，让企业能够访问应对灾难事件和及时恢复运营所需的关键信息。',
        image: waterproofStorage,
        imageAlt: '便携式防水箱'
      },
      {
        header: '便携式潜水泵',
        body:
          '潜水泵和软管可用于防止洪水积聚进入建筑物，或在洪水事件后移除水，减轻对建筑物或供应品的损坏，并加快恢复过程。',
        image: waterPump,
        imageAlt: '便携式潜水泵'
      }
    ]
  },
  continuityPlan: {
    title: '城市规划恢复力零售指南',
    body:
      '这是一种独特的资源，可帮助位于城市洪泛区的零售企业主。',
    view: '建议',
    image: resilientRetail,
    imageAlt: '海岸气候弹性指南'
  }
};
