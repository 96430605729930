export default {
  meta: {
    title: 'Apwopo FloodHelpNY.org',
    description:
      'FloodHelpNY.org te kreye antanke yon platfòm pou angaje epi enfòme abitan New York yo apwopo fason yo ka pwoteje kay yo epi finans yo kont inondasyon yo atann ki ka vin pi mal ak nivo lanmè an k ap monte akoz chanjman klimatik lan.',
    ogDescription:
      'FloodHelpNY.org se yon platfòm pou angaje epi enfòme abitan New York yo apwopo risk pou inondasyon an.',
    url: 'https://www.floodhelpny.org/en/about'
  },
  title: 'Apwopo Nou',
  thisWebsite: {
    header: 'Apwopo Sit Entènèt Sa a',
    intro:
      ' te kreye antanke yon platfòm pou angaje epi enfòme abitan New York yo apwopo fason yo ka pwoteje kay yo epi finans yo kont inondasyon yo atann ki ka vin pi mal ak nivo lanmè an k ap monte akoz chanjman klimatik lan.',
    siteGoal:
      'Yon objektif prensipal sit lan se pou konekte pwopriyetè ki gen revni fèb ak mwayen pou pran desizyon eklere apwopo rediksyon risk yo pou pwochen inondasyon ki pral ede tou pou bese tarif asirans kont inondasyon yo.',
    funded: 'Platfòm lan jwenn finansman atravè ',
    gosr: 'Biwo Gouvènè New York lan pou Retablisman apre Tanpèt',
    and: ' ak ',
    newYorkRising: 'New York Rising',
    fema: 'FEMA',
    partnership: ' nan patenarya ak ',
    mocej: 'Biwo Majistra pou Klima ak Jistis Anviwonnmantal la'
  },
  cnycn: {
    header: 'APWOPO SANT POU KATYE LAVIL NEW YORK YO',
    about:
      'Sit entènèt lan se yon pwojè Center for New York City Neighborhoods, Inc., yon òganizasyon ki pa pou pwofi ki angaje l pou fè pwomosyon epi pwoteksyon pwopriyete abòdab nan New York pou fanmi ki nan klas mwayen ak klas ouvriyè yo kapab bati kominote ki djanm, ki ap pwospere. Aprann plis nan ',
    link: 'cnycn.org.',
    contributions: 'Sant Lan rekonesan pou kontribisyon ',
    mocej:
      'Biwo Majistra Lavil New York lan pou Klima ak Jistis Anviwonnmantal lan (MOCEJ)',
    forGuidanceAndEfforts:
      ', pou alafwa direktiv li kòm ekspè epi pou jefò li yo pou atann abitan New York ki nan risk atravè yon kanpay kominikasyon ak sansibilizasyon sou risk pou inondasyon ak asirans kont inondasyon. MOCEJ sipèvize pwogram tolerans klimatik OneNYC an ki gen plizyè kouch. Fonde sou pi bon syans ki disponib lan jan Panèl Vil New York lan sou Chanjman Klimatik lan devlope l lan, MOCEJ travay pou asire katye, ekonomi ak sèvis piblik vil lan ap pare pou reziste epi sòti pi djanm nan enpak chanjman klimatik yo ak lòt menas 21èm syèk yo.',
    otherContributors:
      'Kontni FloodHelpNY.org yo te rann posib tou an pati atravè ekspètiz ',
    legalServices: 'Legal Services NYC',
    legalServicesFunding: ' epi finansman apati Legal Services Corporation.',
    capitalOne:
      'CapitalOne Bank te founi finansman prekòs pou sit entènèt lan. '
  },
  gosr: {
    header: 'Apwopo Biwo Gouvènè New York lan pou Retablisman apre Tanpèt',
    body:
      'Lè l etabli Biwo Retablisman apre Tanpèt, Gouvènè an te vize abòde bezwen ki pi ijan nan kominote yo, pandan yo ap ankouraje tou idantifikasyon solisyon inovatif epi ki dirab pou ranfòse enfrastrikti ak sistèm kritik Eta a. Li fonksyone anba koupòl New York Rising, Biwo Gouvènè an pou Retablisman apre Tanpèt (GOSR) eksplwate anviwon $4.4 milya nan finansman fleksib ki disponib apati pwogram Sibvansyon Blòk pou Devlopman Kominotè pou Retablisman apre Katastwòf (CDBG-DR) Depatman Ameriken pou Lojman ak Devlopman (HUD) an pou konsantre èd yo nan kat domèn prensipal. Asosye ak finansman federal anplis yo bay lòt ajans Eta yo, pwogram CDBG-DR lan ap pèmèt pwopriyetè kay yo, ti biznis yo ak tout kominote yo rekonstwi pi byen pase anvan. E nan yon Eta yo deja konnen pou gwo tolerans li ak lespri kote li ka fè tout bagay, jefò yo ap konstwi wout pou yon kokennchenn repriz - youn ki pral redinamize New York e pi byen prepare l pou pwochen evennman meteyo ekstrèm ki ap vin sou wout li yo.'
  },
  resiliencyAdvisory: {
    header: 'Komite Konsiltasyon sou Tolerans',
    body:
      'Biwo Gouvènè an pou Retablisman apre Tanpèt ak the Center for NYC Neighborhoods te fòme yon Komite Konsiltasyon sou Tolerans pandan re-konsepsyon FloodHelpNY.org pou yon gwo kantite òganizasyon ki enplike nan reponn ak defi nivo lanmè k ap leve yo epi frè asirans kont inondasyon yo ta ka rasanbe pou kreye yon platfòm ki pataje epi louvri pou pataje enfòmasyon epi abilite abitan New York yo pou prepare yo. Patnè nou nan Komite an te gen ladan yo:',
    americanInstituteOfArchitects:
      'The American Institute of Architects - Staten Island Chapter (Enstiti Achitèk Ameriken yo - Chapit Staten Island lan)',
    enterpriseCommunityPartners:
      'Enterprise Community Partners (Patnè Kominotè Antrepriz yo)',
    fema:
      'Federal Emergency Management Agency (Ajans Jesyon Ijans Federal lan)',
    friendsOfRockaway: 'Friends of Rockaway',
    legalServicesNYC: 'Legal Services NYC',
    deptOfCityPlanning:
      'New York City Department of City Planning (Depatman Planifikasyon Iben Vil New York lan)',
    hpd:
      'New York City Department of Housing Preservation and Development (Depatman Prezèvasyon ak Devlopman Lojman Vil New York lan)',
    sbs:
      'New York City Department of Small Business Services (Depatman pou Sèvis Ti Biznis Vil New York yo)',
    mor: 'Mayor’s Office of Resiliency (Biwo Tolerans Majistra a)',
    stBernardProject: 'St. Bernard Project',
    hud: 'Depatman Lojman ak Devlopma Iben Ameriken an'
  }
};
