export default {
  whatYouToldUs: 'ЧТО ВЫ НАМ РАССКАЗАЛИ',
  property: 'Недвижимостьy',
  basicInfo: 'Базовая информация',
  income: 'Доход:',
  household_size: 'Размер семьи:',
  contactInfo: 'Контактная информация',
  name: 'Имя:',
  email: 'Адрес электронной почты:',
  homePhone: 'Домашний телефон:'
};
