export default {
    currentPrefix: '房屋目前位于 ',
    futurePrefix: '但可能会变为 ',
    xZone: '中等风险区',
    aeZone: '高风险区',
    aoZone: '高风险区',
    veZone: '最高风险区',
    noneZone: '最低危险区',
    minimalHazardZone:
      '该房屋目前处于洪水危害最小的区域。',
    disclaimer:
      '由于地图可能不精确，我们无法100%确定您的房产是否位于其中一个或多个区域。了解更多关于 ',
    disclaimerLink: '条款的信息。'
  };
  