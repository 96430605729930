import FloodZones from '../../constants/FloodZones';
import FloodZoneSeverityHelper from './FloodZoneSeverityHelper';

export default class PrefirmNavigator {
  static nextComponent(results) {
    const floodZone = FloodZoneSeverityHelper.getLessSevereZone(results);

    if (results.preFirm) {
      return this.navigatePrefirm(floodZone);
    }

    return this.navigatePostfirm(floodZone);
  }

  static navigatePrefirm(floodZone) {
    if (floodZone === FloodZones.X || floodZone === FloodZones.NONE) {
      return 'preferredRiskRate';
    }
    return 'severeRepetitiveLoss';
  }

  static navigatePostfirm(floodZone) {
    if (floodZone === FloodZones.X || floodZone === FloodZones.NONE) {
      return 'preferredRiskRate';
    }
    if (floodZone === FloodZones.AE) {
      return 'numberOfSteps';
    }
    return 'elevatedBuilding';
  }
}
