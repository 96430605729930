export default {
  currentlyServed: 'Currently Served Areas',
  neighborhood: {
    header: 'Neighborhoods: ',
    content:
      'Bensonhurst, Bergen Beach, Brighton Beach, Canarsie, Coney Island, Gravesend, Georgetown, Gerritsen Beach, Howard Beach, Lower Manhattan, Manhattan Beach, Marine Park, Mill Basin, Mill Island, Red Hook, Rockaway East, Seagate, and Sheepshead Bay.'
  },
  priority: {
    header: 'Eligibility Prioritizing Criteria:',
    criteria: [
      {
        key: 'income',
        copy: 'income level'
      },
      {
        key: 'sandy',
        copy: 'whether or not you suffered Sandy damage'
      },
      {
        key: 'certificate',
        copy: 'whether or not you have an elevation certificate'
      }
    ]
  },
  downloadEligibilityForm:
    'Download full eligibility requirements <a href="/samples/backwater-valve-eligibility.pdf">here</a>.'
};
