import React from 'react';
import PropTypes from 'prop-types';

const RateCalculatorBreadcrumbs = ({
  provider,
  propertyId,
  locale,
  question
}) => {
  const questions = Object.values(provider.questions);
  const breadcrumbs = () => {
    return questions.map((questionProvider) => {
      const { title, breadcrumb } = questionProvider;
      const active = question === title;
      return (
        <li key={breadcrumb} className={active ? 'active detail' : 'detail'}>
          <span>{breadcrumb}</span>
        </li>
      );
    });
  };

  return (
    <div className="breadcrumbs signpost-container">
      <a className="back" href={`/${locale}/profile/${propertyId}`}>
        {provider.breadcrumbs.back}
      </a>
      <ul className="signpost no-list">{breadcrumbs()}</ul>
    </div>
  );
};

RateCalculatorBreadcrumbs.propTypes = {
  provider: PropTypes.object.isRequired,
  question: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  propertyId: PropTypes.string.isRequired
};

export default RateCalculatorBreadcrumbs;
