import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { darken, lighten } from 'polished';
import { colors, borderRadius } from '../../styles/variables';

const Input = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

const Label = styled.label`
  display: inline-block;
  font-family: 'GT-Walsheim-Pro-Light', 'Helvetica Neue';
  border-radius: ${borderRadius};
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
  transition: background-color 0.25s $ease, transform 0.1s $ease;
  color: #fff;
  background-color: ${colors.accent.main};
  border: none;
  padding: 0 2rem;
  height: 4rem;
  line-height: 4rem;
  font-size: 1rem;

  &:hover {
    background-color: ${darken(0.3, colors.accent.main)};
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px ${lighten(0.3, colors.accent.main)};
  }
  &:active {
    transform: translate3d(0, 2px, 0);
  }
`;

const RemoveButton = styled.button`
  display: inline-block;
  font-family: 'GT-Walsheim-Pro-Light', 'Helvetica Neue';
  border-radius: ${borderRadius};
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
  transition: background-color 0.25s $ease, transform 0.1s $ease;
  color: #fff;
  background-color: ${colors.pinkBerry};
  border: none;
  padding: 0px 40px;
  height: 60px;
  line-height: 60px;
  font-size: 1rem;
  margin-left: 10px;

  &:hover {
    background-color: ${darken(0.3, colors.pinkBerry)};
  }
  &:active {
    transform: translate3d(0, 2px, 0);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px ${lighten(0.3, colors.pinkBerry)};
  }
`;

const FileInput = ({
  answer,
  answers,
  questionKey,
  setAnswers,
  index,
  responseField: {
    title,
    remove,
    label,
    canUploadMultipleFiles,
    fileTypes,
    required
  }
}) => {
  useEffect(() => {
    const removeFile = () => {
      const newAnswersArray = [...answers];
      newAnswersArray[index][questionKey] = '';
      setAnswers(newAnswersArray);
    };

    if (answer) {
      const field = document.querySelector(`#${title}`);
      const button = document.querySelector(`#button-${title}`);
      button.addEventListener('click', () => removeFile());
      button.addEventListener('click', () => {
        field.value = '';
      });
    }
  }, [answer, title, answers, index, questionKey, setAnswers]);

  const setFile = (e) => {
    const newAnswersArray = [...answers];
    newAnswersArray[index][questionKey] = e.target.files[0];
    setAnswers(newAnswersArray);
  };

  const renderInput = () => {
    const labelText = answer ? answer.name : label;
    return (
      <>
        <Input
          type="file"
          id={title}
          multiple={canUploadMultipleFiles}
          accept={fileTypes}
          onChange={(e) => setFile(e)}
          required={required}
        />
        <Label htmlFor={title}>{labelText}</Label>
        {answer && (
          <RemoveButton id={`button-${title}`} type="button" role="button">
            {remove}
          </RemoveButton>
        )}
      </>
    );
  };

  return (
    <div className="response-field">
      <div className="form-group">{renderInput()}</div>
    </div>
  );
};

FileInput.propTypes = {
  responseField: PropTypes.object.isRequired,
  answer: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  questionKey: PropTypes.string.isRequired,
  answers: PropTypes.array.isRequired,
  setAnswers: PropTypes.func.isRequired
};

export default FileInput;
