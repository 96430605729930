export default {
  multifamilyApplication: 'https://www.tfaforms.com/439873',
  addressInput: {
    placeholder: 'Busque su dirección',
    action: 'Ver si estás precalificado'
  },
  eligible: {
    eligible: '¡Buenas noticias! ¡Puede que sea elegible!',
    apply: 'Solicítela ahora'
  },
  ineligible: {
    ineligible: 'Desafortunadamente, su hogar no se encuentra en un vecindario al que este programa presta servicios actualmente. Aprende mas sobre tu riesgo inundaciones',
    callToAction: ' aqui.'
  },
  ineligibleOutsideNYC: {
    copy: '¡Ay! Esta herramienta solo funciona para direcciones en la Ciudad de Nueva York. Haga clic en el botón para fondos nacionales.',
    link: 'Visite FloodSmart.gov'
  },
  ineligibleTooBroad: {
    copy: "We're sorry. We were unable to find information for the address you entered. Please verify the address or try again later."
  },
  termsAndConditions: {
    title: 'Acepto los términos y condiciones y normativa de privacidad',
    disclaimerOne: '1. No podemos garantizarle la exactitud de su zona',
    disclaimerTwo: '2. Es posible que la estimación de su seguro contra inundaciones sea inexacta.',
    links: {
      sentenceOpener: 'Más información en nuestros ',
      termsLinkText: 'Términos y condiciones',
      termsLinkUrl: 'terms',
      conjunction: ' and ',
      privacyLinkText: 'Normativa de privacidad',
      privacyLinkUrl: 'privacy'
    },
    navigation: {
      agree: 'Acepto',
      disagree: 'No acepto'
    }
  },
  lookupError: {
    title: 'Error',
    error: 'Lo sentimos. No hemos podido encontrar información con respecto a la dirección que ingresó. Por favor, verifique la dirección e inténtelo nuevamente.',
    tryAgain: 'Inténtelo nuevamente.'
  },
  multipleResultsError: {
    title: 'Error',
    error: 'Lo sentimos. Hemos encontrado varias coincidencias con respecto a la dirección que ingresó. Por favor, verifique la dirección e inténtelo nuevamente.',
    tryAgain: 'Inténtelo nuevamente.'
  }
};
