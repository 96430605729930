export default {
  meta: {
    title: '您的预计费率',
    url: 'https://floodhelpny.org/zh-CN/rate',
    description: null,
    image: null,
    imageAlt: null
  },
  profileCalloutCopy: '我们增加了一些建议。',
  noMitigationCopy: "我们找不到任何缓解方案",
  profileCalloutLink: '查看您的恢复力档案。',
  betaCalloutCopy:
    "正如您所了解的，预计洪水保险是很复杂的。事实上，纽约市没有人为公众提供类似的工具来估算房价。我们始终致力于开发一种能够提供信息的评估工具。然而，尽管知道这个版本不完美，我们将此工具作为第一个版本发布。在您的帮助下，我们可以为所有纽约人进行改进。如果您认为您得到的结果是错误的，请发送电子邮件至",
  betaCalloutLink: 'info@floodhelpny.org.',

  headerProvider: {
    backToProfileLink: '<返回档案',
    header: '您的预计洪水保险。',
    editAnswersLink: '重新开始',
    copy:
      '地图改变时，我们会马上告诉您潜在的成本。'
  },
  rateCalculatorResultProvider: {
    header: '预计洪水保险',
    beta: '测试版',
    link: '查看您的完整预计',
    currentMapStart: '当前地图（ ',
    currentMapEnd: ' 区域）',
    futureMapStart: '咨询地图（',
    futureMapEnd: ' 区域）',
    assumingMax: '*假设最大承保范围',
    numbersAreEstimates:
      '出于许多原因，这些数字是估计值。要了解更多信息，请访问 ',
    termsAndConditions: '条款',
    floodZoneRateProvider: {
      perYear: '每年*'
    },
    rateAdjusterProvider: {
      adjustYourCoverage: '调整承保范围 ',
      buildingCoverage: '建筑承保',
      buildingCoverageTooltip:
        '建筑保险只承保建筑，不包括物品或庭院。包括一些内置的家具和电器以及公用设备。',
      buildingDeductible: '建筑免赔额',
      buildingDeductibleTooltip:
        '这是在您的洪水保险生效之前，您必须自己支付修复建筑物损坏的金额。',
      contentCoverage: '物品承保范围',
      contentCoverageTooltip:
        '物品保险保障您家中的物品。洗衣机和干衣机以及食品冷冻柜都被视为物品。（烤箱和冰箱由“建筑保险”承保。）',
      contentDeductible: '物品免赔额',
      contentDeductibleTooltip:
        '这是在洪水保险生效之前，您必须自己支付修理或更换损坏的物品的金额。',
      collapse: '崩溃'
    }
  },
  prefirmCalloutProvider: {
    copy:
      '之前的 FIRM 补贴正在消失。您未来的利率可能会每年上涨18%！'
  },
  lomaCalloutProvider: {
    header: '等一等！您的房屋可能不应该在高风险洪水区。',
    body:
      '看起来您的房屋在高地上。获取标高证明，并要求您的保险经纪人申请地图修正书（LOMA），这是更改洪水地图并将您的财产排除在洪水区之外的请求。如果LOMA成功，您可以大幅降低洪水保险费率。',
    link: '了解更多信息'
  }
};
