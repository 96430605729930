import profile from './Profile.es';

export default {
  meta: {
    title: 'Estado de mi auditoría de resiliencia de la vivienda',
    url: 'https://www.floodhelpny.org/es/audit-status'
  },
  previewHeader: 'Vista preliminar del Informe de Resistencia de la Vivienda',
  floodZoneProvider: profile.floodZoneProvider,
  auditRateProvider: {
    header: 'SU TARIFA DE SEGURO, CON BASE EN SU CERTIFICADO DE ELEVACIÓN',
    current: 'Mapas actuales',
    future: 'Mapas de consulta',
    zone: 'zona',
    perYear: 'Por año*',
    assumingMax: '*Suponiendo cobertura máxima',
    numbersAreEstimates:
      'Estos números son estimaciones por muchos motivos. Para más información acerca de ellos, remítase a nuestros ',
    termsAndConditions: 'Términos y Condiciones'
  },
  errorText:
    'Algo salió mal. <a href="mailto:info@floodhelpny.org">Póngase en contacto con nosotros</a> si esto sigue sucediendo.',
  loadingText: 'Cargando...'
};
