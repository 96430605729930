export default {
  currentPrefix: 'Esta propiedad está actualmente en ',
  futurePrefix: 'pero podrá cambiarse a ',
  xZone: 'una zona de riesgo moderado',
  aeZone: 'una zona de riesgo alto',
  aoZone: 'una zona de riesgo alto',
  veZone: 'una zona de riesgo máximo',
  noneZone: 'una zona de peligro mínimo',
  minimalHazardZone:
    'Esta propiedad está actualmente en un área de riesgo mínimo de inundación.',
  disclaimer:
    'Debido a que los mapas pueden ser imprecisos, no podemos estar 100% seguros de que su propiedad está en una o más de estas zonas. Obtenga más información en nuestro ',
  disclaimerLink: 'Términos y Condiciones.'
};
