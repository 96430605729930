import backwaterValve from '../images/backwater-valve.svg';
import cookingOil from '../images/cooking-oil.svg';
import garbage from '../images/garbage.svg';
import roofing from '../images/roofing.svg';
import basement from '../images/basement.svg';
import unpaved from '../images/unpaved.svg';
import rainEventGuide from '../images/rain-event-guide.jpg';
import stormwaterFlooding from '../images/stormwater-flooding.svg';
import floodInsurance from '../images/flood-insurance.svg';

export default {
  meta: {
    title: 'Inundaciones por aguas pluviales | FloodHelpNY.org',
    ogTitle: 'Inundaciones por aguas pluviales en NYC | FloodHelpNY.org',
    url: 'https://www.floodhelpny.org/en/stormwater-flooding',
    description:
      'Las inundaciones por aguas pluviales pueden afectar a comunidades que se encuentran a millas de la costa, lo que representa riesgos para su propiedad y su salud.'
  },
  header: 'Inundaciones por aguas pluviales',
  subHeader:
    'Las inundaciones por aguas pluviales pueden afectar a comunidades que se encuentran a millas de la costa, lo que representa riesgos para su propiedad y su salud.',
  whatIsStormwater: {
    header: '¿QUÉ SON LAS AGUAS PLUVIALES?',
    body:
      'Las aguas pluviales son aguas de lluvia o nieve derretida que se escurren por el césped, las calles y otras superficies terrestres. Idealmente, esta escorrentía sería absorbida por las plantas y el suelo o bajaría por las alcantarillas. Pero, en la ciudad de Nueva York, las banquetas y las calles no absorben el agua de lluvia. En cambio, el agua de la lluvia fluye hacia cuencas de captación de la ciudad, donde se despues se canaliza hacia el sistema de alcantarillado. Desafortunadamente, las cuencas de captación de la ciudad tienen una capacidad limitada. Inevitablemente el exceso de agua fluye a las calles y provoca inundaciones locales. Las aguas residuales también pueden regresar hacia los hogares.'
  },
  pollution: {
    header: 'LAS AGUAS PLUVIALES PUEDEN CONTRIBUIR A LA CONTAMINACIÓN',
    body:
      'Además del riesgo de inundación de la propiedad, también existe el riesgo de contaminación. El exceso de escorrentía que no es absorbida por el suelo puede recolectar contaminantes como aceite, pesticidas, bacterias y basura antes de desembocar en desagües pluviales, zanjas de drenaje y el océano.'
  },
  howRainFallImpacts: {
    header:
      'Las inundaciones causadas por las lluvias pueden afectar su calle, hogar o negocio',
    icon: stormwaterFlooding,
    iconAlt: 'Inundaciones por aguas pluviales',
    buttonText: 'Conozca más',
    buttonUrl:
      'https://experience.arcgis.com/experience/6f4cc60710dc433585790cd2b4b5dd0e'
  },
  protectHome: {
    title: 'Protegiendo su hogar contra daños por aguas pluviales',
    subtitle:
      'Si ha experimentado una inundación o desea reducir la probabilidad de que esto le ocurra, estas son algunas cosas que debe considerar.',
    list: [
      {
        header: 'Instale una válvula antirretorno',
        body:
          'Una válvula antirretorno puede evitar que las aguas residuales suban desde el alcantarillado de la ciudad hasta su casa a través de la tubería del sótano. Durante una lluvia intensa, si las aguas residuales de la ciudad suben al nivel de la tubería del sótano, la válvula se cerrará y bloqueará el ingreso de esta agua a su casa.',
        image: backwaterValve,
        imageAlt: 'Válvula antirretorno'
      },
      {
        header: 'Proteja las áreas por debajo del nivel de la calle',
        body:
          'Cuando llueve, el agua fluye y se acumula en áreas bajas. Espacios como garajes subterráneos, sótanos y otras áreas bajas son los más vulnerables a las inundaciones. Reduce el riesgo de inundaciones sellando las grietas en las paredes de los cimientos y considerando el uso de barreras, como sacos de arena.',
        image: basement,
        imageAlt: 'Sotano'
      },
      {
        header: 'Reduzca la escorrentía de aguas pluviales',
        body:
          'Durante lluvias el agua se escurre del concreto y el asfalto casi de inmediato, esta agua al llegar a las alcantarillas puede exceder los límites de diseño del alcantarillado rápidamente. Crear y conservar espacios verdes y sin pavimentar alrededor de su casa puede ayudar a reducir el flujo de agua hacia el alcantarillado al reducir la cantidad de escorrentía de aguas pluviales que su propiedad envía a las alcantarillas.',
        image: unpaved,
        imageAlt: 'Aguas pluviales'
      },
      {
        header: 'Mantenga el aceite y grasa fuera de las alcantarillas',
        body:
          'Eliminar de forma inadecuada la grasa y el aceite de cocina puede obstruir las tuberías internas de su hogar y las alcantarillas de la ciudad. Esto puede causar inundaciones por obstrucciones en el alcantarillado en su vecindario y en su hogar.',
        image: cookingOil,
        imageAlt: 'Aceite y grasa de cocina'
      },
      {
        header: 'Mantenga despejados los sumideros',
        body:
          'Mantener la basura fuera de la calle ayuda a drenar las aguas pluviales y ayuda a proteger los cuerpos de agua de la ciudad. La ciudad trabaja constantemente para mantener las 7,500 millas de alcantarillas y 148,000 sumideros, que recolectan la basura de la calle. Pero si estas se bloquean, pueden ocasionar inundaciones en las calles. Usted y sus vecinos pueden ayudar a reducir este tipo de inundaciones desechando la basura de manera adecuada y retirando con cuidado las hojas o la basura de las rejillas del sumidero antes o durante un evento de lluvia.',
        image: garbage,
        imageAlt: 'Basura'
      },
      {
        header: 'Instale un drenaje de techo adecuado',
        body:
          'Dirigir las aguas pluviales de su techo lejos de las paredes de los cimientos de su casa es fundamental para mantener su sótano seco. Limpie las canaletas con regularidad para evitar que las aguas pluviales se desborden sobre las paredes y conecte los bajantes a los desagües adecuados. Considere instalar un barril de lluvia como método alternativo para capturar las aguas pluviales.',
        image: roofing,
        imageAlt: 'Drenaje de techo'
      },
      {
        header: 'Compre seguro contra inundaciones',
        body:
          'Donde llueve, puede inundarse; alrededor del 20% de todos los reclamos del NFIP a nivel nacional ocurren fuera de la llanura aluvial de FEMA. Consulte con su aseguradora y considere comprar una póliza de seguro contra inundaciones o de obstrucción de alcantarillado. Estas pólizas podrían cubrir los daños y costos de limpieza que resulten de las inundaciones y obstrucciones del alcantarillado causadas por las fuertes lluvias. Recuerde: los daños por inundaciones no están incluidos en las pólizas de seguro estándar para propietarios o inquilinos.',
        image: floodInsurance,
        imageAlt: 'Drenaje de techo'
      }
    ]
  },
  homeOwnerGuide: {
    header: `La guía de la ciudad para la preparación de propietarios de vivienda en caso de eventos de lluvia.`,
    image: rainEventGuide,
    imageAlt: 'Guía de preparación',
    buttonText: 'Lea más',
    posterUrl:
      'https://www1.nyc.gov/assets/dep/downloads/pdf/climate-resiliency/flood-preparedness-flyer.pdf'
  }
};
