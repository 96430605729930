import testimonialPatti from '../images/testimonial-patti.jpg';
import testimonialCarla from '../images/testimonial-carla.jpg';
import testimonialLucia from '../images/testimonial-lucia.jpg';

export default {
  title: '洪水上涨，费用升高',
  subtitle:
    "阅读中心关于洪水保险和纽约市负担能力危机的里程碑式报告",
  people: [
    {
      name: 'Patti',
      neighborhood: 'Midland Beach',
      image: testimonialPatti
    },
    {
      name: 'Carla',
      neighborhood: 'Canarsie',
      image: testimonialCarla
    },
    {
      name: 'Lucia',
      neighborhood: 'Coney Island',
      image: testimonialLucia
    }
  ],
  explanation:
    "不断上涨的洪水保险费率和上升的海平面有可能永久性地将纽约的工人和中产阶级从沿海地区搬迁出去。如果没有协调一致的干预，不断上涨的保费可能会导致只有最富有的居民才能负担得起居住在海岸。",
  link: '了解更多信息'
};
