export default {
    meta: {
      title: '关于 FloodHelpNY.org',
      description:
        'FloodHelpNY.org 是一个平台，让纽约人参与并了解他们如何保护自己的家园和财务免受洪水侵袭，随着气候变化导致的海平面上升，预计会更频繁地出现洪水。',
      ogDescription:
        'FloodHelpNY.org 是一个让纽约人参与并了解洪水风险的平台',
      url: 'https://www.floodhelpny.org/zh-CN/about'
    },
    title: '关于我们',
    thisWebsite: {
      header: '关于本网站',
      intro:
        ' 随着气候变化导致的海平面上升，预计会更频繁地出现洪水，该平台旨在让纽约市房主了解如何保护他们的房屋和财产免受洪水的影响.',
      siteGoal:
        '网站的一个主要目标是将符合条件的中低收入房主联系起来，做出明智的决策，降低他们未来遭受洪水的风险，这也有助于降低他们的洪水保险费率.',
      funded: '该平台通过下列机构筹措资金: ',
      gosr: '纽约州长风暴恢复办公室',
      and: ' 和 ',
      newYorkRising: '纽约崛起',
      fema: 'FEMA',
      partnership: ' 联合 ',
      mocej: "市长气候与环境正义办公室"
    },
    cnycn: {
      header: '关于纽约市社区中心',
      about:
        '该网站是纽约市社区中心的一个项目，是一个非盈利组织，致力于促进并保护纽约人负担得起住房，让中产阶级和工人阶级家庭能够建立强大、繁荣的社区。了解更多信息，请访问 ',
      link: 'cnycn.org.',
      contributions: '中心感谢 ',
      mocej:
        '纽约市市长气候与环境正义办公室（MOCEJ）的贡献',
      forGuidanceAndEfforts:
        ', 感谢他们提供的专家指导，通过普及洪水风险和洪水保险的宣传和认识运动，让有风险的纽约人了解到相关信息。MOCEJ 负责监督城市多层次 OneNYC 气候弹性计划。根据纽约市气候变化专门委员会开发的最佳可用科学，MOCEJ 致力于确保纽约的社区、经济和公共服务能够承受气候变化和21世纪其他威胁的影响，让城市变得更强大。',
      otherContributors:
        'FloodHelpNY.org 的内容是通过',
      legalServices: '纽约法律服务公司的专业知识',
      legalServicesFunding: ' 和法律服务公司提供的资金实现的。',
      capitalOne:
        '网站的早期资金由 CapitalOne 银行提供。'
    },
    gosr: {
      header: '关于纽约州长风暴恢复办公室',
      body:
        '州长设立了风暴恢复办公室，旨在解决社区最迫切的需求，同时鼓励确定创新和持久的解决方案，加强国家的基础设施和关键系统。州长风暴恢复办公室（GOSR）在纽约崛起的保护下运营，通过美国住房和城市发展部（HUD）社区发展街区赠款灾后恢复（CDBG-DR）计划提供的约44亿美元灵活资金提供援助，援助主要集中在四大领域。与其他州机构获得的额外联邦资金相结合，通过CDBG-DR计划，使房主、小企业和整个社区可以更好地开展重建工作。纽约是一个凭借强大可恢复性和进取精神而著称的州，这些努力正在为巨大的复苏铺平道路——这将重振纽约，并为未来的极端天气事件更好地做准备.'
    },
    resiliencyAdvisory: {
      header: '恢复力咨询委员会',
      body:
        '在 FloodHelpNY.org 重新设计期间，州长风暴恢复办公室和纽约社区中心成立了恢复力咨询委员会，这样，应对海平面上升和洪水保险成本挑战的各种组织能够齐聚一堂，创建一个共享和开放的平台，共享信息并提升纽约人未雨绸缪的能力。我们的法律顾问合作伙伴包括:',
      americanInstituteOfArchitects:
        '美国建筑师协会——斯塔滕岛分会',
      enterpriseCommunityPartners: '企业社区合作伙伴',
      fema: '联邦应急管理局',
      friendsOfRockaway: 'Friends of Rockaway',
      legalServicesNYC: 'Legal Services NYC',
      deptOfCityPlanning: '纽约市城市规划局',
      hpd: '纽约市住房保护和发展部',
      sbs: '纽约市小企业服务部',
      mor: '市长弹性办公室',
      stBernardProject: 'St. Bernard 项目',
      hud: '美国住房和城市发展部'
    }
  };
  