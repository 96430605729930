import React from 'react';
import PropTypes from 'prop-types';
import RtappApplicationState from '../constants/RtappApplicationState';
import { useProvider } from '../helpers/hooks';

const statusStep = {
  [RtappApplicationState.PRE_APPLICATION]: 1,
  [RtappApplicationState.PRE_APPLICATION_APPROVED]: 1,
  [RtappApplicationState.APPLICATION_SUBMITTED]: 1,
  [RtappApplicationState.HOMEOWNER_GRANT]: 1,
  [RtappApplicationState.AUDIT_SCHEDULED]: 2,
  [RtappApplicationState.AUDIT_COMPLETE]: 2,
  [RtappApplicationState.FEASIBILITY_REVIEW]: 3,
  [RtappApplicationState.CONSULTATION_SCHEDULED]: 3,
  [RtappApplicationState.CONSULTATION_COMPLETE]: 3,
  [RtappApplicationState.INSTALLATION_PREPARE]: 4,
  [RtappApplicationState.MEET_YOUR_TEAM]: 4,
  [RtappApplicationState.INSTALLATION_TO_BE_SCHEDULED]: 4,
  [RtappApplicationState.INSTALLATION_HAS_BEEN_SCHEDULED]: 4,
  [RtappApplicationState.INSTALLATION_COMPLETE]: 4,
  [RtappApplicationState.REPORT_COMPLETE]: 5,
  [RtappApplicationState.REJECTED]: 6,
  [RtappApplicationState.WITHDRAWN]: 7
};

const AuditStatusTracker = ({ applicationState }) => {
  const provider = useProvider('auditStatusTracker');

  const renderProgressSteps = () => {
    return Object.keys(provider.progressBar)
      .filter((step) => step !== 'rejected' && step !== 'withdrawn')
      .map((step) => (
        <div className="status-step" key={step}>
          <span className="spacer" />
          <span className="step-title">{provider.progressBar[step]}</span>
        </div>
      ));
  };

  const stepTitles = [
    provider.progressBar.apply,
    provider.progressBar.audit,
    provider.progressBar.review,
    provider.progressBar.install,
    provider.progressBar.complete,
    provider.progressBar.rejected,
    provider.progressBar.withdrawn
  ];
  const stepNumber = statusStep[applicationState];
  const stepTitle = stepTitles[stepNumber - 1];
  return (
    <div className="rtapp-application-state">
      <h2>{provider[applicationState].title}</h2>
      <div className={`status-bar step${stepNumber}`}>
        {renderProgressSteps()}
        <div className="status-step-mobile">{stepTitle}</div>
      </div>
    </div>
  );
};

AuditStatusTracker.propTypes = {
  applicationState: PropTypes.string.isRequired
};

export default AuditStatusTracker;
