import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import help from '../images/help.svg';
import { useProvider } from '../helpers/hooks';

const Wrapper = styled.button`
  position: fixed;
  right: 1%;
  bottom: 1%;
  border: none;
  background: none;

  :focus {
    outline: none;
  }

  img {
    width: 100%;
  }

  @media (max-width: 650px) {
    right: 0;
    bottom: 0;
  }
`;

const HelpButton = ({ onClick }) => {
  const provider = useProvider('help');

  return (
    <Wrapper onClick={onClick}>
      <img src={help} alt={provider.helpButtonAlt} />
    </Wrapper>
  );
};

HelpButton.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default HelpButton;
