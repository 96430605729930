export default {
  meta: {
    title:
      'El riesgo de inundación está aumentando | Noticias e información sobre inundaciones | FloodHelpNY.org',
    ogTitle: 'Noticias e información sobre inundaciones | FloodHelpNY.org',
    description:
      'El cambio climático está aumentando el riesgo de inundaciones en la ciudad de Nueva York. Lea las últimas noticias sobre póliza de seguro contra inundaciones, cambio climático',
    url: 'https://www.floodhelpny.org/es/news',
    imageAlt: 'FloodHelpNY Noticias'
  },
  title: 'Noticias',
  subHeading:
    'Reciba actualizaciones sobre inundaciones, riesgo de inundaciones y clima extremo, además de consejos sobre cómo mantener su propiedad y sus finanzas a salvo de inundaciones.',
  created: 'Publicado',
  updated: 'Actualizado',
  noPosts: 'No existen publicaciones',
  emailSubject: '¡Lee este artículo en FHNY!',
  tweet: 'Lee más en https://www.floodhelpny.org/es/news',
  search: 'Buscar publicaciones de noticias aquí',
  share: 'COMPARTA'
};
