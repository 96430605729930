export default {
    title: 'Принять правила и условия',
    disclaimerOne: 'Мы не можем гарантировать точность вашей зоны',
    disclaimerTwo: 'Оценка вашего страхования от наводнения может быть неточной',
    links: {
      sentenceOpener: 'Дополнительная информация в наших ',
      termsLinkText: 'Правилах и условиях',
      termsLinkUrl: '/ru/terms',
      conjunction: ' и ',
      privacyLinkText: 'Политике конфиденциальности',
      privacyLinkUrl: '/ru/privacy',
      submit: 'Нажимая отправить вы принимаете наши правила и условия'
    },
    navigation: {
      agree: 'Я принимаю',
      disagree: 'Я не принимаю'
    }
  };
  