import reportCard from '../images/report-card.svg';
import certificate from '../images/certificate.svg';
import documents from '../images/documents.svg';
import backwaterValve from '../images/backwater-valve.svg';

const example = 'View example';

export default {
  header: "What You'll Get",
  list: [
    {
      header: 'Backwater Valve',
      body:
        'Our team, including engineers and plumbers, will work with you to determine whether your home could benefit from a backwater valve.',
      image: backwaterValve
    },
    {
      header: 'Flood Insurance Counseling',
      body:
        'Our expert counselors will review your flood insurance policy and discuss potential options to help reduce your flood insurance cost through a review of the elevation certificate and <a href="/en/flood-retrofits">mitigation</a> options.',
      image: documents
    },
    {
      header: 'Resiliency Report',
      body:
        'After we inspect your home and assess its flood risk, we’ll send you a detailed technical report, complete with measurements and information on which retrofits (i.e., raising your mechanical systems) can make you more flood-safe.',
      image: reportCard,
      exampleLink: '/samples/technical_report_john_doe.pdf',
      example
    },
    {
      header: 'Elevation Certificate',
      headerLink: '/en/elevation-certificates',
      body:
        'We’ll also send you a document that certifies your home’s elevation. If you live in a high-risk flood zone, the elevation certificate can keep your flood insurance rate from skyrocketing.',
      image: certificate,
      exampleLink: '/samples/elevation_certificate.pdf',
      example
    }
  ]
};
