export default {
  xZone: 'Riesgo moderado',
  aeZone: 'Riesgo alto',
  aoZone: 'Riesgo alto',
  veZone: 'Riesgo máximo',
  noneZone: 'Peligro mínimo',
  baseFloodElevation: 'Nivel de Inundación Base (BFE)',
  xToolTip:
    'La zona X es una zona de riesgo moderado: las propiedades en esta área corren un riesgo moderado de inundarse durante la próxima tormenta fuerte.',
  aeToolTip:
    'La zona AE es una zona de riesgo alto: las propiedades en esta área corren un riesgo alto de inundarse durante la próxima tormenta fuerte.',
  aoToolTip:
    'La zona AO es una zona de riesgo alto: el seguro contra inundaciones es obligatorio y espera un alto riesgo de inundación.',
  veToolTip:
    'La zona V es la zona de más alto riesgo en la Ciudad de Nueva York: las propiedades en esta área corren el riesgo más alto de inundarse durante la próxima tormenta fuerte.',
  floodZoneUrl: '/es/understanding-flood-insurance',
  learnMoreAboutFloodZones: {
    copy: 'Obtenga más información sobre las zonas de inundación',
    linkText: 'aquí'
  },
  floodZoneExplanations: {
    ae: {
      header:
        'Los edificios en zonas de alto riesgo (AE) tienen el potencial de sufrir graves inundaciones, posiblemente de más de varios pies.',
      copy:
        'Se requiere seguro contra inundaciones en una zona de alto riesgo si tiene una hipoteca.'
    },
    ve: {
      header:
        'Los neoyorquinos que viven en zonas de alto riesgo saben que el potencial de daño es real y que tomar medidas ahora ahorra dinero, tiempo y energía en el futuro.',
      copy:
        'Se requiere seguro contra inundaciones en una zona de alto riesgo si tiene una hipoteca.'
    },
    x: {
      header:
        'Las zonas de riesgo moderado son propensas a inundaciones de varias pulgadas, y esas pulgadas pueden ser costosas. Dependiendo de su propiedad, solo seis pulgadas de agua pueden causar daños importantes.',
      copy:
        'Dado que FEMA establece las tarifas del seguro contra inundaciones, las tarifas no varían de una compañía de seguros a otra. Pero muchos agentes de seguros no comprenden muy bien las reglas y cometen muchos errores de calificación.'
    },
    none: {
      header:
        'El cambio de riesgo de inundación y el cambio de zonas nos afectarán a todos. Sin acción, ese impacto puede repercutir en toda la economía de la Ciudad.',
      copy:
        'Casi el 20% de los reclamos de seguros contra inundaciones provienen de áreas de riesgo moderado y riesgo mínimo.'
    },
    ao: {
      header:
        'Los edificios en zonas AO de alto riesgo tienen el potencial de inundarse en áreas cercanas a ríos o arroyos, así como inundaciones poco profundas cada año.',
      copy:
        'Se requiere seguro contra inundaciones en una zona de alto riesgo si tiene una hipoteca.'
    }
  }
};
