import BuildingFeature from '../../constants/BuildingFeatures';
import ContentLocations from '../../constants/ContentLocations';

export default class MultifloorHelper {
  static isMultifloor(results) {
    if (
      results.buildingFeature &&
      [
        BuildingFeature.NO_BASEMENT_ENCLOSURE,
        BuildingFeature.MOBILE_HOME
      ].indexOf(results.building_feature) === -1
    ) {
      return true;
    }
    if (
      results.contentLocation &&
      results.contentLocation !== ContentLocations.LOWEST_FLOOR_ONLY
    ) {
      return true;
    }
    if (results.basement) {
      return true;
    }
    if (results.moreThanOneFloor) {
      return true;
    }
    return false;
  }
}
