const LatLngTranslator = {
  translate: (feature) => {
    const paths = feature.geometry.coordinates[0].map((latLng) => ({
      lat: latLng[1],
      lng: latLng[0]
    }));
    return paths;
  },
  translatorParametric: (areaServed) => {
    const paths = areaServed.geometry.coordinates[0][0].map((latLng) => {
      return {
        lat: latLng[1],
        lng: latLng[0]
      };
    });
    return paths;
  }
};

export default LatLngTranslator;
