export default class GoogleMapsPlacesHelper {
  constructor(apiData) {
    this.location = apiData;
  }

  getLocation() {
    if (!this.isLocationAutocompleteCompatible()) {
      return null;
    }

    return {
      lat: this.location.geometry.location.lat(),
      lng: this.location.geometry.location.lng(),
      formatted_address: this.location.formatted_address,
      street_address: this.getStreetAddress(),
      county: this.getCounty(),
      country: this.getCountry(),
      postal_code: this.getPostalCode(),
      city: this.getCity(),
      state: this.getState(),
      neighborhood: this.getNeighborhood()
    };
  }

  findLongNameByType(type) {
    const entry = this.location.address_components.filter((data) =>
      data.types.includes(type)
    )[0];
    return entry && entry.long_name;
  }

  getStreetAddress() {
    const streetNumber = this.findLongNameByType('street_number');
    const street = this.findLongNameByType('route');
    return `${streetNumber} ${street}`;
  }

  getCounty() {
    return this.findLongNameByType('administrative_area_level_2');
  }

  getCountry() {
    return this.findLongNameByType('country');
  }

  getPostalCode() {
    return this.findLongNameByType('postal_code');
  }

  getCity() {
    return this.findLongNameByType('sublocality');
  }

  getState() {
    return this.findLongNameByType('administrative_area_level_1');
  }

  getNeighborhood() {
    return this.findLongNameByType('neighborhood');
  }

  isLocationAutocompleteCompatible() {
    if (!this.location) {
      return false;
    }

    const keys = Object.keys(this.location);
    if (keys.length <= 1 && keys[0] === 'name') {
      return false;
    }

    return true;
  }
}
