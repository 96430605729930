export default {
  download: 'Descargar',
  elevationCertificate: {
    header: 'Su Certificado de Elevación (PDF)',
    copy:
      'Puede usar esto para obtener una tarifa precisa de seguro contra inundaciones'
  },
  technicalReport: {
    header: 'Su informe de auditoría completo (PDF)',
    copy: 'Informe de auditoría completo'
  },
  fieldReport: {
    header: 'Su informe de campo completo (PDF)',
    copy: 'Informe de campo completo'
  },
  homeownerGrantAgreement: {
    header: 'Su convenio de subvención del propietario completo (PDF)',
    copy: 'Convenio de subvención del propietario completo'
  },
  triPartyAgreement: {
    header: 'Su acuerdo tripartita completo (PDF)',
    copy: 'Acuerdo tripartita completo'
  }
};
