import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import RateCalculatorRadioInput from './RateCalculatorRadioInput';
import PreferredRiskRateNavigator from '../helpers/calculatorNavigation/PreferredRiskRateNavigator';

const RateCalculatorQuestionPreferredRiskRate = ({
  provider,
  answers,
  setAnswers,
  setNextQuestion
}) => {
  useEffect(() => {
    if (answers.preferredRiskRate) {
      setNextQuestion(PreferredRiskRateNavigator.nextComponent(answers));
    }
  }, [answers, setNextQuestion]);

  const updateFloodClaim = (e) => {
    // TODO clean up this if/else
    const value = e.target.value === 'true';
    if (value) {
      setAnswers((prevAnswers) => ({
        ...prevAnswers,
        floodClaim: true,
        preferredRiskRate: null,
        numberOfClaims: null,
        claimAmount: null
      }));
    } else {
      setAnswers((prevAnswers) => ({
        ...prevAnswers,
        floodClaim: false,
        preferredRiskRate: true,
        numberOfClaims: null,
        claimAmount: null
      }));
    }
  };

  const updateNumberOfClaims = (e) => {
    const value = parseInt(e.target.value);
    switch (value) {
      case 1:
        setAnswers((prevAnswers) => ({
          ...prevAnswers,
          preferredRiskRate: true,
          numberOfClaims: 1,
          claimAmount: null
        }));
        break;
      case 2:
        setAnswers((prevAnswers) => ({
          ...prevAnswers,
          preferredRiskRate: null,
          numberOfClaims: 2,
          claimAmount: null
        }));
        break;
      default:
        setAnswers((prevAnswers) => ({
          ...prevAnswers,
          preferredRiskRate: false,
          numberOfClaims: 3,
          claimAmount: null
        }));
        break;
    }
  };

  const updateClaimAmount = (e) => {
    // TODO update this if/else logic
    const value = e.target.value === 'true';
    if (value) {
      setAnswers((prevAnswers) => ({
        ...prevAnswers,
        preferredRiskRate: false,
        claimAmount: true
      }));
    } else {
      setAnswers((prevAnswers) => ({
        ...prevAnswers,
        preferredRiskRate: true,
        claimAmount: false
      }));
    }
  };

  const renderNumberOfClaimsSection = () => {
    if (!answers.floodClaim) {
      return null;
    }
    return (
      <div className="number-of-claims">
        <h4 className="number-of-claims-question">
          {provider.numberOfClaimsQuestion}
        </h4>

        <p className="number-of-claims-subtitle">
          {provider.numberOfClaimsSubtitle}
        </p>
        <RateCalculatorRadioInput
          radioKey="number-of-claims"
          responseField={provider.numberOfClaimsResponseField}
          answer={answers.numberOfClaims}
          setResponse={updateNumberOfClaims}
        />
      </div>
    );
  };

  const renderClaimAmountSection = () => {
    if (answers.numberOfClaims !== 2) {
      return null;
    }
    return (
      <div className="claim-amount">
        <h4 className="claim-amount-question">
          {provider.claimAmountQuestion}
        </h4>
        <RateCalculatorRadioInput
          radioKey="claim-amount"
          responseField={provider.claimAmountResponseField}
          answer={answers.claimAmount}
          setResponse={updateClaimAmount}
        />
      </div>
    );
  };

  return (
    <div className="preferred-risk-rate-question">
      <div className="flood-claim-section">
        <h4 className="flood-claim-question">
          {provider.floodClaimQuestion}
          *
        </h4>

        <RateCalculatorRadioInput
          radioKey="flood-claim"
          responseField={provider.floodClaimResponseField}
          answer={answers.floodClaim}
          setResponse={updateFloodClaim}
        />
      </div>

      <div className="promo">
        *
        {provider.disclaimer}
      </div>
      {renderNumberOfClaimsSection()}
      {renderClaimAmountSection()}
    </div>
  );
};

RateCalculatorQuestionPreferredRiskRate.propTypes = {
  provider: PropTypes.object.isRequired,
  answers: PropTypes.object.isRequired,
  setAnswers: PropTypes.func.isRequired,
  setNextQuestion: PropTypes.func.isRequired
};

export default RateCalculatorQuestionPreferredRiskRate;
