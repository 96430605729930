export default {
  loadingText: '加载中……',
  addressInput: {
    subHeading: '',
    placeholder: '输入地址',
    action: '开始申请'
  },
  useAutocomplete: '请从下拉列表中选择地址',
  lookupError: {
    title: '错误',
    error:
      '请验证地址或稍后再试。如果您觉得收到的信息有误，请致电646-786-0888联系我们的房主中心。',
    tryAgain: '再试一次'
  },
  apiError: {
    title: '错误',
    error:
      '很抱歉，我们的服务器无法响应Google地址查找服务。如果有任何问题，请致电646-786-0888联系我们的房主中心。',
    tryAgain: '再试一次'
  },
  multipleResultsError: {
    title: '错误',
    error:
      '我们很抱歉。您输入的地址有多个匹配项。请验证地址或稍后再试。如果您觉得收到的信息有误，请致电646-786-0888联系我们的房主中心。',
    tryAgain: '再试一次'
  },
  outsideNycError: {
    title: '错误',
    error:
      '我们很抱歉。该地址似乎与纽约市公开的数据不匹配。如果您觉得收到的信息有误，请致电646-786-0888联系我们的房主中心。',
    tryAgain: '尝试其他地址'
  },
  tooBroadError: {
    title: '您能说得更具体些吗？',
    error:
      'FloodHelpNY.org 根据个人地址提供洪水保险和弹性选项的信息。请输入您的家庭地址或要查找的其他家庭地址。如果您觉得收到的信息有误，请致电646-786-0888联系我们的房主中心。',
    tryAgain: '再试一次'
  }
};
