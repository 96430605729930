export default {
  findYourHome: {
    header: 'Find your home on the flood map',
    body:
      'More than 400,000 New Yorkers might be affected by the future flood zones—find out how they may affect you.'
  },
  estimate: {
    header: 'Get a flood insurance estimate',
    body:
      'In NYC’s flood-prone neighborhoods, 76% of homeowners who do not have an elevation certificate are overpaying for flood insurance.'
  },
  lowerRisk: {
    header: 'Lower your risk and your rate',
    body:
      'More than 100,000 New Yorkers have turned to FloodHelpNY as a place to learn, take action, and become more flood-safe.'
  }
};
