export default {
  meta: {
    title: 'Terms and Conditions',
    url: 'https://www.floodhelpny.org/en/terms'
  },
  title: 'Terms and Conditions',
  national_flood_insurance_policy: 'National Flood Insurance Program',
  privacy_policy: 'Privacy Policy',
  general_disclaimer: {
    title: 'General Disclaimer and Limitation of Liability',
    paragraph_1:
      'The materials and information contained in FloodHelpNY.org (the “Site”) (including any links to other websites and content found at linked sites) are provided "as is" and without any warranty of any kind, either expressed or implied, including but not limited to any implied warranties of merchantability or fitness for a particular purpose. Neither the Center for New York City Neighborhoods, Inc. (the “Center”) nor the Governor’s Office of Storm Recovery (“GOSR”) assumes any liability of any kind, whether in contract, tort, strict liability or any other type of liability, for damages of any kind incurred as a result of the use of this Site. Due to the nature of the Internet, the Center does not warrant that access to this Site will be uninterrupted or error free. The Center does not warrant or make any representations regarding the usefulness of or the expected results of the material contained on this Site.',
    paragraph_2:
      'We believe the information provided on this Site to be accurate, and we make every effort to minimize errors. However, since both humans and computers are subject to mistakes, and since information can change quickly, we cannot absolutely guarantee the accuracy of the information provided. If you find an error, or any inaccuracy, please contact us at ',
    email: 'info@floodhelpny.org.',
    paragraph_3:
      'Insurance cost estimates and costs of retrofitting are provided for illustrative purposes only. You are responsible for verifying the accuracy and suitability of all cost estimates. Please seek the advice of licensed and/or competent individuals before making any investment or financial planning decisions. Do not rely solely on cost estimates and financial information found on this Site.  ',
    paragraph_4:
      'You agree by accessing this Site that under no circumstances or any theories of liability under international or civil, common or statutory law including but not limited to strict liability, negligence or other tort theories or contract, patent or copyright laws, will the Center be liable for damages of any kind occurring from the use of the Site or any information, goods or services obtained on this Site including direct, indirect, consequential, incidental, or punitive damages (even if the Center has been advised of the possibility of such damages), to the fullest extent permitted by law. Some jurisdictions do not allow the exclusion or limitation of certain damages so some of these limitations may not apply to you.'
  },
  flood_zones: {
    title: 'Flood Zones',
    part_1:
      'The flood zone, elevation and flood risk results for any individual location shown on this Site are for general information only and you should not rely on them to determine whether you are required to obtain flood insurance or take any other action. Please be advised that the position of flood zone boundaries may be inaccurate in some areas and the address locator may not be 100% accurate in identifying your address. Property owners should contact the ',
    part_2:
      ' for more information or to view an official copy of the Flood Insurance Rate Maps and discuss the flood elevations and zones in the vicinity of their property prior to starting any construction activity. Additionally, information provided on this Site is generally specific to coastal flooding and may not be indicative of other forms of flooding risk, such as flooding from rivers or other causes. Finally, certain information provided on this Site related to elevations and risk of flood damage are based on the National Flood Insurance Program’s rate map and other products, which do not account for future risk from sea level rise due to global warming. For information about how your personal information may be used please consult our '
  },
  flood_insurance_estimate: {
    title: 'Flood Insurance Estimate',
    paragraph_1:
      'By choosing to use this Site to obtain a flood insurance estimate, you agree to be bound by these terms and conditions. If you do not agree to these terms and conditions, you should not submit a flood insurance rate estimate request.',
    paragraph_2:
      'We are not an insurance company and we do not offer or provide flood insurance. Your request for a flood insurance rate estimate will not bring into effect any quote or offer to provide insurance coverage or any insurance coverage. No liability whatsoever will attach to the Center as a result of your request for a flood insurance rate estimate. Flood insurance coverage may only be obtained when you make an application for flood insurance to an insurance company that provides flood insurance and that application is accepted by an insurance provider as evidenced by a separate policy issued by the insurance provider.',
    paragraph_3:
      'Your entire flood insurance estimate, including the estimated premium and the prospective terms of coverage are tentative and are subject to change without notice based on any and all underwriting criteria, manual rates, rating dates, manuals of rules, rating plans and classifications used by the ',
    paragraph_4_part_1: 'Please read our ',
    paragraph_4_part_2:
      ' to understand how the personal information you provide in your request for a flood insurance estimate may be used. While the Center has taken precautions to safeguard the information you transmit in your request for a flood insurance estimate, the Center does not warrant or guarantee that any information will not be intercepted, reviewed, retransmitted, disseminated or used by an unauthorized third party through the internet or otherwise.',
    paragraph_5:
      'The Center reserves the right to monitor this web Site to identify unauthorized attempts to intercept or change information or cause damage. By using this Site you are consenting to such monitoring. Unauthorized attempts to intercept or change information, or to cause damage are strictly prohibited and may be punishable by law.'
  },
  home_resiliency_audit: {
    title: 'Home Resiliency Audit',
    paragraph_1:
      'By choosing to use this Site to apply for and take part in the Home Resiliency Audit program, you agree to be bound by these terms and conditions. If you do not agree to these terms and conditions, you should not submit an application to take part in the Home Resiliency Audit program.',
    paragraph_2:
      'We cannot guarantee that all homeowners that would like to receive a Home Resiliency Audit will receive one. At this time, the program available only to eligible homeowners located in specific neighborhoods in New York City and is limited in the number of participants that can be accommodated.',
    paragraph_3:
      'The Center selected the engineering firms that will conduct the Home Resiliency Audits through a competitive procurement process that sought out qualified architectural, surveying, and engineering firms. One of the criteria the Center used in selecting engineering firms was that the firms demonstrate a thorough understanding of how the Federal Emergency Management Agency (FEMA) determines a building’s flood insurance premium under the National Flood Insurance Program (NFIP) and how flood damage mitigation measures affect the NFIP insurance premium rate applied to a building. ',
    paragraph_4:
      'The Center is not itself conducting the Home Resiliency Audits and so cannot guarantee the results of the work done by the engineering firms or the accuracy of the information provided.',
    paragraph_5:
      'The Center also selected housing counseling and legal services providers to provide resiliency counseling to 1-4 family residential properties in high flood-impacted New York City neighborhoods as part of the Home Resiliency Audit program through a competitive procurement process. These counseling agencies will provide one-on-one counseling and technical assistance for resiliency audit services. One of the criteria the Center used in selecting counseling agencies was that the counseling agencies demonstrate technical and financial competence and have experience working at the community-level.',
    paragraph_6:
      'The Center is not itself conducting the housing counseling and legal services and so cannot guarantee the accuracy or level of work provided by the counseling agencies.',
    paragraph_7:
      'You should use the Home Resiliency Audit for informational and illustrative purposes only. You are responsible for verifying the accuracy and suitability of all assumptions and calculations contained in the audit. Please seek the advice of licensed and/or competent individuals before making any investment or financial planning decisions or undertaking any reconstruction or retrofitting of your home. Do not rely solely on the Home Resiliency Audit, financial or flood insurance rate estimate calculators/calculations or financial information found on this Site.',
    paragraph_8_part_1: 'Please read our ',
    paragraph_8_part_2:
      ' to understand how the personal information you provide in your application to take part in the Home Resiliency Audit program may be used. While the Center has taken precautions to safeguard the information you transmit in your application to take part in the Home Resiliency Audit program and the information generated as a part of the program, the Center does not warrant or guarantee that any information will not be intercepted, reviewed, retransmitted, disseminated or used by an unauthorized third party through the internet or otherwise.'
  },
  backwater_valve: {
    title: 'BACKWATER VALVE INSTALLATION PROGRAM',
    paragraph_1:
      'By choosing to use this Site to apply for and take part in the Backwater Valve Installation program, you agree to be bound by these terms and conditions. If you do not agree to these terms and conditions, you should not submit an application to take part in the Backwater Valve Installation program.',
    paragraph_2:
      'We cannot guarantee that all homeowners that would like to receive a backwater valve installation will receive one. At this time, the program is available only to low-to-moderate income eligible homeowners located in specific neighborhoods in New York City and is limited in the number of participants that can be accommodated. To be eligible for a backwater valve installation, homeowners must undergo a Home Resiliency Audit and the engineer must determine that the property could benefit from the installation of a sewer backwater valve.',
    paragraph_3:
      'The Center is not itself conducting backwater valve installations and so cannot guarantee the results of the work done by the firms that will install backwater valves. The Center will identify a licensed master plumber from a pool of pre-qualified firms who will complete the installation process for eligible homeowners. The Center will pay the master plumber directly for the cost of installing the valve. Homeowners should review the master plumbers qualifications independently before agreeing to allow any installation work to occur. ',
    paragraph_4:
      'While installing a backwater valve may reduce the risk of damage due to sewer backup, the Center does not guarantee that sewer backup or other forms of damage will not occur after the valve is installed. It is the homeowner’s responsibility to understand how the valve works, maintain the valve, regularly inspect it, and keep it in good working order. ',
    paragraph_5:
      'The Center will not provide any maintenance or inspection services after valve installation. ',
    paragraph_6:
      'There are risks associated with installing a backwater valve. Damage to the home could occur if plumbing fixtures, such as showers, sinks, and toilets are used during the valve installation. In the event of a flood or heavy storm, the valve may fail, and damage to the property may result. ',
    paragraph_7: 'Homeowners who receive a valve installation should:',
    paragraph_7_part_1:
      'Carefully review operation and maintenance instructions provided by the valve’s manufacturer and follow these instructions.',
    paragraph_7_part_2:
      'Avoid flushing wipes, paper towels, sanitary products, oils, fats, or grease down toilets or sinks. These items can accumulate and cause the valve to fail.',
    paragraph_7_part_3:
      'Check the valve before a storm. The valve may fail during a flood if there are any items blocking the flapper in the valve. Look for any items that may block the valve and remove them. ',
    paragraph_8:
      'Homeowners who receive a valve installation must agree to maintain their own homeowner’s insurance policy at their own expense including additional sewer backup insurance if available. They must also carry flood insurance up to the cost of the installation grant.',
    paragraph_9:
      'Please read our Privacy Policy to understand how the personal information you provide in your application to take part in the Backwater Valve Installation program may be used. While the Center has taken precautions to safeguard the information you transmit in your application to take part in the Backwater Valve Installation program and the information generated as a part of the program, the Center does not warrant or guarantee that any information will not be intercepted, reviewed, retransmitted, disseminated or used by an unauthorized third party through the internet or otherwise.'
  }
};
