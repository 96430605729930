import greenTelephone from '../images/green-telephone.svg';
import seaWaves from '../images/sea-waves.svg';
import strategy from '../images/strategy.svg';
import training from '../images/training.svg';

export default {
  header: 'ЧТО ВЫ МОЖЕТЕ СДЕЛАТЬ',
  callsToAction: [
    {
      header: 'Начните составление вашего плана обеспечения бесперебойной деятельности сегодня',
      icon: strategy,
      iconAlt: 'Стратегия',
      body:
        'Используйте эти руководящие принципы для того, чтобы быстро восстановиться после любой приостановки действия. Защитите ваши инвестиции, оставайтесь на связи с вашими работниками и поставщиками, и будьте готовы к реагированию на неопределенность.',
      buttonText: 'Вид',
      buttonLink:
        'https://www.nyc.gov/html/nycbe/downloads/pdf/EM_SBSBCPWorksheetsMar2017.pdf'
    },
    {
      header:
        'Удостоверьтесь, что ваш бизнес готов. Посетите семинар или вебинар.',
      icon: training,
      iconAlt: 'Тренинг',
      body:
        'От утечки газа и пожаров до интенсивных бурь и киберугроз бизнес Нью-Йорка сталкивается с целым рядом непредвиденных чрезвычайных ситуаций. SBS предлагает бесплатные семинары и вебинары по подготовке к чрезвычайным ситуациям для предприятий по всему городу Нью-Йорк.',
      buttonText: 'Дополнительная информация здесь',
      buttonLink:
        'https://www1.nyc.gov/nycbusiness/article/preparedness-response-recovery'
    },
    {
      icon: seaWaves,
      iconAlt: 'волны',
      header: 'Растущая угроза изменения климата',
      body:
        'С повышающимся уровнем моря, более экстремальными и частыми погодными явлениями, вызванными изменением климата, сейчас самое время начать подготовку. Изменение климата - это угроза нашему обществу и, будучи опорой нашего общества и экономики, малый бизнес является жизненно важным элементом для культивации жизнестойкого города.',
      buttonText: 'Читать сейчас',
      buttonLink:
        'https://onenyc.cityofnewyork.us/stories/a-livable-climate/#overview'
    },
    {
      icon: greenTelephone,
      iconAlt: 'телефон',
      header: 'Свяжитесь с Аварийной службой SBS для получения срочной помощи',
      body:
        'Для получения срочной помощи свяжитесь с Аварийной службой SBS. Команда может предоставить вам самую свежую аварийную информацию о закрытии дорог, перебоях в работе коммунальных услуг, безопасности и разрушениях. Они также могут связать вас с местными бизнес организациями для оказания дополнительной помощи. ',
      buttonText: 'Дополнительная информация',
      buttonLink:
        'https://www1.nyc.gov/nycbusiness/article/preparedness-response-recovery'
    }
  ]
};
