export default {
  loadingText: 'Cargando...',
  addressInput: {
    subHeading: '',
    placeholder: 'Ingrese su dirección',
    action: 'Comenzar'
  },
  useAutocomplete: 'Seleccione una dirección del menú desplegable',
  lookupError: {
    title: 'Error',
    error:
      'Por favor verifique la dirección o intente de nuevo más tarde. Si cree que recibió este mensaje por error, llame a nuestro Centro de propietarios de viviendas al 646-786-0888',
    tryAgain: 'Inténtelo nuevamente.'
  },
  apiError: {
    title: 'Error',
    error:
      'Lo sentimos, nuestros servidores no pueden obtener una respuesta del servicio de búsqueda de direcciones de la ciudad de Google. Llame a nuestro Centro de Propietarios al 646-786-0888 si tiene alguna pregunta',
    tryAgain: 'Inténtelo nuevamente.'
  },
  multipleResultsError: {
    title: 'Error',
    error:
      'Lo sentimos. Hemos encontrado varias coincidencias con respecto a la dirección que ingresó. Por favor, verifique la dirección e inténtelo nuevamente.',
    tryAgain: 'Inténtelo nuevamente.'
  },
  outsideNycError: {
    title: 'Error',
    error:
      'Lo sentimos. Este sitio solamente contiene información relacionada con la Ciudad de Nueva York.',
    tryAgain: 'Intente con otra dirección'
  },
  tooBroadError: {
    title: 'SPANISH Can you be more specific?',
    error:
      'SPANISH FloodHelpNY.org provides information on flood insurance and resiliency options based on individual addresses. Please enter your home address or the address of another home you’d like to look up. Or, if you feel you received this message in error, please call our Homeowner Hub at 646-786-0888.',
    tryAgain: 'Intente con otra dirección'
  }
};
