const cartoUrl =
  'https://cartodb-libs.global.ssl.fastly.net/cartodb.js/v3/3.15/cartodb.js';

const createCurrentLayer = (map) => {
  return new Promise((resolve) => {
    window.cartodb
      .createLayer(
        map,
        {
          user_name: 'cnycn-admin',
          type: 'cartodb',
          sublayers: [
            {
              sql: 'SELECT * FROM buoyant_cfirm',
              cartocss: `#current_firm[fld_zone="AE"] {
                  polygon-fill: #F57898;
                  polygon-opacity: 0.6;
                }
                #current_firm[fld_zone="AO"] {
                    polygon-fill: #F57898;
                    polygon-opacity: 0.6;
                  }
                #current_firm[fld_zone="0.2 PCT ANNUAL CHANCE FLOOD HAZARD"] {
                  polygon-fill: #FFC900;
                  polygon-opacity: 0.6;
                }
                #current_firm[fld_zone="VE"] {
                  polygon-fill: #C24D6B;
                  polygon-opacity: 0.6;
                }
                #s_fld_haz_ar[fld_zone="X"] {
                  polygon-fill: #F2EADA;
                  polygon-opacity: 0.6;
                }`
            }
          ]
        },
        { https: true }
      )
      .addTo(map, 0)
      .done((layer) => {
        resolve(layer);
      });
  });
};

const createFutureLayer = (map) => {
  return new Promise((resolve) => {
    window.cartodb
      .createLayer(
        map,
        {
          user_name: 'cnycn-admin',
          type: 'cartodb',
          sublayers: [
            {
              sql: 'SELECT * FROM buoyant_pfirm',
              cartocss: `#s_fld_haz_ar[fld_zone="AE"] {
                  polygon-fill: #F57898;
                  polygon-opacity: 0.6;
                }
                #s_fld_haz_ar[fld_zone="AO"] {
                    polygon-fill: #F57898;
                    polygon-opacity: 0.6;
                  }
                #s_fld_haz_ar[fld_zone="0.2 PCT ANNUAL CHANCE FLOOD HAZARD"] {
                  polygon-fill: #FFC900;
                  polygon-opacity: 0.6;
                }
                #s_fld_haz_ar[fld_zone="VE"] {
                  polygon-fill: #C24D6B;
                  polygon-opacity: 0.6;
                }
                #s_fld_haz_ar[fld_zone="X"] {
                  polygon-fill: #F2EADA;
                  polygon-opacity: 0.6;
                }`
            }
          ]
        },
        { https: true }
      )
      .addTo(map, 1)
      .done((layer) => {
        resolve(layer);
      });
  });
};

const init = () =>
  new Promise((resolve) => {
    if (window.cartodb) {
      resolve();
    }

    const script = document.createElement('script');
    script.src = cartoUrl;
    script.async = true;
    document.body.appendChild(script);
    script.onload = resolve;
  });

export default {
  init,
  createCurrentLayer,
  createFutureLayer
};